<div id="banner" *ngIf="bannerEnabled">
	<span class="secondary" [innerHtml]="'global.main.bannerLeftRightText' | translate | safe: 'html'"></span
	><span class="primary" [innerHtml]="'global.main.bannerCenterText' | translate | safe: 'html'"></span
	><span class="secondary" [innerHtml]="'global.main.bannerLeftRightText' | translate | safe: 'html'"></span>
</div>
<div class="login">
	<div class="wrapper">
		<article class="card">
			<div id="slide-area" [ngClass]="{ 'show-error-details': showErrorDetails }">
				<div class="single-slide">
					<header>
						<h1 [translate]="'login'"></h1>
						<div class="logo">
							<span class="img stages"></span>
						</div>
					</header>
					<ng-container *ngIf="basicAuthenticationEnabled; else noaccess">
						<form name="loginForm" role="form">
							<ng-container *ngIf="showSingleError(maintenanceMessage, securityException, licenseTimeoutSoon)">
								<div class="login-information-container max-height">
									<ng-container *ngTemplateOutlet="formErrors"></ng-container>
								</div>
							</ng-container>
							<ng-container *ngIf="showMultipleErrors(maintenanceMessage, securityException, licenseTimeoutSoon)">
								<div class="field">
									<button (click)="toggleErrorDetails()" class="error error-button">
										<span [translate]="'login.error.loginNotPossible'"></span>
										<i class="ico ico-warning error"></i>
									</button>
								</div>
							</ng-container>

							<div class="field">
								<label for="username" [translate]="'login.username'"></label>
								<input
									type="text"
									id="username"
									name="username"
									tabindex="1"
									class="text username"
									[(ngModel)]="username"
									stagesAutofocus
									autocapitalize="none"
									autocomplete="username"
								/>
							</div>

							<div class="field">
								<label for="password" [translate]="'login.password'"></label>
								<input
									#passwordInput
									type="password"
									id="password"
									name="password"
									tabindex="2"
									class="text password"
									[(ngModel)]="password"
									(focus)="$any($event.target).select()"
									autocomplete="off"
								/>
								<div class="error" *ngIf="errors" [translate]="errors"></div>
							</div>

							<div class="row">
								<div class="field" *ngIf="cookieEnabled">
									<input
										type="checkbox"
										id="remember-me"
										name="remember-me"
										tabindex="4"
										class="checkbox"
										[(ngModel)]="rememberUser"
										property="enableAutologin"
									/><label for="remember-me" [translate]="'login.autologin'"></label>
								</div>
							</div>

							<div class="login-button max">
								<button
									(click)="passwordAuthentication()"
									class="button lg prime"
									[translate]="'login.submit'"
								></button>
							</div>

							<div class="row helpdesk" *ngIf="!!helpDeskInformation || !!helpDeskLinkHref">
								<p>
									<span *ngIf="!!helpDeskInformation">{{ helpDeskInformation }}&nbsp;</span
									><a *ngIf="!!helpDeskLinkHref" target="_blank" href="{{ getHrefForExternalLink(helpDeskLinkHref) }}"
										><span *ngIf="!!helpDeskLinkText">{{ helpDeskLinkText }}</span
										><span *ngIf="!helpDeskLinkText">{{ helpDeskLinkText }}</span></a
									>
								</p>
							</div>
						</form>
					</ng-container>
				</div>
				<div class="single-slide">
					<div class="header">
						<button class="close-button clickable" (click)="toggleErrorDetails()"><i class="ico ico-close"></i></button>
					</div>
					<form role="form">
						<ng-container *ngTemplateOutlet="formErrors"></ng-container>
					</form>
				</div>
				<ng-template #noaccess>
					<div class="field">
						<div class="error" [translate]="'login.basic.disabled'"></div>
					</div>

					<div class="field" *ngIf="maintenanceMessage" style="height: 50px" [translate]="'login.maintenance'">
						<div class="error">{{ maintenanceMessage }}</div>
					</div>

					<div
						class="field error"
						*ngIf="licenseTimeoutSoon"
						[translate]="systemLicenseTimeout ? 'login.systemlicensetimeoutsoon' : 'login.licensetimeoutsoon'"
						[translateParams]="bootstrapProperties"
					></div>

					<div class="field" *ngIf="securityException" [translate]="'login.error.nopermission'">
						<div class="error">{{ securityException }}</div>
					</div>

					<div class="field error" *ngIf="errors" [translate]="errors"></div>

					<div class="row helpdesk" *ngIf="!!helpDeskInformation || !!helpDeskLinkHref">
						<p>
							<span *ngIf="!!helpDeskInformation">{{ helpDeskInformation }}&nbsp;</span
							><a *ngIf="!!helpDeskLinkHref" target="_blank" href="{{ getHrefForExternalLink(helpDeskLinkHref) }}"
								><span *ngIf="!!helpDeskLinkText">{{ helpDeskLinkText }}</span
								><span *ngIf="!helpDeskLinkText">{{ helpDeskLinkText }}</span></a
							>
						</p>
					</div>
				</ng-template>

				<ng-template #errorTemplate let-translate="translate" let-translateParams="translateParams">
					<div class="field">
						<div class="error">
							<span [translate]="translate" [translateParams]="translateParams"></span>
						</div>
					</div>
				</ng-template>
				<ng-template #formErrors>
					<ng-container *ngIf="maintenanceMessage">
						<ng-container
							*ngTemplateOutlet="
								errorTemplate;
								context: { translate: 'login.maintenance', translateParams: { customMessage: maintenanceMessage } }
							"
						></ng-container>
					</ng-container>
					<ng-container *ngIf="licenseTimeoutSoon">
						<ng-container
							*ngTemplateOutlet="
								errorTemplate;
								context: {
									translate: systemLicenseTimeout ? 'login.systemlicensetimeoutsoon' : 'login.licensetimeoutsoon',
									translateParams: bootstrapProperties
								}
							"
						></ng-container>
					</ng-container>
					<ng-container *ngIf="securityException">
						<ng-container
							*ngTemplateOutlet="
								errorTemplate;
								context: {
									translate: 'login.error.nopermission.withReason',
									translateParams: { reason: securityException }
								}
							"
						></ng-container>
					</ng-container>
				</ng-template>
			</div>
		</article>
	</div>
</div>
