import { Component, OnDestroy, OnInit, TrackByFunction } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { MainService } from "core/main.service";
import { NotificationService } from "core/notification.service";
import { PreferencesService } from "core/preferences.service";
import { CommonStore } from "core/store";
import { ViewService } from "core/view.service";
import { FitToPageMenuItemImpl } from "process/diagram/fit-to-page-menu-item.model";
import { FreezeService, JobInfoMap } from "process/freeze/freeze.service";
import { PhaseAdapter } from "process/freeze/overview/phase-adapter.model";
import { PhaseMatrix } from "process/freeze/overview/phase-matrix.model";
import { ProcessAdapter } from "process/freeze/overview/process-adapter.model";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { PhaseFreezeOverviewType } from "process/view/type.interface";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

type ViewableProcess = stages.process.ViewableProcess;
type ViewableElement = stages.process.ViewableElement;

type UserParameters = StringToBoolean;

@Component({
	templateUrl: "./phase-freeze-overview.component.html",
	styleUrls: ["./phase-freeze-overview.component.scss"],
})
export class PhaseFreezeOverviewComponent extends BaseComponent implements OnInit, OnDestroy {
	showUpdateButton?: boolean;
	dependentPhasesPath!: string;
	phaseSubtypeIdent?: string;
	cssClass!: string;
	fitToPage: boolean = true;

	phaseMatrix$!: Observable<PhaseMatrix>;
	destroy$: Subject<boolean> = new Subject<boolean>();

	fitToPageMenuItem!: FitToPageMenuItemImpl;

	private pollHandle?: number;

	constructor(
		componentService: ComponentService,
		private viewService: ViewService,
		private preferencesService: PreferencesService,
		private route: ActivatedRoute,
		private mainService: MainService,
		private card: CardComponent,
		private freezeService: FreezeService,
		private notificationService: NotificationService,
		private phaseFreezeStore: CommonStore,
	) {
		super(componentService);
	}

	getClasses(): string {
		return this.cssClass;
	}

	ngOnInit(): void {
		const componentType = this.type as PhaseFreezeOverviewType;
		this.cssClass = componentType.classes ? componentType.classes : "";

		this.phaseMatrix$ = this.viewService.awaitSelfElementObservable().pipe(
			map((self) => this.createAndStorePhaseMatrix(self)),
			map((phaseMatrix) => this.publishInitialJobStatus(phaseMatrix)),
		);

		this.phaseFreezeStore
			.select<PhaseMatrix | undefined>("phaseMatrix")
			.pipe(takeUntil(this.destroy$))
			.subscribe((pM) => {
				if (pM) {
					this.checkForStopPolling(pM);
					this.checkForStartPolling(pM);
				}
			});

		this.readFitToPageParameter().then((ftp: boolean) => {
			this.fitToPage = ftp;
		});

		this.fitToPageMenuItem = new FitToPageMenuItemImpl(this, "phaseFreezeOverview_fitToPage");
		this.fitToPageMenuItem.onValueChange = (value: boolean) => {
			this.fitToPage = value;
			this.storeFitToPageParameter();
		};
		const menuItems = [this.fitToPageMenuItem];
		this.card.menuItems =
			this.card.menuItems && this.card.menuItems.length > 0
				? this.card.menuItems.concat({ isSeparator: true }, menuItems)
				: menuItems;
	}

	override ngOnDestroy(): void {
		// "super.ngOnDestroy();" is not called. This is perhaps an error, but adding it was explicitely not wanted as part of ST-33629.
		this.destroy$.next(true);
		this.destroy$.unsubscribe();

		if (this.pollHandle) {
			this.notificationService.cancel(this.pollHandle);
		}
		this.phaseFreezeStore.set("phaseMatrix", undefined);
	}

	private createAndStorePhaseMatrix(self: stages.process.ProcessView): PhaseMatrix {
		const phaseMatrix = new PhaseMatrix(
			self as unknown as ViewableProcess,
			this.dependentPhasesPath,
			this.phaseSubtypeIdent,
		);
		this.phaseFreezeStore.set("phaseMatrix", phaseMatrix);
		return phaseMatrix;
	}

	private publishInitialJobStatus(phaseMatrix: PhaseMatrix): PhaseMatrix {
		if (phaseMatrix.program.process.isValidVersion) {
			this.getRunningJobs(phaseMatrix).then(() => {
				this.phaseFreezeStore.set("phaseMatrix", phaseMatrix);
			});
		}
		return phaseMatrix;
	}

	private checkForStartPolling(phaseMatrix: PhaseMatrix): void {
		if (!this.pollHandle && hasRunningJobs(phaseMatrix)) {
			this.pollHandle = this.notificationService.pollPromise(
				async (matrix) => this.getRunningJobs(matrix),
				() => {},
				3000,
				phaseMatrix,
			);
		}
	}

	private checkForStopPolling(phaseMatrix: PhaseMatrix): void {
		if (this.pollHandle && !hasRunningJobs(phaseMatrix)) {
			this.notificationService.cancel(this.pollHandle);
			this.pollHandle = undefined;
			this.viewService.refreshView(phaseMatrix.program.process.workspaceId, phaseMatrix.program.process.pv);
		}
	}

	async getRunningJobs(phaseMatrix: PhaseMatrix): Promise<JobInfoMap> {
		const jobExecutionInfo = await this.freezeService.getRunningFreezeJobs(
			phaseMatrix.program.process.workspaceId,
			phaseMatrix.program.process.pv,
			{ typeIdent: "process", id: phaseMatrix.program.process.id },
			"phase",
			phaseMatrix.getAllPhaseIds(),
			phaseMatrix.getAllWorkspaceIds(),
		);
		phaseMatrix.setJobExecutionInfo(jobExecutionInfo);
		phaseMatrix.setUpdateJobExecutionInfo(jobExecutionInfo);
		this.checkForStopPolling(phaseMatrix);
		return jobExecutionInfo;
	}

	getDependents(phase: ViewableElement): ViewableElement[] | undefined {
		const dependents = phase.associations[this.dependentPhasesPath];
		if (!dependents) {
			return undefined;
		}
		return dependents.list.filter((a) => !!a.targetElement).map((a) => a.targetElement!);
	}

	getPhaseId: TrackByFunction<PhaseAdapter> = (_index, phaseAdapter: PhaseAdapter) => {
		return phaseAdapter.phase.id;
	};

	getProcessId: TrackByFunction<ProcessAdapter> = (_index, processAdapter: ProcessAdapter) => {
		return processAdapter.process.id;
	};

	/* parameter storage handling / preferences */

	private storeParameters(
		prefKey: string,
		keyOfChangedParameter: string,
		defaultUserParams: UserParameters,
		newParameters: UserParameters,
	): void {
		this.preferencesService.getPreference(prefKey, defaultUserParams).then((oldParameters) => {
			if (oldParameters[keyOfChangedParameter] !== newParameters[keyOfChangedParameter]) {
				this.preferencesService.setPreference(prefKey, newParameters);
			}
		});
	}

	private async readParameters(prefKey: string, defaultUserParams: UserParameters): Promise<UserParameters> {
		if (defaultUserParams) {
			return this.preferencesService.getPreference(prefKey, defaultUserParams);
		}
		return new Promise<UserParameters>(defaultUserParams);
	}

	private async readFitToPageParameter(): Promise<boolean> {
		return this.readParameters("phaseFreezeOverview.fitToPage", {
			state: true,
		}).then((parameters) => {
			return parameters.state;
		});
	}

	private storeFitToPageParameter(): void {
		this.storeParameters(
			"phaseFreezeOverview.fitToPage",
			"state",
			{
				state: true,
			},
			{
				state: this.fitToPage,
			},
		);
	}

	getIconClasses(phaseAdapter: PhaseAdapter): string[] {
		const classes = ["ico"];
		return classes.concat(phaseAdapter.getIconClasses());
	}

	openPopup(commands: unknown[]): void {
		this.mainService.openPopup(commands, this.route);
	}
}

function hasRunningJobs(phaseMatrix: PhaseMatrix): boolean {
	if (hasProcessRunningJobs(phaseMatrix.program)) {
		return true;
	}
	return !!phaseMatrix.projects.find(hasProcessRunningJobs);
}

function hasProcessRunningJobs(processAdapter: ProcessAdapter): boolean {
	return processAdapter.isAnyOperationInProgress();
}
