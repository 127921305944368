import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { EnactmentAssociationStore } from "process/enactment/association/enactment-association-store.logic";
import { EnactmentAssociationService } from "process/enactment/association/enactment-association.service";

@Injectable({ providedIn: "root" })
export class EnactmentAssociationStoreResolver implements Resolve<EnactmentAssociationStore> {
	constructor(
		private viewService: ViewService,
		private enactmentAssociationService: EnactmentAssociationService,
		private mainService: MainService,
	) {}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async resolve(): Promise<EnactmentAssociationStore> {
		return new EnactmentAssociationStore(this.viewService, this.enactmentAssociationService, this.mainService);
	}
}
