import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ViewService } from "core/view.service";
import { ComplianceAssociationService } from "process/compliance/associations/compliance-association-service";
import { ComplianceAssociationStore } from "process/compliance/associations/compliance-association-store.logic";

@Injectable({ providedIn: "root" })
export class ComplianceAssociationStoreResolver implements Resolve<ComplianceAssociationStore> {
	constructor(private viewService: ViewService, private associationService: ComplianceAssociationService) {}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async resolve(): Promise<ComplianceAssociationStore> {
		return new ComplianceAssociationStore(this.viewService, this.associationService);
	}
}
