import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { InputBase } from "common/form/input/types/input-base";
import { TextInput } from "common/form/input/types/text-input";
import { TextAreaInput } from "common/form/input/types/textarea-input";
import { ViewService } from "core/view.service";
import { FeedbackService } from "process/feedback/feedback.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { Observable } from "rxjs";

type ProcessView = stages.process.ProcessView;
type UserFeedbackResponse = stages.process.feedback.UserFeedbackResponse;
type UserFeedbackResponseItem = stages.process.feedback.UserFeedbackResponseItem;
type CreatedTicket = stages.process.feedback.CreatedTicket;

@Component({
	selector: "stages-feedback-widget",
	templateUrl: "./feedback-widget.component.html",
	styleUrls: ["./feedback-widget.component.scss"],
})
export class FeedbackWidgetComponent extends BaseComponent implements OnInit {
	processView$!: Observable<ProcessView>;
	inputs: InputBase<unknown>[] = [];
	summaryInput!: InputBase<unknown>;
	form = new FormGroup({});
	state!: "created" | "error" | "inProgress" | "minified" | "open";
	response!: CreatedTicket;
	templateLoaded: boolean = false;

	constructor(
		componentService: ComponentService,
		private feedbackService: FeedbackService,
		private route: ActivatedRoute,
		private viewService: ViewService,
	) {
		super(componentService);
	}

	ngOnInit(): void {
		this.processView$ = this.viewService.awaitSelfElementObservable();
		this.state = "minified";

		this.summaryInput = new TextInput({
			key: "summary",
			value: "",
			translateKey: "process.feedback.mapping.summary",
			required: true,
			maxLength: 250,
		});

		this.summaryInput.expand = () => this.expand();
		const formControl = new FormControl(this.summaryInput.value, this.summaryInput.validators);
		this.form.addControl(this.summaryInput.key, formControl);
	}

	private expand(): void {
		this.loadTemplate();
		this.state = "open";
	}

	private async loadTemplate(): Promise<void> {
		if (this.templateLoaded) {
			return;
		}

		this.templateLoaded = true;

		const workspaceId = this.route.snapshot.paramMap.get("workspaceId")!;
		const template = await this.feedbackService.getUserTemplate(workspaceId);

		template.items.forEach((item) => {
			let input;
			switch (item.type) {
				case "string":
					input = new TextInput({
						key: item.ident,
						value: "",
						translateKey: "process.feedback.mapping." + item.ident,
						required: false,
						maxLength: 250,
					});
					break;
				case "text":
					input = new TextAreaInput({
						key: item.ident,
						value: "", // TODO Autosizing dosn't work on initial view
						translateKey: "process.feedback.mapping." + item.ident,
						required: false,
						maxLength: 1000,
					});

					break;
				// TODO More types, see edit-attributes.component.ts
				default:
					throw new Error(`Unknown attribute type ${item.type}`);
			}

			this.inputs.push(input);
		});

		this.inputs.forEach((input) => {
			if (input) {
				const formControl = new FormControl(input.value, input.validators);
				this.form.addControl(input.key, formControl);
			}
		});
	}

	async save(view: ProcessView): Promise<void> {
		const workspaceId = this.route.snapshot.paramMap.get("workspaceId")!;
		const processVersion = this.route.snapshot.paramMap.get("processVersion")!;

		if (this.form.valid) {
			this.state = "inProgress";

			const items: UserFeedbackResponseItem[] = [];
			Object.keys(this.form.controls).forEach((key, index) => {
				const control = this.form.controls[key];
				const item: UserFeedbackResponseItem = {
					ident: key,
					value: control.value || "",
				};
				items.push(item);
			});

			const userFeedbackResponse: UserFeedbackResponse = {
				items: items,
				elementUrl: window.location.href,
			};

			try {
				this.response = await this.feedbackService.saveFeedback(
					userFeedbackResponse,
					workspaceId,
					processVersion,
					view.type.ident,
					view.id,
				);
				this.state = "created";
				this.clear();
			} catch (e: unknown) {
				this.state = "error";
			}
		}
	}

	isSubmitAllowed(controlName: string): boolean {
		return this.form.valid;
	}

	cancel(): void {
		this.state = "minified";
		this.clear();
	}

	retry(): void {
		this.state = "open";
	}

	clear(): void {
		this.form.reset("");
	}
}
