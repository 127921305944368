import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IQuery } from "process/tailoring/models/query.interface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "stages-process-tailoring-wizard-query-name",
	templateUrl: "./query-name.component.html",
})
export class TailoringWizardQueryNameComponent {
	query$: Observable<IQuery>;

	constructor(private route: ActivatedRoute) {
		this.query$ = this.route.data.pipe(map((data) => data.query));
	}
}
