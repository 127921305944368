import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

type JobIdentifier = stages.core.scheduler.JobIdentifier;
type Status = stages.core.scheduler.Status;
type FreezeSpecification = stages.process.freeze.FreezeSpecification;

export interface JobInfo {
	jobIdentifier: JobIdentifier;
	jobStatus: Status;
}

export type JobInfoMap = Record<string, JobInfo>;

@Injectable({ providedIn: "root" })
export class FreezeService {
	constructor(private readonly httpClient: HttpClient) {}

	async freeze(
		newValidVersionName: string,
		workspaceId: string,
		element: stages.core.TypedId,
		currentWorkspaceId: string,
		pv: string,
		dependentFreezeSpecifications: FreezeSpecification[] = [],
	): Promise<JobIdentifier[]> {
		return lastValueFrom(
			this.httpClient.post<JobIdentifier[]>(
				`app/workspace/${workspaceId}/process/elements/${element.typeIdent}/${element.id}/freeze`,
				dependentFreezeSpecifications,
				{
					params: {
						newValidVersionName: newValidVersionName,
						workspaceId: currentWorkspaceId,
						pv: pv,
					},
				},
			),
		);
	}

	async getRunningFreezeJobs(
		workspaceId: string,
		pv: string,
		process: stages.core.TypedId,
		typeToCheck: string,
		idsToCheck: string[],
		workspaceIdsToCheck: string[],
	): Promise<JobInfoMap> {
		return lastValueFrom(
			this.httpClient.get<JobInfoMap>(
				`app/workspace/${workspaceId}/process/elements/${process.typeIdent}/${process.id}/freeze/jobs`,
				{
					params: {
						pv: pv,
						elementType: typeToCheck,
						ids: idsToCheck,
						workspaceIds: workspaceIdsToCheck,
					},
				},
			),
		);
	}

	async updateValidVersionConsiderFrozen(
		workspaceId: string,
		newValidVersionName: string,
		currentWorkspaceId: string,
		pv: string,
		otherWorkspaceIds: string[],
	): Promise<JobIdentifier[]> {
		return lastValueFrom(
			this.httpClient.post<JobIdentifier[]>(
				`app/management/versions/workspace/${workspaceId}/updateVVConsiderFrozen`,
				otherWorkspaceIds,
				{
					params: {
						newValidVersionName: newValidVersionName,
						workspaceId: currentWorkspaceId,
						pv: pv,
					},
				},
			),
		);
	}
}
