import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function maxValidator(maxlength: number): ValidatorFn {
	return (control: AbstractControl): Record<string, unknown> | null => {
		return !control.value || control.value.length <= maxlength
			? null
			: {
					max: {
						valid: false,
					},
			  };
	};
}

// This directive allows further input after reaching the maximum length, in contrast
// to Angular's built-in MaxLengthValidator, that is bound to the "maxlength" HTML attribute.
@Directive({
	selector: "[stagesMaxLength]",
	providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true }],
})
export class MaxValidatorDirective implements Validator {
	@Input("stagesMaxLength") length!: number;

	validate(control: AbstractControl): ValidationErrors | null {
		return maxValidator(this.length)(control);
	}
}
