import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
	templateUrl: "./start-progress.component.html",
})
export class StartProgressComponent {
	paramMap$: Observable<ParamMap>;

	constructor(private route: ActivatedRoute, private router: Router) {
		this.paramMap$ = route.paramMap;
	}

	close(): void {
		this.router.navigate([
			"workspace",
			this.route.snapshot.paramMap.get("workspaceId"),
			this.route.snapshot.paramMap.get("processVersion"),
			"home",
		]);
	}

	summary(): void {
		this.router.navigate(
			[
				"..",
				"summary",
				{ jobName: this.route.snapshot.paramMap.get("jobName"), jobType: this.route.snapshot.paramMap.get("jobType") },
			],
			{
				relativeTo: this.route,
			},
		);
	}

	getMessageKeyPrefix(paramMap: ParamMap): string {
		if (paramMap.get("jobType") === "COPY_PROCESS") {
			return "install.copy";
		}

		if (paramMap.get("jobType") === "IMPORT_PROCESS") {
			return "install.import";
		}

		return "process.modules.add";
	}
}
