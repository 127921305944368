import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-buttons-bottom",
	templateUrl: "./buttons-bottom.component.html",
	styleUrls: ["buttons-bottom.component.scss"],
})
export class ButtonsBottomComponent {
	@Input()
	removeMargin: boolean = false;
}
