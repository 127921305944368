import { Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { DurationInput } from "common/form/input/types/duration-input";
import { InputBase } from "common/form/input/types/input-base";
import { RichTextInput } from "common/form/input/types/rich-text-input";
import { SelectionInput, SelectionOption } from "common/form/input/types/selection-input";

@Component({
	selector: "stages-input",
	templateUrl: "./input.component.html",
	styleUrls: ["input.component.scss"],
})
export class InputComponent implements OnDestroy {
	@Input() form!: FormGroup;
	@Input() input!: InputBase<unknown>;

	@ViewChild("descriptionComp")
	descriptionComp?: DescriptionEditorComponent;

	constructor(private readonly translate: TranslateService) {}

	ngOnDestroy(): void {
		if (this.descriptionComp) {
			this.descriptionComp.quit();
		}
	}

	isValid(): boolean {
		return this.formControl.valid;
	}

	get isHidden(): boolean {
		return this.input.hidden;
	}

	get formControl(): AbstractControl {
		const control = this.form.controls[this.input.key];

		if (!control) {
			throw new Error(`Could not get control '${this.input.key}' from formGroup.`);
		}

		return control;
	}

	getErrors(): string[] {
		const errors = this.formControl.errors;
		const keys: string[] = [];
		if (errors) {
			Object.keys(errors).forEach((key: string) => keys.push(key));
		}

		return keys;
	}

	getInputLength(): number {
		const value = this.formControl.value;
		return value && typeof (value === "string") ? value.length : 0;
	}

	toggleCheckbox(): void {
		const value = !this.formControl.value;
		this.input.setValue(value);
		this.formControl.setValue(value);
	}

	get fieldLabel(): string {
		return this.getLabelName(this.input.translateKey, this.input.untranslatableName, this.input.key);
	}

	getOptionLabel = (option: SelectionOption): string => {
		return this.getLabelName(option.translateKey, option.untranslatableName, option.value);
	};

	private getLabelName(translateKey: Maybe<string>, untranslatableName: Maybe<string>, fallback: string): string {
		if (translateKey) {
			return this.translate.instant(translateKey);
		}
		if (untranslatableName) {
			return untranslatableName;
		}
		return fallback;
	}

	get selectionOptions(): SelectionOption[] | null {
		return this.input instanceof DurationInput
			? this.input.selectionOptions
			: (this.input as SelectionInput).selectionOptions;
	}

	isMultiSelect(): boolean {
		return (this.input as SelectionInput).multiple;
	}

	isAlphabeticallyOrderedSelection(): boolean {
		return (this.input as SelectionInput).alphabetical;
	}

	setDisabled(formControl: FormControl, disabled: boolean): void {
		disabled ? formControl.disable() : formControl.enable();
	}

	get richtextInput(): RichTextInput {
		return this.input as RichTextInput;
	}

	nop(): void {}
}
