<stages-association-list
	*ngIf="container$ | async as container"
	[associationStore]="$any(associationStore)"
	[groups]="groups"
	[messageKeyNone]="messageKeyNone"
	[editable]="editable"
	[showEmptyGroups]="showEmptyGroups"
	[allowCreateElements]="allowCreateElements"
	[targetElementProcessIds]="targetElementProcessIds"
	[class]="classes"
	[container]="container"
	(openBrowse)="openBrowse($event)"
>
</stages-association-list>
