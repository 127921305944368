<div
	#scrollContainer
	class="scrollable-tab-container"
	(mouseenter)="activateScrollable()"
	(mouseleave)="deactivateScrollable()"
	[ngClass]="{ scrollable: scrollable, isIE: isIE, isFF: isFF, else: !isFF && !isIE && !isMobile }"
>
	<div #tabContainer class="tabs" [ngClass]="{ wrap: wrap }">
		<ng-content></ng-content>
	</div>
</div>
