<stages-card
	#card
	[toolbarId]="toolbarId"
	[toolbarItems]="toolbarItems"
	[menuId]="menuId"
	[menuItems]="menuItems"
	[menuNoCollapse]="menuNoCollapse"
>
	<h5 [translate]="tabDefs[0].translate!" *ngIf="tabDefs.length === 1"></h5>
	<header class="contains-scrollable-tabs" *ngIf="tabDefs.length !== 1">
		<stages-tabs [activeTabName]="getActiveTabName()!">
			<stages-tab-no-route
				*ngFor="let tabDef of tabDefs; trackBy: getTabId"
				(activate)="onActivate(tabDef)"
				[active]="tabDef.active"
			>
				<h5 [translate]="tabDef.translate!"></h5>
			</stages-tab-no-route>
		</stages-tabs>
	</header>

	<ng-container *ngFor="let tabDef of tabDefs; trackBy: getTabId">
		<stages-tab-content-dynamic class="panel" *ngIf="tabDef.active" [type]="tabDef"></stages-tab-content-dynamic>
	</ng-container>
	<stages-add-sequence *ngIf="sequenceOptions && card.expanded" [sequences]="sequenceOptions"></stages-add-sequence>
</stages-card>
