<ul *ngIf="totalPages() > 1" class="pagination">
	<li *ngIf="page != 1">
		<a (click)="pageClicked(prevPage())" class="jump">
			<i class="ico ico-chevron-left"></i>
		</a>
	</li>
	<li *ngFor="let p of pagesRange()" [ngClass]="{ currentPage: p === page }">
		<a (click)="pageClicked(p)" class="pageLink">{{ p }}</a>
	</li>
	<li *ngIf="totalPages() > page">
		<a (click)="pageClicked(nextPage())" class="jump">
			<i class="ico ico-chevron-right"></i>
		</a>
	</li>
</ul>
