<div class="input-container">
	<label class="input-label" for="workspace-filter" translate="search.filter.workspace.title"></label>
	<select
		id="workspace-filter"
		class="select fullsize"
		[(ngModel)]="selectedWorkspaceFilterOption"
		(change)="onFilterChange()"
		*ngIf="workspaceFilterOptions$ | async as workspaceFilterOptions"
	>
		<option
			*ngFor="let workspaceFilterOption of workspaceFilterOptions"
			[ngValue]="workspaceFilterOption"
			[translate]="workspaceFilterOption.name"
		>
			>
		</option>
	</select>
	<div class="info"></div>
</div>

<div class="input-container" *ngIf="typeFilterOptions && objectKeys(typeFilterOptions).length > 0">
	<h5 class="input-label" translate="search.filter.type.title"></h5>
	<ul class="checkbox-group">
		<li *ngFor="let displayTypeName of objectKeys(typeFilterOptions)">
			<input
				type="checkbox"
				id="{{ displayTypeName }}"
				class="checkbox"
				[(ngModel)]="typeFilterOptions[displayTypeName]"
				(change)="onFilterChange()"
			/>
			<label for="{{ displayTypeName }}"
				><span class="p2">{{ displayTypeName }}</span></label
			>
		</li>
	</ul>
	<div class="info"></div>
</div>

<div class="input-container" *ngIf="pvFilterVisible">
	<label for="versions" class="input-label" translate="search.filter.process.version.title"></label>
	<select id="versions" class="select fullsize" [(ngModel)]="selectedPVFilterOption" (change)="onFilterChange()">
		<option *ngFor="let pvFilterOption of pvFilterOptions" [ngValue]="pvFilterOption">
			{{ pvFilterOption | processVersionMarkValidVersion: validVersionName }}
		</option>
	</select>
	<div class="info"></div>
</div>
