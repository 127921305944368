import { Component, OnDestroy } from "@angular/core";
import { MainService } from "core/main.service";
import { VersionCheckService } from "navigation/version-check-update-hint/version-check.service";
import { Subscription } from "rxjs";

@Component({
	selector: "stages-version-check-update-hint",
	templateUrl: "./version-check-update-hint.component.html",
	styleUrls: ["./version-check-update-hint.component.scss"],
})
export class VersionCheckUpdateHintComponent implements OnDestroy {
	readonly subscription: Subscription;
	readonly messageKey = "application.outdated";
	visible = false;

	constructor(private versionCheckService: VersionCheckService, private mainService: MainService) {
		this.subscription = this.versionCheckService.distinctVersions$.subscribe(() => {
			this.visible = true;
			this.mainService.applicationState.navigationDrawerOpen = true;
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	reload(): void {
		location.reload();
	}
}
