import { JobInfoMap } from "process/freeze/freeze.service";
import { PhaseAdapter } from "process/freeze/overview/phase-adapter.model";

type ViewableProcess = stages.process.ViewableProcess;
type JobIdentifier = stages.core.scheduler.JobIdentifier;

export class ProcessAdapter {
	phases: PhaseAdapter[] = [];
	duration = 0;

	inProgress = false;
	hasErrors = false;
	private jobIdentifier?: JobIdentifier;

	constructor(public process: ViewableProcess) {}

	addPhase(phase: PhaseAdapter, newProcessDuration: number): void {
		this.phases.push(phase);
		if (this.duration < newProcessDuration) {
			phase.duration = newProcessDuration - this.duration;
		}

		this.duration = newProcessDuration;
	}

	getRouterLink(): unknown[] {
		return ["/", "workspace", this.process.workspaceId, this.process.pv, "process", "process", this.process.identity];
	}

	setJobExecutionInfo(jobExecutionInfoMap: JobInfoMap): void {
		this.phases.forEach((p) => p.setJobExecutionInfo(jobExecutionInfoMap));
	}

	setUpdateJobExecutionInfo(jobExecutionInfoMap: JobInfoMap): void {
		const jobExecutionInfo = jobExecutionInfoMap["workspace_" + this.process.workspaceId];
		if (!jobExecutionInfo) {
			this.inProgress = false;
			this.jobIdentifier = undefined;
		} else {
			this.inProgress = jobExecutionInfo.jobStatus === "RUNNING" || jobExecutionInfo.jobStatus === "WAITING";
			this.hasErrors = jobExecutionInfo.jobStatus === "COMPLETED_FAILED";
			this.jobIdentifier = jobExecutionInfo.jobIdentifier;
		}
	}

	isAnyOperationInProgress(): boolean {
		return this.inProgress || !!this.phases.find((p) => p.inProgress);
	}

	getIconClasses(): string[] {
		return ["ico", "ico-refresh"];
	}

	isDisabled(): boolean {
		return this.isAnyOperationInProgress() || !this.process.allowedOperations.UPDATE_CONSIDER_FROZEN;
	}

	getJobProgressRoute(): unknown[] {
		if (!this.jobIdentifier) {
			return [];
		}
		return [
			"process",
			"updateConsiderFrozen",
			this.process.workspaceId,
			{ jobType: this.jobIdentifier.group, jobName: this.jobIdentifier.name },
		];
	}

	getUpdateConsiderFrozenRoute(): unknown[] {
		return ["process", "updateConsiderFrozen", this.process.workspaceId];
	}
}
