import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
	templateUrl: "./start-summary.component.html",
})
export class StartSummaryComponent {
	paramMap$: Observable<ParamMap>;

	constructor(private route: ActivatedRoute, private router: Router) {
		this.paramMap$ = route.paramMap;
	}

	close(): void {
		this.router.navigate([
			"workspace",
			this.route.snapshot.paramMap.get("workspaceId"),
			this.route.snapshot.paramMap.get("processVersion"),
			"home",
		]);
	}

	getMessageKey(paramMap: ParamMap): string {
		if (paramMap.get("jobType") === "IMPORT_PROCESS") {
			return "install.import.summary";
		}

		return "process.modules.add.summary";
	}
}
