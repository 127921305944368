<ng-container *ngIf="params$ | async as params">
	<ng-container *ngIf="self$ | async as self">
		<div class="information" *ngIf="!isValidVersion(self)">
			<p class="information-text">
				<i class="ico ico-warning"></i><span translate="process.usergroups.edit.information"></span>
			</p>
		</div>
		<ng-template #itemTemplate let-item>
			<a
				class="label"
				[routerLink]="[
					'/',
					'workspace',
					params.get('workspaceId'),
					params.get('processVersion'),
					{ outlets: { primary: ['administration', 'usergroups', item.userGroupId, 'roles'] } }
				]"
			>
				<div class="text center">
					<h4>{{ item.name }}</h4>
					<p
						*ngIf="item.alreadyAdded"
						class="error"
						translate="process.element.usergroupassignments.alreadyAdded"
						[translateParams]="{ user: item.creator.fullname, date: item.creationDate }"
					></p>
				</div>
			</a>
			<stages-menu
				menuId="ugrp{{ item.id }}"
				[noCollapse]="true"
				class="action"
				iconClasses="ico ico-more-vertical"
				openTo="left"
				[items]="menuItems"
				[context]="item"
			>
			</stages-menu>
		</ng-template>

		<ng-template #autoCompleteTemplate let-userGroup>
			<div class="item">
				<div class="label">
					<h4>{{ userGroup.name }}</h4>
				</div>
			</div>
		</ng-template>

		<stages-responsive-data-view
			#dataView
			[dataSource]="dataSource"
			[listItemTemplate]="itemTemplate"
			[translateNone]="translateNone"
			[autoCompleteTemplate]="autoCompleteTemplate"
			[idFn]="UserGroupsComponent.getId"
			[disabledFn]="UserGroupsComponent.isDisabled"
			[showMore]="true"
			[pageSize]="pageSize"
			listStyles="list dense"
		>
		</stages-responsive-data-view>
	</ng-container>
</ng-container>
