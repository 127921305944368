import { HttpErrorResponse } from "@angular/common/http";
import { ElementRef, Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class FormService {
	populateValidationErrorsOrThrow(validationErrors: ValidationErrors, errResponse: HttpErrorResponse): void {
		if (errResponse.status === 422 && errResponse.error.validatorName) {
			validationErrors[errResponse.error.validatorName] = true;
		} else {
			throw errResponse;
		}
	}

	setServerSideErrorsOnFormControls(
		abstractControls: Record<string, AbstractControl>,
	): (response: HttpErrorResponse) => void {
		return (response) => {
			if (response.status === 422 && response.error.fieldNames) {
				const fieldNames = response.error.fieldNames;
				// TODO Multiple validation messages and possible validator names in backend
				const validatorName = response.error.validatorName;
				const validationMessage = response.error.validationMessage;

				if (Array.isArray(fieldNames)) {
					fieldNames.forEach((fieldName) => {
						const control = abstractControls[fieldName];
						if (control && control instanceof FormControl) {
							control.setErrors({ [validatorName]: validationMessage || true });
							control.markAsTouched();
						}
					});
				}
			} else {
				throw response;
			}
		};
	}

	setServerSideErrorsOnForm(formGroup: FormGroup): (response: HttpErrorResponse) => void {
		return (response) => {
			if (response.status === 422 && response.error.fieldNames) {
				const fieldNames = response.error.fieldNames;
				// TODO Multiple validation messages and possible validator names in backend
				const validatorName = response.error.validatorName;
				const validationMessage = response.error.validationMessage;

				let control: AbstractControl | null;
				if (Array.isArray(fieldNames)) {
					fieldNames.forEach((fieldName) => {
						control = formGroup.get(fieldName);
						if (control && control instanceof FormControl) {
							control.setErrors({ [validatorName]: validationMessage || true });
							control.markAsTouched();
						}
					});
				}
			} else {
				throw response;
			}
		};
	}

	markInvalidControlsAsTouched(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach((controlName) => {
			const control = formGroup.controls[controlName];
			if (!control.valid) {
				control.markAsTouched();
			}
		});
	}

	validateTextInputsDistinct(formArray: FormArray, textInputControlName: string, caseSensitive: boolean = true): void {
		if (!formArray.value || formArray.value.length === 0) {
			return;
		}

		const indexesForTextInputValue = new Map();
		formArray.value.forEach((formArrayItem: StringToString, index: number) => {
			const value = caseSensitive
				? formArrayItem[textInputControlName]
				: formArrayItem[textInputControlName].toLowerCase();
			indexesForTextInputValue.set(value, [...(indexesForTextInputValue.get(value) || []), index]);
		});

		indexesForTextInputValue.forEach((indexes) => {
			indexes.forEach((index: number) => {
				const textInputControl = formArray.controls[index].get(textInputControlName);
				if (textInputControl !== null) {
					if (indexes.length > 1) {
						textInputControl.setErrors({ distinct: true });
					} else if (textInputControl.hasError("distinct")) {
						delete textInputControl.errors!.distinct;
						textInputControl.updateValueAndValidity();
					}
				}
			});
		});
	}

	scrollToFirstInvalidElement(formElement: ElementRef): void {
		const firstInvalidElement = formElement.nativeElement.querySelector(".ng-invalid");
		firstInvalidElement.focus();
		firstInvalidElement.scrollIntoView();
		window.scrollBy(0, -120); // scroll below search bar
	}

	markFormGroupTouched(formGroup: FormGroup): void {
		Object.keys(formGroup.controls).forEach((controlName) => formGroup.controls[controlName].markAsTouched());
	}
}
