import { Injectable } from "@angular/core";
import { MutexService } from "common/concurrency/mutex.service";
import { BroadcastService } from "core/broadcast.service";
import { Events } from "core/events";
import { UserResource } from "core/stages-client";

type UserSettings = stages.user.UserSettings;
type UserSettingsMetadata = stages.user.UserSettingsMetadata;
type Upload = stages.file.Upload;

type UserSettingsPromise = Promise<DataAndInfo<UserSettings, UserSettingsMetadata>>;

@Injectable({ providedIn: "root" })
export class SettingsService {
	private cache = new Map<string, UserSettingsPromise>();

	constructor(
		private mutexService: MutexService,
		private broadcastService: BroadcastService,
		private readonly userResource: UserResource,
	) {}

	async getSettings(
		userId: string,
		currentWorkspaceId: string,
		pv: string,
	): Promise<DataAndInfo<UserSettings, UserSettingsMetadata>> {
		if (!this.cache.get(userId)) {
			this.userResource.setAdditionalHttpParamsForNextRequest({
				workspaceId: currentWorkspaceId,
				pv: pv,
			});
			this.cache.set(userId, this.userResource.getSettings(userId));
		}
		return this.cache.get(userId)!;
	}

	async putSettings(userId: string, userSettings: UserSettings, currentWorkspaceId: string, pv: string): Promise<void> {
		this.userResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: currentWorkspaceId,
			pv: pv,
		});
		return this.userResource.saveSettings(userId, userSettings).then(
			(data) => {
				this.cache.delete(userId);
				return data;
			},
			async () => {
				this.cache.delete(userId);
				return Promise.reject(undefined);
			},
		);
	}

	async changePassword(
		userId: string,
		oldPassword: string,
		newPassword: string,
		currentWorkspaceId: string,
		pv: string,
	): Promise<void> {
		this.userResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: currentWorkspaceId,
			pv: pv,
		});
		return this.mutexService.invokeWithResult("mutexChangePassword", async () =>
			this.userResource
				.changePassword(userId, {
					oldPassword: oldPassword,
					newPassword: newPassword,
				})
				.then(() => {
					this.broadcastService.broadcast(Events.MESSAGE, {
						translate: "user.password.changed",
					});
				}),
		);
	}

	async saveProfileImage(
		userId: string,
		profileImageUpload: Upload,
		cropPoints: number[],
		currentWorkspaceId: string,
		pv: string,
	): Promise<stages.core.format.User> {
		return this.mutexService.invokeWithResult("saveProfileImage", async () => {
			this.userResource.setAdditionalHttpParamsForNextRequest({
				workspaceId: currentWorkspaceId,
				pv: pv,
			});
			return this.userResource.cropProfileImage(userId, {
				profileImageUpload: profileImageUpload,
				cropPoints: cropPoints,
			});
		});
	}

	async cancelPreview(userId: string, uploadId: string, currentWorkspaceId: string, pv: string): Promise<void> {
		this.userResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: currentWorkspaceId,
			pv: pv,
		});
		return this.userResource.cancelPreview(userId, uploadId);
	}

	cleanUserSettingsCache(userId: string): void {
		this.cache.delete(userId);
	}
}
