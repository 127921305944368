<ng-container *ngIf="currentWorkspace$ | async as currentWorkspace">
	<div class="process-version-wrapper" *ngIf="currentWorkspace.viewedProcess">
		<div class="process-version-placeholder"></div>
		<div
			class="process-version"
			*ngIf="!!currentWorkspace.viewedProcess.versionIdentifier"
			(click)="toggle(currentWorkspace)"
			[ngClass]="{ 'dropdown-open': dropdownOpen, switchable: isAllowed(currentWorkspace) }"
			(stagesClickOutside)="closeDropdown()"
		>
			<div class="label">
				<stages-process-version-label
					[versionName]="currentWorkspace.viewedProcess.displayVersionName"
					[validVersion]="currentWorkspace.viewedProcess.versionIdentifier === validVersionIdentifier"
					class="active"
				>
				</stages-process-version-label>
				<i class="ico ico-chevron-down" *ngIf="isAllowed(currentWorkspace)"></i>
			</div>
			<div #scrollContainer *ngIf="dropdownOpen && isAllowed(currentWorkspace)" class="dropdown">
				<ul
					*ngIf="filteredProcessVersions"
					infiniteScroll
					[infiniteScrollDistance]="1"
					[infiniteScrollContainer]="scrollContainer"
					(scrolled)="loadMore()"
				>
					<ng-container *ngFor="let version of shownProcessVersions">
						<li *ngIf="version.unavailable; else clickableVersion">
							<stages-process-version-label
								[versionName]="version.displayVersionName"
								[validVersion]="version.validVersion"
								[ngClass]="'inactive'"
							>
							</stages-process-version-label>
						</li>
						<ng-template #clickableVersion>
							<li (click)="selectProcessVersion(version)">
								<stages-process-version-label
									[versionName]="version.displayVersionName"
									[validVersion]="version.validVersion"
									[ngClass]="{ active: isProcessVersionShown(version, currentWorkspace) }"
								>
								</stages-process-version-label>
							</li>
						</ng-template>
					</ng-container>
					<li *ngIf="filteredProcessVersions && !filteredProcessVersions.length">
						<div translate="process.version.noSelectableVersions"></div>
					</li>
				</ul>
				<div *ngIf="!filteredProcessVersions" class="progress"><div class="indeterminate"></div></div>
			</div>
		</div>
	</div>
</ng-container>
