import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { Sequence } from "process/element/sequence.interface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type ProcessView = stages.process.ProcessView;

@Component({
	selector: "stages-add-sequence",
	templateUrl: "add-sequence.component.html",
})
export class AddSequenceComponent {
	@Input() sequences?: Sequence[];

	menuItems: MenuItem[] = [];
	showMenu?: boolean;
	selfElement$: Observable<ProcessView>;

	constructor(private route: ActivatedRoute, private viewService: ViewService, private mainService: MainService) {
		this.selfElement$ = this.viewService.awaitSelfElementObservable().pipe(
			map((selfElement: ProcessView) => {
				this.setup(selfElement);
				this.showMenu = this.menuItems.length > 0;
				return selfElement;
			}),
		);
	}

	setup(selfElement: ProcessView): void {
		if (this.sequences) {
			this.sequences.forEach((option: Sequence) => {
				const parent = option.level === "same" ? selfElement.parent : selfElement;

				if (parent?.allowedOperations.CREATE_CHILD && hasCreateAssociationPermission(selfElement, option)) {
					this.menuItems.push({
						messageKey: option.msgKey,
						on: () => {
							this.mainService.openPopup(
								[
									"process",
									"sequence",
									this.route.snapshot.paramMap.get("type"),
									this.route.snapshot.paramMap.get("identity"),
									{
										id: option.id,
										level: option.level,
										targetSubtype: option.targetSubtype,
										targetType: option.targetType,
										associationType: option.associationType,
										sourceRole: option.sourceRole,
										msgKey: option.msgKey,
									},
								],
								this.route,
							);
						},
						iconClasses: this.getIconClasses(option.targetType, option.targetSubtype),
					});
				}
			});
		}
	}

	getIconClasses(targetType: string, targetSubtype: string): string[] {
		const typeObject: stages.process.ViewableType = {
			ident: targetType,
			subtypeIdent: targetSubtype,
		};

		return this.viewService.getIconClasses(typeObject, false, false);
	}
}

function hasCreateAssociationPermission(element: ProcessView, sequenceOption: Sequence): boolean {
	let targetSubtype = "";
	let sourceRole = "";

	if (sequenceOption.targetSubtype) {
		targetSubtype = "." + sequenceOption.targetSubtype;
	}

	if (sequenceOption.sourceRole) {
		sourceRole = "$" + sequenceOption.sourceRole;
	}

	return element.associations
		? element.associations[
				sequenceOption.targetType + targetSubtype + ":" + sequenceOption.associationType + sourceRole
		  ].allowedOperations.CREATE_ASSOCIATION
		: false;
}
