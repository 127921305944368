import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
	selector: "stages-dropzone",
	templateUrl: "./dropzone.component.html",
	styleUrls: ["./dropzone.component.scss"],
})
export class DropzoneComponent implements OnInit {
	@Output() readonly handleFiles = new EventEmitter<File[]>();

	@Input()
	multiple?: boolean;

	@Input()
	fileExtensions?: string;

	@Input()
	maxFileSize?: number;

	@Input()
	expandDroparea: boolean = false;

	errors: StringToBoolean = {};
	inaccessibleFile?: string;

	fileExtensionsAsList!: string[];

	@ViewChild("fileInput")
	fileInput!: ElementRef;

	ngOnInit(): void {
		if (this.fileExtensions) {
			this.fileExtensionsAsList = this.fileExtensions.split(",");

			for (let i = 0; i < this.fileExtensionsAsList.length; i++) {
				const extension = this.fileExtensionsAsList[i];
				this.fileExtensionsAsList[i] = extension.trim();
			}
		}
	}

	onDrop(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		const dataTransfer = event.dataTransfer;
		if (dataTransfer) {
			this.executeAction(dataTransfer.files);
		}
	}

	onDragOver(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
		const dataTransfer = event.dataTransfer;
		if (dataTransfer) {
			// Show "forbidden" cursor when dragging multiple files on a single-upload drop zone.
			// Does not work in IE11 and Safari.
			dataTransfer.dropEffect = dataTransfer.items && dataTransfer.items.length > 1 && !this.multiple ? "none" : "move";
		}
	}

	onDragLeave(event: DragEvent): void {
		event.preventDefault();
		event.stopPropagation();
	}

	onBrowse(_event: MouseEvent): void {
		this.fileInput.nativeElement.click();
	}

	onChange(_event: Event): void {
		this.executeAction(this.fileInput.nativeElement.files);
	}

	async executeAction(files: FileList | null): Promise<void> {
		this.errors = {};
		if (files === null) {
			return;
		}

		const filesToSave: File[] = await this.filterFiles(files);
		if (!this.hasErrors()) {
			this.handleFiles.emit(filesToSave);
		}
	}

	hasErrors(): boolean {
		return this.errors.fileExtension || this.errors.fileSize || this.errors.fileCount || this.errors.noAccess;
	}

	async filterFiles(files: FileList): Promise<File[]> {
		if (!this.multiple && files.length > 1) {
			this.errors.fileCount = true;
			return [];
		}

		const resultFilesList: File[] = [];
		for (let i = 0; i < files.length; i++) {
			const file = files.item(i)!;

			if (!this.isFileExtensionValid(file)) {
				this.errors.fileExtension = true;
				continue;
			}

			if (!this.isFileSizeValid(file)) {
				this.errors.fileSize = true;
				continue;
			}

			if (!(await DropzoneComponent.isAccessible(file))) {
				this.errors.noAccess = true;
				this.inaccessibleFile = file.name;
				continue;
			}

			resultFilesList.push(file);
		}
		return resultFilesList;
	}

	private isFileExtensionValid(file: File): boolean {
		if (this.fileExtensions && file.name.lastIndexOf(".") >= 0) {
			const localFileExtension: string = file.name.substring(file.name.lastIndexOf("."), file.name.length);
			const index = this.fileExtensionsAsList.findIndex(
				(extension) => extension.valueOf().toLowerCase() === localFileExtension.toLowerCase(),
			);

			if (index < 0) {
				return false;
			}
		}

		return true;
	}

	private isFileSizeValid(file: File): boolean {
		return !(this.maxFileSize && file.size && file.size > this.maxFileSize);
	}

	private static async isAccessible(file: File): Promise<boolean> {
		try {
			const content = await file.arrayBuffer();
			return content?.byteLength >= 0;
		} catch (err: unknown) {
			// this happens for example, when a folder is dropped
			return false;
		}
	}

	get browseMessage(): string {
		return `files.drop.zone.browse.${this.multiple ? "multiple" : "single"}.message`;
	}
}
