import { Injectable } from "@angular/core";
import { ProcessResource } from "core/stages-client";
import { from, Observable } from "rxjs";

type DisplayDescription = stages.core.format.DisplayDescription;
type NamedTypedViewable = stages.process.Named & stages.process.TypedViewable;
type ManualTailoringPreview = stages.process.tailoring.ManualTailoringPreview;
type Tailorable = stages.process.tailoring.Tailorable;

export enum TailorStatus {
	REMOVE = -1,
	RESET = 0,
	ADD = 1,
}

export interface Tailoring {
	targetStatus: TailorStatus;

	workspaceId: string;

	pv: string;

	elements: Array<NamedTypedViewable>;

	justification: DisplayDescription | null;

	metamodelIdent: string;
}

@Injectable({ providedIn: "root" })
export class TailoringService {
	constructor(private readonly processResource: ProcessResource) {}

	async commitTailoring(
		workspaceId: string,
		pv: string,
		elements: Tailorable[],
		status: TailorStatus,
		justification: DisplayDescription,
	): Promise<void> {
		const typedIds = elements.map((element) => {
			return {
				typeIdent: element.type.ident,
				id: element.id,
			};
		});
		return this.processResource.tailor$POST$workspace_workspaceId_process_tailor(
			workspaceId,
			{
				justification: justification,
				typedIds: typedIds,
			},
			{
				pv: pv,
				status: status,
			},
		);
	}

	previewTailoring(
		workspaceId: string,
		pv: string,
		typeIdent: string,
		ids: string[],
		status: TailorStatus,
	): Observable<ManualTailoringPreview> {
		const typedIds = ids.map((id) => {
			return {
				typeIdent: typeIdent,
				id: id,
			};
		});
		const r = this.processResource.preview$POST$workspace_workspaceId_process_tailor_preview(
			workspaceId,
			{
				typedIds: typedIds,
				justification: null,
			},
			{
				pv: pv,
				status: status,
			},
		);
		return from(r);
	}
}
