<article>
	<header class="page-header">
		<div class="breadcrumb-line">
			<stages-breadcrumb [links]="true" [path]="breadcrumb"></stages-breadcrumb>
			<stages-process-version></stages-process-version>
		</div>
		<stages-element-name [self]="$any(self)"></stages-element-name>
	</header>

	<ng-container stagesSlot="top"></ng-container>

	<div class="container row">
		<div class="container column col-4">
			<div class="container grouped column">
				<ng-container stagesSlot="left"></ng-container>
			</div>
		</div>
		<div class="container column col-4">
			<div class="container grouped column">
				<ng-container stagesSlot="center"></ng-container>
			</div>
		</div>
		<div class="container column col-4">
			<div class="container grouped column">
				<ng-container stagesSlot="right"></ng-container>
			</div>
		</div>
	</div>
</article>

<div id="right-navigation">
	<nav>
		<ul class="toolbar">
			<li>
				<stages-menu
					[menuId]="'menu_' + self?.id"
					[items]="menuItems"
					[context]="{ type: self?.type?.ident, id: self?.id, parentId: self?.parent?.id }"
					hasItemIcon="true"
					iconClasses="ico ico-more-vertical"
					openTo="left"
					[noCollapse]="true"
				>
				</stages-menu>
			</li>
			<li *ngFor="let perspective of perspectives" [ngClass]="{ active: isPerspectiveActive(perspective.ident) }">
				<a
					role="button"
					[attr.title]="'perspective.' + perspective.ident | translate"
					[attr.aria-label]="'perspective.' + perspective.ident | translate"
					(click)="openPerspective(perspective.ident)"
					class="clickable"
				>
					<i class="ico ico-{{ perspective.ident }}"></i>
					<i *ngIf="perspective.changeMarker" class="ico ico-exclamation-mark change-marker"></i>
				</a>
			</li>
		</ul>
	</nav>
</div>
