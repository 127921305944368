import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { VersionsService } from "files/versions/versions.service";

@Injectable({ providedIn: "root" })
export class FileWithHistoryResolver implements Resolve<stages.file.FileWithHistory> {
	constructor(private versionsService: VersionsService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.file.FileWithHistory> {
		return this.versionsService.getFileWithHistory(
			route.paramMap.get("fileId")!,
			route.paramMap.get("workspaceId")!,
			route.paramMap.get("processVersion")!,
		);
	}
}
