<ul class="list version-check-width" *ngIf="visible">
	<li class="list-item error">
		<a class="label clickable" (click)="reload()">
			<i class="ico ico-refresh"></i>
			<div class="text">
				<h3 [translate]="messageKey"></h3>
			</div>
		</a>
	</li>
</ul>
