import { AfterViewInit, Directive, DoCheck, ElementRef, NgZone, OnDestroy } from "@angular/core";
import { BrowserType, UtilService } from "common/util.service";
import { fromEvent, Subject } from "rxjs";
import { auditTime, takeUntil } from "rxjs/operators";

@Directive({
	selector: "[stagesAutoGrow]",
})
export class AutoGrowDirective implements DoCheck, AfterViewInit, OnDestroy {
	_prevValue?: string;
	private readonly _destroyed = new Subject<void>();
	constructor(private elem: ElementRef, private utilService: UtilService, private _ngZone?: NgZone) {
		this.adjustHeight();
	}

	ngDoCheck(): void {
		if (this.elem.nativeElement.value === this._prevValue) {
			return;
		}
		this.adjustHeight();
	}

	ngAfterViewInit(): void {
		this.adjustHeight();
		if (this._ngZone) {
			this._ngZone.runOutsideAngular(() => {
				fromEvent(window, "resize")
					.pipe(auditTime(16), takeUntil(this._destroyed))
					.subscribe(() => this.adjustHeight());
			});
		}
	}

	ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.complete();
	}

	adjustHeight(): void {
		const textarea: HTMLTextAreaElement = this.elem.nativeElement;
		const scrollY = this.getScrollY();
		textarea.style.height = "0px";
		const height: number = textarea.scrollHeight + 10;
		const newHeight = height < 52 ? 52 : height;
		textarea.style.height = `${newHeight}px`;
		this._prevValue = textarea.value;
		window.scroll(0, scrollY);
	}

	getScrollY(): number {
		switch (this.utilService.getBrowserType()) {
			case BrowserType.Chromium:
			case BrowserType.Edge:
				return window.scrollY;
			case BrowserType.Firefox:
			case BrowserType.Safari:
				return window.pageYOffset;
			case BrowserType.IE:
				return document.body.scrollTop;
			default:
				return 0;
		}
	}
}
