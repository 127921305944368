<ng-container *ngIf="this.dataSource.data | async as data">
	<stages-card class="myMostFrequentlyUsedElements" *ngIf="data.totalItemCount > 0">
		<h5 translate="dashboard.myMostFrequentlyUsedElements"></h5>

		<article class="my-most-frequently-used-elements">
			<stages-responsive-data-view
				#dataView
				listStyles="list dense"
				[dataSource]="dataSource"
				[listItemTemplate]="listItemTemplate"
				[idFn]="MyMostFrequentlyUsedElementsComponent.getId"
				[disabledFn]="MyMostFrequentlyUsedElementsComponent.isDisabled"
				translateNone="empty"
			>
			</stages-responsive-data-view>
		</article>

		<ng-template #listItemTemplate let-element>
			<a class="label" [routerLink]="getLink(element)">
				<i class="ico" [ngClass]="getIconClasses(element)"></i>
				<div class="text center">
					<h4>{{ element.name }}</h4>
					<p>{{ element.type.name }}</p>
				</div>
			</a>
		</ng-template>
	</stages-card>
</ng-container>
