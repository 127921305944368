import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PluginRegistry } from "common/editor/description.service";
import { EditorConfigService, StagesEditorConfig } from "common/editor/editor-config.service";
import { NonProcessEditorProperties, PluginService } from "common/editor/plugin.service";
import { RepositionToolbarService, ToolbarPosition } from "common/editor/reposition-toolbar.service";
import { AnchorProcessor } from "common/editor/shared/anchor-processor";
import { StringEscapeUtilsBase } from "common/editor/shared/string-escape-utils";
import { assertDefined } from "core/functions";

type EditorConfig = stages.context.EditorConfig;

@Component({
	selector: "stages-description-editor",
	templateUrl: "./description-editor.component.html",
	styleUrls: ["./description-editor.component.scss"],
	providers: [
		{
			//https://indepth.dev/never-again-be-confused-when-implementing-controlvalueaccessor-in-angular-forms/
			provide: NG_VALUE_ACCESSOR,
			useExisting: DescriptionEditorComponent,
			multi: true,
		},
	],
})
export class DescriptionEditorComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
	editor?: CKEDITOR.editor;

	eventScrollListener!: EventListenerOrEventListenerObject;

	panelId!: string;
	toolbarId!: string;
	toolbarPosition: ToolbarPosition = "top";

	displayDescription!: stages.core.format.DisplayDescription;

	editorConfig!: EditorConfig;

	editorOptions!: StagesEditorConfig;

	editableDescriptionHtml!: string;

	editableDescriptionHtmlOriginal!: string;

	editedImageMap!: Record<string, string>;

	@Input()
	editorId!: string;

	@Output() readonly editEnabledChange = new EventEmitter<boolean>();

	@Input()
	modifiable?: boolean;

	@Input()
	unsafe!: boolean;

	@Input()
	processTypeIdent!: string;

	@Input()
	pluginRegistry!: PluginRegistry;

	@Input()
	beanType?: string;

	@Input()
	beanId!: string;

	@Input()
	beanIdentity!: string;

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	@Input()
	properties?: NonProcessEditorProperties;

	@Input()
	focus: boolean = true;

	@ViewChild("editordiv", { static: true })
	editorElementRef!: ElementRef;

	/** IMPLEMENTS  ControlValueAccessor */
	onChange?: (value: stages.core.format.DisplayDescription) => void;

	onTouched?: () => void;

	writeValue(obj: stages.core.format.DisplayDescription): void {
		this.description = obj ?? { editedImageMap: {}, html: "", language: null, viewingMode: "EDIT" };
	}

	registerOnChange(fn: (value: stages.core.format.DisplayDescription) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		if (this.editor) {
			this.editor.setReadOnly(isDisabled);
		}
	}
	/** END OF IMPLEMENTS  ControlValueAccessor */

	onWindowScroll(): void {
		if (this.modifiable) {
			this.toolbarPosition = this.repositionToolbarService.repositionToolbar(
				this.toolbarPosition,
				this.panelId,
				this.toolbarId,
			);
		}
	}

	constructor(
		private viewContainerRef: ViewContainerRef,
		private configService: EditorConfigService,
		private repositionToolbarService: RepositionToolbarService,
		private pluginService: PluginService,
		private zone: NgZone,
	) {}

	ngOnInit(): void {
		assertDefined(this.beanId);
		assertDefined(this.beanIdentity);
		assertDefined(this.pv);
		this.beanType ??= "process";
		this.panelId = this.editorId + "-panel";
		this.toolbarId = this.editorId + "-toolbar";
		this.eventScrollListener = () => this.onWindowScroll();
	}

	ngOnDestroy(): void {
		this.quit();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.processTypeIdent || changes.workspaceId || changes.pv) {
			this.reloadEditorConfig();
		}
	}

	@Input()
	set description(displayDescription: stages.core.format.DisplayDescription) {
		this.edit(displayDescription);
	}

	edit(description: stages.core.format.DisplayDescription): void {
		this.editedImageMap = description.editedImageMap && {};
		this.displayDescription = description;
		this.editableDescriptionHtml = description.html;
		this.editableDescriptionHtmlOriginal = description.html;
		if (this.editor) {
			this.editor.setData(this.editableDescriptionHtml);
		}
	}

	async reloadEditorConfig(): Promise<void> {
		this.editorConfig = await this.configService.getEditorConfig(this.processTypeIdent, this.workspaceId);
		this.editorOptions = this.configService.getEditorOptions(
			this.editorConfig,
			"",
			"",
			this.unsafe,
			this.toolbarId,
			this.focus /* focus */,
			0,
			this.pluginRegistry,
			!this.modifiable,
		);
		const editorProperties = {};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		(editorProperties as any)["editor_" + this.editorId] = this.pluginService.Editor(
			this.viewContainerRef,
			this.zone,
			this.pluginRegistry,
			this.workspaceId,
			this.beanType,
			this.beanId,
			this.beanIdentity,
			this.pv,
			this.properties,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const pkit = (window as any).PKIT;
		pkit.ckeditor.editorProperties = { ...pkit.ckeditor.editorProperties, ...editorProperties };
		pkit.util = {};
		pkit.util.StringEscapeUtils = StringEscapeUtilsBase;
		pkit.editor = {};
		pkit.editor.AnchorProcessor = AnchorProcessor;
		pkit.tref = {};
		CKEDITOR.disableAutoInline = true;
		this.zone.runOutsideAngular(() => {
			window.addEventListener("scroll", this.eventScrollListener);
			if (CKEDITOR.instances[this.editorId]) {
				CKEDITOR.instances[this.editorId]!.destroy();
			}
			this.editor = CKEDITOR.inline(this.editorElementRef.nativeElement, this.editorOptions);
			this.editor.setData(this.editableDescriptionHtml);
		});
		this.editEnabledChange.emit(true);
		setTimeout(() => {
			if (this.modifiable) {
				this.toolbarPosition = this.repositionToolbarService.repositionToolbar(
					this.toolbarPosition,
					this.panelId,
					this.toolbarId,
				);
			}
			if (this.editor) {
				this.editor.on("change", () => {
					if (this.onChange) {
						const newData = this.editor!.getData();
						if (this.editableDescriptionHtml !== newData) {
							this.editableDescriptionHtml = newData;
							this.onChange(this.getDescription());
						}
					}
				});
				this.editor.on("blur", () => {
					if (this.onTouched) {
						this.onTouched();
					}
				});
			}
		}, 0);
	}

	quit(): void {
		if (this.eventScrollListener) {
			window.removeEventListener("scroll", this.eventScrollListener);
		}
		setTimeout(() => {
			if (this.editor) {
				this.editor.removeAllListeners();
				this.editor.destroy();
			}
			this.editor = undefined;
		});
	}

	cancel(): void {
		this.quit();
	}

	getDescription(): stages.core.format.DisplayDescription {
		const updatedDescription = {
			viewingMode: this.displayDescription.viewingMode,
			html: !this.editor ? this.editableDescriptionHtmlOriginal : this.editor.getData(),
			language: this.displayDescription.language,
			editedImageMap: this.editedImageMap,
		};
		return updatedDescription;
	}
}
