<ng-container *ngIf="fileContainer$ | async as fileContainer">
	<stages-files-files
		[layout]="layout"
		[showStartOptions]="showStartOptions"
		[translateNone]="translateNone"
		[fileSource]="(fileSource$ | async)!"
		[fileContainer]="fileContainer"
	>
	</stages-files-files>
</ng-container>
