import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";
import { UtilService } from "common/util.service";

@Component({
	selector: "stages-spin",
	templateUrl: "./spin.html",
})
export class SpinComponent implements AfterViewInit {
	@ViewChild("spin")
	private spinElement!: ElementRef;

	@Input()
	size?: string;

	needsFallBack: boolean;

	constructor(private utilService: UtilService, private renderer: Renderer2) {
		this.needsFallBack =
			this.utilService.browserIsIE() || this.utilService.browserIsEdge() || this.utilService.deviceIsIOS();
	}

	ngAfterViewInit(): void {
		if (this.needsFallBack) {
			this.getSize();
		}
	}

	getSize(): string {
		let sizeValue;
		let borderWidth;

		switch (this.size) {
			case "md":
				sizeValue = "50px";
				borderWidth = "5px";
				break;
			case "lg":
				sizeValue = "80px";
				borderWidth = "8px";
				break;
			default:
				sizeValue = "20px";
				borderWidth = "2px";
				break;
		}

		if (this.needsFallBack) {
			this.renderer.setAttribute(
				this.spinElement.nativeElement,
				"style",
				"width:" +
					sizeValue +
					"; height: " +
					sizeValue +
					"; border-radius:" +
					sizeValue +
					"; border-width:" +
					borderWidth +
					"; animation-duration: 1s",
			);
		}

		return sizeValue;
	}
}
