import { Injectable } from "@angular/core";
import { StagesCoreModule } from "core/core.module";
import { HttpService } from "core/http.service";
import { ProcessElementsResource, WorkspacesResource } from "core/stages-client";
import { UrlService } from "core/url.service";

type View = stages.process.View;
type Workspace = stages.workspace.Workspace;

@Injectable({
	providedIn: StagesCoreModule,
})
export class BrowseService {
	private url?: string;

	constructor(
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		private readonly httpService: HttpService,
		private readonly urlService: UrlService,
		private readonly workspaceResource: WorkspacesResource,
		private readonly processElementsResource: ProcessElementsResource,
	) {}

	invalidate(): void {
		if (this.url) {
			this.httpService.invalidate(this.url);
		}
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
	async getView(
		browseWorkspaceId: string,
		browsePV: string,
		folderType: string,
		folderIdentity: string,
		currentWorkspaceId: string,
		pv: string,
		lang?: java.util.Locale,
		includeDescriptionIndex = false,
		isReferenceModelBrowse = false,
	): Promise<View> {
		const newUrlSuffix = folderType === "process" ? "/children" : "/{type}/{identity}/children";
		const newUrl = this.urlService.build(
			"app/workspace/{browseWorkspaceId}/process/elements" + newUrlSuffix,
			{
				browseWorkspaceId: browseWorkspaceId,
				type: folderType,
				identity: folderIdentity,
			},
			{
				workspaceId: currentWorkspaceId,
				pv: pv,
				browsePV: browsePV,
				lang: lang,
				includeDescriptionIndex: folderType === "process" && includeDescriptionIndex,
				isReferenceModelBrowse: isReferenceModelBrowse,
			},
		);
		if (this.url !== newUrl) {
			this.invalidate();
			this.url = newUrl;
		}
		if (folderType === "process") {
			return this.processElementsResource.getBrowseView$GET$workspace_workspaceId_process_elements_children(
				browseWorkspaceId,
				{
					browsePV: browsePV,
					lang: lang,
					includeDescriptionIndex: folderType === "process" && includeDescriptionIndex,
					isReferenceModelBrowse: isReferenceModelBrowse,
				},
			) as unknown as View;
		}

		return this.processElementsResource.getBrowseView$GET$workspace_workspaceId_process_elements_type_identity_children(
			browseWorkspaceId,
			folderType,
			folderIdentity,
			{
				browsePV: browsePV,
				lang: lang,
				isReferenceModelBrowse: isReferenceModelBrowse,
			},
		) as unknown as View;
	}

	async getModules(workspaceId: string, editedProcessVersion: string): Promise<Workspace[]> {
		return this.workspaceResource.getModuleWorkspaces({
			workspaceId: workspaceId,
			pv: editedProcessVersion,
		});
	}

	async getRelatedWorkspaces(workspaceId: string, processVersion: string): Promise<Workspace[]> {
		return this.workspaceResource.getRelatedWorkspaces({ workspaceId: workspaceId, pv: processVersion });
	}

	getBrowseProcessVersion(
		relatedWorkspaces: Workspace[] | null,
		browseWorkspaceIdFromRoute: string,
		browseProcessVersionFromRoute: string,
	): string {
		if (relatedWorkspaces) {
			const filtered = relatedWorkspaces.filter((relatedWorkspace: Workspace) => {
				return relatedWorkspace.id === browseWorkspaceIdFromRoute;
			});
			if (filtered.length === 1) {
				const relatedWorkspaceProcessVersion = filtered[0].processVersionIdentifier;
				if (relatedWorkspaceProcessVersion) {
					return relatedWorkspaceProcessVersion;
				}
			}
		}
		return browseProcessVersionFromRoute;
	}
}
