<stages-dialog2 #dialog>
	<stages-dialog-header>
		<header class="title" *ngIf="credentialsValid">
			<i class="ico ico-export"></i>
			<h2 translate="files.download"></h2>
		</header>
	</stages-dialog-header>
	<ng-container *ngIf="credentialsValid">
		<div class="content">
			<div class="panel">
				<p translate="files.downloadAutomatically"></p>
				<p>
					<iframe
						*ngIf="downloadUrl"
						style="display: none"
						[src]="downloadUrl | safe: 'resourceUrl'"
						(load)="iframeLoaded($event)"
					>
					</iframe>
					<a [href]="downloadUrl" class="clickable" translate="files.downloadManually"> </a>
				</p>
				<div class="error" role="alert" *ngIf="errorMessage">{{ errorMessage }}</div>
			</div>
		</div>
		<footer>
			<div class="buttons">
				<button (click)="dialog.close()" class="button lg prime" translate="close"></button>
			</div>
		</footer>
	</ng-container>

	<stages-files-auth-embeddable
		#auth
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		[keys]="$any(keys)"
		*ngIf="!credentialsValid && !oAuthUrl"
	></stages-files-auth-embeddable>
	<stages-files-oauth-embeddable-confirm
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		[authUrl]="oAuthUrl"
		[closeCallback]="dialog.close"
		*ngIf="!credentialsValid && oAuthUrl"
	></stages-files-oauth-embeddable-confirm>
</stages-dialog2>
