import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";

@Component({
	selector: "stages-description-editor-link-item",
	templateUrl: "./link-item.component.html",
})
export class LinkItemComponent implements OnInit, OnChanges {
	itemLabel!: string;
	itemType!: string;

	@Input()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	item: any;

	@Input()
	childrenCount!: number;

	@Input()
	disabled = false;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	@Output() readonly selectItem = new EventEmitter<any>();

	ngOnInit(): void {
		this.itemLabel = this.item.label;
		this.itemType = this.item.type.name;
	}

	ngOnChanges(): void {
		this.itemLabel = this.item.label;
		this.itemType = this.item.type.name;
	}

	onSelect(): void {
		this.selectItem.emit(this.item);
	}
}
