import { Component, ElementRef, Input, OnDestroy, OnInit, TrackByFunction, ViewChild } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { ViewService } from "core/view.service";
import { QueryService } from "process/query/query.service";
import { Subscription } from "rxjs";
import * as XLSX from "xlsx";

type QueryTableJson = stages.process.QueryTableJson;
type QueryTableDataJson = stages.process.QueryTableDataJson;

@Component({
	selector: "stages-query-table",
	templateUrl: "./query-table.component.html",
	styleUrls: ["query-table.component.scss"],
})
export class QueryTableComponent implements OnInit, OnDestroy {
	@ViewChild(MatTable) materialTable!: MatTable<QueryTableDataJson[][]>;
	@ViewChild(MatTable) domTable!: ElementRef;
	@Input()
	queryIdent!: string;

	@Input()
	queryProvider!: string;

	private dropdown: StringToBoolean = {};
	private subscription?: Subscription;
	table!: QueryTableJson | null;
	error?: string | null;
	status = { resolved: false };
	tableColumns: string[] = [];
	sortedTableData!: QueryTableDataJson[][];
	currentFilter: string = "";
	currentSort?: Sort;
	isRunning = false;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly service: QueryService,
		private readonly viewService: ViewService,
	) {}

	ngOnInit(): void {
		this.subscription = this.viewService.awaitSelfElementObservable().subscribe(async (view) => {
			this.isRunning = true;
			try {
				const value = await this.service.getTable(
					this.route.snapshot.paramMap.get("identity")!,
					this.route.snapshot.paramMap.get("type")!,
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.queryProvider,
					this.queryIdent,
					this.route.snapshot.paramMap.get("processVersion")!,
				);
				if (value !== null) {
					this.table = value;
					this.status.resolved = true;
					this.table.headers.forEach((element) => {
						this.tableColumns.push(element.text);
					});
					this.sortedTableData = this.table.data;
				}
				this.isRunning = false;
				// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
			} catch (error) {
				this.error = null;
				if (this.table !== null) {
					this.error = error.error ? error.error.message : (this.error = "unknown Error");
				}
				this.status.resolved = true;
				this.isRunning = false;
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	trackByIndex: TrackByFunction<object> = (index, item) => {
		return index;
	};

	toggle(i: number): void {
		const key = String(i);
		this.dropdown[key] = !this.dropdown[key];
	}

	isOpen(i: number): boolean {
		const key = String(i);
		return this.dropdown[key];
	}

	sortData(sort: Sort): void {
		this.sortTable(sort);
		this.filterTable(this.currentFilter);
		this.materialTable.renderRows();
	}

	applyFilter(filterValue: string): void {
		this.sortTable(this.currentSort);
		this.filterTable(filterValue);
		this.materialTable.renderRows();
	}

	sortTable(sort: Sort | undefined): void {
		if (this.table !== null) {
			this.sortedTableData = sort === undefined ? this.table.data : this.sortTableDataArray(this.table.data, sort);
		}
	}

	sortTableDataArray(tableData: QueryTableDataJson[][], sort: Sort): QueryTableDataJson[][] {
		this.currentSort = sort;
		return tableData.sort(this.compareQueryTableDataJson.bind(this));
	}

	compareQueryTableDataJson(a: QueryTableDataJson[], b: QueryTableDataJson[]): number {
		if (this.currentSort === undefined) {
			return 0;
		} else {
			const currentSortOrderAscending = this.currentSort.direction === "asc";
			const sortByColumnNo = this.tableColumns.indexOf(this.currentSort.active);
			let compareResult = 0;
			if (a[sortByColumnNo] === null && b[sortByColumnNo] === null) {
				compareResult = 0;
			} else if (a[sortByColumnNo] === null) {
				compareResult = -1;
			} else if (b[sortByColumnNo] === null) {
				compareResult = 1;
			} else {
				compareResult = a[sortByColumnNo].text.localeCompare(b[sortByColumnNo].text);
			}
			if (!currentSortOrderAscending) {
				compareResult *= -1;
			}
			return compareResult;
		}
	}

	filterTable(filterValue: string | null | undefined): void {
		if (filterValue !== null && filterValue !== undefined && filterValue.length > 0) {
			const cleansedFilterValue = filterValue.trim().toLowerCase();
			this.currentFilter = cleansedFilterValue;
		} else {
			this.currentFilter = "";
		}
		const filteredData: QueryTableDataJson[][] = new Array<Array<QueryTableDataJson>>();
		this.sortedTableData.forEach((currentRow) => {
			if (this.rowContainsFilterValue(currentRow, this.currentFilter)) {
				filteredData.push(currentRow);
			}
		});
		this.sortedTableData = filteredData;
	}

	rowContainsFilterValue(row: QueryTableDataJson[], filterValue: string): boolean {
		if (filterValue.length === 0) {
			return true;
		}
		return row.find((column) => column !== null && column.text.toLowerCase().indexOf(filterValue) !== -1) !== undefined;
	}

	exportAsExcel(): void {
		const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.getExportData(this.sortedTableData));
		const workBook: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workBook, workSheet, "query");
		XLSX.writeFile(workBook, "Query.xlsx");
	}

	getExportData(tableData: QueryTableDataJson[][]): string[][] {
		if (this.table === null) {
			return [[]];
		}
		const tempArray: string[][] = [[]];
		const headerRow: string[] = [];
		this.table.headers.forEach((column) => {
			if (column !== null) {
				headerRow.push(column.text);
			} else {
				headerRow.push("");
			}
		});
		tempArray.push(headerRow);
		tableData.forEach((row) => {
			const tempRow = new Array<string>();
			row.forEach((cell) => {
				if (cell !== null) {
					tempRow.push(cell.text);
				} else {
					tempRow.push("");
				}
			});
			tempArray.push(tempRow);
		});
		return tempArray;
	}
}
