import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { UtilService } from "common/util.service";

@Directive({
	selector: "[stagesAutofocus]",
})
export class AutofocusDirective implements AfterViewInit {
	@Input()
	stagesAutofocus?: string;

	constructor(private element: ElementRef, private utilService: UtilService) {}

	ngAfterViewInit(): void {
		if (this.utilService.deviceIsIOS()) {
			this.element.nativeElement.focus();
		} else {
			// focus is called twice in order to make sure, newly added elements are being focused as well
			setTimeout(() => {
				this.element.nativeElement.focus();
				if (typeof this.element.nativeElement.selectionStart === "number") {
					this.element.nativeElement.selectionStart = this.element.nativeElement.selectionEnd =
						this.element.nativeElement.value.length;
				} else if (typeof this.element.nativeElement.createTextRange !== "undefined") {
					this.element.nativeElement.focus();
					const range = this.element.nativeElement.createTextRange();
					range.collapse(false);
					range.select();
				}
			}, 1);
		}
	}
}
