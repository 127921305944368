import { createStore, select, setProp, Store, withProps } from "@ngneat/elf";
import {
	addEntities,
	deleteEntitiesByPredicate,
	getAllEntitiesApply,
	getEntitiesCountByPredicate,
	getEntity,
	hasEntity,
	selectAllEntities,
	selectAllEntitiesApply,
	selectEntitiesCountByPredicate,
	selectManyByPredicate,
	setEntities,
	updateEntities,
	updateEntitiesByPredicate,
	withEntities,
} from "@ngneat/elf-entities";
import { Injectable } from "@angular/core";
import { Selectable } from "common/selection/state/selectable.model";
import { EMPTY, Observable, of } from "rxjs";
import { getIdIdentity, getTypeIdOfTypeIdString } from "common/selection/state/selection-store.service";

const selectionStore = createStore(
	{ name: "common-selection" },
	withEntities<Selectable, "identity">({
		idKey: "identity",
	}),
	withProps<{ isLoading: boolean }>({ isLoading: false }),
);

@Injectable({
	providedIn: "root",
})
export class SelectionRepository {
	private stores = new Map<string, Store>();

	selectLoading$ = selectionStore.pipe(select((state) => state.isLoading));

	add(selectable: Selectable): void {
		if (!selectionStore.query(hasEntity(selectable.identity))) {
			selectionStore.update(addEntities(selectable));
		}
	}

	update(key: string, selectable: Partial<Selectable>): void {
		selectionStore.update(updateEntities(key, selectable));
	}

	updateAllEntities(storeIdentifier: string, shouldBeSelected: boolean): void {
		selectionStore.update(
			updateEntitiesByPredicate(
				(el: Selectable) =>
					(el.storeIdentifier === storeIdentifier && el.isSelected !== shouldBeSelected) || el.indeterminate === true,
				(entity) => ({
					...entity,
					isSelected: shouldBeSelected && entity.isSelectable,
					wasUpdated: entity.initiallySelected !== shouldBeSelected,
					indeterminate: false,
					selectedChildrenCount: null,
				}),
			),
		);
	}

	addStore(storeName: string): void {
		const selectionEntityStore = createStore(
			{ name: storeName },
			withEntities<Selectable, "identity">({ idKey: "identity" }),
		);

		this.stores.set(storeName, selectionEntityStore);
	}

	getEntityFromDynamicStore(storeName: string, key: string): Selectable | undefined {
		const store = this.getStore(storeName);
		const typeId = getTypeIdOfTypeIdString(key);
		if (store && typeId) {
			const identity = getIdIdentity(storeName, typeId);
			// TODO use this.getEntity ...
			return store.query(getEntity(identity));
		}
		return undefined;
	}

	// TODO use identity instead of typeId + storeIdentifier ?!
	getEntity(key: string, storeIdentifier: string): Selectable | undefined {
		const foundEntities = selectionStore.query(
			getAllEntitiesApply({
				filterEntity: (e: Selectable) => e.typeId === key && e.storeIdentifier === storeIdentifier,
			}),
		);
		return foundEntities[0];
	}

	getStore(storeName: string): Store | undefined {
		if (this.hasState(storeName)) {
			return this.stores.get(storeName);
		}
		return undefined;
	}

	hasState(storeName: string): boolean {
		if (this.stores.get(storeName) !== undefined) {
			return true;
		}
		return false;
	}

	setLoading(loading: boolean): void {
		selectionStore.update(setProp("isLoading", loading));
	}

	setLoadingDynamicStore(storeName: string, loading: boolean): void {
		const store = this.getStore(storeName);
		if (store) {
			store.update(setProp("isLoading", loading));
		}
	}

	getLoadingDynamicStore(storeName: string): Observable<boolean> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(select((state) => state.isLoading));
		}
		return undefined;
	}

	updateDynamicStore(storeName: string, selectables: Selectable[]): void {
		const store = this.getStore(storeName);
		if (store) {
			store.update(setEntities(selectables));
		}
	}

	updateEntityDynamicStore(selectable: Selectable, changes: Partial<Selectable>): void {
		const store = this.getStore(selectable.storeIdentifier);
		if (store) {
			store.update(updateEntities(selectable.identity, changes));
		}
	}

	resetDynamicStore(storeName: string): void {
		const store = this.getStore(storeName);
		if (store) {
			store.reset();
		}
	}

	reset(): void {
		selectionStore.reset();
	}

	deleteDynamicStore(storeName: string): void {
		const store = this.getStore(storeName);
		if (store) {
			store.destroy();
			// remove from map
			this.stores.delete(storeName);
		}
	}

	setChildrenSelectedDynamicStore(storeName: string, target: Selectable, leavesOnly: boolean): void {
		const store = this.getStore(storeName);
		if (store) {
			if (leavesOnly) {
				store.update(
					updateEntitiesByPredicate(
						(el: Selectable) =>
							el.parentId === target.id && el.type === target.type && !this.hasChildren(storeName, el),
						{
							isSelected: true,
						},
					),
				);
			} else {
				store.update(
					updateEntitiesByPredicate((el: Selectable) => el.parentId === target.id && el.type === target.type, {
						isSelected: true,
					}),
				);
			}
		}
	}

	setChildrenDeSelectedDynamicStore(storeName: string, target: Selectable): void {
		const store = this.getStore(storeName);
		if (store) {
			store.update(
				updateEntitiesByPredicate((el: Selectable) => el.parentId === target.id && el.type === target.type, {
					isSelected: false,
				}),
			);
		}
	}

	private hasChildren(storeName: string, selectable: Selectable): boolean {
		const directChildren = this.getAllDirectChildrenDynamicStore(storeName, selectable);
		return directChildren !== undefined && directChildren.length > 0;
	}

	getAllDirectChildrenDynamicStore(storeName: string, parent: Selectable): Selectable[] | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.query(
				getAllEntitiesApply({
					filterEntity: (e: Selectable) =>
						e.parentId === parent.id && e.type.toLocaleLowerCase() === parent.type.toLocaleLowerCase(),
				}),
			);
		}
		return undefined;
	}

	getAllDirectChildrenCommonStore(parent: Selectable): Selectable[] | undefined {
		return selectionStore.query(
			getAllEntitiesApply({
				filterEntity: (e: Selectable) =>
					e.parentId === parent.id && e.parentType === parent.type && e.storeIdentifier === parent.storeIdentifier,
			}),
		);
	}

	getAllFromDynamicStore$(storeName: string): Observable<Selectable[]> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(selectAllEntities());
		}
		return undefined;
	}

	getAllSelectedFromDynamicStore$(storeName: string): Observable<Selectable[]> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(
				selectAllEntitiesApply({
					filterEntity: (e: Selectable) => e.isSelected,
				}),
			);
		}
		return undefined;
	}

	hasParent(identifier: string): boolean {
		return selectionStore.query(getEntitiesCountByPredicate((e: Selectable) => e.storeIdentifier === identifier)) > 0;
	}

	getSelectedChildrenCountFromCommonStore(parentEntity: Selectable): number | undefined {
		if (selectionStore) {
			return selectionStore.query(
				getEntitiesCountByPredicate(
					(e: Selectable) =>
						e.parentId === parentEntity.id &&
						e.parentType === parentEntity.type &&
						parentEntity.storeIdentifier === e.storeIdentifier &&
						e.isSelected,
				),
			);
		}
		return undefined;
	}

	getSelectedCountOfEntitiesByParentId(parent: Selectable): number | undefined {
		if (selectionStore) {
			return selectionStore.query(
				getEntitiesCountByPredicate(
					(e: Selectable) =>
						e.parentId === parent.id &&
						e.parentType === parent.type &&
						e.storeIdentifier === parent.storeIdentifier &&
						e.isSelected,
				),
			);
		}
		return undefined;
	}

	getDeSelectedChildrenCountFromCommonStore(parent: Selectable): number | undefined {
		if (selectionStore) {
			return selectionStore.query(
				getEntitiesCountByPredicate(
					(e: Selectable) =>
						e.parentId === parent.id &&
						e.parentType === parent.type &&
						e.storeIdentifier === parent.storeIdentifier &&
						!e.isSelected,
				),
			);
		}
		return undefined;
	}

	getDeSelectedCountByIdentifier$(identifier: string): Observable<number> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectEntitiesCountByPredicate(
					(e: Selectable) => e.storeIdentifier === identifier && (!e.isSelected || !!e.indeterminate) && e.isSelectable,
				),
			);
		}
		return of(0);
	}

	getSelectedCountByIdentifier$(identifier: string): Observable<number> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectEntitiesCountByPredicate(
					(e: Selectable) => e.storeIdentifier === identifier && e.isSelected && e.isSelectable,
				),
			);
		}
		return of(0);
	}

	getCountOfEntitiesByParent(parent: Selectable): number | undefined {
		if (selectionStore) {
			return selectionStore.query(
				getEntitiesCountByPredicate(
					(e: Selectable) => e.parentId === parent.id && e.storeIdentifier === parent.storeIdentifier,
				),
			);
		}
		return undefined;
	}
	/**
	 * @param identifier  string of the store identifier.
	 */
	getEntitiesFromCommonStoreByStore$(identifier: string, parentType?: string): Observable<Selectable[]> {
		if (selectionStore) {
			if (parentType) {
				return selectionStore.pipe(
					selectManyByPredicate((e: Selectable) => e.storeIdentifier === identifier && e.parentType === parentType),
				);
			}
			return selectionStore.pipe(selectManyByPredicate((e: Selectable) => e.storeIdentifier === identifier));
		}
		return EMPTY;
	}

	/**
	 * @param identifier  string of the store identifier.
	 */
	getSelectedEntitiesFromCommonStoreByIdentifier$(storeIdentifier: string, type?: string): Observable<Selectable[]> {
		if (selectionStore) {
			if (type) {
				return selectionStore.pipe(
					selectManyByPredicate(
						(e: Selectable) =>
							e.isSelected && !!e.storeIdentifier && e.storeIdentifier === storeIdentifier && e.type === type,
					),
				);
			}
			return selectionStore.pipe(
				selectManyByPredicate(
					(e: Selectable) => e.isSelected && !!e.storeIdentifier && e.storeIdentifier === storeIdentifier,
				),
			);
		}
		return EMPTY;
	}

	getAllSelectedFromCommonStore$(storeIdentifier: string): Observable<Selectable[]> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectManyByPredicate(
					(element: Selectable) => element.isSelected && element.storeIdentifier === storeIdentifier,
				),
			);
		}
		return EMPTY;
	}

	getAllUpdatedFromCommonStore$(storeIdentifier: string): Observable<Selectable[]> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectManyByPredicate(
					(element: Selectable) => element.wasUpdated && element.storeIdentifier === storeIdentifier,
				),
			);
		}
		return EMPTY;
	}

	getAllAddedFromCommonStore$(storeIdentifier: string): Observable<Selectable[]> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectManyByPredicate(
					(element: Selectable) =>
						element.isSelected && !element.initiallySelected && element.storeIdentifier === storeIdentifier,
				),
			);
		}
		return EMPTY;
	}

	getAllAddedCountFromCommonStore$(storeIdentifier: string): Observable<number> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectEntitiesCountByPredicate(
					(element: Selectable) =>
						element.isSelected && !element.initiallySelected && element.storeIdentifier === storeIdentifier,
				),
			);
		}
		return of(0);
	}

	getAllRemovedFromCommonStore$(storeIdentifier: string): Observable<Selectable[]> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectManyByPredicate(
					(element: Selectable) =>
						!element.isSelected && element.initiallySelected && element.storeIdentifier === storeIdentifier,
				),
			);
		}
		return EMPTY;
	}

	getAllRemovedCountFromCommonStore$(storeIdentifier: string): Observable<number> {
		if (selectionStore) {
			return selectionStore.pipe(
				selectEntitiesCountByPredicate(
					(element: Selectable) =>
						!element.isSelected && element.initiallySelected && element.storeIdentifier === storeIdentifier,
				),
			);
		}
		return of(0);
	}

	/**
	 * @param identifier  string of the store identifier.
	 */
	getSelectedEntitiesFromCommonStoreByIdentifier(storeIdentifier: string, type?: string): Selectable[] {
		if (selectionStore) {
			if (type) {
				return selectionStore.query(
					getAllEntitiesApply({
						filterEntity: (e: Selectable) =>
							e.isSelected && !!e.storeIdentifier && e.storeIdentifier === storeIdentifier && e.type === type,
					}),
				);
			}
			return selectionStore.query(
				getAllEntitiesApply({
					filterEntity: (e: Selectable) => e.isSelected && !!e.storeIdentifier && e.storeIdentifier === storeIdentifier,
				}),
			);
		}
		return [];
	}

	getAllSelectedCountFromDynamicStore$(storeName: string): Observable<number> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(selectEntitiesCountByPredicate((e: Selectable) => e.isSelected));
		}
		return undefined;
	}

	getAllAddedCountFromDynamicStore$(storeName: string): Observable<number> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(
				selectEntitiesCountByPredicate((element: Selectable) => element.isSelected && !element.initiallySelected),
			);
		}
		return undefined;
	}

	getAllAddedFromDynamicStore$(storeName: string): Observable<Selectable[]> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(
				selectAllEntitiesApply({
					filterEntity: (element: Selectable) => element.isSelected && !element.initiallySelected,
				}),
			);
		}
		return undefined;
	}

	getAllRemovedCountFromDynamicStore$(storeName: string): Observable<number> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(
				selectEntitiesCountByPredicate((element: Selectable) => !element.isSelected && element.initiallySelected),
			);
		}
		return undefined;
	}

	getAllRemovedFromDynamicStore$(storeName: string): Observable<Selectable[]> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(
				selectAllEntitiesApply({
					filterEntity: (element: Selectable) => !element.isSelected && element.initiallySelected,
				}),
			);
		}
		return undefined;
	}

	getAllUpdatedFromDynamicStore$(storeName: string): Observable<Selectable[]> | undefined {
		const store = this.getStore(storeName);
		if (store) {
			return store.pipe(
				selectAllEntitiesApply({
					filterEntity: (element: Selectable) => element.wasUpdated,
				}),
			);
		}
		return undefined;
	}

	removeFromCommon(storeIdentifier: string): void {
		selectionStore.update(
			deleteEntitiesByPredicate((el: Selectable) => !!el.storeIdentifier && el.storeIdentifier === storeIdentifier),
		);
	}
}
