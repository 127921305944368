import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ColorEvent } from "ngx-color";

export enum Orientation {
	Vertical,
	Horizontal,
}

@Component({
	selector: "stages-color-picker",
	templateUrl: "./color-picker.component.html",
	styleUrls: ["color-picker.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ColorPickerComponent,
			multi: true,
		},
	],
})
export class ColorPickerComponent implements ControlValueAccessor, OnInit {
	@Input()
	required: boolean = false;

	@Input()
	maxLengthOfInput: number = 7;

	@Input()
	colorPalette: string[] = [
		"#f44336",
		"#e91e63",
		"#9c27b0",
		"#673ab7",
		"#3f51b5",
		"#2196f3",
		"#03a9f4",
		"#00bcd4",
		"#009688",
		"#4caf50",
		"#8bc34a",
		"#cddc39",
		"#ffeb3b",
		"#ffc107",
		"#ff9800",
		"#ff5722",
		"#795548",
		"#607d8b",
	];

	@Input()
	orientation: Orientation = Orientation.Vertical;

	@ViewChild("color", { read: ElementRef, static: false })
	color?: ElementRef;

	_currentColor: string = "";
	horizontalWidth?: number;

	get currentColor(): string {
		return this._currentColor;
	}

	set currentColor(value: string) {
		this._currentColor = value;
		if (this.onChange) {
			this.onChange(this._currentColor);
		}
	}

	onChange: (value: string) => void = () => {};
	onTouched: () => void = () => {};

	ngOnInit(): void {
		// The default width of the colorpicker is adjusted to rows of 6 colors.
		// If there are less than 6 colors, we have to adjust the width ourselves
		// A color needs the place of 42 pixels
		if (this.orientation === Orientation.Horizontal && this.colorPalette.length < 6) {
			this.horizontalWidth = this.colorPalette.length * 42;
		}
	}

	// ControlValueAccessor interface
	writeValue(value: string | undefined): void {
		this.currentColor = value ? value : "";
	}

	// ControlValueAccessor interface
	registerOnChange(fn: () => void): void {
		this.onChange = fn;
	}

	// ControlValueAccessor interface
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	onColorChange(event: ColorEvent): void {
		this.currentColor = event.color.hex;
	}

	onInputChange(): void {
		const newColorValue: string = this.color!.nativeElement.value;
		this.currentColor = this.insertHashtagIfMissing(newColorValue);
	}

	isHorizontalOrientation(): boolean {
		return this.orientation === Orientation.Horizontal;
	}

	private insertHashtagIfMissing(color: string): string {
		return color.length > 0 && color.charAt(0) !== "#" ? "#" + color : color;
	}
}
