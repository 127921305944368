<div class="text">
	<h3 *ngIf="entry.labelTranslate" [translate]="entry.labelTranslate"></h3>
	<h3 *ngIf="!entry.labelTranslate">{{ entry.label }}</h3>
	<div class="info">
		<h6 *ngIf="!entry.secondLineTranslate" [ngClass]="entry.labelClasses">{{ entry.secondLine }}</h6>
		<h6 *ngIf="entry.secondLineTranslate" translate="{{ entry.secondLineTranslate }}"></h6>

		<div class="ico-size" [ngClass]="iconClass">
			<i class="ico"></i>
			<i class="ico"></i>
			<i class="ico"></i>
		</div>
	</div>
</div>
