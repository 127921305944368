<div *ngIf="!isHidden" [ngSwitch]="input.type" [ngClass]="{ required: input.required }" [formGroup]="form">
	<div *ngSwitchCase="'text'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<input
			stagesTrim
			[type]="input.inputFieldType"
			[id]="input.key"
			class="text fullsize"
			[formControlName]="input.key"
			[readonly]="input.readonly"
			(focus)="input.expand ? input.expand() : nop()"
			stagesAutofocus
			*ngIf="input.focus"
		/>
		<input
			stagesTrim
			[type]="input.inputFieldType"
			[id]="input.key"
			class="text fullsize"
			[formControlName]="input.key"
			[readonly]="input.readonly"
			(focus)="input.expand ? input.expand() : nop()"
			*ngIf="!input.focus"
		/>
		<div class="info">
			<div *ngIf="input.infoMsgKey && !formControl.errors" class="info-message" [translate]="input.infoMsgKey"></div>
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div
						*ngSwitchCase="'maxlength'"
						class="error"
						translate="input.maxLength"
						[translateParams]="{ length: input.maxLength }"
						role="alert"
					></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.maxLength" class="maxlength">{{ getInputLength() }}/{{ input.maxLength }}</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'checkbox'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<input [type]="input.inputFieldType" hidden="hidden" [formControlName]="input.key" [checked]="formControl.value" />
		<!--[readonly]="input.readonly" is not working for checkboxes-->
		<label [for]="input.key" class="switch" (click)="toggleCheckbox()"></label>
		<div class="info">
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
			<div *ngIf="input.warnMsgKey" class="warning-color">
				<p><span class="ico ico-warning ico-inline"></span> {{ input.warnMsgKey | translate }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'selection'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<select [id]="input.key" [formControlName]="input.key" class="fullsize" [multiple]="isMultiSelect()">
			<option
				*ngFor="let option of selectionOptions | orderAlphabetically: getOptionLabel:isAlphabeticallyOrderedSelection()"
				[value]="option.value"
			>
				{{ getOptionLabel(option) }}
			</option>
		</select>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error" role="alert">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
			<div *ngIf="input.infoMsgKey && !formControl.errors" class="info-message" [translate]="input.infoMsgKey"></div>
		</div>
	</div>

	<div *ngSwitchCase="'password'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<input
			[type]="input.inputFieldType"
			[id]="input.key"
			class="text fullsize"
			[formControlName]="input.key"
			[readonly]="input.readonly"
			autocomplete="new-password"
		/>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error" role="alert">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div
						*ngSwitchCase="'maxlength'"
						class="error"
						translate="input.maxLength"
						[translateParams]="{ length: input.maxLength }"
						role="alert"
					></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.maxLength" class="maxlength">{{ getInputLength() }}/{{ input.maxLength }}</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'textarea'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<textarea
			*ngIf="input.placeholderKey"
			stagesTrim
			[id]="input.key"
			class="text plaintext-input"
			[formControlName]="input.key"
			[readonly]="input.readonly"
			[placeholder]="input.placeholderKey | translate"
			stagesAutoGrow
		>
		</textarea>
		<textarea
			*ngIf="!input.placeholderKey || input.placeholderKey === undefined"
			stagesTrim
			[id]="input.key"
			class="text plaintext-input"
			[formControlName]="input.key"
			[readonly]="input.readonly"
			stagesAutoGrow
		>
		</textarea>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error" role="alert">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div
						*ngSwitchCase="'maxlength'"
						class="error"
						translate="input.maxLength"
						[translateParams]="{ length: input.maxLength }"
						role="alert"
					></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.maxLength" class="maxlength">{{ getInputLength() }}/{{ input.maxLength }}</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'json'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<textarea
			[id]="input.key"
			class="text plaintext-input"
			[formControlName]="input.key"
			[readonly]="input.readonly"
			stagesAutoGrow
		>
		</textarea>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error" role="alert">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div
						*ngSwitchCase="'maxlength'"
						class="error"
						translate="input.maxLength"
						[translateParams]="{ length: input.maxLength }"
						role="alert"
					></div>
					<div *ngSwitchCase="'json'" class="error" translate="input.notValid" role="alert"></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'number'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<input
			[type]="input.inputFieldType"
			[id]="input.key"
			class="text fullsize"
			[formControlName]="input.key"
			[readonly]="input.readonly"
		/>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error" role="alert">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div *ngSwitchCase="'integer'" class="error" translate="input.integer" role="alert"></div>
					<div *ngSwitchCase="'min'" class="error" translate="input.minNumber" role="alert"></div>
					<div *ngSwitchCase="'max'" class="error" translate="input.maxNumber" role="alert"></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'date'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<input
			[type]="input.inputFieldType"
			[id]="input.key"
			class="text fullsize"
			[formControlName]="input.key"
			[readonly]="input.readonly"
		/>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div
						*ngSwitchCase="'date'"
						class="error"
						translate="input.pattern.date"
						[translateParams]="{ format: 'yyyy-MM-dd' }"
						role="alert"
					></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'ids'">
		<label class="input-label" [for]="input.key">{{ fieldLabel }}</label>
		<input
			[type]="input.inputFieldType"
			[id]="input.key"
			class="text fullsize"
			[formControlName]="input.key"
			[readonly]="input.readonly"
		/>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div *ngSwitchCase="'ids'" class="error" translate="input.ids" role="alert"></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'rich_text'">
		<label class="input-label" for="input.key">{{ fieldLabel }}</label>
		<stages-description-editor
			#descriptionComp
			[formControlName]="input.key"
			[editorId]="input.key"
			[modifiable]="!input.readonly"
			[unsafe]="false"
			[processTypeIdent]="richtextInput.processTypeIdent!"
			[pluginRegistry]="richtextInput.pluginRegistry"
			[workspaceId]="richtextInput.workspaceId"
			[beanType]="richtextInput.beanType"
			[beanId]="richtextInput.beanId"
			[beanIdentity]="richtextInput.beanIdentity"
			[properties]="richtextInput.properties"
			[pv]="richtextInput.pv"
			[focus]="richtextInput.focus"
		>
		</stages-description-editor>

		<div class="info"></div>
	</div>

	<div *ngSwitchCase="'duration'">
		<label class="input-label" for="input.key">{{ fieldLabel }}</label>
		<div class="duration-input">
			<div>
				<input
					stagesTrim
					type="number"
					[id]="input.key"
					class="text duration-value"
					[formControlName]="input.key"
					[readonly]="!!$any(input).enabledKey && form.get($any(input).enabledKey)!.value === false"
				/>
				<select
					[id]="$any(input).unitKey"
					[formControlName]="$any(input).unitKey"
					[multiple]="false"
					class="duration-unit"
				>
					<option *ngFor="let option of selectionOptions" [value]="option.value">
						{{ getOptionLabel(option) }}
					</option>
				</select>
			</div>
			<ng-container *ngIf="!!$any(input).enabledKey">
				<input
					type="checkbox"
					hidden="hidden"
					[formControlName]="$any(input).enabledKey"
					class="duration-enabled"
					[name]="$any(input).enabledKey"
					[id]="$any(input).enabledKey"
					(change)="
						$any(input).setDisabled(formControl, form.get($any(input).unitKey)!, form.get($any(input).enabledKey)!)
					"
				/>
				<label [for]="$any(input).enabledKey" class="switch"></label>
			</ng-container>
		</div>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error" role="alert">
					<div *ngSwitchCase="'required'" class="error" translate="input.required" role="alert"></div>
					<div *ngSwitchCase="'integer'" class="error" translate="input.integer" role="alert"></div>
					<div *ngSwitchCase="'min'" class="error" translate="input.minNumber" role="alert"></div>
					<div *ngSwitchCase="'max'" class="error" translate="input.maxNumber" role="alert"></div>
					<div *ngSwitchDefault class="error" [translate]="formControl.errors[error]" role="alert"></div>
				</ng-container>
			</div>
			<div *ngIf="input.helpMessage" class="help">
				<p>{{ input.helpMessage }}</p>
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'color'">
		<label class="input-label" for="input.key">{{ fieldLabel }}</label>
		<div class="color-input">
			<stages-color-picker
				[formControlName]="input.key"
				[required]="input.required!"
				[maxLengthOfInput]="input.maxLength!"
				[colorPalette]="$any(input).colorPalette"
				[orientation]="$any(input).orientation"
			></stages-color-picker>
		</div>
		<div class="info">
			<div *ngIf="formControl.errors && (formControl.dirty || formControl.touched)">
				<ng-container *ngFor="let error of getErrors()" [ngSwitch]="error">
					<div *ngSwitchCase="'required'" class="error" [translate]="'input.required'" role="alert"></div>
					<div
						*ngSwitchCase="'maxlength'"
						class="error"
						[translate]="'input.maxLength'"
						[translateParams]="{ length: input.maxLength }"
						role="alert"
					></div>
					<div *ngSwitchCase="'pattern'" class="error" [translate]="'processrelease.states.edit.color.pattern'"></div>
				</ng-container>
			</div>
			<div *ngIf="input.infoMsgKey && !formControl.errors" class="info-message" [translate]="input.infoMsgKey"></div>
		</div>
	</div>

	<div *ngSwitchCase="'date_time'">
		<label class="input-label" for="input.key">{{ fieldLabel }}</label>
		<stages-date-time-picker
			[formControlName]="input.key"
			[placeholder]="input.placeholderKey ? (input.placeholderKey | translate) : ''"
			[setDate]="$any(input.value)"
			[initialHoursOffset]="$any(input).initialHoursOffset"
		></stages-date-time-picker>
		<div class="info"></div>
	</div>
</div>
