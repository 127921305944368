import { Store } from "common/data/store.logic";
import { DashboardService } from "dashboard/dashboard.service";

type Element = stages.dashboard.Element;

export class MyMostFrequentlyUsedElementsStore implements Store<Element> {
	constructor(private dashboardService: DashboardService) {}

	async getPage(_pageIndex: number, workspaceId: string, pv: string): Promise<PagedResult<Element>> {
		return this.dashboardService.getMyMostFrequentlyUsedElements(workspaceId, pv);
	}
}
