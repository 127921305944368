<ng-container *ngIf="self$ | async as self">
	<ng-container *ngIf="params$ | async as params">
		<article class="card" *ngIf="params.get('smode') !== 'compare' && $any(self).isNewValidVersion && visible" @inOut>
			<div class="label">
				<h3
					translate="process.notification.new.valid.version.message"
					[translateParams]="{
						previous: $any(self).previousValidVersionDisplayName,
						current: $any(self).versionDisplayName
					}"
				></h3>
				<div class="buttons">
					<button
						(click)="open($any(self).previousValidVersionIdentifier, $any(self).previousValidVersionDiffValid)"
						translate="process.notification.new.valid.version.show"
					></button>
					<button (click)="markAsSeen(self.id)" translate="process.notification.new.valid.version.seen"></button>
				</div>
			</div>
		</article>
	</ng-container>
</ng-container>
