<div class="display">
	<div *ngIf="imageRef && !!imageRef.viewUrl" class="preview">
		<img [attr.alt]="imageRef.name" [src]="imageRef && !!imageRef.viewUrl ? imageRef.viewUrl : null" />
	</div>
	<stages-upload
		*ngIf="!imageRef || !imageRef.viewUrl"
		uploadIcon="ico ico-file"
		fileExtensions=".jpg, .jpeg, .gif, .png, .svg, .webp, .bmp"
		[uploadCompletedCallback]="uploadCompletedCallback"
		[uploadUrl]="uploadUrl!"
	>
	</stages-upload>
</div>
<div class="edit" *ngIf="imageRef">
	<div>
		<stages-description-editor-imagemap
			[imageRef]="imageRef"
			(imageMapChanged)="onChange()"
		></stages-description-editor-imagemap>
		<div *ngIf="mapValidationMessageKey" class="validation-info">
			<span class="p2" [translate]="mapValidationMessageKey">&nbsp;</span>
		</div>
	</div>
</div>
