import { Injectable } from "@angular/core";
import { MainService } from "core/main.service";
import { ManagementResource } from "core/stages-client";
import { ListNavigationEntry } from "navigation/list/navigation-entry.interface";
import { NavigationMode } from "navigation/list/navigation.interface";
import { NavigationService } from "navigation/list/navigation.service";

@Injectable({ providedIn: "root" })
export class ManagementNavigationService {
	constructor(
		private navigationService: NavigationService,
		private managementResource: ManagementResource,
		private mainService: MainService,
	) {}

	updateNavigation(workspaceId: string): void {
		this.managementResource.getNavigationEntries(workspaceId).then((navigationEntries) => {
			const list = navigationEntries.map((entry) => {
				return {
					entryType: this.mainService.secondaryMode === "compare" ? "change-marker" : "single-line",
					actions: {},
					editDone: async () => Promise.resolve(),
					exact: entry.exact !== undefined && entry.exact,
					labelTranslate: entry.labelTranslate,
					route: entry.route,
					hasChildren: entry.hasChildren,
					id: entry.id,
					original: entry,
					labelClasses: "only-child",
				};
			});

			const supportedModes = new Map<string, NavigationMode>();
			supportedModes.set("view", {
				actionPermissionName: null,
				isFolderChangeAllowed: true,
				isMenuEnabled: this.mainService.secondaryMode !== "compare",
				isRearrangeEnabled: false,
				isSelectEnabled: false,
				uiActions: [],
			});

			this.navigationService.update({
				parentRoute: ["home"],
				homeRoute: ["home"],
				list: list as ListNavigationEntry[],
				selectionIdentifier: "baseNav.unused",
				supportedModes: supportedModes,
				clipboardConfiguration: {
					workspaceFilter: null,
					routeAfterWorkspaceChange: [],
					supportedModes: new Map<string, NavigationMode>(),
				},
				invalidate(): void {},
			});
		});
	}
}
