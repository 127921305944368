<ng-container *ngIf="navigationConfiguration$ | async as navigationConfiguration">
	<div
		id="clipboard"
		class="nav-drawer animate"
		[ngClass]="{ visible: isNavigationDrawerOpen() }"
		*ngIf="isClipboardOpen(navigationConfiguration)"
		[@slideInOut]
	>
		<header class="main paste" *ngIf="getCurrentActions(navigationConfiguration) as currentActions">
			<button
				[attr.title]="'close' | translate"
				[attr.aria-label]="'close' | translate"
				class="action"
				(click)="currentActions[0].on()"
			>
				<i class="ico ico-close"></i>
			</button>
			<div class="title">
				<h5 translate="clipboard"></h5>
				<span class="counter">{{ getElementCount(navigationConfiguration) }}</span>
				<h6 translate="clipboard.destination"></h6>
			</div>
			<button
				class="button sm save"
				translate="paste"
				(click)="currentActions[1].on()"
				[disabled]="inProgress || currentActions[1].disabled()"
			></button>
		</header>

		<div class="progress" *ngIf="inProgress">
			<div class="indeterminate"></div>
		</div>

		<header class="main buttons nav">
			<button
				[attr.title]="'navigation.workspace.select' | translate"
				[attr.aria-label]="'navigation.workspace.select' | translate"
				class="action workspace-icon"
				role="button"
				(click)="toggleWorkspaceNavigation()"
				*ngIf="isWorkspaceNavEnabled(navigationConfiguration)"
			>
				<i class="ico ico-workspace"></i>
			</button>
			<button
				[attr.title]="'up' | translate"
				[attr.aria-label]="'up' | translate"
				class="action"
				[routerLink]="getRouterLink($any(navigationConfiguration.parentRoute), navigationConfiguration)"
				queryParamsHandling="preserve"
				role="button"
				*ngIf="isUpEnabled(navigationConfiguration)"
			>
				<i class="ico ico-arrow-up"></i>
			</button>
		</header>

		<div class="animate defer" *ngIf="workspaceNavigationOpen">
			<stages-workspace-selection
				*ngIf="
					navigationConfiguration.clipboardConfiguration.workspaceFilter as workspaceFilter;
					else elseWithoutWorkspaceFilter
				"
				(selectWorkspace)="onSelectWorkspace($event, navigationConfiguration)"
				[filterBy]="navigationConfiguration.clipboardConfiguration.workspaceFilter.filterBy"
				[filterParam1]="navigationConfiguration.clipboardConfiguration.workspaceFilter.filterParam1"
				[filterParam2]="navigationConfiguration.clipboardConfiguration.workspaceFilter.filterParam2"
			>
			</stages-workspace-selection>
			<ng-template #elseWithoutWorkspaceFilter>
				<stages-workspace-selection (selectWorkspace)="onSelectWorkspace($event, navigationConfiguration)">
				</stages-workspace-selection>
			</ng-template>
		</div>

		<div class="main-navigation" [ngClass]="{ visible: !workspaceNavigationOpen }">
			<div class="wrapper">
				<nav>
					<div class="wrapper">
						<ul class="list navigation folder" *ngIf="navigationConfiguration.folder">
							<li
								class="list-item"
								*ngFor="let folder of $any([navigationConfiguration.folder]); trackBy: trackById"
								[class.active]="isRouterLinkActive(folder.route, folder.exact === true)"
								id="clipItem{{ folder.id }}"
							>
								<a
									class="label"
									[routerLink]="getRouterLink(folder.route, navigationConfiguration)"
									queryParamsHandling="preserve"
									[ngSwitch]="folder.entryType"
								>
									<stages-navigation-entry-single-line
										class="label"
										*ngSwitchCase="'single-line'"
										[entry]="folder"
										[mode]="folder.displayMode"
									></stages-navigation-entry-single-line>
									<stages-navigation-entry-two-line
										class="label"
										*ngSwitchCase="'two-line'"
										[entry]="folder"
										[mode]="folder.displayMode"
									></stages-navigation-entry-two-line>
									<stages-navigation-entry-two-line-split-rating
										class="label"
										*ngSwitchCase="'two-line-split-rating'"
										[entry]="folder"
										[mode]="folder.displayMode"
									></stages-navigation-entry-two-line-split-rating>
									<stages-navigation-entry-single-line
										class="label"
										*ngSwitchDefault
										[entry]="folder"
										[mode]="folder.displayMode"
									></stages-navigation-entry-single-line>
								</a>
							</li>
							<ng-container
								*ngIf="
									$any(navigationConfiguration.folder).children !== undefined &&
									hasOmmittedEntries($any(navigationConfiguration.folder).children)
								"
							>
								<div class="label ommitted-items">
									<div class="text fullsize dark">
										<h3 translate="list.items.ommitted"></h3>
									</div>
								</div>
							</ng-container>
						</ul>

						<ul class="list navigation" *ngIf="navigationConfiguration">
							<li
								class="list-item"
								*ngFor="
									let element of $any(getVisibleNavigationEntries(navigationConfiguration.list));
									trackBy: trackById
								"
								[class.active]="isRouterLinkActive(element.route, element.exact === true)"
								id="clipItem{{ element.id }}"
							>
								<a
									class="label"
									[routerLink]="getRouterLink(element.route, navigationConfiguration)"
									queryParamsHandling="preserve"
									[ngSwitch]="element.entryType"
								>
									<stages-navigation-entry-single-line
										class="fullsize"
										*ngSwitchCase="'single-line'"
										[entry]="element"
										[mode]="element.displayMode"
									></stages-navigation-entry-single-line>
									<stages-navigation-entry-two-line
										class="fullsize"
										*ngSwitchCase="'two-line'"
										[entry]="element"
										[mode]="element.displayMode"
									></stages-navigation-entry-two-line>
									<stages-navigation-entry-two-line-split-rating
										class="fullsize"
										*ngSwitchCase="'two-line-split-rating'"
										[entry]="element"
										[mode]="element.displayMode"
									></stages-navigation-entry-two-line-split-rating>
									<stages-navigation-entry-single-line
										class="fullsize"
										*ngSwitchDefault
										[entry]="element"
										[mode]="element.displayMode"
									></stages-navigation-entry-single-line>
								</a>
							</li>
							<ng-container *ngIf="hasOmmittedEntries($any(navigationConfiguration).folder.original.children)">
								<div class="label ommitted-items">
									<div class="text fullsize light">
										<h3 translate="list.items.ommitted"></h3>
									</div>
								</div>
							</ng-container>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</ng-container>
