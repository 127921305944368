import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, NgModel, ValidationErrors } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { FormService } from "common/form/form.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { FileService } from "files/files.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type FilePropertiesInfo = stages.file.FilePropertiesInfo;
type Repository = stages.file.RepositoryOption;

@Component({
	selector: "stages-files-properties",
	templateUrl: "./files-properties.component.html",
})
export class FilesPropertiesComponent implements OnInit {
	properties$!: Observable<FilePropertiesInfo>;
	dbRepository!: string;
	repositories$!: Observable<Map<string, Repository>>;
	validationErrors: ValidationErrors = {};

	maxLengthBig = 255;
	maxLengthSmall = 32;

	@ViewChild("form")
	form!: NgForm;

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	constructor(
		private route: ActivatedRoute,
		private mutexService: MutexService,
		private formService: FormService,
		private fileService: FileService,
	) {}

	ngOnInit(): void {
		this.properties$ = this.route.data.pipe(map((data) => data.properties));

		this.repositories$ = this.properties$.pipe(
			map((properties) => {
				const repositories = new Map<string, Repository>();
				properties.repositories.forEach((repository: Repository) => {
					if (repository.database) {
						this.dbRepository = repository.repositoryId!;
					}

					repositories.set(repository.repositoryId!, repository);
				});
				return repositories;
			}),
		);
	}

	isLocationManagable(properties: FilePropertiesInfo): boolean {
		return this.dbRepository !== properties.repository;
	}

	isSecurityLevelEditable(properties: FilePropertiesInfo): boolean {
		// 0: Guidance file - file security level is meaningless (WP #35259)
		// 1: Only "Open" is allowed - no need to display the radio buttons
		return properties.maximumAvailableSecurityLevel > 1;
	}

	getTranslatePattern(
		repositories: Map<string, Repository>,
		properties: FilePropertiesInfo,
		field: string,
	): string | null {
		if (!properties.repository) {
			return null;
		}

		return `${repositories.get(properties.repository)!.type}.pattern.${field}`;
	}

	private resetvalidationErrors(): void {
		if (this.form) {
			this.validationErrors = {};
		}
	}

	getLength(model: NgModel): number {
		return model.control.value ? model.control.value.length : 0;
	}

	save(properties: FilePropertiesInfo): void {
		if (this.form.valid) {
			this.mutexService.invoke("mutexEditFileProperties", async () => {
				return this.fileService
					.saveFileProperties(
						this.route.snapshot.paramMap.get("fileId")!,
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
						properties,
					)
					.then(
						() => {
							this.dialog.close();
							this.resetvalidationErrors();
						},
						(err) => {
							this.resetvalidationErrors();
							this.formService.populateValidationErrorsOrThrow(this.validationErrors, err);
						},
					);
			});
		}
	}
}
