import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "zoom" })
export class ZoomPipe implements PipeTransform {
	transform(coords: number[], xZoomFactor: number, yZoomFactor: number): number[] {
		return coords.map((coord, index) => {
			return index % 2 === 0 ? Math.round(coord * xZoomFactor) : Math.round(coord * yZoomFactor);
		});
	}
}
