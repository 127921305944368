import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { getNavigationMatrixParamsToReuse } from "navigation/list/base/base-navigation.component";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	template: "",
})
export class RedirectToIndexIdentityComponent implements OnDestroy {
	private subscription: Subscription;

	constructor(router: Router, route: ActivatedRoute) {
		this.subscription = route.data.pipe(map((data) => data.view)).subscribe((view: stages.process.View) => {
			const allParams = route.snapshot.paramMap;
			//needed for clipboard. keep parameters after workspace change
			const navigationParams = getNavigationMatrixParamsToReuse(allParams);

			router.navigate([view.processView.type.ident, view.processView.identity, navigationParams], {
				relativeTo: route.parent,
				replaceUrl: true,
			});
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
