import { Injectable } from "@angular/core";
import { AssociationTarget } from "common/associations/association-list.component";

@Injectable({ providedIn: "root" })
export class AssociationTargetLabelService {
	getLabel(targetElement: AssociationTarget): string {
		return targetElement.dependent && targetElement.parent ? targetElement.parent.label : targetElement.label;
	}

	getCombinedLabel(targetElement: AssociationTarget): string {
		return targetElement.dependent && targetElement.parent
			? targetElement.parent.label + " (" + targetElement.label + ")"
			: targetElement.label;
	}
}
