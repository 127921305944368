import { ParamMap } from "@angular/router";
import { Store } from "common/data/store.logic";
import { ProcessReleaseResource } from "core/stages-client";

type ReleaseDetailsPaged = stages.process.release.ReleaseDetailsPaged;

export class ProcessReleaseDetailsStore implements Store<ReleaseDetailsPaged> {
	constructor(
		private processReleaseResource: ProcessReleaseResource,
		private pageSize: number,
		private getModules: boolean,
	) {}

	async getPage(
		pageIndex: number,
		workspaceId: string,
		_pv: string,
		paramMap: ParamMap,
	): Promise<PagedResult<ReleaseDetailsPaged>> {
		return this.processReleaseResource.getCollectedReleaseDetailsByProcessIdPaged(
			workspaceId,
			paramMap.get("processId")!,
			{ page: pageIndex, pageSize: this.pageSize, getModules: this.getModules, excludeSystemTasks: true },
		);
	}

	// eslint-disable-next-line @typescript-eslint/promise-function-async -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	delete(): Promise<PagedResult<ReleaseDetailsPaged>> {
		throw new Error("Method not implemented.");
	}
}
