import { Injectable } from "@angular/core";
import { AuthenticationService } from "core/authentication.service";
import { EntryPageResource } from "core/stages-client";

type EntryPage = stages.entrypage.EntryPage;
type EntryPageTab = stages.entrypage.EntryPageTab;

@Injectable({ providedIn: "root" })
export class EntryPagesService {
	entryPagesMap: Map<string, { version: number; page: EntryPage }>;

	constructor(
		private readonly entryPageResource: EntryPageResource,
		readonly authenticationService: AuthenticationService,
	) {
		this.entryPagesMap = new Map<string, { version: number; page: EntryPage }>();
		authenticationService.invalidateCaches$.subscribe(() => this.invalidateCache());
	}

	async getEntryPage(tab: EntryPageTab): Promise<EntryPage> {
		const cacheEntry = this.entryPagesMap.get(tab.id);
		if (cacheEntry && cacheEntry.version === tab.version) {
			return cacheEntry.page;
		}
		const refreshedPage = await this.entryPageResource.getEntryPage(tab.id);
		this.entryPagesMap.set(tab.id, { version: tab.version, page: refreshedPage });
		return refreshedPage;
	}

	private invalidateCache(): void {
		this.entryPagesMap.clear();
	}
}
