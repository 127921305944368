import { Injectable } from "@angular/core";
import { SearchQueryService } from "core/stages-client";

type Workspace = stages.workspace.Workspace;

@Injectable({ providedIn: "root" })
export class ProcessSearchService {
	constructor(private readonly searchQueryService: SearchQueryService) {}

	async searchElementsByName(
		query: string,
		scope: string,
		categories: string[] | undefined,
		versionIdentifier: string,
		page: number,
		searchWorkspace: Workspace,
	): Promise<unknown[]> {
		const resultSize = 10;
		const startFrom = (page - 1) * resultSize;
		this.searchQueryService.setAdditionalHttpParamsForNextRequest({
			workspaceFilter: scope,
		});
		return this.searchQueryService.findElementsByName({
			query: query,
			searchWorkspaceId: searchWorkspace.id,
			typeFilter: categories !== undefined ? categories : undefined,
			startFrom: startFrom,
			size: resultSize,
			searchPv: versionIdentifier,
		});
	}
}
