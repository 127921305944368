import { Injectable } from "@angular/core";
import { PreferencesService } from "core/preferences.service";
import { ProcessResource, ProcessVersionsManagementResource } from "core/stages-client";

type ProcessVersion = stages.process.ProcessVersion;
type CompareBaselines = stages.process.CompareBaselines;
type JobIdentifier = stages.core.scheduler.JobIdentifier;

export interface ProcessVersionWithAvailability extends ProcessVersion {
	unavailable?: boolean;
}

@Injectable({ providedIn: "root" })
export class ProcessVersionService {
	constructor(
		private readonly processResource: ProcessResource,
		private readonly processVersionsManagementResource: ProcessVersionsManagementResource,
		private readonly preferencesService: PreferencesService,
	) {}

	async getProcessVersions(
		currentWorkspaceId: string,
		suppressTypeCheck: boolean,
		pv?: string,
		type?: string,
		identity?: string,
	): Promise<ProcessVersionWithAvailability[]> {
		if (!suppressTypeCheck && type && identity && pv) {
			return this.getProcessVersionsForElement(currentWorkspaceId, pv, type, identity);
		}
		return this.processResource.getProcessVersions$GET$workspace_workspaceId_process_versions(currentWorkspaceId);
	}

	async getCompareBaselines(
		currentWorkspaceId: string,
		compareWorkspaceId: string,
		pv: string,
	): Promise<CompareBaselines> {
		return this.processResource.getCompareBaselines(currentWorkspaceId, {
			compareWorkspaceId: compareWorkspaceId,
			pv: pv,
		});
	}

	async diff(
		currentWorkspaceId: string,
		pv: string,
		targetWorkspaceId: string,
		targetProcessVersion: string,
	): Promise<JobIdentifier> {
		this.processVersionsManagementResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processVersionsManagementResource.diff(currentWorkspaceId, targetWorkspaceId, targetProcessVersion, {
			pv: pv,
		});
	}

	private async getProcessVersionsForElement(
		currentWorkspaceId: string,
		pv: string,
		type: string,
		identity: string,
	): Promise<ProcessVersionWithAvailability[]> {
		return this.processResource
			.getProcessVersions$GET$workspace_workspaceId_process_versions_type_identity(currentWorkspaceId, type, identity)
			.then((processVersions) => {
				const resultList = processVersions.available;
				processVersions.unavailable.forEach((processVersion: ProcessVersionWithAvailability) => {
					processVersion.unavailable = true;
					resultList.push(processVersion);
				});
				return resultList;
			});
	}

	async getInitialProcessVersionIdentifier(): Promise<string> {
		return this.preferencesService
			.getPreference("pv", {
				pv: "_vv",
			})
			.then((storedProcessVersionIdentifierOrDefault) => {
				return storedProcessVersionIdentifierOrDefault.pv;
			});
	}
}
