<stages-responsive-data-view
	#dataView
	*ngIf="dataSource"
	[dataSource]="dataSource"
	[listItemTemplate]="listItemTemplate"
	[tableItemTemplate]="tableItemTemplate"
	[headerTemplate]="tableHeaderTemplate"
	[idFn]="ProcessInterfacesWorkspaceSelection.getId"
	[idFnNew]="ProcessInterfacesWorkspaceSelection.getIdNew"
	[disabledFn]="ProcessInterfacesWorkspaceSelection.isDisabled"
	[disabledFnNew]="ProcessInterfacesWorkspaceSelection.isDisabled"
	[isPreselectedFn]="ProcessInterfacesWorkspaceSelection.isPreselected"
	[isPartiallySelectedFn]="ProcessInterfacesWorkspaceSelection.isPartiallySelected"
	[showSelectAllSwitch]="showSelectAllSwitch"
	[useBreakpointObserver]="true"
	[mode]="mode"
	[storeIdentifier]="getStoreIdentifier()"
	translateNone="none"
>
</stages-responsive-data-view>

<ng-template #listItemTemplate let-item>
	<a
		*ngIf="useRouteNavigation"
		[routerLink]="getWorkspaceLink(item)"
		queryParamsHandling="merge"
		class="label clickable"
	>
		<i class="ico ico-workspace avatar-cell-48"></i>
		<div class="text">
			<h4>
				<span>{{ item.name }}</span>
				<ng-container *ngIf="mode === dataView.Mode.SELECT">
					<span class="counter"> {{ countSelectedItems(item) }} / {{ item.selectableChildrenCount }}</span>
				</ng-container>
				<ng-container *ngIf="mode === dataView.Mode.VIEW">
					<span class="counter"> {{ item.count }}</span>
				</ng-container>
			</h4>
			<h6>{{ item.path }}</h6>
		</div>
	</a>
	<a *ngIf="!useRouteNavigation" (click)="onWorkspaceClicked(item)" class="label clickable">
		<i class="ico ico-workspace avatar-cell-48"></i>
		<div class="text">
			<h4>
				<span>{{ item.name }}</span>
				<ng-container *ngIf="mode === dataView.Mode.SELECT">
					<span class="counter"> {{ countSelectedItems(item) }} / {{ item.selectableChildrenCount }}</span>
				</ng-container>
				<ng-container *ngIf="mode === dataView.Mode.VIEW">
					<span class="counter"> {{ item.count }}</span>
				</ng-container>
			</h4>
			<h6>{{ item.path }}</h6>
		</div>
	</a>
	<stages-menu
		*ngIf="!!menuItems"
		menuId="itemMenu"
		iconClasses="ico ico-more-vertical"
		openTo="left"
		[items]="menuItems"
		[noCollapse]="false"
		[context]="item"
		class="action"
	></stages-menu>
</ng-template>

<ng-template #tableHeaderTemplate>
	<th translate="management.process.interfaces.page.workspaces.th1"></th>
	<th translate="management.process.interfaces.page.workspaces.th2"></th>
	<th *ngIf="showReleaseWarning" translate="management.process.interfaces.page.workspaces.th3"></th>
	<th class="action" *ngIf="!!menuItems"></th>
</ng-template>

<ng-template #tableItemTemplate let-item>
	<td class="list-item" [ngClass]="isSelect() ? '' : 'no-padding'">
		<a
			*ngIf="useRouteNavigation"
			class="label clickable"
			[routerLink]="useRouteNavigation ? getWorkspaceLink(item) : null"
			queryParamsHandling="merge"
		>
			<i class="ico ico-workspace avatar-cell-48"></i>
			<div class="text">
				<h4>{{ item.name }}</h4>
				<h6>{{ item.path }}</h6>
			</div>
		</a>
		<a *ngIf="!useRouteNavigation" (click)="onWorkspaceClicked(item)" class="label clickable">
			<i class="ico ico-workspace avatar-cell-48"></i>
			<div class="text">
				<h4>{{ item.name }}</h4>
				<h6>{{ item.path }}</h6>
			</div>
		</a>
	</td>
	<td>
		<a (click)="onWorkspaceClicked(item)">
			<ng-container *ngIf="mode === dataView.Mode.SELECT">
				<span class="counter"> {{ countSelectedItems(item) }} / {{ item.selectableChildrenCount }}</span>
			</ng-container>
			<ng-container *ngIf="mode === dataView.Mode.VIEW">
				<span class="counter"> {{ item.count }}</span>
			</ng-container>
		</a>
	</td>
	<td *ngIf="showReleaseWarning" class="release-permission-status">
		<ng-container *ngTemplateOutlet="statusTemplate!; context: { $implicit: getStatus(item) }"></ng-container>
	</td>
	<td class="list-item action" *ngIf="!!menuItems">
		<stages-menu
			menuId="itemMenu"
			iconClasses="ico ico-more-vertical"
			openTo="left"
			[items]="menuItems"
			[noCollapse]="false"
			[context]="item"
			class="action"
		>
		</stages-menu>
	</td>
</ng-template>

<ng-template #statusTemplate let-status>
	<i
		class="ico"
		[ngClass]="status.iconClass"
		[attr.title]="status.messageKey | translate"
		[attr.aria-label]="status.messageKey | translate"
	></i>
</ng-template>
