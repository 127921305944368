import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Group, GroupDefinition } from "common/associations/association-group.service";
import { Groups, SourceElementAndGroup } from "common/associations/association-list.component";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import {
	EnactmentAdapter,
	EnactmentAssociationStore,
} from "process/enactment/association/enactment-association-store.logic";
import { EnactmentAssociationService } from "process/enactment/association/enactment-association.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

type ProcessView = stages.process.ProcessView;
type ViewableEnactmentAssociationGroup = stages.process.ViewableEnactmentAssociationGroup;
type ViewableModuleInstallation = stages.process.ViewableModuleInstallation;
type ViewableProcess = stages.process.ViewableProcess;
type EnactmentTargetElement = stages.common.AssociationTarget;

@Component({
	selector: "stages-enactment-widget",
	templateUrl: "./enactment-association-list.component.html",
})
export class EnactmentAssociationListComponent extends BaseComponent implements OnInit, OnDestroy {
	groupDefinitions: GroupDefinition[] = [
		{
			path: "enactmentactivator:enacts",
			translate: "management.type.plural.enactmentactivator",
			id: "enactgrp1",
		},
		{
			path: "enactmentproperty:enacts",
			translate: "management.type.plural.enactmentproperty",
			id: "enactgrp2",
		},
	];

	messageKeyNone = "none";

	editable = true;

	showEmptyGroups = false;

	allowCreateElements = true;

	processView$!: Observable<ProcessView>;
	associationStore: EnactmentAssociationStore;
	container$!: Observable<{
		sourceElement: EnactmentAdapter;
		associationGroups: Groups<EnactmentTargetElement>;
		preferencesKey: string;
	}>;

	subscription?: Subscription;
	targetElementProcessIds!: string[];
	hasInstalledOnDemandModules: boolean = false;

	constructor(
		componentService: ComponentService,
		private viewService: ViewService,
		private mainService: MainService,
		enactmentAssociationService: EnactmentAssociationService,
		private route: ActivatedRoute,
	) {
		super(componentService);

		this.associationStore = new EnactmentAssociationStore(
			this.viewService,
			enactmentAssociationService,
			this.mainService,
		);
	}

	ngOnInit(): void {
		this.container$ = this.viewService.awaitSelfElementObservable().pipe(
			//while same id
			distinctUntilChanged((p: stages.process.ProcessView, q: stages.process.ProcessView) => p.id !== q.id),
			map((p) => {
				return {
					sourceElement: new EnactmentAdapter(p),
					associationGroups: this.buildGroups(p, this.groupDefinitions),
					preferencesKey: "enactmentAssociation.list.state." + p.processType + "." + p.type.ident,
				};
			}),
		);

		this.subscription = this.container$.subscribe((view) => {
			const currentProcess: ViewableProcess = view.sourceElement.process;
			this.targetElementProcessIds = [currentProcess.id];
			const moduleInstallations: ViewableModuleInstallation[] = currentProcess.moduleInstallations;
			moduleInstallations.forEach((moduleInstallation) => {
				if (moduleInstallation.onDemand && moduleInstallation.module) {
					this.hasInstalledOnDemandModules = true;
					this.targetElementProcessIds.push(moduleInstallation.module.id);
				}
			});
		});
	}

	override ngOnDestroy(): void {
		// "super.ngOnDestroy();" is not called. This is perhaps an error, but adding it was explicitely not wanted as part of ST-33629.
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	buildGroups(self: ProcessView, groupDefinitions: GroupDefinition[]): Groups<EnactmentTargetElement> {
		const result: Groups<EnactmentTargetElement> = {};
		groupDefinitions.forEach((groupDefinition) => {
			const groupName = groupDefinition.path;
			const enactmentAssociations = self.enactmentAssociations;

			if (enactmentAssociations && enactmentAssociations[groupName]) {
				result[groupName] = this.buildGroup(groupDefinition, enactmentAssociations[groupName]);
			}
		});
		return result;
	}

	buildGroup(
		groupDefinition: GroupDefinition,
		associationGroup: ViewableEnactmentAssociationGroup,
	): Group<EnactmentTargetElement> {
		return {
			id: groupDefinition.id,
			path: groupDefinition.path,
			name: groupDefinition.name,
			translate: groupDefinition.translate,
			list: associationGroup.list,
			derived: associationGroup.derived,
			commentSupported: associationGroup.commentSupported,
			commentOnlyAssociationsSupported: false,
			allowAssociateDependentElements: associationGroup.allowAssociateDependentElements,
			targetType: associationGroup.targetType,
			targetSubtypes: associationGroup.targetSubtypes,
			sourceRole: "source",
			type: associationGroup.type,
			actions: associationGroup.allowedOperations,
			subgroups: [],
		};
	}

	openBrowse(sourceElementAndGroup: SourceElementAndGroup<EnactmentAdapter, EnactmentTargetElement>): void {
		const paramMap = this.route.snapshot.paramMap;
		const processVersion = paramMap.get("processVersion")!;
		const browseWorkspaceId = paramMap.get("workspaceId")!;
		const group = sourceElementAndGroup.associationGroup;

		this.mainService.openPopup(
			[
				"process",
				"enactmentbrowse",
				paramMap.get("type"),
				sourceElementAndGroup.sourceElement.identity,
				browseWorkspaceId,
				processVersion,
				group.targetType!.toLocaleLowerCase(),
				"index",
				{
					browseroottype: group.targetType!.toLowerCase(),
					grouppath: group.path,
					allowNavigation: this.hasInstalledOnDemandModules,
					allowIndex: false,
					dependentTypesRestrictions: group.allowAssociateDependentElements
						? group.targetDependentTypes === undefined
							? null
							: group.targetDependentTypes
						: [],
				},
				"elements",
			],
			this.route,
		);
	}
}
