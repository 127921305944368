import { Component, OnInit, TrackByFunction, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { DateTimeInput } from "common/form/input/types/date-time-input";
/* import { InputBase } from "common/form/input/types/input-base"; */
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { ScrollService } from "common/routing/scroll.service";
import { ProcessReleaseResource } from "core/stages-client";
import { ToDoListDataSource } from "dashboard/to-do-list/to-do-list-data-source.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "stages-task-details",
	templateUrl: "task-details.component.html",
	styleUrls: ["task-details.component.scss"],
})
export class TaskDetailsComponent implements OnInit {
	task$: Observable<stages.process.release.TaskDetails>;
	form!: FormGroup;
	dateTimeInput?: DateTimeInput;
	newScheduleReleaseInputKey = "newScheduleRelease";

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	constructor(
		private route: ActivatedRoute,
		private processReleaseResource: ProcessReleaseResource,
		private toDoListDataSource: ToDoListDataSource,
		private scrollService: ScrollService,
		private mutexService: MutexService,
	) {
		this.task$ = route.data.pipe(map((data) => data.task));
	}

	ngOnInit(): void {
		this.task$.subscribe((task) => {
			this.form = new FormGroup({
				comment: new FormControl(task.comment, task.commentRequired ? [Validators.required] : []),
			});

			if (task.scheduleProcessReleaseDateAllowed) {
				this.dateTimeInput = new DateTimeInput({
					key: this.newScheduleReleaseInputKey,
					translateKey: "processrelease.workflows.steps.type.RELEASE_PROCESS_VERSION.field.setReleaseDateTime",
					placeholderKey: "management.process.versions.workflows.schedulerelease",
					initialHoursOffset: 1,
					value: task.scheduledDate,
				});

				this.form.addControl(this.newScheduleReleaseInputKey, new FormControl());
			}
		});
	}

	completeTask(task: stages.process.release.TaskDetails, transitionId: string): void {
		const scheduledProcessReleaseDateControl = this.form.get(this.newScheduleReleaseInputKey);
		const scheduledProcessReleaseDate = scheduledProcessReleaseDateControl?.value
			? new Date(Date.parse(this.form.get(this.newScheduleReleaseInputKey)!.value))
			: null;
		scheduledProcessReleaseDateControl?.setValue(scheduledProcessReleaseDate ? [scheduledProcessReleaseDate] : null);

		this.mutexService.invokeWithResult("completeTask" + task.id, async () => {
			await this.processReleaseResource
				.completeTask(
					this.route.snapshot.paramMap.get("workspaceId")!,
					task.id,
					this.form.get("comment")!.value ?? "",
					{
						transitionId: transitionId,
						scheduledProcessReleaseDate: scheduledProcessReleaseDateControl?.value,
					},
				)
				.then(() => {
					this.toDoListDataSource.dataSource.reloadPage();
					this.close();
				});
		});
	}

	claimTask(task: stages.process.release.TaskDetails): void {
		this.mutexService.invokeWithResult("claimTask" + task.id, async () => {
			await this.processReleaseResource
				.claimTask(this.route.snapshot.paramMap.get("workspaceId")!, task.id)
				.then(() => {
					this.toDoListDataSource.dataSource.reloadPage();
					this.close();
				});
		});
	}

	cancel(): void {
		this.close();
	}

	getStateKey(task: stages.process.release.Task): string {
		if (task.resolution === "EXPIRED") {
			return "dashboard.todolist.task.resolution.EXPIRED";
		}

		return "dashboard.todolist.task.state." + task.state;
	}

	trackBy: TrackByFunction<stages.process.release.Task> = (_index, task) => {
		return task.id;
	};

	private close(): void {
		this.scrollService.setScrollPosition("keep");
		this.dialog.close();
	}
}
