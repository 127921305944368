<input
	[disabled]="isDateTimeDisabled"
	class="text fullsize"
	[tabindex]="tabindex"
	type="text"
	[placeholder]="placeholder"
	(focus)="onFocus($event)"
	[id]="inputId"
	#flatpickr
/>
<label *ngIf="!isMobileDevice" class="icon-label" [for]="inputId"
	><i class="ico ico-calendar" [ngClass]="{ disabled: isDateTimeDisabled }"></i
></label>
