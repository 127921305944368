<div class="support">
	<stages-version-check-update-hint></stages-version-check-update-hint>
	<ul class="list navigation" *ngIf="appContext$ | async as application">
		<li class="list-item">
			<a class="label clickable" (click)="openSettings()">
				<stages-user-image
					[userName]="application.fullname"
					[userId]="application.userId"
					[version]="application.profileImageVersion"
				></stages-user-image>
				<div class="text">
					<h3>{{ application.fullname }}</h3>
				</div>
			</a>
		</li>

		<li class="list-item">
			<a
				class="label clickable"
				href="{{ 'documentation.help.href' | translate }}"
				rel="noopener noreferrer"
				target="_blank"
			>
				<i class="ico ico-help"></i>
				<div class="text">
					<h3 translate="help"></h3>
				</div>
			</a>
		</li>

		<!--  smaller-width is set because of WP #32263 -->
		<li class="list-item smaller-width">
			<a id="logout-link" class="label clickable" (click)="logout()">
				<i id="logout-icon" class="ico ico-power-off"></i>
				<div class="text" id="logout-text-container">
					<h3 id="logout-text" translate="logout"></h3>
				</div>
			</a>
		</li>
	</ul>
</div>
