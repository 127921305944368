import { Pipe, PipeTransform } from "@angular/core";
import { Selectable } from "common/selection/state/selectable.model";

type TypedId = stages.core.TypedId;

@Pipe({
	name: "countSelectedChilds",
})
export class SelectionCounter implements PipeTransform {
	transform(
		elementTypeIdToCountSelectedChildren: TypedId,
		selectedElements: Selectable[],
		allElements: Selectable[],
	): number {
		const transitiveChildrenTypeIds: TypedId[] = [];
		let transitiveSelectChildrenCount: number = 0;

		if (!selectedElements) {
			return 0;
		}

		const elementToCountFor = allElements.find(
			(e) =>
				e.id === elementTypeIdToCountSelectedChildren.id &&
				e.type.toLocaleLowerCase() === elementTypeIdToCountSelectedChildren.typeIdent,
		);

		if (elementToCountFor) {
			const transitiveChildren: TypedId[] = getTransitiveChildren(
				elementToCountFor,
				transitiveChildrenTypeIds,
				allElements,
			);
			const transitiveSelectChildren = selectedElements.filter((el: Selectable) => {
				return transitiveChildren.map((e) => e.id + "_" + e.typeIdent.toLocaleLowerCase()).indexOf(el.typeId) > -1;
			});

			transitiveSelectChildrenCount = transitiveSelectChildren.length;
		}

		return transitiveSelectChildrenCount;
	}
}

function getTransitiveChildren(
	elementToCountFor: Selectable,
	transitiveChildrenTypeIds: TypedId[],
	allElements: Selectable[],
): TypedId[] {
	const directChildren = allElements.filter((e) => {
		return e.parentId === elementToCountFor.id && e.type.toLowerCase() === elementToCountFor.type.toLowerCase();
	});

	directChildren.forEach((child) => {
		transitiveChildrenTypeIds.push({ id: child.id, typeIdent: child.type });
		getTransitiveChildren(child, transitiveChildrenTypeIds, allElements);
	});
	return transitiveChildrenTypeIds;
}
