import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FileService } from "files/files.service";
type FileError = stages.file.FileError;

@Injectable({ providedIn: "root" })
export class ErrorResolverResolver implements Resolve<FileError> {
	constructor(private fileService: FileService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<FileError> {
		return this.fileService.getError(
			route.paramMap.get("fileId")!,
			route.paramMap.get("job")!,
			route.paramMap.get("workspaceId")!,
			route.paramMap.get("processVersion")!,
		);
	}
}
