import { HttpClient as AngularHttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Navigation, Router } from "@angular/router";
import { assertDefined, assertNever } from "core/functions";
import { Headers } from "core/http.service";
import { HttpClient as GeneratorHttpClient } from "core/stages-client";
import { lastValueFrom } from "rxjs";

export type HttpResponseType = "json" | "text";
interface RequestConfig<R> {
	method: string;
	url: string;
	queryParams?: StringToUnknown;
	data?: unknown;
	copyFn?(data: R): R;
}

const URLS_WITHOUT_HTTP_PARAMS_FROM_ROUTE_OR_CURRENT_NAVIGATION = [
	/context/,
	/workspaces\/.*/,
	/workspace.*\/process\/elements\/elementlink\/.*/,
	/files\/.*\/elementlink/,
];

@Injectable({ providedIn: "root" })
export class AngularHttpClientAdapter implements GeneratorHttpClient {
	private additionalHttpParamsForNextRequest?: StringToString;
	private headersForNextRequest?: Headers;
	private responseTypeForNextRequest?: HttpResponseType;

	constructor(
		private readonly angularHttpClient: AngularHttpClient,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router,
	) {}

	async request<R>(requestConfig: RequestConfig<R>): Promise<R> {
		try {
			return lastValueFrom(
				this.angularHttpClient.request(requestConfig.method, "app/" + requestConfig.url, {
					body: requestConfig.data,
					headers: this.headersForNextRequest as StringToString | undefined,
					params: this.createHttpParams(requestConfig),
					responseType: this.responseTypeForNextRequest,
				}),
			) as Promise<R>;
		} finally {
			this.additionalHttpParamsForNextRequest = undefined;
			this.headersForNextRequest = undefined;
			this.responseTypeForNextRequest = undefined;
		}
	}

	// This function (and the property additionalHttpParamsForNextRequest) is perhaps not be necessary, because:
	// - either all neccesary HTTP parameters are declared in the Java method of the REST resource
	// - or the HTTP parameters can always be extracted from the ActivatedRoute
	setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
		this.additionalHttpParamsForNextRequest = httpParams;
	}

	setHeadersForNextRequest(headersForNextRequest: Headers): void {
		this.headersForNextRequest = headersForNextRequest;
	}

	setResponseTypeForNextRequest(responseTypeForNextRequest: HttpResponseType): void {
		this.responseTypeForNextRequest = responseTypeForNextRequest;
	}

	private createHttpParams<R>(requestConfig: RequestConfig<R>): HttpParams {
		let httpParams: HttpParams = new HttpParams();

		if (
			URLS_WITHOUT_HTTP_PARAMS_FROM_ROUTE_OR_CURRENT_NAVIGATION.every(
				(regexp) => regexp.exec(requestConfig.url) === null,
			)
		) {
			httpParams = this.setHttpParamsFromRouteOrCurrentNavigation(httpParams);
		}

		httpParams = setHttpParams(httpParams, this.additionalHttpParamsForNextRequest);

		// HTTP parameters which potentially can have values of type Array must be "appended" at the end, otherwise they are duplicated.
		// That's why the "setHttpParams()" with the "params" parameter is called at the end.
		// If the order is changed and the following line is called at the beginning of this function, the values of the Array are duplicated
		// This happens for example, when assigning multiple Compliance Scopes, after clicking on "Management -> Compliance -> Add Reference Model".
		// This seems to be a bug in Angular's HttpParams class (https://github.com/angular/angular/issues/20430),
		// which seems to be fixed on Mar 1, 2019 (https://github.com/angular/angular/pull/29045).
		httpParams = setHttpParams(httpParams, requestConfig.queryParams);
		return httpParams;
	}

	private setHttpParamsFromRouteOrCurrentNavigation(httpParams: HttpParams): HttpParams {
		let result = this.setHttpParamFromRouteOrCurrentNavigation(httpParams, "workspaceId", 1, "workspaceId");
		result = this.setHttpParamFromRouteOrCurrentNavigation(result, "processVersion", 2, "pv");
		return result;
	}

	private setHttpParamFromRouteOrCurrentNavigation(
		httpParams: HttpParams,
		routeParamName: string,
		segmentsIndex: number,
		httpParamName: string,
	): HttpParams {
		const currentNavigation = this.router.getCurrentNavigation();
		/*
		 * If AngularHttpClientAdapter is called (directly or indirectly) during a browser reload within a Resolver getCurrentNavigation() is not null.
		 * Example: The browser is refreshed when editing a Workspace: WorkspaceSettingsResolver.resolve() calls AngularHttpClientAdapter (indirectly).
		 * In this case, retrieve "workspaceId" and "processVersion" from the current navigation. Otherwise from the activated route.
		 */
		const value =
			currentNavigation !== null && currentNavigation.finalUrl
				? getValueFromNavigation(currentNavigation, segmentsIndex)
				: getValueFromRoute(assertDefined(this.activatedRoute.snapshot.root.firstChild), routeParamName);
		return setHttpParam(httpParams, httpParamName, value);
	}
}

function getValueFromNavigation(currentNavigation: Navigation, segmentsIndex: number): string {
	const segments = currentNavigation.finalUrl!.root.children.primary.segments;
	if (segments[0].path !== "workspace") {
		throw new Error("The current navigation did not contain the necessary HttpParams");
	}
	return segments[segmentsIndex].path;
}

function getValueFromRoute(firstChild: ActivatedRouteSnapshot, routeParamName: string): string {
	const value = firstChild.paramMap.get(routeParamName);
	if (value === null) {
		throw new Error(
			`The parameter "${routeParamName}" was not found in the activated route "${firstChild}". Consider adding an entry to 'URLS_WITHOUT_HTTP_PARAMS_FROM_ROUTE_OR_CURRENT_NAVIGATION' for this REST URL!`,
		);
	}
	return value;
}

function setHttpParams(httpParams: HttpParams, params?: StringToUnknown): HttpParams {
	let result = httpParams;
	if (params) {
		Object.keys(params).forEach((param) => {
			const value = params[param];
			if (value !== undefined && value !== null) {
				result = setHttpParam(result, param, value);
			}
		});
	}
	return result;
}

function setHttpParam(httpParams: HttpParams, name: string, value: unknown): HttpParams {
	// TODO: ST-25234: re-enable assertion that oldvalue===value, if oldValue!==null
	// const oldValue = httpParams.get(name);
	// if (oldValue === null) {
	if (value instanceof Array) {
		let result = httpParams;
		for (const v of value) {
			result = result.append(name, v);
		}
		return result;
	}
	const type = typeof value;
	switch (type) {
		case "string":
		case "number":
		case "bigint":
		case "boolean":
			return httpParams.set(name, String(value));
		case "function":
		case "object": //e.g. 'typeof null === "object"' and 'typeof [1, 2, 3] === "object"'
		case "undefined":
		case "symbol":
			throw new Error(`Unhandled type "${type}" for value "${value}`);
		default:
			assertNever(type);
	}
	// }

	// if (oldValue !== value) {
	/*
	 * If this error occurs, please
	 * - Set a breakpoint in the following "throw" statement
	 * - Repeat the same steps as before and try to reproduce the error
	 * - When stopping at the breakpoint, go up the stacktrace until the call to the generated class in "stages-client.ts"
	 * - If there is preceeding call to "setAdditionalHttpParamsForNextRequest" and it is not meaningful, delete it
	 * - Otherwise contact wsl/twn
	 * For further information, see work package ST-25234.
	 */
	// throw new Error(`The Http parameter ${name} was already set to ${oldValue} and was tried to set to a the different value ${value}`);
	// }
}
