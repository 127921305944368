import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, NgModel, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "common/dialog/dialog.service";
import { FormService } from "common/form/form.service";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { assertDefined } from "core/functions";
import { LanguageService } from "core/translate/language.service";
import { UrlService } from "core/url.service";
import { NavigationService } from "navigation/list/navigation.service";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SettingsService } from "user/settings/settings.service";

type ApplicationContext = stages.context.ApplicationContext;
type Upload = stages.file.Upload;
type UserSettings = stages.user.UserSettings;
type UserSettingsMetadata = stages.user.UserSettingsMetadata;

interface Data {
	uploadUrl: string;
	userSettings: DataAndInfo<UserSettings, UserSettingsMetadata>;
	applicationContext: ApplicationContext;
	availableLanguages: string[];
	passwordForm: FormGroup;
}

@Component({
	selector: "stages-user-settings",
	templateUrl: "./user-settings.component.html",
	styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
	showPreview = false;
	profileImageUpload?: Upload;
	previewImageUrl!: string;
	readonly uploadUrl!: string;
	data$!: Observable<Data>;
	uploadErrorMessage?: string;
	categoryType = { security: "security", profile: "profile", display: "display" };

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private readonly urlService: UrlService,
		private readonly settingsService: SettingsService,
		private readonly languageService: LanguageService,
		private readonly dialogService: DialogService,
		private readonly formService: FormService,
		private readonly titleService: Title,
		private readonly translateService: TranslateService,
		private readonly navigationService: NavigationService,
	) {}

	ngOnInit(): void {
		this.data$ = combineLatest([this.route.data, this.route.paramMap]).pipe(
			map(([data, paramMap]) => {
				return {
					userSettings: data.userSettings,
					applicationContext: data.applicationContext,
					availableLanguages: ["WORKSPACE", "BROWSER"].concat(data.applicationContext),
					passwordForm: new FormGroup({
						oldPassword: new FormControl("", [Validators.required]),
						newPassword: new FormControl("", [
							Validators.required,
							Validators.minLength(data.userSettings.metadata.passwordMinLength),
							Validators.pattern(/^\s*\S.*$/),
						]),
						newPasswordRepeat: new FormControl("", [Validators.required]),
					}),

					uploadUrl: this.urlService.build(
						"app/users/{id}/settings/previewProfileImage",
						{
							id: data.applicationContext.userId,
						},
						{
							workspaceId: paramMap.get("workspaceId"),
							pv: paramMap.get("processVersion"),
						},
					),
				};
			}),
		);
		this.titleService.setTitle(this.translateService.instant("user.settings"));
	}

	private getOldPasswordControl(data: Data): AbstractControl {
		return assertDefined(data.passwordForm.get("oldPassword"), "old password control expected to exist");
	}

	private getNewPasswordControl(data: Data): AbstractControl {
		return assertDefined(data.passwordForm.get("newPassword"), "new password control expected to exist");
	}

	private getNewPasswordRepeatControl(data: Data): AbstractControl {
		return assertDefined(data.passwordForm.get("newPasswordRepeat"), "repeat new password control expected to exist");
	}

	close(): void {
		this.router.navigate([{ outlets: { dialog: null } }], {
			relativeTo: this.route.parent!.parent!.parent,
		});
	}

	async onChange(data: Data, model?: NgModel): Promise<void> {
		if (model && !model.valid) {
			this.settingsService.cleanUserSettingsCache(data.applicationContext.userId);
			return;
		}

		return this.settingsService.putSettings(
			data.applicationContext.userId,
			data.userSettings.data,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
	}

	async onChangeTailoring(data: Data): Promise<void> {
		await this.onChange(data);
		this.navigationService.refresh();
	}

	async onChangeLanguage(data: Data): Promise<void> {
		await this.settingsService.putSettings(
			data.applicationContext.userId,
			data.userSettings.data,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
		await this.languageService.determineAndApplyLanguage(
			this.route.snapshot.paramMap.get("processVersion")!,
			data.userSettings.data.application!.language,
		);
		this.navigationService.refresh();
	}

	openWorkspaceSelection(data: Data): void {
		this.dialogService.selectWorkspace(
			(workspace: WorkspaceSelected) => {
				data.userSettings.data.application!.homeWorkspace.id = workspace.id;
				data.userSettings.data.application!.homeWorkspace.name = workspace.name;
				data.userSettings.data.application!.homeWorkspace.pathSegments = workspace.pathSegments;

				this.onChange(data);
			},
			() => {
				// Nothing to do
			},
		);
	}

	validatePasswordsMatching(data: Data): void {
		const newPasswordControl = this.getNewPasswordControl(data);
		const newPasswordRepeatControl = this.getNewPasswordRepeatControl(data);
		if (newPasswordControl.value !== newPasswordRepeatControl.value) {
			newPasswordRepeatControl.setErrors({ passwordMatch: true });
		} else {
			newPasswordRepeatControl.updateValueAndValidity();
		}
	}

	onChangePassword(data: Data): void {
		if (data.passwordForm.valid) {
			this.settingsService
				.changePassword(
					data.applicationContext.userId,
					this.getOldPasswordControl(data).value,
					this.getNewPasswordControl(data).value,
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
				)
				.then(() => {
					// TODO: Display toast message
					data.passwordForm.reset();
				}, this.formService.setServerSideErrorsOnFormControls(data.passwordForm.controls));
		} else {
			this.formService.markInvalidControlsAsTouched(data.passwordForm);
		}
	}

	hasErrorAfterEditing(data: Data, controlName: string, error: string): boolean {
		const control = assertDefined(data.passwordForm.get(controlName));
		return control.errors && control.errors[error] && (control.dirty || control.touched);
	}

	uploadCompletedCallback = (upload: Upload, status: number): void => {
		if (status === 200) {
			this.showPreview = true;
			this.profileImageUpload = upload;
			this.previewImageUrl = this.urlService.build("app/users/{userId}/settings/previewProfileImage/{uploadId}", {
				userId: this.route.snapshot.data.applicationContext.userId,
				uploadId: upload.uploadId,
			});
			this.uploadErrorMessage = undefined;
		}
	};

	handleUploadError($event: Error): void {
		this.uploadErrorMessage = $event.message;
	}

	cancelProfileImageUpload(data: Data): void {
		const upload = assertDefined(this.profileImageUpload, "Profile image was uploaded");
		void this.settingsService.cancelPreview(
			data.applicationContext.userId,
			upload.uploadId,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
		this.showPreview = false;
		this.profileImageUpload = undefined;
	}

	async saveProfileImage(cropPoints: number[], data: Data): Promise<void> {
		const upload = assertDefined(this.profileImageUpload, "Profile image was uploaded");
		const imageData = await this.settingsService.saveProfileImage(
			data.applicationContext.userId,
			upload,
			cropPoints,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
		data.applicationContext.profileImageVersion = imageData.profileImageVersion;
		this.showPreview = false;
		this.profileImageUpload = undefined;
	}
}
