import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { assertDefined } from "core/functions";
import { FilesResource } from "core/stages-client";
import { FilesAuthEmbeddableComponent } from "files/auth/files-auth-embeddable.component";
import { FilesOperation } from "files/files-operation";
import { Subscription } from "rxjs";

@Component({
	templateUrl: "./download-after-authentication.component.html",
})
export class DownloadAfterAuthenticationComponent implements OnInit, OnDestroy {
	private readonly subscription = new Subscription();

	private readonly fileId: string;
	private readonly workspaceId: string;
	private readonly revisionIdentifier: string | null;

	keys: unknown;
	downloadUrl?: string;
	errorMessage?: string;
	credentialsValid = true;
	oAuthUrl?: string;
	cmsTypeMessageKey?: string;

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	@ViewChild("auth")
	authComponent!: FilesAuthEmbeddableComponent;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly filesResource: FilesResource,
		private readonly changeDetector: ChangeDetectorRef,
	) {
		this.fileId = assertDefined(this.route.snapshot.queryParamMap.get("fileId"));
		this.revisionIdentifier = this.route.snapshot.queryParamMap.get("revisionIdentifier");
		this.workspaceId = assertDefined(this.route.snapshot.paramMap.get("workspaceId"));
		this.keys = {
			fileId: this.fileId,
			elementType: this.route.snapshot.paramMap.get("elementType"),
			elementId: this.route.snapshot.paramMap.get("elementId")!,
			cmsTypeMessageKey: this.cmsTypeMessageKey,
			workspaceId: this.workspaceId,
			processVersion: this.route.snapshot.paramMap.get("processVersion"),
		};
	}

	ngOnInit(): void {
		this.download();
	}

	private async download(): Promise<void> {
		try {
			await this.filesResource.authorizeDownload(this.fileId, { redirectUrl: window.location.href });
			this.downloadUrl = !this.revisionIdentifier
				? `app/files/${this.fileId}?workspaceId=${this.workspaceId}`
				: `app/files/${this.fileId}/revision/${this.revisionIdentifier}?workspaceId=${this.workspaceId}`;
		} catch (e: unknown) {
			if (e instanceof HttpErrorResponse && e.status === 901) {
				this.cmsTypeMessageKey = e.error.cmsTypeMessageKey;
				this.credentialsValid = false;
				if (!!e.error.authInfo && e.error.authInfo.authType === "EXTERNAL_LINK") {
					this.oAuthUrl = e.error.authInfo.url;
				}
				this.changeDetector.detectChanges();
				if (this.oAuthUrl) {
					localStorage.setItem("cmFileId", this.fileId);
					localStorage.setItem("cmWorkspaceId", this.workspaceId);
					localStorage.setItem("cmOpName", FilesOperation.DO_DOWNLOAD_AND_ASSURE_AUTH.toString());
					if (this.revisionIdentifier) {
						localStorage.setItem("cmVersionIdentifier", this.revisionIdentifier);
					}
					localStorage.setItem("cmSingleFile", this.route.snapshot.paramMap.get("singleFile")!);
					localStorage.setItem("cmCurrentFileCount", this.route.snapshot.paramMap.get("currentFileCount")!);
					localStorage.setItem("cmElementId", this.route.snapshot.paramMap.get("elementId")!);
					localStorage.setItem("cmElementType", this.route.snapshot.paramMap.get("elementType")!);
				} else {
					this.subscription.add(
						this.authComponent.resultSubject.subscribe((result) => {
							if (result === "cancel") {
								this.dialog.close();
							} else {
								this.credentialsValid = true;
								this.download();
							}
						}),
					);
				}
			}
			throw e;
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	iframeLoaded(event: Event): void {
		try {
			const iframe = event.target as HTMLIFrameElement;
			const preElements = iframe.contentDocument!.body.getElementsByTagName("pre");
			this.errorMessage = getErrorMessage(preElements);
		} catch (e: unknown) {
			// If downloadUrl is not a filedownload but a redirect to an external site,
			// then it is not allowd to access content of external urls.
			// Firefox and other browsers will prevent iframe loading -> no loaded event
			// IE11 will load the site but throws an error on access content
			// Automatic redirect will never work, click on "manual downlaod" is neccessary
			console.log(e);
		}
	}
}

function getErrorMessage(preElements: HTMLCollectionOf<HTMLPreElement>): string | undefined {
	// If there was an error when downloading, there is one "<pre>" element containing an error message.
	if (preElements.length === 0) {
		return undefined;
	}

	const innerHTML = preElements[0].innerHTML;
	try {
		return JSON.parse(innerHTML).message || innerHTML;
	} catch (e: unknown) {
		return innerHTML;
	}
}
