import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DialogService } from "common/dialog/dialog.service";
import { RedirectToLoginDoneListener, StagesHttpInterceptor } from "core/http-interceptor";
import { lastValueFrom, Observable, of, timer } from "rxjs";
import { catchError, distinctUntilChanged, map, share, skip, switchMap, tap } from "rxjs/operators";

// Check an new version of the index.html
@Injectable({
	providedIn: "root",
})
export class VersionCheckService implements RedirectToLoginDoneListener {
	readonly distinctVersions$: Observable<string>;
	private currentVersion = "";
	private shown: boolean = false;

	constructor(
		private httpClient: HttpClient,
		private dialogService: DialogService,
		httpInterceptor: StagesHttpInterceptor,
	) {
		const TEN_MINUTES = 10 * 60 * 1000;
		//Check for new version every 10 minutes
		this.distinctVersions$ = timer(0, TEN_MINUTES).pipe(
			switchMap(() => this.readVersion()),
			tap((v) => (this.currentVersion = v)),
			distinctUntilChanged(),
			skip(1), //skip change from undefined to initial value
			share(), // prevent mulitple http requests due to multiple subscriptions
		);
		this.distinctVersions$.subscribe(() => this.showVersionChangedDialog());
		httpInterceptor.registerNotifyRedirectToLoginDoneListener(this);
	}

	async needsReload(): Promise<boolean> {
		const newVersion = await lastValueFrom(this.readVersion());
		return this.currentVersion !== "" && this.currentVersion !== newVersion;
	}

	private readVersion(): Observable<string> {
		return this.httpClient
			.head("/stages/", {
				headers: new HttpHeaders({
					"Stages-Polling": "true",
				}),
				observe: "response",
			})
			.pipe(
				map((resp) => {
					const version = resp.headers.get("ETag");
					console.debug("Version read: " + (version === null ? "" : version));
					return version === null ? "" : version;
				}),
				catchError((e: unknown) => {
					console.error(`Can't read the current version from server. '${e}'`);
					return of(this.currentVersion);
				}),
			);
	}

	private showVersionChangedDialog(): void {
		if (!this.shown) {
			this.dialogService
				.confirm("application.outdated", undefined, "application.outdated.reload", "application.outdated.later")
				.then((isReloadOk) => {
					if (isReloadOk) {
						location.reload();
					}
				});
			this.shown = true;
		}
	}

	async redirectToLoginDone(): Promise<void> {
		if (await this.needsReload()) {
			// force reload as the user is already logged out
			location.reload();
		}
	}
}
