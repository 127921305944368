<ng-template #itemTemplate let-item let-context="context">
	<i
		*ngIf="item.hasCheckbox"
		class="ico"
		[ngClass]="{ 'ico-check': item.value || (item.getValue && item.getValue()) }"
	></i>
	<i *ngIf="!item.hasCheckbox && !!item.iconClass" class="ico" [ngClass]="item.iconClass"></i>

	<p
		*ngIf="item.name"
		[translate]="isFunction(item.name) ? item.name(context) : item.name"
		[translateParams]="{ ARG0: item.nameParamValue ? item.nameParamValue : (item.nameParam | translate) }"
	></p>
	<p *ngIf="item.translated">{{ item.translated }}</p>
	<i *ngIf="!!item.secondaryIconClass" [ngClass]="item.secondaryIconClass"></i>

	<stages-button-expand-collapse *ngIf="item.expandable" [isCollapsed]="!item.isExpanded">
	</stages-button-expand-collapse>
</ng-template>

<ng-template #clickTypeTemplate let-node>
	<a *ngIf="node.on" (click)="node.on(context)">
		<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: node, context: context }"></ng-container>
	</a>

	<a *ngIf="node.routerLink" [routerLink]="node.routerLink(context)">
		<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: node, context: context }"></ng-container>
	</a>

	<a *ngIf="node.href" [href]="node.href(context)">
		<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: node, context: context }"></ng-container>
	</a>
</ng-template>

<ng-template #pinTemplate let-node>
	<div class="item-container-pinned">
		<a *ngIf="!node.isSwitch && node.on" (click)="node.on(context)">
			<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: node, context: context }"></ng-container>
		</a>
		<div class="pin" [ngClass]="{ pinned: node.isPinSet(node.id) }" (click)="node.setPinnedItem(node.id)">
			<i class="ico ico-pin"></i>
		</div>
	</div>
</ng-template>

<ng-template #switchTemplate let-node>
	<h6 *ngIf="node.nameParamValue" [translate]="node.name" [translateParams]="{ ARG0: node.nameParamValue }"></h6>
	<h6
		*ngIf="!node.nameParamValue"
		[translate]="node.name"
		[translateParams]="{ ARG0: (node.nameParam | translate) }"
	></h6>
	<div class="switch-container">
		<stages-switch
			id="mi_child_{{ menuId }}_{{ node.id ? node.id : node.name }}"
			[value]="node.getValue(context)"
			(valueChange)="node.onValueChange($event, context)"
		></stages-switch>
	</div>
</ng-template>

<ng-template #baseTemplate let-node let-isGroupHeader="isGroupHeader">
	<li
		*ngIf="!node.isSeparator"
		class="mi full-length"
		[ngClass]="{ 'switch-item': node.isSwitch, 'pin-item': node.hasPin }"
	>
		<ng-container *ngIf="isGroupHeader">
			<a stagesClickStopPropagation (click)="node.isExpanded ? (node.isExpanded = false) : (node.isExpanded = true)">
				<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: node, context: context }"></ng-container>
			</a>
		</ng-container>
		<ng-container *ngIf="!isGroupHeader">
			<ng-container *ngIf="!node.isSwitch">
				<ng-container
					*ngTemplateOutlet="
						node.hasPin ? pinTemplate : clickTypeTemplate;
						context: { $implicit: node, context: context }
					"
				></ng-container>
			</ng-container>
			<div stagesClickStopPropagation *ngIf="node.isSwitch">
				<ng-container *ngTemplateOutlet="switchTemplate; context: { $implicit: node, context: context }"></ng-container>
			</div>
		</ng-container>
	</li>
	<li *ngIf="node.isSeparator" class="mi full-length divider"></li>
</ng-template>

<div class="menu-wrapper" [ngClass]="classes!" *ngIf="finishedLoading && filteredItems.length > 0">
	<div class="menu-container" *ngIf="collapseMenu(filteredItems)">
		<!-- title="{{ ::items[0].name | translate: '{ARG0: (items[0].nameParam | translate)}' }}" -->
		<ng-container *ngTemplateOutlet="collapsedMenuButton; context: { $implicit: filteredItems[0] }"> </ng-container>
	</div>

	<div
		*ngIf="!collapseMenu(filteredItems)"
		class="menu-container"
		[ngClass]="{ 'toolbar-dropdown-container': isToolbarDropdown }"
	>
		<button
			[attr.title]="buttonNameKey | translate"
			[attr.aria-label]="buttonNameKey | translate"
			class="menu-button"
			[ngClass]="{ 'button sm add toolbar-dropdown': isToolbarDropdown }"
			(click)="toggle($event)"
		>
			<i *ngIf="iconClasses" class="ico" [ngClass]="iconClasses"></i>
			<ng-content></ng-content>
		</button>

		<ul class="menu fade open-{{ openTo }}" [id]="menuId" *ngIf="open" [ngClass]="{ title: hasTitle }">
			<li *ngIf="hasTitle" class="mi title-container">
				<div class="title-content">
					<i class="ico" [ngClass]="titleIconClass!"></i>
					<div class="label">
						<div class="text">
							<p *ngIf="menuTitle" [translate]="menuTitle" [translateParams]="{ ARG0: titleParams[0] }"></p>
							<h6 *ngIf="subtitle" [translate]="subtitle" [translateParams]="{ ARG0: titleParams[0] }"></h6>
						</div>
					</div>
				</div>
			</li>

			<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackByIndex">
				<cdk-tree-node *cdkTreeNodeDef="let node" [style.display]="shouldRender(node) ? 'flex' : 'none'">
					<ng-container *ngTemplateOutlet="baseTemplate; context: { $implicit: node, context: context }"></ng-container>
				</cdk-tree-node>

				<cdk-tree-node
					*cdkTreeNodeDef="let node; when: hasChild"
					[style.display]="shouldRender(node) ? 'flex' : 'none'"
				>
					<ng-container
						*ngTemplateOutlet="baseTemplate; context: { $implicit: node, context: context, isGroupHeader: true }"
					></ng-container>
				</cdk-tree-node>
			</cdk-tree>
		</ul>
	</div>
</div>

<ng-template #collapsedMenuButton let-item>
	<a *ngIf="item.routerLink" [routerLink]="item.routerLink(context)">
		<button [attr.aria-label]="item.name | translate" [attr.title]="item.name | translate" class="menu-button">
			<i [ngClass]="item.iconClass"></i>
		</button>
	</a>
	<button
		*ngIf="!item.routerLink"
		[attr.aria-label]="item.name | translate"
		[attr.title]="item.name | translate"
		class="menu-button"
		(click)="item.on(context)"
	>
		<i [ngClass]="item.iconClass"></i>
	</button>
</ng-template>
