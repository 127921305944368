import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { EntryPagesDescriptionService } from "administration/entrypages/entry-pages-description.service";
import { PreferencesService } from "core/preferences.service";
import { EntryPagePreferences } from "dashboard/description/entry-page-preferences";
import { EntryPagesService } from "dashboard/description/entry-pages.service";
import { combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

type EntryPageTab = stages.entrypage.EntryPageTab;
const PREFERENCE_KEY = "entryPage";

@Component({
	selector: "stages-entry-pages",
	templateUrl: "./entry-pages.component.html",
	styleUrls: ["./entry-pages.component.scss"],
})
export class EntryPagesComponent implements OnDestroy {
	private destroy$ = new Subject<boolean>();
	paramMap$: Observable<ParamMap>;
	tabs$: Observable<EntryPageTab[]>;
	entryPageSubject = new ReplaySubject<stages.entrypage.EntryPage>(1);
	entryPage$ = this.entryPageSubject.asObservable();
	active!: string;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly preferencesService: PreferencesService,
		private entryPagesService: EntryPagesService,
		public descriptionService: EntryPagesDescriptionService,
	) {
		this.paramMap$ = route.paramMap;
		this.tabs$ = this.route.data.pipe(map((data) => data.entryPageTabs));

		combineLatest([this.tabs$, this.paramMap$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([entryPageTabs, paramMap]) => {
				const tabId = paramMap.get("tab");
				const tab$ = tabId !== null ? this.getTabForId(entryPageTabs, tabId) : this.getInitialTab(entryPageTabs);
				tab$.then((tab) => {
					this.update(tab);
				});
			});
	}

	tabClicked(entryPageTab: EntryPageTab): void {
		this.router.navigate([{ tab: entryPageTab.id }], { relativeTo: this.route });
	}

	getActiveTabName(tabs: EntryPageTab[]): string | undefined {
		for (const tab of tabs) {
			if (tab.id === this.active) {
				return tab.name;
			}
		}

		return undefined;
	}

	private async update(tab: EntryPageTab): Promise<void> {
		const entryPage = await this.entryPagesService.getEntryPage(tab);
		this.active = entryPage.id;
		this.entryPageSubject.next(entryPage);
		this.savePreference(tab);
	}

	private async getInitialTab(tabs: EntryPageTab[]): Promise<EntryPageTab> {
		const preferences = await this.preferencesService.getPreference(PREFERENCE_KEY, new EntryPagePreferences());
		return this.getTabForId(tabs, preferences.tabId);
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private async getTabForId(tabs: EntryPageTab[], selectedTabId: string | undefined): Promise<EntryPageTab> {
		let defaultTab: EntryPageTab | null = null;
		for (const tab of tabs) {
			if (selectedTabId && selectedTabId === tab.id) {
				return tab;
			}

			if (tab.defaultTab) {
				defaultTab = tab;
			}
		}

		return defaultTab !== null ? defaultTab : tabs[0];
	}

	private savePreference(tab: EntryPageTab): void {
		this.preferencesService.setPreference(PREFERENCE_KEY, {
			tabId: tab.id,
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
