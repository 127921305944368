import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HomeNavigationService } from "navigation/list/home.navigation.service";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { MainService } from "core/main.service";
import { ScreenSize } from "core/screensize";

@Component({
	templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	screenSize: ScreenSize = ScreenSize.XXSMALL;

	constructor(private homeNavigationService: HomeNavigationService, private mainService: MainService) {}

	ngOnInit(): void {
		this.homeNavigationService.update();

		this.mainService.applicationState.screenSize.pipe(takeUntil(this.destroy$)).subscribe((screenSize) => {
			this.screenSize = screenSize;
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static provideTitle(translateService: TranslateService, mergedRouteData: any): Observable<string> {
		return translateService
			.get("navigation.drawer.home")
			.pipe(map((title) => `${title} - ${mergedRouteData.workspaceView.currentWorkspace.name}`));
	}

	isSmallScreen(): boolean {
		return this.screenSize === ScreenSize.XXSMALL;
	}

	isMediumScreen(): boolean {
		switch (this.screenSize) {
			case ScreenSize.XSMALL:
				return true;
			case ScreenSize.SMALL:
				return true;
			case ScreenSize.MEDIUM:
				return true;
			default:
				return false;
		}
	}

	isLargeScreen(): boolean {
		switch (this.screenSize) {
			case ScreenSize.XLARGE:
				return true;
			case ScreenSize.LARGE:
				return true;
			default:
				return false;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
