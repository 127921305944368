import { NgModule } from "@angular/core";
import { StagesCommonModule } from "common/common.module";
import { GlobalSearchFilterComponent } from "search/filter.component";
import { GlobalSearchResultsComponent } from "search/results.component";
import { GlobalSearchBoxComponent } from "search/search.component";

@NgModule({
	imports: [StagesCommonModule],
	declarations: [GlobalSearchBoxComponent, GlobalSearchFilterComponent, GlobalSearchResultsComponent],
	exports: [GlobalSearchBoxComponent, GlobalSearchFilterComponent, GlobalSearchResultsComponent],
})
export class StagesSearchModule {}
