import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	RendererStyleFlags2,
	ViewChild,
	ViewRef,
} from "@angular/core";
import { SafeHtml, SafeUrl } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { DragScrollComponent } from "common/drag-scroll/drag-scroll.component";
import { ImageMap } from "common/image-map/image-map.component";
import { UtilService } from "common/util.service";
import { Step, ZoomOutBehavior, ZoomToolbarService } from "common/zoom/zoom-toolbar/zoom-toolbar.service";
import * as ResizeSensor from "css-element-queries/src/ResizeSensor";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

export interface ImageSize {
	originalWidth: number;
	originalHeight: number;
	width: number;
	height: number;
}

interface ImageZoomCalculation {
	minZoomWidth: number;
	originalImageWidth: number;
	maxZoomWidth: number;
	widthPerZoomStepBelowOriginalImageWidth: number;
	widthPerZoomStepAboveOriginalImageWidth: number;
}

// these flags are needed to add a "!important" to changed styles.
// We have to use it here to change the width of the image. The "framework.css" has a "width: auto !important" for all img-Tags
const RENDERER_FLAGS = RendererStyleFlags2.Important | RendererStyleFlags2.DashCase;

interface Position {
	x: number;
	y: number;
}

// we need an additional identifier for the touchgestures to identify wheter it is the same finger as before or not
interface TouchPosition {
	identifier: number;
	position: Position;
}

@Component({
	selector: "stages-image-zoom",
	templateUrl: "./image-zoom.component.html",
	styleUrls: ["./image-zoom.component.scss"],
})
export class ImageZoomComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild("img")
	img?: ElementRef;

	@ViewChild("dragScroller", { read: DragScrollComponent })
	dragScroller?: DragScrollComponent;

	@Input()
	id!: string;

	@Input()
	prefixedIdent?: string;

	@Input()
	container!: HTMLElement;

	@Input()
	currentElementName?: string;

	@Input()
	isZoomToolSupported: boolean = false;

	showDiagram: boolean = false;

	private oldContainerWidth: number = 0;

	@Input()
	set diagramLoaded(value: boolean) {
		this.imageLoaded = value;
		if (!value) {
			this.showDiagram = false;
		}
	}

	get diagramLoaded(): boolean {
		return this.imageLoaded;
	}

	@Input()
	set src(src: SafeHtml | SafeUrl) {
		this._src = src;
		if (!(this.changeDetector as ViewRef).destroyed) {
			this.changeDetector.detectChanges();
		}
		this.refreshDiagram();
		this.diagramLoaded = true;
	}

	get src(): SafeHtml | SafeUrl {
		return this._src;
	}

	@Input()
	set imageMap(imageMap: ImageMap | undefined) {
		this._imageMap = imageMap;
		if (this.imageLoaded && this.img) {
			this.onInitilizationDone();
		}
	}

	get imageMap(): ImageMap | undefined {
		return this._imageMap;
	}

	@Output()
	get isGrabbable(): boolean {
		return this._isGrabbable;
	}

	@Output()
	readonly triggerResize = new EventEmitter<boolean>();

	private _imageMap?: ImageMap;
	private _src!: SafeHtml | SafeUrl;
	private _isGrabbable: boolean = false;
	private zoomOrigin: Position = { x: 0, y: 0 }; // position to use as origin for the zoom
	private zoomValue?: number;
	private imageZoomCalculation: ImageZoomCalculation = {
		maxZoomWidth: 0,
		originalImageWidth: 0,
		minZoomWidth: 0,
		widthPerZoomStepAboveOriginalImageWidth: 0,
		widthPerZoomStepBelowOriginalImageWidth: 0,
	};

	// mobile zoom
	private isTouchZooming: boolean = false;
	private isZooming: boolean = false;
	private touchPositions: TouchPosition[] = [
		{ identifier: 0, position: { x: 0, y: 0 } },
		{ identifier: 1, position: { x: 0, y: 0 } },
	];

	@Input()
	isFirstDiagram: boolean = false;

	@Input()
	sourceChanged: boolean = false;

	private resizeSensor?: ResizeSensor.default;
	private subscriptions: Subscription = new Subscription();
	private _fitToPage: boolean = false;
	private scrollToSelectedElementOnNextZoom: boolean = false;

	// for IE and old edge
	initializationDone: boolean = false;

	@Input()
	set fitToPage(value: boolean) {
		this._fitToPage = value;
		if (!this.isZoomToolSupported) {
			this.setDiagramWidthBasedOnFitToPage();
			if (!(this.changeDetector as ViewRef).destroyed) {
				this.changeDetector.detectChanges();
			}
			if (this.img) {
				this.updateCurrentImageDimensions(this.img.nativeElement);
			}
		}
	}

	get fitToPage(): boolean {
		return this._fitToPage;
	}

	imageLoaded: boolean = false;

	imageSize: ImageSize = { originalWidth: 0, originalHeight: 0, width: 0, height: 0 }; // image properties
	svgHTML?: SafeHtml;

	constructor(
		private readonly zoomToolbarService: ZoomToolbarService,
		private readonly zone: NgZone,
		private readonly renderer: Renderer2,
		private readonly utilService: UtilService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly router: Router,
	) {}

	ngOnInit(): void {
		if (this.isZoomToolSupported) {
			this.initZoomTool();
			this.subscriptions.add(
				this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
					next: (value) => this.applyZoom(),
				}),
			);
		}
	}

	private initZoomTool(): void {
		this.subscriptions = this.zoomToolbarService.zoomValue.subscribe({
			next: (value) => {
				this.zoomValue = value;
				this.applyZoom();
			},
		});
		this.fitToPage = false;
	}

	ngAfterViewInit(): void {
		if (this.container) {
			this.oldContainerWidth = this.container.clientWidth;
			this.resizeSensor = new ResizeSensor.default(this.container, () => {
				const newContainerWidth: number = this.container.clientWidth;
				if (newContainerWidth !== this.oldContainerWidth) {
					this.recalculate();
					this.applyZoom();
					this.oldContainerWidth = newContainerWidth;
				}
				this.triggerResize.emit();
			});
		}
		if (this.img) {
			this.updateOriginalImageDimensions(this.img.nativeElement);
			this.recalculate();
			if (!this.sourceChanged) {
				this.scrollToSelectedElementOnNextZoom = true;
			}
			this.applyZoom();
		}
	}

	private refreshDiagram(): void {
		if (!this.isZoomToolSupported && this.img && this.img.nativeElement) {
			this.setHtmlImageWidthTo(this.img.nativeElement, "auto");
			this.setDiagramWidthBasedOnFitToPage();
			this.updateCurrentImageDimensions(this.img.nativeElement);
		}
	}

	private updateCurrentImageDimensions(img: HTMLImageElement): void {
		if (!this.isZoomToolSupported) {
			this.imageSize.width = img.width;
			this.imageSize.height = img.height;
		} else {
			this.imageSize.width = img.offsetWidth;
			this.imageSize.height = img.offsetHeight;
		}
	}

	private updateOriginalImageDimensions(img: HTMLImageElement): void {
		if (this.isZoomToolSupported) {
			const svg = img.children[0] as SVGSVGElement;
			this.imageSize.originalWidth = svg.width.animVal.value;
			this.imageSize.originalHeight = svg.height.animVal.value;
		} else {
			this.imageSize.originalWidth = img.naturalWidth;
			this.imageSize.originalHeight = img.naturalHeight;
		}
	}

	private applyZoom(): void {
		this.zone.runOutsideAngular(() => {
			if (
				this.img &&
				this.img.nativeElement.children[0] &&
				this.dragScroller &&
				this.dragScroller.elWidth &&
				this.dragScroller._contentRef &&
				this.zoomValue !== undefined
			) {
				// get all the current values
				const dragScrollWidth: number = Number(this.dragScroller._contentRef.nativeElement.clientWidth);
				const widthBeforeZoom: number = this.img.nativeElement.clientWidth;
				const dragScrollHeight: number = Number(this.dragScroller._contentRef.nativeElement.clientHeight);
				const originalScroll: Position = {
					x: this.dragScroller._contentRef.nativeElement.scrollLeft,
					y: this.dragScroller._contentRef.nativeElement.scrollTop,
				};
				// if we have no specific point we are supposed to zoom to, we use the center of the current visible part of the image
				this.zoomOrigin = { x: dragScrollWidth / 2, y: dragScrollHeight / 2 };

				const targetScrolling: Position = {
					x: originalScroll.x + this.zoomOrigin.x,
					y: originalScroll.y + this.zoomOrigin.y,
				};
				let newImageWidth: string;
				if (this.zoomValue <= this.zoomToolbarService.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE) {
					newImageWidth = this.toPixelString(
						(this.zoomValue / this.zoomToolbarService.ZOOM_FIT_TO_PAGE_TO_ORIGINAL_IMAGE_SIZE_STEP_SIZE) *
							this.imageZoomCalculation.widthPerZoomStepBelowOriginalImageWidth +
							this.imageZoomCalculation.minZoomWidth,
					);
				} else {
					newImageWidth = this.toPixelString(
						this.imageSize.originalWidth +
							((this.zoomValue - this.zoomToolbarService.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE) /
								this.zoomToolbarService.ZOOM_ORIGINAL_IMAGE_SIZE_TO_MAX_ZOOM_IN_STEP_SIZE) *
								this.imageZoomCalculation.widthPerZoomStepAboveOriginalImageWidth,
					);
				}
				const div = this.img.nativeElement;
				this.setHtmlImageWidthTo(div.children[0], newImageWidth);
				this.setHtmlImageHeightTo(div.children[0], "auto");
				this.updateCurrentImageDimensions(this.img.nativeElement);
				const zoomFactor = this.imageSize.width / widthBeforeZoom;
				this.dragScroller._contentRef.nativeElement.scrollLeft = targetScrolling.x * zoomFactor - dragScrollWidth / 2;
				this.checkGrabbable();
				this.showDiagram = true;
				if (!(this.changeDetector as ViewRef).destroyed) {
					this.changeDetector.detectChanges();
				}

				if (this.scrollToSelectedElementOnNextZoom) {
					this.scrollToSelectedElement();
					this.scrollToSelectedElementOnNextZoom = false;
				}
			}
		});
	}

	private recalculate(): void {
		if (this.img && this.isZoomToolSupported) {
			this.calculateStepValue();
			this.checkGrabbable();
		}
	}

	toPixelString(value: number): string {
		return value.toString() + "px";
	}

	private setHtmlImageWidthTo(elementRef: HTMLImageElement, width: string): void {
		if (elementRef) {
			this.renderer.setStyle(elementRef, "width", width, RENDERER_FLAGS);
		}
	}

	private setHtmlImageMaxWidthTo(elementRef: HTMLImageElement, width: string): void {
		if (elementRef) {
			this.renderer.setStyle(elementRef, "max-width", width, RENDERER_FLAGS);
		}
	}

	private setHtmlImageHeightTo(elementRef: HTMLImageElement, height: string): void {
		if (elementRef) {
			this.renderer.setStyle(elementRef, "height", height, RENDERER_FLAGS);
		}
	}

	private scrollToSelectedElement(): void {
		if (this.isSetZoomOriginOnSelectedElementSuccessful()) {
			this.scrollToPosition(this.zoomOrigin.x, this.zoomOrigin.y);
		}
	}

	private scrollToPosition(x: number, y: number): void {
		if (this.dragScroller && this.dragScroller._contentRef && this.container) {
			// VERTICAL SCROLL
			// We don't want to center the element on the screen, if the user already scrolled vertical. Otherwise it would "jump" to the element after it was loaded
			if (window.pageYOffset === 0 && this.isFirstDiagram) {
				const rect = this.container.getBoundingClientRect();
				if (rect) {
					const positionFromTop: number = y + Number(rect.top) + window.pageYOffset;
					const verticalScroll: number = positionFromTop - window.innerHeight / 2;
					window.scrollTo(0, verticalScroll);
				}
			}
			// HORIZONTAL SCROLL
			this.dragScroller._contentRef.nativeElement.scrollLeft =
				x - this.dragScroller._contentRef.nativeElement.clientWidth / 2;
		}
	}

	private isSetZoomOriginOnSelectedElementSuccessful(): boolean {
		const allTitleTags: HTMLCollection = this.img!.nativeElement.getElementsByTagName("a");

		// eslint-disable-next-line @typescript-eslint/prefer-for-of -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		for (let i = 0; i < allTitleTags.length; i++) {
			const anchorTag = allTitleTags[i];
			if (this.currentElementName === anchorTag.getAttribute("title")) {
				let foundElement: SVGGElement | SVGTextElement | null = anchorTag.closest("text");
				if (!foundElement) {
					const foundElements = anchorTag.getElementsByTagName("g");
					if (foundElements && foundElements.length > 0) {
						foundElement = foundElements.item(0);
					} else {
						break;
					}
				}
				if (foundElement !== null) {
					const matrix = foundElement.getCTM();
					if (matrix) {
						const clientRect = foundElement.getBoundingClientRect();
						this.zoomOrigin.x = matrix.e + clientRect.width / 2;
						this.zoomOrigin.y = matrix.f + clientRect.height / 2;
						return true;
					}
				}
			}
		}
		return false;
	}

	private setDiagramWidthBasedOnFitToPage(): void {
		if (this.img) {
			if (this.fitToPage) {
				this.setHtmlImageMaxWidthTo(this.img.nativeElement, "100%");
			} else {
				this.setHtmlImageMaxWidthTo(this.img.nativeElement, "none");
			}
		}
	}

	private calculateStepValue(): void {
		// whenever the window is getting resized, the containersize could change aswell. We have to recalculate our zoomvalues then
		if (this.container) {
			this.imageZoomCalculation.maxZoomWidth =
				this.imageSize.originalWidth *
				(this.zoomToolbarService.ZOOM_MAX_ZOOM_IN_VALUE / this.zoomToolbarService.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE);
			this.imageZoomCalculation.minZoomWidth =
				this.container.clientWidth < this.imageSize.originalWidth
					? this.container.clientWidth
					: this.imageSize.originalWidth;
			if (this.container.clientWidth < this.imageSize.originalWidth) {
				this.zoomToolbarService.updateZoomOutBehaviorForDiagram(this.id, ZoomOutBehavior.useFitToPageSizeForMaxZoomOut);
			} else {
				this.zoomToolbarService.updateZoomOutBehaviorForDiagram(
					this.id,
					ZoomOutBehavior.useOriginalImageSizeForMaxZoomOut,
				);
			}
			this.imageZoomCalculation.originalImageWidth = this.imageSize.originalWidth;
			this.imageZoomCalculation.widthPerZoomStepAboveOriginalImageWidth =
				(this.imageZoomCalculation.maxZoomWidth - this.imageZoomCalculation.originalImageWidth) /
				this.zoomToolbarService.AMOUNT_OF_STEPS_FROM_MAX_ZOOM_IN_TO_ORIGINAL_IMAGE_SIZE;
			this.imageZoomCalculation.widthPerZoomStepBelowOriginalImageWidth =
				(this.imageZoomCalculation.originalImageWidth - this.imageZoomCalculation.minZoomWidth) /
				this.zoomToolbarService.AMOUNT_OF_STEPS_FROM_ORIGINAL_IMAGE_SIZE_TO_FIT_TO_PAGE;
		}
	}

	private checkGrabbable(): void {
		// the visualization is grabbable as soon as it is wider or higher than the container
		this._isGrabbable = this.container
			? this.imageSize.width > Number(this.container.clientWidth) && this.isZoomToolSupported
			: false;
	}

	// when the mouse enters an area of the imagemap (leaves the "normal image"), we need to take the last coordinates we had on the image for the zoomOriginPosition
	onMouseLeave(event: MouseEvent): void {
		if (this.isZoomToolSupported && this.imageLoaded) {
			this.setZoomOriginPositionOnMouseposition(event);
		}
	}

	// only occurs on IE and old Edge
	onInitilizationDone(): void {
		this.initializationDone = true;
		this.setDiagramWidthBasedOnFitToPage();
		if (!(this.changeDetector as ViewRef).destroyed) {
			this.changeDetector.detectChanges();
		}
		this.updateCurrentImageDimensions(this.img!.nativeElement);
	}

	// MOBILE EVENTLISTENERS FOR ZOOM

	@HostListener("touchmove", ["$event"])
	onGesture(event: TouchEvent): void {
		if (this.isZoomToolSupported && this.imageLoaded && !this.isZooming && this.container) {
			if (event.targetTouches.length === 2) {
				stopEvent(event);
				// first doubleTouch? Then we just save the current positions of the fingers so we can compare them whenever they get moved
				if (!this.isTouchZooming) {
					this.isTouchZooming = true;
					const firstTouch = event.targetTouches[0];
					const secondTouch = event.targetTouches[1];
					this.touchPositions[0] = {
						identifier: firstTouch.identifier,
						position: { x: firstTouch.clientX, y: firstTouch.clientY },
					};
					this.touchPositions[1] = {
						identifier: secondTouch.identifier,
						position: { x: secondTouch.clientX, y: secondTouch.clientY },
					};
				} else {
					this.isZooming = true;
					// the fingers are moving. We need to compare the distances before and after the move. If the distance increased, the user wants to zoom out
					const firstTouch = this.touchPositions.filter(
						(touchPosition) => touchPosition.identifier === event.targetTouches[0].identifier,
					);
					const secondTouch = this.touchPositions.filter(
						(touchPosition) => touchPosition.identifier === event.targetTouches[1].identifier,
					);
					const oldDistance = Math.hypot(
						firstTouch[0].position.x - secondTouch[0].position.x,
						firstTouch[0].position.y - secondTouch[0].position.y,
					);
					const newDistance = Math.hypot(
						event.targetTouches[0].clientX - event.targetTouches[1].clientX,
						event.targetTouches[0].clientY - event.targetTouches[1].clientY,
					);
					const rect = this.container.getBoundingClientRect();
					if (rect) {
						this.zoomOrigin = {
							x: (firstTouch[0].position.x + secondTouch[0].position.x) / 2 - rect.left,
							y: (firstTouch[0].position.y + secondTouch[0].position.y) / 2 - rect.top,
						};
						oldDistance > newDistance
							? this.zoomToolbarService.performStep(Step.Down)
							: this.zoomToolbarService.performStep(Step.Up);
						setTimeout(() => (this.isZooming = false), 200);
					}
				}
			} else {
				// if we have less than two fingers or more than two fingers on the screen, we ignore them for the zoomfunctionality
				this.isTouchZooming = false;
				this.touchPositions[0] = { identifier: 0, position: { x: 0, y: 0 } };
				this.touchPositions[1] = { identifier: 0, position: { x: 0, y: 0 } };
			}
		}
	}

	@HostListener("touchend", ["$event"])
	onGestureEnd(event: TouchEvent): void {
		if (this.isZoomToolSupported && this.imageLoaded) {
			this.touchPositions[0] = { identifier: 0, position: { x: 0, y: 0 } };
			this.touchPositions[1] = { identifier: 0, position: { x: 0, y: 0 } };
			this.isTouchZooming = false;
		}
	}

	getMousePositionOnImage(event: MouseEvent | PointerEvent): Position {
		let positionOnImage: Position;
		// firefox
		if (this.utilService.browserIsFirefox()) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			positionOnImage = { x: Number((event as any).layerX), y: Number((event as any).layerY) };
		} else if (this.utilService.isMobileBrowser() && this.dragScroller) {
			positionOnImage = {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				x: Number((event as any).layerX) + Number(this.dragScroller._contentRef!.nativeElement.scrollLeft),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				y: Number((event as any).layerY) + Number(this.dragScroller._contentRef!.nativeElement.scrollTop),
			};
		} else {
			// Chrome && Edge
			positionOnImage = { x: Number(event.offsetX), y: Number(event.offsetY) };
			// the dragScrollComponent sometimes targets the specific imagemaparea and sometimes the image. We have to adjust to that behavior.
			if ((event.target as HTMLElement) === this.container && this.dragScroller && this.dragScroller._contentRef) {
				positionOnImage = {
					x: positionOnImage.x + Number(this.dragScroller._contentRef.nativeElement.scrollLeft),
					y: positionOnImage.y + Number(this.dragScroller._contentRef.nativeElement.scrollTop),
				};
			}
		}
		return positionOnImage;
	}

	private setZoomOriginPositionOnMouseposition(event: MouseEvent): void {
		if (this.dragScroller && this.dragScroller._contentRef) {
			this.zoomOrigin =
				event.offsetX && event.offsetY
					? {
							x: event.offsetX - this.dragScroller._contentRef.nativeElement.scrollLeft,
							y: event.offsetY - this.dragScroller._contentRef.nativeElement.scrollTop,
					  }
					: {
							x: event.pageX - this.dragScroller._contentRef.nativeElement.scrollLeft,
							y: event.pageY - this.dragScroller._contentRef.nativeElement.scrollTop,
					  };
		}
	}

	// for IE & old Edge
	onImageLoad(): void {
		this.imageLoaded = true;
		if (this.img) {
			this.imageSize.originalHeight = this.img.nativeElement.naturalHeight;
			this.imageSize.originalWidth = this.img.nativeElement.naturalWidth;
		}

		if (this.imageMap && this.img) {
			this.onInitilizationDone();
		}
	}

	ngOnDestroy(): void {
		if (this.resizeSensor) {
			this.resizeSensor.detach();
		}
		if (this.isZoomToolSupported) {
			this.zoomToolbarService.deleteZoomOutBehaviorForDiagram(this.id);
		}
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}
}

function stopEvent(event: Event): void {
	event.preventDefault();
	event.stopPropagation();
}
