import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { DashboardResource } from "core/stages-client";

@Injectable({ providedIn: "root" })
export class TodoResolver implements Resolve<stages.dashboard.ToDo | null> {
	constructor(private dashboardResource: DashboardResource, private router: Router) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.dashboard.ToDo | null> {
		try {
			// "await" is important, because the error is thrown asynchronously.
			// If the promise is not awaited, the error handling does take place.
			return await this.dashboardResource.getToDo(route.paramMap.get("id")!, {
				workspaceId: route.paramMap.get("workspaceId")!,
			});
		} catch (e: unknown) {
			console.log(e);
			this.router.navigate([
				"workspace",
				route.paramMap.get("workspaceId"),
				route.paramMap.get("processVersion"),
				"error",
				{
					errorType: "http.notFound.task",
					incidentId: new Date().getTime(),
					isReportable: false,
					isTemporary: false,
				},
			]);
			return null;
		}
	}
}
