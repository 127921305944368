<article class="error-page">
	<div class="wrapper">
		<h5 class="title">{{ getMessage("error.unavailable.title", "Server temporarily not available") }}</h5>
		<div class="error">
			{{
				getMessage(
					"error.unavailable.message",
					"The server is temporarily not available. Please retry later or contact your IT administration if the error persists."
				)
			}}
		</div>
		<button class="sm" (click)="retry()">{{ getMessage("error.retry", "Retry") }}</button>
		<footer>
			<div class="footer">
				<span class="img stages"></span>
			</div>
		</footer>
	</div>
</article>
