import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ViewService } from "core/view.service";
import { FeedbackService } from "process/feedback/feedback.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { Observable } from "rxjs";

type ProcessView = stages.process.ProcessView;

@Component({
	selector: "stages-feedback-link-widget",
	templateUrl: "./feedback-link-widget.component.html",
	styleUrls: ["./feedback-link-widget.component.scss"],
})
export class FeedbackLinkWidgetComponent extends BaseComponent implements OnInit {
	processView$!: Observable<ProcessView>;

	constructor(
		componentService: ComponentService,
		private feedbackService: FeedbackService,
		private route: ActivatedRoute,
		private viewService: ViewService,
	) {
		super(componentService);
	}

	ngOnInit(): void {
		this.processView$ = this.viewService.awaitSelfElementObservable();
	}

	getLink(view: ProcessView): string {
		return this.feedbackService.getFeedbackLink(
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
			view.type.ident,
			view.id,
			window.location.href,
		);
	}
}
