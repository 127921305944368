import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { DataViewComponent, Mode } from "common/data/data-view.component";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { ViewService } from "core/view.service";

import { ProcessInterfacesService } from "management/process-interfaces/process-interfaces.service";
import { InterfacesListStore } from "management/process-interfaces/interfaces-list/interfaces-list-store.logic";
import { debounceTime, takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { ProcessInterfacesFilterService } from "management/process-interfaces/process-interfaces-filter.service";
import { ProcessInterfacesParams } from "management/process-interfaces/process-interfaces.component";

type ViewableType = stages.process.ViewableType;
type ProcessElementInterface = stages.management.interfaces.ProcessElementInterface;
type TypedId = stages.core.TypedId;

@Component({
	selector: "stages-process-interfaces-interfaces-list",
	templateUrl: "./process-interfaces-interfaces-list.component.html",
	styleUrls: ["./process-interfaces-interfaces-list.component.scss"],
})
export class ProcessInterfacesInterfacesListComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	private _sourceWorkspaceId?: string;
	private _targetWorkspaceId?: string;
	private _sourceProcessVersion?: string;
	private _mode?: Mode;
	storeIdentifier = "processInterfaces";

	@Input()
	set sourceWorkspaceId(sourceWorkspaceId: string) {
		this._sourceWorkspaceId = sourceWorkspaceId;
	}

	@Input()
	set targetWorkspaceId(targetWorkspaceId: string) {
		this._targetWorkspaceId = targetWorkspaceId;
	}

	@Input()
	set sourceProcessVersion(sourceProcessVersion: string) {
		this._sourceProcessVersion = sourceProcessVersion;
	}

	get sourceProcessVersion(): string {
		return this._sourceProcessVersion!;
	}

	@Input()
	set mode(mode: Mode) {
		this._mode = mode;
	}

	get mode(): Mode {
		return this._mode ? this._mode : Mode.VIEW;
	}

	@Input()
	clickable = true;

	@Input()
	routable = true;

	@ViewChild("dataView", { static: true })
	dataView!: DataViewComponent<ProcessElementInterface>;

	ProcessInterfacesInterfacesSelection = ProcessInterfacesInterfacesListComponent;

	_showOnlyChanges?: boolean;
	get showOnlyChanges(): boolean | undefined {
		return this._showOnlyChanges;
	}

	@Input()
	set showOnlyChanges(value: boolean | undefined) {
		this._showOnlyChanges = value;
		if (value !== undefined) {
			this.updateInterfaceListStore(value);
		}
	}

	private isReleasePreview = false;

	// actually we can not handle paging here in a good way -> we want to deactivate it
	pageSize: number = 100000;
	dataSource!: PageableDataSource<ProcessElementInterface>;
	interfacesListStore?: InterfacesListStore;

	oldFilterType?: string;

	workspaceNameFrom?: string;
	workspaceNameTo?: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mutexService: MutexService,
		private viewService: ViewService,
		private processInterfacesService: ProcessInterfacesService,
		private processInterfacesFilterService: ProcessInterfacesFilterService,
	) {}

	ngOnInit(): void {
		if (!this._sourceWorkspaceId && !this._targetWorkspaceId && !this._sourceProcessVersion) {
			this.loadParamsFromMap();
		}

		this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data: Data) => {
			this.isReleasePreview = data.isReleasePreview;
			if (this._mode === undefined) {
				this._mode = data.mode ? data.mode : Mode.VIEW;
			}

			if (data.storeIdentifier) {
				this.storeIdentifier = data.storeIdentifier;
			}
		});

		combineLatest([this.processInterfacesService.workspaceNameFrom, this.processInterfacesService.workspaceNameTo])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([workspaceNameFrom, workspaceNameTo]) => {
				this.workspaceNameFrom = workspaceNameFrom;
				this.workspaceNameTo = workspaceNameTo;
			});

		this.connectToInterfacesFilterService();
	}

	setupStoreAndDataSource(): void {
		this.interfacesListStore = new InterfacesListStore(
			this.processInterfacesService,
			this.pageSize,
			this._sourceWorkspaceId!,
			this._sourceProcessVersion!,
			this._targetWorkspaceId!,
			this.showOnlyChanges ?? false,
			this.isReleasePreview,
		);

		this.dataSource = new PageableDataSource(
			this.interfacesListStore,
			this.mutexService,
			this.router,
			this.route,
			this.routable ? "page" : undefined,
		);
	}

	connectToInterfacesFilterService(): void {
		if (this.showOnlyChanges !== undefined) {
			this.processInterfacesFilterService.setShowOnlyChanges(this.showOnlyChanges);
		}

		combineLatest([
			this.processInterfacesFilterService.showOnlyChanges$,
			this.processInterfacesFilterService.filterType$,
			this.processInterfacesFilterService.searchTerm$,
		])
			.pipe(debounceTime(300), takeUntil(this.destroy$))
			.subscribe(([showOnlyChanges, filterType, searchTerm]) => {
				if (this.interfacesListStore) {
					const hasStoreChanged =
						this.interfacesListStore._filterType !== filterType ||
						this.interfacesListStore._showOnlyChanges !== showOnlyChanges ||
						this.interfacesListStore._searchTerm !== searchTerm;

					if (this.interfacesListStore._filterType !== filterType) {
						this.oldFilterType = filterType ? filterType : undefined;
						this.interfacesListStore._filterType = filterType!;
					}

					if (this.interfacesListStore._showOnlyChanges !== showOnlyChanges) {
						this._showOnlyChanges = showOnlyChanges;
						this.interfacesListStore.showOnlyChanges = showOnlyChanges;
					}

					if (this.interfacesListStore._searchTerm !== searchTerm) {
						this.interfacesListStore._searchTerm = searchTerm;
					}

					if (hasStoreChanged) {
						this.dataSource.reloadPage();
					}
				} else {
					this._showOnlyChanges = showOnlyChanges;
					this.setupStoreAndDataSource();
				}
			});
	}

	loadParamsFromMap(): void {
		combineLatest([this.route.paramMap, this.route.queryParamMap])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([urlParamMap, urlQueryParamMap]) => {
				const workspaceId = urlParamMap.has(ProcessInterfacesParams.WORKSPACEID)
					? urlParamMap.get(ProcessInterfacesParams.WORKSPACEID)
					: undefined;
				const targetWId = urlParamMap.has(ProcessInterfacesParams.TARGETWORKSPACEID)
					? urlParamMap.get(ProcessInterfacesParams.TARGETWORKSPACEID)
					: undefined;
				const sourcePV = urlQueryParamMap.has(ProcessInterfacesParams.PVFORINTERFACES)
					? urlQueryParamMap.get(ProcessInterfacesParams.PVFORINTERFACES)!
					: urlParamMap.get(ProcessInterfacesParams.PV)!;

				if (workspaceId && this._sourceWorkspaceId !== workspaceId) {
					this._sourceWorkspaceId = workspaceId;
				}

				if (sourcePV && this._sourceProcessVersion !== sourcePV) {
					this._sourceProcessVersion = sourcePV;
				}

				if (targetWId && this._targetWorkspaceId !== targetWId) {
					this._targetWorkspaceId = targetWId;
					this.processInterfacesService.setTargetWorkspaceId(this._targetWorkspaceId);
				}
			});
	}

	updateInterfaceListStore(showOnlyChanges: boolean): void {
		if (this.interfacesListStore && this.interfacesListStore.showOnlyChanges !== showOnlyChanges) {
			this.interfacesListStore.showOnlyChanges = showOnlyChanges;
			this.dataSource.reloadPage();
		}
	}

	getSourceElementLink(item: ProcessElementInterface): unknown[] | null {
		if (!this.clickable) {
			return null;
		}

		const processVersion =
			item.associationChangeTypeIdent === "removed"
				? "_vv"
				: item.associationChangeTypeIdent === "unavailable"
				? "_wv"
				: this.sourceProcessVersion;

		return ProcessInterfacesInterfacesListComponent.getLink(
			processVersion,
			item.sourceElementWorkspaceId,
			item.sourceElementType.ident,
			item.sourceElementIdentity,
		);
	}

	getTargetElementLink(item: ProcessElementInterface): unknown[] | null {
		if (!this.clickable) {
			return null;
		}

		return ProcessInterfacesInterfacesListComponent.getLink(
			item.associationChangeTypeIdent === "removed" ? "_vv" : "_wv",
			item.targetElementWorkspaceId,
			item.targetElementType.ident,
			item.targetElementIdentity,
		);
	}

	getAssociationChangeType(item: ProcessElementInterface): string {
		return item.associationChangeTypeIdent.toLowerCase();
	}

	getIconClasses(elementType: ViewableType, dependent: boolean): string[] {
		return this.viewService.getIconClasses(elementType, false, dependent);
	}

	private static getLink(
		processVersion: string,
		workspaceId: string,
		elementType: string,
		elementIdentity: string,
	): unknown[] {
		return ["//", "workspace", workspaceId, processVersion, "process", elementType, elementIdentity];
	}

	static getId(processInterface: ProcessElementInterface): string {
		return processInterface.associationId;
	}

	static getParentIdentifier(processInterface: ProcessElementInterface): string {
		return `workspace_${processInterface.targetElementWorkspaceId}`;
	}

	static getIdNew(processInterface: ProcessElementInterface): TypedId {
		return {
			id: processInterface.associationId,
			typeIdent: processInterface.targetElementType.ident,
		};
	}

	static isDisabled(processInterface: ProcessElementInterface): boolean {
		return processInterface.associationChangeTypeIdent === "unavailable";
	}

	static isPreselected(processInterface: ProcessElementInterface): boolean {
		return processInterface.isInitiallyPreselected;
	}

	ngOnDestroy(): void {
		this.processInterfacesService.setTargetWorkspaceId(undefined);
		this.processInterfacesFilterService.setSearchTerm("");
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
