import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { Observable } from "rxjs";

@Component({
	selector: "stages-association-workspace-browser",
	templateUrl: "./association-workspace-browser.component.html",
})
export class AssociationWorkspaceBrowserComponent {
	paramMap$: Observable<ParamMap>;

	showRelated: boolean;
	showFavorites: boolean;
	showNavigate: boolean;
	showSearch: boolean;
	showArchived: boolean;

	constructor(private route: ActivatedRoute, private router: Router) {
		this.paramMap$ = this.route.paramMap;

		this.showRelated = this.route.snapshot.data.showRelated ?? true;
		this.showFavorites = this.route.snapshot.data.showFavorites ?? true;
		this.showFavorites = this.route.snapshot.data.showFavorites ?? true;
		this.showNavigate = this.route.snapshot.data.showNavigate ?? true;
		this.showSearch = this.route.snapshot.data.showSearch ?? true;
		this.showArchived = this.route.snapshot.data.showArchived ?? false;
	}

	filterByElementType(paramMap: ParamMap): boolean {
		return paramMap.get("browseroottype") !== "process";
	}

	onSelectWorkspace(workspace: WorkspaceSelected): void {
		// The identity is ignored in the following cases
		// 1. browsetype !== "process" and browseindentity === "index" will fetch the index element of browsetype
		// 2. browsetype === "process" will fetch the process itself
		this.router.navigate(
			[
				"../../../../..",
				workspace.id,
				this.route.snapshot.paramMap.get("browseProcessVersion"),
				this.route.snapshot.paramMap.get("browseType"),
				this.filterByElementType(this.route.snapshot.paramMap) ? "index" : "process",
				{
					browseroottype: this.route.snapshot.paramMap.get("browseroottype"),
					grouppath: this.route.snapshot.paramMap.get("grouppath"),
					groupby: this.route.snapshot.paramMap.get("groupby"),
					allowNavigation: this.route.snapshot.paramMap.get("allowNavigation"),
					dependentTypesRestrictions: this.route.snapshot.paramMap.get("dependentTypesRestrictions"),
				},
				"elements",
			],
			{
				relativeTo: this.route,
			},
		);
	}
}
