import { Injectable } from "@angular/core";

/**
 * Keeps track of selected items, usually used with multiple checkboxes.
 */
export class Selection<T> {
	/**
	 * @param items
	 *            array of all items (should be immutable)
	 * @param keyFn
	 *            function that produces a unique string identifier for an item
	 */
	constructor(private items: T[], private keyFn: (t: T) => string) {}

	/**
	 * This object can be exposed to ng-model (which needs an assignable expression - functions won't work).
	 */
	set: StringToBoolean = {};

	isSelected(item: T): boolean {
		return !!this.set[this.keyFn(item)];
	}

	toggleSelected(item: T): void {
		this.set[this.keyFn(item)] = !this.isSelected(item);
	}

	selectAll(): void {
		this.items.forEach((item) => (this.set[this.keyFn(item)] = true));
	}

	selectNone(): void {
		this.items.forEach((item) => (this.set[this.keyFn(item)] = false));
	}

	/**
	 * Returns those items that are currently selected.
	 */
	getSelected(): T[] {
		return this.items.filter((x) => this.isSelected(x));
	}

	/**
	 * Returns true if any items are selected, and false if none are selected.
	 */
	isSomeSelected(): boolean {
		return this.items.some((x) => this.isSelected(x));
	}

	/**
	 * Returns true if all items are selected, and false if some are not selected.
	 */
	areAllSelected(): boolean {
		return this.items.every((x) => this.isSelected(x));
	}

	addItems(newItems: T[]): void {
		newItems.forEach((newItem) => this.items.push(newItem));
	}

	addItem(newItem: T): void {
		this.items.push(newItem);
	}
}

@Injectable({ providedIn: "root" })
export class SelectionService {
	newSelection<T>(items: T[], keyFn: (t: T) => string): Selection<T> {
		return new Selection<T>(items, keyFn);
	}
}
