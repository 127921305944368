import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MutexService } from "common/concurrency/mutex.service";
import { Button } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { maxValidator } from "common/form/max.directive";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { UploadCompletedCallback } from "common/upload/upload.component";
import { UploadService } from "core/upload.service";
import { UrlService } from "core/url.service";
import { FilesAuthEmbeddableComponent } from "files/auth/files-auth-embeddable.component";
import { FilesOperation } from "files/files-operation";
import { FileService } from "files/files.service";
import { filetypeValidator } from "files/filetype.directive";
import { EMPTY, lastValueFrom, Observable, Subject } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";
import { ViewService } from "core/view.service";

type FileOperationPropertiesInfo = stages.file.FileOperationPropertiesInfo;
type FileUpload = stages.file.FileUpload;
type Upload = stages.file.Upload;

export class FileFormControl extends FormControl {
	warnings!: Record<string, unknown> | null;

	setWarnings(warnings: Record<string, unknown> | null): void {
		this.warnings = warnings;
	}

	isWarning(): boolean {
		return this.warnings !== null && this.warnings !== {};
	}
}

@Component({
	selector: "stages-files-add",
	templateUrl: "./files-add.component.html",
	styleUrls: ["files-add.component.scss"],
})
export class FilesAddComponent implements OnInit, OnDestroy {
	fileProperties$!: Observable<FileOperationPropertiesInfo>;
	paramMap$!: Observable<ParamMap>;

	uploadForm!: FormGroup;
	fileUpload: FileUpload;
	saved?: boolean;
	errorMessage?: string;
	uploadUrl!: string;
	saveInProgress: boolean = false;
	credentialsValid = true;
	oAuthUrl?: string;
	cmsTypeMessageKey?: string;

	addFilesUpload: stages.file.Upload[] = [];
	updateFilesUpload: stages.file.Upload[] = [];

	addFiles!: FormArray;
	updateFiles!: FormArray;

	loadingError$ = new Subject<HttpErrorResponse>();

	private uploadIdsToDisplayName = new Map<string, string>();

	private destroy$ = new Subject<boolean>();

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	@ViewChild("auth")
	authComponent!: FilesAuthEmbeddableComponent;

	@ViewChild("formElement") formElement!: ElementRef;

	buttons: Button[] = [];

	constructor(
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private fileService: FileService,
		private uploadService: UploadService,
		private mutexService: MutexService,
		private urlService: UrlService,
		private formService: FormService,
		private changeDetector: ChangeDetectorRef,
		private viewService: ViewService,
	) {
		this.fileUpload = {
			attributes: [],
			uploads: [],
			comment: null,
			state: null,
		};
	}

	private createUploadForm(): void {
		this.uploadForm = new FormGroup({
			addFiles: this.addFiles,
			updateFiles: this.updateFiles,
			comment: new FileFormControl(),
			state: new FileFormControl(),
		});
	}

	ngOnInit(): void {
		this.buttons = [
			{
				class: "button sm cancel",
				translate: "cancel",
				click: () => {
					this.close();
				},
				visible: () => true,
				disabled: () => {
					return false;
				},
			},
		];

		this.initFileProperties();

		this.paramMap$ = this.route.paramMap;
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
			this.uploadUrl = this.urlService.build(
				"app/workspace/{workspaceId}/process/elements/{type}/{id}/files",
				{
					workspaceId: paramMap.get("workspaceId")!,
					type: paramMap.get("elementType")!,
					id: paramMap.get("elementId")!,
				},
				{
					pv: paramMap.get("processVersion")!,
				},
			);
		});

		this.addFilesUpload = this.getFilesToCreate();
		this.updateFilesUpload = this.getFilesToUpdate();

		this.addFiles = new FormArray([]);
		this.updateFiles = new FormArray([]);
		this.updateDisplayNameControls();

		this.createUploadForm();
	}

	private initFileProperties(): void {
		this.fileProperties$ = this.fileService
			.getFileOperationProperties(
				this.route,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("elementType")!,
				this.route.snapshot.paramMap.get("elementId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				this.route.snapshot.paramMap.get("fileId")!,
			)
			.pipe(takeUntil(this.destroy$))
			.pipe(
				catchError((e: unknown) => {
					if (e instanceof HttpErrorResponse) {
						if (e.status === 901) {
							this.cmsTypeMessageKey = e.error.cmsTypeMessageKey;
							this.credentialsValid = false;
							if (e.error.authInfo.authType === "EXTERNAL_LINK") {
								this.oAuthUrl = e.error.authInfo.url;
							}
							this.changeDetector.detectChanges();
							if (this.oAuthUrl) {
								localStorage.setItem("cmOpName", FilesOperation.ADD.toString());
								localStorage.setItem("cmSingleFile", this.route.snapshot.paramMap.get("singleFile")!);
								localStorage.setItem("cmCurrentFileCount", this.route.snapshot.paramMap.get("currentFileCount")!);
								localStorage.setItem("cmElementId", this.route.snapshot.paramMap.get("elementId")!);
								localStorage.setItem("cmElementType", this.route.snapshot.paramMap.get("elementType")!);
							} else {
								this.authComponent.resultSubject.pipe(takeUntil(this.destroy$)).subscribe((result) => {
									if (result === "cancel") {
										this.dialog.close();
									} else {
										this.credentialsValid = true;
										this.initFileProperties();
									}
								});
							}
						}
						this.loadingError$.next(e);
						return EMPTY;
					}
					throw e;
				}),
			)
			.pipe(
				tap((fileProperties) => {
					if (fileProperties.states && fileProperties.states.length) {
						this.fileUpload.state = fileProperties.states[0].id;
					}
				}),
			);
	}

	private updateDisplayNameControls(): void {
		while (this.addFiles.length !== 0) {
			this.addFiles.removeAt(0);
		}

		while (this.updateFiles.length !== 0) {
			this.updateFiles.removeAt(0);
		}

		for (const upload of this.getFilesToCreate()) {
			const control: FileFormControl = new FileFormControl(upload.displayName, [maxValidator(255)]);
			control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.renameFileInUploads(upload, control));
			this.addFiles.push(control);
		}

		for (const upload of this.getFilesToUpdate()) {
			this.updateFiles.push(new FileFormControl(upload.displayName, [maxValidator(255)]));
		}
	}

	ngOnDestroy(): void {
		this.clear();
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	renameFileInUploads(upload: stages.file.Upload, control: FileFormControl): void {
		this.fileUpload.uploads.forEach((upl, index, uploads) => {
			if (upl.uploadId === upload.uploadId) {
				uploads[index].displayName = control.value;
			}
		});
	}

	getMessageKeyForAdd(paramMap: ParamMap, fileProperties: FileOperationPropertiesInfo): string {
		return this.fileService.getMessageKeyForAdd(
			paramMap.get("singleFile")! === "true",
			Number.parseInt(paramMap.get("currentFileCount")!, 10),
			fileProperties,
		);
	}

	getUploadCompletedCallback(fileProperties: FileOperationPropertiesInfo): UploadCompletedCallback {
		return (newFileUpload: FileUpload, status: number) => {
			if (status === 200) {
				newFileUpload.uploads.forEach((newUpload) => {
					let isValidNewUpload = true;
					if (!newUpload.deleted && !newUpload.updateFile) {
						const control = existsFileValidator(newUpload.displayName, this.addFiles.controls as FileFormControl[]);

						if (control) {
							isValidNewUpload = false;
							control.setWarnings({ alreadyIncluded: true });
						} else {
							const newControl = new FileFormControl(newUpload.displayName, [
								filetypeValidator(this.getFileType(fileProperties)),
								maxValidator(255),
							]);
							newControl.valueChanges
								.pipe(takeUntil(this.destroy$))
								.subscribe(() => this.renameFileInUploads(newUpload, newControl));
							this.addFiles.push(newControl);
						}
					} else if (!newUpload.deleted && newUpload.updateFile) {
						const control = existsFileValidator(newUpload.displayName, this.updateFiles.controls as FileFormControl[]);

						if (control) {
							isValidNewUpload = false;
							control.setWarnings({ alreadyIncluded: true });
						} else {
							this.updateFiles.push(
								new FileFormControl(newUpload.displayName, filetypeValidator(this.getFileType(fileProperties))),
							);
						}
					}

					if (isValidNewUpload) {
						this.fileUpload.uploads.push(newUpload);
					}
				});

				this.addFilesUpload = this.getFilesToCreate();
				this.updateFilesUpload = this.getFilesToUpdate();

				this.updateSingleFileErrorMessage(this.route.snapshot.paramMap);
			} else {
				this.errorMessage = this.translateService.instant("files.upload.errormessage", {
					errorCode: status,
				});
			}
			this.uploadService.setPendingFiles([]);
		};
	}

	closeCancel(): void {
		this.dialog.close();
	}

	saveClicked(fileProperties: FileOperationPropertiesInfo): void {
		this.formService.markFormGroupTouched(this.uploadForm);
		if (this.uploadForm.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.saved = true;
			this.saveInProgress = true;
			const paramMap = this.route.snapshot.paramMap;
			this.mutexService.invoke("addFiles" + paramMap.get("elementType")! + paramMap.get("elementId")!, async () => {
				return this.upload(fileProperties).then(() => {
					this.dialog.close();
					this.viewService.refresh(paramMap);
					this.saveInProgress = false;
				}, this.setServerSideErrorsOnFormControls());
			});
		}
	}

	private setServerSideErrorsOnFormControls(): (response: HttpErrorResponse) => void {
		return (response) => {
			if (response.status === 422 && response.error.fieldNames) {
				const fieldNames = response.error.fieldNames;
				const validatorName = response.error.validatorName;
				const validationMessage = response.error.validationMessage;

				if (Array.isArray(fieldNames)) {
					fieldNames.forEach((fieldName) => {
						const displayName = this.uploadIdsToDisplayName.get(fieldName);
						const controls = this.addFiles.controls.concat(this.updateFiles.controls);

						controls.forEach((control) => {
							if (control.value === displayName) {
								control.setErrors({ [validatorName]: validationMessage || true });
								control.markAsTouched();
							}
						});
					});
				}
			}
		};
	}

	async upload(fileProperties: FileOperationPropertiesInfo): Promise<void> {
		this.fileUpload.attributes = fileProperties.attributes.map((attribute) => {
			const attributeFormControl = this.uploadForm.controls[attribute.typeIdent];
			attribute.value = attributeFormControl ? attributeFormControl.value : null;
			return attribute;
		});

		this.fileUpload.uploads.forEach((upload) => {
			this.uploadIdsToDisplayName.set(upload.uploadId + ".file", upload.displayName);
		});

		return this.fileService.saveUploads(
			this.route,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("elementType")!,
			this.route.snapshot.paramMap.get("elementId")!,
			this.fileUpload,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
	}

	deleteUpload(uploadId: string, paramMap: ParamMap): void {
		for (const upload of this.fileUpload.uploads) {
			if (upload.uploadId === uploadId) {
				upload.deleted = true;
			}
		}
		this.addFilesUpload = this.getFilesToCreate();
		this.updateFilesUpload = this.getFilesToUpdate();

		this.updateDisplayNameControls();
		this.updateSingleFileErrorMessage(paramMap);
	}

	getFilesToCreate(): Upload[] {
		return this.fileUpload.uploads.filter((upload) => !upload.deleted && !upload.updateFile);
	}

	getCreateFilename(i: number): string {
		const upload = this.addFilesUpload[i];
		if (!upload || !upload.updateFile || !upload.updateFile.name) {
			return "";
		}
		return upload.updateFile.name;
	}

	getFilesToUpdate(): Upload[] {
		return this.fileUpload.uploads.filter((upload) => !upload.deleted && upload.updateFile);
	}

	getUpdateFilename(i: number): string {
		const upload = this.updateFilesUpload[i];
		if (!upload || !upload.updateFile || !upload.updateFile.name) {
			return "";
		}
		return upload.updateFile.name;
	}

	async updateSingleFileErrorMessage(paramMap: ParamMap): Promise<void> {
		this.errorMessage = this.hasMultipleFilesWhileNotAllowed(paramMap)
			? await lastValueFrom(this.translateService.get("files.upload.singlefileonly.message"))
			: undefined;
	}

	hasMultipleFilesWhileNotAllowed(paramMap: ParamMap): boolean {
		return paramMap.get("singleFile") === "true" && this.getFilesToCreate().length + this.getFilesToUpdate().length > 1;
	}

	getFileType(fileProperties: FileOperationPropertiesInfo): string | null | undefined {
		if (this.sourceIsDatabase(fileProperties)) {
			return null;
		}
		return fileProperties.fileType;
	}

	sourceIsDatabase(fileProperties: FileOperationPropertiesInfo): boolean {
		return fileProperties.source === "DATABASE";
	}

	saveButtonDisabled(paramMap: ParamMap): boolean {
		return (
			this.saveInProgress ||
			this.getFilesToCreate().length + this.getFilesToUpdate().length === 0 ||
			this.hasMultipleFilesWhileNotAllowed(paramMap)
		);
	}

	hasAlreadyIncludedWarning(control: FileFormControl): boolean {
		const warning = control.warnings && control.warnings.alreadyIncluded;
		return warning !== undefined && warning !== null;
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};

	private clear(): void {
		// Remove temporary uploaded files when the dialog is closed (no matter how)
		this.uploadService.setPendingFiles([]);
		if (!this.saved) {
			this.fileService.removeUploads(
				this.route,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("elementType")!,
				this.route.snapshot.paramMap.get("elementId")!,
				this.fileUpload,
				this.route.snapshot.paramMap.get("processVersion")!,
			);
		}
	}
}

function existsFileValidator(fileName: string, controls: FileFormControl[]): FileFormControl | null {
	let returnControl = null;
	controls.forEach((control) => {
		if (control.value === fileName) {
			returnControl = control;
		}
	});

	return returnControl;
}
