import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { StagesHttpInterceptor } from "core/http-interceptor";
import { StagesHttpRetryInterceptor } from "core/http-retry-interceptor";

@NgModule({
	imports: [RouterModule, CommonModule, HttpClientModule, TranslateModule],
})
export class StagesCoreModule {
	static forRoot(): ModuleWithProviders<StagesCoreModule> {
		return {
			ngModule: StagesCoreModule,
			providers: [
				[
					// Http interceptor providers in outside-in order
					{ provide: HTTP_INTERCEPTORS, useClass: StagesHttpInterceptor, multi: true },
					{ provide: HTTP_INTERCEPTORS, useClass: StagesHttpRetryInterceptor, multi: true },
				],
			],
		};
	}

	constructor(@Optional() @SkipSelf() coreModule: StagesCoreModule) {
		if (coreModule) {
			throw new Error("StagesCoreModule is already loaded. Import it in the AppModule only");
		}
	}
}
