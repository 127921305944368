import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { EditableDescription } from "common/editor/description.service";
import { MainService } from "core/main.service";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "stages-descriptions-readonly",
	templateUrl: "./descriptions-readonly.component.html",
})
export class DescriptionsReadonlyComponent implements OnInit, OnDestroy {
	description!: EditableDescription;
	descriptionSubscription!: Subscription;
	menuItems: MenuItem[] = [];

	@ViewChild("card", { static: true })
	card!: CardComponent;

	constructor(private route: ActivatedRoute, private router: Router, private mainService: MainService) {}

	ngOnInit(): void {
		this.descriptionSubscription = this.route.data
			.pipe(map((data) => data.description))
			.subscribe((description: EditableDescription) => {
				this.description = description;
				this.card.menuItems = [
					{
						name: "open",
						iconClass: "ico ico-navigate-to",
						on: () => {
							this.router.navigate([
								"/",
								"workspace",
								this.route.snapshot.params.workspaceId,
								this.route.snapshot.params.processVersion,
								{
									outlets: {
										primary: ["management", "descriptions", this.route.snapshot.params.identity],
										dialog: null,
									},
								},
							]);
						},
						disabled: () => {
							return !description.isEditable;
						},
					},
				];
			});
	}

	ngOnDestroy(): void {
		if (this.descriptionSubscription) {
			this.descriptionSubscription.unsubscribe();
		}
	}

	back(): void {
		this.router.navigate([".", { outlets: { dialog: null } }], {
			relativeTo: this.mainService.findPopupContainer(this.route),
		});
	}
}
