import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { Button } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { FilesAuthEmbeddableComponent } from "files/auth/files-auth-embeddable.component";
import { FilesOperation } from "files/files-operation";
import { FileService } from "files/files.service";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, map, takeUntil, tap } from "rxjs/operators";

type FileState = stages.file.FileState;
type FileStateMetaData = stages.file.FileStateMetadata;
type FileStateDataAndInfo = DataAndInfo<FileState, FileStateMetaData>;

@Component({
	selector: "stages-files-set-state",
	templateUrl: "./files-set-state.component.html",
})
export class FilesSetStateComponent implements OnInit, OnDestroy {
	fileStateDataAndInfo$!: Observable<FileStateDataAndInfo>;
	form!: FormGroup;
	state!: FormControl;
	comment!: FormControl;
	credentialsValid = true;
	oAuthUrl?: string;
	cmsTypeMessageKey?: string;

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	@ViewChild("auth")
	authComponent!: FilesAuthEmbeddableComponent;

	@ViewChild("formElement") formElement!: ElementRef;

	private destroy$ = new Subject<boolean>();
	loadingError$ = new Subject<HttpErrorResponse>();

	buttons: Button[] = [];

	constructor(
		private route: ActivatedRoute,
		private filesService: FileService,
		private mutexService: MutexService,
		private formService: FormService,
		private changeDetector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.initFileStateDataAndInfo();

		this.buttons = [
			{
				class: "button sm cancel",
				translate: "cancel",
				click: () => {
					this.close();
				},
				visible: () => true,
				disabled: () => {
					return false;
				},
			},
		];
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	private initFileStateDataAndInfo(): void {
		this.fileStateDataAndInfo$ = this.filesService
			.getStateAndInfo(
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("elementType")!,
				this.route.snapshot.paramMap.get("elementId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				this.route.snapshot.paramMap.get("fileId")!,
			)
			.pipe(takeUntil(this.destroy$))
			.pipe(
				catchError((e: unknown) => {
					if (e instanceof HttpErrorResponse) {
						if (e.status === 901) {
							this.cmsTypeMessageKey = e.error.cmsTypeMessageKey;
							this.credentialsValid = false;
							if (e.error.authInfo.authType === "EXTERNAL_LINK") {
								this.oAuthUrl = e.error.authInfo.url;
							}
							this.changeDetector.detectChanges();
							if (this.oAuthUrl) {
								localStorage.setItem("cmOpName", FilesOperation.SET_STATE.toString());
								localStorage.setItem("cmFileId", this.route.snapshot.paramMap.get("fileId")!);
								localStorage.setItem("cmElementId", this.route.snapshot.paramMap.get("elementId")!);
								localStorage.setItem("cmElementType", this.route.snapshot.paramMap.get("elementType")!);
							} else {
								this.authComponent.resultSubject.pipe(takeUntil(this.destroy$)).subscribe((result) => {
									if (result === "cancel") {
										this.dialog.close();
									} else {
										this.credentialsValid = true;
										this.initFileStateDataAndInfo();
									}
								});
							}
						}
						this.loadingError$.next(e);
						return EMPTY;
					}
					throw e;
				}),
			)
			.pipe(
				map((fileStateDataAndInfo) => {
					if (fileStateDataAndInfo.metadata.successorStates.length > 0) {
						fileStateDataAndInfo.data.state = fileStateDataAndInfo.metadata.successorStates[0].id;
						fileStateDataAndInfo.data.comment = null;
					}
					return fileStateDataAndInfo;
				}),
			)
			.pipe(
				tap((fileStateDataAndInfo) => {
					this.state = new FormControl(fileStateDataAndInfo.data.state);
					this.comment = new FormControl(fileStateDataAndInfo.data.comment);
					this.form = new FormGroup({
						state: this.state,
						comment: this.comment,
					});
				}),
			);
	}

	submit(fileStateDataAndInfo: FileStateDataAndInfo): void {
		this.formService.markFormGroupTouched(this.form);
		if (this.form.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			const outgoingAttributes = fileStateDataAndInfo.data.attributes.map((attribute) => {
				const attributeFormControl = this.form.controls[attribute.typeIdent];
				attribute.value = attributeFormControl ? attributeFormControl.value : null;
				return attribute;
			});

			this.mutexService
				.invokeWithResult(
					"saveState" +
						this.route.snapshot.paramMap.get("elementType")! +
						this.route.snapshot.paramMap.get("elementId")!,
					async () =>
						this.filesService.saveState(
							this.route,
							this.route.snapshot.paramMap.get("fileId")!,
							outgoingAttributes,
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("processVersion")!,
						),
				)
				.then(() => {
					this.dialog.close();
				});
		}
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};
}
