import { AssociationSource } from "common/associations/association-browser.service";
import { Group } from "common/associations/association-group.service";
import { Association } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { Assert } from "core/assert";
import { assertDefined } from "core/functions";
import { ViewService } from "core/view.service";
import { ComplianceAssociationService } from "process/compliance/associations/compliance-association-service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type TypedId = stages.core.TypedId;
type TypedIdAssociationDetails = stages.core.model.TypedIdAssociationDetails;

type ProcessTreeItem = stages.process.ProcessTreeItem;
type ProcessView = stages.process.ProcessView;
type ViewableProcess = stages.process.ViewableProcess;
type ViewableType = stages.process.ViewableType;

type ViewableElement = stages.process.ViewableElement;
type TargetElement = stages.compliance.TargetElement;

export class ComplianceAdapter implements AssociationSource<TargetElement> {
	constructor(private element: ProcessView) {}

	get associations(): Record<string, stages.compliance.ReferenceComplianceAssociationGroup> {
		return assertDefined(
			this.element.complianceRatingContainer,
			"element is expected to have a ComplianceRatingContainer",
		).associations;
	}

	get parent(): ProcessTreeItem | undefined {
		return this.element.parent;
	}

	get process(): ViewableProcess {
		return this.element.process;
	}

	get type(): ViewableType {
		return this.element.type;
	}

	get id(): string {
		return this.element.id;
	}

	get identity(): string {
		return this.element.identity;
	}
}

export class ComplianceAssociationStore implements AssociationStore<ComplianceAdapter, TargetElement> {
	constructor(private viewService: ViewService, private associationService: ComplianceAssociationService) {}

	getAddMenuEntryNameKey(group: Group<TargetElement>): string {
		return group.translate! + ".add";
	}

	getAddMenuEntryNameKeyParam(_group: Group<TargetElement>): string | undefined {
		return undefined;
	}

	getAddMenuEntryNameKeyParamValue(_group: Group<TargetElement>): string | undefined {
		return undefined;
	}

	getInputClasses(
		typeIdent: string | undefined,
		isDependentElement: boolean,
		subtypeIdent: string | null,
		additionalClasses?: string,
	): string[] {
		return this.viewService.getIconClasses(
			{
				ident: typeIdent!,
				subtypeIdent: subtypeIdent,
				name: null,
				pluralName: null,
			},
			false,
			isDependentElement,
			additionalClasses,
		);
	}

	getIconClasses(targetElement: TargetElement, isDependentElement: boolean, additionalClasses?: string): string[] {
		return this.viewService.getIconClasses(
			targetElement.type,
			targetElement.index,
			isDependentElement,
			additionalClasses,
		);
	}

	getLabelClasses(
		_sourceElement: ComplianceAdapter,
		_targetElement: TargetElement,
		_additionalClasses?: string | undefined,
	): string[] {
		return [];
	}

	getQuickAssignLabelClasses(searchResult: AssocSearchResult, _additionalClasses?: string | undefined): string[] {
		return searchResult.tailored ? ["tailored"] : [];
	}

	getActions(association: Association<TargetElement>): StringToBoolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return (association as any).allowedOperations;
	}

	getTargetElementActions(targetElement: TargetElement): StringToBoolean {
		return targetElement.actions;
	}

	getSourceElement(_type: string, _identity: string, _workspaceId: string, _pv: string): Observable<ComplianceAdapter> {
		return this.viewService.awaitSelfElementObservable().pipe(map((self) => new ComplianceAdapter(self)));
	}

	getAdditionalSourceElements(_sourceElement: ComplianceAdapter): ComplianceAdapter[] {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async getParentForNewTargetElement(
		selfElement: ComplianceAdapter,
		targetType: string,
	): Promise<TargetElement | null> {
		if (this.isTargetAndSourceSameType(selfElement, targetType)) {
			return selfElement.parent !== undefined ? (selfElement.parent as unknown as TargetElement) : null;
		}
		let indexOfTargetType = null;
		// eslint-disable-next-line eqeqeq  -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		if (selfElement.process !== undefined && selfElement.process.indexElements != undefined) {
			selfElement.process.indexElements.forEach((indexElem: ViewableElement) => {
				if (indexElem.type.ident === targetType) {
					indexOfTargetType = indexElem;
				}
			});
		}

		return indexOfTargetType;
	}

	isTargetAndSourceSameType(selfElement: ComplianceAdapter, targetType: string): boolean {
		return selfElement.type.ident === targetType;
	}

	asTypedId(sourceElement: ComplianceAdapter): stages.core.TypedId {
		return {
			typeIdent: sourceElement.type.ident,
			id: sourceElement.id,
		};
	}

	// rule is currently disabled, because function throws always an error
	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async createAssociationToNewTargetElement(
		_sourceElement: stages.core.TypedId,
		_newElementParent: TargetElement,
		_newViewableElement: string,
		_newtargetSubtype: string | null,
		_name: string,
		_associationType: string,
		_sourceRole: string | undefined,
		_workspaceId: string,
		_pv: string,
	): Promise<Association<TargetElement>> {
		throw new Error("creating new target elements is not supported for compliance associations");
	}

	async createAssociation(
		sourceElement: stages.core.TypedId,
		targetElementId: string,
		targetElementType: string,
		_associationType: string,
		_sourceRole: string | undefined,
		_workspaceId: string,
		_pv: string,
	): Promise<Association<TargetElement>> {
		return this.associationService
			.createAssociation(sourceElement.id, sourceElement.typeIdent, targetElementId, targetElementType)
			.then(asAssociation);
	}

	async createCommentOnlyAssociation(
		sourceElement: stages.core.TypedId,
		targetType: string,
		_targetSubtype: string | null,
		_associationType: string,
		_sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<Association<TargetElement>> {
		return this.associationService
			.createCommentOnlyAssociation(sourceElement.id, sourceElement.typeIdent, targetType, workspaceId, pv, comment)
			.then(asAssociation);
	}

	async deleteAssociations(
		associationsToDelete: Association<TargetElement>[],
		workspaceId: string,
		pv: string,
	): Promise<Association<TargetElement>[]> {
		return this.associationService
			.deleteAssociations(
				associationsToDelete.map((assoc) => assoc.id),
				workspaceId,
				pv,
			)
			.then(() => associationsToDelete);
	}

	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		return this.associationService.updateComment(associationId, comment, currentWorkspaceId, pv);
	}

	async searchProcessElements(
		searchTerm: string,
		targetElementType: string,
		_targetElementSubtypes: string[],
		targetElementsToIgnore: TargetElement[],
		currentWorkspaceId: string,
		searchWorkspaceId: string,
		pv: string,
	): Promise<AssocSearchResult[]> {
		Assert.truthy(
			currentWorkspaceId !== searchWorkspaceId,
			"Search workspace expected to be different from the current workspace",
		);
		return this.associationService.searchProcessElements(
			searchTerm,
			targetElementType,
			targetElementsToIgnore.map((e) => ({ typeIdent: e.type.ident, id: e.id })),
			searchWorkspaceId,
			pv,
		);
	}

	async searchDependentProcessElements(
		_searchTerm: string,
		_targetElementType: string,
		_containerElementId: string,
		_targetElementsToIgnore: TargetElement[],
		_currentWorkspaceId: string,
		_pv: string,
	): Promise<AssocSearchResult[]> {
		return Promise.resolve([]);
	}

	async applyAssociationModifications(
		sourceElement: ComplianceAdapter,
		groupPath: string,
		added: TypedId[],
		removed: TypedId[],
		modifiedComments: TypedIdAssociationDetails[],
		workspaceId: string,
		pv: string,
	): Promise<Association<TargetElement>[]> {
		return this.associationService
			.applyAssociationModifications(
				sourceElement.type.ident,
				sourceElement.id,
				groupPath,
				added,
				removed,
				modifiedComments,
				workspaceId,
				pv,
			)
			.then((sourceElementAfterUpdate) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				const selfElement = this.viewService.getSelf(sourceElementAfterUpdate as any);
				sourceElement.associations[groupPath] = selfElement.complianceRatingContainer.associations[groupPath];
				this.viewService.notifyModified();
				return selfElement.complianceRatingContainer.associations[groupPath];
			});
	}

	getLink(association: Association<TargetElement>, pv: string): unknown[] {
		return [
			"/",
			"workspace",
			association.targetElement!.workspaceId,
			pv,
			"process",
			association.targetElement!.type.ident,
			association.targetElement!.identity,
		];
	}

	isSelectableInBrowser(
		existingAssociation: Association<TargetElement> | null,
		_sourceElement: ComplianceAdapter,
		targetElement: TargetElement,
		targetSubtypes: string[] | undefined,
	): boolean {
		return (
			hasTargetSubtype(targetElement, targetSubtypes) &&
			(!existingAssociation || this.getActions(existingAssociation).DELETE)
		);
	}

	reset(): void {
		return;
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	async rearrangeAssociations(
		_currentWorkspaceId: string,
		_sortedAssociationIds: string[],
		_sortStrategy: stages.core.sort.SortStrategy,
		_type: string,
		_identity: string,
		_path: string,
		_groupBy: string,
		_pv: string,
	): Promise<Record<string, never>> {
		throw new Error("Method not implemented.");
	}

	getGroupIdentifier(groupPath: string, _groupBy?: string): string {
		return groupPath;
	}
}

function asAssociation(viewableAssociation: stages.compliance.ComplianceAssociation): Association<TargetElement> {
	const assoc = {
		...viewableAssociation,
		actions: viewableAssociation.allowedOperations,
	};
	return assoc as Association<TargetElement>;
}

function hasTargetSubtype(element: TargetElement, targetSubtypes: string[] | undefined): boolean {
	if (!targetSubtypes || targetSubtypes.length === 0) {
		return true;
	}
	return !!element.type.subtypeIdent && targetSubtypes.indexOf(element.type.subtypeIdent) > -1;
}
