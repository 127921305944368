import { Component, OnInit, TrackByFunction } from "@angular/core";
import { ToolbarItem } from "common/toolbar/toolbar-item.interface";
import { PreferencesService } from "core/preferences.service";
import { Sequence } from "process/element/sequence.interface";
import { ComponentType, TabbedCardType, TabType } from "process/view/type.interface";

@Component({
	selector: "stages-tab-card-no-route-dynamic",
	templateUrl: "./tab-card-no-route-dynamic.component.html",
	styleUrls: ["./tab-card-no-route-dynamic.component.scss"],
})
export class TabCardNoRouteDynamicComponent implements OnInit {
	type!: ComponentType;

	sequenceOptions!: Sequence[];
	tabDefs!: TabType[];
	tabsetId!: string;

	toolbarItems!: ToolbarItem[];

	toolbarId!: string;

	menuId!: string;

	menuItems!: MenuItem[];

	menuNoCollapse?: boolean;

	constructor(private preferencesService: PreferencesService) {}

	ngOnInit(): void {
		this.tabDefs = (this.type as TabbedCardType).tabs;
		if ((this.type as TabbedCardType).button) {
			this.sequenceOptions = (this.type as TabbedCardType).button.buttonOptions;
		}
		const tabbedCardType = this.type as TabbedCardType;
		this.tabsetId = "_" + tabbedCardType.id;
		this.menuId = "menu_" + tabbedCardType.id;
		this.toolbarId = "toolbar_" + tabbedCardType.id;
		if (this.tabDefs.length > 0) {
			this.readActiveTab(this.tabsetId, { activeTab: this.tabDefs[0].id }).then((activeTab) => {
				const storedTabExists = !!this.tabDefs.find((tabDef) => tabDef.id === activeTab.activeTab);
				if (storedTabExists) {
					this.activateTab(activeTab.activeTab);
				} else {
					this.activateTab(this.tabDefs[0].id);
				}
			});
		}
	}

	onActivate(activatedTab: TabType): void {
		this.activateTab(activatedTab.id);
	}

	private activateTab(tabId: string): void {
		this.tabDefs.forEach((t) => (t.active = false));
		this.tabDefs
			.filter((t) => t.id === tabId)
			.forEach((tab) => {
				tab.active = true;
				this.storeActiveTab(this.tabsetId, { activeTab: this.tabDefs[0].id }, { activeTab: tabId });
			});
	}

	getTabId: TrackByFunction<TabType> = (_index, tabDef: TabType) => {
		return tabDef.id;
	};

	getActiveTabName(): string | undefined {
		for (const tab of this.tabDefs) {
			if (tab.active) {
				return tab.translate;
			}
		}

		return undefined;
	}

	/* parameter storage handling / preferences */

	private async storeActiveTab(
		prefKey: string,
		defaultTabs: { activeTab: string },
		newParameters: { activeTab: string },
	): Promise<void> {
		return this.preferencesService.getPreference(prefKey, defaultTabs).then((oldParameters) => {
			if (oldParameters.activeTab !== newParameters.activeTab) {
				this.preferencesService.setPreference(prefKey, newParameters);
			}
		});
	}

	private async readActiveTab(
		prefKey: string,
		defaultUserParams: { activeTab: string },
	): Promise<{ activeTab: string }> {
		if (defaultUserParams) {
			return this.preferencesService.getPreference(prefKey, defaultUserParams);
		}
		return new Promise<{ activeTab: string }>(defaultUserParams);
	}
}
