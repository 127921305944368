import { animate, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { MainService, SECONDARY_MODE_PARAM, SECONDARY_PROCESS_VERSION_PARAM } from "core/main.service";
import { ApplicationState } from "core/volatile-application-state.model";
import { Observable } from "rxjs";

type Workspace = stages.workspace.Workspace;

@Component({
	selector: "stages-workspace-navigation",
	templateUrl: "./workspace-navigation.component.html",
	animations: [
		trigger("defer", [
			//defer ngIf of the workspace-list until swipe animation is done
			// style NOP is required for edge, otherwise tab switch is no longer possible after selecting a workspace
			transition(":leave", animate("0.4s", style({ transform: "translate(0)" }))),
		]),
	],
})
export class WorkspaceNavigationComponent {
	applicationState: ApplicationState;

	workspaceView$: Observable<stages.workspace.application.WorkspaceView>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mainService: MainService,
		private translateService: TranslateService,
	) {
		this.applicationState = mainService.applicationState;
		this.workspaceView$ = mainService.workspaceView$;
	}

	private async onNavigateWorkspace(workspace: WorkspaceSelected): Promise<boolean> {
		try {
			const urlTree = this.router.parseUrl(this.router.url);
			const segmentPath: string =
				urlTree.root.children.primary.segments.length > 3 ? urlTree.root.children.primary.segments[3].path : "process";

			// TODO (secondaryWorkspaceId && secondaryProcessVersion) ?
			switch (segmentPath) {
				case "administration":
				case "reporting":
					urlTree.root.children.primary.segments[1].path = workspace.id;
					return this.router.navigateByUrl(urlTree);
				case "process":
				case "management":
				case "home":
					return this.router.navigate([
						"workspace",
						workspace.id,
						this.route.snapshot.paramMap.get("processVersion")!,
						"process",
					]);
				default:
					throw new Error("Appropriate segment missing in urlTree -> navigate to home");
			}
		} catch (error: unknown) {
			return this.router.navigate(
				this.mainService.getWorkspaceHomeParams(
					workspace.id,
					this.route.snapshot.paramMap.get("processVersion")!,
					this.route.snapshot.paramMap.get("smode")!,
				),
			);
		}
	}

	async onSelectWorkspace(workspace: WorkspaceSelected): Promise<void> {
		await this.onNavigateWorkspace(workspace);
		this.closeNavigation();
	}

	restoreSecondaryView(): void {
		const secondaryWorkspaceId = this.mainService.secondaryWorkspaceId;
		const secondaryProcessVersion = this.route.snapshot.paramMap.get(SECONDARY_PROCESS_VERSION_PARAM);
		const smode = this.route.snapshot.paramMap.get(SECONDARY_MODE_PARAM);

		if (secondaryWorkspaceId && secondaryProcessVersion && smode === "compare") {
			this.router.navigate(
				this.mainService.getWorkspaceHomeParams(
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
					smode,
				),
			);
		} else if (secondaryWorkspaceId && secondaryProcessVersion) {
			this.router.navigate(
				this.mainService.getWorkspaceHomeParams(
					secondaryWorkspaceId,
					secondaryProcessVersion,
					smode ? smode : undefined,
				),
			);
		}
	}

	parentExists(workspace: Workspace): boolean {
		return workspace && workspace.pathSegments && workspace.pathSegments.length > 0;
	}

	getParentName(workspace: Workspace): string {
		if (this.parentExists(workspace)) {
			return workspace.pathSegments[workspace.pathSegments.length - 1];
		}
		return "";
	}

	closeNavigation(): void {
		this.applicationState.workspaceNavigationOpen = false;
	}

	getSecondaryMode(): string | undefined {
		return this.mainService.secondaryMode;
	}

	getVersionName(viewedProcess?: stages.workspace.ViewedProcess | null): string | null {
		if (!viewedProcess) {
			return "";
		}

		if (viewedProcess.workingVersion) {
			return this.translateService.instant("management.process.versions.workingrev");
		}

		if (viewedProcess.validVersion) {
			return this.translateService.instant("management.process.versions.namelabel", {
				name: viewedProcess.versionName,
			});
		}

		return viewedProcess.versionName;
	}
}
