import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { ClassInterfaceUsedByEditNameComponent } from "common/newdialog/edit-name.component";
import { assertDefined, provideAs } from "core/functions";
import { CommonStore } from "core/store";
import { FreezeService, JobInfo, JobInfoMap } from "process/freeze/freeze.service";
import { PhaseAdapter } from "process/freeze/overview/phase-adapter.model";
import { PhaseMatrix } from "process/freeze/overview/phase-matrix.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type FreezeSpecification = stages.process.freeze.FreezeSpecification;

@Component({
	providers: [provideAs(FreezeComponent, ClassInterfaceUsedByEditNameComponent)],
	selector: "stages-freeze",
	templateUrl: "freeze.component.html",
})
export class FreezeComponent implements ClassInterfaceUsedByEditNameComponent {
	readonly actionKey = "process.freeze";
	readonly nameLabelKey = "process.freeze.processversion.name";
	readonly namePattern = "^[^/\\\\]+$";
	readonly patternError = "management.process.versions.pattern";
	readonly existsError = "management.process.versions.exists";
	readonly systemError = "management.process.versions.reserved.words";
	readonly explanationKey = "process.freeze.processversion.explanation";
	explanationKeyParams?: StringToString;

	name = "";

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	paramMap$: Observable<ParamMap>;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly freezeService: FreezeService,
		private readonly phaseFreezeStore: CommonStore,
	) {
		this.paramMap$ = this.route.paramMap.pipe(
			map((paramMap) => {
				const phaseId = paramMap.get("id")!;
				this.explanationKeyParams = {
					phaseName: getNameOfPhaseToFreeze(phaseId, paramMap, this.phaseFreezeStore.value.phaseMatrix),
				};
				return paramMap;
			}),
		);
	}

	close(): void {
		this.dialog.close();
	}

	async save(): Promise<boolean> {
		const paramMap = this.route.snapshot.paramMap;
		const phaseId = assertDefined(paramMap.get("id"));
		const typeIdent = assertDefined(paramMap.get("type"));
		const freezeWorkspaceId = assertDefined(paramMap.get("freezeWorkspaceId"));

		const phaseMatrix: PhaseMatrix | undefined = this.phaseFreezeStore.value.phaseMatrix;
		const dependentFreezeSpecifications = getDependentFreezeSpecifications(phaseId, phaseMatrix);
		const dependentPhaseIds = dependentFreezeSpecifications.map((fs) => fs.phaseToFreeze.id);

		const jobIdentifiers = await this.freezeService.freeze(
			this.name,
			freezeWorkspaceId,
			{
				typeIdent: typeIdent,
				id: phaseId,
			},
			paramMap.get("workspaceId")!,
			paramMap.get("processVersion")!,
			dependentFreezeSpecifications,
		);

		const jobInfoMap: JobInfoMap = {};
		const allPhaseIds = [phaseId].concat(dependentPhaseIds);
		for (let i = 0; i < allPhaseIds.length && i < jobIdentifiers.length; i++) {
			jobInfoMap[typeIdent + "_" + allPhaseIds[i]] = createWaitingJobExecutionInfo(jobIdentifiers[i]);
		}
		const matrix = this.phaseFreezeStore.value.phaseMatrix;
		if (matrix) {
			matrix.setJobExecutionInfo(jobInfoMap);
			this.phaseFreezeStore.set("phaseMatrix", matrix);
		}

		return this.router.navigate([{ jobType: jobIdentifiers[0].group, jobName: jobIdentifiers[0].name }], {
			relativeTo: this.route,
		});
	}
}

function getNameOfPhaseToFreeze(phaseId: string, paramMap: ParamMap, phaseMatrix?: PhaseMatrix): string {
	if (paramMap.has("phaseName")) {
		return paramMap.get("phaseName")!;
	}
	if (!phaseMatrix) {
		return "[unknown]";
	}
	const programPhase = phaseMatrix.program.phases.find((phaseAdapter) => phaseAdapter.phase.id === phaseId);
	if (programPhase) {
		return programPhase.phase.label;
	}
	for (const project of phaseMatrix.projects) {
		const projectPhase = project.phases.find((phaseAdapter) => phaseAdapter.phase.id === phaseId);
		if (projectPhase) {
			return projectPhase.phase.label;
		}
	}
	return "[unknown]";
}

function getDependentFreezeSpecifications(phaseId: string, phaseMatrix?: PhaseMatrix): FreezeSpecification[] {
	if (!phaseMatrix) {
		return [];
	}
	const programPhase = phaseMatrix.program.phases.find((phaseAdapter) => phaseAdapter.phase.id === phaseId);
	if (!programPhase) {
		return [];
	}
	return determineDependentFreezeSpecifications(programPhase);
}

function determineDependentFreezeSpecifications(phaseAdapter: PhaseAdapter): FreezeSpecification[] {
	return phaseAdapter.getDependentFreezablePhases().map((phase) => {
		return {
			phaseToFreeze: { typeIdent: phase.type.ident, id: phase.id },
			workspaceId: phase.workspaceId,
		};
	});
}

function createWaitingJobExecutionInfo(jobIdentifier: stages.core.scheduler.JobIdentifier): JobInfo {
	return {
		jobIdentifier: jobIdentifier,
		jobStatus: "WAITING",
	};
}
