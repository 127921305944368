import { Component, Input } from "@angular/core";
import { ViewService } from "core/view.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type ViewableActivity = stages.process.ViewableActivity;

@Component({
	selector: "stages-activity-stream",
	templateUrl: "./activity-stream.component.html",
})
export class ActivityStreamComponent {
	@Input()
	translateNone?: string;

	allEntries$: Observable<ViewableActivity[] | null>;
	isCollapsed: boolean = true;

	constructor(private viewService: ViewService) {
		this.allEntries$ = this.viewService.awaitSelfElementObservable().pipe(
			map((self) => {
				const timeline: Record<string, ViewableActivity[]> = self.timeline;
				const allEntries: ViewableActivity[] = [];
				for (const period in timeline) {
					if (period) {
						for (const entry of timeline[period]) {
							allEntries.push(entry);
						}
					}
				}
				if (allEntries.length === 0) {
					return null;
				}
				return allEntries;
			}),
		);
	}

	toggle(): void {
		this.isCollapsed = !this.isCollapsed;
	}

	getNumberOfInvisibleEntries(visibleEntries: ViewableActivity[], allEntries: ViewableActivity[]): number {
		return allEntries.length - visibleEntries.length;
	}

	showBubble(visibleEntries: ViewableActivity[], allEntries: ViewableActivity[], index: number): boolean {
		return allEntries.length > 3 && visibleEntries.length - 2 === index;
	}
}
