import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	ViewRef,
} from "@angular/core";
import { FlatTreeControl } from "@angular/cdk/tree";
import { ArrayDataSource } from "@angular/cdk/collections";
import { trackByIndexFunction } from "core/functions";
import { BodyHeightService } from "common/menu/body-height.service";
import { BehaviorSubject } from "rxjs";

@Component({
	selector: "stages-menu",
	templateUrl: "./menu.component.html",
	styleUrls: ["./menu.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnChanges, OnDestroy {
	treeControl = new FlatTreeControl<MenuItem>(
		(node) => node.level,
		(node) => node.expandable,
	);

	@Input()
	menuId?: string | null;

	@Input()
	menuTitle?: string;

	@Input()
	subtitle?: string;

	@Input()
	classes?: Set<string> | string[] | StringToUnknown | string;

	@Input()
	iconClasses?: Set<string> | string[] | StringToUnknown | string;

	@Input()
	openTo?: string;

	@Input()
	noCollapse?: boolean;

	private filteredItems$ = new BehaviorSubject<MenuItem[]>([]);

	filteredItems: MenuItem[] = [];

	dataSource: ArrayDataSource<MenuItem>;

	finishedLoading: boolean = false;

	@Input()
	items?: MenuItem[];

	@Input()
	isToolbarDropdown: boolean = false;

	@Input()
	@HostBinding("class.enableOverflowOutbreak")
	enableOverflowOutbreak = false;

	@Input()
	context: unknown;

	@Input()
	keepOpenOnMenuClick?: boolean;

	@Input()
	titleIconClass?: string;

	@Input()
	titleParams!: string[];

	@Input()
	buttonNameKey: string = "contextmenu";

	constructor(
		private bodyHeightService: BodyHeightService,
		private changeDetector: ChangeDetectorRef,
		private elRef: ElementRef,
	) {
		this.dataSource = new ArrayDataSource<MenuItem>(this.filteredItems$.asObservable());
	}

	ngOnChanges(changes: SimpleChanges): void {
		const usedItems = changes.items?.currentValue ?? this.items;
		if (usedItems?.length > 0) {
			const usedContext = changes.context?.currentValue ?? this.context;
			this.filteredItems = getFilteredItems(usedItems, usedContext);
		} else {
			this.filteredItems = [];
		}
		this.filteredItems$.next(this.filteredItems);
		this.finishedLoading = true;
	}

	trackByIndex = trackByIndexFunction;

	open = false;
	private justOpened = false;
	hasTitle = false;

	toggle($event: MouseEvent): void {
		if (!this.open) {
			this.hasTitle = !!this.menuTitle;
			this.open = true;
			this.justOpened = true;
			document.addEventListener("click", this.closeHandler);
			if (this.menuId) {
				// compute the height of the menu and register it
				// this is to handle a Chrome bug (see ST-34423)
				// note: the item height is assumed to be 40px here
				// this must be kept in sync with menu.scss (see .menu .mi)
				this.bodyHeightService.setHeight(
					this.menuId,
					($event.target! as HTMLElement).getBoundingClientRect().top +
						window.pageYOffset +
						this.filteredItems.length * 40 -
						20,
				);
			}
		} else {
			this.open = false;
			this.justOpened = false;
			document.removeEventListener("click", this.closeHandler);
			if (this.menuId) {
				this.bodyHeightService.unsetHeight(this.menuId);
			}
		}
	}

	ignoreClickOnMenuItem(element: ElementRef): boolean {
		return !!this.keepOpenOnMenuClick && element.nativeElement.closest("ul.menu")?.length > 0;
	}

	ignoreClickOnSwitch(element: ElementRef): boolean {
		return element.nativeElement.closest(".switch-item")
			? element.nativeElement.closest(".switch-item").length > 0
			: false;
	}

	hasChild(_: number, node: MenuItem): boolean {
		return node.expandable;
	}

	getParentNode(node: MenuItem): MenuItem {
		const nodeIndex = this.filteredItems.findIndex((item: MenuItem) => item.id === node.id);
		for (let i = nodeIndex - 1; i >= 0; i--) {
			if (this.filteredItems[i].level === node.level - 1) {
				return this.filteredItems[i];
			}
		}

		return null;
	}

	shouldRender(node: MenuItem): boolean {
		let parent = this.getParentNode(node);
		while (parent) {
			if (!parent.isExpanded) {
				return false;
			}
			parent = this.getParentNode(parent);
		}
		return true;
	}

	private closeHandler: EventListener = () => {
		if (
			this.open &&
			!this.justOpened &&
			!this.ignoreClickOnMenuItem(this.elRef) &&
			!this.ignoreClickOnSwitch(this.elRef)
		) {
			setTimeout(() => {
				this.open = false;
				document.removeEventListener("click", this.closeHandler);
				if (this.menuId) {
					this.bodyHeightService.unsetHeight(this.menuId);
				}

				if (this.changeDetector && !(this.changeDetector as ViewRef).destroyed) {
					this.changeDetector.detectChanges();
				}
			}, 0);
		}
		this.justOpened = false;
	};

	collapseMenu(filteredItems: MenuItem[]): boolean {
		if (this.noCollapse) {
			return false;
		}
		if (filteredItems.length > 1) {
			return false;
		}
		return filteredItems.length === 1 && !filteredItems[0].isSwitch;
	}

	isFunction(prop: unknown): boolean {
		return typeof prop === "function";
	}

	ngOnDestroy(): void {
		document.removeEventListener("click", this.closeHandler);
		if (this.open && this.menuId) {
			this.bodyHeightService.unsetHeight(this.menuId);
		}
	}
}

function getFilteredItems(items: MenuItem[] | undefined, context: unknown): MenuItem[] {
	let filteredItems: MenuItem[] = [];
	if (!items) {
		return filteredItems;
	}

	let lastItem: MenuItem;
	filteredItems = items.filter((item) => {
		let shouldBeAdded = true;

		if (item.isSeparator && lastItem?.isSeparator) {
			shouldBeAdded = false;
		}

		let isEnabled = true;

		if (!item.hasOwnProperty("disabled")) {
			isEnabled = true;
		} else if (typeof item.disabled === "boolean") {
			isEnabled = !item.disabled;
		} else if (typeof item.disabled === "function") {
			isEnabled = !item.disabled(context);
		} else if (!item.isSeparator) {
			isEnabled = false;
		}

		if (shouldBeAdded && isEnabled) {
			lastItem = item;
			return true;
		}
		return false;
	});

	// remove last item if it's delimiter
	if (filteredItems.length > 0 && filteredItems[filteredItems.length - 1].isSeparator) {
		filteredItems.splice(-1, 1);
	}

	// remove first item if it's delimiter
	if (filteredItems.length > 0 && filteredItems[0].isSeparator) {
		filteredItems.splice(0, 1);
	}

	// empty menu items when only separators are withing
	if (filteredItems.filter((item) => item.isSeparator).length === filteredItems.length) {
		return [];
	}
	return filteredItems;
}
