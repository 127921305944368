<article class="tailoring-wizard">
	<header class="page-header">
		<ul class="breadcrumb">
			<li>
				<a class="back" (click)="leaveWizard()">
					<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
				</a>
			</li>
		</ul>
		<h1 translate="process.tailoring.wizard.header"></h1>
	</header>

	<stages-media-switch>
		<div *stagesMediaCase="'screen and (min-width: 1600px)'">
			<div class="container row">
				<div class="container column col-8">
					<stages-card>
						<h5 translate="process.tailoring.wizard.title" *ngIf="!preview"></h5>
						<h5 translate="process.tailoring.wizard.title.preview" *ngIf="preview"></h5>
						<article class="separator">
							<div class="buttons-bottom">
								<button class="button lg" (click)="onClickPrevious()" [disabled]="isFirstQurery()">
									<span translate="back"></span>
								</button>
								<button class="button lg" (click)="onClickNext()" [disabled]="isUnAnswered() || preview">
									<span translate="next"></span>
								</button>
							</div>
						</article>
						<router-outlet> </router-outlet>
						<article>
							<div class="buttons-bottom">
								<div class="spacer"></div>
								<button class="button lg cancel" (click)="onClickCancel()"><span>Cancel</span></button>
								<button
									class="button lg"
									[disabled]="!answeredQueriesExists"
									(click)="onClickPreview()"
									*ngIf="!preview"
								>
									<span translate="process.tailoring.wizard.preview.title" *ngIf="!previewInProgress"></span>
									<stages-spin size="sm" *ngIf="previewInProgress"></stages-spin>
								</button>

								<button class="button lg prime" [disabled]="!answeredQueriesExists" (click)="onClickExecute()">
									<span translate="execute" *ngIf="!executeInProgress"></span>
									<stages-spin size="sm" *ngIf="executeInProgress"></stages-spin>
								</button>
							</div>
						</article>
					</stages-card>
				</div>
				<div class="container column col-4">
					<div>
						<stages-card>
							<h5 translate="process.tailoring.wizard.answeredqueries.title"></h5>
							<stages-process-tailoring-wizard-answered-queries> </stages-process-tailoring-wizard-answered-queries>
						</stages-card>
					</div>
				</div>
			</div>
		</div>
		<div *stagesMediaDefault>
			<div class="container row">
				<div class="container column col-12">
					<stages-card>
						<h5 translate="process.tailoring.wizard.title"></h5>
						<article class="separator">
							<div class="buttons-bottom">
								<button class="button lg" (click)="onClickPrevious()" [disabled]="isFirstQurery()">
									<span translate="back"></span>
								</button>
								<button class="button lg" (click)="onClickNext()" [disabled]="isUnAnswered() || preview">
									<span translate="next"></span>
								</button>
							</div>
						</article>
						<router-outlet> </router-outlet>
						<article class="separator">
							<div class="buttons-bottom">
								<div class="spacer"></div>
								<button class="button lg cancel" (click)="onClickCancel()"><span translate="cancel"></span></button>
								<button
									class="button lg"
									[disabled]="!answeredQueriesExists"
									(click)="onClickPreview()"
									*ngIf="!preview"
								>
									<span translate="process.tailoring.wizard.preview.title" *ngIf="!previewInProgress"></span>
									<stages-spin size="sm" *ngIf="previewInProgress"></stages-spin>
								</button>
								<button class="button lg prime" [disabled]="!answeredQueriesExists" (click)="onClickExecute()">
									<span translate="execute" *ngIf="!executeInProgress"></span>
									<stages-spin size="sm" *ngIf="executeInProgress"></stages-spin>
								</button>
							</div>
						</article>
					</stages-card>
					<stages-card>
						<h5 translate="process.tailoring.wizard.answeredqueries.title"></h5>
						<stages-process-tailoring-wizard-answered-queries> </stages-process-tailoring-wizard-answered-queries>
					</stages-card>
				</div>
			</div>
		</div>
	</stages-media-switch>
</article>
