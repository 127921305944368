import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Optional, Renderer2 } from "@angular/core";
import { COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

/**
 * This directive adds the missing trimming functionality in Angular2+ known from AngularJS.
 * It applies automatically in almost all cases.
 * If it should not apply automatically, you can opt-out with a CSS class "stages-trim-disable".
 * If it does not apply automatically but should apply (e.g. for "input" elements
 * with a "type" or "readonly" attribute which is set dynamically with property binding), you can manually add the directive "stagesTrim".
 *
 * This implementation is based on "ng-trim-value-accessor" (https://github.com/khashayar/ng-trim-value-accessor) which does not work well with our current "AutoGrowDirective" implementation.
 */
@Directive({
	providers: [
		{
			multi: true,
			provide: NG_VALUE_ACCESSOR,
			useExisting: TrimDirective,
		},
	],
	selector: `
        [stagesTrim],
        input:not([type=checkbox]):not([type=radio]):not([type=password]):not([readonly]):not(.stages-trim-disable)[formControlName],
        input:not([type=checkbox]):not([type=radio]):not([type=password]):not([readonly]):not(.stages-trim-disable)[formControl],
        input:not([type=checkbox]):not([type=radio]):not([type=password]):not([readonly]):not(.stages-trim-disable)[ngModel],
        textarea:not([readonly]):not(.stages-trim-disable)[formControlName],
        textarea:not([readonly]):not(.stages-trim-disable)[formControl],
        textarea:not([readonly]):not(.stages-trim-disable)[ngModel],
        :not([readonly]):not(.stages-trim-disable)[ngDefaultControl]
        `,
})
export class TrimDirective extends DefaultValueAccessor implements AfterViewInit {
	private afterViewInit = false;

	constructor(
		renderer: Renderer2,
		private elementRef: ElementRef,
		@Optional() @Inject(COMPOSITION_BUFFER_MODE) compositionMode: boolean,
	) {
		super(renderer, elementRef, compositionMode);
	}

	ngAfterViewInit(): void {
		this.afterViewInit = true;
	}

	@HostListener("input", ["$event.target.value"])
	onInput(value: string): void {
		this.onChange(this.trimIfNeeded(value));
	}

	@HostListener("blur", ["$event.target.value"])
	onBlur(value: string): void {
		// Woraround for a problem when both TrimDirective and AutoGrowDirective are on the same element!
		// Without this workaround a comment would not be added on the first "Add" click (only on the second click), when excecuting the following steps:
		// - go to a process element
		// - add comment starting with two newlines before the comment
		// - click (without doing anything else before) on "Add"
		const element: Element = this.elementRef.nativeElement;
		if (element.hasAttribute("stagesautogrow")) {
			return;
		}

		this.writeValue(this.trimIfNeeded(value));
	}

	private trimIfNeeded(value: string): string {
		return this.needsTrim() ? value.trim() : value;
	}

	private needsTrim(): boolean {
		// wait for property binding
		if (!this.afterViewInit) {
			return false;
		}

		const element = this.elementRef.nativeElement;
		if (element instanceof HTMLInputElement) {
			return !element.readOnly && ["checkbox", "radio", "password"].indexOf(element.type) < 0;
		} else if (element instanceof HTMLTextAreaElement) {
			return !element.readOnly;
		}
		return false;
	}
}
