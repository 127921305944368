import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { concatMap, delay, retryWhen } from "rxjs/operators";

const RETRY_STATUS_CODES = [0, 502];

@Injectable({
	providedIn: "root",
})
export class StagesHttpRetryInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			retryWhen<HttpEvent<unknown>>((errors) =>
				errors.pipe(
					concatMap((error, count) => mapError(request, error, count)),
					delay(100),
				),
			),
		);
	}
}

function mapError(
	request: HttpRequest<unknown>,
	error: HttpErrorResponse,
	count: number,
): Observable<HttpErrorResponse> {
	if (count <= 2 && RETRY_STATUS_CODES.includes(error.status) && request.method !== "POST") {
		console.log(`Got status ${error.status}. Retrying ${error.url}. Retry count ${count + 1}`);
		return of(error);
	}
	return throwError(error);
}
