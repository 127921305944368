import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm, ValidationErrors } from "@angular/forms";
import { DataViewComponent } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { KeyboardService } from "common/keyboard.service";

@Component({
	selector: "stages-editable",
	templateUrl: "./editable.component.html",
})
export class EditableComponent<T> implements OnInit, AfterViewChecked, OnDestroy {
	@Input()
	pattern?: string;

	@Input()
	patternMessageKey?: string;

	@Input()
	existsMessageKey?: string;

	@Input()
	reservedMessageKey?: string;

	@Input()
	reserved?: string[] = [];

	@Input()
	item!: T;

	@Input()
	property!: string;

	@Input()
	dataView!: DataViewComponent<T>;

	@Input()
	maxLength?: number | string;

	// This input is only used for editables that are used in data-views. The data-view has to distinguish
	// between editables for a group and editbales for group members
	@Input()
	isGroup: boolean = false;

	@ViewChild("input", { read: ElementRef })
	input!: ElementRef;

	@ViewChild("form")
	form!: NgForm;

	serverSideValidationErrors: ValidationErrors = {};

	value?: string;
	editable = false;
	setFocus = false;
	private mouseDown = false;

	constructor(private formService: FormService, private keyboardService: KeyboardService) {}

	ngOnInit(): void {
		this.dataView.addEditable(this);
		this.reset();
	}

	ngAfterViewChecked(): void {
		if (this.setFocus) {
			this.input.nativeElement.focus();
			this.setFocus = false;
			setTimeout(() => {
				(this.input.nativeElement as HTMLElement).scrollLeft = (this.input.nativeElement as HTMLElement).scrollWidth;
			});
		}
	}

	focus(): void {
		this.setFocus = true;
	}

	onKeyUp(event: KeyboardEvent): void {
		if (this.keyboardService.isReturnKeyPressed(event)) {
			this.enter();
		} else if (this.keyboardService.isEscapeKeyPressed(event)) {
			this.cancel();
		}
	}

	cancel(): void {
		this.reset();
		this.dataView.cancelRename(this.item, false, this.isGroup);
	}

	onMouseDown(): void {
		this.mouseDown = true;
	}

	blur(): void {
		if (!this.mouseDown) {
			this.reset();
			this.dataView.cancelRename(this.item, false, this.isGroup);
		}
		this.mouseDown = false;
	}

	async enter(): Promise<void> {
		if (this.value) {
			if (this.isValid()) {
				try {
					await this.dataView.commitRename(this.item, this.value, this.isGroup);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					(this.item as any)[this.property] = this.value;
					// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
				} catch (e) {
					this.formService.populateValidationErrorsOrThrow(this.serverSideValidationErrors, e);
				}
			}
		} else {
			this.editable = true;
		}
	}

	inputChanged(): void {
		this.serverSideValidationErrors = {};
	}

	private isValid(): boolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return this.value && this.value !== "" && this.value !== (this.item as any)[this.property] && this.form.valid
			? true
			: false;
	}

	private reset(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		this.value = (this.item as any)[this.property];
		if (this.form) {
			this.form.reset();
		}
	}

	ngOnDestroy(): void {
		this.reset();
		this.dataView.cancelRename(this.item, true, this.isGroup);
	}
}
