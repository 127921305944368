import { Component, Input, OnChanges } from "@angular/core";

@Component({
	selector: "stages-progress-bar",
	templateUrl: "./progress-bar.html",
})
export class ProgressBarComponent implements OnChanges {
	@Input()
	progress?: number;

	@Input()
	iconClass?: string;

	width?: string;

	ngOnChanges(): void {
		this.width = String(Math.round(this.progress ? this.progress * 100 : 0)) + "%";
	}
}
