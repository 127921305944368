import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
	templateUrl: "./compare-to-progress.component.html",
})
export class CompareToProgressComponent {
	paramMap$: Observable<ParamMap>;

	constructor(private route: ActivatedRoute, private router: Router) {
		this.paramMap$ = route.paramMap;
	}

	close(): void {
		this.next();
	}

	next(): void {
		const commands: unknown[] = [
			"workspace",
			this.route.snapshot.paramMap.get("forceSameWorkspace")
				? this.route.snapshot.paramMap.get("compareWorkspace")!
				: this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("specificProcessVersion")
				? this.route.snapshot.paramMap.get("specificProcessVersion")!
				: this.route.snapshot.paramMap.get("processVersion")!,
			{
				swid: this.route.snapshot.paramMap.get("compareWorkspace")!,
				spv: this.route.snapshot.paramMap.get("compareVersion")!,
				smode: "compare",
			},
			"process",
		];
		this.router.navigate(commands);
	}
}
