<stages-dialog2 #dialog>
	<stages-dialog-header></stages-dialog-header>
	<ng-container *ngIf="credentialsValid; else authenticate">
		<ng-container *ngIf="fileTemplateForm; else loadingOrError">
			<h1 translate="files.start.template.title"></h1>
			<article class="description">
				<p translate="files.template.info"></p>
			</article>
			<form class="content file-add-dialog" [formGroup]="fileTemplateForm" #formElement>
				<article class="form properties">
					<div class="input-container">
						<label for="files-template-templatesource" class="input-label" translate="files.template.templatesource">
						</label>
						<select id="files-template-templatesource" [formControlName]="'templateSelection'" class="select fullsize">
							<option *ngFor="let template of templateSelectionValues" [value]="template.fileId">
								{{ template.name }}
							</option>
						</select>
						<p *ngIf="templateSelectionValues.length < 1" translate="file.template.error.notemplateavailable"></p>
						<div class="info"></div>
					</div>
					<div class="input-container required">
						<label for="files-template-filename" class="input-label" translate="files.template.filename"></label>
						<input
							id="files-template-filename"
							stagesTrim
							[formControlName]="'fileName'"
							class="text fullsize"
							(input)="updateButtons()"
						/>
						<div class="label info">
							<div class="errors" *ngIf="(fileNameControl.dirty || fileNameControl.touched) && fileNameControl.errors">
								<div *ngIf="fileNameControl.errors['required']" class="error" translate="input.required"></div>
								<div *ngIf="fileNameControl.errors['serverSideError']" class="error">
									{{ fileNameControl.errors["serverSideError"] }}
								</div>
								<div
									*ngIf="fileNameControl.errors['maxlength']"
									class="error"
									translate="input.maxLength"
									[translateParams]="{ length: 255 }"
								></div>
							</div>
							<div class="maxlength">{{ fileNameControl.value.length }}/255</div>
						</div>
					</div>
				</article>
				<article class="form properties">
					<stages-attribute-edit
						[attributeTypes]="fileProperties.attributeTypes"
						[attributes]="fileProperties.attributes"
						[form]="fileTemplateForm"
					>
					</stages-attribute-edit>
				</article>
			</form>
			<footer>
				<div class="buttons-bottom">
					<div class="spacer"></div>
					<ng-container *ngIf="!fileIsAlreadyLinked">
						<button
							class="button sm cancel"
							ngClass="action.buttonClass"
							translate="cancel"
							(click)="closeCancel()"
							[disabled]="isLoading"
						></button>
						<button
							class="button sm save"
							ngClass="action.buttonClass"
							[disabled]="isButtonDisabled()"
							(click)="saveAndDownloadFile(fileProperties)"
						>
							<span translate="files.template.submit" *ngIf="!isLoading"></span>
							<stages-spin [size]="'sm'" *ngIf="isLoading"></stages-spin>
						</button>
					</ng-container>
					<ng-container *ngIf="fileIsAlreadyLinked">
						<button class="button sm" ngClass="action.buttonClass" (click)="closeCancel()">
							<span translate="files.template.ok"></span>
						</button>
					</ng-container>
				</div>
			</footer>
		</ng-container>
	</ng-container>
</stages-dialog2>

<ng-template #loadingOrError>
	<h1 *ngIf="loadingError$ | async as error" translate="error.title"></h1>
	<stages-popup-content>
		<stages-loading-error-embeddable
			[credentialsValid]="credentialsValid"
			[loadingError$]="loadingError$"
		></stages-loading-error-embeddable>
	</stages-popup-content>
	<stages-popup-footer [hasCancelButton]="false" [buttons]="buttons"> </stages-popup-footer>
</ng-template>

<ng-template #authenticate>
	<stages-files-auth-embeddable
		#auth
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		*ngIf="!credentialsValid && !oAuthUrl"
	></stages-files-auth-embeddable>
	<stages-files-oauth-embeddable-confirm
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		[authUrl]="oAuthUrl"
		[closeCallback]="close"
		*ngIf="!credentialsValid && oAuthUrl"
	></stages-files-oauth-embeddable-confirm>
</ng-template>
