import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	OnDestroy,
	Output,
	Renderer2,
	ViewChild,
} from "@angular/core";
import { UtilService } from "common/util.service";
import { fromEvent, Subscription } from "rxjs";

interface Position {
	x: number;
	y: number;
}

// amount of pixels to drag the image at least to recognize it as a "drag" instead of a click
const MIN_DISTANCE_OF_DRAG: number = 8;

@Component({
	selector: "stages-drag-scroll",
	templateUrl: "./drag-scroll.component.html",
	styleUrls: ["./drag-scroll.component.scss"],
})
export class DragScrollComponent implements OnDestroy, AfterViewInit {
	@Output()
	readonly dragStart = new EventEmitter<void>();

	@Output()
	readonly dragEnd = new EventEmitter<void>();

	private _scrollbarHidden = false;

	private _disabled = false;

	private _xDisabled = false;

	private _yDisabled = false;

	private _dragDisabled = false;

	private _isDragging = false;

	private browserSupported = false;

	private currentAnimationFrameId?: number;

	private currentHorizontalScroll: number = 0;

	/**
	 * Is the user currently pressing the element
	 */
	private mouseButtonPressed = false;

	private mousePosition: Position = { x: 0, y: 0 };

	private newMousePosition: Position = { x: 0, y: 0 };

	private displayType: string | null = "block";

	private dragStartPosition?: Position;

	elWidth: string | null = null;

	elHeight: string | null = null;

	@ViewChild("contentRef", { static: true })
	_contentRef: ElementRef | null = null;

	private scrollbarWidth: string | null = null;

	/**
	 * Is the user currently dragging the element
	 */
	get isDragging(): boolean {
		return this._isDragging;
	}

	/**
	 * Whether the scrollbar is hidden
	 */
	@Input("scrollbar-hidden")
	get scrollbarHidden(): boolean {
		return this._scrollbarHidden;
	}

	set scrollbarHidden(value: boolean) {
		this._scrollbarHidden = value;
	}

	/**
	 * Whether horizontally and vertically draging and scrolling is be disabled
	 */
	@Input("drag-scroll-disabled")
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: boolean) {
		this._disabled = value;
	}

	/**
	 * Whether horizontally dragging and scrolling is be disabled
	 */
	@Input("drag-scroll-x-disabled")
	get xDisabled(): boolean {
		return this._xDisabled;
	}

	set xDisabled(value: boolean) {
		this._xDisabled = value;
	}

	/**
	 * Whether vertically dragging and scrolling events is disabled
	 */
	@Input("drag-scroll-y-disabled")
	get yDisabled(): boolean {
		return this._yDisabled;
	}

	set yDisabled(value: boolean) {
		this._yDisabled = value;
	}

	@Input("drag-disabled")
	get dragDisabled(): boolean {
		return this._dragDisabled;
	}

	set dragDisabled(value: boolean) {
		this._dragDisabled = value;
	}

	private _onMouseMoveSubscription: Subscription | null = null;

	private _onMouseUpListener: (() => void) | null = null;

	private _onMouseDownUnlistener: (() => void) | null = null;

	private _onDragStartUnlistener: (() => void) | null = null;

	constructor(
		private readonly elementRef: ElementRef,
		private readonly renderer: Renderer2,
		private readonly zone: NgZone,
		private readonly utilService: UtilService,
	) {
		this.scrollbarWidth = `${this.getScrollbarWidth()}px`;
		this.browserSupported = !(this.utilService.browserIsIE() || this.utilService.browserIsEdge());
	}

	ngAfterViewInit(): void {
		if (this._contentRef && this.browserSupported) {
			// auto assign computed css
			this.renderer.setAttribute(this._contentRef.nativeElement, "drag-scroll", "true");

			this.displayType = window ? window.getComputedStyle(this.elementRef.nativeElement).display : "block";

			this.renderer.setStyle(this._contentRef.nativeElement, "display", this.displayType);
			this.renderer.setStyle(this._contentRef.nativeElement, "whiteSpace", "noWrap");

			// store ele width height for later user
			this.markElDimension();

			this.renderer.setStyle(this._contentRef.nativeElement, "width", this.elWidth);
			this.renderer.setStyle(this._contentRef.nativeElement, "height", this.elHeight);

			this._onMouseDownUnlistener = this.renderer.listen(
				this._contentRef.nativeElement,
				"mousedown",
				this.onMouseDownHandler.bind(this),
			);
			// prevent Firefox, IE and Edgefrom dragging images
			this._onDragStartUnlistener = this.renderer.listen(this.elementRef.nativeElement, "dragstart", (e) => {
				e.preventDefault();
				e.stopPropagation();
			});
			this.markElDimension();
			this.checkScrollbar();
		}
	}

	onMouseMoveHandler(event: MouseEvent): void {
		if (this.currentAnimationFrameId) {
			window.cancelAnimationFrame(this.currentAnimationFrameId);
		}
		this.currentAnimationFrameId = requestAnimationFrame(() => this.onMouseMove(event));
	}

	onMouseMove(event: MouseEvent): void {
		if (this.mouseButtonPressed && !this.disabled && this._contentRef && this.browserSupported) {
			// Workaround for prevent scroll stuck if browser lost focus
			// MouseEvent.buttons not support by Safari
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			if (!event.buttons && !event.which) {
				this.onMouseUpHandler(event);
			}
			this.detectDragging(event);

			this.newMousePosition.x = event.clientX;
			this.newMousePosition.y = event.clientY;

			// Drag X
			if (!this.xDisabled && !this.dragDisabled) {
				this.currentHorizontalScroll = this.currentHorizontalScroll - this.newMousePosition.x + this.mousePosition.x;
				this._contentRef.nativeElement.scrollLeft = this.currentHorizontalScroll;
				this.mousePosition.x = this.newMousePosition.x;
			}

			// Drag Y
			if (!this.yDisabled && !this.dragDisabled) {
				window.scrollBy(0, this.mousePosition.y - this.newMousePosition.y);
				this.mousePosition.y = this.newMousePosition.y;
			}
		}
	}

	private detectDragging(event: MouseEvent): void {
		if (this.dragStartPosition) {
			const mousePosition: Position = { x: event.screenX, y: event.screenY };
			const verticalDistance: number = Math.abs(this.dragStartPosition.y - mousePosition.y);
			const horizontalDistance: number = Math.abs(this.dragStartPosition.x - mousePosition.x);
			if (this.mouseButtonPressed && horizontalDistance + verticalDistance >= MIN_DISTANCE_OF_DRAG) {
				this.setIsDragging(true);
			}
		} else {
			this.dragStartPosition = { x: event.screenX, y: event.screenY };
		}
	}

	onMouseDownHandler(event: MouseEvent): void {
		const isTouchEvent = event.type === "touchstart";
		this.currentHorizontalScroll = this._contentRef!.nativeElement.scrollLeft;
		this._startGlobalListening(isTouchEvent);
		this.mouseButtonPressed = true;
		this.mousePosition = { x: event.clientX, y: event.clientY };
	}

	onMouseUpHandler(event: MouseEvent): void {
		if (this.mouseButtonPressed) {
			this.mouseButtonPressed = false;
		}
		this._stopGlobalListening();
		if (this.utilService.isSafari() && this.isDragging) {
			this.setIsDragging(false);
			stopEvent(event);
		}
	}

	@HostListener("click", ["$event"])
	onClick(event: MouseEvent | PointerEvent): void {
		if (this.browserSupported) {
			if (this.isDragging) {
				this.setIsDragging(false);
				stopEvent(event);
			}
			if (this.dragStartPosition) {
				this.dragStartPosition = undefined;
			}
		}
	}

	private setIsDragging(value: boolean): void {
		if (this._isDragging === value) {
			return;
		}

		this._isDragging = value;
		value ? this.dragStart.emit() : this.dragEnd.emit();
	}

	private _startGlobalListening(isTouchEvent: boolean): void {
		if (!this._onMouseMoveSubscription) {
			const eventName = isTouchEvent ? "touchmove" : "mousemove";
			this._onMouseMoveSubscription = fromEvent(document, eventName).subscribe((event) => {
				this.zone.runOutsideAngular(() => this.onMouseMoveHandler(event as MouseEvent));
			});
		}
		if (!this._onMouseUpListener) {
			const eventName = isTouchEvent ? "touchend" : "mouseup";
			this._onMouseUpListener = this.renderer.listen("document", eventName, this.onMouseUpHandler.bind(this));
		}
	}

	private _stopGlobalListening(): void {
		if (this._onMouseMoveSubscription) {
			this._onMouseMoveSubscription.unsubscribe();
			this._onMouseMoveSubscription = null;
		}
		if (this._onMouseUpListener) {
			this._onMouseUpListener();
			this._onMouseUpListener = null;
		}
	}

	private checkScrollbar(): void {
		if (this._contentRef) {
			if (this._contentRef.nativeElement.scrollWidth <= this._contentRef.nativeElement.clientWidth) {
				this.renderer.setStyle(this._contentRef.nativeElement, "height", "100%");
			} else {
				this.renderer.setStyle(this._contentRef.nativeElement, "height", `calc(100% + ${this.scrollbarWidth})`);
			}
			if (this._contentRef.nativeElement.scrollHeight <= this._contentRef.nativeElement.clientHeight) {
				this.renderer.setStyle(this._contentRef.nativeElement, "width", "100%");
			} else {
				this.renderer.setStyle(this._contentRef.nativeElement, "width", `calc(100% + ${this.scrollbarWidth})`);
			}
		}
	}

	private getScrollbarWidth(): number {
		/**
		 * Browser Scrollbar Widths (2016)
		 * OSX (Chrome, Safari, Firefox) - 15px
		 * Windows XP (IE7, Chrome, Firefox) - 17px
		 * Windows 7 (IE10, IE11, Chrome, Firefox) - 17px
		 * Windows 8.1 (IE11, Chrome, Firefox) - 17px
		 * Windows 10 (IE11, Chrome, Firefox) - 17px
		 * Windows 10 (Edge 12/13) - 12px
		 */
		const outer = this.renderer.createElement("div");
		this.renderer.setStyle(outer, "visibility", "hidden");
		this.renderer.setStyle(outer, "width", "100px");
		this.renderer.setStyle(outer, "msOverflowStyle", "scrollbar"); // needed for WinJS apps
		this.renderer.appendChild(document.body, outer);
		const widthNoScroll = outer.offsetWidth;
		// force scrollbars
		this.renderer.setStyle(outer, "overflow", "scroll");

		// add innerdiv
		const inner = this.renderer.createElement("div");
		this.renderer.setStyle(inner, "width", "100%");
		this.renderer.appendChild(outer, inner);

		const widthWithScroll = inner.offsetWidth;

		// remove divs
		this.renderer.removeChild(document.body, outer);

		/**
		 * Scrollbar width will be 0 on Mac OS with the
		 * default "Only show scrollbars when scrolling" setting (Yosemite and up).
		 * setting default width to 20;
		 */
		return widthNoScroll - widthWithScroll || 20;
	}

	private markElDimension(): void {
		this.elWidth =
			this.elementRef.nativeElement.style.width || String(this.elementRef.nativeElement.offsetWidth) + "px";
		this.elHeight =
			this.elementRef.nativeElement.style.height || String(this.elementRef.nativeElement.offsetHeight) + "px";
	}

	ngOnDestroy(): void {
		if (this._contentRef && this.browserSupported) {
			this.renderer.setAttribute(this._contentRef.nativeElement, "drag-scroll", "false");
		}
		if (this._onMouseDownUnlistener) {
			this._onMouseDownUnlistener();
			this._onMouseDownUnlistener = null;
		}
		if (this._onDragStartUnlistener) {
			this._onDragStartUnlistener();
			this._onDragStartUnlistener = null;
		}
	}
}

function stopEvent(event: Event): void {
	event.preventDefault();
	event.stopPropagation();
}
