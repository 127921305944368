import { Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { AuthService } from "files/auth/auth.service";
import { Subscription } from "rxjs";

@Component({
	selector: "stages-files-auth-dialog",
	templateUrl: "./files-auth-shared-template.html",
})
export class FilesAuthDialogComponent implements OnInit, OnDestroy {
	@HostBinding("class.confirm") customClass = true;

	form: FormGroup;

	showHeader = true;
	loading = false;
	authRetryFailed = false;

	private routerEventsSubscription?: Subscription;

	@Input()
	keys!: {
		ok: string;
		cancel: string;
		fileId: string | null;
		elementType: string | null;
		elementId: string | null;
		cmsTypeMessageKey: string;
		workspaceId: string;
		processVersion: string;
	};

	constructor(private router: Router, private dialog: DialogAdapter, private authService: AuthService) {
		this.form = new FormGroup({
			username: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
			password: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
		});
	}

	ngOnInit(): void {
		this.routerEventsSubscription = this.router.events.subscribe((s) => {
			if (s instanceof NavigationEnd) {
				this.cancel();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.routerEventsSubscription) {
			this.routerEventsSubscription.unsubscribe();
		}
	}

	getCmsTypeMessageKey(): string {
		return this.keys.cmsTypeMessageKey;
	}

	clearLoginError(): void {
		this.authRetryFailed = false;
	}

	cancel(): void {
		this.dialog.close("cancel");
	}

	ok(): void {
		this.authService
			.authenticate(
				this.form.get("username")!.value,
				this.form.get("password")!.value,
				this.keys.fileId,
				this.keys.elementType,
				this.keys.elementId,
				this.keys.workspaceId,
				this.keys.processVersion,
			)
			.then(
				() => {
					this.dialog.close("ok");
				},
				(errorResponse) => {
					this.loading = false;
					if (errorResponse.status === 901) {
						this.authRetryFailed = true;
					}
				},
			);
	}
}
