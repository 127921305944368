import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { MainService } from "core/main.service";

@Injectable({ providedIn: "root" })
export class ExternalLinkRedirectGuard implements CanActivate {
	constructor(private router: Router, private route: ActivatedRoute, private mainService: MainService) {}

	async canActivate(routeSnapshot: ActivatedRouteSnapshot): Promise<boolean> {
		const workspaceId = routeSnapshot.queryParamMap.get("workspaceId");
		const app = await this.mainService.getApplication("_vv");

		if (routeSnapshot.url[1].path === "task") {
			const action = routeSnapshot.data.action;
			const taskId = routeSnapshot.paramMap.get("taskId");

			return this.router.navigate(
				[
					"workspace",
					workspaceId ?? app.homeWorkspace.id,
					"_vv",
					{
						outlets: {
							primary: ["home"],
							popup: ["tasks", action, taskId],
						},
					},
				],
				{ relativeTo: this.route.parent },
			);
		} else if (
			routeSnapshot.url.length >= 2 &&
			routeSnapshot.url[1].path === "administration" &&
			routeSnapshot.url[2].path === "cleanup"
		) {
			return this.router.navigate(
				["workspace", app.homeWorkspace.id, "_vv", "administration", "cleanup", "archives", { page: 1 }],
				{ relativeTo: this.route.parent },
			);
		}

		throw new Error("Route not recognized");
	}
}
