import { Injectable } from "@angular/core";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { MainService } from "core/main.service";
import { ComplianceResource, SearchQueryService } from "core/stages-client";

type TypedId = stages.core.TypedId;
type TypedIdAssociationDetails = stages.core.model.TypedIdAssociationDetails;
type ViewableElement = stages.process.ViewableElement;

@Injectable({ providedIn: "root" })
export class ComplianceAssociationService {
	constructor(
		private readonly complianceResource: ComplianceResource,
		private readonly searchQueryService: SearchQueryService,
		private readonly mainService: MainService,
	) {}

	async deleteAssociations(associationIds: string[], workspaceId: string, pv: string): Promise<void> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.complianceResource.deleteAssociations(workspaceId, {
			associationIds: associationIds,
		});
	}

	async searchProcessElements(
		query: string,
		targetElementType: string,
		alreadyAssociatedElements: TypedId[],
		processModelWorkspaceId: string,
		referenceModelProcessVersion: string,
	): Promise<AssocSearchResult[]> {
		this.searchQueryService.setAdditionalHttpParamsForNextRequest({
			pv: referenceModelProcessVersion,
		});
		return this.searchQueryService.findComplianceAssociationTargetsInProcessModel(alreadyAssociatedElements, {
			query: query,
			type: targetElementType,
			processModelWorkspaceId: processModelWorkspaceId,
			processModelProcessVersion: this.mainService.secondaryProcessVersion,
		}) as Promise<AssocSearchResult[]>;
	}

	async createCommentOnlyAssociation(
		sourceId: string,
		sourceType: string,
		targetType: string,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<stages.compliance.ComplianceAssociation> {
		return this.complianceResource.createCommentOnlyAssociation(
			workspaceId,
			sourceType,
			sourceId,
			targetType,
			comment,
			{
				pv: pv,
			},
		);
	}

	async createAssociation(
		sourceElementId: string,
		sourceElementType: string,
		targetElementId: string,
		targetElementType: string,
	): Promise<stages.compliance.ComplianceAssociation> {
		return this.complianceResource.createAssociationFromReferenceModel(
			sourceElementType,
			sourceElementId,
			targetElementType,
			targetElementId,
		);
	}

	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.complianceResource.updateAssociationComment(currentWorkspaceId, associationId, comment);
	}

	async applyAssociationModifications(
		sourceElementType: string,
		sourceElementId: string,
		groupPath: string,
		added: TypedId[],
		removed: TypedId[],
		modifiedComments: TypedIdAssociationDetails[],
		workspaceId: string,
		pv: string,
	): Promise<ViewableElement> {
		return this.complianceResource.updateAssociationsFromReferenceModel(
			workspaceId,
			sourceElementType,
			sourceElementId,
			{
				addedElements: added,
				removedElements: removed,
				updatedElements: modifiedComments,
			},
		) as Promise<ViewableElement>;
	}
}
