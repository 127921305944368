import { Pipe, PipeTransform } from "@angular/core";
import { Selectable } from "common/selection/state/selectable.model";

type TypedId = stages.core.TypedId;
@Pipe({
	name: "isVisible",
})
export class IsVisibleFilterPipe implements PipeTransform {
	transform(
		elementToCheckForSelectedChildren: TypedId,
		selectedElements: Selectable[],
		allElements: Selectable[],
	): boolean {
		if (!selectedElements) {
			return false;
		}

		const elementToCheckFor = allElements.find(
			(e) =>
				e.id === elementToCheckForSelectedChildren.id &&
				e.type.toLocaleLowerCase() === elementToCheckForSelectedChildren.typeIdent.toLocaleLowerCase(),
		);

		if (elementToCheckFor) {
			if (elementToCheckFor.isSelected) {
				return true;
			}
			return hasTransitiveSelectedChildren(elementToCheckFor, allElements);
		}

		return false;
	}
}

function hasTransitiveSelectedChildren(elementToCountFor: Selectable, allElements: Selectable[]): boolean {
	let hasSelectedChildrens = false;

	if (elementToCountFor.isSelected) {
		return true;
	}

	const directChildren = allElements.filter((e) => {
		return (
			e.parentId === elementToCountFor.id && e.type.toLocaleLowerCase() === elementToCountFor.type.toLocaleLowerCase()
		);
	});

	if (directChildren.length === 0) {
		return false;
	} else {
		for (let i = 0; !hasSelectedChildrens && i < directChildren.length; i++) {
			hasSelectedChildrens = hasTransitiveSelectedChildren(directChildren[i], allElements);
		}
		return hasSelectedChildrens;
	}
}
