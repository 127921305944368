<ng-container *ngIf="processView$ | async as processView">
	<article class="activator-execution mulitple-groups">
		<p
			class="empty"
			translate="none"
			*ngIf="
				processView.enactmentActivatorExecutionContainer!.activators.length == 0 &&
				processView.enactmentActivatorExecutionContainer!.results.items.length == 0
			"
		></p>
		<ul
			class="grouped-list"
			*ngIf="
				processView.enactmentActivatorExecutionContainer!.activators.length > 0 ||
				processView.enactmentActivatorExecutionContainer!.results.items.length > 0
			"
		>
			<li class="group" *ngIf="processView.enactmentActivatorExecutionContainer!.activators.length > 0">
				<header class="group-header">
					<h3
						(click)="toggle(activatorsGroupIdent)"
						translate="process.enactment.activator.execution.activatorssection"
					></h3>
					<span class="counter">{{ processView.enactmentActivatorExecutionContainer!.activators.length }}</span>
					<stages-button-expand-collapse
						[classes]="'action menu-button'"
						[isCollapsed]="!isGroupOpen(activatorsGroupIdent)"
						(pressedButton)="toggle(activatorsGroupIdent)"
					></stages-button-expand-collapse>
				</header>
				<div class="relative-container">
					<div class="animation-container">
						<div
							#expandableActivatorsGroup
							[@expandCollapseFromTop]="getExpandCollapseFromTop(activatorsGroupIdent, expandableActivatorsGroup)"
							class="expandable"
							[ngClass]="isGroupOpen(activatorsGroupIdent) ? 'expand' : 'collapse'"
						>
							<div>
								<ul class="list dense">
									<li
										class="list-item"
										*ngFor="let activator of processView.enactmentActivatorExecutionContainer!.activators"
									>
										<a
											(click)="startExecution({ processView: processView, activatorView: activator })"
											class="label clickable"
											*ngIf="!isExecutionDisabled(processView)"
										>
											<i class="ico ico-enactment-enactmentactivator ico-enactment-executable"></i>
											<div class="text">
												<h4>{{ activator.label }}</h4>
											</div>
										</a>
										<div class="label" *ngIf="isExecutionDisabled(processView)">
											<i class="ico ico-enactment-enactmentactivator ico-enactment-executable"></i>
											<div class="text">
												<h4>{{ activator.label }}</h4>
											</div>
										</div>
										<i
											class="ico ico-check success-color result-icon"
											(click)="openResultsDialogFromActivator(activator.id)"
											*ngIf="
												isStatus('success', activator.id) &&
												processView.enactmentActivatorExecutionContainer!.isReadyForExecution
											"
										></i>
										<i
											class="ico ico-warning error-color result-icon"
											(click)="openResultsDialogFromActivator(activator.id)"
											*ngIf="
												isStatus('failure', activator.id) &&
												processView.enactmentActivatorExecutionContainer!.isReadyForExecution
											"
										></i>
										<i
											class="ico ico-warning warning-color result-icon"
											(click)="openResultsDialogFromActivator(activator.id)"
											*ngIf="
												isStatus('warnings', activator.id) &&
												processView.enactmentActivatorExecutionContainer!.isReadyForExecution
											"
										></i>
										<i
											class="ico ico-warning warning-color result-icon"
											(click)="openNotReadyDialog()"
											*ngIf="!processView.enactmentActivatorExecutionContainer!.isReadyForExecution"
										></i>
										<stages-menu
											menuId="executable-activator-menu"
											iconClasses="ico ico-more-vertical"
											openTo="left"
											[items]="menuItems"
											[context]="{ processView: processView, activatorView: activator }"
											[noCollapse]="false"
											class="action"
											[ngClass]="{ 'menu-ivisible': inProgressActivatorId }"
										></stages-menu>
										<stages-spin *ngIf="showSpinner(activator.id)" size="sm"></stages-spin>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>

			<li class="group" *ngIf="processView.enactmentActivatorExecutionContainer!.results.items.length > 0">
				<header class="group-header">
					<h3 (click)="toggle(resultsGroupIdent)" translate="process.enactment.activator.execution.resultssection"></h3>
					<span class="counter">{{ processView.enactmentActivatorExecutionContainer!.results.totalItemCount }}</span>
					<stages-button-expand-collapse
						[classes]="'action menu-button'"
						[isCollapsed]="isGroupOpen(resultsGroupIdent)"
						(pressedButton)="toggle(resultsGroupIdent)"
					></stages-button-expand-collapse>
				</header>
				<div class="relative-container">
					<div class="animation-container">
						<div
							#expandableResultsGroup
							[@expandCollapseFromTop]="getExpandCollapseFromTop(resultsGroupIdent, expandableResultsGroup)"
							class="expandable"
							[ngClass]="isGroupOpen(resultsGroupIdent) ? 'expand' : 'collapse'"
						>
							<div>
								<ul class="list dense">
									<li
										class="list-item"
										*ngFor="let result of processView.enactmentActivatorExecutionContainer?.results?.items"
									>
										<a class="label clickable" (click)="openResultsDialogFromList(result)">
											<i class="ico ico-warning warning-color" *ngIf="result.status === 'warnings'"></i>
											<i class="ico ico-warning error-color" *ngIf="result.status === 'failure'"></i>
											<i class="ico ico-check success-color" *ngIf="result.status === 'success'"></i>
											<div class="text">
												<h4>{{ result.title }}</h4>
											</div>
										</a>
									</li>
									<li
										class="list-item"
										*ngIf="hasMoreResults(processView.enactmentActivatorExecutionContainer!.results)"
									>
										<a
											class="label clickable"
											(click)="loadMoreResults(processView.enactmentActivatorExecutionContainer!.results)"
										>
											<div class="text center">
												<span class="show-more center" translate="more"></span>
											</div>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</article>
</ng-container>
