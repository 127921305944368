import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { FilesResource } from "core/stages-client";

/**
 * Parses the query parameter "downloadUrl" referencing a file download and
 * returns the route for the process element containing the referenced file
 * with an overlaying download popup.
 *
 * The parameters for this route are retrieved by calling a secured REST request.
 * Initally the user is normally not authenticated, because
 * otherwise the server would not have redirected the user to this guard.
 * Therefore, the non-authenticated user is redirected to the login page (AuthenticationComponent) or
 * triggers an auto-login (e.g. SAML) by StagesHttpInterceptor's error handling when the secured REST request is called.
 * After successful authentication the user will be redirected to this guard again, the secured REST now returns successfully
 * the information about the process element and the route can be constructed and returned.
 */
@Injectable({ providedIn: "root" })
export class DownloadCanActivateGuard implements CanActivate {
	constructor(private readonly router: Router, private readonly filesResource: FilesResource) {}

	async canActivate(route: ActivatedRouteSnapshot): Promise<UrlTree> {
		const fileId = route.queryParamMap.get("fileId");
		const workspaceId = route.queryParamMap.get("workspaceId");
		//revisionIdentifier maybe null for HEAD revision
		const revisionIdentifier = route.queryParamMap.get("revisionIdentifier");
		if (fileId === null) {
			// "downloadUrl" should always be set correctly, but if it not, then the best thing to do is redirecting to element-not-found-error route.
			return this.router.createUrlTree([
				"workspace",
				1,
				"_vv",
				"error",
				{
					errorType: "http.notFound",
					incidentId: new Date().getTime(),
					isReportable: false,
					isTemporary: false,
				},
			]);
		}
		const elementLink = await this.filesResource.getProcessElementLink(fileId, {
			workspaceId: workspaceId,
		});

		// example URL:
		// /workspace/99/_vv/(process/artifact/_uFf5MAp7FrCzOee2tRIbdQ//popup:download)?downloadUrl=http:%2F%2Flocalhost:8080%2Fstages%2Fapp%2Ffiles%2F2054%3FworkspaceId%3D99
		return this.router.createUrlTree(
			[
				"workspace",
				elementLink.workspaceId,
				elementLink.pv,
				{
					outlets: {
						primary: ["process", elementLink.typeIdent, elementLink.id],
						popup: ["files", "download"],
					},
				},
			],
			{
				queryParams: {
					fileId: fileId,
					revisionIdentifier: revisionIdentifier,
				},
			},
		);
	}
}
