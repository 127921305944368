import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "app/app-routing.module";
import { AppComponent } from "app/app.component";
import { MainComponent } from "app/main.component";
import { StagesChartModule } from "charts/chart.module";
import { StagesCommonModule } from "common/common.module";
import { StagesCoreModule } from "core/core.module";
import { StagesMissingTranslationHandler } from "core/translate/missing-translation.handler";
import { StagesTranslateParser } from "core/translate/stages-translate.parser";
import { StagesDashboardModule } from "dashboard/dashboard.module";
import { StagesFilesModule } from "files/files.module";
import { StagesNavigationModule } from "navigation/navigation.module";
import { StagesPrintModule } from "print/print.module";
import { StagesSearchModule } from "search/search.module";
import { StagesUserModule } from "user/user.module";

export function translateHttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, "app/messageResources/", "");
}

@NgModule({
	declarations: [AppComponent, MainComponent],
	imports: [
		RouterModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		StagesCoreModule.forRoot(),
		StagesNavigationModule,
		StagesCommonModule,
		// StagesInstallModule, // lazy loaded
		// StagesProcessModule, // lazy loaded
		// AdministrationModule, // lazy loaded
		// StagesManagementModule, // lazy loaded
		StagesPrintModule,
		StagesFilesModule,
		StagesDashboardModule,
		//StagesReportsModule,
		StagesSearchModule,
		StagesUserModule,
		StagesChartModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateHttpLoaderFactory,
				deps: [HttpClient],
			},
			parser: {
				provide: TranslateParser,
				useExisting: StagesTranslateParser,
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useExisting: StagesMissingTranslationHandler,
			},
			useDefaultLang: false,
		}),
	],
	exports: [RouterModule, TranslateModule],
	bootstrap: [AppComponent],
})
export class AppModule {}
