import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ViewService } from "core/view.service";
import { FileContainer, FileSource } from "files/files.component";
import { FileService } from "files/files.service";
import { lastValueFrom, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
	selector: "stages-process-element-files",
	templateUrl: "./process-files.component.html",
})
export class FilesComponent implements OnInit {
	fileSource$!: Observable<FileSource>;
	fileContainer$!: Observable<FileContainer>;

	@Input()
	translateNone?: string;

	@Input()
	layout?: string;

	@Input()
	showStartOptions?: boolean;

	@Input()
	path: string = ".";

	constructor(private route: ActivatedRoute, private viewService: ViewService, private fileService: FileService) {}

	ngOnInit(): void {
		this.fileContainer$ = this.viewService
			.awaitSelfElementObservable()
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			.pipe(map((self) => (self as any).fileContainer[this.path]));
		this.fileSource$ = this.viewService.awaitSelfElementObservable().pipe(
			map((self) => {
				const fileSource: FileSource = {
					getFilesFunction: async () => {
						return lastValueFrom(
							this.fileService
								.getFileContainer(
									this.route.snapshot.paramMap.get("workspaceId")!,
									self.type.ident,
									self.id,
									this.route.snapshot.paramMap.get("workspaceId")!,
									this.route.snapshot.paramMap.get("processVersion")!,
									this.path,
									true,
								)
								.pipe(
									map((fileContainer) => fileContainer.files as stages.file.File[]),
									tap((files) =>
										notifyDiagramForFileStateChange(files, getFilesForPath(self, this.path), this.viewService),
									),
								),
						);
					},
					pollFilesFunction: async () => {
						return this.fileService.getLatestJobStatus(
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("processVersion")!,
							self.type.ident,
							self.id,
							undefined,
							this.path,
						);
					},
				};
				return fileSource;
			}),
		);
	}
}

function getFilesForPath(self: stages.process.ProcessView, filePath: string): stages.file.File[] {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	return (self as any).fileContainer[filePath].files as stages.file.File[];
}

function notifyDiagramForFileStateChange(
	newFiles: stages.file.File[],
	oldFiles: stages.file.File[],
	viewService: ViewService,
): void {
	if (oldFiles.length !== newFiles.length) {
		viewService.notifyModified();
		return;
	}
	for (const oldFile of oldFiles) {
		const newFile = newFiles.find((f) => f.id === oldFile.id);
		if (!!newFile && newFile.stateIdent !== oldFile.stateIdent) {
			viewService.notifyModified();
			break;
		}
	}
}
