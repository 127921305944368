import { TranslateService } from "@ngx-translate/core";
import { AssociationSource } from "common/associations/association-browser.service";
import { AssociationTarget } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { SearchErrorWithResults, SearchQuery } from "common/autoComplete/search-query";
import { lastValueFrom } from "rxjs";

export class AssocSearchResult {
	isNew = false;
	isRemote = false;
	id?: string; // undefined for virtual search results ("Create new ...")
	identity?: string; // undefined for virtual search results ("Create new ...")
	isComment = false;
	name: string;
	label: string;
	type!: { ident: string; subtypeIdent: string | null };
	parentId?: string;
	workspaceId!: string;
	tailored = false;
	dependentElementsContainer = false;
	dependent = false;
	firstDependentSubtypeName?: string;
	firstDependentSubtypeIdent?: string;

	constructor(name: string) {
		this.name = name;
		this.label = name;
	}

	static newVirtualElement(
		name: string,
		targetElementType: string,
		targetElementSubtypes: string[],
		dependentElementContainer?: AssocSearchResult,
		targetElementParentId?: string,
	): AssocSearchResult {
		const virtualItem = new AssocSearchResult(name);
		virtualItem.type = {
			ident: targetElementType,
			subtypeIdent: dependentElementContainer?.firstDependentSubtypeIdent
				? dependentElementContainer.firstDependentSubtypeIdent
				: AssocSearchResult.firstSubtypeIdent(targetElementSubtypes),
		};
		virtualItem.isNew = true;
		virtualItem.parentId = targetElementParentId;
		virtualItem.dependent = dependentElementContainer !== undefined;
		return virtualItem;
	}

	static newVirtualCommentOnlyAssociation(
		comment: string,
		targetElementType: string,
		targetElementSubtypes: string[],
	): AssocSearchResult {
		const virtualItem = new AssocSearchResult(comment);
		virtualItem.isComment = true;
		virtualItem.type = {
			ident: targetElementType,
			subtypeIdent: AssocSearchResult.firstSubtypeIdent(targetElementSubtypes),
		};
		virtualItem.isNew = true;
		virtualItem.tailored = false;
		return virtualItem;
	}

	static firstSubtypeIdent(subtypeIdents?: string[]): string | null {
		return !subtypeIdents || subtypeIdents.length === 0 ? null : subtypeIdents[0];
	}
}

export class AssociationTargetSearchQuery<T extends AssociationTarget> implements SearchQuery<AssocSearchResult> {
	constructor(
		private translateService: TranslateService,
		private associationStore: AssociationStore<AssociationSource<T>, T>,
		private targetElementType: string,
		private targetElementSubtypes: string[],
		private targetElementsToIgnore: T[],
		private currentWorkspaceId: string,
		private searchWorkspaceId: string,
		private processVersion: string,
		private isCreateElementAllowed: boolean,
		private isCreateCommentOnlyAssociationAllowed: boolean,
		private restrictAssociateDependentElementTypes?: string[],
		private dependentElementContainer?: AssocSearchResult,
		private targetElementParentId?: string,
		private targetProcessIds?: string[],
	) {}

	async run(searchTerm: string): Promise<AssocSearchResult[]> {
		try {
			const results = this.dependentElementContainer
				? (
						await this.associationStore.searchDependentProcessElements(
							searchTerm,
							this.targetElementType,
							this.dependentElementContainer.identity!,
							this.targetElementsToIgnore,
							this.currentWorkspaceId,
							this.processVersion,
							this.targetProcessIds,
						)
				  ).filter(
						(res) =>
							this.restrictAssociateDependentElementTypes === undefined ||
							(res.type.subtypeIdent && this.restrictAssociateDependentElementTypes.includes(res.type.subtypeIdent)),
				  )
				: await this.associationStore.searchProcessElements(
						searchTerm,
						this.targetElementType,
						this.targetElementSubtypes,
						this.targetElementsToIgnore.filter(
							(t) =>
								!allowDependentElements(this.restrictAssociateDependentElementTypes) || !t.dependentElementsContainer,
						),
						this.currentWorkspaceId,
						this.searchWorkspaceId,
						this.processVersion,
						this.targetProcessIds,
				  );

			let items: AssocSearchResult[] = [];
			this.addCreateItems(searchTerm, items);
			if (this.dependentElementContainer && searchTerm.length === 0) {
				const shouldBeIgnored = this.targetElementsToIgnore.find((t) => t.id === this.dependentElementContainer?.id);
				if (!shouldBeIgnored) {
					const dependentElementsContainerPlaceHolder = new AssocSearchResult("");
					Object.assign(dependentElementsContainerPlaceHolder, this.dependentElementContainer);
					const label = await lastValueFrom(
						this.translateService.get("process.element.quickAssign.directly", {
							name: this.dependentElementContainer.label,
						}),
					);
					dependentElementsContainerPlaceHolder.name = label;
					dependentElementsContainerPlaceHolder.label = label;
					dependentElementsContainerPlaceHolder.dependentElementsContainer = false; // needs to be false that the element is associated on selection and the flyout is closed
					items.push(dependentElementsContainerPlaceHolder);
				}
			}
			items = [...items, ...this.groupByLocalOrRemote(results)];
			return items;
		} catch (e: unknown) {
			const items: AssocSearchResult[] = [];
			if (this.isCreateElementAllowed || this.isCreateCommentOnlyAssociationAllowed) {
				this.addCreateItems(searchTerm, items);
				throw new SearchErrorWithResults(items, e);
			}
			throw e;
		}
	}

	private addCreateItems(searchTerm: string, items: AssocSearchResult[]): void {
		if (this.isCreateElementAllowed && searchTerm.length > 0) {
			items.push(
				AssocSearchResult.newVirtualElement(
					searchTerm,
					this.targetElementType,
					this.targetElementSubtypes,
					this.dependentElementContainer,
					this.targetElementParentId,
				),
			);
		}
		if (this.isCreateCommentOnlyAssociationAllowed && searchTerm.length > 0 && !this.dependentElementContainer) {
			items.push(
				AssocSearchResult.newVirtualCommentOnlyAssociation(
					searchTerm,
					this.targetElementType,
					this.targetElementSubtypes,
				),
			);
		}
	}

	groupByLocalOrRemote(results: AssocSearchResult[]): AssocSearchResult[] {
		const local = results
			.filter((result) => result.workspaceId === this.currentWorkspaceId)
			.map((result) => {
				return { ...result, isRemote: false };
			});
		const remote = results
			.filter((result) => result.workspaceId !== this.currentWorkspaceId)
			.map((result) => {
				return { ...result, isRemote: true };
			});
		return [...local, ...remote];
	}
}

function allowDependentElements(restrictions: string[] | undefined): boolean {
	if (!restrictions) {
		return true;
	}
	return restrictions.length > 0;
}
