import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppLoadService } from "app/app-load.service";
import { MutexService } from "common/concurrency/mutex.service";
import { ProcessVersionService } from "common/version/process-version.service";
import { AuthenticationService } from "core/authentication.service";
import { hasProperty } from "core/functions";
import { MainService } from "core/main.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-authentication",
	templateUrl: "./authentication.component.html",
	styleUrls: ["authentication.component.scss"],
})
export class AuthenticationComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	cookieEnabled?: boolean;
	basicAuthenticationEnabled?: boolean;
	maintenanceMessage!: string;
	licenseTimeoutSoon?: boolean;
	systemLicenseTimeout?: boolean;
	licenseTimeoutSoonMessage?: boolean;

	username!: string;
	password!: string;
	rememberUser!: boolean;
	errors!: string;
	securityException!: string;

	bootstrapProperties!: pkit.rest.BootstrapProperties;

	helpDeskInformation?: string;
	helpDeskLinkText?: string;
	helpDeskLinkHref?: string;

	showErrorDetails: boolean = false;

	@ViewChild("passwordInput", { read: ElementRef, static: false })
	passwordInput?: ElementRef;

	bannerEnabled: boolean = false;

	constructor(
		private authService: AuthenticationService,
		private mainService: MainService,
		private mutexService: MutexService,
		private route: ActivatedRoute,
		private router: Router,
		private appLoadService: AppLoadService,
		private ngZone: NgZone,
		private processVersionService: ProcessVersionService,
		private translateService: TranslateService,
	) {
		this.setUpHelpdeskInformation();
	}

	async ngOnInit(): Promise<void> {
		this.errors = this.route.snapshot.queryParamMap.get("error") || "";
		this.securityException = this.getSecurityException();
		/* Fetching basic login properties. */
		this.appLoadService.clearBootstrapProperties();
		this.bootstrapProperties = await this.appLoadService.resolveBootstrapProperties();

		this.cookieEnabled = this.bootstrapProperties.cookieLoginEnabled;
		this.basicAuthenticationEnabled = this.bootstrapProperties.basicAuthenticationEnabled;
		this.maintenanceMessage = this.bootstrapProperties.maintenanceMessage;

		this.licenseTimeoutSoon = this.bootstrapProperties.licenseTimeoutSoon;
		this.systemLicenseTimeout = this.bootstrapProperties.systemLicenseTimeout;

		this.bannerEnabled = this.bootstrapProperties.bannerEnabled;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	getSecurityException(): string {
		return this.route.snapshot.queryParamMap.get("securityException") || "";
	}

	passwordAuthentication(): void {
		if (!!this.username || !!this.password) {
			this.passwordInput?.nativeElement.focus();
			this.mutexService.invoke("login", async () => {
				const promise = this.authService
					.passwordAuthentication(this.username, this.password, this.rememberUser)
					.then(this.authSuccess, this.authFail);
				return promise;
			});
		} else {
			/* will trigger autologin */
			this.authService.invalidateAllCaches();
			this.changeStateOnAck();
		}
	}

	setUpHelpdeskInformation(): void {
		const translatedInformation$: Observable<string> = this.translateService.get("login.help.information");
		const translatedLinkText$: Observable<string> = this.translateService.get("login.help.link.text");
		const translatedLinkHref$: Observable<string> = this.translateService.get("login.help.link.href");

		translatedInformation$.pipe(takeUntil(this.destroy$)).subscribe((information) => {
			this.helpDeskInformation = this.isPropertyDefined(information) ? information : undefined;
		});

		translatedLinkText$.pipe(takeUntil(this.destroy$)).subscribe((linkText) => {
			this.helpDeskLinkText = this.isPropertyDefined(linkText) ? linkText : undefined;
		});

		translatedLinkHref$.pipe(takeUntil(this.destroy$)).subscribe((linkHref) => {
			this.helpDeskLinkHref = this.isPropertyDefined(linkHref) ? linkHref : undefined;
		});
	}

	getHrefForExternalLink(linkHref: string): string {
		return linkHref.indexOf("://") > 0 ? linkHref : `http://${linkHref}`;
	}

	hasError(maintenanceMessage: string, securityException: string, licenseTimeoutSoon?: boolean): boolean {
		return !!maintenanceMessage || !!securityException || !!licenseTimeoutSoon;
	}

	showSingleError(maintenanceMessage: string, securityException: string, licenseTimeoutSoon?: boolean): boolean {
		if (this.hasError(maintenanceMessage, securityException, licenseTimeoutSoon)) {
			//has only MaintenanceMessage
			if (!!maintenanceMessage && !securityException && !licenseTimeoutSoon) {
				return true;
			}

			//has only SecurityException
			if (!!securityException && !maintenanceMessage && !licenseTimeoutSoon) {
				return true;
			}

			//has only License problems
			if (licenseTimeoutSoon && !maintenanceMessage && !securityException) {
				return true;
			}
		}

		return false;
	}

	showMultipleErrors(maintenanceMessage: string, securityException: string, licenseTimeoutSoon?: boolean): boolean {
		return (
			this.hasError(maintenanceMessage, securityException, licenseTimeoutSoon) &&
			!this.showSingleError(maintenanceMessage, securityException, licenseTimeoutSoon)
		);
	}

	toggleErrorDetails(): void {
		this.showErrorDetails = !this.showErrorDetails;
	}

	clearErrors(): void {
		this.errors = "";
	}
	private authSuccess = (): void => {
		this.changeStateOnAck();
	};

	private authFail = (response: unknown): void => {
		this.securityException = this.getSecurityException();
		if (
			response &&
			hasProperty(response, "error") &&
			response.error instanceof Array &&
			response.error[0] &&
			response.error[0].keys &&
			response.error[0].keys[0]
		) {
			this.errors = response.error[0].keys[0];
		} else {
			throw response;
		}
	};

	private changeStateOnAck(): void {
		this.mainService.onLoginCallback();
		if (this.route.snapshot.queryParamMap.has("redirectTo")) {
			// Error 34300, without zone an empty page will be displayed
			this.ngZone.run(async () => this.router.navigateByUrl(this.route.snapshot.queryParamMap.get("redirectTo")!));
		} else {
			this.mainService.getApplication("_vv").then(async (application) => {
				const pv = await this.processVersionService.getInitialProcessVersionIdentifier();
				this.router.navigate(["workspace", application.homeWorkspace.id, pv, "home"]);
			});
		}
	}

	private isPropertyDefined(value: string): boolean {
		return !value.startsWith("???");
	}
}
