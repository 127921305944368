import { Injectable } from "@angular/core";

export type ToolbarPosition = "bottom" | "middle" | "top";

function positionToolbarTop(position: ToolbarPosition, container: JQuery, toolbar: JQuery): ToolbarPosition {
	if (position !== "top") {
		container.css("padding-top", "").css("padding-bottom", "");
		toolbar.css("max-width", "").removeClass("fixed").removeClass("bottom");
	}
	return "top";
}

function positionToolbarMiddle(
	position: ToolbarPosition,
	container: JQuery,
	toolbar: JQuery,
	toolbarHeight: number,
): ToolbarPosition {
	toolbar.css("max-width", container.width()!);
	if (position !== "middle") {
		if (position === "top") {
			container.css("padding-top", toolbarHeight);
		}
		container.css("padding-bottom", "");
		toolbar.removeClass("bottom").addClass("fixed");
	}
	return "middle";
}

function positionToolbarBottom(
	position: ToolbarPosition,
	container: JQuery,
	toolbar: JQuery,
	toolbarHeight: number,
): ToolbarPosition {
	if (position !== "bottom") {
		container.css("padding-bottom", toolbarHeight);
		toolbar.css("max-width", "").removeClass("fixed").addClass("bottom");
	}
	return "bottom";
}

@Injectable({ providedIn: "root" })
export class RepositionToolbarService {
	repositionToolbar(position: ToolbarPosition, panelId: string, toolbarId: string): ToolbarPosition {
		const panel = $("#" + panelId);
		const toolbar = $("#" + toolbarId);

		const searchBox = $("#search");
		const searchBoxBottom = searchBox.offset()!.top + searchBox.outerHeight()!;

		const docViewTop = $(window).scrollTop()!;
		const docViewBottom = docViewTop + $(window).height()!;

		if (panel.offset()) {
			const editorPanelTop = panel.offset()!.top;
			const editorPanelBottom = editorPanelTop + panel.outerHeight()!;
			const toolbarHeight = toolbar.innerHeight()!;

			if (searchBoxBottom <= editorPanelTop) {
				return positionToolbarTop(position, panel, toolbar);
			} else if (editorPanelBottom < docViewBottom) {
				return positionToolbarBottom(position, panel, toolbar, toolbarHeight);
			} else if (position === "top" || editorPanelBottom - docViewBottom > toolbarHeight) {
				return positionToolbarMiddle(position, panel, toolbar, toolbarHeight);
			}
		}
		return position;
	}
}
