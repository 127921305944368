import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";
import SortStrategy = stages.core.sort.SortStrategy;

export type DependentElement = stages.process.ViewableDependentElement;

@Injectable({ providedIn: "root" })
export class DependentElementsManagementService {
	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	async addDependentElement(
		workspaceId: string,
		parentType: string,
		parentId: string,
		newChild: { subtype: string; name: string },
	): Promise<DependentElement> {
		const result = await this.processElementsResource.createChild(workspaceId, parentType, parentId, {
			subtype: newChild.subtype,
			name: newChild.name,
			dependent: true,
		});
		return result as DependentElement;
	}

	async editDependentElement(
		dependentElement: DependentElement,
		workspaceId: string,
		processVersion: string,
	): Promise<stages.core.format.DisplayDescription> {
		return this.processElementsResource.saveDescription(
			workspaceId,
			dependentElement.type.ident,
			dependentElement.identity,
			dependentElement.description,
			{ pv: processVersion },
		);
	}

	async deleteDependentElement(workspaceId: string, dependentElement: DependentElement): Promise<void> {
		return this.deleteDependentElements(workspaceId, Array.of(dependentElement));
	}

	async deleteDependentElements(workspaceId: string, dependentElements: DependentElement[]): Promise<void> {
		const elementIds = dependentElements.map((element) => {
			return {
				typeIdent: element.type.ident,
				id: element.id,
			};
		});
		await this.processElementsResource.delete(workspaceId, elementIds);
	}

	async rearrangeDependentElements(
		workspaceId: string,
		parentType: string,
		parentId: string,
		customSortedDependentElements: DependentElement[],
		sortStrategy: SortStrategy,
	): Promise<void> {
		const sortedIds: string[] = customSortedDependentElements.map((element) => element.id);
		await this.processElementsResource.rearrange(workspaceId, parentType, parentId, sortedIds, {
			alphabetical: sortStrategy === "ALPHABETICAL",
		});
	}

	async renameDependentElement(
		workspaceId: string,
		processVersion: string,
		editedDependentElement: DependentElement,
	): Promise<void> {
		await this.processElementsResource.renameProcessElement(
			workspaceId,
			editedDependentElement.type.ident,
			editedDependentElement.identity,
			{ name: editedDependentElement.label, pv: processVersion },
		);
	}
}
