<article class="card">
	<div class="support">
		<div class="header-picture"><i class="ico ico-support"></i></div>
		<div class="main-text-container">
			<div class="text">
				<h5 translate="dashboard.support.title"></h5>
				<p translate="dashboard.support.text"></p>
				<p>&nbsp;</p>
				<p translate="dashboard.support.euroasien"></p>
				<p translate="dashboard.support.us"></p>
				<p>
					<a
						class="support_link_main"
						href="{{ 'dashboard.support.email.href' | translate }}"
						translate="dashboard.support.email.name"
					></a>
				</p>
			</div>
		</div>
	</div>
	<div class="custom-support" *ngIf="supportDetails.length > 0">
		<div class="custom-text-container">
			<div class="text">
				<h5 translate="dashboard.support.title.custom"></h5>
				<p>&nbsp;</p>
				<ng-container *ngFor="let detail of supportDetails; let i = index">
					<ng-container *ngIf="!detail.link && detail.name">
						<p>{{ detail.name }}</p>
					</ng-container>
					<ng-container *ngIf="detail.link">
						<p>
							<a *ngIf="detail.link" href="{{ detail.link }}" [target]="getTabTarget(detail.newtab)">
								<span class="support_dashboard_link support_dashboard_link_{{ detail.id }}" *ngIf="detail.name">{{
									detail.name
								}}</span>
								<span class="support_dashboard_link support_dashboard_link_{{ detail.id }}" *ngIf="!detail.name">{{
									detail.link
								}}</span>
							</a>
						</p>
					</ng-container>
					<ng-container *ngIf="detail.type === 'empty'">
						<p>&nbsp;</p>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</article>
