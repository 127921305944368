<div class="toolbar visible-gt-xsmall">
	<!-- item.isMousedown can be used to determine in blur event handler, that a specific item has just been clicked -->
	<ng-container *ngFor="let item of $any(items)">
		<ng-container
			*ngTemplateOutlet="!item.dropdownItems ? singleButtonTemplate : buttonWithDropdown; context: { $implicit: item }"
		></ng-container>
	</ng-container>
</div>

<ng-template #buttonWithDropdown let-item>
	<div class="button-with-dropdown" [ngClass]="{ 'flex-end': !isIE }">
		<ng-container *ngTemplateOutlet="singleButtonTemplate; context: { $implicit: item }"></ng-container>
		<ng-container *ngTemplateOutlet="dropdownButton; context: { $implicit: item }"></ng-container>
	</div>
</ng-template>

<ng-template #singleButtonTemplate let-item>
	<button
		class="button sm"
		[ngClass]="item.class"
		(click)="item.isMousedown = false; item.onClick()"
		(mousedown)="item.isMousedown = true"
		*ngIf="!item.disabled()"
	>
		<i [ngClass]="item.iconClass"></i>
		<span title="{{ item.messageKey | translate }}" *ngIf="!item.untranslated" [translate]="item.messageKey"></span>
		<span title="{{ item.messageKey }}" *ngIf="item.untranslated">{{ item.messageKey }}</span>
	</button>
</ng-template>

<ng-template #dropdownButton let-item>
	<stages-menu
		*ngIf="!item.disabled()"
		menuId="add-dropdown"
		[noCollapse]="true"
		class="action"
		openTo="bottom dropdown"
		iconClasses="ico ico-chevron-down"
		[buttonNameKey]="'management.process.versions.automations.list'"
		[isToolbarDropdown]="true"
		[items]="item.dropdownItems"
		[context]="item"
	>
	</stages-menu>
</ng-template>
