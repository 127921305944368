<div class="content">
	<p class="p1" [translate]="keys.message" [translateParams]="keys.values"></p>
	<p class="details" *ngIf="!!keys.details" [translate]="keys.details" [translateParams]="keys.values"></p>
</div>
<footer>
	<div class="buttons">
		<button
			class="sm cancel"
			*ngIf="keys.cancel"
			(click)="cancel()"
			stagesAutofocus
			tabindex="1"
			[translate]="keys.cancel"
		></button>
		<button
			class="sm"
			[ngClass]="{ delete: keys.isDeleteButton, save: !keys.isDeleteButton }"
			*ngIf="keys.ok"
			(click)="ok()"
			tabindex="2"
			[translate]="keys.ok"
		></button>
	</div>
</footer>
