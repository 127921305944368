import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { PerspectiveService } from "core/perspective.service";
import { DecycledObject, RetrocycleService } from "core/retrocycle.service";
import { UrlService } from "core/url.service";
import { lastValueFrom, Observable, ReplaySubject } from "rxjs";
import { map, take } from "rxjs/operators";
import DisplayDescription = stages.core.format.DisplayDescription;

type View = stages.process.View;
type ProcessView = stages.process.ProcessView;
type ViewableType = stages.process.ViewableType;
type ProcessTreeItem = stages.process.ProcessTreeItem;
type JsonCycleHolder = stages.process.JsonCycleHolder;

@Injectable({ providedIn: "root" })
export class ViewService {
	constructor(
		private httpClient: HttpClient,
		private urlService: UrlService,
		private perspectiveService: PerspectiveService,
		private retrocycleService: RetrocycleService,
	) {}

	private viewSubject = new ReplaySubject<View>(1);
	private fetching?: boolean;

	private viewLoadPromise?: Promise<View>;
	private viewLoadUrl?: string;

	async forceViewReload(
		workspaceId: string,
		type: string,
		identity: string,
		perspectiveId: string,
		currentWorkspaceId: string,
		pv: string,
	): Promise<View> {
		const newUrl = this.urlService.build(
			"app/workspace/{workspaceId}/process/elements/{type}/{identity}",
			{
				workspaceId: workspaceId,
				type: type,
				identity: identity,
			},
			{
				perspective: perspectiveId,
				workspaceId: currentWorkspaceId,
				pv: pv,
			},
		);

		if (this.viewLoadUrl === newUrl && this.viewLoadPromise) {
			return this.viewLoadPromise;
		}
		this.viewLoadUrl = newUrl;
		this.viewLoadPromise = lastValueFrom(this.httpClient.get<stages.process.View>(newUrl));
		try {
			const view = await this.viewLoadPromise;
			if (this.viewLoadUrl === newUrl) {
				this.updateView(view, perspectiveId);
			}
			return view;
		} catch (err: unknown) {
			if (this.viewLoadUrl === newUrl) {
				this.viewSubject.error(err);
			}
			throw err;
		} finally {
			if (this.viewLoadUrl === newUrl) {
				this.viewLoadPromise = undefined;
				this.viewLoadUrl = undefined;
			}
		}
	}

	getOverviewElement(selfElement: ProcessTreeItem): ProcessTreeItem {
		return selfElement.isLeaf && selfElement.parent && selfElement.parent.parent && !selfElement.folder
			? selfElement.parent
			: selfElement;
	}

	getSelf<T extends JsonCycleHolder & ProcessTreeItem>(data: T): T {
		if (data.isPreprocessed) {
			return data;
		}
		const retrocycledSelf = this.retrocycleService.retrocycle<T>(data as DecycledObject);
		retrocycledSelf.isLeaf = !retrocycledSelf.children || retrocycledSelf.children.length === 0;
		retrocycledSelf.isPreprocessed = true;
		return retrocycledSelf;
	}

	getIconClasses(
		type: ViewableType | undefined,
		isIndex: boolean,
		isDependentElement: boolean,
		additionalClasses?: string,
	): string[] {
		const classes: string[] = [];

		if (type) {
			classes.push("ico-et-" + type.ident.toLowerCase());
		}

		if (isDependentElement) {
			classes.push("ico-tag");
		}

		if (type?.subtypeIdent) {
			classes.push("ico-et-" + type.subtypeIdent);
		}

		if (isIndex && !isDependentElement) {
			classes.push("ico-et-index");
		}

		if (additionalClasses) {
			classes.push(additionalClasses);
		}

		return classes;
	}

	getUniqueId(typeIdent: string, id: string): string {
		return typeIdent + id;
	}

	getTypeMessageKeySingularSimple(typeIdent: string, subtypeIdent: string | null): string {
		return "process.element.type.singular." + typeIdent + (subtypeIdent ? "." + subtypeIdent : "");
	}

	getTypeMessageKeySingular(type: ViewableType): string {
		return this.getTypeMessageKeySingularSimple(type.ident, type.subtypeIdent);
	}

	getTypeMessageKeyPlural(type: ViewableType): string {
		return "process.element.type.plural." + type.ident + (type.subtypeIdent ? "." + type.subtypeIdent : "");
	}

	private updateView(view: View, perspective: string): void {
		if (perspective && perspective !== view.processView._perspective) {
			console.log(`Perspective '${perspective}' does not seem to be configured in the metamodel`);
			console.log(`View fell back to perspective '${view.processView._perspective}'`);
		}
		this.viewSubject.next(view);
		this.perspectiveService.presetPerspective(perspective, view.perspectives);
	}

	private getViewObservable(): Observable<View> {
		return this.viewSubject.asObservable();
	}

	awaitViewObservable(): Observable<View> {
		return this.getViewObservable();
	}

	async refresh(params: ParamMap): Promise<void> {
		const workspaceId = params.get("workspaceId")!;
		const pv = params.get("processVersion")!;
		await this.refreshView(workspaceId, pv);
	}

	async refreshView(workspaceId: string, pv: string): Promise<void> {
		// reload current View element from backend an push to viewSubject observers
		const current = await lastValueFrom(this.viewSubject.pipe(take(1)));
		this.fetching = true;
		try {
			const type = current.processView.type.ident;
			const identity = current.processView.identity;
			await this.forceViewReload(workspaceId, type, identity, await this.getPerspective(), workspaceId, pv);
		} finally {
			this.fetching = false;
		}
	}

	async updateDisplayDescription(displayDescription: DisplayDescription): Promise<void> {
		if (this.fetching) {
			return;
		}
		const current = await lastValueFrom(this.viewSubject.pipe(take(1)));
		if (current.processView) {
			current.processView.description = displayDescription;
			this.viewSubject.next(current);
		}
	}

	notifyModified(): void {
		if (!this.fetching) {
			this.viewSubject.pipe(take(1)).subscribe((current) => {
				this.viewSubject.next(current);
			});
		}
	}

	awaitSelfElementObservable(): Observable<ProcessView> {
		return this.awaitViewObservable().pipe(map((v) => this.getSelf<ProcessView>(v.processView)));
	}

	async getPerspective(): Promise<string> {
		return this.perspectiveService.getPerspective();
	}
}
