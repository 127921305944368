import { Component, HostBinding, Input } from "@angular/core";
import { DialogAdapter } from "common/dialog/dialog-adapter";

@Component({
	selector: "stages-warn",
	templateUrl: "./warn.component.html",
})
export class WarnComponent {
	@HostBinding("class.confirm") customClass = true;

	constructor(private dialog: DialogAdapter) {}

	@Input()
	keys!: {
		message: string;
		ok: string;
	};

	ok(): void {
		this.dialog.close("ok");
	}
}
