<stages-dialog2 #dialog>
	<stages-dialog-header></stages-dialog-header>

	<ng-container *ngIf="credentialsValid; else authenticate">
		<ng-container *ngIf="fileStateDataAndInfo$ | async as fileStateDataAndInfo; else loadingOrError">
			<h1 translate="setState"></h1>
			<form [formGroup]="form" class="content" novalidate #formElement>
				<div class="form">
					<stages-attribute-edit
						[attributeTypes]="fileStateDataAndInfo.metadata.attributeTypes"
						[attributes]="fileStateDataAndInfo.data.attributes"
						[form]="form"
					>
					</stages-attribute-edit>
				</div>
			</form>
			<stages-popup-footer [hasCancelButton]="false" [buttons]="buttons">
				<button
					*ngIf="form"
					class="button sm ok"
					ngClass="action.buttonClass"
					translate="setState"
					(click)="submit(fileStateDataAndInfo)"
					tabindex="3"
				></button>
			</stages-popup-footer>
		</ng-container>
	</ng-container>
</stages-dialog2>

<ng-template #loadingOrError>
	<h1 *ngIf="loadingError$ | async as error" translate="error.title"></h1>
	<stages-popup-content>
		<stages-loading-error-embeddable
			[credentialsValid]="credentialsValid"
			[loadingError$]="loadingError$"
		></stages-loading-error-embeddable>
	</stages-popup-content>
	<stages-popup-footer [hasCancelButton]="false" [buttons]="buttons"> </stages-popup-footer>
</ng-template>

<ng-template #authenticate>
	<stages-files-auth-embeddable
		#auth
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		*ngIf="!credentialsValid && !oAuthUrl"
	></stages-files-auth-embeddable>
	<stages-files-oauth-embeddable-confirm
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		[authUrl]="oAuthUrl"
		[closeCallback]="close"
		*ngIf="!credentialsValid && oAuthUrl"
	></stages-files-oauth-embeddable-confirm>
</ng-template>
