import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-buttons-responsive",
	templateUrl: "./buttons-responsive.component.html",
	styleUrls: ["buttons-responsive.component.scss"],
})
export class ButtonsResponsiveComponent {
	@Input()
	removeMargin: boolean = false;
}
