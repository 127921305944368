import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { DependencyResource } from "core/stages-client";

type DependencyGroup = stages.administration.credits.DependencyGroup;

@Injectable({ providedIn: "root" })
export class DependencyGroupsResolver implements Resolve<DependencyGroup[]> {
	constructor(private dependencyResource: DependencyResource) {}

	async resolve(): Promise<DependencyGroup[]> {
		return this.dependencyResource.getDependencyGroups();
	}
}
