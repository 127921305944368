<div class="label">
	<div class="avatar-cell">
		<stages-user-image
			[userName]="userName!"
			[userId]="userId!"
			[version]="userProfileVersion!"
			[iconClass]="userId ? undefined : iconClass"
			[isList]="true"
		></stages-user-image>
	</div>
	<div class="text fullsize center">
		<a class="clickable" *ngIf="userEmail" [attr.href]="getMailtoLink()">
			<ng-container *ngTemplateOutlet="userTemplate"></ng-container>
		</a>
		<ng-container *ngIf="!userEmail">
			<ng-container *ngTemplateOutlet="userTemplate"></ng-container>
		</ng-container>
		<ng-container *ngTemplateOutlet="commentTemplate"> </ng-container>
	</div>
</div>

<ng-template #userTemplate>
	<h4>
		<ng-container *ngIf="userName && !userNameSubdetail">
			{{ userName }}
		</ng-container>
		<ng-container *ngIf="userName && userNameSubdetail && useColonDelimiter">
			{{ userName }}:
			<span>{{ userNameSubdetail }} </span>
		</ng-container>
		<ng-container *ngIf="userName && userNameSubdetail && !useColonDelimiter">
			{{ userName }}
			<span>({{ userNameSubdetail }}) </span>
		</ng-container>
		<ng-container *ngIf="!userName && userNameSubdetail">
			<span>{{ userNameSubdetail }} </span>
		</ng-container>
		<i class="ico ico-email ico-inline" *ngIf="userEmail"></i>
	</h4>
</ng-template>

<ng-template #commentTemplate>
	<p *ngIf="comment">{{ comment }}</p>
	<h6 *ngIf="secondary">{{ secondary }}</h6>
	<div class="info">
		<h6 class="date" *ngIf="date || subDetail">
			<span *ngIf="date">{{ date }}</span>
			<span *ngIf="subDetail">
				- <span *ngIf="subDetailColor" [style.color]="'#' + subDetailColor!">{{ subDetail }}</span>
				<span *ngIf="!subDetailColor">{{ subDetail }}</span>
			</span>
		</h6>
		<h6 class="date" *ngIf="subDetail2">
			<span *ngIf="subDetail2">
				<span *ngIf="subDetailColor2" [style.color]="'#' + subDetailColor2!">{{ subDetail2 }}</span>
				<span *ngIf="!subDetailColor2">{{ subDetail2 }}</span>
			</span>
		</h6>
	</div>
</ng-template>
