import { Injectable } from "@angular/core";
import { SupportInfoResource } from "core/stages-client";

@Injectable({ providedIn: "root" })
export class ErrorReportService {
	constructor(private readonly supportInfoResource: SupportInfoResource) {}

	async send(subject: string, message: string): Promise<void> {
		return this.supportInfoResource.send({ subject: subject, message: message });
	}
}
