import { Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Group, GroupDefinition } from "common/associations/association-group.service";
import { Container, Groups, SourceElementAndGroup } from "common/associations/association-list.component";
import { MainService } from "core/main.service";
import { ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { ProcessComplianceAssociationStore } from "process/compliance/associations/process-compliance-association-store.logic";
import { ProcessComplianceAssociationService } from "process/compliance/associations/process-compliance-association.service";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

type ViewableElement = stages.process.ViewableElement;
type ViewableComplianceAssociationGroup = stages.process.ViewableComplianceAssociationGroup;

@Component({
	selector: "stages-process-compliance-association-list",
	templateUrl: "./process-compliance-association-list.component.html",
})
export class ProcessComplianceAssociationListComponent {
	@Input()
	classes: string = "";

	@Input()
	messageKeyNone?: string;

	@Input()
	editable?: boolean;

	@Input()
	showEmptyGroups = false;

	container$: Observable<Container<ViewableElement, ViewableElement>>;

	associationStore: ProcessComplianceAssociationStore;

	constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private viewService: ViewService,
		associationService: ProcessComplianceAssociationService,
		processElementsResource: ProcessElementsResource,
	) {
		this.associationStore = new ProcessComplianceAssociationStore(
			viewService,
			associationService,
			processElementsResource,
		);
		this.container$ = this.viewService.awaitSelfElementObservable().pipe(
			//while same id
			distinctUntilChanged((p: stages.process.ProcessView, q: stages.process.ProcessView) => p.id !== q.id),
			map((p) => {
				return {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					sourceElement: p as any,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					associationGroups: this.buildGroups(p as any),
					preferencesKey: "processComplianceAssociation.list.state." + p.processType + "." + p.type.ident,
				};
			}),
		);
	}

	openBrowse(sourceElementAndGroup: SourceElementAndGroup<ViewableElement, ViewableElement>): void {
		const paramMap = this.route.snapshot.paramMap;
		const group = sourceElementAndGroup.associationGroup;
		this.mainService.openPopup(
			[
				"process",
				"processcompliance",
				paramMap.get("type"),
				sourceElementAndGroup.sourceElement.identity,
				group.workspace!,
				"_wv",
				"process",
				"index",
				{
					browseroottype: "process",
					grouppath: group.path,
					allowNavigation: false,
					allowIndex: true,
					dependentTypesRestrictions: group.allowAssociateDependentElements
						? group.targetDependentTypes === undefined
							? null
							: group.targetDependentTypes
						: [],
				},
				"elements",
			],
			this.route,
		);
	}

	buildGroups(self: ViewableElement): Groups<ViewableElement> {
		const result: Groups<ViewableElement> = {};
		const processComplianceContainer = self.processComplianceContainer;
		if (processComplianceContainer) {
			for (const processComplianceAssociationGroup of processComplianceContainer) {
				result[processComplianceAssociationGroup.groupKey] = this.buildGroup(processComplianceAssociationGroup);
			}
		}
		return result;
	}

	buildGroup(processComplianceAssociationGroup: ViewableComplianceAssociationGroup): Group<ViewableElement> {
		return {
			id: processComplianceAssociationGroup.id,
			path: processComplianceAssociationGroup.groupKey,
			name: processComplianceAssociationGroup.name,
			list: processComplianceAssociationGroup.list,
			derived: false,
			commentSupported: true,
			commentOnlyAssociationsSupported: false,
			allowAssociateDependentElements: false,
			targetType: undefined,
			targetSubtypes: undefined,
			type: processComplianceAssociationGroup.type,
			actions: processComplianceAssociationGroup.allowedOperations,
			workspace: processComplianceAssociationGroup.referenceModelWorkspaceId,
			subgroups: [],
		};
	}

	asArray(groups: Groups<ViewableElement>): GroupDefinition[] {
		return Object.keys(groups).map((key) => {
			return {
				path: key,
				id: groups[key].id,
				name: groups[key].name,
				allowAssociateDependentElements: groups[key].allowAssociateDependentElements,
			};
		});
	}
}
