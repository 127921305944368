import { ParamMap } from "@angular/router";
import { Store } from "common/data/store.logic";
import { ProcessElementsResource, UserGroupResource } from "core/stages-client";

export class UserGroupsStore implements Store<stages.administration.usergroup.role.UserGroupAssignment> {
	constructor(
		private selfPromise: Promise<stages.process.ProcessView>,
		private processElementsResource: ProcessElementsResource,
		private userGroupResource: UserGroupResource,
		private pageSize: number,
	) {}

	async getPage(
		pageIndex: number,
		workspaceId: string,
		_pv: string,
		paramMap: ParamMap,
	): Promise<PagedResult<stages.administration.usergroup.role.UserGroupAssignment>> {
		if (pageIndex === 1) {
			const self = await this.selfPromise;
			if (!self.userGroupContainer) {
				const pagedResult: PagedResult<stages.administration.usergroup.role.UserGroupAssignment> = {
					items: [],
					page: 1,
					totalItemCount: 0,
					totalPageCount: 0,
					pageSize: null,
				};

				return pagedResult;
			}

			return self.userGroupContainer.userGroups;
		} else {
			const promise = this.processElementsResource.getUserGroups(
				workspaceId,
				paramMap.get("type")!,
				paramMap.get("identity")!,
				{
					page: pageIndex,
					firstPageSize: this.pageSize,
					pageSize: this.pageSize,
				},
			);

			return promise;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async search(term: string, paramMap: ParamMap): Promise<any> {
		return this.userGroupResource
			.searchAssignabelUserPermissionGroups({
				term: term,
				workspaceId: paramMap.get("workspaceId"),
				excludeDefault: true,
			})
			.then((result) => {
				return {
					results: result.items,
				};
			});
	}

	async add(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		userGroup: any,
		pageIndex: number,
		paramMap: ParamMap,
	): Promise<PagedResult<stages.administration.usergroup.role.UserGroupAssignment>> {
		const result = await this.processElementsResource.assignUserGroup(
			paramMap.get("workspaceId")!,
			paramMap.get("type")!,
			paramMap.get("identity")!,
			userGroup.id,
			{
				page: 1,
				firstPageSize: this.pageSize * pageIndex,
				pageSize: this.pageSize,
			},
		);
		const self = await this.selfPromise;
		if (self.userGroupContainer) {
			self.userGroupContainer.userGroups = result;
		}

		return result;
	}

	async delete(
		userGroupAssignmentIds: string[],
		pageIndex: number,
		paramMap: ParamMap,
	): Promise<PagedResult<stages.administration.usergroup.role.UserGroupAssignment>> {
		const result = await this.processElementsResource.unassignUserGroups(
			paramMap.get("workspaceId")!,
			paramMap.get("type")!,
			paramMap.get("identity")!,
			userGroupAssignmentIds,
			{
				page: 1,
				firstPageSize: this.pageSize * pageIndex,
				pageSize: this.pageSize,
			},
		);
		const self = await this.selfPromise;
		if (self.userGroupContainer) {
			self.userGroupContainer.userGroups = result;
		}
		return result;
	}
}
