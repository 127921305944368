import { ActivatedRoute, ParamMap } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { MainService } from "core/main.service";
import { FloatingActionButtonConfiguration } from "navigation/list/navigation-configuration.interface";
import { EntryType, LabelData, ListNavigationEntry } from "navigation/list/navigation-entry.interface";
import { Store } from "navigation/list/navigation-store.interface";
import {
	FollowUpAction,
	NavigationMenuItem,
	NavigationMode,
	NavMode,
	RearrangeUIAction,
	SimpleUIAction,
} from "navigation/list/navigation.interface";
import { NavigationService } from "navigation/list/navigation.service";

type ViewableElement = stages.process.ViewableElement;
type ActiveViewableNavigationEntry = stages.process.ViewableElement;
type Parent = stages.process.ProcessTreeItem;

type ViewableType = stages.process.ViewableType;

class ViewableNavigationEntry implements ListNavigationEntry {
	id: string;
	label?: string;
	labelTranslate?: string;
	labelClasses?: string[] | string;
	secondLine?: string;
	secondLineTranslate?: string;
	route: unknown[];
	exact?: boolean;
	disabled?: boolean | ((...args: unknown[]) => boolean);
	icon?: string;
	displayMode?: NAV_ENTRY_DISPLAY_MODE;
	menuItems?: NavigationMenuItem[];
	actions: StringToBoolean;
	sortKey: number;
	valueForAlphabeticalSort: string;
	hasChildren: boolean;
	linkDisabled?: boolean;

	constructor(
		public original: ViewableElement,
		labelData: LabelData,
		readonly entryType: EntryType,
		private store: Store<ViewableElement, ViewableType>,
		private workspaceId: string,
		private pv: string,
	) {
		this.label = labelData.label;
		this.labelTranslate = labelData.labelTranslate;
		this.labelClasses = labelData.labelClasses;
		this.secondLine = labelData.secondLine;
		this.secondLineTranslate = labelData.secondLineTranslate;
		this.valueForAlphabeticalSort = labelData.valueForAlphabeticalSort;
		this.id = store.getIdentifier(original);
		this.route = store.getRoute(original);
		this.actions = original.allowedOperations;
		this.sortKey = original.sortKey;
		this.hasChildren = hasChildren(original);
	}

	async editDone(newLabel: string): Promise<void> {
		if (this.original) {
			return this.store.rename(this.original, newLabel, this.workspaceId, this.pv);
		}
	}

	getValueForAlphabeticalSort(): string {
		return this.valueForAlphabeticalSort;
	}
}

class AddViewableNavigationEntryTamplate implements ListNavigationEntry {
	id = "-1";
	label = "";
	labelTranslate: undefined;
	secondLine?: string;
	secondLineTranslate: undefined;
	route = ["add"];
	exact = true;
	readonly displayMode = "ADD";
	sortKey = 0;
	actions = {};
	hasChildren = false;

	constructor(
		private parent: ViewableElement,
		private type: ViewableType,
		readonly entryType: EntryType,
		private store: Store<ViewableElement, ViewableType>,
		private workspaceId: string,
		private pv: string,
		private refresh: () => void,
	) {
		this.secondLine = type.name === null ? undefined : type.name;
	}

	async editDone(newLabel: string): Promise<void> {
		return this.store.addChild(this.parent, this.type, newLabel, this.workspaceId, this.pv).then(() => {
			this.refresh();
		});
	}
}

export class ViewableNavigationConfigurationProvider {
	constructor(
		private navigationService: NavigationService,
		private mainService: MainService,
		private mutexService: MutexService,
		private store: Store<ViewableElement, ViewableType>,
	) {}

	updateNavigationFromParams(
		activeEntry: ActiveViewableNavigationEntry,
		paramMap: ParamMap,
		route: ActivatedRoute,
		showFloatingActionButton: boolean,
		entryType: EntryType,
	): void {
		const workspaceId = paramMap.get("workspaceId")!;
		const pv = paramMap.get("processVersion")!;
		const subtypeIdentForAdd = paramMap.get("subtype");
		const forcedFolder = paramMap.get("forcedFolder") === "true";
		const folderId = paramMap.get("folderId");
		const action = paramMap.get("action");
		this.updateNavigation(
			activeEntry,
			action,
			forcedFolder,
			folderId,
			subtypeIdentForAdd,
			workspaceId,
			pv,
			route,
			showFloatingActionButton,
			entryType,
		);
	}

	updateNavigation(
		activeEntry: ActiveViewableNavigationEntry,
		action: string | null,
		forcedFolder: boolean,
		folderId: string | null,
		subtypeIdentForAdd: string | null,
		workspaceId: string,
		pv: string,
		route: ActivatedRoute,
		showFloatingActionButton: boolean,
		entryType: EntryType,
	): void {
		const isPasteAction: boolean = (action || "view").indexOf("paste") !== -1;

		const refresh = (): void =>
			this.updateNavigation(
				activeEntry,
				action,
				forcedFolder,
				folderId,
				subtypeIdentForAdd,
				workspaceId,
				pv,
				route,
				showFloatingActionButton,
				entryType,
			);

		const folder = this.determineFolder(activeEntry, forcedFolder, folderId);
		const list = this.getNavigationList(
			activeEntry,
			entryType,
			forcedFolder,
			folderId,
			subtypeIdentForAdd,
			workspaceId,
			pv,
			refresh,
		);
		const folderEntry = this.asNavigationEntry(folder as ViewableElement, entryType, workspaceId, pv);
		const self = this.determineSelf(activeEntry, folderEntry, list);

		this.navigationService.update({
			homeRoute: ["home"],
			parentRoute: this.getParentRoute(activeEntry, entryType, forcedFolder, folderId, isPasteAction, workspaceId, pv),
			folder: folderEntry,
			self: self,
			list: list,
			selectionIdentifier: this.store.getSelectionIdentifier(),
			supportedModes: this.getSupportedModes(folderEntry, self, workspaceId, this.mainService.secondaryWorkspaceId, pv),
			mainMenu: this.newMainMenu(folder),
			activatedRoute: route,
			baseRoute: this.store.getBaseRoute(route),
			floatingActionButton: this.buildFloatingActionButtonConfiguration(
				showFloatingActionButton,
				folder as ViewableElement,
				action,
			),
			clipboardConfiguration: {
				routeAfterWorkspaceChange: this.store.getRouteForClipboardWorkspaceChange(activeEntry),
				workspaceFilter: {
					filterBy: "ELEMENT_TYPE",
					filterParam1: activeEntry.type.ident,
					filterParam2: "CREATE",
				},
				supportedModes: this.getSupportedClipboardModes(folderEntry, self, workspaceId, pv),
			},
			invalidate: () => {
				this.store.invalidate();
			},
		});
	}

	private buildFloatingActionButtonConfiguration(
		showFloatingActionButton: boolean,
		folder: ViewableElement,
		action: string | null,
	): FloatingActionButtonConfiguration | undefined {
		if (!showFloatingActionButton || action != null) {
			return undefined;
		}

		if (folder.validChildSubtypes && folder.validChildSubtypes.length > 0) {
			return {
				type: "withMenu",
				menuItems: folder.validChildSubtypes.map((creationType: ViewableType) => {
					return {
						subtypeIdent: creationType.subtypeIdent!,
						iconClass: this.store.getIconClassesForType(creationType),
						nameTranslate: this.store.getTypeMessageKeySingular(creationType),
					};
				}),
			};
		} else {
			return {
				type: "simple",
			};
		}
	}

	private determineSelf(
		activeEntry: ActiveViewableNavigationEntry,
		folder: ViewableNavigationEntry,
		list: ListNavigationEntry[],
	): ViewableNavigationEntry {
		const selfIdentifier = this.store.getIdentifier(activeEntry);
		if (folder.id === selfIdentifier) {
			return folder;
		}
		const self = list.find((c) => c.id === selfIdentifier) as ViewableNavigationEntry;
		return self ?? folder;
	}

	private isActiveEntryTheFolder(
		activeEntry: ActiveViewableNavigationEntry,
		forcedFolder: boolean,
		folderId: string | null,
	): boolean {
		const activeEntryId = this.store.getIdentifier(activeEntry);
		return (
			activeEntry.index ||
			forcedFolder ||
			(hasChildren(activeEntry) && (folderId === null || activeEntryId === folderId))
		);
	}

	private determineFolder(
		activeEntry: ActiveViewableNavigationEntry,
		forcedFolder: boolean,
		folderId: string | null,
	): Parent {
		if (this.isActiveEntryTheFolder(activeEntry, forcedFolder, folderId)) {
			return activeEntry;
		} else {
			if (activeEntry.parent) {
				return activeEntry.parent;
			} else {
				return activeEntry;
			}
		}
	}

	private getNavigationList(
		activeEntry: ActiveViewableNavigationEntry,
		entryType: EntryType,
		forcedFolder: boolean,
		folderId: string | null,
		subtypeIdentForAdd: string | null,
		workspaceId: string,
		pv: string,
		refresh: () => void,
	): ListNavigationEntry[] {
		if (this.isActiveEntryTheFolder(activeEntry, forcedFolder, folderId)) {
			const folder = activeEntry;
			let children: ListNavigationEntry[] = [];
			if (activeEntry.children) {
				children = activeEntry.children.map((child) =>
					this.withMenu(
						this.asNavigationEntry(child as ViewableElement, entryType, workspaceId, pv),
						activeEntry,
						workspaceId,
						pv,
					),
				);
			}
			if (subtypeIdentForAdd === "") {
				children.push(this.addEntryTemplateFor(folder, folder.type, entryType, workspaceId, pv, refresh));
			} else if (folder.validChildSubtypes !== undefined) {
				folder.validChildSubtypes
					.filter((t) => subtypeIdentForAdd === t.subtypeIdent)
					.forEach((t) => {
						children.push(this.addEntryTemplateFor(folder, t, entryType, workspaceId, pv, refresh));
					});
			}
			return children;
		} else {
			if (activeEntry.parent) {
				const folder = activeEntry.parent;
				const children: ListNavigationEntry[] = !activeEntry.parent.children
					? []
					: activeEntry.parent.children.map((child) =>
							this.withMenu(
								this.asNavigationEntry(child as ViewableElement, entryType, workspaceId, pv),
								activeEntry,
								workspaceId,
								pv,
							),
					  );

				if (subtypeIdentForAdd === "") {
					children.push(this.addEntryTemplateFor(folder, folder.type, entryType, workspaceId, pv, refresh));
				} else if (folder.validChildSubtypes !== undefined) {
					folder.validChildSubtypes
						.filter((t) => subtypeIdentForAdd === t.subtypeIdent)
						.forEach((t) => {
							children.push(this.addEntryTemplateFor(folder, t, entryType, workspaceId, pv, refresh));
						});
				}
				return children;
			} else {
				return [];
			}
		}
	}

	private getParentRoute(
		activeEntry: ActiveViewableNavigationEntry,
		entryType: EntryType,
		forcedFolder: boolean,
		folderId: string | null,
		isPasteAction: boolean,
		workspaceId: string,
		pv: string,
	): unknown[] | undefined {
		let parent;
		if (this.isActiveEntryTheFolder(activeEntry, forcedFolder, folderId)) {
			parent = activeEntry.parent;
		} else {
			if (activeEntry.parent && activeEntry.parent.parent) {
				parent = activeEntry.parent.parent;
			}
		}
		if (isPasteAction) {
			if (parent && parent.type.ident === activeEntry.type.ident) {
				return this.asNavigationEntry(parent, entryType, workspaceId, pv).route;
			}
			return undefined;
		}
		if (parent) {
			return this.asNavigationEntry(parent, entryType, workspaceId, pv).route;
		}
		return this.store.getUppermostUpRoute();
	}

	private asNavigationEntry(
		restEntry: ViewableElement,
		entryType: EntryType,
		workspaceId: string,
		pv: string,
	): ViewableNavigationEntry {
		const viewableNavigationEntry = new ViewableNavigationEntry(
			restEntry,
			this.store.getLabel(restEntry),
			entryType,
			this.store,
			workspaceId,
			pv,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const changeMarker = (restEntry as any).changeMarker;
		if (changeMarker) {
			viewableNavigationEntry.linkDisabled = changeMarker.deleted;
		}
		return viewableNavigationEntry;
	}

	private withMenu(
		entry: ViewableNavigationEntry,
		activeEntry: ActiveViewableNavigationEntry,
		workspaceId: string,
		pv: string,
	): ViewableNavigationEntry {
		entry.menuItems = this.newElementMenu(entry, activeEntry, workspaceId, pv);
		return entry;
	}

	private newMainMenu(folder: Parent): MenuItem[] {
		const menuItems: MenuItem[] = [];

		if (folder.validChildSubtypes && folder.validChildSubtypes.length > 0) {
			folder.validChildSubtypes.forEach((creationType: ViewableType) => {
				menuItems.push({
					name: "process.navigation.action.add",
					nameParam: this.store.getTypeMessageKeySingular(creationType),
					iconClass: this.store.getIconClassesForType(creationType),
					disabled: (context: ViewableNavigationEntry) => !context.actions.CREATE_CHILD,
					// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					onDoFollowUp: async (context: ViewableNavigationEntry) => {
						return new FollowUpAction(NavMode.SELF, "add")
							.withSubtype(creationType.subtypeIdent!)
							.withHash("new-child")
							.withRestoreRoute((params) => this.store.restoreRouteFromParams(params));
					},
				});
			});

			menuItems.push({
				isSeparator: true,
			});
		} else {
			menuItems.push({
				name: "process.navigation.action.add",
				nameParam: this.store.getTypeMessageKeySingular(folder.type),
				iconClass: this.store.getIconClassesForType(folder.type),
				disabled: (context: ViewableNavigationEntry) => !context.actions.CREATE_CHILD,
				// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				onDoFollowUp: async (context: ViewableNavigationEntry) => {
					return new FollowUpAction(NavMode.SELF, "add")
						.withSubtype("")
						.withHash("new-child")
						.withRestoreRoute((params) => this.store.restoreRouteFromParams(params));
				},
			});

			menuItems.push({
				isSeparator: true,
			});
		}

		menuItems.push({
			name: "copy",
			iconClass: "ico ico-copy",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.COPY;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "bulkcopy").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "move",
			iconClass: "ico ico-move",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.MOVE;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "bulkmove").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "rearrange",
			iconClass: "ico ico-rearrange",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children || folder.children.length === 0 || !folder.allowedOperations.REARRANGE_CHILDREN,
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "rearrange").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "delete",
			iconClass: "ico ico-delete",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.DELETE;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "delete").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "process.element.overwrite",
			iconClass: "ico ico-overwrite",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.OVERWRITE;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "overwrite").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "process.element.overwrite.undo",
			iconClass: "ico ico-undo-overwrite",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.RESTORE;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "restore").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			isSeparator: true,
		});

		menuItems.push({
			name: "process.element.tailor.add",
			iconClass: "ico ico-tailoring-add",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.MANUAL_TAILORING_ADD;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "tailoradd").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "process.element.tailor.remove",
			iconClass: "ico ico-tailoring-remove",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.MANUAL_TAILORING_REMOVE;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "tailorremove").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		menuItems.push({
			name: "process.element.tailor.reset",
			iconClass: "ico ico-tailoring-reset",
			disabled: (context: ViewableNavigationEntry) =>
				!folder.children ||
				!folder.children.some((element) => {
					return element.allowedOperations.MANUAL_TAILORING_RESET;
				}),
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async () => {
				return new FollowUpAction(NavMode.FORCED_CHILD_AFTER_BACKUP, "tailorreset").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
		});

		return menuItems;
	}

	private newElementMenu(
		entry: ViewableNavigationEntry,
		activeEntry: ActiveViewableNavigationEntry,
		workspaceId: string,
		pv: string,
	): MenuItem[] {
		const menuItems: MenuItem[] = [];

		if (entry.original.validChildSubtypes && entry.original.validChildSubtypes.length > 0) {
			entry.original.validChildSubtypes.forEach((typeToCreate: ViewableType) => {
				menuItems.push({
					name: "process.navigation.action.add",
					nameParam: this.store.getTypeMessageKeySingular(typeToCreate),
					iconClass: this.store.getIconClassesForType(typeToCreate),
					disabled: (context: ViewableNavigationEntry) => !context.actions.CREATE_CHILD,
					// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					onDoFollowUp: async (context: ViewableNavigationEntry) => {
						return new FollowUpAction(NavMode.CONTEXT_AS_NEW_FOLDER, "add")
							.withSubtype(typeToCreate.subtypeIdent!)
							.withContext(context)
							.withHash("new-child");
					},
				});
			});

			menuItems.push({
				isSeparator: true,
			});
		} else {
			menuItems.push({
				name: "process.navigation.action.add",
				nameParam: this.store.getTypeMessageKeySingular(entry.original.type),
				iconClass: this.store.getIconClassesForType(entry.original.type),
				disabled: (context: ViewableNavigationEntry) => !context.actions.CREATE_CHILD,
				// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				onDoFollowUp: async (context: ViewableNavigationEntry) => {
					return new FollowUpAction(NavMode.CONTEXT_AS_NEW_FOLDER, "add")
						.withSubtype("")
						.withContext(context)
						.withHash("new-child");
				},
			});

			menuItems.push({
				isSeparator: true,
			});
		}

		menuItems.push({
			name: "edit",
			iconClass: "ico ico-edit",
			disabled: (context: ViewableNavigationEntry) => !context.actions.EDIT,
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.TO_CONTEXT).withContext(context).gotoSubstate(["edit"]);
			},
		});

		menuItems.push({
			name: "rename",
			iconClass: "ico ico-edit-text",
			disabled: (context: ViewableNavigationEntry) => !context.actions.RENAME,
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				context.displayMode = "RENAME";
				return new FollowUpAction(NavMode.NO_NAV);
			},
		});

		menuItems.push({
			name: "language.translate",
			iconClass: "ico ico-translate",
			disabled: (context: ViewableNavigationEntry) => !context.actions.TRANSLATE,
			storeElement: false,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.TO_CONTEXT).withContext(context).gotoSubstate(["translate"]);
			},
		});

		menuItems.push({
			name: "copy",
			iconClass: "ico ico-copy",
			disabled: (context: ViewableNavigationEntry) => !context.actions.COPY,
			storeElement: true,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.FORCED_FOLDER_AFTER_BACKUP, "copypaste");
			},
		});

		menuItems.push({
			name: "move",
			iconClass: "ico ico-move",
			disabled: (context: ViewableNavigationEntry) => !context.actions.MOVE,
			storeElement: true,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.FORCED_FOLDER_AFTER_BACKUP, "movepaste");
			},
		});

		menuItems.push({
			name: "delete",
			iconClass: "ico ico-delete",
			disabled: (context: ViewableNavigationEntry) => !context.actions.DELETE,
			storeElement: false,
			indicateProgress: true,
			invalidate: true,
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				const isDeleteActiveEntry = this.store.getIdentifier(activeEntry) === context.id;

				try {
					await this.store.deleteSingle(context.original, workspaceId, pv);
					if (isDeleteActiveEntry) {
						return new FollowUpAction(NavMode.FOLDER_RESET_PARAMS);
					} else {
						return new FollowUpAction(NavMode.SELF)
							.withForcedReload()
							.withRestoreRoute((params) => this.store.restoreRouteFromParams(params));
					}
				} catch (e: unknown) {
					return new FollowUpAction(NavMode.SELF).withRestoreRoute((params) =>
						this.store.restoreRouteFromParams(params),
					);
				}
			},
		});

		menuItems.push({
			name: "process.element.overwrite",
			iconClass: "ico ico-overwrite",
			disabled: (context: ViewableNavigationEntry) => !context.actions.OVERWRITE,
			storeElement: false,
			indicateProgress: true,
			invalidate: true,
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return this.store.executeCommand("overwrite", context.original, workspaceId, pv).then(() => {
					return new FollowUpAction(NavMode.SELF)
						.withForcedReload()
						.withRestoreRoute((params) => this.store.restoreRouteFromParams(params));
				});
			},
		});

		menuItems.push({
			name: "process.element.overwrite.undo",
			iconClass: "ico ico-undo-overwrite",
			disabled: (context: ViewableNavigationEntry) => !context.actions.RESTORE,
			storeElement: false,
			indicateProgress: true,
			invalidate: true,
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				try {
					await this.store.executeCommand("restore", context.original, workspaceId, pv);
					return new FollowUpAction(NavMode.SELF)
						.withForcedReload()
						.withRestoreRoute((params) => this.store.restoreRouteFromParams(params));
				} catch (e: unknown) {
					return new FollowUpAction(NavMode.SELF).withRestoreRoute((params) =>
						this.store.restoreRouteFromParams(params),
					);
				}
			},
		});

		menuItems.push({
			isSeparator: true,
		});

		menuItems.push({
			name: "process.element.tailor.add",
			iconClass: "ico ico-tailoring-add",
			disabled: (context: ViewableNavigationEntry) => !context.actions.MANUAL_TAILORING_ADD,
			storeElement: true,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.SELF)
					.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
					.openPopup(
						this.store.getPopupBaseRoute().concat([
							"mantailor",
							{
								status: 1,
								type: context.original.type.ident,
								ids: [context.original.id],
							},
						]),
					);
			},
		});

		menuItems.push({
			name: "process.element.tailor.remove",
			iconClass: "ico ico-tailoring-remove",
			disabled: (context: ViewableNavigationEntry) => !context.actions.MANUAL_TAILORING_REMOVE,
			storeElement: true,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.SELF)
					.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
					.openPopup(
						this.store.getPopupBaseRoute().concat([
							"mantailor",
							{
								status: -1,
								type: context.original.type.ident,
								ids: [context.original.id],
							},
						]),
					);
			},
		});

		menuItems.push({
			name: "process.element.tailor.reset",
			iconClass: "ico ico-tailoring-reset",
			disabled: (context: ViewableNavigationEntry) => !context.actions.MANUAL_TAILORING_RESET,
			storeElement: true,
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			onDoFollowUp: async (context: ViewableNavigationEntry) => {
				return new FollowUpAction(NavMode.SELF)
					.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
					.openPopup(
						this.store.getPopupBaseRoute().concat([
							"mantailor",
							{
								status: 0,
								type: context.original.type.ident,
								ids: [context.original.id],
							},
						]),
					);
			},
		});

		return menuItems;
	}

	private addEntryTemplateFor(
		parent: ViewableElement,
		type: ViewableType,
		entryType: EntryType,
		workspaceId: string,
		pv: string,
		refresh: () => void,
	): ListNavigationEntry {
		return new AddViewableNavigationEntryTamplate(parent, type, entryType, this.store, workspaceId, pv, refresh);
	}

	private getSupportedModes(
		folderEntry: ViewableNavigationEntry,
		activeEntry: ViewableNavigationEntry,
		workspaceId: string,
		secondaryWorkspaceId: string | undefined,
		pv: string,
	): Map<string, NavigationMode> {
		const cancelAction: SimpleUIAction = {
			name: "cancel",
			buttonClass: "cancel",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (_selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF).withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
			indicateProgress: false,
			invalidate: false,
			folder: folderEntry,
			self: activeEntry,
		};

		const cancelCopyMoveAction: SimpleUIAction = {
			name: "cancel",
			buttonClass: "cancel",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (_selection) => {
				return new FollowUpAction(NavMode.SELF).withRestoreRoute((params) => this.store.restoreRouteFromParams(params));
			},
			indicateProgress: false,
			invalidate: false,
			folder: folderEntry,
			self: activeEntry,
		};

		const deleteAction: SimpleUIAction = {
			name: "delete",
			buttonClass: "delete",
			on: async (selection) => {
				return this.mutexService.invokeWithResult("elementDelete", async () => {
					const isDeleteActiveEntry = selection.isSelected(activeEntry);
					return this.store
						.delete(
							selection.get().map((e) => (e as ViewableNavigationEntry).original),
							workspaceId,
							pv,
						)
						.then(() => {
							if (isDeleteActiveEntry) {
								return new FollowUpAction(NavMode.FOLDER_RESET_PARAMS);
							} else {
								return new FollowUpAction(NavMode.BACK_TO_SELF)
									.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
									.withForcedReload();
							}
						});
				});
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: true,
			invalidate: true,
			folder: folderEntry,
			self: activeEntry,
		};

		const overwriteAction: SimpleUIAction = {
			name: "process.element.overwrite",
			buttonClass: "save",
			on: async (selection) => {
				return this.mutexService.invokeWithResult("elementOverwrite", async () => {
					return this.store
						.executeBatchCommand(
							"overwrite",
							folderEntry.original,
							selection.get().map((e) => (e as ViewableNavigationEntry).original),
							workspaceId,
							pv,
						)
						.then(() => {
							return new FollowUpAction(NavMode.BACK_TO_SELF)
								.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
								.withForcedReload();
						});
				});
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: true,
			invalidate: true,
			folder: folderEntry,
			self: activeEntry,
		};

		const overwriteCancelAction: SimpleUIAction = {
			name: "process.element.overwrite.undo",
			buttonClass: "save",
			on: async (selection) => {
				return this.mutexService.invokeWithResult("elementOverwriteUndo", async () => {
					return this.store
						.executeBatchCommand(
							"restore",
							folderEntry.original,
							selection.get().map((e) => (e as ViewableNavigationEntry).original),
							workspaceId,
							pv,
						)
						.then(() => {
							return new FollowUpAction(NavMode.BACK_TO_SELF)
								.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
								.withForcedReload();
						});
				});
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: true,
			invalidate: true,
			folder: folderEntry,
			self: activeEntry,
		};

		const copyAction: SimpleUIAction = {
			name: "copy",
			buttonClass: "save",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (selection) => {
				selection.persist();
				return new FollowUpAction(NavMode.FORCED_FOLDER, "bulkcopypaste");
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: true,
			invalidate: true,
			folder: folderEntry,
			self: activeEntry,
		};

		const rearrangeAction: RearrangeUIAction = {
			name: "rearrange",
			buttonClass: "save",
			getSortedEntries: () => {
				return [];
			},
			onRearrange: async (sortedChildren: ViewableNavigationEntry[], sortOrder: "alphabetical" | "custom") => {
				return this.mutexService.invokeWithResult("mutexRearrange", async () => {
					return this.store
						.rearrange(
							folderEntry.original,
							sortedChildren.map((c) => c.original),
							sortOrder,
							workspaceId,
							pv,
						)
						.then(() => {
							return new FollowUpAction(NavMode.BACK_TO_SELF).withRestoreRoute((params) =>
								this.store.restoreRouteFromParams(params),
							);
						});
				});
			},
			disabled: () => {
				return false;
			},
			indicateProgress: true,
			invalidate: true,
			folder: folderEntry,
			self: activeEntry,
		};

		const moveAction: SimpleUIAction = {
			name: "move",
			buttonClass: "save",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (selection) => {
				selection.persist();
				return new FollowUpAction(NavMode.FORCED_FOLDER, "bulkmovepaste");
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: true,
			invalidate: true,
			folder: folderEntry,
			self: activeEntry,
		};

		const tailorAddAction: SimpleUIAction = {
			name: "process.element.tailor.action",
			buttonClass: "save",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF)
					.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
					.withForcedReload()
					.openPopup(
						this.store.getPopupBaseRoute().concat([
							"mantailor",
							{
								status: 1,
								type: (selection.get()[0] as ViewableNavigationEntry).original.type.ident,
								ids: selection.get().map((e) => (e as ViewableNavigationEntry).original.id),
								typeIconPrefix: this.store.getTypeIconPrefix(),
							},
						]),
					);
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: false,
			invalidate: false,
			folder: folderEntry,
			self: activeEntry,
		};

		const tailorRemoveAction: SimpleUIAction = {
			name: "process.element.tailor.action",
			buttonClass: "save",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF)
					.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
					.withForcedReload()
					.openPopup(
						this.store.getPopupBaseRoute().concat([
							"mantailor",
							{
								status: -1,
								type: (selection.get()[0] as ViewableNavigationEntry).original.type.ident,
								ids: selection.get().map((e) => (e as ViewableNavigationEntry).original.id),
								typeIconPrefix: this.store.getTypeIconPrefix(),
							},
						]),
					);
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: false,
			invalidate: false,
			folder: folderEntry,
			self: activeEntry,
		};

		const tailorResetAction: SimpleUIAction = {
			name: "process.element.tailor.action",
			buttonClass: "save",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF)
					.withRestoreRoute((params) => this.store.restoreRouteFromParams(params))
					.withForcedReload()
					.openPopup(
						this.store.getPopupBaseRoute().concat([
							"mantailor",
							{
								status: 0,
								type: (selection.get()[0] as ViewableNavigationEntry).original.type.ident,
								ids: selection.get().map((e) => (e as ViewableNavigationEntry).original.id),
								typeIconPrefix: this.store.getTypeIconPrefix(),
							},
						]),
					);
			},
			disabled: (selection) => {
				return !selection.isSomeSelected();
			},
			indicateProgress: false,
			invalidate: false,
			folder: folderEntry,
			self: activeEntry,
		};

		const result = new Map<string, NavigationMode>();
		result.set("view", {
			actionPermissionName: null,
			isFolderChangeAllowed: true,
			isMenuEnabled: secondaryWorkspaceId === undefined,
			isRearrangeEnabled: false,
			isSelectEnabled: false,
			uiActions: [],
		});
		result.set("delete", {
			actionPermissionName: "DELETE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelAction, deleteAction],
		});
		result.set("overwrite", {
			actionPermissionName: "OVERWRITE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelAction, overwriteAction],
		});
		result.set("restore", {
			actionPermissionName: "RESTORE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelAction, overwriteCancelAction],
		});
		result.set("bulkcopy", {
			actionPermissionName: "COPY",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelCopyMoveAction, copyAction],
		});
		result.set("bulkmove", {
			actionPermissionName: "MOVE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelCopyMoveAction, moveAction],
		});
		result.set("rearrange", {
			actionPermissionName: "REARRANGE_CHILDREN",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: true,
			isSelectEnabled: false,
			uiActions: [cancelAction, rearrangeAction],
		});
		result.set("tailoradd", {
			actionPermissionName: "MANUAL_TAILORING_ADD",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelAction, tailorAddAction],
		});
		result.set("tailorremove", {
			actionPermissionName: "MANUAL_TAILORING_REMOVE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelAction, tailorRemoveAction],
		});
		result.set("tailorreset", {
			actionPermissionName: "MANUAL_TAILORING_RESET",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: true,
			uiActions: [cancelAction, tailorResetAction],
		});

		return result;
	}

	getSupportedClipboardModes(
		folder: ViewableNavigationEntry,
		self: ViewableNavigationEntry,
		workspaceId: string,
		pv: string,
	): Map<string, NavigationMode> {
		const cancelAction: SimpleUIAction = {
			name: "cancel",
			buttonClass: "cancel",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (_selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF).withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
			indicateProgress: false,
			invalidate: false,
			folder: folder,
			self: self,
		};

		const cancelBulkCopyAction: SimpleUIAction = {
			name: "cancel",
			buttonClass: "cancel",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (_selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF_AND_FOLDER_KEEP_BACKUP, "bulkcopy").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
			indicateProgress: false,
			invalidate: false,
			folder: folder,
			self: self,
		};

		const cancelBulkMoveAction: SimpleUIAction = {
			name: "cancel",
			buttonClass: "cancel",
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			on: async (_selection) => {
				return new FollowUpAction(NavMode.BACK_TO_SELF_AND_FOLDER_KEEP_BACKUP, "bulkmove").withRestoreRoute((params) =>
					this.store.restoreRouteFromParams(params),
				);
			},
			indicateProgress: false,
			invalidate: false,
			folder: folder,
			self: self,
		};

		const pasteMoveAction: SimpleUIAction = {
			name: "move",
			buttonClass: "save",
			on: async (selection) => {
				return this.mutexService.invokeWithResult("elementPaste", async () => {
					return (
						this.store
							.move(
								folder.original,
								selection.get().map((e) => (e as ViewableNavigationEntry).original),
								workspaceId,
								pv,
							)
							// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
							.then(async () => {
								return new FollowUpAction(NavMode.KEEP_SELF_RESET_PARAMS).withRestoreRoute((params) =>
									this.store.restoreRouteFromParams(params),
								);
							})
					);
				});
			},
			disabled: (selection) => {
				if (!folder || !folder.actions || !folder.actions.PASTE) {
					return true;
				}

				if (!selection.isSomeSelected() || selection.isSelected(folder)) {
					return true;
				}

				if (selection.isSelected(self)) {
					return true;
				}

				return false;
			},
			indicateProgress: true,
			invalidate: true,
			folder: folder,
			self: self,
		};
		const pasteCopyAction: SimpleUIAction = {
			name: "move",
			buttonClass: "save",
			on: async (selection) => {
				return this.mutexService.invokeWithResult("elementPaste", async () => {
					return (
						this.store
							.copy(
								folder.original,
								selection.get().map((e) => (e as ViewableNavigationEntry).original),
								workspaceId,
								pv,
							)
							// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
							.then(async () => {
								return new FollowUpAction(NavMode.KEEP_SELF_RESET_PARAMS).withRestoreRoute((params) =>
									this.store.restoreRouteFromParams(params),
								);
							})
					);
				});
			},
			disabled: (selection) => {
				if (!folder || !folder.actions || !folder.actions.PASTE) {
					return true;
				}

				if (!selection.isSomeSelected() || selection.isSelected(folder)) {
					return true;
				}

				return false;
			},
			indicateProgress: true,
			invalidate: true,
			folder: folder,
			self: self,
		};

		const result = new Map<string, NavigationMode>();
		result.set("bulkcopypaste", {
			actionPermissionName: "PASTE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: false,
			uiActions: [cancelBulkCopyAction, pasteCopyAction],
		});
		result.set("bulkmovepaste", {
			actionPermissionName: "PASTE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: false,
			uiActions: [cancelBulkMoveAction, pasteMoveAction],
		});
		result.set("copypaste", {
			actionPermissionName: "PASTE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: false,
			uiActions: [cancelAction, pasteCopyAction],
		});
		result.set("movepaste", {
			actionPermissionName: "PASTE",
			isFolderChangeAllowed: true,
			isMenuEnabled: false,
			isRearrangeEnabled: false,
			isSelectEnabled: false,
			uiActions: [cancelAction, pasteMoveAction],
		});
		return result;
	}
}

function hasChildren(entry: ActiveViewableNavigationEntry): boolean {
	if (entry.children) {
		return entry.children.length > 0;
	}
	return !entry.isLeaf; //use only as fallback as it might be outdated. i.e. a new child was added at the frontend
}
