<ng-container *ngIf="paramMap$ | async as paramMap">
	<stages-dialog2 *ngIf="templateDefinitions$ | async as templateDefinitions">
		<stages-dialog-header></stages-dialog-header>
		<div class="content">
			<h1 *ngIf="templateDefinitions.length > 1" translate="process.pdfPrint.template"></h1>
			<ul class="list last">
				<li class="list-item with-icon" *ngFor="let templateDefinition of templateDefinitions">
					<stages-pdf-download
						[templateDefinition]="templateDefinition"
						[id]="paramMap.get('id')!"
						[type]="paramMap.get('type')!"
						[workspaceId]="paramMap.get('workspaceId')!"
						[processVersion]="paramMap.get('processVersion')!"
					></stages-pdf-download>
				</li>
			</ul>
		</div>
		<footer></footer>
	</stages-dialog2>
</ng-container>
