import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { PopupComponent } from "common/newdialog/popup.component";
import { UtilService } from "common/util.service";

@Component({
	selector: "stages-dialog2",
	templateUrl: "./dialog.component.html",
	animations: [
		trigger("fadeInOutDialog", [
			transition(":enter", [
				style({ opacity: 0, transform: "scale(0)" }),
				animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)", style({ opacity: 1, transform: "scale(1)" })),
			]),
			transition(":leave", [
				style({ opacity: 1, transform: "scale(1)" }),
				animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)", style({ opacity: 0, transform: "scale(0)" })),
			]),
		]),
	],
})
export class NewDialogComponent implements OnInit, OnDestroy {
	@Input("classes")
	classes: string[] = ["autoHeight"];

	@Output() readonly beforeClose = new EventEmitter<void>();

	needsFallBack?: boolean;
	private closing?: boolean;

	constructor(private popupComponent: PopupComponent, private utilService: UtilService) {
		if (this.utilService.browserIsIE()) {
			this.classes.push("fallback");
		}
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.close(); // Issue 35141
	}

	async close(): Promise<void> {
		if (this.closing) {
			return;
		}
		this.closing = true;

		this.beforeClose.emit();
		await this.popupComponent.close();
	}

	containerClicked(event: Event): void {
		if (
			event.target !== null &&
			event.target instanceof Node &&
			event.target.firstChild instanceof Element &&
			event.target.firstChild.classList[0] === "dialog"
		) {
			this.close();
		}
	}
}
