import { JobInfoMap } from "process/freeze/freeze.service";
import { ProcessAdapter } from "process/freeze/overview/process-adapter.model";

type ViewableElement = stages.process.ViewableElement;
type JobIdentifier = stages.core.scheduler.JobIdentifier;

export class PhaseAdapter {
	duration: number = 1;
	private dependentPhases: ViewableElement[] = [];

	inProgress = false;
	hasErrors = false;
	private jobIdentifier?: JobIdentifier;

	constructor(public phase: ViewableElement) {}

	getIconClasses(): string[] {
		if (isFrozen(this.phase)) {
			return ["ico-check"];
		}
		if (this.dependentPhases.length > 0) {
			return ["ico-snowflake-all"];
		}
		if (this.hasErrors) {
			return ["ico-retry-freeze"];
		}
		return ["ico-snowflake"];
	}

	addDependentPhase(dependentPhase: ViewableElement, program: ProcessAdapter): void {
		const phasesOfSameProcessCount = this.dependentPhases.filter(
			(p) => p.process.id === dependentPhase.process.id,
		).length;
		this.duration += phasesOfSameProcessCount;
		program.duration += phasesOfSameProcessCount;
		this.dependentPhases.push(dependentPhase);
	}

	getRouterLink(): unknown[] {
		return [
			"/",
			"workspace",
			this.phase.workspaceId,
			this.phase.process.pv,
			"process",
			this.phase.type.ident.toLowerCase(),
			this.phase.identity,
		];
	}

	getLabelClasses(): string[] {
		if (this.phase.tailored) {
			return ["name", "tailored"];
		}
		return ["name"];
	}

	getFreezeStateClasses(): string[] {
		if (this.hasErrors) {
			return ["warning"];
		}
		return isFrozen(this.phase) || this.inProgress ? ["frozen"] : ["freeze"];
	}

	isDisabled(): boolean {
		return !this.isFreezeAllowed(this.phase);
	}

	getDependentFreezablePhases(): ViewableElement[] {
		const processIdToPhases = new Map<string, ViewableElement[]>();

		const dependentFreezablePhases = this.dependentPhases.filter((p) => this.isFreezeAllowed(p));
		for (const p of dependentFreezablePhases) {
			if (!processIdToPhases.has(p.process.id)) {
				processIdToPhases.set(p.process.id, []);
			}
			processIdToPhases.get(p.process.id)!.push(p);
		}

		return dependentFreezablePhases.filter((p) => lastOfProcess(p, processIdToPhases));
	}

	private isFreezeAllowed(element: ViewableElement): boolean {
		return !this.inProgress && !isFrozen(element) && element.allowedOperations.FREEZE;
	}

	setJobExecutionInfo(jobExecutionInfoMap: JobInfoMap): void {
		const jobExecutionInfo = jobExecutionInfoMap[this.phase.type.ident + "_" + this.phase.id];
		if (!jobExecutionInfo) {
			this.inProgress = false;
			this.jobIdentifier = undefined;
		} else {
			this.hasErrors = jobExecutionInfo.jobStatus === "COMPLETED_FAILED";
			this.jobIdentifier = jobExecutionInfo.jobIdentifier;
			if (
				(jobExecutionInfo.jobStatus === "COMPLETED_SUCCESSFUL" ||
					jobExecutionInfo.jobStatus === "COMPLETED_WITH_WARNINGS") &&
				this.inProgress
			) {
				setFrozen(this.phase, true);
			}
			this.inProgress = jobExecutionInfo.jobStatus === "RUNNING" || jobExecutionInfo.jobStatus === "WAITING";
		}
	}

	getJobProgressRoute(): unknown[] {
		if (!this.jobIdentifier) {
			return [];
		}
		return [
			"process",
			"freeze",
			this.phase.type.ident.toLowerCase(),
			this.phase.id,
			{ jobType: this.jobIdentifier.group, jobName: this.jobIdentifier.name },
		];
	}

	getStartFreezeRoute(): unknown[] {
		return [
			"process",
			"freeze",
			this.phase.type.ident,
			this.phase.id,
			{ freezeWorkspaceId: this.phase.process.workspaceId },
		];
	}
}

function lastOfProcess(element: ViewableElement, processIdToPhases: Map<string, ViewableElement[]>): boolean {
	const phases = processIdToPhases.get(element.process.id);
	if (!phases) {
		return false;
	}
	return phases[phases.length - 1].id === element.id;
}

function isFrozen(element: ViewableElement): boolean {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	return !!(element as any).frozen;
}

function setFrozen(element: ViewableElement, value: boolean): void {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	(element as any).frozen = value;
}
