<div class="progress-round-pct">
	<div class="clock">
		<div class="percentage span1" *ngIf="!finalState">{{ progress | percent }}</div>
		<div
			class="time-remaining span3"
			*ngIf="showRemaining()"
			translate="progress.remaining"
			[translateParams]="{ number: remainingNumber, unit: remainingUnit }"
		></div>
		<div class="final-state span2" *ngIf="finalState" translate="progress.finished.{{ finalState }}"></div>
		<round-progress
			[attr.aria-label]="'loading' | translate"
			[max]="1"
			[current]="progress"
			[color]="'#F37100'"
			[background]="'#E4E7EC'"
			[responsive]="true"
			[stroke]="10"
			[rounded]="false"
			[clockwise]="true"
			[animation]="'easeOutCubic'"
			[animationDelay]="0"
			[duration]="550"
		>
		</round-progress>
	</div>
	<div class="step" *ngIf="steps">
		<span
			class="span3"
			translate="progress.step"
			[translateParams]="{ stepNr: currentStepNr, totalSteps: steps.length }"
		></span
		>: <span class="span3" [translate]="steps[currentStepNr - 1].messageKey"></span>
	</div>
</div>
