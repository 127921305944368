import { Injectable } from "@angular/core";
import { BroadcastArgs } from "core/broadcast.service";

@Injectable({ providedIn: "root" })
export class SnackbarService {
	messages: BroadcastArgs[] = [];

	pushMessage(message: BroadcastArgs): void {
		this.messages.push(message);
	}

	shiftMessage(): BroadcastArgs | undefined {
		return this.messages.shift();
	}

	peekMessage(): BroadcastArgs | undefined {
		return this.messages[0];
	}
}
