<ng-container *ngIf="mode === 'ADD' || mode === 'RENAME'">
	<div class="text">
		<ng-content></ng-content>
		<h6 *ngIf="!entry.secondLineTranslate">{{ entry.secondLine }}</h6>
		<h6 *ngIf="entry.secondLineTranslate" translate="{{ entry.secondLineTranslate }}"></h6>
	</div>
</ng-container>
<ng-container *ngIf="!(mode === 'ADD' || mode === 'RENAME')">
	<div class="text">
		<h3 *ngIf="entry.labelTranslate" [ngClass]="entry.labelClasses" [translate]="entry.labelTranslate"></h3>
		<h3 *ngIf="!entry.labelTranslate" [ngClass]="entry.labelClasses">{{ entry.label }}</h3>
		<h6 *ngIf="!entry.secondLineTranslate">{{ entry.secondLine }}</h6>
		<h6 *ngIf="entry.secondLineTranslate" translate="{{ entry.secondLineTranslate }}"></h6>
		<div class="info change-marker" *ngIf="originalHasChangeMarker">
			<div>
				<span
					*ngIf="entry.original.changeMarker.changed"
					class="badge changed-badge"
					translate="process.compare.changed"
				></span>
				<span
					*ngIf="entry.original.changeMarker.added"
					class="badge added-badge"
					translate="process.compare.added"
				></span>
				<span
					*ngIf="entry.original.changeMarker.deleted"
					class="badge deleted-badge"
					translate="process.compare.deleted"
				></span>
			</div>
			<div>
				<i *ngIf="entry.original.changeMarker.subelements > 0" class="ico ico-exclamation-mark subelements"></i>
			</div>
		</div>
	</div>
	<ng-container> </ng-container
></ng-container>
