import { Component, Input } from "@angular/core";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { NewDialogComponent } from "common/newdialog/dialog.component";

@Component({
	selector: "stages-popup-header",
	templateUrl: "./popup-header.component.html",
})
export class PopupHeaderComponent {
	@Input()
	viewedDialog?: NewDialogComponent;

	constructor(private dialog: DialogAdapter) {}

	close(): void {
		if (!this.viewedDialog) {
			this.dialog.close(undefined);
		} else {
			this.viewedDialog.close();
		}
	}
}
