import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { StagesCommonModule } from "common/common.module";
import { ProcessInterfacesFilterComponent } from "management/process-interfaces/process-interfaces-filter.component";
import { ProcessInterfacesComponent } from "management/process-interfaces/process-interfaces.component";
import { ProcessInterfacesWorkspaceListComponent } from "management/process-interfaces/workspace-list/process-interfaces-workspace-list.component";
import { ProcessInterfacesRoutingModule } from "management/process-interfaces/process-interfaces-routing.module";
import { ProcessInterfacesInterfacesListComponent } from "management/process-interfaces/interfaces-list/process-interfaces-interfaces-list.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

@NgModule({
	imports: [RouterModule, ProcessInterfacesRoutingModule, StagesCommonModule, InfiniteScrollModule],
	declarations: [
		ProcessInterfacesFilterComponent,
		ProcessInterfacesComponent,
		ProcessInterfacesWorkspaceListComponent,
		ProcessInterfacesInterfacesListComponent,
	],
	exports: [
		RouterModule,
		ProcessInterfacesWorkspaceListComponent,
		ProcessInterfacesInterfacesListComponent,
		ProcessInterfacesFilterComponent,
	],
})
export class ProcessInterfacesModule {}
