<stages-drag-scroll #dragScroller [ngClass]="{ 'grab active-grabbing': isGrabbable }" [drag-disabled]="!isGrabbable">
	<ng-container *ngIf="!isZoomToolSupported">
		<div *ngIf="!initializationDone" style="height: 30px"><stages-spin size="sm"></stages-spin></div>
		<stages-image-map
			#map
			[imageMap]="imageMap"
			[width]="imageSize.width"
			[originalWidth]="imageSize.originalWidth"
			[height]="imageSize.height"
			[originalHeight]="imageSize.originalHeight"
			*ngIf="imageLoaded && imageMap"
		></stages-image-map>
		<div #content>
			<img
				#img
				stagesDragScrollItem
				hidefocus="true"
				[ngClass]="{
					visible: initializationDone,
					invisible: !initializationDone,
					'no-image-width-restraint': !fitToPage
				}"
				[attr.id]="'diag_' + id"
				[attr.usemap]="'#' + id"
				[attr.alt]="'process.diagram.generic.title' | translate"
				[src]="src"
				(load)="onImageLoad()"
				(mouseleave)="onMouseLeave($event)"
				*ngIf="src"
			/>
		</div>
	</ng-container>
	<ng-container *ngIf="isZoomToolSupported">
		<div *ngIf="!showDiagram" style="height: 30px"><stages-spin size="sm"></stages-spin></div>
		<div #content>
			<div
				[id]="'img' + id"
				stagesDragScrollItem
				#img
				[ngClass]="{ invisible: !showDiagram }"
				hidefocus="true"
				[innerHTML]="src"
				(mouseleave)="onMouseLeave($event)"
			></div>
		</div>
	</ng-container>
</stages-drag-scroll>
