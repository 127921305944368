import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { ProcessReleaseResource } from "core/stages-client";

@Injectable({ providedIn: "root" })
export class TaskDetailsResolver implements Resolve<stages.process.release.TaskDetails | null> {
	constructor(private processReleaseResource: ProcessReleaseResource, private router: Router) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.process.release.TaskDetails | null> {
		try {
			// "await" is important, because the error is thrown asynchronously.
			// If the promise is not awaited, the error handling does take place.
			return await this.processReleaseResource.getTaskDetails(
				route.paramMap.get("workspaceId")!,
				route.paramMap.get("id")!,
			);
		} catch (e: unknown) {
			console.log(e);
			this.router.navigate([
				"workspace",
				route.paramMap.get("workspaceId"),
				route.paramMap.get("processVersion"),
				"error",
				{
					errorType: "http.notFound.task",
					incidentId: new Date().getTime(),
					isReportable: false,
					isTemporary: false,
				},
			]);
			return null;
		}
	}
}
