import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { DataViewComponent } from "common/data/data-view.component";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { HeadlineTranslation } from "common/page/page-header.component";
import { ProcessReleaseResource } from "core/stages-client";
import { ProcessReleaseDetailsStore } from "process/process-release-details/process-release-details-store.logic";

type ReleaseDetailsPaged = stages.process.release.ReleaseDetailsPaged;
type Approvement = stages.process.release.ApprovalHistory;

@Component({
	templateUrl: "./process-release-details.component.html",
	styleUrls: ["process-release-details.component.scss"],
	selector: "stages-process-release-details",
})
export class ProcessReleaseDetailsComponent implements OnInit {
	@ViewChild("dialog", { static: true })
	dialogViewedChild!: NewDialogComponent;

	static readonly collapsibleGroupName = "processReleaseDetailsCollapsed";
	pageSize: number = 100;
	defaultIconClass = "ico-administration";

	headline: HeadlineTranslation = {
		translate: "process.releasedetails.headline",
	};

	processReleaseDetailsComponent = ProcessReleaseDetailsComponent;

	@ViewChild("dataView", { static: false })
	dataView!: DataViewComponent<ReleaseDetailsPaged>;

	dataSourceModules!: PageableDataSource<ReleaseDetailsPaged>;
	dataSourceProcess!: PageableDataSource<ReleaseDetailsPaged>;

	constructor(
		private route: ActivatedRoute,
		private processReleaseResource: ProcessReleaseResource,
		private mutexService: MutexService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.dataSourceModules = new PageableDataSource<ReleaseDetailsPaged>(
			new ProcessReleaseDetailsStore(this.processReleaseResource, this.pageSize, true),
			this.mutexService,
			this.router,
			this.route,
			"page",
		);

		this.dataSourceProcess = new PageableDataSource<ReleaseDetailsPaged>(
			new ProcessReleaseDetailsStore(this.processReleaseResource, this.pageSize, false),
			this.mutexService,
			this.router,
			this.route,
			"page",
		);
	}

	hasReadPermission(actions: Record<string, boolean>): boolean {
		return actions.ReadOriginModule;
	}

	openProcess(entry: ReleaseDetailsPaged): unknown[] {
		return ["/", "workspace", entry.workspaceId, entry.processVersionName, "process"];
	}

	static getGroupId(releaseDetails: ReleaseDetailsPaged): string {
		return releaseDetails.workspaceName + releaseDetails.processId;
	}

	static getApprovementId(approvement: Approvement): string {
		return approvement.taskName + approvement.userId;
	}
}
