import { Location } from "@angular/common";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector, NgZone, Type } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router, UrlSegmentGroup } from "@angular/router";
import { DoubleSubmitError } from "common/concurrency/mutex.service";
import { AuthenticationService } from "core/authentication.service";
import { hasProperty } from "core/functions";
import { isEqual } from "lodash";
import { lastValueFrom } from "rxjs";
import * as StackTrace from "stacktrace-js";

export type ErrorType = "cms.notavailable" | "general" | "http.conflict" | "http.locked" | "http.notFound" | "noaccess";

@Injectable({ providedIn: "root" })
export class StagesErrorHandler extends ErrorHandler {
	private pendingErrorPost?: boolean;

	private lastError: unknown;

	private alreadyReloading = false;

	constructor(
		// Use Injector to retrieve all used services instead of injecting these services directly, because otherwise we get the following error:
		// ...Cannot instantiate cyclic dependency...
		private readonly injector: Injector,
	) {
		super();
	}

	override handleError(error: unknown): void {
		try {
			this.handleErrorImpl(
				hasProperty(error, "rejection") && hasProperty(error.rejection, "message") ? error.rejection : error,
			); // Unwrap a "Uncaught (in promise)" error, if there is an object with message property (i.e. probably an error) in the "rejection" property
		} catch (e: unknown) {
			// Do no try any more error handling when error handling already failed.
			console.error(`Error handling failed with '${e}'. Original error was: '${error}'`);
		}
	}

	private handleErrorImpl(error: unknown): void {
		if (error instanceof DoubleSubmitError) {
			console.log(error);
			return;
		}

		if (isEqual(error, this.lastError)) {
			if (this.alreadyReloading) {
				// Even if "location.href" was set before and the browser will be reloaded soon, further errors can occur asynchronously in the mean-time (at least in Chrome).
				// Do not show alert message and reload browser again!
				console.log("Ignored repeating error, because already reloading", error);
			} else if (this.pendingErrorPost) {
				// Do not set location.href, before posting the first error to the Stages server is finished.
				// Otherwise the error would not appear in the stages log files.
				console.log("Ignored repeating error, because error post is pending", error);
			} else if (location.href.includes("#/login") && error instanceof HttpErrorResponse && error.status === 403) {
				// ST-31375: Do not show an alert message, if there is a "403 Forbidden" error in the login page
				console.log("Ignored '403 Forbidden' in login page", error);
			} else {
				this.alreadyReloading = true;
				const errorMessage =
					"Internal Error:\nThe same error was detected twice within a short period of time.\n\nPlease inform the Stages support and provide log files and steps how to reproduce this behavior.\n\n";
				if (location.href.endsWith("/home")) {
					// If there is a repeating error even when showing the home page, redirect to the blank page
					alert(errorMessage + "The blank page will be shown in the browser!");
					location.href = "about:blank";
				} else {
					alert(errorMessage + "Stages is going to be reloaded in the browser!");
					location.href = location.href.substr(0, location.href.indexOf("#"));
				}
			}
			// ignore duplicate error handling
			return;
		}

		this.lastError = error;
		setTimeout(() => (this.lastError = undefined), 3000);

		if (this.injectorGet(AuthenticationService).needsAuthentication(error)) {
			return;
		}

		const incidentId = new Date().getTime();
		console.group("Uncaught exception occurred in Stages client code:");
		try {
			console.error("INCIDENT ID:", incidentId);
			// Call Angular default error handler which provides more information than "console.error(error)", especially when there is a problem in an HTML template.
			super.handleError(error);
			if (error instanceof HttpErrorResponse) {
				logHttpErrorResponse(error);
			}
		} finally {
			console.groupEnd();
		}

		if (error instanceof HttpErrorResponse) {
			this.handleHttpErrorResponse(error, incidentId);
		} else {
			// Check if there is an error because Angular tries to load a module lazily but the server is down.
			if (
				hasProperty(error, "message") &&
				typeof error.message === "string" &&
				error.message.startsWith("Loading chunk ")
			) {
				this.showUnavailableError();
			} else {
				void this.handleGeneralError(error, incidentId);
			}
		}
	}

	private handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse, incidentId: number): void {
		const message = getMessage(httpErrorResponse);
		switch (httpErrorResponse.status) {
			case 0:
				this.showUnavailableError();
				break;
			case 403: // Forbidden
				try {
					this.showError("noaccess", incidentId, false, false);
				} catch {
					this.navigate(["login"], {
						queryParams: {
							redirectTo: this.getRedirectOrCurrentLocation(),
							securityException: message,
						},
					});
				}
				break;
			case 404: // Not Found
				// see StagesNoResultException.forWorkspaceNotFound()
				const wrongWorkspaceIdRegex = /Workspace with id .* not found/g;
				const resetWorkspaceIdAndProcessVersion = !!message.match(wrongWorkspaceIdRegex);
				this.showError("http.notFound", incidentId, false, false, resetWorkspaceIdAndProcessVersion);
				break;
			case 409: // Conflict
				this.showError("http.conflict", incidentId, false, true);
				break;
			case 423: // Locked
				this.showError("http.locked", incidentId, false, true);
				break;
			case 503: // Service Unavailable
				this.handle503ServiceUnavailable(httpErrorResponse, incidentId);
				break;
			case 901:
				// auth error is handled in the concrete service implementation (e.g. files service)
				break;
			default:
				this.showError("general", incidentId, true, false);
				break;
		}
	}

	private handle503ServiceUnavailable(httpErrorResponse: HttpErrorResponse, incidentId: number): void {
		const message = getMessage(httpErrorResponse);
		const statusDetail = getStatusDetail(httpErrorResponse);
		switch (statusDetail) {
			case "searchEngineNotAvailable":
				// Search engine not available -> the caller takes care for this error.
				break;
			case "cmsNotAvailable":
				this.showError("cms.notavailable", incidentId, false, true);
				break;
			default:
				this.navigate(
					[
						"errorMessage",
						{
							message: message,
							title: getTitle(statusDetail),
						},
					],
					{ skipLocationChange: true },
				);
		}
	}

	private async handleGeneralError(error: unknown, incidentId: number): Promise<void> {
		const httpClient = this.injectorGet(HttpClient);
		// The following if-statement is a safeguard against an infinite loop in sending logging requests to the server.
		// For example an infinite loop could occur if the logger web service was declared to produce json,
		// request deserialization failed and a exception mapper which does not produce json handled the exeption.
		// The client code then would not be able to deserialize the exception so that this exception handler decorator
		// would try again to call the logger web service which would fail again and so on.
		if (!this.pendingErrorPost) {
			this.pendingErrorPost = true;
			try {
				const li = await getLoggerInput(error, incidentId);
				await lastValueFrom(httpClient.post("app/logger/error", li));
			} finally {
				this.pendingErrorPost = false;
			}
		}
		this.showError("general", incidentId, true, false);
	}

	private getRedirectOrCurrentLocation(): string {
		const location = this.injectorGet(Location);
		// I think using ActivatedRoute is safe because of query params are global
		const queryParamMap = this.injector.get(ActivatedRoute).snapshot.queryParamMap;
		if (queryParamMap.has("redirectTo")) {
			const path = location.path(true);
			return path === "/" || path === "/login" ? "" : path;
		}
		return queryParamMap.get("redirectTo")!;
	}

	showUnavailableError(): void {
		// Is this an "unavailable" error for a retry on the "unavailable" page?
		let redirectTo = this.injectorGet(ActivatedRoute).snapshot.queryParamMap.get("redirectTo");
		if (!redirectTo) {
			redirectTo = this.injectorGet(Location).path(true);
		}
		this.navigate(["unavailable"], {
			queryParams: { redirectTo: redirectTo },
		});
	}

	showError(
		errorType: ErrorType,
		incidentId: number,
		isReportable: boolean,
		isTemporary: boolean,
		resetWorkspaceIdAndProcessVersion = false,
	): void {
		const router = this.injectorGet(Router);
		const workspaceIdAndProcessVersion = getWorkspaceIdAndProcessVersion(
			router.parseUrl(router.url).root,
			resetWorkspaceIdAndProcessVersion,
		);
		this.navigate(
			[
				"workspace",
				workspaceIdAndProcessVersion.workspaceId,
				workspaceIdAndProcessVersion.processVersion,
				"error",
				{
					errorType: errorType,
					incidentId: incidentId,
					isReportable: isReportable,
					isTemporary: isTemporary,
				},
			],
			{
				// It is difficult to decide what to do with the browser history in case of an error:
				// 1. Show error page URL in address bar, replace latest URL in history (location = replace)
				// 2. Show error page URL in address bar, keep latest URL in history (location = true)
				// 3. Keep latest URL in address bar (location = false)
				// Solution 1 would be the right thing if the latest URL "caused" the error (for instance, an ID of a non-existing element in the URL).
				// The "Back" button would navigate to the latest URL that is "OK" (the URL before the latest URL)
				// Solution 2 would be the right thing if the latest URL is "OK" and the error occurred by some user interaction on the page (for instance, in a menu action handler).
				// The "Back" button would navigate to the latest URL
				// However, we cannot detect here whether an error was "caused" by the URL (directly or indirectly)
				// So, we keep the latest URL in the address bar and it is up to the user
				// to press either "Back" (if this URL is not "OK") or "Reload" (if the URL is "OK")
				skipLocationChange: true,
			},
		);
	}

	navigate(commands: unknown[], extras?: NavigationExtras): void {
		const router = this.injectorGet(Router);
		const zone = this.injectorGet(NgZone);
		void zone.run(async () => router.navigate(commands, extras));
	}

	private injectorGet<T>(token: Type<T>): T {
		return this.injector.get<T>(token);
	}
}

function logHttpErrorResponse(httpErrorResponse: HttpErrorResponse): void {
	const error = httpErrorResponse.error;
	if (!error) {
		return;
	}
	// check if error is a Java Bean Validation constraint violation
	if (error.classViolations) {
		for (const [key, value] of Object.entries(error)) {
			if (value) {
				if (value instanceof Array) {
					for (const element of value) {
						console.error("Java Bean Validation constraint violation:", element);
					}
				} else {
					console.error("Java Bean Validation constraint violation:", "key:", key, "value:", value);
				}
			}
		}
	} else if (error.message) {
		console.error("HttpErrorResponse.error.message: ", error.message);
	} else {
		console.error("HttpErrorResponse.error: ", error);
	}
}

function getWorkspaceIdAndProcessVersion(
	urlSegmentGroup: UrlSegmentGroup,
	resetWorkspaceIdAndProcessVersion: boolean,
): { workspaceId: string; processVersion: string } {
	if (urlSegmentGroup.children.primary && !resetWorkspaceIdAndProcessVersion) {
		const segments = urlSegmentGroup.children.primary.segments;
		if (segments[0].path === "workspace" && segments.length > 2) {
			return {
				processVersion: segments[2].path,
				workspaceId: segments[1].path,
			};
		}
	}

	return {
		processVersion: "_vv",
		workspaceId: "1",
	};
}

function getMessage(httpErrorResponse: HttpErrorResponse): string {
	const error = httpErrorResponse.error;
	if (!error) {
		return "";
	}

	const message = error.message;
	if (typeof message !== "string") {
		return "";
	}

	return message;
}

function getStatusDetail(httpErrorResponse: HttpErrorResponse): string {
	const error = httpErrorResponse.error;
	if (!error) {
		return "";
	}

	const statusDetail = error.statusDetail;
	if (typeof statusDetail !== "string") {
		return "";
	}

	return statusDetail;
}

function getTitle(statusDetail: string): string {
	switch (statusDetail) {
		case "startup":
			return "Startup Error";
		case "license":
			return "License Error";
		default:
			return "Error";
	}
}

export async function getLoggerInput(error: unknown, incidentId: number): Promise<stages.logging.LoggerInput> {
	if (error instanceof Error) {
		const stack = await StackTrace.fromError(error);
		return {
			message: `${error.message} (incidentId: ${incidentId})`,
			stacktrace: stack.map((frame: StackTrace.StackFrame) => {
				return {
					columnNumber: frame.getColumnNumber(),
					fileName: frame.getFileName(),
					functionName: frame.getFunctionName(),
					lineNumber: frame.getLineNumber(),
				};
			}),
		};
	}

	return {
		message: `${error} (incidentId: ${incidentId})`,
		stacktrace: [],
	};
}
