import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { IQuery } from "process/tailoring/models/query.interface";
import { TailoringWizardLogicService } from "process/tailoring/wizard-logic.service";

@Injectable({ providedIn: "root" })
export class TailoringWizardQueryNameResolver implements Resolve<IQuery> {
	constructor(private wizardLogic: TailoringWizardLogicService) {}

	resolve(route: ActivatedRouteSnapshot): IQuery {
		const queryIdToDisplay = route.params.queryId;
		const workspaceId = route.params.workspaceId;
		const processVersion = route.params.processVersion;

		const root = route.parent!.data.root;
		this.wizardLogic.initMapsWithRoot(root, workspaceId, processVersion);
		const query = this.wizardLogic.getQueryById(queryIdToDisplay);
		if (query) {
			return query;
		}
		return root.queries[0];
	}
}
