<ng-container *ngIf="self$ | async as self">
	<ng-template #itemTemplate let-item>
		<a
			class="label"
			[attr.href]="item.user.emailAddress && dataView.mode === Mode.VIEW ? 'mailto:' + item.user.emailAddress : null"
		>
			<div class="avatar-cell-48">
				<stages-user-image
					[userName]="item.user.name"
					[userId]="item.user.id"
					[version]="item.user.profileImageVersion"
				></stages-user-image>
			</div>
			<div class="text center">
				<h4>
					<span
						translate="process.element.userassignments.label"
						[translateParams]="{ fullname: item.user.fullname, username: item.user.name }"
					></span
					><i class="ico ico-email ico-inline" *ngIf="hasEmail(item.user)"></i>
				</h4>
				<ng-container *ngIf="isCollected()">
					<p *ngFor="let element of item.elements">{{ element.name }}</p>
				</ng-container>
				<ng-container *ngIf="!isCollected()">
					<p *ngIf="item.elements[0].deputy" translate="process.element.userassignments.deputy"></p>
					<p *ngIf="dataView.mode !== Mode.EDIT || editedAssignment !== item.user.id">{{ item.elements[0].comment }}</p>
					<input
						#commentInput
						*ngIf="dataView.mode === Mode.EDIT && editedAssignment === item.user.id"
						id="uascomment{{ item.user.id }}"
						class="comment"
						type="text"
						[attr.placeholder]="'editComment.placeholder' | translate"
						[(ngModel)]="commentInputText"
						(blur)="onCommentInputBlur()"
						(stagesKeyUp)="onCommentInputKeyUp($event, item, self.id)"
					/>
				</ng-container>
				<p
					*ngIf="item.alreadyAssigned && isCreatorKnown(item)"
					class="error"
					translate="process.element.userassignments.alreadyAdded"
					[translateParams]="{ user: item.creator.fullname, date: item.creationDate }"
				></p>
				<p
					*ngIf="item.alreadyAssigned && !isCreatorKnown(item)"
					class="error"
					translate="process.element.userassignments.alreadyAdded.unknown"
				></p>
			</div>
		</a>
		<stages-menu
			menuId="uam{{ item.elements[0].guid }}"
			[noCollapse]="true"
			class="action"
			iconClasses="ico ico-more-vertical"
			openTo="left"
			[items]="menuItems"
			[context]="item"
			keepOpenOnSwitchClick="true"
		>
		</stages-menu>
	</ng-template>

	<ng-template #autoCompleteTemplate let-user>
		<div class="item">
			<div class="avatar-cell">
				<stages-user-image
					[userName]="user.name"
					[userId]="user.id"
					[version]="user.profileImageVersion"
				></stages-user-image>
			</div>
			<div class="label">
				<h4 class="primary">{{ user.fullname }} ({{ user.username }})</h4>
				<p *ngIf="user.email_address" class="secondary">{{ user.email_address }}</p>
			</div>
		</div>
	</ng-template>

	<stages-responsive-data-view
		#dataView
		[dataSource]="dataSource"
		[listItemTemplate]="itemTemplate"
		[autoCompleteTemplate]="autoCompleteTemplate"
		[translateNone]="translateNone"
		[idFn]="UserAssignmentsComponent.getId"
		[disabledFn]="UserAssignmentsComponent.isDisabled"
		[showMore]="true"
		[pageSize]="pageSize"
	>
	</stages-responsive-data-view>
</ng-container>
