import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

interface SupportInformation {
	id?: string;
	type: string;
	name?: string;
	link?: string;
	newtab: boolean;
}

@Component({
	selector: "stages-support",
	templateUrl: "./support.component.html",
	styleUrls: ["./support.component.scss"],
})
export class SupportComponent {
	numberOfPossibleProperties: number = 10;
	supportDetails: SupportInformation[] = [];
	translateService: TranslateService;

	constructor(translateService: TranslateService) {
		this.translateService = translateService;
		this.translateService.onLangChange.subscribe(() => {
			this.supportDetails = this.getSupportInformation();
		});
		this.supportDetails = this.getSupportInformation();
	}

	private getSupportInformation(): SupportInformation[] {
		const gatheredSupportInformation: SupportInformation[] = [];

		for (let i = 1; i <= this.numberOfPossibleProperties; i++) {
			let pushedObjects: number = 0;

			const tel = this.getInformationByType("tel", i.toString());

			if (tel.name) {
				gatheredSupportInformation.push(tel);
				pushedObjects++;
			}

			const email = this.getInformationByType("email", i.toString());

			if (email.link) {
				gatheredSupportInformation.push(email);
				pushedObjects++;
			}

			const link = this.getInformationByType("link", i.toString());

			if (link.link) {
				gatheredSupportInformation.push(link);
				pushedObjects++;
			}

			if (pushedObjects === 3 && gatheredSupportInformation[i].type !== gatheredSupportInformation[i - 1].type) {
				gatheredSupportInformation.push({ type: "empty", newtab: false });
			}
		}

		return gatheredSupportInformation;
	}

	private getInformationByType(type: string, iterator: string): SupportInformation {
		// ST-32258: Use the raw "translations" object instead of translateService.instant() because we do not want to fall back to the default language
		// We really want "undefined" when an additional support information property is not defined for a language
		const currentLang = this.translateService.currentLang;
		const translations = this.translateService.translations;
		if (currentLang && translations) {
			const translationsInCurrentLanguage = translations[currentLang];
			if (translationsInCurrentLanguage) {
				return {
					id: iterator,
					type: type,
					name: translationsInCurrentLanguage[
						this.getStringToTranslate(type, iterator, type === "tel" ? undefined : "name")
					],
					link: this.getLink(
						translationsInCurrentLanguage[this.getStringToTranslate(type, iterator, "href")],
						translationsInCurrentLanguage[this.getStringToTranslate(type, iterator, "href", "newtab")],
					),
					newtab: !!translationsInCurrentLanguage[this.getStringToTranslate(type, iterator, "href", "newtab")],
				};
			}
		}

		// Be very defensive in this component :-)
		return {
			type: type,
			name: undefined,
			link: undefined,
			newtab: false,
		};
	}

	private getLink(href1: string, href2: string): string {
		return href1 ? href1 : href2;
	}

	private getStringToTranslate(value: string, iterator: string, ending?: string, newtab?: string): string {
		const typeAndIterator = value + "." + iterator;

		if (ending && newtab) {
			return "dashboard.support." + typeAndIterator + "." + ending + "." + newtab;
		}

		return ending ? "dashboard.support." + typeAndIterator + "." + ending : "dashboard.support." + typeAndIterator;
	}

	getTabTarget(newTab: boolean): string {
		return newTab ? "_blank" : "_self";
	}
}
