import { ValidatorFn } from "@angular/forms";
import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class DateInput extends InputBase<string> {
	constructor(args: DateInputArgs) {
		super({
			key: args.key,
			value: typeof args.value === "string" ? args.value.slice(0, 10) : args.value,
			type: "date",
			inputFieldType: args.textInputOnly ? "text" : "date",
			required: args.required,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});

		if (args.textInputOnly) {
			super.addValidator(this.validateDatePattern());
		}
	}

	validateDatePattern(): ValidatorFn {
		return (control) => {
			if (!control.value) {
				return null;
			}

			const regExp = new RegExp("^\\d{4}-\\d{2}-\\d{2}$");
			return regExp.test(control.value)
				? null
				: {
						date: {
							valid: false,
						},
				  };
		};
	}
}

export class DateInputArgs extends CommonArgs<string> {
	textInputOnly?: boolean;
}
