import { Component, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SlotService } from "common/slot.service";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { get } from "lodash";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { ViewSpec } from "process/view/type.interface";
import { Observable, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-process-view",
	templateUrl: "./process-view.component.html",
	providers: [SlotService],
})
export class ProcessViewComponent extends BaseComponent implements OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		componentService: ComponentService,
		slotService: SlotService,
		private viewService: ViewService,
		private mainService: MainService,
	) {
		super(componentService, slotService);
		// prevent ExpressionChangedAfterItHasBeenCheckedError
		setTimeout(() => {
			this.mainService.applicationState.toolbarVisible = true;
		});

		this.viewService
			.awaitSelfElementObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe((view) => {
				this.type = (view.viewType as ViewSpec).content;
				super.updateSlots(this.type);
			});
	}

	override ngOnDestroy(): void {
		// "super.ngOnDestroy();" is not called. This is perhaps an error, but adding it was explicitely not wanted as part of ST-33629.
		this.mainService.applicationState.toolbarVisible = false;

		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static provideTitle(_translateService: TranslateService, mergedRouteData: any): Observable<string> {
		const preferedLabel = get(mergedRouteData, "view.processView.label", "");
		const preferedSubLabel = get(mergedRouteData, "workspaceView.currentWorkspace.name", "");
		return preferedLabel && preferedSubLabel ? of(`${preferedLabel} - ${preferedSubLabel}`) : of("");
	}
}
