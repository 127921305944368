<form #form="ngForm" class="form-add-item">
	<div class="text">
		<h4>
			<input
				#input="ngModel"
				[(ngModel)]="name"
				id="name"
				name="name"
				(stagesKeyUp)="onKeyUp($event)"
				(input)="change()"
				(blur)="blur()"
				required
				[attr.placeholder]="placeholder"
				[stagesMaxLength]="maxLength"
				pattern="{{ pattern || '.*' }}"
			/>
		</h4>
		<div class="info">
			<!-- workaround maybe better with FormService.setServerSideErrorsOnFormControls but has to be handled in dataview component -->
			<div class="errors error" *ngIf="itemExist" [translate]="existsMessageKey"></div>
			<div class="errors" *ngIf="input.errors && wasNonEmpty && (input.dirty || input.touched)">
				<div *ngIf="input.errors.required" class="error" [translate]="'input.required'"></div>
				<div
					*ngIf="input.errors.max"
					class="error"
					[translate]="'input.maxLength'"
					[translateParams]="{ length: maxLength }"
				></div>
				<div *ngIf="input.errors.pattern && patternMessageKey" class="error" [translate]="patternMessageKey"></div>
				<!-- <div *ngIf="input.errors.exists" class="error" [translate]="existsMessageKey"></div> -->
			</div>
			<div class="errors" *ngIf="hasCustomErrors() && (input.dirty || input.touched)">
				<ng-content></ng-content>
			</div>
			<div class="max-length">{{ input.value?.length || 0 }}/{{ maxLength }}</div>
		</div>
	</div>
</form>
