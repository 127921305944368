<article class="comments module widget" *ngIf="viewLoaded">
	<ng-container *ngIf="canCreateComments()">
		<form class="form" [formGroup]="group">
			<div class="input-container relative">
				<textarea
					formControlName="newComment"
					(focus)="setShowButton(true)"
					(stagesClickOutside)="setShowButton(false)"
					id="newComment"
					name="newComment"
					rows="0"
					[placeholder]="'process.element.comments.placeholder' | translate"
					stagesAutoGrow
					class="icon-spacer"
				></textarea>
				<i class="ico ico-smile"></i>
				<div class="info">
					<div class="errors">
						<ng-container *ngFor="let error of getErrors('newComment')" [ngSwitch]="error">
							<div
								*ngSwitchCase="'maxlength'"
								class="error"
								[translate]="'input.maxLength'"
								[translateParams]="{ length: commentMaxLength }"
								role="alert"
							></div>
						</ng-container>
					</div>
					<div class="maxlength">{{ getCommentLengthInfo("newComment") }}</div>
				</div>
			</div>
			<div [hidden]="!showButton" class="input-container right">
				<button
					[disabled]="!isSubmitAllowed('newComment')"
					(click)="addComment()"
					class="button sm save"
					translate="add"
				></button>
			</div>
		</form>
	</ng-container>

	<h3
		*ngIf="getTotalCommentCount()"
		[translate]="getCommentCountMessageKey()"
		[translateParams]="{ count: getTotalCommentCount() }"
	></h3>

	<ul class="list" *ngIf="hasComments()" [formGroup]="group">
		<ng-container *ngFor="let comment of getItems(); let last = last">
			<li class="list-item">
				<div class="label">
					<div class="avatar-cell">
						<stages-user-image
							[userName]="comment.creator.fullname"
							userId="{{ comment.creator.id }}"
							version="{{ comment.creator.profileImageVersion }}"
						></stages-user-image>
					</div>
					<div class="text fullsize center">
						<div>
							<a class="user clickable"
								><h4>{{ comment.creator.fullname }}</h4></a
							>
							<h6>{{ comment.creationDate }}</h6>
							<h6
								*ngIf="comment.modified"
								[translate]="'process.element.comments.modified'"
								[translateParams]="comment"
							></h6>
						</div>
						<p *ngIf="!isEditOn(comment.id)" class="preformatted">{{ comment.value }}</p>
						<div class="relative" *ngIf="isEditOn(comment.id)">
							<textarea
								[formControlName]="'comment' + comment.id"
								id="comment"
								name="comment"
								rows="0"
								[placeholder]="'process.element.comments.placeholder' | translate"
								stagesAutoGrow
								class="icon-spacer"
							></textarea>
							<i class="ico ico-smile"></i>
							<div class="info">
								<div class="errors">
									<ng-container *ngFor="let error of getErrors('comment' + comment.id)" [ngSwitch]="error">
										<div
											*ngSwitchCase="'maxlength'"
											class="error"
											[translate]="'input.maxLength'"
											[translateParams]="{ length: commentMaxLength }"
											role="alert"
										></div>
									</ng-container>
								</div>
								<div class="maxlength">{{ getCommentLengthInfo("comment" + comment.id) }}</div>
							</div>
							<div class="buttons">
								<button
									class="sm cancel button-space-right"
									(click)="cancelEdit(comment.id)"
									translate="cancel"
								></button>
								<button
									class="sm save"
									(click)="saveEditExistingComment(comment, 'comment' + comment.id)"
									[disabled]="!isSubmitAllowed('comment' + comment.id)"
									translate="save"
								></button>
							</div>
						</div>
					</div>
					<stages-menu
						*ngIf="!isEditOn(comment.id)"
						class="action"
						[menuId]="'commentMenu'"
						iconClasses="ico ico-more-vertical"
						openTo="bottom"
						[items]="getMenuItems(comment)"
					></stages-menu>
				</div>
			</li>
			<li *ngIf="!last" class="divider"></li>
			<li class="list-item" *ngIf="last && isShowMoreButtonDisplayed()">
				<div class="label">
					<div class="avatar-cell"></div>
					<a class="label clickable" (click)="loadMore()">
						<i class="ico ico-refresh"></i>
						<h4 class="text">{{ "process.element.comments.showMore" | translate }}</h4>
					</a>
				</div>
			</li>
		</ng-container>
	</ul>

	<p *ngIf="!hasComments() && !canCreateComments()" class="empty" translate="none"></p>
</article>
