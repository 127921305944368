import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

type ProcessView = stages.process.ProcessView;

@Component({
	selector: "stages-navigation-entry-two-line-split-rating",
	templateUrl: "./two-line-split-rating-navigation-entry.component.html",
	styleUrls: ["./two-line-split-rating-navigation-entry.component.scss"],
})
export class TwoLineSplitRatingNavigationEntryComponent implements OnChanges {
	private readonly CSS_BY_RATING: StringToString = {
		NOT_RATED: "rating-not-rated",
		NONE: "rating-none",
		INCOMPLETE: "rating-incomplete",
		FAIR: "rating-fair",
		COMPLETE: "rating-complete",
	};

	@Input()
	entry!: TwoLineEntry;

	@Input()
	mode!: NAV_ENTRY_DISPLAY_MODE;

	iconClass!: string;

	ngOnChanges(changes: SimpleChanges): void {
		this.iconClass = changes.entry && this.entry.original ? this.getIconClass(this.entry.original) : "";
	}

	private getIconClass(element: ProcessView): string {
		const ratingContainer = element.complianceRatingContainer;
		if (ratingContainer) {
			const rating = ratingContainer.rating.rating;
			if (rating && this.CSS_BY_RATING[rating]) {
				return this.CSS_BY_RATING[rating];
			}
		}
		return "";
	}
}
