import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LoadingErrorService {
	error!: HttpErrorResponse;

	setError(error: HttpErrorResponse): void {
		this.error = error;
	}

	getError(): HttpErrorResponse {
		return this.error;
	}
}
