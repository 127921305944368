<article class="external-link">
	<div class="form">
		<div class="input-container lg required" [formGroup]="form">
			<label for="editor-link-url" class="input-label" translate="editor.link.url"></label>
			<input
				id="editor-link-url"
				#nextTab
				type="text"
				class="text fullsize"
				formControlName="url"
				(ngModelChange)="setTargetUrl($event)"
				stagesAutofocus
			/>
			<div class="info">
				<div class="errors">
					<ng-container *ngIf="form.get('url')!.dirty || form.get('url')!.touched">
						<div
							class="error"
							role="alert"
							*ngIf="form.get('url')!.hasError('required')"
							translate="input.required"
						></div>
						<div
							class="error"
							role="alert"
							*ngIf="form.get('url')!.hasError('urlPattern')"
							translate="input.pattern.url"
						></div>
					</ng-container>
					<div class="error" role="alert" *ngIf="!form.get('url')!.errors || form.get('url')!.errors?.length === 0">
						&nbsp;
					</div>
				</div>
			</div>
		</div>
		<div class="input-container lg">
			<label for="editor-link-name" class="input-label" translate="editor.link.name"></label>
			<input
				id="editor-link-name"
				#nextTab
				type="text"
				class="text fullsize"
				[ngModel]="displayName"
				(ngModelChange)="setDisplayName($event)"
			/>
		</div>
	</div>
	<div class="buttons-bottom">
		<div class="spacer"></div>
		<button class="sm" translate="insert" (click)="onInsert()" [disabled]="isButtonDisabled(form)"></button>
	</div>
</article>
