import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateParser } from "@ngx-translate/core";
import { DialogService } from "common/dialog/dialog.service";
import { MainService } from "core/main.service";
import { NotificationService } from "core/notification.service";
import { LanguageService } from "core/translate/language.service";
import { StagesTranslateParser } from "core/translate/stages-translate.parser";
import { ApplicationState } from "core/volatile-application-state.model";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-main",
	templateUrl: "./main.component.html",
	styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnDestroy {
	private destroy$ = new Subject<boolean>();

	applicationState: ApplicationState;
	menuItems: unknown[] = [];
	dialogActive: boolean = false;
	searchOpen: boolean = false;
	bannerEnabled: boolean = false;
	private oldToolbarVisibleState: boolean = false;

	constructor(
		private mainService: MainService,
		private route: ActivatedRoute,
		private languageService: LanguageService,
		private translateParser: TranslateParser,
		private notificationService: NotificationService,
		private dialogService: DialogService,
	) {
		this.applicationState = mainService.applicationState;

		// update language setting on workspace change
		this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
			(this.translateParser as StagesTranslateParser).setCurrentMessageScope(
				data.workspaceView.currentWorkspace.messageScope,
			);
			this.languageService.determineAndApplyLanguage(
				this.route.snapshot.paramMap.get("processVersion")!,
				data.applicationContext.preferredLanguage,
			);

			this.bannerEnabled = data.applicationContext.bannerEnabled;
		});

		this.mainService.menuItems$.pipe(takeUntil(this.destroy$)).subscribe((menuItems) => (this.menuItems = menuItems));
		this.notificationService.subscribe("system", {});
		this.notificationService.setHandlers("system", {
			MAINTENANCE_STARTED: (maintenanceForm: stages.administration.maintenance.MaintenanceForm) => {
				this.dialogService.warn(maintenanceForm.message);
			},
		});

		this.applicationState.isSearchOpen.pipe(takeUntil(this.destroy$)).subscribe((isSearchOpen: boolean) => {
			this.searchOpen = isSearchOpen;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();

		this.notificationService.unsubscribe("system");
	}

	onDialogActivate(): void {
		this.dialogActive = true;
		this.oldToolbarVisibleState = this.applicationState.toolbarVisible;
		// timeout needed in order to come after ProcessViewComponent that also uses timeout
		setTimeout(() => {
			this.applicationState.toolbarVisible = false;
		});
	}

	onDialogDeactivate(): void {
		this.dialogActive = false;
		this.applicationState.toolbarVisible = this.oldToolbarVisibleState;
	}

	onPopupOpened($event: Event): void {
		this.mainService.popupOpenState$.next(true);
	}

	onPopupClosed($event: Event): void {
		this.mainService.popupOpenState$.next(false);
	}
}
