import { Component, Input } from "@angular/core";
import { BreadcrumbItem } from "common/breadcrumb/breadcrumb-item";

export interface BreadCrumbTemplate {
	links: boolean;
	path: Array<BreadcrumbItem>;
}

@Component({
	selector: "stages-breadcrumb",
	templateUrl: "breadcrumb.component.html",
})
export class BreadcrumbComponent {
	@Input() links?: boolean;
	@Input() path?: Array<BreadcrumbItem>;
}
