import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { EditableDescription } from "common/editor/description.service";
import { nullToUndefined } from "core/functions";
import { ProcessElementsResource } from "core/stages-client";

const TYPE = "description";

@Injectable({ providedIn: "root" })
export class DescriptionsReadonlyResolver implements Resolve<EditableDescription> {
	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<EditableDescription> {
		const descriptionTO = await this.processElementsResource.getReadonlyDescriptionResource(
			route.paramMap.get("twid")!,
			TYPE,
			route.paramMap.get("identity")!,
		);
		const editableDescription = {
			description: descriptionTO.editableDescription,
			isEditable: descriptionTO.editable,
			elementLabel: nullToUndefined(descriptionTO.elementLabel),
		};
		return editableDescription;
	}
}
