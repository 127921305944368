<map [attr.name]="imageMap.name" *ngIf="imageMap">
	<!-- eslint-disable-next-line -- Eslint can not handle area tags. Should be fixed in future eslint versions -->
	<area
		*ngFor="let area of imageMap.areas"
		[shape]="area.shape"
		[coords]="
			area.coords
				| zoom: getXZoomFactor(imageMap, width, originalWidth):getYZoomFactor(imageMap, height, originalHeight)
		"
		[alt]="area.alt"
		[title]="area.title"
		[href]="getHref(area)"
		[target]="getTarget(area)"
		rel="noopener noreferrer"
	/>
</map>
