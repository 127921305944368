<article class="card">
	<form class="form" [formGroup]="form">
		<div class="input-container">
			<div class="compare">
				<div class="animation-container-left" [ngClass]="{ inactive: !leftDescriptionViewable }">
					<div class="input-container right buttons"></div>
					<div class="header">
						<stages-tabs [wrap]="true">
							<stages-tab-no-route
								*ngFor="let language of translation.languages"
								[active]="translation.languageA === language.id"
								(activate)="onLanguageChangeA(translation, language.id!)"
								[warning]="!form.get(language.id!)!.valid"
							>
								<h5 [translate]="language.name"></h5>
							</stages-tab-no-route>
						</stages-tabs>
					</div>
					<ng-container *ngFor="let language of translation.languages">
						<div
							*ngIf="!translation.isIndexElement && language.id === translation.languageA"
							[formGroupName]="language.id"
						>
							<label class="input-label" for="nameA" translate="process.element.edit.name"></label>
							<input
								id="nameA"
								name="nameA"
								type="text"
								class="text fullsize"
								tabindex="1"
								formControlName="name"
								stagesAutofocus
							/>
							<div class="info">
								<div
									*ngIf="
										form.get(language.id!)!.get('name')!.errors &&
										(form.get(language.id!)!.get('name')!.dirty || form.get(language.id!)!.get('name')!.touched)
									"
								>
									<div
										*ngIf="form.get(language.id!)!.get('name')!.hasError('maxlength')"
										class="error"
										translate="input.maxLength"
										[translateParams]="{ length: '255' }"
										role="alert"
									></div>
									<div
										*ngIf="form.get(language.id!)!.get('name')!.hasError('pattern')"
										class="error"
										translate="process.element.edit.name.pattern"
									></div>
									<div
										*ngIf="form.get(language.id!)!.get('name')!.hasError('required')"
										class="error"
										translate="input.required"
									></div>
								</div>
								<div class="maxlength">{{ form.get(language.id!)!.get("name")!.value?.length || 0 }}/255</div>
							</div>
						</div>
					</ng-container>
					<div class="description">
						<div>
							<label class="input-label" for="descriptionA" translate="process.element.edit.description"></label>
						</div>
						<stages-description-editor
							#descriptionA
							[editorId]="'descriptionA'"
							[modifiable]="true"
							[unsafe]="false"
							[description]="translation.translatedElements[translation.languageA].displayDescription"
							[processTypeIdent]="'process'"
							[pluginRegistry]="pluginRegistry"
							[beanType]="beanType"
							[beanId]="beanId"
							[beanIdentity]="beanIdentity"
							[workspaceId]="workspaceId"
							[pv]="pv"
						>
						</stages-description-editor>
					</div>
				</div>

				<div class="separator"></div>

				<div class="animation-container-right" [ngClass]="{ inactive: leftDescriptionViewable }">
					<div class="input-container right buttons">
						<button
							type="submit"
							class="button sm save"
							name="submit"
							tabindex="101"
							(click)="save(translation)"
							[disabled]="!form.valid"
						>
							<span translate="save" *ngIf="!isSaving"></span>
							<stages-spin size="sm" *ngIf="isSaving"></stages-spin>
						</button>
						<button
							type="button"
							class="button sm cancel"
							name="cancel"
							tabindex="100"
							(click)="cancel(translation)"
							translate="cancel"
						></button>
					</div>
					<div class="header">
						<stages-tabs [wrap]="true">
							<stages-tab-no-route
								*ngFor="let language of translation.languages"
								[active]="translation.languageB === language.id"
								(activate)="onLanguageChangeB(translation, language.id!)"
								[warning]="!form.get(language.id!)!.valid"
							>
								<h5 [translate]="language.name"></h5>
							</stages-tab-no-route>
						</stages-tabs>
					</div>
					<ng-container *ngFor="let language of translation.languages">
						<div
							*ngIf="!translation.isIndexElement && language.id === translation.languageB"
							[formGroupName]="language.id"
						>
							<label class="input-label" for="nameB" translate="process.element.edit.name"></label>
							<input
								id="nameB"
								name="nameB"
								type="text"
								class="text fullsize"
								tabindex="1"
								formControlName="name"
								stagesAutofocus
							/>
							<div class="info">
								<div
									*ngIf="
										form.get(language.id!)!.get('name')!.errors! &&
										(form.get(language.id!)!.get('name')!.dirty || form.get(language.id!)!.get('name')!.touched)
									"
								>
									<div
										*ngIf="form.get(language.id!)!.get('name')!.hasError('maxlength')"
										class="error"
										[translate]="'input.maxLength'"
										[translateParams]="{ length: '255' }"
										role="alert"
									></div>
									<div
										*ngIf="form.get(language.id!)!.get('name')!.hasError('pattern')"
										class="error"
										[translate]="'process.element.edit.name.pattern'"
									></div>
									<div
										*ngIf="form.get(language.id!)!.get('name')!.hasError('required')"
										class="error"
										translate="input.required"
									></div>
								</div>
								<div class="maxlength">{{ form.get(language.id!)!.get("name")!.value?.length || 0 }}/255</div>
							</div>
						</div>
					</ng-container>
					<div class="description">
						<div>
							<label class="input-label" for="descriptionB" translate="process.element.edit.description"></label>
						</div>
						<stages-description-editor
							#descriptionB
							[editorId]="'descriptionB'"
							[modifiable]="true"
							[unsafe]="false"
							[description]="translation.translatedElements[translation.languageB].displayDescription"
							[processTypeIdent]="'process'"
							[pluginRegistry]="pluginRegistry"
							[beanType]="beanType"
							[beanId]="beanId"
							[beanIdentity]="beanIdentity"
							[workspaceId]="workspaceId"
							[pv]="pv"
						>
						</stages-description-editor>
					</div>
					<div class="input-container right buttons">
						<button
							type="submit"
							class="button sm save"
							name="submit"
							tabindex="4"
							(click)="save(translation)"
							[disabled]="!form.valid"
						>
							<span translate="save" *ngIf="!isSaving"></span>
							<stages-spin size="sm" *ngIf="isSaving"></stages-spin>
						</button>
						<button
							type="button"
							class="button sm cancel"
							name="cancel"
							tabindex="3"
							(click)="cancel(translation)"
							translate="cancel"
						></button>
					</div>
				</div>
			</div>
		</div>
	</form>
	<footer [ngClass]="{ inactive: leftDescriptionViewable }">
		<div class="buttons-bottom buttons-right buttons">
			<button
				type="submit"
				class="button sm save"
				name="submit"
				tabindex="4"
				(click)="save(translation)"
				[disabled]="!form.valid"
			>
				<span translate="save" *ngIf="!isSaving"></span>
				<stages-spin size="sm" *ngIf="isSaving"></stages-spin>
			</button>
			<button
				type="button"
				class="button sm cancel"
				name="cancel"
				tabindex="3"
				(click)="cancel(translation)"
				translate="cancel"
			></button>
		</div>
	</footer>
</article>
