import { Injectable } from "@angular/core";
import { Folder } from "common/associations/association-browser.interface";
import { AssociationTarget } from "common/associations/association-browser.service";

export class Comments {
	count = 0;
	set: StringToString = {};
	private initialSet: StringToString = {};
	private modified = false;

	private visitedTargets: Record<string, AssociationTarget> = {};

	constructor(private key: string, private store: Map<string, Comments>, private keyFunction: (e: Folder) => string) {}

	getKey(): string {
		return this.key;
	}

	isModified(): boolean {
		return this.modified;
	}

	setModified(): void {
		this.modified = this.getModified().length > 0;
	}

	emptyToNull(aString: string): string | null {
		if (!aString) {
			return null;
		}
		const trimmed = aString.trim();
		if (trimmed.length === 0) {
			return null;
		}
		return trimmed;
	}

	/**
	 * Has to be called whenever a element is selected or deselected.
	 */
	onChange(identity: string): void {
		if (!this.emptyToNull(this.set[identity])) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			delete this.set[identity];
		}
		this.setModified();
	}

	persist(): void {
		this.store.delete(this.key);
		this.store.set(this.key, this);
	}

	getModified(): Folder[] {
		return Object.keys(this.visitedTargets)
			.filter((elementKey) => this.initialSet[elementKey] !== this.set[elementKey])
			.map((elementKey) => this.visitedTargets[elementKey].element);
	}

	addTargets(targets: AssociationTarget[]): void {
		targets.forEach((target) => {
			const elementKey = this.keyFunction(target.element);
			if (!this.visitedTargets[elementKey]) {
				this.visitedTargets[elementKey] = target;
				if (target.comment) {
					this.set[elementKey] = target.comment;
					this.initialSet[elementKey] = target.comment;
				}
			}
		});
	}

	clear(target: AssociationTarget): void {
		const elementKey = this.keyFunction(target.element);
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		delete this.set[elementKey];
		this.setModified();
	}
}

@Injectable({ providedIn: "root" })
export class AssociationCommentService {
	private store = new Map<string, Comments>();

	loadComments(targets: AssociationTarget[], namespace: string, keyFunction: (e: Folder) => string): Comments {
		let comments = this.store.get(namespace);
		if (!comments) {
			comments = new Comments(namespace, this.store, keyFunction);
			this.store.set(namespace, comments);
		}
		comments.addTargets(targets);
		return comments;
	}

	remove(namespace: string): void {
		this.store.delete(namespace);
	}
}
