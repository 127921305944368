<ng-template let-editableElement="editableElement" #buttonbar>
	<div class="input-container right">
		<button
			type="submit"
			class="button sm save"
			name="submit"
			tabindex="101"
			[disabled]="isSaveInProgress"
			(click)="save(editableElement)"
		>
			<span *ngIf="!isSaveInProgress" translate="save"></span>
			<stages-spin *ngIf="isSaveInProgress" size="s"></stages-spin>
		</button>
		<button
			type="button"
			class="button sm cancel"
			name="cancel"
			tabindex="100"
			(click)="cancel()"
			translate="cancel"
		></button>
	</div>
</ng-template>

<ng-container *ngIf="workspaceView$ | async as workspaceView">
	<ng-container *ngIf="data$ | async as data">
		<article class="edit-element" *ngIf="editableElement$ | async as editableElement">
			<header class="page-header">
				<ul class="breadcrumb">
					<li>
						<a class="back" (click)="cancel()">
							<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
						</a>
					</li>
				</ul>
				<h1 translate="process.element.edit.title"></h1>
				<stages-breadcrumb [path]="getBreadcrumbPath(data.view)" [links]="true"></stages-breadcrumb>
			</header>

			<article class="card">
				<form class="form" [formGroup]="formGroup" *ngIf="editableElement.element" #formElement>
					<ng-container *ngTemplateOutlet="buttonbar; context: { editableElement: editableElement }"></ng-container>
					<div class="form-group" *ngIf="!editableElement.element.isIndex">
						<div class="input-container md required">
							<label class="input-label" for="name" translate="process.element.edit.name"></label>
							<input
								id="name"
								name="name"
								type="text"
								class="text fullsize"
								tabindex="1"
								formControlName="name"
								stagesAutofocus
								(change)="setErrorsOnForm(getFormControl('name'))"
							/>
							<div class="info">
								<div
									*ngIf="
										getFormControl('name').errors && (getFormControl('name').dirty || getFormControl('name').touched)
									"
								>
									<ng-container *ngFor="let error of getControlErrors('name')" [ngSwitch]="error">
										<div *ngSwitchCase="'required'" class="error" [translate]="'input.required'" role="alert"></div>
										<div
											*ngSwitchCase="'maxlength'"
											class="error"
											[translate]="'input.maxLength'"
											[translateParams]="{ length: '255' }"
											role="alert"
										></div>
										<div
											*ngSwitchCase="'pattern'"
											class="error"
											[translate]="'process.element.edit.name.pattern'"
										></div>
									</ng-container>
								</div>
								<div class="maxlength">{{ getMaxLengthAsString(getFormControl("name"), "255") }}</div>
							</div>
						</div>

						<div class="input-container sm" *ngIf="editableElement.shortnameEnabled">
							<label class="input-label" for="shortname" translate="process.element.edit.abbreviation"></label>
							<input
								id="shortname"
								name="shortname"
								type="text"
								class="text fullsize"
								tabindex="2"
								formControlName="shortname"
								(change)="setErrorsOnForm(getFormControl('shortname'))"
							/>
							<div class="info">
								<div
									*ngIf="
										getFormControl('shortname').errors &&
										(getFormControl('shortname').dirty || getFormControl('shortname').touched)
									"
								>
									<ng-container *ngFor="let error of getControlErrors('shortname')" [ngSwitch]="error">
										<div
											*ngSwitchCase="'maxlength'"
											class="error"
											[translate]="'input.maxLength'"
											[translateParams]="{ length: '32' }"
											role="alert"
										></div>
										<div
											*ngSwitchCase="'pattern'"
											class="error"
											[translate]="'process.element.edit.abbreviation.pattern'"
										></div>
									</ng-container>
								</div>
								<div class="maxlength">{{ getMaxLengthAsString(getFormControl("shortname"), "32") }}</div>
							</div>
						</div>
					</div>

					<div class="form-group" *ngIf="!editableElement.element.isIndex && editableElement.validSubtypes.length">
						<div class="input-container md">
							<label class="input-label" for="type" translate="process.element.edit.type"></label>
							<select id="type" formControlName="subtypeIdent" tabindex="3">
								<option
									*ngFor="let subtype of editableElement.validSubtypes"
									value="{{ subtype.subtypeIdent }}"
									translate="{{ subtype.name!.keys[0] }}"
								></option>
							</select>
							<div class="info"></div>
						</div>

						<div class="input-container blind sm"></div>
					</div>

					<div class="input-container">
						<label class="input-label" for="edt1" translate="process.element.edit.description"></label>
						<stages-description-editor
							[editorId]="'elementDescriptionEditor'"
							[modifiable]="true"
							[unsafe]="false"
							[description]="editableElement.element.displayDescription"
							[processTypeIdent]="data.view.processView.processType"
							[pluginRegistry]="pluginRegistry"
							[beanType]="data.view.processView.type.ident"
							[beanId]="data.view.processView.id"
							[beanIdentity]="data.view.processView.identity"
							[workspaceId]="workspaceView.currentWorkspace.id"
							[pv]="workspaceView.currentWorkspace.viewedProcess!.versionIdentifier"
						>
						</stages-description-editor>
						<div class="info"></div>
					</div>

					<div class="input-container" *ngIf="!editableElement.element.isIndex">
						<legend class="input-label" translate="security.level"></legend>
						<div class="radio" *ngIf="editableElement.maximumAvailableSecurityLevel >= 1" tabindex="5">
							<input id="level-open" type="radio" name="securityLevel" [value]="1" formControlName="securityLevel" />
							<label for="level-open" translate="security.level.1"></label>
						</div>
						<div class="radio" *ngIf="editableElement.maximumAvailableSecurityLevel >= 2" tabindex="6">
							<input id="level-project" type="radio" name="securityLevel" [value]="2" formControlName="securityLevel" />
							<label for="level-project" translate="security.level.2"></label>
						</div>
						<div class="radio" *ngIf="editableElement.maximumAvailableSecurityLevel >= 3" tabindex="7">
							<input
								id="level-internal"
								type="radio"
								name="securityLevel"
								[value]="3"
								formControlName="securityLevel"
							/>
							<label for="level-internal" translate="security.level.3"></label>
						</div>
						<div class="radio" *ngIf="editableElement.maximumAvailableSecurityLevel >= 4" tabindex="8">
							<input
								id="level-confidential"
								type="radio"
								name="securityLevel"
								[value]="4"
								formControlName="securityLevel"
							/>
							<label for="level-confidential" translate="security.level.4"></label>
						</div>
						<div class="radio" *ngIf="editableElement.maximumAvailableSecurityLevel >= 5" tabindex="9">
							<input id="level-secret" type="radio" name="securityLevel" [value]="5" formControlName="securityLevel" />
							<label for="level-secret" translate="security.level.5"></label>
						</div>
						<div class="info"></div>
					</div>

					<stages-attribute-edit
						[attributeTypes]="editableElement.attributeTypes"
						[attributes]="editableElement.element.attributes"
						[form]="formGroup"
						[richtextInputContext]="getContext(data, workspaceView)"
					>
					</stages-attribute-edit>

					<div class="info"></div>
					<ng-container *ngTemplateOutlet="buttonbar; context: { editableElement: editableElement }"></ng-container>
				</form>
			</article>
		</article>
	</ng-container>
</ng-container>
