<stages-dialog2 #dialog class="autoHeight">
	<stages-dialog-header></stages-dialog-header>
	<stages-manual-tailoring
		*ngIf="tailoring$ | async as tailoring; else wait"
		[targetStatus]="tailoring.status"
		[workspaceId]="tailoring.workspaceId"
		[pv]="tailoring.pv"
		[processId]="tailoring.processId"
		[tailoredElements]="tailoring.tailoredElements"
		[removedElements]="tailoring.removedElements"
		[addedElements]="tailoring.addedElements"
		[processTypeIdent]="tailoring.metamodelIdent"
		[justification]="tailoring.justification"
		[complianceConflicts]="tailoring.complianceConflicts"
		[typeIconPrefix]="typeIconPrefix"
		(save)="save()"
		(cancel)="cancel()"
	>
	</stages-manual-tailoring>
	<ng-template #wait>
		<div class="content wait">
			<h1 translate="process.element.tailor.heading"></h1>
			<div class="panel">
				<h4 translate="process.element.tailor.progress"></h4>
				<stages-spin class="spin-without-button" size="sm"></stages-spin>
			</div>
		</div>
		<footer>
			<div class="buttons">
				<button class="button sm cancel" translate="cancel" (click)="cancel()"></button>
				<button class="button sm ok" translate="save" [disabled]="true"></button>
			</div>
		</footer>
	</ng-template>
</stages-dialog2>
