import { Injectable } from "@angular/core";

export enum BrowserType {
	Chromium,
	IE,
	Edge,
	Firefox,
	Safari,
	UnknownBrowser,
}

@Injectable({ providedIn: "root" })
export class UtilService {
	deviceIsWindowsPhone(): boolean {
		return navigator.userAgent.indexOf("Windows Phone") >= 0;
	}

	deviceIsIOS(): boolean {
		return /iP(ad|hone|od)/.test(navigator.userAgent) && !this.deviceIsWindowsPhone();
	}

	deviceIsAndroid(): boolean {
		return navigator.userAgent.indexOf("Android") > 0 && !this.deviceIsWindowsPhone();
	}

	browserIsIE(): boolean {
		return navigator.userAgent.indexOf("Trident") >= 0;
	}

	browserIsEdge(): boolean {
		return navigator.userAgent.indexOf("Edge") >= 0;
	}

	browserIsFirefox(): boolean {
		return navigator.userAgent.indexOf("Firefox") >= 0;
	}

	browserIsChrome(): boolean {
		return navigator.userAgent.indexOf("Chrome") >= 0 && navigator.vendor.indexOf("Google Inc.") >= 0;
	}

	isMobileBrowser(): boolean {
		return this.deviceIsAndroid() || this.deviceIsIOS() || this.deviceIsWindowsPhone();
	}

	isSafari(): boolean {
		return (
			navigator.vendor.indexOf("Apple") > -1 &&
			navigator.userAgent.indexOf("CriOS") === -1 &&
			navigator.userAgent.indexOf("FxiOS") === -1
		);
	}

	getBrowserType(): BrowserType {
		if (this.browserIsChrome()) {
			return BrowserType.Chromium;
		} else if (this.browserIsEdge()) {
			return BrowserType.Edge;
		} else if (this.browserIsFirefox()) {
			return BrowserType.Firefox;
		} else if (this.isSafari()) {
			return BrowserType.Safari;
		} else if (this.browserIsIE()) {
			return BrowserType.IE;
		} else {
			return BrowserType.UnknownBrowser;
		}
	}

	isUnknownBrowser(): boolean {
		return !this.browserIsEdge() && !this.browserIsIE() && !this.browserIsFirefox() && !this.browserIsChrome();
	}

	escapeHtml(unsafe: string): string {
		return unsafe
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&#039;");
	}
}
