<ng-container *ngIf="paramMap$ | async as paramMap">
	<stages-dialog2 #dialog>
		<stages-edit-name *ngIf="!paramMap.has('jobName')"></stages-edit-name>
		<ng-container *ngIf="paramMap.has('jobName')">
			<h1 translate="process.freeze"></h1>
			<div class="content">
				<ng-container>
					<stages-common-progress
						[operationName]="paramMap.get('jobType')!"
						[jobName]="paramMap.get('jobName')!"
						[workspaceId]="paramMap.get('workspaceId')!"
						[pv]="paramMap.get('processVersion')!"
						[messageKeyPrefix]="'process.freeze'"
						(close)="close()"
						(next)="close()"
					>
					</stages-common-progress>
				</ng-container>
			</div>
		</ng-container>
	</stages-dialog2>
</ng-container>
