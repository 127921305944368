import { Injectable } from "@angular/core";
import { PDFPrintResource } from "core/stages-client";

export interface ExtendedTemplateDefinition extends stages.print.TemplateDefinition {
	description?: string;
	link: string;
}

@Injectable({ providedIn: "root" })
export class PDFService {
	constructor(private readonly pdfPrintResource: PDFPrintResource) {}

	async createPdfFor(
		workspaceId: string,
		pv: string,
		type: string,
		id: string,
		template?: string,
	): Promise<stages.core.scheduler.JobIdentifier> {
		return this.pdfPrintResource.generatePdfForId(type, id, {
			template: template,
			workspaceId: workspaceId,
			pv: pv,
		});
	}

	async getTemplates(workspaceId: string, pv: string, type: string, id: string): Promise<ExtendedTemplateDefinition[]> {
		return (await this.pdfPrintResource.getAvailableTemplates(type, id, {
			workspaceId: workspaceId,
			pv: pv,
		})) as unknown as Promise<ExtendedTemplateDefinition[]>;
	}
}
