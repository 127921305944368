import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

// Abstract base class that can be implemented by any component that adds new item (to a parent component, for example)
// NIT: The type of new items that are added
@Directive()
export abstract class ItemEditor<NIT> {
	@Input()
	placeholder?: string;

	@Output() readonly cancelled = new EventEmitter<void>();

	@Output() readonly submitted = new EventEmitter<NIT>();

	@Output() readonly focusLost = new EventEmitter<NIT>();

	customErrors: ValidationErrors = {};

	abstract focus(): void;

	abstract cancel(): void;

	onChange(): void {
		this.customErrors = {};
	}

	hasCustomErrors(): boolean {
		return Object.keys(this.customErrors).length > 0;
	}
}
