import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { PluginComponent, PluginService } from "common/editor/plugin.service";
import { TextualReference } from "common/editor/shared/textual-reference";
import { ListService } from "common/list/list.service";
import { UtilService } from "common/util.service";
import { BrowseService } from "core/browse.service";
import { MainService } from "core/main.service";
import { ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { LinkSearchEvent } from "process/description/link-plugin/link-search.component";
import { ProcessElementPluginComponent } from "process/description/link-plugin/process-element.plugin.component";

type ResolvedTextualReference = stages.process.ResolvedTextualReference;
type ProcessTreeItem = stages.process.ProcessTreeItem;
type ProcessView = stages.process.ProcessView;
type ViewableElement = stages.process.ViewableElement;

@Component({
	animations: [
		trigger("slide", [
			state(
				"INTERNAL",
				style({
					transform: "translateX(0%)",
				}),
			),
			state(
				"ANCHORS",
				style({
					transform: "translateX(-50%)",
				}),
			),
			transition("* => *", animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
	selector: "stages-description-editor-link",
	templateUrl: "./link-plugin.component.html",
	styleUrls: ["./link-plugin.component.scss"],
})
export class LinkPluginComponent extends ProcessElementPluginComponent implements PluginComponent, OnInit {
	selectedReference: TextualReference;
	externalReference: TextualReference;
	isInlining!: boolean;
	alignment?: "center" | "left" | "right";
	selectedElement?: ProcessTreeItem;
	detailsExpanded: boolean;
	preferredDisplayText?: string;
	searchTerm: string;
	searchResults?: unknown[];
	searchEngineAvailable: boolean;
	linkableTypes?: string[];
	resolvedTextualReference!: ResolvedTextualReference;
	isResolvedTextualReferenceAvailable = false;
	previewTitle?: string;
	previewHtml?: string;
	selectedAnchor: string = ""; // empty string means top of element

	constructor(
		private mainService: MainService,
		private utilService: UtilService,
		private viewService: ViewService,
		private browseService: BrowseService,
		private pluginService: PluginService,
		processElementsResource: ProcessElementsResource,
		listService: ListService,
		private descriptionEditor: DescriptionEditorComponent,
	) {
		super(processElementsResource, listService);
		this.pluginService = pluginService;
		this.selectedReference = new TextualReference();
		this.externalReference = new TextualReference();
		this.detailsExpanded = false;
		this.searchTerm = "";
		this.searchEngineAvailable = true;
	}

	async ngOnInit(): Promise<void> {
		this.currentWorkspace = (await this.mainService.getCurrentWorkspace()).currentWorkspace;
		if (this.currentWorkspace.viewedProcess) {
			await this.setBrowseView(this.getOriginWorkspaceId(), "process", "");
		} else {
			this.browseWorkspace = this.currentWorkspace;
		}
		if (this.dialog.getSelectedReference()) {
			this.isInlining = this.dialog.getSelectedReference().isInlining();
			this.alignment = this.dialog.getSelectedReference().getAlignment();
			this.selectedAnchor = this.dialog.getSelectedReference().getHeading()
				? this.dialog.getSelectedReference().getHeading()!
				: "";

			if (this.dialog.getSelectedReference().isExternalLink()) {
				this.mode = "EXTERNAL";
				this.externalReference = this.dialog.getSelectedReference();
				this.initFolder(this.dialog.getBeanType(), this.dialog.getBeanIdentity(), this.getOriginWorkspaceId());
			} else if (this.dialog.getSelectedReference().isLocalHeadingLink()) {
				const folder = await this.initFolder(
					this.dialog.getBeanType(),
					this.dialog.getBeanIdentity(),
					this.getOriginWorkspaceId(),
				);
				if (folder) {
					this.selectItem(folder);
				}
			} else {
				this.resolvedTextualReference = await this.pluginService.resolveInternalReference(
					this.dialog.getSelectedReference(),
					this.getOriginWorkspaceId(),
					this.dialog.getProcessVersionIdentifier(),
					this.descriptionEditor.displayDescription.language!,
				);
				if (this.resolvedTextualReference.valid && !this.resolvedTextualReference.external) {
					const element = await this.initFolder(
						this.resolvedTextualReference.targetElementType!,
						this.resolvedTextualReference.targetElementIdentity!,
						this.resolvedTextualReference.targetWorkspaceId!,
					);
					if (element) {
						this.selectItem(element);
					}
				} else {
					await this.initFolder(this.dialog.getBeanType(), this.dialog.getBeanIdentity(), this.getOriginWorkspaceId());
				}

				this.isResolvedTextualReferenceAvailable = true;
			}
		} else {
			this.initFolder(this.dialog.getBeanType(), this.dialog.getBeanIdentity(), this.getOriginWorkspaceId());
		}

		this.dialog.registerDialogCancelHandler(() => {
			return true;
		});
	}

	get selectedReferenceExist(): boolean {
		return this.resolvedTextualReference && this.resolvedTextualReference.valid;
	}

	private updateSelectedReference(): void {
		const heading = this.selectedAnchor === "" ? undefined : this.selectedAnchor;
		this.selectedReference = this.dialog.getTextualReference(
			this.selectedElement as ViewableElement,
			this.browseWorkspace,
			this.getOriginWorkspaceId(),
			this.isInlining,
			this.hasFixedOriginWorkspace(),
			this.alignment,
			heading,
		);
		if (
			this.preferredDisplayText &&
			this.preferredDisplayText.length &&
			this.pluginService.getLinkName(this.selectedReference) !== this.preferredDisplayText
		) {
			this.selectedReference.setDisplayText(this.preferredDisplayText);
		}
	}

	private hasFixedOriginWorkspace(): boolean {
		return this.dialog.getNonProcessEditorProperties() !== undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private async convertToProcessElement(searchResult: any): Promise<ProcessTreeItem> {
		const browseView = await this.browseService.getView(
			searchResult.workspaceId,
			this.dialog.getProcessVersionIdentifier(),
			searchResult.type.ident,
			searchResult.identity,
			this.dialog.getWorkspaceId(),
			this.dialog.getProcessVersionIdentifier(),
		);
		return this.viewService.getSelf(browseView.processView);
	}

	private async selectItem(item: ProcessTreeItem): Promise<ProcessTreeItem> {
		// WP #31689: Convert search result to real process element (search result has no parent)
		const element = item.hasOwnProperty("_score") ? await this.convertToProcessElement(item) : item;
		if (this.selectedElement && !this.pluginService.areElementsEqual(this.selectedElement, element)) {
			this.selectedAnchor = "";
		}
		this.selectedElement = element;
		this.detailsExpanded = true;
		this.updateSelectedReference();
		setTimeout(scrollToSelectedItem, 0);
		return element;
	}

	async setBrowseView(workspaceId: string, type: string, identity: string): Promise<ProcessView> {
		const view = await this.browseService.getView(
			workspaceId,
			this.dialog.getProcessVersionIdentifier(),
			type,
			identity,
			this.dialog.getWorkspaceId(),
			this.dialog.getProcessVersionIdentifier(),
			this.descriptionEditor.displayDescription.language
				? this.descriptionEditor.displayDescription.language
				: undefined,
			true,
		);
		this.browseWorkspace = view.viewWorkspace;
		const element = this.viewService.getSelf(view.processView);
		if (type === "process") {
			this.linkableTypes = this.setLinkableTypes(element);
		}
		return element;
	}

	setLinkableTypes(processView: ProcessView): string[] | undefined {
		const linkableTypes: string[] = ["description"];
		if (processView.children) {
			for (const indexElement of processView.children) {
				linkableTypes.push(indexElement.type.ident);
			}
			return linkableTypes;
		}
		return undefined;
	}

	private async initFolder(
		elementType: string,
		elementIdentity: string,
		elementWorkspaceId: string,
	): Promise<ProcessView | undefined> {
		const element = await this.updateFolder(elementType, elementIdentity, elementWorkspaceId);
		const selectedText = this.dialog.getSelectedText();
		if (this.mode === "INTERNAL" && selectedText && selectedText.trim().length) {
			this.preferredDisplayText = selectedText.trim();
		}
		return element;
	}

	async insertLinkTo(element: ProcessTreeItem): Promise<void> {
		await this.selectItem(element);
		this.onInsert();
	}

	onInsert(selectedReference?: TextualReference): void {
		if (selectedReference) {
			this.selectedReference = selectedReference;
		}
		if (this.selectedReference && this.selectedReference.isValid()) {
			const html =
				`<a href="${this.selectedReference.getHtml()}" data-pkit-ref="${this.selectedReference.getHtml()}">` +
				`${this.utilService.escapeHtml(this.pluginService.getLinkName(this.selectedReference))}</a>`;
			this.dialog.insertHtml(html);
			this.dialog.close();
		}
	}

	onSearch($event: LinkSearchEvent): void {
		this.searchTerm = $event.term;
		this.searchResults = $event.results;
		this.searchEngineAvailable = $event.searchEngineAvailable;
	}

	onKeyUp(keyEvent: KeyboardEvent): void {
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		if (keyEvent.which === 13 && this.selectedReference.toString() !== "[[]]") {
			this.onInsert();
		}
	}

	isElementSelected(element: ProcessTreeItem): boolean {
		return (
			!!this.selectedElement &&
			element &&
			element.type &&
			this.pluginService.areElementsEqual(this.selectedElement, element)
		);
	}

	async toggleDetails(element: ProcessTreeItem): Promise<void> {
		this.detailsExpanded = !this.detailsExpanded;
		if (!this.isElementSelected(element)) {
			this.selectItem(element);
		}
		return Promise.resolve();
	}

	navigateToStart(): void {
		this.updateFolder("process", "", this.browseWorkspace.id);
	}

	override toggleBrowseWorkspaceMode(): void {
		if (this.mode === "EXTERNAL") {
			return;
		}
		this.mode = this.mode === "INTERNAL" ? "WORKSPACE" : "INTERNAL";
	}

	async toggleAnchors(element?: ProcessTreeItem): Promise<void> {
		if (element) {
			this.previewHtml = await this.getEditableDescription(element, false);
			this.previewTitle = element.label;
			this.mode = "ANCHORS";
			await this.selectItem(element);
		} else {
			this.mode = "INTERNAL";
		}
	}

	selectAnchor(anchorName: string): void {
		this.selectedAnchor = anchorName;
		this.updateSelectedReference();
	}

	onSlideDone(): void {
		if (this.mode !== "ANCHORS") {
			this.previewHtml = undefined;
			this.previewTitle = undefined;
		}
	}
}

function scrollToSelectedItem(): void {
	const selectedDomElement = document.querySelector<HTMLElement>("li.list-item.internal-target.active");
	if (selectedDomElement) {
		const topPos = selectedDomElement.offsetTop;
		const headerHeight = document.querySelector<HTMLElement>("div.link-plugin header")?.offsetHeight ?? 0;
		const containerDomElement = document.querySelector("article.internal-targets");
		if (containerDomElement) {
			containerDomElement.scrollTop = topPos - headerHeight;
		}
	}
}
