<ng-container *ngIf="isFilterToolbarVisible()">
	<div class="panel">
		<div class="group form-inline">
			<div class="input-container" *ngIf="showSearch">
				<label
					for="searchByName"
					class="form-label"
					translate="management.process.interfaces.filter.search.label"
				></label>
				<div class="filter-container">
					<input
						id="searchByName"
						type="search"
						class="fullsize search"
						[(ngModel)]="searchTermValue"
						placeholder="{{ placeholderText | translate }}"
						(stagesDebouncedChange)="onSearchTermChange()"
						delay="1000"
					/>
				</div>
			</div>
			<div class="input-container" *ngIf="dropdownContent">
				<label for="typeFilter" class="form-label" translate="management.process.interfaces.filter.type.label"></label>
				<div class="filter-container">
					<select
						name="dropdown"
						id="typeFilter"
						class="fullsize"
						[(ngModel)]="dropdownValue"
						(change)="onFilterTypeChange()"
					>
						<option
							*ngFor="let option of dropdownContent"
							[ngValue]="option.ident"
							[translate]="option.translate"
						></option>
					</select>
				</div>
			</div>
			<div class="input-container" *ngIf="showChangesSwitch && workingVersionSelected">
				<label
					for="changesOnly"
					class="form-label"
					translate="management.process.interfaces.filter.showchanges"
				></label>
				<div class="filter-container">
					<input
						type="checkbox"
						hidden="hidden"
						id="changesOnly"
						[(ngModel)]="showChanges"
						(change)="onShowOnlyChangesToggle()"
					/>
					<label for="changesOnly" class="switch fullsize"></label>
				</div>
			</div>
		</div>
	</div>
</ng-container>
