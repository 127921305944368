import { ValidatorFn, Validators } from "@angular/forms";
import { Subject } from "rxjs";

export class InputBase<T> {
	value?: T | null;
	key: string;
	type: string;
	inputFieldType?: string;
	required?: boolean;
	maxLength?: number;
	translateKey?: string;
	untranslatableName?: string;
	helpMessage?: string | null;
	infoMsgKey?: string;
	/* Checkbox only, implement for other types if needed */
	warnMsgKey?: string;
	readonly?: boolean;
	hidden: boolean;
	/* Text field only */
	expand?: () => void;
	focus: boolean;
	placeholderKey?: string | null;
	validators: ValidatorFn[] = new Array<ValidatorFn>();
	valueSubject = new Subject<unknown>();

	constructor(args: {
		key: string;
		value?: T | null;
		type: string;
		inputFieldType?: string;
		required?: boolean;
		maxLength?: number | null;
		translateKey?: string | null;
		untranslatableName?: string | null;
		readonly?: boolean | null;
		hidden?: boolean;
		focus?: boolean;
		placeholderKey?: string | null;
	}) {
		this.key = args.key;
		this.value = args.value;
		this.type = args.type;
		this.inputFieldType = args.inputFieldType;
		this.required = args.required;
		this.maxLength = args.maxLength || undefined;
		this.translateKey = args.translateKey || undefined;
		this.untranslatableName = args.untranslatableName || undefined;
		this.readonly = args.readonly || false;
		this.hidden = args.hidden || false;
		this.focus = args.focus || false;
		this.placeholderKey = args.placeholderKey;

		this.addDefaultValidators();
	}

	addValidator(validator: ValidatorFn): void {
		this.validators.push(validator);
	}

	private addDefaultValidators(): void {
		if (this.required) {
			this.validators.push(Validators.required);
		}
		if (this.maxLength) {
			this.validators.push(Validators.maxLength(this.maxLength));
		}
	}

	setValue(newValue: T): void {
		this.value = newValue;
		this.valueSubject.next(newValue);
	}
}

export class CommonArgs<T> {
	key!: string;
	value?: T | null;
	required?: boolean;
	translateKey?: string | null;
	untranslatableName?: string | null;
	readonly?: boolean | null;
	hidden?: boolean;
	infoMsgKey?: string;
}
