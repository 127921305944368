import { NgModule } from "@angular/core";
import { MostFrequentlyUsedElementsComponent } from "charts/most-frequently-used-elements.component";
import { MostFrequentlyUsedFilesComponent } from "charts/most-frequently-used-files.component";
import { MostFrequentlyUsedTypesComponent } from "charts/most-frequently-used-types.component";
import { MostFrequentlyUsedWorkspacesComponent } from "charts/most-frequently-used-workspaces.component";
import { StagesCommonModule } from "common/common.module";
import * as Highcharts from "highcharts";
import { HighchartsChartModule } from "highcharts-angular";
import { default as NoDataToDisplay } from "highcharts/modules/no-data-to-display";

NoDataToDisplay(Highcharts);

@NgModule({
	imports: [StagesCommonModule, HighchartsChartModule],
	declarations: [
		MostFrequentlyUsedElementsComponent,
		MostFrequentlyUsedWorkspacesComponent,
		MostFrequentlyUsedTypesComponent,
		MostFrequentlyUsedFilesComponent,
	],
	exports: [
		MostFrequentlyUsedElementsComponent,
		MostFrequentlyUsedWorkspacesComponent,
		MostFrequentlyUsedTypesComponent,
		MostFrequentlyUsedFilesComponent,
	],
})
export class StagesChartModule {}
