<ng-template let-property #label>
	<div class="property-label">
		<div class="text">
			<h4 translate="{{ getNameKey(property) }}"></h4>
			<h6 *ngIf="property.isLinkable">
				<a [routerLink]="getLink(property)">{{ getDisplayValue(property) }}</a>
			</h6>
			<ng-container *ngIf="!property.isLinkable">
				<p *ngIf="!hasHtmlValue(property)" class="preformatted">{{ getDisplayValue(property) }}</p>
				<stages-description-view *ngIf="hasHtmlValue(property)" [html]="getHTMLValue(property)">
				</stages-description-view>
			</ng-container>
			<p *ngIf="getChangeMarker(property) && getChangeMarker(property).added">
				<span class="badge added-badge" translate="process.compare.added"></span>
			</p>
			<p *ngIf="getChangeMarker(property) && getChangeMarker(property).deleted">
				<span class="badge deleted-badge" translate="process.compare.deleted"></span>
			</p>
		</div>
	</div>
</ng-template>

<ng-template let-property #buttonbar>
	<div class="input-container right">
		<button type="submit" class="button sm save" name="submit" tabindex="101" [disabled]="isSaveInProgress">
			<span *ngIf="!isSaveInProgress" translate="save"></span>
			<stages-spin *ngIf="isSaveInProgress" size="s"></stages-spin>
		</button>
		<input
			type="button"
			class="button sm cancel"
			tabindex="100"
			(mousedown)="cancel()"
			(click)="cancel()"
			value="{{ 'cancel' | translate }}"
		/>
	</div>
</ng-template>

<ng-container *ngIf="currentElement">
	<article class="properties-grid module" [ngClass]="{ 'has-categories': hasCategories() }">
		<ng-container *ngIf="!editOn">
			<p *ngIf="showTranslateNone(propertyList) && translateNone" class="empty" translate="{{ translateNone }}"></p>
			<ul class="grouped-list" *ngIf="hasCategories()">
				<ng-container *ngFor="let category of getVisiblePropertyList(); let last = last">
					<li class="group" [ngClass]="{ last: last }">
						<header class="group-header">
							<h3 (click)="toggle(category)">
								{{ category.nameKey | translate }}<span class="counter">{{ getVisiblePropertiesCount(category) }}</span>
							</h3>
							<stages-button-expand-collapse
								[classes]="'action menu-button'"
								[isCollapsed]="!isCategoryOpen(category)"
								(pressedButton)="toggle(category)"
							></stages-button-expand-collapse>
						</header>
						<div class="relative-container" *ngIf="isCategoryOpen(category)">
							<div class="animation-container">
								<div class="expandable">
									<ul class="list dense">
										<ng-container *ngFor="let property of category.properties">
											<li class="list-item" *ngIf="show(property)">
												<ng-container *ngTemplateOutlet="label; context: { $implicit: property }"></ng-container>
											</li>
										</ng-container>
									</ul>
								</div>
							</div>
						</div>
					</li>
				</ng-container>
			</ul>
			<ul class="grouped-list panel-component" *ngIf="!hasCategories()">
				<ul class="list dense">
					<ng-container *ngFor="let property of propertyList">
						<li class="list-item" *ngIf="show(property)">
							<ng-container *ngTemplateOutlet="label; context: { $implicit: property }"></ng-container>
						</li>
					</ng-container>
				</ul>
			</ul>
		</ng-container>
		<form
			[formGroup]="form"
			class="form widget-spacer-top-bottom"
			name="form"
			(ngSubmit)="onSave()"
			novalidate
			stagesFocusError
			*ngIf="editOn"
		>
			<ng-container *ngTemplateOutlet="buttonbar"></ng-container>
			<stages-attribute-edit
				[form]="form"
				[attributeTypes]="editableAttributeTypes"
				[attributes]="editableAttributes"
				[richtextInputContext]="getContext()"
			>
			</stages-attribute-edit>
			<ng-container *ngTemplateOutlet="buttonbar"></ng-container>
		</form>
	</article>
</ng-container>
