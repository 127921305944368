import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type DependencyGroup = stages.administration.credits.DependencyGroup;

@Component({
	selector: "stages-common-credit",
	templateUrl: "./credit.component.html",
})
export class CreditComponent {
	dependencyGroups$: Observable<DependencyGroup[]>;

	constructor(route: ActivatedRoute) {
		this.dependencyGroups$ = route.data.pipe(map((data) => data.dependencyGroups));
	}
}
