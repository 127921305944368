import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MainService } from "core/main.service";
import { lastValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LanguageService {
	constructor(private mainService: MainService, private translateService: TranslateService) {}

	/**
	 * Returns the currently set language, even if the translations are not fetched yet.
	 */
	getCurrentLanguage(): string {
		return this.translateService.currentLang;
	}

	get langChange$(): Observable<string> {
		return this.translateService.onLangChange.pipe(map((lce) => lce.lang));
	}

	/**
	 * Sets the language, and waits until translations have been loaded.
	 *
	 */
	async setCurrentLanguage(language: string): Promise<void> {
		await lastValueFrom(this.translateService.use(language));
	}

	/**
	 * Given the language string (or "BROWSER", or "WORKSPACE"), sets the effective language.
	 */
	async determineAndApplyLanguage(processVersion: string, languageSetting: string): Promise<void> {
		if (languageSetting === "BROWSER") {
			const application = await this.mainService.getApplication(processVersion);
			return this.setCurrentLanguage(
				determineBrowserLanguageWithFallback(application.browserLanguage, application.availableLanguages),
			);
		} else if (languageSetting === "WORKSPACE") {
			const workspaceView = await this.mainService.getCurrentWorkspace();
			return this.setCurrentLanguage(workspaceView.currentWorkspace.language);
		} else {
			return this.setCurrentLanguage(languageSetting);
		}
	}
}

function determineBrowserLanguageWithFallback(browserLanguage: string, supportedLanguages: string[]): string {
	if (!supportedLanguages || supportedLanguages.length === 0 || supportedLanguages.indexOf(browserLanguage) > -1) {
		return browserLanguage;
	}
	return supportedLanguages[0];
}
