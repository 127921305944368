import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PluginRegistry } from "common/editor/description.service";
import { ContextResource } from "core/stages-client";

type EditorConfig = stages.context.EditorConfig;
type FormatTag = stages.context.FormatTag;

export interface StagesEditorConfig extends CKEDITOR.config {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	[key: string]: any;
	toolbar_PKit: string[][];
	disableObjectResizing: boolean;
	auto_paragraph: boolean;
	pkitContextPath: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	directives?: any[];
	pkitEditedBeanGuid?: string;
	pkitEditedBeanType?: string;
}

@Injectable({ providedIn: "root" })
export class EditorConfigService {
	constructor(private readonly $translate: TranslateService, private readonly contextResource: ContextResource) {}

	getEditorOptions(
		editorConfig: EditorConfig,
		beanGuid: string,
		beanType: string,
		unsafe: boolean,
		toolbarId: string,
		focus: boolean,
		tabIndex: number,
		pluginRegistry: PluginRegistry,
		readonly: boolean = false,
	): StagesEditorConfig {
		return buildOptions(
			this.$translate.currentLang,
			editorConfig,
			beanGuid,
			beanType,
			unsafe,
			toolbarId,
			focus,
			tabIndex,
			pluginRegistry,
			readonly,
		);
	}

	async getEditorConfig(processTypeIdent: string, currentWorkspaceId: string): Promise<EditorConfig> {
		this.contextResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: currentWorkspaceId,
		});
		return this.contextResource.getEditorConfig({
			processTypeIdent: processTypeIdent,
		});
	}
}

function addOptionalEditorPlugin(
	pluginName: string,
	pluginRegistry: PluginRegistry,
	extraPlugins: string[],
	removePlugins: string[],
): void {
	if (pluginRegistry[pluginName]) {
		extraPlugins.push(pluginName);
	} else {
		removePlugins.push(pluginName);
	}
}

function buildPluginsLoadedFn(formatTags: FormatTag[]): (evt: CKEDITOR.eventInfo) => void {
	return (evt) => {
		formatTags.forEach((formatTag) => {
			const formatProperty = "tag_" + formatTag.id;
			evt.editor.lang.format[formatProperty] = formatTag.label;
		});
		if (CKEDITOR.env.ie && CKEDITOR.env.version >= 9) {
			evt.editor.on("contentDom", () => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				const editable = (evt.editor as any).editable();
				const doc = evt.editor.document;
				const cancelEvent = (): boolean => false;
				editable.attachListener(doc, "selectionchange", () => {
					try {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any -- The following block was once added in order to disable image resizing in IE based browsers. TODO: Check if this is still needed at all.
						const nativeStart = doc.getSelection().getRanges()[0].startPath().blockLimit.$ as any;
						if (nativeStart && nativeStart.currentStyle && nativeStart.currentStyle.hasLayout) {
							nativeStart.onresizestart = cancelEvent;
						}
						// eslint-disable-next-line no-empty -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					} catch (err: unknown) {}
				});
			});
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		evt.editor.addRemoveFormatFilter((element: any) => {
			if (
				element.getName() === "img" &&
				element.getAttribute("class") &&
				element.getAttribute("class").indexOf("stages_") >= 0
			) {
				return false;
			}
			return true;
		});
	};
}

function buildOptions(
	language: string,
	editorConfig: EditorConfig,
	beanGuid: string,
	beanType: string,
	unsafe: boolean,
	toolbarId: string,
	focus: boolean,
	tabIndex: number,
	pluginRegistry: PluginRegistry,
	readonly: boolean,
): StagesEditorConfig {
	const extraPluginsConfig = editorConfig.extraPluginButtons;
	const formatTags = editorConfig.formatTags.filter((formatTag) => !!formatTag);
	const formatTagsJoined = formatTags.map((formatTag) => formatTag.id).join(";");
	const editorDirectives = editorConfig.editorDirectives;
	const pkitContextPath = editorConfig.pkitContextPath;
	const descriptionClass = "stages_description";
	const extraPlugins = [
		"sourcedialog",
		"stagesclipboard",
		"stagesnewanchor",
		"stageshtml",
		"stagesdirective",
		"stagestable",
		"stagestabletools",
		"stagespaste",
		"stagesshowborders",
	];
	const removePlugins = [
		"a11yhelp",
		"link",
		"anchor",
		"forms",
		"image",
		"table",
		"tabletools",
		"liststyle",
		"showborders",
		"clipboard",
	];
	addOptionalEditorPlugin("stageslink", pluginRegistry, extraPlugins, removePlugins);
	addOptionalEditorPlugin("stagesimage", pluginRegistry, extraPlugins, removePlugins);
	addOptionalEditorPlugin("stageshtmltemplate", pluginRegistry, extraPlugins, removePlugins);
	const options: StagesEditorConfig = {
		startupFocus: focus,
		toolbar: "PKit",
		toolbar_PKit: [
			["Maximize"],
			["Bold", "Italic", "Underline", "Strike", "-", "RemoveFormat"],
			["Subscript", "Superscript"],
			["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
			["Format"],
			["StagesHtmlTemplate", "StagesDirective", "StagesHtml"],
			["NumberedList", "BulletedList", "Outdent", "Indent"],
			["TextColor", "BGColor"],
			["StagesLink", "StagesUnlink", "StagesNewAnchor", "StagesImage"],
			["StagesTable"],
		],
		entities: false,
		allowedContent: true,
		toolbarCanCollapse: false,
		dialog_noConfirmCancel: true,
		resize_enabled: false,
		disableNativeSpellChecker: false,
		disableObjectResizing: true,
		scayt_autoStartup: false,
		auto_paragraph: true,
		fillEmptyBlocks: true,
		enterMode: CKEDITOR.ENTER_P,
		shiftEnterMode: CKEDITOR.ENTER_BR,
		sharedSpaces: {
			top: toolbarId,
		},
		removeFormatTags:
			"h1,h2,h3,h4,h5,h6,b,big,code,del,dfn,em,font,i,ins,kbd,q,s,samp,small,span,strike,strong,sub,sup,tt,u,var",
		bodyClass: descriptionClass,
		/* sharedspace, */
		extraPlugins: extraPlugins.join(","),
		removePlugins: removePlugins.join(","),
		removeButtons: editorConfig.removePluginButtons.join(","),
		skin: "stagesmoono",
		pkitContextPath: pkitContextPath,
		keystrokes: [
			[CKEDITOR.CTRL + 90, "undo"],
			[CKEDITOR.CTRL + 89, "redo"],
			[CKEDITOR.CTRL + 66, "bold"],
			[CKEDITOR.CTRL + 73, "italic"],
			[CKEDITOR.CTRL + 85, "underline"],
			[CKEDITOR.CTRL + 83, "save"],
			[CKEDITOR.CTRL + CKEDITOR.ALT + CKEDITOR.SHIFT + 83, "sourcedialog"],
		],
		language: language,
		format_tags: formatTagsJoined,
		on: {
			instanceReady: (evt) => {
				const document = evt.editor.document;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				const ckeditor = (window as any).PKIT.ckeditor;
				document.on("drop", (evt2) => ckeditor.DragAndDropHandler.onDrop(evt2));
				document.getBody().on("drop", (evt2) => ckeditor.DragAndDropHandler.onDrop(evt2));
				evt.editor.resetDirty();
			},
		},
		tabIndex: tabIndex || 0,
		uiColor: "#FAFAFA",
		readOnly: readonly,
		title: false,
	};

	formatTags.forEach((formatTag) => {
		const formatProperty = "format_" + formatTag.id;
		options[formatProperty] = {
			element: formatTag.tag,
			attributes: {
				class: formatTag.cssClass,
			},
		};
	});

	options.on!.pluginsLoaded = buildPluginsLoadedFn(formatTags);

	if (extraPluginsConfig && extraPluginsConfig.length > 0) {
		options.toolbar_PKit.push(extraPluginsConfig);
	}
	if (editorDirectives && editorDirectives.length > 0) {
		options.directives = editorDirectives;
	}
	if (beanGuid) {
		options.pkitEditedBeanGuid = beanGuid;
	}
	if (beanType) {
		options.pkitEditedBeanType = beanType;
	}
	if (unsafe) {
		options.toolbar_PKit.push(["Sourcedialog"]);
	}

	return options;
}
