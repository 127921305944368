import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ViewService } from "core/view.service";

@Injectable({ providedIn: "root" })
export class ViewResolver implements Resolve<stages.process.View> {
	constructor(private viewService: ViewService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.process.View> {
		return this.viewService.forceViewReload(
			route.paramMap.get("workspaceId")!,
			route.paramMap.has("type") ? route.paramMap.get("type")! : "process",
			route.paramMap.has("identity") ? route.paramMap.get("identity")! : "index",
			await this.viewService.getPerspective(),
			route.paramMap.get("workspaceId")!,
			route.paramMap.get("processVersion")!,
		);
	}
}
