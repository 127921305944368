import { FormControl, ValidatorFn, Validators } from "@angular/forms";
import { CommonArgs, InputBase } from "common/form/input/types/input-base";
import { SelectionOption } from "common/form/input/types/selection-input";

export interface Duration {
	unit: string;
	value: number;
	enabled: boolean;
}

export class DurationInputArgs extends CommonArgs<Duration> {
	unitKey!: string;
	enabledKey?: string;
}

export class DurationInput extends InputBase<Duration> {
	selectionOptions: SelectionOption[] = [
		{ value: "MINUTES", translateKey: "input.duration.MINUTES" },
		{ value: "HOURS", translateKey: "input.duration.HOURS" },
		{ value: "DAYS", translateKey: "input.duration.DAYS" },
		{ value: "WEEKS", translateKey: "input.duration.WEEKS" },
		{ value: "MONTHS", translateKey: "input.duration.MONTHS" },
	];

	unitKey: string;
	enabledKey?: string;

	constructor(args: DurationInputArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "duration",
			inputFieldType: "number",
			required: args.required,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});

		this.unitKey = args.unitKey;
		this.enabledKey = args.enabledKey;

		super.addValidator(Validators.min(1));
		super.addValidator(Validators.max(2147483647));
		super.addValidator(this.validateNumberPattern());
	}

	validateNumberPattern(): ValidatorFn {
		return (control) => {
			const regExp = new RegExp("^-?\\d+$");
			return regExp.test(control.value)
				? null
				: {
						integer: {
							valid: false,
						},
				  };
		};
	}

	setDisabled(durationInput: FormControl, unitInput: FormControl, enabledInput: FormControl): void {
		if (enabledInput.value === true) {
			durationInput.enable();
			unitInput.enable();
		} else {
			durationInput.disable();
			unitInput.disable();
		}
	}
}
