<div class="content query" *ngIf="query$ | async as query">
	<div>
		{{ query.name }}
	</div>
	<div class="answer-item-container">
		<stages-process-tailoring-wizard-answer-item [query]="query"></stages-process-tailoring-wizard-answer-item>
	</div>
	<div class="tailoring-description">
		<p>
			<ng-container>
				<stages-readonly-description [displayDescription]="$any(query.description)"> </stages-readonly-description>
			</ng-container>
		</p>
	</div>
</div>
