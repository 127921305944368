import { Component, HostBinding, Input } from "@angular/core";
import { DialogAdapter } from "common/dialog/dialog-adapter";

@Component({
	selector: "stages-confirm",
	templateUrl: "./confirm.component.html",
})
export class ConfirmComponent {
	@HostBinding("class.confirm") customClass = true;

	constructor(private dialog: DialogAdapter) {}

	@Input()
	keys!: {
		message: string;
		details?: string;
		cancel: string;
		ok: string;
		isDeleteButton: boolean;
		// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		values: {};
	};

	cancel(): void {
		this.dialog.close("cancel");
	}

	ok(): void {
		this.dialog.close("ok");
	}
}
