import { HttpClient } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class AppLoadService {
	private bootstrapProperties?: pkit.rest.BootstrapProperties;

	constructor(
		private readonly errorHandler: ErrorHandler,
		private readonly translateService: TranslateService,
		private readonly httpClient: HttpClient,
	) {}

	async resolveBootstrapProperties(): Promise<pkit.rest.BootstrapProperties> {
		if (!this.bootstrapProperties) {
			try {
				this.bootstrapProperties = await lastValueFrom(
					this.httpClient.get<pkit.rest.BootstrapProperties>("rest/bootstrapProperties"),
				);
			} catch (e: unknown) {
				// Catch every error during application loading and return dummy BootstrapProperties, because otherwise
				// the application does not start and startup errors (e.g. "license validation failed") are not displayed.
				// Do not assign dummy BootstrapProperties to the "bootstrapProperties"-property, because the dummy values should not be cached.
				// Do not rethrow the exception, but call ErrorHandler.
				this.errorHandler.handleError(e);
				return {
					cookieLoginEnabled: false,
					basicAuthenticationEnabled: true,
					defaultLanguage: "en",
					licenseTimeoutSoon: false,
					systemLicenseTimeout: false,
					licenseValidityDate: null,
					licensePoolName: null,
					maintenanceMessage: "",
					supportedLanguages: ["en"],
					bannerEnabled: false,
				};
			}
		}
		return this.bootstrapProperties;
	}

	clearBootstrapProperties(): void {
		this.bootstrapProperties = undefined;
	}

	async initializeLanguage(): Promise<void> {
		const bootstrapProperties = await this.resolveBootstrapProperties();
		this.translateService.setDefaultLang(bootstrapProperties.defaultLanguage);
		await (bootstrapProperties.supportedLanguages.indexOf(navigator.language) < 0
			? lastValueFrom(this.translateService.use(bootstrapProperties.defaultLanguage))
			: lastValueFrom(this.translateService.use(navigator.language)));
	}
}
