import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
	selector: "stages-tab-no-route",
	templateUrl: "./tab-no-route.component.html",
})
export class TabNoRouteComponent {
	@Input() active?: boolean;
	@Input() disabled?: boolean;
	@Input() warning?: boolean;
	@Output() readonly activate = new EventEmitter<void>();
	@Input() styleClass?: string;
	@ViewChild("tab")
	tab?: ElementRef;

	tabClicked(_$event: MouseEvent): void {
		if (!this.disabled) {
			this.activate.emit();
		}
	}
}
