import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { KeyboardService } from "common/keyboard.service";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";

const DELAY = 150;

@Directive({
	selector: "[stagesDebouncedChange]",
})
export class DebouncedChangeDirective implements OnInit, OnDestroy {
	@Input() debouncedEvent = "keyup"; //TODO: Can we remove this input parameter and always set the debaouncedEvent to "input" as needed by global search

	@Output("stagesDebouncedChange") readonly changed = new EventEmitter<string>();

	private inputHandler!: Subscription;

	constructor(private elementRef: ElementRef, private keyboardService: KeyboardService) {}

	ngOnInit(): void {
		this.inputHandler = fromEvent(this.elementRef.nativeElement, this.debouncedEvent)
			.pipe(
				debounceTime(DELAY),
				filter((ev) => !this.keyboardService.isNavigationKey(ev as KeyboardEvent)),
				distinctUntilChanged(),
			)
			.subscribe(() => this.fireChanged());
	}

	ngOnDestroy(): void {
		this.inputHandler.unsubscribe();
	}

	private fireChanged(): void {
		this.changed.emit(this.elementRef.nativeElement.value);
	}
}
