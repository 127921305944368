import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";

type TypedViewable = stages.process.TypedViewable;

@Injectable({ providedIn: "root" })
export class PasteService {
	elements: Element[] = [];

	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	async pasteElements(
		targetElement: TypedViewable,
		action: "copy" | "move",
		elements2Paste: TypedViewable[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		const elementIds = elements2Paste.map((element: TypedViewable) => {
			return {
				typeIdent: element.type.ident,
				id: element.id,
			};
		});
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		if (action === "copy") {
			return this.processElementsResource.copy(workspaceId, targetElement.type.ident, targetElement.id, elementIds);
		}
		return this.processElementsResource.move(workspaceId, targetElement.type.ident, targetElement.id, elementIds);
	}
}
