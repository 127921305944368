<article class="upload">
	<stages-dropzone
		[maxFileSize]="maxFileSize"
		[multiple]="multiple"
		[fileExtensions]="fileExtensions"
		[expandDroparea]="expandDroparea"
		(handleFiles)="handleFilesUpdated($event)"
	></stages-dropzone>

	<stages-progress-bar [progress]="progress!" iconClass="{{ uploadIcon }}" [hidden]="!progress"></stages-progress-bar>
</article>
