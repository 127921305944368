<header class="page-header">
	<ul class="breadcrumb">
		<li>
			<a class="back" (click)="back()">
				<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
			</a>
		</li>
	</ul>
	<h1>{{ description.elementLabel }}</h1>
</header>
<stages-card #card [menuNoCollapse]="true">
	<h5 translate="process.description"></h5>
	<article class="description">
		<stages-readonly-description [displayDescription]="description.description"></stages-readonly-description>
	</article>
</stages-card>
