import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class UserPreferencesService {
	getGlobalPreference<T extends object>(userPrefix: string, prefKey: string, defaultValue: T): T {
		if (typeof Storage !== "undefined") {
			const fullPrefKey = `${userPrefix}.${prefKey}`;
			const localStorageStringValue = localStorage[fullPrefKey];
			if (localStorageStringValue) {
				const localStorageJSONValue = JSON.parse(localStorageStringValue);
				return { ...(defaultValue as object), ...localStorageJSONValue };
			}
			return defaultValue;
		} else {
			console.log("no storage support");
			return defaultValue;
		}
	}

	getPreference<T extends object>(userPrefix: string, prefKey: string, defaultValue: T): T {
		if (typeof Storage !== "undefined") {
			const fullPrefKey = `${userPrefix}.${prefKey}`;
			if (!sessionStorage[fullPrefKey]) {
				const localStorageStringValue = localStorage[fullPrefKey];
				if (localStorageStringValue) {
					sessionStorage[fullPrefKey] = localStorageStringValue;
				} else {
					return { ...defaultValue };
				}
			}
			const sessionStorageStringValue = sessionStorage[fullPrefKey];
			if (sessionStorageStringValue) {
				const sessionStorageJSONValue = JSON.parse(sessionStorageStringValue);
				return { ...defaultValue, ...sessionStorageJSONValue };
			}
			return { ...defaultValue };
		} else {
			console.log("no storage support");
			return { ...defaultValue };
		}
	}

	setPreference<T extends object>(userPrefix: string, prefKey: string, value: T): void {
		if (typeof Storage !== "undefined") {
			const fullPrefKey = `${userPrefix}.${prefKey}`;
			sessionStorage[fullPrefKey] = JSON.stringify(value);
			localStorage[fullPrefKey] = JSON.stringify(value);
		} else {
			console.log("no storage support");
		}
	}

	delPreference(userPrefix: string, prefKey: string): void {
		if (typeof Storage !== "undefined") {
			const fullPrefKey = `${userPrefix}.${prefKey}`;
			sessionStorage.removeItem(fullPrefKey);
			localStorage.removeItem(fullPrefKey);
		} else {
			console.log("no storage support");
		}
	}
}
