<stages-dialog2 #dialog [classes]="[]" *ngIf="repositories$ | async as repositories">
	<stages-dialog-header>
		<div *ngIf="repositories.length <= 1" class="title">
			<i class="ico ico-database"></i>
			<h3 class="shorten">{{ activeRepository.name }} ({{ activeRepository.repositoryType }})</h3>
		</div>

		<a
			*ngIf="repositories.length > 1 && !isRepositorySelectionActive"
			class="title clickable"
			(click)="showRepositories()"
			title="{{ 'management.files.browser.changeRepository' | translate }}"
		>
			<i class="ico ico-database"></i>
			<h3 class="shorten">{{ activeRepository.name }} ({{ activeRepository.repositoryType }})</h3>
		</a>

		<a
			class="title clickable"
			*ngIf="isRepositorySelectionActive"
			(click)="showFileSelection()"
			title="{{ getSwitchToFileInputMessage() }}"
		>
			<i class="ico ico-arrow-back"></i>
			<h3>{{ getSwitchToFileInputMessage() }}</h3>
		</a>
	</stages-dialog-header>

	<h1 translate="{{ getDialogTitleKey() }}"></h1>

	<div class="panel" *ngIf="!isRepositorySelectionActive && activeRepository.error">
		<p class="error" translate="{{ activeRepository.error.titleKey }}"></p>
		<p>{{ activeRepository.error.message }}</p>
	</div>

	<div class="contentpadding" *ngIf="!isRepositorySelectionActive && activeRepository.contentBrowseable">
		<div class="input-container auto-complete">
			<input id="input-filepath" type="search" class="search" [readonly]="true" [(ngModel)]="activePath" />
			<div class="info">
				<div class="errors">
					<div
						*ngIf="isPathTooLong()"
						class="error"
						role="alert"
						translate="management.files.browser.pathTooLong"
					></div>
				</div>
			</div>
		</div>
	</div>

	<div class="form auto-complete" *ngIf="isInManualEntryMode()">
		<form #form="ngForm">
			<div class="input-container">
				<label for="input-filepath" class="input-label" translate="management.files.browser.path.label"></label>
				<input
					#pathControl="ngModel"
					id="input-filepath"
					type="search"
					name="path"
					class="search"
					[(ngModel)]="activePath"
					[stagesMaxLength]="255"
					required
					stagesAutofocus
				/>
			</div>
			<div class="info">
				<div class="errors" *ngIf="form.submitted || pathControl.dirty || pathControl.touched">
					<div
						*ngIf="pathControl?.errors?.max"
						class="error"
						role="alert"
						translate="input.maxLength"
						[translateParams]="{ length: '255' }"
					></div>
					<div *ngIf="pathControl?.errors?.required" class="error" role="alert" translate="input.required"></div>
				</div>
				<div class="maxlength"></div>
			</div>
		</form>
	</div>

	<div
		*ngIf="!isRepositorySelectionActive && activeRepository.contentBrowseable"
		class="button-line contentpadding"
		[ngClass]="{ hidden: navigationButtonsHidden }"
	>
		<button
			class="button clickable"
			(click)="handleUpClicked()"
			title="{{ 'management.files.browser.navigateUp' | translate }}"
		>
			<i class="ico ico-arrow-up"></i>
		</button>
		<button
			class="button clickable"
			(click)="handleRootClicked()"
			title="{{ 'management.files.browser.navigateToRoot' | translate }}"
		>
			<i class="ico ico-root-folder"></i>
		</button>
	</div>

	<article class="content" *ngIf="!isRepositorySelectionActive">
		<nav *ngIf="activeRepository?.contentBrowseable && activeItem?.children">
			<stages-table-viewer
				[items]="activeItem.children"
				[columns]="tableColumns"
				[rowProvider]="getTableRow"
				[cellProvider]="getTableCell"
				[selection]="selectionInThisFolder"
				(selectionChanged)="handleSelectionUpdate()"
				(itemClicked)="handleItemClicked($event)"
				(sortOrderChanged)="handleSortOrderChanged($event)"
				columnNamePrefix="management.files.browser.column."
				mediaQuery="screen and (max-width:768px)"
				translateNone="management.files.browser.emptyFolder"
			>
			</stages-table-viewer>
		</nav>
	</article>

	<article class="content" *ngIf="isRepositorySelectionActive">
		<nav>
			<ul class="list">
				<li class="list-item" *ngFor="let repository of repositories; trackBy: trackByRepositoryId">
					<a class="label clickable" (click)="handleRepositorySelected(repository)">
						<div class="text">
							<h3>{{ repository.name }}</h3>
							<h6>{{ repository.repositoryType }}</h6>
						</div>
					</a>
				</li>
			</ul>
		</nav>
	</article>

	<p class="contentpadding" *ngIf="needsSelectionSizeDisplay()">
		<span>{{ getSelectionSizeMessage() }}</span>
	</p>

	<footer *ngIf="needsSaveAndCancelButtons()">
		<div class="buttons">
			<button class="button sm cancel" (click)="dialog.close()" translate="cancel"></button>
			<button class="button sm save" [disabled]="!canSave()" (click)="save()">
				<span *ngIf="!saveInProgress" translate="files.start.cm.button"></span>
				<stages-spin *ngIf="saveInProgress" size="s"></stages-spin>
			</button>
		</div>
	</footer>
</stages-dialog2>
