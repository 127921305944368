<ng-template let-entry="entry" #label>
	<div class="text-and-icon">
		<span [ngClass]="entry.getLabelClasses()" class="span2 title">{{ entry.label }}</span>
		<i [ngClass]="getIconClasses(entry)"></i>
	</div>
	<div class="badges" *ngIf="entry.badges">
		<span *ngFor="let badge of entry.badges" class="badge {{ badge.styleClass }}" [translate]="badge.translate"></span>
	</div>
	<i *ngIf="entry.showWarning" class="ico ico-exclamation-mark"></i>
</ng-template>

<article *ngFor="let entry of getVisibleEntries(); trackBy: trackByIndex" [ngClass]="getCardClasses(entry)">
	<a class="label" *ngIf="!entry.linkDisabled" [routerLink]="entry.getRouterLink()" title="{{ entry.label }}">
		<ng-container *ngTemplateOutlet="label; context: { entry: entry }"></ng-container>
	</a>
	<div class="label" *ngIf="entry.linkDisabled">
		<ng-container *ngTemplateOutlet="label; context: { entry: entry }"></ng-container>
	</div>
</article>

<ng-container *ngIf="hasOmmittedEntries()">
	<div class="limitReached">
		<div class="label ommitted-items">
			<div class="text fullsize dark">
				<h3 translate="list.items.ommitted"></h3>
				<h6 [translate]="'list.items.ommitted.search.general'"></h6>
			</div>
		</div>
	</div>
</ng-container>
