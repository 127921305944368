<ng-container *ngIf="container$ | async as container">
	<stages-association-list
		(openBrowse)="openBrowse($event)"
		[allowCreateElements]="true"
		[allowCreateCommentOnlyElements]="false"
		[associationStore]="associationStore"
		[container]="container"
		[editable]="true"
		[groups]="groupDefinitions"
		[messageKeyNone]="'none'"
		[showEmptyGroups]="false"
		[targetElementProcessIds]="targetElementProcessIds"
	>
	</stages-association-list>
</ng-container>
