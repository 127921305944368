import { Store } from "common/data/store.logic";
import { ProcessInterfacesService } from "management/process-interfaces/process-interfaces.service";
import { ParamMap } from "@angular/router";

type ProcessInterfaceWorkspace = stages.management.interfaces.ProcessInterfaceWorkspace;
type ProcessInterfaceWorkspaceSelectionOverview =
	stages.management.interfaces.ProcessInterfaceWorkspaceSelectionOverview;

export class WorkspaceListStore implements Store<ProcessInterfaceWorkspace> {
	_showOnlyChanges: boolean = false;
	_isReleasePreview: boolean = false;

	set showOnlyChanges(showOnlyChanges: boolean) {
		this._showOnlyChanges = showOnlyChanges;
	}

	set isReleasePreview(isReleasePreview: boolean) {
		this._isReleasePreview = isReleasePreview;
	}

	constructor(
		private processInterfacesService: ProcessInterfacesService,
		private pageSize: number,
		showOnlyChanges: boolean,
		isReleasePreview: boolean,
	) {
		this.showOnlyChanges = showOnlyChanges;
		this.isReleasePreview = isReleasePreview;
	}

	async getPage(
		pageIndex: number,
		_workspaceId: string,
		_pv: string,
		paramMap: ParamMap,
		queryParamMap: ParamMap,
	): Promise<SecuredPagedResultAndInfo<ProcessInterfaceWorkspace, ProcessInterfaceWorkspaceSelectionOverview[]>> {
		const processVersion: string = queryParamMap.has("pvForInterfaces")!
			? queryParamMap.get("pvForInterfaces")!
			: paramMap.get("processVersion")!;

		const processVersionToCompare: string = this._showOnlyChanges ? "_vv" : processVersion;
		return await this.processInterfacesService.loadMoreWorkspaces(
			pageIndex,
			this.pageSize,
			this._showOnlyChanges,
			this._isReleasePreview,
			paramMap.get("workspaceId")!,
			processVersion,
			processVersionToCompare,
		);
	}
}
