import { Injectable } from "@angular/core";
import { StagesCoreModule } from "core/core.module";
import { Observable, Subject } from "rxjs";

@Injectable({
	providedIn: StagesCoreModule,
})
export class UploadService {
	pendingFiles: File[] = [];
	pendingFilesObservableOutput = new Observable<File[]>();
	private pendingFilesObservableInput: Subject<File[]>;

	constructor() {
		this.pendingFilesObservableInput = new Subject<File[]>();
		this.pendingFilesObservableOutput = this.pendingFilesObservableInput.asObservable();
	}

	setPendingFiles(files: File[]): void {
		this.pendingFiles = files;
	}

	getPendingFiles(): File[] {
		return this.pendingFiles;
	}

	appendUpload(formData: FormData, file: File): void {
		formData.append("filedata", file);
		formData.append("filenames", new Blob([file.name], { type: "text/plain;charset=utf8" }));
	}

	createFormData(files: File[]): FormData {
		const formData = new FormData();
		for (const file of Array.from(files)) {
			formData.append("filedata", file);
			formData.append("filenames", new Blob([file.name], { type: "text/plain;charset=utf8" }));
		}
		return formData;
	}

	setFilesToUpload(files: File[]): void {
		this.pendingFilesObservableInput.next(files);
	}
}
