import { Directive, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
	selector: "[stagesValidateOnBlur]",
})
export class ValidateOnBlurDirective {
	constructor(public formControl: NgControl) {}

	@HostListener("focus") onFocus(): void {
		if (this.formControl.control) {
			this.formControl.control.markAsUntouched();
		}
	}

	@HostListener("blur") onBlur(): void {
		if (this.formControl.control) {
			this.formControl.control.markAsTouched();
		}
	}
}
