// The constants not starting with "DO_" represent the complete set of actions that need to be done for a file operation
// (e.g. for CHECKIN a popup needs to be opened, the cms attributes need to be fetched and then - after submit button
// is pressed - the actual checkin is done).
// The constants stating with "DO_" represent the actual actions that need to be done for completing a file operation
// without the precedent preparing actions.
export enum FilesOperation {
	DOWNLOAD,
	LOCK,
	DOWNLOAD_AND_LOCK,
	UNLOCK,
	ADD,
	ADD_FOM_TEMPLATE,
	CHECKIN,
	SET_STATE,

	DO_DOWNLOAD,
	DO_DOWNLOAD_AND_ASSURE_AUTH,
	DO_LOCK,
	DO_DOWNLOAD_AND_LOCK,
	DO_UNLOCK,
	DO_ADD,
	DO_ADD_FROM_TEMPLATE,
	DO_ADD_FROM_TEMPLATE_AND_LOCK,
	DO_CHECKIN,
	DO_SET_STATE,
}
