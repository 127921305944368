import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { UrlService } from "core/url.service";
import { FileService } from "files/files.service";
import { Observable, of, Subject, timer } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";
import { ViewService } from "core/view.service";

@Component({
	selector: "stages-files-edit-url",
	templateUrl: "./files-edit-url.component.html",
})
export class FilesEditUrlComponent implements OnInit, OnDestroy {
	urlProperties$!: Observable<stages.file.UrlProperties>;
	titleKey!: string;

	private destroy$ = new Subject<boolean>();

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	urlForm!: FormGroup;
	saveInProgress = false;

	constructor(
		private route: ActivatedRoute,
		private mutexService: MutexService,
		private fileService: FileService,
		private urlService: UrlService,
		private fb: FormBuilder,
		private viewService: ViewService,
	) {}

	ngOnInit(): void {
		this.urlProperties$ = this.route.data.pipe(map((data) => data.urlProperties)).pipe(takeUntil(this.destroy$));
		this.urlProperties$.subscribe((urlProperties) => {
			this.titleKey = urlProperties.id ? "properties" : "files.start.url.title";

			this.urlForm = this.fb.group({
				name: [urlProperties.name, [Validators.required]],
				url: [urlProperties.url, [Validators.required], urlValidator(this.fileService, this.urlService, this.route)],
			});
		});
	}

	get urlName(): AbstractControl | null {
		return this.urlForm.get("name");
	}

	get url(): AbstractControl | null {
		return this.urlForm.get("url");
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	save(urlProperties: stages.file.UrlProperties): void {
		if (this.urlForm.valid) {
			this.saveInProgress = true;
			urlProperties.name = this.urlForm.get("name")!.value;
			urlProperties.url = this.urlService.assureProtocolPrefix(this.urlForm.get("url")!.value);
			this.mutexService.invoke("mutexAddUrl", async () => {
				await (urlProperties.id
					? this.fileService.saveUrl(
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("processVersion")!,
							urlProperties,
					  )
					: this.fileService.addUrl(
							this.route.snapshot.paramMap.get("elementType")!,
							this.route.snapshot.paramMap.get("elementId")!,
							urlProperties,
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("processVersion")!,
					  ));
				this.saveInProgress = false;
				await this.dialog.close();
				await this.viewService.refresh(this.route.snapshot.paramMap);
			});
		}
	}

	isButtonDisabled(urlForm: FormGroup): boolean {
		return !urlForm.valid || urlForm.status === "PENDING" || this.saveInProgress;
	}
}

function urlValidator(fileService: FileService, urlService: UrlService, route: ActivatedRoute): AsyncValidatorFn {
	return (control: AbstractControl): Observable<ValidationErrors | null> | Promise<ValidationErrors | null> => {
		const urlWithProtocol = urlService.assureProtocolPrefix(control.value);

		return timer(500).pipe(
			switchMap(() =>
				fileService.validateUrl(
					route.snapshot.paramMap.get("elementType")!,
					route.snapshot.paramMap.get("elementId")!,
					urlWithProtocol,
					route.snapshot.paramMap.get("workspaceId")!,
					route.snapshot.paramMap.get("processVersion")!,
				),
			),
			map(() => {
				return null;
			}),
			catchError(() => of({ urlPattern: true })),
		);
	};
}
