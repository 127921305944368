import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "core/authentication.service";
import { MainService } from "core/main.service";
import { Observable } from "rxjs";

@Component({
	selector: "stages-support-section",
	templateUrl: "./support-section.component.html",
})
export class SupportSectionComponent {
	appContext$: Observable<stages.context.ApplicationContext>;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthenticationService,
		private mainService: MainService,
	) {
		this.appContext$ = this.mainService.applicationContext$;
	}

	logout(): void {
		this.authService.logout(null);
	}

	openSettings(): void {
		this.router.navigate([{ outlets: { dialog: ["user", "settings"] } }], { relativeTo: this.route });
		if (this.mainService.applicationState.isMobileView.getValue()) {
			this.mainService.applicationState.navigationDrawerOpen = false;
		}
		this.mainService.applicationState.maskVisible = false;
	}
}
