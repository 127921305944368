import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
	selector: "[stagesEmail]",
	providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }],
})

/*
    this validator is used for a more specific email validation check. The angular provided validator
    proves an email address in the format xxxx@xxx. as an proper address.
*/
export class EmailValidatorDirective implements Validator {
	@Input()
	validatorPatterns!: stages.context.ValidatorPatterns;

	validate(control: AbstractControl): ValidationErrors | null {
		return emailValidator(this.validatorPatterns.emailPattern)(control);
	}
}

export function emailValidator(emailPattern: string | null): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!emailPattern || !control.value || typeof control.value !== "string") {
			return null;
		}

		const value = control.value.trim().toLowerCase();
		return new RegExp(emailPattern).test(value)
			? null
			: {
					stagesEmail: {
						valid: false,
					},
			  };
	};
}
