import { Injectable, ViewContainerRef } from "@angular/core";

type SlotListener = (slotName: string, viewContainerRef: ViewContainerRef) => void;

@Injectable()
export class SlotService {
	private readonly createListeners: SlotListener[] = [];

	private readonly destroyListeners: SlotListener[] = [];

	onSlotCreated(createListener: SlotListener): void {
		this.createListeners.push(createListener);
	}

	onSlotDestroyed(destroyListener: SlotListener): void {
		this.destroyListeners.push(destroyListener);
	}

	registerSlot(slotName: string, viewContainerRef: ViewContainerRef): void {
		this.createListeners.forEach((createListener) => {
			createListener(slotName, viewContainerRef);
		});
	}

	destroySlot(slotName: string, viewContainerRef: ViewContainerRef): void {
		this.destroyListeners.forEach((destroyListener) => {
			destroyListener(slotName, viewContainerRef);
		});
	}
}
