import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MutexService } from "common/concurrency/mutex.service";
import { Button } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { maxValidator } from "common/form/max.directive";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { FilesAuthEmbeddableComponent } from "files/auth/files-auth-embeddable.component";
import { FilesOperation } from "files/files-operation";
import { FileContainer } from "files/files.component";
import { FileService } from "files/files.service";
import { EMPTY, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";

type FileOperationPropertiesInfo = stages.file.FileOperationPropertiesInfo;
type FileOperationPropertiesInfoWithTemplateProperties = stages.file.FileOperationPropertiesInfoWithTemplateProperties;
type FilePropertiesFromTemplate = stages.file.FilePropertiesFromTemplate;
type FileDescriptor = stages.process.FileDescriptor;
type Attribute = stages.core.Attribute;

export interface TemplateFile {
	name: string;
	fileId: string;
	fileContainerId: string;
	fileContainerType: string;
	workspaceId: string;
	pv: string;
}

@Component({
	selector: "stages-files-template",
	templateUrl: "./files-template.component.html",
	styleUrls: ["files-template.component.scss"],
})
export class FilesTemplateComponent implements OnInit, OnDestroy {
	templateFileDescriptors!: FileDescriptor[];
	fileName!: string;
	isLoading: boolean = false;
	refreshView: boolean = false;
	fileIsAlreadyLinked: boolean = false;
	fileProperties!: FileOperationPropertiesInfo;

	loadingError$ = new Subject<HttpErrorResponse>();

	fileTemplateForm!: FormGroup;

	templateSelectionValues: TemplateFile[] = [];
	templateSelectionControl!: FormControl;
	fileNameControl!: FormControl;

	workspaceId?: string;
	elementPV?: string;
	elementType?: string;
	elementId?: string;

	credentialsValid = true;
	oAuthUrl?: string;
	cmsTypeMessageKey?: string;

	private destroy$ = new Subject<boolean>();

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	@ViewChild("auth")
	authComponent!: FilesAuthEmbeddableComponent;

	@ViewChild("formElement") formElement!: ElementRef;

	buttons: Button[] = [];

	constructor(
		private readonly route: ActivatedRoute,
		private readonly translateService: TranslateService,
		private readonly fileService: FileService,
		private readonly mutexService: MutexService,
		private readonly formService: FormService,
		private readonly changeDetector: ChangeDetectorRef,
	) {
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
			this.workspaceId = params.get("workspaceId")!;
			this.elementPV = params.get("processVersion")!;
			this.elementType = params.get("elementType")!;
			this.elementId = params.get("elementId")!;
		});

		this.buttons = [
			{
				class: "button sm cancel",
				translate: "cancel",
				click: () => {
					this.close();
				},
				visible: () => true,
				disabled: () => {
					return false;
				},
			},
		];
	}

	ngOnInit(): void {
		if (this.workspaceId && this.elementPV && this.elementType && this.elementId) {
			this.fileService
				.getFileContainer(this.workspaceId, this.elementType, this.elementId, this.workspaceId, this.elementPV, ".")
				.pipe(takeUntil(this.destroy$))
				.subscribe((fileContainer: FileContainer) => {
					this.templateFileDescriptors = fileContainer.templateFileDescriptors;
					this.createForm(this.templateFileDescriptors);
				});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	saveAndDownloadFile(fileProperties: FileOperationPropertiesInfo): void {
		this.formService.markFormGroupTouched(this.fileTemplateForm);
		if (this.fileTemplateForm.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.fileName = this.fileNameControl.value;
			this.isLoading = true;

			this.mutexService.invoke(`addFileTemplate${this.elementType}${this.elementId}`, async () =>
				this.fileService
					.saveLockAndDownloadTemplateByTemplateFile(
						this.route,
						this.workspaceId!,
						this.elementType!,
						this.elementId!,
						this.fileTemplateForm.value.templateSelection,
						this.fileTemplateForm.value.fileName,
						this.getAttributesFromForm(this.fileTemplateForm, fileProperties),
						this.elementPV!,
						fileProperties.source === "CMS",
						this.getTemplateByValue(this.fileTemplateForm.value.templateSelection),
					)
					.then(() => {
						this.refreshView = true;
						this.isLoading = false;
						this.closeCancel();
					}, this.setServerSideErrorsOnFormControls()),
			);
		}
	}

	closeCancel(): void {
		this.dialog.close();
	}

	createForm(fileDescriptors: FileDescriptor[]): void {
		this.isLoading = true;
		this.fileService
			.getPropertiesWithTemplateProperties(
				this.workspaceId!,
				this.elementPV!,
				this.elementId!,
				this.elementType!,
				fileDescriptors,
			)
			.pipe(
				catchError((e: unknown) => {
					if (e instanceof HttpErrorResponse) {
						if (e.status === 901) {
							this.cmsTypeMessageKey = e.error.cmsTypeMessageKey;
							this.credentialsValid = false;
							if (e.error.authInfo.authType === "EXTERNAL_LINK") {
								this.oAuthUrl = e.error.authInfo.url;
							}
							this.changeDetector.detectChanges();
							if (this.oAuthUrl) {
								localStorage.setItem("cmOpName", FilesOperation.ADD_FOM_TEMPLATE.toString());
								localStorage.setItem("cmElementId", this.route.snapshot.paramMap.get("elementId")!);
								localStorage.setItem("cmElementType", this.route.snapshot.paramMap.get("elementType")!);
							} else {
								this.authComponent.resultSubject.pipe(takeUntil(this.destroy$)).subscribe((result) => {
									if (result === "cancel") {
										this.dialog.close();
									} else {
										this.credentialsValid = true;
										this.createForm(fileDescriptors);
									}
								});
							}
						}
						this.isLoading = false;
						this.loadingError$.next(e);
						return EMPTY;
					}
					throw e;
				}),
			)
			.subscribe((templateProperties: FileOperationPropertiesInfoWithTemplateProperties) => {
				this.fileProperties = templateProperties.currentFileProperties;

				for (const templateFileProperties of templateProperties.associatedTemplateProperties) {
					const fileDescriptor = fileDescriptors.find((descriptor) => {
						return (
							templateFileProperties.templateElementType === descriptor.containerTypedId.typeIdent &&
							templateFileProperties.templateElementId === descriptor.containerTypedId.id
						);
					});
					if (fileDescriptor) {
						const templateFile = FilesTemplateComponent.getFileInformationFromTemplate(
							templateFileProperties,
							fileDescriptor.workspaceId,
							this.elementPV!,
						);
						if (templateFile) {
							this.templateSelectionValues.push(templateFile);
						}
					}
				}

				this.fileNameControl = new FormControl("", [Validators.required, maxValidator(255)]);
				this.templateSelectionControl = new FormControl(
					this.templateSelectionValues[0] ? this.templateSelectionValues[0].fileId : "",
				);
				this.fileTemplateForm = new FormGroup({
					templateSelection: this.templateSelectionControl,
					fileName: this.fileNameControl,
				});
				this.isLoading = false;
			});
	}

	getAttributesFromForm(formGroup: FormGroup, fileProperties: FileOperationPropertiesInfo): Attribute[] {
		return fileProperties.attributes.map((attribute) => {
			const attributeFormControl = formGroup.controls[attribute.typeIdent];
			attribute.value = attributeFormControl ? attributeFormControl.value : null;
			return attribute;
		});
	}

	isButtonDisabled(): boolean {
		return this.isLoading ? true : !this.fileTemplateForm;
	}

	updateButtons(): void {
		this.fileIsAlreadyLinked = this.fileName === this.fileNameControl.value;
	}

	private static getFileInformationFromTemplate(
		fileProperties: FilePropertiesFromTemplate,
		workspaceId: string,
		pv: string,
	): TemplateFile | undefined {
		if (
			fileProperties.name &&
			fileProperties.id &&
			fileProperties.templateElementId &&
			fileProperties.templateElementType
		) {
			return {
				name: fileProperties.name,
				fileId: fileProperties.id,
				fileContainerId: fileProperties.templateElementId,
				fileContainerType: fileProperties.templateElementType,
				workspaceId: workspaceId,
				pv: pv,
			};
		}
		return undefined;
	}

	private setServerSideErrorsOnFormControls(): (response: HttpErrorResponse) => void {
		return (response) => {
			let validatorMessage = this.translateService.instant("file.template.error.general");

			this.refreshView = true;
			if (response.status === 403 || response.status === 500) {
				validatorMessage = this.translateService.instant("file.template.error." + response.status.toString());
				this.fileIsAlreadyLinked = true;
			}

			// eslint-disable-next-line no-useless-computed-key -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			this.fileNameControl.setErrors({ ["serverSideError"]: validatorMessage });
			this.isLoading = false;
		};
	}

	private getTemplateByValue(value: string): TemplateFile | undefined {
		return this.templateSelectionValues.find((template) => {
			return template.fileId === value;
		});
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};
}
