<div
	class="nav-drawer"
	[class.hide]="!applicationState.navigationDrawerOpen"
	[class.visible]="applicationState.navigationDrawerOpen"
	[ngClass]="{ 'has-secondary-workspace': applicationState.secondaryWorkspaceEnabled }"
>
	<button
		[attr.title]="
			preference ? ('navigation.workspace.hidedrawer' | translate) : ('navigation.workspace.showdrawer' | translate)
		"
		[attr.aria-label]="
			preference ? ('navigation.workspace.hidedrawer' | translate) : ('navigation.workspace.showdrawer' | translate)
		"
		class="hide-drawer"
		(click)="toggleMenu()"
	>
		<i class="ico ico-chevron-left-small" [ngClass]="{ rotate: !applicationState.navigationDrawerOpen }"></i>
	</button>
	<stages-workspace-navigation></stages-workspace-navigation>
	<div
		class="main-navigation"
		[ngClass]="{
			visible:
				(!applicationState.workspaceNavigationOpen && !applicationState.secondaryWorkspaceNavigationOpen) ||
				(!applicationState.workspaceNavigationOpen && !applicationState.secondaryWorkspaceEnabled)
		}"
	>
		<div class="wrapper">
			<stages-navigation></stages-navigation>
		</div>
	</div>
</div>
<stages-clipboard></stages-clipboard>
