<div class="download">
	<div class="flexContainer">
		<a
			(click)="startPdfCreation()"
			class="clickable"
			[attr.disabled]="this.pollHandle !== undefined || this.downloadReady || this.error !== undefined ? true : null"
		>
			<h4>{{ templateDefinition.titleKey | translate }}</h4>
			<p class="text">{{ templateDefinition.descriptionKey | translate }}</p>
		</a>
		<div class="icon-container action">
			<stages-spin *ngIf="this.pollHandle !== undefined" size="s"></stages-spin>
			<button
				*ngIf="error !== undefined"
				(click)="toggleShowErrorDetails()"
				[attr.title]="'error' | translate"
				[attr.aria-label]="'error' | translate"
			>
				<i class="ico ico-warning error"></i>
			</button>
			<button
				*ngIf="warnings !== undefined"
				(click)="toggleShowWarningsDetails()"
				[attr.title]="'warning' | translate"
				[attr.aria-label]="'warning' | translate"
			>
				<i class="ico ico-warning warning-color"></i>
			</button>
		</div>
	</div>
	<div *ngIf="showErrorDetails || showWarningsDetails">
		<div class="error" role="alert" *ngIf="error !== undefined && showErrorDetails">
			<p>{{ error }}</p>
		</div>
		<div class="warning-color" role="alert" *ngIf="warnings !== undefined && showWarningsDetails">
			<p>{{ warnings }}</p>
		</div>
		<div *ngIf="showWarningsDetails || showErrorDetails" class="icon-container action"></div>
	</div>
	<div class="button-container">
		<button
			*ngIf="downloadReady"
			class="button sm ok"
			(click)="downloadPdf()"
			[attr.title]="'download' | translate"
			[attr.aria-label]="'download' | translate"
		>
			{{ "download" | translate }}
		</button>
	</div>
</div>
