import { createStore } from "@ngneat/elf";
import {
	addEntities,
	hasEntity,
	selectActiveEntity,
	setActiveId,
	withActiveId,
	withEntities,
} from "@ngneat/elf-entities";
import { Injectable } from "@angular/core";

type Folder = stages.workspace.Folder;

const folderStore = createStore({ name: "folder" }, withEntities<Folder>(), withActiveId());

@Injectable({
	providedIn: "root",
})
export class FolderRepository {
	activeFolder$ = folderStore.pipe(selectActiveEntity());

	add(folder: Folder): void {
		if (!this.hasItem(folder.id)) {
			folderStore.update(addEntities(folder));
		}
	}

	setActive(id: string): void {
		folderStore.update(setActiveId(id));
	}

	reset(): void {
		folderStore.reset();
	}

	hasItem(id: string): boolean {
		return folderStore.query(hasEntity(id));
	}
}
