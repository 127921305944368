import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { Observable } from "rxjs";

@Component({
	selector: "stages-error-dialog",
	templateUrl: "./error-dialog.component.html",
})
export class ErrorDialogComponent {
	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	paramMap$: Observable<ParamMap>;

	constructor(private route: ActivatedRoute) {
		this.paramMap$ = this.route.paramMap;
	}

	close(): void {
		this.dialog.close();
	}
}
