import { ChangeDetectorRef, Component, HostBinding, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { Dialog, PluginComponent, PluginService } from "common/editor/plugin.service";
import { UploadCompletedCallback } from "common/upload/upload.component";
import { assertDefined } from "core/functions";
import { ImagesResource } from "core/stages-client";
import { UrlService } from "core/url.service";

type ImageReference = stages.images.ImageReference;

@Component({
	selector: "stages-description-editor-image",
	templateUrl: "./image-plugin.component.html",
})
export class ImagePluginComponent implements PluginComponent, OnInit {
	imageRef?: ImageReference;
	mapValidationMessageKey?: string;
	tref?: string;
	uploadUrl?: string;
	originalImageMap: string | null = null;
	usemap!: string;

	uploadCompletedCallback: UploadCompletedCallback;

	@HostBinding("class.image-plugin")
	imagePluginClass = true;

	@Input()
	dialog!: Dialog;

	constructor(
		private readonly imagesResource: ImagesResource,
		private readonly pluginService: PluginService,
		private readonly urlService: UrlService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private descriptionEditor: DescriptionEditorComponent,
	) {
		// TODO:no-any (change type of UploadCompletedCallback)
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		this.uploadCompletedCallback = (imageReference: any, status: number): void => {
			if (status === 200) {
				this.imageRef = imageReference;
				this.tref = "[[[Image]" + this.pluginService.escapeRefPart(imageReference.name) + "]]";
			} else {
				this.imageRef = undefined;
				alert(imageReference.message);
			}
			changeDetectorRef.detectChanges();
		};
	}

	async ngOnInit(): Promise<void> {
		const processVersion: string = this.activatedRoute.snapshot.paramMap.get("processVersion") ?? "_wv";
		this.uploadUrl = this.urlService.build("app/images/{containerType}/{containerId}", this.getUploadUrlTypedId(), {
			workspaceId: this.activatedRoute.snapshot.paramMap.get("workspaceId"),
			pv: processVersion,
		});

		if (this.dialog.getSelectedReference()) {
			const imageName = this.dialog.getSelectedReference().getTarget();
			const srcAttr = this.dialog.getSelectedElement()!.attributes.getNamedItem("src");
			const usemapAttr = this.dialog.getSelectedElement()!.attributes.getNamedItem("usemap");
			this.usemap = usemapAttr && usemapAttr.value.length > 1 ? usemapAttr.value.substring(1) : generateMapName();
			const imgContainerTypedId = isStoredImage(srcAttr)
				? getContainerTypedId(srcAttr!)
				: [this.dialog.getBeanType(), this.dialog.getBeanId()];

			this.imageRef = await this.imagesResource.getImageProperties(
				imgContainerTypedId[0],
				imgContainerTypedId[1],
				imageName!,
			);
			this.originalImageMap = this.imageRef.imageMap;
			if (this.descriptionEditor.editedImageMap[this.usemap]) {
				this.imageRef.imageMap = this.descriptionEditor.editedImageMap[this.usemap];
			}

			this.tref = assertDefined(this.dialog.getSelectedReference().getText());
			this.changeDetectorRef.detectChanges();
		} else {
			this.imageRef = undefined;
			this.usemap = generateMapName();
		}

		this.dialog.registerDialogOkHandler(() => {
			if (this.imageRef && this.imageRef.name) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				const TREF_ATTRIBUTE_NAME = (window as any).PKIT.ckeditor.CkEditorUtils.TREF_ATTRIBUTE_NAME as string;
				const selectedElement = this.dialog.getSelectedElement();
				let html = `<img src="${this.imageRef.viewUrl}" `;
				if (selectedElement && selectedElement.getAttribute("data-pasted")) {
					html += 'data-pasted="true" ';
				}
				html += `${TREF_ATTRIBUTE_NAME}="${this.tref}" alt="${this.imageRef.name}" usemap="#${this.usemap}"/>`;
				if (this.originalImageMap !== this.imageRef.imageMap) {
					this.descriptionEditor.editedImageMap[this.usemap] = this.imageRef.imageMap!;
				}
				this.dialog.insertHtml(html);
			}
			this.dialogClose();
			return true;
		});

		this.dialog.registerDialogCancelHandler(() => {
			this.dialogClose();
			return true;
		});
	}

	private dialogClose(): void {
		this.imageRef = undefined;
	}

	private getUploadUrlTypedId(): { [key: string]: Maybe<string> } {
		const properties = this.dialog.getNonProcessEditorProperties();
		return {
			containerType: properties?.imageContainerType ? properties.imageContainerType : this.dialog.getBeanType(),
			containerId: this.dialog.getBeanId(),
		};
	}

	async onChange(): Promise<void> {
		try {
			if (this.imageRef) {
				const uploadUrlTypedId = this.getUploadUrlTypedId();
				this.dialog.disableOkButton();
				await this.imagesResource.validateImageMap(
					uploadUrlTypedId.containerType!,
					uploadUrlTypedId.containerId!,
					this.imageRef!.imageMap!,
				);
			}
			this.mapValidationMessageKey = undefined;
			this.dialog.enableOkButton();
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
		} catch (errorResponse) {
			this.mapValidationMessageKey = `editor.image.invalid.${errorResponse.error.validatorName}`;
			this.dialog.disableOkButton();
		}
	}
}

function generateMapName(): string {
	return new Date().getTime().toString() + "_" + Math.floor(Math.random() * 1000).toString();
}

function isStoredImage(imageSrc: Attr | null): boolean {
	return imageSrc !== null && imageSrc.value.indexOf("app/images/") >= 0;
}

function getContainerTypedId(imageSrc: Attr): [string, string] {
	const relevantSnippet = imageSrc.value.substring(imageSrc.value.indexOf("app/images/") + 11);
	const parts = relevantSnippet.split("/");
	return [parts[0], parts[1]];
}
