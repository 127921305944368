<ng-template let-association="association" #associationlabel>
	<h2>
		<ng-container *ngIf="autoNumber">{{ getIndex(association) }} </ng-container>{{ association.targetElement.label }}
		<span
			*ngIf="association.changeMarker && association.changeMarker.added"
			class="badge added-badge"
			translate="process.compare.added"
		></span>
		<span
			*ngIf="association.changeMarker && association.changeMarker.deleted"
			class="badge deleted-badge"
			translate="process.compare.deleted"
		></span>
	</h2>
</ng-template>

<ng-container *ngIf="self$ | async as self">
	<article class="document" *ngIf="associations$ | async as associations">
		<section *ngFor="let association of associations">
			<ng-container *ngIf="$any(association.targetElement) !== self">
				<a [routerLink]="getRoute(association)" *ngIf="!(association.changeMarker && association.changeMarker.deleted)"
					><ng-container *ngTemplateOutlet="associationlabel; context: { association: association }"></ng-container
				></a>
				<div *ngIf="association.changeMarker && association.changeMarker.deleted">
					<ng-container *ngTemplateOutlet="associationlabel; context: { association: association }"></ng-container>
				</div>
			</ng-container>
			<stages-description-view
				descriptionClass="shift-font-size"
				[html]="$any(association.targetElement).description.html"
			></stages-description-view>
		</section>
	</article>
</ng-container>
