import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { TailoringService } from "process/tailoring/manual/tailoring.service";
import { Observable, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

@Component({
	templateUrl: "./routeable-tailoring.component.html",
	styleUrls: ["./routeable-tailoring.component.scss"],
})
export class RouteableTailoringComponent implements OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();

	tailoring$: Observable<stages.process.tailoring.ManualTailoringPreview>;

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	typeIconPrefix = "et";

	constructor(route: ActivatedRoute, private tailoringService: TailoringService) {
		this.tailoring$ = route.paramMap.pipe(
			switchMap((paramMap) => {
				const typeIdent = paramMap.get("type")!;
				const ids = paramMap.get("ids")!.split(",");
				const pv = paramMap.get("processVersion")!;
				const workspaceId = paramMap.get("workspaceId")!;
				const status = +paramMap.get("status")!;
				this.typeIconPrefix = paramMap.get("typeIconPrefix") ?? "et";

				return this.tailoringService.previewTailoring(workspaceId, pv, typeIdent, ids, status);
			}),
			takeUntil(this.destroy$),
		);
	}

	ngOnDestroy(): void {
		if (this.destroy$) {
			this.destroy$.next(true);
		}
	}

	cancel(): void {
		this.dialog.close();
	}

	save(): void {
		this.dialog.close();
	}
}
