import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { FilesOperation } from "files/files-operation";
import { FileService } from "files/files.service";

type Attribute = stages.core.Attribute;
type FileUpload = stages.file.FileUpload;

@Component({
	templateUrl: "./files-oauth-success.component.html",
})
export class FilesOAuthSuccessComponent implements OnInit, OnDestroy {
	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	constructor(private fileService: FileService, private route: ActivatedRoute) {}

	ngOnDestroy(): void {
		this.clearLocalStore();
	}

	clearLocalStore(): void {
		localStorage.removeItem("cmFileId");
		localStorage.removeItem("cmElementId");
		localStorage.removeItem("cmElementType");
		localStorage.removeItem("cmSingleFile");
		localStorage.removeItem("cmWorkspaceId");
		localStorage.removeItem("cmProcessVersionIdentifier");
		localStorage.removeItem("cmAttributesJSON");
		localStorage.removeItem("cmFileUploadJSON");
		localStorage.removeItem("cmTemplateId");
		localStorage.removeItem("cmFileName");
		localStorage.removeItem("cmVersionIdentifier");
		localStorage.removeItem("cmOpenNewTab");
	}

	ngOnInit(): void {
		const cmOpName = localStorage.getItem("cmOpName");
		const fileId = localStorage.getItem("cmFileId")!;
		const elementType = localStorage.getItem("cmElementType")!;
		const elementId = localStorage.getItem("cmElementId")!;
		const workspaceId = localStorage.getItem("cmWorkspaceId")!;
		const processVersion = localStorage.getItem("cmProcessVersionIdentifier")!;

		if (cmOpName) {
			switch (cmOpName) {
				case FilesOperation.DOWNLOAD_AND_LOCK.toString():
					this.retry(() => this.fileService.lockAndDownload(fileId, elementType, elementId, true, this.route));
					break;
				case FilesOperation.LOCK.toString():
					this.retry(() => this.fileService.lockAndDownload(fileId, elementType, elementId, false, this.route));
					break;
				case FilesOperation.UNLOCK.toString():
					this.retry(() => this.fileService.unlock(fileId, elementType, elementId, this.route));
					break;
				case FilesOperation.CHECKIN.toString():
					this.retry(() => this.fileService.openPopup(fileId, elementType, elementId, "checkin", false, this.route));
					break;
				case FilesOperation.SET_STATE.toString():
					this.retry(() => this.fileService.openPopup(fileId, elementType, elementId, "setState", false, this.route));
					break;
				case FilesOperation.ADD.toString():
					this.retry(async () => {
						const cmSingleFile = localStorage.getItem("cmSingleFile")! === "true";
						const currentFileCount =
							localStorage.getItem("currentFileCount")! === "true"
								? Number.parseInt(localStorage.getItem("cmCurrentFileCount")!, 10)
								: 0;
						return this.fileService.openFilesAddPopup(
							elementType,
							elementId,
							cmSingleFile,
							currentFileCount,
							this.route,
						);
					});
					break;
				case FilesOperation.ADD_FOM_TEMPLATE.toString():
					this.retry(() => this.fileService.openFileTemplatePopup(elementType, elementId, this.route));
					break;

				case FilesOperation.DO_DOWNLOAD_AND_LOCK.toString():
					this.retry(() =>
						this.fileService.doLockAndDownload(
							this.route,
							fileId,
							this.getAttributes(),
							workspaceId,
							processVersion,
							true,
						),
					);
					break;
				case FilesOperation.DO_LOCK.toString():
					this.retry(() =>
						this.fileService.doLockAndDownload(
							this.route,
							fileId,
							this.getAttributes(),
							workspaceId,
							processVersion,
							false,
						),
					);
					break;
				case FilesOperation.DO_UNLOCK.toString():
					this.retry(() =>
						this.fileService.doUnlock(this.route, fileId, this.getAttributes(), workspaceId, processVersion),
					);
					break;
				case FilesOperation.DO_CHECKIN.toString():
					this.retry(() =>
						this.fileService.checkin(this.route, fileId, this.getFileUpload(), workspaceId, processVersion),
					);
					break;
				case FilesOperation.DO_ADD.toString():
					this.retry(() =>
						this.fileService.saveUploads(
							this.route,
							workspaceId,
							elementType,
							elementId,
							this.getFileUpload(),
							processVersion,
						),
					);
					break;
				case FilesOperation.DO_ADD_FROM_TEMPLATE.toString():
					this.retry(async () => {
						const templateId = localStorage.getItem("cmTemplateId")!;
						const fileName = localStorage.getItem("cmFileName")!;
						return this.fileService.saveLockAndDownloadTemplate(
							this.route,
							workspaceId,
							elementType,
							elementId,
							templateId,
							fileName,
							this.getAttributes(),
							processVersion,
							false,
						);
					});
					break;
				case FilesOperation.DO_ADD_FROM_TEMPLATE_AND_LOCK.toString():
					this.retry(async () => {
						const templateId = localStorage.getItem("cmTemplateId")!;
						const fileName = localStorage.getItem("cmFileName")!;
						return this.fileService.saveLockAndDownloadTemplate(
							this.route,
							workspaceId,
							elementType,
							elementId,
							templateId,
							fileName,
							this.getAttributes(),
							processVersion,
							true,
						);
					});
					break;
				case FilesOperation.DO_SET_STATE.toString():
					this.retry(() =>
						this.fileService.saveState(this.route, fileId, this.getAttributes(), workspaceId, processVersion),
					);
					break;
				case FilesOperation.DO_DOWNLOAD.toString():
					this.retry(async () => {
						const version = localStorage.getItem("cmVersionIdentifier")!;
						const openNewTab = localStorage.getItem("cmOpenNewTab")! === "true";
						return this.fileService.downloadAlreadyAuthenticated(
							this.route,
							fileId,
							workspaceId,
							processVersion,
							version,
							openNewTab,
						);
					});
					break;
				case FilesOperation.DO_DOWNLOAD_AND_ASSURE_AUTH.toString():
					this.retry(async () => {
						const version = localStorage.getItem("cmVersionIdentifier")!;
						const openNewTab = localStorage.getItem("cmOpenNewTab")! === "true";
						return this.fileService.download(this.route, fileId, workspaceId, processVersion, version, openNewTab);
					});
					break;

				default:
					throw new Error("unexpected operation: " + cmOpName);
			}
		} else {
			this.exit();
		}
	}

	private async retry(retryFunction: () => Promise<unknown>): Promise<void> {
		await retryFunction();
		this.exit();
	}

	private getAttributes(): Attribute[] {
		const cmAttributesJSON = localStorage.getItem("cmAttributesJSON");
		if (cmAttributesJSON) {
			return JSON.parse(cmAttributesJSON);
		}
		return [];
	}

	private getFileUpload(): FileUpload {
		const fileUploadJSON = localStorage.getItem("cmFileUploadJSON");
		if (fileUploadJSON) {
			return JSON.parse(fileUploadJSON);
		}
		throw new Error("no file upload avaiable");
	}

	private exit(): void {
		this.clearLocalStore();
		this.dialog.close();
	}
}
