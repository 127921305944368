<div class="auto-complete">
	<label class="relative">
		<i *ngIf="iconClass" [class]="iconClass"></i>
		<input
			#searchInput
			type="search"
			class="search"
			[placeholder]="'search.link.placeholder' | translate"
			autoComplete="off"
			[(ngModel)]="term"
			maxlength="256"
		/>
	</label>
</div>
