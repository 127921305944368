<div #diagramContainer class="container" [@expandCollapseFromTop]="getExpandCollapseFromTop(diagramContainer)">
	<stages-image-zoom
		#imgZoom
		*ngIf="imageZoomToolLoaded && currentElement"
		[sourceChanged]="sourceChanged"
		[diagramLoaded]="diagramLoaded!"
		[isFirstDiagram]="isFirstDiagramOnPage"
		[isZoomToolSupported]="isBrowserSupported"
		[id]="diagramId!"
		[fitToPage]="fitToPage"
		[prefixedIdent]="config.prefixedIdent"
		[currentElementName]="currentElement.label"
		[imageMap]="imageMap"
		[src]="src!"
		[container]="diagramContainer"
		(triggerResize)="setScrollPosition()"
	></stages-image-zoom>
	<div *ngIf="expanded && (!imageZoomToolLoaded || !currentElement || refreshingDiagram)" style="height: 30px">
		<stages-spin size="sm"></stages-spin>
	</div>
</div>
