import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BroadcastService } from "core/broadcast.service";
import { Events } from "core/events";
import { assertDefined } from "core/functions";
import { MainService } from "core/main.service";
import { ProcessResource } from "core/stages-client";

@Directive({
	selector: "[stagesLazyReferenceResolver]",
})
export class LazyReferenceResolverDirective implements OnChanges {
	attributeRegex = /(\d+)\|(.+)/;

	@Input()
	html!: string;

	constructor(
		private elem: ElementRef,
		private processResource: ProcessResource,
		private route: ActivatedRoute,
		private router: Router,
		private broadcastService: BroadcastService,
		private mainService: MainService,
	) {}

	ngOnChanges(): void {
		const lazyAreas = this.elem.nativeElement.querySelectorAll("area[data-lazy-ref]");
		for (const lazyArea of lazyAreas) {
			const attributeMatch = assertDefined(this.attributeRegex.exec(lazyArea.getAttribute("data-lazy-ref")));
			const workspaceId = attributeMatch[1];
			const tref = attributeMatch[2];
			lazyArea.href = buildRedirectUrl(workspaceId, tref, this.route.snapshot.params.processVersion);
			lazyArea.addEventListener("click", async () => {
				this.mainService.setNavigationInProgress(true);
				try {
					const resolvedReference = await this.processResource.resolveTextualReference(workspaceId, {
						tref: tref,
					});
					if (resolvedReference.valid) {
						this.router.navigateByUrl(resolvedReference.link!.replace(/^#+/g, ""));
					} else {
						this.handleUnresolvableReference(tref);
						this.mainService.setNavigationInProgress(false);
					}
				} catch (err: unknown) {
					this.mainService.setNavigationInProgress(false);
					throw err;
				}
			});
		}
	}

	handleUnresolvableReference(tref: string): void {
		console.warn(`Unresolvable textual reference, navigation aborted: ${tref}`);
		this.broadcastService.broadcast(Events.MESSAGE, { translate: "application.unresolvablereference" });
	}
}

function buildRedirectUrl(workspaceId: string, tref: string, pv: string): string {
	return `app/workspace/${workspaceId}/process/resolveRedirect?pv=${encodeURIComponent(pv)}&tref=${encodeURIComponent(
		tref,
	)}`;
}
