import { Component, Input } from "@angular/core";

export interface StartOption {
	classes: string[];
	title: string;
	icon: string;
	message: string;
	button: string;
	disabled: boolean;
	on(): void;
}

@Component({
	selector: "stages-files-start",
	templateUrl: "./files-start.component.html",
	styleUrls: ["./files-start.component.scss"],
})
export class FilesStartComponent {
	@Input()
	headline?: string;

	@Input()
	translateNone?: string;

	@Input()
	startOptions!: StartOption[];
}
