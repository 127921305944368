<ng-container *ngIf="selfElement$ | async">
	<stages-floating-action-menu iconClass="ico ico-add" *ngIf="sequences && showMenu">
		<li *ngFor="let menuItem of menuItems">
			<button class="action needsclick" (click)="menuItem.on()">
				<i class="ico" [ngClass]="menuItem.iconClasses"></i>
				<span class="bubble" [translate]="menuItem.messageKey"></span>
			</button>
		</li>
	</stages-floating-action-menu>
</ng-container>
