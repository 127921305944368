import { Injectable } from "@angular/core";
import { DialogService } from "common/dialog/dialog.service";
import { ResetDialogComponent } from "process/tailoring/reset/reset-dialog.component";

@Injectable({ providedIn: "root" })
export class ResetTailoringDialogService {
	constructor(private dialogService: DialogService) {}

	openDialog(workspaceId: string, pv: string): void {
		this.dialogService.open<void>(`reset-tailoring-dialog-${workspaceId}`, {
			component: ResetDialogComponent,
			componentArgs: {
				workspaceId: workspaceId,
				pv: pv,
			},
			defaultResult: undefined,
		});
	}
}
