import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import {
	AbstractControl,
	AsyncValidatorFn,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Dialog, PluginService } from "common/editor/plugin.service";
import { UrlService } from "core/url.service";
import { FileService } from "files/files.service";
import { LinkDetailsComponent } from "process/description/link-plugin/link-details.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";
import { Observable, of, timer } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Component({
	selector: "stages-external-link",
	templateUrl: "./external-link.component.html",
})
export class ExternalLinkComponent extends LinkDetailsComponent implements OnInit, AfterViewInit {
	@ViewChildren("nextTab") labels?: QueryList<ElementRef>;

	form: FormGroup;

	dialog?: Dialog;

	constructor(
		private fileService: FileService,
		private route: ActivatedRoute,
		pluginService: PluginService,
		linkPluginComponent: LinkPluginComponent,
		private urlService: UrlService,
	) {
		super(pluginService);
		this.form = new FormGroup({
			url: new FormControl(
				{ value: "" },
				[Validators.required],
				urlValidator(this.fileService, this.urlService, this.route),
			),
		});
		this.dialog = linkPluginComponent.dialog;
	}

	override ngOnInit(): void {
		// "super.ngOnInit();" is not called. This is perhaps an error, but adding it was explicitely not wanted as part of ST-33629.
		this.form.reset({ url: this.selectedReference.getTarget() });
		if (this.preferredDisplayText) {
			this.selectedReference.setDisplayText(this.preferredDisplayText);
		}
		this.updateDisplayName();
	}

	ngAfterViewInit(): void {
		if (this.labels && this.dialog) {
			this.labels.forEach((label) => {
				this.dialog!.addFocusElement(label.nativeElement);
			});
		}
	}

	protected override getLinkName(): string {
		if (!this.selectedReference) {
			return "";
		}
		return this.selectedReference.getDisplayText();
	}

	setTargetUrl(value?: string): void {
		if (!value) {
			return;
		}

		const url = this.urlService.assureProtocolPrefix(value);
		this.selectedReference.setTargetPath([url]);
	}

	getControlErrors(name: string): string[] {
		const control = this.form.get(name);
		return control?.errors ? Object.keys(control.errors) : [];
	}

	isButtonDisabled(form: FormGroup): boolean {
		return form.invalid || form.status === "PENDING";
	}
}

function urlValidator(fileService: FileService, urlService: UrlService, route: ActivatedRoute): AsyncValidatorFn {
	return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
		const urlWithProtocol = urlService.assureProtocolPrefix(control.value);

		return timer(500).pipe(
			switchMap(() =>
				fileService.validateUrl(
					route.snapshot.paramMap.get("elementType")!,
					route.snapshot.paramMap.get("elementId")!,
					urlWithProtocol,
					route.snapshot.paramMap.get("workspaceId")!,
					route.snapshot.paramMap.get("processVersion")!,
				),
			),
			map(() => {
				return null;
			}),
			catchError(() => of({ urlPattern: true })),
		);
	};
}
