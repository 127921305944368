<header class="page-header" *ngIf="isActive()">
	<stages-process-version *ngIf="showProcessVersionSwitch"></stages-process-version>
	<div class="breadcrumb-line">
		<stages-breadcrumb
			*ngIf="showBreadCrumb()"
			[links]="breadCrumbTemplate!.links"
			[path]="breadCrumbTemplate!.path"
		></stages-breadcrumb>
		<ng-container *ngIf="showBackButton()">
			<stages-backbutton
				*ngIf="!backButtonWithRouterLink"
				[isEmbeddedInCard]="isEmbeddedInCard"
				(backButtonClicked)="backButtonClicked.emit(true)"
			></stages-backbutton>
			<stages-backbutton *ngIf="backButtonWithRouterLink" [backButton]="backButtonWithRouterLink"></stages-backbutton>
		</ng-container>
	</div>
	<ng-container *ngIf="headline !== undefined">
		<h1
			class="shorten"
			*ngIf="headline!.params !== undefined"
			[translate]="headline!.translate!"
			[translateParams]="headline!.params!"
		></h1>
		<ng-container *ngIf="!headline!.params">
			<h1 class="shorten" *ngIf="headline!.translate" [translate]="headline!.translate!"></h1>
			<h1 class="shorten" *ngIf="headline!.plainText">{{ headline!.plainText! }}</h1>
		</ng-container>
	</ng-container>
</header>
