import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";
import { UrlService } from "core/url.service";

type UserFeedbackRequestTemplate = stages.process.feedback.UserFeedbackRequestTemplate;
type UserFeedbackResponse = stages.process.feedback.UserFeedbackResponse;
type CreatedTicket = stages.process.feedback.CreatedTicket;

@Injectable({ providedIn: "root" })
export class FeedbackService {
	constructor(
		private readonly processElementsResource: ProcessElementsResource,
		private readonly urlService: UrlService,
	) {}

	async saveFeedback(
		userFeedback: UserFeedbackResponse,
		workspaceId: string,
		processVersion: string,
		type: string,
		id: string,
	): Promise<CreatedTicket> {
		return this.processElementsResource.save(workspaceId, type, id, userFeedback, {
			pv: processVersion,
		});
	}

	async getUserTemplate(workspaceId: string): Promise<UserFeedbackRequestTemplate> {
		return this.processElementsResource.getTemplate(workspaceId);
	}

	getFeedbackLink(workspaceId: string, processVersion: string, type: string, id: string, elementUrl: string): string {
		return this.urlService.build(
			"app/workspace/{workspaceId}/process/elements/{type}/{id}/feedbacklink",
			{
				workspaceId: workspaceId,
				type: type,
				id: id,
			},
			{
				pv: processVersion,
				elementUrl: elementUrl,
				workspaceId: workspaceId,
			},
		);
	}
}
