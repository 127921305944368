import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MutexService } from "common/concurrency/mutex.service";
import { MainService } from "core/main.service";
import { TranslateResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DependentElement } from "process/dependant-elements/dependent-elements-management.service";

type Translation = stages.process.translate.Translation;

interface Data {
	view: stages.process.View;
	translation: Translation;
	dependent: boolean;
}

@Component({
	selector: "stages-translate",
	templateUrl: "translate.component.html",
})
export class TranslateComponent {
	TranslateComponent = TranslateComponent;

	data$: Observable<Data>;
	workspaceView$: Observable<stages.workspace.application.WorkspaceView>;
	dependentElement?: DependentElement;
	type!: string;
	identity!: string;
	id!: string;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mainService: MainService,
		private mutexService: MutexService,
		private viewService: ViewService,
		private readonly translateResource: TranslateResource,
	) {
		this.data$ = combineLatest([
			this.route.data,
			this.route.data.pipe(
				switchMap(async (data) => {
					const dependentIdentity: string | null = this.route.snapshot.paramMap.get("depidentity");
					if (dependentIdentity !== null) {
						const dependentElements: DependentElement[] = data.view.processView.dependentElements;
						this.dependentElement = dependentElements.find((element) => element.identity === dependentIdentity);
						this.type = this.dependentElement!.type.ident;
						this.identity = this.dependentElement!.identity;
						this.id = this.dependentElement!.id;
					} else {
						this.type = data.view.processView.type.ident;
						this.identity = data.view.processView.identity;
						this.id = data.view.processView.id;
					}
					return this.translateResource.getTranslation(data.view.viewWorkspace.id, this.type, this.identity, {});
				}),
			),
		]).pipe(
			map(([data, translation]) => {
				return {
					view: data.view.processView,
					translation: translation,
					dependent: this.route.snapshot.paramMap.get("depidentity") !== null,
				};
			}),
		);

		this.workspaceView$ = this.mainService.workspaceView$;
	}

	save(translation: Translation, dependent: boolean): void {
		const translatedElements = translation.translatedElements;
		const paramMap = this.route.snapshot.paramMap;

		this.mutexService.invoke("processSave", async () => {
			await this.translateResource.saveTranslate(
				paramMap.get("workspaceId")!,
				translation.typeIdent,
				this.identity,
				translatedElements,
			);
			await this.viewService.refresh(paramMap);
			await this.router.navigate(TranslateComponent.getCloseLink(translation, dependent), { relativeTo: this.route });
		});
	}

	async cancel(translation: Translation, dependent: boolean): Promise<void> {
		await this.router.navigate(TranslateComponent.getCloseLink(translation, dependent), { relativeTo: this.route });
	}

	static getCloseLink(translation: Translation, dependent: boolean): string[] {
		if (dependent) {
			return translation.typeIdent === "process" ? ["../../../.."] : ["../.."];
		}
		return translation.typeIdent === "process" ? ["../../.."] : [".."];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static provideTitle(translateService: TranslateService, mergedRouteData: any): Observable<string> {
		return translateService
			.get("edit")
			.pipe(
				map((edit) => `${edit} ${mergedRouteData.view.processView.label} - ${mergedRouteData.view.viewWorkspace.name}`),
			);
	}
}
