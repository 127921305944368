import { Injectable } from "@angular/core";
import { Headers, HttpService } from "core/http.service";
import { UrlService } from "core/url.service";

@Injectable({ providedIn: "root" })
export class AuthService {
	// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	constructor(private httpService: HttpService, private urlService: UrlService) {}

	async authenticate(
		username: string,
		password: string,
		fileId: string | null,
		elementType: string | null,
		elementId: string | null,
		workspaceId: string,
		processVersion: string,
	): Promise<unknown> {
		let url;
		if (fileId) {
			url = this.urlService.build(
				"app/files/{id}/performCmsAuthentication",
				{
					id: fileId,
				},
				{
					workspaceId: workspaceId,
					pv: processVersion,
				},
			);
		} else {
			url = this.urlService.build(
				"app/workspace/{workspaceId}/process/elements/{elementType}/{elementId}/files/performCmsAuthentication",
				{
					workspaceId: workspaceId,
					elementType: elementType,
					elementId: elementId,
				},
				{
					workspaceId: workspaceId,
					pv: processVersion,
				},
			);
		}

		return this.executeRequest(url, "POST", null, username, password);
	}

	async executeRequest(
		url: string,
		method: string,
		jsonBody: unknown,
		username: string,
		password: string,
	): Promise<unknown> {
		const authHeader = buildAuthorizationHeader(username, password);

		return this.executeRequest0(url, method, jsonBody, authHeader);
	}

	async executeRequest0(url: string, method: string, jsonBody: unknown, authHeader?: Headers): Promise<unknown> {
		const upperCaseMethod = method.toUpperCase();
		switch (upperCaseMethod) {
			case "POST":
				return this.httpService.post(url, jsonBody, authHeader, "json");
			case "PUT":
				return this.httpService.put(url, jsonBody, authHeader, "json");
			case "GET":
				return this.httpService.get(url, null, authHeader, "json");
			case "DELETE":
				return this.httpService.post(url, jsonBody, authHeader, "json");
			default:
				throw new Error("Unsupported http method: " + upperCaseMethod);
		}
	}
}

function buildAuthorizationHeader(username: string, password: string): Headers {
	const encCredentials: string = btoa(username + ":" + password);
	const authVal: string = "Basic " + encCredentials;
	return { Authorization: authVal };
}
