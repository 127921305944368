import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { ViewService } from "core/view.service";
import { DashboardService } from "dashboard/dashboard.service";
import { MyMostFrequentlyUsedElementsStore } from "dashboard/myMostRequentlyUsedElements/my-most-frequently-used-elements-store.logic";

type Element = stages.dashboard.Element;

@Component({
	selector: "stages-my-most-frequently-used-elements",
	templateUrl: "./my-most-frequently-used-elements.component.html",
	styleUrls: ["my-most-frequently-used-elements.component.scss"],
})
export class MyMostFrequentlyUsedElementsComponent implements OnInit, OnDestroy {
	MyMostFrequentlyUsedElementsComponent = MyMostFrequentlyUsedElementsComponent;
	dataSource!: PageableDataSource<Element>;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mutexService: MutexService,
		private dashboardService: DashboardService,
		private viewService: ViewService,
	) {}

	ngOnInit(): void {
		this.dataSource = new PageableDataSource(
			new MyMostFrequentlyUsedElementsStore(this.dashboardService),
			this.mutexService,
			this.router,
			this.route,
			"page",
		);
	}

	ngOnDestroy(): void {
		if (this.dataSource) {
			this.dataSource.disconnect();
		}
	}

	getIconClasses(element: Element): string[] {
		return this.viewService.getIconClasses(
			{
				ident: element.type.ident,
				subtypeIdent: element.type.subtypeIdent,
			},
			false,
			false,
		);
	}

	getLink(element: Element): unknown[] {
		return [
			"/",
			"workspace",
			element.workspaceId,
			this.route.snapshot.paramMap.get("processVersion"),
			"process",
			element.type.ident.toLowerCase(),
			element.identity,
		];
	}

	static getId(element: Element): string {
		return element.guid;
	}

	static isDisabled(): boolean {
		return false;
	}
}
