<div class="workspace-selection slide-container" [ngClass]="classes!">
	<div class="tabs" role="tablist">
		<a
			*ngFor="let tab of tabs"
			class="tab"
			role="tab"
			[class.is-active]="activeTab && activeTab === tab"
			(click)="openTab(tab)"
		>
			<div class="text-and-border">
				<h5 [translate]="'navigation.workspace.tab.' + tab"></h5>
				<span class="tab-border"></span>
			</div>
		</a>
	</div>

	<nav>
		<div class="filter">
			<input
				#searchInput
				type="text"
				class="text"
				[(ngModel)]="inputSearchTerm"
				[attr.placeholder]="'navigation.workspace.filter.placeholder' | translate"
			/>
		</div>

		<stages-carousel #carousel>
			<stages-carousel-item *ngFor="let tab of tabs" [name]="tab">
				<ng-template>
					<stages-workspace-list
						*ngIf="tab !== 'navigate'"
						[workspaces]="(workspaces$ | async)!"
						[selected]="currentWorkspaceId"
						[filterTerm]="searchTerm"
						[enableLocalFilter]="tab === 'favorites' || tab === 'archived' || tab === 'related' || tab === 'modules'"
						[bookmarkable]="bookmarkable"
						[showEmpty]="tab !== 'search'"
						[searchEngineAvailable]="tab !== 'search' || searchEngineAvailable"
						(select)="onSelectWorkspace($event)"
						(bookmark)="onBookmark($event)"
						stagesClickStopPropagation
					>
					</stages-workspace-list>
					<stages-workspace-carousel
						*ngIf="tab === 'navigate'"
						[folder]="(folder$ | async)!"
						[selected]="currentWorkspaceId"
						[filterTerm]="searchTerm"
						(up)="onNavigate($event)"
						(down)="onNavigate($event)"
						(select)="onSelectWorkspace($event)"
						stagesClickStopPropagation
					>
					</stages-workspace-carousel>
				</ng-template>
			</stages-carousel-item>
		</stages-carousel>
	</nav>
</div>
