import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface BackButton {
	routerLink: string;
}

@Component({
	selector: "stages-backbutton",
	templateUrl: "./backbutton.component.html",
	styleUrls: ["./backbutton.component.scss"],
})
export class BackButtonComponent {
	@Input()
	backButton?: BackButton;

	@Input()
	isEmbeddedInCard: boolean = false;

	@Output() readonly backButtonClicked = new EventEmitter<boolean>();

	hasRouterLink(backButton?: BackButton): boolean {
		return backButton?.routerLink !== undefined;
	}
}
