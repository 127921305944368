import { Location } from "@angular/common";
import { Injectable, NgZone } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AnchorScrollService {
	registered: number = 0;
	deactivated: number = 0;
	eventScrollListener: EventListenerOrEventListenerObject;
	scrollTimeout?: number;
	fragmentTimeout?: number;
	searchBarHeight: number = 0;
	readonly IN_VIEW_TOP_BONUS = 100;
	readonly SCROLL_TO_TOP = "scrollToTop";

	constructor(private zone: NgZone, private location: Location) {
		this.eventScrollListener = () => this.onWindowScroll();
	}

	private onWindowScroll(): void {
		if (this.scrollTimeout) {
			window.clearTimeout(this.scrollTimeout);
		}
		if (this.registered === 0 || this.deactivated !== 0) {
			return;
		}
		this.scrollTimeout = window.setTimeout(() => {
			this.onScrollEnd();
		}, 100);
	}

	private onScrollEnd(): void {
		if (this.searchBarHeight === 0) {
			this.searchBarHeight = document.getElementById("search")!.getBoundingClientRect().height;
		}
		const currentPath = this.location.path(false);
		const pathWithoutHash = this.getPathWithoutHash(currentPath);
		const anchorName = this.getAnchorInView();
		const newPath = anchorName ? `${pathWithoutHash}#${anchorName}` : pathWithoutHash;
		if (currentPath !== newPath) {
			this.location.replaceState(newPath);
		}
	}

	private getAnchorInView(): string | undefined {
		if (window.pageYOffset === 0) {
			return undefined;
		}
		const nodeList = document.querySelectorAll(".stages_description a[name]");
		for (let i = 0; i < nodeList.length; i++) {
			const anchorElement = nodeList.item(i) as HTMLElement;
			if (this.isElementInView(anchorElement)) {
				return anchorElement.attributes.getNamedItem("name")!.value;
			}
		}
		return undefined;
	}

	private isElementInView(element: HTMLElement): boolean {
		const bounding = element.getBoundingClientRect();
		const bottomValue = window.innerHeight || document.documentElement.clientHeight;
		return bounding.top >= this.searchBarHeight - this.IN_VIEW_TOP_BONUS && bounding.bottom <= bottomValue;
	}

	getPathWithoutHash(path: string): string {
		return path.search(/[^/]\#/) > 0 ? path.substring(0, path.lastIndexOf("#")) : path;
	}

	getHashFromPath(path: string): string | undefined {
		if (path.search(/[^/]\#/) < 0) {
			return undefined;
		}
		return path.substring(path.lastIndexOf("#") + 1);
	}

	scrollToFragment(fragment: string, onScrollDoneCallback: () => void): void {
		if (this.searchBarHeight === 0) {
			this.searchBarHeight = document.getElementById("search")!.getBoundingClientRect().height;
		}
		const anchorElement = document.querySelector(`.stages_description a[name="${fragment.replace(/\"/gi, '\\"')}"]`);
		if (anchorElement) {
			anchorElement.scrollIntoView();
			const scrolledY = window.pageYOffset;
			if (scrolledY) {
				window.scroll(0, scrolledY - this.searchBarHeight);
			}
		} else if (fragment === this.SCROLL_TO_TOP) {
			window.scroll(window.pageXOffset, 0);
		}

		if (this.fragmentTimeout) {
			window.clearTimeout(this.fragmentTimeout);
		}
		this.fragmentTimeout = window.setTimeout(() => {
			onScrollDoneCallback();
		}, 100);
	}

	/**
	 * Descriptions need to register if they want the browser location to be updated with the current fragment after scroll.
	 */
	register(): void {
		this.registered++;
		if (this.registered === 1) {
			this.deactivated = 0;
			this.zone.runOutsideAngular(() => {
				window.addEventListener("scroll", this.eventScrollListener);
			});
		}
	}

	/**
	 * If all registered descriptions have been unregistered, the scroll event listener will be removed.
	 */
	unregister(): void {
		if (this.registered === 0) {
			return;
		}
		this.registered--;
		if (this.registered === 0) {
			this.deactivated = 0;
			window.removeEventListener("scroll", this.eventScrollListener);
		}
	}

	/**
	 * If a description has deactivated the scroll service, it has to reactivate it afterwards.
	 */
	reactivate(): void {
		if (this.deactivated === 0) {
			return;
		}
		this.deactivated--;
	}

	/**
	 * A description has to deactivate the scroll service, when it is being edited.
	 */
	deactivate(): void {
		this.deactivated++;
	}
}
