import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
	selector: "[stagesReserved]",
	providers: [{ provide: NG_VALIDATORS, useExisting: ReservedValidatorDirective, multi: true }],
})
export class ReservedValidatorDirective implements Validator {
	@Input("stagesReserved") reservedWords!: string[];

	validate(control: AbstractControl): ValidationErrors | null {
		return ReservedValidatorDirective.reserved(this.reservedWords)(control);
	}

	static reserved(reservedWords: string[]): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value || typeof control.value !== "string") {
				return null;
			}

			const value = control.value.trim().toLowerCase();
			return reservedWords.indexOf(value) === -1
				? null
				: {
						reserved: {
							valid: false,
						},
				  };
		};
	}
}
