import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Sequence } from "process/element/sequence.interface";

@Injectable({ providedIn: "root" })
export class SequenceResolver implements Resolve<Sequence> {
	/* eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".*/
	async resolve(route: ActivatedRouteSnapshot): Promise<Sequence> {
		const sequence: Sequence = {
			id: route.paramMap.get("id")!,
			level: route.paramMap.get("level")!,
			targetType: route.paramMap.get("targetType")!,
			targetSubtype: route.paramMap.get("targetSubtype")!,
			associationType: route.paramMap.get("associationType")!,
			msgKey: route.paramMap.get("msgKey")!,
			sourceRole: route.paramMap.get("sourceRole")!,
		};
		return sequence;
	}
}
