<header class="dialog-header">
	<div class="title" translate="navigation.workspace.select"></div>
	<button
		[attr.title]="'close' | translate"
		[attr.aria-label]="'close' | translate"
		class="menu-item action"
		(click)="dialogClosed()"
	>
		<i class="ico ico-close"></i>
	</button>
</header>
<stages-workspace-selection
	(selectWorkspace)="onSelectWorkspace($event)"
	[bookmarkable]="true"
	[filterBy]="filter.filterBy"
	[filterParam1]="filter.filterParam1"
	[filterParam2]="filter.filterParam2"
></stages-workspace-selection>
