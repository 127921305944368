import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";
import { DialogService } from "common/dialog/dialog.service";
import { TableZoomComponent } from "common/editor/table-zoom.component";
import { UtilService } from "common/util.service";
import { DescriptionViewComponent } from "common/editor/description-view.component";

type HandleResizeForTableFn = (
	renderer: Renderer2,
	utilService: UtilService,
	tableReplacement: HTMLElement,
	descriptionElement: HTMLElement,
	index: number,
	table: JQuery,
) => void;

@Directive({
	selector: "[stagesReplaceBigTables]",
})
export class ReplaceBigTablesDirective implements OnChanges {
	handleResizeForTableFn: HandleResizeForTableFn;

	@Input()
	html!: string;

	constructor(
		private elem: ElementRef,
		private renderer: Renderer2,
		private dialogService: DialogService,
		private utilService: UtilService,
		private descriptionViewComponent: DescriptionViewComponent,
	) {
		this.handleResizeForTableFn = this.handleResizeForTable;
	}

	ngOnChanges(): void {
		this.descriptionViewComponent.registerResizeHandler("replaceBigTables", () => {
			this.handleTables(this.handleResizeForTableFn);
		});
	}

	handleResizeForTable(
		renderer: Renderer2,
		utilService: UtilService,
		tableReplacement: HTMLElement,
		descriptionElement: HTMLElement,
		index: number,
		table: JQuery,
	): void {
		if (table.parents(".stages_description table").length) {
			return;
		}
		const elementId = `replaced${index}`;
		const tableWidth = utilService.browserIsIE() ? table.width()! : table.outerWidth()!;
		const tableParentWidth = $(descriptionElement).width();
		if (!table.hasClass("replaced") && tableWidth && tableParentWidth && tableWidth > tableParentWidth) {
			table.addClass("replaced");
			table.css("display", "none");
			table.attr("id", elementId);
			renderer.insertBefore(table.parent().get(0), tableReplacement, table.get(0));
		} else if (table.hasClass("replaced")) {
			const placeholderTable = $(`table#${table.attr("id")}-placeholder`);
			const placeholderTableDataWidth = placeholderTable.attr("data-width");
			if (placeholderTableDataWidth && tableParentWidth && parseInt(placeholderTableDataWidth, 10) < tableParentWidth) {
				table.removeClass("replaced");
				table.css("display", "");
				placeholderTable.remove();
			}
		}
	}

	private handleTables(handlerFn: HandleResizeForTableFn): void {
		$(this.elem.nativeElement)
			.find("table:not([stagesDescriptionTableZoom])")
			.each((index, table) => {
				setTableLayout($(table));
				const tableWidth = $(table).outerWidth();
				handlerFn(
					this.renderer,
					this.utilService,
					this.getTableReplacement(`replaced${index}`, tableWidth ? tableWidth : 0),
					this.elem.nativeElement,
					index,
					$(table),
				);
			});
	}

	private getTableReplacement(elementId: string, tableWidth: number): HTMLElement {
		const table = this.renderer.createElement("table");
		table.setAttribute("id", elementId + "-placeholder");
		table.setAttribute("class", "placeholder");
		table.setAttribute("data-width", String(tableWidth));
		table.setAttribute("stagesDescriptionTableZoom", elementId);
		this.renderer.listen(table, "click", () => {
			this.openTableZoom(elementId);
		});
		for (let i = 1; i < 3; i++) {
			const tr = this.renderer.createElement("tr");

			const td1 = this.renderer.createElement("td");
			if (i === 1) {
				const span = this.renderer.createElement("span");
				span.setAttribute("class", "placeholder-icon ico ico-search");
				td1.appendChild(span);
			} else {
				const span1 = this.renderer.createElement("span");
				td1.appendChild(span1);
			}

			const td2 = this.renderer.createElement("td");
			const span2 = this.renderer.createElement("span");
			td2.appendChild(span2);
			const td3 = this.renderer.createElement("td");
			const span3 = this.renderer.createElement("span");
			td3.appendChild(span3);

			tr.appendChild(td1);
			tr.appendChild(td2);
			tr.appendChild(td3);

			table.appendChild(tr);
		}
		return table;
	}

	private openTableZoom(elementId: string): void {
		this.dialogService.open("table-dialog-" + elementId, {
			component: TableZoomComponent,
			componentArgs: {
				tableZoomId: elementId,
			},
			defaultResult: {
				value: "cancel",
			},
		});
	}
}

// WP #34704: Setting table-layout:fixed on tables with images makes these images scale down properly in IE 11
// See also: https://stackoverflow.com/questions/9147255/why-doesnt-ie-respect-table-width-with-fluid-image-child
// We cannot set table-layout:fixed on all tables because tables with a lot of text, but no fixed widths wouldn't behave
// properly (they would be replaced by a zoom placeholder)
function setTableLayout(table: JQuery): void {
	if (table.find("img").length > 0) {
		table.css("table-layout", "fixed");
	}
}
