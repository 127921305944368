import { ComponentRef, Directive, OnDestroy, ViewContainerRef } from "@angular/core";
import { SlotService } from "common/slot.service";
import { ComponentService } from "process/view/component.service";
import { ComponentType } from "process/view/type.interface";

@Directive()
export abstract class BaseComponent implements OnDestroy {
	type?: ComponentType;

	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private componentRefs: ComponentRef<{}>[] = [];
	private slots: { slotName: string; viewContainerRef: ViewContainerRef }[] = [];

	constructor(protected componentService: ComponentService, private slotService?: SlotService) {
		if (this.slotService) {
			this.slotService.onSlotCreated((slotName: string, viewContainerRef: ViewContainerRef) => {
				if (this.type) {
					this.onSlotCreated(slotName, viewContainerRef, this.type.components);
				}
				this.slots.push({ slotName: slotName, viewContainerRef: viewContainerRef });
			});
		}
	}

	protected onSlotCreated(
		slotName: string,
		viewContainerRef: ViewContainerRef,
		childComponents?: ComponentType[],
	): void {
		if (!childComponents) {
			return;
		}

		const matchingChildComponentsBySlot = childComponents.filter(
			(component) => component.slot === slotName || component.slot === undefined,
		);
		matchingChildComponentsBySlot.forEach((component) => {
			this.componentRefs.push(this.componentService.appendComponent(component, viewContainerRef));
		});
	}

	protected updateSlots(type: ComponentType): void {
		this.clearSlots();
		this.type = type;
		this.slots.forEach((slot) => this.onSlotCreated(slot.slotName, slot.viewContainerRef, type.components));
	}

	protected clearSlots(): void {
		this.componentRefs.forEach((componentRef) => {
			componentRef.destroy();
		});
	}

	ngOnDestroy(): void {
		this.clearSlots();
	}
}
