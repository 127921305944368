import { Injectable } from "@angular/core";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { ComplianceResource, SearchQueryService } from "core/stages-client";

type TypedId = stages.core.TypedId;
type TypedIdAssociationDetails = stages.core.model.TypedIdAssociationDetails;
type ViewableAssociation = stages.process.ViewableAssociation;
type ViewableElement = stages.process.ViewableElement;

@Injectable({ providedIn: "root" })
export class ProcessComplianceAssociationService {
	constructor(
		private readonly complianceResource: ComplianceResource,
		private readonly searchQueryService: SearchQueryService,
	) {}

	async searchProcessElements(
		query: string,
		alreadyAssociatedElements: TypedId[],
		referenceModelWorkspaceId: string,
		pv: string,
	): Promise<AssocSearchResult[]> {
		this.searchQueryService.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.searchQueryService.findComplianceAssociationTargetsInReferenceModel(alreadyAssociatedElements, {
			query: query,
			referenceModelWorkspaceId: referenceModelWorkspaceId,
		}) as Promise<AssocSearchResult[]>;
	}

	async createAssociation(
		sourceElementId: string,
		sourceElementType: string,
		targetElementId: string,
		targetElementType: string,
	): Promise<ViewableAssociation> {
		return this.complianceResource.createAssociationFromProcess(
			sourceElementType,
			sourceElementId,
			targetElementType,
			targetElementId,
		) as unknown as Promise<ViewableAssociation>;
	}

	async deleteAssociations(associationIds: string[], workspaceId: string, pv: string): Promise<void> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.complianceResource.deleteAssociations(workspaceId, {
			associationIds: associationIds,
		});
	}

	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.complianceResource.updateAssociationComment(currentWorkspaceId, associationId, comment);
	}

	async applyAssociationModifications(
		processElementType: string,
		processElementId: string,
		groupPath: string,
		added: TypedId[],
		removed: TypedId[],
		modifiedComments: TypedIdAssociationDetails[],
		processModelWorkspaceId: string,
		referenceModelWorkspaceId: string,
	): Promise<ViewableElement> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			swid: referenceModelWorkspaceId,
			spv: "_wv",
		});
		return this.complianceResource.updateAssociationsFromProcess(
			processModelWorkspaceId,
			processElementType,
			processElementId,
			{
				addedElements: added,
				removedElements: removed,
				updatedElements: modifiedComments,
			},
		) as Promise<ViewableElement>;
	}
}
