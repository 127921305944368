import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { Subscription } from "rxjs";

type ExecutionResult = stages.management.enactment.ExecutionResult;

@Component({
	selector: "stages-enactment-activator-execution-details",
	templateUrl: "enactment-activator-execution-details.component.html",
	styleUrls: ["./enactment-activator-execution-details.component.scss"],
})
export class EnactmentActivatorExecutionDetailsComponent implements OnInit, OnDestroy {
	@Input()
	keys!: {
		executionResult: ExecutionResult;
	};

	private routerEventsSubscription?: Subscription;

	constructor(private dialog: DialogAdapter, private router: Router) {}

	ngOnInit(): void {
		this.routerEventsSubscription = this.router.events.subscribe((s) => {
			if (s instanceof NavigationEnd) {
				this.cancel();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.routerEventsSubscription) {
			this.routerEventsSubscription.unsubscribe();
		}
	}

	getStatusMessageKey(): string {
		return "process.enactment.activator.execution.status." + this.keys.executionResult.status;
	}

	cancel(): void {
		this.dialog.close("cancel");
	}
}
