import { Component } from "@angular/core";
import { IAnswer } from "process/tailoring/models/answer.interface";
import { IAnsweredQuery } from "process/tailoring/models/answered-query.interface";
import { IQuery } from "process/tailoring/models/query.interface";
import { TailoringWizardStore } from "process/tailoring/store";
import { TailoringWizardLogicService } from "process/tailoring/wizard-logic.service";
import { Observable } from "rxjs";

@Component({
	selector: "stages-process-tailoring-wizard-answered-queries",
	templateUrl: "./answered-queries.component.html",
})
export class TailoringWizardAnsweredQueriesComponent {
	answeredQueries$: Observable<IAnsweredQuery[]>;

	constructor(private store: TailoringWizardStore, private wizardLogic: TailoringWizardLogicService) {
		this.answeredQueries$ = this.store.select<IAnsweredQuery[]>("answeredQueries");
	}

	getAnswer(answerId: string): IAnswer | undefined {
		return this.wizardLogic.getAnswerByAnswerId(answerId);
	}

	getQuery(queryId: string): IQuery | undefined {
		return this.wizardLogic.getQueryById(queryId);
	}
}
