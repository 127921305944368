import { animate, AnimationEvent, style, transition, trigger } from "@angular/animations";
import { Component, ComponentRef, HostListener } from "@angular/core";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { ScrollService } from "common/routing/scroll.service";
import { UtilService } from "common/util.service";

export interface DialogConfig {
	size: "large" | "small" | "medium";
	autoHeight?: boolean;
}

@Component({
	selector: "stages-dialog",
	templateUrl: "./dialog.component.html",
	animations: [
		trigger("fadeInOut", [
			transition(":enter", [
				style({ opacity: 0, transform: "scale(0)" }),
				animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)", style({ opacity: 1, transform: "scale(1)" })),
			]),
			transition(":leave", [
				style({ opacity: 1, transform: "scale(1)" }),
				animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)", style({ opacity: 0, transform: "scale(0)" })),
			]),
		]),
	],
})
export class DialogComponent<T> {
	isOpen = false;

	defaultResult!: T;

	contentElement!: HTMLElement;
	contentComponent!: ComponentRef<unknown>;
	componentRef!: ComponentRef<DialogComponent<T>>;

	constructor(
		private dialogAdapter: DialogAdapter,
		private utilService: UtilService,
		private scrollService: ScrollService,
	) {}

	@HostListener("document:keydown", ["$event"])
	onKeyDown(event: KeyboardEvent): void {
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		if (event.which === 27 /* escape */) {
			(event.target as HTMLElement).blur();
			this.close();
		}
	}

	close(event?: Event): void {
		if (event && event.stopPropagation) {
			event.stopPropagation();
			event.preventDefault();
		}
		this.scrollService.enableWindowScroll();
		if (this.isOpen) {
			this.dialogAdapter.close(this.defaultResult);
		}
	}

	async show(
		componentRef: ComponentRef<DialogComponent<T>>,
		contentComponent: ComponentRef<unknown>,
		defaultResult: T,
		dialogConfig?: DialogConfig,
	): Promise<T> {
		this.defaultResult = defaultResult;
		this.isOpen = true;
		this.scrollService.disableWindowScroll();
		this.componentRef = componentRef;
		this.contentComponent = contentComponent;
		this.contentElement = contentComponent.location.nativeElement as HTMLElement;
		this.contentElement.addEventListener("click", this.dialogContentClicked.bind(this));
		this.contentElement.classList.add("dialog");
		this.contentElement.setAttribute("role", "dialog");
		if (dialogConfig) {
			switch (dialogConfig.size) {
				case "large":
					this.contentElement.classList.add("lg");
					break;
				case "medium":
					this.contentElement.classList.add("md");
					break;
				default:
					this.contentElement.classList.add("sm");
					break;
			}
			if (dialogConfig.autoHeight) {
				this.contentElement.classList.add("autoHeight");
			}
		}
		return new Promise<T>((resolve) => {
			this.dialogAdapter.close = (result: T) => {
				this.isOpen = false;

				this.dialogAdapter.result = result || this.defaultResult;
				resolve(result || this.defaultResult);
			};
		});
	}

	fadeInOutDone($event: AnimationEvent): void {
		if ($event.toState === "void") {
			//needed for leave animation to finish before destroying
			this.contentComponent.destroy();
			this.componentRef.destroy();
		}

		if (!this.utilService.deviceIsIOS() && this.contentElement) {
			this.contentElement.focus();
		}
	}

	dialogContentClicked($event: Event): void {
		if ($event.stopPropagation) {
			$event.stopPropagation();
		}
	}
}
