/* eslint-disable @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".*/
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { getStateMessageKey } from "core/functions";

@Injectable({ providedIn: "root" })
export class PropertyService {
	constructor(private $translate: TranslateService) {}

	getValue(
		currentElement: any,
		property: any,
		useDisplayValue: boolean,
	): stages.core.format.DisplayDescription | string | null | undefined {
		if (property.isAttribute) {
			if (currentElement.attribute) {
				return this.getAttributeValue(currentElement.attribute[property.ident], useDisplayValue);
			} else {
				return null;
			}
		} else if (property.isLinkable) {
			if (currentElement[property.ident]) {
				if (property.type === "module") {
					const modulePath = getModulePath(currentElement.moduleInstallation);
					const label: string = currentElement[property.ident].label;
					if (modulePath) {
						return modulePath + label;
					}
				}
				return currentElement[property.ident].label;
			}
		} else if (property.type === "module") {
			return this.getModuleValue(currentElement, property, useDisplayValue);
		} else if (typeof currentElement[property.ident] === "boolean") {
			const key = currentElement[property.ident] ? "yes" : "no";
			return this.$translate.instant(key);
		} else {
			return this.getElementPropertyValue(currentElement, property, useDisplayValue);
		}
		return null;
	}

	private getElementPropertyValue(element: any, property: any, useDisplayValue: boolean): any {
		const propertyByIdent: any = element[property.ident];
		if (propertyByIdent) {
			if (propertyByIdent.fullname) {
				return propertyByIdent.fullname;
			} else if (propertyByIdent.html && useDisplayValue) {
				return propertyByIdent.html;
			} else if (property.ident === "elementState") {
				return this.$translate.instant(getStateMessageKey(propertyByIdent as string));
			} else {
				return propertyByIdent;
			}
		}
		return undefined;
	}

	private getAttributeValue(attribute: any, useDisplayValue: boolean): any {
		if (!attribute) {
			return null;
		}

		const value = attribute.changeMarker
			? attribute.changeMarker.displayValue
			: useDisplayValue
			? attribute.displayValue
			: attribute.value;
		if (Array.isArray(value)) {
			if (value.length === 0) {
				return null;
			}
			return value.join(", ");
		}
		if (attribute.type.type === "BOOLEAN") {
			if (useDisplayValue) {
				if (attribute.changeMarker) {
					return attribute.changeMarker.displayValue;
				}

				if (value === undefined || value === null) {
					return null;
				}

				return this.$translate.instant(value ? "yes" : "no");
			} else if (value) {
				return value;
			}
			return attribute.value === null ? null : attribute.value;
		}
		if (useDisplayValue && value !== undefined && value != null && value.hasOwnProperty("html")) {
			return value.html;
		}
		return value;
	}

	private getModuleValue(currentElement: any, property: any, useDisplayValue: boolean): any {
		if (!currentElement.moduleInstallation) {
			return null;
		}

		return this.getElementPropertyValue(currentElement.moduleInstallation, property, useDisplayValue);
	}
}

function getModulePath(module: any): string | null {
	const path: string = module.modulePath.replace(/\|/g, " > ");
	if (path.length > 0) {
		return path + " > ";
	}
	return null;
}
