<ng-template #listItemTemplate let-item>
	<a class="label clickable" (click)="navigateAndClose(item)">
		<i class="ico ico-workspace"></i>
		<div class="text" [style.text-decoration]="item.targetElement.tailored ? 'line-through' : 'none'">
			<h4>
				{{ item.targetElement.workspace.name }}
				<i
					[ngClass]="getProcessInterfaceIconClasses(item)"
					*ngIf="item.isProcessInterface"
					title="{{ 'process.element.associationList.processInterfaceIconTitle' | translate }} "
				></i>
			</h4>
			<p>{{ item.targetElement.workspace.path }}</p>
		</div>
	</a>
</ng-template>

<ng-template #listHeaderTemplate let-data>
	<h3
		class="assoc-list-header"
		[translate]="'process.element.associationCombined.workspacesContaining'"
		[translateParams]="{ name: targetElementLabel }"
	></h3>
</ng-template>

<stages-dialog2 #dialog class="autoHeight">
	<stages-dialog-header></stages-dialog-header>
	<ng-container *ngIf="assocType | async as assoctype">
		<h1
			*ngIf="dataView.mode === 'DELETE'"
			[translate]="'process.element.associationCombined.delete'"
			[translateParams]="{ name: targetElementLabel }"
		></h1>
		<h1
			*ngIf="dataView.mode !== 'DELETE'"
			[translate]="'process.element.associationCombined.fromMultipleWorkspaces'"
			[translateParams]="{ type: (assoctype | translate) }"
		></h1>
	</ng-container>
	<div class="scrollable-content">
		<stages-responsive-data-view
			#dataView
			[dataSource]="dataSource"
			[listItemTemplate]="listItemTemplate"
			[listHeaderTemplate]="listHeaderTemplate"
			translateNone="none"
			[idFn]="getAssocId"
			[disabledFn]="isDisabled"
			[pageSize]="pageSize"
		>
		</stages-responsive-data-view>
		<div class="buttons-bottom">
			<div class="spacer"></div>
			<button
				type="button"
				class="button sm cancel"
				name="cancel"
				tabindex="100"
				(click)="close()"
				translate="cancel"
			></button>
			<button
				type="submit"
				class="button sm delete"
				translate="remove"
				(click)="delete()"
				tabindex="101"
				[disabled]="dataView.selection.selected.length === 0"
				*ngIf="dataView.mode === 'DELETE'"
			></button>
		</div>
	</div>
</stages-dialog2>
