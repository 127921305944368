<ng-container *ngIf="paramMap$ | async as paramMap">
	<stages-dialog2 #dialog>
		<stages-dialog-header></stages-dialog-header>

		<ng-container *ngIf="credentialsValid; else authenticate">
			<ng-container *ngIf="fileProperties$ | async as fileProperties; else loadingOrError">
				<h1 [translate]="getHeaderMessageKey(fileProperties)" [translateParams]="{ name: fileProperties.name }"></h1>
				<form [formGroup]="formModel" class="content file-add-dialog" name="group" #formElement>
					<stages-upload [uploadCompletedCallback]="getUploadCompletedCallback(fileProperties)" [uploadUrl]="uploadUrl">
					</stages-upload>

					<div *ngIf="errorMessage" class="error hint">
						<span>{{ errorMessage }}</span>
					</div>

					<article class="new-versions" *ngIf="filesToCreateOrUpdate.length">
						<ul class="list dense" formArrayName="displayName">
							<li
								class="list-item"
								*ngFor="let displayNameControl of $any(formModel.get('displayName'))?.controls; let i = index"
							>
								<div class="label">
									<i class="ico ico-file"></i>
									<div class="text">
										<input type="hidden" [formControlName]="i" />
										<h4>{{ displayNameControl.value }}</h4>
										<p
											[translate]="getNewVersionMessageKey(fileProperties)"
											[translateParams]="{ name: getUpdateFilename(i) }"
										></p>
										<div class="errors" *ngIf="displayNameControl.errors">
											<div
												*ngIf="displayNameControl.errors.filetype"
												class="error"
												translate="input.typeMismatch"
												[translateParams]="{ filetype: fileProperties.fileType }"
											></div>
											<div
												*ngIf="displayNameControl.errors.alreadySelected"
												class="error"
												translate="input.alreadySelected"
											></div>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</article>

					<article class="form properties">
						<stages-attribute-edit
							[attributeTypes]="fileProperties.attributeTypes"
							[attributes]="fileProperties.attributes"
							[form]="formModel"
						>
						</stages-attribute-edit>
					</article>
				</form>
				<footer>
					<div class="buttons">
						<button class="button sm cancel" translate="cancel" (click)="dialog.close()"></button>
						<button class="button sm save" [disabled]="saveButtonDisabled()" (click)="saveClicked(fileProperties)">
							<span *ngIf="!saveInProgress" translate="files.start.upload.button"></span>
							<stages-spin *ngIf="saveInProgress" size="s"></stages-spin>
						</button>
					</div>
				</footer>
			</ng-container>
		</ng-container>
	</stages-dialog2>
</ng-container>

<ng-template #loadingOrError>
	<h1 *ngIf="loadingError$ | async as error" translate="error.title"></h1>
	<stages-popup-content>
		<stages-loading-error-embeddable
			[credentialsValid]="credentialsValid"
			[loadingError$]="loadingError$"
		></stages-loading-error-embeddable>
	</stages-popup-content>
	<stages-popup-footer [hasCancelButton]="false" [buttons]="buttons"> </stages-popup-footer>
</ng-template>

<ng-template #authenticate>
	<stages-files-auth-embeddable
		#auth
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		*ngIf="!credentialsValid && !oAuthUrl"
	></stages-files-auth-embeddable>
	<stages-files-oauth-embeddable-confirm
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		[authUrl]="oAuthUrl"
		[closeCallback]="close"
		*ngIf="!credentialsValid && oAuthUrl"
	></stages-files-oauth-embeddable-confirm>
</ng-template>
