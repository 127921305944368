import { Component, OnDestroy } from "@angular/core";
import { BroadcastArgs, BroadcastService } from "core/broadcast.service";
import { Events } from "core/events";
import { SnackbarService } from "core/snackbar.service";
import { isEqual } from "lodash";
import { Subscription } from "rxjs";

const MESSAGE_DISPLAY_TIME = 4000;

const DELAY_BETWEEN_MESSAGES = 500;

@Component({
	selector: "stages-snackbar",
	templateUrl: "./snackbar.component.html",
})
export class SnackbarComponent implements OnDestroy {
	isVisible = false;

	subscription: Subscription;

	message?: BroadcastArgs;

	timerId?: number;

	constructor(broadcastService: BroadcastService, private snackbarService: SnackbarService) {
		this.subscription = broadcastService.on(Events.MESSAGE).subscribe((data) => {
			if (data) {
				// if an "equal" message is currently displayed, do not add new message, but re-start the timeout
				if (this.timerId && isEqual(data, this.message)) {
					window.clearTimeout(this.timerId);
					this.timerId = window.setTimeout(() => {
						this.hideSnackbar();
					}, MESSAGE_DISPLAY_TIME);
				} else {
					this.snackbarService.pushMessage(data);
					this.processQueue();
				}
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.timerId) {
			window.clearTimeout(this.timerId);
		}
		this.isVisible = false;
		this.message = undefined;
	}

	processQueue(): void {
		if (!this.message) {
			this.message = this.snackbarService.peekMessage();
			this.showSnackbar();
		}
	}

	showSnackbar(): void {
		if (this.message) {
			this.isVisible = true;
			this.timerId = window.setTimeout(() => {
				this.hideSnackbar();
			}, MESSAGE_DISPLAY_TIME);
		}
	}

	hideSnackbar(): void {
		if (!this.message) {
			return;
		}

		if (this.timerId) {
			window.clearTimeout(this.timerId);
		}

		this.isVisible = false;
		this.snackbarService.shiftMessage();

		window.setTimeout(() => {
			this.message = undefined;
			this.processQueue();
		}, DELAY_BETWEEN_MESSAGES);
	}

	close(): void {
		this.hideSnackbar();
	}
}
