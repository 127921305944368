import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { NotificationService } from "core/notification.service";
import { ProgressService } from "core/progress.service";
import { TranslateChoiceService } from "core/translate/translate-choice.service";

@Component({
	selector: "stages-common-progress",
	templateUrl: "./progress.component.html",
	styleUrls: ["progress.component.scss"],
})
export class CommonProgressComponent implements OnInit, OnDestroy {
	@Input()
	set disableNextUntilDone(disableNextUntilDone: boolean) {
		this.nextDisabled = disableNextUntilDone;
	}

	@Input()
	operationName!: string;

	@Input()
	jobName!: string;

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	@Input()
	messageKeyPrefix?: string;

	@Input()
	download?: string | null;

	@Input()
	resumable?: boolean = true;

	@Output() readonly close = new EventEmitter<void>();

	@Output() readonly next = new EventEmitter<void>();

	@Output() readonly finished = new EventEmitter<stages.common.ProgressInfo>();

	finalState!: string;
	progress!: number;
	isWaiting?: boolean;
	remainingTimeMillis!: number;
	error?: string | null;
	incidentId?: string | null;
	warnings!: string[];
	warningsMessageKey!: string;
	nextDisabled: boolean = false;

	private pollingHandle?: number;

	constructor(
		private notificationService: NotificationService,
		private progressService: ProgressService,
		private translateChoiceService: TranslateChoiceService,
	) {}

	ngOnInit(): void {
		this.getProgress(true).then((progress) => {
			this.onProgressUpdate(progress);
		});

		this.pollingHandle = this.notificationService.pollPromise(
			async () => this.getProgress(true),
			(progress: stages.common.ProgressInfo) => {
				this.onProgressUpdate(progress);
				if (this.finalState) {
					this.notificationService.cancel(this.pollingHandle!);
				}
			},
		);
	}

	ngOnDestroy(): void {
		if (this.pollingHandle) {
			this.notificationService.cancel(this.pollingHandle);
			this.pollingHandle = undefined;
		}
	}

	hasWarnings(): boolean {
		return this.finalState === "warnings";
	}

	async getProgress(isPolling: boolean): Promise<stages.common.ProgressInfo> {
		return this.progressService.getJobProgress(this.operationName, this.workspaceId, this.pv, this.jobName, isPolling);
	}

	onProgressUpdate(progress: stages.common.ProgressInfo): void {
		this.isWaiting = progress.jobStatus === "WAITING";
		this.progress = progress.value;
		this.remainingTimeMillis = progress.remainingTimeMillis;
		switch (progress.jobStatus) {
			case "COMPLETED_SUCCESSFUL":
				this.finalState = "success";
				this.nextDisabled = false;
				this.finished.emit(progress);
				break;

			case "COMPLETED_FAILED":
				this.finalState = "failure";
				this.error = progress.errorMessage;
				this.incidentId = progress.incidentId;
				this.finished.emit(progress);
				break;

			case "COMPLETED_WITH_WARNINGS":
				this.finalState = "warnings";
				this.warnings = progress.warnings;
				this.warningsMessageKey = this.translateChoiceService.getKey(
					{ length: this.warnings.length },
					"progress.warnings.count.{length}",
				);
				this.finished.emit(progress);
				break;

			default:
				// nothing to do
				break;
		}
	}
}
