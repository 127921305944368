import { Injectable } from "@angular/core";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogService } from "common/dialog/dialog.service";
import { ProcessElementsResource } from "core/stages-client";

export interface DeletableElement {
	type: stages.process.ViewableType;
	id: string;
}

export interface SingleDeletableElement extends DeletableElement {
	label: string; //confirm dialog
}

@Injectable({ providedIn: "root" })
export class DeleteService {
	constructor(
		private readonly processElementsResource: ProcessElementsResource,
		private readonly dialogService: DialogService,
		private readonly mutexService: MutexService,
	) {}

	async deleteSingleElement(
		workspaceId: string,
		pv: string,
		elementToDelete: SingleDeletableElement,
		afterDelete: () => void,
		onCancel: () => void,
	): Promise<void> {
		if (
			await this.dialogService.confirm(
				"delete.confirm.message",
				{ name: elementToDelete.label },
				"delete",
				"cancel",
				true,
			)
		) {
			this.mutexService.invoke("elementDelete", async () => {
				return this.deleteElements(workspaceId, pv, [elementToDelete]).then(afterDelete);
			});
		} else {
			onCancel();
		}
	}

	async deleteElements(workspaceId: string, pv: string, elements: DeletableElement[]): Promise<void> {
		const elementIds = elements.map((element) => {
			return {
				typeIdent: element.type.ident,
				id: element.id,
			};
		});
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource.delete(workspaceId, elementIds);
	}
}
