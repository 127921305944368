import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-list-item-profile-and-comment-template",
	templateUrl: "./list-item-profile-and-comment-template.component.html",
	styleUrls: ["list-item-profile-and-comment-template.component.scss"],
})
export class ListItemProfileAndCommentTemplateComponent {
	@Input()
	userId?: string;

	@Input()
	userProfileVersion?: number | string;

	@Input()
	userName?: string;

	@Input()
	userNameSubdetail?: string;

	@Input()
	userEmail?: string;

	@Input()
	date?: string;

	@Input()
	comment?: string;

	@Input()
	secondary?: string;

	@Input()
	subDetail?: string;

	@Input()
	subDetailColor?: string;

	@Input()
	subDetail2?: string;

	@Input()
	subDetailColor2?: string;

	@Input()
	iconClass?: string;

	@Input()
	useColonDelimiter?: boolean;

	getMailtoLink(): string {
		return `mailto:${this.userEmail}`;
	}
}
