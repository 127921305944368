import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { ProcessDescriptionService } from "process/description/process-description.service";
import { Observable } from "rxjs";
import { DescriptionComponent } from "common/editor/description.component";

type ViewedProcess = stages.workspace.ViewedProcess;
type ProcessView = stages.process.ProcessView;
type ViewableElement = stages.process.ViewableElement;

@Component({
	selector: "stages-process-description",
	templateUrl: "./process-description.component.html",
	styles: [":host { display: block; }"],
})
export class ProcessDescriptionComponent {
	workspaceView$: Observable<stages.workspace.application.WorkspaceView>;
	self$?: Observable<stages.process.ProcessView>;

	constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private viewService: ViewService,
		public descriptionService: ProcessDescriptionService,
	) {
		this.workspaceView$ = this.mainService.workspaceView$;
		this.self$ = this.viewService.awaitSelfElementObservable();
	}

	async afterSave(descriptionComponent: DescriptionComponent): Promise<void> {
		await this.viewService.updateDisplayDescription(descriptionComponent.displayDescription);
	}

	currentProcessVersionIdentifier(viewedProcess: ViewedProcess | null): string | undefined {
		if (viewedProcess) {
			const requestedPV = this.route.snapshot.paramMap.get("processVersion");
			if (requestedPV === "_wv" && viewedProcess.workingVersion) {
				return "_wv";
			}

			return viewedProcess.versionIdentifier;
		}

		return undefined;
	}

	getDescription(self: ProcessView & ViewableElement): unknown {
		if (self.changeMarker && self.changeMarker.description && !self.changeMarker.added && !self.changeMarker.deleted) {
			return self.changeMarker.description;
		}

		return self.description;
	}
}
