import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	NgZone,
	OnDestroy,
	OnInit,
	TrackByFunction,
} from "@angular/core";
import {
	ActivatedRoute,
	Event,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router,
	RouterEvent,
} from "@angular/router";
import { ListService } from "common/list/list.service";
import { MultiLevelSelectionService } from "common/multi-level-selection.service";
import { trackByIndexFunction } from "core/functions";
import { MainService } from "core/main.service";
import { LanguageService } from "core/translate/language.service";
import { ApplicationState } from "core/volatile-application-state.model";
import {
	ActionStateWrapper,
	BaseNavigationComponent,
	isFollowUpActionMenuItem,
	isRearrangeUIAction,
} from "navigation/list/base/base-navigation.component";
import { NavigationConfiguration } from "navigation/list/navigation-configuration.interface";
import { ListNavigationEntry } from "navigation/list/navigation-entry.interface";
import { FollowUpAction, MenuUIAction, NavigationMenuItem, NavMode } from "navigation/list/navigation.interface";
import { NavigationService } from "navigation/list/navigation.service";
import { Observable, of, Subject, Subscription, timer } from "rxjs";
import { filter, map, switchMap, take, takeUntil } from "rxjs/operators";
import { NavigationRearrangeListService } from "navigation/list/navigation-rearrange-list.service";

@Component({
	selector: "stages-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends BaseNavigationComponent implements AfterViewChecked, OnInit, OnDestroy {
	applicationState: ApplicationState;

	navigationConfiguration$!: Observable<NavigationConfiguration>;
	workspaceView$!: Observable<stages.workspace.application.WorkspaceView>;
	newNavigationConfiguration?: NavigationConfiguration;
	focusedEntry?: ListNavigationEntry;

	showAddButton = false;

	trackByIndex = trackByIndexFunction;

	rearrangeOpts: JQueryUI.SortableOptions = {
		axis: "y",
		handle: ".draggableAreaForSort",
		disabled: false,
		// typescript errors are ignored for the following statement,
		// since our placeholder option is non-conformant to the jquery-ui api
		// @ts-expect-error
		placeholder: {
			element: (currentItem: JQuery) => {
				return $('<li class="list-item ghost"><div class="label"></div></li>')[0];
			},
			update: () => {},
		},
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		start: (event: JQueryEventObject, ui: JQueryUI.SortableUIParams) => {
			ui.item.data("startIndex", ui.item.index());
		},
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		update: (event: JQueryEventObject, ui: JQueryUI.SortableUIParams) => {
			this.zone.run(() => {
				this.navigationRearrangeListService.moveNavigationEntry(ui.item.data("startIndex"), ui.item.index());
				this.rearrangeOrder = "custom";
				this.cd.detectChanges();
			});
		},
		animation: 300,
	};

	alphabeticalOrderToggle?: boolean;

	lastAction!: string;
	lastFolderId?: string;

	singleLine = "single-line";
	twoLine = "two-line";
	twoLineSplitRating = "two-line-split-rating";
	changeMarker = "change-marker";

	private destroy$ = new Subject<boolean>();
	private cancelProgressIndicatorTimer$ = new Subject<boolean>();
	private navigationContentChange$?: Subscription;
	private editInProgress: boolean = false;
	private isMobileView: boolean = false;

	constructor(
		route: ActivatedRoute,
		router: Router,
		selectionService: MultiLevelSelectionService<ListNavigationEntry>,
		private languageService: LanguageService,
		private navigationService: NavigationService,
		private navigationRearrangeListService: NavigationRearrangeListService,
		public mainService: MainService,
		private zone: NgZone,
		private listService: ListService,
		private cd: ChangeDetectorRef,
	) {
		super(route, router, selectionService);
		this.applicationState = mainService.applicationState;
	}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((e: Event): e is RouterEvent => e instanceof RouterEvent),
				takeUntil(this.destroy$),
			)
			.subscribe((e: RouterEvent) => {
				this.handleProgressbar(e);
			});

		this.navigationConfiguration$ = this.navigationService.navigationConfiguration$.pipe(
			map((navigationConfiguration) => {
				this.newNavigationConfiguration = navigationConfiguration;
				const actionParam = this.getActionParam(navigationConfiguration);
				if (actionParam === "rearrange") {
					this.rearrangeOrder = this.determineCurrentSortOrder(navigationConfiguration.list);
					if (this.rearrangeOrder === "alphabetical") {
						// copying array is necessary (see test report of #34549)
						const copiedList = navigationConfiguration.list.slice();
						this.preliminaryAlphabeticalSorting(copiedList);
						navigationConfiguration.list = copiedList;
					}
				}
				if (actionParam !== this.lastAction) {
					this.focusedEntry = undefined;
				}
				this.resetSelectionAfterAction(actionParam, navigationConfiguration);

				return navigationConfiguration;
			}),
		);
		this.workspaceView$ = this.mainService.workspaceView$;

		this.navigationService.forceRefresh$
			.pipe(
				switchMap((forceRefresh) => {
					if (!forceRefresh) {
						return of(undefined);
					}
					return this.navigationService.navigationConfiguration$.pipe(take(1));
				}),
				takeUntil(this.destroy$),
			)
			.subscribe((conf) => {
				if (conf?.activatedRoute && conf.baseRoute) {
					this.navigate(new FollowUpAction(NavMode.SELF).withForcedReload(), conf);
					this.cd.detectChanges();
				}
			});

		this.applicationState.isMobileView.pipe(takeUntil(this.destroy$)).subscribe((isMobileView) => {
			this.isMobileView = isMobileView;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		if (this.navigationContentChange$) {
			this.navigationContentChange$.unsubscribe();
		}
	}

	ngAfterViewChecked(): void {
		if (this.newNavigationConfiguration) {
			const actionParam = this.getActionParam(this.newNavigationConfiguration);
			if (actionParam === "view") {
				if (this.focusedEntry) {
					scrollToEntry(this.focusedEntry, this.newNavigationConfiguration.folder);
				} else {
					this.scrollTo(this.newNavigationConfiguration);
				}
			}
			if (actionParam === "rearrange") {
				this.navigationRearrangeListService.rearrangeList = this.newNavigationConfiguration.list.slice();
				$(".stages-sortable").sortable(this.rearrangeOpts);
				this.cd.detectChanges();
			} else {
				// jquery-ui might have added the ui-sortable class
				$(".ui-sortable").sortable("destroy");
			}
			this.newNavigationConfiguration = undefined;
		}
	}

	private resetSelectionAfterAction(actionParam: string, navigationConfiguration: NavigationConfiguration): void {
		if (isViewAction(actionParam)) {
			if (!isViewAction(this.lastAction)) {
				this.resetSelection(navigationConfiguration.selectionIdentifier);
			} else if (
				this.lastFolderId !== undefined &&
				navigationConfiguration.folder !== undefined &&
				navigationConfiguration.folder.id !== this.lastFolderId
			) {
				this.resetSelection(navigationConfiguration.selectionIdentifier);
			}
		}
		this.lastAction = actionParam;
		this.lastFolderId = navigationConfiguration.folder !== undefined ? navigationConfiguration.folder.id : undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32217: Enable strict template type checking and suppress or fix all existing warnings.
	trackByIdAndAlphabeticalOrderToggle: TrackByFunction<any> = (_index, navElement) => {
		if (this.navigationRearrangeListService.rearrangeList.length > 0) {
			return `${this.trackById(_index, navElement)}_${this.alphabeticalOrderToggle}`;
		}
		return this.trackById(_index, navElement);
	};

	closeNavigationIfLeaf(entry: ListNavigationEntry): void {
		if (!entry.hasChildren && this.isMobileView) {
			this.applicationState.toggleNavigationDrawer();
		}
	}

	private triggerProgressIndicatorTimer(): void {
		timer(1000)
			.pipe(takeUntil(this.cancelProgressIndicatorTimer$))
			.subscribe(() => {
				this.mainService.setNavigationInProgress(true);
				this.cd.detectChanges();
			});
	}

	scrollTo(navigationConfiguration: NavigationConfiguration): void {
		const folder = navigationConfiguration.folder;
		const self = navigationConfiguration.self;

		if (!folder || !self) {
			return;
		}

		scrollToEntry(self, folder);
	}

	scrollToNewChild(): void {
		const newChildElement = document.getElementById("new-child");
		if (newChildElement) {
			newChildElement.scrollIntoView();
		}
	}

	onOrderChange(rearrangeOrder: "alphabetical" | "custom", navigationConfiguration: NavigationConfiguration): void {
		this.rearrangeOrder = rearrangeOrder;
		if (this.rearrangeOrder === "alphabetical") {
			navigationConfiguration.list.forEach((entry) => {
				entry.sortKey = 0;
			});
			this.navigationService.update(navigationConfiguration);
			this.alphabeticalOrderToggle = !this.alphabeticalOrderToggle;
		}
	}

	determineCurrentSortOrder(entries: ListNavigationEntry[]): "alphabetical" | "custom" {
		return !entries || entries.length === 0 || entries[0].sortKey === 0 ? "alphabetical" : "custom";
	}

	preliminaryAlphabeticalSorting(entries: ListNavigationEntry[]): void {
		if (localeCompareSupported()) {
			entries.sort((a: ListNavigationEntry, b: ListNavigationEntry) => {
				return getValueForSort(a).localeCompare(getValueForSort(b), this.languageService.getCurrentLanguage());
			});
		} else {
			entries.sort(poorLocaleComparePolyfill);
		}
	}

	wrapMenuItems(
		context: ListNavigationEntry,
		menuItems: NavigationMenuItem[] | undefined,
		navigationConfiguration: NavigationConfiguration,
	): MenuItem[] {
		let convertedMenuItems: MenuItem[] = [];
		if (!menuItems) {
			return convertedMenuItems;
		}
		convertedMenuItems = menuItems.map((item: NavigationMenuItem) => {
			return isFollowUpActionMenuItem(item)
				? {
						name: item.name,
						nameParam: item.nameParam,
						iconClass: Array.isArray(item.iconClass) ? item.iconClass : [item.iconClass], // TODO Vllt Interface anpassen?!
						disabled: item.disabled,
						on: () => {
							let selection = null;
							if (item.storeElement) {
								selection = this.newSelection(navigationConfiguration);
								selection.select(context);
							}
							const action: MenuUIAction = {
								indicateProgress: item.indicateProgress,
								invalidate: item.invalidate,
								async onMenuClick(): Promise<FollowUpAction> {
									return item.onDoFollowUp(context, navigationConfiguration.folder!);
								},
								self: navigationConfiguration.self!,
								folder: navigationConfiguration.folder!,
							};
							this.processFollowUpAction(
								action,
								navigationConfiguration.activatedRoute!,
								navigationConfiguration.baseRoute!,
								selection,
								() => navigationConfiguration.invalidate(),
							);
						},
				  }
				: item;
		});
		return convertedMenuItems;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	hasToolbar(navigationConfiguration: NavigationConfiguration): {} {
		return {
			"has-toolbar":
				navigationConfiguration.homeRoute !== undefined || navigationConfiguration.parentRoute !== undefined,
		};
	}

	isMainMenuEnabled(navigationConfiguration: NavigationConfiguration): boolean {
		const actionName = this.getActionParam(navigationConfiguration);
		const navigationMode = this.getCurrentMode(navigationConfiguration.supportedModes, actionName);
		return navigationMode.isMenuEnabled;
	}

	getCurrentActions(navigationConfiguration: NavigationConfiguration): ActionStateWrapper[] {
		const actionName = this.getActionParam(navigationConfiguration);
		const result: Array<ActionStateWrapper> = [];
		const currentMode = this.getCurrentMode(navigationConfiguration.supportedModes, actionName);
		if (currentMode.uiActions.length > 0) {
			const selection = this.getSelection(navigationConfiguration, currentMode.isSelectEnabled);
			for (const a of currentMode.uiActions) {
				if (isRearrangeUIAction(a)) {
					a.getSortedEntries = () => {
						return this.navigationRearrangeListService.rearrangeList;
					};
				}
				result.push(
					new ActionStateWrapper(
						a,
						this,
						selection,
						navigationConfiguration.activatedRoute!,
						navigationConfiguration.baseRoute!,
						() => navigationConfiguration.invalidate(),
					),
				);
			}
		}
		return result;
	}

	async onEditInput($event: KeyboardEvent, entry: ListNavigationEntry, singleEdit: boolean): Promise<void> {
		const inputElement = $event.target as HTMLInputElement;
		const currentInput = inputElement.value;
		if (
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			$event.keyCode === 13 && // ENTER-Key
			currentInput !== undefined &&
			currentInput.trim() !== ""
		) {
			if (entry.editDone) {
				if (!this.editInProgress) {
					this.mainService.setNavigationInProgress(true);
					this.editInProgress = true;
					inputElement.readOnly = true;
					try {
						await entry.editDone(currentInput);
					} finally {
						this.mainService.setNavigationInProgress(false);
						this.editInProgress = false;
						if (singleEdit) {
							inputElement.blur();
						} else {
							inputElement.value = "";
							inputElement.readOnly = false;
							this.scrollToNewChild();
						}
					}
				}
			} else {
				console.log("no editDone callback defined for " + JSON.stringify(entry));
			}
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		} else if ($event.keyCode === 27) {
			// ESC-Key
			inputElement.blur();
		}
	}

	stopEditEntry(
		entry: ListNavigationEntry,
		navigationConfiguration: NavigationConfiguration,
		mouseEvent: MouseEvent,
	): void {
		entry.displayMode = "VIEW";
		this.focusedEntry = entry;
		if (this.isInternalLinkClicked(mouseEvent)) {
			return;
		}
		this.navigate(new FollowUpAction(NavMode.SELF).withForcedReload(), navigationConfiguration);
	}

	isInternalLinkClicked(mouseEvent: MouseEvent): boolean {
		return (
			!!mouseEvent.relatedTarget &&
			mouseEvent.relatedTarget instanceof HTMLAnchorElement &&
			!this.isExternalLinkClicked(mouseEvent.relatedTarget)
		);
	}

	isExternalLinkClicked(clickedElement: HTMLAnchorElement): boolean {
		return clickedElement.target === "_blank" && !!clickedElement.href;
	}

	isFloatingActionButtonWithMenuVisible(navigationConfiguration: NavigationConfiguration): boolean {
		const folder = navigationConfiguration.folder;
		const actionButtonSpec = navigationConfiguration.floatingActionButton;
		return (
			actionButtonSpec !== undefined &&
			actionButtonSpec.type === "withMenu" &&
			actionButtonSpec.menuItems!.length > 0 &&
			folder !== undefined &&
			folder.actions.CREATE_CHILD
		);
	}

	isSimpleFloatingActionButtonVisible(navigationConfiguration: NavigationConfiguration): boolean {
		const folder = navigationConfiguration.folder;
		const actionButtonSpec = navigationConfiguration.floatingActionButton;
		return (
			actionButtonSpec !== undefined &&
			actionButtonSpec.type === "simple" &&
			folder !== undefined &&
			folder.actions.CREATE_CHILD
		);
	}

	onFloatingActionButtonClicked(subtypeIdent: string, navigationConfiguration: NavigationConfiguration): void {
		this.navigate(new FollowUpAction(NavMode.SELF, "add").withSubtype(subtypeIdent), navigationConfiguration);
	}

	private async navigate(
		followUpAction: FollowUpAction,
		navigationConfiguration: NavigationConfiguration,
	): Promise<boolean> {
		const action: MenuUIAction = {
			indicateProgress: false,
			invalidate: false,
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			async onMenuClick(): Promise<FollowUpAction> {
				return followUpAction;
			},
			self: navigationConfiguration.self!,
			folder: navigationConfiguration.folder!,
		};
		return this.processFollowUpAction(
			action,
			navigationConfiguration.activatedRoute!,
			navigationConfiguration.baseRoute!,
			null,
			() => navigationConfiguration.invalidate(),
		);
	}

	isSingleLineDisplayed(displayType: string): boolean {
		switch (displayType) {
			case this.singleLine:
				return true;
			case this.twoLine:
				return false;
			case this.twoLineSplitRating:
				return false;
			case this.changeMarker:
				return false;
			default:
				return true;
		}
	}

	getVisibleNavigationEntries(completeList: ListNavigationEntry[]): ListNavigationEntry[] {
		let addEntry;
		if (completeList.length > 0 && completeList[completeList.length - 1].displayMode === "ADD") {
			addEntry = completeList[completeList.length - 1];
		}
		const visibleNavigationEntries = this.listService.getVisibleListItems(completeList) as ListNavigationEntry[];
		if (addEntry && visibleNavigationEntries[visibleNavigationEntries.length - 1].displayMode !== "ADD") {
			visibleNavigationEntries.push(addEntry);
		}
		return visibleNavigationEntries;
	}

	hasOmmittedEntries(completeList: ListNavigationEntry[]): boolean {
		return this.listService.hasOmmittedItems(completeList);
	}

	handleProgressbar(event: RouterEvent): void {
		if (event instanceof NavigationStart) {
			this.triggerProgressIndicatorTimer();
		}
		if (event instanceof NavigationEnd) {
			this.cancelProgressIndicator();
		}

		// Set loading state to false in both of the below events to hide the ProgressIndicator in case a request fails
		if (event instanceof NavigationCancel) {
			this.cancelProgressIndicator();
		}
		if (event instanceof NavigationError) {
			this.cancelProgressIndicator();
		}
	}

	private cancelProgressIndicator(): void {
		this.mainService.setNavigationInProgress(false);
		this.cancelProgressIndicatorTimer$.next(true);
	}
}

function isViewAction(action: string | null): boolean {
	return action === null || action === "view";
}

function scrollToEntry(entry: ListNavigationEntry, folder?: ListNavigationEntry): void {
	const container: Element | null = document.querySelector(".nav-drawer nav");
	if (!container) {
		return;
	}

	if (folder && folder.id === entry.id) {
		container.scrollTop = 0;
		return;
	}

	/*
	 * The selected element is not a folder, so we need to decide if we have to scroll or not.
	 */
	const item: HTMLElement | null = document.getElementById("navItem" + entry.id);
	if (!item) {
		return;
	}
	const contRect = container.getBoundingClientRect();
	const itemRect = item.getBoundingClientRect();
	if (itemRect.top < contRect.top || itemRect.bottom > contRect.bottom) {
		item.scrollIntoView();
	}
}

function localeCompareSupported(): boolean {
	return !!"foo".localeCompare;
}

function isLowerCase(s: string): boolean {
	if (!s) {
		return false;
	}
	return s.startsWith(s[0].toLowerCase());
}

function getValueForSort(entry: ListNavigationEntry): string {
	if (!entry.getValueForAlphabeticalSort) {
		return "";
	}
	return entry.getValueForAlphabeticalSort();
}

function poorLocaleComparePolyfill(a: ListNavigationEntry, b: ListNavigationEntry): number {
	const valueA = getValueForSort(a);
	const valueB = getValueForSort(b);
	const aIsLowerCase = isLowerCase(valueA);
	const bIsLowerCase = isLowerCase(valueB);
	if (aIsLowerCase && !bIsLowerCase) {
		return -1;
	}
	if (!aIsLowerCase && bIsLowerCase) {
		return 1;
	}
	if (valueA < valueB) {
		return -1;
	} else if (valueA > valueB) {
		return 1;
	}
	return 0;
}
