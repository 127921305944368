<header class="dialog-header">
	<div></div>
	<button [attr.aria-label]="'close' | translate" [attr.title]="'close' | translate" class="action" (click)="cancel()">
		<i class="ico ico-close"></i>
	</button>
</header>

<div class="activator-execution-result content">
	<h1 class="title">{{ keys.executionResult.title }}</h1>
	<ul class="list dense">
		<li class="list-item">
			<div class="label">
				<div class="text">
					<h4 translate="process.enactment.activator.execution.status"></h4>
					<div class="icon-and-text">
						<i class="ico ico-warning warning-color" *ngIf="keys.executionResult.status === 'warnings'"></i>
						<i class="ico ico-warning error-color" *ngIf="keys.executionResult.status === 'failure'"></i>
						<i class="ico ico-check success-color" *ngIf="keys.executionResult.status === 'success'"></i>
						<p [translate]="getStatusMessageKey()"></p>
					</div>
				</div>
			</div>
		</li>
		<li class="list-item">
			<div class="label">
				<div class="text">
					<h4 translate="process.enactment.activator.execution.activator"></h4>
					<p>{{ keys.executionResult.activatorName }}</p>
				</div>
			</div>
		</li>
		<li class="list-item" *ngIf="keys.executionResult.executionDate">
			<div class="label">
				<div class="text">
					<h4 translate="process.enactment.activator.execution.date"></h4>
					<p>{{ keys.executionResult.executionDate }}</p>
				</div>
			</div>
		</li>
		<li class="list-item">
			<div class="label">
				<div class="text">
					<h4 translate="process.enactment.activator.execution.user"></h4>
					<p>{{ keys.executionResult.userFullname }}</p>
				</div>
			</div>
		</li>
		<li class="list-item" *ngIf="keys.executionResult.summary">
			<div class="label">
				<div class="text">
					<h4 translate="process.enactment.activator.execution.summary"></h4>
					<p
						*ngIf="keys.executionResult.summary.messageKey && !keys.executionResult.summary.url"
						[translate]="keys.executionResult.summary.messageKey"
					></p>
					<p *ngIf="!keys.executionResult.summary.messageKey && !keys.executionResult.summary.url">
						{{ keys.executionResult.summary.message }}
					</p>
					<a
						*ngIf="keys.executionResult.summary.messageKey && keys.executionResult.summary.url"
						[href]="keys.executionResult.summary.url"
						target="_blank"
						>{{ keys.executionResult.summary.messageKey | translate }}</a
					>
					<a
						*ngIf="!keys.executionResult.summary.messageKey && keys.executionResult.summary.url"
						[href]="keys.executionResult.summary.url"
						target="_blank"
						>{{ keys.executionResult.summary.message }}</a
					>
				</div>
			</div>
		</li>
		<li class="list-item" *ngIf="keys.executionResult.partialResults.length > 0">
			<div class="label">
				<div class="text">
					<h4 translate="process.enactment.activator.execution.partialresults"></h4>
					<ul class="list sublist dense">
						<li class="list-item sublist-item" *ngFor="let partialResult of keys.executionResult.partialResults">
							<i class="ico ico-warning warning-color" *ngIf="partialResult.status === 'warnings'"></i>
							<i class="ico ico-warning error-color" *ngIf="partialResult.status === 'failure'"></i>
							<i class="ico ico-check success-color" *ngIf="partialResult.status === 'success'"></i>
							<p *ngIf="partialResult.messageKey && !partialResult.url" translate="partialResult.messageKey"></p>
							<p *ngIf="!partialResult.messageKey && !partialResult.url">{{ partialResult.message }}</p>
							<a *ngIf="partialResult.messageKey && partialResult.url" [href]="partialResult.url" target="_blank">{{
								partialResult.messageKey | translate
							}}</a>
							<a *ngIf="!partialResult.messageKey && partialResult.url" [href]="partialResult.url" target="_blank">{{
								partialResult.message
							}}</a>
						</li>
					</ul>
				</div>
			</div>
		</li>
	</ul>
</div>

<footer>
	<div class="buttons-bottom buttons-right buttons-bottom-margin-fix">
		<div class="spacer"></div>
		<button class="button sm ok" translate="ok" (click)="cancel()" tabindex="1"></button>
	</div>
</footer>
