import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MainService } from "core/main.service";
import { IRoot } from "process/tailoring/models/root.interface";
import { TailoringWizardStore } from "process/tailoring/store";
import { TailoringWizardService } from "process/tailoring/tailoring-wizard.service";
import { TailoringWizardLogicService } from "process/tailoring/wizard-logic.service";
import { Subject } from "rxjs";
import { filter, map, mergeMap, takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-process-tailoring-tailoring-wizard",
	templateUrl: "./tailoring-wizard.component.html",
})
export class TailoringWizardComponent implements OnInit, OnDestroy {
	root!: IRoot;

	private destroy$ = new Subject<boolean>();

	executeInProgress: boolean = false;
	previewInProgress: boolean = false;

	preview: boolean = false;

	constructor(
		private store: TailoringWizardStore,
		private router: Router,
		private route: ActivatedRoute,
		private wizardLogic: TailoringWizardLogicService,
		private tailoringWizardService: TailoringWizardService,
		private mainService: MainService,
	) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this.route),
				map((route) => {
					let childRoute = route;
					while (childRoute.firstChild) {
						childRoute = childRoute.firstChild;
					}
					return childRoute;
				}),
				mergeMap((route) => route.data),
				takeUntil(this.destroy$),
			)
			.subscribe((data) => {
				if (data.preview) {
					this.preview = true;
					this.previewInProgress = false;
				}
				if (data.query) {
					this.preview = false;
					this.previewInProgress = false;
					this.store.set("query", data.query);
				}
			});

		this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
			const workspaceId = this.route.snapshot.paramMap.get("workspaceId")!;
			const processVersion = this.route.snapshot.paramMap.get("processVersion")!;
			this.root = data.root;
			this.wizardLogic.initMapsWithRoot(this.root, workspaceId, processVersion);
			//this.wizardLogic.loadDataFromSession();

			if (this.root.queries.length > 0) {
				const defaultQueryToDisplay = this.root.queries[0].id;
				let queryToDisplay = defaultQueryToDisplay;

				if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.params.queryId) {
					queryToDisplay = this.route.snapshot.firstChild.params.queryId;
				}

				this.router.navigate(["query/", queryToDisplay], {
					relativeTo: this.route,
					replaceUrl: true,
				});
			} else {
				console.log("no Tailoring queries defined for this process");
			}
		});
	}

	isFirstQurery(): boolean {
		// TODO bugfix on F5 toor is not set
		if (
			this.route.snapshot.firstChild &&
			this.route.snapshot.firstChild.params.queryId &&
			this.root &&
			this.root.queries.length > 0
		) {
			return this.route.snapshot.firstChild.params.queryId === this.root.queries[0].id;
		}
		return false;
	}

	onClickPrevious(): void {
		if (this.preview) {
			const lastQuery = this.wizardLogic.getLastQuery();
			if (lastQuery) {
				this.router.navigate(["query/", lastQuery.id], {
					relativeTo: this.route,
				});
			} else {
				this.router.navigate(["query/", this.store.value.query!.id], {
					relativeTo: this.route,
				});
			}
		} else {
			const currentQueryId = this.route.snapshot.firstChild!.params.queryId;
			const previousQuery = this.wizardLogic.getPreviousQuery(currentQueryId);
			if (previousQuery) {
				this.router.navigate(["query/", previousQuery.id], {
					relativeTo: this.route,
				});
			}
		}
	}

	onClickNext(): void {
		let query;
		if (this.route.snapshot.firstChild && this.route.snapshot.firstChild.params.queryId) {
			const currentQueryId = this.route.snapshot.firstChild.params.queryId;
			const currentAnswer = this.wizardLogic.getAnswerOfQuery(currentQueryId);

			query = currentAnswer
				? this.wizardLogic.getNextQueryByAnswer(currentAnswer.id)
				: this.wizardLogic.getNextQueryByAnswer(null);
		}
		if (query) {
			this.router.navigate(["query/", query.id], {
				relativeTo: this.route,
			});
		} else {
			this.onClickPreview();
		}
	}

	isUnAnswered(): boolean {
		let isUnAnswered = true;
		const currentQuery = this.store.value.query;
		let currentAnswer;

		if (currentQuery) {
			currentAnswer = this.wizardLogic.getAnswerOfQuery(currentQuery.id);
		}
		if (currentAnswer) {
			isUnAnswered = false;
		}

		return isUnAnswered;
	}

	onClickCancel(): void {
		this.leaveWizard();
	}

	leaveWizard(): void {
		this.wizardLogic.removeAnsweredQueriesFromStorage();
		this.mainService.closeDialog(this.route);
	}

	onClickPreview(): void {
		this.previewInProgress = true;

		this.router.navigate(["preview"], {
			relativeTo: this.route,
		});
	}

	async onClickExecute(): Promise<void> {
		this.executeInProgress = true;
		const answeredQueries = this.store.value.answeredQueries!;
		await this.tailoringWizardService.commitTailoring(
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
			answeredQueries,
		);
		this.executeInProgress = false;

		this.leaveWizard();
	}

	get answeredQueriesExists(): boolean {
		return !!this.store.value.answeredQueries && this.store.value.answeredQueries.length > 0;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.wizardLogic.removeAnsweredQueriesFromStorage();
	}
}
