import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { InputBase } from "common/form/input/types/input-base";
import { TextInput } from "common/form/input/types/text-input";
import { Subscription } from "rxjs";

@Component({
	selector: "stages-enactment-activator-execution-start",
	templateUrl: "enactment-activator-execution-start.component.html",
	styleUrls: ["./enactment-activator-execution-start.component.scss"],
})
export class EnactmentActivatorExecutionStartComponent implements OnInit, OnDestroy {
	@Input()
	keys!: {
		activatorName: string;
		callback(executionTitle: string): void;
	};

	executionTitle!: string;
	form = new FormGroup({});
	inputs: InputBase<string[] | boolean | string>[] = [];

	private routerEventsSubscription?: Subscription;

	constructor(private dialog: DialogAdapter, private router: Router, private mutexService: MutexService) {}

	ngOnInit(): void {
		this.routerEventsSubscription = this.router.events.subscribe((s) => {
			if (s instanceof NavigationEnd) {
				this.cancel();
			}
		});

		const executionTitleInput = new TextInput({
			key: "executionTitle",
			translateKey: "process.enactment.activator.execution.start.field.executionTitle",
			required: true,
			maxLength: 128,
		});
		this.inputs.push(executionTitleInput);
		this.form.addControl("executionTitle", new FormControl(executionTitleInput.value, executionTitleInput.validators));
	}

	ngOnDestroy(): void {
		if (this.routerEventsSubscription) {
			this.routerEventsSubscription.unsubscribe();
		}
	}

	cancel(): void {
		this.dialog.close("cancel");
	}

	execute(): void {
		this.form.get("executionTitle")!.markAsTouched();

		if (this.form.valid) {
			// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			this.mutexService.invoke("activatorExecutionTitle", async () => {
				this.keys.callback(this.form.get("executionTitle")!.value);
				this.dialog.close("ok");
				return null;
			});
		}
	}
}
