<article class="compliance-rating" *ngIf="rating">
	<div class="rating-container">
		<div class="rating rating-container-content">
			<div class="ico-size" [ngClass]="getIconClasses()">
				<i class="ico"></i>
				<i class="ico"></i>
				<i class="ico"></i>
			</div>
			<span *ngIf="rating" translate="{{ translateMap[rating.rating] }}"></span>&nbsp;
			<span *ngIf="rating.derived" translate="process.compliance.rating.derived"></span>
		</div>
		<stages-menu
			class="action"
			[menuId]="$any(ratingMenuItems)"
			iconClasses="ico ico-more-vertical"
			[items]="ratingMenuItems"
			openTo="left"
		></stages-menu>
	</div>
</article>

<article class="compliance-rating comments subsection" *ngIf="rating">
	<header>
		<div class="wrapper">
			<h5><span translate="compliance.rating.notes"></span></h5>
			<stages-menu
				[menuId]="$any(commentMenuItems)"
				class="action"
				iconClasses="ico ico-more-vertical"
				openTo="bottom"
				[items]="commentMenuItems"
			></stages-menu>
		</div>
	</header>
	<div class="rating-comment-container">
		<div class="rating-container-content">
			<div *ngIf="!editEnabled && isEmpty()">
				<p class="rating-container-comments-content-empty" translate="none"></p>
			</div>
			<div class="rating-container-comments-content" *ngIf="!editEnabled && !isEmpty()">{{ comment }}</div>
			<div class="rating-container-comments-content description" *ngIf="editEnabled">
				<textarea
					class="text plaintext-input"
					[(ngModel)]="comment"
					tabindex="30"
					stagesAutoGrow
					stagesAutofocus
				></textarea>
				<div class="buttons">
					<button class="sm cancel" (click)="cancel()" translate="cancel"></button>
					<button class="sm save" (click)="saveComment()" translate="save"></button>
				</div>
			</div>
		</div>
	</div>
</article>

<article class="description" *ngIf="!rating">
	<p translate="process.compliance.rating.invisible"></p>
</article>
