<article class="error-page" *ngIf="paramMap$ | async as paramMap">
	<div class="wrapper">
		<h5 class="title">{{ paramMap.get("title") }}</h5>
		<div class="error">{{ paramMap.get("message") }}</div>

		<footer>
			<div class="footer">
				<span class="img stages"></span>
			</div>
		</footer>
	</div>
</article>
