import { Injectable } from "@angular/core";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { ProcessElementsResource, SearchQueryService } from "core/stages-client";
import { ViewService } from "core/view.service";

type TypedIdentity = stages.core.TypedIdentity;
type ViewableAssociation = stages.process.ViewableAssociation;
type SortStrategy = stages.core.sort.SortStrategy;

@Injectable({ providedIn: "root" })
export class AssociationService {
	constructor(
		private readonly processElementsResource: ProcessElementsResource,
		private readonly searchQueryService: SearchQueryService,
		private readonly viewService: ViewService,
	) {}

	async deleteAssociations(associations: ViewableAssociation[], workspaceId: string, pv: string): Promise<void> {
		const ids = associations.map((association: ViewableAssociation) => association.id);

		return this.processElementsResource.deleteAssociations(workspaceId, {
			associationIds: ids,
			pv: pv,
		});
	}

	async searchProcessElements(
		query: string,
		targetElementType: string,
		targetElementSubtypes: string[] | undefined,
		associatedElementIdentities: TypedIdentity[],
		currentWorkspaceId: string,
		pv: string,
		targetProcessIds: string[],
	): Promise<AssocSearchResult[]> {
		return this.searchQueryService.findAssociationTargetsInSelectedProcesses(associatedElementIdentities, {
			query: query,
			type: targetElementType,
			subtypes: targetElementSubtypes,
			workspaceId: currentWorkspaceId,
			pv: pv,
			processIds: targetProcessIds,
			documentTypes: ["process_element"],
			searchRelatedWorkspaces: true,
		}) as Promise<AssocSearchResult[]>;
	}

	async searchDependentProcessElements(
		query: string,
		targetElementType: string,
		containerElementIdentity: string,
		associatedElementIdentities: TypedIdentity[],
		currentWorkspaceId: string,
		pv: string,
		targetProcessIds: string[],
		ignoreTailoringStatus: boolean,
		ignoreAssignable: boolean,
	): Promise<AssocSearchResult[]> {
		return this.searchQueryService.findDependentAssociationTargets(associatedElementIdentities, {
			query: query,
			type: targetElementType,
			containerElementIdentity: containerElementIdentity,
			workspaceId: currentWorkspaceId,
			pv: pv,
			processIds: targetProcessIds,
			searchRelatedWorkspaces: true,
			ignoreTailoringStatus: ignoreTailoringStatus,
			ignoreAssignable: ignoreAssignable,
		}) as Promise<AssocSearchResult[]>;
	}

	async createCommentOnlyAssociation(
		sourceId: string,
		sourceType: string,
		targetType: string,
		targetSubtype: string | null,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<ViewableAssociation> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource.addCommentOnlyAssociation(
			workspaceId,
			sourceType,
			sourceId,
			associationType,
			sourceRole!,
			targetType,
			targetSubtype!,
			comment,
		) as Promise<ViewableAssociation>;
	}

	async createAssociation(
		sourceElementId: string,
		sourceElementType: string,
		targetElementId: string,
		targetElementType: string,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
	): Promise<ViewableAssociation> {
		if (sourceRole === undefined) {
			return this.processElementsResource.addAssociation$POST$workspace_workspaceId_process_elements_type_id_associations_assocType_targetElementType_targetElementId(
				workspaceId,
				sourceElementType,
				sourceElementId,
				associationType,
				targetElementType,
				targetElementId,
				{
					pv: pv,
				},
			) as Promise<ViewableAssociation>;
		}
		return this.processElementsResource.addAssociation$POST$workspace_workspaceId_process_elements_type_id_associations_assocType_sourceRolesourceRole_targetElementType_targetElementId(
			workspaceId,
			sourceElementType,
			sourceElementId,
			associationType,
			sourceRole,
			targetElementType,
			targetElementId,
			{
				pv: pv,
			},
		) as Promise<ViewableAssociation>;
	}

	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			viewMode: "view",
		});
		return this.processElementsResource.updateAssociationComment(currentWorkspaceId, associationId, comment, {
			pv: pv,
		});
	}

	async applyAssociationModifications(
		sourceElementType: string,
		sourceElementId: string,
		groupPath: string,
		added: stages.core.TypedId[],
		removed: stages.core.TypedId[],
		modifiedComments: stages.core.model.TypedIdAssociationDetails[],
		workspaceId: string,
		pv: string,
	): Promise<ViewableAssociation[]> {
		const associations = await this.processElementsResource.updateAssociations(
			workspaceId,
			sourceElementType,
			sourceElementId,
			groupPath,
			{
				added: added,
				removed: removed,
				modified: modifiedComments,
			},
			{
				pv: pv,
			},
		);
		return associations as ViewableAssociation[];
	}

	async getTree(browseWorkspaceId: string, elementTypes: string[]): Promise<stages.process.TreeElement[]> {
		return this.processElementsResource.getTree(browseWorkspaceId, elementTypes[0], {
			elementTypes: elementTypes,
			includeDependentElements: true,
		});
	}

	async rearrangeAssociations(
		workspaceId: string,
		type: string,
		identity: string,
		sortedAssociationIDs: string[],
		sortStrategy: SortStrategy,
		path: string,
		groupBy: string | null,
		pv: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO
	): Promise<Record<string, any>> {
		const view = await this.processElementsResource.rearrangeAssociations(workspaceId, type, identity, {
			sortStrategy: sortStrategy,
			groupBy: groupBy,
			path: path,
			sortedAssociationIds: sortedAssociationIDs,
			pv: pv,
		});
		return this.viewService.getSelf(view.processView).associations;
	}

	async getCombinedAssociations(
		targetElementIdentities: string[],
		currentWorkspaceId: string,
		sourceElementType: string,
		sourceElementIdentity: string,
		path: string,
		page: number | undefined,
		pageSize: number | undefined,
		pv: string,
	): Promise<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>> {
		return this.processElementsResource.getCombinedAssociations(
			currentWorkspaceId,
			sourceElementType,
			sourceElementIdentity,
			{ combinedTargetElementIdentities: targetElementIdentities, path: path, page: page, pageSize: pageSize, pv: pv },
		) as Promise<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>>;
	}
}
