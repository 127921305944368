import { Injectable } from "@angular/core";
import { ProcessResource } from "core/stages-client";

@Injectable({ providedIn: "root" })
export class ResetTailoringService {
	constructor(private processResource: ProcessResource) {}

	async reset(workspaceId: string, pv: string): Promise<void> {
		return this.processResource.reset(workspaceId, {
			pv: pv,
		});
	}
}
