/* eslint-disable @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
import { Injectable } from "@angular/core";
import { ProgressService } from "core/progress.service";

type JobIdentifier = stages.core.scheduler.JobIdentifier;

@Injectable({ providedIn: "root" })
export class NotificationService {
	private attempts = 1;
	private socket!: WebSocket;
	private subscriptions: any = {};
	private messages: any[] = [];
	private subscriptionQueue: any[] = [];
	private lastHandled: any = {};
	private preservingSubscriptions = false;

	constructor(private progressService: ProgressService) {}

	init(): void {
		if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
			const url =
				(window.location.protocol === "http:" ? "ws://" : "wss://") +
				window.location.host +
				window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")) +
				"/socket";
			this.socket = new WebSocket(url);
			this.socket.onmessage = (event) => {
				this.dispatch(event);
			};
			this.socket.onopen = () => {
				this.onopen();
			};
			this.socket.onclose = (e) => {
				this.onclose(e);
			};
		}
	}

	destroy(): void {
		if (this.socket) {
			this.socket.close();
		}
	}

	private onopen(): void {
		this.attempts = 1;

		this.subscriptionQueue.forEach((subscription) => {
			this.socket.send(subscription);
		});
		this.subscriptionQueue = [];
	}

	private onclose(e: CloseEvent): void {
		if (e.code !== 1000) {
			// Abnormal
			this.reconnect();
		}
	}

	private reconnect(): void {
		if (!window.location.hash.startsWith("#/login")) {
			const interval = this.generateInterval(this.attempts);
			setTimeout(() => {
				this.attempts++;
				this.init();
			}, interval);
		}
	}

	// could eventually be private
	dispatch(event: any): void {
		const message = JSON.parse(event.data);
		this.messages.push(message);
		const channel = message.channel;
		const handlers = this.subscriptions[message.channel];
		if (!!handlers) {
			const handler = handlers[message.type];
			if (!!handler) {
				this.lastHandled[channel] = message.sequenceNumber;
				handler(message.data);
			}
		}
	}

	generateInterval(k: number): number {
		// eslint-disable-next-line prefer-exponentiation-operator -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		let maxInterval = (Math.pow(2, k) - 1) * 1000;

		if (maxInterval > 30 * 1000) {
			maxInterval = 30 * 1000; // If the generated interval is more than 30 seconds, truncate it down to 30 seconds.
		}

		// generate the interval to a random number between 0 and the maxInterval determined from above
		return Math.random() * maxInterval;
	}

	subscribe(channelName: string, handlers: any): void {
		if (this.preservingSubscriptions) {
			return;
		}

		this.init();

		if (!this.lastHandled[channelName]) {
			this.lastHandled[channelName] = 0;
		}

		this._send("SUBSCRIBE", channelName);
		this.setHandlers(channelName, handlers);
	}

	setHandlers(channelName: string, handlers: any): void {
		this.subscriptions[channelName] = handlers;
		if (handlers) {
			this.handleOutstandingMessages(channelName, handlers);
		}
	}

	unsubscribe(channelName: string): void {
		if (this.preservingSubscriptions) {
			return;
		}

		this._send("UNSUBSCRIBE", channelName);
		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		delete this.subscriptions[channelName];
	}

	handleOutstandingMessages(channel: string, handlers: any): void {
		const start = this.lastHandled[channel];
		this.messages.forEach((message) => {
			if (channel === message.channel && message.sequenceNumber > start) {
				const handler = handlers[message.type];
				if (!!handler) {
					this.lastHandled[channel] = message.sequenceNumber;
					handler(message.data);
				}
			}
		});
	}

	async doPreservingSubscriptions(promiseReturningFunc: () => Promise<any>): Promise<any> {
		this.preservingSubscriptions = true;
		return promiseReturningFunc().then(
			() => {
				this.preservingSubscriptions = false;
			},
			() => {
				this.preservingSubscriptions = false;
			},
		);
	}

	getJobChannel(jobIdentifier: JobIdentifier): string {
		return jobIdentifier.group + "." + jobIdentifier.name;
	}

	private _send(type: string, channel: string): void {
		const subscription = JSON.stringify({
			type: type,
			channel: channel,
		});

		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(subscription);
		} else {
			this.subscriptionQueue.push(subscription);
		}
	}

	/**
	 * special case of pollProgress for progress details; polls every second
	 */
	pollProgress(jobIdentifier: JobIdentifier, workspaceId: string, pv: string, callback: (a: any) => void): number {
		return this.pollPromise(async () => {
			return this.progressService.getJobProgress(jobIdentifier.group, workspaceId, pv, jobIdentifier.name, true);
		}, callback);
	}

	/**
	 * fetches data (customizable with a supplier function) every 3 seconds
	 *
	 * @param supplier:
	 *            function returning a promise
	 * @param callback:
	 *            function consuming the return value of the promise
	 * @param interval:
	 *            polling interval in ms, default: 3000ms
	 * @param params:
	 *            params which are passed down to the supplier function
	 */
	pollPromise(
		supplier: (params: any) => Promise<any>,
		callback: (a: any) => void,
		interval?: number,
		params?: any,
	): number {
		let pending = false;
		// An alternative is to use "Observable.interval"
		const handle = window.setInterval(
			async () => {
				if (pending) {
					return;
				}
				pending = true;
				try {
					const value = await supplier(params);
					pending = false;
					callback(value);
				} catch (err: unknown) {
					this.cancel(handle);
					pending = false;
					throw err;
				}
			},
			interval ? interval : 3000,
		);

		return handle;
	}

	cancel(handle: number): void {
		window.clearInterval(handle);
	}
}
