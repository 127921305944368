import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { BrowseView } from "common/associations/association-browser.interface";
import { BrowseService } from "core/browse.service";
import { EnactmentElementResource } from "core/stages-client";

type Workspace = stages.workspace.Workspace;

@Injectable({ providedIn: "root" })
export class EnactmentBrowseViewResolver implements Resolve<BrowseView> {
	constructor(
		private readonly enactmentElementResource: EnactmentElementResource,
		private browseService: BrowseService,
	) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<BrowseView> {
		const onDemandModuleWorkspaces: Workspace[] = route.parent!.data.related ? route.parent!.data.related : null;
		return this.enactmentElementResource.getBrowseView(
			route.paramMap.get("browseWorkspaceId")!,
			route.paramMap.get("browseType")!,
			route.paramMap.get("browseIdentity")!,
			{
				browsePV: this.browseService.getBrowseProcessVersion(
					onDemandModuleWorkspaces,
					route.paramMap.get("browseWorkspaceId")!,
					route.paramMap.get("browseProcessVersion")!,
				),
			},
		);
	}
}
