<stages-dialog2 #dialog class="autoHeight">
	<stages-dialog-header> </stages-dialog-header>
	<ng-container *ngIf="task$ | async as task">
		<div class="content">
			<h1>{{ task.name }}</h1>
			<form [formGroup]="form" class="form">
				<ng-container *ngIf="!task.actions.ClaimTask">
					<div class="form-group">
						<div class="input-container sm" [ngClass]="{ required: task.commentRequired }">
							<label for="comment" class="input-label" translate="dashboard.todolist.task.comment"></label>
							<textarea
								id="comment"
								name="comment"
								rows="0"
								tabindex="1"
								formControlName="comment"
								stagesAutoGrow
								stagesTrim
								stagesAutofocus
								class="text plaintext-input"
							></textarea>
							<div class="info">
								<div
									*ngIf="form.get('comment')!.errors && (form.get('comment')!.dirty || form.get('comment')!.touched)"
								>
									<div
										*ngIf="form.get('comment')!.errors?.required"
										class="error"
										translate="input.required"
										role="alert"
									></div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<div class="input-container sm">
							<stages-input *ngIf="dateTimeInput" [form]="form" [input]="dateTimeInput"></stages-input>
						</div>
					</div>
				</ng-container>

				<div class="form-group" *ngIf="task.description.html !== ''">
					<div class="input-container">
						<label
							for="todolist-task-description"
							class="input-label"
							translate="dashboard.todolist.task.description"
						></label>
						<stages-description-view id="todolist-task-description" #description [html]="task.description.html">
						</stages-description-view>
						<div class="info"></div>
					</div>
				</div>

				<div class="form-group" *ngIf="task.versionDescription!.html !== ''">
					<div class="input-container">
						<label
							for="todolist-task-versiondescription"
							class="input-label"
							translate="dashboard.todolist.task.versionDescription"
						></label>
						<stages-description-view
							id="todolist-task-versiondescription"
							#description
							[html]="task.versionDescription!.html"
						>
						</stages-description-view>
						<div class="info"></div>
					</div>
				</div>

				<div class="form-group">
					<div class="input-container sm">
						<label for="todolist-task-process" class="input-label" translate="dashboard.todolist.task.process"></label>
						<ul id="todolist-task-process" class="list dense">
							<li class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.versionName"></h4>
										<p>{{ task.versionName }}</p>
									</div>
								</div>
							</li>
							<li class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.workspaceName"></h4>
										<p>{{ task.workspaceName }}</p>
									</div>
								</div>
							</li>
							<li class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.workspacePath"></h4>
										<p>{{ task.workspacePath }}</p>
									</div>
								</div>
							</li>
						</ul>
						<div class="info"></div>
					</div>
					<div class="input-container sm">
						<h5 class="input-label" translate="dashboard.todolist.task.details"></h5>
						<ul class="list dense">
							<li class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.state"></h4>
										<p>
											<span
												class="badge changed-badge"
												[ngClass]="task.state"
												[translate]="'dashboard.todolist.task.state.' + task.state"
											></span>
										</p>
									</div>
								</div>
							</li>
							<li class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.creationDate"></h4>
										<p>{{ task.creationDate }}</p>
									</div>
								</div>
							</li>
							<li *ngIf="!!task.dueDate" class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.dueDate"></h4>
										<p>{{ task.dueDate }}</p>
									</div>
								</div>
							</li>
							<li class="list-item no-hover no-padding">
								<div class="label">
									<div class="text">
										<h4 translate="dashboard.todolist.task.workflowName"></h4>
										<p>{{ task.workflowName }}</p>
									</div>
								</div>
							</li>
						</ul>
						<div class="info"></div>
					</div>
				</div>
			</form>

			<ng-container *ngIf="task.stepType == 'APPROVE_PROCESS_INTERFACES' && task.actions['ReadInterfaces']">
				<div class="contentpadding">
					<h5 class="input-label" translate="dashboard.todolist.task.changes"></h5>
				</div>
				<stages-process-interfaces-interfaces-list
					[sourceWorkspaceId]="task.workspaceId"
					[sourceProcessVersion]="task.versionIdentifier"
					[targetWorkspaceId]="task.workflowInstanceWorkspaceId"
					[showOnlyChanges]="true"
					[routable]="false"
				></stages-process-interfaces-interfaces-list>
				<div class="form">
					<div class="form-group">
						<div class="input-container sm">
							<div class="info"></div>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="task.relatedTasks.length > 0">
				<div class="contentpadding">
					<h5 class="input-label" translate="dashboard.todolist.task.related"></h5>
				</div>
				<ul class="list">
					<ng-container *ngFor="let relatedTask of task.relatedTasks; trackBy: trackBy; let last = last">
						<li class="list-item">
							<div class="label">
								<div class="avatar-cell">
									<stages-user-image
										*ngIf="relatedTask.user"
										[userName]="relatedTask.user.name"
										userId="{{ relatedTask.user.id }}"
										version="{{ relatedTask.user.profileImageVersion }}"
									></stages-user-image>
									<img
										*ngIf="!relatedTask.user"
										class="avatar"
										[attr.alt]="'accessibility.profilepicture.group.placeholder' | translate"
										src="img/image/avatar/group.png"
									/>
								</div>
								<div class="text center">
									<h4>
										{{ relatedTask.name }}
										<span
											class="badge changed-badge"
											[ngClass]="relatedTask.state"
											[translate]="getStateKey(relatedTask)"
										></span>
									</h4>
									<p *ngIf="relatedTask.user">{{ relatedTask.user.fullname }}</p>
									<p>
										<span *ngIf="!!relatedTask.decision">{{ relatedTask.decision }} </span
										>{{ relatedTask.completionDate }}
									</p>
									<p *ngIf="relatedTask.comment !== null">{{ relatedTask.comment }}</p>
								</div>
							</div>
						</li>
						<li class="divider" *ngIf="!last"></li>
					</ng-container>
				</ul>
			</ng-container>
		</div>

		<footer>
			<stages-buttons-bottom [removeMargin]="true">
				<button type="button" class="button flat" translate="cancel" (click)="cancel()" tabindex="2"></button>
				<button
					type="button"
					class="button sm action"
					(click)="claimTask(task)"
					*ngIf="!!task.actions.ClaimTask"
					tabindex="3"
					translate="management.process.versions.workflows.task.claim"
				></button>
				<ng-container *ngIf="!task.actions.ClaimTask">
					<button
						type="button"
						class="button sm action"
						(click)="completeTask(task, option.id!)"
						*ngFor="let option of task.options; let index = index"
						[disabled]="!this.form.valid"
						[ngStyle]="{
							'background-color': this.form.valid ? option.color : null,
							color: this.form.valid ? option.textColor : null
						}"
						tabindex="{{ 3 + index }}"
					>
						{{ option.name }}
					</button>
				</ng-container>
			</stages-buttons-bottom>
		</footer>
	</ng-container>
</stages-dialog2>
