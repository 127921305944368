import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MainService } from "core/main.service";
import { LanguageService } from "core/translate/language.service";

@Injectable({ providedIn: "root" })
export class ApplicationContextResolver implements Resolve<stages.context.ApplicationContext> {
	constructor(private mainService: MainService, private languageService: LanguageService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.context.ApplicationContext> {
		const application = await this.mainService.getApplication(route.params.processVersion);
		await this.languageService.determineAndApplyLanguage(route.params.processVersion, application.preferredLanguage);
		return application;
	}
}
