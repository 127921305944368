import { Injectable } from "@angular/core";
import {
	DescriptionService as CommonDescriptionService,
	EditableDescription,
	PluginRegistry,
} from "common/editor/description.service";
import { ImagePluginComponent } from "common/editor/image-plugin/image-plugin.component";
import { EntryPageAdministrationResource } from "core/stages-client";
import { HtmlTemplatePluginComponent } from "process/description/htmltemplate-plugin/htmltemplate-plugin.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";

type DisplayDescription = stages.core.format.DisplayDescription;

@Injectable({ providedIn: "root" })
export class EntryPagesDescriptionService implements CommonDescriptionService {
	constructor(private readonly entryPageAdministrationResource: EntryPageAdministrationResource) {}

	// unused parameters are only there, because the interface demands them
	async getDescription(
		type: string,
		entryPageId: string,
		workspaceId: string,
		pv: string,
	): Promise<EditableDescription> {
		const descriptionTO = await this.entryPageAdministrationResource.getDescription(entryPageId, {
			viewMode: "EDIT",
		});
		const editableDescription = {
			description: descriptionTO.data,
			isEditable: true,
			metadata: descriptionTO.metadata,
		};
		return editableDescription;
	}

	// unused parameters are only there, because the interface demands them
	async putDescription(
		type: string,
		entryPageId: string,
		workspaceId: string,
		pv: string,
		description: DisplayDescription,
	): Promise<DisplayDescription> {
		return this.entryPageAdministrationResource
			.setDescription(entryPageId, description)
			.then((dataAndInfo) => dataAndInfo.data);
	}

	getPluginRegistry(): PluginRegistry {
		return {
			stagesimage: ImagePluginComponent,
			stageslink: LinkPluginComponent,
			stageshtmltemplate: HtmlTemplatePluginComponent,
		};
	}
}
