import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { ErrorReportService } from "common/exception/error-report.service";
import { MainService } from "core/main.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	templateUrl: "error-report.component.html",
})
export class ErrorReportComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	inProgress = false;
	subject!: string;
	messageBody!: string;
	paramMap$!: Observable<ParamMap>;

	constructor(
		private route: ActivatedRoute,
		private readonly mutexService: MutexService,
		private readonly mainService: MainService,
		private readonly errorReportService: ErrorReportService,
	) {}

	ngOnInit(): void {
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
			const incidentId = paramMap.get("incidentId");
			this.subject = incidentId ? `[Incident ID ${incidentId}]` : "";
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	async send(): Promise<void> {
		this.inProgress = true;
		try {
			await this.mutexService.invokeWithResult("sendErrorReport", async () =>
				this.errorReportService.send(this.subject, this.messageBody),
			);
		} finally {
			this.inProgress = false;
		}
		void this.mainService.goHome(
			this.route.snapshot.paramMap.has("processVersion") ? this.route.snapshot.paramMap.get("processVersion")! : "_vv",
		);
	}

	cancel(): void {
		void this.mainService.goHome(
			this.route.snapshot.paramMap.has("processVersion") ? this.route.snapshot.paramMap.get("processVersion")! : "_vv",
		);
	}
}
