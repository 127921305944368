import { Injectable } from "@angular/core";
import { BreadcrumbItem } from "common/breadcrumb/breadcrumb-item";

type ProcessView = stages.process.ProcessView;

@Injectable({ providedIn: "root" })
export class BreadcrumbService {
	newBreadcrumbItems(selfView: ProcessView, addSelf: boolean, isEditing: boolean): BreadcrumbItem[] {
		const { transitiveParents, process } = selfView;
		const parentPath: BreadcrumbItem[] = [];

		if (transitiveParents) {
			// Toplevel Entry:
			const rootElement = getBreadcrumbElement(process, isEditing);
			parentPath.push(rootElement);

			for (const transitiveParent of transitiveParents) {
				const element = getBreadcrumbElement(transitiveParent, isEditing);
				parentPath.push(element);
			}
		}

		if (addSelf) {
			const selfElement = getBreadcrumbElement(selfView, isEditing);
			parentPath.push(selfElement);
		}

		return parentPath;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
function getBreadcrumbElement(element: any, isEditing: boolean): BreadcrumbItem {
	const breadcrumbElement = new BreadcrumbItem();
	breadcrumbElement.label = element.label;
	if (isEditing) {
		breadcrumbElement.route = ["../../../..", "process", element.type.ident, element.identity];
	} else {
		breadcrumbElement.route = ["../../..", "process", element.type.ident, element.identity];
	}

	if (element.tailored) {
		breadcrumbElement.listItemClass = "tailored";
	}

	return breadcrumbElement;
}
