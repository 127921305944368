<ng-container *ngIf="workspaceView$ | async as workspaceView">
	<ng-container *ngIf="data$ | async as data">
		<header class="page-header">
			<ul class="breadcrumb">
				<li>
					<a class="back" [routerLink]="TranslateComponent.getCloseLink(data.translation, data.dependent)">
						<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
					</a>
				</li>
			</ul>
			<h1 *ngIf="!data.dependent" translate="process.element.translate.title"></h1>
			<h1
				*ngIf="data.dependent"
				translate="process.element.translate.type.title"
				[translateParams]="{ type: dependentElement!.type.name }"
			></h1>
		</header>
		<article class="translate-element">
			<stages-edit-translations
				[translation]="data.translation"
				[beanType]="type"
				[beanId]="id"
				[beanIdentity]="identity"
				[workspaceId]="workspaceView.currentWorkspace.id"
				[pv]="workspaceView.currentWorkspace.viewedProcess!.versionIdentifier"
				(cancelled)="cancel($event, data.dependent)"
				(submitted)="save($event, data.dependent)"
			>
			</stages-edit-translations>
		</article>
	</ng-container>
</ng-container>
