import { ValidatorFn } from "@angular/forms";
import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class IdsInput extends InputBase<string> {
	constructor(args: CommonArgs<string>) {
		super({
			key: args.key,
			value: args.value,
			type: "ids",
			inputFieldType: "text",
			required: args.required,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});

		super.addValidator(this.validateCommaSeparatedNumbers());
	}

	validateCommaSeparatedNumbers(): ValidatorFn {
		return (control) => {
			if (!control.value) {
				return null;
			}

			const regExp = new RegExp("^\\d+(,d+)*$");
			return regExp.test(control.value)
				? null
				: {
						ids: {
							valid: false,
						},
				  };
		};
	}
}
