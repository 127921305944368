<ng-container *ngIf="!searchEngineAvailable; else show">
	<ul class="list">
		<li class="list-item">
			<div class="label">
				<p translate="search.notavailable"></p>
			</div>
		</li>
	</ul>
</ng-container>

<ng-template #show>
	<ng-container *ngIf="filter(workspaces) as filteredWorkspaces; else wait">
		<ul class="list" stagesClickStopPropagation>
			<li
				class="list-item"
				*ngFor="let workspace of filteredWorkspaces; trackBy: trackBy"
				[ngClass]="{ active: selected === workspace.id }"
			>
				<a class="label clickable" (click)="selectWorkspace(workspace)">
					<div class="text">
						<h3 [innerHTML]="workspace.name | highlightMatches: filterTerm"></h3>
						<h6>{{ workspace.path }}</h6>
					</div>
				</a>
				<button
					[attr.title]="
						!workspace.favorite
							? ('navigation.workspace.addworkspacetofavorites' | translate)
							: ('navigation.workspace.removeworkspacefromfavorites' | translate)
					"
					[attr.aria-label]="
						!workspace.favorite
							? ('navigation.workspace.addworkspacetofavorites' | translate)
							: ('navigation.workspace.removeworkspacefromfavorites' | translate)
					"
					(click)="toggleFavorite(workspace)"
					class="action"
					*ngIf="bookmarkable"
				>
					<i class="ico" [ngClass]="{ 'ico-star-border': !workspace.favorite, 'ico-star': workspace.favorite }"></i>
				</button>
			</li>

			<li class="list-item" *ngIf="filteredWorkspaces.length === 0 && getListIsEmptyProperty() !== undefined">
				<div class="label">
					<p [translate]="getListIsEmptyProperty()!"></p>
				</div>
			</li>
		</ul>
	</ng-container>
</ng-template>

<ng-template #wait>
	<stages-spin class="spin-without-button" size="sm"></stages-spin>
</ng-template>
