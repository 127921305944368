import { asyncScheduler, concat, MonoTypeOperatorFunction, SchedulerLike } from "rxjs";
import { debounceTime, publish, take } from "rxjs/operators";

/**
 * Inspired by https://stackoverflow.com/questions/55130781/debouncetime-only-after-first-value
 *
 * The first amount values are forwarded immediately, while all others are debounced by dueTime milliseconds
 */
export function debounceTimeAfter<T>(
	amount: number,
	dueTime: number,
	scheduler: SchedulerLike = asyncScheduler,
): MonoTypeOperatorFunction<T> {
	return publish((value) => concat(value.pipe(take(amount)), value.pipe(debounceTime(dueTime, scheduler))));
}

/**
 * Inspired by https://stackoverflow.com/questions/55130781/debouncetime-only-after-first-value
 *
 * The first value is forwarded immediately, while all others are debounced by dueTime milliseconds
 */
export function debounceTimeAfterFirst<T>(
	dueTime: number,
	scheduler: SchedulerLike = asyncScheduler,
): MonoTypeOperatorFunction<T> {
	return debounceTimeAfter(1, dueTime, scheduler);
}
