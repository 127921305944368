import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MutexService } from "common/concurrency/mutex.service";
import { Button } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { UploadService } from "core/upload.service";
import { UrlService } from "core/url.service";
import { FilesAuthEmbeddableComponent } from "files/auth/files-auth-embeddable.component";
import { FilesOperation } from "files/files-operation";
import { FileService } from "files/files.service";
import { filetypeValidator } from "files/filetype.directive";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, takeUntil, tap } from "rxjs/operators";

export type UploadCompletedCallback = (newFileUpload: stages.file.FileUpload, status: number) => void;
type FileOperationPropertiesInfo = stages.file.FileOperationPropertiesInfo;

@Component({
	templateUrl: "./files-checkin.component.html",
})
export class FilesCheckinComponent implements OnInit, OnDestroy {
	fileProperties$!: Observable<FileOperationPropertiesInfo>;
	paramMap$!: Observable<ParamMap>;

	formModel!: FormGroup;
	fileUpload: stages.file.FileUpload = {
		attributes: [],
		comment: "",
		state: "",
		uploads: [],
	};

	saved?: boolean;
	errorMessage?: string;
	uploadUrl!: string;
	headerMessageKey!: string;
	newVersionMessageKey!: string;
	filesToCreateOrUpdate: stages.file.Upload[] = [];
	displayNameControls!: FormArray;
	loadingError$ = new Subject<HttpErrorResponse>();
	credentialsValid = true;
	oAuthUrl?: string;
	cmsTypeMessageKey?: string;

	private destroy$ = new Subject<boolean>();
	saveInProgress: boolean = false;

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	@ViewChild("auth")
	authComponent!: FilesAuthEmbeddableComponent;

	@ViewChild("formElement") formElement!: ElementRef;

	buttons: Button[] = [];

	constructor(
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private fileService: FileService,
		private uploadService: UploadService,
		private mutexService: MutexService,
		private formService: FormService,
		private urlService: UrlService,
		private changeDetector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.buttons = [
			{
				class: "button sm cancel",
				translate: "cancel",
				click: () => {
					this.close();
				},
				visible: () => true,
				disabled: () => {
					return false;
				},
			},
		];

		this.initFileProperties();

		this.paramMap$ = this.route.paramMap;
		this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
			this.uploadUrl = this.urlService.build(
				"app/files/{fileId}",
				{
					fileId: paramMap.get("fileId"),
				},
				{
					workspaceId: paramMap.get("workspaceId"),
					pv: paramMap.get("processVersion"),
				},
			);
		});

		this.filesToCreateOrUpdate = this.getFilesToCreateOrUpdate();
		this.displayNameControls = new FormArray([]);
		for (const upload of this.getFilesToCreateOrUpdate()) {
			this.displayNameControls.push(new FormControl(upload.displayName));
		}

		this.formModel = new FormGroup({
			displayName: this.displayNameControls,
			comment: new FormControl(),
			state: new FormControl(),
		});
	}

	private initFileProperties(): void {
		this.fileProperties$ = this.fileService
			.getFileOperationProperties(
				this.route,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("elementType")!,
				this.route.snapshot.paramMap.get("elementId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				this.route.snapshot.paramMap.get("fileId")!,
			)
			.pipe(takeUntil(this.destroy$))
			.pipe(
				catchError((e: unknown) => {
					if (e instanceof HttpErrorResponse) {
						if (e.status === 901) {
							this.cmsTypeMessageKey = e.error.cmsTypeMessageKey;
							this.credentialsValid = false;
							if (e.error.authInfo.authType === "EXTERNAL_LINK") {
								this.oAuthUrl = e.error.authInfo.url;
							}
							if (this.oAuthUrl) {
								localStorage.setItem("cmOpName", FilesOperation.CHECKIN.toString());
								localStorage.setItem("cmFileId", this.route.snapshot.paramMap.get("fileId")!);
								localStorage.setItem("cmElementId", this.route.snapshot.paramMap.get("elementId")!);
								localStorage.setItem("cmElementType", this.route.snapshot.paramMap.get("elementType")!);
							} else {
								this.changeDetector.detectChanges();
								this.authComponent.resultSubject.pipe(takeUntil(this.destroy$)).subscribe((result) => {
									if (result === "cancel") {
										this.dialog.close();
									} else {
										this.credentialsValid = true;
										this.initFileProperties();
									}
								});
							}
						}
						this.loadingError$.next(e);
						return EMPTY;
					}
					throw e;
				}),
			)
			.pipe(
				tap((fileProperties) => {
					if (fileProperties.states && fileProperties.states.length) {
						this.fileUpload.state = fileProperties.states[0].id;
					}
				}),
			);
	}

	ngOnDestroy(): void {
		// Remove temporary uploaded files when the dialog is closed (no matter how)
		this.uploadService.setPendingFiles([]);
		if (!this.saved) {
			this.fileService.removeUploadsWithoutSave(this.route, this.fileUpload);
		}

		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	getUploadCompletedCallback(fileProperties: FileOperationPropertiesInfo): UploadCompletedCallback {
		return (newFileUpload: stages.file.FileUpload, status: number) => {
			if (status === 200) {
				this.deleteExistingUploads();
				newFileUpload.uploads.forEach((newUpload) => {
					this.fileUpload.uploads.push(newUpload);
					this.displayNameControls.push(
						new FormControl(newUpload.displayName, filetypeValidator(this.getFileType(fileProperties))),
					);
				});
				this.filesToCreateOrUpdate = this.getFilesToCreateOrUpdate();
			} else {
				this.errorMessage = this.translateService.instant("files.upload.errormessage", {
					errorCode: status,
				});
			}
			this.uploadService.setPendingFiles([]);
		};
	}

	saveClicked(fileProperties: FileOperationPropertiesInfo): void {
		this.formService.markFormGroupTouched(this.formModel);
		if (this.formModel.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.saved = true;
			this.saveInProgress = true;
			this.mutexService.invoke(
				"addFiles" + this.route.snapshot.paramMap.get("elementType")! + this.route.snapshot.paramMap.get("elementId")!,
				async () => {
					return this.checkin(fileProperties).then(
						() => {
							this.dialog.close();
						},
						(response) => {
							if (response.status === 901) {
								// handled in service
							} else {
								try {
									this.formService.setServerSideErrorsOnFormControls(this.formModel.controls)(response);
								} catch (e: unknown) {
									// error is handled in backend and shown as warning marker in files widget
									console.log(e);
								}
							}
						},
					);
				},
			);
		}
	}

	async checkin(fileProperties: FileOperationPropertiesInfo): Promise<void> {
		this.fileUpload.attributes = fileProperties.attributes.map((attribute) => {
			const attributeFormControl = this.formModel.controls[attribute.typeIdent];
			attribute.value = attributeFormControl ? attributeFormControl.value : null;
			return attribute;
		});

		return this.fileService.checkin(
			this.route,
			this.route.snapshot.paramMap.get("fileId")!,
			this.fileUpload,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
	}

	private deleteExistingUploads(): void {
		this.fileUpload.uploads.forEach((oldUpload: stages.file.Upload) => (oldUpload.deleted = true));
		while (this.displayNameControls.length !== 0) {
			this.displayNameControls.removeAt(0);
		}
	}

	getFilesToCreateOrUpdate(): stages.file.Upload[] {
		return this.fileUpload.uploads.filter((upload: stages.file.Upload) => !upload.deleted);
	}

	getUpdateFilename(i: number): string {
		const upload = this.filesToCreateOrUpdate[i];
		if (!upload || !upload.updateFile || !upload.updateFile.name) {
			return "";
		}
		return upload.updateFile.name;
	}

	getFileType(fileProperties: FileOperationPropertiesInfo): string | null | undefined {
		if (this.sourceIsDatabase(fileProperties)) {
			return null;
		}
		return fileProperties.fileType;
	}

	sourceIsDatabase(fileProperties: stages.file.FileOperationPropertiesInfo): boolean {
		return fileProperties.source === "DATABASE";
	}

	getHeaderMessageKey(fileProperties: stages.file.FileOperationPropertiesInfo): string {
		return this.sourceIsDatabase(fileProperties) ? "files.replace" : "files.checkin";
	}

	getNewVersionMessageKey(fileProperties: stages.file.FileOperationPropertiesInfo): string {
		return this.sourceIsDatabase(fileProperties) ? "files.replace" : "files.add.versions.of";
	}

	saveButtonDisabled(): boolean {
		return this.getFilesToCreateOrUpdate().length !== 1;
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};
}
