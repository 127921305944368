import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "orderAlphabetically" })
export class OrderAlphabeticallyPipe<T> implements PipeTransform {
	transform(array: Array<T> | null, getName: (item: T) => string, alphabetically: boolean): Array<T> | null {
		if (array && alphabetically) {
			array.sort((a: T, b: T) => {
				const nameAToLowerCase = getName(a).toLowerCase();
				const nameBToLowerCase = getName(b).toLowerCase();
				if (nameAToLowerCase < nameBToLowerCase) {
					return -1;
				} else if (nameAToLowerCase > nameBToLowerCase) {
					return 1;
				} else {
					return 0;
				}
			});
		}
		return array;
	}
}
