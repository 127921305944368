import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

/*
Bind to the output events of this directive, (stagesKeyUp) and (stagesKeyDown) instead of (keyup) and (keydown) if you want correct handling of
inputs made via IME (heavily used for non-latin scripts)

The following problems with the "keyup" event should be fixed with this directive:
- A "keyup" event with keyCode === 13 is fired twice when "Return" is pressed (see ST-31182: Two process elements are created in Korean)
- "Return" immediately submits the input instead of confirming a character selection
The trick is to ignore the first "keyup" event immediately after a "compositionend" event

The following problems with the "keydown" event should be fixed with this directive:
- A "keydown" event with key === "Tab" is fired twice when "Tab" is pressed.
The trick is to ignore "keydown" events between "compositionstart" and "compositionend" events

See also: https://www.stum.de/2016/06/24/handling-ime-events-in-javascript/
(the "keydown" handler from the example must not be set today!)

*/
@Directive({
	selector: "[stagesKeyUp],[stagesKeyDown]",
})
export class KeyUpDirective {
	private isComposing = false;

	private hasCompositionJustEnded = false;

	@Output("stagesKeyUp")
	readonly keyUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

	@Output("stagesKeyDown")
	readonly keyDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

	@HostListener("keyup", ["$event"]) onKeyUp(event: KeyboardEvent): void {
		if (this.isComposing || this.hasCompositionJustEnded) {
			this.hasCompositionJustEnded = false;
			return;
		}

		this.keyUp.emit(event);
	}

	@HostListener("keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
		if (this.isComposing) {
			return;
		}

		this.keyDown.emit(event);
	}

	@HostListener("compositionstart") onCompositionStart(): void {
		this.isComposing = true;
	}

	@HostListener("compositionend") onCompositionEnd(): void {
		this.isComposing = false;
		this.hasCompositionJustEnded = true;
	}
}
