<div class="content preview" *ngIf="preview$ | async as preview">
	<stages-list-of-lists>
		<stages-collapsible-list
			*ngIf="preview.complianceConflicts.length > 0"
			[ngClass]="'compliance-conflicts'"
			[chevronClass]="'chevron'"
			[header]="'process.element.tailor.complianceconflicts.title' | translate"
			[listItemTemplate]="listConflictsTemplate"
			[listClass]="'dense conflict'"
			[items]="preview.complianceConflicts"
			[expanded]="true"
		>
		</stages-collapsible-list>
		<stages-collapsible-list
			[showEmpty]="showEmpty(preview)"
			[header]="'process.element.tailor.addedelements' | translate"
			[listItemTemplate]="listItemTemplate"
			[listClass]="'dense'"
			[items]="preview.addedElements"
			[expanded]="false"
		>
		</stages-collapsible-list>
		<stages-collapsible-list
			[showEmpty]="showEmpty(preview)"
			[header]="'process.element.tailor.removedelements' | translate"
			[listItemTemplate]="listItemTemplate"
			[listClass]="'dense'"
			[items]="preview.removedElements"
			[expanded]="false"
		>
		</stages-collapsible-list>
	</stages-list-of-lists>
</div>

<ng-template #listItemTemplate let-item>
	<div class="label" *ngIf="!item.dependent">
		<i class="ico" [ngClass]="getIconClasses(item)"></i>
		<h4>{{ item.label }}</h4>
	</div>
	<div class="label" *ngIf="item.dependent">
		<i class="ico" [ngClass]="getIconClasses(item.dependentElementContainer)"></i>
		<h4>{{ item.dependentElementContainer.label }}</h4>
		<i class="ico ico-tag" [ngClass]="getIconClasses(item)"></i>
		<h4>{{ item.label }}</h4>
	</div>
</ng-template>

<ng-template #listConflictsTemplate let-conflict>
	<div class="compliance-conflict">
		<div class="label">
			<i class="ico" [ngClass]="getIconClasses(conflict)"></i>
			<h4>{{ conflict.label }}</h4>
		</div>
		<div class="workspace">
			<p>{{ conflict.workspaceName }}</p>
		</div>
	</div>
</ng-template>
