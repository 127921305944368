<ng-content *ngIf="!editable"></ng-content>
<form *ngIf="editable" #form="ngForm" class="form-add-item">
	<div class="text">
		<h4>
			<input
				#input="ngModel"
				[(ngModel)]="value"
				id="value"
				name="value"
				(stagesKeyUp)="onKeyUp($event)"
				(blur)="blur()"
				(ngModelChange)="inputChanged()"
				required
				[stagesMaxLength]="$any(maxLength)"
				[stagesReserved]="reserved!"
				pattern="{{ pattern || '.*' }}"
			/>
		</h4>
		<div class="info">
			<div class="errors" *ngIf="(input.errors || serverSideValidationErrors.exists) && (input.dirty || input.touched)">
				<div *ngIf="input.errors && input.errors.required" class="error" [translate]="'input.required'"></div>
				<div
					*ngIf="input.errors && input.errors.max"
					class="error"
					[translate]="'input.maxLength'"
					[translateParams]="{ length: maxLength }"
				></div>
				<div *ngIf="input.errors && input.errors.pattern" class="error" [translate]="patternMessageKey!"></div>
				<div *ngIf="serverSideValidationErrors.exists" class="error" [translate]="existsMessageKey!"></div>
				<div *ngIf="input.errors && input.errors.reserved" class="error" [translate]="reservedMessageKey!"></div>
			</div>
			<div class="max-length">{{ input.value?.length || 0 }}/{{ maxLength }}</div>
		</div>
	</div>
</form>
