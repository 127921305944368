import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	Renderer2,
	TemplateRef,
	TrackByFunction,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataViewComponent, Mode } from "common/data/data-view.component";
import { NotificationService } from "core/notification.service";
import { PreferencesService } from "core/preferences.service";
import { SelectionStoreService } from "common/selection/state/selection-store.service";
import { SelectionRepository } from "common/selection/state/selection.repository";

@Component({
	selector: "stages-grouped-data-view",
	templateUrl: "./grouped-data-view.component.html",
	styleUrls: ["./grouped-data-view.component.scss"],
})
export class GroupedDataViewComponent<T> extends DataViewComponent<T> {
	collapsibleGroupSettings?: StringToBoolean;

	@Input()
	listItemTemplate?: TemplateRef<unknown>;

	@Input()
	groupTemplate?: TemplateRef<unknown>;

	@Input()
	groupMenuItemsTemplate?: TemplateRef<unknown>;

	@Input()
	tableItemTemplate?: TemplateRef<unknown>;

	@Input()
	listStyles = "grouped-list";

	@Input()
	collapsibleGroupName?: string;

	@Input()
	browseItemButtonActive: boolean = false;

	@Output()
	readonly closeBrowserButtonPressed = new EventEmitter<T>();

	@Output()
	readonly openBrowserButtonPressed = new EventEmitter<T>();

	@Input()
	groupDisabledFn!: (item: T, data: PagedResult<T>, mode: Mode) => boolean;

	@Input()
	groupInactiveFn: (item: T) => boolean = () => false;

	constructor(
		private route: ActivatedRoute,
		chRef: ChangeDetectorRef,
		notificationService: NotificationService,
		renderer: Renderer2,
		private preferencesService: PreferencesService,
		selectionStoreService: SelectionStoreService,
		selectionRepository: SelectionRepository,
	) {
		super(chRef, notificationService, renderer, selectionStoreService, selectionRepository);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32217: Enable strict template type checking and suppress or fix all existing warnings.
	groupTrackBy: TrackByFunction<any> = (_index, group) => {
		return this.groupIdFn(group);
	};

	// collapsible settings
	toggleGroupExpanded(groupId: string): void {
		const groupIdentifier = this.route.snapshot.paramMap.get("workspaceId")! + groupId;
		this.collapsibleGroupSettings![groupIdentifier] = !this.collapsibleGroupSettings![groupIdentifier];
		this.preferencesService.setPreference(this.collapsibleGroupName!, this.collapsibleGroupSettings!);
	}

	isExpanded(groupId: string): boolean {
		if (this.collapsibleGroupName) {
			const groupIdentifier = this.route.snapshot.paramMap.get("workspaceId")! + groupId;
			if (!this.collapsibleGroupSettings) {
				this.preferencesService
					.getPreference(this.collapsibleGroupName, {})
					.then((collapseSettings: StringToBoolean) => {
						this.collapsibleGroupSettings = collapseSettings;
						return this.collapsibleGroupSettings[groupIdentifier];
					});
			}
			if (this.collapsibleGroupSettings) {
				if (!this.collapsibleGroupSettings.hasOwnProperty(groupIdentifier)) {
					this.collapsibleGroupSettings[groupIdentifier] = true;
				}
				return this.collapsibleGroupSettings[groupIdentifier];
			}
		}

		return true;
	}

	openButtonPressed(group: T): void {
		this.openBrowserButtonPressed.emit(group);
	}

	closeButtonPressed(group: T): void {
		this.closeBrowserButtonPressed.emit(group);
	}
}
