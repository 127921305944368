import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "common/util.service";
import { LanguageService } from "core/translate/language.service";
import flatpickr from "flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import { Options } from "flatpickr/dist/types/options";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-date-time-picker",
	templateUrl: "./date-time-picker.component.html",
	styleUrls: ["date-time-picker.component.scss"],
})
export class DateTimePickerComponent implements AfterViewInit, OnDestroy, OnInit, ControlValueAccessor {
	private destroy$ = new Subject<boolean>();

	flatpickrInstance?: Instance;
	private _setDate?: Date;
	private _tabindex = 0;
	private _isDisabled: boolean = false;

	inputId: string = "dateTimePickerInput";
	isMobileDevice: boolean = false;
	isIE: boolean = false;

	private defaultFlatpickrOptions: Options = {
		clickOpens: true,
		enableTime: true,
		// eslint-disable-next-line camelcase -- 3rd party api
		time_24hr: true,
		dateFormat: "d.m.Y H:i",
		onChange: (selectedDates: Date[]) => {
			if (selectedDates.length === 0) {
				this.writeValue(null);
			} else {
				this.writeValue(selectedDates);
			}
		},
		static: !this.isMobileDevice,
	};

	@ViewChild("flatpickr", {
		static: true,
	})
	flatpickrElement!: ElementRef;

	@Input()
	get setDate(): Date | undefined {
		return this._setDate;
	}

	// value can either be of type string (Unix timestamp) or Date
	set setDate(value: Date | string | undefined) {
		this._setDate = typeof value === "string" ? flatpickr.parseDate(value, "U") : value;
	}

	@Input()
	get tabindex(): number {
		return this._tabindex;
	}

	set tabindex(ti: number) {
		this._tabindex = Number(ti);
	}

	@Input()
	initialHoursOffset: number = 0;

	@Input()
	placeholder: string = "";

	@Input()
	get isDateTimeDisabled(): boolean {
		return this._isDisabled;
	}

	set isDateTimeDisabled(value: boolean) {
		this._isDisabled = value;
	}

	constructor(
		private languageService: LanguageService,
		private translateService: TranslateService,
		private utilService: UtilService,
		ngControl: NgControl,
	) {
		this.isMobileDevice = this.utilService.deviceIsIOS();
		this.isIE = this.utilService.browserIsIE();
		ngControl.valueAccessor = this;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- See ControlValueAccessor interface.
	propagateChange: any = () => {};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- See ControlValueAccessor interface.
	propagateTouch: any = () => {};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- See ControlValueAccessor interface.
	writeValue(obj: any): void {
		this.propagateChange(obj);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- See ControlValueAccessor interface.
	registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- See ControlValueAccessor interface.
	registerOnTouched(fn: any): void {
		this.propagateTouch = fn;
	}

	onFocus(event: FocusEvent): void {
		if (!this.setDate) {
			const dateForNextFullHour = this.getDateForNextFullHour();
			this.flatpickrInstance!.setDate(dateForNextFullHour, false, "U");
			this.writeValue([dateForNextFullHour]);
		}
		this.propagateTouch(event);
	}

	private getDateTimeFormat(): string {
		return this.translateService.instant("date.datetimepicker.pattern");
	}

	private use24HourFormat(): boolean {
		return this.translateService.instant("date.datetimepicker.24_hour_format").toLowerCase() === "true";
	}

	private setLanguageDependentConfig(): void {
		this.flatpickrInstance!.set("dateFormat", this.getDateTimeFormat());
		this.flatpickrInstance!.set("time_24hr", this.use24HourFormat());
	}

	private setLanguageProperties(): void {
		const weekdaysShorthand = this.translateService.instant("date.weekdays.shorthand").split(" ");
		const weekdaysLonghand = this.translateService.instant("date.weekdays.longhand").split(" ");
		const monthsLonghand = this.translateService.instant("date.months.longhand").split(" ");
		this.flatpickrInstance!.set({
			locale: {
				firstDayOfWeek: 1,
				weekdays: { shorthand: weekdaysShorthand, longhand: weekdaysLonghand },
				months: { shorthand: monthsLonghand, longhand: monthsLonghand },
			},
		});
	}

	private getDateForNextFullHour(): Date {
		const newDateTimeInFuture: Date = new Date();
		if (this.initialHoursOffset > 0) {
			newDateTimeInFuture.setHours(newDateTimeInFuture.getHours() + this.initialHoursOffset, 0, 0, 0);
		}

		return newDateTimeInFuture;
	}

	ngOnInit(): void {
		if (this.setDate) {
			this.writeValue([this.setDate]);
		}
	}

	ngAfterViewInit(): void {
		if (this.flatpickrElement.nativeElement) {
			this.flatpickrInstance = flatpickr(this.flatpickrElement.nativeElement, {
				...this.defaultFlatpickrOptions,
				dateFormat: this.getDateTimeFormat(),
				// eslint-disable-next-line camelcase -- 3rd party api
				time_24hr: this.use24HourFormat(),
			});
			const calendarContainer = this.flatpickrInstance.calendarContainer;
			if (this.flatpickrInstance.calendarContainer) {
				calendarContainer.classList.add("stages-date-time-picker");
				if (this.isIE) {
					calendarContainer.classList.add("isIE");
				}
			}
			this.setLanguageProperties();
		}

		if (this.setDate) {
			this.flatpickrInstance!.setDate(this.setDate);
		}

		this.languageService.langChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.setLanguageProperties();
			this.setLanguageDependentConfig();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
