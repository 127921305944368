<div class="html-template-plugin slide-container" tabindex="1">
	<div class="two-slot-slide-panels" [@slide]="viewState" (@slide.done)="onSlideDone()">
		<div class="slide-panel">
			<header>
				<div class="current-workspace">
					<a
						class="title"
						[ngClass]="mode === 'INTERNAL' ? 'clickable' : ''"
						*ngIf="mode !== 'WORKSPACE'"
						(click)="toggleBrowseWorkspaceMode()"
					>
						<i class="ico ico-workspace-interface"></i>
						<h3 class="shorten" *ngIf="browseWorkspace">{{ browseWorkspace.name }}</h3>
					</a>
					<a class="title clickable" *ngIf="mode === 'WORKSPACE'" (click)="toggleBrowseWorkspaceMode()">
						<i class="ico ico-arrow-back"></i>
						<h3 class="shorten" translate="editor.link.element.selection"></h3>
					</a>
				</div>
				<h3 class="plugin-title" *ngIf="mode !== 'WORKSPACE'" translate="editor.htmlTemplate.insert"></h3>
			</header>
			<stages-workspace-selection
				*ngIf="mode === 'WORKSPACE'"
				(changeTab)="onChangeTab($any($event))"
				(selectWorkspace)="onSelectWorkspace($event)"
				filterBy="PROCESS_EXISTS"
			></stages-workspace-selection>
			<article class="content" *ngIf="mode === 'INTERNAL' && folder">
				<nav>
					<div class="button-line">
						<button
							[attr.title]="'up' | translate"
							[attr.aria-label]="'up' | translate"
							class="button clickable"
							*ngIf="folder.parent"
							(click)="navigateToElement(folder.parent)"
						>
							<i class="ico ico-arrow-up"></i>
						</button>
					</div>
					<ul class="list">
						<li ngClass="list-item selectable folder" *ngIf="!!folder">
							<div class="label">
								<div class="text">
									<stages-description-editor-link-item
										class="link-item"
										[item]="folder"
										(selectItem)="insertLinkTo(folder)"
										[disabled]="folder.type.subtypeIdent !== 'htmlTemplate'"
									></stages-description-editor-link-item>
								</div>
								<button
									[attr.title]="'editor.preview' | translate"
									[attr.aria-label]="'editor.preview' | translate"
									class="action flap"
									tabindex="-1"
									*ngIf="folder.type.subtypeIdent === 'htmlTemplate'"
									(click)="toggleDetails(folder)"
								>
									<i class="ico ico-editor-preview"></i>
								</button>
							</div>
						</li>

						<li
							ngClass="list-item selectable"
							*ngFor="let element of getVisibleEntries(folder.children); trackBy: getUniqueId"
						>
							<div class="label">
								<div class="text">
									<stages-description-editor-link-item
										class="link-item"
										[item]="element"
										(selectItem)="clickBrowseElement($event)"
										[childrenCount]="element.children ? element.children.length : 0"
										[ngClass]="{ clickable: !!element.children && element.children.length }"
									></stages-description-editor-link-item>
								</div>
								<button
									[attr.title]="'editor.preview' | translate"
									[attr.aria-label]="'editor.preview' | translate"
									class="action flap"
									tabindex="-1"
									*ngIf="element.type.subtypeIdent === 'htmlTemplate'"
									(click)="toggleDetails(element)"
								>
									<i class="ico ico-editor-preview"></i>
								</button>
							</div>
						</li>

						<li class="none" *ngIf="!folder.children || folder.children.length == 0">
							<div class="label">
								<i class="ico"></i>
								<p class="empty" translate="editor.htmlTemplate.none"></p>
							</div>
						</li>
						<ng-container *ngIf="hasOmmittedEntries(folder.children)">
							<div class="label ommitted-items">
								<div class="text fullsize dark">
									<h3 translate="list.items.ommitted"></h3>
								</div>
							</div>
						</ng-container>
					</ul>
				</nav>
			</article>
		</div>
		<div class="slide-panel">
			<header>
				<div class="current-workspace">
					<a class="title clickable" (click)="toggleDetails()">
						<i class="ico ico-arrow-back"></i>
						<h3 class="shorten" translate="editor.link.element.selection"></h3>
					</a>
				</div>
				<h3 class="plugin-title">{{ previewTitle }}</h3>
			</header>
			<article class="preview-container">
				<nav>
					<div class="preview" *ngIf="previewHtml" [innerHtml]="previewHtml | safe: 'html'"></div>
					<ul class="list" *ngIf="!previewHtml">
						<li class="none">
							<p class="empty" translate="editor.htmlTemplate.empty"></p>
						</li>
					</ul>
					<div class="buttons-bottom" *ngIf="previewHtml">
						<div class="spacer"></div>
						<button class="sm save" translate="insert" (click)="onInsert(previewHtml)"></button>
					</div>
				</nav>
			</article>
		</div>
	</div>
</div>
