import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { Button } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { FileService } from "files/files.service";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";

type Attribute = stages.core.Attribute;
type AttributeType = stages.core.AttributeType;

@Component({
	selector: "stages-files-checkout",
	templateUrl: "./files-checkout.component.html",
})
export class FilesCheckoutComponent implements OnInit, OnDestroy {
	checkoutDataAndInfo$!: Observable<DataAndInfo<Attribute[], AttributeType[]>>;
	formModel: FormGroup;

	loadingError$ = new Subject<HttpErrorResponse>();
	private destroy$ = new Subject<boolean>();

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	@ViewChild("formElement") formElement!: ElementRef;

	buttons: Button[] = [];

	constructor(
		private fileService: FileService,
		private formService: FormService,
		private mutexService: MutexService,
		private route: ActivatedRoute,
	) {
		this.formModel = new FormGroup({});
	}

	ngOnInit(): void {
		this.buttons = [
			{
				class: "button sm cancel",
				translate: "cancel",
				click: () => {
					this.close();
				},
				visible: () => true,
				disabled: () => {
					return false;
				},
			},
		];

		this.checkoutDataAndInfo$ = this.fileService
			.getCheckoutDataAndInfo(
				this.route.snapshot.paramMap.get("fileId")!,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
			)
			.pipe(takeUntil(this.destroy$))
			.pipe(
				catchError((e: unknown) => {
					if (e instanceof HttpErrorResponse) {
						this.loadingError$.next(e);
						return EMPTY;
					}
					throw e;
				}),
			);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	get actionMessageProperty(): string {
		return this.route.snapshot.paramMap.get("download") === "true" ? "checkout" : "lock";
	}

	submit(attributes: Attribute[]): void {
		this.formService.markFormGroupTouched(this.formModel);
		if (this.formModel.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.mutexService.invoke("checkout" + this.route.snapshot.paramMap.get("fileId")!, async () => {
				const outgoingAttributes = attributes.map((attribute) => {
					const attributeFormControl = this.formModel.controls[attribute.typeIdent];
					attribute.value = attributeFormControl ? attributeFormControl.value : null;
					return attribute;
				});

				return this.fileService
					.doLockAndDownload(
						this.route,
						this.route.snapshot.paramMap.get("fileId")!,
						outgoingAttributes,
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
						false,
					)
					.then(() => {
						this.dialog.close();
						if (this.route.snapshot.paramMap.get("download") === "true") {
							this.fileService.downloadAlreadyAuthenticated(
								this.route,
								this.route.snapshot.paramMap.get("fileId")!,
								this.route.snapshot.paramMap.get("workspaceId")!,
								this.route.snapshot.paramMap.get("processVersion")!,
								null,
								false,
							);
						}
					}, this.formService.setServerSideErrorsOnFormControls(this.formModel.controls));
			});
		}
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};
}
