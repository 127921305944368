<div *ngIf="dialog.isVisible" class="link-plugin slide-container" tabindex="1" (stagesKeyUp)="onKeyUp($event)">
	<div class="two-slot-slide-panels" [@slide]="mode" (@slide.done)="onSlideDone()">
		<div class="slide-panel">
			<header>
				<div class="current-workspace">
					<a
						class="title"
						[ngClass]="mode === 'INTERNAL' ? 'clickable' : ''"
						*ngIf="mode !== 'WORKSPACE'"
						(click)="toggleBrowseWorkspaceMode()"
					>
						<i class="ico ico-workspace-interface"></i>
						<h3 class="shorten" *ngIf="browseWorkspace">{{ browseWorkspace.name }}</h3>
					</a>
					<a class="title clickable" *ngIf="mode === 'WORKSPACE'" (click)="toggleBrowseWorkspaceMode()">
						<i class="ico ico-arrow-back"></i>
						<h3 class="shorten" translate="editor.link.element.selection"></h3>
					</a>
				</div>
				<div class="insert-link-header" *ngIf="mode !== 'WORKSPACE'">
					<div class="tabs" role="tablist">
						<h5
							class="tab"
							role="tab"
							(click)="mode = 'INTERNAL'"
							[class.is-active]="mode === 'INTERNAL'"
							translate="editor.link.internal"
						></h5>
						<h5
							class="tab"
							role="tab"
							(click)="mode = 'EXTERNAL'"
							[class.is-active]="mode === 'EXTERNAL'"
							translate="editor.link.external"
						></h5>
					</div>
				</div>
				<stages-description-editor-link-search
					*ngIf="mode === 'INTERNAL'"
					(search)="onSearch($event)"
					[workspace]="$any(browseWorkspace)"
					[linkableTypes]="linkableTypes!"
				></stages-description-editor-link-search>
			</header>
			<stages-workspace-selection
				*ngIf="mode === 'WORKSPACE'"
				(changeTab)="onChangeTab($any($event))"
				(selectWorkspace)="onSelectWorkspace($event)"
				filterBy="PROCESS_EXISTS"
			></stages-workspace-selection>
			<stages-external-link
				*ngIf="mode === 'EXTERNAL'"
				class="link-details"
				[selectedReference]="externalReference"
				[preferredDisplayText]="preferredDisplayText"
				(insert)="onInsert($event)"
			></stages-external-link>
			<article class="content internal-targets" *ngIf="mode === 'INTERNAL'">
				<nav>
					<div *ngIf="isResolvedTextualReferenceAvailable && !detailsExpanded && !selectedReferenceExist">
						<p class="error editor-message" role="alert">
							{{ "plugin.nonexisting.textualreference" | translate }}: {{ dialog.getSelectedReference().getText() }}
						</p>
					</div>

					<div class="search-results" *ngIf="searchTerm && searchTerm.length && searchResults">
						<h4
							*ngIf="searchTerm.length && searchEngineAvailable && searchResults.length === 0"
							translate="search.flyout.no.results"
							[translateParams]="{ term: searchTerm }"
						></h4>
						<h4 *ngIf="searchTerm.length && !searchEngineAvailable" translate="search.notavailable"></h4>
						<ul class="list">
							<li
								ngClass="list-item selectable"
								*ngFor="let resultItem of $any(searchResults); trackBy: getUniqueId"
								[class.active]="isElementSelected(resultItem)"
							>
								<div class="label">
									<div class="text">
										<stages-description-editor-link-item
											class="link-item"
											[item]="resultItem"
											(selectItem)="insertLinkTo(resultItem)"
										></stages-description-editor-link-item>
									</div>
									<stages-button-expand-collapse
										[classes]="'action menu-button'"
										[isCollapsed]="!(isElementSelected(resultItem) && detailsExpanded)"
										(pressedButton)="toggleDetails(resultItem)"
									></stages-button-expand-collapse>
									<button
										[attr.title]="'editor.showAnchors' | translate"
										[attr.aria-label]="'editor.showAnchors' | translate"
										class="action flap"
										tabindex="-1"
										(click)="toggleAnchors(resultItem)"
									>
										<i class="ico ico-editor-preview"></i>
									</button>
								</div>
								<stages-description-editor-link-details
									class="link-details"
									*ngIf="isElementSelected(resultItem) && detailsExpanded"
									[selectedReference]="selectedReference"
									[preferredDisplayText]="preferredDisplayText"
									(insert)="onInsert()"
								></stages-description-editor-link-details>
							</li>
						</ul>
					</div>

					<div class="button-line" *ngIf="folder && (!searchTerm || !searchTerm.length)">
						<button
							[attr.title]="'editor.link.goToRoot' | translate"
							[attr.aria-label]="'editor.link.goToRoot' | translate"
							class="button clickable"
							*ngIf="folder.parent"
							(click)="navigateToStart()"
						>
							<i class="ico ico-process"></i>
						</button>
						<button
							[attr.title]="'up' | translate"
							[attr.aria-label]="'up' | translate"
							class="button clickable"
							*ngIf="folder.parent"
							(click)="navigateToElement(folder.parent)"
						>
							<i class="ico ico-arrow-up"></i>
						</button>
					</div>
					<ul class="list" *ngIf="!searchTerm || !searchTerm.length">
						<ng-container *ngIf="folder">
							<li ngClass="list-item selectable folder" [class.active]="isElementSelected(folder)">
								<div class="label">
									<div class="text">
										<stages-description-editor-link-item
											class="link-item"
											[item]="folder"
											(selectItem)="insertLinkTo($event)"
										></stages-description-editor-link-item>
									</div>
									<stages-button-expand-collapse
										[classes]="'action'"
										[isCollapsed]="!(isElementSelected(folder) && detailsExpanded)"
										(pressedButton)="toggleDetails(folder)"
									></stages-button-expand-collapse>
									<button
										[attr.title]="'editor.showAnchors' | translate"
										[attr.aria-label]="'editor.showAnchors' | translate"
										class="action flap"
										tabindex="-1"
										(click)="toggleAnchors(folder)"
									>
										<i class="ico ico-editor-preview"></i>
									</button>
								</div>
								<stages-description-editor-link-details
									class="link-details"
									*ngIf="isElementSelected(folder) && detailsExpanded"
									[selectedReference]="selectedReference"
									[preferredDisplayText]="preferredDisplayText"
									(insert)="onInsert($event)"
								></stages-description-editor-link-details>
							</li>

							<li
								ngClass="list-item selectable internal-target"
								*ngFor="let element of getVisibleEntries(folder.children); trackBy: getUniqueId"
								[class.active]="isElementSelected(element)"
							>
								<div class="label">
									<div class="text">
										<stages-description-editor-link-item
											class="link-item"
											[item]="element"
											(selectItem)="clickBrowseElement($event)"
											[childrenCount]="element.children ? element.children.length : 0"
											[ngClass]="{ clickable: !!element.children && element.children.length }"
										></stages-description-editor-link-item>
									</div>
									<stages-button-expand-collapse
										[classes]="'action menu-button'"
										[isCollapsed]="!(isElementSelected(folder) && detailsExpanded)"
										(pressedButton)="toggleDetails(element)"
									></stages-button-expand-collapse>
									<button
										[attr.title]="'editor.showAnchors' | translate"
										[attr.aria-label]="'editor.showAnchors' | translate"
										class="action flap"
										tabindex="-1"
										(click)="toggleAnchors(element)"
									>
										<i class="ico ico-editor-preview"></i>
									</button>
								</div>
								<stages-description-editor-link-details
									class="link-details"
									*ngIf="isElementSelected(element) && detailsExpanded"
									[selectedReference]="selectedReference"
									[preferredDisplayText]="preferredDisplayText"
									(insert)="onInsert($event)"
								></stages-description-editor-link-details>
							</li>
							<ng-container *ngIf="hasOmmittedEntries(folder.children)">
								<div class="label ommitted-items">
									<div class="text fullsize dark">
										<h3 translate="list.items.ommitted"></h3>
										<h6 [translate]="'list.items.ommitted.search.dialog'"></h6>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ul>
				</nav>
			</article>
		</div>
		<div class="slide-panel">
			<stages-description-editor-link-anchors
				[selectedAnchor]="selectedAnchor"
				[previewTitle]="previewTitle"
				[previewHtml]="previewHtml"
				(selectAnchor)="selectAnchor($event)"
				(closeAnchors)="toggleAnchors()"
				(insertAnchor)="onInsert()"
			>
			</stages-description-editor-link-anchors>
		</div>
	</div>
</div>
