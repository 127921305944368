import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class KeyboardService {
	isKeyWithNoModifiers(event: KeyboardEvent, key: string): boolean {
		return event.key === key && !event.shiftKey && !event.altKey && !event.ctrlKey;
	}

	isReturnKeyPressed(event: KeyboardEvent): boolean {
		return (
			event.key === "Enter" ||
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			event.keyCode === 13 ||
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			event.which === 13
		);
	}

	isEscapeKeyPressed(event: KeyboardEvent): boolean {
		return (
			event.key === "Escape" /* not working in Internet Explorer */ ||
			event.key === "Esc" /* only working in Internet Explorer */ ||
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			event.keyCode === 27 ||
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			event.which === 27
		);
	}

	isDownKeyPressed(event: KeyboardEvent): boolean {
		return (
			event.key === "ArrowDown" || event.key === "Down" // IE 11
		);
	}

	isUpKeyPressed(event: KeyboardEvent): boolean {
		return (
			event.key === "ArrowUp" || event.key === "Up" // IE 11
		);
	}

	isLeftKeyPressed(event: KeyboardEvent): boolean {
		return (
			event.key === "ArrowLeft" || event.key === "Left" // IE 11
		);
	}

	isRightKeyPressed(event: KeyboardEvent): boolean {
		return (
			event.key === "ArrowRight" || event.key === "Right" // IE 11
		);
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
	isNavigationKey(event: KeyboardEvent): boolean {
		return (
			event.key === "ArrowLeft" ||
			event.key === "ArrowRight" ||
			event.key === "ArrowUp" ||
			event.key === "ArrowDown" ||
			event.key === "Left" || // IE 11
			event.key === "Right" || // IE 11
			event.key === "Up" || // IE 11
			event.key === "Down" || // IE 11
			event.key === "Home" ||
			event.key === "End" ||
			event.key === "PageUp" ||
			event.key === "PageDown"
		);
	}
}
