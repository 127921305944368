import { Injectable } from "@angular/core";
import { Events } from "core/events";
import { Observable, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";

export interface BroadcastArgs {
	translate: string;
	translateParams?: StringToUnknown;
}

export interface BroadcastEvent {
	eventType: Events;
	data?: BroadcastArgs;
}

/*
    TODO
    The generic BroadcastService is currently only used for MESSAGE events. All other event types are now replaced by RxJS.
    Perhaps this BroadcastService should be simplified (e.g. delete enum "Events") and renamed or combined with SnackbarService.
*/
@Injectable({ providedIn: "root" })
export class BroadcastService {
	private events = new Subject<BroadcastEvent>();

	broadcast(eventType: Events, data?: BroadcastArgs): void {
		this.events.next({
			eventType: eventType,
			data: data,
		});
	}

	on(eventType: Events): Observable<BroadcastArgs | undefined> {
		return this.events.pipe(
			filter((e) => e.eventType === eventType),
			map((e) => e.data),
		);
	}
}
