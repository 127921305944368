import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
} from "@angular/core";
import { SearchErrorWithResults, SearchQuery } from "common/autoComplete/search-query";
import { ItemEditor } from "common/list/item-editor";
import { UtilService } from "common/util.service";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";
import { animate, style, transition, trigger } from "@angular/animations";
import { KeyboardService } from "common/keyboard.service";

const DEBOUNCE_TIME = 300;

//because of korean 2 char words
const MIN_SEARCH_TERM_LENGTH = 1;

const MAX_SEARCH_TERM_LENGTH = Number.MAX_SAFE_INTEGER;

export class SuperordinatedResult {
	id!: string;
	name!: string;
	subordinatedTypeName!: string;
}

@Component({
	selector: "stages-auto-complete",
	templateUrl: "auto-complete.component.html",
	styleUrls: ["./auto-complete.component.scss"],
	animations: [
		trigger("slide", [
			transition(":enter", [
				style({ transform: "translateX({{enterFrom}}%)" }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ transform: "translateX({{enterTo}}%)" })),
			]),
			transition(":leave", [
				style({ transform: "translateX({{leaveFrom}}%)" }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ transform: "translateX({{leaveTo}}%)" })),
			]),
		]),
	],
})
export class AutoCompleteComponent<T> extends ItemEditor<T> implements OnInit, OnDestroy, AfterViewInit {
	readonly BACK_BUTTON_INDEX = -1;

	@Input()
	query!: SearchQuery<T>;

	@Input() maxLength = MAX_SEARCH_TERM_LENGTH;

	@Input() searchResultTemplate?: TemplateRef<unknown>;

	@Input() searchResultSeparatorTemplate?: TemplateRef<unknown>;

	@Input() focusOnInit: boolean = false;

	@Input() browseButtonActive: boolean = false;

	@Input() isDense: boolean = false;

	@Input()
	dropDownClass?: string;

	@Input()
	inputIconClasses?: string[];

	@Input() set subordinatedElementsMode(isSubordinatedElementsMode: boolean) {
		if (!this.loading && this._subordinatedElementsMode !== isSubordinatedElementsMode) {
			this._subordinatedElementsMode = isSubordinatedElementsMode;
			if (isSubordinatedElementsMode) {
				this.dropDownOpen = true;
			}
			// eslint-disable-next-line promise/prefer-await-to-then -- await is not allowed in setter
			this.onSearchInput().then(() => {
				this.focus();
			});
		}
	}

	@Output()
	readonly closeButtonPressed = new EventEmitter<void>();

	@Output()
	readonly openBrowserButtonPressed = new EventEmitter<void>();

	@Output()
	readonly backButtonPressed = new EventEmitter<void>();

	@ViewChild("autoCompleteInput", { read: ElementRef, static: true })
	input!: ElementRef;

	params = {
		enterFrom: "0",
		enterTo: "0",
		leaveFrom: "0",
		leaveTo: "0",
	};

	term: string = "";

	oldTerm: string = "";

	results: T[] = [];

	subordinatedResults?: T[] = undefined;

	private destroy$: Subject<boolean> = new Subject();

	private mouseDown = false;

	searchEngineAvailable = true;

	dropDownOpen: boolean = false;

	index: number = -1;

	isIE: boolean = false;

	_subordinatedElementsMode: boolean = false;

	loading: boolean = false;

	constructor(
		utilService: UtilService,
		private changeDetector: ChangeDetectorRef,
		private keyboardService: KeyboardService,
	) {
		super();
		this.isIE = utilService.browserIsIE();
	}

	ngOnInit(): void {
		const allKeys = fromEvent<KeyboardEvent>(this.input.nativeElement, "keyup");

		allKeys
			.pipe(
				filter((e) => !this.isSpecialKey(e)),
				debounceTime(DEBOUNCE_TIME),
				takeUntil(this.destroy$),
			)
			// eslint-disable-next-line rxjs/no-async-subscribe -- we want to subscribe with async function
			.subscribe(async () => this.onSearchInput());

		allKeys
			.pipe(
				filter((e) => this.isSpecialKey(e)),
				takeUntil(this.destroy$),
			)
			.subscribe((e) => this.onSpecialKey(e));
	}

	ngAfterViewInit(): void {
		if (this.focusOnInit) {
			this.focus();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
	}

	focus(): void {
		this.input.nativeElement.focus();
	}

	cancel(): void {
		this.cancelled.emit();
	}

	async onSearchInput(): Promise<void> {
		if (this._subordinatedElementsMode || (this.term && this.term.length >= MIN_SEARCH_TERM_LENGTH)) {
			this.loading = true;
			try {
				await this.executeSearchQuery(this._subordinatedElementsMode);
				this.searchEngineAvailable = true;
			} catch (e: unknown) {
				this.passErrorToResults(e, this._subordinatedElementsMode);
				this.searchEngineAvailable = false;
			} finally {
				this.loading = false;
				this.openOrCloseDropDown();
			}
		} else {
			this.index = -1;
		}
	}

	async executeSearchQuery(subordinated: boolean): Promise<void> {
		if (subordinated) {
			this.subordinatedResults = await this.query.run(this.term);
		} else {
			this.results = await this.query.run(this.term);
		}
	}

	passErrorToResults(error: unknown, subordinated: boolean): void {
		if (subordinated) {
			this.subordinatedResults = error instanceof SearchErrorWithResults ? error.results : [];
		} else {
			this.results = error instanceof SearchErrorWithResults ? error.results : [];
		}
	}

	onSpecialKey(event: KeyboardEvent): void {
		if (this.keyboardService.isEscapeKeyPressed(event)) {
			this.escapeKeyPressed();
		} else if (this.keyboardService.isReturnKeyPressed(event)) {
			this.returnKeyPressed();
		} else if (this.keyboardService.isNavigationKey(event)) {
			if (this.keyboardService.isDownKeyPressed(event)) {
				this.downKeyPressed();
			} else if (this.keyboardService.isUpKeyPressed(event)) {
				this.upKeyPressed();
			} else if (this.keyboardService.isRightKeyPressed(event)) {
				this.rightKeyPressed();
			} else if (this.keyboardService.isLeftKeyPressed(event)) {
				this.leftKeyPressed();
			}
		}
	}

	private escapeKeyPressed(): void {
		if (this._subordinatedElementsMode) {
			this.back();
		} else {
			this.cancelled.emit();
		}
	}

	private downKeyPressed(): void {
		if (this._subordinatedElementsMode && this.subordinatedResults) {
			this.index++;
			if (this.index > this.subordinatedResults.length - 1) {
				this.index = this.BACK_BUTTON_INDEX;
			}
		} else {
			this.index = (this.index + 1) % this.results.length;
		}
	}

	private upKeyPressed(): void {
		this.index--;
		if (this._subordinatedElementsMode && this.subordinatedResults && this.index < this.BACK_BUTTON_INDEX) {
			this.index = this.subordinatedResults.length - 1;
		} else if (!this._subordinatedElementsMode && this.index < 0) {
			this.index = this.results.length - 1;
		}
	}

	private returnKeyPressed(): void {
		if (this.index >= 0) {
			if (this._subordinatedElementsMode && this.subordinatedResults) {
				this.onSelect(this.subordinatedResults[this.index]);
			} else {
				this.onSelect(this.results[this.index]);
			}
		} else if (this.index === this.BACK_BUTTON_INDEX && this._subordinatedElementsMode) {
			this.back();
		}
	}

	private rightKeyPressed(): void {
		// eslint-disable-next-line  @typescript-eslint/no-explicit-any -- it is just an check for existence
		if (this.index >= 0 && (this.results[this.index] as any).dependentElementsContainer) {
			this.onSelect(this.results[this.index]);
		}
	}

	private leftKeyPressed(): void {
		if (this._subordinatedElementsMode) {
			this.back();
		}
	}

	@HostListener("document:click", ["$event.target"])
	async onDocumentClick(targetElement: Element): Promise<void> {
		if (targetElement !== this.input.nativeElement) {
			if (this._subordinatedElementsMode) {
				this.term = "";
				await this.onSearchInput();
			} else {
				this.dropDownOpen = false;
			}
		} else if (this.term && this.term.length > MIN_SEARCH_TERM_LENGTH) {
			this.dropDownOpen = true;
		}
	}

	onSelect(result: T): void {
		this.oldTerm = this.term;
		this.params = {
			enterFrom: "0",
			enterTo: "-100",
			leaveFrom: "0",
			leaveTo: "-100",
		};
		this.changeDetector.detectChanges();
		this.term = "";
		this.dropDownOpen = false;
		this.submitted.emit(result);
		// preselect "back"-button (-1 index) when entering subordinated mode
		// unselect everything when not entering subordinated mode
		this.index = this._subordinatedElementsMode ? -2 : -1;
	}

	onMouseDown(): void {
		this.mouseDown = true;
	}

	onBlur(element: FocusEvent): void {
		if (this.browseButtonActive && this.isIE) {
			this.mouseDown = false;
			return;
		}
		if (!this.mouseDown && !(element.relatedTarget instanceof Element && element.relatedTarget.id === "open-browser")) {
			this.focusLost.emit();
		}
		this.mouseDown = false;
	}

	openOrCloseDropDown(): void {
		this.dropDownOpen = this.results !== undefined || this.term.length >= MIN_SEARCH_TERM_LENGTH;
	}

	hasNoResults(): boolean {
		return this.dropDownOpen && this.results.length === 0;
	}

	isSelected(index: number): boolean {
		return index === this.index;
	}

	closeSearch(): void {
		this.closeButtonPressed.emit();
	}

	openBrowser(): void {
		this.openBrowserButtonPressed.emit();
	}

	back(): void {
		this.params = {
			enterFrom: "-200",
			enterTo: "-100",
			leaveFrom: "0",
			leaveTo: "100",
		};
		this.changeDetector.detectChanges();
		this.subordinatedResults = undefined;
		this.term = this.oldTerm;
		this.backButtonPressed.emit();
	}

	isSpecialKey(event: KeyboardEvent): boolean {
		return (
			this.keyboardService.isEscapeKeyPressed(event) ||
			this.keyboardService.isReturnKeyPressed(event) ||
			this.keyboardService.isNavigationKey(event)
		);
	}
}
