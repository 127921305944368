import { Injectable } from "@angular/core";
import { ParamMap } from "@angular/router";
import { MainService } from "core/main.service";
import { SearchQueryService } from "core/stages-client";
import { from, Observable } from "rxjs";

type WorkspaceView = stages.workspace.application.WorkspaceView;

interface Type {
	ident: string;
	subtypeIdent: string;
	name?: string;
}

export interface Suggestion {
	id: string;
	identity: string;
	processId?: string;
	processVersionIdentifier: string;
	type: Type;
	fileType?: string;
	workspaceId: string;
	containerElementTypeIdent?: string;
	containerElementIdentity?: string;
	containerElementName?: string;
	label?: string;
	workspaceName: string;
	tailored?: boolean;
}

export interface TypeAggregation {
	key: string;
	doc_count: string;
}

export interface OtherWorkspacesCount {
	doc_count: number;
}

export interface OverviewResults {
	type_aggregations: TypeAggregation[];
	suggestions: Suggestion[];
	other_workspaces_count: OtherWorkspacesCount[];
}

export type FilterableTypes = Record<string, string[]>;

export interface ResultsContainer {
	total: number;
	suppressedDuplicates: number;
	startFrom: number;
	size: number;
	results: Result[];
	filters: FilterableTypes;
}

export interface Result {
	type: Type;
	identity: string;
	processId?: string;
	tailored: boolean;
	workspaceId: string;
	workspaceName: string;
	processVersionIdentifier: string;
	alternatives?: Result[];
	attributes: StringToUnknown;
	dependentElementHits: DependentElementHit[];
	dropdown?: StandardMenuItem[];
}

export interface DependentElementHit {
	name: string;
	descriptionHit?: string;
	type: string;
	subtype: string;
}

@Injectable({ providedIn: "root" })
export class SearchService {
	private defaultSearchScope!: string;
	private searchScope!: string;
	private defaultProcessVersion!: string;
	private processVersion!: string;
	private categories: string[] = [];

	constructor(private readonly mainService: MainService, private readonly searchQueryService: SearchQueryService) {}

	async getOverview(currentWorkspaceId: string, pv: string, term: string, scope: string): Promise<OverviewResults> {
		this.searchQueryService.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.searchQueryService.getSearchQueryOverview({
			query: term,
			workspaceId: currentWorkspaceId,
			searchPv: pv,
			workspaceFilter: scope,
		}) as Promise<OverviewResults>;
	}

	search(
		term: string,
		scope: string,
		categories: string[] | undefined,
		versionIdentifier: string,
		page: number,
		currentWorkspaceId: string,
		currentPv: string,
		resultSize: number,
	): Observable<ResultsContainer> {
		const startFrom = (page - 1) * resultSize;
		this.searchQueryService.setAdditionalHttpParamsForNextRequest({
			pv: currentPv,
		});
		return from(
			this.searchQueryService.searchThroughWorkspaces({
				query: term,
				workspaceId: currentWorkspaceId,
				workspaceFilter: scope,
				typeFilter: categories,
				startFrom: startFrom,
				size: resultSize,
				searchPv: versionIdentifier,
			}),
		) as Observable<ResultsContainer>;
	}

	preserveSecondaryWorkspaceInSearchResultNavigation(
		workspaces: WorkspaceView,
		searchResultWorkspaceId: string,
		searchResultProcessId?: string,
	): boolean {
		return (
			this.mainService.secondaryWorkspaceId !== undefined &&
			searchResultWorkspaceId === workspaces.currentWorkspace.id &&
			searchResultProcessId === workspaces.currentWorkspace.viewedProcess!.id
		);
	}

	setDefaultSearchScope(defaultSearchScope: string): void {
		this.defaultSearchScope = defaultSearchScope;
	}

	setSearchScope(searchScope: string): void {
		this.searchScope = searchScope;
	}

	getSearchScope(): string {
		return this.searchScope;
	}

	getSearchInvertedScope(): string {
		return this.searchScope === "all" ? "local" : "all";
	}

	setDefaultProcessVersionToSearchFor(defaultProcessVersion: string): void {
		this.defaultProcessVersion = defaultProcessVersion;
	}

	setProcessVersionToSearchFor(processVersion: string): void {
		this.processVersion = processVersion;
	}

	getProcessVersionToSearchFor(): string {
		return this.processVersion;
	}

	resetSearchSettings(): void {
		this.processVersion = this.defaultProcessVersion;
		this.searchScope = this.defaultSearchScope;
		this.categories = [];
	}

	setCategories(paramMap: ParamMap): string[] {
		// ST-33214: Reload of search result page with multiple categories returns no results
		// When using "getAll()" multiple categories are sometimes (!) not returned correctly.
		// Sometimes multiple categories are not returned as an array with multiple string elements, but as an array with a single string with comma-separated categories.
		// This seems to be a bug: https://github.com/angular/angular/issues/19179
		// As a workaround for this bug, we split by hand, if the array has one element.
		const categories = paramMap.getAll("categories");
		const fixedCategories = categories.length === 1 ? categories[0].split(",") : categories;

		// I'm not sure if filtering is really necessary.
		// Before this helper function "getCategories" was extracted, it has been used in some places.
		return (this.categories = fixedCategories.filter((category) => category !== ""));
	}

	getCategories(): string[] {
		return this.categories;
	}
}
