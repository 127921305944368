<ng-container *ngIf="results$ | async as results">
	<article class="search-results" *ngIf="workspaces">
		<div
			class="progress-circular progress-circular-stages progress-circular-center"
			*ngIf="!results && searchEngineAvailable"
		>
			<div class="progress-circular-wrapper">
				<div class="progress-circular-inner">
					<div class="progress-circular-left">
						<div class="progress-circular-spinner"></div>
					</div>
					<div class="progress-circular-gap"></div>
					<div class="progress-circular-right">
						<div class="progress-circular-spinner"></div>
					</div>
				</div>
			</div>
		</div>

		<header class="page-header" *ngIf="results && searchEngineAvailable">
			<ul class="breadcrumb">
				<li>
					<a class="back" (click)="close()">
						<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
					</a>
				</li>
			</ul>
			<h1
				*ngIf="totalResultsCount === 1"
				translate="search.result.single.title"
				[translateParams]="{ term: term }"
			></h1>
			<h1
				*ngIf="totalResultsCount > 1"
				translate="search.result.multi.title"
				[translateParams]="{ count: totalResultsCount, term: term }"
			></h1>
			<h1 *ngIf="totalResultsCount === 0" translate="search.result.none.title" [translateParams]="{ term: term }"></h1>
		</header>

		<div class="container responsive-8-4" *ngIf="results && searchEngineAvailable">
			<article class="card col-8">
				<header>
					<div class="wrapper">
						<h5 translate="search.result.results.title" class="visible-gt-large"></h5>

						<h5 translate="filter.show" class="visible-lt-large"></h5>
						<div class="visible-lt-large switch-filter">
							<input id="search.filter" type="checkbox" hidden="hidden" [(ngModel)]="showFilter" />
							<label for="search.filter" class="switch"></label>
						</div>
					</div>
				</header>

				<form *ngIf="showFilter" class="form visible-lt-large">
					<stages-global-search-filter [typeFilterOptions]="typeFilterOptions"></stages-global-search-filter>
				</form>

				<ul class="results" *ngIf="!loading; else wait">
					<li *ngFor="let result of $any(results.results)">
						<div class="label" *ngIf="isFile(result)">
							<a
								*ngIf="isFileDownloadable(result)"
								class="clickable"
								href="{{ getFileUrl(result.id, result.workspaceId) }}"
							>
								<h4 [ngClass]="{ tailored: result.tailored }">{{ result.label }}</h4>
							</a>
							<h4 *ngIf="!isFileDownloadable(result)" [ngClass]="{ tailored: result.tailored }">{{ result.label }}</h4>
							<h6>{{ result.type.name }}</h6>
							<div *ngIf="result.alternatives && result.alternatives.length >= 1" class="further-workspaces">
								<stages-menu
									menuId="file-container-menu-{{ result.id }}"
									[noCollapse]="true"
									openTo="bottom"
									[items]="result.dropdown"
								>
									<span
										class="clickable"
										translate="search.result.results.file.containers"
										[translateParams]="{ count: result.alternatives.length + 1 }"
									></span>
								</stages-menu>
							</div>
							<div *ngIf="!result.alternatives || result.alternatives.length === 0" class="further-workspaces">
								<a
									class="clickable"
									[routerLink]="createResultLink(result)"
									translate="search.result.results.file.container"
									[translateParams]="{ elementName: result.containerElementName, workspaceName: result.workspaceName }"
								></a>
							</div>
						</div>
						<div class="label" *ngIf="!isFile(result)">
							<a class="clickable" [routerLink]="createResultLink(result)">
								<h4 [ngClass]="{ tailored: result.tailored }">{{ result.label }}</h4>
							</a>
							<h6>{{ result.type.name }}</h6>
							<h6>{{ result.workspaceName }}</h6>

							<div *ngIf="result.alternatives && result.alternatives.length > 1" class="further-workspaces">
								<stages-menu
									menuId="alternatives-menu-{{ result.identity }}"
									[noCollapse]="true"
									openTo="bottom"
									[items]="result.dropdown"
								>
									<span
										class="clickable"
										translate="search.result.results.further.workspaces"
										[translateParams]="{ count: result.alternatives.length }"
									></span>
								</stages-menu>
							</div>
							<div *ngIf="result.alternatives && result.alternatives.length === 1" class="further-workspaces">
								<a
									class="clickable"
									[routerLink]="createResultLink(result.alternatives[0])"
									translate="search.result.results.further.workspace"
									[translateParams]="{ name: result.alternatives[0].workspaceName }"
								></a>
							</div>
						</div>

						<div class="preview">
							<p *ngIf="isFile(result) && result.physicalPath">{{ result.physicalPath }}</p>
							<p *ngIf="isFile(result) && result.fileUrl">
								<a class="clickable" href="{{ result.fileUrl }}">{{ result.fileUrl }}</a>
							</p>

							<p *ngIf="result.description" [innerHTML]="result.description"></p>
							<ng-container *ngFor="let dependentElementHit of result.dependentElementHits">
								<p [innerHTML]="dependentElementHit.descriptionHit" *ngIf="dependentElementHit.descriptionHit"></p>
							</ng-container>
							<div *ngIf="hasAttributes(result) || hasDependentElementNames(result)" class="horizontal-list">
								<div class="content" *ngFor="let entry of result.attributes | keyvalue">
									<i class="ico ico-tag attribute-icon"></i>
									<h6 class="tag">{{ entry.value }}</h6>
								</div>
								<div class="content" *ngFor="let dependentElementHit of result.dependentElementHits">
									<i
										[ngClass]="getDependentElementIconClasses(dependentElementHit)"
										[title]="getDependentElementTooltip(dependentElementHit) | async"
										[attr.aria-label]="getDependentElementTooltip(dependentElementHit) | async"
									></i>
									<h6 class="tag">{{ dependentElementHit.name }}</h6>
								</div>
							</div>
						</div>
					</li>
				</ul>
				<div class="clear"></div>
				<ng-container *ngIf="!results.results.length && !loading">
					<p class="empty">
						<span translate="search.result.results.empty" [translateParams]="{ term: term }"> </span>
					</p>
					<ng-container *ngIf="overviewResultsFromOtherWorkspaces > 0">
						<p class="empty">
							<span class="clickable" (click)="goToPage(1, 'all')" translate="search.result.search.all"></span>
						</p>
					</ng-container>
				</ng-container>
				<footer *ngIf="!loading">
					<stages-pager
						[total]="totalResultsCount"
						[page]="page"
						[pageSize]="resultsSize"
						(goTo)="goToPage($event)"
					></stages-pager>
				</footer>
			</article>
			<article class="card facets col-4 visible-gt-large">
				<form class="form">
					<stages-global-search-filter [typeFilterOptions]="typeFilterOptions"></stages-global-search-filter>
				</form>
			</article>
		</div>
	</article>
</ng-container>

<ng-template #wait>
	<stages-spin class="spin-without-button" size="sm"></stages-spin>
</ng-template>

<article class="search-results" *ngIf="!searchEngineAvailable">
	<!-- Displayed when the search is not available. -->
	<header class="page-header">
		<ul class="breadcrumb">
			<li>
				<a class="back" (click)="close()">
					<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
				</a>
			</li>
		</ul>
		<h1 translate="search.notavailable.title"></h1>
	</header>

	<div class="container responsive-8-4">
		<article class="card col-8">
			<header>
				<div class="wrapper">
					<h5 translate="search.result.results.title" class="visible-gt-large"></h5>
				</div>
			</header>

			<div class="clear"></div>
			<p class="empty" translate="search.notavailable"></p>
		</article>
	</div>
</article>
