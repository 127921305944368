import { Injectable } from "@angular/core";
import { Selectable } from "common/selection/state/selectable.model";
import { SelectionRepository } from "common/selection/state/selection.repository";
import { emitOnce, Store } from "@ngneat/elf";
// import { emitOnce } from '@elf/store';
type TypedId = stages.core.TypedId;

@Injectable({ providedIn: "root" })
export class SelectionStoreService {
	constructor(private readonly selectionRepository: SelectionRepository) {}

	static defaultStoreIdentifier = "common";

	private createState(storeName: string): void {
		this.selectionRepository.addStore(storeName);
	}

	getState(name: string): Store | undefined {
		return this.selectionRepository.getStore(name);
	}

	hasState(name: string): boolean {
		return this.selectionRepository.getStore(name) !== undefined;
	}

	createAndInitializeDynamicStore(
		storeName: string,
		allSelectables: Selectable[],
		initialSelections: Selectable[],
	): void {
		this.createState(storeName);
		this.selectionRepository.setLoadingDynamicStore(storeName, true);

		const initialSelectedSelectables: Selectable[] = this.createSelectables(allSelectables, initialSelections);
		this.selectionRepository.updateDynamicStore(storeName, initialSelectedSelectables);
		this.selectionRepository.setLoadingDynamicStore(storeName, false);
	}

	private createSelectables(allSelectables: Selectable[], initialSelections: Selectable[]): Selectable[] {
		const selectablesToStore: Selectable[] = [];

		allSelectables.forEach((selectable) => {
			const isSelected = isInSelection(initialSelections, selectable);
			const selectableToSave: Selectable = {
				...selectable,
				typeId: selectable.typeId,
				initiallySelected: isSelected,
				isSelected: isSelected,
				wasUpdated: false,
			};
			selectablesToStore.push(selectableToSave);
		});
		return selectablesToStore;
	}

	initializeCommonStore(initialSelections: Selectable[]): void {
		const initialSelectedSelectables: Selectable[] = this.createInitialSelectables(initialSelections);
		this.selectionRepository.setLoading(true);
		emitOnce(() => {
			initialSelectedSelectables.forEach((initialSelectable) => {
				this.selectionRepository.add(initialSelectable);
			});
		});
		this.selectionRepository.setLoading(false);
	}

	selectAllInCommonByIdentifier(storeIdentifier: string): void {
		this.selectionRepository.updateAllEntities(storeIdentifier, true);
	}

	deSelectAllInCommonByIdentifier(storeIdentifier: string): void {
		this.selectionRepository.updateAllEntities(storeIdentifier, false);
	}

	private createInitialSelectables(selectables: Selectable[]): Selectable[] {
		// Even if the initialSelectables are provided we want to ensure correct initialization
		const selectablesToStore: Selectable[] = [];

		selectables.forEach((selectable) => {
			const selectableToSave: Selectable = {
				id: selectable.id,
				type: selectable.type,
				typeId: selectable.typeId,
				identity: selectable.storeIdentifier + "_" + selectable.typeId,
				parentId: selectable.parentId ?? null,
				parentType: selectable.type || null,
				storeIdentifier: selectable.storeIdentifier,
				isSelectable: selectable.isSelectable,
				initiallySelected: selectable.initiallySelected,
				indeterminate: selectable.indeterminate,
				selectableChildrenCount: null,
				initiallySelectedCount: null,
				totalChildrenCount: null,
				isSelected: true,
				comment: selectable.comment,
				isCommentEditable: selectable.isCommentEditable,
				wasUpdated: false,
			};
			selectablesToStore.push(selectableToSave);
		});
		return selectablesToStore;
	}

	createBasicSelectablesFromTreeElements(
		treeElements: stages.process.TreeElement[],
		storeIdentifier: string,
	): Selectable[] {
		const selectables: Selectable[] = [];

		const storeIdentifierWithDefault = storeIdentifier ?? SelectionStoreService.defaultStoreIdentifier;

		treeElements.forEach((element) => {
			const selectable: Selectable = {
				id: element.elementId,
				type: element.elementType.toLocaleLowerCase(),
				typeId: getIdKeyOfTypeId({ id: element.elementId, typeIdent: element.elementType }),
				identity:
					storeIdentifierWithDefault +
					"_" +
					getIdKeyOfTypeId({ id: element.elementId, typeIdent: element.elementType }),
				parentId: element.parentId ? element.parentId : null,
				parentType: null,
				storeIdentifier: storeIdentifierWithDefault,
				initiallySelected: false, // recalculated later in createSelectables for dynamic stores
				indeterminate: false,
				selectableChildrenCount: null,
				initiallySelectedCount: null,
				totalChildrenCount: null,
				isSelectable: element.selectable,
				isSelected: false, // recalculated later in createSelectables for dynamic stores
				comment: null, // until now no comments possible / needed in dynamic store usages
				isCommentEditable: false, // until now no comments possible / needed in dynamic store usages
				wasUpdated: false,
			};

			selectables.push(selectable);
		});
		return selectables;
	}

	// TODO introduce new BasicSelectalbe vs / ExtendedSelectable
	createBasicSelectablesFromTypeIds(selectedTypeIDs: stages.core.TypedId[], storeIdentifier: string): Selectable[] {
		const selectables: Selectable[] = [];
		const storeIdentifierWithDefault = storeIdentifier ?? SelectionStoreService.defaultStoreIdentifier;

		selectedTypeIDs.forEach((typeId) => {
			const selectable: Selectable = {
				id: typeId.id,
				type: typeId.typeIdent,
				typeId: getIdKeyOfTypeId({ id: typeId.id, typeIdent: typeId.typeIdent }),
				identity:
					storeIdentifierWithDefault +
					"_" +
					getIdKeyOfTypeId({
						id: typeId.id,
						typeIdent: typeId.typeIdent,
					}),
				parentId: null,
				parentType: null,
				storeIdentifier: storeIdentifierWithDefault,
				initiallySelected: true, // recalculated later in createSelectables for dynamic stores
				indeterminate: false,
				selectableChildrenCount: null,
				initiallySelectedCount: null,
				totalChildrenCount: null,
				isSelectable: true,
				isSelected: true, // recalculated later in createSelectables for dynamic stores
				comment: null, // until now no comments possible / needed in dynamic store usages
				isCommentEditable: false, // until now no comments possible / needed in dynamic store usages
				wasUpdated: false,
			};

			selectables.push(selectable);
		});
		return selectables;
	}

	// TODO remove complexity
	// eslint-disable-next-line sonarjs/cognitive-complexity,complexity
	addNewSelectable(
		selectableTypedId: TypedId,
		initiallySelected: boolean,
		parentTypeId?: TypedId,
		storeIdentifier?: string, // TODO make not optional
		indeterminate?: boolean,
		selectableChildrenCount?: number,
		initiallySelectedChildrenCount?: number,
		totalChildrenCount?: number,
		isSelectable?: boolean,
		isSelected?: boolean,
	): void {
		const storeIdentifierWithDefault = storeIdentifier ?? SelectionStoreService.defaultStoreIdentifier;
		let parent: Selectable | undefined;
		if (parentTypeId) {
			parent = this.selectionRepository.getEntity(getIdKeyOfTypeId(parentTypeId), storeIdentifierWithDefault);
		}

		let showAsSelected = !!isSelected;
		if (isSelectable) {
			if (parent?.isSelected && !parent.indeterminate) {
				showAsSelected = true;
			} else if (!parent || parent?.indeterminate) {
				showAsSelected = initiallySelected || !!isSelected;
			}
		}

		const selectableToSave: Selectable = {
			id: selectableTypedId.id,
			type: selectableTypedId.typeIdent,
			typeId: getIdKeyOfTypeId(selectableTypedId),
			identity: storeIdentifierWithDefault + "_" + getIdKeyOfTypeId(selectableTypedId),
			parentId: parent?.id ?? null,
			parentType: parent?.type ?? null,
			storeIdentifier: storeIdentifierWithDefault,
			isSelectable: isSelectable ?? true,
			initiallySelected: initiallySelected,
			indeterminate: indeterminate ?? false,
			selectableChildrenCount: selectableChildrenCount ?? null,
			initiallySelectedCount: initiallySelectedChildrenCount ?? null,
			totalChildrenCount: totalChildrenCount ?? null,
			isSelected: showAsSelected,
			comment: null,
			isCommentEditable: true,
			wasUpdated: false,
		};

		this.selectionRepository.add(selectableToSave);
		if (
			selectableToSave.parentId &&
			selectableToSave.parentType &&
			this.selectionRepository.hasParent(selectableToSave.parentId)
		) {
			const parentIdentifier = getIdIdentity(storeIdentifierWithDefault, {
				typeIdent: selectableToSave.parentType,
				id: selectableToSave.parentId,
			});
			this.setDirty(parentIdentifier);
		}
	}

	selectToCommonSelection(selectable: Selectable): void {
		// we set wasUpdated to true, even if the selectable was initialliSelected aswell, because maybe a comment was added meanwhile
		this.selectionRepository.update(selectable.identity, {
			isSelected: true,
			wasUpdated: !selectable.initiallySelected,
			indeterminate:
				!!selectable.totalChildrenCount &&
				!!selectable.selectableChildrenCount &&
				selectable.totalChildrenCount > 0 &&
				selectable.totalChildrenCount > selectable.selectableChildrenCount,
		});

		this.selectFromCommonSelectionIncludeChildren(selectable);
		if (selectable.parentId) {
			const parentTypedId = {
				typeIdent: selectable.parentType!,
				id: selectable.parentId,
			};
			const parent = this.selectionRepository.getEntity(getIdKeyOfTypeId(parentTypedId), selectable.storeIdentifier);
			if (parent) {
				this.setDirty(parent.identity);
				this.setIndeterminateAndSelectedForParent(parent);
			}

			if (selectable.parentId && selectable.parentType && this.selectionRepository.hasParent(selectable.parentId)) {
				const parentIdentifier = getIdIdentity(selectable.storeIdentifier, {
					typeIdent: selectable.parentType,
					id: selectable.parentId,
				});
				this.setDirty(parentIdentifier);
			}
		}
	}

	unselectFromCommonSelection(selectable: Selectable): void {
		this.selectionRepository.update(selectable.identity, {
			isSelected: false,
			indeterminate: false,
			wasUpdated: selectable.initiallySelected,
		});

		if (selectable.parentId) {
			const parentTypedId = {
				typeIdent: selectable.parentType!,
				id: selectable.parentId,
			};
			const parent = this.selectionRepository.getEntity(getIdKeyOfTypeId(parentTypedId), selectable.storeIdentifier);
			if (parent) {
				this.updateIsSelected(parent, false);
				this.setIndeterminateAndSelectedForParent(parent);
			}

			const parentIdentifier = getIdIdentity(selectable.storeIdentifier, {
				typeIdent: selectable.parentType!,
				id: selectable.parentId,
			});
			this.setDirty(parentIdentifier);
		}

		this.unselectChildrenFromCommonSelection(selectable);
	}

	unselectChildrenFromCommonSelection(selectable: Selectable): void {
		const directChildren = this.selectionRepository.getAllDirectChildrenCommonStore(selectable);
		if (directChildren) {
			directChildren.forEach((child: Selectable) => {
				this.updateIsSelected(child, false);
			});
		}
	}

	selectFromCommonSelectionIncludeChildren(selectable: Selectable): void {
		const directChildren = this.selectionRepository.getAllDirectChildrenCommonStore(selectable);
		if (directChildren) {
			directChildren.forEach((child: Selectable) => {
				this.updateIsSelected(child, true);
			});
		}
	}

	addToSelection(selectable: Selectable): void {
		if (selectable.isSelectable) {
			this.selectionRepository.updateEntityDynamicStore(selectable, {
				isSelected: true,
				wasUpdated: !selectable.initiallySelected,
			});

			if (selectable.parentId && this.selectionRepository.hasParent(selectable.parentId)) {
				this.setDirty(selectable.parentId);
			}
		}
	}

	removeFromSelection(selectable: Selectable): void {
		this.selectionRepository.updateEntityDynamicStore(selectable, {
			isSelected: false,
			wasUpdated: selectable.initiallySelected,
		});
	}

	selectAll(storeName: string, parentTypeId: TypedId, leavesOnly: boolean): void {
		this.selectionRepository.setLoadingDynamicStore(storeName, true);
		const parentTarget = this.selectionRepository.getEntityFromDynamicStore(storeName, getIdKeyOfTypeId(parentTypeId));

		if (parentTarget) {
			emitOnce(() => {
				this.setChildrenSelected(storeName, parentTarget, leavesOnly);
				if (!leavesOnly || (leavesOnly && !this.hasChildren(parentTarget, storeName))) {
					this.selectionRepository.updateEntityDynamicStore(parentTarget, { isSelected: true });
				}
			});
		}
		this.selectionRepository.setLoadingDynamicStore(storeName, false);
	}

	private hasChildren(target: Selectable, storeName: string): boolean | undefined {
		const children = this.selectionRepository.getAllDirectChildrenDynamicStore(storeName, target);
		return !!children?.length;
	}

	setChildrenSelected(storeName: string, target: Selectable, leavesOnly: boolean): void {
		this.selectionRepository.setChildrenSelectedDynamicStore(storeName, target, leavesOnly);
		const directChildren = this.selectionRepository.getAllDirectChildrenDynamicStore(storeName, target);

		if (directChildren) {
			directChildren.forEach((child: Selectable) => {
				this.setChildrenSelected(storeName, child, leavesOnly);
			});
		}
	}

	setChildrenDeSelected(storeName: string, target: Selectable): void {
		this.selectionRepository.setChildrenDeSelectedDynamicStore(storeName, target);
		const directChildren = this.selectionRepository.getAllDirectChildrenDynamicStore(storeName, target);

		if (directChildren) {
			directChildren.forEach((child: Selectable) => {
				this.setChildrenDeSelected(storeName, child);
			});
		}
	}

	deselectAllDynamicStore(storeName: string, parentTypeId: TypedId): void {
		this.selectionRepository.setLoadingDynamicStore(storeName, true);

		const parentTarget = this.selectionRepository.getEntityFromDynamicStore(storeName, getIdKeyOfTypeId(parentTypeId));
		if (parentTarget) {
			emitOnce(() => {
				this.setChildrenDeSelected(storeName, parentTarget);
				this.selectionRepository.updateEntityDynamicStore(parentTarget, { isSelected: false });
			});
		}

		this.selectionRepository.setLoadingDynamicStore(storeName, false);
	}

	getUpdatedSelectables(
		selectablesToAdd: Selectable[],
		selectablesToRemove: Selectable[],
		updatedSelectables: Selectable[],
	): stages.common.UpdatedElements {
		const addableTypedIds: TypedId[] = [];
		const removableTypedIds: TypedId[] = [];

		selectablesToAdd.forEach((selectable) => {
			addableTypedIds.push({ id: selectable.id, typeIdent: selectable.type });
		});

		selectablesToRemove.forEach((selectable) => {
			removableTypedIds.push({ id: selectable.id, typeIdent: selectable.type });
		});

		return {
			addedElements: addableTypedIds,
			removedElements: removableTypedIds,
			updatedElements: this.getAssociationDetails(updatedSelectables),
		};
	}

	updateComment(selectable: Selectable, newComment: string): void {
		this.selectionRepository.update(selectable.identity, { comment: newComment, wasUpdated: true });
	}

	private updateIsSelected(selectable: Selectable, shouldBeSelected: boolean): void {
		this.selectionRepository.update(selectable.identity, {
			isSelected: shouldBeSelected && selectable.isSelectable,
			wasUpdated: selectable.initiallySelected !== shouldBeSelected,
		});
	}

	private setIndeterminateAndSelectedForParent(parent: Selectable): void {
		const directSelectableChildren = this.selectionRepository
			.getAllDirectChildrenCommonStore(parent)
			?.filter((child) => child.isSelectable);
		const hasSelectedChildren = !!directSelectableChildren?.find((child) => child.isSelected);
		const hasDeselectedChildren = !!directSelectableChildren?.find((child) => !child.isSelected);
		this.selectionRepository.update(parent.identity, {
			wasUpdated: parent.indeterminate !== hasSelectedChildren && hasDeselectedChildren,
			indeterminate: hasSelectedChildren && hasDeselectedChildren,
			isSelected: parent.isSelectable && hasSelectedChildren,
		});
	}

	setDirty(identity: string): void {
		this.selectionRepository.update(identity, { wasUpdated: true });
	}

	getAssociationDetails(updatedSelectables: Selectable[]): stages.core.model.TypedIdAssociationDetails[] {
		const modifiedComments: stages.core.model.TypedIdAssociationDetails[] = [];
		updatedSelectables.forEach((updatedSelectable: Selectable) => {
			const modifiedComment = {
				typeIdent: updatedSelectable.type,
				id: updatedSelectable.id,
				comment: updatedSelectable.comment,
			};
			modifiedComments.push(modifiedComment);
		});
		return modifiedComments;
	}

	setLoading(storeName: string, value: boolean): void {
		this.selectionRepository.setLoadingDynamicStore(storeName, value);
	}

	reset(storeName: string): void {
		this.selectionRepository.resetDynamicStore(storeName);
	}

	removeFromCommonStore(storeIdentifier: string): void {
		this.selectionRepository.removeFromCommon(storeIdentifier);
	}

	resetCommon(): void {
		this.selectionRepository.reset();
	}

	delete(storeName: string): void {
		this.selectionRepository.deleteDynamicStore(storeName);
	}
}

function isInSelection(selections: Selectable[], selectable: Selectable): boolean {
	for (const selection of selections) {
		if (getIdKeyOfSelectable(selection) === selectable.typeId) {
			return true;
		}
	}
	return false;
}

export function getIdKeyOfSelectable(selection: Selectable): string {
	return selection.type.toLocaleLowerCase() + "_" + selection.id;
}

export function getIdKeyOfTypeId(typeId: TypedId): string {
	return typeId.typeIdent.toLocaleLowerCase() + "_" + typeId.id;
}

export function getTypeIdOfTypeIdString(key: string): TypedId | undefined {
	const strParts = key.split("_");
	if (strParts[0] && strParts[1]) {
		return { typeIdent: strParts[0], id: strParts[1] };
	}
	return undefined;
}

export function getIdIdentity(storeIdentifier: string, typeId: TypedId): string {
	return storeIdentifier + "_" + getIdKeyOfTypeId(typeId);
}
