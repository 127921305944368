import { Injectable } from "@angular/core";
import { uniqBy } from "lodash";

/*
 * MultiLevel Selection service.
 *
 * elements: list of elements that are available for selection on this level
 * key: key for this selection level
 * elementKeyName: deprecated
 * keyFunction: function to compute an unique key for a element
 *
 */
export class MultiLevelSelection<E> {
	count = 0;
	/**
	 * This object can be exposed to ng-model (which needs an assignable expression - functions won't work).
	 */
	set: StringToBoolean = {};
	initialCount = 0;
	initialSet: StringToBoolean = {};
	transitiveSet: StringToBoolean = {};
	loadedBefore: StringToBoolean = {};
	modified = false;

	constructor(
		private store: Map<string, MultiLevelSelection<E>>,
		private elements: E[],
		private key: string,
		public keyFunction: (e: E) => string,
	) {
		this.load();
		this.persist();
	}

	getKey(): string {
		return this.key;
	}

	/**
	 * Returns all selected elements.
	 */
	get(): E[] {
		return this.elements.filter((element: E) => this.set[this.keyFunction(element)]);
	}

	/**
	 * Gets whether all elements are selected.
	 */
	get all(): boolean {
		// return true, if all elements are selected and there are selectable elements
		return this.elements.length > 0 && this.count === this.elements.length;
	}

	/**
	 * Sets whether all elements are selected.
	 */
	set all(value: boolean) {
		this.count = 0;
		if (value) {
			this.elements.forEach((element: E) => {
				this.set[this.keyFunction(element)] = value;
				this.count++;
			});
		} else {
			this.set = {};
		}
		this.setModified();
	}

	select(element: E): void {
		this.set[this.keyFunction(element)] = true;
		this.count++;
		this.setModified();
	}

	deselect(element: E): void {
		this.set[this.keyFunction(element)] = false;
		this.count--;
		this.setModified();
	}

	/**
	 * Return true if some element is selected.
	 */
	isSomeSelected(): boolean {
		return this.count !== 0;
	}

	getSelectedCount(): number {
		return this.count;
	}

	isModified(): boolean {
		return this.modified;
	}

	setModified(): void {
		this.modified = this.count !== this.initialCount || this.getAdded().length > 0;
	}

	/**
	 * Returns whether or not the specified element is selected.
	 */
	isSelected(element: E): boolean {
		return this.set[this.keyFunction(element)];
	}

	/**
	 * Has to be called whenever a element is selected or deselected.
	 */
	onChange(element: E): void {
		this.set[this.keyFunction(element)] ? this.count++ : this.count--;
		this.setModified();
	}

	load(): void {
		this.count = 0;
		this.set = {};
		this.modified = false;
		if (!this.store.has(this.key)) {
			return;
		}

		const selection = this.store.get(this.key)!;
		this.elements = uniqBy([...selection.elements, ...this.elements], this.keyFunction);

		this.loadedBefore = selection.loadedBefore;

		this.elements.forEach((element: E) => {
			const elementKey = this.keyFunction(element);
			if (selection.set[elementKey]) {
				this.set[elementKey] = true;
				this.count++;
			}
			if (selection.initialSet[elementKey]) {
				this.initialSet[elementKey] = true;
				this.initialCount++;
			}
		});
		this.setModified();
	}

	persist(): void {
		this.store.delete(this.key);
		this.store.set(this.key, this);
	}

	clean(): void {
		this.store.delete(this.key);
	}

	setInitiallySelected(element: E): void {
		const elementKey = this.keyFunction(element);
		this.initialSet[elementKey] = true;
		this.initialCount++;
		this.setLoadedBefore(elementKey);
	}

	setTransitiveSelected(element: E): void {
		const elementKey = this.keyFunction(element);
		this.transitiveSet[elementKey] = true;
		this.setLoadedBefore(elementKey);
	}

	setLoadedBefore(elementKey: string): void {
		if (!this.modified || !this.loadedBefore[elementKey]) {
			this.set[elementKey] = true;
			this.loadedBefore[elementKey] = true;
			this.count++;
		}
	}

	getAdded(): E[] {
		return this.elements.filter((element: E) => {
			const elementKey = this.keyFunction(element);
			return !this.initialSet[elementKey] && this.set[elementKey];
		});
	}

	getRemoved(): E[] {
		return this.elements.filter((element: E) => {
			const elementKey = this.keyFunction(element);
			return (this.initialSet[elementKey] || this.transitiveSet[elementKey]) && !this.set[elementKey];
		});
	}

	isAnySelectionModified(): boolean {
		if (this.isModified()) {
			return true;
		}
		let result = false;
		this.store.forEach((selection: MultiLevelSelection<E>) => {
			result = result || selection.isModified();
		});
		return result;
	}
}

@Injectable({ providedIn: "root" })
export class MultiLevelSelectionService<E> {
	store = new Map<string, MultiLevelSelection<E>>();

	newSelection(elements: E[], key: string, keyFunction: (e: E) => string): MultiLevelSelection<E> {
		return new MultiLevelSelection(this.store, elements, key, keyFunction);
	}

	remove(key: string): void {
		this.store.delete(key);
	}

	removeAll(): void {
		this.getAll().forEach((sel) => this.remove(sel.getKey()));
	}

	get(key: string): MultiLevelSelection<E> | undefined {
		return this.store.get(key);
	}

	getAll(): MultiLevelSelection<E>[] {
		const allSelections: MultiLevelSelection<E>[] = [];
		this.store.forEach((selection: MultiLevelSelection<E>) => allSelections.push(selection));
		return allSelections;
	}
}
