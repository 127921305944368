import { Component, Input, OnChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export type Step = StringToString;

@Component({
	selector: "stages-round-progress",
	templateUrl: "./round-progress.component.html",
})
export class RoundProgressComponent implements OnChanges {
	@Input()
	progress!: number;

	@Input()
	remainingTimeMillis!: number;

	@Input()
	finalState?: string;

	@Input()
	steps?: Step[];

	@Input()
	currentStepNr!: number;

	remainingNumber?: number;

	remainingUnit?: string;

	constructor(private translateService: TranslateService) {}

	showRemaining(): boolean {
		return !this.finalState && !!this.remainingTimeMillis;
	}

	ngOnChanges(): void {
		const remainingSeconds = this.remainingTimeMillis / 1000;
		if (remainingSeconds < 60) {
			this.setRemaining(remainingSeconds, "second");
		} else if (remainingSeconds < 60 * 60) {
			this.setRemaining(remainingSeconds / 60, "minute");
		} else {
			this.setRemaining(remainingSeconds / (60 * 60), "hour");
		}
	}

	setRemaining(remaining: number, unit: string): void {
		this.remainingNumber = Math.ceil(remaining);
		let messageKey = "unit." + unit;
		if (this.remainingNumber !== 1) {
			messageKey += ".plural";
		}
		this.remainingUnit = this.translateService.instant(messageKey);
	}
}
