import { ActivatedRoute, Router } from "@angular/router";
import { SearchQuery } from "common/autoComplete/search-query";
import { MutexService } from "common/concurrency/mutex.service";
import { Store } from "common/data/store.logic";
import { NotificationService } from "core/notification.service";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";

export class QueryAdapter implements SearchQuery<unknown> {
	constructor(private store: Store<unknown>, private route: ActivatedRoute) {}

	async run(searchTerm: string): Promise<unknown[]> {
		if (!this.store.search) {
			throw new Error("Store does not implement search");
		}

		return this.store.search(searchTerm, this.route.snapshot.paramMap).then((data) => {
			return data.results;
		});
	}
}

export class PageableDataSource<T> {
	private subject = new ReplaySubject<PagedResult<T> | SecuredPagedResult<T> | SecuredPagedResultAndInfo<T, unknown>>(
		1,
	);
	protected observable = this.subject.asObservable().pipe(shareReplay(1));
	private pollingHandle?: number;
	private loadedPages = 1;
	private subscription!: Subscription;

	constructor(
		private store: Store<T>,
		private mutexService: MutexService,
		private router: Router,
		private route: ActivatedRoute,
		private pageParam?: string,
	) {
		this.connect(route);
	}

	connect(route: ActivatedRoute): void {
		this.route = route;
		this.subscription = route.paramMap.subscribe((paramMap) => {
			this.load(
				paramMap.has("page") ? Number.parseInt(paramMap.get("page")!, 10) : 1,
				paramMap.get("workspaceId")!,
				paramMap.get("processVersion")!,
			);
		});
	}

	disconnect(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	get data(): Observable<PagedResult<T> | SecuredPagedResult<T> | SecuredPagedResultAndInfo<T, unknown>> {
		return this.observable;
	}

	reloadPage(): void {
		this.load(
			this.getCurrentPage(),
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
	}

	async goTo(pageIndex: number, workspaceId?: string, processVersion?: string): Promise<boolean> {
		if (!this.pageParam) {
			try {
				await this.load(pageIndex, workspaceId, processVersion);
				return true;
			} catch (err: unknown) {
				console.log(err);
				return false;
			}
		} else {
			return this.router.navigate([{ page: pageIndex }], { relativeTo: this.route, queryParamsHandling: "merge" });
		}
	}

	loadMore(): void {
		this.store
			.getPage(
				++this.loadedPages,
				this.route.snapshot.root.firstChild!.paramMap.get("workspaceId")!,
				this.route.snapshot.root.firstChild!.paramMap.get("processVersion")!,
				this.route.snapshot.paramMap,
				this.route.snapshot.queryParamMap,
			)
			.then((page) => {
				if (this.pageParam) {
					this.router.navigate([{ page: page.page }], { relativeTo: this.route, queryParamsHandling: "merge" });
				}
				this.concat(page);
				return page;
			});
	}

	async delete(itemIds: string[], token?: string): Promise<PagedResult<T>> {
		return this.mutexService.invokeWithResult(`delete ${token}`, async () => {
			const page = await this.store.delete!(itemIds, this.getCurrentPage(), this.route.snapshot.paramMap);
			this.replace(page);
			return page;
		});
	}

	async deleteFromGroup(items: unknown[], groupId: string, token?: string): Promise<PagedResult<T>> {
		return this.mutexService.invokeWithResult(
			`delete
             from group ${token}`,
			async () => {
				const page = await this.store.deleteFromGroup!(
					items,
					groupId,
					this.getCurrentPage(),
					this.route.snapshot.paramMap,
				);

				if (page.items.length === 0 && this.getCurrentPage() > 1) {
					this.router.navigate([{ page: this.getCurrentPage() - 1 }], { relativeTo: this.route });
				}
				this.replace(page);
				return page;
			},
		);
	}

	newSearchQuery(): SearchQuery<unknown> {
		return new QueryAdapter(this.store, this.route);
	}

	async add(item: unknown, token?: string): Promise<PagedResult<T>> {
		return this.mutexService.invokeWithResult(`add ${token}`, async () => {
			const page = await this.store.add!(item, this.getCurrentPage(), this.route.snapshot.paramMap);
			this.replace(page);
			return page;
		});
	}

	async addToGroup(items: unknown[], groupId: string, token?: string): Promise<PagedResult<T>> {
		return this.mutexService.invokeWithResult(`add to group ${token}`, async () => {
			const page = await this.store.addToGroup!(items, groupId, this.getCurrentPage(), this.route.snapshot.paramMap);
			this.replace(page);
			return page;
		});
	}

	async rename(item: unknown, name: string, token?: string): Promise<void> {
		return this.mutexService.invokeWithResult(`rename ${token}`, async () => {
			if (!this.store.rename) {
				throw new Error("Store does not implement rename");
			}
			await this.store.rename(item, name, this.route.snapshot.paramMap);
			this.reloadPage();
		});
	}

	async rearrange(items: unknown[], token?: string): Promise<void> {
		return this.mutexService.invokeWithResult(`rearrange ${token}`, async () => {
			if (!this.store.rearrange) {
				throw new Error("Store does not implement rearrange");
			}
			await this.store.rearrange(items);
			this.reloadPage();
		});
	}

	async restoreAll(token?: string): Promise<PagedResult<T>> {
		return this.mutexService.invokeWithResult(`restore all group defaults ${token}`, async () => {
			const page = await this.store.restoreAll!(this.getCurrentPage(), this.route.snapshot.paramMap);
			this.replace(page);
			return page;
		});
	}

	async restoreGroup(item: unknown, token?: string): Promise<PagedResult<T>> {
		return this.mutexService.invokeWithResult(`restore group default ${token}`, async () => {
			const page = await this.store.restoreGroup!(item, this.getCurrentPage(), this.route.snapshot.paramMap);
			this.replace(page);
			return page;
		});
	}

	async filter(term: string): Promise<void> {
		return this.store.filter!(term, this.route.snapshot.paramMap).then((pageable) => {
			this.replace(pageable);
		});
	}

	startPolling(notificationService: NotificationService): void {
		if (!this.store.poll) {
			return;
		}

		if (!this.pageParam) {
			throw new Error("not yet supported");
		}

		if (this.pollingHandle) {
			return;
		}

		this.pollingHandle = notificationService.pollPromise(
			async () => {
				return this.store.poll!(
					Number.parseInt(this.route.snapshot.paramMap.get("page")!, 10),
					this.route.snapshot.paramMap,
				);
			},
			(page) => {
				this.replace(page);
			},
		);
	}

	stopPolling(notificationService: NotificationService): void {
		if (!this.store.poll || !this.pollingHandle) {
			return;
		}

		notificationService.cancel(this.pollingHandle);
		this.pollingHandle = undefined;
	}

	filterEnabled(): boolean {
		return !!this.store.filter;
	}

	replace(pageable: PagedResult<T>): void {
		this.subject.next(pageable);
	}

	private async load(pageIndex: number, workspaceId?: string, processVersion?: string): Promise<PagedResult<T>> {
		return this.store
			.getPage(
				pageIndex,
				workspaceId ? workspaceId : this.route.snapshot.root.firstChild!.paramMap.get("workspaceId")!,
				processVersion ? processVersion : this.route.snapshot.root.firstChild!.paramMap.get("processVersion")!,
				this.route.snapshot.paramMap,
				this.route.snapshot.queryParamMap,
			)
			.then((page) => {
				this.replace(page);
				return page;
			});
	}

	private concat(pageable: PagedResult<T>): void {
		// replace by scan operator?
		const destroy$ = new Subject<boolean>();
		this.data.pipe(takeUntil(destroy$)).subscribe((value) => {
			destroy$.next(true);
			destroy$.unsubscribe();
			this.subject.next({
				items: value.items.concat(pageable.items),
				totalItemCount: pageable.totalItemCount,
				totalPageCount: pageable.totalPageCount,
				page: pageable.page,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				actions: (pageable as any).actions,
				pageSize: pageable.pageSize,
			});
		});

		// const value = this.subject.getValue();
	}

	private getCurrentPage(): number {
		if (this.pageParam) {
			const value = this.route.snapshot.paramMap.get(this.pageParam);
			if (value) {
				return Number.parseInt(value, 10);
			}
		}
		return this.loadedPages;
	}
}

export class PageableDataAndInfoSource<T, M> extends PageableDataSource<T> {
	override get data(): Observable<SecuredPagedResultAndInfo<T, M>> {
		return this.observable as Observable<SecuredPagedResultAndInfo<T, M>>;
	}
}
