import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MainService } from "core/main.service";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, switchMap, takeUntil, tap } from "rxjs/operators";
import { FilterSettings } from "search/filter.component";
import { DependentElementHit, OverviewResults, Result, ResultsContainer, SearchService } from "search/search.service";

type WorkspaceView = stages.workspace.application.WorkspaceView;

interface FileResult extends Result {
	physicalPath?: string;
	fileUrl?: string;
	containerElementTypeIdent: string;
	containerElementIdentity: string;
	containerElementName?: string;
	fileContentExists: boolean;
}

@Component({
	selector: "stages-global-search-results",
	templateUrl: "results.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchResultsComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	searchEngineAvailable: boolean = true;

	term!: string;
	filterSettings!: FilterSettings;
	typeFilterOptions: StringToBoolean = {};
	cachedTypeFilterOptions: StringToBoolean = {};
	showFilter?: boolean;
	loading?: boolean;
	resultsSize: number = 25;

	results$!: Observable<ResultsContainer>;
	workspaces!: WorkspaceView;
	page!: number;
	totalResultsCount!: number;

	overviewResultsFromOtherWorkspaces!: number;

	constructor(
		private mainService: MainService,
		private searchService: SearchService,
		private router: Router,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private titleService: Title,
		private changeDetector: ChangeDetectorRef,
	) {
		this.mainService.workspaceView$.pipe(takeUntil(this.destroy$)).subscribe((workspaceView: WorkspaceView) => {
			this.workspaces = workspaceView;
		});
	}

	ngOnInit(): void {
		this.results$ = this.route.paramMap.pipe(
			switchMap((paramMap: ParamMap) => {
				const categories = this.searchService.setCategories(paramMap);
				this.loading = true;

				const results = this.searchService.search(
					paramMap.get("term")!,
					paramMap.get("scope")!,
					categories,
					paramMap.has("pv") ? paramMap.get("pv")! : paramMap.get("processVersion")!,
					Number.parseInt(paramMap.get("rpage")!, 10),
					paramMap.get("workspaceId")!,
					paramMap.get("processVersion")!,
					this.resultsSize,
				);

				this.searchService
					.getOverview(
						this.workspaces.currentWorkspace.id,
						this.route.snapshot.paramMap.get("processVersion")!,
						paramMap.get("term")!,
						paramMap.get("scope")!,
					)
					.then((overviewResults: OverviewResults) => {
						this.overviewResultsFromOtherWorkspaces = overviewResults.other_workspaces_count[0].doc_count;
						this.changeDetector.detectChanges();
					});

				return results.pipe(tap((res: ResultsContainer) => this.initializeFilter(res, paramMap)));
			}),
			catchError(() => {
				this.searchEngineAvailable = false;
				this.loading = false;
				return EMPTY;
			}),
		);
	}

	initializeFilter(results: ResultsContainer, paramMap: ParamMap): void {
		const categories = this.searchService.setCategories(paramMap);
		this.page = Number.parseInt(paramMap.get("rpage")!, 10);
		this.term = paramMap.get("term")!;
		this.searchEngineAvailable = true;
		this.totalResultsCount = results.total;
		this.titleService.setTitle(this.translateService.instant("search.history.entry", { ARG0: this.term }));

		results.results.forEach((result: Result) => {
			if (result.alternatives) {
				this.addDropdown(result);
			}
		});

		this.typeFilterOptions = {};
		if (results.filters.types && this.totalResultsCount > 0) {
			results.filters.types.forEach((displayTypeName: string) => {
				this.typeFilterOptions[displayTypeName] = categories && categories.indexOf(displayTypeName) !== -1;
			});
			categories.forEach((category: string) => {
				if (!this.typeFilterOptions[category]) {
					this.typeFilterOptions[category] = true;
				}
			});
			this.cachedTypeFilterOptions = this.typeFilterOptions;
		} else if (categories.length !== 0) {
			this.typeFilterOptions = this.cachedTypeFilterOptions;
		}
		this.loading = false;
	}

	ngOnDestroy(): void {
		this.searchService.resetSearchSettings();
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	close(): void {
		this.router.navigate([{ outlets: { dialog: null } }], {
			relativeTo: this.route.parent,
		});
	}

	goToPage(page: number, scope?: string): void {
		this.router.navigate(
			[
				{
					term: this.route.snapshot.paramMap.get("term"),
					rpage: page,
					scope: scope ? scope : this.route.snapshot.paramMap.get("scope"),
					pv: this.route.snapshot.paramMap.get("pv"),
					categories: this.searchService.setCategories(this.route.snapshot.paramMap),
				},
			],
			{ relativeTo: this.route },
		);
	}

	private addDropdown(result: Result): void {
		if (result.alternatives) {
			result.dropdown = result.alternatives.map((alternative: Result) => {
				return {
					translated: this.getMenuItemName(alternative, this.isFile(alternative)),
					disabled: false,
					on: (): void => {
						const commands = this.createResultLink(alternative);
						this.router.navigate(commands, { relativeTo: this.route });
					},
				};
			});

			if (this.isFile(result)) {
				result.dropdown.unshift({
					translated: this.getMenuItemName(result, this.isFile(result)),
					disabled: false,
					on: (): void => {
						const commands = this.createResultLink(result);
						this.router.navigate(commands, { relativeTo: this.route });
					},
				});
			}
		}
	}

	createResultLink = (searchResult: Result): unknown[] => {
		if (!searchResult) {
			// eslint-disable-next-line unicorn/error-message -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			throw new Error("");
		}

		return this.buildRouterLink(searchResult, createCommands(searchResult));
	};

	private buildRouterLink(searchResult: Result, commands: unknown[]): unknown[] {
		return this.searchService.preserveSecondaryWorkspaceInSearchResultNavigation(
			this.workspaces,
			searchResult.workspaceId,
			searchResult.processId,
		)
			? [
					"/",
					"workspace",
					searchResult.workspaceId,
					searchResult.processVersionIdentifier,
					{
						swid: this.mainService.secondaryWorkspaceId,
						spv: this.mainService.secondaryProcessVersion,
						smode: this.mainService.secondaryMode,
					},
					{ outlets: { primary: commands, dialog: null } },
			  ]
			: [
					"/",
					"workspace",
					searchResult.workspaceId,
					searchResult.processVersionIdentifier,
					{ outlets: { primary: commands, dialog: null } },
			  ];
	}

	private getMenuItemName(resultOrAlternative: Result, isFile: boolean): string {
		if (isFile) {
			this.translateFileContainerLabel(resultOrAlternative as FileResult);
		}
		return resultOrAlternative.workspaceName;
	}

	private translateFileContainerLabel(result: FileResult): string {
		return this.translateService.instant("search.result.results.file.container.label", {
			elementName: result.containerElementName,
			workspaceName: result.workspaceName,
		});
	}

	isFile(searchResult: Result): boolean {
		return searchResult.type.ident === "file";
	}

	getFileUrl(fileId: number, workspaceId: number): string {
		return `file/${fileId}?workspaceId=${workspaceId}`;
	}

	isFileDownloadable(file: FileResult): boolean {
		if (file.physicalPath && !file.fileContentExists) {
			return false;
		}

		return true;
	}

	hasAttributes(result: Result): boolean {
		return Object.keys(result.attributes).length > 0;
	}

	hasDependentElementNames(result: Result): boolean {
		return result.dependentElementHits.length > 0;
	}

	getDependentElementIconClasses(dependentElement: DependentElementHit): string[] {
		return [
			"ico",
			"ico-et-" + dependentElement.type,
			"ico-tag",
			"ico-et-" + dependentElement.subtype,
			"dependent-element-icon",
		];
	}

	getDependentElementTooltip(dependentElement: DependentElementHit): Observable<string> {
		const messageKey = "process.element.type.singular." + dependentElement.type + "." + dependentElement.subtype;
		return this.translateService.get(messageKey);
	}
}

function createCommands(searchResult: Result): unknown[] {
	switch (searchResult.type.ident) {
		case "process":
			return ["process"];
		case "file":
			return [
				"process",
				(searchResult as FileResult).containerElementTypeIdent,
				(searchResult as FileResult).containerElementIdentity,
			];
		case "description":
			return ["management", "descriptions", searchResult.identity];
		case "tailoring":
			return ["management", "tailoring", searchResult.identity];
		case "enactmentactivator":
			return ["management", "enactment", "activator", searchResult.identity];
		case "enactmentscript":
			return ["management", "enactment", "script", searchResult.identity];
		case "enactmentproperty":
			return ["management", "enactment", "property", searchResult.identity];
		default:
			return ["process", searchResult.type.ident, searchResult.identity];
	}
}
