import { Injectable } from "@angular/core";
import { CmsBrowserResource } from "core/stages-client";

type BrowserRepository = stages.management.files.browser.BrowserRepository;
type BrowserItem = stages.management.files.browser.BrowserItem;

@Injectable({ providedIn: "root" })
export class CmsBrowserService {
	static readonly SEPARATOR = "/";

	constructor(private readonly cmsBrowserResource: CmsBrowserResource) {}

	async getRepositories(
		workspaceId: string,
		processVersion: string,
		scope: string,
		id: string,
	): Promise<BrowserRepository[]> {
		return this.cmsBrowserResource.getBrowserRepositories(workspaceId, scope, id, { pv: processVersion });
	}

	async getItem(
		workspaceId: string,
		processVersion: string,
		scope: string,
		id: string,
		repositoryId: string,
		path: string,
		sortKey: string,
		sortOrder: string,
	): Promise<BrowserItem> {
		return this.cmsBrowserResource.getItem(workspaceId, scope, id, repositoryId, {
			path: path,
			pv: processVersion,
			sortKey: sortKey,
			sortOrder: sortOrder,
		});
	}
}
