import { Pipe, PipeTransform } from "@angular/core";
import { Selectable } from "common/selection/state/selectable.model";

@Pipe({
	name: "isSelected",
})
export class IsSelectedFilterPipe implements PipeTransform {
	transform(typeId: stages.core.TypedId, selectedElements: Selectable[]): boolean {
		if (!selectedElements) {
			return false;
		}

		const selection = selectedElements.find(
			(e) => e.id === typeId.id && e.type.toLocaleLowerCase() === typeId.typeIdent.toLocaleLowerCase(),
		);

		return selection ? selection.isSelected : false;
	}
}
