import { Injectable } from "@angular/core";
import { Folder } from "common/associations/association-browser.interface";
import { AssociationCommentService, Comments } from "common/associations/association-comment.service";
import { BrowseAssociationStore } from "common/associations/browse-association-store.interface";
import { Selectable } from "common/selection/state/selectable.model";
import { getIdKeyOfTypeId, SelectionStoreService } from "common/selection/state/selection-store.service";
import { nullToUndefined } from "core/functions";
import { ProcessElementsResource } from "core/stages-client";

type TreeElement = stages.process.TreeElement;

export interface AssociationTarget {
	element: Folder;
	isSelectable: boolean;
	initiallySelected: boolean;
	hasChildren: boolean;
	comment: string | null;
	isCommentEditable: boolean;
}

export interface ExistingAssociationTarget {
	type: {
		ident: string;
	};
	id: string;
	identity: string;
}

export interface ExistingAssociation<T extends ExistingAssociationTarget = ExistingAssociationTarget> {
	targetElement: T | null;
	comment: string | null;
	combined: boolean | null;
	identity: string | null;
}

export interface AssociationGroup<T extends ExistingAssociationTarget = ExistingAssociationTarget> {
	targetSubtypes?: string[] | null;
	targetDependentTypes?: string[];
	commentSupported?: boolean;
	list: ExistingAssociation<T>[];
	subgroups?: AssociationSubgroup<T>[];
}

export interface AssociationSubgroup<T extends ExistingAssociationTarget = ExistingAssociationTarget> {
	list: ExistingAssociation<T>[];
}

export interface AssociationSource<T extends ExistingAssociationTarget = ExistingAssociationTarget> {
	id: string;
	label?: string;
	dependent?: boolean;
	associations: Record<string, AssociationGroup<T>> | null;
	type: {
		ident: string;
		subtypeIdent: string | null;
	};
}

@Injectable({ providedIn: "root" })
export class AssociationBrowserService {
	constructor(
		private readonly associationCommentService: AssociationCommentService,
		private readonly selectionStoreService: SelectionStoreService,
		private readonly processElementsResource: ProcessElementsResource,
	) {}

	static defaultStoreIdentifier = "assoc";

	newAssociationComments(sourceElement: AssociationSource, groupPath: string, targets: AssociationTarget[]): Comments {
		const namespace = `assoc.comments.${sourceElement.type.ident}${sourceElement.id}_${groupPath}`;
		return this.associationCommentService.loadComments(targets, namespace, getElementKey);
	}

	getTargetElements(
		sourceElement: AssociationSource,
		associationsGroup: AssociationGroup | undefined,
		elements: Folder[],
		associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
	): AssociationTarget[] {
		const targets: AssociationTarget[] = [];
		const targetSubtypes = associationsGroup ? associationsGroup.targetSubtypes : undefined;
		elements.forEach((element) => {
			const matchingAssoc = associationsGroup
				? findMatchingAssociation(this.getCombinedList(associationsGroup), element.id)
				: null;
			const initiallySelected = matchingAssoc !== null;
			const comment = matchingAssoc !== null && matchingAssoc.comment !== null ? matchingAssoc.comment : null;

			const target = {
				element: element,
				isSelectable: associationStore.isSelectableInBrowser(
					matchingAssoc,
					sourceElement,
					element,
					nullToUndefined(targetSubtypes),
					associationsGroup?.targetDependentTypes,
				),
				initiallySelected: initiallySelected,
				hasChildren: hasChildren(element),
				comment: comment,
				isCommentEditable: isCommentEditable(associationStore, associationsGroup, matchingAssoc),
			};

			targets.push(target);
		});
		return targets;
	}

	getChildrenOfFolder(
		sourceElement: AssociationSource,
		associationsGroup: AssociationGroup | undefined,
		folder: Folder,
		associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
	): Selectable[] {
		const targets: Selectable[] = [];
		const targetSubtypes = associationsGroup ? associationsGroup.targetSubtypes : undefined;

		const children = folder.children;
		if (children) {
			children.forEach((element) => {
				const matchingAssoc = associationsGroup
					? findMatchingAssociation(this.getCombinedList(associationsGroup), element.id)
					: null;
				const comment = matchingAssoc !== null && matchingAssoc.comment !== null ? matchingAssoc.comment : null;

				const typedId = getIdKeyOfTypeId({ id: element.id, typeIdent: element.type.ident });

				const target: Selectable = {
					id: element.id,
					type: element.type.ident,
					typeId: typedId,
					identity: AssociationBrowserService.defaultStoreIdentifier + "_" + typedId,
					parentId: null,
					parentType: null,
					storeIdentifier: AssociationBrowserService.defaultStoreIdentifier,
					initiallySelected: true,
					indeterminate: false,
					selectableChildrenCount: null,
					initiallySelectedCount: null,
					totalChildrenCount: null,
					isSelected: true,
					wasUpdated: false,
					isCommentEditable: isCommentEditable(associationStore, associationsGroup, matchingAssoc),

					isSelectable: associationStore.isSelectableInBrowser(
						matchingAssoc,
						sourceElement,
						element,
						nullToUndefined(targetSubtypes),
						associationsGroup?.targetDependentTypes,
					),
					comment: comment,
				};

				targets.push(target);
			});
		}

		return targets;
	}

	getSelectablesFromAssociations(
		associations: ExistingAssociation[],
		associationsGroup: AssociationGroup | undefined,
		associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
	): Selectable[] {
		const selectables: Selectable[] = [];

		// TODO extract to SelectionStoreService.createInitialSelectables()
		associations?.forEach((element) => {
			if (!element.targetElement) {
				// in this case we have a comment only element and can break this loop
				return;
			}
			const matchingAssoc = associationsGroup
				? findMatchingAssociation(this.getCombinedList(associationsGroup), element.targetElement.id)
				: null;
			const targetElementTypeIdent = element.targetElement.type.ident;

			const typedId = getIdKeyOfTypeId({ id: element.targetElement.id, typeIdent: targetElementTypeIdent });

			const selectable: Selectable = {
				id: element.targetElement.id,
				type: targetElementTypeIdent,
				typeId: typedId,
				identity: AssociationBrowserService.defaultStoreIdentifier + "_" + typedId,
				parentId: null,
				parentType: null,
				storeIdentifier: AssociationBrowserService.defaultStoreIdentifier,
				isSelectable: true,
				initiallySelected: true,
				indeterminate: false,
				selectableChildrenCount: null,
				initiallySelectedCount: null,
				totalChildrenCount: null,
				comment: element.comment?.length === 0 ? null : element.comment,
				isSelected: true,
				wasUpdated: false,
				isCommentEditable: isCommentEditable(associationStore, associationsGroup, matchingAssoc),
			};
			selectables.push(selectable);
		});
		return selectables;
	}

	async getTreeElements(
		elementTypes: string[],
		browseWorkspaceId: string,
		associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
	): Promise<TreeElement[]> {
		if (!associationStore.getTree) {
			throw new Error("Method not implemented by " + typeof associationStore);
		}
		const treeElements = await associationStore.getTree(browseWorkspaceId, elementTypes);
		return treeElements;
	}

	reset(): void {
		this.selectionStoreService.resetCommon();
	}

	async getScopeElements(
		workspaceId: string,
		processVersion: string,
		referenceWorkspaceId: string,
	): Promise<stages.core.TypedId[]> {
		return this.processElementsResource.getAllElementsInScope(workspaceId, {
			pv: processVersion,
			referenceModelWorkspaceId: referenceWorkspaceId,
		});
	}

	getCombinedList(associationGroup: AssociationGroup): ExistingAssociation[] {
		if (associationGroup.subgroups && associationGroup.subgroups.length > 0) {
			const combinedList: ExistingAssociation[] = [];
			associationGroup.subgroups.forEach((subgroup) => {
				combinedList.push(...subgroup.list);
			});
			return combinedList;
		}
		return associationGroup.list;
	}

	async getCombinedAssociations(
		targetElementIdentities: string[],
		currentWorkspaceId: string,
		sourceElementType: string,
		sourceElementIdentity: string,
		path: string,
		page: number | undefined,
		pageSize: number | undefined,
		pv: string,
	): Promise<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>> {
		return this.processElementsResource.getCombinedAssociations(
			currentWorkspaceId,
			sourceElementType,
			sourceElementIdentity,
			{ combinedTargetElementIdentities: targetElementIdentities, path: path, page: page, pageSize: pageSize, pv: pv },
		) as Promise<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>>;
	}
}

function findMatchingAssociation(
	associations: ExistingAssociation[],
	expectedTargetElementId: string,
): ExistingAssociation | null {
	for (const assoc of associations) {
		if (assoc.targetElement && assoc.targetElement.id === expectedTargetElementId) {
			return assoc;
		}
	}
	return null;
}

function hasChildren(element: Folder): boolean {
	return element.children !== undefined && element.children.length > 0;
}

export function getElementKey(element: Folder): string {
	return element.identity && element.id;
}

function isModifyRight(
	associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
	matchingAssoc: ExistingAssociation | null,
): boolean {
	if (!matchingAssoc) {
		return true;
	}
	return associationStore.getActions(matchingAssoc).MODIFY;
}

function isCommentEditable(
	associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
	associationsGroup: AssociationGroup | undefined,
	matchingAssoc: ExistingAssociation | null,
): boolean {
	return associationsGroup
		? associationsGroup.commentSupported !== undefined &&
				associationsGroup.commentSupported &&
				isModifyRight(associationStore, matchingAssoc)
		: false;
}
