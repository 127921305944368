import { ActivatedRoute } from "@angular/router";
import { ViewService } from "core/view.service";
import { LabelData } from "navigation/list/navigation-entry.interface";
import { Store } from "navigation/list/navigation-store.interface";
import { AddService } from "process/element/add/add.service";
import { DeleteService } from "process/element/delete/delete.service";
import { EditService } from "process/element/edit/edit.service";
import { PasteService } from "process/element/paste/paste.service";
import { RearrangeService } from "process/element/rearrange/rearrange.service";
import { ModuleService } from "process/modules/module.service";

export class ProcessStore implements Store<stages.process.ViewableElement, stages.process.ViewableType> {
	constructor(
		private editService: EditService,
		private addService: AddService,
		private deleteService: DeleteService,
		private pasteService: PasteService,
		private rearrangeService: RearrangeService,
		private moduleService: ModuleService,
		private viewService: ViewService,
	) {}

	getTypeIconPrefix(): string {
		return "et";
	}

	getUppermostUpRoute(): unknown[] {
		return ["home"];
	}

	getRoute(entry: stages.process.ViewableElement): unknown[] {
		return ["process", entry.type.ident, entry.identity];
	}

	restoreRouteFromParams(params: stages.process.ViewableElement): unknown[] {
		return ["process", params.type, params.identity];
	}

	getRouteForClipboardWorkspaceChange(activeEntry: stages.process.ViewableElement): unknown[] {
		return ["process", activeEntry.type.ident, "index"];
	}

	getFilterForClipboardWorkspaceChange(
		activeEntry: stages.process.ViewableElement,
	): { filterBy: string; filterParam1: string; filterParam2: string } | null {
		return {
			filterBy: "ELEMENT_TYPE",
			filterParam1: activeEntry.type.ident,
			filterParam2: "CREATE",
		};
	}

	getPopupBaseRoute(): unknown[] {
		return ["process"];
	}

	getBaseRoute(activatedRoute: ActivatedRoute): ActivatedRoute {
		return activatedRoute.parent!.parent!.parent!;
	}

	getIdentifier(entry: stages.process.ViewableElement): string {
		return entry.type.ident + entry.id;
	}

	getSelectionIdentifier(): string {
		return "process.navigation.selection";
	}

	getLabel(entry: stages.process.ViewableElement): LabelData {
		return {
			label: entry.label,
			labelTranslate: undefined,
			labelClasses: entry.tailored ? "tailored" : "",
			secondLine: entry.type.name === null ? undefined : entry.type.name,
			secondLineTranslate: undefined,
			valueForAlphabeticalSort: entry.label,
		};
	}

	async rename(
		entry: stages.process.ViewableElement,
		newLabel: string,
		workspaceId: string,
		pv: string,
	): Promise<void> {
		return this.editService.renameElement(entry, entry.type.ident, entry.identity, newLabel, workspaceId, pv);
	}

	async addChild(
		parent: stages.process.ViewableElement,
		type: stages.process.ViewableType,
		newLabel: string,
		workspaceId: string,
		pv: string,
	): Promise<stages.process.ViewableElement> {
		return this.addService.addChild(parent, type, newLabel, workspaceId, pv, false).then((child) => {
			this.viewService.notifyModified();
			return child;
		});
	}

	async deleteSingle(entry: stages.process.ViewableElement, workspaceId: string, pv: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.deleteService.deleteSingleElement(
				workspaceId,
				pv,
				entry,
				() => {
					resolve();
				},
				() => {
					reject("canceled");
				},
			);
		});
	}

	async rearrange(
		parent: stages.process.ViewableElement,
		sortedEntries: stages.process.ViewableElement[],
		sortOrder: "alphabetical" | "custom",
		workspaceId: string,
		pv: string,
	): Promise<stages.process.ViewableElement[]> {
		return this.rearrangeService
			.rearrange(workspaceId, pv, parent, sortOrder === "alphabetical", sortedEntries)
			.then(() => sortedEntries);
	}

	async move(
		targetEntry: stages.process.ViewableElement,
		entries: stages.process.ViewableElement[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		return this.pasteService.pasteElements(targetEntry, "move", entries, workspaceId, pv);
	}

	async copy(
		targetEntry: stages.process.ViewableElement,
		entries: stages.process.ViewableElement[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		return this.pasteService.pasteElements(targetEntry, "copy", entries, workspaceId, pv);
	}

	async delete(entries: stages.process.ViewableElement[], workspaceId: string, pv: string): Promise<void> {
		return this.deleteService.deleteElements(workspaceId, pv, entries);
	}

	async executeCommand(
		commandType: "overwrite" | "restore",
		entry: stages.process.ViewableElement,
		workspaceId: string,
		pv: string,
	): Promise<stages.process.ViewableElement> {
		switch (commandType) {
			case "overwrite":
				return this.moduleService.overwriteSingleElement(workspaceId, pv, entry).then(() => {
					overwriteDone(entry);
					return entry;
				});
			case "restore":
				return new Promise<stages.process.ViewableElement>((resolve, reject) => {
					this.moduleService.restoreSingleElement(
						workspaceId,
						pv,
						entry,
						() => {
							restoreDone(entry);
							resolve(entry);
						},
						() => {
							reject("canceled");
						},
					);
				});
			default:
				throw new Error(`There is no implementation of ${commandType}`);
		}
	}

	async executeBatchCommand(
		commandType: "overwrite" | "restore",
		_targetEntry: stages.process.ViewableElement,
		entries: stages.process.ViewableElement[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		switch (commandType) {
			case "overwrite":
				return this.moduleService
					.overwriteElements(workspaceId, pv, entries)
					.then(() => entries.forEach(overwriteDone));
			case "restore":
				return this.moduleService.restoreElements(workspaceId, pv, entries).then(() => entries.forEach(restoreDone));
			default:
				throw new Error(`There is no implementation of ${commandType}`);
		}
	}

	getTypeMessageKeySingular(type: stages.process.ViewableType): string {
		return this.viewService.getTypeMessageKeySingular(type);
	}

	getIconClassesForType(type: stages.process.ViewableType): string[] {
		return this.viewService.getIconClasses(type, false, false, "ico");
	}

	invalidate(): void {}
}

function overwriteDone(entry: stages.process.ViewableElement): void {
	entry.allowedOperations.OVERWRITE = false;
	entry.allowedOperations.RESTORE = true;
}

function restoreDone(entry: stages.process.ViewableElement): void {
	entry.allowedOperations.OVERWRITE = true;
	entry.allowedOperations.RESTORE = false;
}
