import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AssociationSource } from "common/associations/association-browser.service";
import { Association, AssociationTarget } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { CombinedAssociationsStore } from "common/associations/combined-association-detail-view/combined-association-store.logic";
import { MutexService } from "common/concurrency/mutex.service";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { MainService } from "core/main.service";
import { DataViewComponent } from "common/data/data-view.component";
import { ProcessElementsResource } from "core/stages-client";

@Component({
	selector: "stages-combined-association-detail-view.component.ts",
	templateUrl: "./combined-association-detail-view.component.html",
	styleUrls: ["./combined-association-detail-view.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinedAssociationDetailViewComponent implements OnInit {
	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	@ViewChild("dataView", { static: true })
	dataView!: DataViewComponent<AssociationTarget>;

	dataSource: PageableDataSource<Association<stages.process.RemoteTargetElement>>;
	pageSize = 10;

	associationStore: AssociationStore<AssociationSource<AssociationTarget>, AssociationTarget>;

	assocType: Promise<string> | null;
	targetElementLabel!: string;

	constructor(
		private mutexService: MutexService,
		private mainService: MainService,
		private processElementsResource: ProcessElementsResource,
		private router: Router,
		private route: ActivatedRoute,
	) {
		this.associationStore = this.route.snapshot.data.associationStore as AssociationStore<
			AssociationSource<AssociationTarget>,
			AssociationTarget
		>;

		this.dataSource = new PageableDataSource(
			new CombinedAssociationsStore(this.associationStore, this.pageSize, this.processElementsResource),
			this.mutexService,
			router,
			route,
			"page",
		);
		const path = this.route.snapshot.paramMap.get("path")!;
		const groupBy = this.route.snapshot.paramMap.get("groupBy");
		this.assocType = this.associationStore.getAssociationGroupTranslateable
			? this.associationStore.getAssociationGroupTranslateable(path, groupBy)
			: null;
		this.targetElementLabel = this.route.snapshot.paramMap.get("targetElementLabel")!;
	}

	ngOnInit(): void {
		this.dataView.mode = this.route.snapshot.data.mode;
	}

	async close(): Promise<void> {
		await this.dialog.close();
	}

	async navigateAndClose(association: Association<stages.process.RemoteTargetElement>): Promise<void> {
		await this.close();
		if (association.targetElement) {
			let targetElement = association.targetElement;
			const workspaceId = targetElement.workspace.id;
			const processVersion = targetElement.process.pv;
			if (targetElement.dependent && targetElement.parent) {
				targetElement = targetElement.parent as stages.process.RemoteTargetElement;
			}

			const commands: unknown[] = ["/", "workspace", workspaceId, processVersion];
			if (
				this.mainService.secondaryMode &&
				this.mainService.secondaryWorkspaceId &&
				this.mainService.secondaryProcessVersion &&
				!association.isProcessInterface
			) {
				commands.push({
					swid: this.mainService.secondaryWorkspaceId,
					spv: this.mainService.secondaryProcessVersion,
					smode: this.mainService.secondaryMode,
				});
			}

			await this.router.navigate([...commands, "process", targetElement.type.ident, targetElement.identity]);
		}
	}

	getAssocId(association: Association<unknown>): string {
		return association.id;
	}

	isDisabled(association: Association<unknown>): boolean {
		return !association.actions!.DELETE;
	}

	getProcessInterfaceIconClasses(association: Association<AssociationTarget>): string[] {
		if (!association.isProcessInterface || !association.targetElement) {
			return [];
		}

		const classes: string[] = [];
		classes.push("ico");

		if (association.targetElement.process?.isValidVersion) {
			if (
				association.targetElement.process.isWorkingRevision &&
				this.route.snapshot.paramMap.get("processVersion") === "_wv"
			) {
				classes.push("ico-change-workspace-w");
			} else {
				classes.push("ico-change-workspace-v");
			}
		} else if (association.targetElement.process?.isWorkingRevision) {
			classes.push("ico-change-workspace-w");
		} else {
			classes.push("ico-change-workspace");
		}

		return classes;
	}

	async delete(): Promise<void> {
		await this.dataView.dataSource.delete(this.dataView.selection.selected, "del-from-combi-assoc");
		this.dataView.selection.clear();
		await this.dialog.close();
	}
}
