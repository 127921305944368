<footer>
	<stages-buttons-bottom [removeMargin]="removeMargin">
		<button *ngIf="hasCancelButton" class="button sm cancel" translate="cancel" (click)="close()"></button>
		<ng-container *ngFor="let button of buttons; let i = index">
			<button
				*ngIf="button.visible!()"
				(click)="button.click()"
				[class]="button.class"
				[translate]="button.translate"
				[disabled]="button.disabled!()"
				[tabindex]="i + tabIndexOffset"
			></button>
		</ng-container>
		<ng-content></ng-content>
	</stages-buttons-bottom>
</footer>
