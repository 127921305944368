import { Component, Input } from "@angular/core";
import { CardComponent } from "common/card/card.component";

@Component({
	selector: "stages-toolbar",
	templateUrl: "./toolbar.component.html",
	styleUrls: ["toolbar.component.scss"],
})
export class ToolbarComponent {
	@Input()
	toolbarId?: string;

	@Input()
	items?: unknown;

	isIE: boolean = false;

	constructor(readonly card: CardComponent) {
		this.isIE = card.isIE;
	}
}
