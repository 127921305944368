import { Injectable } from "@angular/core";
import { ListNavigationEntry } from "navigation/list/navigation-entry.interface";

@Injectable({ providedIn: "root" })
export class NavigationRearrangeListService {
	private _rearrangeList: ListNavigationEntry[] = [];

	get rearrangeList(): ListNavigationEntry[] {
		return this._rearrangeList;
	}

	set rearrangeList(value: ListNavigationEntry[]) {
		this._rearrangeList = value;
	}

	moveNavigationEntry(oldIndex: number, newIndex: number): void {
		const entry = this._rearrangeList[oldIndex];
		this._rearrangeList.splice(oldIndex, 1);
		this._rearrangeList.splice(newIndex, 0, entry);
	}
}
