import { animate, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { ProcessVersionService } from "common/version/process-version.service";
import { MainService } from "core/main.service";
import { NotificationResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { Observable } from "rxjs";

type ProcessView = stages.process.ProcessView;

@Component({
	selector: "stages-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["notification.component.scss"],
	animations: [
		trigger("inOut", [
			transition(":leave", [
				style({ opacity: 1 }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ opacity: 0 })),
			]),
		]),
	],
})
export class NotificationComponent extends BaseComponent {
	self$: Observable<ProcessView>;
	params$: Observable<Params>;
	visible: boolean = true;

	constructor(
		componentService: ComponentService,
		private mainService: MainService,
		private viewService: ViewService,
		private route: ActivatedRoute,
		private router: Router,
		private versionService: ProcessVersionService,
		private mutexService: MutexService,
		private notificationResource: NotificationResource,
	) {
		super(componentService);
		this.self$ = this.viewService.awaitSelfElementObservable();
		this.params$ = this.route.paramMap;
	}

	markAsSeen(processId: string): void {
		this.mutexService.invoke("markAsSeen" + processId, async () =>
			this.notificationResource
				.markAsSeen({
					objectType: "Process",
					objectId: processId,
				})
				.then(() => {
					this.visible = false;
					this.viewService.refresh(this.route.snapshot.paramMap);
				}),
		);
	}

	open(previousValidVersionIdentifier: string, previousValidVersionDiffValid: boolean): void {
		if (previousValidVersionDiffValid) {
			const commands: unknown[] = [
				"workspace",
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
			];
			commands.push({
				swid: this.route.snapshot.paramMap.get("workspaceId")!,
				spv: previousValidVersionIdentifier,
				smode: "compare",
			});
			commands.push("process");
			this.router.navigate(commands);
		} else {
			this.mutexService.invoke("compare", async () =>
				this.versionService
					.diff(
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
						this.route.snapshot.paramMap.get("workspaceId")!,
						previousValidVersionIdentifier,
					)
					.then(async (jobIdentifier) => {
						return this.router.navigate(
							[
								{
									outlets: {
										popup: [
											"process",
											"compare",
											"progress",
											{
												jobName: jobIdentifier.name,
												compareWorkspace: this.route.snapshot.paramMap.get("workspaceId")!,
												compareVersion: previousValidVersionIdentifier,
											},
										],
									},
								},
							],
							{ relativeTo: this.mainService.findPopupContainer(this.route) },
						);
					}),
			);
		}
	}
}
