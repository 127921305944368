import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { PluginRegistry } from "common/editor/description.service";
import { ProcessDescriptionService } from "process/description/process-description.service";

type Translation = stages.process.translate.Translation;

@Component({
	selector: "stages-edit-translations",
	templateUrl: "edit-translations.component.html",
	styleUrls: ["edit-translations.component.scss"],
})
export class EditTranslationsComponent implements OnInit, OnDestroy {
	@Input()
	translation!: Translation;

	@Input()
	beanType!: string;

	@Input()
	beanId!: string;

	@Input()
	beanIdentity!: string;

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	@Output() readonly submitted = new EventEmitter<Translation>();

	@Output() readonly cancelled = new EventEmitter<Translation>();

	pluginRegistry: PluginRegistry;

	form: FormGroup = new FormGroup({});

	@ViewChild("descriptionA")
	descriptionA!: DescriptionEditorComponent;

	@ViewChild("descriptionB")
	descriptionB!: DescriptionEditorComponent;

	leftDescriptionViewable: boolean = false;

	isSaving: boolean = false;

	constructor(private descriptionService: ProcessDescriptionService) {
		this.pluginRegistry = this.descriptionService.getPluginRegistry();
	}

	ngOnInit(): void {
		this.form = new FormGroup({});

		this.translation.languages.forEach((language) => {
			const validators = [Validators.maxLength(255), Validators.pattern(/^[^|]+$/)];
			if (language.id === this.translation.defaultLanguage && !this.translation.isIndexElement) {
				validators.push(Validators.required);
			}

			const nameControl = new FormControl(this.translation.translatedElements[language.id!].name, validators);

			this.form.addControl(
				language.id!,
				new FormGroup({
					name: nameControl,
				}),
			);
		});
	}

	ngOnDestroy(): void {
		if (this.descriptionA) {
			this.descriptionA.quit();
		}

		if (this.descriptionB) {
			this.descriptionB.quit();
		}
	}

	save(translation: Translation): void {
		this.isSaving = true;
		this.updateModel(translation);
		this.submitted.emit(translation);
	}

	cancel(translation: Translation): void {
		this.cancelled.emit(translation);
	}

	onLanguageChangeA(translation: Translation, languageA: string): void {
		this.updateModel(translation);
		translation.languageA = languageA;

		if (translation.languageB === languageA) {
			const newLanguageB = translation.languages.find((language) => language.id !== languageA);
			translation.languageB = newLanguageB!.id!;
		}
	}

	onLanguageChangeB(translation: Translation, languageB: string): void {
		this.updateModel(translation);
		translation.languageB = languageB;

		if (translation.languageA === languageB) {
			const newLanguageA = translation.languages.find((language) => language.id !== languageB);
			translation.languageA = newLanguageA!.id!;
		}
	}

	private updateModel(translation: Translation): void {
		translation.translatedElements[translation.languageA].displayDescription = this.descriptionA.getDescription();
		translation.translatedElements[translation.languageB].displayDescription = this.descriptionB.getDescription();
		if (!translation.isIndexElement) {
			translation.languages.forEach((language) => {
				translation.translatedElements[language.id!].name = this.form.get(language.id!)!.get("name")!.value;
			});
		}
	}
}
