<ng-container *ngIf="workspaceView$ | async as workspaceView">
	<stages-description
		*ngIf="self$ | async as self"
		[beanGuid]="self.guid"
		[beanType]="self.type.ident"
		[beanIdentity]="self.identity"
		[beanId]="self.id"
		[modifiable]="!!self.allowedOperations.MODIFY"
		[processTypeIdent]="self.processType"
		[unsafe]="self.hasUnsafeDescription"
		[displayDescription]="$any(getDescription($any(self)))"
		[descriptionService]="descriptionService"
		[workspaceId]="workspaceView.currentWorkspace.id"
		[pv]="currentProcessVersionIdentifier(workspaceView.currentWorkspace.viewedProcess)!"
		(afterSave)="afterSave($event)"
	>
	</stages-description>
</ng-container>
