import { isBlank } from "common/editor/shared/textual-reference";

/**
 * Static string of valid Base64 chars.
 */
const BASE64_CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

/**
 * A conversion map to convert named HTML entities to their numerical representation.
 */

const ENTITY_NAMED_MAP: Record<string, string> = {
	"&quot;": "&#34;",
	"&amp;": "&#38;",
	"&apos;": "&#39;",
	"&lt;": "&#60;",
	"&gt;": "&#62;",
	"&nbsp;": "&#160;",
	"&iexcl;": "&#161;",
	"&cent;": "&#162;",
	"&pound;": "&#163;",
	"&curren;": "&#164;",
	"&yen;": "&#165;",
	"&brvbar;": "&#166;",
	"&sect;": "&#167;",
	"&uml;": "&#168;",
	"&copy;": "&#169;",
	"&ordf;": "&#170;",
	"&laquo;": "&#171;",
	"&not;": "&#172;",
	"&shy;": "&#173;",
	"&reg;": "&#174;",
	"&macr;": "&#175;",
	"&deg;": "&#176;",
	"&plusmn;": "&#177;",
	"&sup2;": "&#178;",
	"&sup3;": "&#179;",
	"&acute;": "&#180;",
	"&micro;": "&#181;",
	"&para;": "&#182;",
	"&middot;": "&#183;",
	"&cedil;": "&#184;",
	"&sup1;": "&#185;",
	"&ordm;": "&#186;",
	"&raquo;": "&#187;",
	"&frac14;": "&#188;",
	"&frac12;": "&#189;",
	"&frac34;": "&#190;",
	"&iquest;": "&#191;",
	"&Agrave;": "&#192;",
	"&Aacute;": "&#193;",
	"&Acirc;": "&#194;",
	"&Atilde;": "&#195;",
	"&Auml;": "&#196;",
	"&Aring;": "&#197;",
	"&AElig;": "&#198;",
	"&Ccedil;": "&#199;",
	"&Egrave;": "&#200;",
	"&Eacute;": "&#201;",
	"&Ecirc;": "&#202;",
	"&Euml;": "&#203;",
	"&Igrave;": "&#204;",
	"&Iacute;": "&#205;",
	"&Icirc;": "&#206;",
	"&Iuml;": "&#207;",
	"&ETH;": "&#208;",
	"&Ntilde;": "&#209;",
	"&Ograve;": "&#210;",
	"&Oacute;": "&#211;",
	"&Ocirc;": "&#212;",
	"&Otilde;": "&#213;",
	"&Ouml;": "&#214;",
	"&times;": "&#215;",
	"&Oslash;": "&#216;",
	"&Ugrave;": "&#217;",
	"&Uacute;": "&#218;",
	"&Ucirc;": "&#219;",
	"&Uuml;": "&#220;",
	"&Yacute;": "&#221;",
	"&THORN;": "&#222;",
	"&szlig;": "&#223;",
	"&agrave;": "&#224;",
	"&aacute;": "&#225;",
	"&acirc;": "&#226;",
	"&atilde;": "&#227;",
	"&auml;": "&#228;",
	"&aring;": "&#229;",
	"&aelig;": "&#230;",
	"&ccedil;": "&#231;",
	"&egrave;": "&#232;",
	"&eacute;": "&#233;",
	"&ecirc;": "&#234;",
	"&euml;": "&#235;",
	"&igrave;": "&#236;",
	"&iacute;": "&#237;",
	"&icirc;": "&#238;",
	"&iuml;": "&#239;",
	"&eth;": "&#240;",
	"&ntilde;": "&#241;",
	"&ograve;": "&#242;",
	"&oacute;": "&#243;",
	"&ocirc;": "&#244;",
	"&otilde;": "&#245;",
	"&ouml;": "&#246;",
	"&divide;": "&#247;",
	"&oslash;": "&#248;",
	"&ugrave;": "&#249;",
	"&uacute;": "&#250;",
	"&ucirc;": "&#251;",
	"&uuml;": "&#252;",
	"&yacute;": "&#253;",
	"&thorn;": "&#254;",
	"&yuml;": "&#255;",
	"&OElig;": "&#338;",
	"&oelig;": "&#339;",
	"&Scaron;": "&#352;",
	"&scaron;": "&#353;",
	"&Yuml;": "&#376;",
	"&fnof;": "&#402;",
	"&circ;": "&#710;",
	"&tilde;": "&#732;",
	"&Alpha;": "&#913;",
	"&Beta;": "&#914;",
	"&Gamma;": "&#915;",
	"&Delta;": "&#916;",
	"&Epsilon;": "&#917;",
	"&Zeta;": "&#918;",
	"&Eta;": "&#919;",
	"&Theta;": "&#920;",
	"&Iota;": "&#921;",
	"&Kappa;": "&#922;",
	"&Lambda;": "&#923;",
	"&Mu;": "&#924;",
	"&Nu;": "&#925;",
	"&Xi;": "&#926;",
	"&Omicron;": "&#927;",
	"&Pi;": "&#928;",
	"&Rho;": "&#929;",
	"&Sigma;": "&#931;",
	"&Tau;": "&#932;",
	"&Upsilon;": "&#933;",
	"&Phi;": "&#934;",
	"&Chi;": "&#935;",
	"&Psi;": "&#936;",
	"&Omega;": "&#937;",
	"&alpha;": "&#945;",
	"&beta;": "&#946;",
	"&gamma;": "&#947;",
	"&delta;": "&#948;",
	"&epsilon;": "&#949;",
	"&zeta;": "&#950;",
	"&eta;": "&#951;",
	"&theta;": "&#952;",
	"&iota;": "&#953;",
	"&kappa;": "&#954;",
	"&lambda;": "&#955;",
	"&mu;": "&#956;",
	"&nu;": "&#957;",
	"&xi;": "&#958;",
	"&omicron;": "&#959;",
	"&pi;": "&#960;",
	"&rho;": "&#961;",
	"&sigmaf;": "&#962;",
	"&sigma;": "&#963;",
	"&tau;": "&#964;",
	"&upsilon;": "&#965;",
	"&phi;": "&#966;",
	"&chi;": "&#967;",
	"&psi;": "&#968;",
	"&omega;": "&#969;",
	"&thetasym;": "&#977;",
	"&upsih;": "&#978;",
	"&piv;": "&#982;",
	"&ensp;": "&#8194;",
	"&emsp;": "&#8195;",
	"&thinsp;": "&#8201;",
	"&zwnj;": "&#8204;",
	"&zwj;": "&#8205;",
	"&lrm;": "&#8206;",
	"&rlm;": "&#8207;",
	"&ndash;": "&#8211;",
	"&mdash;": "&#8212;",
	"&lsquo;": "&#8216;",
	"&rsquo;": "&#8217;",
	"&sbquo;": "&#8218;",
	"&ldquo;": "&#8220;",
	"&rdquo;": "&#8221;",
	"&bdquo;": "&#8222;",
	"&dagger;": "&#8224;",
	"&Dagger;": "&#8225;",
	"&bull;": "&#8226;",
	"&hellip;": "&#8230;",
	"&permil;": "&#8240;",
	"&prime;": "&#8242;",
	"&Prime;": "&#8243;",
	"&lsaquo;": "&#8249;",
	"&rsaquo;": "&#8250;",
	"&oline;": "&#8254;",
	"&frasl;": "&#8260;",
	"&euro;": "&#8364;",
	"&image;": "&#8465;",
	"&weierp;": "&#8472;",
	"&real;": "&#8476;",
	"&trade;": "&#8482;",
	"&alefsym;": "&#8501;",
	"&larr;": "&#8592;",
	"&uarr;": "&#8593;",
	"&rarr;": "&#8594;",
	"&darr;": "&#8595;",
	"&harr;": "&#8596;",
	"&crarr;": "&#8629;",
	"&lArr;": "&#8656;",
	"&uArr;": "&#8657;",
	"&rArr;": "&#8658;",
	"&dArr;": "&#8659;",
	"&hArr;": "&#8660;",
	"&forall;": "&#8704;",
	"&part;": "&#8706;",
	"&exist;": "&#8707;",
	"&empty;": "&#8709;",
	"&nabla;": "&#8711;",
	"&isin;": "&#8712;",
	"&notin;": "&#8713;",
	"&ni;": "&#8715;",
	"&prod;": "&#8719;",
	"&sum;": "&#8721;",
	"&minus;": "&#8722;",
	"&lowast;": "&#8727;",
	"&radic;": "&#8730;",
	"&prop;": "&#8733;",
	"&infin;": "&#8734;",
	"&ang;": "&#8736;",
	"&and;": "&#8743;",
	"&or;": "&#8744;",
	"&cap;": "&#8745;",
	"&cup;": "&#8746;",
	"&int;": "&#8747;",
	"&there4;": "&#8756;",
	"&sim;": "&#8764;",
	"&cong;": "&#8773;",
	"&asymp;": "&#8776;",
	"&ne;": "&#8800;",
	"&equiv;": "&#8801;",
	"&le;": "&#8804;",
	"&ge;": "&#8805;",
	"&sub;": "&#8834;",
	"&sup;": "&#8835;",
	"&nsub;": "&#8836;",
	"&sube;": "&#8838;",
	"&supe;": "&#8839;",
	"&oplus;": "&#8853;",
	"&otimes;": "&#8855;",
	"&perp;": "&#8869;",
	"&sdot;": "&#8901;",
	"&lceil;": "&#8968;",
	"&rceil;": "&#8969;",
	"&lfloor;": "&#8970;",
	"&rfloor;": "&#8971;",
	"&loz;": "&#9674;",
	"&spades;": "&#9824;",
	"&clubs;": "&#9827;",
	"&hearts;": "&#9829;",
	"&diams;": "&#9830;",
	"&lang;": "&#10216;",
	"&rang;": "&#10217;",
};

export class StringEscapeUtilsBase {
	static escapeBase64(source: string | undefined): string | undefined {
		const input = StringEscapeUtilsBase.encodeUtf8ToByteString(source);

		if (!input) {
			return undefined;
		}

		let result = "";
		let charsLeft = 0;
		let char1: number;
		let char2: number;
		let char3: number;
		let byte1: number;
		let byte2: number;
		let byte3: number;
		let byte4: number;
		for (let i = 0; i < input.length; i++) {
			charsLeft = input.length - i;
			char1 = input.charAt(i).charCodeAt(0);
			byte1 = char1 >> 2;
			if (charsLeft >= 3) {
				char2 = input.charAt((i += 1)).charCodeAt(0);
				char3 = input.charAt((i += 1)).charCodeAt(0);
				byte2 = ((char1 & 3) << 4) | (char2 >> 4);
				byte3 = ((char2 & 15) << 2) | (char3 >> 6);
				byte4 = char3 & 63;
			} else if (charsLeft === 2) {
				char2 = input.charAt((i += 1)).charCodeAt(0);
				char3 = 0;
				byte2 = ((char1 & 3) << 4) | (char2 >> 4);
				byte3 = ((char2 & 15) << 2) | (char3 >> 6);
				byte4 = 64;
			} else {
				char2 = 0;
				byte2 = ((char1 & 3) << 4) | (char2 >> 4);
				byte3 = byte4 = 64;
			}
			result = result.concat(BASE64_CHARS.charAt(byte1));
			result = result.concat(BASE64_CHARS.charAt(byte2));
			result = result.concat(BASE64_CHARS.charAt(byte3));
			result = result.concat(BASE64_CHARS.charAt(byte4));
		}
		return result;
	}

	static unescapeBase64(source: string | undefined): string | undefined {
		if (!source) {
			return undefined;
		}

		let result = "";
		let charsLeft = 0;
		let char1: number;
		let char2: number;
		let char3: number;
		let byte1: number;
		let byte2: number;
		let byte3: number;
		let byte4: number;
		for (let i = 0; i < source.length; i++) {
			charsLeft = source.length - i;
			byte1 = BASE64_CHARS.indexOf(source.charAt(i));
			if (charsLeft >= 4) {
				byte2 = BASE64_CHARS.indexOf(source.charAt((i += 1)));
				byte3 = BASE64_CHARS.indexOf(source.charAt((i += 1)));
				byte4 = BASE64_CHARS.indexOf(source.charAt((i += 1)));
			} else if (charsLeft === 3) {
				byte2 = BASE64_CHARS.indexOf(source.charAt((i += 1)));
				byte3 = BASE64_CHARS.indexOf(source.charAt((i += 1)));
				byte4 = 0;
			} else if (charsLeft === 2) {
				byte2 = BASE64_CHARS.indexOf(source.charAt((i += 1)));
				byte3 = byte4 = 0;
			} else {
				byte2 = byte3 = byte4 = 0;
			}
			char1 = (byte1 << 2) | (byte2 >> 4);
			char2 = ((byte2 & 15) << 4) | (byte3 >> 2);
			char3 = ((byte3 & 3) << 6) | byte4;
			result = result.concat(String.fromCharCode(char1));
			if (byte3 < 64) {
				result = result.concat(String.fromCharCode(char2));
				if (byte4 < 64) {
					result = result.concat(String.fromCharCode(char3));
				}
			}
		}
		return StringEscapeUtilsBase.decodeUtf8FromByteString(result);
	}

	private static encodeUtf8ToByteString(source: string | undefined): string | undefined {
		if (!source) {
			return undefined;
		}

		let result = "";

		for (let i = 0; i < source.length; i++) {
			const charI: number = source.charAt(i).charCodeAt(0);
			if (charI <= 127) {
				result = result.concat(String.fromCharCode(charI));
			} else if (charI <= 2047) {
				result = result.concat(String.fromCharCode((charI >> 6) | 192));
				result = result.concat(String.fromCharCode((charI & 63) | 128));
			} else if (charI <= 65535) {
				result = result.concat(String.fromCharCode((charI >> 12) | 224));
				result = result.concat(String.fromCharCode(((charI >> 6) & 63) | 128));
				result = result.concat(String.fromCharCode((charI & 63) | 128));
			} else {
				result = result.concat(String.fromCharCode((charI >> 18) | 240));
				result = result.concat(String.fromCharCode(((charI >> 12) & 63) | 128));
				result = result.concat(String.fromCharCode(((charI >> 6) & 63) | 128));
				result = result.concat(String.fromCharCode((charI & 63) | 128));
			}
		}
		return result;
	}

	private static decodeUtf8FromByteString(source: string | undefined): string | undefined {
		if (!source) {
			return undefined;
		}

		let result = "";
		try {
			let byte1: number;
			let byte2: number;
			let byte3: number;
			let byte4: number;
			let out = 0;
			for (let i = 0; i < source.length; i++) {
				byte1 = source.charAt(i).charCodeAt(0);
				if ((byte1 & 240) === 240) {
					byte2 = source.charAt((i += 1)).charCodeAt(0);
					byte3 = source.charAt((i += 1)).charCodeAt(0);
					byte4 = source.charAt((i += 1)).charCodeAt(0);
					byte1 = (byte1 & 7) << 18;
					byte2 = (byte2 & 63) << 12;
					byte3 = (byte3 & 63) << 6;
					byte4 = byte4 & 63;
					out = byte1 | byte2 | byte3 | byte4;
				} else if ((byte1 & 224) === 224) {
					byte2 = source.charAt((i += 1)).charCodeAt(0);
					byte3 = source.charAt((i += 1)).charCodeAt(0);
					byte1 = (byte1 & 15) << 12;
					byte2 = (byte2 & 63) << 6;
					byte3 = byte3 & 63;
					out = byte1 | byte2 | byte3;
				} else if ((byte1 & 192) === 192) {
					byte2 = source.charAt((i += 1)).charCodeAt(0);
					byte1 = (byte1 & 31) << 6;
					byte2 = byte2 & 63;
					out = byte1 | byte2;
				} else {
					out = byte1;
				}
				result = result.concat(String.fromCharCode(out));
			}
		} catch (e: unknown) {
			return "";
		}
		return result;
	}

	/**
	 * Escapes UTF-8 and special characters (&'"<) to their encoded numerical
	 * representation - i.e. '&#60;
	 *
	 * @param source The input string to escape.
	 *
	 * @return The escaped input string or null if the input string is null.
	 */
	static escapeHtml(source: string | undefined): string | undefined {
		if (!source) {
			return source;
		}

		let result = "";

		for (let i = 0; i < source.length; i++) {
			const codepoint = source.codePointAt(i);

			if (codepoint! < 20 || codepoint! > 126) {
				// characters below 20 (whitespace) and above 126 (tilde) need to be encoded
				result = result.concat(`&#${codepoint!};`);
			} else {
				switch (codepoint) {
					case 38:
					case 39:
					case 34:
					case 60:
						// also encode special chars: & (38), ' (39), " (34), < (60)
						// quotes are encoded as they are often used for XSS attacks
						result = result.concat(`&#${codepoint};`);
						break;
					default:
						result = result.concat(source.charAt(i));
				}
			}
		}

		return result;
	}

	/**
	 * @return A string with all reserved JavaScript characters escaped.
	 */
	static escapeJavaScript(source: string | undefined): string | undefined {
		if (!source) {
			return source;
		}
		return source.replace(new RegExp("([\\\\\"'])", "g"), "\\\\$1");
	}

	/**
	 * Unescapes all HTML-escaped characters. Entities which can not be unescaped
	 * are returned as-is.
	 *
	 * @param source The escaped input string.
	 *
	 * @return The unescaped input string or null if the input string is null.
	 */
	static unescapeHtml(source: string | undefined): string | undefined {
		if (isBlank(source)) {
			return source;
		}

		const regexp = new RegExp("&(#[0-9]{1,5}|#x[a-f0-9]{1,4}|[a-z0-9]+);", "gi");

		let result = "";
		let match = regexp.exec(source!);
		let lastmatchEndidx = 0;

		while (match !== null) {
			// copy everything up to the match
			result = result.concat(source!.substring(lastmatchEndidx, match.index));
			lastmatchEndidx = match.index + match[0].length;

			let entity = match[0];

			// decode named entity to numerical
			if (entity.charAt(1) !== "#") {
				entity = ENTITY_NAMED_MAP[entity];
			}

			// decode numerical entity to character
			const codepoint = StringEscapeUtilsBase.unescapeHtmlCodePoint(entity);
			result = !codepoint ? result.concat(match[0]) : result.concat(codepoint);

			match = regexp.exec(source!);
		}

		// copy rest of source which did not match
		result = result.concat(source!.substring(lastmatchEndidx));

		return result.toString();
	}

	static unescapeHtmlCodePoint(source: string | undefined): string | undefined {
		if (!source) {
			return undefined;
		}
		try {
			if (source.charAt(1) !== "#") {
				return source;
			}
			let codepoint: string;
			let radix: number;
			if (source.charAt(2) === "x" || source.charAt(2) === "X") {
				codepoint = source.substring(3, source.length - 1);
				radix = 16;
			} else {
				codepoint = source.substring(2, source.length - 1);
				radix = 10;
			}

			return String.fromCodePoint(parseInt(codepoint, radix));
		} catch (e: unknown) {
			// invalid codepoints are ignored
		}
		// invalid codepoint -> return as-is
		return source;
	}
}
