import { Injectable } from "@angular/core";
import { UserAdministrationResource, UserAssignmentsResource } from "core/stages-client";

type UserAssignment = stages.userassignments.UserAssignment;
type PagedUserAssignments = PagedResult<UserAssignment>;

@Injectable({ providedIn: "root" })
export class UserAssignmentsService {
	constructor(
		private readonly userAssignmentsResource: UserAssignmentsResource,
		private readonly userManagementResource: UserAdministrationResource,
	) {}

	async loadMore(
		elementTypeIdent: string,
		elementIdentity: string,
		workspaceId: string,
		pv: string,
		path: string | null,
		page: number,
		pageSize: number,
		elementPageSize: number,
	): Promise<PagedUserAssignments> {
		return this.userAssignmentsResource.getPage(workspaceId, elementTypeIdent, elementIdentity, {
			path: path,
			page: page,
			pageSize: pageSize,
			elementPageSize: elementPageSize,
			pv: pv,
		});
	}

	async search(term: string, pv: string): Promise<{ results: stages.administration.user.DisplayUser[] }> {
		this.userManagementResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		const pagedResult = await this.userManagementResource.searchUsersPaged({
			query: term,
		});
		return {
			results: pagedResult.items,
		};
	}

	async assign(
		elementTypeIdent: string,
		elementId: string,
		elementIdentity: string,
		userId: string,
		workspaceId: string,
		pv: string,
		page: number,
		pageSize: number,
		firstPageSize: number,
		elementPageSize: number,
	): Promise<PagedUserAssignments> {
		return this.userAssignmentsResource.assign(workspaceId, elementTypeIdent, elementId, userId, {
			pv: pv,
			identity: elementIdentity,
			page: page,
			pageSize: pageSize,
			firstPageSize: firstPageSize,
			elementPageSize: elementPageSize,
		});
	}

	async unassign(
		elementTypeIdent: string,
		elementId: string,
		elementIdentity: string,
		guids: string[],
		workspaceId: string,
		pv: string,
		page: number,
		pageSize: number,
		firstPageSize: number,
		elementPageSize: number,
	): Promise<PagedUserAssignments> {
		return this.userAssignmentsResource.unassign(workspaceId, elementTypeIdent, elementId, {
			pv: pv,
			identity: elementIdentity,
			guids: guids,
			page: page,
			pageSize: pageSize,
			firstPageSize: firstPageSize,
			elementPageSize: elementPageSize,
		});
	}

	async updateComment(
		elementTypeIdent: string,
		elementId: string,
		elementIdentity: string,
		guid: string,
		workspaceId: string,
		pv: string,
		commentInputText: string,
	): Promise<void> {
		this.userAssignmentsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
			identity: elementIdentity,
		});
		return this.userAssignmentsResource.comment(workspaceId, elementTypeIdent, elementId, commentInputText, {
			guid: guid,
		});
	}

	async updateDeputy(
		elementTypeIdent: string,
		elementId: string,
		elementIdentity: string,
		guid: string,
		workspaceId: string,
		pv: string,
		deputy: boolean,
	): Promise<void> {
		this.userAssignmentsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
			identity: elementIdentity,
		});
		return this.userAssignmentsResource.deputy(workspaceId, elementTypeIdent, elementId, deputy, {
			guid: guid,
		});
	}
}
