import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MutexService } from "common/concurrency/mutex.service";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";
import { TailoringService, TailorStatus } from "process/tailoring/manual/tailoring.service";
import { ViewService } from "core/view.service";
import { ActivatedRoute } from "@angular/router";

type DisplayDescription = stages.core.format.DisplayDescription;

@Component({
	selector: "stages-manual-tailoring",
	templateUrl: "./manual-tailoring.component.html",
	styleUrls: ["./manual-tailoring.component.scss"],
})
export class ManualTailoringComponent implements OnInit, OnDestroy {
	TailorStatus = TailorStatus;
	@Input()
	targetStatus!: TailorStatus;

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	@Input()
	tailoredElements: Array<stages.process.tailoring.Tailorable> = [];

	@Input()
	addedElements: Array<stages.process.tailoring.Tailorable> = [];

	@Input()
	removedElements: Array<stages.process.tailoring.Tailorable> = [];

	@Input()
	justification!: DisplayDescription;

	@Input()
	processTypeIdent?: string;

	@Input()
	processId?: string;

	@Input()
	typeIconPrefix: string = "et";

	@ViewChild(DescriptionEditorComponent, { static: false })
	descriptionEditor?: DescriptionEditorComponent;

	@Output() readonly save = new EventEmitter<void>();

	@Output() readonly cancel = new EventEmitter<void>();

	@Input()
	complianceConflicts!: Array<stages.process.tailoring.ComplianceConflict>;

	pluginRegistry = {
		stageslink: LinkPluginComponent,
	};

	private editEnabled = false;

	get titleKey(): string {
		const postix = this.removedElements.length > 1 || this.addedElements.length > 1 ? ".plural" : "";
		switch (this.targetStatus) {
			case TailorStatus.ADD:
				return "process.element.tailor.manual.title.type.add" + postix;
			case TailorStatus.RESET:
				return "process.element.tailor.manual.title.type.reset" + postix;
			case TailorStatus.REMOVE:
				return "process.element.tailor.manual.title.type.remove" + postix;
			default:
				return "process.element.tailor.manual.title.type.unknown";
		}
	}

	menuItems?: MenuItem[];
	saveInProgress: boolean = false;

	constructor(
		private mutexService: MutexService,
		private manualTailoringService: TailoringService,
		private viewService: ViewService,
		private route: ActivatedRoute,
	) {}

	ngOnInit(): void {
		this.descriptionEditor?.edit(this.justification);
	}

	ngOnDestroy(): void {
		this.closeEditor();
	}

	onEditEnabledChanged(editEnabled: boolean): void {
		this.editEnabled = editEnabled;
	}

	onSave(): void {
		this.saveInProgress = true;
		this.justification = this.descriptionEditor
			? this.descriptionEditor.getDescription()
			: {
					editedImageMap: {},
					html: "",
					language: null,
					viewingMode: "",
			  };
		this.mutexService
			.invokeWithResult("tailoring", async () => {
				return this.manualTailoringService.commitTailoring(
					this.workspaceId,
					this.pv,
					this.tailoredElements,
					this.targetStatus,
					this.justification,
				);
			})
			.then(() => {
				this.descriptionEditor?.quit();
				this.save.emit();
				this.saveInProgress = false;
				const paramMap = this.route.snapshot.paramMap;
				this.viewService.refresh(paramMap);
			});
	}

	isSaveDisabled(): boolean {
		if (!this.tailoredElements || this.tailoredElements.length === 0 || this.saveInProgress) {
			return true;
		}
		return this.editEnabled
			? this.descriptionEditor?.editorElementRef.nativeElement.innerText.trim().length === 0
			: this.justification.html.trim().length === 0;
	}

	onCancel(): void {
		this.closeEditor();
		this.cancel.emit();
	}

	private closeEditor(): void {
		this.descriptionEditor?.cancel();
	}

	getIconClasses(tailorable: stages.process.tailoring.Tailorable): string[] {
		const result: string[] = [];
		result.push(`ico-${this.typeIconPrefix}-${tailorable.type.ident}`);
		if (tailorable.type.subtypeIdent) {
			result.push(`ico-${this.typeIconPrefix}-${tailorable.type.subtypeIdent}`);
		}
		return result;
	}
}
