import { Injectable } from "@angular/core";
import { TranslateDefaultParser } from "@ngx-translate/core";
import { StagesMissingTranslationHandler } from "core/translate/missing-translation.handler";

@Injectable({ providedIn: "root" })
export class StagesTranslateParser extends TranslateDefaultParser {
	currentMessageScope?: string;

	override getValue(target: unknown, key: string): unknown {
		if (this.currentMessageScope !== undefined) {
			const prefixedKey = this.currentMessageScope + "." + key;
			const result = super.getValue(target, prefixedKey);
			if (result !== undefined && result !== StagesMissingTranslationHandler.getMissingTransaltionResult(prefixedKey)) {
				return result;
			}
		}

		return super.getValue(target, key);
	}

	setCurrentMessageScope(messageScope: string): void {
		this.currentMessageScope = messageScope;
	}
}
