import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MutexService } from "common/concurrency/mutex.service";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { PluginRegistry } from "common/editor/description.service";
import { ImagePluginComponent } from "common/editor/image-plugin/image-plugin.component";
import { FormService } from "common/form/form.service";
import { RichTextInputContext } from "common/form/input/types/rich-text-input";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { HtmlTemplatePluginComponent } from "process/description/htmltemplate-plugin/htmltemplate-plugin.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";
import { ProcessDescriptionService } from "process/description/process-description.service";
import { EditService } from "process/element/edit/edit.service";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

type EditableProcess = stages.process.EditableProcess;
type Attribute = stages.core.Attribute;

@Component({
	selector: "stages-edit-process",
	templateUrl: "edit.component.html",
})
export class EditComponent implements OnInit {
	@ViewChild("formElement") formElement!: ElementRef;
	@ViewChild(DescriptionEditorComponent) descriptionEditor!: DescriptionEditorComponent;

	pluginRegistry: PluginRegistry;
	formGroup: FormGroup = new FormGroup({});
	isSaveInProgress: boolean = false;
	workspaceView$: Observable<stages.workspace.application.WorkspaceView>;
	data$: Observable<Data>;
	editableProcess$!: Observable<EditableProcess>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mainService: MainService,
		private editService: EditService,
		private descriptionService: ProcessDescriptionService,
		private formService: FormService,
		private mutexService: MutexService,
		private viewService: ViewService,
	) {
		this.data$ = this.route.data;
		this.workspaceView$ = this.mainService.workspaceView$;
		this.pluginRegistry = this.descriptionService.getPluginRegistry();
	}

	ngOnInit(): void {
		this.editableProcess$ = this.data$.pipe(
			mergeMap(async (data: Data) => {
				const editableProcess = await this.editService.getEditableProcess(
					data.view.viewWorkspace.id,
					data.view.processView.pv,
				);
				return editableProcess;
			}),
		);
	}

	getContext(data: Data, workspaceView: stages.workspace.application.WorkspaceView): RichTextInputContext {
		const processView = data.view.processView as stages.process.ProcessView;
		return {
			beanType: processView.type.ident,
			beanId: processView.id,
			beanIdentity: processView.identity,
			pv: workspaceView.currentWorkspace.viewedProcess!.versionIdentifier,
			processTypeIdent: workspaceView.currentWorkspace.viewedProcess!.type,
			workspaceId: workspaceView.currentWorkspace.id,
			pluginRegistry: {
				stagesimage: ImagePluginComponent,
				stageslink: LinkPluginComponent,
				stageshtmltemplate: HtmlTemplatePluginComponent,
			},
		};
	}

	cancel(): void {
		this.router.navigate([".."], { relativeTo: this.route });
	}

	async save(editableProcess: EditableProcess): Promise<void> {
		this.formService.markFormGroupTouched(this.formGroup);
		if (this.formGroup.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.isSaveInProgress = true;
			try {
				this.prepareDataSave(editableProcess);
				if (!this.formGroup.valid) {
					return;
				}
				this.formGroup.disable();
				try {
					const params = this.route.snapshot.paramMap;
					await this.mutexService.invokeWithResult("processSave", async () => {
						try {
							await this.editService.saveElement(
								"process",
								params.get("identity")!,
								editableProcess.process,
								params.get("workspaceId")!,
								params.get("processVersion")!,
							);
							this.descriptionEditor.quit();

							this.viewService.refresh(params);
							this.router.navigate([".."], { relativeTo: this.route });
							// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
						} catch (errorResponse) {
							this.formService.setServerSideErrorsOnFormControls(this.formGroup.controls)(errorResponse);
						}
					});
				} finally {
					this.formGroup.enable();
				}
			} finally {
				this.isSaveInProgress = false;
			}
		}
	}

	private prepareDataSave(editableProcess: EditableProcess): void {
		const attributesMap = new Map(
			editableProcess.process.attributes.map((attribute): [string, Attribute] => [attribute.typeIdent, attribute]),
		);
		for (const attributeTypeIdent in this.formGroup.controls) {
			if (attributesMap.has(attributeTypeIdent)) {
				const attribute: Attribute | undefined = attributesMap.get(attributeTypeIdent);
				if (attribute) {
					attribute.value = this.formGroup.value[attributeTypeIdent];
					attributesMap.set(attribute.typeIdent, attribute);
				}
			}
		}

		editableProcess.process.attributes = Array.from(attributesMap.values());

		editableProcess.process.displayDescription = this.descriptionEditor.getDescription();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static provideTitle(translateService: TranslateService, mergedRouteData: any): Observable<string> {
		return translateService
			.get("edit")
			.pipe(
				map((edit) => `${edit} ${mergedRouteData.view.processView.label} - ${mergedRouteData.view.viewWorkspace.name}`),
			);
	}
}
