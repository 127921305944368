import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MainService } from "core/main.service";
import { SettingsService } from "user/settings/settings.service";

type UserSettings = stages.user.UserSettings;
type UserSettingsMetadata = stages.user.UserSettingsMetadata;

@Injectable({ providedIn: "root" })
export class UserSettingsResolver implements Resolve<DataAndInfo<UserSettings, UserSettingsMetadata>> {
	constructor(private settingsService: SettingsService, private mainService: MainService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<DataAndInfo<UserSettings, UserSettingsMetadata>> {
		return this.mainService.getApplication(route.paramMap.get("processVersion")!).then(async (application) => {
			return this.settingsService.getSettings(
				application.userId,
				route.paramMap.get("workspaceId")!,
				route.paramMap.get("processVersion")!,
			);
		});
	}
}
