import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AssociationBrowserDialogComponent } from "common/associations/association-browser-dialog.component";
import { BrowseView, Folder, TargetWorkspace } from "common/associations/association-browser.interface";
import {
	AssociationBrowserService,
	AssociationSource,
	AssociationTarget,
	ExistingAssociation,
	ExistingAssociationTarget,
	getElementKey,
} from "common/associations/association-browser.service";
import { BrowseAssociationStore } from "common/associations/browse-association-store.interface";
import { MutexService } from "common/concurrency/mutex.service";
import { Selectable } from "common/selection/state/selectable.model";
import { combineLatest, Observable, of, ReplaySubject, Subscription } from "rxjs";

const CURSOR_STYLE_CLASS = "default-cursor";

type CombinedViewForBrowser = stages.common.CombinedViewForBrowser;
type TypedId = stages.core.TypedId;

@Component({
	selector: "stages-association-browser",
	templateUrl: "association-browser.component.html",
	styleUrls: ["association-browser.component.scss"],
})
export class AssociationBrowserComponent implements OnInit, OnDestroy {
	allowNavigation: boolean;
	resetStore: boolean = false;
	isSimpleMode: boolean = true;
	private readonly subs = new Subscription();
	hasCache$: Observable<boolean> = of(false);
	loadingCompleted: boolean = false;
	allTreeElements: Selectable[] = [];
	initialSelection: TypedId[] = [];
	initialSelections: Selectable[] = [];
	cachedSelectableTypedIds?: TypedId[];
	commentsAllowed: boolean = false;
	indexAllowed: boolean = false;
	browseData$ = new ReplaySubject<CombinedViewForBrowser>(1);
	workspaceName: string = "WS";
	browseRootType: string = "process";
	targetWorkspace: TargetWorkspace = { id: "-1", name: "default" };
	folder!: Folder;
	selectableChildren?: Selectable[];
	loading: boolean = false;
	self!: AssociationSource;
	workspaceId: string;
	pv: string;
	restrictAssociateDependentElementTypes?: string[];

	getElementKey = getElementKey;

	private readonly groupPath: string;
	private readonly groupBy?: string;
	private combinedAssociationsLoaded: boolean = false;

	storeIdentifier = AssociationBrowserService.defaultStoreIdentifier;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mutexService: MutexService,
		private dialog: AssociationBrowserDialogComponent,
		private associationBrowserService: AssociationBrowserService,
	) {
		const paramMap = route.snapshot.paramMap;
		this.allowNavigation = paramMap.get("allowNavigation") === "true";
		this.groupPath = paramMap.get("grouppath")!;
		this.groupBy = paramMap.get("groupby")!;
		this.workspaceId = paramMap.get("workspaceId")!;
		this.pv = paramMap.get("processVersion")!;
		const dependenTypesRestrictions = paramMap.get("dependentTypesRestrictions");
		this.restrictAssociateDependentElementTypes =
			dependenTypesRestrictions === null || dependenTypesRestrictions === "null"
				? undefined
				: dependenTypesRestrictions.split(",");
		this.browseRootType = paramMap.get("browseroottype")!;
	}

	ngOnInit(): void {
		const paramMap = this.route.snapshot.paramMap;
		const browseWorkspaceId = paramMap.get("browseWorkspaceId")!;

		const associationStore = this.route.snapshot.data.associationStore as BrowseAssociationStore<
			AssociationSource,
			ExistingAssociationTarget
		>;

		const self$ = this.getSourceElement(associationStore, paramMap);

		this.subs.add(
			combineLatest([self$, this.route.data]).subscribe(async ([self, data]) => {
				const browseView = data.browseView as BrowseView;
				const browseFolder = browseView.folder;
				const workspaceName = browseView.targetWorkspace.name;
				const filterScope = !!data.filterByScopes;
				const commentsAllowed = !!data.commentsAllowed;
				const indexAllowed = !!data.allowIndex;

				if (!this.combinedAssociationsLoaded) {
					const existingAssociations: ExistingAssociation[] = this.associationBrowserService.getCombinedList(
						self.associations![associationStore.getGroupIdentifier(this.groupPath, this.groupBy)],
					);
					if (existingAssociations.length > 0) {
						const allCombinedAssociations = await this.getAllCombinedAssociations(existingAssociations);
						// we need to filter the combined associations, because we already have them in the "allCombinedAssociations"-list
						const existingAssociationsWithoutCombined = existingAssociations.filter((assoc) => !assoc.combined);
						const allAssociations = [...existingAssociationsWithoutCombined, ...allCombinedAssociations];
						this.initialSelections = this.associationBrowserService.getSelectablesFromAssociations(
							allAssociations,
							self.associations![associationStore.getGroupIdentifier(this.groupPath, this.groupBy)],
							associationStore,
						);
						this.combinedAssociationsLoaded = true;
					}
				}

				if (filterScope && !this.cachedSelectableTypedIds) {
					this.cachedSelectableTypedIds = await this.associationBrowserService.getScopeElements(
						this.workspaceId,
						this.pv,
						browseWorkspaceId,
					);
				}

				this.self = self;
				this.folder = browseFolder;
				this.selectableChildren = this.associationBrowserService.getChildrenOfFolder(
					self,
					self.associations![associationStore.getGroupIdentifier(this.groupPath, this.groupBy)],
					browseFolder,
					associationStore,
				);
				this.commentsAllowed = commentsAllowed ? commentsAllowed : this.commentsAllowed;
				this.indexAllowed = indexAllowed ? indexAllowed : this.indexAllowed;
				this.workspaceName = workspaceName;
				this.loadingCompleted = true;
			}),
		);
	}

	private async getAllCombinedAssociations(associations: ExistingAssociation[]): Promise<ExistingAssociation[]> {
		const allAssociations: ExistingAssociation[] = [];
		//get all combined associations that we have in the frontend
		const combinedAssocTargetElementIdentities: string[] = associations
			.filter((assoc) => assoc.combined && assoc.targetElement !== null)
			.map((combinedAssoc) => combinedAssoc.targetElement!.identity);

		if (combinedAssocTargetElementIdentities.length > 0) {
			const paramMap = this.route.snapshot.paramMap;
			const result = await this.associationBrowserService.getCombinedAssociations(
				combinedAssocTargetElementIdentities,
				this.route.snapshot.root.firstChild!.paramMap.get("workspaceId")!,
				paramMap.get("type")!,
				paramMap.get("identity")!,
				this.groupPath,
				1,
				0,
				this.route.snapshot.root.firstChild!.paramMap.get("processVersion")!,
			);
			const allCombinedAssocs = result.items;

			allCombinedAssocs.forEach((assoc) => {
				const convertedAssoc: ExistingAssociation = {
					combined: true,
					comment: assoc.comment,
					identity: assoc.identity,
					targetElement: assoc.targetElement,
				};
				allAssociations.push(convertedAssoc);
			});
		}
		return allAssociations;
	}

	onCancel(): void {
		this.dialog.close();
	}

	ngOnDestroy(): void {
		const associationStore = this.route.snapshot.data.associationStore;
		if (associationStore.reset) {
			associationStore.reset();
		}
		this.subs.unsubscribe();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	headlineCursor(element: any): string {
		return element.parent ? "" : CURSOR_STYLE_CLASS;
	}

	listCursor(element: AssociationTarget): string {
		return element.hasChildren ? "" : CURSOR_STYLE_CLASS;
	}

	navigateToChild(element: Folder): void {
		if (!element.children || element.children.length <= 0) {
			return;
		}
		const commands: unknown[] = [
			"../../../../..",
			this.route.snapshot.paramMap.get("browseWorkspaceId"),
			this.route.snapshot.paramMap.get("browseProcessVersion"),
			element.type.ident,
			element.identity,
			"elements",
			{
				browseroottype: this.route.snapshot.paramMap.get("browseroottype"),
				grouppath: this.route.snapshot.paramMap.get("grouppath"),
				groupby: this.route.snapshot.paramMap.get("groupby"),
				allowNavigation: this.route.snapshot.paramMap.get("allowNavigation"),
				allowIndex: this.route.snapshot.paramMap.get("allowIndex"),
				dependentTypesRestrictions: this.route.snapshot.paramMap.get("dependentTypesRestrictions"),
			},
		];

		this.router.navigate(commands, { relativeTo: this.route, queryParamsHandling: "preserve" });
	}

	navigateToParent(element: Folder): void {
		if (!element.parent) {
			return;
		}
		this.router.navigate(
			[
				"../../../../..",
				this.route.snapshot.paramMap.get("browseWorkspaceId"),
				this.route.snapshot.paramMap.get("browseProcessVersion"),
				element.parent.type.ident,
				element.parent.identity,
				{
					browseroottype: this.route.snapshot.paramMap.get("browseroottype"),
					grouppath: this.route.snapshot.paramMap.get("grouppath"),
					groupby: this.route.snapshot.paramMap.get("groupby"),
					allowNavigation: this.route.snapshot.paramMap.get("allowNavigation"),
					allowIndex: this.route.snapshot.paramMap.get("allowIndex"),
					dependentTypesRestrictions: this.route.snapshot.paramMap.get("dependentTypesRestrictions"),
				},
				"elements",
			],
			{ relativeTo: this.route, queryParamsHandling: "preserve" },
		);
	}

	navigateToWorkspaces(): void {
		this.resetStore = false;
		this.router.navigate(
			[
				"..",
				{
					browseroottype: this.route.snapshot.paramMap.get("browseroottype"),
					grouppath: this.route.snapshot.paramMap.get("grouppath"),
					groupby: this.route.snapshot.paramMap.get("groupby"),
					allowNavigation: this.route.snapshot.paramMap.get("allowNavigation"),
					allowIndex: this.route.snapshot.paramMap.get("allowIndex"),
					dependentTypesRestrictions: this.route.snapshot.paramMap.get("dependentTypesRestrictions"),
				},
				"workspaces",
			],
			{ relativeTo: this.route, queryParamsHandling: "preserve" },
		);
	}

	onSave(updatedElements: stages.common.UpdatedElements): void {
		const sourceWorkspaceId = this.route.snapshot.paramMap.get("workspaceId")!;
		const targetWorkspaceId = this.route.snapshot.paramMap.get("browseWorkspaceId")!;
		const pv = this.route.snapshot.paramMap.get("processVersion")!;
		const associationStore = this.route.snapshot.data.associationStore;
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		this.mutexService.invoke("associate", async () =>
			associationStore
				.applyAssociationModifications(
					this.self,
					this.groupPath,
					updatedElements.addedElements,
					updatedElements.removedElements,
					updatedElements.updatedElements,
					sourceWorkspaceId,
					pv,
					targetWorkspaceId,
					this.groupBy,
				)
				.then(() => {
					this.loading = false;
					this.dialog.close();
				}),
		);
	}

	getSourceElement(
		associationStore: BrowseAssociationStore<AssociationSource, ExistingAssociationTarget>,
		paramMap: ParamMap,
	): Observable<AssociationSource> {
		const type = paramMap.get("type")!;
		const identity = paramMap.get("identity")!;
		return associationStore.getSourceElement(type, identity, this.workspaceId, this.pv);
	}
}
