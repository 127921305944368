import {
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	QueryList,
	ViewChild,
} from "@angular/core";
import { TabNoRouteComponent } from "common/tab/tab-no-route.component";
import { UtilService } from "common/util.service";
import * as ResizeSensor from "css-element-queries/src/ResizeSensor";

@Component({
	selector: "stages-tabs",
	templateUrl: "./tabs.component.html",
	styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input()
	wrap: boolean = false;

	@Input()
	set activeTabName(name: string) {
		if (this._activeName !== name) {
			this._activeName = name;
			this.onResize();
		}
	}

	@ViewChild("scrollContainer")
	scrollContainer?: ElementRef;

	@ViewChild("tabContainer")
	tabContainer?: ElementRef;

	@ContentChildren(TabNoRouteComponent)
	tabs?: QueryList<TabNoRouteComponent>;

	private _activeName?: string;
	private resizeSensor!: ResizeSensor.default;
	private preventScrollbarFromClosing: number | null = null;
	scrollable: boolean = false;
	isIE: boolean = false;
	isFF: boolean = false;
	isMobile: boolean = false;

	constructor(private readonly utilService: UtilService) {}

	ngOnInit(): void {
		this.isIE = this.utilService.browserIsIE();
		this.isFF = this.utilService.browserIsFirefox();
		this.isMobile = this.utilService.isMobileBrowser();
	}

	ngAfterViewInit(): void {
		if (this.scrollContainer) {
			this.resizeSensor = new ResizeSensor.default(this.scrollContainer.nativeElement, () => {
				this.onResize();
			});
		}

		this.onResize();
	}

	ngOnDestroy(): void {
		if (this.resizeSensor) {
			this.resizeSensor.detach();
		}

		this.clearTimeout();
	}

	activateScrollable(): void {
		this.clearTimeout();
		this.updateIsScrollable();
	}

	deactivateScrollable(): void {
		this.preventScrollbarFromClosing = window.setTimeout(() => {
			this.scrollable = false;
			this.onResize();
		}, 3000);
	}

	private updateIsScrollable(): void {
		if (this.scrollContainer && this.scrollContainer.nativeElement) {
			const scrollContainerHTML = this.scrollContainer.nativeElement as HTMLElement;

			/*  Somehow, the clientWidth in ie11 differs 1px from scrollWidth.
                So we manually add 1px to make sure the scrollbar is not activated, if it's not used
            */

			const clientWidth = this.isIE ? scrollContainerHTML.clientWidth + 1 : scrollContainerHTML.clientWidth;
			this.scrollable = clientWidth < scrollContainerHTML.scrollWidth;
		}
	}

	private onResize(): void {
		setTimeout(() => {
			this.updateXCoordinateOfActiveTab();
		}, 10);
	}

	private updateXCoordinateOfActiveTab(): void {
		if (this.scrollContainer && this.tabContainer && this.tabs) {
			this.tabs.forEach((tab) => {
				if (tab.active && tab.tab) {
					this.updateScrollPosition(tab.tab.nativeElement);
				}
			});
		}
	}

	private updateScrollPosition(tab: HTMLElement): void {
		const tabContainerElement = this.tabContainer!.nativeElement as HTMLElement;
		const scrollHtmlElement = this.scrollContainer!.nativeElement as HTMLElement;
		scrollHtmlElement.scrollLeft = tab.offsetLeft - tabContainerElement.offsetLeft;
	}

	private clearTimeout(): void {
		if (this.preventScrollbarFromClosing !== null) {
			window.clearTimeout(this.preventScrollbarFromClosing);
		}
	}
}
