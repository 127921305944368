import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { Subject } from "rxjs";

@Component({
	selector: "stages-loading-error-embeddable",
	templateUrl: "./stages-loading-error-embeddable.component.html",
})
export class FilesLoadingErrorEmbeddableComponent {
	@Input()
	credentialsValid!: boolean;

	@Input()
	loadingError$!: Subject<HttpErrorResponse>;
}
