import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MainService } from "core/main.service";
import { Subscription } from "rxjs";

@Component({
	template: "",
})
export class RedirectComponent implements OnDestroy {
	private subscription: Subscription;

	constructor(private router: Router, route: ActivatedRoute, private mainService: MainService) {
		this.subscription = this.mainService.workspaceView$.subscribe(
			(workspaceView: stages.workspace.application.WorkspaceView) => {
				if (workspaceView.currentWorkspace.viewedProcess) {
					this.router.navigate(["process", workspaceView.currentWorkspace.viewedProcess.identity], {
						relativeTo: route.parent,
						replaceUrl: true,
					});
				} else if (
					workspaceView.currentWorkspace.actions &&
					(workspaceView.currentWorkspace.actions.CreateProcess || workspaceView.currentWorkspace.actions.AddModule)
				) {
					this.router.navigate(["start", "options"], { relativeTo: route.parent, replaceUrl: true });
				} else {
					this.router.navigate(["noprocess"], { relativeTo: route.parent, replaceUrl: true });
				}
			},
		);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
