<ng-container *ngIf="!iconClass">
	<img
		[attr.alt]="'accessibility.profilepicture.single' | translate: { name: userName }"
		class="avatar"
		[src]="buildUrl()"
	/>
</ng-container>

<ng-container *ngIf="iconClass">
	<div class="avatar custom-icon" [ngClass]="{ 'table-icon': !isList, 'list-icon': isList }">
		<i class="ico {{ iconClass }}"></i>
	</div>
</ng-container>
