import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

/**
 * If this directive fires even for contained elements, use ClickStopPropagationDirective on those Angular components (but not on a "ng-container" element).
 */
@Directive({
	selector: "[stagesClickOutside]",
})
export class ClickOutsideDirective {
	private readonly nativeElement: Node;

	@Output() readonly stagesClickOutside = new EventEmitter<void>();

	constructor(elementRef: ElementRef<Node>) {
		this.nativeElement = elementRef.nativeElement;
	}

	@HostListener("document:click", ["$event.target"])
	onClick(targetElement: Node): void {
		let e: Node | null = targetElement;

		while (e) {
			if (e === this.nativeElement) {
				return;
			}
			e = e.parentElement;
		}
		this.stagesClickOutside.emit();
	}
}
