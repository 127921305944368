import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "visibleFilter",
})
export class VisibleFilterPipe implements PipeTransform {
	transform(items: unknown[], ...args: unknown[]): unknown {
		const filterActive = args[0];
		if (!items || !filterActive) {
			return items;
		}

		// eslint-disable-next-line array-callback-return -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return items.filter((item, index, allItems) => {
			if (allItems.length < 3) {
				return item;
			} else if (index < 2) {
				return item;
			} else if (index === allItems.length - 1) {
				return item;
			}
		});
	}
}
