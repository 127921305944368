import { ParamMap } from "@angular/router";
import { AssociationSource } from "common/associations/association-browser.service";
import { Association, AssociationTarget } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { Store } from "common/data/store.logic";
import { ProcessElementsResource } from "core/stages-client";

export class CombinedAssociationsStore implements Store<Association<stages.process.RemoteTargetElement>> {
	constructor(
		private associationStore: AssociationStore<AssociationSource<AssociationTarget>, AssociationTarget>,
		private pageSize: number,
		private processElementsResource: ProcessElementsResource,
	) {}

	async getPage(
		pageIndex: number,
		workspaceId: string,
		pv: string,
		paramMap: ParamMap,
	): Promise<SecuredPagedResult<Association<stages.process.RemoteTargetElement>>> {
		return this.associationStore.getCombinedAssociations!(
			[paramMap.get("targetElementIdentity")!],
			workspaceId,
			paramMap.get("type")!,
			paramMap.get("identity")!,
			paramMap.get("path")!,
			pageIndex,
			this.pageSize,
			pv,
		);
	}

	async delete(
		associationIds: string[],
		pageIndex: number,
		paramMap: ParamMap,
	): Promise<SecuredPagedResult<Association<stages.process.RemoteTargetElement>>> {
		return this.processElementsResource.deleteFromCombinedAssociations(
			paramMap.get("workspaceId")!,
			paramMap.get("type")!,
			paramMap.get("identity")!,
			{
				associationIds: associationIds,
				combinedTargetElementIdentities: [paramMap.get("targetElementIdentity")!],
				path: paramMap.get("path")!,
				page: pageIndex,
				pageSize: this.pageSize,
				pv: paramMap.get("processVersion")!,
			},
		) as Promise<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>>;
	}
}
