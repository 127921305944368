import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "stages-description-editor-imagemap",
	templateUrl: "./description-editor-imagemap.component.html",
})
export class DescriptionEditorImageMapComponent {
	@Input()
	imageRef!: stages.images.ImageReference;

	@Output() readonly imageMapChanged = new EventEmitter<void>();

	constructor() {}

	onChange(): void {
		this.imageMapChanged.emit();
	}
}
