<ng-container *ngIf="credentialsValid">
	<div class="operation-result-outer" *ngIf="loadingError$ | async as loadingError; else loading">
		<div class="operation-result error-color">
			<i class="ico ico-warning error-color"></i>
			<div *ngIf="loadingError.error.title">
				<p>
					<span> {{ loadingError.error.title | translate }}:</span><br />
					<strong class="error-color">{{ loadingError.error.message }}</strong>
				</p>
				<div *ngIf="loadingError.error.causes">
					<p *ngFor="let cause of loadingError.error.causes">
						<br />
						<span>{{ cause.name }}</span>
						<span *ngIf="cause.name">:</span>
						<br />
						<strong class="error-color">{{ cause.message }}</strong>
					</p>
				</div>
			</div>
			<div *ngIf="!loadingError.error.title">
				<p>
					<span translate="error.general.title"></span><br />
					<strong class="error-color" translate="error.general.message"></strong>
				</p>
			</div>
		</div>
	</div>
	<ng-template #loading>
		<stages-spin [size]="'sm'"></stages-spin>
	</ng-template>
</ng-container>
