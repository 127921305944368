import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { Button } from "common/data/data-view.component";
import { FormService } from "common/form/form.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { FileService } from "files/files.service";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";

type Attribute = stages.core.Attribute;
type AttributeType = stages.core.AttributeType;

@Component({
	selector: "stages-files-revert",
	templateUrl: "./files-revert.component.html",
})
export class FilesRevertComponent implements OnInit {
	revertDataAndInfo$: Observable<DataAndInfo<Attribute[], AttributeType[]>>;
	formModel: FormGroup;
	loadingError$ = new Subject<HttpErrorResponse>();

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	@ViewChild("formElement") formElement!: ElementRef;

	buttons: Button[] = [];

	constructor(
		private route: ActivatedRoute,
		private fileService: FileService,
		private formService: FormService,
		private mutexService: MutexService,
	) {
		this.revertDataAndInfo$ = this.fileService
			.getRevertDataAndInfo(
				this.route.snapshot.paramMap.get("fileId")!,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
			)
			.pipe(
				catchError((e: unknown) => {
					if (e instanceof HttpErrorResponse) {
						this.loadingError$.next(e);
						return EMPTY;
					}
					throw e;
				}),
			);

		this.formModel = new FormGroup({});
	}

	ngOnInit(): void {
		this.buttons = [
			{
				class: "button sm cancel",
				translate: "cancel",
				click: () => {
					this.close();
				},
				visible: () => true,
				disabled: () => {
					return false;
				},
			},
		];
	}

	submit(attributes: Attribute[]): void {
		this.formService.markFormGroupTouched(this.formModel);
		if (this.formModel.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.mutexService.invoke("revert" + this.route.snapshot.paramMap.get("fileId")!, async () => {
				const outgoingAttributes = attributes.map((attribute) => {
					const attributeFormControl = this.formModel.controls[attribute.typeIdent];
					attribute.value = attributeFormControl ? attributeFormControl.value : null;
					return attribute;
				});

				return this.fileService
					.doUnlock(
						this.route,
						this.route.snapshot.paramMap.get("fileId")!,
						outgoingAttributes,
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
					)
					.then(() => {
						this.dialog.close();
					}, this.formService.setServerSideErrorsOnFormControls(this.formModel.controls));
			});
		}
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};
}
