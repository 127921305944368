import {
	ApplicationRef,
	ComponentFactoryResolver,
	Injectable,
	Injector,
	NgZone,
	ViewContainerRef,
} from "@angular/core";
import { PluginRegistry } from "common/editor/description.service";
import { TextualReference } from "common/editor/shared/textual-reference";
import { ScrollService } from "common/routing/scroll.service";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { ProcessResource } from "core/stages-client";

export interface NonProcessEditorProperties {
	linkOriginWorkspaceId: string;
	imageContainerType?: string;
}

type ProcessTreeItem = stages.process.ProcessTreeItem;
type ViewableElement = stages.process.ViewableElement;
type ResolvedTextualReference = stages.process.ResolvedTextualReference;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
type DialogHandler = (event: unknown, data: any) => boolean;

export interface PluginComponent {
	dialog: Dialog;
}

export class Dialog {
	private _editor: CKEDITOR.editor;
	private _selectedText: string;
	private _selectedElement: CKEDITOR.dom.element;
	private _selectedReference!: TextualReference;
	private _dialogOkHandler?: DialogHandler | null;
	private _dialogCancelHandler?: DialogHandler | null;

	isVisible: boolean = true;

	constructor(
		private scrollService: ScrollService,
		private dialog: CKEDITOR.dialog,
		private workspaceId: string,
		private beanType: string,
		private beanId: string,
		private beanIdentity: string,
		private pv: string,
		private properties?: NonProcessEditorProperties,
	) {
		this._editor = dialog.getParentEditor();
		this._selectedText = this._editor.getSelection().getSelectedText();
		this._selectedElement = this._editor.getSelection().getSelectedElement();

		scrollService.disableWindowScroll();

		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		(dialog as any).definition.onOk = (event: unknown, data: any) => this.fireDialogOk(event, data);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		(dialog as any).definition.onCancel = (event: unknown, data: any) => this.fireDialogCancel(event, data);
	}

	getWorkspaceId(): string {
		return this.workspaceId;
	}

	getBeanType(): string {
		return this.beanType;
	}

	getBeanId(): string {
		return this.beanId;
	}

	getBeanIdentity(): string {
		return this.beanIdentity;
	}

	getProcessVersionIdentifier(): string {
		return this.pv;
	}

	getNonProcessEditorProperties(): NonProcessEditorProperties | undefined {
		return this.properties;
	}

	getData(): string {
		return this._editor.getData(false);
	}

	getSelectedText(): string {
		return this._selectedText;
	}

	getSelectedElement(): HTMLElement | undefined {
		return this._selectedElement ? this._selectedElement.$ : undefined;
	}

	getSelectedReference(): TextualReference {
		return this._selectedReference;
	}

	setSelectedReference(tref: string): void {
		this._selectedReference = TextualReference.fromText(tref);
	}

	insertHtml(html: string): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		(window as any).PKIT.ckeditor.CkEditorUtils.extendSelectionToCompleteLink(this._editor);
		this._editor.insertHtml(html, "unfiltered_html");
		this._editor.fire("change");
	}

	close(): void {
		this.dialog.hide();
		this.scrollService.enableWindowScroll();
		this.isVisible = false;
	}

	registerDialogOkHandler(handlerFn: DialogHandler): void {
		this._dialogOkHandler = handlerFn;
	}

	registerDialogCancelHandler(handlerFn: DialogHandler): void {
		this._dialogCancelHandler = handlerFn;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private fireDialogOk(event: unknown, data: any): boolean {
		const success = this._dialogOkHandler!.apply(event, data);
		if (success) {
			this._dialogOkHandler = null;
			this.scrollService.enableWindowScroll();
			this.isVisible = false;
		}
		return success;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private fireDialogCancel(event: unknown, data: any): boolean {
		const success = this._dialogCancelHandler!.apply(event, data);
		if (success) {
			this._dialogCancelHandler = null;
			this.scrollService.enableWindowScroll();
			this.isVisible = false;
		}
		return success;
	}

	getTextualReference(
		referencedElement: ViewableElement,
		referencedWorkspace: WorkspaceSelected,
		originWorkspaceId: string,
		isInlining: boolean,
		forceWorkspacePath: boolean,
		alignment?: "center" | "left" | "right",
		heading?: string,
	): TextualReference {
		const tref = new TextualReference();
		if (forceWorkspacePath || referencedWorkspace.id !== originWorkspaceId) {
			tref.setProjectPath(this.getWorkspacePath(referencedWorkspace));
		}
		if (referencedElement.type.ident === "process") {
			tref.setTargetPath(["MAIN"]);
			tref.setDisplayText(referencedWorkspace.name);
		} else if (!referencedElement.parent || referencedElement.index) {
			tref.setTargetPath(["INDEX"]);
			tref.setDisplayText(referencedElement.label);
		} else {
			if (referencedWorkspace.id !== originWorkspaceId) {
				tref.setTargetPath(this.getElementPathUsingDefaultLabels(referencedElement));
				tref.setDisplayText(referencedElement.label);
			} else {
				if (!(heading && this.isCurrentElement(referencedElement))) {
					tref.setTargetPath(this.getElementPath(referencedElement));
				}
			}
		}
		tref.setType(this.typeIdentToReferenceType(referencedElement.type.ident));
		if (isInlining) {
			tref.setInlining(isInlining);
		}
		if (alignment) {
			tref.setAlignment(alignment);
		}
		if (heading) {
			tref.setHeading(heading);
		}
		return tref;
	}

	getElementPath(element: ViewableElement): string[] {
		if (element && element.parent && !element.index) {
			const path = this.getElementPath(element.parent);
			path.push(element.label);
			return path;
		}
		return [];
	}

	getElementPathUsingDefaultLabels(element: ViewableElement): string[] {
		if (element && element.parent && !element.index) {
			const path = this.getElementPathUsingDefaultLabels(element.parent);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			path.push((element as any).defaultLabel);
			return path;
		}
		return [];
	}

	getWorkspacePath(workspace: WorkspaceSelected): string[] {
		if (workspace) {
			return workspace.pathSegments.concat(workspace.name);
		}
		return [];
	}

	typeIdentToReferenceType(typeIdent: string): string {
		if (typeIdent === "process") {
			return "Process Workbench";
		}
		return typeIdent.replace(/^(.)/, ($1) => {
			return $1.toUpperCase();
		});
	}

	isCurrentElement(element: ProcessTreeItem): boolean {
		return element.type.ident === this.getBeanType() && element.id === this.getBeanId();
	}

	addFocusElement(element: HTMLElement): void {
		this.dialog.addFocusable(new CKEDITOR.dom.element(element));
	}

	enableOkButton(): void {
		this.dialog.enableButton("ok");
	}

	disableOkButton(): void {
		this.dialog.disableButton("ok");
	}
}

export class Editor {
	private _currentDialog!: Dialog;

	constructor(
		private pluginComponentRegistry: PluginRegistry,
		private scrollService: ScrollService,
		private factoryResolver: ComponentFactoryResolver,
		private injector: Injector,
		private zone: NgZone,
		private app: ApplicationRef,
		private workspaceId: string,
		private beanType: string,
		private beanId: string,
		private beanIdentity: string,
		private pv: string,
		private properties?: NonProcessEditorProperties,
	) {}

	compileAndRegisterDialog(pluginName: string, domElement: HTMLElement, dialog: CKEDITOR.dialog, tref: string): void {
		this._currentDialog = new Dialog(
			this.scrollService,
			dialog,
			this.workspaceId,
			this.beanType,
			this.beanId,
			this.beanIdentity,
			this.pv,
			this.properties,
		);
		if (tref) {
			this._currentDialog.setSelectedReference(tref);
		}
		this.addDynamicComponent(pluginName, domElement, this._currentDialog);
	}

	private addDynamicComponent(pluginName: string, dialogElement: HTMLElement, dialog: Dialog): void {
		const factory = this.factoryResolver.resolveComponentFactory(this.pluginComponentRegistry[pluginName]);
		const newNode = document.createElement("div");
		newNode.id = "placeholder";
		while (dialogElement.firstChild) {
			dialogElement.removeChild(dialogElement.firstChild);
		}
		dialogElement.appendChild(newNode);

		const ref = factory.create(this.injector, [], newNode);
		ref.instance.dialog = dialog;

		this.zone.run(() => {
			this.app.attachView(ref.hostView);
		});
	}

	getCurrentDialog(): Dialog {
		return this._currentDialog;
	}
}

@Injectable({ providedIn: "root" })
export class PluginService {
	// eslint-disable-next-line @typescript-eslint/ban-types  -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	Editor: Function;

	constructor(
		factoryResolver: ComponentFactoryResolver,
		private readonly processResource: ProcessResource,
		private scrollService: ScrollService,
		app: ApplicationRef,
	) {
		this.Editor = (
			viewContainerRef: ViewContainerRef,
			zone: NgZone,
			pluginComponentRegistry: PluginRegistry,
			workspaceId: string,
			beanType: string,
			beanId: string,
			beanIdentity: string,
			pv: string,
			properties?: NonProcessEditorProperties,
		) => {
			return new Editor(
				pluginComponentRegistry,
				this.scrollService,
				factoryResolver,
				viewContainerRef.injector,
				zone,
				app,
				workspaceId,
				beanType,
				beanId,
				beanIdentity,
				pv,
				properties,
			);
		};
	}

	escapeRefPart(refPart: string): string {
		return refPart.replace(/([\\\[\]:#\\])/g, "\\$1");
	}

	getLinkName(tref: TextualReference): string {
		return tref.getDisplayText() || tref.getTarget() || tref.getHeading() || "";
	}

	areElementsEqual(element1: ProcessTreeItem, element2: ProcessTreeItem): boolean {
		return element1.type.ident === element2.type.ident && element1.id === element2.id;
	}

	async resolveInternalReference(
		tref: TextualReference,
		workspaceId: string,
		pv: string,
		lang: java.util.Locale,
	): Promise<ResolvedTextualReference> {
		return this.processResource.resolveTextualReference(workspaceId, {
			tref: tref.toString(),
			pv: pv,
			lang: lang,
		});
	}
}
