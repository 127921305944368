import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-user-image",
	templateUrl: "./user-image.component.html",
	styleUrls: ["./user-image.component.scss"],
})
export class UserImageComponent {
	@Input() userId!: string;

	@Input() userName!: string;

	@Input() version!: number | string;

	@Input() iconClass?: string;

	@Input() isList: boolean = false;

	buildUrl(): string {
		const encodedUserId = encodeURIComponent(this.userId);
		const encodedVersion = encodeURIComponent(this.version);
		return `app/users/${encodedUserId}/profileImage?v=${encodedVersion}`;
	}
}
