<stages-dialog2 #dialog *ngIf="error$ | async as error">
	<stages-dialog-header></stages-dialog-header>

	<div class="content slide-container">
		<h1 translate="{{ error?.titleKey }}"></h1>
		<div class="panel">
			<p>{{ error?.message }}</p>
		</div>

		<div class="panel">
			<div class="switch-details">
				<h5 translate="details"></h5>
				<input id="details" type="checkbox" hidden="hidden" [(ngModel)]="details" (click)="toggleState()" />
				<label for="details" class="switch"></label>
			</div>
		</div>

		<div class="two-slot-slide-panels" [@swipe]="detailsState">
			<div class="slide-panel">
				<img [attr.alt]="'files.backgroundimage' | translate" class="birds" src="img/birds.svg" />
			</div>

			<div class="slide-panel">
				<ul class="list">
					<li class="list-item" *ngIf="error?.jobDetails">
						<div class="label">
							<div class="text">
								<h4 translate="files.error.job.started"></h4>
								<p
									translate="files.error.job.info"
									[translateParams]="{ date: error?.jobDetails?.date, user: error?.jobDetails?.user?.fullname }"
								></p>
							</div>
						</div>
					</li>
					<li class="list-item" *ngFor="let detail of errorDetails$ | async">
						<div class="label">
							<div class="text">
								<h4 [translate]="$any(detail).key"></h4>
								<p>{{ $any(detail).value }}</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<footer>
		<div class="buttons">
			<button class="sm" (click)="dialog.close()" translate="close"></button>
		</div>
	</footer>
</stages-dialog2>
