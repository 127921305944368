import { animate, state, style, transition, trigger } from "@angular/animations";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	TemplateRef,
	TrackByFunction,
} from "@angular/core";
import { assertDefined } from "core/functions";
import { PreferencesService } from "core/preferences.service";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

export interface Group {
	ident: string;
	name?: string;
	nameKey?: string;
	showEmpty: boolean;

	getMemberCount(): number;
}

@Component({
	selector: "stages-common-collapsible-groups",
	templateUrl: "./collapsible-groups.component.html",
	styleUrls: ["./collapsible-groups.component.scss"],
	animations: [
		trigger("expandCollapseFromTop", [
			state("collapsed", style({ "margin-top": "{{contentHeight}}px" }), { params: { contentHeight: 0 } }),
			state("expanded", style({ "margin-top": 0 })),
			transition("collapsed <=> expanded", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsibleGroupsComponent implements OnInit {
	@Input() translateNone?: string;
	@Input() groups!: Group[];
	@Input() groupContentTemplate!: TemplateRef<unknown>;
	@Input() preferencesPrefix!: string;
	@Input() defaultOpen = false;
	@Input() singleGroupCollapsible = false;
	@Input() groupHeaderTemplate?: TemplateRef<unknown>;
	@Input() groupHeaderActionMenuTemplate?: TemplateRef<unknown>;
	@Input() hoverOnHeader = false;

	groupsOpenCloseState: StringToBoolean = {};

	constructor(private preferencesService: PreferencesService, private changeDetection: ChangeDetectorRef) {}

	ngOnInit(): void {
		assertDefined(this.preferencesPrefix);
		assertDefined(this.groups);
		assertDefined(this.groupContentTemplate);

		forkJoin(
			this.groups.map((g) =>
				getPreferencesPromise(g, this.preferencesService, this.defaultOpen, this.preferencesPrefix),
			),
		)
			.pipe(
				map((arr) => {
					arr.forEach(([ident, isOpen]) => (this.groupsOpenCloseState[ident] = isOpen));
				}),
			)
			.subscribe(() => {
				this.changeDetection.detectChanges();
			});
	}

	showTranslateNone(groups: Group[]): boolean {
		return groups.reduce((count: number, g: Group) => count + g.getMemberCount(), 0) === 0;
	}

	toggle(group: Group): void {
		this.groupsOpenCloseState[group.ident] = !this.groupsOpenCloseState[group.ident];
		this.preferencesService.setPreference(getPreferencesKey(this.preferencesPrefix, group), {
			open: this.groupsOpenCloseState[group.ident],
		});
	}

	isGroupOpen(group: Group): boolean {
		if (this.groupsOpenCloseState[group.ident] === undefined) {
			return this.defaultOpen;
		}
		return this.groupsOpenCloseState[group.ident];
	}

	trackBy: TrackByFunction<Group> = (_index, group) => {
		return group.ident;
	};

	getVisibleGroups(groups: Group[]): Group[] {
		return groups.filter((g) => g.showEmpty || g.getMemberCount() > 0);
	}

	getExpandCollapseFromTop(
		group: Group,
		expandable: HTMLDivElement,
	): { value: string; params: Record<string, unknown> } {
		const targetState = this.isGroupOpen(group) ? "expanded" : "collapsed";

		return {
			value: targetState,
			params: {
				contentHeight: 0 - expandable.scrollHeight,
			},
		};
	}
}

async function getPreferencesPromise(
	group: Group,
	preferencesService: PreferencesService,
	defaultValue: boolean,
	preferencesPrefix: string,
): Promise<[string, boolean]> {
	const pref = await preferencesService.getPreference(getPreferencesKey(preferencesPrefix, group), {
		open: defaultValue,
	});
	return [group.ident, pref.open];
}

function getPreferencesKey(preferencesPrefix: string, group: Group): string {
	return preferencesPrefix + "_" + group.ident;
}
