<ng-template let-group="group" let-subgroup="subgroup" let-association="association" #associationlabel>
	<i
		*ngIf="group.action !== 'delete'"
		class="ico"
		[ngClass]="
			association.targetElement.dependent
				? getIconClasses(association.targetElement.parent)
				: getIconClasses(association.targetElement)
		"
	></i>
	<div class="text" [ngClass]="getLabelClasses(association.sourceElement, association.targetElement)">
		<h4>
			{{ getTargetElementLabel(association.targetElement) }}
			<i
				[ngClass]="getProcessInterfaceIconClasses(association)"
				*ngIf="association.isProcessInterface"
				title="{{ 'process.element.associationList.processInterfaceIconTitle' | translate }}"
				attr.aria-label="{{ 'process.element.associationList.processInterfaceIconTitle' | translate }}"
			></i>
		</h4>
		<p *ngIf="association.targetElement.dependent || association.sourceElement?.dependent">
			<ng-container *ngIf="association.sourceElement?.dependent">
				{{ association.sourceElement.label }}
				<i
					class="ico"
					[ngClass]="getIconClasses(association.sourceElement)"
					[title]="association.sourceElement.type.name"
					[attr.aria-label]="association.sourceElement.type.name"
				></i>
			</ng-container>
			<ng-container *ngIf="association.targetElement.dependent">
				{{ association.targetElement.label }}
				<i
					class="ico"
					[ngClass]="getIconClasses(association.targetElement)"
					[title]="association.targetElement.type.name"
					[attr.aria-label]="association.targetElement.type.name"
				></i>
			</ng-container>
		</p>
		<ng-container *ngIf="showProcessInterfaceName && association.isProcessInterface">
			<p *ngIf="!association.combined">
				{{ association.targetElement.process.workspaceName }}
			</p>
		</ng-container>
		<p *ngIf="association.combined" translate="process.element.associationList.fromMultipleWorkspaces"></p>
		<ng-container *ngIf="!isCommentInputAssociation(association, subgroup)">
			<p
				*ngIf="
					!association.changeMarker || association.changeMarker.added || association.changeMarker.deleted;
					else compare
				"
			>
				{{ association.comment }}
			</p>
			<ng-template #compare>
				<p #compare [innerHTML]="association.changeMarker.comment"></p>
			</ng-template>
		</ng-container>
		<p *ngIf="association.changeMarker && association.changeMarker.added">
			<span class="badge added-badge" translate="process.compare.added"></span>
		</p>
		<p *ngIf="association.changeMarker && association.changeMarker.deleted">
			<span class="badge deleted-badge" translate="process.compare.deleted"></span>
		</p>
	</div>
</ng-template>

<ng-template
	let-group="group"
	let-subgroup="subgroup"
	let-association="association"
	let-expandableGroup="expandableGroup"
	let-sourceElement="sourceElement"
	#associationTemplate
>
	<div *ngIf="group.action === 'delete'" class="select">
		<input
			type="checkbox"
			class="checkbox"
			[(ngModel)]="group.selection!.set[association.id]"
			(ngModelChange)="group.selection!.onChange(association)"
			[disabled]="!isSelectable(association)"
			id="a{{ group.id }}{{ association.id }}"
		/>
		<label for="a{{ group.id }}{{ association.id }}"></label>
	</div>
	<a
		*ngIf="
			!association.isCommentOnly &&
			!isCommentInputAssociation(association, subgroup) &&
			!isCombinedAssociation(association, subgroup) &&
			!(association.changeMarker && association.changeMarker.deleted)
		"
		class="label"
		[routerLink]="getTargetElementLink(association)"
	>
		<ng-container
			*ngTemplateOutlet="associationlabel; context: { group: group, subgroup: subgroup, association: association }"
		></ng-container>
	</a>
	<a
		*ngIf="isCombinedAssociation(association, subgroup)"
		class="label clickable"
		(click)="openDetailViewOfCombined(association, group, sourceElement)"
	>
		<ng-container
			*ngTemplateOutlet="associationlabel; context: { group: group, subgroup: subgroup, association: association }"
		></ng-container>
	</a>
	<div
		*ngIf="
			!association.isCommentOnly &&
			!isCommentInputAssociation(association, subgroup) &&
			!isCombinedAssociation(association, subgroup) &&
			association.changeMarker &&
			association.changeMarker.deleted
		"
		class="label"
	>
		<ng-container
			*ngTemplateOutlet="associationlabel; context: { group: group, subgroup: subgroup, association: association }"
		></ng-container>
	</div>
	<div
		*ngIf="!association.isCommentOnly && isCommentInputAssociation(association, subgroup)"
		class="label"
		id="comment-editor-{{ association.id }}"
	>
		<i
			class="ico"
			[ngClass]="
				association.targetElement.dependent
					? getIconClasses(association.targetElement.parent)
					: getIconClasses(association.targetElement)
			"
		></i>
		<div class="text">
			<h4>
				{{ getTargetElementCombinedLabel(association.targetElement) }}
				<i
					[ngClass]="getProcessInterfaceIconClasses(association)"
					*ngIf="association.isProcessInterface"
					title="{{ 'process.element.associationList.processInterfaceIconTitle' | translate }} "
				></i>
			</h4>
			<input
				class="comment"
				type="text"
				[placeholder]="'editComment.placeholder' | translate"
				[(ngModel)]="commentInputText"
				(blur)="hideCommentInput()"
				(stagesKeyUp)="onCommentInputKeyUp($event, association)"
				stagesAutofocus
			/>
		</div>
	</div>
	<div *ngIf="association.isCommentOnly" class="label comment-only">
		<i
			*ngIf="group.action !== 'delete'"
			class="ico comment"
			[ngClass]="getCommentAssociationIconClasses(association)"
		></i>
		<div class="text">
			<ng-container *ngIf="!isCommentInputAssociation(association, subgroup)">
				<h4
					*ngIf="
						!association.changeMarker || association.changeMarker.added || association.changeMarker.deleted;
						else compare
					"
				>
					{{ association.comment }}
				</h4>
				<ng-template #compare>
					<h4 #compare [innerHTML]="association.changeMarker.comment"></h4>
				</ng-template>
			</ng-container>
			<div
				id="comment-editor-{{ association.id }}"
				class="label"
				*ngIf="isCommentInputAssociation(association, subgroup)"
			>
				<input
					class="comment"
					type="text"
					[placeholder]="'editComment.placeholder' | translate"
					(blur)="hideCommentInput()"
					[(ngModel)]="commentInputText"
					(stagesKeyUp)="onCommentInputKeyUp($event, association)"
					stagesAutofocus
				/>
			</div>
			<p *ngIf="association.changeMarker && association.changeMarker.added">
				<span *ngIf="association.changeMarker.added" class="badge added-badge" translate="process.compare.added"></span>
			</p>
			<p *ngIf="association.changeMarker && association.changeMarker.deleted">
				<span
					*ngIf="association.changeMarker.deleted"
					class="badge deleted-badge"
					translate="process.compare.deleted"
				></span>
			</p>
		</div>
	</div>
	<stages-menu
		*ngIf="editable && showMenu"
		class="action"
		[context]="{ association: association, group: group, subgroup: subgroup, expandableGroup: expandableGroup }"
		menuId="assocMenu{{ association.id }}"
		iconClasses="ico ico-more-vertical"
		openTo="left"
		[items]="associationMenuItems"
	></stages-menu>
</ng-template>

<p *ngIf="!visibleGroups.length" class="empty" translate="{{ messageKeyNone }}"></p>
<ul *ngIf="visibleGroups.length" class="grouped-list">
	<li
		class="group"
		*ngFor="let group of visibleGroups; trackBy: trackById"
		[id]="group.id"
		[ngClass]="getGroupStyleClasses(group)"
		[hidden]="!group.visible"
	>
		<header class="group-header">
			<div class="list-header-with-counter">
				<h3 *ngIf="group.translate; else useName" (click)="toggle(group)" [translate]="group.translate"></h3>
				<ng-template #useName>
					<h3 (click)="toggle(group)">{{ group.name }}</h3>
				</ng-template>
				<span class="counter">{{ getAssociationsCount(group) }}</span>
			</div>
			<stages-button-expand-collapse
				class="action menu-button"
				[isCollapsed]="!group.expand"
				(pressedButton)="toggle(group)"
			>
			</stages-button-expand-collapse>
			<stages-menu
				*ngIf="hasAnyAssociations(group)"
				[context]="group"
				menuId="assocGroupMenu{{ group.id }}"
				class="action"
				iconClasses="ico ico-more-vertical"
				openTo="left"
				[items]="associationGroupMenuItems"
			></stages-menu>
		</header>
		<div class="relative-container">
			<div class="animation-container">
				<div
					#expandableGroup
					[@expandCollapseFromTop]="getExpandCollapseFromTop(group, expandableGroup)"
					class="expandable"
					(@expandCollapseFromTop.done)="doneExpandCollapse($event, group)"
					[ngClass]="group.expand ? 'expand' : 'collapse'"
				>
					<div class="button-bar" *ngIf="group.action === 'add'" (click)="preventClosingAdd($event)">
						<div class="buttons">
							<button class="sm cancel" (click)="closeEdit(group)" translate="cancel"></button>
							<button class="sm prime" (click)="browse(group)" translate="browse" stages-scroll="keep"></button>
						</div>
						<stages-add-target-element
							[associationStore]="associationStore"
							[sourceElement]="asTypedId(sourceElementForAdd)"
							[sourceRole]="group.sourceRole"
							[targetElementWorkspace]="group.workspace"
							[targetElementType]="group.targetType!"
							[targetElementSubtypes]="group.targetSubtypes!"
							[targetElementParentId]="group.targetElementParentId"
							[targetElementProcessIds]="targetElementProcessIds"
							[targetElementCreateAllowed]="group.targetElementCreateAllowed"
							[commentOnlyAssociationsAllowed]="group.commentOnlyAssociationsSupported"
							[targetElementsToIgnore]="getTargetElementsToIgnore(group)"
							[associationType]="group.type"
							[remoteWorkspaceSeparatorInAutocomplete]="remoteWorkspaceSeparatorInAutocomplete"
							[restrictAssociateDependentElementTypes]="getDependentElementTypesRestrictions(group)"
							(addedCallback)="associationAdded($event, group.id)"
							(cancelled)="closeEdit(group)"
						>
						</stages-add-target-element>
					</div>

					<stages-buttons-responsive *ngIf="group.action === 'delete' || group.action === 'sort'">
						<ng-container *ngIf="group.action === 'delete'">
							<button class="sm cancel" (click)="closeEdit(group)" translate="cancel"></button>
							<button
								class="sm delete"
								(click)="deleteAssociations(group)"
								translate="remove"
								[disabled]="!group.selection!.isSomeSelected()"
							></button>
						</ng-container>
						<ng-container *ngIf="group.action === 'sort'">
							<button class="sm cancel" (click)="onCancel(group)" translate="cancel"></button>
							<button
								class="button sm save sort"
								type="submit"
								name="submit"
								[disabled]="isSaveInProgress || !sortOrderWasChanged"
								(click)="saveSortedAssociations(group)"
							>
								<span *ngIf="!isSaveInProgress" translate="rearrange"></span>
								<stages-spin *ngIf="isSaveInProgress" size="s"></stages-spin>
							</button>
						</ng-container>
					</stages-buttons-responsive>

					<div class="sort-selection-box" *ngIf="group.action === 'sort'">
						<div class="select"></div>
						<div class="label">
							<select
								name="order"
								id="order"
								(change)="onOrderChange($any($event.target).value, group)"
								class="fullsize"
							>
								<option
									value="ALPHABETICAL"
									translate="rearrange.order.az"
									[selected]="getActualSortStrategy(group) === 'ALPHABETICAL'"
								></option>
								<option
									value="CUSTOM"
									translate="rearrange.order.custom"
									[selected]="getActualSortStrategy(group) === 'CUSTOM'"
								></option>
								<option
									value="HIERARCHICAL"
									translate="rearrange.order.hierarchical"
									[selected]="getActualSortStrategy(group) === 'HIERARCHICAL'"
								></option>
							</select>
						</div>
					</div>
					<div #scrollContainer>
						<ul
							#assocList
							cdkDropList
							(cdkDropListDropped)="drop($event, group)"
							[cdkDropListDisabled]="group.action !== 'sort'"
							class="list dense"
							[ngClass]="{ selectable: group.action === 'delete' }"
						>
							<li
								class="group subgroup"
								*ngFor="let subgroup of group.subgroups"
								[style.display]="subgroup.visible ? 'block' : 'none'"
							>
								<header class="group-header">
									<h4
										[style.text-decoration]="subgroup.tailored ? 'line-through' : 'none'"
										(click)="toggleSubgroup(subgroup, group)"
									>
										{{ subgroup.name }}
									</h4>
									<span class="counter">{{ subgroup.list.length }}</span>
									<stages-button-expand-collapse
										class="action menu-button"
										[isCollapsed]="!subgroup.expand"
										[ngClass]="hasAnyEnabledGroupMenuItems(group) ? 'right-indent' : ''"
										(pressedButton)="toggleSubgroup(subgroup, group)"
									>
									</stages-button-expand-collapse>
								</header>
								<div class="animation-container">
									<div
										#expandableSubgroup
										[@expandCollapseFromTop]="getExpandCollapseFromTop(group, expandableSubgroup, subgroup)"
										class="expandable"
										[ngClass]="subgroup.expand ? 'expand' : 'collapse'"
									>
										<ul
											#assocList
											cdkDropList
											(cdkDropListDropped)="dropSubgroup($event, group, subgroup)"
											[cdkDropListDisabled]="group.action !== 'sort'"
											class="list dense subgroup-list"
										>
											<li
												#assocListItem
												cdkDragLockAxis="y"
												cdkDrag
												class="list-item"
												*ngFor="let association of subgroup.visibleList; trackBy: trackById"
											>
												<div class="dragged-item-placeholder" *cdkDragPlaceholder></div>
												<div *ngIf="group.action === 'sort'" class="handle draggableAreaForSort">
													<i class="ico ico-handle"></i>
												</div>
												<ng-container
													*ngTemplateOutlet="
														associationTemplate;
														context: {
															group: group,
															subgroup: subgroup,
															association: association,
															expandableGroup: expandableGroup,
															sourceElement: container.sourceElement
														}
													"
												></ng-container>
											</li>
											<li class="list-item" *ngIf="hasMoreInSubgroup(subgroup)">
												<a class="label clickable" (click)="loadMoreIntoSubgroup(subgroup)">
													<div class="text center">
														<span class="show-more center" translate="more"></span>
													</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</li>

							<li
								class="list-item"
								cdkDrag
								cdkDragLockAxis="y"
								*ngFor="let association of group.visibleList; trackBy: trackById"
							>
								<div class="dragged-item-placeholder" *cdkDragPlaceholder></div>
								<div *ngIf="group.action === 'sort'" class="handle draggableAreaForSort">
									<i class="ico ico-handle"></i>
								</div>
								<ng-container
									*ngTemplateOutlet="
										associationTemplate;
										context: {
											group: group,
											association: association,
											expandableGroup: expandableGroup,
											sourceElement: container.sourceElement
										}
									"
								></ng-container>
							</li>
							<li class="list-item" *ngIf="hasMore(group)">
								<a class="label clickable" (click)="loadMore(group)">
									<div class="text center">
										<span class="show-more center" translate="more"></span>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</li>
</ul>

<ng-template #spinner>
	<stages-spin size="sm"></stages-spin>
</ng-template>
