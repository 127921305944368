import { Store } from "common/data/store.logic";
import { DashboardResource } from "core/stages-client";

type ToDoGroup = stages.dashboard.ToDoGroup;

export class MyTaskStore implements Store<ToDoGroup> {
	constructor(private dashboardResource: DashboardResource) {}

	async getPage(pageIndex: number, workspaceId: string): Promise<PagedResult<ToDoGroup>> {
		return this.dashboardResource.getToDoList({ workspaceId: workspaceId, page: pageIndex, pageSize: 20 });
	}
}
