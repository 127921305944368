import { InputBase } from "common/form/input/types/input-base";
import { TextInputArgs } from "common/form/input/types/text-input";

export class TextAreaInput extends InputBase<string> {
	constructor(args: TextAreaInputArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "textarea",
			required: args.required,
			maxLength: args.maxLength,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
			placeholderKey: args.placeholderKey,
		});
	}
}

export class TextAreaInputArgs extends TextInputArgs {
	placeholderKey?: string | null;
}
