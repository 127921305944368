import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
	selector: "stages-files-oauth-confirm",
	templateUrl: "./files-oauth-confirm.component.html",
	styleUrls: ["./files-oauth-confirm.component.scss"],
})
export class FilesOAuthConfirmComponent implements OnDestroy {
	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	cmType$: Observable<string>;
	authUrl: string | null;

	constructor(private route: ActivatedRoute, private translateService: TranslateService) {
		this.cmType$ = this.route.paramMap.pipe(
			switchMap((paramMap) => this.translateService.get(paramMap.get("cmTypeMessageKey")!)),
		);
		this.authUrl = this.route.snapshot.paramMap.get("authUrl");
	}

	ngOnDestroy(): void {}

	submit(): void {
		if (this.authUrl) {
			window.location.href = this.authUrl;
		}
	}

	close(): void {
		this.dialog.close();
	}
}
