import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { DataViewComponent, Mode } from "common/data/data-view.component";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { DialogService } from "common/dialog/dialog.service";
import { ProcessElementsResource, UserGroupResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { UserGroupsStore } from "process/usergroups/user-groups-store.logic";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { lastValueFrom, Observable, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";

@Component({
	selector: "stages-user-groups",
	templateUrl: "./user-groups.component.html",
})
export class UserGroupsComponent extends BaseComponent implements OnInit, OnDestroy {
	UserGroupsComponent = UserGroupsComponent;
	@Input()
	pageSize: number = 10;

	@Input()
	translateNone?: string;

	@ViewChild("dataView")
	dataView!: DataViewComponent<stages.administration.usergroup.role.UserGroupAssignment>;

	params$!: Observable<ParamMap>;
	dataSource!: PageableDataSource<stages.administration.usergroup.role.UserGroupAssignment>;
	menuItems: MenuItem[] = [];
	userGroupContainer$!: Observable<stages.process.ViewableUserGroupContainer | undefined>;
	self$!: Observable<stages.process.ProcessView>;
	private subscription!: Subscription;

	constructor(
		componentService: ComponentService,
		private viewService: ViewService,
		private mutexService: MutexService,
		private route: ActivatedRoute,
		private router: Router,
		private card: CardComponent,
		private processElementsResource: ProcessElementsResource,
		private dialogService: DialogService,
		private userGroupResource: UserGroupResource,
	) {
		super(componentService);
	}

	ngOnInit(): void {
		this.self$ = this.viewService.awaitSelfElementObservable();
		this.dataSource = new PageableDataSource(
			new UserGroupsStore(
				lastValueFrom(this.self$.pipe(take(1))),
				this.processElementsResource,
				this.userGroupResource,
				this.pageSize,
			),
			this.mutexService,
			this.router,
			this.route,
		);

		this.params$ = this.route.paramMap;
		this.userGroupContainer$ = this.self$.pipe(map((self) => self.userGroupContainer));

		// prevent ExpressionChangedAfterItHasBeenCheckedError
		setTimeout(() => {
			this.subscription = this.self$.subscribe((view) => {
				this.card.menuItems = [
					{
						name: "add",
						iconClass: "ico ico-add",
						disabled: () => {
							return (
								!view.userGroupContainer ||
								!view.userGroupContainer.allowedOperations.AssignRoles ||
								!this.isValidVersion(view)
							);
						},
						on: () => {
							this.dataView.enterAdd(false);
						},
					},
					{
						name: "delete",
						iconClass: "ico ico-delete",
						disabled: () => {
							return (
								!view.userGroupContainer ||
								!view.userGroupContainer.allowedOperations.UnassignRoles ||
								!this.isValidVersion(view)
							);
						},
						on: () => {
							this.dataView.enterDelete();
						},
					},
				];

				this.menuItems = [
					{
						name: "delete",
						iconClass: "ico ico-delete",
						disabled: (userGroup: stages.administration.usergroup.role.UserGroupAssignment) => {
							return !userGroup.allowedOperations.UnassignRoles || !this.isValidVersion(view);
						},
						on: async (userGroupAssignment: stages.administration.usergroup.role.UserGroupAssignment) => {
							if (
								await this.dialogService.confirm(
									"process.element.usergroupassignments.unassign.confirm",
									{ name: userGroupAssignment.name },
									"delete",
									"cancel",
									true,
								)
							) {
								this.dataView.dataSource.delete(
									[userGroupAssignment.id!],
									"unassignUserGroup" + userGroupAssignment.id!,
								);
							}
						},
					},
				];
			});

			if (this.dataView) {
				this.dataView.buttons = [
					{
						class: "button sm cancel",
						translate: "cancel",
						click: () => {
							this.dataView.mode = Mode.VIEW;
						},
						visible: () => {
							return this.dataView.mode === Mode.DELETE;
						},
						disabled: () => {
							return false;
						},
					},
					{
						class: "button sm delete",
						translate: "delete",
						click: () => {
							void this.dataView.dataSource.delete(this.dataView.selection.selected, "userGroupAssignments");
							this.dataView.mode = Mode.VIEW;
						},
						visible: () => {
							return this.dataView.mode === Mode.DELETE;
						},
						disabled: () => {
							return this.dataView.selection.isEmpty();
						},
					},
				];
			}
		});
	}

	isValidVersion(view: stages.process.ProcessView): boolean {
		return view.process && view.process.isValidVersion;
	}

	ngDestroy(): void {
		if (this.dataSource) {
			this.dataSource.disconnect();
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	static getId(userGroupAssignment: stages.administration.usergroup.role.UserGroupAssignment): string {
		return userGroupAssignment.id!;
	}

	static isDisabled(): boolean {
		return false;
	}
}
