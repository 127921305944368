import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogService } from "common/dialog/dialog.service";
import { MainService } from "core/main.service";
import { FilesResource, ProcessElementsResource } from "core/stages-client";
import { UrlService } from "core/url.service";
import { FilesAuthDialogComponent } from "files/auth/files-auth-dialog.component";
import { LoadingErrorService } from "files/error/loading-error.service";
import { FilesOperation } from "files/files-operation";
import { BehaviorSubject, from, lastValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TemplateFile } from "files/template/files-template.component";

type Attribute = stages.core.Attribute;
type AttributeType = stages.core.AttributeType;
type AttributeDataAndInfo = DataAndInfo<Attribute[], AttributeType[]>;
type File = stages.file.File;
type FileError = stages.file.FileError;
type FileProperties = stages.file.FileProperties;
type FileDescriptor = stages.process.FileDescriptor;
type FileState = stages.file.FileState;
type FileStateMetadata = stages.file.FileStateMetadata;
type FileStateDataAndInfo = DataAndInfo<FileState, FileStateMetadata>;
type FileUpload = stages.file.FileUpload;
type LinkFileSpec = stages.file.LinkFileSpec;
type Status = stages.core.scheduler.Status;
type Upload = stages.file.Upload;
type UrlProperties = stages.file.UrlProperties;
type FileOperationPropertiesInfo = stages.file.FileOperationPropertiesInfo;
type AuthInfo = stages.core.cms.AuthInfo;

export interface UrlTemplate {
	name: string;
	url: string;
	securityLevel: number;
}

export interface FileContainerDescriptor {
	workspaceId: string;
	elementId: string;
	elementType: string;
}

class OAuthAttributes {
	attributes!: Attribute[] | null;

	constructor(attributes: Attribute[] | null) {
		this.attributes = attributes;
	}
}

class OAuthCheckinAttributes extends OAuthAttributes {
	fileUpload: FileUpload;

	constructor(fileUpload: FileUpload) {
		super(null);
		this.fileUpload = fileUpload;
	}
}

class OAuthTemplateAttributes extends OAuthAttributes {
	templateId: string;
	fileName: string;

	constructor(attributes: Attribute[], templateId: string, fileName: string) {
		super(attributes);
		this.templateId = templateId;
		this.fileName = fileName;
	}
}

class OAuthDownloadAttributes extends OAuthAttributes {
	versionIdentifier: string | null;
	openNewTab: boolean;

	constructor(versionIdentifier: string | null, openNewTab: boolean) {
		super(null);
		this.versionIdentifier = versionIdentifier;
		this.openNewTab = openNewTab;
	}
}

@Injectable({ providedIn: "root" })
export class FileService implements FileService {
	inProgressFileIds$ = new BehaviorSubject<Set<string>>(new Set<string>());

	constructor(
		private readonly urlService: UrlService,
		private readonly mainService: MainService,
		private readonly processElementsResource: ProcessElementsResource,
		private readonly filesResource: FilesResource,
		private readonly dialogService: DialogService,
		private readonly mutexService: MutexService,
		private readonly loadingErrorService: LoadingErrorService,
	) {}

	addInProgressFileIds(fileIds: string[]): void {
		if (!fileIds.length) {
			return;
		}
		const newValue = new Set<string>(this.inProgressFileIds$.value);
		fileIds.forEach((fileId) => newValue.add(fileId));
		this.inProgressFileIds$.next(newValue);
	}

	deleteInProgressFileIds(fileIds: string[]): void {
		const newValue = new Set<string>(this.inProgressFileIds$.value);
		fileIds.forEach((fileId) => newValue.delete(fileId));
		this.inProgressFileIds$.next(newValue);
	}

	async saveUploads(
		route: ActivatedRoute,
		workspaceId: string,
		elementType: string,
		elementId: string,
		fileUpload: FileUpload,
		pv: string,
	): Promise<void> {
		try {
			await this.processElementsResource.saveUploads(workspaceId, elementType, elementId, fileUpload, {
				pv: pv,
				redirectUrl: window.location.href,
			});
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
		} catch (error) {
			if (error.status === 901) {
				const oAuthAttributes = new OAuthCheckinAttributes(fileUpload);
				this.handleAuthWithRetry(
					error,
					() => {
						this.saveUploads(route, workspaceId, elementType, elementId, fileUpload, pv);
					},
					null,
					elementType,
					elementId,
					workspaceId,
					pv,
					oAuthAttributes,
					FilesOperation.DO_ADD,
					route,
				);
			} else {
				this.handleNonAuthError(error, route);
			}
		}
	}

	async link(workspaceId: string, type: string, id: string, linkSpecs: LinkFileSpec[], pv: string): Promise<void> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
			redirectUrl: window.location.href,
		});
		return this.processElementsResource.link(workspaceId, type, id, linkSpecs);
	}

	async saveLockAndDownloadTemplate(
		route: ActivatedRoute,
		workspaceId: string,
		elementType: string,
		elementId: string,
		templateId: string,
		fileName: string,
		attributes: Attribute[],
		pv: string,
		lockFile: boolean,
	): Promise<void> {
		return this.saveLockAndDownloadTemplateByTemplateFile(
			route,
			workspaceId,
			elementType,
			elementId,
			templateId,
			fileName,
			attributes,
			pv,
			lockFile,
			undefined,
		);
	}

	/*
	 * This method makes sure, the authentication on a cms is done
	 * from the file management plan of the process element where the chosen
	 * template file is attached to
	 */
	async saveLockAndDownloadTemplateByTemplateFile(
		route: ActivatedRoute,
		workspaceId: string,
		elementType: string,
		elementId: string,
		templateId: string,
		fileName: string,
		attributes: Attribute[],
		pv: string,
		lockFile: boolean,
		templateFileWithRemoteInformation?: TemplateFile,
	): Promise<void> {
		try {
			await this.saveLockAndDownloadTemplateInternal(
				route,
				workspaceId,
				elementType,
				elementId,
				templateId,
				fileName,
				attributes,
				pv,
				lockFile,
			);
		} catch (error: unknown) {
			const errorResponse = error as HttpErrorResponse;
			if (errorResponse.status === 901) {
				this.handleAuthWithRetry(
					errorResponse,
					() => {
						this.saveLockAndDownloadTemplateByTemplateFile(
							route,
							workspaceId,
							elementType,
							elementId,
							templateId,
							fileName,
							attributes,
							pv,
							lockFile,
							templateFileWithRemoteInformation,
						);
					},
					templateFileWithRemoteInformation ? templateFileWithRemoteInformation.fileId : templateId,
					templateFileWithRemoteInformation ? templateFileWithRemoteInformation.fileContainerType : elementType,
					templateFileWithRemoteInformation ? templateFileWithRemoteInformation.fileContainerId : elementId,
					templateFileWithRemoteInformation ? templateFileWithRemoteInformation.workspaceId : workspaceId,
					templateFileWithRemoteInformation ? templateFileWithRemoteInformation.pv : pv,
					new OAuthTemplateAttributes(attributes, templateId, fileName),
					lockFile ? FilesOperation.DO_ADD_FROM_TEMPLATE_AND_LOCK : FilesOperation.DO_ADD_FROM_TEMPLATE,
					route,
				);
			} else {
				this.handleNonAuthError(errorResponse, route);
			}
		}
	}

	getPropertiesWithTemplateProperties(
		workspaceId: string,
		pv: string,
		id: string,
		type: string,
		fileDescriptors: FileDescriptor[],
	): Observable<stages.file.FileOperationPropertiesInfoWithTemplateProperties> {
		return from(
			this.processElementsResource.getPropertiesWithTemplateProperties(workspaceId, type, id, fileDescriptors, {
				pv: pv,
				redirectUrl: window.location.href,
			}),
		);
	}

	async removeUploads(
		route: ActivatedRoute,
		workspaceId: string,
		type: string,
		id: string,
		fileUpload: FileUpload,
		pv: string,
	): Promise<void> {
		if (fileUpload.uploads.length > 0) {
			fileUpload.uploads.forEach((upload: Upload) => {
				upload.deleted = true;
			});
			await this.saveUploads(route, workspaceId, type, id, fileUpload, pv);
		}
	}

	async removeUploadsWithoutSave(route: ActivatedRoute, fileUpload: FileUpload, pv?: string): Promise<void> {
		fileUpload.uploads.forEach((upload: Upload) => {
			upload.deleted = true;
		});

		if (pv !== undefined) {
			this.filesResource.setAdditionalHttpParamsForNextRequest({
				pv: pv,
			});
		}

		await this.filesResource.removeUploads(fileUpload);
	}

	getMessageKeyForAdd(singleFileOnly: boolean, numberOfFiles: number, fileProperties?: FileProperties): string {
		if (fileProperties && fileProperties.id) {
			return "files.checkin";
		} else if (singleFileOnly && numberOfFiles === 0) {
			return "files.add.single";
		} else if (singleFileOnly && numberOfFiles > 0) {
			return "files.replace.single";
		}
		return "files.add.multiple";
	}

	async download(
		route: ActivatedRoute,
		id: string,
		workspaceId: string,
		pv: string,
		versionIdentifier: string | null,
		openNewTab: boolean,
	): Promise<void> {
		return this.downloadInternal(route, id, workspaceId, pv, versionIdentifier, openNewTab, true);
	}

	// Use this when cms user authentication is already assured (e.g. for lock & download). The download will fail, if the user has no valid cms credentials.
	async downloadAlreadyAuthenticated(
		route: ActivatedRoute,
		id: string,
		workspaceId: string,
		pv: string,
		versionIdentifier: string | null,
		openNewTab: boolean,
	): Promise<void> {
		return this.downloadInternal(route, id, workspaceId, pv, versionIdentifier, openNewTab, false);
	}

	private async downloadInternal(
		route: ActivatedRoute,
		fileId: string,
		workspaceId: string,
		pv: string,
		versionIdentifier: string | null,
		openNewTab: boolean,
		assureAuth: boolean,
	): Promise<void> {
		const downloadUrl = this.getDownloadUrl(fileId, versionIdentifier, workspaceId);
		const target: string = openNewTab ? "_blank" : "_self";

		try {
			this.filesResource.setAdditionalHttpParamsForNextRequest({
				workspaceId: workspaceId,
				pv: pv,
				redirectUrl: window.location.href,
			});
			if (assureAuth) {
				await this.filesResource.authorizeDownload(fileId);
			}
			window.open(downloadUrl, target);
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
		} catch (error) {
			if (error.status === 901) {
				const filesOperation = assureAuth ? FilesOperation.DO_DOWNLOAD_AND_ASSURE_AUTH : FilesOperation.DO_DOWNLOAD;
				const oAuthAttributes = new OAuthDownloadAttributes(versionIdentifier, openNewTab);
				this.handleAuthWithRetry(
					error,
					() => {
						this.downloadInternal(route, fileId, workspaceId, pv, versionIdentifier, openNewTab, assureAuth);
					},
					fileId,
					null,
					null,
					workspaceId,
					pv,
					oAuthAttributes,
					filesOperation,
					route,
				);
			} else {
				this.handleNonAuthError(error, route);
			}
		}
	}

	async lockAndDownload(
		fileId: string,
		elementType: string,
		elementId: string,
		download: boolean,
		route: ActivatedRoute,
	): Promise<void> {
		return this.mutexService.invokeWithResult("lockAndDownload" + fileId, async () => {
			try {
				const checkoutDataAndInfo = await lastValueFrom(
					this.getCheckoutDataAndInfo(
						fileId,
						route.snapshot.paramMap.get("workspaceId")!,
						route.snapshot.paramMap.get("processVersion")!,
					),
				);

				if (checkoutDataAndInfo.metadata.length > 0) {
					this.openPopup(fileId, elementType, elementId, "checkout", download, route);
				} else {
					await this.doLockAndDownload(
						route,
						fileId,
						[],
						route.snapshot.paramMap.get("workspaceId")!,
						route.snapshot.paramMap.get("processVersion")!,
						download,
					);
				}
				// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
			} catch (error) {
				if (error.status === 901) {
					const fileOperation = download ? FilesOperation.DOWNLOAD_AND_LOCK : FilesOperation.LOCK;
					const oAuthAttributes = new OAuthAttributes(null);
					this.handleAuthWithRetry(
						error,
						() => {
							this.lockAndDownload(fileId, elementType, elementId, download, route);
						},
						fileId,
						elementType,
						elementId,
						null,
						null,
						oAuthAttributes,
						fileOperation,
						route,
					);
				} else {
					this.handleNonAuthError(error, route);
				}
			}
		});
	}

	async doLockAndDownload(
		route: ActivatedRoute,
		fileId: string,
		attributes: Attribute[],
		workspaceId: string,
		pv: string,
		download: boolean,
	): Promise<void> {
		return this.mutexService.invokeWithResult("doLock" + fileId, async () => {
			try {
				this.filesResource.setAdditionalHttpParamsForNextRequest({
					workspaceId: workspaceId,
					pv: pv,
					redirectUrl: window.location.href,
				});
				await this.filesResource.checkout(fileId, attributes ? attributes : []);
				this.addInProgressFileIds([fileId]);
				if (download) {
					const downloadUrl = this.getDownloadUrl(fileId, null, workspaceId, pv);
					window.location.href = downloadUrl;
				}
				// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
			} catch (error) {
				if (error.status === 901) {
					const fileOperation = download ? FilesOperation.DO_DOWNLOAD_AND_LOCK : FilesOperation.DO_LOCK;
					const oAuthAttributes = new OAuthAttributes(attributes);
					this.handleAuthWithRetry(
						error,
						() => {
							this.doLockAndDownload(route, fileId, attributes, workspaceId, pv, download);
						},
						fileId,
						null,
						null,
						workspaceId,
						pv,
						oAuthAttributes,
						fileOperation,
						route,
					);
				} else {
					this.handleNonAuthError(error, route);
				}
			}
		});
	}

	async unlock(fileId: string, elementType: string, elementId: string, route: ActivatedRoute): Promise<void> {
		return this.mutexService.invokeWithResult("unlock" + fileId, async () => {
			try {
				const revertDataAndInfo = await lastValueFrom(
					this.getRevertDataAndInfo(
						fileId,
						route.snapshot.paramMap.get("workspaceId")!,
						route.snapshot.paramMap.get("processVersion")!,
					),
				);

				if (revertDataAndInfo.metadata.length > 0) {
					this.openPopup(fileId, elementType, elementId, "revert", false, route);
				} else {
					await this.doUnlock(
						route,
						fileId,
						[],
						route.snapshot.paramMap.get("workspaceId")!,
						route.snapshot.paramMap.get("processVersion")!,
					);
				}
				// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
			} catch (error) {
				if (error.status === 901) {
					const oAuthAttributes = new OAuthAttributes(null);
					this.handleAuthWithRetry(
						error,
						() => {
							this.unlock(fileId, elementType, elementId, route);
						},
						fileId,
						elementType,
						elementId,
						null,
						null,
						oAuthAttributes,
						FilesOperation.UNLOCK,
						route,
					);
				} else {
					this.handleNonAuthError(error, route);
				}
			}
		});
	}

	async doUnlock(
		route: ActivatedRoute,
		fileId: string,
		attributes: Attribute[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		return this.mutexService.invokeWithResult("doUnlock" + fileId, async () => {
			try {
				this.filesResource.setAdditionalHttpParamsForNextRequest({
					pv: pv,
					redirectUrl: window.location.href,
				});
				await this.filesResource.revert(fileId, attributes, {
					workspaceId: workspaceId,
				});
				this.addInProgressFileIds([fileId]);
				// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
			} catch (error) {
				if (error.status === 901) {
					const oAuthAttributes = new OAuthAttributes(attributes);
					this.handleAuthWithRetry(
						error,
						() => {
							this.doUnlock(route, fileId, attributes, workspaceId, pv);
						},
						fileId,
						null,
						null,
						workspaceId,
						pv,
						oAuthAttributes,
						FilesOperation.DO_UNLOCK,
						route,
					);
				} else {
					this.handleNonAuthError(error, route);
				}
			}
		});
	}

	async checkin(
		route: ActivatedRoute,
		fileId: string,
		fileUpload: stages.file.FileUpload,
		workspaceId: string,
		pv: string,
	): Promise<void> {
		try {
			this.filesResource.setAdditionalHttpParamsForNextRequest({
				workspaceId: workspaceId,
				redirectUrl: window.location.href,
			});
			await this.filesResource.saveUploads(fileId, fileUpload, {
				pv: pv,
			});
			this.addInProgressFileIds([fileId]);
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
		} catch (error) {
			if (error.status === 901) {
				const oAuthAttributes = new OAuthCheckinAttributes(fileUpload);
				this.handleAuthWithRetry(
					error,
					() => {
						this.checkin(route, fileId, fileUpload, workspaceId, pv);
					},
					fileId,
					null,
					null,
					workspaceId,
					pv,
					oAuthAttributes,
					FilesOperation.DO_CHECKIN,
					route,
				);
			} else {
				this.handleNonAuthError(error, route);
			}
		}
	}

	async saveState(
		route: ActivatedRoute,
		fileId: string,
		attributes: Attribute[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		try {
			this.filesResource.setAdditionalHttpParamsForNextRequest({
				workspaceId: workspaceId,
				pv: pv,
				redirectUrl: window.location.href,
			});
			await this.filesResource.saveState(fileId, attributes);
			this.addInProgressFileIds([fileId]);
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
		} catch (error) {
			if (error.status === 901) {
				const oAuthAttributes = new OAuthAttributes(attributes);
				this.handleAuthWithRetry(
					error,
					() => {
						this.saveState(route, fileId, attributes, workspaceId, pv);
					},
					fileId,
					null,
					null,
					workspaceId,
					pv,
					oAuthAttributes,
					FilesOperation.DO_SET_STATE,
					route,
				);
			} else {
				this.handleNonAuthError(error, route);
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
	async openPopup(
		fileId: string,
		elementType: string,
		elementId: string,
		dialogName: string,
		download: boolean,
		route: ActivatedRoute,
	): Promise<void> {
		this.mainService.openPopup(
			[
				"files",
				dialogName,
				{
					elementType: elementType,
					elementId: elementId,
					fileId: fileId,
					download: download,
				},
			],
			route,
		);
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
	async openFilesAddPopup(
		elementType: string,
		elementId: string,
		singleFile: boolean,
		currentFileCount: number,
		route: ActivatedRoute,
	): Promise<void> {
		this.mainService.openPopup(
			[
				"files",
				"add",
				{
					elementType: elementType,
					elementId: elementId,
					singleFile: singleFile,
					currentFileCount: currentFileCount,
				},
			],
			route,
		);
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
	async openFileTemplatePopup(elementType: string, elementId: string, route: ActivatedRoute): Promise<void> {
		this.mainService.openPopup(
			[
				"files",
				"template",
				{
					elementType: elementType,
					elementId: elementId,
				},
			],
			route,
		);
	}

	private openErrorPopup(error: HttpErrorResponse, route: ActivatedRoute): void {
		this.loadingErrorService.setError(error);
		this.mainService.openPopup(["files", "loadingError"], route);
	}

	private handleAuthWithRetry(
		errorResponse: HttpErrorResponse,
		retryFunction: () => void,
		fileId: string | null,
		elementType: string | null,
		elementId: string | null,
		workspaceId: string | null,
		pv: string | null,
		oAuthAdditionalAttributes: OAuthAttributes,
		filesOperation: FilesOperation,
		route: ActivatedRoute,
	): void {
		const authInfo: AuthInfo = errorResponse.error.authInfo;
		if (authInfo.authType === "EXTERNAL_LINK") {
			localStorage.setItem("cmOpName", filesOperation.toString());
			if (fileId) {
				localStorage.setItem("cmFileId", fileId);
			}
			if (elementType) {
				localStorage.setItem("cmElementType", elementType);
			}
			if (elementId) {
				localStorage.setItem("cmElementId", elementId);
			}
			if (workspaceId) {
				localStorage.setItem("cmWorkspaceId", workspaceId);
			}
			if (pv) {
				localStorage.setItem("cmProcessVersionIdentifier", pv);
			}
			if (oAuthAdditionalAttributes.attributes) {
				localStorage.setItem("cmAttributesJSON", JSON.stringify(oAuthAdditionalAttributes.attributes));
			}
			if (oAuthAdditionalAttributes instanceof OAuthCheckinAttributes) {
				localStorage.setItem("cmFileUploadJSON", JSON.stringify(oAuthAdditionalAttributes.fileUpload));
			}
			if (oAuthAdditionalAttributes instanceof OAuthTemplateAttributes) {
				localStorage.setItem("cmTemplateId", oAuthAdditionalAttributes.templateId);
				localStorage.setItem("cmFileName", oAuthAdditionalAttributes.fileName);
			}
			if (oAuthAdditionalAttributes instanceof OAuthDownloadAttributes) {
				const versionIdentifier = oAuthAdditionalAttributes.versionIdentifier;
				if (versionIdentifier) {
					localStorage.setItem("cmVersionIdentifier", versionIdentifier);
				}
				localStorage.setItem("cmOpenNewTab", oAuthAdditionalAttributes.openNewTab.toString());
			}
			this.mainService.openPopup(
				[
					"files",
					"oauth-confirm",
					{
						authUrl: authInfo.url,
						cmTypeMessageKey: errorResponse.error.cmsTypeMessageKey,
					},
				],
				route,
			);
		} else {
			this.dialogService
				.open<string>("confirm", {
					component: FilesAuthDialogComponent,
					componentArgs: {
						keys: {
							ok: "ok",
							cancel: "cancelKey",
							fileId: fileId,
							elementType: elementType,
							elementId: elementId,
							workspaceId: workspaceId,
							processVersion: pv,
							cmsTypeMessageKey: errorResponse.error.cmsTypeMessageKey,
						},
					},
					defaultResult: "cancel",
					dialogConfig: {
						size: "large",
						autoHeight: true,
					},
				})
				.then((result) => {
					if (result === "cancel") {
						if (fileId) {
							this.addInProgressFileIds([fileId]);
						}
					} else {
						retryFunction();
					}
				});
		}
	}

	private handleNonAuthError(error: HttpErrorResponse, route: ActivatedRoute): void {
		if (error.status === 902) {
			this.handleCmsOperationError(error, route);
		} else {
			throw error;
		}
	}

	private handleCmsOperationError(error: HttpErrorResponse, route: ActivatedRoute): void {
		if (error instanceof HttpErrorResponse) {
			this.openErrorPopup(error, route);
		}
	}

	getStateAndInfo(
		workspaceId: string,
		type: string,
		id: string,
		pv: string,
		fileId: string,
	): Observable<FileStateDataAndInfo> {
		return from(
			this.processElementsResource.getStateAndInfo(workspaceId, type, id, fileId, {
				pv: pv,
				redirectUrl: window.location.href,
			}),
		);
	}

	getCheckoutDataAndInfo(id: string, workspaceId: string, pv: string): Observable<AttributeDataAndInfo> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: workspaceId,
			pv: pv,
			redirectUrl: window.location.href,
		});
		return from(this.filesResource.getCheckoutDataAndInfo(id));
	}

	getRevertDataAndInfo(id: string, workspaceId: string, pv: string): Observable<AttributeDataAndInfo> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: workspaceId,
			pv: pv,
			redirectUrl: window.location.href,
		});
		return from(this.filesResource.getRevertDataAndInfo(id));
	}

	getFileStateClasses(stateIdent: string | null, messageKey: string | null): string[] {
		const fileStateClasses: string[] = [];
		if (stateIdent) {
			fileStateClasses.push("state");
			if (messageKey) {
				fileStateClasses.push("file-state-" + stateIdent.replace(/ /g, "_"));
			} else {
				fileStateClasses.push("file-state-unknown");
			}
		}
		return fileStateClasses;
	}

	async refresh(id: string, workspaceId: string, pv: string): Promise<void> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: workspaceId,
			pv: pv,
			redirectUrl: window.location.href,
		});
		await this.filesResource.refresh(id);
		this.addInProgressFileIds([id]);
	}

	getFileContainer(
		workspaceId: string,
		type: string,
		id: string,
		currentWorkspaceId: string,
		pv: string,
		path: string,
		withFiles: boolean = false,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	): Observable<any> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: currentWorkspaceId,
			pv: pv,
			redirectUrl: window.location.href,
		});
		const fileContainerView = from(
			this.processElementsResource.getFileContainerView(workspaceId, type, id, {
				withFiles: withFiles,
				path: path,
			}),
		);
		return fileContainerView.pipe(
			map((view) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				const fileContainer = (view as any).processView.fileContainer;
				return fileContainer && fileContainer[path] ? fileContainer[path] : [];
			}),
		);
	}

	getDownloadUrl(fileId: string, versionIdentifier: string | null, workspaceId?: string, pv?: string): string {
		if (versionIdentifier) {
			return this.urlService.build(
				"file/{fileId}/revision/{versionIdentifier}",
				{
					fileId: fileId,
					versionIdentifier: versionIdentifier,
				},
				{
					workspaceId: workspaceId,
					pv: pv,
					redirectUrl: window.location.href,
				},
			);
		}

		return this.urlService.build(
			"file/{fileId}",
			{
				fileId: fileId,
			},
			{
				workspaceId: workspaceId,
				pv: pv,
				redirectUrl: window.location.href,
			},
		);
	}

	async deleteFile(file: File, workspaceId: string, pv: string): Promise<void> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: workspaceId,
			pv: pv,
		});
		return this.filesResource.delete([file.id]);
	}

	getFileOperationProperties(
		route: ActivatedRoute,
		workspaceId: string,
		type: string,
		id: string,
		pv: string,
		fileId: string,
	): Observable<FileOperationPropertiesInfo> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			fileId: fileId,
			pv: pv,
			redirectUrl: window.location.href,
		});
		return from(this.processElementsResource.getProperties(workspaceId, type, id));
		// Errors (like invalid authentication) are handled by callers => no try
	}

	async getFileProperties(fileId: string, workspaceId: string, pv: string): Promise<FileProperties> {
		return this.filesResource.getFileProperties(fileId, {
			workspaceId: workspaceId,
			pv: pv,
		});
	}

	async saveFileProperties(fileId: string, workspaceId: string, pv: string, properties: FileProperties): Promise<void> {
		await this.filesResource.editFileProperties(fileId, properties, {
			workspaceId: workspaceId,
			pv: pv,
			redirectUrl: window.location.href,
		});
		this.addInProgressFileIds([fileId]);
	}

	getNewUrlTemplate(): UrlTemplate {
		return {
			name: "",
			url: "",
			securityLevel: 1,
		};
	}

	async getUrlProperties(fileId: string, workspaceId: string, pv: string): Promise<UrlProperties> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: workspaceId,
			pv: pv,
		});
		return this.filesResource.getUrlProperties(fileId);
	}

	async addUrl(
		elementType: string,
		elementId: string,
		properties: UrlProperties,
		workspaceId: string,
		pv: string,
	): Promise<void> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource.addUrl(workspaceId, elementType, elementId, properties);
	}

	async saveUrl(workspaceId: string, pv: string, properties: UrlProperties): Promise<void> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		await this.filesResource.editUrl(properties.id!, properties);
	}

	async validateUrl(
		elementType: string,
		elementId: string,
		url: string,
		workspaceId: string,
		pv: string,
	): Promise<void> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource.validateUrl(workspaceId, elementType, elementId, {
			url: url,
		});
	}

	async rename(fileId: string, name: string, workspaceId: string, pv: string): Promise<void> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		await this.filesResource.rename(fileId, name);
	}

	async getError(fileId: string, job: string, workspaceId: string, pv: string): Promise<FileError> {
		this.filesResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.filesResource.getError(fileId, job);
	}

	async getLatestJobStatus(
		workspaceId: string,
		pv: string,
		type: string,
		id: string,
		fileId?: string,
		path?: string,
	): Promise<Map<string, Status>> {
		if (fileId !== undefined) {
			this.processElementsResource.setAdditionalHttpParamsForNextRequest({
				fileId: fileId,
			});
		}
		return this.processElementsResource.getLatestJobStatus$GET$workspace_workspaceId_process_elements_type_id_files_jobs(
			workspaceId,
			type,
			id,
			{
				pv: pv,
				path: path,
			},
		) as unknown as Promise<Map<string, Status>>;
	}

	async getLatestJobStatusForFileIds(fileIds: string[]): Promise<Map<string, Status>> {
		return this.filesResource.getLatestJobStatus({
			fileIds: fileIds,
		}) as unknown as Promise<Map<string, Status>>;
	}

	/* This method saves the association between a process
	 * element and a file template, is capable of locking and downloading it afterwards
	 */
	async saveLockAndDownloadTemplateInternal(
		route: ActivatedRoute,
		workspaceId: string,
		elementType: string,
		elementId: string,
		templateId: string,
		fileName: string,
		attributes: Attribute[],
		pv: string,
		lockFile: boolean,
	): Promise<void> {
		const queryParams = {
			pv: pv,
			templateId: templateId,
			fileName: fileName,
			redirectUrl: window.location.href,
		};
		const newFileId = await (lockFile
			? this.processElementsResource.saveTemplateAndLock(workspaceId, elementType, elementId, attributes, queryParams)
			: this.processElementsResource.saveTemplate(workspaceId, elementType, elementId, attributes, queryParams));
		this.addInProgressFileIds([newFileId]);
		await this.download(route, newFileId, workspaceId, pv, null, false);
	}
}
