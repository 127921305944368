import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { hasMethod, hasProperty } from "core/functions";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TitleResolver implements Resolve<string> {
	constructor(private translateService: TranslateService) {}

	resolve(route: ActivatedRouteSnapshot): Observable<string> | Promise<string> | string {
		if (route.component) {
			const component = route.component;
			if (hasMethod(component, "provideTitlePrefixKeyAndPostfix")) {
				const [prefixKey, postfix] = component.provideTitlePrefixKeyAndPostfix(getMergedRouteData(route));
				return this.translateService
					.get(prefixKey)
					.pipe(map((prefix) => (postfix ? `${prefix} - ${postfix}` : prefix)));
			}
			// TODO ST-32211: "provideTitle" is deprecated and should be replaced by "provideTitlePrefixKeyAndPostfix"
			if (hasMethod(component, "provideTitle")) {
				return component.provideTitle(this.translateService, getMergedRouteData(route));
			}
			throw new Error(
				`TitleResolver configured for ${
					hasProperty(component, "name") ? component.name : component
				}, but provideTitle(translateService, mergedRouteData) not present`,
			);
		}
		throw new Error("TitleResolver configured, but no component found for the route");
	}
}

function getMergedRouteData(route: ActivatedRouteSnapshot): unknown {
	const result = {};
	getMergedRouteDataRecursively(route, result);
	return result;
}

function getMergedRouteDataRecursively(route: ActivatedRouteSnapshot, result: StringToUnknown): void {
	if (route.parent) {
		getMergedRouteDataRecursively(route.parent, result);
	}
	if (route.data) {
		for (const prop in route.data) {
			if (prop) {
				result[prop] = route.data[prop];
			}
		}
	}
}
