import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";

type EditableElement = stages.process.EditableElement;
type EditableProcess = stages.process.EditableProcess;
type EditableAttribute = stages.process.EditableAttribute;
type Attribute = stages.core.Attribute;

export type AttributeScope = "ALL" | "PROCESS" | "PROJECT";
export type ElementTypeIdent = "activty" | "artifact" | "guidance" | "phase" | "role";
export type ElementTypeIdentOrProcess = ElementTypeIdent | "process";

@Injectable({ providedIn: "root" })
export class EditService {
	constructor(
		private readonly viewService: ViewService,
		private readonly processElementsResource: ProcessElementsResource,
	) {}

	async getEditableAttributes(
		type: ElementTypeIdent,
		identity: string,
		attributeScope: AttributeScope,
		currentWorkspaceId: string,
		pv: string,
	): Promise<EditableAttribute[]> {
		return this.processElementsResource.getEditableAttributes(currentWorkspaceId, type, identity, attributeScope, {
			pv: pv,
		});
	}

	async saveAttributes(
		type: ElementTypeIdent,
		identity: string,
		attributeScope: AttributeScope,
		attributes: Attribute[],
		currentWorkspaceId: string,
		pv: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	): Promise<any> {
		return this.processElementsResource.saveEditedAttributes(
			currentWorkspaceId,
			type,
			identity,
			attributeScope,
			attributes,
			{
				pv: pv,
			},
		);
	}

	async getEditableElement(
		type: ElementTypeIdentOrProcess,
		identity: string,
		currentWorkspaceId: string,
		pv: string,
	): Promise<EditableElement> {
		return this.processElementsResource.getEditableElement(currentWorkspaceId, type, identity, {
			pv: pv,
		});
	}

	async saveElement(
		type: ElementTypeIdentOrProcess | "process",
		identity: string,
		element: stages.process.Element | stages.process.Process,
		currentWorkspaceId: string,
		pv: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	): Promise<any> {
		if (type === "process") {
			return this.saveProcess(element as stages.process.Process, currentWorkspaceId, pv);
		}
		return this.processElementsResource.saveEditedElement(
			currentWorkspaceId,
			type,
			identity,
			element as stages.process.Element,
			{
				pv: pv,
			},
		);
	}

	async getEditableProcess(currentWorkspaceId: string, pv: string): Promise<EditableProcess> {
		return this.processElementsResource.getEditableProcess(currentWorkspaceId, {
			pv: pv,
		});
	}

	async saveProcess(process: stages.process.Process, currentWorkspaceId: string, pv: string): Promise<void> {
		return this.processElementsResource.saveEditedProcess(currentWorkspaceId, process, {
			pv: pv,
		});
	}

	async renameElement(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		element: any,
		type: string,
		identity: string,
		elementName: string,
		currentWorkspaceId: string,
		pv: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	): Promise<any> {
		const promise = this.processElementsResource
			.renameProcessElement(currentWorkspaceId, type, identity, {
				name: elementName,
				pv: pv,
			})
			.then((updatedElement) => {
				element.name = updatedElement.name;
				element.shortname = updatedElement.shortname;
				element.label = updatedElement.label;
				this.viewService.notifyModified();
			});
		return promise;
	}
}
