import { Component, Input } from "@angular/core";
import { ViewService } from "core/view.service";
import { Observable } from "rxjs";

@Component({
	selector: "stages-process-element-properties-wrapper",
	templateUrl: "./properties-wrapper.component.html",
})
export class PropertiesWrapperComponent {
	@Input() translateNone?: string;
	@Input() propertyList?: unknown;

	selfElement$: Observable<stages.process.ProcessTreeItem>;

	constructor(private viewService: ViewService) {
		this.selfElement$ = this.viewService.awaitSelfElementObservable();
	}
}
