import { Component, OnInit } from "@angular/core";
import { ViewService } from "core/view.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { Observable } from "rxjs";

type ProcessView = stages.process.ProcessView;

@Component({
	selector: "stages-feedback-switch-widget",
	templateUrl: "./feedback-switch-widget.component.html",
})
export class FeedbackSwitchWidgetComponent extends BaseComponent implements OnInit {
	processView$!: Observable<ProcessView>;

	constructor(componentService: ComponentService, private viewService: ViewService) {
		super(componentService);
	}

	ngOnInit(): void {
		this.processView$ = this.viewService.awaitSelfElementObservable();
	}
}
