<stages-viewable-selectable-browser
	*ngIf="loadingCompleted"
	[browseRootType]="browseRootType"
	[folder]="$any(folder)"
	[children]="selectableChildren"
	[rootLabel]="workspaceName"
	[commentsAllowed]="commentsAllowed"
	[allowIndexElementsForSelection]="indexAllowed"
	[initialSelections]="initialSelections"
	[allElements]="allTreeElements"
	[filteredSelectables]="cachedSelectableTypedIds"
	[storeInstanceName]="storeIdentifier"
	[isDialog]="true"
	[translateNone]="'process.element.associationBrowser.none'"
	[allowWorkspaceNavigation]="allowNavigation"
	[restrictAssociateDependentElementTypes]="restrictAssociateDependentElementTypes"
	(navigateToChild)="navigateToChild($event)"
	(navigateToParent)="navigateToParent($event)"
	(navigateToWorkspaces)="navigateToWorkspaces()"
	(save)="onSave($event)"
	(cancel)="onCancel()"
>
</stages-viewable-selectable-browser>
