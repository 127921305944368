import { ObserversModule } from "@angular/cdk/observers";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { StagesCommonModule } from "common/common.module";
import { DisabledFilterPipe } from "navigation/disabled-filter.pipe";
import { NavigationDrawerComponent } from "navigation/drawer/navigation-drawer.component";
import { ClipboardComponent } from "navigation/list/clipboard/clipboard.component";
import { ChangeMarkerNavigationEntryComponent } from "navigation/list/entry/change-marker-navigation-entry.component";
import { SingleLineNavigationEntryComponent } from "navigation/list/entry/single-line-navigation-entry.component";
import { TwoLineNavigationEntryComponent } from "navigation/list/entry/two-line-navigation-entry.component";
import { TwoLineSplitRatingNavigationEntryComponent } from "navigation/list/entry/two-line-split-rating-navigation-entry.component";
import { NavigationComponent } from "navigation/list/navigation.component";
import { SupportSectionComponent } from "navigation/support-section/support-section.component";
import { VersionCheckUpdateHintComponent } from "navigation/version-check-update-hint/version-check-update-hint.component";
import { WorkspaceNavigationComponent } from "navigation/workspace/workspace-navigation.component";

@NgModule({
	imports: [StagesCommonModule, RouterModule, ObserversModule],
	declarations: [
		NavigationDrawerComponent,
		SupportSectionComponent,
		WorkspaceNavigationComponent,
		DisabledFilterPipe,
		NavigationComponent,
		ClipboardComponent,
		SingleLineNavigationEntryComponent,
		TwoLineNavigationEntryComponent,
		TwoLineSplitRatingNavigationEntryComponent,
		ChangeMarkerNavigationEntryComponent,
		VersionCheckUpdateHintComponent,
	],
	exports: [NavigationDrawerComponent, NavigationComponent, ClipboardComponent],
})
export class StagesNavigationModule {}
