<article class="errorReport" role="dialog" *ngIf="paramMap$ | async as paramMap">
	<header class="page-header">
		<ul class="breadcrumb">
			<li>
				<a
					class="back"
					[routerLink]="[
						'..',
						'error',
						{
							errorType: paramMap.get('errorType'),
							incidentId: paramMap.get('incidentId'),
							isReportable: paramMap.get('isReportable'),
							isTemporary: paramMap.get('isTemporary')
						}
					]"
				>
					<i class="ico ico-arrow-back"></i>
					<span class="label" translate="back"></span>
				</a>
			</li>
		</ul>
		<h1 translate="error.report.title"></h1>
	</header>

	<article class="card">
		<div class="progress" *ngIf="inProgress">
			<div class="indeterminate"></div>
		</div>
		<form class="form" name="form" #form="ngForm" (ngSubmit)="form.valid && send()" stagesFocusError>
			<section>
				<div class="form-group">
					<div class="input-container sm">
						<label for="subject" translate="error.report.mail.subject"></label>
						<input
							id="subject"
							name="subject"
							#subjectControl="ngModel"
							type="text"
							class="text fullsize"
							[(ngModel)]="subject"
							required
							stagesAutofocus
						/>
						<div class="info">
							<div class="errors" *ngIf="subjectControl.errors && (subjectControl.dirty || subjectControl.touched)">
								<div *ngIf="subjectControl.errors.required" class="error" translate="input.required"></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div class="form-group">
					<div class="input-container sm">
						<label for="messageBody" translate="error.report.mail.messageBody"></label>
						<textarea
							id="messageBody"
							name="messageBody"
							class="textarea fullsize"
							[(ngModel)]="messageBody"
							[placeholder]="'error.report.placeholder' | translate"
							stagesAutoGrow
						></textarea>
					</div>
				</div>
			</section>

			<section>
				<div class="form-group">
					<div class="input-container sm">
						<p translate="error.report.content.notice"></p>
					</div>
				</div>
			</section>

			<div class="input-container right">
				<button type="submit" class="button sm save" translate="send"></button>
				<button type="button" class="button sm cancel" (click)="cancel()" translate="cancel"></button>
			</div>
		</form>
	</article>
</article>
