import { Injectable } from "@angular/core";
import { NavigationService } from "navigation/list/navigation.service";

@Injectable({ providedIn: "root" })
export class HomeNavigationService {
	constructor(private navigationService: NavigationService) {}

	update(): void {
		this.navigationService.updateSimpleNavigation({
			list: [
				{
					id: "home",
					labelTranslate: "navigation.drawer.home",
					route: ["home"],
					icon: "ico-home",
					hasChildren: false,
				},
				{
					id: "process",
					labelTranslate: "navigation.drawer.views",
					route: ["process"],
					icon: "ico-process",
					disabled: (args) => {
						return !(args[0] as stages.workspace.application.WorkspaceView).currentWorkspace.viewedProcess;
					},
					hasChildren: true,
				},
				{
					id: "noprocess",
					labelTranslate: "navigation.drawer.views",
					route: ["process"],
					icon: "ico-process",
					disabled: (args) => {
						return !isNewEnabled((args[0] as stages.workspace.application.WorkspaceView).currentWorkspace);
					},
					hasChildren: false,
				},
				{
					id: "management",
					labelTranslate: "navigation.drawer.management",
					route: ["management"],
					icon: "ico-management",
					disabled: (args) => {
						return !isManagementEnabled((args[0] as stages.workspace.application.WorkspaceView).currentWorkspace);
					},
					hasChildren: true,
				},
				{
					id: "reporting",
					labelTranslate: "navigation.drawer.reports",
					route: ["reporting"],
					icon: "ico-metric-report",
					disabled: (args) => {
						return !isReportingEnabled((args[0] as stages.workspace.application.WorkspaceView).currentWorkspace);
					},
					hasChildren: true,
				},
				{
					id: "administration",
					labelTranslate: "navigation.drawer.administration",
					route: ["administration"],
					icon: "ico-administration",
					disabled: (args) => {
						return !isAdministrationEnabled((args[0] as stages.workspace.application.WorkspaceView).currentWorkspace);
					},
					hasChildren: true,
				},
			],
		});
	}
}

function isManagementEnabled(currentWorkspace: stages.workspace.application.CurrentWorkspace): boolean {
	return !!currentWorkspace.viewedProcess && currentWorkspace.viewedProcess.actions.ViewManagementDashboard;
}

function isAdministrationEnabled(currentWorkspace: stages.workspace.application.CurrentWorkspace): boolean {
	return hasAnyAdministrationAction(currentWorkspace);
}

function isNewEnabled(currentWorkspace: stages.workspace.application.CurrentWorkspace): boolean {
	return (
		!currentWorkspace.viewedProcess && (currentWorkspace.actions.CreateProcess || currentWorkspace.actions.AddModule)
	);
}

function isReportingEnabled(currentWorkspace: stages.workspace.application.CurrentWorkspace): boolean {
	return currentWorkspace.actions.ACCESS_REPORTING;
}

function hasAnyAdministrationAction(currentWorkspace: stages.workspace.application.CurrentWorkspace): boolean {
	return (
		currentWorkspace.actions.USER_ADMINISTRATION ||
		currentWorkspace.actions.USERGROUP_ADMINISTRATION ||
		currentWorkspace.actions.WORKSPACE_ADMINISTRATION ||
		currentWorkspace.actions.ACCESS_ADMINJOBS ||
		currentWorkspace.actions.ENTRYPAGE_ADMINISTRATION ||
		currentWorkspace.actions.maintenance ||
		currentWorkspace.actions.ProcessReleaseAdministration
	);
}
