<ul class="floating-action-menu" [ngClass]="{ active: open }" (stagesClickOutside)="closeHandler()">
	<li>
		<button
			[attr.title]="'navigation.workspace.addelement' | translate"
			[attr.aria-label]="'navigation.workspace.addelement' | translate"
			class="action main"
			(click)="toggleMenu()"
		>
			<i [class]="iconClass"></i>
		</button>
		<ul class="related-actions" [ngClass]="{ active: open }">
			<ng-content></ng-content>
		</ul>
	</li>
</ul>
