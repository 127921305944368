import { animate, state, style, transition, trigger } from "@angular/animations";
import { Location } from "@angular/common";
import {
	AfterViewChecked,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { AnchorScrollService } from "common/editor/anchor-scroll.service";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { DescriptionService, EditableDescription, PluginRegistry } from "common/editor/description.service";
import { EditorToDisplayDescriptionMap } from "common/editor/editor-to-display-description-map";
import { NonProcessEditorProperties } from "common/editor/plugin.service";
import { PreferencesService } from "core/preferences.service";
import { Subscription, SubscriptionLike } from "rxjs";

type DisplayDescription = stages.core.format.DisplayDescription;

@Component({
	selector: "stages-description",
	templateUrl: "./description.component.html",
	styleUrls: ["description.component.scss"],
	animations: [
		trigger("expandCollapseFromTop", [
			state("collapsed", style({ "margin-top": "{{contentHeight}}px" }), { params: { contentHeight: 0 } }),
			state("expanded", style({ "margin-top": "0px" })),
			transition("collapsed <=> expanded", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
})
export class DescriptionComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
	descriptionId = "desc1";
	editorId = "edt1";
	renderedDescription!: string;
	toggleFunction: MenuItem;
	pluginRegistry!: PluginRegistry;
	editorActive = false;
	anchorSubscription!: SubscriptionLike;
	fragmentSubscription!: Subscription;
	fragment?: string | null;
	private _modifiable?: boolean;

	@Input()
	beanGuid!: string;

	@Input()
	beanType!: string;

	@Input()
	beanIdentity!: string;

	@Input()
	beanId!: string;

	@Input()
	set modifiable(value: boolean | undefined) {
		this._modifiable = value;
	}

	get modifiable(): boolean | undefined {
		return this._modifiable;
	}

	@Input()
	processTypeIdent?: string;

	@Input()
	unsafe?: boolean;

	@Input()
	displayDescription!: DisplayDescription;

	@Input()
	descriptionService!: DescriptionService;

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	@Input()
	properties?: NonProcessEditorProperties;

	@Output() readonly afterSave = new EventEmitter<DescriptionComponent>();

	@ViewChild(DescriptionEditorComponent)
	descriptionEditor!: DescriptionEditorComponent;

	@ViewChild("descriptionSlideContainer")
	descriptionSlideContainer!: ElementRef;

	private descriptionIdentifier!: string;
	private descriptionPreference: string = "descriptionExpanded";
	private oldState: "collapsed" | "expanded" | null = null;

	expanded: boolean = true;
	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	animationParams: {} = {};
	firstLoad: boolean = true;
	descriptionHeight!: number;
	saveEnabled = false;
	isLoading = false;

	constructor(
		private route: ActivatedRoute,
		private location: Location,
		private router: Router,
		private mutexService: MutexService,
		private preferencesService: PreferencesService,
		private anchorScrollService: AnchorScrollService,
		private editorToDisplayDescriptionMap: EditorToDisplayDescriptionMap,
		@Optional()
		private card: CardComponent,
	) {}

	ngOnInit(): void {
		this.route.paramMap.subscribe((paramMap) => {
			this.firstLoad = true;
			this.descriptionIdentifier = "description" + paramMap.get("identity")!;

			this.preferencesService.getPreference(this.descriptionPreference, {}).then((preferences: StringToUnknown) => {
				this.toggleFunction = {
					disabled: () => {
						return this.editorActive;
					},
					on: () => {
						this.toggleDescription();
					},
				};

				this.pluginRegistry = this.descriptionService.getPluginRegistry();

				if (this.card) {
					if (this.card.contentCollapsible === undefined) {
						this.card.contentCollapsible = !this.editorActive;
						this.card.showCollapseOption = !this.editorActive;
						this.card.resetHeight();
					}

					let preference = true;

					if (this.card.contentCollapsible && preferences[this.descriptionIdentifier] !== undefined) {
						preference = preferences[this.descriptionIdentifier] as boolean;
					}

					this.expanded = preference;
					this.card.expanded = preference;
					this.card.externalToggleFunctions?.push(this.toggleFunction);
					this.card.menuItems = this.createMenuItems();

					this.renderedDescription = this.displayDescription.html;

					if ((!this.card.contentCollapsible || this.fragment) && !this.expanded) {
						this.expanded = true;
						this.card.expanded = true;
						this.storeState(this.expanded);
					}
				}
			});
		});

		this.fragmentSubscription = this.route.fragment.subscribe((f) => {
			this.fragment = f;
		});

		this.anchorSubscription = this.location.subscribe((locationChange) => {
			const url = locationChange.url!;
			const newFragment = this.anchorScrollService.getHashFromPath(url);
			if (!newFragment) {
				this.fragment = this.anchorScrollService.SCROLL_TO_TOP;
				return;
			}
			const oldPath = this.router.serializeUrl(this.router.createUrlTree(["."], { relativeTo: this.route }));
			const newPath = this.anchorScrollService.getPathWithoutHash(decodeURI(url));
			if (newPath === oldPath) {
				this.fragment = decodeURIComponent(newFragment);
			}
		});

		this.anchorScrollService.register();

		const storedDescription = this.editorToDisplayDescriptionMap.getAndDelete(
			this.editorId,
			this.beanType,
			this.beanId,
		);
		if (storedDescription) {
			this.edit(storedDescription);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.beanGuid || changes.beanType || changes.beanId) {
			this.cancel();
		}
		if (changes.modifiable) {
			this.card.menuItems = this.createMenuItems();
		}
	}

	ngAfterViewChecked(): void {
		if (this.fragment) {
			this.anchorScrollService.scrollToFragment(this.fragment, () => {
				this.fragment = undefined;
			});
		}

		this.setExpandCollapseFromTop();
	}

	ngOnDestroy(): void {
		if (this.editorActive) {
			this.editorToDisplayDescriptionMap.set(
				this.editorId,
				this.beanType,
				this.beanId,
				this.descriptionEditor.getDescription(),
			);
		}

		if (this.fragmentSubscription) {
			this.fragmentSubscription.unsubscribe();
		}

		this.fragment = undefined;

		if (this.anchorSubscription) {
			this.anchorSubscription.unsubscribe();
		}
		this.anchorScrollService.unregister();
		this.isLoading = false;
	}

	isEmpty(): boolean {
		return !this.displayDescription || this.displayDescription.html === "";
	}

	edit(description?: DisplayDescription): void {
		this.setEditorActive(true);
		this.fragment = undefined;
		this.anchorScrollService.deactivate();
		this.saveEnabled = false; // ST-32547: Disable save until description is loaded
		if (description) {
			// wait until the DescriptionEditorComponent has been created after the next check cycle
			setTimeout(() => {
				this.descriptionEditor.edit(description);
				this.saveEnabled = true;
			}, 0);
		} else {
			this.isLoading = true;
			this.descriptionService
				.getDescription(this.beanType, this.beanIdentity, this.workspaceId, this.pv)
				.then((editableDescription: EditableDescription) => {
					// ST-32547: If editing is canceled, before getDescription()-Promise is fulfilled, descriptionEditor is not available anymore.
					if (this.descriptionEditor) {
						this.descriptionEditor.edit(editableDescription.description);
						this.saveEnabled = true;
						this.isLoading = false;
					}
				});
		}
	}

	cancel(): void {
		if (!this.editorActive) {
			return;
		}
		this.setEditorActive(false);
		this.descriptionEditor.cancel();
		this.anchorScrollService.reactivate();
	}

	async save(): Promise<void> {
		if (!this.editorActive) {
			return;
		}

		this.saveEnabled = false;
		this.isLoading = true;
		const updatedDescription = this.descriptionEditor.getDescription();
		await this.mutexService.invokeWithResult("saveDescription", async () => {
			const displayDescription = await this.descriptionService.putDescription(
				this.beanType,
				this.beanIdentity,
				this.workspaceId,
				this.pv,
				updatedDescription,
			);
			// If editing is canceled, before putDescription()-Promise is awaited, descriptionEditor is not available anymore.
			// Similar to ST-32547: Inline description edit causes internal error
			if (this.descriptionEditor) {
				this.descriptionEditor.quit();
			}
			this.setEditorActive(false);
			this.displayDescription = displayDescription;
			this.anchorScrollService.reactivate();
			this.isLoading = false;
			this.afterSave.emit(this);
		});
	}

	setEditorActive(isEditorActive: boolean): void {
		this.editorActive = isEditorActive;
		this.card.showCollapseOption = !isEditorActive;
		this.card.menuItems = this.createMenuItems();
	}

	setExpandCollapseFromTop(): void {
		const targetState = !this.expanded ? "collapsed" : "expanded";
		if (!this.oldState || targetState !== this.oldState) {
			this.oldState = targetState;
			const scrollHeight = (this.descriptionSlideContainer.nativeElement as HTMLDivElement).scrollHeight;
			this.animationParams = {
				value: targetState,
				params: {
					contentHeight: -30 - scrollHeight,
				},
			};
		}
	}

	storeState(expanded: boolean): void {
		this.preferencesService.getPreference(this.descriptionPreference, {}).then((oldPreferences: StringToBoolean) => {
			const newPreferences = { ...oldPreferences };

			newPreferences[this.descriptionIdentifier] = expanded;
			this.preferencesService.setPreference(this.descriptionPreference, newPreferences);
		});
	}

	toggleDescription(): void {
		this.expanded = !this.expanded;
		this.firstLoad = false;
		this.setExpandCollapseFromTop();
		this.storeState(this.expanded);
	}

	createMenuItems(): MenuItem[] {
		return [
			{
				name: "process.description.action.edit",
				iconClass: "ico ico-edit",
				disabled: this.editorActive || !this.modifiable,
				on: () => {
					this.edit();
				},
			},
		];
	}
}
