import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { TailoringWizardStore } from "process/tailoring/store";
import { TailoringWizardService } from "process/tailoring/tailoring-wizard.service";
import { TailoringWizardLogicService } from "process/tailoring/wizard-logic.service";

@Injectable({ providedIn: "root" })
export class TailoringWizardPreviewResolver implements Resolve<stages.process.tailoring.wizard.TailoringWizardPreview> {
	constructor(
		private tailoringWizardService: TailoringWizardService,
		private store: TailoringWizardStore,
		private wizardLogic: TailoringWizardLogicService,
	) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.process.tailoring.wizard.TailoringWizardPreview> {
		let answeredQueries = this.store.value.answeredQueries;
		if (!answeredQueries) {
			this.wizardLogic.loadDataFromSession();
			answeredQueries = this.store.value.answeredQueries;
		}

		return this.tailoringWizardService.preview(route.params.workspaceId, route.params.processVersion, answeredQueries);
	}
}
