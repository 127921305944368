import { ValidatorFn, Validators } from "@angular/forms";
import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class NumberInput extends InputBase<number> {
	constructor(args: CommonArgs<number>) {
		super({
			key: args.key,
			value: args.value,
			type: "number",
			inputFieldType: "number",
			required: args.required,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});

		super.addValidator(Validators.min(-2147483648));
		super.addValidator(Validators.max(2147483647));
		super.addValidator(this.validateNumberPattern());
	}

	validateNumberPattern(): ValidatorFn {
		return (control) => {
			if (!control.value) {
				return null;
			}

			const regExp = new RegExp("^-?\\d+$");
			return regExp.test(control.value)
				? null
				: {
						integer: {
							valid: false,
						},
				  };
		};
	}
}
