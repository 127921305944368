import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Selection } from "common/selection.service";
import { assertDefined } from "core/functions";

export interface TableColumn {
	name: string;
	columnClass?: string;
	sortable?: boolean;
	initialSortOrder: SortOrder;
	optional?: boolean;
}

export interface TableCell {
	text: string | null;
}

export interface TableRow {
	iconClass?: string;
	disabled: boolean;
	clickable: boolean;
	checkItem: boolean;
	id: string;
}

export interface SortEvent {
	sortKey: string;
	sortOrder: SortOrder;
}

export enum SortOrder {
	Undefined,
	Ascending,
	Descending,
}

@Component({
	selector: "stages-table-viewer",
	templateUrl: "table-viewer.component.html",
})
export class TableViewerComponent<T> implements OnInit, OnChanges {
	sortOrder: Record<string, SortOrder> = {};

	@Input()
	columns!: TableColumn[];

	@Input()
	items!: T[];

	@Input() rowProvider!: (item: T) => TableRow;

	@Input() cellProvider!: (item: T, index: number) => TableCell;

	@Input()
	columnNamePrefix!: string;

	@Input()
	mediaQuery!: string;

	@Input()
	translateNone!: string;

	@Input()
	selection!: Selection<T>;

	@Output() readonly selectionChanged = new EventEmitter<void>();

	@Output() readonly sortOrderChanged = new EventEmitter<SortEvent>();

	@Output() readonly itemClicked = new EventEmitter<T>();

	allSelected = false;

	constructor(private translateService: TranslateService) {}

	ngOnInit(): void {
		assertDefined(this.columns);
		assertDefined(this.items);
		assertDefined(this.rowProvider);
		assertDefined(this.cellProvider);
		assertDefined(this.columnNamePrefix);
		assertDefined(this.mediaQuery);
		assertDefined(this.translateNone);
		assertDefined(this.selection);

		this.columns.forEach((column) => (this.sortOrder[column.name] = column.initialSortOrder));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selection) {
			this.onSelect();
		}
	}

	sort(key: string): void {
		this.columns.forEach((column) => {
			if (column.name === key) {
				this.sortOrder[column.name] =
					this.sortOrder[column.name] === SortOrder.Descending ? SortOrder.Ascending : SortOrder.Descending;
				this.sortOrderChanged.emit({ sortKey: column.name, sortOrder: this.sortOrder[column.name] });
			} else {
				this.sortOrder[column.name] = SortOrder.Undefined;
			}
		});
	}

	getRowIconClasses(item: T): string[] {
		const iconClasses: string[] = [];
		const row: TableRow = this.rowProvider(item);
		if (row.iconClass) {
			iconClasses.push(row.iconClass);
		}
		if (row.clickable) {
			iconClasses.push("clickable");
		}
		return iconClasses;
	}

	getColumnClass(column: TableColumn, isLargeSizeView: boolean): string | undefined {
		return isLargeSizeView ? column.columnClass : "";
	}

	onSelectAllToggled(): void {
		if (this.selection.areAllSelected()) {
			this.allSelected = false;
			this.selection.selectNone();
		} else {
			this.allSelected = true;
			this.selection.selectAll();
		}
		this.selectionChanged.emit();
	}

	getSelectCheckboxTitle(): string {
		return this.allSelected
			? this.translateService.instant("select.none")
			: this.translateService.instant("select.all");
	}

	isClickHandled(item: T): boolean {
		const row: TableRow = this.rowProvider(item);
		return !row.checkItem && row.clickable && !row.disabled;
	}

	isSortedBy(column: TableColumn, expected: SortOrder): boolean {
		return expected === this.sortOrder[column.name];
	}

	isDisplayingMessageWhenEmpty(): boolean {
		return this.items && this.items.length === 0;
	}

	isSelectAllDisplayed(): boolean {
		return (
			this.items &&
			this.items.some((item) => {
				const row: TableRow = this.rowProvider(item);
				return row.checkItem && !row.disabled;
			})
		);
	}

	onSelect(): void {
		this.allSelected = this.selection.areAllSelected();
		this.selectionChanged.emit();
	}
}
