import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
	selector: "[stagesFocusError]",
})
export class FocusErrorDirective {
	constructor(private elem: ElementRef) {}

	@HostListener("submit") onSubmit(): void {
		const currentElem: HTMLTextAreaElement = this.elem.nativeElement;
		const firstInvalid = currentElem.querySelector("input.ng-invalid, textarea.ng-invalid, select.ng-invalid");
		if (firstInvalid) {
			(firstInvalid as HTMLElement).focus();
		}
	}
}
