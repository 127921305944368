import { Injectable } from "@angular/core";
import { AsyncValidatorFn } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MainService } from "core/main.service";

@Injectable({ providedIn: "root" })
export class UrlService {
	constructor(private mainService: MainService, private route: ActivatedRoute) {}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	build(pattern: string, pathParams: Record<string, Maybe<string>>, queryParams?: Record<string, any>): string {
		let url = pattern;
		const rewrittenQueryParams = queryParams ? queryParams : {};

		for (const key in pathParams) {
			const regEx = new RegExp("\\{" + key + "\\}", "g");
			url = url.replace(regEx, encodeURIComponent(String(pathParams[key])));
		}
		if (Object.keys(rewrittenQueryParams).length > 0) {
			let separatorChar: string = pattern.indexOf("?") !== -1 ? "&" : "?";
			Object.keys(rewrittenQueryParams).forEach((key) => {
				if (typeof rewrittenQueryParams[key] === "undefined" || rewrittenQueryParams[key] === null) {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					delete rewrittenQueryParams[key];
				} else if (typeof rewrittenQueryParams[key] === "boolean") {
					rewrittenQueryParams[key] = rewrittenQueryParams[key] ? "true" : "false";
				} else if (typeof rewrittenQueryParams[key] === "number") {
					rewrittenQueryParams[key] = `${rewrittenQueryParams[key]}`;
				}
			});
			for (const key in rewrittenQueryParams) {
				if (rewrittenQueryParams[key] instanceof Array) {
					const array = rewrittenQueryParams[key];
					// eslint-disable-next-line @typescript-eslint/no-loop-func -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					array.forEach((element: string) => {
						url += separatorChar + key;
						url += "=" + encodeURIComponent(element);
						separatorChar = "&";
					});
				} else {
					url += separatorChar + key;
					if (rewrittenQueryParams[key] && rewrittenQueryParams[key].length > 0) {
						url += "=" + encodeURIComponent(rewrittenQueryParams[key]);
					}
				}
				separatorChar = "&";
			}
		}
		return url;
	}

	validateUrlPattern(appendMissingProtocolPrefix: boolean): AsyncValidatorFn {
		return async (control) => {
			if (!control.value) {
				return null;
			}

			const applicationContext = await this.mainService.getApplication(this.route.snapshot.params.processVersion);
			const urlValidationPattern = applicationContext.validatorPatterns.urlPattern;
			if (!urlValidationPattern || urlValidationPattern.length === 0) {
				return null;
			}

			const url = appendMissingProtocolPrefix ? this.assureProtocolPrefix(control.value) : control.value;
			const regExp = new RegExp(urlValidationPattern, "i");
			return regExp.test(url)
				? null
				: {
						urlPattern: {
							valid: false,
						},
				  };
		};
	}

	assureProtocolPrefix(url: string): string {
		return /^www./.test(url) ? `http://${url}` : url;
	}
}
