<ng-container *ngIf="this.dataSource.data | async as data">
	<ng-container *ngIf="data.totalItemCount > 0 && data.items.length > 0">
		<stages-card class="to-do-list">
			<h5 translate="dashboard.todolist"></h5>

			<article>
				<stages-grouped-data-view
					#dataView
					listStyles="grouped-list dense"
					[dataSource]="$any(dataSource)"
					[groupTemplate]="groupTemplate"
					[listItemTemplate]="listItemTemplate"
					[idFn]="ToDoListComponent.getId"
					[disabledFn]="ToDoListComponent.isDisabled"
					[classesFn]="ToDoListComponent.getClasses"
					[groupDisabledFn]="$any(ToDoListComponent.isGroupDisabled)"
					[groupIdFn]="$any(ToDoListComponent.getGroupId)"
					translateNone="empty"
				>
				</stages-grouped-data-view>
			</article>

			<ng-template #groupTemplate let-group>
				<div class="label">
					<h3 [translate]="group.name"></h3>
				</div>
			</ng-template>

			<ng-template #listItemTemplate let-task>
				<a class="label clickable" (click)="openTask(task)">
					<div class="text center">
						<h4>{{ task.name }}</h4>
						<div class="info">
							<h6>{{ task.workspaceName }}</h6>
							<h6 [ngClass]="{ overdue: task.overdue }">
								{{ task.duration }}
							</h6>
						</div>
					</div>
				</a>
				<stages-menu
					menuId="mtodo{{ task.id }}"
					iconClasses="ico ico-more-vertical"
					openTo="left"
					[items]="menuItems"
					[context]="task"
					[noCollapse]="false"
					class="action"
				>
				</stages-menu>
			</ng-template>
		</stages-card>
	</ng-container>
</ng-container>
