<div class="embeddable-oauth-confirm">
	<h1 translate="login.to" [translateParams]="{ name: cmsTypeMessageKey | translate }"></h1>

	<div class="panel">
		<p translate="files.cms.oauth" [translateParams]="{ name: cmsTypeMessageKey | translate }"></p>
	</div>

	<div class="panel content">
		<button class="button authentication" (click)="submit()">
			<img [attr.alt]="'files.cms.oauth.signin' | translate" src="img/assets/sign-in.svg" />
			<span translate="login"></span>
		</button>
	</div>

	<footer>
		<div class="buttons">
			<button class="button sm cancel" (click)="close()" translate="cancel" tabindex="3"></button>
		</div>
	</footer>
</div>
