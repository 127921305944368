<article class="content">
	<stages-round-progress [progress]="progress" [remainingTimeMillis]="remainingTimeMillis" [finalState]="finalState">
	</stages-round-progress>

	<p *ngIf="!finalState" class="centered-text">
		<span *ngIf="isWaiting" translate="progress.waiting"></span>
		<span *ngIf="!isWaiting" translate="{{ messageKeyPrefix }}.progress"></span>
	</p>
	<p *ngIf="finalState === 'success'" class="centered-text" translate="{{ messageKeyPrefix }}.{{ finalState }}"></p>

	<div class="operation-result-outer">
		<div class="operation-result warning-color" *ngIf="finalState === 'warnings'">
			<i class="ico ico-warning warning-color"></i>
			<p>
				<span translate="{{ messageKeyPrefix }}.{{ finalState }}"></span>&nbsp;
				<a
					class="warning-color clickable"
					[translate]="warningsMessageKey"
					[translateParams]="{ length: warnings.length }"
					(click)="next.emit()"
				></a>
			</p>
		</div>

		<div class="operation-result error-color" *ngIf="finalState === 'failure'">
			<i class="ico ico-warning error-color"></i>
			<div>
				<p>
					<strong class="error-color" translate="{{ messageKeyPrefix }}.{{ finalState }}"></strong>
				</p>
				<p>{{ error }}</p>
				<p>{{ "progress.error.incidentid" | translate }}:{{ incidentId }}</p>
			</div>
		</div>
	</div>
</article>
<footer>
	<div class="buttons-bottom">
		<div class="spacer"></div>
		<button type="button" class="button lg cancel" (click)="close.emit()" *ngIf="hasWarnings() && !download">
			<span translate="progress.later"></span>
		</button>
		<a
			[href]="download"
			role="button"
			class="button lg cancel"
			(click)="close.emit()"
			*ngIf="hasWarnings() && download"
		>
			<span translate="download"></span>
		</a>
		<button type="button" class="button lg next" (click)="next.emit()" *ngIf="hasWarnings()">
			<span translate="progress.summary"></span>
		</button>
		<button
			type="button"
			class="lg next"
			(click)="close.emit()"
			*ngIf="!hasWarnings() && resumable"
			[disabled]="nextDisabled"
		>
			<span translate="continue"></span>
		</button>
		<a
			role="button"
			class="button lg next"
			[href]="download"
			(click)="close.emit()"
			*ngIf="!hasWarnings() && download"
			[class.disabled]="nextDisabled ? true : null"
		>
			<span translate="download"></span>
		</a>
	</div>
</footer>
