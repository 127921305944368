import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-navigation-entry-single-line",
	templateUrl: "./single-line-navigation-entry.component.html",
	styleUrls: ["./single-line-navigation-entry.component.scss"],
})
export class SingleLineNavigationEntryComponent {
	@Input()
	entry!: SingleLineEntry;

	@Input()
	mode?: NAV_ENTRY_DISPLAY_MODE;
}
