import { JobInfoMap } from "process/freeze/freeze.service";
import { PhaseAdapter } from "process/freeze/overview/phase-adapter.model";
import { ProcessAdapter } from "process/freeze/overview/process-adapter.model";

type ViewableProcess = stages.process.ViewableProcess;
type ViewableElement = stages.process.ViewableElement;
type ViewableAssociation = stages.process.ViewableAssociation;

export class PhaseMatrix {
	program: ProcessAdapter;
	projects: ProcessAdapter[] = [];

	constructor(process: ViewableProcess, private dependentPhasesPath: string, phaseSubtypeIdent?: string) {
		this.program = new ProcessAdapter(process);

		const phases = process
			.indexElements!.find((e) => e.type.ident === "phase")!
			.children!.filter((c) => !phaseSubtypeIdent || c.type.subtypeIdent === phaseSubtypeIdent) as ViewableElement[];

		for (let i = 0; i < phases.length; i++) {
			const phase = phases[i];
			const phaseAdapter = new PhaseAdapter(phase);
			this.add(phaseAdapter, i);

			const correlateAssocs = phase.associations[this.dependentPhasesPath];
			if (!correlateAssocs) {
				continue;
			}
			correlateAssocs.list.filter(isValidVersionAssociation).forEach((assoc) => {
				const dependentPhase = assoc.targetElement!;
				this.add(new PhaseAdapter(dependentPhase), i);
				phaseAdapter.addDependentPhase(dependentPhase, this.program);
			});
		}
	}

	add(phaseAdapter: PhaseAdapter, i: number): void {
		const process = phaseAdapter.phase.process;
		if (!process) {
			return;
		}

		if (this.program.process.id === process.id) {
			this.program.addPhase(phaseAdapter, i + 1);
		} else {
			let projectAdapter = this.projects.find((p) => p.process.id === process.id);
			if (!projectAdapter) {
				projectAdapter = new ProcessAdapter(process);
				this.projects.push(projectAdapter);
			}

			projectAdapter.addPhase(phaseAdapter, this.program.duration);
		}
	}

	getIconClasses(): string[] {
		if (this.projects.length === 0) {
			return ["ico", "ico-refresh"];
		}
		return ["ico", "ico-refresh-all"];
	}

	getAllPhaseIds(): string[] {
		let result: string[] = [];
		result = result.concat(this.program.phases.map((p) => p.phase.id));
		this.projects.forEach((processAdapter) => {
			result = result.concat(processAdapter.phases.map((p) => p.phase.id));
		});
		return result;
	}

	setJobExecutionInfo(jobExecutionInfoMap: JobInfoMap): void {
		this.program.setJobExecutionInfo(jobExecutionInfoMap);
		this.projects.forEach((p) => p.setJobExecutionInfo(jobExecutionInfoMap));
	}

	setUpdateJobExecutionInfo(jobExecutionInfoMap: JobInfoMap): void {
		this.program.setUpdateJobExecutionInfo(jobExecutionInfoMap);
		this.projects.forEach((p) => p.setUpdateJobExecutionInfo(jobExecutionInfoMap));
	}

	getUpdateConsiderFrozenAllRoute(): unknown[] {
		if (this.projects.length === 0) {
			return ["process", "updateConsiderFrozen", this.program.process.workspaceId];
		}
		return [
			"process",
			"updateConsiderFrozen",
			this.program.process.workspaceId,
			{ workspaceIds: this.projects.filter((pA) => !pA.isDisabled()).map((pA) => pA.process.workspaceId) },
		];
	}

	getAllWorkspaceIds(): string[] {
		return [this.program.process.workspaceId].concat(this.projects.map((p) => p.process.workspaceId));
	}
}

function isValidVersionAssociation(assoc: ViewableAssociation): boolean {
	return assoc.isProcessInterface && assoc.targetElement!.process.isValidVersion;
}
