import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import * as ResizeSensor from "css-element-queries/src/ResizeSensor";

type ResizeSensorCallback = (size: { width: number; height: number }) => void;

@Component({
	selector: "stages-description-view",
	templateUrl: "./description-view.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionViewComponent implements AfterViewInit {
	@Input()
	descriptionId?: string;

	@Input()
	descriptionClass?: string;

	@Input()
	html!: string;

	@Input()
	workspaceId!: string;

	resizeHandlers = new Map<string, ResizeSensorCallback>();

	constructor(private elem: ElementRef) {}

	ngAfterViewInit(): void {
		void new ResizeSensor.default(this.elem.nativeElement.parentNode, (size) => {
			for (const handler of this.resizeHandlers.values()) {
				handler(size);
			}
		});
	}

	registerResizeHandler(name: string, handler: ResizeSensorCallback): void {
		this.resizeHandlers.set(name, handler);
	}
}
