import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MainService } from "core/main.service";
import { FileContainer } from "files/files.component";
import { FileService } from "files/files.service";
import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export interface StartOption {
	classes: string[];
	title: string;
	icon: string;
	message: string;
	button: string;
	disabled: boolean;
	on(): void;
}

@Component({
	templateUrl: "./files-start-dialog.component.html",
})
export class FilesStartDialogComponent implements OnInit {
	startOptions$!: Observable<StartOption[]>;

	constructor(private route: ActivatedRoute, private mainService: MainService, private fileService: FileService) {}

	ngOnInit(): void {
		this.startOptions$ = combineLatest([this.route.paramMap, this.route.data]).pipe(
			switchMap(([paramMap]) => {
				return this.fileService
					.getFileContainer(
						paramMap.get("workspaceId")!,
						paramMap.get("elementType")!,
						paramMap.get("elementId")!,
						paramMap.get("workspaceId")!,
						paramMap.get("processVersion")!,
						".",
					)
					.pipe(
						map((fileContainer: FileContainer) => {
							const startOptions: StartOption[] = [
								{
									classes: ["upload-file"],
									icon: "ico ico-add-file",
									title: "files.start.upload.title",
									message: "files.start.upload.message",
									button: "files.start.upload.button",
									disabled: !fileContainer.allowedOperations.AddFiles || !fileContainer.allowedOperations.Upload,
									on: () => {
										this.navigateTo("add", fileContainer);
									},
								},
								{
									classes: ["file-template"],
									icon: "ico ico-add-file",
									title: "files.start.template.title",
									message: "files.start.template.message",
									button: "files.start.template.button",
									disabled:
										!fileContainer.allowedOperations.AddFiles ||
										!fileContainer.allowedOperations.Upload ||
										fileContainer.templateFileDescriptors.length === 0,
									on: () => {
										this.navigateTo("template", fileContainer);
									},
								},
								{
									classes: ["add-url"],
									icon: "ico ico-link",
									title: "files.start.url.title",
									message: "files.start.url.message",
									button: "files.start.url.button",
									disabled: !fileContainer.allowedOperations.AddURLs,
									on: () => {
										this.navigateTo("addUrl", fileContainer);
									},
								},
								{
									classes: ["link-file"],
									icon: "ico ico-link-file",
									title: "files.start.cm.title",
									message: "files.start.cm.message",
									button: "files.start.cm.button",
									disabled: !fileContainer.allowedOperations.AddFiles || !fileContainer.allowedOperations.LinkFiles,
									on: () => {
										this.navigateTo("browse", fileContainer);
									},
								},
							];

							return startOptions;
						}),
					);
			}),
		);
	}

	private navigateTo(to: string, fileContainer: FileContainer): void {
		this.mainService.openPopup(
			[
				"files",
				to,
				{
					singleFile: this.route.snapshot.paramMap.get("singleFile"),
					elementType: fileContainer.elementType,
					elementId: fileContainer.elementId,
				},
			],
			this.route,
		);
	}
}
