import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import {
	MainService,
	SECONDARY_MODE_PARAM,
	SECONDARY_PROCESS_VERSION_PARAM,
	SECONDARY_WORKSPACE_ID_PARAM,
} from "core/main.service";

@Injectable({ providedIn: "root" })
export class WorkspaceViewResolver implements Resolve<stages.workspace.application.WorkspaceView> {
	constructor(private mainService: MainService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.workspace.application.WorkspaceView> {
		const params = route.paramMap;
		const workspaceId = params.get("workspaceId")!;
		const processVersion = params.get("processVersion")!;
		this.mainService.setSecondaryWorkspaceId(params.get(SECONDARY_WORKSPACE_ID_PARAM) || undefined);
		this.mainService.secondaryProcessVersion = params.get(SECONDARY_PROCESS_VERSION_PARAM) || undefined;
		this.mainService.secondaryMode = params.get(SECONDARY_MODE_PARAM) || undefined;
		return this.mainService.getWorkspaceView(workspaceId, processVersion);
	}
}
