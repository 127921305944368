import { Injectable } from "@angular/core";
import { ProgressMonitorService } from "core/stages-client";

type ProgressInfo = stages.common.ProgressInfo;

@Injectable({ providedIn: "root" })
export class ProgressService {
	constructor(private readonly progressMonitorService: ProgressMonitorService) {}

	async getJobProgress(
		operationType: string,
		workspaceId: string,
		pv: string,
		jobName: string,
		isPolling = false,
	): Promise<ProgressInfo> {
		if (isPolling) {
			this.progressMonitorService.setHeadersForNextRequest({
				"Stages-Polling": "true",
			});
		}
		this.progressMonitorService.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.progressMonitorService.getCurrentProgress(jobName, {
			operationType: operationType,
			workspaceId: workspaceId,
		});
	}

	async getLatestJob(operationTypes: string[], workspaceId: string, pv: string): Promise<ProgressInfo> {
		this.progressMonitorService.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.progressMonitorService.getLatestJob({
			operationType: operationTypes,
			workspaceId: workspaceId,
		});
	}
}
