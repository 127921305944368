<div class="dropzone" [ngClass]="{'remove-margin': expandDroparea}" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)">
	<div>
		<i class="ico ico-cloud-upload"></i>
	</div>
	<span class="span2" translate="files.drop.zone.drag.and.drop"></span>
	<p>
		{{ browseMessage | translate }}
		<a (click)="onBrowse($event)" class="clickable needsclick" translate="files.drop.zone.browse.link"></a>
	</p>
	<div class="errors" *ngIf="hasErrors()">
		<div class="error" *ngIf="errors.fileSize && multiple" translate="files.drop.multiple.fileSize"></div>
		<div class="error" *ngIf="errors.fileSize && !multiple" translate="files.drop.single.fileSize"></div>
		<div
			class="error"
			*ngIf="errors.fileExtension"
			translate="files.drop.fileExtension"
			[translateParams]="{ extensions: fileExtensions }"
		></div>
		<div class="error" *ngIf="errors.fileCount && !multiple" translate="files.drop.single.fileCount"></div>
		<div
			class="error"
			*ngIf="errors.noAccess"
			translate="files.drop.no.access"
			[translateParams]="{ filename: inaccessibleFile }"
		></div>
	</div>
	<div> <!-- The div container for the input element is needed because otherwise the click() does not work in Windows 7 Internet Explorer 11.-->
		<input #fileInput type="file" style="display:none" (change)="onChange($event)" [attr.accept]="fileExtensions" [attr.multiple]="multiple">
	</div>
</div>
