import { Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";

type Workspace = stages.workspace.Workspace;

@Component({
	selector: "stages-workspace-list",
	styleUrls: ["./workspace-list.component.scss"],
	templateUrl: "./workspace-list.component.html",
})
export class WorkspaceListComponent {
	private _filterTerm?: string;

	@Input()
	selected?: string;

	@Input()
	workspaces?: Workspace[];

	@Input()
	bookmarkable?: boolean;

	@Input()
	showEmpty: boolean = true;

	@Input()
	searchEngineAvailable?: boolean;

	@Input()
	enableLocalFilter?: boolean = false;

	@Input()
	set filterTerm(filterTerm: string | undefined) {
		this._filterTerm = filterTerm ? filterTerm.toLocaleLowerCase() : undefined;
	}

	get filterTerm(): string | undefined {
		return this._filterTerm;
	}

	@Output() readonly up = new EventEmitter<string>();

	@Output() readonly down = new EventEmitter<string>();

	@Output() readonly select = new EventEmitter<WorkspaceSelected>();

	@Output() readonly bookmark = new EventEmitter<Workspace>();

	trackBy: TrackByFunction<Workspace> = (_index, workspace) => {
		return workspace.id;
	};

	toggleFavorite(workspace: Workspace): void {
		this.bookmark.emit(workspace);
	}

	selectWorkspace(workspace: Workspace): void {
		this.select.emit(workspace as WorkspaceSelected);
	}

	getListIsEmptyProperty(): string | undefined {
		return this.filterTerm ? "navigation.workspace.filter.nothing_found" : this.showEmpty ? "none" : undefined;
	}

	filter(workspaces?: stages.workspace.Workspace[]): stages.workspace.Workspace[] | undefined {
		return workspaces && this.enableLocalFilter
			? workspaces.filter((workspace) => {
					return !this.filterTerm || workspace.name.toLocaleLowerCase().indexOf(this.filterTerm) >= 0;
			  })
			: workspaces;
	}
}
