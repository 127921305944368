import { NgModule } from "@angular/core";
import { StagesCommonModule } from "common/common.module";
import { DashboardComponent } from "dashboard/dashboard.component";
import { EntryPagesComponent } from "dashboard/description/entry-pages.component";
import { GettingStartedComponent } from "dashboard/getting-started.component";
import { LinksComponent } from "dashboard/links.component";
import { MyMostFrequentlyUsedElementsComponent } from "dashboard/myMostRequentlyUsedElements/my-most-frequently-used-elements.component";
import { MyRolesComponent } from "dashboard/myRoles/my-roles.component";
import { RecentlyUsedFilesComponent } from "dashboard/recentlyUsedFiles/recently-used-files.component";
import { SupportComponent } from "dashboard/support.component";
import { TaskDetailsComponent } from "dashboard/to-do-list/task-details.component";
import { ToDoListComponent } from "dashboard/to-do-list/to-do-list.component";
import { StagesFilesModule } from "files/files.module";
import { StagesProcessModule } from "process/process.module";
import { ProcessInterfacesModule } from "management/process-interfaces/process-interfaces.module";

@NgModule({
	imports: [StagesCommonModule, StagesFilesModule, StagesProcessModule, ProcessInterfacesModule],
	declarations: [
		DashboardComponent,
		RecentlyUsedFilesComponent,
		EntryPagesComponent,
		MyRolesComponent,
		GettingStartedComponent,
		LinksComponent,
		SupportComponent,
		MyMostFrequentlyUsedElementsComponent,
		ToDoListComponent,
		TaskDetailsComponent,
	],
})
export class StagesDashboardModule {}
