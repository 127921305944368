import { NgModule } from "@angular/core";
import { StagesCommonModule } from "common/common.module";
import { UserSettingsComponent } from "user/settings/user-settings.component";
import { UserRoutingModule } from "user/user-routing.module";
import { UserComponent } from "user/user.component";

@NgModule({
	imports: [StagesCommonModule, UserRoutingModule],
	declarations: [UserComponent, UserSettingsComponent],
})
export class StagesUserModule {}
