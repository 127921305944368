<article *ngIf="paramMap$ | async as paramMap">
	<header class="page-header">
		<h1 [translate]="getMessageKey(paramMap)"></h1>
	</header>
	<article class="card">
		<div>
			<stages-summary
				[operationName]="paramMap.get('jobType')!"
				[jobName]="paramMap.get('jobName')!"
				[workspaceId]="paramMap.get('workspaceId')!"
				[pv]="paramMap.get('processVersion')!"
				(close)="close()"
			>
			</stages-summary>
		</div>
	</article>
</article>
