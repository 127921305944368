<stages-responsive-data-view
	*ngIf="dataSource"
	#dataView
	[dataSource]="dataSource"
	[listItemTemplate]="listItemTemplate"
	[tableItemTemplate]="tableItemTemplate"
	[headerTemplate]="tableHeaderTemplate"
	[informationTemplate]="informationTemplate"
	[idFn]="ProcessInterfacesInterfacesSelection.getId"
	[idFnNew]="ProcessInterfacesInterfacesSelection.getIdNew"
	[disabledFn]="ProcessInterfacesInterfacesSelection.isDisabled"
	[disabledFnNew]="ProcessInterfacesInterfacesSelection.isDisabled"
	[isPreselectedFn]="ProcessInterfacesInterfacesSelection.isPreselected"
	[useBreakpointObserver]="true"
	[mode]="mode"
	[parentIdentifier]="ProcessInterfacesInterfacesSelection.getParentIdentifier"
	[storeIdentifier]="storeIdentifier"
	[showSelectAllSwitch]="false"
	translateNone="none"
>
</stages-responsive-data-view>

<ng-template #listItemTemplate let-item>
	<li class="list-item no-padding">
		<div class="label">
			<div class="text">
				<h4>
					<a
						*ngIf="item.actions.OpenSourceElement"
						[ngClass]="{ clickable: clickable, tailored: item.sourceElementTailored }"
						[routerLink]="getSourceElementLink(item)"
						>{{ item.sourceElementName
						}}<span *ngIf="item.dependentSourceElement"> ({{ item.dependentSourceElementLabel }})</span>
					</a>
					<span
						*ngIf="!item.actions.OpenSourceElement"
						[ngClass]="{ clickable: clickable, tailored: item.sourceElementTailored }"
						>{{ item.sourceElementName
						}}<span *ngIf="item.dependentSourceElement"> ({{ item.dependentSourceElementLabel }})</span>
					</span>
					<span>&nbsp;<span translate="management.process.interfaces.page.interfaces.to"></span>&nbsp;</span>
					<a
						*ngIf="item.actions.OpenTargetElement"
						[ngClass]="{ clickable: clickable, tailored: item.targetElementTailored }"
						[routerLink]="getTargetElementLink(item)"
						>{{ item.targetElementName
						}}<span *ngIf="item.dependentTargetElement"> ({{ item.dependentTargetElementLabel }})</span>
					</a>
					<span
						*ngIf="!item.actions.OpenTargetElement"
						[ngClass]="{ clickable: clickable, tailored: item.targetElementTailored }"
						>{{ item.targetElementName }}
						<ng-container *ngIf="item.dependentTargetElement"> ({{ item.dependentTargetElementLabel }})</ng-container>
					</span>
				</h4>
				<p>
					<span
						>{{ item.sourceElementType.name }}
						<span translate="management.process.interfaces.page.interfaces.to"></span>
						{{ item.targetElementType.name }}
					</span>
				</p>
				<p class="subheadline">
					<span class="badge" [ngClass]="'info-badge '">{{ item.associationTypeLabel }}</span>
					<span
						*ngIf="showOnlyChanges && getAssociationChangeType(item) !== 'no_change'"
						class="badge"
						[ngClass]="getAssociationChangeType(item)"
						[translate]="'management.process.interfaces.changetype.' + getAssociationChangeType(item)"
					></span>
				</p>
			</div>
		</div>
	</li>
	<li class="separator"></li>
</ng-template>
<ng-template #tableHeaderTemplate>
	<th translate="management.process.interfaces.page.interfaces.th1"></th>
	<th translate="management.process.interfaces.page.interfaces.th2"></th>
	<th translate="management.process.interfaces.page.interfaces.th3"></th>
	<th *ngIf="showOnlyChanges" translate="management.process.interfaces.page.interfaces.th4"></th>
</ng-template>
<ng-template #tableItemTemplate let-item>
	<td>
		<div class="list-item no-padding">
			<a class="label" [routerLink]="getSourceElementLink(item)" *ngIf="item.actions.OpenSourceElement">
				<ng-container *ngTemplateOutlet="sourceElementTemplate; context: { item: item }"></ng-container>
			</a>
			<span class="label" *ngIf="!item.actions.OpenSourceElement">
				<ng-container *ngTemplateOutlet="sourceElementTemplate; context: { item: item }"></ng-container>
			</span>
		</div>
	</td>
	<td>
		<div class="list-item no-padding">
			<a class="label" [routerLink]="getTargetElementLink(item)" *ngIf="item.actions.OpenTargetElement">
				<ng-container *ngTemplateOutlet="targetElementTemplate; context: { item: item }"></ng-container>
			</a>
			<span class="label" *ngIf="!item.actions.OpenTargetElement">
				<ng-container *ngTemplateOutlet="targetElementTemplate; context: { item: item }"></ng-container>
			</span>
		</div>
	</td>
	<td>{{ item.associationTypeLabel }}</td>
	<td *ngIf="showOnlyChanges">
		<span
			*ngIf="getAssociationChangeType(item) !== 'no_change'"
			class="badge"
			[ngClass]="getAssociationChangeType(item)"
			[translate]="'management.process.interfaces.changetype.' + getAssociationChangeType(item)"
		></span>
	</td>
</ng-template>

<ng-template #sourceElementTemplate let-item="item">
	<i class="ico avatar-cell-48" [ngClass]="getIconClasses(item.sourceElementType, false)"></i>
	<div class="text">
		<h4 [ngClass]="{ tailored: item.sourceElementTailored }">
			<span>{{ item.sourceElementName }}</span>
		</h4>
		<p *ngIf="item.dependentSourceElement">
			{{ item.dependentSourceElementLabel }}
			<i class="ico" [ngClass]="getIconClasses(item.sourceElementType, true)"></i>
		</p>
		<p>{{ item.sourceElementType.name }}</p>
	</div>
</ng-template>

<ng-template #targetElementTemplate let-item="item">
	<i class="ico avatar-cell-48" [ngClass]="getIconClasses(item.targetElementType, false)"></i>
	<div class="text">
		<h4 [ngClass]="{ tailored: item.targetElementTailored }">
			<span>{{ item.targetElementName }}</span>
		</h4>
		<p *ngIf="item.dependentTargetElement">
			{{ item.dependentTargetElementLabel }}
			<i class="ico" [ngClass]="getIconClasses(item.targetElementType, true)"></i>
		</p>
		<p>{{ item.targetElementType.name }}</p>
	</div>
</ng-template>

<ng-template #informationTemplate>
	<div class="text">
		<p
			[translate]="'management.process.interfaces.page.interfaces.connectFromTo'"
			[translateParams]="{ workspaceFrom: workspaceNameFrom, workspaceTo: workspaceNameTo }"
		></p>
	</div>
</ng-template>
