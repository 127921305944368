<!-- UI header section -->
<ng-container *ngIf="isDialog; else fullScreenHead">
	<stages-dialog-header>
		<a *ngIf="allowWorkspaceNavigation" class="title clickable" (click)="goToWorkspaces(folder)">
			<i class="ico ico-workspace-interface"></i>
			<h3 class="shorten">{{ rootLabel }}</h3>
		</a>
		<div *ngIf="!allowWorkspaceNavigation" class="title">
			<i class="ico ico-workspace-interface"></i>
			<h3 class="shorten">{{ rootLabel }}</h3>
		</div>
	</stages-dialog-header>
</ng-container>

<ng-template #fullScreenHead>
	<div class="form">
		<div class="form-group">
			<div class="input-container">
				<label for="showVisibleOnly" class="input-label">
					<span
						*ngIf="extendedMode"
						class="label-text"
						translate="common.browser.selectable.selected.elements.only"
					></span>
					<span class="counter">{{ selectedElementsCounter$ | async }} </span>
					<label for="showVisibleOnly" class="count-label">
						<ng-container *ngIf="addedElementsCounter$ | async as addedElementsCounter">
							<span class="counter" [ngClass]="{ added: addedElementsCounter > 0 }">
								<span *ngIf="addedElementsCounter > 0">+</span>
								{{ addedElementsCounter }}
							</span>
						</ng-container>
						<ng-container *ngIf="removedElementsCounter$ | async as removedElementsCounter">
							<span class="counter" [ngClass]="{ removed: removedElementsCounter > 0 }">
								<span *ngIf="removedElementsCounter > 0">-</span>
								{{ removedElementsCounter }}
							</span>
						</ng-container>
					</label>
				</label>
				<input
					*ngIf="extendedMode"
					type="checkbox"
					hidden="hidden"
					id="showVisibleOnly"
					[ngModel]="showVisibleOnly"
					(change)="onToggleVisibleOnly()"
				/>
				<label *ngIf="extendedMode" for="showVisibleOnly" class="switch"></label>
			</div>
		</div>
	</div>
</ng-template>

<!-- UI body section -->
<ng-container *ngIf="isDialog; else fullScreenContent">
	<article class="assoc-browser content">
		<h1 translate="process.element.associationBrowser.target.selection"></h1>
		<nav *ngIf="!isLoading">
			<ul class="list">
				<ng-container
					*ngIf="
						(!folder.children && translateNone) || (folder.children?.length === 0 && translateNone);
						else listContent
					"
				>
					<li class="list-item">
						<div class="avatar-cell-48"></div>
						<div class="label">
							<div class="text" translate="{{ translateNone }}"></div>
						</div>
					</li>
				</ng-container>
			</ul>
		</nav>
	</article>
</ng-container>

<ng-template #fullScreenContent>
	<article class="assoc-browser content">
		<nav class="separator">
			<div *ngIf="folder.children && folder.children.length > 10" class="buttons-top buttons-right buttons">
				<button
					type="button"
					class="button sm cancel"
					name="cancel"
					tabindex="100"
					(click)="onCancel()"
					translate="cancel"
				></button>
				<button
					type="submit"
					class="button sm save"
					name="submit"
					tabindex="101"
					[disabled]="isLoading || !isDirty"
					(click)="onSave()"
				>
					<span *ngIf="!isLoading" translate="save"></span>
					<stages-spin *ngIf="isLoading" size="s"></stages-spin>
				</button>
			</div>
			<ul class="list">
				<ng-container *ngIf="folder.children!.length === 0 && translateNone; else listContent">
					<li class="list-item">
						<div class="avatar-cell-48"></div>
						<div class="label">
							<div class="text" translate="{{ translateNone }}"></div>
						</div>
					</li>
				</ng-container>
			</ul>
		</nav>
	</article>
</ng-template>

<!-- UI footer section -->
<footer>
	<div class="buttons-bottom buttons-right" [ngClass]="{ 'no-margin': isDialog }">
		<ng-container *ngIf="isDialog && extendedMode">
			<ng-container *ngTemplateOutlet="counter"></ng-container>
		</ng-container>
		<ng-container *ngTemplateOutlet="buttonsbottom"></ng-container>
	</div>
</footer>

<!-- Template section -->

<ng-template #listContent>
	<ng-container *ngIf="!isBrowseRoot; else folderIsRoot">
		<ng-container *ngTemplateOutlet="navigateUp"></ng-container>
	</ng-container>

	<!-- Folder / Children -->
	<ng-container *ngFor="let child of folder.children; trackBy: trackById">
		<ng-container
			*ngIf="
				!extendedMode ||
				!showVisibleOnly ||
				({ id: child.id, typeIdent: child.type.ident } | isVisible: selectedElements:allSelectables)
			"
		>
			<li class="list-item" *ngIf="!showDependentElements(child)">
				<ng-container *ngTemplateOutlet="childTemplate; context: { $implicit: child }"></ng-container>
				<ng-container *ngTemplateOutlet="childActionMenuTemplate; context: { $implicit: child }"></ng-container>
			</li>
			<li class="list-item-grouped" *ngIf="showDependentElements(child)">
				<stages-common-collapsible-groups
					[defaultOpen]="true"
					[groups]="[getGroupInfo(child)]"
					[preferencesPrefix]="'assocBrowserDependents'"
					[groupContentTemplate]="dependentElementsSublist"
					[singleGroupCollapsible]="true"
					[groupHeaderTemplate]="groupHeaderTemplate"
					[hoverOnHeader]="true"
				></stages-common-collapsible-groups>
			</li>
		</ng-container>
	</ng-container>
</ng-template>

<ng-template #groupHeaderTemplate let-child>
	<div class="enforce-height"></div>
	<div class="avatar-cell-48">
		<input type="checkbox" class="checkbox" [checked]="false" [disabled]="true" />
	</div>

	<div class="label">
		<div class="text">
			<ng-container *ngIf="child.children && child.children.length > 0">
				<a class="clickable" (click)="goToChild(child, $event)">
					<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: child }"></ng-container>
				</a>
			</ng-container>

			<ng-container *ngIf="!child.children || child.children.length === 0">
				<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: child }"></ng-container>
			</ng-container>
		</div>
	</div>

	<ng-container
		*ngIf="
			extendedMode &&
			({ id: child.id, typeIdent: child.type.ident }
				| countSelectedChilds: selectedElements:allSelectables) as countedChildren
		"
	>
		<label class="count-label" for="{{ getSelectionKey(child) }}_menu">
			<span class="counter"> {{ countedChildren }}</span>
		</label>
	</ng-container>
</ng-template>

<ng-template #childTemplate let-child>
	<div class="enforce-height"></div>
	<div class="avatar-cell-48">
		<input
			#elem
			[id]="getSelectionKey(child)"
			type="checkbox"
			class="checkbox"
			[checked]="{ id: child.id, typeIdent: child.type.ident } | isSelected: selectedElements"
			[disabled]="!isSelectable(child)"
			(change)="onSelectionChanged({ id: child.id, typeIdent: child.type.ident })"
		/>
		<label for="{{ getSelectionKey(child) }}"></label>
	</div>

	<div class="label">
		<div class="text">
			<ng-container *ngIf="child.children && child.children.length > 0 && !showDependentElements(child)">
				<a class="clickable" (click)="goToChild(child, $event)">
					<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: child }"></ng-container>
				</a>
			</ng-container>

			<ng-container *ngIf="(!child.children || child.children.length === 0) && !showDependentElements(child)">
				<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: child }"></ng-container>
			</ng-container>

			<ng-container *ngIf="showDependentElements(child)">
				<h4 [translate]="'process.element.quickAssign.directly'" [translateParams]="{ name: child.label }"></h4>
				<h6>{{ child.type.name }}</h6>
			</ng-container>

			<input
				#commentInput
				class="comment-input"
				*ngIf="commentsAllowed && ({ id: child.id, typeIdent: child.type.ident } | isSelected: selectedElements)"
				id="{{ elem.id }}"
				type="text"
				[ngModel]="getComment(child)"
				(stagesKeyUp)="updateComment({ id: child.id, typeIdent: child.type.ident })"
				placeholder="{{ 'process.element.associationBrowser.comment.placeholder' | translate }}"
				(click)="$event.stopPropagation()"
			/>
		</div>
	</div>

	<ng-container
		*ngIf="
			extendedMode &&
			!showDependentElements(child) &&
			({ id: child.id, typeIdent: child.type.ident }
				| countSelectedChilds: selectedElements:allSelectables) as countedChildren
		"
	>
		<label for="{{ getSelectionKey(child) }}_menu" class="count-label">
			<span class="counter"> {{ countedChildren }}</span>
		</label>
	</ng-container>
</ng-template>

<ng-template #childActionMenuTemplate let-child>
	<stages-menu
		*ngIf="extendedMode"
		id="{{ getSelectionKey(child) }}_menu"
		menuId="{{ getSelectionKey(child) }}_menu"
		iconClasses="ico ico-more-vertical"
		openTo="left"
		[items]="contextMenuItems"
		[context]="child"
		[noCollapse]="true"
		[keepOpenOnMenuClick]="false"
		[enableOverflowOutbreak]="true"
		class="action"
	>
	</stages-menu>
</ng-template>

<ng-template #dependentElementsSublist let-child>
	<ul class="sublist" *ngIf="showDependentElements(child)">
		<li class="list-item">
			<ng-container *ngTemplateOutlet="childTemplate; context: { $implicit: child }"></ng-container>
			<ng-container *ngTemplateOutlet="childActionMenuTemplate; context: { $implicit: child }"></ng-container>
		</li>
		<li
			class="list-item"
			*ngFor="
				let dependent of child.dependentElements
					| getAssignableDependentElements: ignoreDependentsAssignable:restrictAssociateDependentElementTypes;
				trackBy: trackById
			"
		>
			<ng-container *ngTemplateOutlet="childTemplate; context: { $implicit: dependent }"></ng-container>
			<ng-container *ngTemplateOutlet="childActionMenuTemplate; context: { $implicit: dependent }"></ng-container>
		</li>
	</ul>
</ng-template>

<ng-template #navigateUp>
	<li class="list-item">
		<a class="clickable change-level" (click)="goToParent(folder)">
			<div class="avatar-cell-48">
				<i class="ico ico-arrow-up"></i>
			</div>
			<div class="text">
				<ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: folder }"></ng-container>
			</div>
		</a>
	</li>
</ng-template>

<ng-template #folderIsRoot>
	<li class="list-item" *ngIf="rootLabel">
		<div class="avatar-cell-48">
			<ng-container *ngIf="folder.type.ident !== 'process'">
				<!-- Display root must be selectable in some cases (index elements in the process model for reference compliance) -->
				<input
					*ngIf="allowIndexElementsForSelection"
					type="checkbox"
					class="checkbox"
					id="{{ getSelectionKey(folder) }}"
					[checked]="{ id: folder.id, typeIdent: folder.type.ident } | isSelected: selectedElements"
					[disabled]="!isSelectable(folder)"
					(change)="onSelectionChanged({ id: folder.id, typeIdent: folder.type.ident })"
				/>
				<label for="{{ getSelectionKey(folder) }}"></label>
			</ng-container>
		</div>
		<div class="label">{{ folder.type.ident === "process" ? rootLabel : folder.label }}</div>
	</li>
</ng-template>

<ng-template #itemTemplate let-item>
	<h4>{{ item.label }}</h4>
	<h6>{{ item.type.name }}</h6>
</ng-template>

<ng-template #buttonsbottom>
	<button
		type="button"
		class="button sm cancel"
		name="cancel"
		tabindex="100"
		(click)="onCancel()"
		translate="cancel"
	></button>
	<button
		type="submit"
		class="button sm save"
		name="submit"
		tabindex="101"
		[disabled]="isLoading || !isDirty"
		(click)="onSave()"
	>
		<span *ngIf="!isLoading" translate="save"></span>
		<stages-spin *ngIf="isLoading" size="s"></stages-spin>
	</button>
</ng-template>

<ng-template #counter>
	<div class="counter-switch-container">
		<label class="input-label">
			<input
				type="checkbox"
				hidden="hidden"
				id="showVisibleOnly"
				[ngModel]="showVisibleOnly"
				(change)="onToggleVisibleOnly()"
			/>
			<label for="showVisibleOnly" class="switch"></label>
		</label>
		<div class="count-label">
			<span class="label-text" translate="common.browser.selectable.selected.elements.only"></span>
			<span class="counter">{{ selectedElementsCounter$ | async }} </span>
			<ng-container *ngIf="addedElementsCounter$ | async as addedElementsCounter">
				<span class="counter" [ngClass]="{ added: addedElementsCounter > 0 }">
					<span *ngIf="addedElementsCounter > 0">+</span>
					{{ addedElementsCounter }}
				</span>
			</ng-container>
			<ng-container *ngIf="removedElementsCounter$ | async as removedElementsCounter">
				<span class="counter" [ngClass]="{ removed: removedElementsCounter > 0 }">
					<span *ngIf="removedElementsCounter > 0">-</span>
					{{ removedElementsCounter }}
				</span>
			</ng-container>
		</div>
	</div>
</ng-template>
