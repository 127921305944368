import { TrackByFunction } from "@angular/core";
import { ActivatedRoute, IsActiveMatchOptions, NavigationExtras, ParamMap, Router } from "@angular/router";
import { MultiLevelSelection, MultiLevelSelectionService } from "common/multi-level-selection.service";
import { hasProperty } from "core/functions";
import { NavigationConfiguration } from "navigation/list/navigation-configuration.interface";
import { ListNavigationEntry } from "navigation/list/navigation-entry.interface";
import {
	FollowUpAction,
	FollowUpActionMenuItem,
	MenuUIAction,
	NavigationMenuItem,
	NavigationMode,
	NavMode,
	RearrangeUIAction,
	UIAction,
} from "navigation/list/navigation.interface";

export function isFollowUpActionMenuItem(arg: NavigationMenuItem): arg is FollowUpActionMenuItem {
	return hasProperty(arg, "onDoFollowUp");
}

export function isRearrangeUIAction(arg: unknown): arg is RearrangeUIAction {
	return hasProperty(arg, "onRearrange");
}

export function isMenuUIAction(arg: unknown): arg is MenuUIAction {
	return hasProperty(arg, "onMenuClick");
}

export class ActionStateWrapper {
	name: string;
	buttonClass: string;

	on(): void {
		this.nav.processFollowUpAction(this.uiAction, this.activatedRoute, this.baseRoute, this.selection, this.invalidate);
	}

	disabled(): boolean {
		if (this.uiAction.disabled === undefined) {
			return false;
		}
		if (this.selection) {
			return this.uiAction.disabled(this.selection);
		}
		return false;
	}

	constructor(
		private uiAction: UIAction,
		private nav: BaseNavigationComponent,
		private selection: MultiLevelSelection<ListNavigationEntry> | null,
		private activatedRoute: ActivatedRoute,
		private baseRoute: ActivatedRoute,
		private invalidate: () => void,
	) {
		this.name = uiAction.name;
		this.buttonClass = uiAction.buttonClass;
	}
}

export class NavigationViewMode implements NavigationMode {
	actionPermissionName = null;
	isFolderChangeAllowed = true;
	isMenuEnabled = true;
	isRearrangeEnabled = false;
	isSelectEnabled = false;
	addChildInputVisible = false;
	uiActions = [];
}

export abstract class BaseNavigationComponent {
	private dummySelection: MultiLevelSelection<ListNavigationEntry>;

	protected constructor(
		protected route: ActivatedRoute,
		readonly router: Router,
		protected selectionService: MultiLevelSelectionService<ListNavigationEntry>,
	) {
		this.dummySelection = this.selectionService.newSelection([], "dummy", (e: ListNavigationEntry) => e.id);
	}

	inProgress: boolean = false;
	rearrangeOrder!: "alphabetical" | "custom";

	getSelection(
		navigationConfiguration: NavigationConfiguration,
		selectEnabled: boolean,
	): MultiLevelSelection<ListNavigationEntry> {
		if (!selectEnabled) {
			return this.dummySelection;
		}
		const existingSelection = this.selectionService.get(navigationConfiguration.selectionIdentifier);
		return existingSelection !== undefined ? existingSelection : this.newSelection(navigationConfiguration);
	}

	protected newSelection(navigationConfiguration: NavigationConfiguration): MultiLevelSelection<ListNavigationEntry> {
		const actionParam = this.getActionParam(navigationConfiguration);
		const currentMode = this.getCurrentMode(navigationConfiguration.supportedModes, actionParam);
		const selection = this.selectionService.newSelection(
			navigationConfiguration.list.filter((element: ListNavigationEntry) => {
				return this.isSelectable(element, currentMode.actionPermissionName);
			}),
			navigationConfiguration.selectionIdentifier,
			(e: ListNavigationEntry) => e.id,
		);
		return selection;
	}

	isSelectable(entry: ListNavigationEntry, actionPermissionName: string | null): boolean {
		if (!entry.actions) {
			return false;
		}
		if (actionPermissionName === null) {
			return true;
		}
		return entry.actions[actionPermissionName];
	}

	protected resetSelection(selectionIdentifier: string): void {
		this.selectionService.remove(selectionIdentifier);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32217: Enable strict template type checking and suppress or fix all existing warnings.
	trackById: TrackByFunction<any> = (_index, navElement) => {
		return navElement.id;
	};

	async statego(
		state: unknown[],
		params: unknown,
		substate: unknown[],
		popupRoute: unknown[] | null,
		baseRoute: ActivatedRoute,
	): Promise<boolean> {
		return this.stategoOutlet({ globalRoute: [], primaryRoute: state }, params, substate, popupRoute, baseRoute);
	}

	async stategoOutlet(
		state: { globalRoute: unknown[]; primaryRoute: unknown[] },
		params: unknown,
		substate: unknown[],
		popupRoute: unknown[] | null,
		baseRoute: ActivatedRoute,
	): Promise<boolean> {
		const relativeTo: NavigationExtras = { relativeTo: baseRoute };
		const cleanedParams = this.skipNullOrUndefinedOrFalseParams(params);

		return this.router.navigate(
			[
				...state.globalRoute,
				{ outlets: { primary: state.primaryRoute.concat([cleanedParams], substate), popup: popupRoute } },
			],
			{ ...relativeTo },
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	skipNullOrUndefinedOrFalseParams(params: any): Record<string, string> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const result: any = {};
		Object.keys(params)
			.filter((key) => {
				const value = params[key];
				if (value === undefined || value === null) {
					return false;
				}
				if (typeof value === "boolean") {
					return value;
				}
				return true;
			})
			.forEach((key) => (result[key] = params[key]));
		return result;
	}

	async processFollowUpAction(
		action: MenuUIAction | RearrangeUIAction | UIAction,
		route: ActivatedRoute,
		baseRoute: ActivatedRoute,
		selection: MultiLevelSelection<ListNavigationEntry> | null,
		invalidate: () => void,
	): Promise<boolean> {
		if (action.indicateProgress) {
			this.inProgress = true;
		}
		let followUp: FollowUpAction;
		if (isRearrangeUIAction(action)) {
			followUp = await action.onRearrange(action.getSortedEntries(), this.rearrangeOrder);
		} else if (isMenuUIAction(action)) {
			followUp = await action.onMenuClick();
		} else {
			followUp = await action.on(selection!);
		}

		if (action.invalidate) {
			invalidate();
		}
		const params = route.snapshot.paramMap;
		const substate = followUp.substateName || [];
		switch (followUp.navMode) {
			case NavMode.FOLDER_RESET_PARAMS: // go to parent i.e. after delete
				return this.statego(
					action.folder.route,
					{
						hash: followUp.hash,
						action: null,
						forcedFolder: null,
						folderId: null,
						subtype: null,
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.FORCED_FOLDER: //switch to PASTE
				return this.statego(
					action.folder.route,
					{
						...reuse(params),
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: true,
						folderId: null,
						subtype: followUp.subtypeIdent,
						...backupFolderId(params),
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.FORCED_CHILD_AFTER_BACKUP:
				return this.statego(
					getSelfRoute(action, params, followUp),
					{
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: null,
						folderId: action.folder.id,
						subtype: null,
						...backup(params),
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.FORCED_FOLDER_AFTER_BACKUP:
				return this.statego(
					action.folder.route,
					{
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: true,
						folderId: null,
						subtype: followUp.subtypeIdent,
						...backup(params),
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.CONTEXT_AS_NEW_FOLDER:
				if (followUp.context === undefined) {
					throw new Error("Illegal state: CONTEXT_AS_FORCED_FOLDER_AFTER_BACKUP requested but no context given.");
				}
				return this.statego(
					followUp.context.route,
					{
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: true,
						folderId: null,
						subtype: followUp.subtypeIdent,
						...backup(params),
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.BACK_TO_SELF_AND_FOLDER_KEEP_BACKUP: //cancel of paste mode
				return this.stategoOutlet(
					restoreRoute(params, followUp.restoreRouteFromParams),
					{
						...reuse(params),
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: null,
						...restoreFolderId(params),
						subtype: null,
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.BACK_TO_SELF:
				return this.stategoOutlet(
					restoreRoute(params, followUp.restoreRouteFromParams),
					{
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: null,
						folderId: null,
						subtype: undefined,
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.KEEP_SELF_RESET_PARAMS:
				return this.statego(
					getSelfRoute(action, params, followUp),
					{
						hash: followUp.hash,
						action: null,
						forcedFolder: null,
						folderId: null,
						subtype: undefined,
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.SELF:
				return this.statego(
					getSelfRoute(action, params, followUp),
					{
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: null,
						folderId: null,
						subtype: followUp.subtypeIdent,
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			case NavMode.TO_CONTEXT:
				return this.statego(
					followUp.context!.route,
					{
						hash: followUp.hash,
						action: followUp.actionParamName,
						forcedFolder: null,
						folderId: null,
						subtype: followUp.subtypeIdent,
						refresh: getForceRefresh(followUp.forceReload, params),
					},
					substate,
					followUp.popupRoute,
					baseRoute,
				).finally(() => this.afterNavigation(action));
			default:
				// NO_NAV
				this.afterNavigation(action);
				return true;
		}
	}

	private afterNavigation(action: MenuUIAction | RearrangeUIAction | UIAction): void {
		if (action.indicateProgress) {
			this.inProgress = false;
		}
	}

	protected reuseNavigationParams(params: ParamMap): StringToString {
		return getNavigationMatrixParamsToReuse(params);
	}

	getCurrentMode(supportedModes: Map<string, NavigationMode> | undefined, actionName: string): NavigationMode {
		const navMode = supportedModes === undefined ? undefined : supportedModes.get(actionName);
		return navMode === undefined ? new NavigationViewMode() : navMode;
	}

	getActionParam(navigationConfiguration: NavigationConfiguration): string {
		if (!navigationConfiguration.activatedRoute) {
			return "view";
		}
		const actionParam = navigationConfiguration.activatedRoute.snapshot.paramMap.get("action");
		return actionParam ? actionParam : "view";
	}

	isRouterLinkActive(commands: unknown[], exact: boolean): boolean {
		const options: IsActiveMatchOptions = exact
			? { paths: "exact", queryParams: "exact", fragment: "ignored", matrixParams: "ignored" }
			: { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" };
		return this.router.isActive(this.router.createUrlTree(commands, { relativeTo: this.route }), options);
	}

	getRouterLink(
		commands: unknown[],
		navigationConfiguration: NavigationConfiguration,
		currentMode?: NavigationMode,
	): unknown[] | undefined {
		if (!commands || (currentMode && this.isRouterLinkDisabled(currentMode))) {
			return undefined;
		}
		const commandsWithParameters = navigationConfiguration.activatedRoute
			? commands.concat(this.reuseNavigationParams(navigationConfiguration.activatedRoute.snapshot.paramMap))
			: commands;
		return [{ outlets: { primary: commandsWithParameters, dialog: null } }];
	}

	isRouterLinkDisabled(currentMode: NavigationMode): boolean {
		return currentMode.isRearrangeEnabled;
	}
}

export function getNavigationMatrixParamsToReuse(params: ParamMap): StringToString {
	const result: StringToString = {};
	params.keys.forEach((key) => {
		if (isNotInParamsToNotReuse(key, params.get(key)!, params.get("action"))) {
			result[key] = params.get(key)!;
		}
	});
	return result;
}

/**
 * Checks if a parameter doesn't starts with "dup_" and if the parameter isn't in a list of params to not reuse.
 * @param paramToCheck The param to check.
 * @paramValue Some params may not be reused only if they have a certain value.
 * @returns true When the param doesn't starts with "dup_" and isn't in the list of params to not reuse.
 */
function isNotInParamsToNotReuse(paramToCheck: string, paramValue: string, action: string | null): boolean {
	//never reuse refresh param, its used to force an url change
	const paramsToNotReuse = [
		"workspaceId",
		"processVersion",
		"type",
		"identity",
		"refresh",
		"swid",
		"spv",
		"smode",
		"subtype",
	];

	const paramValuePairsToNotReuse = new Map();
	//The add action may not reused because the input field has to close on navigating
	paramValuePairsToNotReuse.set("action", "add");

	if (action === "add") {
		paramsToNotReuse.push("forcedFolder");
		paramValuePairsToNotReuse.set("hash", "new-child");
	}

	return (
		paramsToNotReuse.indexOf(paramToCheck) === -1 &&
		!isMapContainingKeyValuePair(paramValuePairsToNotReuse, paramToCheck, paramValue) &&
		!paramToCheck.startsWith("dup_")
	);
}

function isMapContainingKeyValuePair<K, V>(map: Map<K, V>, key: K, value: V): boolean {
	return map.has(key) && map.get(key) === value;
}

function getForceRefresh(forceRefresh: boolean, params: ParamMap): boolean {
	if (!forceRefresh) {
		return false;
	}
	return params.get("refresh") === null; //toggle refresh
}

function restoreRoute(
	params: ParamMap,
	restoreRouteFromParams?: (params: unknown) => unknown[],
): { globalRoute: unknown[]; primaryRoute: unknown[] } {
	if (!restoreRouteFromParams) {
		throw new Error("restore route function missing!");
	}
	const result: StringToString = {};
	params.keys
		.filter((key) => key.indexOf("back") === 0)
		.forEach((key) => {
			result[key.substring(4)] = params.get(key)!;
		});
	if (params.has("swid") && params.has("spv")) {
		return {
			globalRoute: [
				"/",
				"workspace",
				result.workspaceId,
				result.processVersion,
				{ swid: params.get("swid"), spv: params.get("spv"), smode: params.get("smode") },
			],
			primaryRoute: restoreRouteFromParams(result),
		};
	}
	return {
		globalRoute: ["/", "workspace", result.workspaceId, result.processVersion],
		primaryRoute: restoreRouteFromParams(result),
	};
}

function getSelfRoute(
	action: MenuUIAction | RearrangeUIAction | UIAction,
	params: ParamMap,
	followUp: FollowUpAction,
): unknown[] {
	return action.self === undefined && followUp.restoreRouteFromParams !== undefined
		? restoreSelfFromParams(params, followUp.restoreRouteFromParams)
		: action.self.route;
}

function restoreSelfFromParams(params: ParamMap, restoreRouteFromParams: (params: unknown) => unknown[]): unknown[] {
	const cleanedParams: StringToString = {};
	params.keys.forEach((key) => {
		cleanedParams[key] = params.get(key)!;
	});
	return restoreRouteFromParams(cleanedParams);
}

function backup(params: ParamMap): StringToString {
	const result: StringToString = {};
	params.keys
		.filter((k) => k !== "folderId")
		.forEach((key) => {
			result["back" + key] = params.get(key)!;
		});
	return result;
}

function reuse(params: ParamMap): StringToString {
	const result: StringToString = {};
	params.keys
		.filter((k) => k !== "backfolderId")
		.forEach((key) => {
			if (isNotInParamsToNotReuse(key, params.get(key)!, params.get("action"))) {
				result[key] = params.get(key)!;
			}
		});
	return result;
}

function backupFolderId(params: ParamMap): StringToString {
	const result: StringToString = {};
	params.keys
		.filter((k) => k === "folderId")
		.forEach((key) => {
			result["back" + key] = params.get(key)!;
		});
	return result;
}

function restoreFolderId(params: ParamMap): StringToString {
	const result: StringToString = {};
	params.keys
		.filter((k) => k === "backfolderId")
		.forEach((key) => {
			result[key.substring(4)] = params.get(key)!;
		});
	return result;
}
