import { HttpErrorResponse } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { LoadingErrorService } from "files/error/loading-error.service";
import { Subject } from "rxjs";

@Component({
	selector: "stages-loading-error",
	templateUrl: "./loading-error.component.html",
})
export class StagesLoadingErrorComponent {
	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	loadingError$: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

	constructor(public loadingErrorService: LoadingErrorService) {
		window.setTimeout(() => {
			this.loadingError$.next(this.loadingErrorService.getError());
		}, 1);
	}

	close = (): void => {
		if (this.dialog) {
			this.dialog.close();
		}
	};
}
