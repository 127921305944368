<stages-dialog-header>
    <header class="title">
        <h2 translate="process.compare.unavailable"></h2>
    </header>
</stages-dialog-header>
<p class="panel" translate="process.compare.unavailable.message"></p>
<footer>
	<div class="buttons-bottom">
		<div class="spacer"></div>
		<button type="button" class="lg confirm" (click)="close()">
			<span translate="ok"></span>
		</button>
	</div>
</footer>