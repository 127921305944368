import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class SelectionInput extends InputBase<string[]> {
	selectionOptions: SelectionOption[];
	multiple: boolean;
	alphabetical: boolean;

	constructor(args: SelectionArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "selection",
			required: args.required,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: false, // SELECT Html element has no read readonly attribute. It can only be disabled but why should anyone want that?
			hidden: args.hidden,
		});
		this.selectionOptions = args.selectionOptions;
		this.multiple = args.multiple || false;
		this.alphabetical = args.alphabetical || false;

		this.setSelectionValue(this.value);
	}

	setSelectionValue(optionStrings?: string[] | null): void {
		if (this.selectionOptions.length === 0) {
			this.value = [];
		} else {
			const validOptionStrings = this.getValidOptionStrings(optionStrings);
			if (validOptionStrings.length > 0) {
				this.setValueToValidOption(validOptionStrings);
			} else if (!this.multiple) {
				this.setValueToFirstOption();
			}
		}
	}

	getValidOptionStrings(optionStrings?: string[] | null): string[] {
		const validOptionStrings: string[] = [];

		if (optionStrings) {
			optionStrings.forEach((optionString) => {
				this.selectionOptions.forEach((option) => {
					if (option.value === optionString) {
						validOptionStrings.push(optionString);
					}
				});
			});
		}

		return validOptionStrings;
	}

	setValueToValidOption(validOptionStrings: string[]): void {
		this.value = validOptionStrings;
	}

	setValueToFirstOption(): void {
		this.value = [this.selectionOptions[0].value];
	}
}

export class SelectionOption {
	value: string;
	translateKey?: string | null;
	untranslatableName?: string | null;

	constructor(args: { value: string; translateKey?: string | null; untranslatableName?: string | null }) {
		this.value = args.value;
		this.translateKey = args.translateKey;
		this.untranslatableName = args.untranslatableName;
	}
}

export class SelectionArgs extends CommonArgs<string[]> {
	selectionOptions!: SelectionOption[];
	multiple?: boolean | null;
	alphabetical?: boolean | null;
}
