import { Injectable } from "@angular/core";
import { PreferencesService } from "core/preferences.service";

type Perspective = stages.process.Perspective;

interface PerspectiveIdHolder {
	perspective: string;
}

@Injectable({ providedIn: "root" })
export class PerspectiveService {
	constructor(private preferencesService: PreferencesService) {}

	async getPerspective(): Promise<string> {
		return (await this.preferencesService.getPreference("perspective", { perspective: "viewing" })).perspective;
	}

	async presetPerspective(
		perspectiveIdFromState: string | undefined,
		availablePerspectives: Perspective[],
	): Promise<string> {
		if (!perspectiveIdFromState) {
			if (availablePerspectives && availablePerspectives.length > 0) {
				const firstPerspective = availablePerspectives[0].ident;
				return this.preferencesService
					.getPreference("perspective", { perspective: firstPerspective })
					.then((storedPerspectiveOrDefault: PerspectiveIdHolder) => {
						const availablePerspectiveIdents = availablePerspectives.map((p) => p.ident);
						return availablePerspectiveIdents.indexOf(storedPerspectiveOrDefault.perspective) !== -1
							? storedPerspectiveOrDefault.perspective
							: firstPerspective;
					});
			} else {
				throw new Error("No perspectives defined");
			}
		} else {
			this.preferencesService.setPreference("perspective", { perspective: perspectiveIdFromState });
			return perspectiveIdFromState;
		}
	}
}
