import { Component, ViewChild } from "@angular/core";
import { NewDialogComponent } from "common/newdialog/dialog.component";

@Component({
	templateUrl: "compare-to-dialog.component.html",
})
export class CompareToDialogComponent {
	classes?: string[] = ["autoHeight"];

	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;
}
