import { Component, Input } from "@angular/core";
import { Button } from "common/data/data-view.component";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { NewDialogComponent } from "common/newdialog/dialog.component";

@Component({
	selector: "stages-popup-footer",
	templateUrl: "./popup-footer.component.html",
	styleUrls: ["./popup-footer.component.scss"],
})
export class PopupFooterComponent {
	@Input()
	tabIndexOffset: number = 10;

	@Input()
	buttons: Button[] = [];

	@Input()
	hasCancelButton: boolean = true;

	@Input()
	removeMargin: boolean = true;

	@Input()
	viewedDialog?: NewDialogComponent;

	constructor(private dialog: DialogAdapter) {}

	close(): void {
		if (!this.viewedDialog) {
			this.dialog.close(undefined);
		} else {
			this.viewedDialog.close();
		}
	}
}
