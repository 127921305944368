import { ParamMap } from "@angular/router";
import { Store } from "common/data/store.logic";
import { UserAssignmentsService } from "process/userassignments/user-assignments.service";

export class UserAssignmentsStore implements Store<stages.userassignments.UserAssignment> {
	constructor(
		private selfPromise: Promise<stages.process.ProcessView>,
		private userAssignmentsService: UserAssignmentsService,
		private path: string | null,
		private pageSize: number,
		private elementPageSize: number,
	) {}

	async getPage(
		pageIndex: number,
		workspaceId: string,
		pv: string,
		paramMap: ParamMap,
	): Promise<PagedResult<stages.userassignments.UserAssignment>> {
		if (pageIndex === 1) {
			return this.selfPromise.then((self) => {
				if (!self.userContainer) {
					const pagedResult: PagedResult<stages.userassignments.UserAssignment> = {
						items: [],
						page: 1,
						totalItemCount: 0,
						totalPageCount: 0,
						pageSize: null,
					};

					return pagedResult;
				}

				return self.userContainer.userAssignments;
			});
		} else {
			const promise = this.userAssignmentsService.loadMore(
				paramMap.get("type")!,
				paramMap.get("identity")!,
				workspaceId,
				pv,
				this.path,
				pageIndex,
				this.pageSize,
				this.elementPageSize,
			);

			return promise;
		}
	}

	async delete(
		userAssignmentIds: string[],
		pageIndex: number,
		paramMap: ParamMap,
	): Promise<PagedResult<stages.userassignments.UserAssignment>> {
		const self = await this.selfPromise;
		const result = await this.userAssignmentsService.unassign(
			paramMap.get("type")!,
			self.id,
			paramMap.get("identity")!,
			userAssignmentIds,
			paramMap.get("workspaceId")!,
			paramMap.get("processVersion")!,
			1,
			this.pageSize,
			this.pageSize * pageIndex,
			this.elementPageSize,
		);

		if (self.userContainer) {
			self.userContainer.userAssignments = result;
		}
		return result;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async search(term: string, paramMap: ParamMap): Promise<any> {
		return this.userAssignmentsService.search(term, paramMap.get("processVersion")!);
	}

	async add(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		user: any,
		pageIndex: number,
		paramMap: ParamMap,
	): Promise<PagedResult<stages.userassignments.UserAssignment>> {
		const self = await this.selfPromise;
		const result = await this.userAssignmentsService.assign(
			paramMap.get("type")!,
			self.id,
			paramMap.get("identity")!,
			user.id,
			paramMap.get("workspaceId")!,
			paramMap.get("processVersion")!,
			1,
			this.pageSize,
			this.pageSize * pageIndex,
			this.elementPageSize,
		);

		if (self.userContainer) {
			self.userContainer.userAssignments = result;
		}
		return result;
	}
}
