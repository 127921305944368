<div class="details form-inline">
	<div class="input-container">
		<label for="editor-link-name" class="form-label" translate="editor.link.name"></label>
		<input
			id="editor-link-name"
			type="text"
			class="text"
			[ngModel]="displayName"
			(ngModelChange)="setDisplayName($event)"
		/>
	</div>
	<div class="input-container">
		<label for="editor-link-link" class="form-label" translate="editor.link.link"></label>
		<input
			id="editor-link-link"
			type="text"
			class="text"
			[ngClass]="{ error: !referenceIsValid() }"
			[ngModel]="textualReference()"
			(ngModelChange)="textualReference($event)"
		/>
	</div>
	<div class="input-container" *ngIf="inlineSupported">
		<label for="editor-link-inline" class="form-label" translate="editor.link.inline"></label>
		<input
			name="inline"
			id="editor-link-inline"
			type="checkbox"
			hidden="hidden"
			[(ngModel)]="inline"
			(ngModelChange)="inlineChanged($event)"
		/>
		<label for="editor-link-inline" class="switch"></label>
	</div>
	<div class="buttons">
		<button class="sm" [disabled]="!referenceIsValid()" translate="insert" (click)="onInsert()"></button>
	</div>
</div>
