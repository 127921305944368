<stages-dialog2 #dialog *ngIf="urlProperties$ | async as urlProperties">
	<stages-dialog-header></stages-dialog-header>
	<form [formGroup]="urlForm" class="content" novalidate stagesFocusError>
		<h1 translate="{{ titleKey }}"></h1>

		<div class="form" novalidate>
			<div class="form-group">
				<div class="input-container md required">
					<label class="input-label" for="name" translate="files.name"></label>
					<input
						type="text"
						class="text fullsize"
						tabindex="1"
						formControlName="name"
						[stagesMaxLength]="255"
						stagesAutofocus
						stagesValidateOnBlur
					/>
					<div class="info">
						<div class="errors" *ngIf="urlName?.dirty || urlName?.touched">
							<div class="error" role="alert" *ngIf="urlName?.hasError('required')" translate="input.required"></div>
							<div
								class="error"
								role="alert"
								*ngIf="urlName?.hasError('max')"
								translate="input.maxLength"
								[translateParams]="{ length: 255 }"
							></div>
						</div>
						<div class="maxlength">{{ urlName?.value.length || 0 }}/255</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="input-container md required">
					<label class="input-label" for="url" translate="files.url"></label>
					<input
						id="url"
						name="url"
						type="text"
						class="text fullsize"
						tabindex="1"
						formControlName="url"
						stagesValidateOnBlur
					/>
					<div class="info">
						<div class="errors">
							<ng-container *ngIf="url?.dirty || url?.touched">
								<div class="error" role="alert" *ngIf="url?.hasError('required')" translate="input.required"></div>
								<div class="error" role="alert" *ngIf="url?.hasError('urlPattern')" translate="input.pattern.url"></div>
							</ng-container>
							<div class="error" role="alert" *ngIf="!url?.errors || url?.errors?.length === 0">&nbsp;</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
	<footer>
		<div class="buttons">
			<button class="button sm cancel" (click)="dialog.close()" translate="cancel"></button>
			<button class="button sm save" [disabled]="isButtonDisabled(urlForm)" (click)="save(urlProperties)">
				<span *ngIf="!saveInProgress" translate="files.start.url.button"></span>
				<stages-spin *ngIf="saveInProgress" size="s"></stages-spin>
			</button>
		</div>
	</footer>
</stages-dialog2>
