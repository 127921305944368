import { PluginRegistry } from "common/editor/description.service";
import { NonProcessEditorProperties } from "common/editor/plugin.service";
import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class RichTextInput extends InputBase<string> {
	processTypeIdent?: string | null;
	pluginRegistry!: PluginRegistry;
	beanType?: string;
	beanIdentity!: string;
	beanId!: string;
	workspaceId!: string;
	pv!: string;
	properties?: NonProcessEditorProperties;

	constructor(args: RichTextInputArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "rich_text",
			inputFieldType: "text",
			required: args.required,
			maxLength: args.maxLength,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});
		this.processTypeIdent = args.processTypeIdent;
		this.pluginRegistry = args.pluginRegistry;
		this.beanType = args.beanType;
		this.beanIdentity = args.beanIdentity;
		this.beanId = args.beanId;
		this.workspaceId = args.workspaceId;
		this.pv = args.pv;
	}
}

export interface RichTextInputContext {
	processTypeIdent?: string | null;
	pluginRegistry: PluginRegistry;
	beanType?: string;
	beanIdentity: string;
	beanId: string;
	workspaceId: string;
	pv: string;
	properties?: NonProcessEditorProperties;
}

export class RichTextInputArgs extends CommonArgs<string> implements RichTextInputContext {
	maxLength?: number | null;
	processTypeIdent?: string | null;
	pluginRegistry!: PluginRegistry;
	beanType?: string;
	beanIdentity!: string;
	beanId!: string;
	workspaceId!: string;
	pv!: string;
}
