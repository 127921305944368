import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-readonly-description",
	templateUrl: "./readonly-description.component.html",
	styles: [":host { display: block; }"],
})
export class ReadonlyDescriptionComponent {
	@Input()
	displayDescription!: stages.core.format.DisplayDescription;
}
