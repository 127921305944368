import { Injectable } from "@angular/core";
import { WindowRef } from "common/window-ref.service";
import { MainService } from "core/main.service";

/**
 * Scrolls to top by default, but setting the scroll position otherwise will overrule that behavior for the next view load.
 * Also provides disabling of window scrolling, so the main page is not scrollable if e.g. dialogs are open.
 */
@Injectable({ providedIn: "root" })
export class ScrollService implements ScrollService {
	scrollPosition = "top";
	scrollDisabled = false;

	constructor(private windowRef: WindowRef, private mainService: MainService) {}

	setScrollPosition(newScrollPosition: string): void {
		this.scrollPosition = newScrollPosition;
	}

	/* Shouldn't be called more than once. */
	scrollIfNecessary(): void {
		if (this.scrollDisabled) {
			return;
		}

		if (this.scrollPosition === "top") {
			this.windowRef.nativeWindow.scrollTo(0, 0);
		}

		this.scrollPosition = "top";
	}

	disableWindowScroll(): void {
		this.scrollDisabled = true;
		this.mainService.applicationState.scrollDisabled = true;
		document.documentElement.style.overflowY = "hidden";
	}

	enableWindowScroll(): void {
		this.scrollDisabled = false;
		this.mainService.applicationState.scrollDisabled = false;
		document.documentElement.style.overflowY = "auto";
	}

	isWindowScrollDisabled(): boolean {
		return this.scrollDisabled;
	}
}
