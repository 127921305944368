import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NewDialogComponent } from "common/newdialog/dialog.component";

@Component({
	selector: "stages-dialog-header",
	templateUrl: "./dialog-header.component.html",
	styleUrls: ["dialog-header.component.scss"],
})
export class DialogHeaderComponent {
	selectedLanguage: number = 0;

	@Input()
	languages?: string[];

	@Output()
	readonly languageSelected = new EventEmitter<number>();

	constructor(private dialogComponent: NewDialogComponent) {}

	isLanguageSelected(index: number): boolean {
		return this.selectedLanguage === index;
	}

	selectLanguage(index: number): void {
		this.selectedLanguage = index;
		this.languageSelected.emit(this.selectedLanguage);
	}

	close(): void {
		this.dialogComponent.close();
	}
}
