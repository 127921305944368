import { Component, OnDestroy, OnInit } from "@angular/core";
import { MainService } from "core/main.service";
import { PreferencesService } from "core/preferences.service";
import { ApplicationState } from "core/volatile-application-state.model";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-navigation-drawer",
	templateUrl: "./navigation-drawer.component.html",
	styleUrls: ["./navigation-drawer.component.scss"],
})
export class NavigationDrawerComponent implements OnDestroy, OnInit {
	private prefKey: string = "navigationDrawerOpen";
	private destroy$ = new Subject<boolean>();
	applicationState: ApplicationState;
	preference: boolean = true;

	constructor(mainService: MainService, private preferencesService: PreferencesService) {
		this.applicationState = mainService.applicationState;
	}

	async ngOnInit(): Promise<void> {
		const pref = await this.preferencesService.getPreference(this.prefKey, { value: true });
		this.preference = pref.value;
		this.applicationState.navigationDrawerOpen = this.preference;

		this.applicationState.isMobileView.pipe(takeUntil(this.destroy$)).subscribe((isMobileView: boolean) => {
			this.applicationState.navigationDrawerOpen = isMobileView ? false : this.preference;
			this.applicationState.maskVisible = false;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	toggleMenu(): void {
		this.applicationState.toggleNavigationDrawer();
		this.preference = this.applicationState.navigationDrawerOpen;
		this.preferencesService.setPreference(this.prefKey, { value: this.applicationState.navigationDrawerOpen });
	}
}
