export class SearchErrorWithResults<T> extends Error {
	constructor(public results: T[], public cause: unknown) {
		super("Search request produced an error, but there are results");

		// Set the prototype explicitly when extending built-in types.
		// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, SearchErrorWithResults.prototype);
	}
}

export interface SearchQuery<NIT> {
	run(searchTerm: string): Promise<NIT[]>;
}
