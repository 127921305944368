<stages-dialog2 #dialog>
	<stages-popup-header [viewedDialog]="dialogViewedChild"></stages-popup-header>
	<stages-popup-content>
		<stages-page-header [headline]="headline" [backButtonDisabled]="true"> </stages-page-header>

		<stages-grouped-data-view
			#dataView
			listStyles="grouped-list dense"
			[dataSource]="$any(dataSourceProcess)"
			[groupTemplate]="groupItemTemplate"
			[listItemTemplate]="listView"
			[tableItemTemplate]="listView"
			[idFn]="processReleaseDetailsComponent.getApprovementId"
			[groupIdFn]="$any(processReleaseDetailsComponent.getGroupId)"
			[groupDisabledFn]="$any(false)"
			[pageSize]="pageSize"
			translateNone="process.releasedetails.empty"
		>
		</stages-grouped-data-view>

		<h5 class="headline" translate="process.releasedetails.card.modules"></h5>

		<stages-grouped-data-view
			#dataView
			listStyles="grouped-list dense"
			[dataSource]="$any(dataSourceModules)"
			[groupTemplate]="groupItemTemplate"
			[listItemTemplate]="listView"
			[tableItemTemplate]="listView"
			[idFn]="processReleaseDetailsComponent.getApprovementId"
			[groupIdFn]="$any(processReleaseDetailsComponent.getGroupId)"
			[groupDisabledFn]="$any(false)"
			[collapsibleGroupName]="processReleaseDetailsComponent.collapsibleGroupName"
			[pageSize]="pageSize"
			translateNone="process.releasemodules.empty"
		>
		</stages-grouped-data-view>
	</stages-popup-content>
	<stages-popup-footer *ngIf="dialogViewedChild" [viewedDialog]="dialogViewedChild"></stages-popup-footer>
</stages-dialog2>

<ng-template #groupItemTemplate let-entry>
	<div class="label">
		<a *ngIf="hasReadPermission(entry.actions)" [routerLink]="openProcess(entry)" target="_blank">
			<ng-content *ngTemplateOutlet="groupTitle; context: { $implicit: entry }"></ng-content>
		</a>
		<ng-container *ngIf="!hasReadPermission(entry.actions)">
			<ng-content *ngTemplateOutlet="groupTitle; context: { $implicit: entry }"></ng-content>
		</ng-container>
	</div>
</ng-template>

<ng-template #groupTitle let-entry>
	<h3>
		{{
			entry.processVersionName !== null
				? entry.workspaceName + " (" + entry.processVersionName + ")"
				: entry.workspaceName
		}}
	</h3>
</ng-template>

<ng-template #listView let-item>
	<ng-container *ngIf="item && item.items && item.items.length > 0">
		<ng-container *ngFor="let approvement of item.items">
			<li class="list-item">
				<stages-list-item-profile-and-comment-template
					[userName]="approvement.participantName"
					[userNameSubdetail]="approvement.userName"
					[userId]="approvement.userId"
					[userEmail]="approvement.emailAddress"
					[userProfileVersion]="approvement.userProfileVersion"
					[date]="approvement.creationDate"
					[subDetail]="approvement.transitionName"
					[subDetail2]="approvement.newState"
					[subDetailColor2]="approvement.newStateColor"
					[useColonDelimiter]="true"
				>
				</stages-list-item-profile-and-comment-template>
			</li>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="item && item.items && item.items.length <= 0">
		<ng-container *ngTemplateOutlet="empty; context: { $implicit: item }"></ng-container>
	</ng-container>
</ng-template>

<ng-template #empty let-item>
	<li class="list-item">
		<div class="label">
			<div class="text center">
				<p class="notice" [translate]="'process.releasedetails.empty'"></p>
			</div>
		</div>
	</li>
</ng-template>
