<article class="history">
	<ul *ngIf="allEntries$ | async as allEntries; else isEmpty" class="list">
		<ng-container *ngIf="allEntries | visibleFilter: isCollapsed as visibleEntries">
			<ng-container *ngFor="let entry of $any(visibleEntries); let i = index; let last = last">
				<li class="list-item">
					<div class="label">
						<div class="avatar-cell">
							<stages-user-image
								[userName]="entry.user.name"
								userId="{{ entry.user.id }}"
								version="{{ entry.user.profileImageVersion }}"
							></stages-user-image>
						</div>
						<div class="text">
							<div class="info">
								<div class="wrapper">
									<p>
										<span class="user spacer highlight">{{ entry.user.fullname }}</span>
										<span class="spacer" translate="process.history.change.{{ entry.changeType }}"></span>
										<span [hidden]="!entry.label.length" class="highlight spacer">{{ entry.label }}</span>
									</p>
								</div>
								<h6 class="date">{{ entry.date }}</h6>
							</div>
							<p></p>
						</div>
					</div>
				</li>
				<li class="list-item bubble" *ngIf="showBubble($any(visibleEntries), allEntries, i) && isCollapsed">
					<a class="label" (click)="toggle()">
						<div class="avatar-cell">
							<div class="circle-icon">
								<i class="ico ico-more-horizontal"></i>
							</div>
						</div>
						<div class="text fullsize">
							<div class="info">
								<p class="clickable">
									<span translate="process.history.showall"></span>
									<span class="counter">{{ getNumberOfInvisibleEntries($any(visibleEntries), allEntries) }}</span>
								</p>
							</div>
						</div>
					</a>
				</li>
				<li *ngIf="!last" class="divider"></li>
			</ng-container>
		</ng-container>
	</ul>
	<ng-template #isEmpty>
		<p class="empty" translate="{{ translateNone }}"></p>
	</ng-template>
</article>
