import { Injectable } from "@angular/core";
import { IAnswer } from "process/tailoring/models/answer.interface";
import { AnsweredQuery } from "process/tailoring/models/answered-query";
import { IAnsweredQuery } from "process/tailoring/models/answered-query.interface";
import { IQuery } from "process/tailoring/models/query.interface";
import { IRoot } from "process/tailoring/models/root.interface";
import { TailoringWizardStore } from "process/tailoring/store";

@Injectable({ providedIn: "root" })
export class TailoringWizardLogicService {
	private sessionStorageKey!: string;
	root!: IRoot;

	queryIdToQueryMap!: Map<string, IQuery>;
	answerIdToAnswerMap!: Map<string, IAnswer>;
	queryIdToParentAnswerMap!: Map<string, IAnswer>;
	answerIdToQueryMap!: Map<string, IQuery>;

	queryIdToSelectedAnswerMap!: Map<string, IAnswer>;

	answeredQueriesLocalStore!: IAnsweredQuery[];
	_answeredQueryMap: Map<string, IAnsweredQuery>;

	constructor(private store: TailoringWizardStore) {
		this._answeredQueryMap = new Map();
	}

	initMapsWithRoot(root: IRoot, workspaceId: string, processVersion: string): void {
		this.sessionStorageKey = "tailoringWizard_" + workspaceId + "_" + processVersion;

		this.queryIdToQueryMap = new Map<string, IQuery>();
		this.answerIdToAnswerMap = new Map<string, IAnswer>();
		this.queryIdToParentAnswerMap = new Map<string, IAnswer>();
		this.answerIdToQueryMap = new Map<string, IQuery>();

		this.root = root;
		const queries = root.queries;

		for (const query of queries) {
			this.handleQueryForMap(query);
		}
		this.loadDataFromSession();
		if (this.answeredQueries.length === 0) {
			this.setAnsweredQueries(root.answeredQueries);
		}
	}

	private handleQueryForMap(query: IQuery): void {
		this.queryIdToQueryMap.set(query.id, query);
		const answers = query.answers;
		if (answers) {
			for (const answer of answers) {
				this.answerIdToAnswerMap.set(answer.id, answer);
				this.answerIdToQueryMap.set(answer.id, query);
				this.handleAnswerForMap(answer);
			}
		}
	}

	private handleAnswerForMap(answer: IAnswer): void {
		const queries = answer.queries;
		if (queries) {
			for (const query of queries) {
				this.queryIdToParentAnswerMap.set(query.id, answer);
				this.handleQueryForMap(query);
			}
		}
	}

	getNextQueryByAnswer(currentAnswerId: string | null): IQuery | null {
		if (!currentAnswerId) {
			return this.findNextQueryInParent(this.store.value.query!.id);
		}
		this.persist();
		const firstQuery = 0;
		const currentAnswer = this.answerIdToAnswerMap.get(currentAnswerId);
		const currentQuery = this.answerIdToQueryMap.get(currentAnswerId);

		const queriesOfCurrentAnswer = currentAnswer!.queries;
		if (queriesOfCurrentAnswer && queriesOfCurrentAnswer.length > 0) {
			return queriesOfCurrentAnswer[firstQuery];
		} else {
			return this.findNextQueryInParent(currentQuery!.id);
		}
	}

	private findNextQueryInParent(queryId: string): IQuery | null {
		const parentAnswer = this.queryIdToParentAnswerMap.get(queryId);
		if (!parentAnswer) {
			return this.getNextQueryOnRootLevel(queryId);
		}
		let found = false;
		for (const query of parentAnswer.queries) {
			if (queryId === query.id) {
				found = true;
			} else if (found) {
				return query;
			}
		}
		const queryOfParentAnswer = this.answerIdToQueryMap.get(parentAnswer.id);
		return this.findNextQueryInParent(queryOfParentAnswer!.id);
	}

	getQueryByIndex(index: number): IQuery {
		return this.root.queries[index];
	}

	getPreviousQuery(queryId: string): Maybe<IQuery> {
		const answeredQueries = this.store.value.answeredQueries!;
		const indexOfCurrentAnswer = answeredQueries.findIndex(
			(answeredQuery: IAnsweredQuery) => answeredQuery.queryId === queryId,
		);
		const wasFirstAnsweredQuery = indexOfCurrentAnswer === 0;
		const prevAnsweredQuery =
			indexOfCurrentAnswer !== -1
				? answeredQueries[indexOfCurrentAnswer - 1]
				: answeredQueries[answeredQueries.length - 1];
		return wasFirstAnsweredQuery ? null : this.getQueryById(prevAnsweredQuery.queryId);
	}

	getLastQuery(): Maybe<IQuery> {
		const answeredQueries = this.store.value.answeredQueries;
		if (answeredQueries && answeredQueries.length > 0) {
			const lastAnsweredQuery = answeredQueries[answeredQueries.length - 1];
			return this.getQueryById(lastAnsweredQuery.queryId);
		}
		return null;
	}

	getPreviousQueryOnRootLevel(queryId: string): IQuery | undefined {
		const foundIndex = this.root.queries.findIndex((query: IQuery) => query.id === queryId);
		let queryIndexOnRoot;
		if (foundIndex > 0) {
			queryIndexOnRoot = foundIndex - 1;
		} else {
			return undefined;
		}
		return this.root.queries[queryIndexOnRoot];
	}

	getNextQueryOnRootLevel(queryId: string): IQuery | null {
		const foundIndex = this.root.queries.findIndex((query: IQuery) => query.id === queryId);
		let queryIndexOnRoot;
		if (foundIndex < this.root.queries.length - 1) {
			queryIndexOnRoot = foundIndex + 1;
		} else {
			return null;
		}
		return this.root.queries[queryIndexOnRoot];
	}

	getAnswerOf(query: IQuery): Maybe<IAnswer> {
		let answer;
		const storedAnsweredQueries = this.store.value.answeredQueries!;
		const answeredQueries: IAnsweredQuery[] = storedAnsweredQueries;

		if (answeredQueries) {
			const queryIndexOnAnsweredQueries = searchForQuery(answeredQueries, query);

			if (!!~queryIndexOnAnsweredQueries) {
				answer = this.answerIdToAnswerMap.get(answeredQueries[queryIndexOnAnsweredQueries].answerId);
			}
		}

		return answer;
	}

	queryIsAnswered(query: IQuery): boolean {
		let isAnswered = false;
		const storedAnsweredQueries = this.store.value.answeredQueries!;
		const answeredQueries: IAnsweredQuery[] = storedAnsweredQueries;

		if (answeredQueries) {
			const queryIndexOnAnsweredQueries = searchForQuery(answeredQueries, query);

			if (!!~queryIndexOnAnsweredQueries) {
				isAnswered = true;
			}
		}

		return isAnswered;
	}

	getAnswerOfQuery(queryId: string): Maybe<IAnswer> {
		if (!this.answerIdToAnswerMap) {
			throw new Error("missing init");
		}

		const answeredQueries = this.store.value.answeredQueries;
		if (answeredQueries) {
			const givenAnswer = answeredQueries.find((answeredQuery: IAnsweredQuery) => answeredQuery.queryId === queryId);
			return givenAnswer ? this.answerIdToAnswerMap.get(givenAnswer.answerId) : null;
		} else {
			return undefined;
		}
	}

	getQueryById(queryId: string): IQuery | undefined {
		let query: IQuery | undefined;
		// possibly need to calculate maps when using bookmarked queries
		if (this.queryIdToQueryMap) {
			query = this.queryIdToQueryMap.get(queryId);
		}

		if (query) {
			return query;
		} else {
			throw new Error("missing init");
		}
	}

	getAnswerByAnswerId(answerId: string): IAnswer | undefined {
		let answer: IAnswer | undefined;
		// possibly need to calculate maps when using bookmarked queries
		if (this.answerIdToAnswerMap) {
			answer = this.answerIdToAnswerMap.get(answerId);
		}

		if (answer) {
			return answer;
		} else {
			throw new Error("missing init");
		}
	}

	selectAnswer(query: IQuery, answer: IAnswer): void {
		this._answeredQueryMap.set(query.id, new AnsweredQuery(query.id, answer.id));

		const storedAnsweredQueries = this.store.value.answeredQueries;
		let answeredQueries: IAnsweredQuery[];
		if (storedAnsweredQueries) {
			answeredQueries = storedAnsweredQueries;
			const queryIndexOnAnsweredQueries = searchForQuery(answeredQueries, query);
			if (!!~queryIndexOnAnsweredQueries) {
				this.updateAnsweredQuery(answeredQueries, queryIndexOnAnsweredQueries, query.id, answer.id);
			} else {
				this.insertAnsweredQuery(answeredQueries, query.id, answer.id);
			}
		} else {
			answeredQueries = [];
			answeredQueries.push(new AnsweredQuery(query.id, answer.id));
		}

		this.setAnsweredQueries(answeredQueries);
	}

	private updateAnsweredQuery(
		answeredQueries: IAnsweredQuery[],
		atIndex: number,
		queryId: string,
		answerId: string,
	): IAnsweredQuery[] {
		answeredQueries[atIndex].queryId = queryId;
		answeredQueries[atIndex].answerId = answerId;

		return this.cleanIndependentAnswers(
			answeredQueries,
			this.queryIdToQueryMap.get(queryId)!,
			this.answerIdToAnswerMap.get(answerId)!,
		);
	}

	private insertAnsweredQuery(answeredQueries: IAnsweredQuery[], queryId: string, answerId: string): void {
		let parentAnsweredQuery = this.getparentQuery(queryId);

		if (!parentAnsweredQuery) {
			parentAnsweredQuery = this.getPreviousQueryOnRootLevel(queryId);
		}
		if (!parentAnsweredQuery) {
			answeredQueries.splice(0, 0, new AnsweredQuery(queryId, answerId));
		} else {
			const parentAnswer = this.getAnswerOf(parentAnsweredQuery);
			if (parentAnswer) {
				const deepestAnswer = this.findDeepestAnswerOf(answeredQueries, parentAnsweredQuery, parentAnswer);
				const queryOfDeepestAnswer = this.answerIdToQueryMap.get(deepestAnswer!.id);
				const queryIndexOnAnsweredQueries = searchForQuery(answeredQueries, queryOfDeepestAnswer!);
				answeredQueries.splice(queryIndexOnAnsweredQueries + 1, 0, new AnsweredQuery(queryId, answerId));
			} else {
				const parentQueryAnswer = this.getparentQuery(parentAnsweredQuery.id)!;
				const index = answeredQueries.findIndex(
					(answeredQuery: IAnsweredQuery) => answeredQuery.answerId === parentQueryAnswer.id,
				);
				answeredQueries.splice(index + 1, 0, new AnsweredQuery(queryId, answerId));
			}
		}
	}

	private findDeepestAnswerOf(
		answeredQueries: IAnsweredQuery[],
		query: IQuery,
		alreadyFoundAnswer: IAnswer,
	): IAnswer | null | undefined {
		const startIndex = searchForAnswer(answeredQueries, alreadyFoundAnswer);
		const foundIndex = this.findDeeperAnswerOf(answeredQueries, alreadyFoundAnswer, startIndex);

		return this.answerIdToAnswerMap.get(answeredQueries[foundIndex].answerId);
	}

	private findDeeperAnswerOf(
		answeredQueries: IAnsweredQuery[],
		alreadyFoundAnswer: IAnswer,
		startIndex: number,
	): number {
		let foundIndex = startIndex;

		alreadyFoundAnswer.queries.forEach((queryToAnalyze: IQuery) => {
			const newFoundIndex = searchForQuery(answeredQueries, queryToAnalyze);
			if (newFoundIndex > 0 && newFoundIndex > foundIndex) {
				const answerId = answeredQueries[newFoundIndex].answerId;
				const answer = this.answerIdToAnswerMap.get(answerId)!;
				foundIndex = this.findDeeperAnswerOf(answeredQueries, answer, newFoundIndex);
			}
		});
		return foundIndex;
	}

	private cleanIndependentAnswers(
		answeredQueries: IAnsweredQuery[],
		query: IQuery,
		dependentAnswer: IAnswer,
	): IAnsweredQuery[] {
		query.answers.forEach((answer: IAnswer) => {
			if (answer.id !== dependentAnswer.id) {
				this.removeDependentAnsweredQueries(answeredQueries, answer);
			}
		});
		return answeredQueries;
	}

	private removeDependentAnsweredQueries(answeredQueries: IAnsweredQuery[], answer: IAnswer): void {
		const queriesToRemove: IQuery[] = answer.queries;

		if (queriesToRemove && answeredQueries) {
			queriesToRemove.forEach((query: IQuery) => {
				this.removeQuery(answeredQueries, query);
			});
		}
	}

	private removeQuery(queriesToDeleteFrom: IAnsweredQuery[], queryToDelete: IQuery): void {
		queriesToDeleteFrom.forEach((item, index) => {
			if (item.queryId === queryToDelete.id) {
				queriesToDeleteFrom.splice(index, 1);
				this.cleanAllDependentQueriesFrom(queriesToDeleteFrom, queryToDelete);
			}
		});
	}

	private cleanAllDependentQueriesFrom(answeredQueries: IAnsweredQuery[], query: IQuery): void {
		query.answers.forEach((answer: IAnswer) => {
			this.cleanAllIndependentAnswers(answeredQueries, query);
		});
	}

	private cleanAllIndependentAnswers(answeredQueries: IAnsweredQuery[], query: IQuery): IAnsweredQuery[] {
		query.answers.forEach((answer: IAnswer) => {
			this.removeDependentAnsweredQueries(answeredQueries, answer);
		});
		return answeredQueries;
	}

	setAnsweredQueries(answeredQueries: IAnsweredQuery[]): void {
		this.store.set("answeredQueries", answeredQueries);
		this.persist();
	}

	get answeredQueries(): IAnsweredQuery[] {
		return Array.from(this._answeredQueryMap.values());
	}

	get answeredQueryIds(): IAnsweredQuery[] {
		const result: IAnsweredQuery[] = [];
		result.push(...this.answeredQueries);
		return result;
	}

	loadAnswersFromStorage(processIdentity: string): void {
		const result = new Map();
		if (typeof Storage !== "undefined") {
			const answeredQueries: IAnsweredQuery[] = JSON.parse(sessionStorage[this.sessionStorageKey] || "[]");
			answeredQueries.forEach((answeredQuery) => result.set(answeredQuery.queryId, answeredQuery));
		}
		this._answeredQueryMap = result;
	}

	persist(): void {
		if (typeof Storage !== "undefined") {
			const answeredQueries = this.store.value.answeredQueries;
			sessionStorage[this.sessionStorageKey] = JSON.stringify(answeredQueries);
		}
	}

	loadDataFromSession(): void {
		if (sessionStorage.getItem(this.sessionStorageKey) !== null) {
			this.loadAnswersFromStorage(this.sessionStorageKey);
			const answeredQueries = this.answeredQueryIds.length > 0 ? this.answeredQueryIds : undefined;
			this.store.set("answeredQueries", answeredQueries);
		}
	}

	isAnswerSelected(queryId: string, answerId: string): boolean {
		const answeredQueries = this.store.value.answeredQueries;
		const answer = this.answerIdToAnswerMap.get(answerId);
		if (answeredQueries && answer) {
			const givenAnswer = answeredQueries.find(
				(answeredQuery) => answeredQuery.answerId === answerId && answeredQuery.queryId === queryId,
			);
			return !!givenAnswer;
		}
		return false;
	}

	existAnsweredQueries(): boolean {
		if (this.store.value.answeredQueries) {
			return this.store.value.answeredQueries.length > 0;
		} else {
			return false;
		}
	}

	private getparentQuery(queryId: string): IQuery | undefined {
		const parentAnswer = this.queryIdToParentAnswerMap.get(queryId);
		if (parentAnswer) {
			return this.answerIdToQueryMap.get(parentAnswer.id);
		}

		return undefined;
	}

	existPreviousAnsweredQuery(): boolean {
		if (this.answeredQueries.length === 0) {
			return false;
		} else {
			return true;
		}
	}

	getNextAnsweredQuery(queryId: string): IQuery | null | undefined {
		if (this.answeredQueries.length > 0 && queryId === this.answeredQueries.slice(-1)[0].queryId) {
			const answerId = this._answeredQueryMap.get(queryId)!.answerId;
			if (answerId) {
				return this.getNextQueryByAnswer(answerId);
			}
		}
		const currentQuery = this.getQueryById(queryId);
		let queryIndexLookingFor: number = 0;
		if (currentQuery) {
			const currentQueryIndex = searchForQuery(this.answeredQueries, currentQuery);
			if (!!~currentQueryIndex) {
				queryIndexLookingFor = currentQueryIndex + 1;
				queryIndexLookingFor =
					queryIndexLookingFor > this.answeredQueries.length ? this.answeredQueries.length - 1 : queryIndexLookingFor;
				return this.getQueryById(this.answeredQueries[queryIndexLookingFor].queryId);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	removeAnsweredQueriesFromStorage(): void {
		if (typeof Storage !== "undefined") {
			sessionStorage.removeItem(this.sessionStorageKey);
		}

		let answeredQueries: IAnsweredQuery[];
		// eslint-disable-next-line prefer-const -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		answeredQueries = [];
		this.setAnsweredQueries(answeredQueries);
		this._answeredQueryMap = new Map();
	}
}

function searchForQuery(answeredQueries: IAnsweredQuery[], query: IQuery): number {
	return answeredQueries.findIndex((answeredQueryItem: IAnsweredQuery) => answeredQueryItem.queryId === query.id);
}

function searchForAnswer(answeredQueries: IAnsweredQuery[], answer: IAnswer): number {
	return answeredQueries.findIndex((answeredQueryItem: IAnsweredQuery) => answeredQueryItem.answerId === answer.id);
}
