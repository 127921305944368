import { Injectable } from "@angular/core";

export type DecycledObject = Record<string, Ref | unknown>;

@Injectable({ providedIn: "root" })
export class RetrocycleService {
	retrocycle<T>(value: DecycledObject): T {
		return walkThrough(value, value) as T;
	}
}

interface Ref {
	$ref: string;
}

function isRef(item: unknown): item is Ref {
	return !!item && (item as Ref).$ref !== undefined;
}

const pathRegex = /^\$?\["([^"]+)"](.*)/;

function resolvePath(path: string, root: DecycledObject): unknown {
	let resolved = root;
	let residual = path;
	let x;
	while ((x = pathRegex.exec(residual))) {
		resolved = resolved[x[1]] as DecycledObject;
		residual = x[2];
	}
	return resolved;
}

function mapValue(value: unknown, root: DecycledObject): unknown {
	if (isRef(value)) {
		return resolvePath(value.$ref, root);
	}
	return walkThrough(value as DecycledObject, root);
}

function walkThrough(current: DecycledObject, root: DecycledObject): unknown {
	if (current && typeof current === "object") {
		if (Array.isArray(current)) {
			current.forEach((value, index) => {
				current[index] = mapValue(value, root);
			});
		} else {
			Object.keys(current).forEach((key) => {
				current[key] = mapValue(current[key], root);
			});
		}
	}
	return current;
}
