import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-floating-action-menu",
	templateUrl: "floating-action-menu.component.html",
})
export class FloatingActionMenuComponent {
	@Input()
	iconClass?: string;

	open = false;

	closeHandler(): void {
		this.open = false;
	}

	toggleMenu(): void {
		this.open = !this.open;
	}
}
