<ng-template #propertyTemplate let-column="column" let-association="association">
	<ng-container *ngFor="let property of column.properties">
		<stages-description-view
			*ngIf="hasDescriptionValue(association, property, true)"
			descriptionClass="{{ property.class }} shift-font-size"
			[html]="getValue(association, property, true)!"
		></stages-description-view>
		<div class="{{ property.class }}" *ngIf="!hasDescriptionValue(association, property, true)">
			<ng-container>{{ getValue(association, property, true) }}</ng-container>
		</div>
	</ng-container>
</ng-template>

<ng-container *ngIf="associations$ | async as associations">
	<table class="process-table">
		<tbody>
			<tr *ngFor="let association of associations">
				<ng-container *ngFor="let column of $any(columns)">
					<td class="{{ column.class }} process-table-cell">
						<a *ngIf="column.isLinkable" [routerLink]="getRoute(association)">
							<ng-container
								*ngTemplateOutlet="propertyTemplate; context: { column: column, association: association }"
							></ng-container>
						</a>
						<ng-container *ngIf="!column.isLinkable">
							<ng-container
								*ngTemplateOutlet="propertyTemplate; context: { column: column, association: association }"
							></ng-container>
						</ng-container>
					</td>
				</ng-container>
			</tr>
		</tbody>
	</table>
</ng-container>
