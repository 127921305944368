import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { DataViewComponent, Mode } from "common/data/data-view.component";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { DialogService } from "common/dialog/dialog.service";
import { ViewService } from "core/view.service";
import { UserAssignmentsStore } from "process/userassignments/user-assignments-store.logic";
import { UserAssignmentsService } from "process/userassignments/user-assignments.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { lastValueFrom, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
	selector: "stages-user-assignments",
	templateUrl: "./user-assignments.component.html",
})
export class UserAssignmentsComponent extends BaseComponent implements OnInit, OnDestroy {
	UserAssignmentsComponent = UserAssignmentsComponent;
	@Input()
	path?: string;

	@Input()
	pageSize: number = 7;

	@Input()
	elementPageSize: number = 2;

	@Input()
	translateNone?: string;

	@Input()
	showUsername?: boolean;

	@ViewChild("dataView")
	dataView!: DataViewComponent<stages.userassignments.UserAssignment>;

	@ViewChild("commentInput", { read: ElementRef })
	commentInput!: ElementRef;

	dataSource!: PageableDataSource<stages.userassignments.UserAssignment>;
	menuItems: MenuItem[] = [];
	commentInputText!: string;
	editedAssignment!: string;
	self$!: Observable<stages.process.ProcessView>;
	Mode = Mode;

	private subscription!: Subscription;

	constructor(
		componentService: ComponentService,
		private card: CardComponent,
		private route: ActivatedRoute,
		private viewService: ViewService,
		private userAssignmentsService: UserAssignmentsService,
		private mutexService: MutexService,
		private dialogService: DialogService,
		private router: Router,
		private chRef: ChangeDetectorRef,
	) {
		super(componentService);
	}

	ngOnInit(): void {
		this.self$ = this.viewService.awaitSelfElementObservable();
		this.dataSource = new PageableDataSource(
			new UserAssignmentsStore(
				lastValueFrom(this.self$.pipe(take(1))),
				this.userAssignmentsService,
				this.path ? this.path : null,
				this.pageSize,
				this.elementPageSize,
			),
			this.mutexService,
			this.router,
			this.route,
		);

		// prevent ExpressionChangedAfterItHasBeenCheckedError
		setTimeout(() => {
			this.subscription = this.self$.subscribe((view) => {
				this.card.menuItems = [
					{
						name: "add",
						iconClass: "ico ico-add",
						disabled: () => {
							return this.isCollected() || !view.userContainer || !view.userContainer.actions.AddUserAssignmentPolicy;
						},
						on: () => {
							this.dataView.enterAdd(false);
						},
					},
					{
						name: "delete",
						iconClass: "ico ico-delete",
						disabled: () => {
							return (
								this.isCollected() || !view.userContainer || !view.userContainer.actions.DeleteUserAssignmentPolicy
							);
						},
						on: () => {
							this.dataView.enterDelete();
						},
					},
				];

				this.menuItems = [
					{
						name: "process.element.userassignments.deputy",
						isSwitch: true,
						disabled: (userAssignment: stages.userassignments.UserAssignment) => {
							return this.isCollected() || !userAssignment.actions.SetUserAssignmentDeputyPolicy;
						},
						model: "elements[0].deputy",
						getValue: (assignment: stages.userassignments.UserAssignment) => {
							return assignment.elements[0].deputy;
						},
						onValueChange: (value: boolean, assignment: stages.userassignments.UserAssignment) => {
							this.userAssignmentsService
								.updateDeputy(
									this.route.snapshot.paramMap.get("type")!,
									// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
									(view as any).elementId,
									this.route.snapshot.paramMap.get("identity")!,
									assignment.elements[0].guid,
									this.route.snapshot.paramMap.get("workspaceId")!,
									this.route.snapshot.paramMap.get("processVersion")!,
									value,
								)
								.then(() => {
									assignment.elements[0].deputy = value;
								});
						},
					},
					{
						isSeparator: true,
					},
					{
						name: "editComment",
						iconClass: "ico ico-edit",
						disabled: (userAssignment: stages.userassignments.UserAssignment) => {
							return this.isCollected() || !userAssignment.actions.SetUserAssignmentCommentPolicy;
						},
						on: (assignment: stages.userassignments.UserAssignment) => {
							this.dataView.mode = Mode.EDIT;
							this.editedAssignment = assignment.user.id;
							this.commentInputText = assignment.elements[0].comment!;

							this.chRef.detectChanges();
							this.commentInput.nativeElement.focus();
						},
					},
					{
						name: "delete",
						iconClass: "ico ico-delete",
						disabled: (userAssignment: stages.userassignments.UserAssignment) => {
							return this.isCollected() || !userAssignment.actions.DeleteUserAssignmentPolicy;
						},
						on: async (deletedAssignment: stages.userassignments.UserAssignment) => {
							if (
								await this.dialogService.confirm(
									"process.element.userassignments.unassign.confirm",
									{ name: deletedAssignment.user.fullname },
									"delete",
									"cancel",
									true,
								)
							) {
								const guid: string = deletedAssignment.elements[0].guid;
								this.dataView.dataSource.delete([guid], guid);
							}
						},
					},
				];

				this.dataView.buttons = [
					{
						class: "button sm cancel",
						translate: "cancel",
						click: () => {
							this.dataView.mode = Mode.VIEW;
						},
						visible: () => {
							return this.dataView.mode === Mode.DELETE;
						},
						disabled: () => {
							return false;
						},
					},
					{
						class: "button sm delete",
						translate: "delete",
						click: () => {
							void this.dataView.dataSource.delete(this.dataView.selection.selected, "userAssignments");
							this.dataView.mode = Mode.VIEW;
						},
						visible: () => {
							return this.dataView.mode === Mode.DELETE;
						},
						disabled: () => {
							return this.dataView.selection.isEmpty();
						},
					},
				];
			});
		});
	}

	ngDestroy(): void {
		if (this.dataSource) {
			this.dataSource.disconnect();
		}

		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	isCollected(): boolean {
		return this.path !== undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	hasEmail(user: any): boolean {
		return user.emailAddress !== null;
	}

	isCreatorKnown(userAssignment: stages.userassignments.UserAssignment): boolean {
		return userAssignment.creator !== null && userAssignment.creationDate !== null;
	}

	onCommentInputBlur(): void {
		this.dataView.mode = Mode.VIEW;
		this.editedAssignment = "";
	}

	async onCommentInputKeyUp(
		event: KeyboardEvent,
		assignment: stages.userassignments.UserAssignment,
		elementId: string,
	): Promise<void> {
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		if (event.keyCode === 27) {
			this.onCommentInputBlur();
			this.commentInputText = "";
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		} else if (event.keyCode === 13) {
			await this.userAssignmentsService.updateComment(
				this.route.snapshot.paramMap.get("type")!,
				elementId,
				this.route.snapshot.paramMap.get("identity")!,
				assignment.elements[0].guid,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				this.commentInputText,
			);
			assignment.elements[0].comment = this.commentInputText;
			this.commentInputText = "";
			this.dataView.mode = Mode.VIEW;
			this.onCommentInputBlur();
		}
	}

	static getId(userAssignment: stages.userassignments.UserAssignment): string {
		return userAssignment.elements[0].guid;
	}

	static isDisabled(): boolean {
		return false;
	}
}
