import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FileService } from "files/files.service";

@Injectable({ providedIn: "root" })
export class UrlPropertiesResolver implements Resolve<stages.file.UrlProperties> {
	constructor(private fileService: FileService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.file.UrlProperties> {
		return this.fileService.getUrlProperties(
			route.paramMap.get("fileId")!,
			route.paramMap.get("workspaceId")!,
			route.paramMap.get("processVersion")!,
		);
	}
}
