import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import * as ResizeSensor from "css-element-queries/src/ResizeSensor";

@Component({
	selector: "stages-fit-to-page",
	templateUrl: "./fit-to-page.component.html",
	styleUrls: ["./fit-to-page.component.scss"],
})
export class FitToPageComponent implements AfterViewInit, OnDestroy {
	private _fitToPage: boolean = true;

	@HostBinding("class.zoom") bindStyle: boolean = !this._fitToPage;

	@Input("fitToPage")
	set fitToPage(value: boolean) {
		this._fitToPage = value;
		this.bindStyle = !this._fitToPage;
		this.update();
	}

	@ViewChild("contentWrapper", { static: true })
	contentWrapper!: ElementRef;

	resizeSensor?: ResizeSensor.default;

	fitToPageScale?: SafeStyle;

	constructor(private hostElement: ElementRef, private sanitizer: DomSanitizer) {}

	ngAfterViewInit(): void {
		this.update();
		this.resizeSensor = new ResizeSensor.default(this.hostElement.nativeElement, () => {
			this.update();
		});
	}

	ngOnDestroy(): void {
		if (this.resizeSensor) {
			this.resizeSensor.detach();
		}
	}

	private update(): void {
		const scalingFactor = this._fitToPage ? this.calculateScalingFactor() : 1;
		const scale = scalingFactor < 1 ? scalingFactor : 1;
		this.fitToPageScale = this.sanitizer.bypassSecurityTrustStyle(
			`translate(-50%, -50%) scale(${scale}) translate(50%, 50%)`,
		);
	}

	private calculateScalingFactor(): number {
		return determineWidth(this.hostElement) / determineWidth(this.contentWrapper);
	}
}

function determineWidth(elementRef: ElementRef): number {
	const htmlElem = elementRef.nativeElement as HTMLElement;
	return htmlElem.offsetWidth;
}
