<header class="dialog-header" *ngIf="showHeader">
	<div></div>
	<button [attr.title]="'close' | translate" [attr.aria-label]="'close' | translate" class="action">
		<i class="ico ico-close" (click)="cancel()"></i>
	</button>
</header>

<form [formGroup]="form" style="overflow-x: hidden; overflow-y: auto" novalidate>
	<h1 translate="login.to" [translateParams]="{ name: getCmsTypeMessageKey() | translate }"></h1>

	<div class="form">
		<div class="form-group">
			<div class="input-container sm required">
				<label class="input-label" class="input-label" for="cmsauth-user" translate="files.cms.auth.username"></label>
				<input
					id="cmsauth-user"
					type="text"
					formControlName="username"
					class="text fullsize"
					tabindex="1"
					required
					(change)="clearLoginError()"
					(blur)="form.get('username')!.markAsTouched()"
				/>
				<!-- Cannot use stagesAutoFocus on input field because it causes an ExpressionChangedAfterItHasBeenCheckedError
					     when the template is used in a new dialog (e.g. with lock file operation) and the back button is pressed. -->
				<div class="info">
					<div class="errors" *ngIf="form.get('username')!.dirty || form.get('username')!.touched">
						<div
							*ngIf="form.get('username')!.errors && form.get('username')!.errors!['required']"
							class="error"
							role="alert"
							translate="input.required"
						></div>
						<div
							*ngIf="form.get('username')!.errors && form.get('username')!.errors!['maxlength']"
							class="error"
							role="alert"
							[translate]="'input.maxLength'"
							[translateParams]="{ length: '255' }"
						></div>
					</div>
				</div>
			</div>
		</div>
		<div class="form-group">
			<div class="input-container sm required">
				<label class="input-label" translate="files.cms.auth.password"></label>
				<input
					id="cmsauth-password"
					type="password"
					formControlName="password"
					class="text fullsize"
					tabindex="2"
					required
					(change)="clearLoginError()"
				/>
				<div class="info">
					<div class="errors" *ngIf="form.get('password')!.dirty || form.get('password')!.touched">
						<div
							*ngIf="form.get('password')!.errors && form.get('password')!.errors!['required']"
							class="error"
							role="alert"
							translate="input.required"
						></div>
						<div
							*ngIf="form.get('password')!.errors && form.get('password')!.errors!['maxlength']"
							class="error"
							role="alert"
							translate="input.maxLength"
							[translateParams]="{ length: 255 }"
						></div>
						<div *ngIf="authRetryFailed" class="error" role="alert" translate="login.error.loginincorrect"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>

<footer>
	<div class="buttons">
		<button class="button sm cancel" translate="cancel" (click)="cancel()" tabindex="4"></button>
		<button class="button sm ok" class="button sm ok" [disabled]="!form.valid || loading" (click)="ok()" tabindex="3">
			<span translate="send" *ngIf="!loading"></span>
			<span><stages-spin size="sm" *ngIf="loading"></stages-spin></span>
		</button>
	</div>
</footer>
