/* eslint-disable @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".*/
import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { ImagePluginComponent } from "common/editor/image-plugin/image-plugin.component";
import { FormService } from "common/form/form.service";
import { RichTextInputContext } from "common/form/input/types/rich-text-input";
import { PreferencesService } from "core/preferences.service";
import { ViewService } from "core/view.service";
import { HtmlTemplatePluginComponent } from "process/description/htmltemplate-plugin/htmltemplate-plugin.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";
import { EditService } from "process/element/edit/edit.service";
import { PropertyService } from "process/element/properties/property.service";

type Attribute = stages.core.Attribute;
type AttributeType = stages.core.AttributeType;
type EditableAttribute = stages.process.EditableAttribute;
type PropertyValueTypeNonNull = stages.core.format.DisplayDescription | boolean | number | string;
type PropertyValueType = PropertyValueTypeNonNull | null | undefined;

interface PropertiesProcessView extends stages.process.ProcessView {
	[key: string]: any;
	_attributes?: any;
	attribute?: any;
	moduleInstallation?: stages.management.modules.ModuleInstallation;
}

@Component({
	selector: "stages-process-element-properties",
	templateUrl: "./properties.component.html",
	styleUrls: ["./properties.component.scss"],
})
export class PropertiesComponent implements OnInit {
	@Input() translateNone?: string;
	@Input() propertyList?: any;
	@Input() currentElement!: PropertiesProcessView;

	propertyListWithoutCategories: any;

	isSaveInProgress = false;
	editOn = false;
	categoriesOpenCloseState: StringToBoolean = {};
	private preferencesPrefix!: string;

	editableAttributes!: Attribute[];
	editableAttributeTypes!: AttributeType[];

	menuItems: MenuItem[] = [];
	form!: FormGroup;

	constructor(
		private route: ActivatedRoute,
		private $translate: TranslateService,
		private editService: EditService,
		private formService: FormService,
		private mutexService: MutexService,
		private viewService: ViewService,
		private card: CardComponent,
		private preferencesService: PreferencesService,
		private propertyService: PropertyService,
	) {}

	async ngOnInit(): Promise<void> {
		this.preferencesPrefix = this.getPreferencesPrefix();
		this.form = new FormGroup({});

		for (const property of this.propertyList) {
			if (property.type === "category") {
				const openState = await this.preferencesService.getPreference(
					getPreferencesKey(this.preferencesPrefix, property.ident),
					{ open: true },
				);
				this.categoriesOpenCloseState[property.ident] = openState.open;
			}
		}

		this.propertyListWithoutCategories = getPropertiesWithoutCategories(this.propertyList);

		if (this.card) {
			this.card.menuItems = this.createMenuItems(this.editOn);
		}
	}

	isSomeEditAllowed(attributesMap: any): boolean {
		if (
			attributesMap &&
			this.propertyListWithoutCategories.filter(this.someEditablePropertiesFilterFunction).length > 0
		) {
			for (const ident of Object.keys(attributesMap)) {
				if (attributesMap[ident].allowedOperations.MODIFY) {
					return true;
				}
			}
		}
		return false;
	}

	private someEditablePropertiesFilterFunction = (property: any): boolean => {
		return property.isAttribute;
	};

	onEdit(): void {
		this.editService
			.getEditableAttributes(
				this.route.snapshot.paramMap.get("type")! as any,
				this.route.snapshot.paramMap.get("identity")!,
				"ALL",
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
			)
			.then((editableAttributesArray: EditableAttribute[]) => {
				if (editableAttributesArray) {
					const availableProperties: Array<string> = this.propertyListWithoutCategories.map((p: any) => p.ident);
					this.editableAttributes = [];
					this.editableAttributeTypes = [];
					editableAttributesArray.forEach((editableAttribute) => {
						const typeIdent = editableAttribute.attribute.typeIdent;
						if (availableProperties.indexOf(typeIdent) >= 0) {
							this.editableAttributes.push(editableAttribute.attribute);
							this.editableAttributeTypes.push(editableAttribute.attributeType);
						}
					});
					this.setEditorActive(true);
				} else {
					this.closeEditMode();
				}
			});
	}

	setEditorActive(isEditorActive: boolean): void {
		this.editOn = isEditorActive;
		this.card.menuItems = this.createMenuItems(isEditorActive);
	}

	hasCategories(): boolean {
		for (const property of this.propertyList) {
			if (property.type === "category") {
				return true;
			}
		}
		return false;
	}

	getVisiblePropertiesCount(category: any): number {
		const filteredProperties = category.properties.filter(this.show);
		return filteredProperties.length;
	}

	isCategoryVisible(category: any): boolean {
		if (category.ident !== "module" && this.getVisiblePropertiesCount(category) > 0) {
			return true;
		}
		return (
			category.ident === "module" &&
			this.currentElement.moduleInstallation !== undefined &&
			this.currentElement.moduleInstallation != null
		);
	}

	isReadable(property: any): boolean {
		if (property.isAttribute) {
			return this.currentElement.attribute && this.currentElement.attribute[property.ident];
		}
		return true;
	}

	getContext(): RichTextInputContext {
		const containerProcess =
			this.currentElement.type.ident !== "process" ? this.currentElement.process : this.currentElement;
		return {
			beanType: this.currentElement.type.ident,
			beanId: this.currentElement.id,
			beanIdentity: this.currentElement.identity,
			pv: containerProcess.pv,
			processTypeIdent: this.currentElement.processType,
			workspaceId: containerProcess.workspaceId,
			pluginRegistry: {
				stagesimage: ImagePluginComponent,
				stageslink: LinkPluginComponent,
				stageshtmltemplate: HtmlTemplatePluginComponent,
			},
		};
	}

	async onSave(): Promise<void> {
		const attributeLookUp = new Map(
			this.editableAttributes.map((attribute): [string, Attribute] => [attribute.typeIdent, attribute]),
		);

		Object.keys(this.form.controls).forEach((controlName) => {
			const control = this.form.controls[controlName];
			if (typeof control.value === "string" && control.value.trim() === "") {
				// workaround for missing autotrim in angular and for the problem that deletion of a number from a number input field sets the value to "" instead to null
				control.setValue(null, { onlySelf: false, emitEvent: true });
			}
			control.markAsTouched();
			if (this.form.valid) {
				const attribute = attributeLookUp.get(controlName);
				if (attribute) {
					attribute.value = control.value;
				}
			}
		});

		const trimmedAttributesToSave = Array.from(attributeLookUp.values());

		if (this.form.valid) {
			this.form.disable();
			this.isSaveInProgress = true;
			try {
				// the returns are needed such that this call waits for all the promises to be resolved
				// and editing is stopped only when updated values are available,
				// but also editableAttributes are not updated before edit is stopped or validation fails to prevent
				// problematic reinitialization of description editors in edit-attributes component
				await this.mutexService.invokeWithResult("elementAttributesSave", async () => {
					try {
						const selfWithAttributes = await this.editService.saveAttributes(
							this.route.snapshot.paramMap.get("type")! as any,
							this.route.snapshot.paramMap.get("identity")!,
							"ALL",
							trimmedAttributesToSave,
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("processVersion")!,
						);
						const afterEdit = this.viewService.getSelf(selfWithAttributes);
						for (const key in afterEdit.attributes) {
							if (!this.currentElement.attribute[key]) {
								continue;
							}
							this.currentElement.attribute[key].displayValue = afterEdit.attributes[key].displayValue;
						}
						const perspective = await this.viewService.getPerspective();

						const view: stages.process.View = await this.viewService.forceViewReload(
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("type")!,
							this.route.snapshot.paramMap.get("identity")!,
							perspective,
							this.route.snapshot.paramMap.get("workspaceId")!,
							this.route.snapshot.paramMap.get("processVersion")!,
						);

						this.currentElement = this.viewService.getSelf(view.processView);
						this.closeEditMode();
					} catch (e: unknown) {
						this.formService.setServerSideErrorsOnFormControls(this.form.value);
					}
				});
			} finally {
				this.isSaveInProgress = false;
				this.form.enable();
			}
		}
		this.editableAttributes = trimmedAttributesToSave;
	}

	cancel(): void {
		this.closeEditMode();
		this.createMenuItems(false);
	}

	getNameKey(property: any): string {
		if (property.nameKey) {
			return property.nameKey;
		}
		if (property.isAttribute) {
			return "attribute." + (property.ident as string);
		}
		return "element." + (property.ident as string);
	}

	getLink(property: any): any[] {
		const linkable = this.currentElement[property.ident];
		if (property.isLinkable && linkable) {
			return [
				"/",
				"workspace",
				linkable.workspaceId,
				linkable.process.pv,
				"process",
				linkable.type.ident,
				linkable.identity,
			];
		}
		return ["."];
	}

	getChangeMarker(property: any): any {
		if (property.isAttribute && this.currentElement.attribute && this.currentElement.attribute[property.ident]) {
			return this.currentElement.attribute[property.ident].changeMarker;
		}

		return null;
	}

	getDisplayValue(property: any): PropertyValueType {
		if (!this.hasValue(property)) {
			return this.$translate.instant("none");
		}
		return this.getValue(property, true);
	}

	getHTMLValue(property: any): string {
		if (!this.hasHtmlValue(property)) {
			return this.$translate.instant("none");
		}
		const value = this.getValue(property, true);
		if (value !== undefined && value !== null && value.toString().length > 0) {
			return value.toString();
		}
		return this.$translate.instant("none");
	}

	getValue(property: any, useDisplayValue: boolean): PropertyValueType {
		return this.propertyService.getValue(this.currentElement, property, useDisplayValue);
	}

	showTranslateNone(propertyList: any): boolean {
		for (const element of propertyList) {
			if (element.type === "category" && element.properties) {
				for (const property of element.properties) {
					if (this.show(property)) {
						return false;
					}
				}
			}

			if (element.type === "element" && this.show(element)) {
				return false;
			}
		}

		return true;
	}

	show = (property: any): boolean => {
		return (
			this.isReadable(property) && (this.hasValue(property) || property.showEmpty || !!this.getChangeMarker(property))
		);
	};

	private hasValue(property: any): boolean {
		const value = this.getValue(property, false);
		const hasValue = !(
			value === null ||
			value === undefined ||
			value === 0 ||
			this.isEmptyString(value) ||
			this.isEmptyDescription(value)
		);
		return hasValue;
	}

	private isEmptyString(value: PropertyValueTypeNonNull): boolean {
		return value.hasOwnProperty("length") && (value as string).trim().length === 0;
	}

	private isEmptyDescription(value: PropertyValueTypeNonNull): boolean {
		return value.hasOwnProperty("html") && (value as stages.core.format.DisplayDescription).html.trim().length === 0;
	}

	toggle(category: any): void {
		this.categoriesOpenCloseState[category.ident] = !this.categoriesOpenCloseState[category.ident];
		this.preferencesService.setPreference(getPreferencesKey(this.preferencesPrefix, category.ident), {
			open: this.categoriesOpenCloseState[category.ident],
		});
	}

	isCategoryOpen(category: any): boolean {
		return this.categoriesOpenCloseState[category.ident];
	}

	hasHtmlValue(property: any): boolean {
		if (!!this.getChangeMarker(property)) {
			return true;
		}

		if (!property.isAttribute && this.currentElement[property.ident] && this.currentElement[property.ident].html) {
			return true;
		}

		if (property.isAttribute) {
			const value = this.getValue(property, false);
			if (value !== null && value !== undefined && value.hasOwnProperty("html")) {
				return true;
			}
		}

		return false;
	}

	getVisiblePropertyList(): any[] {
		return (this.propertyList as any[]).filter((category) => this.isCategoryVisible(category));
	}

	getPreferencesPrefix(): string {
		return this.currentElement.processType + "_" + this.currentElement.type.ident;
	}

	closeEditMode(): void {
		this.setEditorActive(false);
		this.form = new FormGroup({});
	}

	private createMenuItems(editOn: boolean): MenuItem[] {
		return [
			{
				name: "edit",
				iconClass: "ico ico-edit",
				disabled: () => {
					return !this.isSomeEditAllowed(this.currentElement._attributes) || editOn;
				},
				on: () => {
					this.onEdit();
				},
			},
		];
	}
}

function getPreferencesKey(metamodelIdent: string, categoryIdent: string): string {
	return "element-properties_" + metamodelIdent + "_" + categoryIdent;
}

function getPropertiesWithoutCategoriesInternal(unfilteredProperties: any, filteredProperties: Array<any>): Array<any> {
	for (const property of unfilteredProperties) {
		if (property.type === "category") {
			getPropertiesWithoutCategoriesInternal(property.properties, filteredProperties);
		} else {
			filteredProperties.push(property);
		}
	}
	return filteredProperties;
}

function getPropertiesWithoutCategories(unfilteredProperties: any): Array<any> {
	return getPropertiesWithoutCategoriesInternal(unfilteredProperties, new Array<any>());
}
