import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpService } from "core/http.service";
import { MainService } from "core/main.service";
import { NotificationService } from "core/notification.service";
import { lastValueFrom, Observable, Subject } from "rxjs";

declare interface LogoutResult {
	externalLogoutUrl?: string;
}

@Injectable({ providedIn: "root" })
export class AuthenticationService {
	private parallelAuth: Promise<void> | null = null;

	private _triggerInvalidateCaches: Subject<void> = new Subject();

	get invalidateCaches$(): Observable<void> {
		return this._triggerInvalidateCaches.asObservable();
	}

	constructor(
		// "HttpService.invalidateAll()" is needed
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		private readonly httpService: HttpService,
		private readonly httpClient: HttpClient,
		private readonly router: Router,
		private readonly mainService: MainService,
		private readonly notificationService: NotificationService,
	) {}

	/**
	 * Tries to authenticate the user based on the provided username and password.
	 *
	 * @param username
	 *            Username.
	 * @param password
	 *            Password.
	 * @param rememberUser
	 *            If set to true, a cookie will be stored, which enables the user to stay authenticated, until he/she explicitly signs out.
	 * @return promise
	 */
	async passwordAuthentication(username: string, password: string, rememberUser: boolean): Promise<void> {
		const params: pkit.auth.AuthenticationCredentials = {
			password: password,
			rememberUser: !!rememberUser,
			username: username,
		};
		const promise = this.httpService.post("rest/authentication/password", params);
		return promise.then((response) => {
			this.invalidateAllCaches();
			this.notificationService.init();
			return response;
		});
	}

	/**
	 * Tries to authenticate the stages user automatically (e.g. Spnego, Cookie etc.)
	 *
	 * @return Promise for the asynchronous XHR or NULL, if there is another request pending.
	 */
	async autoAuthentication(manualAnonymous = false): Promise<void> {
		if (!this.parallelAuth) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			const params: any = {};
			if (manualAnonymous) {
				params.manualAnonymous = manualAnonymous;
			}
			const promise = this.httpService.get("rest/authentication/auto", params);
			this.parallelAuth = promise.then(
				(response) => {
					this.parallelAuth = null;
					this.invalidateAllCaches();
					this.notificationService.init();
					return response;
				},
				async (response) => {
					this.parallelAuth = null;
					return Promise.reject(response);
				},
			);
			return this.parallelAuth;
		}
		return this.parallelAuth;
	}

	async logout(redirectToQueryParam: string | null): Promise<void> {
		try {
			const res = await lastValueFrom(
				this.httpClient.request("DELETE", "rest/authentication/logout", {
					observe: "response",
				}),
			);
			const logoutResult = res.body as LogoutResult;
			if (logoutResult.externalLogoutUrl) {
				window.location.href = logoutResult.externalLogoutUrl;
			} else {
				void this.router.navigate(["login"], {
					queryParams: {
						redirectTo: redirectToQueryParam,
					},
				});
			}
		} finally {
			this.notificationService.destroy();
			this.invalidateAllCaches();
		}
	}

	/**
	 * To be used only for special cases, like auto Kerberos (SPNEGO) reauthentication.
	 */
	invalidateAllCaches(): void {
		this.httpService.invalidateAll();
		this.mainService.applicationState.navigationDrawerOpen = false;
		this._triggerInvalidateCaches.next();
	}

	needsAuthentication(errorResponse: unknown): boolean {
		if (errorResponse instanceof HttpErrorResponse) {
			switch (errorResponse.status) {
				case 401:
					return true;
				case 403:
					// return true for 403, if not a stages exception.
					return errorResponse.error ? errorResponse.error.stagesExceptionType === undefined : true;
				default:
					return false;
			}
		}
		return false;
	}
}
