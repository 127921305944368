import { ChangeDetectorRef, Component, ComponentRef, NgZone, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { SlotService } from "common/slot.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { ComponentType } from "process/view/type.interface";

@Component({
	selector: "stages-responsive-widget-container",
	templateUrl: "responsive-widget-container.component.html",
	providers: [SlotService],
})
export class ResponsiveWidgetContainerComponent extends BaseComponent implements OnInit, OnDestroy {
	private viewContainerRef!: ViewContainerRef;

	private queryToComponentMap: {
		query: MediaQueryList;
		component: ComponentType;
	}[] = [];

	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private currentComponentRef?: ComponentRef<{}>;

	constructor(
		componentService: ComponentService,
		private zone: NgZone,
		private changeDetectorRef: ChangeDetectorRef,
		slotService: SlotService,
	) {
		super(componentService, slotService);
	}

	protected override onSlotCreated(slotName: string, viewContainerRef: ViewContainerRef): void {
		this.viewContainerRef = viewContainerRef;
		this.update();
	}

	ngOnInit(): void {
		if (!this.type || !this.type.components) {
			return;
		}

		this.type.components.forEach((component) => {
			if (component.media) {
				const mediaQueryList = window.matchMedia(component.media);
				// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				mediaQueryList.addListener(this.windowResizeListener);
				this.queryToComponentMap.push({
					query: mediaQueryList,
					component: component,
				});
			}
		});
	}

	override ngOnDestroy(): void {
		const currentComponentRef = this.currentComponentRef;
		if (currentComponentRef) {
			currentComponentRef.destroy();
		}

		for (const queryToComponentEntry of this.queryToComponentMap) {
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			queryToComponentEntry.query.removeListener(this.windowResizeListener);
		}
	}

	update(): void {
		let foundMatchingQuery = false;

		for (const queryToComponentEntry of this.queryToComponentMap) {
			const query = queryToComponentEntry.query;
			const component = queryToComponentEntry.component;
			if (query.matches && !foundMatchingQuery) {
				this.show(component);
				foundMatchingQuery = true;
			}
		}

		if (!foundMatchingQuery) {
			const defaultComponent = this.getDefaultComponent();
			if (defaultComponent) {
				this.show(defaultComponent);
			}
		}
	}

	private show(component: ComponentType): void {
		if (this.viewContainerRef) {
			this.viewContainerRef.clear();
			this.currentComponentRef = this.componentService.appendComponent(component, this.viewContainerRef);
		}
	}

	private getDefaultComponent(): ComponentType | undefined {
		if (!this.type || !this.type.components) {
			return undefined;
		}
		return this.type.components.find((comp) => !comp.media);
	}

	windowResizeListener = (): void => {
		this.zone.run(() => {
			this.update();
			this.changeDetectorRef.detectChanges();
		});
	};
}
