import { Component, OnDestroy, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { maxValidator } from "common/form/max.directive";
import { KeyboardService } from "common/keyboard.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { ViewService } from "core/view.service";
import { AddService } from "process/element/add/add.service";
import { Sequence } from "process/element/sequence.interface";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

type ProcessTreeItem = stages.process.ProcessTreeItem;

@Component({
	selector: "stages-add-sequence-dialog",
	templateUrl: "add-sequence-dialog.component.html",
})
export class AddSequenceDialogComponent implements OnDestroy {
	formGroup: FormGroup = new FormGroup({});
	controlsLength: number = 0;
	maxLength = "255";
	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	self$: Observable<ProcessTreeItem>;
	sequence$: Observable<Sequence>;
	private subscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private mutexService: MutexService,
		private addService: AddService,
		private viewService: ViewService,
		private keyboardService: KeyboardService,
	) {
		this.self$ = viewService.awaitSelfElementObservable();
		this.sequence$ = this.route.data.pipe(map((data) => data.sequence));
		this.subscription = this.sequence$.subscribe((sequence) => {
			this.add(sequence);
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	close(): void {
		this.dialog.close();
	}

	save(self: ProcessTreeItem, sequence: Sequence): void {
		if (this.formGroup.valid) {
			const names = this.getNamesFromInputs();
			if (names.length === 0) {
				this.dialog.close();
				return;
			}

			const origin = sequence.level === "same" ? self.parent : self;
			const originViewable = origin as stages.process.ViewableElement;
			const predecessor = sequence.level === "same" && self.type.ident === sequence.targetType ? self : null;
			const predecessorViewable = predecessor as stages.process.ViewableElement;

			this.mutexService.invoke("mutexAddSequence", async () => {
				await this.addService.addSequence(
					originViewable,
					predecessorViewable,
					names,
					sequence.associationType,
					sequence.targetType,
					sequence.targetSubtype,
					sequence.sourceRole,
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
				);
				this.viewService.refreshView(
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
				);
				this.dialog.close();
			});
		}
	}

	add(sequence: Sequence): void {
		this.addNewFormControl(sequence);
	}

	private addNewFormControl(sequence: Sequence): void {
		const controlName = sequence.targetType + "_control_" + this.controlsLength.toString();
		this.formGroup.addControl(controlName, new FormControl("", [maxValidator(255), Validators.pattern(/^[^|]+$/)]));
		this.controlsLength++;
	}

	getFormControlName(i: number, sequence: Sequence): string {
		return sequence.targetType + "_control_" + i.toString();
	}

	getFormControl(i: number, sequence: Sequence): AbstractControl {
		return this.formGroup.controls[this.getFormControlName(i, sequence)];
	}

	getFormControls(): AbstractControl[] {
		const controls: AbstractControl[] = [];
		for (const control in this.formGroup.controls) {
			if (this.formGroup.controls[control]) {
				controls.push(this.formGroup.controls[control]);
			}
		}
		return controls;
	}

	getFormControlErrors(i: number, sequence: Sequence): string[] {
		const controlName: string = this.getFormControlName(i, sequence);
		const control: AbstractControl = this.formGroup.controls[controlName];

		const errors = control.errors;
		const keys = new Array();

		if (errors) {
			keys.push(Object.keys(errors));
		}

		return keys;
	}

	getMaxInputLength(i: number, sequence: Sequence): string {
		const control = this.getFormControl(i, sequence);
		const actualLength: string = control.value.length.toString();

		return actualLength.toString() + "/" + this.maxLength.toString();
	}

	getErrors(): string[] {
		const errors = this.formGroup.errors;
		const keys = new Array();

		if (errors) {
			keys.push(Object.keys(errors));
		}

		return keys;
	}

	onKeyDown(event: KeyboardEvent, self: ProcessTreeItem, sequence: Sequence, index: number): void {
		if (this.isLastInputControl(index) && this.keyboardService.isKeyWithNoModifiers(event, "Tab")) {
			this.add(sequence);
		} else if (this.keyboardService.isKeyWithNoModifiers(event, "Enter")) {
			this.save(self, sequence);
		}
	}

	private isLastInputControl(index: number): boolean {
		return index === this.controlsLength - 1;
	}

	getNamesFromInputs(): string[] {
		const names: string[] = [];
		for (const controlName in this.formGroup.controls) {
			if (controlName && this.formGroup.controls[controlName].value) {
				names.push(this.formGroup.controls[controlName].value);
			}
		}
		return names;
	}
}
