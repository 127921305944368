<header class="group-header" (click)="toggleGroupExpanded()" *ngIf="showEmpty || items.length > 0">
	<div class="list-header-with-counter">
		<h3>{{ header }}</h3>
		<span *ngIf="hasCounter && activeCounter !== undefined" class="counter active-counter" [ngClass]="counterClass!">{{ activeCounter }} / {{ items.length }}</span>
		<span *ngIf="hasCounter && activeCounter === undefined" class="counter" [ngClass]="counterClass!">{{ items.length }}</span>
	</div>
	<stages-button-expand-collapse [isCollapsed]="!isExpanded()" [classes]="'action menu-button'">
	</stages-button-expand-collapse>
</header>
<div *ngIf="isExpanded()">
	<ul class="list" [ngClass]="listClass!">
		<li *ngFor="let item of shownItems; let i = index" class="list-item" [ngClass]="itemClass!">
			<ng-container
				*ngTemplateOutlet="optionTemplateRef ?? listItemTemplate!; context: { $implicit: item, index: i }"
			></ng-container>
		</li>
		<li class="list-item" *ngIf="hasMore()">
			<a class="label clickable" (click)="loadMore()">
				<div class="text center">
					<span class="show-more center" translate="more"></span>
				</div>
			</a>
		</li>
	</ul>
</div>
