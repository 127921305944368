<button
	[attr.title]="
		accessibilityTitle ? accessibilityTitle : isCollapsed ? ('expand' | translate) : ('collapse' | translate)
	"
	[attr.aria-label]="
		accessibilityTitle ? accessibilityTitle : isCollapsed ? ('expand' | translate) : ('collapse' | translate)
	"
	[attr.class]="classes"
	(click)="emitPressedButton()"
>
	<i class="ico" [ngClass]="isCollapsed ? 'ico-chevron-down' : 'ico-chevron-up'"></i>
</button>
