import { AssociationSource } from "common/associations/association-browser.service";
import { Group } from "common/associations/association-group.service";
import { Association } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { keyBy } from "lodash";
import { ProcessComplianceAssociationService } from "process/compliance/associations/process-compliance-association.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type ProcessTreeItem = stages.process.ProcessTreeItem;
type ProcessComplianceAssociationGroup = stages.compliance.ProcessComplianceAssociationGroup;
type ProcessView = stages.process.ProcessView;
type ViewableProcess = stages.process.ViewableProcess;
type ViewableType = stages.process.ViewableType;

type TargetElement = stages.compliance.TargetElement;

export class ProcessComplianceAdapter implements AssociationSource<TargetElement> {
	constructor(private element: ProcessView) {}

	get associations(): Record<string, stages.compliance.ProcessComplianceAssociationGroup> {
		return keyBy(this.element.processComplianceContainer, "groupKey");
	}

	updateAssociations(groupKey: string, processComplianceContainer: ProcessComplianceAssociationGroup[]): void {
		const groupToUpdate = this.element.processComplianceContainer!.find((g) => g.groupKey === groupKey);
		const newGroup = processComplianceContainer.find((g) => g.groupKey === groupKey);
		if (groupToUpdate && newGroup) {
			groupToUpdate.list = newGroup.list;
		}
	}

	get parent(): ProcessTreeItem | undefined {
		return this.element.parent;
	}

	get process(): ViewableProcess {
		return this.element.process;
	}

	get type(): ViewableType {
		return this.element.type;
	}

	get id(): string {
		return this.element.id;
	}
}

export class ProcessComplianceAssociationStore implements AssociationStore<ProcessComplianceAdapter, TargetElement> {
	constructor(
		private viewService: ViewService,
		private associationService: ProcessComplianceAssociationService,
		private readonly processElementsResource: ProcessElementsResource,
	) {}

	getAddMenuEntryNameKey(group: Group<TargetElement>): string {
		return "compliance.references.addForProcess";
	}

	getAddMenuEntryNameKeyParam(_group: Group<TargetElement>): string | undefined {
		return undefined;
	}

	getAddMenuEntryNameKeyParamValue(group: Group<TargetElement>): string | undefined {
		return group.name;
	}

	getInputClasses(
		typeIdent: string | undefined,
		isDependentElement: boolean,
		subtypeIdent: string | null,
		additionalClasses?: string | undefined,
	): string[] {
		if (typeIdent) {
			// Icon for search results dropdown items
			return this.viewService.getIconClasses(
				{
					ident: typeIdent,
					subtypeIdent: subtypeIdent,
				},
				false,
				isDependentElement,
				additionalClasses,
			);
		}
		// Icon for search input text box
		return ["ico", "ico-compliance"];
	}

	getIconClasses(targetElement: TargetElement, isDependentElement: boolean, additionalClasses?: string | undefined): string[] {
		return this.viewService.getIconClasses(targetElement.type, targetElement.index, isDependentElement, additionalClasses);
	}

	getLabelClasses(
		_sourceElement: ProcessComplianceAdapter,
		_targetElement: TargetElement,
		additionalClasses?: string | undefined,
	): string[] {
		// return targetElement.tailored ? ["tailored"] : [];
		return [];
	}

	getQuickAssignLabelClasses(searchResult: AssocSearchResult, additionalClasses?: string | undefined): string[] {
		return searchResult.tailored ? ["tailored"] : [];
	}

	getActions(association: Association<TargetElement>): StringToBoolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return (association as any).allowedOperations;
	}

	getTargetElementActions(targetElement: TargetElement): StringToBoolean {
		return targetElement.actions;
	}

	getSourceElement(
		type: string,
		identity: string,
		workspaceId: string,
		pv: string,
	): Observable<ProcessComplianceAdapter> {
		return this.viewService.awaitSelfElementObservable().pipe(map((self) => new ProcessComplianceAdapter(self)));
	}

	getAdditionalSourceElements(_sourceElement: ProcessComplianceAdapter): ProcessComplianceAdapter[] {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async getParentForNewTargetElement(
		sourceElement: ProcessComplianceAdapter,
		targetType: string,
	): Promise<TargetElement | null> {
		throw new Error("Method not implemented.");
	}

	asTypedId(sourceElement: ProcessComplianceAdapter): stages.core.TypedId {
		return {
			typeIdent: sourceElement.type.ident,
			id: sourceElement.id,
		};
	}

	// rule is currently disabled, because function throws always an error
	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async createAssociationToNewTargetElement(
		sourceElement: stages.core.TypedId,
		newElementParent: TargetElement,
		targetType: string,
		targetSubtype: string | null,
		name: string,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
	): Promise<Association<TargetElement>> {
		throw new Error("Method not implemented.");
	}

	// rule is currently disabled, because function throws always an error
	async createAssociation(
		sourceElement: stages.core.TypedId,
		targetElementId: string,
		targetElementType: string,
		associationType: string,
		sourceRole: string | undefined,
		currentWorkspaceId: string,
		pv: string,
	): Promise<Association<TargetElement>> {
		return this.associationService
			.createAssociation(sourceElement.id, sourceElement.typeIdent, targetElementId, targetElementType)
			.then(asAssociation);
	}

	// rule is currently disabled, because function throws always an error
	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async createCommentOnlyAssociation(
		sourceElement: stages.core.TypedId,
		targetType: string,
		targetSubtype: string | null,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<Association<TargetElement>> {
		throw new Error("Method not implemented.");
	}

	async deleteAssociations(
		associationsToDelete: Association<TargetElement>[],
		workspaceId: string,
		pv: string,
	): Promise<Association<TargetElement>[]> {
		return this.associationService
			.deleteAssociations(
				associationsToDelete.map((assoc) => assoc.id),
				workspaceId,
				pv,
			)
			.then(() => associationsToDelete);
	}

	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		return this.associationService.updateComment(associationId, comment, currentWorkspaceId, pv);
	}

	async searchProcessElements(
		searchTerm: string,
		_targetElementType: string,
		_targetElementSubtypes: string[],
		targetElementsToIgnore: TargetElement[],
		_currentWorkspaceId: string,
		searchWorkspaceId: string,
		pv: string,
	): Promise<AssocSearchResult[]> {
		return this.associationService.searchProcessElements(
			searchTerm,
			targetElementsToIgnore.map((e) => ({ typeIdent: e.type.ident, id: e.id })),
			searchWorkspaceId,
			pv,
		);
	}

	async searchDependentProcessElements(
		_searchTerm: string,
		_targetElementType: string,
		_containerElementId: string,
		_targetElementsToIgnore: TargetElement[],
		_currentWorkspaceId: string,
		_pv: string,
	): Promise<AssocSearchResult[]> {
		return Promise.resolve([]);
	}

	async applyAssociationModifications(
		sourceElement: ProcessComplianceAdapter,
		groupPath: string,
		added: stages.core.TypedId[],
		removed: stages.core.TypedId[],
		modifiedCommentJson: stages.core.model.TypedIdAssociationDetails[],
		processModelWorkspaceId: string,
		pv: string,
		referenceModelWorkspaceId: string,
	): Promise<Association<TargetElement>[]> {
		const sourceElementAfterUpdate = await this.associationService.applyAssociationModifications(
			sourceElement.type.ident,
			sourceElement.id,
			groupPath,
			added,
			removed,
			modifiedCommentJson,
			processModelWorkspaceId,
			referenceModelWorkspaceId,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const selfElement = this.viewService.getSelf(sourceElementAfterUpdate as any);
		const updatedAssocs = keyBy(selfElement.processComplianceContainer, "groupKey")[groupPath];
		sourceElement.updateAssociations(groupPath, selfElement.processComplianceContainer);
		this.viewService.notifyModified();
		return updatedAssocs;
	}

	getLink(association: Association<TargetElement>, pv: string): unknown[] {
		return [
			"/",
			"workspace",
			association.targetElement!.workspaceId,
			pv,
			"process",
			association.targetElement!.type.ident,
			association.targetElement!.identity,
		];
	}

	isSelectableInBrowser(
		existingAssociation: Association<TargetElement> | null,
		sourceElement: ProcessComplianceAdapter,
		targetElement: TargetElement,
		targetSubtypes: string[] | undefined,
	): boolean {
		if (targetElement.type.ident === "process") {
			return false;
		}

		return !existingAssociation || this.getActions(existingAssociation).DELETE;
	}

	reset(): void {
		return;
	}

	async getTree(browseWorkspaceId: string, elementTypes: string[]): Promise<stages.process.TreeElement[]> {
		return this.processElementsResource.getTree(browseWorkspaceId, elementTypes[0], { elementTypes: elementTypes });
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	async rearrangeAssociations(
		currentWorkspaceId: string,
		sortedAssociationIds: string[],
		sortStrategy: stages.core.sort.SortStrategy,
		type: string,
		identity: string,
		path: string,
		groupBy: string,
		pv: string,
	): Promise<Record<string, never>> {
		throw new Error("Method not implemented.");
	}

	getGroupIdentifier(groupPath: string, _groupBy?: string): string {
		return groupPath;
	}
}

function asAssociation(viewableAssociation: stages.process.ViewableAssociation): Association<TargetElement> {
	const assoc = {
		...viewableAssociation,
		actions: viewableAssociation.allowedOperations,
	};
	return assoc as Association<TargetElement>;
}
