import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Group, GroupDefinition } from "common/associations/association-group.service";
import { Container, Groups, SourceElementAndGroup } from "common/associations/association-list.component";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { AssociationService } from "process/associations/association.service";
import { ModelAssociationStore } from "process/associations/model-association.store.logic";
import { AddService } from "process/element/add/add.service";
import { Observable, Subscription } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AssociationTargetLabelService } from "common/associations/association-target-label.service";

type ViewableElement = stages.process.ViewableElement;
type ViewableModuleInstallation = stages.process.ViewableModuleInstallation;
type ViewableProcess = stages.process.ViewableProcess;

@Component({
	selector: "stages-model-association-list",
	templateUrl: "./model-association-list.component.html",
})
export class ModelAssociationListComponent implements OnInit, OnDestroy {
	@Input()
	classes: string = "";

	@Input()
	groups!: GroupDefinition[];

	@Input()
	messageKeyNone?: string;

	@Input()
	editable?: boolean;

	@Input()
	showEmptyGroups = false;

	@Input()
	allowCreateElements?: boolean;

	container$!: Observable<Container<ViewableElement, ViewableElement>>;

	associationStore: ModelAssociationStore;

	targetElementProcessIds!: string[];

	private subscription!: Subscription;

	constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private viewService: ViewService,
		associationService: AssociationService,
		associationTargetLabelService: AssociationTargetLabelService,
		elementAddService: AddService,
	) {
		this.associationStore = new ModelAssociationStore(
			viewService,
			associationService,
			associationTargetLabelService,
			elementAddService,
			mainService,
		);
	}

	ngOnInit(): void {
		this.container$ = this.viewService.awaitSelfElementObservable().pipe(
			//while same id
			distinctUntilChanged((p: stages.process.ProcessView, q: stages.process.ProcessView) => p.id !== q.id),
			map((p) => {
				return {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					sourceElement: p as any,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					associationGroups: this.buildGroups(p as any, this.groups),
					preferencesKey: "modelAssociation.list.state." + p.processType + "." + p.type.ident,
				};
			}),
		);

		this.subscription = this.container$.subscribe((view) => {
			const currentProcess: ViewableProcess = view.sourceElement.process;
			this.targetElementProcessIds = [currentProcess.id];
			const moduleInstallations: ViewableModuleInstallation[] = currentProcess.moduleInstallations;
			moduleInstallations.forEach((moduleInstallation) => {
				if (moduleInstallation.onDemand && moduleInstallation.module) {
					this.targetElementProcessIds.push(moduleInstallation.module.id);
				}
			});
		});
	}

	private buildGroups(self: ViewableElement, groupDefinitions: GroupDefinition[]): Groups<ViewableElement> {
		const result: Groups<ViewableElement> = {};
		groupDefinitions.forEach((gd) => (result[this.getGroupIdentifier(gd)] = this.buildGroup(self, gd)));
		return result;
	}

	private buildGroup(self: ViewableElement, groupDefinition: GroupDefinition): Group<ViewableElement> {
		const associationGroup = self.associations[this.getGroupIdentifier(groupDefinition)];
		return {
			id: groupDefinition.id,
			path: groupDefinition.path,
			name: groupDefinition.name,
			translate: groupDefinition.translate,
			groupBy: groupDefinition.groupBy,
			forcedSort: groupDefinition.forcedSort,
			list: associationGroup.list,
			derived: associationGroup.derived,
			commentSupported: associationGroup.commentSupported,
			commentOnlyAssociationsSupported: true,
			allowAssociateDependentElements: associationGroup.allowAssociateDependentElements,
			sourceDependentTypes: associationGroup.sourceDependentTypes,
			targetType: associationGroup.targetType,
			targetSubtypes: associationGroup.targetSubtypes,
			targetDependentTypes: associationGroup.targetDependentTypes,
			sourceRole: associationGroup.sourceRole,
			type: associationGroup.type,
			actions: associationGroup.allowedOperations,
			subgroups: associationGroup.subgroups ? associationGroup.subgroups : [],
		};
	}

	private getGroupIdentifier(group: GroupDefinition): string {
		return this.associationStore.getGroupIdentifier(group.path, group.groupBy);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	openBrowse(sourceElementAndGroup: SourceElementAndGroup<ViewableElement, ViewableElement>): void {
		const paramMap = this.route.snapshot.paramMap;
		// Do not use source element from container because this as wrong for the case in which we want to create an
		// association from a dependent element.
		const sourceElement = sourceElementAndGroup.sourceElement;

		//handle case where _wv == _vv and _vv is in the URL
		const processVersion =
			sourceElement.process && sourceElement.process.isWorkingRevision ? "_wv" : paramMap.get("processVersion")!;

		const group = sourceElementAndGroup.associationGroup;

		const browseWorkspaceId = group?.limitTo ? this.mainService.secondaryWorkspaceId : paramMap.get("workspaceId")!;

		this.mainService.openPopup(
			[
				"process",
				"browse",
				paramMap.get("type"),
				sourceElement.identity,
				browseWorkspaceId,
				processVersion,
				group.targetType,
				"index",
				{
					browseroottype: group.targetType,
					grouppath: group.path,
					groupby: group.groupBy ? group.groupBy : "",
					allowNavigation: !group.limitTo,
					allowIndex: false,
					dependentTypesRestrictions: group.allowAssociateDependentElements
						? group.targetDependentTypes === undefined
							? null
							: group.targetDependentTypes
						: [],
				},
				"elements",
			],
			this.route,
		);
	}
}
