import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";

@Component({
	selector: "stages-media-query",
	template: "<ng-content></ng-content>",
})
export class MediaQueryComponent implements OnInit, OnDestroy {
	@Input() query!: string;

	matches?: boolean;

	private mediaQueryList!: MediaQueryList;

	constructor(private zone: NgZone) {}

	ngOnInit(): void {
		this.mediaQueryList = window.matchMedia(this.query);
		this.matches = this.mediaQueryList.matches;
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		this.mediaQueryList.addListener(this.windowResizeListener);
	}

	ngOnDestroy(): void {
		if (this.mediaQueryList) {
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			this.mediaQueryList.removeListener(this.windowResizeListener);
		}
	}

	windowResizeListener = (event: MediaQueryListEvent): void => {
		this.zone.run(() => {
			this.matches = event.matches;
		});
	};
}
