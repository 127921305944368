import { Injectable } from "@angular/core";
import { FilesResource } from "core/stages-client";

@Injectable({ providedIn: "root" })
export class VersionsService {
	constructor(private readonly filesResource: FilesResource) {}

	async getFileWithHistory(fileId: string, workspaceId: string, pv: string): Promise<stages.file.FileWithHistory> {
		return this.filesResource.getFileWithHistory(fileId, {
			pv: pv,
			workspaceId: workspaceId,
		});
	}
}
