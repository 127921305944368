import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PluginService } from "common/editor/plugin.service";

@Component({
	selector: "stages-description-editor-link-details",
	templateUrl: "./link-details.component.html",
})
export class LinkDetailsComponent implements OnInit {
	displayName?: string;

	inline: boolean = false;

	inlineSupported: boolean = false;

	@Input()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	selectedReference: any;

	@Input()
	preferredDisplayText?: string;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	@Output() readonly insert = new EventEmitter<any>();

	constructor(protected pluginService: PluginService) {}

	ngOnInit(): void {
		this.updateDisplayName();
		this.inlineSupported = this.selectedReference.getType() === "Description";
		this.inline = this.selectedReference.isInlining();
	}

	protected updateDisplayName(): void {
		this.displayName = !this.selectedReference.getDisplayText() && this.isIndexReference() ? "" : this.getLinkName();
	}

	private isIndexReference(): boolean {
		return this.selectedReference.getTarget() === "MAIN" || this.selectedReference.getTarget() === "INDEX";
	}

	protected getLinkName(): string {
		if (!this.selectedReference) {
			return "";
		}
		return this.pluginService.getLinkName(this.selectedReference);
	}

	onInsert(): void {
		this.insert.emit(this.selectedReference);
	}

	setDisplayName(value: string): void {
		if (this.selectedReference.isValid()) {
			if ((this.selectedReference.getTarget() || this.selectedReference.getHeading()) === value) {
				this.selectedReference.setDisplayText("");
			} else {
				this.selectedReference.setDisplayText(value);
			}
		}
		this.updateDisplayName();
	}

	textualReference(value?: string): string {
		if (value) {
			this.preferredDisplayText = undefined;
			if (this.selectedReference.getText() !== value) {
				this.selectedReference.setText(value);
				if (
					this.selectedReference.getDisplayText() ===
					(this.selectedReference.getTarget() || this.selectedReference.getHeading())
				) {
					this.selectedReference.setDisplayText("");
				}
			}
			this.updateDisplayName();
		}
		return this.selectedReference.getText();
	}

	inlineChanged(newValue: boolean): void {
		this.selectedReference.setInlining(newValue);
	}

	referenceIsValid(): boolean {
		return this.selectedReference.isValid();
	}
}
