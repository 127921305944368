import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

export function filetypeValidator(fileType: string | null | undefined): ValidatorFn {
	return (control: AbstractControl): Record<string, unknown> | null => {
		if (!control.value || typeof control.value !== "string") {
			return null;
		}
		if (!fileType) {
			return null;
		}
		const value = control.value.trim().toLowerCase();
		const isValid =
			fileType === ""
				? new RegExp("[^.]").test(value)
				: new RegExp("." + fileType.toLowerCase() + "$", "i").test(value);

		return isValid
			? null
			: {
					filetype: {
						valid: false,
					},
			  };
	};
}

@Directive({
	selector: "[stagesFiletype]",
	providers: [{ provide: NG_VALIDATORS, useExisting: FiletypeValidatorDirective, multi: true }],
})
export class FiletypeValidatorDirective implements Validator {
	@Input("stagesFiletype") fileType?: string;

	validate(control: AbstractControl): ValidationErrors | null {
		return filetypeValidator(this.fileType)(control);
	}
}
