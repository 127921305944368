import { NgModule } from "@angular/core";
import { StagesCommonModule } from "common/common.module";
import { PdfComponent } from "print/pdf.component";
import { PrintRoutingModule } from "print/print-routing.module";
import { PrintComponent } from "print/print.component";
import { PdfDownloadComponent } from "print/pdf-download.component";

@NgModule({
	imports: [StagesCommonModule, PrintRoutingModule],
	declarations: [PrintComponent, PdfComponent, PdfDownloadComponent],
	exports: [PdfComponent, PdfDownloadComponent],
})
export class StagesPrintModule {}
