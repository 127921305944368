<ng-container [ngSwitch]="mode">
	<ng-container *ngSwitchCase="'RENAME'">
		<i class="ico" *ngIf="entry.icon" [ngClass]="entry.icon"></i>
		<ng-content></ng-content>
	</ng-container>
	<ng-container *ngSwitchCase="'ADD'">
		<i class="ico" *ngIf="entry.icon" [ngClass]="entry.icon"></i>
		<ng-content></ng-content>
	</ng-container>
	<ng-container *ngSwitchDefault>
		<i class="ico" *ngIf="entry.icon" [ngClass]="entry.icon"></i>
		<div class="text">
			<h3 *ngIf="entry.label" [ngClass]="entry.labelClasses">{{ entry.label }}</h3>
			<h3 *ngIf="entry.labelTranslate" [translate]="entry.labelTranslate"></h3>
		</div>
		<ng-container> </ng-container> </ng-container
></ng-container>
