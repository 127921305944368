import { Injectable } from "@angular/core";
import { DescriptionService, EditableDescription, PluginRegistry } from "common/editor/description.service";
import { ImagePluginComponent } from "common/editor/image-plugin/image-plugin.component";
import { ProcessElementsResource } from "core/stages-client";
import { HtmlTemplatePluginComponent } from "process/description/htmltemplate-plugin/htmltemplate-plugin.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";

type DisplayDescription = stages.core.format.DisplayDescription;

@Injectable({ providedIn: "root" })
export class ProcessDescriptionService implements DescriptionService {
	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	async getDescription(type: string, identity: string, workspaceId: string, pv: string): Promise<EditableDescription> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({ pv: pv });
		return this.processElementsResource
			.getDescription(workspaceId, type, identity, { editable: true })
			.then((descriptionTO: stages.process.Description) => {
				return {
					description: descriptionTO.editableDescription,
					isEditable: descriptionTO.editable,
				};
			});
	}

	async putDescription(
		type: string,
		identity: string,
		workspaceId: string,
		pv: string,
		description: DisplayDescription,
	): Promise<DisplayDescription> {
		return this.processElementsResource.saveDescription(workspaceId, type, identity, description);
	}

	getPluginRegistry(): PluginRegistry {
		return {
			stagesimage: ImagePluginComponent,
			stageslink: LinkPluginComponent,
			stageshtmltemplate: HtmlTemplatePluginComponent,
		};
	}
}
