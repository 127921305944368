import { TextualReferenceUtilBase } from "common/editor/shared/textual-reference-util-base";
import { escape, isEmpty, unescape } from "lodash";

export function isBlank(str?: string): boolean {
	return !str || isEmpty(str.replace(/\s/g, ""));
}

function trimFormatters(formatters: string[]): string[] {
	const trimmedFormatters: string[] = [];
	formatters.forEach((formatter: string) => {
		const trimmedFormatter = formatter.trim();
		if (
			!isBlank(trimmedFormatter) &&
			trimmedFormatter.toLowerCase() !== "null" &&
			trimmedFormatters.indexOf(trimmedFormatter) === -1
		) {
			trimmedFormatters.push(formatter.trim());
		}
	});
	return trimmedFormatters;
}

export class TextualReference {
	constructor() {
		this.clear();
	}

	private static readonly RE_TREF_STR: string =
		"\\[\\[(?:\\[((?:[^\\\\\\[\\]]|\\\\.)+?)\\])?((?:[^\\\\\\[\\]]|\\\\.)+?)(?:\\[((?:[^\\\\\\[\\]]|\\\\.)+?)\\])?\\]\\]";

	private static readonly RE_TREF_GRP_LOCATOR: number = 1;
	private static readonly RE_TREF_GRP_POINTER: number = 2;
	private static readonly RE_TREF_GRP_DISPLAY: number = 3;

	private static readonly RE_TREF = new RegExp(`^${TextualReference.RE_TREF_STR}$`);

	// locator pattern & groups
	private static readonly RE_LOCATOR_STR = "^(?:((?:[^:\\/\\\\]|\\\\.)+):)?((?:[^\\/\\\\]|\\\\.)*)(?:\\/(.+))?$";
	private static readonly RE_LOCATOR_GRP_PROJECT = 1;
	private static readonly RE_LOCATOR_GRP_TYPE = 2;
	private static readonly RE_LOCATOR_GRP_FORMAT = 3;

	private static readonly RE_LOCATOR = new RegExp(TextualReference.RE_LOCATOR_STR);

	// pointer pattern & groups
	private static readonly RE_POINTER_STR = "^((?:[^#\\\\]|\\\\.)+)?(?:#(.*))?$";
	private static readonly RE_POINTER_GRP_TARGETPATH = 1;
	private static readonly RE_POINTER_GRP_HEADING = 2;

	private static readonly RE_POINTER = new RegExp(TextualReference.RE_POINTER_STR);

	private static readonly RE_PROTOCOLS_STR = "([a-z0-9+-\\.]+://|file:|mailto:|news:|javascript:|www\\.)";
	private static readonly RE_EXTLINK_STR: string = `^${TextualReference.RE_PROTOCOLS_STR}.*`;

	private static readonly RE_EXTLINK = new RegExp(TextualReference.RE_EXTLINK_STR, "i");

	private static readonly RE_ESCAPE_DISPLAY_TEXT_CHARS = "\\\\\\[\\]";

	private static readonly RE_ESCAPE_CHARS = TextualReference.RE_ESCAPE_DISPLAY_TEXT_CHARS + ":#\\/";

	private static readonly INACTIVE_ANCHOR_REFERENCE_STR = "[[#]]";

	private isTextValid = false;
	private text?: string;
	private displayText?: string;
	private projectPath: string[] = [];
	private targetPath: string[] = [];
	private formatters: string[] = [];
	private type?: string;
	private heading?: string;

	/**
	 * Clears the current data.
	 * @private
	 */
	clear(): void {
		this.displayText = undefined;
		this.targetPath = [];
		this.formatters = [];
		this.heading = undefined;
		this.projectPath = [];
		this.type = undefined;
		this.text = undefined;
		this.isTextValid = true;
	}

	isValid(): boolean {
		// instances which were initialized with an invalid reference can never be valid
		if (!this.isTextValid) {
			return false;
		}

		// we need at least an element path or a heading to generate a valid reference
		return this.targetPath.length > 0 || !!this.heading || TextualReference.INACTIVE_ANCHOR_REFERENCE_STR === this.text;
	}

	getDisplayText(): string | undefined {
		return this.displayText;
	}

	getTarget(): string | undefined {
		if (this.targetPath.length === 0) {
			return undefined;
		}

		return this.targetPath[this.targetPath.length - 1];
	}

	getTargetPath(): string[] {
		return this.targetPath;
	}

	getHeading(): string | undefined {
		return this.heading;
	}

	getHtml(): string | undefined {
		const ref = this.getText();

		if (!!ref) {
			return escape(ref);
		}

		return undefined;
	}

	getProjectPath(): string[] {
		return this.projectPath;
	}

	getText(): string | undefined {
		if (!this.isTextValid) {
			return this.text;
		}

		return this.toString();
	}

	isExternalLink(): boolean {
		if (isEmpty(this.getTarget())) {
			return false;
		}

		return TextualReference.RE_EXTLINK.test(this.getTarget()!);
	}

	isLocalHeadingLink(): boolean {
		if (TextualReference.INACTIVE_ANCHOR_REFERENCE_STR === this.text) {
			return true;
		}
		if (isEmpty(this.getHeading())) {
			return false;
		}
		return isEmpty(this.getTarget());
	}

	isOverviewPage(): boolean {
		const target = this.getTarget();

		if (target === "MAIN" || target === "INDEX") {
			return true;
		}

		return false;
	}

	hasFormatters(): boolean {
		if (this.formatters.length > 0) {
			return true;
		}

		return false;
	}

	getType(): string | undefined {
		return this.type;
	}

	setDisplayText(text: string | undefined): void {
		this.displayText = isBlank(text) ? undefined : text;
	}

	setTargetPath(path: string[] | undefined): void {
		this.targetPath = !path ? [] : path;
	}

	setHeading(heading: string | undefined): void {
		this.heading = isBlank(heading) ? undefined : heading;
	}

	/**
	 * Decodes the HTML encoded input string and calls {@link #setText(String)}.
	 *
	 * @param text A HTML encoded reference string.
	 */
	setHtml(text: string | undefined): void {
		this.setText(!text ? undefined : unescape(text));
	}

	setProjectPath(path: string[] | undefined): void {
		this.projectPath = !path ? [] : path;
	}

	isEmail(): boolean {
		return this.formatters.indexOf("email") > -1;
	}

	private containsFormat(format: string): boolean {
		return this.formatters.indexOf(format) > -1;
	}

	private addFormat(format: string): void {
		if (!this.containsFormat(format)) {
			this.formatters.push(format);
		}
	}

	private removeFormat(format: string): void {
		const index = this.formatters.indexOf(format);
		if (index > -1) {
			this.formatters.splice(index, 1);
		}
	}

	isInlining(): boolean {
		return this.containsFormat("inline");
	}

	setInlining(inline: boolean): void {
		if (inline) {
			this.addFormat("inline");
		} else {
			this.removeFormat("inline");
		}
	}

	getAlignment(): "center" | "left" | "right" | undefined {
		if (this.formatters.length === 0) {
			return undefined;
		}

		if (this.containsFormat("center")) {
			return "center";
		}
		if (this.containsFormat("left")) {
			return "left";
		}
		if (this.containsFormat("right")) {
			return "right";
		}
		return undefined;
	}

	setAlignment(alignment: string): void {
		const newAlignmentFormatter = getAlignmentFormatter(alignment);

		this.removeFormat("center");
		this.removeFormat("left");
		this.removeFormat("right");

		if (newAlignmentFormatter) {
			this.addFormat(newAlignmentFormatter);
		}
	}

	setText(text: string | undefined): void {
		this.clear();

		if (!text || !text.startsWith("[[") || !text.endsWith("]]")) {
			this.text = !text ? "" : text;
			this.isTextValid = false;
			this.type = "";
			return;
		}

		this.text = text;
		this.isTextValid = true;

		let correctedText = text;
		if (!TextualReference.RE_TREF.test(correctedText)) {
			correctedText = TextualReferenceUtilBase.correctReferenceEscaping(text);
		}

		const mTref = TextualReference.RE_TREF.exec(correctedText);

		if (!!mTref) {
			this.text = correctedText;
			this.setLocator(mTref[TextualReference.RE_TREF_GRP_LOCATOR]);

			const pointerStr = mTref[TextualReference.RE_TREF_GRP_POINTER];

			if (!isBlank(pointerStr)) {
				const mPointer = TextualReference.RE_POINTER.exec(pointerStr);

				if (mPointer !== null) {
					this.setTargetPathFromEscapedString(mPointer[TextualReference.RE_POINTER_GRP_TARGETPATH]);
					this.setHeading(TextualReference.unescapeRefPart(mPointer[TextualReference.RE_POINTER_GRP_HEADING]));
				} else {
					this.isTextValid = false;
				}
			}

			this.setDisplayText(TextualReference.unescapeRefPart(mTref[TextualReference.RE_TREF_GRP_DISPLAY]));
		} else {
			this.isTextValid = false;
		}

		// cleanup
		if (this.isTextValid && !this.getType() && !this.getTarget() && this.getTarget() === "MAIN") {
			this.setType("process workbench");
		}
	}

	setLocator(locatorStr: string): void {
		if (!isBlank(locatorStr)) {
			const mLocator = TextualReference.RE_LOCATOR.exec(locatorStr);

			if (!!mLocator) {
				const format = mLocator[TextualReference.RE_LOCATOR_GRP_FORMAT];
				const formatStr = !format ? undefined : format.toLowerCase();

				this.setProjectPathFromEscapedString(mLocator[TextualReference.RE_LOCATOR_GRP_PROJECT]);
				this.setType(TextualReference.unescapeRefPart(mLocator[TextualReference.RE_LOCATOR_GRP_TYPE]));
				this.setFormatString(TextualReference.unescapeRefPart(formatStr));
			} else {
				this.isTextValid = false;
			}
		}
	}

	setType(type: string | undefined): void {
		this.type = isBlank(type) ? undefined : type;
	}

	toString(): string {
		const refStartSymbol = "[[";

		const values: string[] = [refStartSymbol];

		if (this.targetPath.length > 0) {
			if (this.projectPath.length > 0 || !isEmpty(this.getType()) || this.getFormatters().length > 0) {
				values.push("[");

				if (this.projectPath.length > 0) {
					values.push(this.getProjectPathAsEscapedString()!);
					values.push(":");
				}

				if (!isEmpty(this.getType())) {
					values.push(TextualReference.escapeRefPart(this.getType())!);
				}

				if (this.getFormatters().length > 0) {
					values.push("/");
					values.push(TextualReference.escapeRefPart(this.getFormatString())!);
				}

				values.push("]");
			}

			values.push(this.getTargetPathAsEscapedString()!);
		}

		if (!isEmpty(this.getHeading())) {
			values.push("#");
			values.push(TextualReference.escapeRefPart(this.getHeading())!);
		}

		if (!isEmpty(this.getDisplayText()) && refStartSymbol !== values.join("")) {
			values.push("[");
			values.push(TextualReference.escapeDisplayText(this.getDisplayText())!);
			values.push("]");
		}
		values.push("]]");

		return values.join("");
	}

	isEmptyReference(): boolean {
		const textRef = this.toString();
		return textRef === "[[]]";
	}

	toNormalizedReference(): string | undefined {
		if (!this.isValid()) {
			return undefined;
		}

		const values: string[] = ["[["];

		if (!isEmpty(this.getType())) {
			values.push("[");

			if (this.projectPath.length > 0) {
				values.push(this.getProjectPathAsEscapedString()!);
				values.push(":");
			}

			values.push(TextualReference.escapeRefPart(this.getType())!);

			values.push("]");
		}

		if (this.targetPath.length > 0) {
			values.push(this.getTargetPathAsEscapedString()!);
		}

		if (!isEmpty(this.getHeading())) {
			values.push("#");
			values.push(TextualReference.escapeRefPart(this.getHeading())!);
		}

		values.push("]]");

		return values.join("");
	}

	/**
	 * @return The target path including the element name as escaped string or null.
	 *
	 */
	getTargetPathAsEscapedString(): string | undefined {
		return TextualReference.joinPath(this.getTargetPath());
	}

	/**
	 * Sets a new element path from an escaped string. The new value will be ignored if an invalid reference was
	 * previously set using {@link #setText(String)}. In this case the current data has to be cleared first
	 * using {@link #clear()} before a new value can be set.
	 *
	 * @param path The new element path and name as escaped string or null.
	 * @see #setTargetPath(List)
	 */
	private setTargetPathFromEscapedString(path: string): void {
		this.targetPath = !path ? ([] as string[]) : TextualReference.splitPath(path)!;
	}

	/**
	 * @return The full project path as escaped string.
	 */
	private getProjectPathAsEscapedString(): string | undefined {
		return TextualReference.joinPath(this.getProjectPath());
	}

	/**
	 * Sets a new project path from an escaped string. The new value will be ignored if an invalid reference was
	 * previously set using {@link #setText(String)}. In this case the current data has to be cleared first using
	 * {@link #clear()} before a new value can be set.
	 *
	 * @param path The new project path as escaped string or <code>null</code>.
	 * @see #setProjectPath(List)
	 */
	private setProjectPathFromEscapedString(path: string | undefined): void {
		this.projectPath = !path ? ([] as string[]) : TextualReference.splitPath(path)!;
	}

	getFormatters(): string[] {
		return this.formatters;
	}

	setFormatters(format: string[] | undefined): void {
		this.formatters = !format ? [] : trimFormatters(format);
	}

	private getFormatString(): string {
		if (this.formatters.length === 0) {
			return "";
		}

		return this.formatters.join(",");
	}

	private setFormatString(formatString: string | undefined): void {
		if (isEmpty(formatString)) {
			this.setFormatters(undefined);
		} else {
			this.setFormatters(formatString!.split("[,/]"));
		}
	}

	getOriginalText(): string | undefined {
		return this.text;
	}

	optimize(): void {
		const displayText = this.getDisplayText();
		if (!displayText) {
			return;
		}

		const target = this.getTarget();
		if (displayText === target) {
			this.setDisplayText(undefined);
		}

		const heading = this.getHeading();
		if (!target && displayText === heading) {
			this.setDisplayText(undefined);
		}
	}

	/**
	 * Returns a new instance and sets the given reference. If an invalid reference is set
	 * {@link #isValid()} will never return true unless a new, valid reference is set using
	 * {@link #setText(String)}.
	 *
	 * @param text The textual reference to set.
	 * @return A new TextualReference instance, never <code>null</code>.
	 * @see #setText(String)
	 */
	static fromText(text: string): TextualReference {
		const ref = new TextualReference();
		ref.setText(text);
		return ref;
	}

	/**
	 * Unescape a reference component.
	 *
	 * @param escapedPart The escaped reference component (possibly <code>null</code>).
	 *
	 * @return The unescaped reference component or <code>null</code>.
	 */
	private static unescapeRefPart(escapedPart: string | undefined): string | undefined {
		if (!escapedPart) {
			return undefined;
		}

		return escapedPart.replace(new RegExp("\\\\(.)", "g"), "$1");
	}

	/**
	 * Escapes all special characters which are reserved for reference parsing.
	 *
	 * @param part The reference component to escape (possibly <code>null</code>).
	 *
	 * @return The escaped reference component or <code>null</code>.
	 */
	static escapeRefPart(part: string | undefined): string | undefined {
		if (!part) {
			return undefined;
		}

		return part.replace(new RegExp(`([${TextualReference.RE_ESCAPE_CHARS}])`, "g"), "\\$1");
	}

	/**
	 * Escapes all special characters which are reserved for reference parsing except the slash symbol.
	 *
	 * @param part The reference component to escape (possibly <code>null</code>).
	 *
	 * @return The escaped reference component or <code>null</code>.
	 */
	static escapeDisplayText(part: string | undefined): string | undefined {
		if (!part) {
			return undefined;
		}

		return part.replace(new RegExp(`([${TextualReference.RE_ESCAPE_DISPLAY_TEXT_CHARS}])`, "g"), "\\$1");
	}

	/**
	 * Escapes all special characters of a path component.
	 *
	 * @param component The path component to escape (possibly <code>null</code>).
	 *
	 * @return The escaped path component or <code>null</code>.
	 */
	private static escapePathComponent(component: string | undefined): string | undefined {
		const pcomponent = TextualReference.escapeRefPart(component);

		if (!pcomponent) {
			return undefined;
		}

		return pcomponent.replace(/([|])/g, "\\$1");
	}

	/**
	 * Creates an escaped String with all path components separated by '|'.
	 *
	 * @param path_parts The not yet escaped path components.
	 * @return An escaped path string or <code>null</code>.
	 */
	private static joinPath(pathParts: string[]): string | undefined {
		if (pathParts.length === 0) {
			return undefined;
		}

		const values: string[] = [];

		pathParts.forEach((pathPart) => {
			values.push(TextualReference.escapePathComponent(pathPart)!);
		});

		return values.join("|");
	}

	/**
	 * Splits an escaped path string and returns the unescaped path components as array.
	 *
	 * @param path An escaped path string (possibly <code>null</code>).
	 * @return An array containing all unescaped path components or <code>null</code>.
	 */
	private static splitPath(path: string | undefined): string[] | undefined {
		if (isBlank(path)) {
			return undefined;
		}

		const pathComponents: string[] = [];
		let component = "";
		let isEscaped = false;
		let c: string;

		for (let i = 0; i < path!.length; i++) {
			c = path!.charAt(i);

			if (c === "|" && !isEscaped) {
				pathComponents.push(TextualReference.unescapeRefPart(component.trim())!);
				component = "";
			} else {
				component = component.concat(c);

				if (isEscaped) {
					isEscaped = false;
				} else if (c === "\\") {
					isEscaped = true;
				}
			}
		}

		if (component.length > 0) {
			pathComponents.push(TextualReference.unescapeRefPart(component.toString().trim())!);
		}

		if (pathComponents.length > 0) {
			return pathComponents;
		}

		return undefined;
	}
}

function getAlignmentFormatter(alignment: string): "center" | "left" | "right" | undefined {
	switch (alignment) {
		case "right":
		case "center":
		case "left":
			return alignment;
	}
	return undefined;
}
