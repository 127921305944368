import { NgModule } from "@angular/core";
import { Routes } from "@angular/router";
import { FilesAddComponent } from "files/add/files-add.component";
import { FilesOAuthConfirmComponent } from "files/auth/files-oauth-confirm.component";
import { FilesOAuthSuccessComponent } from "files/auth/files-oauth-success.component";
import { BrowserRepositoriesResolver } from "files/browser/browser-repositories-resolver.service";
import { CmsBrowserComponent } from "files/browser/cms-browser.component";
import { FilesCheckinComponent } from "files/checkin/files-checkin.component";
import { FilesCheckoutComponent } from "files/checkout/files-checkout.component";
import { DownloadAfterAuthenticationComponent } from "files/download-after-authentication/download-after-authentication.component";
import { ErrorResolverResolver } from "files/error/error-resolver.service";
import { FilesErrorComponent } from "files/error/files-error.component";
import { StagesLoadingErrorComponent } from "files/error/loading-error.component";
import { MainComponent } from "files/main.component";
import { FilesPropertiesComponent } from "files/properties/files-properties.component";
import { PropertiesResolverResolver } from "files/properties/properties-resolver.service";
import { FilesRevertComponent } from "files/revert/files-revert.component";
import { FilesStartDialogComponent } from "files/start/files-start-dialog.component";
import { FilesSetStateComponent } from "files/state/files-set-state.component";
import { FilesTemplateComponent } from "files/template/files-template.component";
import { FilesEditUrlComponent } from "files/url/files-edit-url.component";
import { UrlPropertiesResolver } from "files/url/url-properties-resolver.service";
import { UrlTemplateResolver } from "files/url/url-template-resolver.service";
import { FileWithHistoryResolver } from "files/versions/file-with-history-resolver.service";
import { FilesVersionsComponent } from "files/versions/files-versions.component";

export const filesRoutes: Routes = [
	{
		path: "",
		component: MainComponent,
		children: [
			{
				path: "oauth-confirm",
				component: FilesOAuthConfirmComponent,
			},
			{
				path: "oauth-success",
				component: FilesOAuthSuccessComponent,
			},
			{
				path: "add",
				component: FilesAddComponent,
			},
			{
				path: "checkout",
				component: FilesCheckoutComponent,
			},
			{
				path: "checkin",
				component: FilesCheckinComponent,
			},
			{
				path: "revert",
				component: FilesRevertComponent,
			},
			{
				path: "setState",
				component: FilesSetStateComponent,
			},
			{
				path: "properties",
				component: FilesPropertiesComponent,
				resolve: {
					properties: PropertiesResolverResolver,
				},
			},
			{
				path: "start",
				component: FilesStartDialogComponent,
			},
			{
				path: "addUrl",
				component: FilesEditUrlComponent,
				resolve: {
					urlProperties: UrlTemplateResolver,
				},
			},
			{
				path: "editUrl",
				component: FilesEditUrlComponent,
				resolve: {
					urlProperties: UrlPropertiesResolver,
				},
			},
			{
				path: "browse",
				component: CmsBrowserComponent,
				resolve: {
					browserRepositories: BrowserRepositoriesResolver,
				},
			},
			{
				path: "error",
				component: FilesErrorComponent,
				resolve: {
					error: ErrorResolverResolver,
				},
			},
			{
				path: "versions",
				component: FilesVersionsComponent,
				resolve: {
					fileWithHistory: FileWithHistoryResolver,
				},
			},
			{
				path: "loadingError",
				component: StagesLoadingErrorComponent,
			},
			{
				path: "template",
				component: FilesTemplateComponent,
			},
			{
				path: "download",
				component: DownloadAfterAuthenticationComponent,
			},
		],
	},
];

@NgModule({
	imports: [
		// not yet
		//        RouterModule.forChild(
		//            filesRoutes,
		//        )
	],
	exports: [],
	providers: [
		ErrorResolverResolver,
		PropertiesResolverResolver,
		UrlTemplateResolver,
		UrlPropertiesResolver,
		FileWithHistoryResolver,
		BrowserRepositoriesResolver,
	],
})
export class FilesRoutingModule {}
