import { Component, ViewChild } from "@angular/core";
import { AssociationBrowserService } from "common/associations/association-browser.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { ViewService } from "core/view.service";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "stages-association-browser-dialog",
	templateUrl: "association-browser-dialog.component.html",
})
export class AssociationBrowserDialogComponent {
	@ViewChild("dialog", { static: true })
	dialog!: NewDialogComponent;

	constructor(
		private associationBrowserService: AssociationBrowserService,
		private viewService: ViewService,
		private route: ActivatedRoute,
	) {}

	close(): void {
		this.onBeforeClose();
		this.dialog.close();
	}

	onBeforeClose(): void {
		this.viewService.refresh(this.route.snapshot.paramMap);
		this.associationBrowserService.reset();
	}
}
