import { Injectable } from "@angular/core";
import { UtilService } from "common/util.service";
import { MainService } from "core/main.service";

@Injectable({ providedIn: "root" })
export class ListService {
	private ieMaxSize?: number;
	private maxSize?: number;

	constructor(private utilService: UtilService, private mainService: MainService) {
		this.mainService.getApplication("_vv").then((applicationContext) => {
			this.ieMaxSize =
				applicationContext.ieListLimit === null || applicationContext.ieListLimit < 1
					? undefined
					: applicationContext.ieListLimit;
			this.maxSize = this.getMaxListItemSize();
		});
	}

	private getMaxListItemSize(): number | undefined {
		if (this.utilService.browserIsIE()) {
			return this.ieMaxSize;
		}
		return undefined;
	}

	getVisibleListItems(completeList: object[]): object[] {
		if (this.maxSize && completeList.length > this.maxSize) {
			return completeList.slice(0, this.maxSize);
		}
		return completeList;
	}

	hasOmmittedItems(completeList: object[]): boolean {
		return this.maxSize !== undefined && completeList.length > this.maxSize;
	}
}
