import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { ClassInterfaceUsedByEditNameComponent } from "common/newdialog/edit-name.component";
import { provideAs } from "core/functions";
import { CommonStore } from "core/store";
import { FreezeService, JobInfo, JobInfoMap } from "process/freeze/freeze.service";
import { Observable } from "rxjs";

@Component({
	providers: [provideAs(UpdateConsiderFrozenComponent, ClassInterfaceUsedByEditNameComponent)],
	templateUrl: "update-consider-frozen.component.html",
})
export class UpdateConsiderFrozenComponent implements ClassInterfaceUsedByEditNameComponent {
	readonly actionKey = "management.process.versions.updateConsiderFrozen";
	readonly nameLabelKey = "process.freeze.processversion.name";
	readonly namePattern = "^[^/\\\\]+$";
	readonly patternError = "management.process.versions.pattern";
	readonly existsError = "management.process.versions.exists";
	readonly systemError = "management.process.versions.reserved.words";

	name = "";

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	paramMap$: Observable<ParamMap>;

	constructor(
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly freezeService: FreezeService,
		private readonly phaseFreezeStore: CommonStore,
	) {
		this.paramMap$ = this.route.paramMap;
	}

	close(): void {
		this.dialog.close();
	}

	async save(): Promise<boolean> {
		const paramMap = this.route.snapshot.paramMap;
		const currentWorkspaceId = paramMap.get("workspaceId")!;
		const freezeWorkspaceId = paramMap.get("freezeWorkspaceId")!;
		const otherWorkspaceIds = paramMap.has("workspaceIds") ? paramMap.get("workspaceIds")!.split(",") : [];

		const jobIdentifiers = await this.freezeService.updateValidVersionConsiderFrozen(
			freezeWorkspaceId,
			this.name,
			currentWorkspaceId,
			paramMap.get("processVersion")!,
			otherWorkspaceIds,
		);

		const jobInfoMap: JobInfoMap = {};
		const workspaceIds = [freezeWorkspaceId].concat(otherWorkspaceIds);
		for (let i = 0; i < workspaceIds.length && i < jobIdentifiers.length; i++) {
			jobInfoMap["workspace_" + workspaceIds[i]] = createWaitingJobExecutionInfo(jobIdentifiers[i]);
		}
		const matrix = this.phaseFreezeStore.value.phaseMatrix;
		if (matrix) {
			matrix.setUpdateJobExecutionInfo(jobInfoMap);
			this.phaseFreezeStore.set("phaseMatrix", matrix);
		}

		return this.router.navigate([{ jobType: jobIdentifiers[0].group, jobName: jobIdentifiers[0].name }], {
			relativeTo: this.route,
		});
	}
}

function createWaitingJobExecutionInfo(jobIdentifier: stages.core.scheduler.JobIdentifier): JobInfo {
	return {
		jobIdentifier: jobIdentifier,
		jobStatus: "WAITING",
	};
}
