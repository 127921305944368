<div class="carousel-wrapper">
	<div class="carousel-inner">
		<div
			class="carousel-item"
			*ngFor="let item of selection ? [selection] : []"
			[@slide]="{ value: '', params: params }"
		>
			<ng-container *ngTemplateOutlet="item.template!"></ng-container>
		</div>
	</div>
</div>
