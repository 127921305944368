<stages-media-query #media query="screen and (min-width: 768px)">
	<ng-container *ngIf="data$ | async as data">
		<div class="button-bar" *ngIf="buttons.length && !actionInProgress">
			<div class="buttons">
				<div class="buttons">
					<ng-container *ngFor="let button of buttons">
						<button
							*ngIf="$any(button).visible()"
							(click)="button.click()"
							[class]="button.class"
							[translate]="button.translate"
							[disabled]="$any(button).disabled()"
						></button>
					</ng-container>
				</div>
			</div>
		</div>

		<ul [class]="listStyles">
			<li *ngIf="mode === Mode.ADD" class="relative indented-list-item add-item fullsize">
				<div class="fullsize">
					<stages-add
						#addComponent
						[focusOnInit]="true"
						[placeholder]="'start.typing' | translate"
						[maxLength]="addMaxLength"
						[pattern]="addPattern"
						[patternMessageKey]="addPatternMessageKey"
						(submitted)="add($event)"
						(focusLost)="cancelAdd()"
						(cancelled)="cancelAdd()"
						class="relative"
					>
					</stages-add>
				</div>
			</li>
			<ng-container *ngFor="let group of $any(data.items); trackBy: groupTrackBy">
				<li class="group" [ngClass]="{ 'new-item': isRecentlyAdded(group) }" [class.disabled]="groupInactiveFn(group)">
					<header class="group-header">
						<div *ngIf="selectable" class="select">
							<input
								type="checkbox"
								class="checkbox"
								[id]="'select' + groupIdFn(group)"
								[name]="'select' + groupIdFn(group)"
								[checked]="selection.isSelected(groupIdFn(group))"
								(change)="selection.toggle(groupIdFn(group))"
								[disabled]="groupDisabledFn(group, data, mode)"
							/>
							<label [for]="'select' + groupIdFn(group)"></label>
						</div>
						<ng-container *ngTemplateOutlet="groupTemplate!; context: { $implicit: group }"></ng-container>
						<stages-button-expand-collapse
							*ngIf="collapsibleGroupName"
							[isCollapsed]="!isExpanded(groupIdFn(group))"
							[classes]="'action menu-button'"
							(pressedButton)="toggleGroupExpanded(groupIdFn(group))"
						></stages-button-expand-collapse>
						<ng-container *ngTemplateOutlet="groupMenuItemsTemplate!; context: { $implicit: group }"></ng-container>
					</header>
					<ul
						class="list dense"
						*ngIf="
							(collapsibleGroupName && isExpanded(groupIdFn(group))) ||
							(!collapsibleGroupName && group.items.length > 0) ||
							(autoCompleteTemplate && this.groupViewStates.get(group.id) === Mode.ADD)
						"
					>
						<li
							class="relative list-item add-item"
							*ngIf="autoCompleteTemplate && this.groupViewStates.get(group.id) === Mode.ADD"
						>
							<div class="fullsize">
								<stages-auto-complete
									#autoComplete
									[focusOnInit]="true"
									[placeholder]="'start.typing' | translate"
									[query]="dataSource.newSearchQuery()"
									(submitted)="add($event, group.id)"
									(cancelled)="cancelAdd(group.id)"
									(focusLost)="cancelAdd(group.id)"
									[searchResultTemplate]="autoCompleteTemplate"
									[browseButtonActive]="browseItemButtonActive"
									(openBrowserButtonPressed)="openButtonPressed(group)"
									(closeButtonPressed)="closeButtonPressed(group)"
									class="relative"
								>
								</stages-auto-complete>
							</div>
						</li>
						<li class="list-item" *ngIf="group.moreOnPreviousPage">
							<div
								class="label"
								translate="paging.moreOnPreviousPage"
								[translateParams]="{ group: (group.name | translate) }"
							></div>
						</li>
						<ng-container *ngIf="!tableItemTemplate">
							<ng-container *ngFor="let item of group.items; trackBy: trackBy; let i = index">
								<li class="list-item" [ngClass]="getClasses(item)" [class.disabled]="inactiveFn(item)">
									<!-- not yet -->
									<!--
										<div *ngIf="selectable" class="select">
											<input
											type="checkbox"
											class="checkbox"
											[id]="'select' + idFn(item)"
											[name]="'select' + idFn(item)"
											[checked]="selection.isSelected(idFn(item))"
											(change)="selection.toggle(idFn(item))"
											[disabled]="disabledFn(item, data, mode)">
											<label [for]="'select' + idFn(item)"></label>
										</div>
									-->
									<ng-container
										#empty
										*ngTemplateOutlet="listItemTemplate!; context: { $implicit: item, group: group }"
									></ng-container>
								</li>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="tableItemTemplate">
							<ng-container *ngIf="media.matches">
								<ng-template *ngTemplateOutlet="tableItemTemplate; context: { $implicit: group }"></ng-template>
							</ng-container>
							<ng-container *ngIf="!media.matches">
								<ng-template *ngTemplateOutlet="listItemTemplate!; context: { $implicit: group }"></ng-template>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="group.items.length === 0 && collapsibleGroupSettings">
							<ng-container *ngTemplateOutlet="listItemTemplate!; context: { group: group }"></ng-container>
						</ng-container>
						<li class="list-item" *ngIf="group.moreOnNextPage">
							<div
								class="label"
								translate="paging.moreOnNextPage"
								[translateParams]="{ group: (group.name | translate) }"
							></div>
						</li>
					</ul>
				</li>
			</ng-container>

			<li class="list-item none" *ngIf="!!translateNone && data.totalItemCount === 0">
				<div class="label">
					<p [translate]="translateNone"></p>
				</div>
			</li>
		</ul>

		<div class="list" *ngIf="data.totalPageCount > 1">
			<footer class="footer-pager">
				<stages-pager
					[pageSize]="data.pageSize ? data.pageSize : pageSize"
					[total]="data.totalItemCount"
					[page]="data.page"
					(goTo)="dataSource.goTo($event)"
				></stages-pager>
			</footer>
		</div>
	</ng-container>
</stages-media-query>
