import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class CheckboxInput extends InputBase<boolean> {
	constructor(args: CommonArgs<boolean>) {
		super({
			key: args.key,
			value: args.value || false,
			type: "checkbox",
			inputFieldType: "checkbox",
			required: false,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});
	}
}
