import { Component, OnInit } from "@angular/core";
import { HomeNavigationService } from "navigation/list/home.navigation.service";

@Component({
	templateUrl: "./start.component.html",
})
export class StartComponent implements OnInit {
	constructor(private homeNavigationService: HomeNavigationService) {}

	ngOnInit(): void {
		this.homeNavigationService.update();
	}
}
