import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";

type Workspace = stages.workspace.Workspace;

@Component({
	animations: [
		trigger("slide", [
			transition(":enter", [
				style({ transform: "translateX({{enterFrom}}%)" }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ transform: "translateX({{enterTo}}%)" })),
			]),
			transition(":leave", [
				style({ transform: "translateX({{leaveFrom}}%)" }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ transform: "translateX({{leaveTo}}%)" })),
			]),
		]),
	],
	selector: "stages-workspace-carousel",
	styleUrls: ["./workspace-carousel.component.scss"],
	templateUrl: "./workspace-carousel.component.html",
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceCarouselComponent {
	private _folder!: stages.workspace.Folder;
	private _filterTerm?: string;

	currentWorkspaceId!: string;
	params = {
		enterFrom: "0",
		enterTo: "0",
		leaveFrom: "0",
		leaveTo: "0",
	};

	@Input()
	set folder(folder: stages.workspace.Folder) {
		if (this._folder && folder) {
			if (this._folder.parent && this._folder.parent.id === folder.id) {
				// up
				this.params = {
					enterFrom: "-200",
					enterTo: "-100",
					leaveFrom: "0",
					leaveTo: "100",
				};
			} else {
				// down
				this.params = {
					enterFrom: "0",
					enterTo: "-100",
					leaveFrom: "0",
					leaveTo: "-100",
				};
			}
		}

		this.chRef.detectChanges();
		this._folder = folder;
	}

	get folder(): stages.workspace.Folder {
		return this._folder;
	}

	@Input()
	selected?: string;

	@Input()
	set filterTerm(filterTerm: string | undefined) {
		this._filterTerm = filterTerm ? filterTerm.toLocaleLowerCase() : undefined;
	}

	get filterTerm(): string | undefined {
		return this._filterTerm;
	}

	@Output() readonly up = new EventEmitter<string>();

	@Output() readonly down = new EventEmitter<string>();

	@Output() readonly select = new EventEmitter<WorkspaceSelected>();

	constructor(private chRef: ChangeDetectorRef) {}

	onDown(folderEntry: stages.workspace.FolderEntry): void {
		this.down.emit(folderEntry.id);
	}

	onUp(folderEntry: stages.workspace.FolderEntry): void {
		this.up.emit(folderEntry.id);
	}

	selectWorkspace(workspace: stages.workspace.Node | Workspace): void {
		this.select.emit(workspace as WorkspaceSelected);
	}

	getId: TrackByFunction<stages.workspace.FolderEntry> = (_index, folder) => {
		return folder.id;
	};

	filterChildren(children: stages.workspace.FolderEntry[]): stages.workspace.FolderEntry[] {
		return children.filter((child) => {
			return !this.filterTerm || child.name.toLocaleLowerCase().indexOf(this.filterTerm) >= 0;
		});
	}
}
