import { Injectable } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { DeleteService } from "process/element/delete/delete.service";
import { ModuleService } from "process/modules/module.service";
import { ResetTailoringDialogService } from "process/tailoring/reset/reset-tailoring-dialog-service";

type Perspective = stages.process.Perspective;
type ProcessView = stages.process.ProcessView;
type Workspace = stages.workspace.Workspace;

@Injectable({ providedIn: "root" })
export class MenuFactoryService {
	constructor(
		private router: Router,
		private deleteService: DeleteService,
		private moduleService: ModuleService,
		private viewService: ViewService,
		private mainService: MainService,
		private resetTailoringDialogService: ResetTailoringDialogService,
	) {}

	newMenuItems(
		self: ProcessView,
		perspectives: Perspective[],
		workspace: Workspace,
		paramMap: ParamMap,
		route: ActivatedRoute,
	): MenuItem[] {
		const menuItems: MenuItem[] = [
			{
				disabled: () => {
					return !self.allowedOperations.EDIT;
				},
				isSwitch: false,
				iconClass: "ico ico-edit",
				name: "edit",
				on: async () =>
					this.router.navigate([
						"workspace",
						paramMap.get("workspaceId"),
						paramMap.get("processVersion"),
						"process",
						self.type.ident,
						paramMap.get("identity"),
						"edit",
					]),
			},
			{
				disabled: () => {
					return !self.allowedOperations.TRANSLATE;
				},
				isSwitch: false,
				iconClass: "ico ico-translate",
				name: "language.translate",
				on: async () =>
					this.router.navigate([
						"workspace",
						paramMap.get("workspaceId"),
						paramMap.get("processVersion"),
						"process",
						self.type.ident,
						paramMap.get("identity"),
						"translate",
					]),
			},
			{
				disabled: () => {
					return !self.allowedOperations.OVERWRITE;
				},
				isSwitch: false,
				iconClass: "ico ico-overwrite",
				name: "process.element.overwrite",
				on: async () =>
					this.moduleService
						.overwriteSingleElement(paramMap.get("workspaceId")!, paramMap.get("processVersion")!, self)
						.then(() => {
							self.allowedOperations.OVERWRITE = false;
							self.allowedOperations.RESTORE = true;
							this.viewService.refresh(paramMap);
						}),
			},
			{
				disabled: () => {
					return !self.allowedOperations.RESTORE;
				},
				isSwitch: false,
				iconClass: "ico ico-undo-overwrite",
				name: "process.element.overwrite.undo",
				on: () => {
					this.moduleService.restoreSingleElementInElementMenu(
						paramMap.get("workspaceId")!,
						paramMap.get("processVersion")!,
						self,
						() => {
							self.allowedOperations.OVERWRITE = true;
							self.allowedOperations.RESTORE = false;
							this.viewService.refresh(paramMap);
						},
					);
				},
			},
			{
				disabled: !self.allowedOperations.DELETE || self.type.ident === "process",
				isSwitch: false,
				iconClass: "ico ico-delete",
				name: "delete",
				on: () => {
					this.deleteService.deleteSingleElement(
						paramMap.get("workspaceId")!,
						paramMap.get("processVersion")!,
						self,
						() => {
							this.router.navigate([
								"workspace",
								paramMap.get("workspaceId"),
								paramMap.get("processVersion"),
								"process",
								self.type.ident,
								self.parent!.identity,
							]);
						},
						() => {},
					);
				},
			},
			{
				disabled: !self.allowedOperations.PdfPrint,
				isSwitch: false,
				iconClass: "ico ico-pdf-print",
				name: "pdfprint",
				// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				on: async () => this.mainService.openPopup(["print", "pdf", { type: self.type.ident, id: self.id }], route),
			},
			{
				disabled:
					(!self.previousValidVersionDiffValid && !self.allowedOperations.READ_PROCESS_VERSIONS) ||
					!self.allowedOperations.COMPARE_PROCESS ||
					self.type.ident !== "process",
				isSwitch: false,
				iconClass: "ico ico-interface",
				name: "compare",
				on: async () =>
					this.router.navigate([{ outlets: { popup: ["process", "compare", "to"] } }], {
						relativeTo: this.mainService.findPopupContainer(route),
					}),
			},
			{
				disabled: !self.allowedOperations.EXECUTE_TAILORING_WIZARD,
				isSwitch: false,
				iconClass: "ico ico-tailor-wizard",
				name: "process.tailoring.wizard.tailoringmenu",
				// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				on: async () => this.mainService.openDialog(["process", "tailoringwizard"], route),
			},
			{
				disabled: !self.allowedOperations.RESET_TAILORING,
				isSwitch: false,
				iconClass: "ico  ico-tailoring-reset",
				name: "management.tailoring.root.reset",
				on: () => {
					this.resetTailoringDialogService.openDialog(paramMap.get("workspaceId")!, paramMap.get("processVersion")!);
				},
			},
			{
				disabled: !self.allowedOperations.FREEZE,
				isSwitch: false,
				iconClass: "ico ico-snowflake",
				name: "process.freeze",
				// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				on: async () =>
					this.mainService.openPopup(
						[
							"process",
							"freeze",
							self.type.ident,
							self.id,
							{ phaseName: self.label, freezeWorkspaceId: self.process.workspaceId },
						],
						route,
					),
			},
			{
				disabled:
					(!self.allowedOperations.READ_PROCESS_VERSIONS && self.type.ident !== "process") ||
					(self as unknown as stages.process.ViewableProcess).isWorkingRevision,
				isSwitch: false,
				iconClass: "ico ico-filehistory",
				name: "processreleasedetails",

				// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				on: async () =>
					this.mainService.openPopup(
						["process", self.type.ident, paramMap.get("identity"), "processId", self.id, "releasedetails"],
						route,
					),
			},
			{
				isSeparator: true,
			},
		];

		this.viewService.getPerspective().then((currentPerspective) => {
			perspectives.forEach((perspective: Perspective) => {
				menuItems.push({
					hasCheckbox: true,
					value: currentPerspective === perspective.ident,
					name: "perspective." + perspective.ident,
					secondaryIconClass:
						self.changeMarker &&
						self.changeMarker.perspectives &&
						self.changeMarker.perspectives.includes(perspective.ident)
							? ["ico", "ico-exclamation-mark"]
							: undefined,
					on: () => {
						this.viewService.forceViewReload(
							paramMap.get("workspaceId")!,
							paramMap.get("type")!,
							paramMap.get("identity")!,
							perspective.ident,
							paramMap.get("workspaceId")!,
							paramMap.get("processVersion")!,
						);
					},
				});
			});
		});

		return menuItems;
	}
}
