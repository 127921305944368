/* eslint-disable @angular-eslint/use-component-view-encapsulation */
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ContentChild,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PreferencesService } from "core/preferences.service";

@Component({
	selector: "stages-collapsible-list",
	templateUrl: "./collapsible-list.component.html",
	styleUrls: ["./collapsible-list.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CollapsibleListComponent<T> implements AfterViewInit, OnChanges {
	@HostBinding("class.group")
	group = true;

	@Input()
	listItemTemplate?: TemplateRef<unknown>;

	// If you want to save the toggle preferences please
	// provide header + identifier + collapisbleGroupName + dropdownSettings

	@Input()
	header?: string;

	@Input()
	identifier?: string;

	@Input()
	collapsibleGroupName?: string;

	@Input()
	items: T[] = [];

	@Input()
	itemClass?: string;

	@Input()
	listClass?: string;

	@Input()
	counterClass?: string;

	@Input()
	chevronClass?: string;

	@Input()
	expanded: boolean = true;

	@Input()
	showEmpty: boolean = false;

	@Input()
	hasCounter: boolean = true;

	@Input()
	activeCounter?: number;

	@Input()
	collapseWhenAllActive: boolean = true;

	@ContentChild("optionTemplate", { static: false })
	optionTemplateRef?: TemplateRef<unknown>;

	collapsibleGroupSettings: StringToBoolean = {};

	currentOffset: number = 1;
	visibleItemsMultiplikator = 25;
	shownItems: T[] = [];

	constructor(
		private route: ActivatedRoute,
		private preferencesService: PreferencesService,
		private cdr: ChangeDetectorRef,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.items && changes.items.currentValue) {
			this.shownItems = this.items.slice(0, this.visibleItemsMultiplikator);
		}
	}

	ngAfterViewInit(): void {
		if (this.collapseWhenAllActive && this.activeCounter && this.expanded && this.activeCounter === this.items.length) {
			this.toggleGroupExpanded();
			this.cdr.detectChanges();
		}
	}

	isPrefenceSavingActive(): boolean {
		return this.header !== undefined && this.identifier !== undefined && this.collapsibleGroupName !== undefined;
	}

	hasMore(): boolean {
		if (this.items.length > 0) {
			const currentLength = this.currentOffset * this.visibleItemsMultiplikator;
			return currentLength < this.items.length;
		}
		return false;
	}

	loadMore(): void {
		this.currentOffset++;
		this.shownItems = this.items.slice(0, this.currentOffset * this.visibleItemsMultiplikator);
	}

	toggleGroupExpanded(): void {
		if (this.isPrefenceSavingActive()) {
			const groupIdentifier = this.route.snapshot.paramMap.get("workspaceId")! + this.identifier!;
			if (this.collapsibleGroupSettings) {
				this.collapsibleGroupSettings[groupIdentifier] = !this.collapsibleGroupSettings![groupIdentifier];
				this.preferencesService.setPreference(this.collapsibleGroupName!, this.collapsibleGroupSettings!);
			}
		} else {
			this.expanded = !this.expanded;
		}
	}

	isExpanded(): boolean {
		if (this.isPrefenceSavingActive()) {
			const groupIdentifier = this.route.snapshot.paramMap.get("workspaceId")! + this.identifier!;
			if (!this.collapsibleGroupSettings) {
				this.preferencesService
					.getPreference(this.collapsibleGroupName!, {})
					.then((collapseSettings: StringToBoolean) => {
						this.collapsibleGroupSettings = collapseSettings;
						return this.collapsibleGroupSettings[groupIdentifier];
					});
			}
			if (this.collapsibleGroupSettings) {
				if (!this.collapsibleGroupSettings.hasOwnProperty(groupIdentifier)) {
					this.collapsibleGroupSettings[groupIdentifier] = true;
				}
				return this.collapsibleGroupSettings[groupIdentifier];
			}
		}

		return this.expanded;
	}
}
