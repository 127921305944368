import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { KeyboardService } from "common/keyboard.service";
import { MainService } from "core/main.service";

@Component({
	selector: "stages-popup",
	templateUrl: "./popup.component.html",
})
export class PopupComponent implements OnInit, OnDestroy {
	constructor(
		private readonly route: ActivatedRoute,
		private readonly keyboardService: KeyboardService,
		private readonly mainService: MainService,
	) {}

	ngOnInit(): void {
		document.documentElement.style.overflowY = "hidden";
	}

	ngOnDestroy(): void {
		document.documentElement.style.overflowY = "auto";
	}

	@HostListener("document:keydown", ["$event"])
	onKeyDown(event: KeyboardEvent): void {
		if (this.keyboardService.isEscapeKeyPressed(event)) {
			this.close();
		}
	}

	async close(): Promise<void> {
		await this.mainService.closePopup(this.route);
	}
}
