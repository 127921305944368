import { Location } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DataRoutePipe } from "common/data-route.pipe";

@Pipe({ name: "diagramHtml" })
export class DiagramHtmlPipe extends DataRoutePipe implements PipeTransform {
	baseUrl?: string;

	constructor(
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		sanitized: DomSanitizer,
	) {
		super(sanitized);
		this.baseUrl = window.location.href.substring(0, window.location.href.indexOf("#"));
	}

	renderHref(dataRoute: string): string {
		const decodedDataRoute = JSON.parse(decodeURIComponent(this.decodeXMLSpecialCharacters(dataRoute)));
		const urlTree = this.router.createUrlTree(decodedDataRoute.commands, {
			relativeTo: this.route,
		});
		return ` href="${this.baseUrl}${this.encodeXMLSpecialCharacters(
			this.location.prepareExternalUrl(this.router.serializeUrl(urlTree)),
		)}"`;
	}
}
