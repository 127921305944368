import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "common/dialog/dialog.service";
import { MainService } from "core/main.service";
import { PreferencesService } from "core/preferences.service";
import { EnactmentExportResource, ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { EnactmentActivatorExecutionDetailsComponent } from "process/enactment/execution/enactment-activator-execution-details.component";
import { EnactmentActivatorExecutionStartComponent } from "process/enactment/execution/enactment-activator-execution-start.component";
import { lastValueFrom, Observable } from "rxjs";
import { first } from "rxjs/operators";

type ProcessView = stages.process.ProcessView;
type ViewableActivator = stages.process.ViewableEnactmentElement;
type ApplicationContext = stages.context.ApplicationContext;
type ExecutionResult = stages.management.enactment.ExecutionResult;
type ExecutionResultDetail = stages.management.enactment.ExecutionResultResultDetail;
type ViewableExecutionResult = stages.process.ViewableEnactmentExecutionResult;
type ViewableEnactmentExecutionResultDetail = stages.process.ViewableEnactmentExecutionResultDetail;
type ViewableExecutionResultContainer = stages.process.ViewableEnactmentExecutionResultContainer;

declare interface ProcessViewAndActivator {
	processView: ProcessView;
	activatorView: ViewableActivator;
}

type StringToExecutionResult = Record<string, ExecutionResult | null>;

@Component({
	selector: "stages-enactment-activator-execution",
	templateUrl: "./enactment-activator-execution.component.html",
	styleUrls: ["./enactment-activator-execution.component.scss"],
	animations: [
		trigger("expandCollapseFromTop", [
			state("collapsed", style({ "margin-top": "{{contentHeight}}px" }), { params: { contentHeight: 0 } }),
			state("expanded", style({ "margin-top": 0 })),
			transition("collapsed <=> expanded", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
})
export class EnactmentActivatorExecutionComponent implements OnInit {
	constructor(
		private viewService: ViewService,
		private mainService: MainService,
		private preferencesService: PreferencesService,
		private dialogService: DialogService,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private exportResource: EnactmentExportResource,
		private processElementsResource: ProcessElementsResource,
	) {}

	processView$!: Observable<ProcessView>;
	applicationContext!: ApplicationContext;

	groupsOpenCloseState: StringToBoolean = {};
	readonly activatorsGroupIdent = "activatorsGroup";
	readonly resultsGroupIdent = "resultsGroup";

	inProgressActivatorId?: string;
	lastExecutions: StringToExecutionResult = {};

	menuItems: MenuItem[] = [];

	private executionContext?: ProcessViewAndActivator;

	private notReadyMessage!: string;

	async ngOnInit(): Promise<void> {
		this.processView$ = this.viewService.awaitSelfElementObservable();
		this.applicationContext = await lastValueFrom(this.mainService.applicationContext$.pipe(first()));

		this.buildMenuItems();

		this.preferencesService
			.getPreference(this.getPreferencesKey(this.activatorsGroupIdent), { open: true })
			.then((result: StringToBoolean) => (this.groupsOpenCloseState[this.activatorsGroupIdent] = result.open));
		this.preferencesService
			.getPreference(this.getPreferencesKey(this.resultsGroupIdent), { open: false })
			.then((result: StringToBoolean) => (this.groupsOpenCloseState[this.resultsGroupIdent] = result.open));

		this.notReadyMessage = await lastValueFrom(
			this.translateService.get("process.enactment.activator.execution.notready.message"),
		);
	}

	buildMenuItems(): void {
		this.menuItems = [
			{
				name: "execute",
				iconClass: "ico ico-play-button",
				on: (context: ProcessViewAndActivator) => {
					this.startExecution(context);
				},
				disabled: (context: ProcessViewAndActivator): boolean => {
					return this.isExecutionDisabled(context.processView);
				},
			},
		];
	}

	startExecution(context: ProcessViewAndActivator): void {
		this.executionContext = context;
		this.dialogService
			.open<string>("confirm", {
				component: EnactmentActivatorExecutionStartComponent,
				componentArgs: {
					keys: {
						activatorName: context.activatorView.label,
						callback: (executionTitle: string) => {
							this.execute(executionTitle);
						},
					},
				},
				defaultResult: "cancel",
				dialogConfig: {
					size: "large",
					autoHeight: true,
				},
			})
			.then((result) => {
				if (result === "cancel") {
					this.executionContext = undefined;
					return;
				}
			});
	}

	isExecutionDisabled(processView: ProcessView): boolean {
		return !processView.allowedOperations.ACTIVATOR_EXECUTION_DO;
	}

	async execute(executionTitle: string): Promise<void> {
		const executionContext = this.executionContext;
		if (!executionContext) {
			throw new Error("execution context not set");
		}

		const activatorId = executionContext.activatorView.id;
		try {
			this.inProgressActivatorId = activatorId;
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			delete this.lastExecutions[activatorId];

			const result = await this.exportResource.executeActivator(
				this.route.snapshot.params.workspaceId,
				activatorId,
				executionTitle,
				{
					processElementId: executionContext.processView.id,
					processElementType: executionContext.processView.type.ident,
					pv: this.route.snapshot.params.processVersion,
				},
			);
			this.lastExecutions[activatorId] = result;
			// Don't add technical errors to result list
			if (!result.hideInResultList) {
				this.addToResultsList(result, executionContext);
			}
			// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
		} catch (e) {
			const failedExecutionResult = this.buildFailedExecutionResult(
				executionContext.activatorView.label,
				e.error && e.error.message ? e.error.message : null,
			);
			this.lastExecutions[activatorId] = failedExecutionResult;
		} finally {
			this.executionContext = undefined;
			this.inProgressActivatorId = undefined;
		}
	}

	private addToResultsList(
		result: stages.management.enactment.ExecutionResult,
		executionContext: ProcessViewAndActivator,
	): void {
		const user: stages.core.format.User = {
			emailAddress: null,
			id: "",
			fullname: result.userFullname,
			name: "",
			profileImageVersion: 1,
		};

		let viewableSummary: ViewableEnactmentExecutionResultDetail | undefined;
		if (result.summary) {
			viewableSummary = {
				id: "",
				guid: "",
				allowedOperations: {},
				message: result.summary.message,
				messageKey: result.summary.messageKey,
				status: result.summary.status,
				url: result.summary.url,
			};
		}

		const viewablePartialResults: ViewableEnactmentExecutionResultDetail[] = [];
		result.partialResults.forEach((partialResult) => {
			const viewablePartialResult: ViewableEnactmentExecutionResultDetail = {
				id: "",
				guid: "",
				allowedOperations: {},
				message: partialResult.message,
				messageKey: partialResult.messageKey,
				status: partialResult.status,
				url: partialResult.url,
			};
			viewablePartialResults.push(viewablePartialResult);
		});

		const viewableResult: ViewableExecutionResult = {
			id: "",
			guid: "",
			allowedOperations: {},
			activatorName: result.activatorName,
			executionDate: result.executionDate ? result.executionDate : "",
			status: result.status,
			title: result.title,
			user: user,
			summary: viewableSummary,
			partialResults: viewablePartialResults,
		};
		executionContext.processView.enactmentActivatorExecutionContainer!.results.items.unshift(viewableResult);
		executionContext.processView.enactmentActivatorExecutionContainer!.results.totalItemCount++;
	}

	buildFailedExecutionResult(activatorName: string, errorMessage?: string): ExecutionResult {
		const failedExecutionResult: ExecutionResult = {
			hideInResultList: true,
			activatorName: activatorName,
			partialResults: [],
			status: "failure",
			title: "Failed Execution",
			userFullname: this.applicationContext.fullname,
			executionDate: null,
			summary: !errorMessage
				? null
				: ({
						message: errorMessage,
				  } as unknown as ExecutionResultDetail),
		};

		return failedExecutionResult;
	}

	isEnactmentManagementAccessible(processView: ProcessView): boolean {
		return processView.allowedOperations.ACCESS_MANAGEMENT_ENACTMENT;
	}

	getTargetElementLink(target: ViewableActivator): unknown[] {
		const commands = [
			"/",
			"workspace",
			this.route.snapshot.params.workspaceId,
			this.route.snapshot.params.processVersion,
		];

		if (
			this.mainService.secondaryMode &&
			this.mainService.secondaryWorkspaceId &&
			this.mainService.secondaryProcessVersion
		) {
			commands.push({
				swid: this.mainService.secondaryWorkspaceId,
				spv: this.mainService.secondaryProcessVersion,
				smode: this.mainService.secondaryMode,
			});
		}

		const enactmentRouterType = target.typeIdent.replace("enactment", ""); //TODO Same type idents in backend/frontend
		return [...commands, "management", "enactment", enactmentRouterType, target.identity];
	}

	getExpandCollapseFromTop(
		groupIdent: string,
		expandable: HTMLDivElement,
	): { value: string; params: Record<string, unknown> } {
		const targetState = this.groupsOpenCloseState[groupIdent] ? "expanded" : "collapsed";

		const animationParameters = {
			value: targetState,
			params: {
				contentHeight: 0 - expandable.scrollHeight,
			},
		};
		return animationParameters;
	}

	isGroupOpen(groupIdent: string): boolean {
		return this.groupsOpenCloseState[groupIdent];
	}

	toggle(groupIdent: string): void {
		this.groupsOpenCloseState[groupIdent] = !this.groupsOpenCloseState[groupIdent];
		this.preferencesService.setPreference(this.getPreferencesKey(groupIdent), {
			open: this.groupsOpenCloseState[groupIdent],
		});
	}

	showSpinner(activatorId?: string): boolean {
		if (!activatorId) {
			return false;
		}

		return this.inProgressActivatorId === activatorId;
	}

	isStatus(statusToTest: string, activatorId?: string): boolean {
		if (!activatorId) {
			return false;
		}

		const executionResult = this.lastExecutions[activatorId];
		if (!executionResult) {
			return false;
		}

		if (executionResult.status === statusToTest) {
			return true;
		}

		return false;
	}

	openResultsDialogFromActivator(activatorId: string): void {
		const executionResult = this.lastExecutions[activatorId];
		if (!executionResult) {
			return;
		}

		this.openResultsDialog(executionResult);
	}

	private openResultsDialog(executionResult: ExecutionResult): void {
		this.dialogService.open<string>("confirm", {
			component: EnactmentActivatorExecutionDetailsComponent,
			componentArgs: {
				keys: {
					executionResult: executionResult,
				},
			},
			defaultResult: "cancel",
			dialogConfig: {
				size: "large",
				autoHeight: true,
			},
		});
	}

	openResultsDialogFromList(viewableResult: ViewableExecutionResult): void {
		const partialResults: ExecutionResultDetail[] = [];

		viewableResult.partialResults.forEach((partialViewableResult) => {
			const partialResult: ExecutionResultDetail = {
				message: partialViewableResult.message,
				messageKey: partialViewableResult.messageKey,
				status: partialViewableResult.status,
				url: partialViewableResult.url,
			};
			partialResults.push(partialResult);
		});

		let summary: ExecutionResultDetail | null = null;
		if (viewableResult.summary) {
			summary = {
				message: viewableResult.summary.message,
				messageKey: viewableResult.summary.messageKey,
				status: viewableResult.summary.status,
				url: viewableResult.summary.url,
			};
		}

		const executionResult: ExecutionResult = {
			hideInResultList: false,
			executionDate: viewableResult.executionDate,
			activatorName: viewableResult.activatorName,
			status: viewableResult.status,
			title: viewableResult.title,
			userFullname: viewableResult.user.fullname,
			partialResults: partialResults,
			summary: summary,
		};

		this.openResultsDialog(executionResult);
	}

	hasMoreResults(results: ViewableExecutionResultContainer): boolean {
		if (results.totalItemCount > 0) {
			return results.items.length < results.totalItemCount;
		}
		return false;
	}

	async loadMoreResults(results: ViewableExecutionResultContainer): Promise<void> {
		const from = results.items.length;
		const to = from + 10;
		const view = await this.processElementsResource.getEnactmentExecutionResultsPage(
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("type")!,
			this.route.snapshot.paramMap.get("identity")!,
			{ from: from, to: to, pv: this.route.snapshot.paramMap.get("processVersion")! },
		);

		const newResultsPage = view.processView.enactmentActivatorExecutionContainer.results;
		if (newResultsPage.items.length > 0) {
			results.items = results.items.concat(newResultsPage.items);
		}
		results.totalItemCount = newResultsPage.totalItemCount;
		results.totalPageCount = newResultsPage.totalPageCount;
	}

	openNotReadyDialog(): void {
		this.mainService.openPopup(
			[
				"process",
				this.route.snapshot.params.type,
				this.route.snapshot.params.identity,
				"errorDialog",
				{ errorMessage: this.notReadyMessage, errorTitle: "process.enactment.activator.execution.notready.title" },
			],
			this.route,
			true,
		);
	}

	private getPreferencesKey(groupIdent: string): string {
		return "enactmentActivatorExecution_" + groupIdent;
	}
}
