import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class TranslateChoiceService {
	constructor(private translateService: TranslateService) {}

	getKey(params: StringToUnknown, propertyTemplate: string): string {
		return buildPropertyKey(params, propertyTemplate);
	}

	getTranslation(params: StringToUnknown, propertyTemplate: string): string {
		return this.translateService.instant(this.getKey(params, propertyTemplate), params);
	}
}

function buildPropertyKey(params: StringToUnknown, propertyTemplate: string): string {
	let key = propertyTemplate;
	for (const param in params) {
		if (param) {
			const regEx = new RegExp("\\{" + param + "\\}", "g");
			const value = params[param];
			if (typeof value === "number") {
				if (value === 0) {
					key = key.replace(regEx, "zero");
				} else if (value === 1) {
					key = key.replace(regEx, "one");
				} else {
					key = key.replace(regEx, "multiple");
				}
			} else if (typeof value === "boolean") {
				key = key.replace(regEx, value ? param : "not_" + param);
			} else {
				key = key.replace(regEx, String(value));
			}
		}
	}
	return key;
}
