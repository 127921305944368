import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

@Component({
	selector: "stages-carousel-item",
	templateUrl: "./carousel-item.component.html",
})
export class CarouselItemComponent {
	//@ViewChild(TemplateRef, { read: TemplateRef }) template: TemplateRef<unknown>; // eager
	@ContentChild(TemplateRef, { read: TemplateRef, static: true }) template?: TemplateRef<unknown>; // lazy
	@Input() name?: string;
	position!: number;
}
