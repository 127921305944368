<stages-dialog2 [classes]="$any('autoHeight auth-dialog')" #dialog>
	<stages-dialog-header></stages-dialog-header>
	<h1 translate="login.to" [translateParams]="{ name: (cmType$ | async) }"></h1>

	<div class="panel">
		<p translate="files.cms.oauth" [translateParams]="{ name: (cmType$ | async) }"></p>
	</div>

	<div class="panel content">
		<button class="button authentication" (click)="submit()">
			<img [attr.alt]="'files.cms.oauth.signin' | translate" src="img/assets/sign-in.svg" />
			<span translate="login"></span>
		</button>
	</div>

	<footer>
		<div class="buttons">
			<button class="button sm cancel" (click)="close()" translate="cancel" tabindex="3"></button>
		</div>
	</footer>
</stages-dialog2>
