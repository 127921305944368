import { Component, Input, OnDestroy } from "@angular/core";
import { ExtendedTemplateDefinition, PDFService } from "print/pdf.service";
import { NotificationService } from "core/notification.service";
import { ProgressMonitorService } from "core/stages-client";
import { UrlService } from "core/url.service";

@Component({
	selector: "stages-pdf-download",
	templateUrl: "./pdf-download.component.html",
	styleUrls: ["./pdf-download.component.scss"],
})
export class PdfDownloadComponent implements OnDestroy {
	private readonly PDF_CREATION_FAILED: string =
		"Stages was not able to create the requested pdf. Please close this window and try again.";

	@Input()
	templateDefinition!: ExtendedTemplateDefinition;

	@Input()
	workspaceId!: string;

	@Input()
	processVersion!: string;

	@Input()
	type!: string;

	@Input()
	id!: string;

	pollHandle?: number;
	downloadReady: boolean = false;
	showErrorDetails: boolean = false;
	showWarningsDetails: boolean = false;
	error?: string;
	warnings?: string;
	private tempFolderName?: string;

	constructor(
		private pdfService: PDFService,
		private notificationService: NotificationService,
		private progressMonitorService: ProgressMonitorService,
		private urlService: UrlService,
	) {}

	async startPdfCreation(): Promise<void> {
		const jobIdentifier = await this.pdfService.createPdfFor(
			this.workspaceId,
			this.processVersion,
			this.type,
			this.id,
			this.templateDefinition.ident,
		);
		this.pollHandle = this.notificationService.pollPromise(
			() => {
				return this.progressMonitorService.getCurrentProgress(jobIdentifier.name, {
					operationType: jobIdentifier.group,
					workspaceId: this.workspaceId,
				});
			},
			(progress: stages.common.ProgressInfo) => this.updateProgress(progress),
		);
	}

	stopPolling(): void {
		if (this.pollHandle) {
			this.notificationService.cancel(this.pollHandle);
			this.pollHandle = undefined;
		}
	}

	toggleShowErrorDetails(): void {
		this.showErrorDetails = !this.showErrorDetails;
	}

	toggleShowWarningsDetails(): void {
		this.showWarningsDetails = !this.showWarningsDetails;
	}

	updateProgress(progress: stages.common.ProgressInfo): void {
		if (progress) {
			switch (progress.jobStatus) {
				case "COMPLETED_SUCCESSFUL":
					this.stopPolling();
					this.downloadReady = true;
					this.tempFolderName = progress.resultName!;
					break;
				case "COMPLETED_WITH_WARNINGS":
					this.stopPolling();
					this.downloadReady = true;
					this.tempFolderName = progress.resultName!;
					this.warnings = progress.warnings.length > 0 ? progress.warnings.join("\n") : this.PDF_CREATION_FAILED;
					break;
				case "COMPLETED_FAILED":
					this.stopPolling();
					this.error = progress.errorMessage ? progress.errorMessage : this.PDF_CREATION_FAILED;
					break;
				default:
					break;
			}
		}
	}

	ngOnDestroy(): void {
		this.stopPolling();
	}

	downloadPdf(): void {
		window.open(
			this.urlService.build(
				"app/print/pdf/{type}/{id}/{tempFolderName}",
				{
					type: this.type,
					id: this.id,
					tempFolderName: this.tempFolderName,
				},
				{
					workspaceId: this.workspaceId,
					pv: this.processVersion,
				},
			),
			"_self",
		);
	}
}
