import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "files/auth/auth.service";
import { Subject } from "rxjs";

export type AuthResult = "cancel" | "ok";

@Component({
	selector: "stages-files-auth-embeddable",
	templateUrl: "./files-auth-shared-template.html",
})
export class FilesAuthEmbeddableComponent {
	@Input()
	cmsTypeMessageKey!: string;

	form: FormGroup;

	showHeader = false;
	loading = false;
	authRetryFailed = false;

	resultSubject = new Subject<AuthResult>();

	@Input()
	keys?: {
		fileId: string | null;
		elementType: string | null;
		elementId: string | null;
		workspaceId: string;
		processVersion: string;
	};

	constructor(private route: ActivatedRoute, private authService: AuthService) {
		this.form = new FormGroup({
			username: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
			password: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
		});
	}

	getCmsTypeMessageKey(): string {
		return this.cmsTypeMessageKey;
	}

	clearLoginError(): void {
		this.authRetryFailed = false;
	}

	cancel(): void {
		this.resultSubject.next("cancel");
	}

	ok(): void {
		this.authService
			.authenticate(
				this.form.get("username")!.value,
				this.form.get("password")!.value,
				(this.keys && this.keys.fileId) || this.route.snapshot.paramMap.get("fileId"),
				(this.keys && this.keys.elementType) || this.route.snapshot.paramMap.get("elementType")!,
				(this.keys && this.keys.elementId) || this.route.snapshot.paramMap.get("elementId")!,
				(this.keys && this.keys.workspaceId) || this.route.snapshot.paramMap.get("workspaceId")!,
				(this.keys && this.keys.processVersion) || this.route.snapshot.paramMap.get("processVersion")!,
			)
			.then(
				() => {
					this.resultSubject.next("ok");
				},
				(errorResponse) => {
					this.loading = false;
					if (errorResponse.status === 901) {
						this.authRetryFailed = true;
					}
				},
			);
	}
}
