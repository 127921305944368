import { AssociationSource } from "common/associations/association-browser.service";
import { Group } from "common/associations/association-group.service";
import { Association } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { assertDefined, nullToUndefined } from "core/functions";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { EnactmentAssociationService } from "process/enactment/association/enactment-association.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type ViewableElement = stages.process.ViewableElement;
type ViewableEnactmentAssociationGroup = stages.process.ViewableEnactmentAssociationGroup;
type ProcessView = stages.process.ProcessView;
type ViewableType = stages.process.ViewableType;
type ViewableProcess = stages.process.ViewableProcess;
type ProcessTreeItem = stages.process.ProcessTreeItem;
type EnactmentTargetElement = stages.common.AssociationTarget;

export class EnactmentAdapter implements AssociationSource<EnactmentTargetElement> {
	constructor(private element: ProcessView) {}

	get associations(): Record<string, ViewableEnactmentAssociationGroup> {
		return assertDefined(
			this.element.enactmentAssociations,
			"element is expected to have EnactmentElement associations",
		);
	}

	get parent(): ProcessTreeItem | undefined {
		return this.element.parent;
	}

	get process(): ViewableProcess {
		return this.element.process;
	}

	get type(): ViewableType {
		return this.element.type;
	}

	get id(): string {
		return this.element.id;
	}

	get identity(): string {
		return this.element.identity;
	}
}

export class EnactmentAssociationStore implements AssociationStore<EnactmentAdapter, EnactmentTargetElement> {
	constructor(
		private viewService: ViewService,
		private enactmentAssociationService: EnactmentAssociationService,
		private mainService: MainService,
	) {}

	reset(): void {
		return;
	}

	getAddMenuEntryNameKey(group: Group<EnactmentTargetElement>): string {
		return group.translate! + ".add";
	}

	getAddMenuEntryNameKeyParam(group: Group<EnactmentTargetElement>): string | undefined {
		return nullToUndefined(group.targetType);
	}

	getAddMenuEntryNameKeyParamValue(_group: Group<EnactmentTargetElement>): string | undefined {
		return undefined;
	}

	getInputClasses(typeIdent: string, isDependentElement: boolean, subtypeIdent?: string | undefined, additionalClasses?: string): string[] {
		const classes: string[] = [];
		classes.push("ico");

		if(isDependentElement) {
			classes.push("ico-tag");
		}

		if (typeIdent && !isDependentElement) {
			classes.push("ico-enactment-" + typeIdent.toLowerCase());
		}

		if (typeIdent && subtypeIdent) {
			classes.push("ico-enactment-" + subtypeIdent.toLowerCase());
		}
		return classes;
	}

	getIconClasses(targetElement: EnactmentTargetElement, isDependentElement: boolean, additionalClasses?: string | undefined): string[] {
		return this.getInputClasses(
			targetElement.type.ident,
			isDependentElement,
			nullToUndefined(targetElement.type.subtypeIdent),
			additionalClasses,
		);
	}

	getLabelClasses(
		sourceElement: EnactmentAdapter,
		targetElement: EnactmentTargetElement,
		additionalClasses?: string | undefined,
	): string[] {
		return targetElement.tailored ? ["tailored"] : [];
	}

	getQuickAssignLabelClasses(searchResult: AssocSearchResult, additionalClasses?: string | undefined): string[] {
		return searchResult.tailored ? ["tailored"] : [];
	}

	getActions(association: Association<EnactmentTargetElement>): StringToBoolean {
		return association.allowedOperations ? association.allowedOperations : {};
		// // FIXME in backend...
	}

	getTargetElementActions(targetElement: EnactmentTargetElement): StringToBoolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return (targetElement as any).allowedOperations;
	}

	getSourceElement(type: string, identity: string, workspaceId: string, pv: string): Observable<EnactmentAdapter> {
		return this.viewService.awaitSelfElementObservable().pipe(map((self) => new EnactmentAdapter(self)));
	}

	getAdditionalSourceElements(_sourceElement: EnactmentAdapter): EnactmentAdapter[] {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async getParentForNewTargetElement(
		sourceElement: EnactmentAdapter,
		targetType: string,
	): Promise<EnactmentTargetElement | null> {
		const associationGroup = Object.values(sourceElement.associations).find((ag) => targetType === ag.targetType);
		if (!associationGroup) {
			return null;
		}
		return associationGroup.parentForNewTargetElement;
	}

	isTargetAndSourceSameType(sourceElement: ViewableElement, targetType: string): boolean {
		return sourceElement.type.ident === targetType;
	}

	asTypedId(sourceElement: EnactmentAdapter): stages.core.TypedId {
		return {
			typeIdent: sourceElement.type.ident,
			id: sourceElement.id,
		};
	}

	async createAssociationToNewTargetElement(
		sourceElement: stages.core.TypedId,
		newElementParent: EnactmentTargetElement,
		targetType: string,
		targetSubtype: string | null,
		name: string,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
	): Promise<Association<EnactmentTargetElement>> {
		return this.enactmentAssociationService
			.addChild(newElementParent.id, targetType, targetSubtype, name, workspaceId, pv)
			.then(async (newTargetElement: NavigationEntry<stages.core.metamodel.NamedType>) => {
				return this.createAssociation(
					sourceElement,
					newTargetElement.id,
					newTargetElement.type.ident,
					associationType,
					sourceRole,
					workspaceId,
					pv,
				);
			});
	}

	async createAssociation(
		sourceElement: stages.core.TypedId,
		targetElementId: string,
		targetElementType: string,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
	): Promise<Association<EnactmentTargetElement>> {
		const assoc = await this.enactmentAssociationService.createAssociation(
			targetElementType,
			targetElementId,
			sourceElement.typeIdent,
			sourceElement.id,
			associationType,
			sourceRole,
			workspaceId,
			pv,
		);
		this.viewService.refreshView(workspaceId, pv);
		return assoc;
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async createCommentOnlyAssociation(
		sourceElement: stages.core.TypedId,
		targetType: string,
		targetSubtype: string | null,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<Association<EnactmentTargetElement>> {
		throw new Error("Method not implemented.");
	}

	async deleteAssociations(
		associationsToDelete: Association<EnactmentTargetElement>[],
		workspaceId: string,
		pv: string,
	): Promise<Association<EnactmentTargetElement>[]> {
		return this.enactmentAssociationService.deleteAssociations(associationsToDelete, workspaceId, pv).then(() => {
			this.viewService.refreshView(workspaceId, pv);
			return associationsToDelete;
		});
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		throw new Error("Method not implemented.");
	}

	async searchProcessElements(
		searchTerm: string,
		targetElementType: string,
		targetElementSubtypes: string[],
		targetElementsToIgnore: EnactmentTargetElement[],
		currentWorkspaceId: string,
		_searchWorkspaceId: string,
		pv: string,
		targetProcessIds?: string[],
	): Promise<AssocSearchResult[]> {
		const results = await this.enactmentAssociationService.searchProcessElements(
			searchTerm,
			targetElementType,
			targetElementSubtypes,
			targetElementsToIgnore.map((e) => ({
				typeIdent: e.type.ident,
				identity: e.identity,
				processId: e.process.id,
			})),
			currentWorkspaceId,
			pv,
			targetProcessIds,
		);
		return results.filter((r) => r.type.subtypeIdent !== "folder");
	}

	async searchDependentProcessElements(
		_searchTerm: string,
		_targetElementType: string,
		_containerElementId: string,
		_targetElementsToIgnore: EnactmentTargetElement[],
		_currentWorkspaceId: string,
		_pv: string,
	): Promise<AssocSearchResult[]> {
		return Promise.resolve([]);
	}

	getLink(association: Association<EnactmentTargetElement>, pv: string): unknown[] {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const commands = ["/", "workspace", (association.targetElement as any).workspaceId, pv];

		if (
			this.mainService.secondaryMode &&
			this.mainService.secondaryWorkspaceId &&
			this.mainService.secondaryProcessVersion &&
			!association.isProcessInterface
		) {
			commands.push({
				swid: this.mainService.secondaryWorkspaceId,
				spv: this.mainService.secondaryProcessVersion,
				smode: this.mainService.secondaryMode,
			});
		}

		const enactmentRouterType = association.targetElement!.type.ident.replace("enactment", ""); //TODO Same type idents in backend/frontend
		return [...commands, "management", "enactment", enactmentRouterType, association.targetElement!.identity];
	}

	async applyAssociationModifications(
		sourceElement: EnactmentAdapter,
		groupPath: string,
		added: stages.core.TypedId[],
		removed: stages.core.TypedId[],
		modifiedCommentJson: stages.core.model.TypedIdAssociationDetails[],
		workspaceId: string,
		pv: string,
	): Promise<Association<EnactmentTargetElement>[]> {
		return this.enactmentAssociationService
			.applyAssociationModifications(
				sourceElement.type.ident,
				sourceElement.id,
				groupPath,
				added,
				removed,
				workspaceId,
				pv,
			)
			.then(() => {
				this.viewService.refreshView(workspaceId, pv);
				return [];
			});
	}

	isSelectableInBrowser(
		existingAssociation: Association<EnactmentTargetElement> | null,
		sourceElement: EnactmentAdapter,
		targetElement: EnactmentTargetElement,
		targetSubtypes: string[] | undefined,
	): boolean {
		return (
			(existingAssociation && this.getActions(existingAssociation).DELETE) ||
			(!existingAssociation && targetElement.type.subtypeIdent !== "folder")
		);
	}

	async getTree(browseWorkspaceId: string, elementTypes: string[]): Promise<stages.process.TreeElement[]> {
		return this.enactmentAssociationService.getTree(browseWorkspaceId, elementTypes);
	}

	// eslint-disable-next-line @typescript-eslint/require-await
	async rearrangeAssociations(
		_currentWorkspaceId: string,
		_sortedAssociationIds: string[],
		_sortStrategy: stages.core.sort.SortStrategy,
		_type: string,
		_identity: string,
		_path: string,
		_groupBy: string,
		_pv: string,
	): Promise<Record<string, never>> {
		throw new Error("Method not implemented.");
	}

	getGroupIdentifier(groupPath: string, _groupBy?: string): string {
		return groupPath;
	}
}
