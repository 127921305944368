import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { RoundProgressModule } from "angular-svg-round-progressbar";
import { AddTargetElementComponent } from "common/associations/add-target-element.component";
import { AssociationBrowserDialogComponent } from "common/associations/association-browser-dialog.component";
import { AssociationBrowserComponent } from "common/associations/association-browser.component";
import { AssociationListComponent } from "common/associations/association-list.component";
import { AssociationWorkspaceBrowserComponent } from "common/associations/association-workspace-browser.component";
import { CombinedAssociationDetailViewComponent } from "common/associations/combined-association-detail-view/combined-association-detail-view.component";
import { EditAttributeComponent } from "common/attribute/edit-attribute.component";
import { AuthenticationComponent } from "common/authentication/authentication.component";
import { AutoCompleteComponent } from "common/autoComplete/auto-complete.component";
import { AutofocusDirective } from "common/autofocus/autofocus.directive";
import { AutoGrowDirective } from "common/autoGrow/autogrow.directive";
import { BreadcrumbComponent } from "common/breadcrumb/breadcrumb.component";
import {
	GetAssignableDependentElementsPipe,
	ViewableSelectableBrowserComponent,
} from "common/browser/viewable-selectable-browser.component";
import { ButtonExpandCollapseComponent } from "common/buttons/button-expand-collapse.component";
import { ButtonsBottomComponent } from "common/buttons/buttons-bottom.component";
import { ButtonsResponsiveComponent } from "common/buttons/buttons-responsive.component";
import { ButtonsTileComponent } from "common/buttons/buttons-tile.component";
import { CardComponent } from "common/card/card.component";
import { FitToPageComponent } from "common/card/fitToPageCard/fit-to-page.component";
import { TabCardNoRouteComponent } from "common/card/tab-card-no-route.component";
import { TabCardComponent } from "common/card/tab-card.component";
import { CarouselItemComponent } from "common/carousel/carousel-item.component";
import { CarouselComponent } from "common/carousel/carousel.component";
import { ClickOutsideDirective } from "common/click-outside.directive";
import { ClickStopPropagationDirective } from "common/click-stop-propagation.directive";
import { CollapsibleListComponent } from "common/collapsible-list/collapsible-list.component";
import { ListOfCollapsibleListsComponent } from "common/collapsible-list/list-of-lists.component";
import { HighlightMatchesPipe } from "common/collection/highlight-matches.pipe";
import { MapValuesPipe } from "common/collection/map-values.pipe";
import { ColorPickerComponent } from "common/color-picker/color-picker.component";
import { AddComponent } from "common/data/add.component";
import { EditableComponent } from "common/data/editable.component";
import { GroupedDataViewComponent } from "common/data/grouped-data-view.component";
import { ResponsiveDataViewComponent } from "common/data/responsive-data-view.component";
import { DateTimePickerComponent } from "common/date-time-picker/date-time-picker.component";
import { DebouncedChangeDirective } from "common/debounced-change.directive";
import { ConfirmComponent } from "common/dialog/confirm.component";
import { DialogComponent } from "common/dialog/dialog.component";
import { DialogService } from "common/dialog/dialog.service";
import { WarnComponent } from "common/dialog/warn.component";
import { WorkspaceSelectionDialogComponent } from "common/dialog/workspace-selection-dialog.component";
import { SafePipe } from "common/download/safe.pipe";
import { DragScrollModule } from "common/drag-scroll/drag-scroll.module";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { DescriptionHtmlPipe } from "common/editor/description-html.pipe";
import { DescriptionViewComponent } from "common/editor/description-view.component";
import { DescriptionComponent } from "common/editor/description.component";
import { DescriptionEditorImageMapComponent } from "common/editor/image-plugin/description-editor-imagemap.component";
import { ImagePluginComponent } from "common/editor/image-plugin/image-plugin.component";
import { LazyReferenceResolverDirective } from "common/editor/lazy-reference-resolver.directive";
import { ReadonlyDescriptionComponent } from "common/editor/readonly-description.component";
import { ReplaceBigTablesDirective } from "common/editor/replace-big-tables.directive";
import { ResizeImageMapsDirective } from "common/editor/resize-image-maps.directive";
import { TableZoomComponent } from "common/editor/table-zoom.component";
import { ErrorDialogComponent } from "common/exception/error-dialog.component";
import { StagesErrorHandler } from "common/exception/error-handler";
import { ErrorMessageComponent } from "common/exception/error-message.component";
import { ErrorReportComponent } from "common/exception/error-report.component";
import { ErrorComponent } from "common/exception/error.component";
import { UnavailableComponent } from "common/exception/unavailable.component";
import { FloatingActionMenuComponent } from "common/floating-action-menu/floating-action-menu.component";
import { EmailValidatorDirective } from "common/form/email.directive";
import { FocusErrorDirective } from "common/form/focus-error.directive";
import { InputComponent } from "common/form/input/input.component";
import { MaxValidatorDirective } from "common/form/max.directive";
import { ReservedValidatorDirective } from "common/form/reserved.directive";
import { RestrictInputDirective } from "common/form/restrict-input.directive";
import { ValidateOnBlurDirective } from "common/form/validate-on-blur.directive";
import { ImageCropComponent } from "common/image-crop/image-crop.component";
import { ImageMapComponent } from "common/image-map/image-map.component";
import { ZoomPipe } from "common/image-map/zoom.pipe";
import { IndexComponent } from "common/index/index.component";
import { KeyUpDirective } from "common/key-up.directive";
import { ListItemProfileAndCommentTemplateComponent } from "common/list/list-item-profile-and-comment-template.component";
import { ListService } from "common/list/list.service";
import { MediaQueryComponent } from "common/media-query.component";
import { MediaCaseDirective, MediaDefaultDirective, MediaSwitchComponent } from "common/media-switch.component";
import { MenuComponent } from "common/menu/menu.component";
import { NamesPipe } from "common/names.pipe";
import { DialogHeaderComponent } from "common/newdialog/dialog-header.component";
import { DialogWithRouterOutletComponent } from "common/newdialog/dialog-with-router-outlet.component";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { EditNameComponent } from "common/newdialog/edit-name.component";
import { PopupComponent } from "common/newdialog/popup.component";
import { PageHeaderComponent } from "common/page/page-header.component";
import { PagerComponent } from "common/paging/pager.component";
import { PopupContentComponent } from "common/popup/popup-content.component";
import { PopupFooterComponent } from "common/popup/popup-footer.component";
import { PopupHeaderComponent } from "common/popup/popup-header.component";
import { ProcessVersionValidVersionPipe } from "common/process-version-vv.pipe";
import { PercentPipe } from "common/progress/percent.pipe";
import { ProgressBarComponent } from "common/progress/progress-bar.component";
import { CommonProgressComponent } from "common/progress/progress.component";
import { RoundProgressComponent } from "common/progress/round-progress.component";
import { SummaryCardComponent } from "common/progress/summary-card.component";
import { SummaryComponent } from "common/progress/summary.component";
import { ProxyComponent } from "common/proxy/proxy.component";
import { IsSelectedFilterPipe } from "common/selection/is-selected.pipe";
import { SelectionCounter } from "common/selection/selection-counter.pipe";
import { IsVisibleFilterPipe } from "common/selection/selection-visible.pipe";
import { SlotDirective } from "common/slot.directive";
import { SnackbarComponent } from "common/snackbar/snackbar.component";
import { OrderAlphabeticallyPipe } from "common/sort/order-alphabetically.pipe";
import { SpinComponent } from "common/spin/spin.component";
import { StepperComponent } from "common/stepper/stepper.component";
import { SwitchComponent } from "common/switch/switch.component";
import { TabContentNoRouteComponent } from "common/tab/tab-content-no-route.component";
import { TabNoRouteComponent } from "common/tab/tab-no-route.component";
import { TabComponent } from "common/tab/tab.component";
import { TabsComponent } from "common/tab/tabs.component";
import { TableViewerComponent } from "common/tableViewer/table-viewer.component";
import { ToolbarComponent } from "common/toolbar/toolbar.component";
import { TrimDirective } from "common/trim.directive";
import { DropzoneComponent } from "common/upload/dropzone.component";
import { UploadComponent } from "common/upload/upload.component";
import { UserImageComponent } from "common/user-image.component";
import { ProcessVersionComponent, ProcessVersionLabelComponent } from "common/version/process-version.component";
import { WorkspaceCarouselComponent } from "common/workspace/workspace-carousel.component";
import { WorkspaceListComponent } from "common/workspace/workspace-list.component";
import { WorkspaceSelectionComponent } from "common/workspace/workspace-selection.component";
import { WorkspaceTreeComponent } from "common/workspace/workspace-tree.component";
import { ZoomToolbarComponent } from "common/zoom/zoom-toolbar/zoom-toolbar.component";
import { ColorCircleModule } from "ngx-color/circle";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CollapsibleGroupsComponent } from "common/collapsible-groups/collapsible-groups.component";
import { CdkTreeModule } from "@angular/cdk/tree";
import { BackButtonComponent } from "common/breadcrumb/backbutton.component";
import { SelectionStoreIsSelectedPipe } from "common/selection/selectionstore-is-selected.pipe";
import { SelectionStoreIsPartiallySelectedPipe } from "common/selection/selectionstore-is-partially-selected.pipe";
import { CreditComponent } from "common/credits/credit.component";

@NgModule({
	imports: [
		RouterModule,
		InfiniteScrollModule,
		RoundProgressModule,
		ReactiveFormsModule,
		CommonModule,
		FormsModule,
		TranslateModule,
		DragScrollModule,
		ColorCircleModule,
		DragDropModule,
		CdkTreeModule,
	],
	providers: [
		DialogService, // Workaround for https://github.com/angular/angular/issues/14324 (see WP 35719)
		ListService,
		[{ provide: ErrorHandler, useClass: StagesErrorHandler }],
		DescriptionHtmlPipe,
	],
	declarations: [
		AutoCompleteComponent,
		AutoGrowDirective,
		AutofocusDirective,
		FocusErrorDirective,
		GetAssignableDependentElementsPipe,
		MediaCaseDirective,
		MediaDefaultDirective,
		MediaSwitchComponent,
		MediaQueryComponent,
		UserImageComponent,
		MaxValidatorDirective,
		ReservedValidatorDirective,
		RestrictInputDirective,
		ValidateOnBlurDirective,
		EmailValidatorDirective,
		TabsComponent,
		TabComponent,
		TableViewerComponent,
		CardComponent,
		ToolbarComponent,
		ZoomToolbarComponent,
		MenuComponent,
		PagerComponent,
		DropzoneComponent,
		UploadComponent,
		SpinComponent,
		SnackbarComponent,
		BreadcrumbComponent,
		ViewableSelectableBrowserComponent,
		ProgressBarComponent,
		InputComponent,
		TrimDirective,
		DescriptionComponent,
		DescriptionEditorComponent,
		TableZoomComponent,
		DescriptionViewComponent,
		TableZoomComponent,
		DescriptionHtmlPipe,
		LazyReferenceResolverDirective,
		ResizeImageMapsDirective,
		ReplaceBigTablesDirective,
		ImagePluginComponent,
		DescriptionEditorImageMapComponent,
		OrderAlphabeticallyPipe,
		EditAttributeComponent,
		SwitchComponent,
		ErrorDialogComponent,
		ErrorReportComponent,
		ErrorMessageComponent,
		UnavailableComponent,
		ErrorComponent,
		ResponsiveDataViewComponent,
		GroupedDataViewComponent,
		AddComponent,
		EditableComponent,
		DebouncedChangeDirective,
		KeyUpDirective,
		StepperComponent,
		RoundProgressComponent,
		PercentPipe,
		NamesPipe,
		CommonProgressComponent,
		AddComponent,
		WorkspaceListComponent,
		WorkspaceCarouselComponent,
		WorkspaceSelectionComponent,
		WorkspaceTreeComponent,
		SummaryComponent,
		SummaryCardComponent,
		ImageMapComponent,
		ImageCropComponent,
		ZoomPipe,
		TabNoRouteComponent,
		TabCardNoRouteComponent,
		TabCardComponent,
		TabContentNoRouteComponent,
		ConfirmComponent,
		WarnComponent,
		DialogComponent,
		WorkspaceSelectionDialogComponent,
		SlotDirective,
		AuthenticationComponent,
		FloatingActionMenuComponent,
		ClickOutsideDirective,
		ClickStopPropagationDirective,
		IndexComponent,
		ProxyComponent,
		ProcessVersionComponent,
		NewDialogComponent,
		DialogWithRouterOutletComponent,
		EditNameComponent,
		DialogHeaderComponent,
		PopupComponent,
		SafePipe,
		ProcessVersionLabelComponent,
		CollapsibleListComponent,
		ListOfCollapsibleListsComponent,
		AssociationListComponent,
		AssociationBrowserComponent,
		AssociationBrowserDialogComponent,
		AddTargetElementComponent,
		AssociationWorkspaceBrowserComponent,
		ReadonlyDescriptionComponent,
		FitToPageComponent,
		MapValuesPipe,
		HighlightMatchesPipe,
		ProcessVersionValidVersionPipe,
		CarouselComponent,
		CarouselItemComponent,
		IsSelectedFilterPipe,
		SelectionStoreIsSelectedPipe,
		SelectionStoreIsPartiallySelectedPipe,
		IsVisibleFilterPipe,
		SelectionCounter,
		PageHeaderComponent,
		PopupHeaderComponent,
		PopupFooterComponent,
		PopupContentComponent,
		ColorPickerComponent,
		ButtonsBottomComponent,
		ButtonsResponsiveComponent,
		ListItemProfileAndCommentTemplateComponent,
		ButtonsTileComponent,
		DateTimePickerComponent,
		CombinedAssociationDetailViewComponent,
		ButtonExpandCollapseComponent,
		CollapsibleGroupsComponent,
		BackButtonComponent,
		CreditComponent,
	],
	exports: [
		RouterModule,
		InputComponent,
		TrimDirective,
		AutoCompleteComponent,
		AutoGrowDirective,
		AutofocusDirective,
		FocusErrorDirective,
		ReactiveFormsModule,
		CommonModule,
		FormsModule,
		MediaCaseDirective,
		MediaDefaultDirective,
		MediaSwitchComponent,
		MediaQueryComponent,
		UserImageComponent,
		TranslateModule,
		MaxValidatorDirective,
		ReservedValidatorDirective,
		RestrictInputDirective,
		ValidateOnBlurDirective,
		EmailValidatorDirective,
		TabsComponent,
		TabComponent,
		TableViewerComponent,
		CardComponent,
		ToolbarComponent,
		ZoomToolbarComponent,
		MenuComponent,
		PagerComponent,
		DropzoneComponent,
		UploadComponent,
		SpinComponent,
		SnackbarComponent,
		BreadcrumbComponent,
		ViewableSelectableBrowserComponent,
		ProgressBarComponent,
		DescriptionComponent,
		DescriptionEditorComponent,
		TableZoomComponent,
		NamesPipe,
		OrderAlphabeticallyPipe,
		EditAttributeComponent,
		ResponsiveDataViewComponent,
		GroupedDataViewComponent,
		AddComponent,
		EditableComponent,
		DebouncedChangeDirective,
		KeyUpDirective,
		StepperComponent,
		RoundProgressComponent,
		CommonProgressComponent,
		SummaryComponent,
		ImageMapComponent,
		ImageCropComponent,
		TabNoRouteComponent,
		TabCardNoRouteComponent,
		TabCardComponent,
		TabContentNoRouteComponent,
		ConfirmComponent,
		DialogComponent,
		WorkspaceSelectionComponent,
		SlotDirective,
		WorkspaceTreeComponent,
		FloatingActionMenuComponent,
		ClickOutsideDirective,
		ClickStopPropagationDirective,
		IndexComponent,
		ProcessVersionComponent,
		NewDialogComponent,
		DialogWithRouterOutletComponent,
		EditNameComponent,
		DialogHeaderComponent,
		PopupComponent,
		CollapsibleListComponent,
		ListOfCollapsibleListsComponent,
		AssociationListComponent,
		DescriptionHtmlPipe,
		DescriptionViewComponent,
		ProcessVersionValidVersionPipe,
		ResizeImageMapsDirective,
		ReplaceBigTablesDirective,
		ReadonlyDescriptionComponent,
		FitToPageComponent,
		DragScrollModule,
		SafePipe,
		ColorCircleModule,
		PageHeaderComponent,
		PopupHeaderComponent,
		PopupFooterComponent,
		PopupContentComponent,
		ColorPickerComponent,
		ButtonsBottomComponent,
		ButtonsResponsiveComponent,
		ListItemProfileAndCommentTemplateComponent,
		ButtonsTileComponent,
		DateTimePickerComponent,
		CombinedAssociationDetailViewComponent,
		ButtonExpandCollapseComponent,
		CollapsibleGroupsComponent,
		BackButtonComponent,
		ProcessVersionLabelComponent,
	],
})
export class StagesCommonModule {}
