<article class="user-settings" *ngIf="data$ | async as data">
	<header class="page-header">
		<ul class="breadcrumb">
			<li>
				<a class="back" (click)="close()">
					<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
				</a>
			</li>
		</ul>
	</header>
	<div class="wrapper">
		<ng-container
			*ngTemplateOutlet="
				baseTemplate;
				context: { $implicit: data, category: categoryType.profile, hideHorizontalRuler: true }
			"
		></ng-container>

		<ng-container
			*ngTemplateOutlet="baseTemplate; context: { $implicit: data, category: categoryType.display }"
		></ng-container>

		<ng-container *ngIf="data.userSettings.actions.CHANGE_PASSWORD">
			<ng-container
				*ngTemplateOutlet="baseTemplate; context: { $implicit: data, category: categoryType.security }"
			></ng-container>
		</ng-container>

		<ng-container *ngIf="data.userSettings.actions.DEPENDENCIES">
			<hr />
			<ng-container *ngTemplateOutlet="versionTemplate; context: { $implicit: data }"></ng-container>
		</ng-container>
	</div>
</article>

<ng-template #baseTemplate let-data let-category="category" let-hideHorizontalRuler="hideHorizontalRuler">
	<hr *ngIf="!hideHorizontalRuler" />
	<article class="card relative">
		<ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: data, category: category }"></ng-container>

		<ng-container [ngSwitch]="category">
			<ng-container *ngSwitchCase="categoryType.profile">
				<div class="form remove-margin-top">
					<div class="input-container">
						<label
							for="user-settings-profilepicture"
							class="input-label"
							translate="user.settings.profilePicture"
						></label>
						<stages-upload
							id="user-settings-profilepicture"
							*ngIf="!showPreview"
							uploadIcon="ico ico-file"
							fileExtensions=".jpg, .jpeg, .gif, .png, .bmp"
							[expandDroparea]="true"
							[uploadCompletedCallback]="$any(uploadCompletedCallback)"
							(errorEmitter)="handleUploadError($event)"
							[uploadUrl]="data.uploadUrl"
						>
						</stages-upload>
						<stages-image-crop
							*ngIf="showPreview"
							[source]="previewImageUrl"
							(cancel)="cancelProfileImageUpload(data)"
							(save)="saveProfileImage($event, data)"
						>
						</stages-image-crop>
						<div class="info"></div>
					</div>

					<div class="input-container" *ngIf="data.userSettings.actions.CHANGE_EMAIL_ADDRESS">
						<label class="input-label" for="user.settings.email" translate="user.email"></label>
						<input
							class="text fullsize"
							stagesEmail
							[validatorPatterns]="data.applicationContext.validatorPatterns"
							id="user.settings.email"
							#emailControl="ngModel"
							name="email"
							[stagesMaxLength]="255"
							[(ngModel)]="data.userSettings.data.notifications!.emailAddress"
							(stagesDebouncedChange)="onChange(data, emailControl)"
						/>
						<div class="info">
							<div class="errors" *ngIf="emailControl.errors && (emailControl.dirty || emailControl.touched)">
								<div
									class="error"
									*ngIf="emailControl.errors.stagesEmail"
									role="alert"
									translate="user.email.invalid"
								></div>
								<div
									class="error"
									*ngIf="emailControl.errors.max"
									role="alert"
									translate="input.maxLength"
									[translateParams]="{ length: 255 }"
								></div>
							</div>
							<div class="maxlength">{{ emailControl.value?.length || 0 }}/255</div>
						</div>
					</div>

					<div class="input-container">
						<label class="input-label" for="user.settings.language" translate="language"></label>
						<select
							class="fullsize"
							id="user.settings.language"
							name="language"
							[(ngModel)]="data.userSettings.data.application!.language"
							(change)="onChangeLanguage(data)"
						>
							<option
								*ngFor="let langOption of data.userSettings.metadata.availableLanguages"
								value="{{ langOption.id }}"
								translate="{{ langOption.name }}"
							></option>
						</select>
						<div class="info"></div>
					</div>

					<div class="input-container">
						<label class="input-label" for="user.settings.workspace.home" translate="workspace.home"></label>
						<div class="relative browse-workspace" (click)="openWorkspaceSelection(data)">
							<i class="ico ico-workspace"></i>
							<input
								id="user.settings.workspace.home"
								name="homeWorkspace"
								type="text"
								class="text fullsize"
								[(ngModel)]="data.userSettings.data.application!.homeWorkspace.name"
								(focus)="$any($event.target).blur()"
								readonly
							/>
						</div>
						<div class="info"></div>
					</div>

					<div class="input-container">
						<label class="input-label" for="user.settings.searchScope" translate="user.settings.searchScope"></label>
						<select
							class="fullsize"
							id="user.settings.searchScope"
							name="serachScope"
							[(ngModel)]="data.userSettings.data.application!.searchScope"
							(change)="onChange(data)"
						>
							<option
								*ngFor="let searchScopeOption of data.userSettings.metadata.availableSearchScopes"
								value="{{ searchScopeOption.id }}"
								translate="{{ searchScopeOption.name }}"
							></option>
						</select>
						<div class="info"></div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="categoryType.display">
				<div class="form">
					<div class="input-container">
						<label
							class="input-label"
							for="user.settings.tailoringDetails"
							translate="process.tailoring.tailoringDetails"
						></label>
						<p translate="process.tailoring.tailoringDetails.secondary"></p>
						<input
							id="user.settings.tailoringDetails"
							type="checkbox"
							hidden="hidden"
							name="tailoringDetails"
							[(ngModel)]="data.userSettings.data.application!.showTailoringDetails"
							(change)="onChangeTailoring(data)"
						/>
						<label for="user.settings.tailoringDetails" class="switch"></label>
						<div class="info"></div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="categoryType.security">
				<form class="form" [formGroup]="data.passwordForm">
					<div class="input-container">
						<label class="input-label" for="user.settings.oldPassword" translate="user.oldPassword"></label>
						<input
							class="text fullsize"
							type="password"
							id="user.settings.oldPassword"
							name="oldPassword"
							formControlName="oldPassword"
							autocomplete="off"
						/>
						<div class="info">
							<div class="errors">
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'oldPassword', 'required')"
									role="alert"
									translate="input.required"
								></div>
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'oldPassword', 'incorrect')"
									role="alert"
									translate="user.password.incorrect"
								></div>
							</div>
						</div>
					</div>
					<div class="input-container">
						<label class="input-label" for="user.settings.newPassword" translate="user.password"></label>
						<input
							class="text fullsize"
							type="password"
							name="newPassword"
							id="user.settings.newPassword"
							formControlName="newPassword"
							(input)="validatePasswordsMatching(data)"
							required
							autocomplete="new-password"
						/>
						<div class="info">
							<div class="errors">
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPassword', 'required')"
									role="alert"
									translate="input.required"
								></div>
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPassword', 'minlength')"
									role="alert"
									translate="input.minLength"
									[translateParams]="{ length: data.userSettings.metadata.passwordMinLength }"
								></div>
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPassword', 'pattern')"
									role="alert"
									translate="user.password.pattern"
								></div>
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPassword', 'regex')"
									role="alert"
									translate="user.password.regex"
								></div>
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPassword', 'userdata')"
									role="alert"
									translate="user.password.userdata"
								></div>
							</div>
						</div>
					</div>
					<div class="input-container">
						<label class="input-label" for="user.settings.newPasswordRepeat" translate="user.passwordRepeat"></label>
						<input
							class="text fullsize"
							type="password"
							name="newPasswordRepeat"
							id="user.settings.newPasswordRepeat"
							formControlName="newPasswordRepeat"
							(input)="validatePasswordsMatching(data)"
							autocomplete="new-password"
						/>
						<div class="info">
							<div class="errors">
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPasswordRepeat', 'required')"
									role="alert"
									translate="input.required"
								></div>
								<div
									class="error"
									*ngIf="hasErrorAfterEditing(data, 'newPasswordRepeat', 'passwordMatch')"
									role="alert"
									translate="user.passwordRepeat.notmatching"
								></div>
							</div>
						</div>
					</div>
					<stages-buttons-bottom>
						<button class="lg" (click)="onChangePassword(data)" translate="user.password.change"></button>
					</stages-buttons-bottom>
				</form>
			</ng-container>
		</ng-container>
	</article>
</ng-template>

<ng-template #headerTemplate let-data let-category="category">
	<header class="header" [ngClass]="category">
		<div class="header-title">
			<h2 *ngIf="category === categoryType.profile">{{ data.applicationContext.fullname }}</h2>
			<h2 *ngIf="category !== categoryType.profile" translate="user.settings.{{ category }}"></h2>
		</div>
	</header>
	<stages-user-image
		*ngIf="category === categoryType.profile"
		[userName]="data.applicationContext.fullname"
		userId="{{ data.applicationContext.userId }}"
		version="{{ data.applicationContext.profileImageVersion }}"
	></stages-user-image>
</ng-template>

<ng-template #versionTemplate let-data>
	<stages-card class="dependencies">
		<h5 translate="administration.dashboard.versiondetails"></h5>
		<ul class="list dense">
			<li class="list-item">
				<div class="label pointerOnHidden">
					<div class="text">
						<h4 translate="administration.dashboard.versiondetails.versionnumber"></h4>
						<p>{{ data.userSettings.metadata.systemVersion }} ({{ data.userSettings.metadata.systemStatus }})</p>
					</div>
				</div>
			</li>
			<li class="list-item">
				<div class="label pointerOn">
					<a [routerLink]="'credits'" class="clickable">
						<div class="text">
							<h4 translate="administration.dashboard.supportingtechnologies.credits"></h4>
							<p translate="administration.dashboard.supportingtechnologies.librarydescription"></p>
						</div>
					</a>
				</div>
			</li>
		</ul>
	</stages-card>
</ng-template>
