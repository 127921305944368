export class TextualReferenceUtilBase {
	/**
	 * Fixes invalid textual references from legacy systems by escaping all
	 * special characters that do not act as control characters.<br/>
	 * <br/>
	 * Cases:
	 * <ol>
	 *  <li>Balanced square brackets if they do not act as locator or display delimiters.</li>
	 *  <li>Special characters in general if they are not defined as control characters for the specific reference part.</li>
	 * </ol>
	 *
	 * @see #isControlCharacterInLocator(char)
	 * @see #isControlCharacterInPointer(char)
	 * @see #isControlCharacterInDisplay(char)
	 */
	// eslint-disable-next-line complexity -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static correctReferenceEscaping(originalText: string): string {
		if (!originalText || !originalText.startsWith("[[") || !originalText.endsWith("]]")) {
			throw new Error("Textual reference string is not enclosed by double square brackets: " + originalText);
		}
		const values: string[] = ["[["];

		const hasLocator = originalText.charAt(2) === "[";
		const hasDisplay =
			originalText.charAt(originalText.length - 3) === "]" && originalText.charAt(originalText.length - 4) !== "\\";

		// locator walkthrough
		let brackets = 0;
		let i = 2;
		if (hasLocator) {
			for (; i < originalText.length - 2; i++) {
				if (originalText.charAt(i - 1) === "\\") {
					if (!TextualReferenceUtilBase.isControlCharacterInLocator(originalText.charAt(i))) {
						values.push("\\");
					}
				} else {
					if (originalText.charAt(i) === "[") {
						if (brackets > 0) {
							values.push("\\");
						}
						brackets++;
					} else if (originalText.charAt(i) === "]") {
						brackets--;
						if (brackets === 0) {
							values.push("]");
							i++;
							break;
						}
						values.push("\\");
					} else if (
						TextualReferenceUtilBase.isSpecialCharacter(originalText.charAt(i)) &&
						!TextualReferenceUtilBase.isControlCharacterInLocator(originalText.charAt(i))
					) {
						values.push("\\");
					}
				}
				values.push(originalText.charAt(i));
			}
		}

		// display reverse walkthrough
		let j = originalText.length - 3;
		brackets = 0;
		let display = "";
		if (hasDisplay) {
			for (; j > 2; j--) {
				display = originalText.charAt(j) + display;
				if (originalText.charAt(j - 1) === "\\") {
					if (!TextualReferenceUtilBase.isControlCharacterInDisplay(originalText.charAt(j))) {
						display = "\\" + display;
					}
				} else {
					if (originalText.charAt(j) === "]") {
						if (brackets > 0) {
							display = "\\" + display;
						}
						brackets++;
					} else if (originalText.charAt(j) === "[") {
						brackets--;
						if (brackets === 0) {
							j--;
							break;
						}
						display = "\\" + display;
					} else if (
						TextualReferenceUtilBase.isSpecialCharacter(originalText.charAt(j)) &&
						!TextualReferenceUtilBase.isControlCharacterInDisplay(originalText.charAt(j))
					) {
						display = "\\" + display;
					}
				}
			}
		}

		// pointer walkthrough
		for (let k = i; k <= j; k++) {
			if (!TextualReferenceUtilBase.isControlCharacterInPointer(originalText.charAt(k))) {
				if (originalText.charAt(k - 1) === "\\") {
					values.push("\\");
				} else {
					if (TextualReferenceUtilBase.isSpecialCharacter(originalText.charAt(k))) {
						values.push("\\");
					}
				}
			}
			values.push(originalText.charAt(k));
		}

		if (hasDisplay) {
			values.push(display);
		}
		values.push("]]");

		return values.join("");
	}

	/**
	 * Returns <code>true</code> if the character has a special meaning in at least one textual reference part,
	 * and therefore must be escaped within names.
	 */
	static isSpecialCharacter(ch: string): boolean {
		return (
			TextualReferenceUtilBase.isControlCharacterInLocator(ch) ||
			TextualReferenceUtilBase.isControlCharacterInPointer(ch) ||
			TextualReferenceUtilBase.isControlCharacterInDisplay(ch)
		);
	}

	/**
	 * Returns <code>true</code> if the character is a control character in the locator part.
	 */
	static isControlCharacterInLocator(ch: string): boolean {
		switch (ch) {
			case "\\":
			case "[":
			case "]":
			case "|":
			case ":":
			case "/":
				return true;
			default:
				return false;
		}
	}

	/**
	 * Returns <code>true</code> if the character is a control character in the pointer part.
	 */
	static isControlCharacterInPointer(ch: string): boolean {
		switch (ch) {
			case "\\":
			case "|":
			case "#":
				return true;
			default:
				return false;
		}
	}

	/**
	 * Returns <code>true</code> if the character is a control character in the display part.
	 */
	static isControlCharacterInDisplay(ch: string): boolean {
		switch (ch) {
			case "\\":
			case "[":
			case "]":
				return true;
			default:
				return false;
		}
	}

	/**
	 * Converts the path to a single pipe separated path string to be used for Target lookup
	 * @param unescapedPath as stored by the ITextualReference
	 * @return a pipe separated path string
	 */
	static toUnescapedPathString(unescapedPath: string[]): string {
		return unescapedPath.join("|");
	}
}
