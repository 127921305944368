import { Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild } from "@angular/core";
import { ToolbarItem } from "common/toolbar/toolbar-item.interface";
import { UtilService } from "common/util.service";
import { ZoomToolbarService } from "common/zoom/zoom-toolbar/zoom-toolbar.service";
import { PreferencesService } from "core/preferences.service";
import { Subscription } from "rxjs";

const MINIMAL_COLLAPSIBLE_CARD_HEIGHT = "72px";

@Component({
	selector: "stages-card",
	templateUrl: "./card.component.html",
	providers: [ZoomToolbarService],
})
export class CardComponent implements OnDestroy {
	@Input()
	toolbarId?: string;

	@Input()
	toolbarItems: ToolbarItem[] = [];

	@Input()
	menuId?: string;

	@Input()
	menuItems: MenuItem[] = [];

	@Input()
	menuNoCollapse?: boolean;

	// needed to to toggle child component and to detect if
	// a card menu function has to be force triggered
	@Input()
	externalToggleFunctions: MenuItem[] = [];

	@Input()
	contentCollapsible?: boolean;

	@Input()
	showCollapseOption?: boolean;

	@Input()
	showHeader: boolean = true;

	@ViewChild("tabsContainer")
	tabsContainer?: ElementRef;

	// To collapse the card till the headline,
	// you have to change the min-height attribute
	@HostBinding("style.min-height")
	minHeight = "";

	expanded: boolean = true;
	title?: string;
	showZoomToolbar: boolean = false;
	private subscription?: Subscription;
	isIE: boolean = false;

	constructor(
		readonly zoomToolbarService: ZoomToolbarService,
		readonly preferencesService: PreferencesService,
		readonly utilService: UtilService,
	) {
		if (this.zoomToolbarService.isBrowserSupported) {
			this.subscription = this.zoomToolbarService.amountOfVisibleDiagrams.subscribe({
				next: (value) => (this.showZoomToolbar = value > 0),
			});
			this.zoomToolbarService.applyZoomParametersFromLocalStorage();
		}
		this.isIE = utilService.browserIsIE();
	}

	resetHeight(): void {
		this.minHeight = this.contentCollapsible ? MINIMAL_COLLAPSIBLE_CARD_HEIGHT : "";
	}

	toggleContentView(): void {
		if (this.externalToggleFunctions.length > 0) {
			this.expanded = !this.expanded;
			this.externalToggleFunctions.forEach((externalToggle) => externalToggle.on());
		}
	}

	// Expands the content that is collapsed by toggle function
	forceShowContent(): void {
		if (!this.expanded) {
			this.toggleContentView();
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
