import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PdfComponent } from "print/pdf.component";
import { PrintComponent } from "print/print.component";
import { TemplateDefinitionsResolver } from "print/template-definitions-resolver.service";

export const printRoutes: Routes = [
	{
		path: "",
		component: PrintComponent,
		children: [
			{
				path: "pdf",
				component: PdfComponent,
				resolve: {
					templateDefinitions: TemplateDefinitionsResolver,
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(printRoutes)],
})
export class PrintRoutingModule {}
