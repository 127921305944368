import { Component, HostBinding } from "@angular/core";

@Component({
	selector: "stages-list-of-lists",
	templateUrl: "./list-of-lists.component.html",
})
export class ListOfCollapsibleListsComponent {
	@HostBinding("class")
	group = "grouped-list";
}
