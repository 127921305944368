import { Component, Input } from "@angular/core";
import { SlotService } from "common/slot.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { TabType } from "process/view/type.interface";

@Component({
	selector: "stages-tab-content-dynamic",
	templateUrl: "./tab-content-dynamic.component.html",
	styleUrls: ["./tab-content-dynamic.component.scss"],
	providers: [SlotService],
})
export class TabContentDynamicComponent extends BaseComponent {
	@Input()
	override type?: TabType;

	constructor(componentService: ComponentService, slotService: SlotService) {
		super(componentService, slotService);
	}
}
