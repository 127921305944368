import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AssociationSource } from "common/associations/association-browser.service";
import { Association, AssociationTarget } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { AssociationTargetSearchQuery, AssocSearchResult } from "common/associations/search-query.logic";
import { AutoCompleteComponent } from "common/autoComplete/auto-complete.component";
import { SearchQuery } from "common/autoComplete/search-query";
import { DialogService } from "common/dialog/dialog.service";

@Component({
	styleUrls: ["add-target-element.component.scss"],
	selector: "stages-add-target-element",
	templateUrl: "./add-target-element.component.html",
})
export class AddTargetElementComponent<S extends AssociationSource<T>, T extends AssociationTarget> {
	@Input()
	sourceElement!: stages.core.TypedId;

	@Input()
	sourceRole?: string;

	@Input()
	targetElementWorkspace?: string;

	@Input()
	targetElementType!: string;

	@Input()
	targetElementSubtypes!: string[];

	@Input()
	targetElementParentId?: string;

	@Input()
	targetElementProcessIds?: string[];

	@Input()
	targetElementCreateAllowed!: boolean;

	@Input()
	commentOnlyAssociationsAllowed!: boolean;

	@Input()
	associationType!: string;

	@Input()
	targetElementsToIgnore: T[] = [];

	@Input()
	associationStore!: AssociationStore<S, T>;

	@Input()
	remoteWorkspaceSeparatorInAutocomplete = true;

	@Input()
	restrictAssociateDependentElementTypes?: string[];

	@Output() readonly addedCallback = new EventEmitter<Association<T>>();

	@Output() readonly cancelled = new EventEmitter<void>();

	@ViewChild("autoComplete")
	autoCompleteComponent!: AutoCompleteComponent<AssocSearchResult>;

	dependentElementContainer?: AssocSearchResult;

	constructor(
		private route: ActivatedRoute,
		private readonly dialogService: DialogService,
		private readonly translateService: TranslateService,
	) {}

	getSearchQuery(): SearchQuery<AssocSearchResult> {
		return new AssociationTargetSearchQuery(
			this.translateService,
			this.associationStore,
			this.targetElementType,
			this.targetElementSubtypes,
			this.targetElementsToIgnore,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.getTargetElementWorkspaceId(),
			this.route.snapshot.paramMap.get("processVersion")!,
			this.targetElementCreateAllowed,
			this.commentOnlyAssociationsAllowed,
			this.restrictAssociateDependentElementTypes,
			this.dependentElementContainer ? this.dependentElementContainer : undefined,
			this.dependentElementContainer ? this.dependentElementContainer.id : this.targetElementParentId,
			this.targetElementProcessIds,
		);
	}

	getInputIconClasses(): string[] {
		return this.associationStore.getInputClasses(
			this.targetElementType,
			!!this.dependentElementContainer,
			undefined,
			"ico",
		);
	}

	getIconClasses(element: AssocSearchResult, additionalClasses?: string): string[] {
		return this.associationStore.getInputClasses(
			element.type.ident,
			!!this.dependentElementContainer,
			element.type.subtypeIdent,
			additionalClasses,
		);
	}

	getLabelClasses(element: AssocSearchResult, additionalClasses?: string): string[] {
		return this.associationStore.getQuickAssignLabelClasses(element, additionalClasses);
	}

	addNewAssociationToList(association: Association<T>): void {
		this.addedCallback.emit(association);
	}

	async selectItemCallback(element: AssocSearchResult): Promise<void> {
		const currentWorkspaceId = this.route.snapshot.paramMap.get("workspaceId")!;
		if (element.isNew) {
			if (element.isComment) {
				this.createCommentOnlyAssociation(element, currentWorkspaceId);
			} else if (this.elementWithSameLabelExists(element)) {
				if (await this.confirmCreationOfElementWithSameName(element)) {
					this.createAssociationToNewTargetElement(element, currentWorkspaceId);
				}
			} else {
				this.createAssociationToNewTargetElement(element, currentWorkspaceId);
			}
		} else if (
			element.dependentElementsContainer &&
			(this.restrictAssociateDependentElementTypes === undefined ||
				this.restrictAssociateDependentElementTypes.length > 0)
		) {
			this.dependentElementContainer = element;
		} else {
			this.createAssociationToExistingElement(element, currentWorkspaceId);
		}
	}

	private async confirmCreationOfElementWithSameName(element: AssocSearchResult): Promise<boolean> {
		return this.dialogService.confirm(
			"search.autoComplete.assocTarget.sameNameExistsCreateConfirm",
			{ name: element.label },
			"create",
			"cancel",
			false,
		);
	}

	private createAssociationToNewTargetElement(element: AssocSearchResult, currentWorkspaceId: string): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const parent: any = {};
		parent.id = element.parentId;
		parent.type = {
			ident: this.targetElementType,
		};
		parent.children = [];

		this.associationStore
			.createAssociationToNewTargetElement(
				this.sourceElement,
				parent,
				element.type.ident,
				element.type.subtypeIdent,
				element.name,
				this.associationType,
				this.sourceRole,
				currentWorkspaceId,
				this.route.snapshot.paramMap.get("processVersion")!,
				element.dependent,
			)
			.then((assoc) => this.addNewAssociationToList(assoc));
	}

	private createCommentOnlyAssociation(element: AssocSearchResult, currentWorkspaceId: string): void {
		this.associationStore
			.createCommentOnlyAssociation(
				this.sourceElement,
				element.type.ident,
				element.type.subtypeIdent,
				this.associationType,
				this.sourceRole,
				currentWorkspaceId,
				this.route.snapshot.paramMap.get("processVersion")!,
				element.name,
			)
			.then((assoc) => this.addNewAssociationToList(assoc));
	}

	private createAssociationToExistingElement(element: AssocSearchResult, currentWorkspaceId: string): void {
		this.associationStore
			.createAssociation(
				this.sourceElement,
				element.id!,
				element.type.ident,
				this.associationType,
				this.sourceRole,
				currentWorkspaceId,
				this.route.snapshot.paramMap.get("processVersion")!,
			)
			.then((assoc) => this.addNewAssociationToList(assoc));
	}

	private elementWithSameLabelExists(element: AssocSearchResult): boolean {
		return element.dependent
			? this.autoCompleteComponent.subordinatedResults!.filter(
					(targetElement) => targetElement.name.toLowerCase() === element.name.toLowerCase() && !targetElement.isNew,
			  ).length > 0
			: this.autoCompleteComponent.results.filter(
					(targetElement) => targetElement.name.toLowerCase() === element.name.toLowerCase() && !targetElement.isNew,
			  ).length > 0;
	}

	cancel(): void {
		this.cancelled.emit();
	}

	back(): void {
		this.dependentElementContainer = undefined;
	}

	getTargetElementWorkspaceId(): string {
		return this.targetElementWorkspace ? this.targetElementWorkspace : this.route.snapshot.paramMap.get("workspaceId")!;
	}

	getIsSubordinatedElementsMode(): boolean {
		return this.dependentElementContainer !== undefined;
	}
}
