<header *ngIf="showHeader">
	<div class="wrapper">
		<!-- This small hack is needed for a proper headline aligning in ie11 -->
		<div *ngIf="isIE" style="height: 48px"></div>
		<ng-content *ngIf="!title" select="h5"></ng-content>
		<ng-content select="[switchFilter]"></ng-content>
		<ng-content select="header"></ng-content>
		<stages-toolbar [toolbarId]="toolbarId" [items]="toolbarItems"></stages-toolbar>
		<ng-container *ngIf="showZoomToolbar && expanded">
			<div style="flex-grow: 1"></div>
			<stages-zoom-toolbar></stages-zoom-toolbar>
		</ng-container>
		<stages-button-expand-collapse
			*ngIf="contentCollapsible && showCollapseOption"
			class="action"
			[classes]="'menu-button'"
			[isCollapsed]="!expanded"
			(pressedButton)="toggleContentView()"
		>
		</stages-button-expand-collapse>
		<stages-menu
			class="action"
			[menuId]="menuId"
			iconClasses="ico ico-more-vertical"
			openTo="bottom"
			[items]="menuItems"
			[noCollapse]="menuNoCollapse"
			(click)="forceShowContent()"
		></stages-menu>
	</div>
</header>

<ng-content></ng-content>
