import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { PluginComponent } from "common/editor/plugin.service";
import { ListService } from "common/list/list.service";
import { MainService } from "core/main.service";
import { ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { ProcessElementPluginComponent } from "process/description/link-plugin/process-element.plugin.component";

type ProcessTreeItem = stages.process.ProcessTreeItem;
type ProcessView = stages.process.ProcessView;

const htmlTemplateIdent = "htmlTemplate";

@Component({
	animations: [
		trigger("slide", [
			state(
				"browse",
				style({
					transform: "translateX(0%)",
				}),
			),
			state(
				"preview",
				style({
					transform: "translateX(-50%)",
				}),
			),
			transition("* => *", animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
	selector: "stages-description-editor-htmltemplate",
	templateUrl: "./htmltemplate-plugin.component.html",
})
export class HtmlTemplatePluginComponent extends ProcessElementPluginComponent implements OnInit, PluginComponent {
	viewState: string = "browse";
	previewTitle?: string;
	previewHtml?: string;

	constructor(
		private mainService: MainService,
		processElementsResource: ProcessElementsResource,
		private viewService: ViewService,
		private descriptionEditor: DescriptionEditorComponent,
		listService: ListService,
	) {
		super(processElementsResource, listService);
	}

	async ngOnInit(): Promise<void> {
		this.currentWorkspace = (await this.mainService.getCurrentWorkspace()).currentWorkspace;
		this.updateFolder("description", "", this.getOriginWorkspaceId());
		this.dialog.registerDialogCancelHandler(() => {
			return true;
		});
	}

	navigateToStart(): void {
		this.updateFolder("description", "", this.browseWorkspace.id);
	}

	async setBrowseView(workspaceId: string, type: string, identity: string): Promise<ProcessView> {
		const view = !identity
			? await this.processElementsResource.getIndexViewPerSubtype(workspaceId, type, htmlTemplateIdent, {
					browsePV: this.dialog.getProcessVersionIdentifier(),
					lang: this.descriptionEditor.displayDescription.language
						? this.descriptionEditor.displayDescription.language
						: undefined,
			  })
			: await this.processElementsResource.getBrowseViewPerSubtype(workspaceId, type, identity, htmlTemplateIdent, {
					browsePV: this.dialog.getProcessVersionIdentifier(),
					lang: this.descriptionEditor.displayDescription.language
						? this.descriptionEditor.displayDescription.language
						: undefined,
			  });
		this.browseWorkspace = view.viewWorkspace;
		return this.viewService.getSelf(view.processView);
	}

	async toggleDetails(element?: ProcessTreeItem): Promise<void> {
		if (element) {
			const lang = this.descriptionEditor.displayDescription.language
				? this.descriptionEditor.displayDescription.language
				: undefined;
			this.previewHtml = await this.getEditableDescription(element, true, lang);
			this.previewTitle = element.label;
			this.viewState = "preview";
		} else {
			this.viewState = "browse";
		}
	}

	async insertLinkTo(element: ProcessTreeItem): Promise<void> {
		if (element.type.subtypeIdent !== htmlTemplateIdent) {
			return;
		}
		const lang = this.descriptionEditor.displayDescription.language
			? this.descriptionEditor.displayDescription.language
			: undefined;
		const descriptionHtml = await this.getEditableDescription(element, true, lang);
		if (descriptionHtml) {
			this.onInsert(descriptionHtml);
		} else {
			this.previewTitle = element.label;
			this.viewState = "preview";
		}
	}

	onInsert(html: string): void {
		if (html) {
			this.dialog.insertHtml(html);
		}
		this.dialog.close();
	}

	onSlideDone(): void {
		if (this.viewState === "browse") {
			this.previewHtml = undefined;
			this.previewTitle = undefined;
		}
	}
}
