import { Group, GroupDefinition } from "common/associations/association-group.service";
import { Association } from "common/associations/association-list.component";
import { AssociationStore } from "common/associations/association-store.interface";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { Assert } from "core/assert";
import { assertDefined, nullToUndefined } from "core/functions";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { AssociationService } from "process/associations/association.service";
import { AddService } from "process/element/add/add.service";
import { AssociationListType, ComponentType, TabbedCardType, ViewSpec } from "process/view/type.interface";
import { lastValueFrom, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AssociationTargetLabelService } from "common/associations/association-target-label.service";
import ViewableElement = stages.process.ViewableElement;
import ViewableSourceElement = stages.process.ViewableSourceElement;

export class ModelAssociationStore implements AssociationStore<ViewableSourceElement, ViewableElement> {
	constructor(
		private viewService: ViewService,
		private associationService: AssociationService,
		private associationTargetLabelService: AssociationTargetLabelService,
		private elementAddService: AddService,
		private mainService: MainService,
	) {}

	getAddMenuEntryNameKey(group: Group<ViewableElement>): string {
		return group.translate! + ".add";
	}

	getAddMenuEntryNameKeyParam(_group: Group<ViewableElement>): string | undefined {
		return undefined;
	}

	getAddMenuEntryNameKeyParamValue(_group: Group<ViewableElement>): string | undefined {
		return undefined;
	}

	getInputClasses(
		typeIdent: string | undefined,
		isDependentElement: boolean,
		subtypeIdent: string | null,
		additionalClasses?: string,
	): string[] {
		return this.viewService.getIconClasses(
			{
				ident: typeIdent!,
				subtypeIdent: subtypeIdent,
			},
			isDependentElement,
			false,
			additionalClasses,
		);
	}

	getIconClasses(targetElement: ViewableElement, isDependentElement: boolean, additionalClasses?: string): string[] {
		return this.viewService.getIconClasses(
			targetElement.type,
			targetElement.index,
			isDependentElement,
			additionalClasses,
		);
	}

	getLabelClasses(
		sourceElement: stages.process.ViewableSourceElement,
		targetElement: ViewableElement,
		_additionalClasses?: string | undefined,
	): string[] {
		if (targetElement.tailored) {
			return ["tailored"];
		}
		if (sourceElement.dependent && sourceElement.tailored && !sourceElement.parent?.tailored) {
			return ["tailored"];
		}
		return [];
	}

	getQuickAssignLabelClasses(searchResult: AssocSearchResult, _additionalClasses?: string | undefined): string[] {
		return searchResult.tailored ? ["tailored"] : [];
	}

	getActions(association: Association<ViewableElement>): StringToBoolean {
		return association.allowedOperations!;
	}

	getTargetElementActions(targetElement: ViewableElement): StringToBoolean {
		return targetElement.allowedOperations;
	}

	getSourceElement(
		_type: string,
		identity: string,
		_workspaceId: string,
		_pv: string,
	): Observable<ViewableSourceElement> {
		const viewableElementObservable =
			this.viewService.awaitSelfElementObservable() as unknown as Observable<ViewableElement>;
		return viewableElementObservable.pipe(
			map((self) => {
				if (self.identity === identity || self.dependentElements?.length === 0) {
					return self;
				}

				return ModelAssociationStore.getDependentSourceElement(self, identity);
			}),
		);
	}

	private static getDependentSourceElement(
		self: stages.process.ViewableElement,
		identity: string,
	): ViewableSourceElement {
		const matchingDependentElement = self.dependentElements!.find(
			(dependentElement) => dependentElement.identity === identity,
		)!;
		const filteredAssocs: Record<string, stages.process.ViewableAssociationGroup> = {};
		for (const groupPath in self.associations) {
			const group = self.associations[groupPath];
			const filteredList: stages.process.ViewableAssociation[] = [];
			for (const association of group.list) {
				if (association.sourceElement!.identity === identity) {
					filteredList.push(association);
				}
			}
			filteredAssocs[groupPath] = group;
			group.list = filteredList;
		}
		const result: ViewableSourceElement = {
			...matchingDependentElement,
			associations: filteredAssocs,
			parent: self,
		};
		result.associations = filteredAssocs;
		result.parent = self;
		return result;
	}

	getAdditionalSourceElements(sourceElement: ViewableElement): stages.process.ViewableSourceElement[] {
		const assignableDependentElements = sourceElement.dependentElements
			?.filter((dependentElement) => dependentElement.type.isAssignableDependentElement)
			.map((d) => {
				return {
					...d,
					dependent: true,
					associations: {} as Record<string, stages.process.ViewableAssociationGroup>,
					parent: undefined,
				};
			});
		return assignableDependentElements ?? [];
	}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async getParentForNewTargetElement(
		selfElement: ViewableElement,
		targetType: string,
	): Promise<ViewableElement | null> {
		if (this.isTargetAndSourceSameType(selfElement, targetType)) {
			return selfElement.parent === undefined ? null : (selfElement.parent as unknown as ViewableElement);
		}
		let indexOfTargetType = null;
		if (selfElement.process?.indexElements !== undefined) {
			selfElement.process.indexElements.forEach((indexElem: ViewableElement) => {
				if (indexElem.type.ident === targetType) {
					indexOfTargetType = indexElem;
				}
			});
		}

		return indexOfTargetType;
	}

	isTargetAndSourceSameType(selfElement: ViewableElement, targetType: string): boolean {
		return selfElement.type.ident === targetType;
	}

	asTypedId(sourceElement: ViewableElement): stages.core.TypedId {
		return {
			typeIdent: sourceElement.type.ident,
			id: sourceElement.id,
		};
	}

	async createAssociationToNewTargetElement(
		sourceElement: stages.core.TypedId,
		newElementParent: ViewableElement,
		newtargetType: string,
		newtargetSubtype: string | null,
		name: string,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
		dependent: boolean,
	): Promise<Association<ViewableElement>> {
		return this.elementAddService
			.addChild(
				newElementParent,
				{
					ident: newtargetType,
					subtypeIdent: newtargetSubtype,
				},
				name,
				workspaceId,
				pv,
				dependent,
			)
			.then(async (newTargetElement: ViewableElement) => {
				return this.createAssociation(
					sourceElement,
					newTargetElement.id,
					newTargetElement.type.ident,
					associationType,
					sourceRole,
					workspaceId,
					pv,
				);
			});
	}

	async createAssociation(
		sourceElement: stages.core.TypedId,
		targetElementId: string,
		targetElementType: string,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
	): Promise<Association<ViewableElement>> {
		return this.associationService
			.createAssociation(
				sourceElement.id,
				sourceElement.typeIdent,
				targetElementId,
				targetElementType,
				associationType,
				sourceRole,
				workspaceId,
				pv,
			)
			.then(asAssociation)
			.then((assoc) => {
				this.viewService.refreshView(workspaceId, pv);
				return assoc;
			});
	}

	async createCommentOnlyAssociation(
		sourceElement: stages.core.TypedId,
		targetType: string,
		targetSubtype: string | null,
		associationType: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<Association<ViewableElement>> {
		return this.associationService
			.createCommentOnlyAssociation(
				sourceElement.id,
				sourceElement.typeIdent,
				targetType,
				targetSubtype,
				associationType,
				sourceRole,
				workspaceId,
				pv,
				comment,
			)
			.then(asAssociation)
			.then((assoc) => {
				this.viewService.refreshView(workspaceId, pv);
				return assoc;
			});
	}

	async deleteAssociations(
		associationsToDelete: Association<ViewableElement>[],
		workspaceId: string,
		pv: string,
	): Promise<Association<ViewableElement>[]> {
		return this.associationService
			.deleteAssociations(associationsToDelete as unknown as stages.process.ViewableAssociation[], workspaceId, pv)
			.then(() => {
				this.viewService.refreshView(workspaceId, pv);
				return associationsToDelete;
			});
	}

	async updateComment(associationId: string, comment: string, currentWorkspaceId: string, pv: string): Promise<void> {
		await this.associationService.updateComment(associationId, comment, currentWorkspaceId, pv);
		this.viewService.notifyModified();
	}

	async searchProcessElements(
		searchTerm: string,
		targetElementType: string,
		targetElementSubtypes: string[],
		targetElementsToIgnore: ViewableElement[],
		currentWorkspaceId: string,
		searchWorkspaceId: string,
		pv: string,
		targetProcessIds: string[],
	): Promise<AssocSearchResult[]> {
		Assert.truthy(currentWorkspaceId === searchWorkspaceId, "Search workspace expected to be the current workspace");
		return this.associationService.searchProcessElements(
			searchTerm,
			targetElementType,
			targetElementSubtypes,
			targetElementsToIgnore.map((e) => ({
				typeIdent: e.type.ident,
				identity: e.identity,
				processId: e.process.id,
			})),
			currentWorkspaceId,
			pv,
			targetProcessIds,
		);
	}

	async searchDependentProcessElements(
		searchTerm: string,
		targetElementType: string,
		containerElementIdentity: string,
		targetElementsToIgnore: ViewableElement[],
		currentWorkspaceId: string,
		pv: string,
		targetProcessIds: string[],
	): Promise<AssocSearchResult[]> {
		return this.associationService.searchDependentProcessElements(
			searchTerm,
			targetElementType,
			containerElementIdentity,
			targetElementsToIgnore.map((e) => ({
				typeIdent: e.type.ident,
				identity: e.identity,
				processId: e.process.id,
			})),
			currentWorkspaceId,
			pv,
			targetProcessIds,
			false,
			false,
		);
	}

	getGroupIdentifier(groupPath: string, groupBy?: string): string {
		if (!groupBy || groupBy === "") {
			return groupPath;
		}
		return groupPath + ">>" + groupBy;
	}

	async applyAssociationModifications(
		sourceElement: ViewableElement,
		groupPath: string,
		added: stages.core.TypedId[],
		removed: stages.core.TypedId[],
		modifiedComments: stages.core.model.TypedIdAssociationDetails[],
		workspaceId: string,
		pv: string,
		_targetWorkspaceId?: string,
		groupBy?: string,
	): Promise<Association<ViewableElement>[]> {
		return this.associationService
			.applyAssociationModifications(
				sourceElement.type.ident,
				sourceElement.id,
				groupPath,
				added,
				removed,
				modifiedComments,
				workspaceId,
				pv,
			)
			.then((viewableAssociations) => {
				sourceElement.associations[this.getGroupIdentifier(groupPath, groupBy)].list = viewableAssociations;
				this.viewService.notifyModified();
				return viewableAssociations;
			});
	}

	getDependentContainerForDependentElement(elementOrDependent: ViewableElement): ViewableElement {
		if (!elementOrDependent.dependent || elementOrDependent.parent === undefined) {
			return elementOrDependent;
		}
		return elementOrDependent.parent;
	}

	getLink(association: Association<ViewableElement | null>, pv: string): unknown[] {
		const targetElement = this.getDependentContainerForDependentElement(assertDefined(association.targetElement));
		const identity = targetElement.identity;

		const commands: unknown[] = ["/", "workspace", targetElement.process.workspaceId, pv];

		if (
			this.mainService.secondaryMode &&
			this.mainService.secondaryWorkspaceId &&
			this.mainService.secondaryProcessVersion &&
			!association.isProcessInterface
		) {
			commands.push({
				swid: this.mainService.secondaryWorkspaceId,
				spv: this.mainService.secondaryProcessVersion,
				smode: this.mainService.secondaryMode,
			});
		}

		return [...commands, "process", targetElement.type.ident, identity];
	}

	getDetailLink(
		sourceElement: ViewableElement,
		association: Association<ViewableElement | null>,
		path: string,
		groupBy?: string,
		mode?: string,
	): unknown[] {
		if (groupBy) {
			return [
				"process",
				mode === "remove" ? "removefromcombined" : "combined",
				sourceElement.type.ident,
				sourceElement.identity,
				"detailView",
				association.targetElement!.identity,
				path,
				{
					groupBy: groupBy,
					targetElementLabel: this.associationTargetLabelService.getCombinedLabel(association.targetElement!),
				},
			];
		}
		return [
			"process",
			mode === "remove" ? "removefromcombined" : "combined",
			sourceElement.type.ident,
			sourceElement.identity,
			"detailView",
			association.targetElement!.identity,
			path,
			{ targetElementLabel: this.associationTargetLabelService.getCombinedLabel(association.targetElement!) },
		];
	}

	isSelectableInBrowser(
		existingAssociation: Association<ViewableElement> | null,
		_sourceElement: ViewableElement,
		targetElement: ViewableElement,
		targetSubtypes: string[] | undefined,
		targetDependentTypes: string[] | undefined,
	): boolean {
		return (
			(hasTargetSubtype(targetElement, targetSubtypes) ||
				(!!targetElement.dependent && isOfSpecifiedDependentType(targetElement, targetDependentTypes))) &&
			targetElement.type.subtypeIdent !== "folder" &&
			(!existingAssociation || this.getActions(existingAssociation).DELETE) &&
			!targetElement.index
		);
	}

	async getTree(browseWorkspaceId: string, elementTypes: string[]): Promise<stages.process.TreeElement[]> {
		return this.associationService.getTree(browseWorkspaceId, elementTypes);
	}

	reset(): void {}

	async rearrangeAssociations(
		currentWorkspaceId: string,
		sortedAssociationIds: string[],
		sortStrategy: stages.core.sort.SortStrategy,
		type: string,
		identity: string,
		path: string,
		groupBy: string,
		pv: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- viewable
	): Promise<Record<string, any>> {
		return this.associationService.rearrangeAssociations(
			currentWorkspaceId,
			type,
			identity,
			sortedAssociationIds,
			sortStrategy,
			path,
			groupBy,
			pv,
		);
	}

	async getCombinedAssociations(
		targetElementIdentities: string[],
		currentWorkspaceId: string,
		sourceElementType: string,
		sourceElementIdentity: string,
		path: string,
		page: number,
		pageSize: number,
		pv: string,
	): Promise<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>> {
		return this.associationService.getCombinedAssociations(
			targetElementIdentities,
			currentWorkspaceId,
			sourceElementType,
			sourceElementIdentity,
			path,
			page,
			pageSize,
			pv,
		);
	}

	async getAssociationGroupTranslateable(path: string, groupBy: string | null): Promise<string> {
		const sourceElement = await lastValueFrom(this.viewService.awaitSelfElementObservable().pipe(take(1)));
		const content = (sourceElement.viewType as ViewSpec).content;
		const assocListType = this.findAssocList(content, path, groupBy);
		if (!assocListType) {
			return "unknown";
		}
		const group = findGroupByPath(assocListType, path, groupBy);
		return group!.translate!;
	}

	private findAssocList(
		component: ComponentType,
		path: string,
		groupBy: string | null,
	): AssociationListType | undefined {
		if (component.components) {
			for (const componentType of component.components) {
				if (isAssociationListType(componentType)) {
					const group = findGroupByPath(componentType, path, groupBy);
					if (group) {
						return componentType;
					}
				} else {
					const assocListType = this.findAssocList(componentType, path, groupBy);
					if (assocListType) {
						return assocListType;
					}
				}
			}
		}
		if (isTabbedCardType(component)) {
			for (const tab of component.tabs) {
				const assocListType = this.findAssocList(tab, path, groupBy);
				if (assocListType) {
					return assocListType;
				}
			}
		}
		return undefined;
	}
}

function asAssociation(viewableAssociation: stages.process.ViewableAssociation): Association<ViewableElement> {
	return {
		...viewableAssociation,
		actions: viewableAssociation.allowedOperations,
	};
}

function hasTargetSubtype(element: ViewableElement, targetSubtypes: string[] | undefined): boolean {
	if (!targetSubtypes || targetSubtypes.length === 0) {
		return true;
	}
	return !!element.type.subtypeIdent && targetSubtypes.includes(element.type.subtypeIdent);
}

function isTabbedCardType(arg: ComponentType): arg is TabbedCardType {
	return (arg as TabbedCardType).tabs !== undefined;
}

function isAssociationListType(arg: ComponentType): arg is AssociationListType {
	return (arg as AssociationListType).groups !== undefined;
}

function findGroupByPath(
	assocListType: AssociationListType,
	path: string,
	groupBy: string | null,
): GroupDefinition | undefined {
	return assocListType.groups.find((group) => path === group.path && nullToUndefined(groupBy) === group.groupBy);
}

function isOfSpecifiedDependentType(
	targetElement: ViewableElement,
	targetDependentTypes: string[] | undefined,
): boolean {
	if (!targetElement.dependent) {
		return false;
	}
	return (
		!targetDependentTypes ||
		(!!targetElement.type.subtypeIdent && targetDependentTypes.includes(targetElement.type.subtypeIdent))
	);
}
