import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";

type Element = stages.process.ViewableElement;

@Injectable({ providedIn: "root" })
export class RearrangeService {
	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	async rearrange(
		workspaceId: string,
		pv: string,
		folder: Element,
		alphabetical: boolean,
		sortedEntries: Element[],
	): Promise<void> {
		const sortedEntriesIds = sortedEntries.map((entry) => {
			return entry.id;
		});
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource.rearrange(workspaceId, folder.type.ident, folder.id, sortedEntriesIds, {
			alphabetical: alphabetical,
		});
	}
}
