import { Injectable } from "@angular/core";
import { ProcessElementsQueryResource } from "core/stages-client";

type QueryTableJson = stages.process.QueryTableJson;

@Injectable({ providedIn: "root" })
export class QueryService {
	constructor(private readonly processElementsQueryResource: ProcessElementsQueryResource) {}

	async getTable(
		identity: string,
		type: string,
		workspaceId: string,
		queryProvider: string,
		queryIdent: string,
		processVersion: string,
	): Promise<QueryTableJson | null> {
		return this.processElementsQueryResource.getQueryResult(workspaceId, type, identity, queryProvider, queryIdent, {
			pv: processVersion,
		});
	}
}
