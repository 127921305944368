import { Injectable } from "@angular/core";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogService } from "common/dialog/dialog.service";
import { ModulesResource } from "core/stages-client";

export interface OverwriteableElement {
	type: stages.process.ViewableType;
	id: string;
}

export interface SingleOverwriteableElement extends OverwriteableElement {
	label: string; //confirm dialog
}

@Injectable({ providedIn: "root" })
export class ModuleService {
	constructor(
		private readonly modulesResource: ModulesResource,
		private readonly dialogService: DialogService,
		private readonly mutexService: MutexService,
	) {}

	async overwriteSingleElement(workspaceId: string, pv: string, element: SingleOverwriteableElement): Promise<void> {
		return this.overwriteElements(workspaceId, pv, [element]);
	}

	async overwriteElements(workspaceId: string, pv: string, elements: OverwriteableElement[]): Promise<void> {
		const elementIds = elements.map((element) => {
			return {
				typeIdent: element.type.ident,
				id: element.id,
			};
		});
		return this.modulesResource.overwrite(workspaceId, elementIds, {
			pv: pv,
		});
	}

	async restoreSingleElement(
		workspaceId: string,
		pv: string,
		elementToRestore: SingleOverwriteableElement,
		afterRestore: () => void,
		onCancel: () => void,
	): Promise<void> {
		if (
			await this.dialogService.confirm(
				"overwrite.undo.confirm.message",
				{ name: elementToRestore.label },
				"process.element.overwrite.undo",
			)
		) {
			this.mutexService.invoke("elementUndoOverwrite", async () => {
				return this.restoreElements(workspaceId, pv, [elementToRestore]).then(afterRestore);
			});
		} else {
			onCancel();
		}
	}

	async restoreElements(workspaceId: string, pv: string, elements: OverwriteableElement[]): Promise<void> {
		const elementIds = elements.map((element) => {
			return {
				typeIdent: element.type.ident,
				id: element.id,
			};
		});

		return this.modulesResource.restore(workspaceId, elementIds, {
			pv: pv,
		});
	}

	restoreSingleElementInElementMenu(
		workspaceId: string,
		pv: string,
		elementToRestore: SingleOverwriteableElement,
		afterRestore: () => void,
	): void {
		this.restoreSingleElement(workspaceId, pv, elementToRestore, afterRestore, () => {});
	}
}
