import { Pipe, PipeTransform } from "@angular/core";

interface ProcessVersionFilterOption {
	displayVersionName: string;
	versionIdentifier: string;
	validVersion: boolean;
}

@Pipe({ name: "processVersionMarkValidVersion" })
export class ProcessVersionValidVersionPipe implements PipeTransform {
	transform(processVersion?: ProcessVersionFilterOption, markWith = "Valid Version"): string {
		if (processVersion) {
			return processVersion.validVersion
				? processVersion.displayVersionName.concat(" (", markWith, ")")
				: processVersion.displayVersionName;
		} else {
			return "";
		}
	}
}
