<ng-container *ngIf="navigationConfiguration$ | async as navigationConfiguration">
	<ng-container *ngIf="workspaceView$ | async as workspaceView">
		<ng-container *ngIf="mainService.navigationInProgress$ | async as inProgress">
			<div class="progress" *ngIf="inProgress">
				<div class="indeterminate"></div>
			</div>
		</ng-container>

		<ng-container *ngIf="getCurrentActions(navigationConfiguration) as currentActions">
			<header
				class="main buttons"
				*ngIf="
					(navigationConfiguration.homeRoute || navigationConfiguration.parentRoute) && currentActions.length === 0
				"
			>
				<button
					[attr.title]="'home' | translate"
					[attr.aria-label]="'home' | translate"
					class="action"
					[routerLink]="getRouterLink(navigationConfiguration.homeRoute, navigationConfiguration)"
					*ngIf="navigationConfiguration.homeRoute"
					role="button"
				>
					<i class="ico ico-home"></i>
				</button>
				<button
					[attr.title]="'up' | translate"
					[attr.aria-label]="'up' | translate"
					class="action"
					[routerLink]="getRouterLink(navigationConfiguration.parentRoute, navigationConfiguration)"
					*ngIf="navigationConfiguration.parentRoute"
					role="button"
				>
					<i class="ico ico-arrow-up"></i>
				</button>
				<div class="action more" *ngIf="isMainMenuEnabled(navigationConfiguration)">
					<stages-menu
						menuId="folderActionMenu"
						[noCollapse]="true"
						iconClasses="ico ico-more-vertical"
						openTo="left"
						[items]="
							wrapMenuItems(
								$any(navigationConfiguration.folder),
								navigationConfiguration.mainMenu,
								navigationConfiguration
							)
						"
						[context]="navigationConfiguration.folder"
					></stages-menu>
				</div>
			</header>

			<header class="main buttons" *ngIf="currentActions.length > 0">
				<button
					class="button sm {{ action.buttonClass }}"
					translate="{{ action.name }}"
					(click)="action.on()"
					[disabled]="inProgress || action.disabled()"
					*ngFor="let action of currentActions; trackBy: trackByIndex"
				></button>
			</header>
		</ng-container>

		<nav
			[ngClass]="hasToolbar(navigationConfiguration)"
			*ngIf="
				getCurrentMode(navigationConfiguration.supportedModes, getActionParam(navigationConfiguration)) as currentMode
			"
		>
			<div class="wrapper" *ngIf="getSelection(navigationConfiguration, currentMode.isSelectEnabled) as selection">
				<ng-container *ngIf="navigationConfiguration.folder">
					<!-- handle folder as array so we can use ngFor which works around a bug in routerLink active: https://github.com/angular/angular/issues/13865 -->

					<ul class="list folder" *ngFor="let folder of [navigationConfiguration.folder]; let first = first">
						<li
							class="list-item"
							[ngClass]="{ separator: first }"
							[class.active]="isRouterLinkActive(folder.route, folder.exact === true)"
							id="navItemf{{ folder.id }}"
						>
							<a
								class="label"
								[routerLink]="getRouterLink(folder.route, navigationConfiguration)"
								(click)="closeNavigationIfLeaf(folder)"
								[ngSwitch]="folder.entryType"
							>
								<stages-navigation-entry-single-line
									*ngSwitchCase="singleLine"
									[entry]="$any(folder)"
									[mode]="folder.displayMode"
								>
								</stages-navigation-entry-single-line>
								<stages-navigation-entry-two-line
									*ngSwitchCase="twoLine"
									[entry]="$any(folder)"
									[mode]="folder.displayMode"
								>
								</stages-navigation-entry-two-line>
								<stages-navigation-entry-two-line-split-rating
									class="fullsize"
									*ngSwitchCase="twoLineSplitRating"
									[entry]="$any(folder)"
									[mode]="$any(folder).displayMode"
								>
								</stages-navigation-entry-two-line-split-rating>
								<stages-navigation-entry-change-marker
									class="fullsize"
									*ngSwitchCase="changeMarker"
									[entry]="$any(folder)"
									[mode]="folder.displayMode"
								>
								</stages-navigation-entry-change-marker>
								<stages-navigation-entry-single-line
									*ngSwitchDefault
									[entry]="$any(folder)"
									[mode]="folder.displayMode"
								>
								</stages-navigation-entry-single-line>
							</a>
							<div class="action" *ngIf="currentMode.isMenuEnabled"></div>
						</li>
					</ul>
				</ng-container>
				<ul class="list" *ngIf="currentMode.isSelectEnabled">
					<li class="list-item select-all">
						<div class="select">
							<input type="checkbox" class="checkbox" id="select-all-checkbox" [(ngModel)]="selection.all" />
							<label for="select-all-checkbox"></label>
						</div>
						<a class="label">
							<div class="text">
								<h3 translate="select.all"></h3>
							</div>
						</a>
					</li>
				</ul>
				<ul class="list" *ngIf="currentMode.isRearrangeEnabled">
					<li class="list-item order">
						<div class="select"></div>
						<div class="label">
							<select
								name="order"
								id="order"
								(change)="onOrderChange($any($event.target).value, navigationConfiguration)"
							>
								<option
									value="alphabetical"
									translate="rearrange.order.az"
									[selected]="rearrangeOrder === 'alphabetical'"
								></option>
								<option
									value="custom"
									translate="rearrange.order.custom"
									[selected]="rearrangeOrder === 'custom'"
								></option>
							</select>
						</div>
					</li>
				</ul>

				<ul class="list stages-sortable">
					<ng-container
						*ngFor="
							let element of $any(
								getVisibleNavigationEntries(navigationConfiguration.list) | disabledFilter: workspaceView
							);
							trackBy: trackByIdAndAlphabeticalOrderToggle;
							index as i;
							let last = last
						"
					>
						<li
							class="list-item"
							[ngClass]="{ separator: !last && !isSingleLineDisplayed(element.entryType) }"
							[class.active]="
								isRouterLinkDisabled(currentMode) ? '' : isRouterLinkActive(element.route, element.exact === true)
							"
							id="navItem{{ element.id }}"
							*ngIf="!element.displayMode || element.displayMode === 'VIEW'"
						>
							<div class="select" *ngIf="currentMode.isSelectEnabled">
								<input
									type="checkbox"
									class="checkbox"
									[(ngModel)]="selection.set[selection.keyFunction(element)]"
									(ngModelChange)="selection.onChange(element)"
									[disabled]="!isSelectable(element, currentMode.actionPermissionName)"
									id="e{{ element.id }}"
								/>
								<label for="e{{ element.id }}"></label>
							</div>
							<div class="handle draggableAreaForSort" *ngIf="currentMode.isRearrangeEnabled">
								<i class="ico ico-handle"></i>
							</div>
							<a
								class="label"
								[ngClass]="{ draggableAreaForSort: currentMode.isRearrangeEnabled }"
								[routerLink]="getRouterLink(element.route, navigationConfiguration, currentMode)"
								(click)="closeNavigationIfLeaf(element)"
								[ngSwitch]="element.entryType"
								*ngIf="!element.linkDisabled"
							>
								<stages-navigation-entry-single-line
									class="fullsize"
									*ngSwitchCase="singleLine"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-single-line>
								<stages-navigation-entry-two-line
									class="fullsize"
									*ngSwitchCase="twoLine"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-two-line>
								<stages-navigation-entry-two-line-split-rating
									class="fullsize"
									*ngSwitchCase="twoLineSplitRating"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-two-line-split-rating>
								<stages-navigation-entry-change-marker
									class="fullsize"
									*ngSwitchCase="changeMarker"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-change-marker>
								<stages-navigation-entry-single-line
									class="fullsize"
									*ngSwitchDefault
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-single-line>
							</a>
							<div
								class="label"
								[ngClass]="{ draggableAreaForSort: currentMode.isRearrangeEnabled }"
								(click)="closeNavigationIfLeaf(element)"
								[ngSwitch]="element.entryType"
								*ngIf="element.linkDisabled"
							>
								<stages-navigation-entry-single-line
									class="fullsize"
									*ngSwitchCase="singleLine"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-single-line>
								<stages-navigation-entry-two-line
									class="fullsize"
									*ngSwitchCase="twoLine"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-two-line>
								<stages-navigation-entry-two-line-split-rating
									class="fullsize"
									*ngSwitchCase="twoLineSplitRating"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-two-line-split-rating>
								<stages-navigation-entry-change-marker
									class="fullsize"
									*ngSwitchCase="changeMarker"
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-change-marker>
								<stages-navigation-entry-single-line
									class="fullsize"
									*ngSwitchDefault
									[entry]="element"
									[mode]="element.displayMode"
								>
								</stages-navigation-entry-single-line>
							</div>
							<div class="action" *ngIf="currentMode.isMenuEnabled">
								<stages-menu
									menuId="elementActionMenu{{ i + 1 }}"
									[noCollapse]="true"
									iconClasses="ico ico-more-vertical"
									openTo="left"
									[items]="wrapMenuItems(element, element.menuItems, navigationConfiguration)"
									[context]="element"
								></stages-menu>
							</div>
						</li>
						<li
							class="list-item new-child"
							[class.active]="
								isRouterLinkDisabled(currentMode) ? '' : isRouterLinkActive(element.route, element.exact === true)
							"
							[id]="element.displayMode === 'ADD' ? 'new-child' : 'navItem' + element.id"
							*ngIf="element.displayMode === 'RENAME' || element.displayMode === 'ADD'"
						>
							<div class="label new-child" [ngSwitch]="element.entryType">
								<a
									[routerLink]="getRouterLink(element.route, navigationConfiguration, currentMode)"
									(click)="closeNavigationIfLeaf(element)"
								></a
								><!-- make router link active work during rename-->
								<stages-navigation-entry-single-line
									class="fullsize"
									*ngSwitchCase="'single-line'"
									[entry]="element"
									[mode]="element.displayMode"
								>
									<input
										id="{{ i + 1 }}-rename-input"
										type="text"
										stagesRestrictInput="\|"
										maxlength="255"
										[placeholder]="'process.navigation.add.child.placeholder' | translate"
										[ngModel]="element.label"
										(stagesKeyUp)="onEditInput($event, element, element.displayMode !== 'ADD')"
										(blur)="stopEditEntry(element, navigationConfiguration, $any($event))"
										[stagesAutofocus]
									/>
								</stages-navigation-entry-single-line>
								<stages-navigation-entry-two-line
									class="fullsize"
									*ngSwitchCase="'two-line'"
									[entry]="element"
									[mode]="element.displayMode"
								>
									<input
										id="{{ i + 1 }}-rename-input"
										type="text"
										stagesRestrictInput="\|"
										maxlength="255"
										[placeholder]="'process.navigation.add.child.placeholder' | translate"
										[ngModel]="element.label"
										(stagesKeyUp)="onEditInput($event, element, element.displayMode !== 'ADD')"
										(blur)="stopEditEntry(element, navigationConfiguration, $any($event))"
										[stagesAutofocus]
									/>
								</stages-navigation-entry-two-line>
								<stages-navigation-entry-two-line-split-rating
									class="fullsize"
									*ngSwitchCase="'two-line-split-rating'"
									[entry]="element"
									[mode]="element.displayMode"
								>
									<input
										id="{{ i + 1 }}-rename-input"
										type="text"
										stagesRestrictInput="\|"
										maxlength="255"
										[placeholder]="'process.navigation.add.child.placeholder' | translate"
										[ngModel]="element.label"
										(stagesKeyUp)="onEditInput($event, element, element.displayMode !== 'ADD')"
										(blur)="stopEditEntry(element, navigationConfiguration, $any($event))"
										[stagesAutofocus]
									/>
								</stages-navigation-entry-two-line-split-rating>
								<stages-navigation-entry-single-line
									class="fullsize"
									*ngSwitchDefault
									[entry]="element"
									[mode]="element.displayMode"
								>
									<input
										id="{{ i + 1 }}-rename-input"
										type="text"
										stagesRestrictInput="\|"
										maxlength="255"
										[placeholder]="'process.navigation.add.child.placeholder' | translate"
										[ngModel]="element.label"
										(stagesKeyUp)="onEditInput($event, element, element.displayMode !== 'ADD')"
										(blur)="stopEditEntry(element, navigationConfiguration, $any($event))"
										[stagesAutofocus]
									/>
								</stages-navigation-entry-single-line>
							</div>
							<div class="action" *ngIf="currentMode.isMenuEnabled">
								<stages-menu
									menuId="elementActionMenu{{ i + 1 }}"
									[noCollapse]="true"
									iconClasses="ico ico-more-vertical"
									openTo="left"
									[items]="wrapMenuItems(element, element.menuItems, navigationConfiguration)"
									[context]="element"
								></stages-menu>
							</div>
						</li>
					</ng-container>
					<ng-container *ngIf="hasOmmittedEntries(navigationConfiguration.list)">
						<div class="label ommitted-items">
							<div class="text fullsize light">
								<h3 translate="list.items.ommitted"></h3>
								<h6 [translate]="'list.items.ommitted.search.general'"></h6>
							</div>
						</div>
					</ng-container>
				</ul>
				<stages-support-section></stages-support-section>
			</div>
		</nav>

		<stages-floating-action-menu
			iconClass="ico ico-add"
			*ngIf="isFloatingActionButtonWithMenuVisible(navigationConfiguration)"
		>
			<li *ngFor="let menuItem of navigationConfiguration.floatingActionButton?.menuItems; trackBy: trackByIndex">
				<button
					class="action needsclick"
					(click)="onFloatingActionButtonClicked(menuItem.subtypeIdent, navigationConfiguration)"
				>
					<i class="ico" [ngClass]="menuItem.iconClass"></i>
					<span class="bubble" *ngIf="!menuItem.nameTranslate">{{ menuItem.name }}</span>
					<span class="bubble" *ngIf="menuItem.nameTranslate" [translate]="menuItem.nameTranslate"></span>
				</button>
			</li>
		</stages-floating-action-menu>
		<button
			class="floating-action needsclick"
			(click)="onFloatingActionButtonClicked('', navigationConfiguration)"
			*ngIf="isSimpleFloatingActionButtonVisible(navigationConfiguration)"
		>
			<i class="ico ico-add"></i>
		</button>
	</ng-container>
</ng-container>
