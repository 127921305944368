import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { AnchorProcessor } from "common/editor/shared/anchor-processor";

@Component({
	selector: "stages-description-editor-link-anchors",
	templateUrl: "./link-anchors.component.html",
})
export class LinkAnchorsComponent {
	@HostBinding("class.anchors")
	anchorsClass = true;

	@Input()
	selectedAnchor?: string;

	@Input()
	previewTitle?: string;

	@Input()
	previewHtml?: string;

	@Output() readonly selectAnchor = new EventEmitter<string>();

	@Output() readonly closeAnchors = new EventEmitter<void>();

	@Output() readonly insertAnchor = new EventEmitter<void>();

	getAnchors(description: string): string[] {
		return this.previewHtml ? AnchorProcessor.getAnchors(description) : [];
	}

	selectAnchorName(anchorName: string): void {
		this.selectedAnchor = anchorName;
		this.selectAnchor.emit(anchorName);
	}
}
