import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { UtilService } from "common/util.service";
import { DescriptionViewComponent } from "common/editor/description-view.component";

@Directive({
	selector: "[stagesResizeImageMaps]",
})
export class ResizeImageMapsDirective implements OnChanges {
	cachedCoords: Map<string, string[]> = new Map<string, string[]>();

	@Input()
	html!: string;

	constructor(
		private utilService: UtilService,
		private elem: ElementRef,
		private descriptionViewComponent: DescriptionViewComponent,
	) {}

	ngOnChanges(): void {
		this.descriptionViewComponent.registerResizeHandler("imagemaps", () => {
			this.resizeImageMaps();
		});
		this.resizeImageMaps();
	}

	resizeImageMaps(): void {
		const imageMaps = [...this.elem.nativeElement.querySelectorAll(".stages_description map")];
		imageMaps.forEach((mapElement: HTMLMapElement) => {
			let coords = this.cachedCoords.get(mapElement.name);
			if (!coords) {
				coords = this.cacheCoords(mapElement);
			}
			this.resizeImageMap(mapElement.name, coords);
		});
	}

	private cacheCoords(mapElement: HTMLMapElement): string[] {
		const coords = [];
		for (const area of Array.from(mapElement.areas)) {
			const areaElement = area as HTMLAreaElement;
			coords.push(normalizeCoords(areaElement.coords));
		}
		this.cachedCoords.set(mapElement.name, coords);

		return coords;
	}

	private resizeImageMap(mapName: string, cachedCoords: string[]): void {
		const image = this.elem.nativeElement.querySelector(`img[usemap='#${mapName}']`) as HTMLImageElement;
		if (!image) {
			return;
		}

		if (image.naturalWidth === 0 && image.naturalHeight === 0) {
			image.onload = () => {
				this.resizeImageMapInternal(mapName, cachedCoords, image);
			};
		} else {
			this.resizeImageMapInternal(mapName, cachedCoords, image);
		}
	}

	private resizeImageMapInternal(mapName: string, cachedCoords: string[], image: HTMLImageElement): void {
		if (!image) {
			return;
		}
		const mapElement = this.elem.nativeElement.querySelector(`map[name='${mapName}']`) as HTMLMapElement;
		if (!mapElement) {
			console.error(`Image map with name '${mapName}' not found in description!`);
			return;
		}

		const scalingFactor = {
			width: image.width / image.naturalWidth,
			height: image.height / image.naturalHeight,
		};

		const padding = this.utilService.browserIsChrome()
			? {
					width: parseInt(window.getComputedStyle(image, null).getPropertyValue("padding-left"), 10),
					height: parseInt(window.getComputedStyle(image, null).getPropertyValue("padding-top"), 10),
			  }
			: {
					width: 0,
					height: 0,
			  };

		for (let i = 0; i < cachedCoords.length; i++) {
			const areaElement = mapElement.areas[i] as HTMLAreaElement;
			areaElement.coords = scale(cachedCoords[i].split(","), scalingFactor, padding).join(",");
		}
	}
}

function scale(coords: string[], scalingFactor: Record<string, number>, padding: Record<string, number>): number[] {
	return coords.map((coord, index) => {
		return index % 2 === 0
			? padding.width + Math.round(Number(coord) * scalingFactor.width)
			: padding.height + Math.round(Number(coord) * scalingFactor.height);
	});
}

function normalizeCoords(coords: string): string {
	return coords.replace(/ *, */g, ",").replace(/ +/g, ",");
}
