import { animate, style, transition, trigger } from "@angular/animations";
import {
	AfterContentChecked,
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	OnDestroy,
	QueryList,
	TrackByFunction,
} from "@angular/core";
import { CarouselItemComponent } from "common/carousel/carousel-item.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	animations: [
		trigger("slide", [
			transition(":enter", [
				style({ transform: "translateX({{enterFrom}}%)" }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ transform: "translateX({{enterTo}}%)" })),
			]),
			transition(":leave", [
				style({ transform: "translateX({{leaveFrom}}%)" }),
				animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)", style({ transform: "translateX({{leaveTo}}%)" })),
			]),
		]),
	],
	selector: "stages-carousel",
	styleUrls: ["./carousel.component.scss"],
	templateUrl: "./carousel.component.html",
})
export class CarouselComponent implements AfterContentInit, AfterContentChecked, OnDestroy {
	private destroy$ = new Subject<boolean>();
	selection?: CarouselItemComponent;

	params = {
		enterFrom: "0",
		enterTo: "0",
		leaveFrom: "0",
		leaveTo: "0",
	};

	@ContentChildren(CarouselItemComponent) items!: QueryList<CarouselItemComponent>;

	constructor(private chRef: ChangeDetectorRef) {}

	ngAfterContentInit(): void {
		this.items.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.selection = this.items.length > 0 ? this.items.toArray()[0] : undefined;
		});
	}

	ngAfterContentChecked(): void {
		this.items.forEach((item: CarouselItemComponent, index: number) => {
			item.position = index;
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	select(name: string, isAnimate: boolean = true): void {
		const newSelection = this.items.find((item) => item.name === name);
		if (newSelection === undefined) {
			return;
		}

		if (this.selection === undefined) {
			this.selection = newSelection;
		}

		const addRight = this.selection.position < newSelection.position;

		if (isAnimate) {
			this.params = addRight
				? {
						enterFrom: "0",
						enterTo: "-100",
						leaveFrom: "0",
						leaveTo: "-100",
				  }
				: {
						enterFrom: "-200",
						enterTo: "-100",
						leaveFrom: "0",
						leaveTo: "100",
				  };
			this.chRef.detectChanges();
		}
		this.selection = newSelection;
	}

	getName: TrackByFunction<CarouselItemComponent> = (_index, item) => {
		return item.name;
	};
}
