<ul class="breadcrumb" [ngClass]="{ 'add-padding': isEmbeddedInCard }">
	<li>
		<a *ngIf="!hasRouterLink(backButton)" class="back" (click)="backButtonClicked.emit(true)">
			<i class="ico ico-arrow-back"></i>
			<span class="label" translate="back"></span>
		</a>
		<a *ngIf="hasRouterLink(backButton)" class="back" [routerLink]="[backButton!.routerLink]">
			<i class="ico ico-arrow-back"></i>
			<span class="label" translate="back"></span>
		</a>
	</li>
</ul>
