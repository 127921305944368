import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { DashboardService } from "dashboard/dashboard.service";
import { FileContainer, FileSource } from "files/files.component";
import { FileService } from "files/files.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";

class RecentlyUsedFileContainer implements FileContainer {
	files: stages.file.File[];
	allowedOperations: StringToBoolean = {};
	elementType = "workspace";
	elementId: string;
	templateFileDescriptors!: stages.process.FileDescriptor[];

	constructor(files: stages.file.File[], workspaceId: string) {
		this.files = files;
		this.elementId = workspaceId;
	}
}

@Component({
	selector: "stages-recently-used-files",
	templateUrl: "./recently-used-files.component.html",
})
export class RecentlyUsedFilesComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	recentlyUsedFileContainer$!: Observable<FileContainer | undefined>;
	fileSource!: FileSource;

	@Input()
	translateNone?: string;

	@Input()
	layout?: string;

	@Input()
	showStartOptions?: boolean;

	constructor(
		private dashboardService: DashboardService,
		private route: ActivatedRoute,
		private fileService: FileService,
	) {}

	ngOnInit(): void {
		this.recentlyUsedFileContainer$ = combineLatest([
			this.route.paramMap.pipe(
				switchMap(async (paramMap) =>
					this.dashboardService.getRecentlyUsedFiles(paramMap.get("workspaceId")!, paramMap.get("processVersion")!),
				),
			),
			this.route.paramMap,
		])
			.pipe(
				map(([files, paramMap]: [stages.file.File[] | null | undefined, ParamMap]) => {
					if (!files || files.length === 0) {
						return undefined;
					}
					return new RecentlyUsedFileContainer(files, paramMap.get("workspaceId")!);
				}),
			)
			.pipe(takeUntil(this.destroy$));

		this.fileSource = {
			getFilesFunction: async () => {
				return this.dashboardService.getRecentlyUsedFiles(
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
				);
			},
			pollFilesFunction: async (fileIds: string[]) => {
				return this.fileService.getLatestJobStatusForFileIds(fileIds);
			},
		};
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
