<div class="container row" *ngIf="recentlyUsedFileContainer$ | async as recentlyUsedFileContainer">
	<div class="container column col-12">
		<stages-card class="recently">
			<h5 translate="dashboard.recently.used"></h5>
			<div>
				<stages-files-files
					[layout]="layout"
					[showStartOptions]="showStartOptions"
					[translateNone]="translateNone"
					[fileSource]="fileSource"
					[fileContainer]="recentlyUsedFileContainer"
				>
				</stages-files-files>
			</div>
		</stages-card>
	</div>
</div>
