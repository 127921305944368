import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class TextInput extends InputBase<string> {
	constructor(args: TextInputArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "text",
			inputFieldType: "text",
			required: args.required,
			maxLength: args.maxLength,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
			focus: args.focus, // Do set to "true" in a dialog because otherwise ExpressionChangedAfterItHasBeenCheckedError will be thrown when the back button is pressed while the dialog is open!
		});
		this.infoMsgKey = args.infoMsgKey;
	}
}

export class TextInputArgs extends CommonArgs<string> {
	maxLength?: number | null;
	focus?: boolean;
}
