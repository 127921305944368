<header class="page-header">
	<stages-backbutton [backButton]="{ routerLink: '../' }"></stages-backbutton>
	<h1 translate="administration.dashboard.supportingtechnologies.credits"></h1>
</header>
<article class="card administration">
	<ul class="list">
		<li class="list-item pointerOff">
			<div class="text center">
				<h4 translate="administration.dashboard.supportingtechnologies.credits.summary"></h4>
			</div>
		</li>

		<ng-container *ngIf="dependencyGroups$ | async as dependencyGroups">
			<ng-container *ngFor="let group of dependencyGroups">
				<li class="list-item pointerOff">
					<div class="text center">
						<h3 translate="{{ group.nameKey }}"></h3>
					</div>
				</li>
				<li class="list-item" *ngFor="let dependency of group.dependencies">
					<div class="text">
						<h4>{{ dependency.name }} {{ dependency.version }}</h4>
						<p>
							<span translate="administration.dashboard.supportingtechnologies.credits.license"></span
							><span>: {{ dependency.licenseType }}</span
							><a [href]="dependency.licenseUrl"> {{ dependency.licenseUrl }}</a>
						</p>
						<p>
							<span translate="administration.dashboard.supportingtechnologies.credits.link"></span>:
							<a [href]="dependency.repositoryUrl">{{ dependency.repositoryUrl }}</a>
						</p>
					</div>
				</li>
			</ng-container>
		</ng-container>
	</ul>
</article>
