<ng-container>
	<div *ngIf="zoomValue !== undefined" class="zoomToolbar">
		<button
			[disabled]="bottomBoundaryReached"
			class="{{ zoomToolbarIconClasses.fitToPage }} diagramIcons remove-on-small-devices"
			(click)="fitToPageButtonClicked()"
			title="{{ 'fitToPage' | translate }}"
		></button>
		<button
			[disabled]="bottomBoundaryReached"
			class="{{ zoomToolbarIconClasses.oneStepDown }} diagramIcons"
			(click)="stepButtonClicked(Step.Down)"
			title="{{ 'process.diagram.zoomtool.zoomout' | translate }}"
		></button>
		<h3 class="zoomText remove-on-small-devices">{{ zoomValue }}%</h3>
		<button
			[disabled]="topBoundaryReached"
			class="{{ zoomToolbarIconClasses.oneStepUp }} diagramIcons"
			(click)="stepButtonClicked(Step.Up)"
			title="{{ 'process.diagram.zoomtool.zoomin' | translate }}"
		></button>
	</div>
</ng-container>
