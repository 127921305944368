import { createStore, withProps, select } from "@ngneat/elf";
import { Injectable } from "@angular/core";

type ApplicationContext = stages.context.ApplicationContext;

interface AppContextProps {
	applicationContext?: ApplicationContext;
}

const applicationContextStore = createStore(
	{ name: "application-context" },
	withProps<AppContextProps>({ applicationContext: undefined }),
);

@Injectable({
	providedIn: "root",
})
export class ApplicationContextRepository {
	searchScope$ = applicationContextStore.pipe(select((state) => state.applicationContext!.searchScope));

	update(applicationContext: AppContextProps["applicationContext"]): void {
		applicationContextStore.update((state) => ({
			...state,
			applicationContext,
		}));
	}
}
