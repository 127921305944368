import { Component, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ProcessVersionService } from "common/version/process-version.service";
import { MainService } from "core/main.service";
import { combineLatest, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

type ProcessVersion = stages.process.ProcessVersion;

interface WorkspaceFilterOption {
	name: string;
	value: string;
}

interface ProcessVersionFilterOption {
	displayVersionName: string;
	versionIdentifier: string;
	validVersion: boolean;
}

export interface FilterSettings {
	selectedTypes: string[];
	selectedWorkspaceFilter: WorkspaceFilterOption;
	selectedPvFilter: ProcessVersionFilterOption;
}

@Component({
	selector: "stages-global-search-filter",
	templateUrl: "filter.component.html",
})
export class GlobalSearchFilterComponent implements OnDestroy {
	private destroy$ = new Subject<boolean>();
	@Input() typeFilterOptions?: StringToBoolean;
	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	objectKeys: (o: {}) => string[];
	workspaceFilterOptions$ = new Subject<WorkspaceFilterOption[]>();
	selectedWorkspaceFilterOption!: WorkspaceFilterOption;
	pvFilterOptions!: ProcessVersionFilterOption[];
	selectedPVFilterOption!: ProcessVersionFilterOption;
	pvFilterVisible?: boolean;
	validVersionName?: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mainService: MainService,
		private processVersionService: ProcessVersionService,
		private translateService: TranslateService,
	) {
		this.validVersionName = this.translateService.instant("process.version.validVersion");
		this.objectKeys = Object.keys;

		const paramMap$ = this.route.paramMap;
		const application$ = paramMap$.pipe(
			switchMap(async (paramMap) => this.mainService.getApplication(paramMap.get("processVersion")!)),
		);
		const processVersions$ = paramMap$.pipe(
			switchMap(async (paramMap) =>
				this.processVersionService.getProcessVersions(
					paramMap.get("workspaceId")!,
					true,
				),
			),
		);
		const workspaceView$ = this.mainService.workspaceView$;

		combineLatest([paramMap$, application$, workspaceView$, processVersions$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([paramMap, _application, workspaceView, processVersions]) => {
				this.initializeWorkspaceFilter(paramMap);

				this.pvFilterVisible = workspaceView.currentWorkspace.viewedProcess
					? workspaceView.currentWorkspace.viewedProcess.actions.READ_PROCESS_VERSIONS
					: false;
				if (this.pvFilterVisible) {
					this.initializePvFilter(paramMap, processVersions);
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	private initializeWorkspaceFilter(paramMap: ParamMap): void {
		const workspaceFilterOptions = [
			{
				name: "search.filter.workspace.local",
				value: "local",
			},
			{
				name: "search.filter.workspace.my",
				value: "my",
			},
			{
				name: "search.filter.workspace.recommended",
				value: "recommended",
			},
			{
				name: "search.filter.workspace.all",
				value: "all",
			},
		];
		this.selectedWorkspaceFilterOption = this.getWorkspaceFilterOptionFromState(paramMap, workspaceFilterOptions);
		this.workspaceFilterOptions$.next(workspaceFilterOptions);
	}

	private initializePvFilter(paramMap: ParamMap, processVersions: ProcessVersion[]): void {
		const processVersionParam = paramMap.has("pv") ? paramMap.get("pv") : paramMap.get("processVersion");
		this.pvFilterOptions = new Array<ProcessVersionFilterOption>();

		processVersions.forEach((processVersion) => {
			const processVersionFilter: ProcessVersionFilterOption = {
				displayVersionName: processVersion.displayVersionName,
				versionIdentifier: processVersion.versionIdentifier,
				validVersion: processVersion.validVersion,
			};

			this.pvFilterOptions.push(processVersionFilter);
			if (processVersion.versionIdentifier === processVersionParam) {
				this.selectedPVFilterOption = processVersionFilter;
			} else if (
				!this.selectedPVFilterOption &&
				processVersion.versionIdentifier === "_wv" &&
				processVersion.workingVersion &&
				processVersion.validVersion &&
				processVersionParam === "_vv"
			) {
				this.selectedPVFilterOption = processVersionFilter;
			} else if (
				!this.selectedPVFilterOption &&
				processVersion.versionIdentifier === "_vv" &&
				processVersion.workingVersion &&
				processVersion.validVersion &&
				processVersionParam === "_wv"
			) {
				this.selectedPVFilterOption = processVersionFilter;
			}
		});

		if (!this.selectedPVFilterOption && this.pvFilterOptions.length > 0) {
			this.pvFilterOptions.forEach((filterOption) => {
				if (filterOption.validVersion) {
					this.selectedPVFilterOption = filterOption;
				}
			});
		}
	}

	private getWorkspaceFilterOptionFromState(
		paramMap: ParamMap,
		workspaceFilterOptions: WorkspaceFilterOption[],
	): WorkspaceFilterOption {
		const currentValue = paramMap.get("scope");
		let ret;
		for (const option of workspaceFilterOptions) {
			if (option.value === currentValue) {
				ret = option;
				break;
			}
		}
		if (!ret) {
			return workspaceFilterOptions[0];
		}
		return ret;
	}

	private getSelectedTypes(): string[] {
		const selectedTypes: string[] = [];
		if (this.typeFilterOptions) {
			for (const displayTypeName in this.typeFilterOptions) {
				if (this.typeFilterOptions[displayTypeName]) {
					selectedTypes.push(displayTypeName);
				}
			}
		}
		return selectedTypes;
	}

	onFilterChange(): void {
		this.router.navigate(
			[
				".",
				{
					term: this.route.snapshot.paramMap.get("term"),
					scope: this.selectedWorkspaceFilterOption.value,
					pv: this.pvFilterVisible ? this.selectedPVFilterOption.versionIdentifier : "_vv",
					categories: this.getSelectedTypes(),
					rpage: 1,
				},
			],
			{
				relativeTo: this.route,
			},
		);
	}
}
