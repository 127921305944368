import { animate, state, style, transition, trigger } from "@angular/animations";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnDestroy,
	Pipe,
	PipeTransform,
	QueryList,
	Renderer2,
	ViewChildren,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { Mode } from "common/data/data-view.component";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { ResponsiveDataViewComponent } from "common/data/responsive-data-view.component";
import { DialogService } from "common/dialog/dialog.service";
import { NotificationService } from "core/notification.service";
import { PreferencesService } from "core/preferences.service";
import { ViewService } from "core/view.service";
import { DependentElementStore } from "process/dependant-elements/dependent-element-store.logic";
import {
	DependentElement,
	DependentElementsManagementService,
} from "process/dependant-elements/dependent-elements-management.service";
import { ProcessDescriptionService } from "process/description/process-description.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { Observable, Subscription } from "rxjs";
import { DescriptionComponent } from "common/editor/description.component";
import { MainService } from "core/main.service";
import { SelectionStoreService } from "common/selection/state/selection-store.service";
import { SelectionRepository } from "common/selection/state/selection.repository";
import ProcessView = stages.process.ProcessView;
import setTimeout = CKEDITOR.tools.setTimeout;
import ViewableType = stages.process.ViewableType;

type SortStrategy = stages.core.sort.SortStrategy;

@Pipe({
	name: "getDependentElementDescription",
	pure: true,
})
export class GetDependentElementDescriptionPipe implements PipeTransform {
	transform(dependentElement: DependentElement): stages.core.format.DisplayDescription {
		if (
			dependentElement.changeMarker?.description !== undefined &&
			!dependentElement.changeMarker.added &&
			!dependentElement.changeMarker.deleted
		) {
			return dependentElement.changeMarker.description;
		}

		return dependentElement.description;
	}
}

export enum DependentElementListMode {
	VIEW = 0,
	ADD = 1,
	BULKDELETE = 2,
	REARRANGE = 3,
	RENAME = 4,
	EDIT = 5,
}

export interface UIDependentElement extends DependentElement {
	isInRenameMode: boolean;
	selected: boolean;
	expanded: boolean;
}

@Component({
	selector: "stages-dependent-elements-list-old",
	templateUrl: "./dependent-elements-list.component.html",
	styleUrls: ["./dependent-elements-list.component.scss"],
	animations: [
		trigger("expandCollapseFromTop", [
			state("collapsed", style({ "margin-top": "{{contentHeight}}px" }), { params: { contentHeight: 0 } }),
			state("expanded", style({ "margin-top": 0 })),
			state("add", style({ "margin-top": 0 })),
			transition("collapsed <=> expanded", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
			transition("add => collapsed", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
})
export class DependentElementsListComponent extends BaseComponent implements AfterContentInit, OnDestroy {
	readonly descriptionEditorIdPrefix: string = "desc-edit-";

	@Input()
	messageKeyNone?: string;

	@Input()
	classes?: string;

	@Input()
	dependentElementTypeIdent?: string;

	@HostBinding("class")
	get hostClasses(): string {
		return this.classes ?? "";
	}

	Mode = DependentElementListMode;
	mode: DependentElementListMode = DependentElementListMode.VIEW;
	processView$: Observable<ProcessView>;
	processView?: ProcessView;
	dependentElementMenuItems: MenuItem[];
	dependentElements: UIDependentElement[] = [];
	dependentSubType?: ViewableType;
	parentType!: ViewableType;
	isSaving: boolean = false;
	readonly workspaceId: string;
	readonly processVersion: string;
	private parentId!: string;
	private cardMenuItems: MenuItem[] = [];
	private subscriptions: Subscription = new Subscription();

	// rearrange
	private originalSortedDependentElements?: UIDependentElement[];
	private originalSortStrategy?: SortStrategy;
	sortOrderWasChanged: boolean = false;
	sortStrategy: SortStrategy = "HIERARCHICAL";

	// delete
	private bulkDeleteAvailable: boolean = false;

	// edit
	@ViewChildren(DescriptionComponent)
	descriptionComponents!: QueryList<DescriptionComponent>;

	// rename
	dataView!: ResponsiveDataViewComponent<UIDependentElement>;

	constructor(
		componentService: ComponentService,
		private readonly card: CardComponent,
		private readonly dependentElementsManagementService: DependentElementsManagementService,
		private readonly dialogService: DialogService,
		private readonly mutexService: MutexService,
		private readonly preferencesService: PreferencesService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly viewService: ViewService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly notificationService: NotificationService,
		private readonly renderer: Renderer2,
		private readonly mainService: MainService,
		readonly descriptionService: ProcessDescriptionService,
		private selectionStoreService: SelectionStoreService,
		private selectionRepository: SelectionRepository,
	) {
		super(componentService);
		this.workspaceId = this.route.snapshot.paramMap.get("workspaceId")!;
		this.processVersion = this.route.snapshot.paramMap.get("processVersion")!;
		this.dependentElementMenuItems = this.createDependentElementMenuItems();
		this.processView$ = this.viewService.awaitSelfElementObservable();
	}

	async ngAfterContentInit(): Promise<void> {
		this.subscriptions.add(
			this.processView$.subscribe((processView: ProcessView) => {
				this.processView = processView;
				this.parentType = processView.type;
				this.parentId = processView.id;
				const filteredDependentElements =
					processView.dependentElements && this.dependentElementTypeIdent
						? processView.dependentElements.filter(
								(dependentElement) => dependentElement.type.subtypeIdent === this.dependentElementTypeIdent,
						  )
						: processView.dependentElements;
				this.dependentElements = filteredDependentElements
					? this.copyAndConvertToUIElements(filteredDependentElements)
					: [];
				if (processView.dependentChildSubtypes && processView.dependentChildSubtypes?.length > 0) {
					// eslint-disable-next-line unicorn/prefer-ternary -- we want to comment the else part
					if (this.dependentElementTypeIdent) {
						this.dependentSubType = processView.dependentChildSubtypes.find(
							(subType) => subType.subtypeIdent === this.dependentElementTypeIdent,
						);
					} else {
						// if no dependentSubType was specified in the metamodel, we take the first one
						this.dependentSubType = processView.dependentChildSubtypes?.[0];
					}
				}
				if (this.dependentSubType?.subtypeIdent) {
					this.dataView = new ResponsiveDataViewComponent<UIDependentElement>(
						this.changeDetector,
						this.notificationService,
						this.renderer,
						this.mainService,
						this.selectionStoreService,
						this.selectionRepository,
					);
					this.dataView.dataSource = new PageableDataSource<UIDependentElement>(
						new DependentElementStore(
							this.dependentElements,
							1000,
							this.workspaceId,
							this.processVersion,
							this.parentType.ident,
							this.parentId,
							this.dependentSubType.subtypeIdent,
							this.dependentElementsManagementService,
							this.viewService,
						),
						this.mutexService,
						this.router,
						this.route,
						"page",
					);
					this.dataView.idFn = DependentElementsListComponent.getId;
				}

				this.initializeCard();
				if (this.dependentElements.length > 0) {
					const deletableDependentElements = this.dependentElements.filter(
						(dependentElement: UIDependentElement) => dependentElement.allowedOperations.DELETE,
					);
					this.bulkDeleteAvailable = deletableDependentElements.length > 1;
					this.sortStrategy = this.getSortStrategy(this.dependentElements[0]);
				}
			}),
		);
		await this.initializeExpandedState();
	}

	private createDependentElementMenuItems(): MenuItem[] {
		return [
			{
				name: "edit",
				iconClass: "ico ico-edit",
				disabled: (dependentElement: UIDependentElement) => {
					return !dependentElement.allowedOperations.EDIT || this.isInRearrangeOrBulkDeleteMode();
				},
				on: async (dependentElement: UIDependentElement) => {
					await this.enterEdit(dependentElement);
				},
			},
			{
				name: "rename",
				iconClass: "ico ico-edit-text",
				disabled: (dependentElement: UIDependentElement) => {
					return !dependentElement.allowedOperations.EDIT || this.isInRearrangeOrBulkDeleteMode();
				},
				on: (dependentElement: UIDependentElement) => {
					this.enterRename(dependentElement);
				},
			},
			{
				name: "language.translate",
				iconClass: "ico ico-translate",
				disabled: (dependentElement: UIDependentElement) => {
					return !dependentElement.allowedOperations.TRANSLATE || this.isInRearrangeOrBulkDeleteMode();
				},
				on: async (dependentElement: UIDependentElement) =>
					this.router.navigate(["translate", dependentElement.identity], { relativeTo: this.route }),
			},
			{
				name: "delete",
				iconClass: "ico ico-delete",
				disabled: (dependentElement: UIDependentElement) => {
					return !dependentElement.allowedOperations.DELETE || this.isInRearrangeOrBulkDeleteMode();
				},
				on: async (dependentElement: UIDependentElement) => {
					if (
						await this.dialogService.confirm(
							"process.element.dependentElementsList.delete",
							{ name: dependentElement.label },
							"remove",
							"cancel",
							true,
						)
					) {
						this.mutexService.invoke("dependentElementDelete" + dependentElement.id, async () => {
							await this.dependentElementsManagementService.deleteDependentElement(this.workspaceId, dependentElement);
							await this.viewService.refreshView(this.workspaceId, this.processVersion);
						});
					}
				},
			},
			{
				isSeparator: true,
			},
			{
				name: "process.element.tailor.add",
				iconClass: "ico ico-tailoring-add",
				disabled: (dependentElement: UIDependentElement) => !dependentElement.allowedOperations.MANUAL_TAILORING_ADD,
				on: (dependentElement: UIDependentElement) =>
					this.mainService.openPopup(
						[
							"process",
							"mantailor",
							{
								status: 1,
								type: dependentElement.type.ident,
								ids: [dependentElement.id],
							},
						],
						this.route,
					),
			},
			{
				name: "process.element.tailor.remove",
				iconClass: "ico ico-tailoring-remove",
				disabled: (dependentElement: UIDependentElement) => !dependentElement.allowedOperations.MANUAL_TAILORING_REMOVE,
				on: (dependentElement: UIDependentElement) =>
					this.mainService.openPopup(
						[
							"process",
							"mantailor",
							{
								status: -1,
								type: dependentElement.type.ident,
								ids: [dependentElement.id],
							},
						],
						this.route,
					),
			},
			{
				name: "process.element.tailor.reset",
				iconClass: "ico ico-tailoring-reset",
				disabled: (dependentElement: UIDependentElement) => !dependentElement.allowedOperations.MANUAL_TAILORING_RESET,
				on: (dependentElement: UIDependentElement) =>
					this.mainService.openPopup(
						[
							"process",
							"mantailor",
							{
								status: 0,
								type: dependentElement.type.ident,
								ids: [dependentElement.id],
							},
						],
						this.route,
					),
			},
		];
	}

	copyAndConvertToUIElements(dependentElements: DependentElement[]): UIDependentElement[] {
		const uiDependentElements: UIDependentElement[] = [];
		dependentElements.forEach((dependentElement: DependentElement) => {
			uiDependentElements.push(this.convertToUIElementCopy(dependentElement));
		});
		return uiDependentElements;
	}

	convertToUIElementCopy(dependentElement: DependentElement): UIDependentElement {
		dependentElement.description = dependentElement.description ?? {
			html: "",
			editedImageMap: {},
			language: null,
			viewingMode: "SCREEN",
		};
		return {
			type: { ...dependentElement.type },
			label: dependentElement.label,
			id: dependentElement.id,
			identity: dependentElement.identity,
			guid: dependentElement.guid,
			description: { ...dependentElement.description },
			allowedOperations: { ...dependentElement.allowedOperations },
			sortKey: dependentElement.sortKey,
			changeMarker: dependentElement.changeMarker ? { ...dependentElement.changeMarker } : undefined,
			tailored: dependentElement.tailored,
			selected: false,
			isInRenameMode: false,
			expanded: false,
			associations: {},
		};
	}

	initializeCard(): void {
		if (this.card) {
			this.cardMenuItems = this.createCardMenuItems();
			const cardToolbarMenuItems: MenuItem[] = [
				{
					messageKey: "add",
					class: "add",
					iconClass: "ico ico-add",
					onClick: () => {
						this.enterAdd();
					},
					disabled: () =>
						!this.processView?.allowedOperations.CREATE ||
						!this.dependentSubType ||
						this.isInRearrangeOrBulkDeleteMode(),
				},
			];

			setTimeout(() => {
				this.card.menuNoCollapse = true;
				this.card.menuItems = this.cardMenuItems;
				this.card.toolbarItems = cardToolbarMenuItems;
				this.card.contentCollapsible = false;
				this.card.showCollapseOption = false;
			});
		}
	}

	private createCardMenuItems(): MenuItem[] {
		return [
			{
				name: "add",
				iconClass: "ico ico-add",
				disabled: () =>
					!this.processView?.allowedOperations.CREATE || !this.dependentSubType || this.isInRearrangeOrBulkDeleteMode(),
				isSwitch: false,
				on: () => {
					this.enterAdd();
				},
			},
			{
				name: "rearrange",
				iconClass: "ico ico-rearrange",
				disabled: () =>
					!this.processView?.allowedOperations.REARRANGE_CHILDREN ||
					this.dependentElements.length === 0 ||
					this.isInRearrangeOrBulkDeleteMode(),
				isSwitch: false,
				on: () => {
					this.enterRearrange();
				},
			},
			{
				name: "delete",
				iconClass: "ico ico-delete",
				disabled: () => !this.bulkDeleteAvailable || this.isInRearrangeOrBulkDeleteMode(),
				isSwitch: false,
				on: () => {
					this.enterDelete();
				},
			},
		];
	}

	async initializeExpandedState(): Promise<void> {
		const preferences: StringToBoolean = await this.preferencesService.getPreference("dependentElements", {});
		this.dependentElements.forEach((dependentElement: UIDependentElement) => {
			dependentElement.expanded =
				typeof preferences[dependentElement.identity] === "boolean" ? preferences[dependentElement.identity] : false;
		});
	}

	async toggleExpanded(dependentElement: UIDependentElement): Promise<void> {
		dependentElement.expanded = !dependentElement.expanded;
		await this.storeExpandedState();
	}

	async storeExpandedState(): Promise<void> {
		const oldPreferences = await this.preferencesService.getPreference("dependentElements", {});
		const newPreferences: StringToBoolean = { ...oldPreferences };

		this.dependentElements.forEach((dependentElement: UIDependentElement) => {
			newPreferences[dependentElement.identity] = dependentElement.expanded;
		});
		this.preferencesService.setPreference("dependentElements", newPreferences);
	}

	getExpandCollapseFromTop(
		dependentElement: UIDependentElement,
		expandable: HTMLDivElement,
	): { value: string; params: Record<string, unknown> } {
		const targetState = dependentElement.expanded ? "expanded" : "collapsed";
		return {
			value: targetState,
			params: {
				contentHeight: 0 - expandable.scrollHeight,
			},
		};
	}

	// delete

	enterDelete(): void {
		this.mode = DependentElementListMode.BULKDELETE;
	}

	isSelectable(dependentElement: UIDependentElement): boolean {
		return dependentElement.allowedOperations.DELETE;
	}

	anyElementSelectedForDelete(): boolean {
		return (
			this.dependentElements.find((dependentElement: UIDependentElement) => dependentElement.selected) !== undefined
		);
	}

	async deleteSelectedElements(): Promise<void> {
		this.isSaving = true;
		try {
			const elementsToDelete: UIDependentElement[] = this.dependentElements.filter(
				(dependentElement: UIDependentElement) => dependentElement.selected,
			);
			if (elementsToDelete.length > 0) {
				await this.dependentElementsManagementService.deleteDependentElements(this.workspaceId, elementsToDelete);
				await this.viewService.refreshView(this.workspaceId, this.processVersion);
			}
			this.mode = DependentElementListMode.VIEW;
		} finally {
			this.isSaving = false;
		}
	}

	toggleSelected(dependentElement: UIDependentElement): void {
		dependentElement.selected = !dependentElement.selected;
	}

	cancelDelete(): void {
		this.mode = DependentElementListMode.VIEW;
		this.dependentElements.forEach((dependentElement: UIDependentElement) => {
			dependentElement.selected = false;
		});
	}

	// rearrange

	getSortStrategy(dependentElement: DependentElement): SortStrategy {
		return dependentElement.sortKey === 0 ? "ALPHABETICAL" : dependentElement.sortKey === 1 ? "HIERARCHICAL" : "CUSTOM";
	}

	async rearrange(): Promise<void> {
		this.isSaving = true;
		try {
			await this.dependentElementsManagementService.rearrangeDependentElements(
				this.workspaceId,
				this.parentType.ident,
				this.parentId,
				this.dependentElements,
				this.sortStrategy,
			);
			this.mode = DependentElementListMode.VIEW;
			this.updateViewWithNewSort();
		} finally {
			this.isSaving = false;
		}
	}

	drop(event: CdkDragDrop<DependentElement>): void {
		this.sortOrderWasChanged = true;
		moveItemInArray(this.dependentElements, event.previousIndex, event.currentIndex);
		this.sortStrategy = "CUSTOM";
	}

	onSortStrategyChange(sortStrategy: SortStrategy): void {
		this.sortStrategy = sortStrategy;
		if (sortStrategy === "ALPHABETICAL") {
			this.dependentElements.sort((a: DependentElement, b: DependentElement) => a.label.localeCompare(b.label));
		}
		this.sortOrderWasChanged = true;
	}

	enterRearrange(): void {
		this.originalSortedDependentElements = [...this.dependentElements];
		this.originalSortStrategy = this.sortStrategy;
		this.mode = DependentElementListMode.REARRANGE;
	}

	cancelRearrange(): void {
		this.mode = DependentElementListMode.VIEW;
		if (this.originalSortStrategy) {
			this.sortStrategy = this.originalSortStrategy;
			this.originalSortStrategy = undefined;
		}

		if (this.originalSortedDependentElements) {
			this.dependentElements = this.originalSortedDependentElements;
			this.originalSortedDependentElements = undefined;
		}
		this.sortOrderWasChanged = false;
	}

	// add

	async add(name: string): Promise<void> {
		if (
			this.elementWithSameLabelExists(name) &&
			!(await this.dialogService.confirm(
				"search.autoComplete.assocTarget.sameNameExistsCreateConfirm",
				{ name: name },
				"create",
				"cancel",
				false,
			))
		) {
			return;
		}
		await this.dependentElementsManagementService.addDependentElement(
			this.workspaceId,
			this.parentType.ident,
			this.parentId,
			{
				name: name,
				subtype: this.dependentSubType!.subtypeIdent!,
			},
		);
		await this.viewService.refreshView(this.workspaceId, this.processVersion);
	}

	enterAdd(): void {
		this.mode = DependentElementListMode.ADD;
	}

	cancelAdd(): void {
		this.mode = DependentElementListMode.VIEW;
	}

	// edit

	async enterEdit(dependentElement: UIDependentElement): Promise<void> {
		const editor = this.descriptionComponents.find((component) => component.beanIdentity === dependentElement.identity);
		if (editor) {
			editor.edit(dependentElement.description);
		}
		dependentElement.expanded = true;
		await this.storeExpandedState();
		this.mode = DependentElementListMode.EDIT;
	}

	afterDescriptionEditSave(descriptionComponent: DescriptionComponent): void {
		const dependentElement = this.dependentElements.find(
			(element) => element.identity === descriptionComponent.beanIdentity,
		);
		if (dependentElement) {
			dependentElement.description = descriptionComponent.displayDescription;
			if (this.processView?.dependentElements) {
				const originalElement = this.processView.dependentElements.find(
					(element) => element.id === dependentElement.id,
				);
				if (originalElement) {
					originalElement.description = descriptionComponent.displayDescription;
					this.viewService.notifyModified();
				}
			}
		}
		this.mode = DependentElementListMode.VIEW;
	}

	// rename

	enterRename(dependentElement: UIDependentElement): void {
		dependentElement.isInRenameMode = true;
		this.dataView.rename(dependentElement);
	}

	isInRenameMode(dependentELement: UIDependentElement): boolean {
		// after renaming an element, the dataView will switch to another mode. We have to reset our dependentElement then
		if (this.dataView.mode !== Mode.RENAME) {
			dependentELement.isInRenameMode = false;
		}
		return dependentELement.isInRenameMode;
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.subscriptions.unsubscribe();
	}

	isInRearrangeOrBulkDeleteMode(): boolean {
		return this.dataView.mode === Mode.DELETE || this.dataView.mode === Mode.REARRANGE;
	}

	static getId(dependentElement: DependentElement): string {
		return dependentElement.id;
	}

	private elementWithSameLabelExists(label: string): boolean {
		return this.dependentElements.filter((element) => element.label.toLowerCase() === label.toLowerCase()).length > 0;
	}

	private updateViewWithNewSort(): void {
		if (this.processView?.dependentElements) {
			const sortedIds = this.dependentElements.map((dependentElement) => dependentElement.id);
			this.processView.dependentElements = this.processView.dependentElements.sort((a, b) => {
				return sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
			});
			this.viewService.notifyModified();
		}
	}
}
