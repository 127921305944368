import { ChangeDetectorRef, Component, EventEmitter, Input, Output, Renderer2, TemplateRef } from "@angular/core";
import { DataViewComponent } from "common/data/data-view.component";
import { NotificationService } from "core/notification.service";
import { MainService } from "core/main.service";
import { takeUntil } from "rxjs/operators";
import { ScreenSize } from "core/screensize";
import { SelectionStoreService } from "common/selection/state/selection-store.service";
import { SelectionRepository } from "common/selection/state/selection.repository";

@Component({
	selector: "stages-responsive-data-view",
	templateUrl: "./responsive-data-view.component.html",
	styleUrls: ["./responsive-data-view.component.scss"],
})
export class ResponsiveDataViewComponent<T> extends DataViewComponent<T> {
	_screenSize: ScreenSize = ScreenSize.LARGE;

	@Input()
	listItemTemplate?: TemplateRef<unknown>;

	@Input()
	listHeaderTemplate?: TemplateRef<unknown>;

	@Input()
	informationTemplate?: TemplateRef<unknown>;

	@Input()
	listStyles = "list";

	@Input()
	autoCompleteBrowseTemplatePragraphStyles = "";

	@Input()
	browseItemButtonActive: boolean = false;

	@Output()
	readonly closeBrowserButtonPressed = new EventEmitter<T>();

	@Output()
	readonly openBrowserButtonPressed = new EventEmitter<T>();

	@Input()
	tableItemTemplate?: TemplateRef<unknown>;

	@Input()
	headerTemplate?: TemplateRef<unknown>;

	@Input()
	tableColumns: number | string = 1;

	@Input()
	filterPlaceholder?: string;

	@Input()
	searchPlaceholder?: string;

	@Input()
	useBreakpointObserver: boolean = false;

	private readonly defaultPlaceholder = "start.typing";

	constructor(
		chRef: ChangeDetectorRef,
		notificationService: NotificationService,
		renderer: Renderer2,
		private mainService: MainService,
		selectionStoreService: SelectionStoreService,
		selectionRepository: SelectionRepository,
	) {
		super(chRef, notificationService, renderer, selectionStoreService, selectionRepository);
		this.mainService = mainService;
		this.mainService.applicationState.screenSize.pipe(takeUntil(this.destroy$)).subscribe((screenSize) => {
			this._screenSize = screenSize;
		});
	}

	getSearchPlaceHolder(): string {
		return this.searchPlaceholder ? this.searchPlaceholder : this.defaultPlaceholder;
	}

	getFilterPlaceHolder(): string {
		return this.filterPlaceholder ? this.filterPlaceholder : this.defaultPlaceholder;
	}

	isSelectAllEnabled(): boolean {
		return this.selectable && this.showSelectAllSwitch && this.hasItems;
	}

	showListTemplate(): boolean {
		switch (this._screenSize) {
			case ScreenSize.XXSMALL:
				return true;
			case ScreenSize.XSMALL:
				return true;
			default:
				return false;
		}
	}

	openButtonPressed(): void {
		this.openBrowserButtonPressed.emit();
	}

	closeButtonPressed(): void {
		this.closeBrowserButtonPressed.emit();
	}
}
