import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BreadcrumbItem } from "common/breadcrumb/breadcrumb-item";
import { MutexService } from "common/concurrency/mutex.service";
import { DescriptionEditorComponent } from "common/editor/description-editor.component";
import { PluginRegistry } from "common/editor/description.service";
import { ImagePluginComponent } from "common/editor/image-plugin/image-plugin.component";
import { FormService } from "common/form/form.service";
import { RichTextInputContext } from "common/form/input/types/rich-text-input";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { BreadcrumbService } from "process/breadcrumb.service";
import { HtmlTemplatePluginComponent } from "process/description/htmltemplate-plugin/htmltemplate-plugin.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";
import { ProcessDescriptionService } from "process/description/process-description.service";
import { EditService } from "process/element/edit/edit.service";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

type EditableElement = stages.process.EditableElement;
type Element = stages.process.Element;
type Attribute = stages.core.Attribute;
type ElementTypeIdent = "activty" | "artifact" | "guidance" | "phase" | "role";

@Component({
	selector: "stages-edit-element",
	templateUrl: "edit-element.component.html",
})
export class EditElementComponent implements OnInit {
	@ViewChild("formElement") formElement!: ElementRef;
	@ViewChild(DescriptionEditorComponent) descriptionEditor!: DescriptionEditorComponent;

	pluginRegistry: PluginRegistry;
	formGroup: FormGroup = new FormGroup({});
	workspaceView$: Observable<stages.workspace.application.WorkspaceView>;
	isSaveInProgress: boolean = false;
	data$: Observable<Data>;
	editableElement$!: Observable<stages.process.EditableElement>;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mainService: MainService,
		private breadcrumbService: BreadcrumbService,
		private editService: EditService,
		private descriptionService: ProcessDescriptionService,
		private formService: FormService,
		private mutexService: MutexService,
		private viewService: ViewService,
	) {
		this.workspaceView$ = this.mainService.workspaceView$;
		this.pluginRegistry = this.descriptionService.getPluginRegistry();
		this.data$ = this.route.data;
	}

	ngOnInit(): void {
		this.editableElement$ = this.data$.pipe(
			mergeMap(async (data: Data) => {
				let pv = data.view.processView.process.pv;
				// In case of wv=vv, we want to use the pv-paramater of the url
				if (data.view.processView.process.isWorkingRevision && data.view.processView.process.isValidVersion) {
					pv = this.route.snapshot.paramMap.get("processVersion");
				}
				const editableElement = await this.editService.getEditableElement(
					data.view.processView.type.ident as ElementTypeIdent,
					data.view.processView.identity,
					data.view.viewWorkspace.id,
					pv,
				);
				if (!editableElement.element.isIndex) {
					this.setupFormControls(editableElement.element);
				}
				return editableElement;
			}),
		);
	}

	getContext(data: Data, workspaceView: stages.workspace.application.WorkspaceView): RichTextInputContext {
		const processView = data.view.processView as stages.process.ProcessView;
		return {
			beanType: processView.type.ident,
			beanId: processView.id,
			beanIdentity: processView.identity,
			pv: workspaceView.currentWorkspace.viewedProcess!.versionIdentifier,
			processTypeIdent: processView.processType,
			workspaceId: workspaceView.currentWorkspace.id,
			pluginRegistry: {
				stagesimage: ImagePluginComponent,
				stageslink: LinkPluginComponent,
				stageshtmltemplate: HtmlTemplatePluginComponent,
			},
		};
	}

	getBreadcrumbPath(view: stages.process.View): BreadcrumbItem[] {
		return this.breadcrumbService.newBreadcrumbItems(view.processView, true, true);
	}

	cancel(): void {
		this.descriptionEditor.cancel();
		this.router.navigate([".."], { relativeTo: this.route });
	}

	async save(editableElement: EditableElement): Promise<void> {
		this.formService.markFormGroupTouched(this.formGroup);
		if (this.formGroup.invalid) {
			this.formService.scrollToFirstInvalidElement(this.formElement);
		} else {
			this.isSaveInProgress = true;
			try {
				this.prepareDataSave(editableElement);
				if (!this.formGroup.valid) {
					//needs to be checked while form is enabled
					return;
				}
				this.formGroup.disable();
				try {
					const params = this.route.snapshot.paramMap;
					await this.mutexService.invokeWithResult("elementSave", async () => {
						try {
							await this.editService.saveElement(
								params.get("type") as ElementTypeIdent,
								params.get("identity")!,
								editableElement.element,
								params.get("workspaceId")!,
								params.get("processVersion")!,
							);
							this.descriptionEditor.quit();
							this.viewService.refresh(params);
							this.router.navigate(["../"], { relativeTo: this.route });
							// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
						} catch (errorResponse) {
							this.formService.setServerSideErrorsOnFormControls(this.formGroup.controls)(errorResponse);
						}
					});
				} finally {
					this.formGroup.enable();
				}
			} finally {
				this.isSaveInProgress = false;
			}
		}
	}

	private prepareDataSave(editableElement: EditableElement): void {
		const attributesMap = new Map(
			editableElement.element.attributes.map((attribute): [string, Attribute] => [attribute.typeIdent, attribute]),
		);
		for (const attributeTypeIdent in this.formGroup.controls) {
			if (attributesMap.has(attributeTypeIdent)) {
				const attribute: Attribute | undefined = attributesMap.get(attributeTypeIdent);
				if (attribute) {
					attribute.value = this.formGroup.value[attributeTypeIdent];
					attributesMap.set(attribute.typeIdent, attribute);
				}
			}
		}

		editableElement.element.attributes = Array.from(attributesMap.values());

		if (!editableElement.element.isIndex) {
			editableElement.element.name = this.getFormControl("name").value;
			editableElement.element.securityLevel = this.getFormControl("securityLevel").value;
			editableElement.element.shortname = this.getFormControl("shortname").value;
			editableElement.element.subtypeIdent = this.getFormControl("subtypeIdent").value;
		}
		editableElement.element.displayDescription = this.descriptionEditor.getDescription();
	}

	setupFormControls(element: Element): void {
		this.formGroup = new FormGroup({
			attributes: new FormControl(element.attributes, []),
			name: new FormControl(element.name, [
				Validators.required,
				Validators.maxLength(255),
				Validators.pattern(/^[^|]+$/),
			]),
			securityLevel: new FormControl(element.securityLevel),
			shortname: new FormControl(element.shortname, [Validators.maxLength(32), Validators.pattern(/^[^|]+$/)]),
			subtypeIdent: new FormControl(element.subtypeIdent),
		});
	}

	getFormControl(name: string): FormControl {
		const control = this.formGroup.get(name);

		if (!control) {
			throw new Error("Could not get control from formGroup");
		}

		return control as FormControl;
	}

	setErrorsOnForm(control: FormControl): void {
		this.formGroup.setErrors(control.errors);
	}

	getControlErrors(name: string): string[] {
		const control = this.getFormControl(name);
		const errors = control.errors;
		const keys = errors ? Object.keys(errors) : [];
		return keys;
	}

	getMaxLengthAsString(control: FormControl, limit: string): string {
		const length = control.value && control.value.length !== undefined ? control.value.length : 0;
		return (length as string) + "/" + limit;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static provideTitle(translateService: TranslateService, mergedRouteData: any): Observable<string> {
		return translateService
			.get("edit")
			.pipe(
				map((edit) => `${edit} ${mergedRouteData.view.processView.label} - ${mergedRouteData.view.viewWorkspace.name}`),
			);
	}
}
