import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UserSettingsResolver } from "user/settings/user-settings-resolver.service";
import { UserSettingsComponent } from "user/settings/user-settings.component";
import { UserComponent } from "user/user.component";
import { CreditComponent } from "common/credits/credit.component";
import { DependencyGroupsResolver } from "common/credits/dependency-groups.resolver";

const userRoutes: Routes = [
	{
		path: "",
		component: UserComponent,
		children: [
			{
				path: "settings",
				component: UserSettingsComponent,
				resolve: {
					userSettings: UserSettingsResolver,
				},
			},
			{
				path: "settings/credits",
				component: CreditComponent,
				resolve: {
					dependencyGroups: DependencyGroupsResolver,
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(userRoutes)],
})
export class UserRoutingModule {}
