import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { MainService } from "core/main.service";
import { ProcessResource } from "core/stages-client";

@Injectable({ providedIn: "root" })
export class StartService {
	constructor(
		private readonly router: Router,
		private readonly mutexService: MutexService,
		private readonly mainService: MainService,
		private readonly processResource: ProcessResource,
	) {}

	async createEmptyProcess(workspaceId: string, pv: string): Promise<stages.process.ProcessVersion> {
		return this.mutexService.invokeWithResult("mutexCreateEmptyProcess", async () => {
			this.processResource.setAdditionalHttpParamsForNextRequest({
				pv: pv,
			});
			return this.processResource.createProcess(workspaceId);
		});
	}

	goToProcessOrHome(): void {
		this.mainService.getCurrentWorkspace().then((workspaceView) => {
			if (workspaceView.currentWorkspace.viewedProcess) {
				this.router.navigate(
					[
						"main.global.process.view.element",
						{
							type: "process",
							identity: workspaceView.currentWorkspace.viewedProcess.identity,
							view: "browse",
							action: "view",
						},
					],
					{
						//  reload: true
					},
				);
			} else {
				this.router.navigate(["main.global.workspace.home"]);
			}
		});
	}
}
