<ng-container *ngIf="paramMap$ | async as paramMap">
	<stages-dialog2 #dialog>
		<stages-dialog-header></stages-dialog-header>
		<ng-container *ngIf="credentialsValid; else authenticate">
			<ng-container *ngIf="fileProperties$ | async as fileProperties; else loadingOrError">
				<h1 translate="{{ getMessageKeyForAdd(paramMap, fileProperties) }}"></h1>
				<form [formGroup]="uploadForm" class="content file-add-dialog" name="uploadForm" novalidate #formElement>
					<stages-upload
						[uploadCompletedCallback]="getUploadCompletedCallback(fileProperties)"
						[uploadUrl]="uploadUrl"
						[multiple]="paramMap.get('singleFile') !== 'true'"
					></stages-upload>

					<div *ngIf="errorMessage" class="error hint">
						<span>{{ errorMessage }}</span>
					</div>

					<article class="new-files" *ngIf="addFilesUpload.length">
						<header>
							<h5 translate="files.add.files"></h5>
						</header>
						<ul class="list dense" formArrayName="addFiles">
							<li class="list-item column-style" *ngFor="let addFileControl of addFiles.controls; let i = index">
								<div class="label">
									<div class="icon-spacer">
										<i class="ico ico-file"></i>
									</div>
									<input type="text" class="text" required [formControlName]="i" autocomplete="off" />
									<button
										[attr.title]="'delete' | translate"
										[attr.aria-label]="'delete' | translate"
										(click)="deleteUpload(addFilesUpload[i].uploadId, paramMap)"
										class="action clickable"
									>
										<i class="ico ico-delete"></i>
									</button>
								</div>
								<div class="label info">
									<div
										class="errors"
										*ngIf="
											hasAlreadyIncludedWarning($any(addFileControl)) ||
											(!uploadForm.valid && (addFileControl.dirty || addFileControl.touched))
										"
									>
										<ng-container
											*ngIf="
												addFileControl.errors && !uploadForm.valid && (addFileControl.dirty || addFileControl.touched)
											"
										>
											<div *ngIf="addFileControl.errors['required']" class="error" translate="input.required"></div>
											<div
												*ngIf="addFileControl.errors['max']"
												class="error"
												translate="input.maxLength"
												[translateParams]="{ length: 255 }"
											></div>
											<div
												*ngIf="addFileControl.errors['alreadySelected']"
												class="error"
												translate="input.alreadySelected"
											></div>
										</ng-container>
										<div
											*ngIf="hasAlreadyIncludedWarning($any(addFileControl))"
											class="error info"
											translate="input.alreadyIncluded"
										></div>
									</div>
									<div class="maxlength">{{ addFileControl.value.length }}/255</div>
								</div>
							</li>
						</ul>
					</article>

					<article class="new-versions" *ngIf="updateFilesUpload.length">
						<header>
							<h5 translate="files.add.versions"></h5>
						</header>
						<p class="hint" translate="files.add.versions.message"></p>
						<ul class="list dense" formArrayName="updateFiles">
							<li class="list-item" *ngFor="let updateFilesControl of updateFiles.controls; let i = index">
								<i class="ico ico-file"></i>
								<div class="text">
									<input type="hidden" [formControlName]="i" stagesFiletype="{{ getFileType(fileProperties) }}" />
									<h4>{{ updateFilesControl.value }}</h4>
									<p translate="files.add.versions.of" [translateParams]="{ name: getUpdateFilename(i) }"></p>
									<div class="errors">
										<ng-container *ngIf="!uploadForm.valid && (updateFilesControl.dirty || updateFilesControl.touched)">
											<div
												*ngIf="updateFilesControl.errors!['filetype']"
												class="error"
												translate="input.typeMismatch"
												[translateParams]="{ filetype: fileProperties.fileType }"
											></div>
											<div
												*ngIf="updateFilesControl.errors!['alreadySelected']"
												class="error"
												translate="input.alreadySelected"
											></div>
										</ng-container>
									</div>
								</div>
								<button
									[attr.title]="'delete' | translate"
									[attr.aria-label]="'delete' | translate"
									(click)="deleteUpload(updateFilesUpload[i].uploadId, paramMap)"
									class="action clickable"
								>
									<i class="ico ico-delete"></i>
								</button>
							</li>
						</ul>
					</article>

					<article class="form properties">
						<stages-attribute-edit
							[attributeTypes]="fileProperties.attributeTypes"
							[attributes]="fileProperties.attributes"
							[form]="uploadForm"
						>
						</stages-attribute-edit>
					</article>
				</form>
				<footer>
					<div class="buttons">
						<button class="button sm cancel" translate="cancel" (click)="dialog.close()"></button>
						<button
							class="button sm save"
							[disabled]="saveButtonDisabled(paramMap)"
							(click)="saveClicked(fileProperties)"
						>
							<span *ngIf="!saveInProgress" translate="files.start.upload.button"></span>
							<stages-spin *ngIf="saveInProgress" size="s"></stages-spin>
						</button>
					</div>
				</footer>
			</ng-container>
		</ng-container>
	</stages-dialog2>
</ng-container>

<ng-template #loadingOrError>
	<h1 *ngIf="loadingError$ | async as error" translate="error.title"></h1>
	<stages-popup-content>
		<stages-loading-error-embeddable
			[credentialsValid]="credentialsValid"
			[loadingError$]="loadingError$"
		></stages-loading-error-embeddable>
	</stages-popup-content>
	<stages-popup-footer [hasCancelButton]="false" [buttons]="buttons"> </stages-popup-footer>
</ng-template>

<ng-template #authenticate>
	<stages-files-auth-embeddable
		#auth
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		*ngIf="!credentialsValid && !oAuthUrl"
	></stages-files-auth-embeddable>
	<stages-files-oauth-embeddable-confirm
		[cmsTypeMessageKey]="cmsTypeMessageKey!"
		[authUrl]="oAuthUrl"
		[closeCallback]="close"
		*ngIf="!credentialsValid && oAuthUrl"
	></stages-files-oauth-embeddable-confirm>
</ng-template>
