import { APP_INITIALIZER, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppLoadService } from "app/app-load.service";
import { ApplicationContextResolver } from "app/application-context-resolver.service";
import { HomeGuard } from "app/home-guard.service";
import { MainComponent } from "app/main.component";
import { WorkspaceViewResolver } from "app/workspace-view-resolver.service";
import { AuthenticationComponent } from "common/authentication/authentication.component";
import { ErrorMessageComponent } from "common/exception/error-message.component";
import { ErrorReportComponent } from "common/exception/error-report.component";
import { ErrorComponent } from "common/exception/error.component";
import { UnavailableComponent } from "common/exception/unavailable.component";
import { PopupComponent } from "common/newdialog/popup.component";
import { ProxyComponent } from "common/proxy/proxy.component";
import { TitleResolver } from "common/title-resolver.service";
import { DashboardComponent } from "dashboard/dashboard.component";
import { EntryPageTabsResolver } from "dashboard/description/entry-page-tabs-resolver";
import { TaskDetailsResolver } from "dashboard/to-do-list/task-details-resolver.service";
import { TaskDetailsComponent } from "dashboard/to-do-list/task-details.component";
import { DownloadCanActivateGuard } from "files/download-after-authentication/download.can-activate.guard";
import { filesRoutes } from "files/files-routing.module";
import { GlobalSearchResultsComponent } from "search/results.component";
import { TaskProcessDiffRedirectComponent } from "dashboard/to-do-list/task-process-diff-redirect.component";
import { TodoResolver } from "dashboard/to-do-list/to-do-resolver.service";
import { ExternalLinkRedirectGuard } from "app/external-link-redirect-guard.service";

const appRoutes: Routes = [
	{
		path: "login",
		component: AuthenticationComponent,
		data: { titleKey: "main.title" },
	},
	{
		path: "download",
		component: ProxyComponent, // dummy component, because route will be changed in DownloadGuard
		canActivate: [DownloadCanActivateGuard],
	},
	{
		path: "extlink/task/:taskId/todo",
		component: ProxyComponent, // dummy component, because route will be changed in ExternalLinkRedirectGuard
		data: { action: "task" },
		canActivate: [ExternalLinkRedirectGuard],
	},
	{
		path: "extlink/task/:taskId/diff",
		component: ProxyComponent, // dummy component, because route will be changed in ExternalLinkRedirectGuard
		data: { action: "taskdiff" },
		canActivate: [ExternalLinkRedirectGuard],
	},
	{
		path: "extlink/administration/cleanup/archives",
		component: ProxyComponent, // dummy component, because route will be changed in ExternalLinkRedirectGuard
		data: { action: "archives" },
		canActivate: [ExternalLinkRedirectGuard],
	},
	{
		path: "workspace/:workspaceId/:processVersion",
		resolve: {
			workspaceView: WorkspaceViewResolver,
			applicationContext: ApplicationContextResolver,
		},
		component: MainComponent,
		data: { popupContainer: true },
		children: [
			{
				path: "home",
				component: DashboardComponent,
				resolve: {
					title: TitleResolver,
					entryPageTabs: EntryPageTabsResolver,
				},
				data: { popupContainer: false },
			},
			{
				path: "process",
				loadChildren: () => import("process/process.module").then((m) => m.StagesProcessModule),
				data: { popupContainer: false },
			},
			{
				path: "process",
				outlet: "popup",
				component: PopupComponent,
				loadChildren: () => import("process/process.module").then((m) => m.StagesProcessModule),
				data: { popupContainer: false },
			},
			{
				path: "process",
				outlet: "dialog",
				loadChildren: () => import("process/process.module").then((m) => m.StagesProcessModule),
				data: { popupContainer: false },
			},
			{
				path: "management",
				loadChildren: () => import("management/management.module").then((m) => m.StagesManagementModule),
				data: { popupContainer: false },
			},
			{
				path: "management",
				outlet: "popup",
				component: PopupComponent,
				loadChildren: () => import("management/management.module").then((m) => m.StagesManagementModule),
				data: { popupContainer: false },
			},
			{
				path: "reporting",
				loadChildren: () => import("reports/reporting.module").then((m) => m.StagesReportingModule),
				data: { popupContainer: false },
			},
			{
				path: "reporting",
				outlet: "popup",
				component: PopupComponent,
				loadChildren: () => import("reports/reporting.module").then((m) => m.StagesReportingModule),
				data: { popupContainer: false },
			},
			{
				path: "administration",
				loadChildren: () => import("administration/administration.module").then((m) => m.AdministrationModule),
				data: { popupContainer: false },
			},
			{
				path: "administration",
				outlet: "popup",
				component: PopupComponent,
				loadChildren: () => import("administration/administration.module").then((m) => m.AdministrationModule),
				data: { popupContainer: false },
			},
			{
				path: "user",
				loadChildren: () => import("user/user.module").then((m) => m.StagesUserModule),
				data: { popupContainer: false },
			},
			{
				path: "user",
				outlet: "dialog",
				loadChildren: () => import("user/user.module").then((m) => m.StagesUserModule),
				data: { popupContainer: false },
			},
			{
				path: "search",
				outlet: "dialog",
				component: GlobalSearchResultsComponent,
				data: { popupContainer: false },
			},
			{
				path: "install",
				outlet: "popup",
				component: PopupComponent,
				loadChildren: () => import("install/install.module").then((m) => m.StagesInstallModule),
				data: { popupContainer: false },
			},
			{
				path: "files",
				// loadChildren: "files/files.module#StagesFilesModule", // not yet
				children: filesRoutes,
				data: { popupContainer: false },
			},
			{
				path: "files",
				outlet: "popup",
				component: PopupComponent,
				//loadChildren: "files/files.module#StagesFilesModule", // not yet
				children: filesRoutes,
				data: { popupContainer: false },
			},
			{
				path: "files",
				outlet: "dialog",
				//loadChildren: "files/files.module#StagesFilesModule", // not yet
				children: filesRoutes,
				data: { popupContainer: false },
			},
			{
				path: "print",
				outlet: "popup",
				component: PopupComponent,
				loadChildren: () => import("print/print.module").then((m) => m.StagesPrintModule),
				data: { popupContainer: false },
			},
			{
				path: "tasks",
				outlet: "popup",
				component: PopupComponent,
				data: { popupContainer: false },
				children: [
					{
						component: TaskDetailsComponent,
						path: "task/:id",
						resolve: {
							task: TaskDetailsResolver,
						},
					},
					{
						component: TaskProcessDiffRedirectComponent,
						path: "taskdiff/:id",
						resolve: {
							task: TodoResolver,
						},
					},
				],
			},
			{
				path: "error",
				component: ErrorComponent,
				data: {
					titleKey: "error.title",
				},
			},
			{
				path: "errorReport",
				component: ErrorReportComponent,
				data: {
					titleKey: "error.title",
				},
			},
		],
	},
	{
		path: "unavailable",
		component: UnavailableComponent,
		data: {
			titleKey: "error.title",
		},
	},
	{
		path: "errorMessage",
		component: ErrorMessageComponent,
		data: {
			titleKey: "error.title",
		},
	},
	{
		path: "",
		pathMatch: "full",
		component: ProxyComponent,
		canActivate: [HomeGuard],
	},
	{
		path: "**",
		redirectTo: "/",
	},
];

export function initializeLanguage(appLoadService: AppLoadService): () => void {
	return async () => appLoadService.initializeLanguage();
}

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			enableTracing: false,
			useHash: true,
			paramsInheritanceStrategy: "always",
			scrollPositionRestoration: "disabled",
			relativeLinkResolution: "corrected",
		}),
	],
	exports: [RouterModule],
	providers: [{ provide: APP_INITIALIZER, useFactory: initializeLanguage, deps: [AppLoadService], multi: true }],
})
export class AppRoutingModule {}
