<!-- Template for Spinner-animation. Provides Fallback for IE and Edge because of a lack of css-animation support -->
<!-- Fallback Spin -->
<div [attr.aria-busy]="'true'" [attr.title]="'loading' | translate" class="spin-container" *ngIf="needsFallBack">
	<div class="fallback-spinner" #spin></div>
</div>
<div [attr.aria-busy]="'true'" [attr.title]="'loading' | translate" class="spin-container" *ngIf="!needsFallBack">
	<div [style.width]="getSize()" [style.height]="getSize()">
		<svg class="svg-spin-content" viewBox="0 0 66 66" xmlns:svg="http://www.w3.org/2000/svg">
			<svg:circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></svg:circle>
		</svg>
	</div>
</div>
