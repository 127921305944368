import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { ProcessInterfacesService } from "management/process-interfaces/process-interfaces.service";
import { WorkspaceListStore } from "management/process-interfaces/workspace-list/workspace-list-store.logic";
import { MutexService } from "common/concurrency/mutex.service";
import { DataViewComponent, Mode } from "common/data/data-view.component";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { ProcessInterfacesFilterService } from "management/process-interfaces/process-interfaces-filter.service";
import { SelectionRepository } from "common/selection/state/selection.repository";
import { getIdKeyOfTypeId } from "common/selection/state/selection-store.service";
import TypedId = stages.core.TypedId;

type ProcessInterfaceWorkspace = stages.management.interfaces.ProcessInterfaceWorkspace;

interface ReleaseStatus {
	messageKey: string;
	iconClass: string;
}

@Component({
	selector: "stages-process-interfaces-workspace-list",
	templateUrl: "./process-interfaces-workspace-list.component.html",
	styleUrls: ["./process-interfaces-workspace-list.component.scss"],
})
export class ProcessInterfacesWorkspaceListComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	static entityType = "workspace";
	@ViewChild("dataView", { static: false })
	dataView!: DataViewComponent<ProcessInterfaceWorkspace>;

	@Input()
	mode: Mode = Mode.VIEW;

	@Input()
	showReleaseWarning: boolean = false;

	@Input()
	menuItems?: MenuItem[];

	@Input()
	showSelectAllSwitch: boolean = true;

	@Input()
	useRouteNavigation: boolean = true;

	_showOnlyChanges?: boolean;
	get showOnlyChanges(): boolean | undefined {
		return this._showOnlyChanges;
	}

	@Input()
	set showOnlyChanges(value: boolean | undefined) {
		this._showOnlyChanges = value;
		if (value !== undefined) {
			this.updateWorkspaceListStore(value);
		}
	}

	@Input()
	isReleasePreview: boolean = false;

	@Output()
	readonly workspaceClicked = new EventEmitter<string>();

	@Output()
	readonly workspacesSelected = new EventEmitter<string[]>();

	ProcessInterfacesWorkspaceSelection = ProcessInterfacesWorkspaceListComponent;

	// until now we can  not handle multiple pageable datasources on one site
	// so we want to disable it
	pageSize: number = 10000;
	dataSource!: PageableDataSource<ProcessInterfaceWorkspace>;
	workspaceListStore?: WorkspaceListStore;

	// TODO static identfier can cause problems when using the component more than once in the same place
	static storeIdentifier: string;

	private selectedProcessVersion?: string | null;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private processInterfacesService: ProcessInterfacesService,
		private processInterfacesFilterService: ProcessInterfacesFilterService,
		private mutexService: MutexService,
		private readonly selectionRepository: SelectionRepository,
	) {
		// default storeIdentifier
		ProcessInterfacesWorkspaceListComponent.storeIdentifier = "processInterfaces";
	}

	ngOnInit(): void {
		this.connectToInterfacesFilterService();
		this.processInterfacesService.setTargetWorkspaceId(undefined);

		// optional storeIdentifier can be overwritten by routing
		this.route.data
			.pipe(map((data) => data.storeIdentifier))
			.pipe(takeUntil(this.destroy$))
			.subscribe((storeIdentifier) => {
				if (storeIdentifier) {
					ProcessInterfacesWorkspaceListComponent.storeIdentifier = storeIdentifier;
				}
			});

		combineLatest([this.route.paramMap, this.route.queryParamMap])
			.pipe(takeUntil(this.destroy$))
			.subscribe(async ([urlParamMap, urlQueryParamMap]) => {
				if (urlParamMap.has("page")) {
					this.processInterfacesService.setWorkspacePage(Number.parseInt(urlParamMap.get("page")!, 10));
				}

				if (urlQueryParamMap.has("pvForInterfaces")) {
					this.selectedProcessVersion = urlQueryParamMap.get("pvForInterfaces")!;
				} else {
					await this.router.navigate([], {
						queryParams: { pvForInterfaces: urlParamMap.get("processVersion") },
						replaceUrl: true,
					});
				}
			});

		if (!this.menuItems) {
			this.setupMenuItems();
		}
	}

	setupStoreAndDataSource(): void {
		this.workspaceListStore = new WorkspaceListStore(
			this.processInterfacesService,
			this.pageSize,
			this.showOnlyChanges ?? false,
			this.isReleasePreview ?? false,
		);
		this.dataSource = new PageableDataSource(
			this.workspaceListStore,
			this.mutexService,
			this.router,
			this.route,
			"page",
		);
	}

	connectToInterfacesFilterService(): void {
		if (this.showOnlyChanges !== undefined) {
			this.processInterfacesFilterService.setShowOnlyChanges(this.showOnlyChanges);
		}

		this.processInterfacesFilterService.showOnlyChanges$
			.pipe(debounceTime(300), takeUntil(this.destroy$))
			.subscribe((showOnlyChanges) => {
				if (this.workspaceListStore === undefined) {
					this._showOnlyChanges = showOnlyChanges;
					this.setupStoreAndDataSource();
				} else {
					this.updateWorkspaceListStore(showOnlyChanges);
				}
			});
	}

	private setupMenuItems(): void {
		this.menuItems = [
			{
				name: "management.process.interfaces.page.workspace.open",
				iconClass: "ico ico-navigate-to",
				disabled: () => {
					return false;
				},
				routerLink: (listEntry: ProcessInterfaceWorkspace) => [
					"//",
					"workspace",
					listEntry.id,
					this.selectedProcessVersion,
					"process",
				],
			},
		];
	}

	onWorkspaceClicked(processInterfaceWorkspace: ProcessInterfaceWorkspace): void {
		this.workspaceClicked.emit(processInterfaceWorkspace.id);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- angular uses any for the commands as well
	getWorkspaceLink(processInterfaceWorkspace: ProcessInterfaceWorkspace): any[] {
		return ["./", processInterfaceWorkspace.id, { page: 1 }];
	}

	updateWorkspaceListStore(showOnlyChanges: boolean): void {
		if (this.workspaceListStore) {
			this.workspaceListStore._showOnlyChanges = showOnlyChanges;
			this.dataSource.reloadPage();
		}
	}

	getStatus(processInterfaceWorkspace: ProcessInterfaceWorkspace): ReleaseStatus {
		if (
			processInterfaceWorkspace.actions.ReleaseInterfaceOfWorkspace &&
			processInterfaceWorkspace.hasApplicableChanges
		) {
			return {
				messageKey: "ok",
				iconClass: "ico-check",
			};
		} else if (!processInterfaceWorkspace.actions.Release) {
			return {
				messageKey: "management.process.interfaces.page.workspaces.th3.noPermission",
				iconClass: "ico-close",
			};
		} else {
			return {
				messageKey: "management.process.interfaces.page.workspaces.th3.noChanges",
				iconClass: "ico-close",
			};
		}
	}

	countSelectedItems(workspace: ProcessInterfaceWorkspace): number {
		const workspaceEntity = this.selectionRepository.getEntity(
			getIdKeyOfTypeId(ProcessInterfacesWorkspaceListComponent.getIdNew(workspace)),
			ProcessInterfacesWorkspaceListComponent.storeIdentifier,
		);

		if (workspaceEntity) {
			const hasChildren = workspace.count > 0;
			// const hasChildren = workspace.selectableChildrenCount > 0;
			const selectedChildrenCountFromCommonStore =
				this.selectionRepository.getSelectedChildrenCountFromCommonStore(workspaceEntity) ?? 0;

			const deSelectedChildrenCount =
				this.selectionRepository.getDeSelectedChildrenCountFromCommonStore(workspaceEntity) ?? 0;

			const interfacesUnknown = selectedChildrenCountFromCommonStore === 0 && deSelectedChildrenCount === 0;

			let newTotal = 0;

			// TODO check if interfacesUnknown can be removed
			if (interfacesUnknown && workspaceEntity.isSelected) {
				newTotal = workspaceEntity.indeterminate
					? workspaceEntity.initiallySelectedCount ?? 0
					: workspaceEntity.selectableChildrenCount ?? 0;
			}

			return hasChildren && interfacesUnknown ? newTotal : selectedChildrenCountFromCommonStore;
		}
		return 0;
	}

	getStoreIdentifier(): string {
		return ProcessInterfacesWorkspaceListComponent.storeIdentifier;
	}

	isSelect(): boolean {
		return this.mode === Mode.SELECT;
	}

	static getId(workspace: ProcessInterfaceWorkspace): string {
		return workspace.id;
	}

	static getIdNew(workspace: ProcessInterfaceWorkspace): TypedId {
		return {
			id: workspace.id,
			typeIdent: ProcessInterfacesWorkspaceListComponent.entityType,
		};
	}

	static isDisabled(workspace: ProcessInterfaceWorkspace): boolean {
		return !workspace.hasApplicableChanges;
	}

	static isPreselected(workspace: ProcessInterfaceWorkspace): boolean {
		return workspace.initiallySelectedCount > 0;
	}

	static isPartiallySelected(workspace: ProcessInterfaceWorkspace): boolean {
		return workspace.initiallySelectedCount < workspace.selectableChildrenCount && workspace.initiallySelectedCount > 0;
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
