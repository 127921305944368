import { Directive, Input, TrackByFunction } from "@angular/core";
import { Dialog } from "common/editor/plugin.service";
import { ListService } from "common/list/list.service";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { ProcessElementsResource } from "core/stages-client";

type ProcessTreeItem = stages.process.ProcessTreeItem;
type ProcessView = stages.process.ProcessView;
type CurrentWorkspace = stages.workspace.application.CurrentWorkspace;

@Directive()
export abstract class ProcessElementPluginComponent {
	mode: "ANCHORS" | "EXTERNAL" | "INTERNAL" | "WORKSPACE" = "INTERNAL";
	currentWorkspace!: CurrentWorkspace;
	browseWorkspace!: WorkspaceSelected;
	folder?: ProcessTreeItem;
	workspaceTab!: string;

	@Input()
	dialog!: Dialog;

	constructor(protected readonly processElementsResource: ProcessElementsResource, private listService: ListService) {}

	getOriginWorkspaceId(): string {
		const properties = this.dialog.getNonProcessEditorProperties();
		return properties ? properties.linkOriginWorkspaceId : this.dialog.getWorkspaceId();
	}

	abstract setBrowseView(workspaceId: string, type: string, identity: string): Promise<ProcessView>;

	async updateFolder(type: string, identity: string, workspaceId: string): Promise<ProcessView | undefined> {
		if (this.currentWorkspace.id === workspaceId && !this.currentWorkspace.viewedProcess) {
			return undefined;
		}
		const element = await this.setBrowseView(workspaceId, type, identity);
		this.folder = element.parent && (!element.children || element.children.length === 0) ? element.parent : element;
		if (!this.folder.parent) {
			if (!this.browseWorkspace.id) {
				throw new Error("Unknown workspace");
			}
			const self = await this.setBrowseView(workspaceId, this.folder.type.ident, this.folder.identity);
			this.folder = self.parent && (!self.children || self.children.length === 0) ? self.parent : self;
		}
		return element;
	}

	onSelectWorkspace(workspace: WorkspaceSelected): void {
		if (workspace.id !== this.browseWorkspace.id) {
			this.browseWorkspace = workspace;
			this.navigateToStart();
			this.mode = "INTERNAL";
		} else {
			this.mode = "INTERNAL";
		}
	}

	toggleBrowseWorkspaceMode(): void {
		this.mode = this.mode === "INTERNAL" ? "WORKSPACE" : "INTERNAL";
	}

	abstract navigateToStart(): void;

	navigateToElement(element: ProcessTreeItem): void {
		this.updateFolder(element.type.ident, element.identity, this.browseWorkspace.id);
	}

	abstract insertLinkTo(element: ProcessTreeItem): Promise<void>;

	abstract toggleDetails(element?: ProcessTreeItem): Promise<void>;

	clickBrowseElement(element: ProcessTreeItem): void {
		if (!!element.children && element.children.length) {
			this.navigateToElement(element);
		} else {
			this.insertLinkTo(element);
		}
	}

	onChangeTab(tab: string): void {
		this.workspaceTab = tab;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32217: Enable strict template type checking and suppress or fix all existing warnings.
	getUniqueId: TrackByFunction<any> = (index) => {
		return `${index}`;
	};

	async getEditableDescription(
		element: ProcessTreeItem,
		viewModeEdit: boolean,
		lang?: java.util.Locale,
	): Promise<string> {
		if (this.isCurrentElement(element)) {
			return this.dialog.getData();
		}
		const description =
			element.type.ident === "description"
				? await this.processElementsResource.getReadonlyDescriptionResource(
						this.browseWorkspace.id,
						element.type.ident,
						element.identity,
						{ viewModeEdit: viewModeEdit, lang: lang },
				  )
				: await this.processElementsResource.getDescription(
						this.browseWorkspace.id,
						element.type.ident,
						element.identity,
						{ editable: viewModeEdit, lang: lang },
				  );
		return description.editableDescription.html;
	}

	getVisibleEntries(completeList: ProcessTreeItem[] | undefined): ProcessTreeItem[] {
		if (!completeList) {
			return [];
		}
		return this.listService.getVisibleListItems(completeList) as ProcessTreeItem[];
	}

	hasOmmittedEntries(completeList: ProcessTreeItem[] | undefined): boolean {
		if (!completeList) {
			return false;
		}
		return this.listService.hasOmmittedItems(completeList);
	}

	private isCurrentElement(element: ProcessTreeItem): boolean {
		return element.id === this.dialog.getBeanId() && element.type.ident === this.dialog.getBeanType();
	}
}
