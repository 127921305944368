import { NgModule } from "@angular/core";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { StagesCommonModule } from "common/common.module";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { PopupComponent } from "common/newdialog/popup.component";
import { ImageZoomComponent } from "common/zoom/image-zoom/image-zoom.component";
import { StagesFilesModule } from "files/files.module";
import { ModelAssociationListComponent } from "process/associations/model-association-list.component";
import { ElementCommentComponent } from "process/comments/element-comment.component";
import { CompareToDialogComponent } from "process/compare/compare-to-dialog.component";
import { CompareToProgressComponent } from "process/compare/compare-to-progress.component";
import { CompareToComponent } from "process/compare/compare-to.component";
import {
	ComplianceAssociationListComponent
} from "process/compliance/associations/compliance-association-list.component";
import {
	ProcessComplianceAssociationListComponent
} from "process/compliance/associations/process-compliance-association-list.component";
import { RatingComponent } from "process/compliance/rating/rating.component";
import { DescriptionsReadonlyComponent } from "process/description/descriptions/descriptions-readonly.component";
import { HtmlTemplatePluginComponent } from "process/description/htmltemplate-plugin/htmltemplate-plugin.component";
import { ExternalLinkComponent } from "process/description/link-plugin/external-link.component";
import { LinkAnchorsComponent } from "process/description/link-plugin/link-anchors.component";
import { LinkDetailsComponent } from "process/description/link-plugin/link-details.component";
import { LinkItemComponent } from "process/description/link-plugin/link-item.component";
import { LinkPluginComponent } from "process/description/link-plugin/link-plugin.component";
import { LinkSearchComponent } from "process/description/link-plugin/link-search.component";
import { ProcessDescriptionComponent } from "process/description/process-description.component";
import { DiagramHtmlPipe } from "process/diagram/diagram-html.pipe";
import { DiagramComponent } from "process/diagram/diagram.component";
import { EditComponent } from "process/edit/edit.component";
import { AddSequenceDialogComponent } from "process/element/add/add-sequence-dialog.component";
import { AddSequenceComponent } from "process/element/add/add-sequence.component";
import { EditElementComponent } from "process/element/edit/edit-element.component";
import { FilesComponent } from "process/element/process-files.component";
import { PropertiesWrapperComponent } from "process/element/properties/properties-wrapper.component";
import { PropertiesComponent } from "process/element/properties/properties.component";
import { EnactmentAssociationListComponent } from "process/enactment/association/enactment-association-list.component";
import {
	EnactmentActivatorExecutionDetailsComponent
} from "process/enactment/execution/enactment-activator-execution-details.component";
import {
	EnactmentActivatorExecutionStartComponent
} from "process/enactment/execution/enactment-activator-execution-start.component";
import {
	EnactmentActivatorExecutionComponent
} from "process/enactment/execution/enactment-activator-execution.component";
import { FeedbackLinkWidgetComponent } from "process/feedback/feedback-link-widget.component";
import { FeedbackSwitchWidgetComponent } from "process/feedback/feedback-switch-widget.component";
import { FeedbackWidgetComponent } from "process/feedback/feedback-widget.component";
import { FreezeComponent } from "process/freeze/freeze.component";
import { PhaseFreezeOverviewComponent } from "process/freeze/overview/phase-freeze-overview.component";
import { UpdateConsiderFrozenComponent } from "process/freeze/update-consider-frozen.component";
import { ActivityStreamComponent } from "process/history/activity-stream.component";
import { VisibleFilterPipe } from "process/history/visible-filter-pipe";
import { IndexComponent } from "process/index/index.component";
import { ProcessNavigationComponent } from "process/navigation/process-navigation.component";
import { NoProcessComponent } from "process/noprocess.component";
import { NotificationComponent } from "process/notification/notification.component";
import { ProcessReleaseDetailsComponent } from "process/process-release-details/process-release-details.component";
import { ProcessRoutingModule } from "process/process-routing.module";
import { ProcessComponent } from "process/process.component";
import { QueryTableComponent } from "process/query/query-table.component";
import { RedirectToIndexIdentityComponent } from "process/redirect-to-index-identity.component";
import { RedirectComponent } from "process/redirect.component";
import { StartOptionsComponent } from "process/start/start-options.component";
import { StartProgressComponent } from "process/start/start-progress.component";
import { StartSummaryComponent } from "process/start/start-summary.component";
import { StartComponent } from "process/start/start.component";
import { ProcessDocumentComponent } from "process/table/process-document.component";
import { ProcessListComponent } from "process/table/process-list.component";
import { ProcessTableComponent } from "process/table/process-table.component";
import { TailoringWizardAnswerItemComponent } from "process/tailoring/components/answer-item/answer-item.component";
import {
	TailoringWizardAnsweredQueriesComponent
} from "process/tailoring/components/answered-queries/answered-queries.component";
import { TailoringWizardPreviewComponent } from "process/tailoring/components/preview/preview.component";
import { TailoringWizardQueryNameComponent } from "process/tailoring/components/query-name/query-name.component";
import { TailoringWizardComponent } from "process/tailoring/containers/tailoring-wizard/tailoring-wizard.component";
import { ManualTailoringComponent } from "process/tailoring/manual/manual-tailoring.component";
import { RouteableTailoringComponent } from "process/tailoring/manual/routeable-tailoring.component";
import { TailoringWizardAnsweredPipe } from "process/tailoring/pipes/answered.pipe";
import { ResetDialogComponent } from "process/tailoring/reset/reset-dialog.component";
import { EditTranslationsComponent } from "process/translate/edit-translations.component";
import { TranslateComponent } from "process/translate/translate.component";
import { UserAssignmentsComponent } from "process/userassignments/user-assignments.component";
import { UserGroupsComponent } from "process/usergroups/user-groups.component";
import { CardDynamicComponent } from "process/view/card-dynamic.component";
import { DummyComponent } from "process/view/dummy.component";
import { ElementNameComponent } from "process/view/element-name.component";
import { ProcessViewComponent } from "process/view/process-view.component";
import { ResponsiveWidgetContainerComponent } from "process/view/responsive-widget-container.component";
import { TabCardNoRouteDynamicComponent } from "process/view/tab-card-no-route-dynamic.component";
import { TabContentDynamicComponent } from "process/view/tab-content-dynamic.component";
import { Template12Component } from "process/view/template-12.component";
import { Template444Component } from "process/view/template-4-4-4.component";
import { Template66Component } from "process/view/template-6-6.component";
import { Template84Component } from "process/view/template-8-4.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import {
	DependentElementsListComponent,
	GetDependentElementDescriptionPipe
} from "process/dependant-elements/dependent-elements-list.component";
import { CompareToUnavailableComponent } from "process/compare/compare-to-unavailable.component";

const viewComponents = {
	ResponsiveWidgetContainerComponent: ResponsiveWidgetContainerComponent,
	card: CardDynamicComponent,
	tabbedcard: TabCardNoRouteDynamicComponent,
	IndexComponent: IndexComponent,
	QueryTableComponent: QueryTableComponent,
	Template12Component: Template12Component,
	Template444Component: Template444Component,
	Template66Component: Template66Component,
	Template84Component: Template84Component,
	RatingComponent: RatingComponent,
	FilesComponent: FilesComponent,
	ProcessDescriptionComponent: ProcessDescriptionComponent,
	UserAssignmentsComponent: UserAssignmentsComponent,
	PropertiesComponent: PropertiesWrapperComponent,
	DiagramComponent: DiagramComponent,
	AssociationListComponent: ModelAssociationListComponent,
	ComplianceAssociationListComponent: ComplianceAssociationListComponent,
	ProcessComplianceAssociationListComponent: ProcessComplianceAssociationListComponent,
	ElementCommentComponent: ElementCommentComponent,
	ActivityStreamComponent: ActivityStreamComponent,
	AddSequenceComponent: AddSequenceComponent,
	FeedbackWidgetComponent: FeedbackWidgetComponent,
	FeedbackLinkWidgetComponent: FeedbackLinkWidgetComponent,
	FeedbackSwitchWidgetComponent: FeedbackSwitchWidgetComponent,
	EnactmentAssociationListComponent: EnactmentAssociationListComponent,
	EnactmentActivatorExecutionComponent: EnactmentActivatorExecutionComponent,
	PhaseFreezeOverviewComponent: PhaseFreezeOverviewComponent,
	ProcessTableComponent: ProcessTableComponent,
	ProcessListComponent: ProcessListComponent,
	ProcessDocumentComponent: ProcessDocumentComponent,
	NotificationComponent: NotificationComponent,
	UserGroupsComponent: UserGroupsComponent,
	DependentElementsListComponent: DependentElementsListComponent,
};

@NgModule({
	imports: [
		RouterModule,
		StagesCommonModule,
		StagesFilesModule,
		ProcessRoutingModule,
		MatTableModule,
		MatSortModule,
		DragDropModule,
	],
	declarations: [
		NoProcessComponent,
		DummyComponent,
		ProcessComponent,
		ModelAssociationListComponent,
		ProcessDescriptionComponent,
		FilesComponent,
		RatingComponent,
		ComplianceAssociationListComponent,
		ProcessComplianceAssociationListComponent,
		CardDynamicComponent,
		ImageZoomComponent,
		ProcessViewComponent,
		ResponsiveWidgetContainerComponent,
		Template12Component,
		Template444Component,
		Template66Component,
		Template84Component,
		IndexComponent,
		QueryTableComponent,
		StartComponent,
		StartOptionsComponent,
		StartProgressComponent,
		StartSummaryComponent,
		UserAssignmentsComponent,
		PropertiesComponent,
		PropertiesWrapperComponent,
		DiagramComponent,
		TabCardNoRouteDynamicComponent,
		TabContentDynamicComponent,
		ElementCommentComponent,
		ActivityStreamComponent,
		VisibleFilterPipe,
		EditComponent,
		EditElementComponent,
		AddSequenceComponent,
		AddSequenceDialogComponent,
		ManualTailoringComponent,
		RouteableTailoringComponent,
		TailoringWizardAnswerItemComponent,
		TailoringWizardAnsweredQueriesComponent,
		TailoringWizardAnsweredPipe,
		TailoringWizardComponent,
		TailoringWizardPreviewComponent,
		TailoringWizardQueryNameComponent,
		ProcessReleaseDetailsComponent,
		DependentElementsListComponent,
		GetDependentElementDescriptionPipe,

		RedirectComponent,
		RedirectToIndexIdentityComponent,
		ProcessNavigationComponent,

		// Description
		ProcessDescriptionComponent,
		LinkPluginComponent,
		LinkItemComponent,
		LinkDetailsComponent,
		LinkAnchorsComponent,
		ExternalLinkComponent,
		LinkSearchComponent,
		DescriptionsReadonlyComponent,
		ResetDialogComponent,
		FeedbackWidgetComponent,
		FeedbackLinkWidgetComponent,
		FeedbackSwitchWidgetComponent,
		EnactmentAssociationListComponent,
		EnactmentActivatorExecutionComponent,
		EnactmentActivatorExecutionDetailsComponent,
		EnactmentActivatorExecutionStartComponent,
		HtmlTemplatePluginComponent,

		FreezeComponent,
		PhaseFreezeOverviewComponent,
		UpdateConsiderFrozenComponent,
		CompareToDialogComponent,
		CompareToComponent,
		CompareToProgressComponent,
		CompareToUnavailableComponent,
		ElementNameComponent,
		TranslateComponent,
		EditTranslationsComponent,
		ProcessTableComponent,
		ProcessListComponent,
		ProcessDocumentComponent,
		NotificationComponent,
		UserGroupsComponent,
		DiagramHtmlPipe,
	],
	providers: [
		{
			provide: "ViewComponents",
			useValue: viewComponents,
		},
		DiagramHtmlPipe,
		PopupComponent,
		DialogAdapter,
	],
	exports: [RatingComponent, EditTranslationsComponent, ImageZoomComponent, ElementNameComponent],
})
export class StagesProcessModule {}
