import {
	Component,
	Directive,
	Injector,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from "@angular/core";

abstract class AbstractMediaCaseDirective {
	constructor(
		protected switcher: MediaSwitchComponent,
		private templateRef: TemplateRef<unknown>,
		private viewContainer: ViewContainerRef,
	) {}

	private hasView?: boolean;

	set componentVisible(value: boolean) {
		if (value && !this.hasView) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.hasView = true;
		} else if (!value && this.hasView) {
			this.viewContainer.clear();
			this.hasView = false;
		}
	}
}

@Directive({
	selector: "[stagesMediaCase]",
})
export class MediaCaseDirective extends AbstractMediaCaseDirective implements OnInit {
	@Input() stagesMediaCase!: string;

	constructor(
		// Use Injector to retrieve MediaSwitchComponent instead of injecting it directly, because otherwise we get the following runtime error for non-aot Angular builds:
		// "Can't resolve all parameters for ..."
		injector: Injector,
		templateRef: TemplateRef<unknown>,
		viewContainer: ViewContainerRef,
	) {
		super(injector.get<MediaSwitchComponent>(MediaSwitchComponent), templateRef, viewContainer);
	}

	ngOnInit(): void {
		this.switcher.addDirectiveForResolution(this);
	}
}

@Directive({
	selector: "[stagesMediaDefault]",
})
export class MediaDefaultDirective extends AbstractMediaCaseDirective implements OnInit {
	constructor(
		// Use Injector to retrieve MediaSwitchComponent instead of injecting it directly, because otherwise we get the following runtime error for non-aot Angular builds:
		// "Can't resolve all parameters for ..."
		injector: Injector,
		templateRef: TemplateRef<unknown>,
		viewContainer: ViewContainerRef,
	) {
		super(injector.get<MediaSwitchComponent>(MediaSwitchComponent), templateRef, viewContainer);
	}

	ngOnInit(): void {
		this.switcher.addDefaultDirective(this);
	}
}

@Component({
	selector: "stages-media-switch",
	template: "<ng-content></ng-content>",
})
export class MediaSwitchComponent implements OnDestroy {
	defaultDirective?: MediaDefaultDirective;

	private directivesForResolutions: {
		query: MediaQueryList;
		directive: MediaCaseDirective;
	}[] = [];

	constructor(private zone: NgZone) {}

	addDirectiveForResolution(directiveForResolution: MediaCaseDirective): void {
		const queryForResolution = window.matchMedia(directiveForResolution.stagesMediaCase);
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		queryForResolution.addListener(this.windowResizeListener);
		this.directivesForResolutions.push({
			query: queryForResolution,
			directive: directiveForResolution,
		});
		this.update();
	}

	addDefaultDirective(directive: MediaDefaultDirective): void {
		this.defaultDirective = directive;
		this.update();
	}

	ngOnDestroy(): void {
		for (const directiveForResolution of this.directivesForResolutions) {
			// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			directiveForResolution.query.removeListener(this.windowResizeListener);
		}
	}

	update(): void {
		let foundMatchingQuery = false;

		for (const directiveForResolution of this.directivesForResolutions) {
			const queryForResolution = directiveForResolution.query;
			const directive = directiveForResolution.directive;
			if (queryForResolution.matches && !foundMatchingQuery) {
				directive.componentVisible = true;
				if (this.defaultDirective) {
					this.defaultDirective.componentVisible = false;
				}
				foundMatchingQuery = true;
			} else {
				directive.componentVisible = false;
			}
		}

		if (!foundMatchingQuery && this.defaultDirective) {
			this.defaultDirective.componentVisible = true;
		}
	}

	windowResizeListener = (): void => {
		this.zone.run(() => {
			this.update();
		});
	};
}
