import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";

type ViewableElement = stages.process.ViewableElement;
type ViewableComment = stages.process.ViewableComment;

@Injectable({ providedIn: "root" })
export class ElementCommentService {
	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	saveComment(element: ViewableElement, comment: ViewableComment, workspaceId: string): void {
		const value = comment.value.trim().replace(/\n{3,}/, "\n\n");
		this.processElementsResource
			.saveComment(workspaceId, element.type.ident, element.id, comment.id, { value: value })
			.then((newComment) => {
				const commentItems = element.comments!.items;
				const i = commentItems.indexOf(comment);
				commentItems[i] = newComment as ViewableComment;
			});
	}

	async deleteComment(element: ViewableElement, comment: ViewableComment, workspaceId: string): Promise<void> {
		return this.processElementsResource
			.deleteComment(workspaceId, element.type.ident, element.id, comment.id)
			.then(() => {
				const commentItems = element.comments!.items;
				const index = commentItems.indexOf(comment);
				commentItems.splice(index, 1);
				element.comments!.totalItemCount--;
			});
	}

	addComment(element: ViewableElement, value: string, workspaceId: string, pv: string): void {
		const newValue = value.trim().replace(/\n{3,}/, "\n\n");
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		this.processElementsResource
			.createComment(workspaceId, element.type.ident, element.id, {
				value: newValue,
			})
			.then((newComment) => {
				element.comments!.items.unshift(newComment as ViewableComment);
				element.comments!.totalItemCount++;
			});
	}

	async loadMoreComments(
		element: ViewableElement,
		workspaceId: string,
		pv: string,
		from: string,
		to: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	): Promise<any> {
		return this.processElementsResource.getCommentsPage(workspaceId, element.type.ident, element.identity, {
			from: Number(from),
			to: Number(to),
			pv: pv,
		});
	}
}
