import { Injectable } from "@angular/core";
import { ProcessResource } from "core/stages-client";
import { IAnsweredQuery } from "process/tailoring/models/answered-query.interface";
import { IRoot } from "process/tailoring/models/root.interface";
import { TailoringWizardStore } from "process/tailoring/store";
import { from, Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TailoringWizardService {
	constructor(private readonly processResource: ProcessResource, private readonly store: TailoringWizardStore) {}

	getTailoringWizardRoot$(workspaceId: string, pv: string): Observable<IRoot> {
		return from(this.processResource.getTailoringWizard(workspaceId, { pv: pv })).pipe(
			tap((root: stages.process.tailoring.wizard.WizardRoot) => {
				this.store.set("root", root);

				if (root.answeredQueries.length > 0) {
					this.store.set("answeredQueries", root.answeredQueries);
				} else {
					this.store.set("answeredQueries", undefined);
				}

				this.store.set("queries", root.queries);
				this.store.set("query", root.queries[0]);
				this.store.set("workspaceId", workspaceId);
				this.store.set("pv", pv);
			}),
		) as unknown as Observable<IRoot>;
	}

	async preview(
		workspaceId: string,
		pv: string,
		answeredQueries: IAnsweredQuery[] | undefined,
	): Promise<stages.process.tailoring.wizard.TailoringWizardPreview> {
		return this.processResource
			.preview$POST$workspace_workspaceId_process_tailoringwizard_preview(
				workspaceId,
				answeredQueries !== undefined ? answeredQueries : [],
				{
					pv: pv,
				},
			)
			.then((preview) => {
				this.store.set("previewInProgress", false);
				return preview;
			});
	}

	async commitTailoring(workspaceId: string, pv: string, answeredQueries: IAnsweredQuery[]): Promise<void> {
		return this.processResource.tailor$POST$workspace_workspaceId_process_tailoringwizard(
			workspaceId,
			answeredQueries,
			{
				pv: pv,
			},
		);
	}
}
