import { Store } from "common/data/store.logic";
import { ViewService } from "core/view.service";
import { UIDependentElement } from "process/dependant-elements/dependent-elements-list.component";
import {
	DependentElement,
	DependentElementsManagementService,
} from "process/dependant-elements/dependent-elements-management.service";
import { lastValueFrom, of } from "rxjs";

export class DependentElementStore implements Store<UIDependentElement> {
	constructor(
		private dependentElements: UIDependentElement[],
		private pageSize: number,
		private workspaceId: string,
		private processVersion: string,
		private parentType: string,
		private parentId: string,
		private dependentSubtype: string,
		private dependentElementsManagementService: DependentElementsManagementService,
		private viewService: ViewService,
	) {}

	async getPage(pageIndex: number): Promise<PagedResult<UIDependentElement>> {
		return lastValueFrom(
			of({
				page: pageIndex,
				pageSize: this.pageSize,
				items: this.dependentElements,
				totalPageCount: 1,
				totalItemCount: this.dependentElements.length,
			}),
		);
	}

	async add(newName: string): Promise<PagedResult<UIDependentElement>> {
		await this.dependentElementsManagementService.addDependentElement(
			this.workspaceId,
			this.parentType,
			this.parentId,
			{
				subtype: this.dependentSubtype,
				name: newName,
			},
		);
		await this.viewService.refreshView(this.workspaceId, this.processVersion);
		return this.getPage(0);
	}

	async rename(dependentElement: DependentElement, name: string): Promise<void> {
		dependentElement.label = name;
		await this.dependentElementsManagementService.renameDependentElement(
			this.workspaceId,
			this.processVersion,
			dependentElement,
		);
		await this.viewService.refreshView(this.workspaceId, this.processVersion);
		await this.getPage(0);
	}

	async delete(itemIds: string[]): Promise<PagedResult<UIDependentElement>> {
		const dependentElementsToDelete: DependentElement[] = [];

		itemIds.forEach((id) => {
			const dependentElement: UIDependentElement = this.dependentElements.find((element) => element.id === id)!;
			dependentElementsToDelete.push(dependentElement);
		});
		await this.dependentElementsManagementService.deleteDependentElements(this.workspaceId, dependentElementsToDelete);
		await this.viewService.refreshView(this.workspaceId, this.processVersion);
		return this.getPage(0);
	}

	async rearrange?(items: UIDependentElement[]): Promise<PagedResult<UIDependentElement>> {
		await this.dependentElementsManagementService.rearrangeDependentElements(
			this.workspaceId,
			this.parentType,
			this.parentId,
			items,
			"CUSTOM",
		);
		this.viewService.notifyModified();
		return this.getPage(0);
	}
}
