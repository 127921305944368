import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { KeyboardService } from "common/keyboard.service";
import { ItemEditor } from "common/list/item-editor";

@Component({
	selector: "stages-add",
	templateUrl: "./add.component.html",
})
export class AddComponent extends ItemEditor<string> implements AfterViewInit {
	@Input() maxLength = 255;

	@Input() pattern?: string;

	@Input() patternMessageKey?: string;

	@Input() focusOnInit: boolean = false;

	existsMessageKey = "add.item.name.existing";
	itemExist = false;

	@ViewChild("input", { read: ElementRef, static: true })
	input!: ElementRef;

	@ViewChild("form", { static: true })
	form!: NgForm;

	name?: string;
	wasNonEmpty = false;
	private mouseDown = false;

	constructor(private keyboardService: KeyboardService) {
		super();
	}

	ngAfterViewInit(): void {
		if (this.focusOnInit) {
			this.focus();
		}
	}

	focus(): void {
		this.input.nativeElement.focus();
	}

	onKeyUp(event: KeyboardEvent): void {
		if (this.keyboardService.isReturnKeyPressed(event)) {
			this.enter();
		} else if (this.keyboardService.isEscapeKeyPressed(event)) {
			this.cancel();
		}
	}

	cancel(): void {
		this.customErrors = {};
		this.name = "";
		this.wasNonEmpty = false;
		this.form.reset();
		this.cancelled.emit();
	}

	onMouseDown(): void {
		this.mouseDown = true;
	}

	blur(): void {
		if (!this.mouseDown) {
			if (this.name && this.name !== "" && this.form.valid) {
				this.submitted.emit(this.name);
				this.name = "";
				this.wasNonEmpty = false;
				this.form.reset();
			} else {
				this.focusLost.emit();
			}
		}
		this.mouseDown = false;
	}

	change(): void {
		super.onChange();

		// Workaround for IE/Angular bug
		// In IE 11, the "input" event erroneously fires as soon as the input element is focused.
		// See also: https://github.com/angular/angular/issues/14440#issuecomment-350812348
		// ...which will make the placeholder text disappear (known bug already in Stages 7.1) and the "Required" validation error appear
		// before the user has changed anything. This here will fix the second problem.
		if (this.name && this.name.length > 0) {
			this.wasNonEmpty = true;
			this.itemExist = false;
		}
	}

	private enter(): void {
		if (this.name && this.form.valid) {
			this.submitted.emit(this.name);
			this.name = "";
			this.wasNonEmpty = false;
			this.form.reset();
		}
	}
}
