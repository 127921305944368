import { Injectable } from "@angular/core";
import { ProcessElementsResource } from "core/stages-client";

type Element = stages.process.ViewableElement;
type ViewableType = stages.process.ViewableType;

@Injectable({ providedIn: "root" })
export class AddService {
	constructor(private readonly processElementsResource: ProcessElementsResource) {}

	async addChild(
		parent: Element,
		subtype: ViewableType | undefined,
		name: string,
		workspaceId: string,
		pv: string,
		dependent: boolean,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	): Promise<any> {
		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource
			.createChild(workspaceId, parent.type.ident, parent.id, {
				subtype: subtype ? subtype.subtypeIdent : undefined,
				name: name,
				dependent: dependent,
			})
			.then((newChild) => {
				parent.children!.push(newChild as stages.process.ProcessTreeItem);
				return newChild;
			});
	}

	async addSequence(
		origin: Element,
		predecessor: Element,
		names: string[],
		associationType: string,
		type: string,
		subtype: string,
		sourceRole: string,
		workspaceId: string,
		pv: string,
	): Promise<void> {
		const sequence = names.map((name) => {
			return {
				type: type,
				subtype: subtype,
				name: name,
				associationType: associationType,
				sourceRole: sourceRole,
			};
		});

		this.processElementsResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.processElementsResource.addSequence(workspaceId, origin.type.ident, origin.id, sequence, {
			predecessorType: predecessor ? predecessor.type.ident : undefined,
			predecessorId: predecessor ? predecessor.id : undefined,
		});
	}
}
