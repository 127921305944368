import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { CmsBrowserService } from "files/browser/cms-browser.service";
type BrowserRepository = stages.management.files.browser.BrowserRepository;

@Injectable({ providedIn: "root" })
export class BrowserRepositoriesResolver implements Resolve<BrowserRepository[]> {
	constructor(private cmsBrowserService: CmsBrowserService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<BrowserRepository[]> {
		return this.cmsBrowserService.getRepositories(
			route.paramMap.get("workspaceId")!,
			route.paramMap.get("processVersion")!,
			route.paramMap.get("elementType")!,
			route.paramMap.get("elementId")!,
		);
	}
}
