import { BehaviorSubject, Subject } from "rxjs";
import { ScreenSize } from "core/screensize";

export interface ApplicationState {
	scrollDisabledChange: Subject<boolean>;
	navigationDrawerOpen: boolean;
	toolbarVisible: boolean;
	workspaceNavigationOpen: boolean;
	secondaryWorkspaceNavigationOpen: boolean;
	documentViewEnabled: boolean;
	isSearchOpen: BehaviorSubject<boolean>;
	isMobileView: BehaviorSubject<boolean>;
	screenSize: BehaviorSubject<ScreenSize>;
	navigationVisible: boolean;
	maskVisible: boolean;
	secondaryWorkspaceEnabled: boolean;
	scrollDisabled: boolean;
	toggleNavigationDrawer(): void;
	closeSearch(): void;
	toggleSearch(): void;
	toggleWorkspaceNavigation(): void;
	toggleSecondaryWorkspaceNavigation(): void;
}

export class VolatileApplicationState implements ApplicationState {
	isMobileView = new BehaviorSubject(false);
	screenSize = new BehaviorSubject(ScreenSize.XXSMALL);
	scrollDisabledChange = new Subject<boolean>();
	isSearchOpen = new BehaviorSubject(false);
	navigationDrawerOpen!: boolean;
	private _workspaceNavigationOpen!: boolean;
	secondaryWorkspaceNavigationOpen!: boolean;
	navigationVisible!: boolean;
	documentViewEnabled!: boolean;
	private _searchOpen!: boolean;
	private _secondaryWorkspaceEnabled!: boolean;
	private _scrollDisabled!: boolean;
	private _maskVisible!: boolean;
	private _toolbarVisible!: boolean;

	get workspaceNavigationOpen(): boolean {
		return this._workspaceNavigationOpen ?? false;
	}

	set workspaceNavigationOpen(value: boolean) {
		this._workspaceNavigationOpen = value;
		if (value) {
			this.secondaryWorkspaceNavigationOpen = false;
		}
	}

	get secondaryWorkspaceEnabled(): boolean {
		return this._secondaryWorkspaceEnabled ?? false;
	}

	set secondaryWorkspaceEnabled(value: boolean) {
		this._secondaryWorkspaceEnabled = value;
		this.secondaryWorkspaceNavigationOpen = false;
	}

	get toolbarVisible(): boolean {
		return this._toolbarVisible ?? false;
	}

	set toolbarVisible(value: boolean) {
		this._toolbarVisible = value;
	}

	get maskVisible(): boolean {
		return this._maskVisible ?? false;
	}

	set maskVisible(value: boolean) {
		this._maskVisible = value;
		this.scrollDisabled = value;
	}

	toggleNavigationDrawer(): void {
		this.navigationDrawerOpen = !this.navigationDrawerOpen;
		this.maskVisible = this.isMobileView.getValue() && this.navigationDrawerOpen;
	}

	closeSearch(): void {
		this._searchOpen = false;
		this.isSearchOpen.next(this._searchOpen);
	}

	toggleSearch(): void {
		this._searchOpen = !this._searchOpen;
		this.isSearchOpen.next(this._searchOpen);
	}

	toggleWorkspaceNavigation(): void {
		this.workspaceNavigationOpen = !this.workspaceNavigationOpen;
	}

	toggleSecondaryWorkspaceNavigation(): void {
		this.secondaryWorkspaceNavigationOpen = !(this.secondaryWorkspaceNavigationOpen && !this.workspaceNavigationOpen);
		this.workspaceNavigationOpen = false;
	}

	get scrollDisabled(): boolean {
		return this._scrollDisabled;
	}

	set scrollDisabled(value: boolean) {
		this._scrollDisabled = value;
		this.scrollDisabledChange.next(value);
	}
}
