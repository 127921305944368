import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MainService } from "core/main.service";
import { ProgressService } from "core/progress.service";
import { StartService } from "process/start/start.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, map, switchMap, takeUntil } from "rxjs/operators";

type ProgressInfo = stages.common.ProgressInfo;

@Component({
	templateUrl: "./start-options.component.html",
})
export class StartOptionsComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	isCreateProcessAllowed?: boolean;
	isAddModuleAllowed?: boolean;
	isImportProcessAllowed?: boolean;
	isCopyProcessAllowed?: boolean;
	latestJob$!: Observable<ProgressInfo>;
	inProgress: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private startService: StartService,
		private mainService: MainService,
		private progressService: ProgressService,
	) {}

	ngOnInit(): void {
		this.latestJob$ = this.route.paramMap.pipe(
			switchMap(async (paramMap) =>
				this.progressService.getLatestJob(
					["ADD_MODULE", "DELETE_PROCESS", "IMPORT_PROCESS", "COPY_PROCESS"],
					paramMap.get("workspaceId")!,
					paramMap.get("processVersion")!,
				),
			),
		);

		combineLatest([this.mainService.workspaceView$, this.latestJob$])
			.pipe(
				distinctUntilChanged(
					(
						x: [stages.workspace.application.WorkspaceView, stages.common.ProgressInfo],
						y: [stages.workspace.application.WorkspaceView, stages.common.ProgressInfo],
					) => {
						// ignore update call if workspace id changed. Destroy will be called soon
						// removing this results in requesting the process from the new workspace in the context of the old workspace
						return x[0].currentWorkspace.id !== y[0].currentWorkspace.id;
					},
				),
				takeUntil(this.destroy$),
			)
			.subscribe(([workspaceView, latestJob]) => {
				if (workspaceView.currentWorkspace.viewedProcess) {
					// user clicked on outdated link to start page AND a process exists (e.g. it has not been marked as deleted)
					// or: empty process is created
					this.router.navigate(
						["../../../..", "_wv", "process", "process", workspaceView.currentWorkspace.viewedProcess.identity],
						{
							relativeTo: this.route,
						},
					);
				} else if (
					latestJob &&
					(latestJob.jobIdentifier!.group === "ADD_MODULE" ||
						latestJob.jobIdentifier!.group === "COPY_PROCESS" ||
						latestJob.jobIdentifier!.group === "IMPORT_PROCESS") &&
					(latestJob.jobStatus === "WAITING" || latestJob.jobStatus === "RUNNING")
				) {
					this.router.navigate(
						[
							"../../../..",
							"_wv",
							"process",
							"start",
							"progress",
							{
								jobName: latestJob.jobIdentifier!.name,
								jobType: latestJob.jobIdentifier!.group,
							},
						],
						{
							relativeTo: this.route,
							replaceUrl: true,
						},
					);
				} else {
					// no process exists, nor is being created
					this.isCreateProcessAllowed = workspaceView.currentWorkspace.actions.CreateProcess;
					this.isAddModuleAllowed = workspaceView.currentWorkspace.actions.AddModule;
					this.isCopyProcessAllowed = workspaceView.currentWorkspace.actions.CopyProcess;
					this.isImportProcessAllowed = workspaceView.currentWorkspace.actions.ImportProcess;
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	onStartEmpty(): void {
		this.inProgress = true;
		this.startService.createEmptyProcess(
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
	}

	onCopy(): void {
		this.inProgress = true;
	}

	onImport(): void {
		this.mainService.openPopup(["install", "import", "upload"], this.route);
	}

	openProgressDetails(latestJob: ProgressInfo): void {
		this.router.navigate(
			[
				"..",
				"progress",
				{
					jobName: latestJob.jobIdentifier!.name,
					jobType: latestJob.jobIdentifier!.group,
				},
			],
			{
				relativeTo: this.route,
				replaceUrl: true,
			},
		);
	}

	hasError(latestJob: ProgressInfo, type: string): boolean {
		return (latestJob && latestJob.jobIdentifier!.group === type && latestJob.errorMessage) as boolean;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	static provideTitle(translateService: TranslateService, mergedRouteData: any): Observable<string> {
		return translateService
			.get("navigation.drawer.home")
			.pipe(map((title) => `${title} - ${mergedRouteData.workspaceView.currentWorkspace.name}`));
	}
}
