import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class BodyHeightService {
	requestedHeights = new Map<string, number>();

	constructor(router: Router) {
		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.requestedHeights.clear();
				this.resetHeight();
			}
		});
	}

	setHeight(menuId: string, valueInPx: number): void {
		this.requestedHeights.set(menuId, valueInPx);
		this.resetHeight();
	}

	unsetHeight(menuId: string): void {
		this.requestedHeights.delete(menuId);
		this.resetHeight();
	}

	private resetHeight(): void {
		if (this.requestedHeights.size > 0) {
			document.body.style.minHeight = `${Math.max(...this.requestedHeights.values())}px`;
		} else {
			document.body.style.removeProperty("min-height");
		}
	}
}
