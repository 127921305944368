import { Component, OnDestroy, Optional } from "@angular/core";
import { CardComponent } from "common/card/card.component";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { PropertyService } from "process/element/properties/property.service";
import { CollectedAssociation, ProcessCollectionComponent } from "process/table/process-collection.component";

@Component({
	templateUrl: "./process-document.component.html",
	styleUrls: ["./process-document.component.scss"],
})
export class ProcessDocumentComponent extends ProcessCollectionComponent implements OnDestroy {
	autoNumber = false;

	constructor(
		viewService: ViewService,
		propertyService: PropertyService,
		mainService: MainService,
		@Optional() cardComponent?: CardComponent,
	) {
		super(viewService, propertyService, mainService);

		if (!cardComponent) {
			setTimeout(() => {
				mainService.applicationState.documentViewEnabled = true;
			});
		}
	}

	getIndex(association: CollectedAssociation): string {
		return association.sequence!.join(".");
	}

	ngOnDestroy(): void {
		this.mainService.applicationState.documentViewEnabled = false;
	}
}
