import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type } from "@angular/core";
import { ConfirmComponent } from "common/dialog/confirm.component";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { DialogComponent, DialogConfig } from "common/dialog/dialog.component";
import { WarnComponent } from "common/dialog/warn.component";
import { WorkspaceFilter, WorkspaceSelectionDialogComponent } from "common/dialog/workspace-selection-dialog.component";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";

export interface SimpleDialogResult {
	value: "cancel" | "ok";
}

@Injectable()
export class DialogService {
	private dialogRegistry = new Map<string, DialogComponent<unknown>>();

	constructor(
		private applicationRef: ApplicationRef,
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector,
	) {}

	async open<T>(
		dialogId: string,
		options: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			component: Type<any>;
			componentArgs: Record<string, unknown>;
			defaultResult: T;
			dialogConfig?: DialogConfig;
		},
	): Promise<T> {
		const dialogAdapter = new DialogAdapter();

		// Angular bug https://github.com/angular/angular/issues/14324 could be the root cause of error "No component factory found for" in the following line (see WP 35719).
		const contentComponentFactory = this.componentFactoryResolver.resolveComponentFactory(options.component);
		const contentComponentInjector = Injector.create({
			providers: [{ provide: DialogAdapter, useValue: dialogAdapter }],
			parent: this.injector,
			name: "contentComponentInjector",
		});
		const contentComponentRef = contentComponentFactory.create(contentComponentInjector);
		if (options.componentArgs) {
			Object.keys(options.componentArgs).forEach((arg: string) => {
				contentComponentRef.instance[arg] = options.componentArgs[arg];
			});
		}

		this.applicationRef.attachView(contentComponentRef.hostView);

		const dialogFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
		const dialogRef = dialogFactory.create(contentComponentInjector, [[contentComponentRef.location.nativeElement]]);

		this.applicationRef.attachView(dialogRef.hostView);
		document.body.appendChild(dialogRef.location.nativeElement);

		this.dialogRegistry.set(dialogId, dialogRef.instance);
		const dialogPromise = (dialogRef.instance as DialogComponent<T>).show(
			dialogRef as ComponentRef<DialogComponent<T>>,
			contentComponentRef,
			options.defaultResult,
			options.dialogConfig || { size: "small", autoHeight: false },
		);
		void dialogPromise.then(() => {
			this.close(dialogId);
		});
		return dialogPromise;
	}

	close(dialogId: string): void {
		const dialog = this.dialogRegistry.get(dialogId);
		if (dialog) {
			this.dialogRegistry.delete(dialogId);
			dialog.close();
		}
	}

	async confirm(
		messageKey: string,
		translateValues?: Record<string, number | string>,
		okKey: string = "delete",
		cancelKey: string = "cancel",
		isDeleteButton: boolean = false,
		detailsKey?: string,
		size: "large" | "small" | "medium" = "small"
	): Promise<boolean> {
		return (await this.confirmImpl(messageKey, okKey, cancelKey, isDeleteButton, translateValues, detailsKey, size)) === "ok";
	}

	async warn(message: string, okKey: string = "ok"): Promise<boolean> {
		return (await this.warnImpl(message, okKey)) === "ok";
	}

	private async confirmImpl(
		messageKey: string,
		okKey: string,
		cancelKey: string,
		isDeleteButton: boolean,
		translateValues?: unknown,
		detailsKey?: string,
		size: "large" | "small" | "medium" = "small",
	): Promise<string> {
		return this.open<string>("confirm", {
			component: ConfirmComponent,
			componentArgs: {
				keys: {
					message: messageKey,
					ok: okKey,
					cancel: cancelKey,
					isDeleteButton: isDeleteButton,
					values: translateValues ? translateValues : {},
					details: detailsKey,
				},
			},
			defaultResult: "cancel",
			dialogConfig: {
				size: size,
				autoHeight: false,
			},
		});
	}

	private async warnImpl(message: string, okKey: string): Promise<string> {
		return this.open<string>("warn", {
			component: WarnComponent,
			componentArgs: {
				keys: {
					message: message,
					ok: okKey,
				},
			},
			defaultResult: "cancel",
			dialogConfig: {
				size: "small",
				autoHeight: false,
			},
		});
	}

	selectWorkspace(
		okFn: (workspace: WorkspaceSelected) => void,
		cancelFn: () => void,
		workspaceFilter?: WorkspaceFilter,
	): void {
		void this.open<{
			value: string;
			workspace?: WorkspaceSelected;
		}>("dialog,service.workspace.selection", {
			component: WorkspaceSelectionDialogComponent,
			componentArgs: {
				filter: workspaceFilter || {},
			},
			defaultResult: {
				value: "cancel",
			},
			dialogConfig: {
				size: "large",
				autoHeight: false,
			},
		}).then((result) => {
			if (result.value === "ok") {
				okFn(result.workspace!);
			} else if (cancelFn) {
				cancelFn();
			}
		});
	}
}
