import { animate, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ListService } from "common/list/list.service";
import { MultiLevelSelectionService } from "common/multi-level-selection.service";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { MainService } from "core/main.service";
import { ActionStateWrapper, BaseNavigationComponent } from "navigation/list/base/base-navigation.component";
import { NavigationConfiguration } from "navigation/list/navigation-configuration.interface";
import { ListNavigationEntry } from "navigation/list/navigation-entry.interface";
import { NavigationService } from "navigation/list/navigation.service";
import { Observable } from "rxjs";

interface FunctionToName {
	name: string;
}

@Component({
	selector: "stages-clipboard",
	templateUrl: "./clipboard.component.html",
	animations: [
		trigger("slideInOut", [
			transition(":enter", [
				style({ visibility: 0, transform: "translateY(100%)" }),
				animate("0.6s 0.1s cubic-bezier(0.4, 0, 0.2, 1)", style({ visibility: 1, transform: "translateY(0%)" })),
			]),
			transition(":leave", [
				style({ visibility: 1, transform: "translateY(0%)" }),
				animate("0.6s 0.1s cubic-bezier(0.4, 0, 0.2, 1)", style({ visibility: 0, transform: "translateY(100%)" })),
			]),
		]),
	],
})
export class ClipboardComponent extends BaseNavigationComponent {
	workspaceNavigationOpen = false;
	navigationConfiguration$: Observable<NavigationConfiguration>;

	currentActions?: ActionStateWrapper[];

	constructor(
		route: ActivatedRoute,
		router: Router,
		private navigationService: NavigationService,
		selectionService: MultiLevelSelectionService<ListNavigationEntry>,
		private mainService: MainService,
		private listService: ListService,
	) {
		super(route, router, selectionService);
		this.navigationConfiguration$ = this.navigationService.navigationConfiguration$;
	}

	isClipboardOpen(navigationConfiguration: NavigationConfiguration): boolean {
		if (navigationConfiguration.activatedRoute) {
			const params = navigationConfiguration.activatedRoute.snapshot.paramMap;
			const action = params.get("action");
			return action !== null && action.indexOf("paste") !== -1;
		}
		return false;
	}

	isUpEnabled(navigationConfiguration: NavigationConfiguration): boolean {
		return navigationConfiguration.parentRoute !== undefined && !this.workspaceNavigationOpen;
	}

	isWorkspaceNavEnabled(navigationConfiguration: NavigationConfiguration): boolean {
		if (navigationConfiguration.activatedRoute) {
			const component = navigationConfiguration.activatedRoute.snapshot.component;
			if (component !== null && (component as FunctionToName).name === "ReportsComponent") {
				return false;
			}
		}
		return true;
	}

	toggleWorkspaceNavigation(): void {
		this.workspaceNavigationOpen = !this.workspaceNavigationOpen;
	}

	getElementCount(navigationConfiguration: NavigationConfiguration): number {
		const selection = this.getSelection(navigationConfiguration, true);
		if (!selection) {
			return 0;
		}
		return selection.getSelectedCount();
	}

	async onSelectWorkspace(
		workspace: WorkspaceSelected,
		navigationConfiguration: NavigationConfiguration,
	): Promise<void> {
		this.workspaceNavigationOpen = false;

		const paramMap = navigationConfiguration.activatedRoute!.snapshot.paramMap;

		this.inProgress = true;
		try {
			await this.router.navigate([
				"workspace",
				workspace.id,
				paramMap.get("processVersion"),
				...navigationConfiguration.clipboardConfiguration.routeAfterWorkspaceChange,
				this.reuseNavigationParams(paramMap),
			]);
		} finally {
			this.inProgress = false;
		}
	}

	isNavigationDrawerOpen(): boolean {
		return this.mainService.applicationState.navigationDrawerOpen;
	}

	getCurrentActions(navigationConfiguration: NavigationConfiguration): ActionStateWrapper[] {
		const actionName = this.getActionParam(navigationConfiguration);
		const result: Array<ActionStateWrapper> = [];
		if (navigationConfiguration.clipboardConfiguration) {
			const currentMode = this.getCurrentMode(
				navigationConfiguration.clipboardConfiguration.supportedModes,
				actionName,
			);
			if (currentMode.uiActions.length > 0) {
				const selection = this.getSelection(navigationConfiguration, true);
				for (const a of currentMode.uiActions) {
					result.push(
						new ActionStateWrapper(
							a,
							this,
							selection,
							navigationConfiguration.activatedRoute!,
							navigationConfiguration.baseRoute!,
							() => navigationConfiguration.invalidate(),
						),
					);
				}
			}
		}
		return result;
	}

	getVisibleNavigationEntries(completeList: ListNavigationEntry[]): ListNavigationEntry[] {
		return this.listService.getVisibleListItems(completeList) as ListNavigationEntry[];
	}

	hasOmmittedEntries(completeList: ListNavigationEntry[]): boolean {
		return this.listService.hasOmmittedItems(completeList);
	}
}
