import { Injectable } from "@angular/core";

export class DoubleSubmitError extends Error {
	constructor(name: string) {
		super(`Ignored double submit for '${name}'`);

		// Set the prototype explicitly when extending built-in types.
		// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, DoubleSubmitError.prototype);
	}
}

@Injectable({ providedIn: "root" })
export class MutexService {
	private readonly scope: StringToBoolean = {};

	invoke(name: string, fn: () => Promise<unknown>): void {
		void this.invokeWithResult(name, fn);
	}

	// Non-Fire-And-Forget version of "invoke", which returns the inner promise as result.
	// Normally, the simpler "invoke" can be used and OnResolved/OnRejected callbacks can be added to the inner promise (if any).
	async invokeWithResult<T>(name: string, fn: () => Promise<T>): Promise<T> {
		if (this.scope[name]) {
			throw new DoubleSubmitError(name);
		}

		this.scope[name] = true;

		try {
			return await fn();
		} finally {
			this.scope[name] = false;
		}
	}
}
