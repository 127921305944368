import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BreadCrumbTemplate } from "common/breadcrumb/breadcrumb.component";
import { ZoomToolbarService } from "common/zoom/zoom-toolbar/zoom-toolbar.service";
import { BackButton } from "common/breadcrumb/backbutton.component";

export interface HeadlineTranslation {
	translate?: string;
	params?: unknown;
	plainText?: string;
}

@Component({
	selector: "stages-page-header",
	templateUrl: "./page-header.component.html",
	providers: [ZoomToolbarService],
})
export class PageHeaderComponent {
	@Input()
	headline?: HeadlineTranslation;

	// Please either choose breadCrumb or backButton. Not both.
	@Input()
	breadCrumbTemplate?: BreadCrumbTemplate;

	@Input()
	backButtonWithRouterLink?: BackButton;

	@Input()
	backButtonDisabled: boolean = false;

	@Input()
	isEmbeddedInCard: boolean = false;

	@Input()
	showProcessVersionSwitch: boolean = false;

	@Output() readonly backButtonClicked = new EventEmitter<boolean>();

	isActive(): boolean {
		return (
			this.headline !== undefined ||
			this.breadCrumbTemplate !== undefined ||
			this.backButtonWithRouterLink !== undefined ||
			this.showProcessVersionSwitch
		);
	}

	showBreadCrumb(): boolean {
		return (
			this.breadCrumbTemplate !== undefined && (this.backButtonWithRouterLink === undefined || this.backButtonDisabled)
		);
	}

	showBackButton(): boolean {
		return (
			this.breadCrumbTemplate === undefined && (this.backButtonWithRouterLink !== undefined || !this.backButtonDisabled)
		);
	}
}
