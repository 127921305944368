import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "stages-button-expand-collapse",
	templateUrl: "./button-expand-collapse.component.html",
})
export class ButtonExpandCollapseComponent {
	@Input()
	isCollapsed: boolean = false;

	@Input()
	classes?: string;

	@Input()
	accessibilityTitle?: string;

	@Output()
	readonly pressedButton = new EventEmitter<string>();

	emitPressedButton(): void {
		this.pressedButton.emit();
	}
}
