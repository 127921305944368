import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewRef,
} from "@angular/core";
import { ZoomToolbarIconClasses } from "common/zoom/zoom-toolbar/zoom-toolbar-iconclasses.interface";
import { Step, ZoomOutBehavior, ZoomToolbarService } from "common/zoom/zoom-toolbar/zoom-toolbar.service";
import { Subscription } from "rxjs";

@Component({
	selector: "stages-zoom-toolbar",
	templateUrl: "./zoom-toolbar.component.html",
	styleUrls: ["./zoom-toolbar.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomToolbarComponent implements OnInit, OnDestroy {
	// this is a pattern to make the enum available in the template
	get Step(): typeof Step {
		return Step;
	}

	@Input()
	zoomToolbarIconClasses: ZoomToolbarIconClasses = {
		header: "ico ico-zoom",
		oneStepDown: "ico ico-minus",
		oneStepUp: "ico ico-plus",
		fitToPage: "ico ico-fit-to-page",
	};

	zoomValue?: number;
	bottomBoundaryReached: boolean = false;
	topBoundaryReached: boolean = false;

	private zoomOutBehavior: ZoomOutBehavior = ZoomOutBehavior.useFitToPageSizeForMaxZoomOut;
	private subscriptions?: Subscription;

	constructor(
		private readonly zoomToolbarService: ZoomToolbarService,
		private readonly changeDetector: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.subscriptions = this.zoomToolbarService.zoomValue.subscribe({
			next: (value) => {
				this.zoomValue = value;
				this.deactivateStepDownAndFitToPageButtonIfNecessary();
				this.deactivateStepUpButtonIfNecessary();
				if (!(this.changeDetector as ViewRef).destroyed) {
					this.changeDetector.detectChanges();
				}
			},
		});
		this.subscriptions.add(
			this.zoomToolbarService.zoomOutBehavior.subscribe({
				next: (value) => {
					this.zoomOutBehavior = value;
					this.deactivateStepDownAndFitToPageButtonIfNecessary();
					if (!(this.changeDetector as ViewRef).destroyed) {
						this.changeDetector.detectChanges();
					}
				},
			}),
		);
	}

	private deactivateStepUpButtonIfNecessary(): void {
		if (this.zoomValue !== undefined) {
			this.topBoundaryReached = this.zoomValue >= this.zoomToolbarService.ZOOM_MAX_ZOOM_IN_VALUE;
		}
	}

	private deactivateStepDownAndFitToPageButtonIfNecessary(): void {
		if (this.zoomValue !== undefined) {
			this.bottomBoundaryReached =
				this.zoomOutBehavior === ZoomOutBehavior.useFitToPageSizeForMaxZoomOut
					? this.zoomValue <= this.zoomToolbarService.ZOOM_MAX_ZOOM_OUT_VALUE
					: this.zoomValue <= this.zoomToolbarService.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE;
		}
	}

	fitToPageButtonClicked(): void {
		this.zoomToolbarService.setZoomValueToLowestPossibleValue();
	}

	stepButtonClicked(step: Step): void {
		this.zoomToolbarService.performStep(step);
	}

	ngOnDestroy(): void {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}
}
