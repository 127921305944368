import { StringEscapeUtilsBase } from "common/editor/shared/string-escape-utils";

export class AnchorProcessor {
	static getAnchors(htmlText: string): string[] {
		const anchors: string[] = [];

		const regExp = /<a\s+([^>]+\s)?name="([^"]+)".*?>/gi;
		let match = regExp.exec(htmlText);
		while (match !== null) {
			anchors.push(StringEscapeUtilsBase.unescapeHtml(match[2])!);
			match = regExp.exec(htmlText);
		}
		return anchors;
	}
}
