import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ViewportScroller } from "@angular/common";
import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Event, NavigationEnd, Router, Scroll } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { ScrollService } from "common/routing/scroll.service";
import { MainService } from "core/main.service";
import { of, Subject } from "rxjs";
import { filter, map, mergeMap, takeUntil } from "rxjs/operators";
import { ScreenSize } from "core/screensize";

@Component({
	selector: "stages-app",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	constructor(
		private renderer: Renderer2,
		private mainService: MainService,
		private translateService: TranslateService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private breakpointObserver: BreakpointObserver,
		private viewportScroller: ViewportScroller,
		private scrollService: ScrollService,
	) {}

	ngOnInit(): void {
		this.setUpPageTitleUpdate();

		this.router.events
			.pipe(filter((e: Event): e is Scroll => e instanceof Scroll))
			.pipe(takeUntil(this.destroy$))
			.subscribe((e) => {
				if (e.position) {
					// backward navigation
					this.viewportScroller.scrollToPosition(e.position);
				} else if (e.anchor) {
					// anchor navigation
					this.viewportScroller.scrollToAnchor(e.anchor);
				} else {
					// forward navigation
					if (this.scrollService.scrollPosition === "top") {
						this.viewportScroller.scrollToPosition([0, 0]);
					}
				}
				this.scrollService.scrollPosition = "top";
			});

		this.mainService.applicationState.scrollDisabledChange.pipe(takeUntil(this.destroy$)).subscribe((disabled) => {
			if (disabled) {
				this.renderer.addClass(document.documentElement, "disable-scroll");
				this.renderer.addClass(document.body, "disable-scroll");
			} else {
				this.renderer.removeClass(document.documentElement, "disable-scroll");
				this.renderer.removeClass(document.body, "disable-scroll");
			}
		});

		if (this.translateService.currentLang) {
			this.renderer.setAttribute(
				document.getElementById("html-index-element"),
				"lang",
				this.translateService.currentLang,
			);
			this.renderer.setAttribute(document.body, "lang", this.translateService.currentLang);
		}
		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			this.renderer.setAttribute(
				document.getElementById("html-index-element"),
				"lang",
				this.translateService.currentLang,
			);
			this.renderer.setAttribute(document.body, "lang", event.lang);
		});

		this.breakpointObserver
			.observe([
				ScreenSize.XXSMALL,
				ScreenSize.XSMALL,
				ScreenSize.SMALL,
				ScreenSize.MEDIUM,
				ScreenSize.LARGE,
				ScreenSize.XLARGE,
			])
			.pipe(takeUntil(this.destroy$))
			.subscribe((breakpoint: BreakpointState) => {
				if (breakpoint.matches) {
					for (const bp in breakpoint.breakpoints) {
						if (breakpoint.breakpoints[bp]) {
							if (this.isMobileView(bp)) {
								this.mainService.applicationState.isMobileView.next(true);
							} else {
								this.mainService.applicationState.isMobileView.next(false);
							}
							this.mainService.applicationState.screenSize.next(bp as ScreenSize);
						}
					}
				} else {
					// On reload in mobile view the breakpoint observer somehow does not
					// recognize the screen size. With this else case we provide the "mobile view" as
					// a default, until the observer gets the correct size
					this.mainService.applicationState.isMobileView.next(true);
					this.mainService.applicationState.screenSize.next(ScreenSize.XXSMALL);
				}
			});
	}

	isMobileView(breakpoint: string): boolean {
		return breakpoint !== ScreenSize.MEDIUM && breakpoint !== ScreenSize.LARGE && breakpoint !== ScreenSize.XLARGE;
	}

	setUpPageTitleUpdate(): void {
		this.router.events
			.pipe(
				// See https://toddmotto.com/dynamic-page-titles-angular-2-router-events
				filter((event) => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map((route) => {
					let childRoute = route;
					while (childRoute.firstChild) {
						childRoute = childRoute.firstChild;
					}
					return childRoute;
				}),
				filter((route) => route.outlet === "primary"),
				mergeMap((route) => route.data),
				mergeMap((data) => {
					if (data.title) {
						return of(data.title);
					}

					if (data.titleKey) {
						return this.translateService.get(data.titleKey);
					}

					return of("Method Park Stages");
				}),
				takeUntil(this.destroy$),
			)
			.subscribe((title) => {
				this.titleService.setTitle(title);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
