import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { EntryPageResource } from "core/stages-client";

type EntryPageTab = stages.entrypage.EntryPageTab;

@Injectable({ providedIn: "root" })
export class EntryPageTabsResolver implements Resolve<EntryPageTab[]> {
	constructor(private entryPageResource: EntryPageResource) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<EntryPageTab[]> {
		return this.entryPageResource.getTabs({
			workspaceId: route.paramMap.get("workspaceId")!,
			pv: route.paramMap.get("processVersion")!,
		});
	}
}
