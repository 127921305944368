import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { FileService, UrlTemplate } from "files/files.service";

@Injectable({ providedIn: "root" })
export class UrlTemplateResolver implements Resolve<UrlTemplate> {
	constructor(private fileService: FileService) {}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
	async resolve(): Promise<UrlTemplate> {
		return this.fileService.getNewUrlTemplate();
	}
}
