import { forwardRef, Provider, TrackByFunction, Type } from "@angular/core";
import { As, Assert } from "core/assert";

export function isDefined<T>(value: T): value is NonNullable<T> {
	return value !== undefined && value !== null;
}

export function hasMethod<K extends string>(
	unknownValue: unknown,
	methodName: K,
	// eslint-disable-next-line @typescript-eslint/ban-types -- Use of "Function" as a type is ok here, because the exact signature cannot be retrieved at runtime
): unknownValue is Record<K, Function> {
	if (!hasProperty(unknownValue, methodName)) return false;

	return typeof unknownValue[methodName] === "function";
}

export function hasProperty<K extends string>(
	unknownValue: unknown,
	propertyName: K,
): unknownValue is Record<K, unknown> {
	if (typeof unknownValue !== "object" && typeof unknownValue !== "function") return false;

	if (!unknownValue) return false;

	return propertyName in unknownValue;
}

export function emptyOrUndefinedToNull(s?: string | null): string | null {
	switch (s) {
		case undefined:
		case "":
			return null;
		default:
			return s;
	}
}

export function nullToUndefined<T>(val: T | null): T | undefined {
	if (val === null) {
		return undefined;
	}
	return val;
}

export function assertNever(x: never): never {
	Assert.never(x);
}

export function assertDefined<T>(value?: T | null, message?: string): T {
	return As.defined(value, message);
}

/**
 * Helper function to allow simple but powerful interaction between a 'smart component' and its 'presentation components'.
 * All interaction is decoupled via class-interfaces and dependency injection:
 * https://angular.io/guide/dependency-injection-in-action#class-interface
 *
 * This pattern is a more object-oriented and simpler way (but as powerful) than the HTML- or Decorator-based recipes
 * described in the component interaction cookbook (https://angular.io/guide/component-interaction)
 *
 * @param smartComponentClass - The component class containing all the application logic and which wires together all dumb presentation components.
 * @param classInterfaceOfSmartComponentClass - One of the abstract base classes of the component class.
 *        This base class declares only those properties and methods of the smart component class, which are used by one of its child presentation components.
 *        Only this base class must be injected in the constructor of the child presentation component.
 *        This base class must be implemented (not extended!) by the smart component class.
 * @returns The provider which must be added to the 'providers' property of the @Component decorator of the smart component class.
 */
export function provideAs(smartComponentClass: Type<object>, classInterfaceOfSmartComponentClass: object): Provider {
	// eslint-disable-next-line @angular-eslint/no-forward-ref -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	return { provide: classInterfaceOfSmartComponentClass, useExisting: forwardRef(() => smartComponentClass) };
}

export const trackByIndexFunction: TrackByFunction<unknown> = (index) => {
	return index;
};

export function getStateMessageKey(stateIdent: string): string {
	return `process.element.state.${toCamelCase(stateIdent)}`;
}

export function toCamelCase(sentence: string): string {
	let result = "";
	sentence.split(" ").forEach((word, i) => {
		result += (i === 0 ? word[0].toLowerCase() : word[0].toUpperCase()) + word.slice(1);
	});
	return result;
}
