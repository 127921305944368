import { Component, Input, OnInit } from "@angular/core";
import { Entry } from "common/index/index.component";
import { ViewService } from "core/view.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { IndexType } from "process/view/type.interface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type ViewableElement = stages.process.ViewableElement;
type ProcessTreeItem = stages.process.ProcessTreeItem;

@Component({
	selector: "stages-index",
	templateUrl: "./index.component.html",
})
export class IndexComponent extends BaseComponent implements OnInit {
	cssClass!: string;

	childrenEntries$!: Observable<Entry[]>;

	@Input()
	hideShortname: boolean = false;

	constructor(componentService: ComponentService, private viewService: ViewService) {
		super(componentService);
	}

	getClasses(): string[] {
		return [this.cssClass];
	}

	ngOnInit(): void {
		const indexType = this.type as IndexType;
		this.cssClass = indexType.cssClass;

		this.childrenEntries$ = this.viewService
			.awaitSelfElementObservable()
			.pipe(map((self) => this.asEntries(self.children)));
	}

	private asEntries(children?: ProcessTreeItem[]): Entry[] {
		if (children === undefined) {
			return [];
		}
		return children.map((child) => {
			const entry: Entry = {
				label: this.hideShortname ? child.name : child.label,
				getLabelClasses(): string[] {
					return child.tailored ? ["tailored"] : [];
				},
				getCardClasses(): string[] {
					return [child.type.ident + "-card"];
				},
				getIconClasses: () => {
					if ((child as ViewableElement).index) {
						return ["ico", "ico-et-" + child.type.ident.toLowerCase()];
					}

					return this.viewService.getIconClasses(child.type, (child as ViewableElement).index, false, "ico");
				},
				getRouterLink(): unknown[] {
					return ["../..", child.type.ident.toLowerCase(), child.identity];
				},
			};

			if (child.changeMarker) {
				entry.showWarning = child.changeMarker.subelements > 0;
				entry.badges = [];
				if (child.changeMarker.added) {
					entry.badges.push({
						styleClass: "added-badge",
						translate: "process.compare.added",
					});
				}
				if (child.changeMarker.deleted) {
					entry.linkDisabled = true;
					entry.badges.push({
						styleClass: "deleted-badge",
						translate: "process.compare.deleted",
					});
				}
				if (child.changeMarker.changed) {
					entry.badges.push({
						styleClass: "changed-badge",
						translate: "process.compare.changed",
					});
				}
			}

			return entry;
		});
	}
}
