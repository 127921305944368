<article class="no-process">
	<div class="wrapper">
		<div class="text">
			<i class="ico ico-process"></i>
			<div class="description">
				<p translate="process.process.text"></p>
			</div>
		</div>
	</div>
</article>
