import { Component } from "@angular/core";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { PropertyService } from "process/element/properties/property.service";
import { ProcessCollectionComponent } from "process/table/process-collection.component";

@Component({
	templateUrl: "./process-table.component.html",
	styleUrls: ["./process-table.component.scss"],
})
export class ProcessTableComponent extends ProcessCollectionComponent {
	columns: unknown;

	constructor(viewService: ViewService, propertyService: PropertyService, mainService: MainService) {
		super(viewService, propertyService, mainService);
	}
}
