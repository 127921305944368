import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { IRoot } from "process/tailoring/models/root.interface";
import { TailoringWizardService } from "process/tailoring/tailoring-wizard.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TailoringWizardResolver implements Resolve<IRoot> {
	constructor(private tailoringWizardService: TailoringWizardService) {}
	resolve(route: ActivatedRouteSnapshot): Observable<IRoot> {
		return this.tailoringWizardService.getTailoringWizardRoot$(route.params.workspaceId, route.params.processVersion);
	}
}
