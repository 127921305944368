<form class="form operation-summary">
	<article class="content">
		<div class="grouped-list">
			<div class="group">
				<header class="group-header" *ngIf="warnings.length > 0">
					<div (click)="toggleWarningList()">
						<h3>
							<span translate="progress.summary.warnings"></span><span class="counter">{{ warnings.length }}</span>
						</h3>
						<stages-button-expand-collapse
							[isCollapsed]="!warningsExpanded"
							[classes]="'action'"
						></stages-button-expand-collapse>
					</div>
				</header>
				<div *ngIf="warningsExpanded">
					<ul class="list">
						<li class="list-item" *ngFor="let warn of warnings">
							<i class="ico ico-warning warning-color"></i> {{ warn }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</article>
	<footer>
		<div class="input-container right">
			<button *ngIf="!download" class="button lg" (click)="close.emit()"><span translate="continue"></span></button>
			<a *ngIf="download" [href]="download" class="button lg" role="button" (click)="close.emit()"
				><span translate="download"></span
			></a>
		</div>
	</footer>
</form>
