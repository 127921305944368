<stages-auto-complete
	#autoComplete
	[placeholder]="
		this.dependentElementContainer?.firstDependentSubtypeName
			? ('process.element.quickAssign.select.dependentElementPlaceholder' | translate)
			: ('search.autoComplete.placeholder' | translate)
	"
	[query]="getSearchQuery()"
	(submitted)="selectItemCallback($event)"
	[searchResultTemplate]="searchResultTemplate"
	[searchResultSeparatorTemplate]="searchResultSeparatorTemplate"
	[dropDownClass]="'margin-reduced-h'"
	[inputIconClasses]="getInputIconClasses()"
	[maxLength]="255"
	(cancelled)="cancel()"
	(backButtonPressed)="back()"
	[subordinatedElementsMode]="getIsSubordinatedElementsMode()"
>
	<ng-template #searchResultTemplate let-item>
		<i *ngIf="!item.isNew && !item.dependent" class="ico" [ngClass]="getIconClasses(item)"></i>
		<i *ngIf="!item.isNew && item.dependent" class="ico ico-tag" [ngClass]="getIconClasses(item)"></i>
		<i *ngIf="item.isNew && !item.dependent" class="ico add" [ngClass]="getIconClasses(item)"></i>
		<i *ngIf="item.isNew && item.dependent" class="ico add ico-add"></i>
		<ng-container *ngIf="!item.isNew">
			<p *ngIf="!item.isNew" [ngClass]="getLabelClasses(item)" class="has-chevron-right">
				{{ item.label }}<i class="ico ico-change-workspace" *ngIf="item.isRemote"></i>
				<i
					class="ico ico-chevron-right ico-right"
					*ngIf="
						item.dependentElementsContainer &&
						(restrictAssociateDependentElementTypes === undefined || restrictAssociateDependentElementTypes.length > 0)
					"
				></i>
			</p>
			<p class="workspace-name" *ngIf="item.isRemote">{{ item.workspaceName }}</p>
		</ng-container>
		<p
			*ngIf="item.isNew && !item.isComment"
			[ngClass]="getLabelClasses(item)"
			[translate]="'search.autoComplete.create'"
			[translateParams]="{ ARG0: item.name }"
		></p>
		<p
			*ngIf="item.isNew && item.isComment"
			[ngClass]="getLabelClasses(item)"
			[translate]="'search.autoComplete.createCommentOnlyAssociation'"
			[translateParams]="{ ARG0: item.name }"
		></p>
	</ng-template>

	<ng-template #searchResultSeparatorTemplate let-item>
		<li class="separator" *ngIf="item.current.isNew && !item.next.isNew && !item.next.isRemote">
			<h6 translate="search.autoComplete.assocTarget.inThisWorkspace"></h6>
		</li>
		<li
			class="separator"
			*ngIf="!item.current.isRemote && item.next.isRemote && remoteWorkspaceSeparatorInAutocomplete"
		>
			<h6 translate="search.autoComplete.assocTarget.inOtherWorkspaces"></h6>
		</li>
	</ng-template>
</stages-auto-complete>
