import { Directive, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { BreadcrumbItem } from "common/breadcrumb/breadcrumb-item";
import { SlotService } from "common/slot.service";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { BreadcrumbService } from "process/breadcrumb.service";
import { MenuFactoryService } from "process/menu-factory.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { ComponentType } from "process/view/type.interface";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

type View = stages.process.View;
type Perspective = stages.process.Perspective;
type ProcessView = stages.process.ProcessView;

@Directive()
export class WidgetContainerComponent extends BaseComponent implements OnInit, OnDestroy {
	self!: ProcessView;
	perspectives!: Perspective[];
	perspective!: string;
	menuItems!: MenuItem[];
	breadcrumb!: BreadcrumbItem[];
	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		componentService: ComponentService,
		private route: ActivatedRoute,
		private mainService: MainService,
		private viewService: ViewService,
		private breadcrumbService: BreadcrumbService,
		private menuFactoryService: MenuFactoryService,
		slotService?: SlotService,
	) {
		super(componentService, slotService);
	}

	ngOnInit(): void {
		this.initializeFromView(this.route.snapshot.data.view, this.route.snapshot.paramMap);
	}

	initializeFromView(view: View, paramMap: ParamMap): void {
		this.perspectives = view.perspectives;

		combineLatest([this.viewService.awaitViewObservable(), this.route.paramMap])
			.pipe(takeUntil(this.destroy$))
			// eslint-disable-next-line @typescript-eslint/no-shadow -- TODO: Remove this comment and fix warnings!
			.subscribe(async ([view, paramMap]) => {
				this.self = this.viewService.getSelf(view.processView);
				this.perspective = await this.viewService.getPerspective();
				const menuItems = this.menuFactoryService.newMenuItems(
					this.self,
					this.perspectives,
					view.viewWorkspace,
					paramMap,
					this.route,
				);
				this.menuItems = menuItems;

				this.mainService.menuItems$.next(menuItems);

				this.breadcrumb = this.breadcrumbService.newBreadcrumbItems(this.self, false, false);

				if (this.self.changeMarker && this.self.changeMarker.perspectives) {
					this.perspectives.forEach((perspective) => {
						perspective.changeMarker = this.self.changeMarker!.perspectives!.includes(perspective.ident);
					});
				}
			});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.mainService.menuItems$.next([]);
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	onTypeChange(type: ComponentType): void {
		return;
	}

	openPerspective(ident: string): void {
		this.viewService.forceViewReload(
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("type")!,
			this.route.snapshot.paramMap.get("identity")!,
			ident,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
	}

	isPerspectiveActive(ident: string): boolean {
		return this.perspective === ident;
	}
}
