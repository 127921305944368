<ng-container *ngIf="versions$ | async as versions">
	<stages-dialog-header>
		<a *ngIf="!workspaceSelectionMode" class="title clickable" (click)="toggleWorkspaceSelection()">
			<i class="ico ico-workspace-interface"></i>
			<h3 class="shorten">{{ workspaceSelected.getValue().name }}</h3>
		</a>
		<a class="title clickable" *ngIf="workspaceSelectionMode" (click)="toggleWorkspaceSelection()">
			<i class="ico ico-arrow-back"></i>
			<h3 class="shorten" translate="process.compare.selection"></h3>
		</a>
	</stages-dialog-header>

	<div *ngIf="!workspaceSelectionMode" class="content">
		<h1 translate="process.compare"></h1>
		<form [formGroup]="formGroup">
			<ul class="list" *ngIf="versions.length > 0">
				<li class="list-item" *ngFor="let version of versions" (click)="selectVersion(version.versionIdentifier)">
					<div class="avatar-cell-48">
						<div class="radio">
							<input type="radio" name="version" formControlName="version" [value]="version.versionIdentifier" />
							<label for="version"></label>
						</div>
					</div>
					<div class="dot-cell center">
						<span [ngClass]="getDotClasses($any(version))"></span>
					</div>
					<div class="text center">
						<h4>{{ version.displayVersionName }}</h4>
						<p *ngIf="!version.workingVersion">{{ version.creationDateFormatted }}</p>
						<p *ngIf="!version.workingVersion && !!$any(version).state">{{ $any(version).state.name }}</p>
					</div>
				</li>
			</ul>
		</form>
		<ul class="list" *ngIf="versions.length === 0">
			<li class="list-item none">
				<div class="label">
					<p translate="process.compare.none"></p>
				</div>
			</li>
		</ul>
	</div>
	<stages-workspace-selection
		*ngIf="workspaceSelectionMode"
		(selectWorkspace)="onSelectWorkspace($event)"
		filterBy="MODULE_INSTALLED_OR_USED_BY"
		[filterParam1]="currentWorkspaceId"
	></stages-workspace-selection>

	<footer *ngIf="!workspaceSelectionMode">
		<div class="buttons">
			<button type="button" class="button sm cancel" translate="cancel" (click)="close()" tabindex="3"></button>
			<button
				type="submit"
				class="button sm ok"
				translate="compare"
				(click)="compare($any(versions))"
				tabindex="4"
				[disabled]="!formGroup.valid"
			></button>
		</div>
	</footer>
</ng-container>
