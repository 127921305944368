import { Location } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DataRoutePipe } from "common/data-route.pipe";
import { MainService } from "core/main.service";

@Pipe({ name: "descriptionHtml" })
export class DescriptionHtmlPipe extends DataRoutePipe implements PipeTransform {
	constructor(
		private mainService: MainService,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		sanitized: DomSanitizer,
	) {
		super(sanitized);
	}

	renderHref(dataRoute: string): string {
		const decodedDataRoute = JSON.parse(decodeURIComponent(this.decodeXMLSpecialCharacters(dataRoute)));
		const relativeTo =
			decodedDataRoute.commands.length === 1 && decodedDataRoute.commands[0] === "./"
				? this.route
				: this.mainService.findPopupContainer(this.route);
		const urlTree = this.router.createUrlTree(decodedDataRoute.commands, {
			relativeTo: relativeTo,
		});
		const url = [];
		url.push(
			` href="${this.encodeXMLSpecialCharacters(this.location.prepareExternalUrl(this.router.serializeUrl(urlTree)))}`,
		);
		if (decodedDataRoute.fragment) {
			url.push(`#${encodeURIComponent(unescapeHtml(decodedDataRoute.fragment))}`);
		}
		url.push('" ');
		return url.join("");
	}
}

function unescapeHtml(value: string): string {
	const doc = new DOMParser().parseFromString(value, "text/html");
	return doc.documentElement.textContent!;
}
