import { Injectable } from "@angular/core";
import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class StagesMissingTranslationHandler extends MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams): string {
		return StagesMissingTranslationHandler.getMissingTransaltionResult(params.key);
	}

	static getMissingTransaltionResult(key: string): string {
		return "???" + key + "???";
	}
}
