import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { AsyncValidatorFn, FormControl, FormGroup } from "@angular/forms";
import { CheckboxInput } from "common/form/input/types/checkbox-input";
import { DateInput } from "common/form/input/types/date-input";
import { DateTimeInput } from "common/form/input/types/date-time-input";
import { IdsInput } from "common/form/input/types/ids-input";
import { InputBase } from "common/form/input/types/input-base";
import { JsonInput } from "common/form/input/types/json-input";
import { NumberInput } from "common/form/input/types/number-input";
import { RichTextInput, RichTextInputContext } from "common/form/input/types/rich-text-input";
import { SelectionInput, SelectionOption } from "common/form/input/types/selection-input";
import { TextInput } from "common/form/input/types/text-input";
import { TextAreaInput } from "common/form/input/types/textarea-input";
import { UtilService } from "common/util.service";
import { hasProperty } from "core/functions";

type Attribute = stages.core.Attribute;
type AttributeType = stages.core.AttributeType;

@Component({
	templateUrl: "./edit-attribute.component.html",
	selector: "stages-attribute-edit",
})
export class EditAttributeComponent implements OnInit, OnChanges {
	@Input() form!: FormGroup;

	@Input() attributes?: Attribute[];
	@Input() attributeTypes?: AttributeType[];
	@Input() richtextInputContext?: RichTextInputContext;
	@Input() additionalAsyncValidators?: Map<string, AsyncValidatorFn>;

	constructor(private utilService: UtilService) {}

	inputs: InputBase<Date | string[] | boolean | number | string>[] = [];

	ngOnInit(): void {
		this.init();
	}

	// Attention: Do not call init() on every ngOnChange (every click triggers one), but only in case of changes to this component
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.form || changes.attributeTypes) {
			this.init();
		}
	}

	init(): void {
		this.inputs.splice(0);

		if (this.attributeTypes && this.attributes) {
			const attributesMap = new Map(
				this.attributes.map((attribute): [string, Attribute] => [attribute.typeIdent, attribute]),
			);
			this.attributeTypes.forEach((attributeType) => {
				const attribute = attributesMap.get(attributeType.typeIdent);
				if (attribute) {
					this.createInputs(attributeType, attribute);
				}
			});
		}
	}

	createInputs(attributeType: AttributeType, attribute: Attribute): void {
		if (!attributeType || !attributeType.typeType) {
			//TODO Filter attributes in backend or generate attribute types for every attribute
			return;
		}

		let input;
		switch (attributeType.typeType) {
			case "STRING":
				input = new TextInput({
					key: attribute.typeIdent,
					value: getInitialValueString(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					maxLength: attributeType.maxSize,
					readonly: attributeType.readonly,
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "TEXT":
				input = new TextAreaInput({
					key: attribute.typeIdent,
					value: getInitialValueString(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					maxLength: attributeType.maxSize,
					readonly: attributeType.readonly,
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "JSON":
				input = new JsonInput({
					key: attribute.typeIdent,
					value: getInitialValueString(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "CHANGE_REQUEST":
				input = new IdsInput({
					key: attribute.typeIdent,
					value: getInitialValueString(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
				});
				break;
			case "NUMBER":
			case "INTEGER":
				input = new NumberInput({
					key: attribute.typeIdent,
					value: getInitialValueNumber(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "BOOLEAN":
				input = new CheckboxInput({
					key: attribute.typeIdent,
					value: getInitialValueBoolean(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "SELECTION":
				const options: SelectionOption[] = new Array();
				if ((attributeType.options && attributeType.options.length > 0) || attributeType.required) {
					for (const typeOption of attributeType.options) {
						const option = new SelectionOption({
							value: typeOption.ident,
							translateKey: toMessageKey(typeOption.messageKeyOrName),
							untranslatableName: toUntranslatableString(typeOption.messageKeyOrName),
						});
						options.push(option);
					}
					input = new SelectionInput({
						key: attribute.typeIdent,
						value: getInitialValueArray(attribute, attributeType),
						translateKey: toMessageKey(attributeType.messageKeyOrName),
						untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
						required: attributeType.required,
						selectionOptions: options,
						multiple: attributeType.multipleValues,
						alphabetical: attributeType.alphabetical,
						readonly: attributeType.readonly,
					});
					input.helpMessage = attributeType.helpMessage;
				}
				break;
			case "DATE":
				input = new DateInput({
					key: attribute.typeIdent,
					value: getInitialValueString(attribute, attributeType),
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
					textInputOnly: this.utilService.browserIsIE(),
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "DATETIME":
				input = new DateTimeInput({
					key: attribute.typeIdent,
					value: attribute.value,
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
				});
				input.helpMessage = attributeType.helpMessage;
				break;
			case "RICH_TEXT":
				input = new RichTextInput({
					key: attribute.typeIdent,
					value: attribute.displayValue,
					translateKey: toMessageKey(attributeType.messageKeyOrName),
					untranslatableName: toUntranslatableString(attributeType.messageKeyOrName),
					required: attributeType.required,
					readonly: attributeType.readonly,
					beanId: this.richtextInputContext!.beanId,
					beanIdentity: this.richtextInputContext!.beanIdentity,
					beanType: this.richtextInputContext!.beanType,
					processTypeIdent: this.richtextInputContext!.processTypeIdent,
					workspaceId: this.richtextInputContext!.workspaceId,
					pv: this.richtextInputContext!.pv,
					pluginRegistry: this.richtextInputContext!.pluginRegistry,
				});
				input.properties = this.richtextInputContext!.properties;
				input.helpMessage = attributeType.helpMessage;
				break;
			default:
				throw new Error(`Unknown attribute type ${attributeType.typeType}`);
		}

		if (input) {
			this.inputs.push(input);

			const formControl = new FormControl(input.value, input.validators);

			const additionalAsyncValidator = this.additionalAsyncValidators?.get(attribute.typeIdent);
			if (additionalAsyncValidator) {
				formControl.setAsyncValidators(additionalAsyncValidator);
			}

			this.form.addControl(input.key, formControl);
		}
	}
}

function getInitialValueArray(attribute: Attribute, attributeType: AttributeType): string[] {
	if (attribute.value) {
		return attribute.value;
	}

	if (attributeType.defaultValue) {
		return attributeType.defaultValue;
	}

	return [];
}

function getInitialValueString(attribute: Attribute, _attributeType: AttributeType): string | null {
	if (attribute.value) {
		return attribute.value;
	}
	return "";
}

function getInitialValueNumber(attribute: Attribute, attributeType: AttributeType): number | null {
	if (attribute.value !== null) {
		return attribute.value;
	}

	if (attributeType.defaultValue) {
		return attributeType.defaultValue;
	}

	return null;
}

function getInitialValueBoolean(attribute: Attribute, attributeType: AttributeType): boolean | null {
	if (attribute.value) {
		return attribute.value;
	}

	if (attributeType.defaultValue) {
		return attributeType.defaultValue;
	}

	return false;
}

function toMessageKey(messageKeyOrString: unknown): string | null {
	return hasProperty(messageKeyOrString, "keys") ? (messageKeyOrString.keys as string[])[0] : null;
}

function toUntranslatableString(messageKeyOrString: unknown): string | null {
	return !hasProperty(messageKeyOrString, "keys") ? (messageKeyOrString as string) : null;
}
