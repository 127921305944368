import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AssociationBrowserDialogComponent } from "common/associations/association-browser-dialog.component";
import { AssociationBrowserComponent } from "common/associations/association-browser.component";
import { AssociationWorkspaceBrowserComponent } from "common/associations/association-workspace-browser.component";
import { CombinedAssociationDetailViewComponent } from "common/associations/combined-association-detail-view/combined-association-detail-view.component";
import { ModelBrowseViewResolver } from "common/associations/model-browse-view-resolver.service";
import { ModuleResolver } from "common/associations/module-resolver.service";
import { RelatedWorkspacesResolver } from "common/associations/related-workspaces-resolver.service";
import { ErrorDialogComponent } from "common/exception/error-dialog.component";
import { TitleResolver } from "common/title-resolver.service";
import { ModelAssociationStoreResolver } from "process/associations/model-association-store-resolver.service";
import { CompareToDialogComponent } from "process/compare/compare-to-dialog.component";
import { CompareToProgressComponent } from "process/compare/compare-to-progress.component";
import { CompareToComponent } from "process/compare/compare-to.component";
import { ComplianceAssociationStoreResolver } from "process/compliance/associations/compliance-association-store-resolver.service";
import { ProcessComplianceAssociationStoreResolver } from "process/compliance/associations/process-compliance-association-store-resolver.service";
import { DescriptionsReadonlyResolver } from "process/description/descriptions/descriptions-readonly-resolver.service";
import { DescriptionsReadonlyComponent } from "process/description/descriptions/descriptions-readonly.component";
import { EditComponent } from "process/edit/edit.component";
import { AddSequenceDialogComponent } from "process/element/add/add-sequence-dialog.component";
import { SequenceResolver } from "process/element/add/sequence-resolver.service";
import { EditElementComponent } from "process/element/edit/edit-element.component";
import { EnactmentAssociationStoreResolver } from "process/enactment/association/enactment-association-store-resolver.service";
import { EnactmentBrowseViewResolver } from "process/enactment/association/enactment-browse-view-resolver.service";
import { FreezeComponent } from "process/freeze/freeze.component";
import { UpdateConsiderFrozenComponent } from "process/freeze/update-consider-frozen.component";
import { MSOfficeGuard } from "process/msoffice-guard.service";
import { ProcessNavigationComponent } from "process/navigation/process-navigation.component";
import { NoProcessComponent } from "process/noprocess.component";
import { ProcessReleaseDetailsComponent } from "process/process-release-details/process-release-details.component";
import { ProcessComponent } from "process/process.component";
import { RedirectToIndexIdentityComponent } from "process/redirect-to-index-identity.component";
import { RedirectComponent } from "process/redirect.component";
import { StartOptionsComponent } from "process/start/start-options.component";
import { StartProgressComponent } from "process/start/start-progress.component";
import { StartSummaryComponent } from "process/start/start-summary.component";
import { StartComponent } from "process/start/start.component";
import { TailoringWizardPreviewComponent } from "process/tailoring/components/preview/preview.component";
import { TailoringWizardPreviewResolver } from "process/tailoring/components/preview/preview.resolver";
import { TailoringWizardQueryNameComponent } from "process/tailoring/components/query-name/query-name.component";
import { TailoringWizardQueryNameResolver } from "process/tailoring/components/query-name/query-name.resolver";
import { TailoringWizardComponent } from "process/tailoring/containers/tailoring-wizard/tailoring-wizard.component";
import { TailoringWizardResolver } from "process/tailoring/containers/tailoring-wizard/tailoring-wizard.resolver";
import { RouteableTailoringComponent } from "process/tailoring/manual/routeable-tailoring.component";
import { TranslateComponent } from "process/translate/translate.component";
import { ViewResolver } from "process/view-resolver.service";
import { ProcessViewComponent } from "process/view/process-view.component";
import { CompareToUnavailableComponent } from "process/compare/compare-to-unavailable.component";
import { Mode } from "common/data/data-view.component";

export const processRoutes: Routes = [
	{
		path: "",
		component: ProcessComponent,
		children: [
			{
				path: "",
				pathMatch: "full",
				component: RedirectComponent,
			},
			{
				path: "mantailor",
				component: RouteableTailoringComponent,
			},
			{
				path: "compare",
				component: CompareToDialogComponent,
				children: [
					{
						path: "to",
						component: CompareToComponent,
					},
					{
						path: "progress",
						component: CompareToProgressComponent,
					},
					{
						path: "unavailable",
						component: CompareToUnavailableComponent,
					},
				],
			},
			{
				path: "browse/:type/:identity/:browseWorkspaceId/:browseProcessVersion/:browseType/:browseIdentity",
				resolve: {
					related: RelatedWorkspacesResolver,
				},
				data: {
					onlyModules: false,
				},
				children: [
					{
						path: "",
						component: AssociationBrowserDialogComponent,
						resolve: {
							browseView: ModelBrowseViewResolver,
							associationStore: ModelAssociationStoreResolver,
						},
						children: [
							{
								path: "elements",
								component: AssociationBrowserComponent,
								data: { commentsAllowed: true },
							},
							{
								path: "workspaces",
								component: AssociationWorkspaceBrowserComponent,
							},
						],
					},
				],
			},
			{
				path: "enactmentbrowse/:type/:identity/:browseWorkspaceId/:browseProcessVersion/:browseType/:browseIdentity",
				resolve: {
					related: ModuleResolver,
				},
				data: {
					onlyModules: true,
				},
				children: [
					{
						path: "",
						component: AssociationBrowserDialogComponent,
						resolve: {
							browseView: EnactmentBrowseViewResolver,
							associationStore: EnactmentAssociationStoreResolver,
						},
						children: [
							{
								path: "elements",
								component: AssociationBrowserComponent,
							},
							{
								path: "workspaces",
								component: AssociationWorkspaceBrowserComponent,
								data: {
									showFavorites: false,
									showNavigate: false,
									showSearch: false,
								},
							},
						],
					},
				],
			},
			{
				path: "compliance/:type/:identity/:browseWorkspaceId/:browseProcessVersion/:browseType/:browseIdentity",
				component: AssociationBrowserDialogComponent,
				data: {
					allowIndex: true,
				},
				resolve: {
					browseView: ModelBrowseViewResolver,
					associationStore: ComplianceAssociationStoreResolver,
				},
				children: [
					{
						path: "elements",
						component: AssociationBrowserComponent,
					},
				],
			},
			{
				path: "processcompliance/:type/:identity/:browseWorkspaceId/:browseProcessVersion/:browseType/:browseIdentity",
				component: AssociationBrowserDialogComponent,
				data: {
					filterByScopes: true,
					allowIndex: true,
				},
				resolve: {
					browseView: ModelBrowseViewResolver,
					associationStore: ProcessComplianceAssociationStoreResolver,
				},
				children: [
					{
						path: "elements",
						component: AssociationBrowserComponent,
					},
				],
			},
			{
				path: "combined/:type/:identity/detailView/:targetElementIdentity/:path",
				component: CombinedAssociationDetailViewComponent,
				data: {
					mode: Mode.VIEW,
				},
				resolve: {
					associationStore: ModelAssociationStoreResolver,
				},
			},
			{
				path: "removefromcombined/:type/:identity/detailView/:targetElementIdentity/:path",
				component: CombinedAssociationDetailViewComponent,
				data: {
					mode: Mode.DELETE,
				},
				resolve: {
					associationStore: ModelAssociationStoreResolver,
				},
			},
			{
				path: "sequence/:type/:identity",
				component: AddSequenceDialogComponent,
				resolve: {
					sequence: SequenceResolver,
				},
			},
			{
				path: "tailoringwizard",
				component: TailoringWizardComponent,
				resolve: {
					root: TailoringWizardResolver,
				},
				children: [
					{
						path: "query/:queryId",
						component: TailoringWizardQueryNameComponent,
						resolve: {
							query: TailoringWizardQueryNameResolver,
						},
					},
					{
						path: "preview",
						component: TailoringWizardPreviewComponent,
						resolve: {
							preview: TailoringWizardPreviewResolver,
						},
					},
				],
			},
			{
				path: "start",
				component: StartComponent,
				children: [
					{
						path: "options",
						component: StartOptionsComponent,
						resolve: {
							title: TitleResolver,
						},
					},
					{
						path: "progress",
						component: StartProgressComponent,
					},
					{
						path: "summary",
						component: StartSummaryComponent,
					},
					{
						path: "install",
						loadChildren: () => import("install/install.module").then((m) => m.StagesInstallModule),
					},
				],
			},
			{
				path: "noprocess",
				component: ProcessNavigationComponent,
				children: [
					{
						path: "",
						pathMatch: "full",
						component: NoProcessComponent,
						//resolve: { title: TitleResolver }
					},
				],
			},
			{
				path: "process/:identity/edit",
				component: ProcessNavigationComponent,
				resolve: {
					view: ViewResolver,
				},
				children: [
					{
						path: "",
						component: EditComponent,
						resolve: {
							title: TitleResolver,
						},
					},
				],
			},
			{
				path: "process/:identity/translate",
				component: ProcessNavigationComponent,
				resolve: {
					view: ViewResolver,
				},
				children: [
					{
						path: "",
						pathMatch: "full",
						component: TranslateComponent,
						resolve: {
							title: TitleResolver,
						},
					},
					{
						path: ":depidentity",
						component: TranslateComponent,
						resolve: {
							title: TitleResolver,
						},
					},
				],
			},
			{
				path: "description/:identity/targetWorkspaceId/:twid",
				component: DescriptionsReadonlyComponent,
				resolve: {
					description: DescriptionsReadonlyResolver,
				},
			},
			{
				path: "freeze/:type/:id",
				component: FreezeComponent,
			},
			{
				path: "updateConsiderFrozen/:freezeWorkspaceId",
				component: UpdateConsiderFrozenComponent,
			},
			{
				path: ":type/index",
				component: RedirectToIndexIdentityComponent,
				resolve: {
					view: ViewResolver,
				},
			},
			{
				path: ":type/:identity",
				component: ProcessNavigationComponent,
				resolve: {
					view: ViewResolver,
				},
				canActivate: [MSOfficeGuard],
				children: [
					{
						path: "",
						pathMatch: "full",
						component: ProcessViewComponent,
						resolve: { title: TitleResolver },
					},
					{
						path: "edit",
						component: EditElementComponent,
						resolve: {
							title: TitleResolver,
						},
					},
					{
						path: "translate",
						children: [
							{
								path: "",
								pathMatch: "full",
								component: TranslateComponent,
								resolve: {
									title: TitleResolver,
								},
							},
							{
								path: ":depidentity",
								component: TranslateComponent,
								resolve: {
									view: ViewResolver,
									title: TitleResolver,
								},
							},
						],
					},
					{
						path: "errorDialog",
						component: ErrorDialogComponent,
					},
					{
						path: "processId/:processId/releasedetails",
						component: ProcessReleaseDetailsComponent,
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(processRoutes)],
})
export class ProcessRoutingModule {}
