import { Component, EventEmitter, Input, Output } from "@angular/core";
import { range } from "lodash";

// only use odd numbers for MAX_SHOWN_PAGES to prevent variation of X_SHOWN_PAGES calculation
const MAX_SHOWN_PAGES = 5;
const LEFT_SHOWN_PAGES = Math.floor(MAX_SHOWN_PAGES / 2);
const RIGHT_SHOW_PAGES = Math.floor(MAX_SHOWN_PAGES / 2);

function calculatePages(currentPage: number, lastPage: number): number[] {
	let start: number;
	let end: number;
	if (currentPage - LEFT_SHOWN_PAGES < 1) {
		start = 1;
		end = Math.min(lastPage, MAX_SHOWN_PAGES);
	} else if (currentPage + RIGHT_SHOW_PAGES > lastPage) {
		start = Math.max(1, lastPage - (MAX_SHOWN_PAGES - 1));
		end = lastPage;
	} else {
		start = currentPage - LEFT_SHOWN_PAGES;
		end = currentPage + RIGHT_SHOW_PAGES;
	}
	return range(start, end + 1);
}

@Component({
	selector: "stages-pager",
	templateUrl: "./pager.html",
})
export class PagerComponent {
	@Input()
	total: number = 0;

	@Input()
	page: number = 1;

	@Input()
	pageSize: number = 10;

	@Output() readonly goTo = new EventEmitter<number>();

	totalPages(): number {
		return Math.ceil(this.total / this.pageSize);
	}

	pagesRange(): number[] {
		return calculatePages(this.page, this.totalPages());
	}

	prevPage(): number {
		return Math.max(1, this.page - 1);
	}

	nextPage(): number {
		return Math.min(this.totalPages(), this.page + 1);
	}

	pageClicked(page: number): void {
		this.goTo.emit(page);
	}
}
