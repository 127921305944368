<div
	class="auto-complete"
	[ngClass]="{
		'has-icon': !!inputIconClasses,
		'browse-button-active': browseButtonActive,
		dense: isDense,
		subordinated: _subordinatedElementsMode
	}"
>
	<label class="relative input">
		<i *ngIf="inputIconClasses" [ngClass]="inputIconClasses"></i>
		<input
			#autoCompleteInput
			autoComplete="off"
			[(ngModel)]="term"
			id="term"
			name="term"
			type="search"
			class="search"
			[maxlength]="maxLength"
			[attr.placeholder]="placeholder"
			(blur)="onBlur($event)"
			stagesAutofocus
		/>
	</label>
	<ng-container *ngIf="browseButtonActive">
		<button
			type="button"
			[attr.title]="'navigation.workspace.select' | translate"
			[attr.aria-label]="'navigation.workspace.select' | translate"
			id="open-browser"
			class="open-browser"
			(click)="openBrowser()"
		>
			<i class="ico ico-workspace"></i>
		</button>
		<button
			type="button"
			[attr.title]="'close' | translate"
			[attr.aria-label]="'close' | translate"
			class="search-close clickable"
			(click)="closeSearch()"
		>
			<i class="ico ico-close"></i>
		</button>
	</ng-container>
	<div *ngIf="dropDownOpen" class="dropdown" [ngClass]="dropDownClass!">
		<div class="dropdown-wrapper">
			<div class="dropdown-inner">
				<div
					*ngIf="!subordinatedResults; else subordinatedResultList"
					class="dropdown-item"
					[@slide]="{ value: '', params: params }"
				>
					<ul class="dropdown-list" [@slide]="{ value: '', params: params }">
						<ng-container *ngTemplateOutlet="resultList; context: { $implicit: results }"></ng-container>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #subordinatedResultList>
	<div class="dropdown-item" [@slide]="{ value: '', params: params }">
		<ul class="dropdown-list">
			<li [ngClass]="{ selected: isSelected(BACK_BUTTON_INDEX) }">
				<a class="clickable" title="{{ 'back' | translate }}" (click)="back()">
					<i class="ico ico-chevron-left"></i>
					<div class="label subordinated" translate="back"></div>
				</a>
			</li>
			<ng-container *ngTemplateOutlet="resultList; context: { $implicit: subordinatedResults }"></ng-container>
		</ul>
	</div>
</ng-template>

<ng-template #resultList let-resultsToUse>
	<ng-container *ngFor="let result of resultsToUse; index as index">
		<li [ngClass]="{ selected: isSelected(index) }">
			<a class="clickable" (click)="onSelect(result)" (mousedown)="onMouseDown()" *ngIf="!$any(result).isSeparator">
				<ng-container *ngTemplateOutlet="searchResultTemplate!; context: { $implicit: result }"></ng-container>
			</a>
		</li>
		<ng-container *ngIf="searchResultSeparatorTemplate && index < resultsToUse.length - 1">
			<ng-container
				*ngTemplateOutlet="
					searchResultSeparatorTemplate;
					context: { $implicit: { current: resultsToUse[index], next: resultsToUse[index + 1] } }
				"
			></ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngTemplateOutlet="noResultOrSearchEngineUnavailable"></ng-container>
</ng-template>

<ng-template #noResultOrSearchEngineUnavailable>
	<li *ngIf="hasNoResults() && searchEngineAvailable" class="no-results">
		<p translate="search.noresults"></p>
	</li>
	<li *ngIf="!searchEngineAvailable" class="no-results">
		<p translate="search.notavailable"></p>
	</li>
</ng-template>
