<ng-container *ngIf="folder; else wait">
	<div class="carousel-wrapper" stagesClickStopPropagation>
		<div class="carousel-inner">
			<div class="carousel-item" *ngFor="let item of [folder]" [@slide]="{ value: '', params: params }">
				<div class="list">
					<div class="list-item parent" [ngClass]="{ active: item.id === selected }">
						<div class="nav-button parent" *ngIf="item.parent">
							<button
								[attr.title]="'up' | translate"
								[attr.aria-label]="'up' | translate"
								(click)="onUp($any(item.parent))"
								class="action"
							>
								<i class="ico ico-chevron-left-small"></i>
							</button>
						</div>

						<a class="label clickable" (click)="selectWorkspace(item)" *ngIf="item.selectable">
							<div class="text">
								<h6>{{ item.path }}<ng-container *ngIf="item.parent">&nbsp;&gt;</ng-container></h6>
								<h3>{{ item.name }}</h3>
							</div>
						</a>
						<div class="label" *ngIf="!item.selectable">
							<div class="text">
								<h6>{{ item.path }}<ng-container *ngIf="item.parent">&nbsp;&gt;</ng-container></h6>
								<h3>{{ item.name }}</h3>
							</div>
						</div>
					</div>
					<ng-container *ngIf="filterChildren(item.children) as workspaces">
						<div
							class="list-item child"
							*ngFor="let workspace of workspaces; trackBy: getId"
							[ngClass]="{ active: selected === workspace.id, 'split-hover': workspace.hasChildren }"
						>
							<div class="workspace-link">
								<a class="label clickable" (click)="selectWorkspace(workspace)" *ngIf="workspace.selectable">
									<div class="text">
										<h3 [innerHTML]="workspace.name | highlightMatches: filterTerm"></h3>
									</div>
								</a>
								<div class="label" *ngIf="!workspace.selectable">
									<div class="text">
										<h3 [innerHTML]="workspace.name | highlightMatches: filterTerm"></h3>
									</div>
								</div>
							</div>
							<div class="nav-button child" *ngIf="workspace.hasChildren">
								<button
									[attr.title]="'navigation.workspace.navigatetochild' | translate"
									[attr.aria-label]="'navigation.workspace.navigatetochild' | translate"
									(click)="onDown(workspace)"
								>
									<div class="right action">
										<i class="ico ico-chevron-right-small"></i>
									</div>
								</button>
							</div>
						</div>
						<div class="list-item empty" *ngIf="workspaces.length === 0">
							<div class="label">
								<p translate="navigation.workspace.filter.nothing_found"></p>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-template #wait>
	<stages-spin class="spin-without-button" size="sm"></stages-spin>
</ng-template>
