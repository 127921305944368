import { CommonArgs, InputBase } from "common/form/input/types/input-base";

export class DateTimeInput extends InputBase<string> {
	readonly initialHoursOffset?: number;
	constructor(args: DateTimeInputArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "date_time",
			translateKey: args.translateKey,
			placeholderKey: args.placeholderKey,
		});
		this.initialHoursOffset = args.initialHoursOffset;
	}
}

export class DateTimeInputArgs extends CommonArgs<string> {
	placeholderKey?: string | null;
	initialHoursOffset?: number;
}
