/* eslint-disable @typescript-eslint/no-explicit-any  -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".*/
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

export interface Headers {
	Authorization?: string;
	"Stages-Polling"?: string;
	Accept?: string;
}

export type HttpResponseType = "json" | "text";

/**
 * @deprecated If caching is not needed, use generated classes in "stages-client.ts" (e.g. "WorkspaceResource") or use Angular "HttpClient". Otherwise, use "// eslint-disable-next-line deprecation/deprecation".
 */
@Injectable({ providedIn: "root" })
export class HttpService {
	private readonly requestCache: Map<string, Promise<any>>;

	constructor(private readonly httpClient: HttpClient) {
		this.requestCache = new Map();
	}

	async get(url: string, params?: any, headers?: Headers, responseType?: HttpResponseType): Promise<any> {
		return this.executeRequest(
			{
				headers: headers,
				method: "GET",
				params: params,
				url: url,
			},
			responseType,
		);
	}

	async getCached(url: string): Promise<any> {
		const cachedRequest = this.requestCache.get(url);
		if (cachedRequest) {
			return cachedRequest;
		}
		const promise = this.get(url);
		this.requestCache.set(url, promise);
		return promise.catch(async (response) => {
			this.requestCache.delete(url);
			return Promise.reject(response);
		});
	}

	invalidate(url: string): void {
		this.requestCache.delete(url);
	}

	invalidateAll(): void {
		this.requestCache.clear();
	}

	async put(url: string, json?: any, headers?: Headers, responseType?: HttpResponseType): Promise<any> {
		return this.executeRequest(
			{
				data: json,
				headers: headers,
				method: "PUT",
				url: url,
			},
			responseType,
		);
	}

	async post(url: string, json?: any, headers?: Headers, responseType?: HttpResponseType): Promise<any> {
		return this.executeRequest(
			{
				data: json,
				headers: headers,
				method: "POST",
				url: url,
			},
			responseType,
		);
	}

	async del(url: string, json?: any, headers?: Headers, responseType?: HttpResponseType): Promise<any> {
		// Various fake HttpErrorResponses for testing:
		// throw newHttpErrorResponse(url, -1, "ErrorEvent", new ErrorEvent("type"));
		// throw newHttpErrorResponse(url, 0, "Server ist nicht mehr da");
		// throw newHttpErrorResponse(url, 503, "Service Unavailable", { statusDetail: "startup", message: "PkitConfig.xml heißt jetzt config.xml. Sonst ändert sich nix!" });
		// throw newHttpErrorResponse(url, 507, "Insufficient Storage");
		// throw newHttpErrorResponse(url, 404, "Not Found");
		// throw "fake error of type 'string'"; // Caution: It as not a good style to throw errors of type string, because there will be no stacktrace in global error handler.
		// throw new Error("fake error of type 'Error'");

		return this.executeRequest(
			{
				data: json,
				headers: headers,
				method: "DELETE",
				url: url,
			},
			responseType,
		);
	}

	private async executeRequest(req: any, responseType?: HttpResponseType): Promise<any> {
		return lastValueFrom(
			this.httpClient.request(req.method, req.url, {
				body: req.data,
				headers: new HttpHeaders(req.headers),
				params: req.params,
				responseType: responseType,
			}),
		);
	}
}

export function newHttpErrorResponse(url: string, status: number, statusText: string, error?: any): HttpErrorResponse {
	return new HttpErrorResponse({
		error: error,
		headers: new HttpHeaders(),
		status: status,
		statusText: statusText,
		url: url,
	});
}
