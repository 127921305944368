import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

export abstract class DataRoutePipe {
	constructor(private sanitized: DomSanitizer) {}

	transform(value: string): SafeHtml {
		const linkified = value.replace(/\sdata-route=\"([^\"]*)\"/g, (_match: string, capture: string) =>
			this.renderHref(capture),
		);
		return this.sanitized.bypassSecurityTrustHtml(linkified);
	}

	decodeXMLSpecialCharacters(dataRoute: string): string {
		return dataRoute
			.replace(/&gt;/g, ">")
			.replace(/&lt;/g, "<")
			.replace(/&quot;/g, '"')
			.replace(/&apos;/g, "'")
			.replace(/&amp;/g, "&"); //order is important as replacing  &amp; might produce e.g. &qout;
	}

	encodeXMLSpecialCharacters(dataRoute: string): string {
		return dataRoute
			.replace(/&/g, "&amp;") //order is important
			.replace(/>/g, "&gt;")
			.replace(/</g, "&lt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&apos;");
	}

	abstract renderHref(dataRoute: string): string;
}
