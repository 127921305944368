import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ManagementNavigationService } from "management/management-navigation.service";
import { combineLatest, Subject } from "rxjs";
import { BreadCrumbTemplate } from "common/breadcrumb/breadcrumb.component";
import { HeadlineTranslation } from "common/page/page-header.component";
import { TranslateService } from "@ngx-translate/core";
import { DropdownEntry } from "management/process-interfaces/process-interfaces-filter.component";
import { BackButton } from "common/breadcrumb/backbutton.component";
import { ProcessInterfacesService } from "management/process-interfaces/process-interfaces.service";
import { MainService } from "core/main.service";
import { ProcessInterfacesFilterService } from "management/process-interfaces/process-interfaces-filter.service";
import { ScreenSize } from "core/screensize";

export enum ProcessInterfacesParams {
	WORKSPACEID = "workspaceId",
	TARGETWORKSPACEID = "targetWorkspaceId",
	PVFORINTERFACES = "pvForInterfaces",
	PV = "processVersion",
	SHOWONLYCHANGES = "showOnlyChanges",
	WORKINGVERSION = "_wv",
	VALIDVERSION = "_vv",
	MERGE = "merge",
	TYPE = "type",
}

@Component({
	templateUrl: "./process-interfaces.component.html",
	styleUrls: ["./process-interfaces.component.scss"],
})
export class ProcessInterfacesComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	_specificProcessVersion?: string;
	_currentWorkspacePage: number = 1;
	_showChanges: boolean = false;

	breadCrumb?: BreadCrumbTemplate;
	backButton?: BackButton;
	showProcessVersionSelector: boolean;
	processInterfacesWorkspacesListPageHeader?: HeadlineTranslation;

	detailsWorkspaceId?: string;
	isFilterToolbarVisible: boolean = false;
	dropdownContentArray?: DropdownEntry[];

	isFullSize: boolean = false;
	showFilterActive: boolean = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private managementNavigationService: ManagementNavigationService,
		private translateService: TranslateService,
		private processInterfacesService: ProcessInterfacesService,
		private changeDetector: ChangeDetectorRef,
		private mainService: MainService,
		private processInterfacesFilterService: ProcessInterfacesFilterService,
	) {
		this.showProcessVersionSelector = this.route.snapshot.data.showProcessVersionSelector ?? false;
	}

	ngOnInit(): void {
		combineLatest([this.route.paramMap, this.route.queryParamMap])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([urlParamMap, urlQueryParamMap]) => {
				this._specificProcessVersion = urlQueryParamMap.has(ProcessInterfacesParams.PVFORINTERFACES)
					? urlQueryParamMap.get(ProcessInterfacesParams.PVFORINTERFACES)!
					: urlParamMap.get(ProcessInterfacesParams.PV)!;
				this.managementNavigationService.updateNavigation(urlParamMap.get(ProcessInterfacesParams.WORKSPACEID)!);
				this.processInterfacesWorkspacesListPageHeader = this.getPageHeader();
			});

		this.processInterfacesService.targetWorkspaceId$.pipe(takeUntil(this.destroy$)).subscribe((targetWorkspaceId) => {
			this.detailsWorkspaceId = targetWorkspaceId;
			this.breadCrumb = targetWorkspaceId
				? undefined
				: getBreadCrumb(this.route.snapshot.paramMap, this.showProcessVersionSelector);
			this.changeDetector.detectChanges();
		});

		this.processInterfacesService.workspacePage$.pipe(takeUntil(this.destroy$)).subscribe((workspacePage) => {
			this._currentWorkspacePage = workspacePage;
		});

		this.processInterfacesService.filterableElementTypes$.pipe(takeUntil(this.destroy$)).subscribe((elementTypes) => {
			if (elementTypes.length > 0) {
				this.dropdownContentArray = [];
				this.dropdownContentArray.push({ ident: "all", translate: "management.process.interfaces.filter.type" });
				for (const element of elementTypes) {
					this.dropdownContentArray.push({
						ident: element,
						translate: "process.element.type.singular." + element,
					});
				}
			} else {
				this.dropdownContentArray = undefined;
			}
		});

		this.processInterfacesFilterService.isFilterToolbarVisible$
			.pipe(takeUntil(this.destroy$))
			.subscribe((isVisible) => {
				this.isFilterToolbarVisible = isVisible;
				this.changeDetector.detectChanges();
			});

		this.mainService.applicationState.screenSize.pipe(takeUntil(this.destroy$)).subscribe((result) => {
			this.isFullSize = result === ScreenSize.XLARGE || result === ScreenSize.LARGE;
		});
	}

	getCardHeadlineTranslateKey(detailsWorkspaceId?: string): string {
		const titleType = detailsWorkspaceId ? "interfaces" : "workspaces";
		return "management.process.interfaces.page." + titleType + ".title";
	}

	getPageHeader(): HeadlineTranslation {
		return this._specificProcessVersion === undefined
			? {
					translate: "management.process.interfaces.page.title",
			  }
			: {
					translate: "management.process.interfaces.page.title.withparam.version",
					params: {
						processVersion: ProcessInterfacesComponent.isValidOrWorkingVersion(this._specificProcessVersion)
							? this.translateService.instant("management.process.interfaces.version." + this._specificProcessVersion)
							: this._specificProcessVersion,
					},
			  };
	}

	private static isValidOrWorkingVersion(versionName: string): boolean {
		return (
			versionName === ProcessInterfacesParams.WORKINGVERSION || versionName === ProcessInterfacesParams.VALIDVERSION
		);
	}

	async onDetailsClosed(): Promise<void> {
		await this.router.navigate(["workspaces", { page: this._currentWorkspacePage }], {
			relativeTo: this.route,
			queryParamsHandling: ProcessInterfacesParams.MERGE,
			queryParams: {
				type: null,
				searchTerm: null,
			},
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}

function getBreadCrumb(paramMap: ParamMap, showProcessVersionSelector: boolean): BreadCrumbTemplate {
	const workspaceId = paramMap.get(ProcessInterfacesParams.WORKSPACEID);
	const currentProcessVersion = paramMap.get(ProcessInterfacesParams.PV);

	const managementNavigationPoint = {
		labelKey: "management.navigation.title",
		route: ["//", "workspace", workspaceId, currentProcessVersion, "management"],
	};

	const processVersionNavigationPoint = {
		labelKey: "management.navigation.process.versions",
		route: ["//", "workspace", workspaceId, currentProcessVersion, "management", "versions"],
	};

	return {
		links: true,
		path: showProcessVersionSelector
			? [managementNavigationPoint]
			: [managementNavigationPoint, processVersionNavigationPoint],
	};
}
