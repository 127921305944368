/// <reference types="@types/ckeditor" />
/// <reference types="@types/jqueryui" />

import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateParser } from "@ngx-translate/core";
import { ApplicationContextRepository } from "core/application-context.repository";
import { BroadcastService } from "core/broadcast.service";
import { Events } from "core/events";
import { HttpService } from "core/http.service";
import { ContextResource, WorkspacesResource } from "core/stages-client";
import { StagesTranslateParser } from "core/translate/stages-translate.parser";
import { ApplicationState, VolatileApplicationState } from "core/volatile-application-state.model";
import { isEqual } from "lodash";
import { BehaviorSubject, lastValueFrom, Observable, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, first, pairwise } from "rxjs/operators";

type ApplicationContext = stages.context.ApplicationContext;
type WorkspaceView = stages.workspace.application.WorkspaceView;

export const SECONDARY_WORKSPACE_ID_PARAM = "swid";
export const SECONDARY_PROCESS_VERSION_PARAM = "spv";
export const SECONDARY_MODE_PARAM = "smode";

@Injectable({ providedIn: "root" })
export class MainService {
	readonly applicationState: ApplicationState;
	readonly workspaceView$: Observable<WorkspaceView>;
	readonly applicationContext$: Observable<ApplicationContext>;
	readonly menuItems$ = new ReplaySubject<MenuItem[]>(1);
	readonly popupOpenState$ = new BehaviorSubject(false);
	readonly navigationInProgress$ = new Subject<boolean>();

	private readonly workspaceSubject = new ReplaySubject<WorkspaceView>(1);
	private readonly applicationContextSubject = new ReplaySubject<ApplicationContext>(1);
	private applicationContext?: ApplicationContext;
	private _secondaryWorkspaceId?: string;
	private _secondaryProcessVersion?: string;
	private _secondaryMode?: string;

	constructor(
		private readonly router: Router,
		// "HttpService.invalidateAll()" is needed
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		private readonly httpService: HttpService,
		private readonly broadcastService: BroadcastService,
		private readonly translateParser: TranslateParser,
		private readonly applicationContextRepository: ApplicationContextRepository,
		private readonly contextResource: ContextResource,
		private readonly workspacesResource: WorkspacesResource,
	) {
		this.applicationState = new VolatileApplicationState(/*stateService*/);
		this.workspaceView$ = this.workspaceSubject.pipe(distinctUntilChanged(isEqual));
		this.applicationContext$ = this.applicationContextSubject.pipe(distinctUntilChanged(isEqual));
		this.workspaceView$.subscribe((workspaceView) => {
			console.log(`WorkspaceView content changed for workspace ${workspaceView.currentWorkspace.name}`);
			(this.translateParser as StagesTranslateParser).setCurrentMessageScope(
				workspaceView.currentWorkspace.messageScope!,
			);
			// could be necessary, but not sure (e.g. the user's language is set to "Workspace Language" and the user switches between workspaces of different language)
			this.httpService.invalidateAll();
		});
		this.workspaceView$.pipe(pairwise()).subscribe((pair) => {
			const osw = pair[0].secondaryWorkspace;
			const nsw = pair[1].secondaryWorkspace;
			const mappingStartedOrStopped = osw && nsw && osw.id !== nsw.id;
			// The snackbar should be shown, iff workspace changes, but not if compliance mapping is started/stopped:
			if (pair[0].currentWorkspace.id !== pair[1].currentWorkspace.id && !mappingStartedOrStopped) {
				this.broadcastService.broadcast(Events.MESSAGE, { translate: "process.version.switchWorkspace" });
			}
			const ovp = pair[0].currentWorkspace.viewedProcess;
			const nvp = pair[1].currentWorkspace.viewedProcess;
			// The snackbar should be shown, iff the current versionName changes and both versions are neither valid nor working versions.
			if (
				ovp &&
				nvp &&
				ovp.versionName !== nvp.versionName &&
				!(ovp.validVersion && nvp.validVersion) &&
				!(ovp.workingVersion && nvp.workingVersion)
			) {
				this.broadcastService.broadcast(Events.MESSAGE, { translate: "process.version.switchVersion" });
			}
		});
	}

	async getCurrentWorkspace(): Promise<WorkspaceView> {
		return lastValueFrom(this.workspaceView$.pipe(first()));
	}

	async getWorkspaceView(workspaceId: string, processVersion: string): Promise<WorkspaceView> {
		const wid = workspaceId ? workspaceId : (await this.getApplication(processVersion)).homeWorkspace.id!;
		const workspaceViewPromise = this.resolveWorkspace(wid, processVersion);
		this.workspaceSubject.next(await workspaceViewPromise);
		return workspaceViewPromise;
	}

	/**
	 * Resolves the workspace from the backend
	 */
	private async resolveWorkspace(workspaceId: string, processVersion: string): Promise<WorkspaceView> {
		const workspaceView = await this.workspacesResource.getCurrentWorkspaceWithHomeSpec(workspaceId, {
			pv: processVersion,
		});
		if (workspaceId !== workspaceView.currentWorkspace.id) {
			this.broadcastService.broadcast(Events.MESSAGE, { translate: "application.workspace.not.found" });
			this.goHome(processVersion);
		}
		return workspaceView;
	}

	async goHome(processVersion: string): Promise<boolean> {
		const application = await this.getApplication(processVersion);
		return this.router.navigate(["workspace", application.homeWorkspace.id, processVersion, "home"]);
	}

	/**
	 * Returns global information (application context) needed throughout the application (e.g user).
	 *
	 * @return promise
	 */
	async getApplication(processVersion: string): Promise<ApplicationContext> {
		if (this.applicationContext) {
			return this.applicationContext;
		}

		// This caches the result of a successful promise, but only after the fulfillment.
		// "getApplication" calls between the start and the fulfillment of the request are not cached.
		// If required, this could be improved:
		// - changed to caching the promise itself (instead of the resolved value)
		// - chache directly after starting
		// - set cached promise to undefined on promise rejection
		const applicationContext = await this.contextResource.getApplicationContext({
			pv: processVersion,
		});
		this.applicationContext = applicationContext;
		this.updateApplicationContextSubject(applicationContext);
		return applicationContext;
	}

	/**
	 * Refreshes the currently opened workspace & application context, with every REST request.
	 */
	refreshCurrentWorkspaceAndAppContext(newWorkspaceView: WorkspaceView, applicationContext: ApplicationContext): void {
		this.applicationContext = applicationContext;
		this.updateApplicationContextSubject(applicationContext);
		this.workspaceSubject.next(newWorkspaceView);
	}

	private updateApplicationContextSubject(applicationContext: ApplicationContext): void {
		this.applicationContextSubject.next(applicationContext);
		// during migration of applicationContext to akita store, both subjects have to be updated
		// if all applicationContextSubject usages are updated to use applicationContextQuery the duplication can be removed
		// usage see search.component.ts
		this.applicationContextRepository.update(applicationContext);
	}

	onLoginCallback(): void {
		this.applicationContext = undefined;
	}

	get secondaryWorkspaceId(): string | undefined {
		return this._secondaryWorkspaceId;
	}

	setSecondaryWorkspaceId(secondaryWorkspaceId: string | undefined): void {
		this._secondaryWorkspaceId = secondaryWorkspaceId;
		// && secondaryWorkspaceId !== currentWorkspaceId
		this.applicationState.secondaryWorkspaceEnabled = secondaryWorkspaceId !== undefined;
	}

	get secondaryProcessVersion(): string | undefined {
		return this._secondaryProcessVersion;
	}

	set secondaryProcessVersion(secondaryProcessVersion: string | undefined) {
		this._secondaryProcessVersion = secondaryProcessVersion;
	}

	get secondaryMode(): string | undefined {
		return this._secondaryMode;
	}

	set secondaryMode(secondaryMode: string | undefined) {
		this._secondaryMode = secondaryMode;
	}

	getSecondaryRouterPart(condition: boolean): {
		swid?: string;
		spv?: string;
		smode?: string;
	}[] {
		if (condition) {
			return [
				{
					swid: this.secondaryWorkspaceId,
					spv: this.secondaryProcessVersion,
					smode: this.secondaryMode,
				},
			];
		}
		return [];
	}

	openPopup(commands: unknown[], route: ActivatedRoute, skipLocationChange = false, queryParams?: Params): void {
		this.router.navigate(
			[
				{
					outlets: {
						popup: commands,
					},
				},
			],
			{
				relativeTo: this.findPopupContainer(route),
				skipLocationChange: skipLocationChange,
				queryParams: queryParams,
				queryParamsHandling: "merge",
			},
		);
	}

	openDialog(commands: unknown[], route: ActivatedRoute, skipLocationChange = false): void {
		void this.router.navigate(
			[
				{
					outlets: {
						dialog: commands,
					},
				},
			],
			{
				relativeTo: this.findPopupContainer(route),
				skipLocationChange: skipLocationChange,
			},
		);
	}

	closeDialog(route: ActivatedRoute): void {
		void this.router.navigate(
			[
				{
					outlets: {
						dialog: null,
					},
				},
			],
			{
				relativeTo: this.findPopupContainer(route),
			},
		);
	}

	findPopupContainer(route: ActivatedRoute): ActivatedRoute {
		if (route.snapshot.data.popupContainer === true) {
			return route;
		}

		if (!route.parent) {
			throw new Error("No popup container found.");
		}

		return this.findPopupContainer(route.parent);
	}

	async closePopup(route: ActivatedRoute): Promise<void> {
		await this.router.navigate(
			[
				{
					outlets: {
						popup: null,
					},
				},
			],
			{
				relativeTo: this.findPopupContainer(route),
			},
		);
	}

	getWorkspaceHomeParams(
		workspaceId: string,
		processVersion: string,
		secondaryWorkspaceId?: string,
		secondaryProcessVersion?: string,
		smode?: string,
	): unknown[] {
		return secondaryWorkspaceId && secondaryProcessVersion
			? [
					"workspace",
					workspaceId,
					processVersion,
					{ swid: secondaryWorkspaceId, spv: secondaryProcessVersion, smode: smode },
					"home",
			  ]
			: ["workspace", workspaceId, processVersion, "home"];
	}

	setNavigationInProgress(value: boolean): void {
		this.navigationInProgress$.next(value);
	}
}
