import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, OperatorFunction } from "rxjs";
import { distinctUntilChanged, pluck } from "rxjs/operators";

type Workspace = stages.workspace.Workspace;

export interface State {
	allWorkspaces?: Workspace[];
	favWorkspaces?: Workspace[];
	archivedWorkspaces?: Workspace[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	phaseMatrix: any;
}

const UNDEFINED_STATE: Partial<State> = {
	allWorkspaces: undefined,
	favWorkspaces: undefined,
	archivedWorkspaces: undefined,
};

// good idea?
type StoreType =
	| "allWorkspaces"
	| "archivedWorkspaces"
	| "copyProcessSelection"
	| "favWorkspaces"
	| "phaseMatrix"
	| "systemProperties";

@Injectable({ providedIn: "root" })
export class CommonStore {
	private subject = new BehaviorSubject(UNDEFINED_STATE);
	private store = this.subject.asObservable().pipe(distinctUntilChanged());

	get value(): Partial<State> {
		return this.subject.value;
	}

	select<T>(name: StoreType): Observable<T> {
		return this.store.pipe(pluck(name) as OperatorFunction<Partial<State>, T>);
	}

	set(name: StoreType, state: unknown): void {
		this.subject.next({
			...this.value,
			[name]: state,
		});
	}
}
