import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-files-oauth-embeddable-confirm",
	templateUrl: "./files-oauth-embeddable-confirm.component.html",
	styleUrls: ["./files-oauth-confirm.component.scss"],
})
export class FilesOAuthEmbeddableConfirmComponent {
	@Input()
	authUrl!: string;

	@Input()
	cmsTypeMessageKey!: string;

	@Input()
	closeCallback!: () => void;

	close(): void {
		this.closeCallback();
	}

	submit(): void {
		window.location.href = this.authUrl;
	}
}
