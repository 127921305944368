<ng-template #propertyTemplate let-column="column" let-association="association">
	<ng-container *ngFor="let property of column.properties">
		<stages-description-view
			*ngIf="hasDescriptionValue(association, property, true)"
			descriptionClass="{{ property.class }} shift-font-size"
			[html]="getValue(association, property, true)!"
		></stages-description-view>
		<div class="{{ property.class }}" *ngIf="!hasDescriptionValue(association, property, true)">
			<ng-container>{{ getValue(association, property, true) }}</ng-container>
		</div>
	</ng-container>
</ng-template>

<ng-container *ngIf="associations$ | async as associations">
	<ul class="process-list">
		<li class="process-list-item" *ngFor="let association of associations">
			<div class="label">
				<div class="text">
					<ng-container *ngFor="let property of $any(properties)">
						<a *ngIf="property.isLinkable" [routerLink]="getRoute(association)" class="{{ property.class }}">
							<ng-container
								*ngTemplateOutlet="propertyTemplate; context: { column: property, association: association }"
							></ng-container>
						</a>
						<div *ngIf="!property.isLinkable" class="{{ property.class }}">
							<ng-container
								*ngTemplateOutlet="propertyTemplate; context: { column: property, association: association }"
							></ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</li>
	</ul>
</ng-container>
