<ng-container *ngIf="self$ | async as self">
	<ng-container *ngIf="sequence$ | async as sequence">
		<stages-dialog2 #dialog [formGroup]="formGroup" *ngIf="sequence">
			<stages-dialog-header></stages-dialog-header>

			<div class="content">
				<h1 translate="{{ sequence.msgKey }}"></h1>
				<div class="form">
					<div class="input-container" *ngFor="let control of getFormControls(); let i = index">
						<input
							[formControlName]="getFormControlName(i, sequence)"
							type="text"
							class="text fullsize"
							autocomplete="off"
							(stagesKeyDown)="onKeyDown($event, self, sequence, i)"
							stagesAutofocus
						/>
						<div class="info">
							<div
								class="errors"
								*ngIf="
									getFormControl(i, sequence).errors &&
									(getFormControl(i, sequence).dirty || getFormControl(i, sequence).touched)
								"
							>
								<ng-container *ngFor="let error of getFormControlErrors(i, sequence)" [ngSwitch]="error">
									<div
										*ngSwitchCase="'pattern'"
										class="error"
										translate="process.element.edit.name.pattern"
										role="alert"
									></div>
									<div
										*ngSwitchCase="'max'"
										class="error"
										translate="input.maxLength"
										[translateParams]="{ length: maxLength }"
										role="alert"
									></div>
									<div
										*ngSwitchCase="'required'"
										class="error"
										translate="input.required"
										[translateParams]="{ length: maxLength }"
										role="alert"
									></div>
								</ng-container>
							</div>
							<div class="maxlength">{{ getMaxInputLength(i, sequence) }}</div>
						</div>
					</div>
					<div class="input-container right">
						<button
							type="button"
							class="button flat"
							(click)="add(sequence)"
							translate="process.element.add.sequence.add.input"
						></button>
					</div>
				</div>
			</div>

			<footer>
				<div class="buttons">
					<button class="sm cancel" (click)="close()" translate="cancel"></button>
					<button class="sm save" (click)="save(self, sequence)" translate="save"></button>
				</div>
			</footer>
		</stages-dialog2>
	</ng-container>
</ng-container>
