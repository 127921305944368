import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { BrowseService } from "core/browse.service";

@Injectable({ providedIn: "root" })
export class ModuleResolver implements Resolve<stages.workspace.Workspace[]> {
	constructor(private browseService: BrowseService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<stages.workspace.Workspace[]> {
		return this.browseService.getModules(route.paramMap.get("workspaceId")!, route.paramMap.get("processVersion")!);
	}
}
