import { Injectable } from "@angular/core";
import { UtilService } from "common/util.service";
import { PreferencesService } from "core/preferences.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export enum Step {
	Up,
	Down,
}

export enum ZoomOutBehavior {
	useOriginalImageSizeForMaxZoomOut,
	useFitToPageSizeForMaxZoomOut,
}

interface ZoomParameters {
	zoomParameterSet: boolean;
	zoomValue: number;
}

@Injectable()
export class ZoomToolbarService {
	// zoom toolbar configuration
	readonly ZOOM_MAX_ZOOM_OUT_VALUE: number = 0;
	readonly ZOOM_ORIGINAL_IMAGE_SIZE_VALUE: number = 100;
	readonly ZOOM_MAX_ZOOM_IN_VALUE: number = 200;
	readonly ZOOM_FIT_TO_PAGE_TO_ORIGINAL_IMAGE_SIZE_STEP_SIZE: number = 20;
	readonly ZOOM_ORIGINAL_IMAGE_SIZE_TO_MAX_ZOOM_IN_STEP_SIZE: number = 20;

	get AMOUNT_OF_STEPS_FROM_ORIGINAL_IMAGE_SIZE_TO_FIT_TO_PAGE(): number {
		return (
			(this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE - this.ZOOM_MAX_ZOOM_OUT_VALUE) /
			this.ZOOM_FIT_TO_PAGE_TO_ORIGINAL_IMAGE_SIZE_STEP_SIZE
		);
	}

	get AMOUNT_OF_STEPS_FROM_MAX_ZOOM_IN_TO_ORIGINAL_IMAGE_SIZE(): number {
		return (
			(this.ZOOM_MAX_ZOOM_IN_VALUE - this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE) /
			this.ZOOM_ORIGINAL_IMAGE_SIZE_TO_MAX_ZOOM_IN_STEP_SIZE
		);
	}

	private _zoomValue: BehaviorSubject<number> = new BehaviorSubject(100);

	private _amountOfVisibleDiagrams: Subject<number>;
	private currentAmountOfVisibleDiagrams: number = 0;

	private _zoomOutBehavior: Subject<ZoomOutBehavior>;
	private currentZoomOutBehavior: ZoomOutBehavior = ZoomOutBehavior.useFitToPageSizeForMaxZoomOut;

	// public API to subscribe to
	zoomValue: Observable<number>;
	amountOfVisibleDiagrams: Observable<number>;
	zoomOutBehavior: Observable<ZoomOutBehavior>;

	private diagramIdToZoomOutBehaviorMap: Map<string, ZoomOutBehavior>;
	isBrowserSupported: boolean = false;

	constructor(private readonly utilService: UtilService, private readonly preferencesService: PreferencesService) {
		this._amountOfVisibleDiagrams = new Subject();
		this._zoomOutBehavior = new Subject();

		this.zoomValue = this._zoomValue.asObservable();
		this.amountOfVisibleDiagrams = this._amountOfVisibleDiagrams.asObservable();
		this.zoomOutBehavior = this._zoomOutBehavior.asObservable();

		this.diagramIdToZoomOutBehaviorMap = new Map();
		this.isBrowserSupported = !(this.utilService.browserIsEdge() || this.utilService.browserIsIE());
	}

	setZoomValue(value: number): void {
		let newZoomValue = 0;
		if (value > this.ZOOM_MAX_ZOOM_IN_VALUE) {
			newZoomValue = this.ZOOM_MAX_ZOOM_IN_VALUE;
		} else if (value < this.ZOOM_MAX_ZOOM_OUT_VALUE) {
			newZoomValue = this.ZOOM_MAX_ZOOM_OUT_VALUE;
		} else {
			newZoomValue = value;
		}
		this._zoomValue.next(newZoomValue);
	}

	setZoomValueToLowestPossibleValue(): void {
		this.currentZoomOutBehavior === ZoomOutBehavior.useOriginalImageSizeForMaxZoomOut
			? this.setZoomValue(this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE)
			: this.setZoomValue(this.ZOOM_MAX_ZOOM_OUT_VALUE);
	}

	performStep(step: Step): void {
		let newZoomValue = 0;
		if (step === Step.Down) {
			newZoomValue =
				this._zoomValue.value <= this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE
					? this._zoomValue.value - this.ZOOM_FIT_TO_PAGE_TO_ORIGINAL_IMAGE_SIZE_STEP_SIZE
					: this._zoomValue.value - this.ZOOM_ORIGINAL_IMAGE_SIZE_TO_MAX_ZOOM_IN_STEP_SIZE;
		} else {
			newZoomValue =
				this._zoomValue.value >= this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE
					? this._zoomValue.value + this.ZOOM_ORIGINAL_IMAGE_SIZE_TO_MAX_ZOOM_IN_STEP_SIZE
					: this._zoomValue.value + this.ZOOM_FIT_TO_PAGE_TO_ORIGINAL_IMAGE_SIZE_STEP_SIZE;
		}
		this.setZoomValue(newZoomValue);
	}

	registerVisibleDiagram(): void {
		this.currentAmountOfVisibleDiagrams += 1;
		this._amountOfVisibleDiagrams.next(this.currentAmountOfVisibleDiagrams);
	}

	unregisterVisibleDiagram(): void {
		this.currentAmountOfVisibleDiagrams -= 1;
		this._amountOfVisibleDiagrams.next(this.currentAmountOfVisibleDiagrams);
	}

	updateZoomOutBehaviorForDiagram(diagramId: string, useOriginalImageSizeForMaxZoomOut: ZoomOutBehavior): void {
		this.diagramIdToZoomOutBehaviorMap.set(diagramId, useOriginalImageSizeForMaxZoomOut);
		this.currentZoomOutBehavior = ZoomOutBehavior.useOriginalImageSizeForMaxZoomOut;
		// even if only one diagram needs to use the fitToPageSizeForMaxZoomOut, we will use it for all diagrams on the page
		this.diagramIdToZoomOutBehaviorMap.forEach((zoomOutBehavior) => {
			if (zoomOutBehavior === ZoomOutBehavior.useFitToPageSizeForMaxZoomOut) {
				this.currentZoomOutBehavior = ZoomOutBehavior.useFitToPageSizeForMaxZoomOut;
			}
		});
		this._zoomOutBehavior.next(this.currentZoomOutBehavior);
		if (
			this.currentZoomOutBehavior === ZoomOutBehavior.useOriginalImageSizeForMaxZoomOut &&
			this._zoomValue.value < this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE
		) {
			this.setZoomValue(this.ZOOM_ORIGINAL_IMAGE_SIZE_VALUE);
		}
	}

	deleteZoomOutBehaviorForDiagram(diagramId: string): void {
		this.diagramIdToZoomOutBehaviorMap.delete(diagramId);
	}

	applyZoomParametersFromLocalStorage(): void {
		this.readZoomParameters().then((parameters) => {
			if (parameters.zoomParameterSet) {
				this.setZoomValue(parameters.zoomValue);
			} else {
				this.setZoomValueToLowestPossibleValue();
			}
		});
	}

	private async readZoomParameters(): Promise<ZoomParameters> {
		const prefkey: string = "diagram.zoom";
		return this.preferencesService.getPreference(prefkey, { zoomValue: 0, zoomParameterSet: false });
	}

	private storeZoomParameters(): void {
		const prefKey: string = "diagram.zoom";
		this.preferencesService.setPreference(prefKey, { zoomParameterSet: true, zoomValue: this._zoomValue.value });
	}

	storeZoomParametersIfVisibleDiagramExists(): void {
		if (this.currentAmountOfVisibleDiagrams > 0 && this.isBrowserSupported) {
			this.storeZoomParameters();
		}
	}
}
