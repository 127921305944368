import { HttpErrorResponse } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Directive,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Renderer2,
	SimpleChanges,
	TemplateRef,
	TrackByFunction,
	ViewChild,
} from "@angular/core";
import { AutoCompleteComponent } from "common/autoComplete/auto-complete.component";
import { Selection } from "common/collection/selection.model";
import { AddComponent } from "common/data/add.component";
import { EditableComponent } from "common/data/editable.component";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { NotificationService } from "core/notification.service";
import { Observable, Subject } from "rxjs";
import {
	getIdKeyOfTypeId,
	getTypeIdOfTypeIdString,
	SelectionStoreService,
} from "common/selection/state/selection-store.service";
import { SelectionRepository } from "common/selection/state/selection.repository";
import { Selectable } from "common/selection/state/selectable.model";
import { debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";

type TypedId = stages.core.TypedId;

export enum Mode {
	VIEW = "VIEW",
	ADD = "ADD",
	RENAME = "RENAME",
	DELETE = "DELETE",
	EDIT = "EDIT",
	UPDATE = "UPDATE",
	SELECT = "SELECT",
	REARRANGE = "REARRANGE",
}

export interface Button {
	class: string;
	translate: string;
	disabled?(): boolean;
	visible?(): boolean;
	click(): void;
}

@Directive()
export abstract class DataViewComponent<T> implements OnInit, OnDestroy, OnChanges {
	private filterUpdated$ = new Subject<string>();
	destroy$ = new Subject<boolean>();
	Mode = Mode;
	_mode: Mode = Mode.VIEW;
	buttons: Button[] = [];
	selection: Selection<string>;
	filterTerm?: string;
	searchEngineAvailable: boolean = true;
	groupViewStates = new Map<string, Mode>();
	isSelectAllActivated: boolean = false;
	selectAllButtonPressed = false;
	triggerChangeCounter = 0;
	private groupId?: string;

	@Input()
	dataSource!: PageableDataSource<T>;

	@Input()
	translateNone?: string;

	@Input()
	autoCompleteTemplate?: TemplateRef<unknown>;

	@Input()
	autoCompleteBrowseTemplate?: TemplateRef<unknown>;

	@Input()
	renameTemplate?: TemplateRef<unknown>;

	@Input()
	showMore: boolean = false;

	@Input()
	idFn!: (item: T) => string;

	@Input()
	idFnNew?: (item: T) => TypedId;

	@Input()
	groupIdFn!: (item: T) => string;

	@Input()
	classesFn?: (item: T) => string[];

	// TODO only use one disabled function. this one seems completly unused
	@Input()
	disabledFn!: (item: T, data: PagedResult<T>, mode: Mode) => boolean;

	@Input()
	inactiveFn: (item: T) => boolean = () => false;

	@Input()
	isPreselectedFn: (item: T) => boolean = () => false;

	@Input()
	isPartiallySelectedFn: (item: T) => boolean = () => false;

	@Input()
	disabledFnNew: (item: T) => boolean = () => false;

	@Input()
	actionInProgress: boolean = false;

	@Input()
	pageSize: number = 10;

	@Input()
	addMaxLength = 255;

	@Input()
	addPattern?: string;

	@Input()
	addPatternMessageKey?: string;

	@Input()
	showSelectAllSwitch: boolean = false;

	@Input()
	storeIdentifier: string = "common";

	@Input()
	parentIdentifier?: (item: T) => string;

	@ViewChild(AutoCompleteComponent)
	autoComplete?: AutoCompleteComponent<unknown>;

	@ViewChild(AddComponent)
	addComponent?: AddComponent;

	@ViewChild("coreTable", { read: ElementRef })
	coreTable!: ElementRef;

	editables: Record<string, EditableComponent<T>> = {};

	data$!: Observable<PagedResult<T> | SecuredPagedResult<T> | SecuredPagedResultAndInfo<T, unknown>>;

	renamed?: T;

	allElements: Selectable[] = [];

	hasItems: boolean = false;

	constructor(
		private chRef: ChangeDetectorRef,
		private notificationService: NotificationService,
		private renderer: Renderer2,
		private selectionStoreService: SelectionStoreService,
		private selectionRepository: SelectionRepository,
	) {
		this.selection = new Selection<string>(true);
	}

	initializeSelection(initiallySelectedValues: string[]): void {
		this.selection = new Selection<string>(true, initiallySelectedValues);
	}

	get selectable(): boolean {
		return (
			(this.mode === Mode.DELETE || this.mode === Mode.UPDATE || this.mode === Mode.SELECT) && !this.actionInProgress
		);
	}

	get mode(): Mode {
		return this._mode;
	}

	@Input()
	set mode(mode: Mode) {
		const wasSelectionMode = this.selectable;
		if (this._mode === Mode.ADD && mode === Mode.VIEW) {
			window.setTimeout(() => {
				this._mode = mode;
			}, 500);
		} else {
			this._mode = mode;
		}

		if (wasSelectionMode && !this.selectable) {
			this.selection.clear();
		}
	}

	private updatePollingStatus(): void {
		if (this.dataSource) {
			if (this.mode === Mode.VIEW) {
				this.dataSource.startPolling(this.notificationService);
			} else {
				this.dataSource.stopPolling(this.notificationService);
			}
		}
	}

	ngOnInit(): void {
		this.data$ = this.dataSource.data;

		if (this.mode === Mode.SELECT) {
			const deselectedCount$ = this.selectionRepository.getDeSelectedCountByIdentifier$(this.storeIdentifier);

			deselectedCount$
				.pipe(
					map((count) => {
						return count === 0;
					}),
					takeUntil(this.destroy$),
				)
				.subscribe((isSelectAllCurrentlyActivated) => {
					this.triggerChangeCounter++;
					this.isSelectAllActivated = isSelectAllCurrentlyActivated;
				});

			this.data$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
				for (const item of value.items) {
					this.addOrUpdateSelection(item);
				}

				this.hasItems = value.items.length > 0;
			});
		}

		if (this.mode === Mode.VIEW) {
			this.dataSource.startPolling(this.notificationService);
		}

		this.filterUpdated$
			.asObservable()
			.pipe(debounceTime(300))
			.pipe(distinctUntilChanged())
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.onFilterInput();
			});
	}

	ngOnDestroy(): void {
		this.dataSource.stopPolling(this.notificationService);
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.dataSource?.currentValue || changes.mode?.currentValue) {
			this.updatePollingStatus();
		}
	}

	async add(item: unknown, groupId?: string): Promise<void> {
		try {
			if (groupId && this.dataSource.addToGroup) {
				await this.dataSource.addToGroup([item], groupId);
			}

			if (!groupId && this.dataSource.add) {
				await this.dataSource.add(item);
			}

			if (this.autoComplete) {
				this.autoComplete.focus();
			}
			if (this.addComponent) {
				this.addComponent.itemExist = false;
				this.addComponent.form.reset();
				this.addComponent.focus();
			}
		} catch (e: unknown) {
			if (e instanceof HttpErrorResponse && e.status === 422) {
				this.addComponent!.itemExist = true;
				if (typeof item === "string") {
					this.addComponent!.name = item;
				}
			} else {
				throw e;
			}
		}
	}

	async enterAdd(goToFirstPage: boolean = true): Promise<void> {
		if (goToFirstPage) {
			await this.dataSource.goTo(1);
		}
		this.mode = Mode.ADD;
	}

	enterAddByGroup(groupId: string): void {
		this.groupViewStates.set(groupId, Mode.ADD);
		for (const entry of this.groupViewStates) {
			if (entry[0] !== groupId) {
				this.groupViewStates.set(entry[0], Mode.VIEW);
			}
		}
	}

	enterDelete(): void {
		this.mode = Mode.DELETE;
	}

	cancelAdd(groupId?: string): void {
		if (groupId) {
			this.groupViewStates.set(groupId, Mode.VIEW);
		} else {
			this.mode = Mode.VIEW;
		}
	}

	rename(item: T, renameGroup: boolean = false): void {
		this.mode = Mode.RENAME;
		this.chRef.detectChanges();
		const id = renameGroup ? this.groupIdFn(item) : this.idFn(item);
		const editable = this.editables[id];
		editable.editable = true;
		editable.focus();
	}

	enterRearrange(): void {
		this.mode = Mode.REARRANGE;
		this.chRef.detectChanges();
	}

	cancelRearrange(skipRedraw = false): void {
		this.mode = Mode.VIEW;
		if (!skipRedraw) {
			this.redrawTable();
		}
		this.dataSource.reloadPage();
	}

	async commitRearrange(items: T[]): Promise<void> {
		if (this.dataSource.rearrange) {
			await this.dataSource.rearrange(items);
			this.mode = Mode.VIEW;
			this.redrawTable();
		}
	}

	cancelRename(item: T, skipRedraw = false, renameGroup: boolean = false): void {
		const id = renameGroup ? this.groupIdFn(item) : this.idFn(item);
		const editable = this.editables[id];
		editable.editable = false;
		if (this.mode === Mode.RENAME) {
			this.mode = Mode.VIEW;
		}
		if (!skipRedraw) {
			this.redrawTable();
		}
	}

	async commitRename(item: T, name: string, renameGroup: boolean = false): Promise<void> {
		const id = renameGroup ? this.groupIdFn(item) : this.idFn(item);
		const editable = this.editables[id];
		if (this.dataSource.rename) {
			await this.dataSource.rename(item, name);
			this.mode = Mode.VIEW;
			editable.editable = false;
			this.redrawTable();
			return;
		}
		return undefined;
	}

	trackBy: TrackByFunction<T> = (_index, item) => {
		if (this.idFnNew) {
			const typedId = this.idFnNew(item);
			return typedId.id + typedId.typeIdent;
		}
		return this.idFn(item);
	};

	addEditable(editable: EditableComponent<T>): void {
		const id = editable.isGroup ? this.groupIdFn(editable.item) : this.idFn(editable.item);
		this.editables[id] = editable;
	}

	onFilterKeyUp(value: string): void {
		this.filterUpdated$.next(value);
	}

	isItemRenamed(item: T): boolean {
		if (this.mode !== Mode.RENAME) {
			return false;
		}

		const editable = this.editables[this.idFn(item)];
		return editable?.editable;
	}

	isGroupRenamed(group: T): boolean {
		if (this.mode !== Mode.RENAME) {
			return false;
		}

		const editable = this.editables[this.groupIdFn(group)];
		return editable?.editable;
	}

	isRecentlyAdded(item: T): boolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings!
		return (item as any).recentlyAdded;
	}

	getGroupId(): string {
		return this.groupId!;
	}

	isAutoCompleteOpen(groupId?: string): boolean {
		return groupId ? this.groupViewStates.get(groupId) === Mode.ADD : false;
	}

	getClasses(item: T): string[] {
		const classes: string[] = this.classesFn ? [...this.classesFn(item)] : [];
		if (this.isItemRenamed(item)) {
			classes.push("add-item");
		}
		if (this.isRecentlyAdded(item)) {
			classes.push("new-item");
		}

		return classes;
	}

	selectDeselectAll(): void {
		this.selectAllButtonPressed = true;

		// TODO maybe not needed to handle manually because we subscribed to deselectedCount$
		this.isSelectAllActivated = !this.isSelectAllActivated;

		if (this.isSelectAllActivated) {
			this.selectionStoreService.selectAllInCommonByIdentifier(this.storeIdentifier);
		} else {
			this.selectionStoreService.deSelectAllInCommonByIdentifier(this.storeIdentifier);
		}
		// trigger recalculate isSelected and isPreselected in pipes
		this.triggerChangeCounter++;
	}

	addOrUpdateSelection(item: T): void {
		if (this.idFnNew) {
			const typedId = this.idFnNew!(item);
			const selectionKey = getIdKeyOfTypeId(typedId);
			const selectionTarget = this.selectionRepository.getEntity(selectionKey, this.storeIdentifier);
			if (!selectionTarget) {
				const initiallySelectedChildrenCount = (item as unknown as Selectable).hasOwnProperty("initiallySelectedCount")
					? (item as unknown as Selectable).initiallySelectedCount
					: 0;

				const selectableChildrenCount = (item as unknown as Selectable).hasOwnProperty("selectableChildrenCount")
					? (item as unknown as Selectable).selectableChildrenCount
					: 0;
				this.selectionStoreService.addNewSelectable(
					typedId,
					this.isPreselectedFn(item),
					this.parentIdentifier ? getTypeIdOfTypeIdString(this.parentIdentifier(item)) : undefined,
					this.storeIdentifier ? this.storeIdentifier : undefined,
					this.isPartiallySelectedFn(item),
					selectableChildrenCount ?? 0,
					initiallySelectedChildrenCount ?? undefined,
					selectableChildrenCount ?? 0,
					this.disabledFnNew ? !this.disabledFnNew(item) : true,
				);
			}
		}
	}

	toggleSelection(item: T): void {
		if (this.idFnNew) {
			this.selectAllButtonPressed = false;
			const typedId = this.idFnNew!(item);
			const selectionKey = getIdKeyOfTypeId(typedId);
			const selectionTarget = this.selectionRepository.getEntity(selectionKey, this.storeIdentifier);
			if (selectionTarget) {
				this.toggleExisting(selectionTarget);
			} else {
				if (this.parentIdentifier && this.storeIdentifier)
					this.selectionStoreService.addNewSelectable(
						typedId,
						this.isPreselectedFn(item),
						// TODO parentIdentifier not needed anymore!
						getTypeIdOfTypeIdString(this.parentIdentifier(item)),
						this.storeIdentifier,
					);
				else {
					this.selectionStoreService.addNewSelectable(
						typedId,
						this.isPreselectedFn(item),
						undefined,
						this.storeIdentifier,
					);
				}
			}
			this.triggerChangeCounter++;
		} else {
			this.selection.toggle(this.idFn(item));
		}
	}

	private toggleExisting(selectable: Selectable): void {
		if (selectable?.isSelected && selectable.indeterminate) {
			this.selectionStoreService.selectToCommonSelection(selectable);
		} else if (selectable?.isSelected && !selectable.indeterminate) {
			this.selectionStoreService.unselectFromCommonSelection(selectable);
		} else if (!selectable?.isSelected) {
			this.selectionStoreService.selectToCommonSelection(selectable);
		}
	}

	async onFilterInput(): Promise<void> {
		if (this.filterTerm) {
			if (this.filterTerm.length >= 1 && this.dataSource.filter) {
				try {
					await this.dataSource.filter(this.filterTerm);
					this.searchEngineAvailable = true;
				} catch {
					this.searchEngineAvailable = false;
				}
			}
		} else {
			this.dataSource.reloadPage();
		}
	}

	private redrawTable(): void {
		// redraw table on edge, without this code the table layout is broken after rename (e.g. workspace attribute rename)
		const ua = window.navigator.userAgent;
		if (this.coreTable && ua.indexOf("Edge/") > 0) {
			this.renderer.setStyle(this.coreTable.nativeElement, "display", "block");
			this.chRef.detectChanges();
			setTimeout(() => {
				this.renderer.setStyle(this.coreTable.nativeElement, "display", "table");
			}, 200);
		}
	}
}
