import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { MainService } from "core/main.service";

@Injectable({ providedIn: "root" })
export class HomeGuard implements CanActivate {
	constructor(private router: Router, private mainService: MainService) {}

	canActivate(): Promise<boolean> {
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return this.mainService.getApplication("_vv").then(async (application) => {
			this.router.navigate(["workspace", application.homeWorkspace.id, "_vv", "home"]);
			return false;
		});
	}
}
