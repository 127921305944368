<div *ngIf="workspaceView$ | async as workspaceView" (stagesClickOutside)="closeNavigation()">
	<div
		class="workspace-nav primary"
		[ngClass]="{
			visible: applicationState.workspaceNavigationOpen,
			'bottom-72': applicationState.secondaryWorkspaceNavigationOpen && applicationState.secondaryWorkspaceEnabled
		}"
	>
		<div
			class="workspace-divider animate"
			[ngClass]="{ 'shadow-border': applicationState.secondaryWorkspaceNavigationOpen }"
		></div>
		<header class="primary-header" [ngClass]="{ open: applicationState.secondaryWorkspaceEnabled }">
			<div class="workspace-selection-area">
				<button
					[attr.title]="'navigation.workspace.select' | translate"
					[attr.aria-label]="'navigation.workspace.select' | translate"
					class="action nav-button"
					(click)="applicationState.toggleWorkspaceNavigation()"
				>
					<i class="ico ico-workspace"></i>
				</button>
				<div class="title" (click)="applicationState.toggleWorkspaceNavigation()">
					<h3 class="shorten">{{ workspaceView.currentWorkspace.name }}</h3>
					<h6
						class="shorten"
						*ngIf="parentExists($any(workspaceView.currentWorkspace)) && getSecondaryMode() !== 'compare'"
					>
						{{ getParentName($any(workspaceView.currentWorkspace)) }}
					</h6>
					<h6 class="shorten" *ngIf="getSecondaryMode() === 'compare'">
						{{ getVersionName(workspaceView.currentWorkspace.viewedProcess) }}
					</h6>
				</div>
				<button
					[attr.aria-label]="'close' | translate"
					[attr.title]="'close' | translate"
					class="workspace-close-button"
					(click)="restoreSecondaryView()"
					*ngIf="applicationState.secondaryWorkspaceEnabled"
				>
					<div>
						<i class="ico ico-close"></i>
					</div>
				</button>
			</div>
		</header>

		<div class="animate defer">
			<stages-workspace-selection
				activeTabPreferenceKey="WorkspaceNavigationComponent.activeTab"
				(selectWorkspace)="onSelectWorkspace($event)"
				[bookmarkable]="true"
				[selected]="workspaceView.currentWorkspace.id"
				*ngIf="applicationState.workspaceNavigationOpen"
				[@defer]
			></stages-workspace-selection>
		</div>
	</div>

	<div
		*ngIf="applicationState.secondaryWorkspaceEnabled"
		class="workspace-nav secondary animate"
		[ngClass]="{ visible: applicationState.secondaryWorkspaceNavigationOpen }"
		[@defer]
	>
		<header>
			<div class="workspace-selection-area">
				<button
					disabled="true"
					[attr.title]="'notaccessible' | translate"
					[attr.aria-label]="'notaccessible' | translate"
					class="action nav-button"
				>
					<i class="ico" [ngClass]="getSecondaryMode() === 'compare' ? 'ico-interface' : 'ico-workspace'"></i>
				</button>
				<div class="title">
					<h3 class="shorten">{{ workspaceView.secondaryWorkspace.name }}</h3>
					<h6
						class="shorten"
						*ngIf="parentExists($any(workspaceView.secondaryWorkspace)) && getSecondaryMode() !== 'compare'"
					>
						{{ getParentName($any(workspaceView.secondaryWorkspace)) }}
					</h6>
					<h6 class="shorten" *ngIf="getSecondaryMode() === 'compare'">
						{{ getVersionName(workspaceView.secondaryWorkspace.viewedProcess) }}
					</h6>
				</div>
			</div>
		</header>
	</div>
</div>
