<article class="start-process responsive-6-6">
	<header class="page-header">
		<h1 translate="process.start.title"></h1>
	</header>

	<div class="container">
		<article class="card" *ngIf="isCreateProcessAllowed">
			<header>
				<div class="wrapper">
					<h5 translate="process.start.empty.title"></h5>
				</div>
			</header>
			<p translate="process.start.empty.message"></p>
			<footer>
				<div class="buttons">
					<button class="lg" [disabled]="inProgress" (click)="onStartEmpty()">
						<span translate="process.start.empty.button" *ngIf="!inProgress"></span>
						<stages-spin size="sm" *ngIf="inProgress"></stages-spin>
					</button>
				</div>
			</footer>
		</article>

		<article class="card" *ngIf="isAddModuleAllowed">
			<header>
				<div class="wrapper">
					<h5 translate="process.start.modules.title"></h5>
				</div>
			</header>
			<p translate="process.start.modules.message"></p>
			<footer>
				<div class="buttons">
					<ng-container *ngIf="latestJob$ | async as latestJob">
						<button
							[attr.aria-label]="'error' | translate"
							[attr.title]="'error' | translate"
							*ngIf="hasError(latestJob, 'ADD_MODULE')"
							(click)="openProgressDetails(latestJob)"
						>
							<i class="ico ico-warning error-color"></i>
						</button>
					</ng-container>
					<button
						class="lg"
						[routerLink]="['../../../..', '_wv', 'process', 'start', 'install', 'add', { start: true }, 'select']"
						translate="process.start.modules.button"
					></button>
				</div>
			</footer>
		</article>

		<article class="card" *ngIf="isCopyProcessAllowed">
			<header>
				<div class="wrapper">
					<h5 translate="process.start.copy.title"></h5>
				</div>
			</header>
			<p translate="process.start.copy.message"></p>
			<footer>
				<div class="buttons">
					<ng-container *ngIf="latestJob$ | async as latestJob">
						<button
							[attr.aria-label]="'error' | translate"
							[attr.title]="'error' | translate"
							*ngIf="hasError(latestJob, 'COPY_PROCESS')"
							(click)="openProgressDetails(latestJob)"
						>
							<i class="ico ico-warning error-color"></i>
						</button>
					</ng-container>
					<button
						class="lg"
						[routerLink]="['../../../..', '_wv', 'process', 'start', 'install', 'copy', 'select']"
						translate="process.start.copy.button"
					></button>
				</div>
			</footer>
		</article>

		<article class="card" *ngIf="isImportProcessAllowed">
			<header>
				<div class="wrapper">
					<h5 translate="process.start.import.title"></h5>
				</div>
			</header>
			<p translate="process.start.import.message"></p>
			<footer>
				<div class="buttons">
					<ng-container *ngIf="latestJob$ | async as latestJob">
						<button
							[attr.aria-label]="'error' | translate"
							[attr.title]="'error' | translate"
							*ngIf="hasError(latestJob, 'IMPORT_PROCESS')"
							(click)="openProgressDetails(latestJob)"
						>
							<i class="ico ico-warning error-color"></i>
						</button>
					</ng-container>
					<button class="lg" [routerLink]="['../../../..', '_wv', 'process', 'start', 'install', 'import']">
						<span translate="process.start.import.button"></span>
					</button>
				</div>
			</footer>
		</article>
	</div>
</article>
