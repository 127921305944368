<ng-container *ngIf="paramMap$ | async as paramMap">
	<ng-container *ngIf="tabs$ | async as tabs">
		<ng-container *ngIf="entryPage$ | async as entryPage">
			<stages-card cardId="entryPagesCard">
				<header class="contains-scrollable-tabs" *ngIf="tabs.length > 1">
					<stages-tabs [activeTabName]="$any(getActiveTabName(tabs))">
						<stages-tab-no-route *ngFor="let tab of tabs" [active]="active === tab.id" (activate)="tabClicked(tab)">
							<h5>{{ tab.name }}</h5>
						</stages-tab-no-route>
					</stages-tabs>
				</header>

				<div class="panel">
					<stages-description
						[beanGuid]="entryPage.guid"
						[beanIdentity]="entryPage.id"
						[beanId]="entryPage.id"
						[modifiable]="entryPage.actions.MODIFY"
						[processTypeIdent]="''"
						[unsafe]="false"
						[displayDescription]="entryPage.description"
						[descriptionService]="descriptionService"
						[workspaceId]="$any(paramMap.get('workspaceId'))"
						[pv]="$any(paramMap.get('processVersion'))"
						[properties]="{ linkOriginWorkspaceId: entryPage.rootWorkspaceId, imageContainerType: 'entrypage' }"
					>
					</stages-description>
				</div>
			</stages-card>
		</ng-container>
	</ng-container>
</ng-container>
