import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { CarouselComponent } from "common/carousel/carousel.component";
import { UtilService } from "common/util.service";
import { TabName, WorkspaceService } from "common/workspace/workspace.service";
import { MainService } from "core/main.service";
import { PreferencesService } from "core/preferences.service";
import { combineLatest, EMPTY, from, fromEvent, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { FolderRepository } from "common/workspace/folder.repository";

const DEBOUNCE_TIME = 250;
type Workspace = stages.workspace.Workspace;
type Folder = stages.workspace.Folder;

interface Preference {
	tabName: TabName;
}

export interface WorkspaceSelected {
	name: string;
	id: string;
	pathSegments: string[];
	path: string;
}

@Component({
	selector: "stages-workspace-selection",
	templateUrl: "./workspace-selection.component.html",
})
export class WorkspaceSelectionComponent implements OnInit, OnDestroy {
	folder$!: Observable<Folder | undefined>;
	workspaces$!: Observable<Workspace[]>;
	favorites$!: Observable<Set<string>>;
	currentWorkspaceId!: string;
	currentProcessVersion!: string;

	tabs!: TabName[];
	activeTab!: TabName;

	inputSearchTerm?: string;
	searchTerm?: string;
	searchEngineAvailable = true;

	@Input()
	activeTabPreferenceKey?: string;

	@Input()
	bookmarkable?: boolean;

	@Input()
	classes?: string[];

	@Input()
	filterBy?: string;

	@Input()
	filterParam1?: string;

	@Input()
	filterParam2?: string;

	@Input()
	selected?: string;

	@Input()
	useBrowsePV?: boolean;

	@Input()
	showRelated?: boolean;

	@Input()
	showArchived: boolean = true;

	@Input()
	showFavorites: boolean = true;

	@Input()
	showSearch: boolean = true;

	@Input()
	showNavigate: boolean = true;

	@Output() readonly selectWorkspace = new EventEmitter<WorkspaceSelected>();

	@ViewChild("searchInput", { read: ElementRef, static: true })
	input!: ElementRef;

	@ViewChild("carousel", { static: true })
	carousel!: CarouselComponent;

	private destroy$ = new Subject<boolean>();

	constructor(
		private readonly mainService: MainService,
		private readonly utilService: UtilService,
		private readonly route: ActivatedRoute,
		private readonly preferencesService: PreferencesService,
		private chRef: ChangeDetectorRef,
		private workspaceService: WorkspaceService,
		private readonly folderRepository: FolderRepository,
	) {}

	ngOnInit(): void {
		this.workspaceService.clearStore();
		this.folder$ = this.folderRepository.activeFolder$;

		combineLatest([
			this.route.data,
			this.route.root.firstChild!.paramMap,
			this.route.paramMap,
			this.activeTabPreferenceKey
				? // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				  from(this.preferencesService.getPreference<any>(this.activeTabPreferenceKey, undefined))
				: of(undefined),
		])
			.pipe(
				distinctUntilChanged(
					([data, rootParamMap, paramMap, preference], [data2, rootParamMap2, paramMap2, preference2]) =>
						rootParamMap.get("processVersion") === rootParamMap2.get("processVersion") &&
						paramMap.get("browseProcessVersion") === paramMap2.get("browseProcessVersion") &&
						data.related === data2.related &&
						data.onlyModules === data2.onlyModules,
				),
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(([data, rootParamMap, paramMap, preference]) => {
				this.currentWorkspaceId = rootParamMap.get("workspaceId")!;
				this.currentProcessVersion = this.useBrowsePV
					? paramMap.get("browseProcessVersion")!
					: rootParamMap.get("processVersion")!;

				this.tabs = [];
				if (this.showRelated && data.related && data.related.length > 0) {
					if (data.onlyModules) {
						this.tabs.push("modules");
					} else {
						this.tabs.push("related");
					}
				}

				if (this.showFavorites) {
					this.tabs.push("favorites");
				}

				if (this.showNavigate) {
					this.tabs.push("navigate");
				}

				if (this.showSearch) {
					this.tabs.push("search");
				}

				if (this.showArchived) {
					this.tabs.push("archived");
				}

				this.chRef.detectChanges();

				const storedTab = preference
					? this.tabs.filter((tab) => tab === (preference as Preference).tabName)[0]
					: undefined;
				this.openTab(storedTab ? storedTab : this.tabs[0], rootParamMap, paramMap, false);
			});

		fromEvent(this.input.nativeElement, "keyup")
			.pipe(debounceTime(DEBOUNCE_TIME))
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.searchTerm = this.inputSearchTerm;
				if (this.activeTab === "search") {
					this.search(this.filterBy, this.filterParam1, this.filterParam2);
				}
			});
	}

	openTab(tab: TabName, rootParamMap?: ParamMap, paramMap?: ParamMap, isAnimate: boolean = true): void {
		if (tab === "navigate") {
			this.mainService.getCurrentWorkspace().then((workspaceView) => {
				this.getWorkspaceFolderFor(
					workspaceView.currentWorkspace.parentId
						? workspaceView.currentWorkspace.parentId
						: workspaceView.currentWorkspace.id,
				);
			});
		} else if (tab === "search") {
			this.favorites$ = from(
				this.workspaceService.getWorkspaces(
					"favorites",
					this.currentWorkspaceId,
					this.currentProcessVersion,
					this.filterBy,
					this.filterParam1,
					this.filterParam2,
				),
			).pipe(
				map((favorites) => {
					const favoriteSet = new Set<string>();
					favorites.forEach((favorite) => {
						if (favorite.favorite) {
							favoriteSet.add(favorite.id!);
						}
					});
					return favoriteSet;
				}),
			);

			this.search(this.filterBy, this.filterParam1, this.filterParam2);
		} else {
			this.workspaces$ = from(
				this.workspaceService.getWorkspaces(
					tab,
					this.currentWorkspaceId,
					this.currentProcessVersion,
					this.filterBy,
					this.filterParam1,
					this.filterParam2,
				),
			);
		}

		this.carousel.select(tab, isAnimate);
		this.activeTab = tab;

		if (!this.utilService.deviceIsIOS()) {
			this.input.nativeElement.focus();
		}

		if (this.activeTabPreferenceKey) {
			this.preferencesService.setPreference<Preference>(this.activeTabPreferenceKey, { tabName: tab });
		}
	}

	onSelectWorkspace(workspaceChanged: WorkspaceSelected): void {
		this.clearSearchTermForCarouselSelection();
		this.selectWorkspace.emit(workspaceChanged);
	}

	onNavigate(workspaceId: string): void {
		this.clearSearchTermForCarouselSelection();
		this.getWorkspaceFolderFor(workspaceId);
		if (!this.utilService.deviceIsIOS()) {
			this.input.nativeElement.focus();
		}
	}

	private clearSearchTermForCarouselSelection(): void {
		// Clear the search term, when the workspace is selected in the "Navigate" tab or on navigation up/down.
		// In the "Navigate" tab the search term is (in most cases) only be used to find a child and is not useful for further navigation.
		if (this.carousel.selection && this.carousel.selection.name === "navigate") {
			this.inputSearchTerm = this.searchTerm = "";
		}
	}

	onBookmark(workspace: Workspace): void {
		this.mainService
			.getApplication(this.route.root.firstChild!.snapshot.paramMap.get("processVersion")!)
			.then((application) => {
				this.workspaceService.toggleFavorite(
					application.userId,
					workspace,
					this.currentWorkspaceId,
					this.currentProcessVersion,
				);
			});
	}

	private search(filterBy?: string, filterParam1?: string, filterParam2?: string): void {
		if (this.searchTerm) {
			this.workspaces$ = combineLatest([
				from(
					this.workspaceService.searchWorkspaces(
						this.searchTerm,
						this.currentWorkspaceId,
						this.currentProcessVersion,
						filterBy,
						filterParam1,
						filterParam2,
					),
				),
				this.favorites$,
			]).pipe(
				map(([workspaces, favorites]) => {
					workspaces.forEach((workspace) => (workspace.favorite = favorites.has(workspace.id!)));
					return workspaces;
				}),
				catchError(() => {
					this.searchEngineAvailable = false;
					return EMPTY;
				}),
			);
		} else {
			this.workspaces$ = of([]);
			this.searchEngineAvailable = true;
		}
	}

	private getWorkspaceFolderFor(workspaceId: string): void {
		if (!this.folderRepository.hasItem(workspaceId)) {
			this.workspaceService
				.loadWorkspaceFolder(
					workspaceId,
					this.filterBy ? this.filterBy : null,
					this.filterParam1 ? this.filterParam1 : null,
					this.filterParam2 ? this.filterParam2 : null,
					this.currentWorkspaceId,
					this.currentProcessVersion,
				)
				.then(() => this.workspaceService.setActiveWorkspace(workspaceId));
		} else {
			this.workspaceService.setActiveWorkspace(workspaceId);
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.workspaceService.clearStore();
	}
}
