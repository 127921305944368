import { Directive, HostListener } from "@angular/core";

/**
 * Can be used, if ClickOutsideDirective does not work correctly (see comment there).
 */
@Directive({
	selector: "[stagesClickStopPropagation]",
})
export class ClickStopPropagationDirective {
	@HostListener("click", ["$event"])
	onClick(event: Event): void {
		event.stopPropagation();
	}
}
