<stages-dialog-header> </stages-dialog-header>
<h1 class="shorten" translate="process.compare.progress"></h1>
<ng-container *ngIf="paramMap$ | async as paramMap">
	<stages-common-progress
		[operationName]="'COMPARE_PROCESS'"
		[jobName]="paramMap.get('jobName')!"
		[workspaceId]="paramMap.get('workspaceId')!"
		[pv]="paramMap.get('processVersion')!"
		[messageKeyPrefix]="'process.compare'"
		[disableNextUntilDone]="true"
		(close)="close()"
		(next)="next()"
	>
	</stages-common-progress>
</ng-container>
