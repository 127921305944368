import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { ScrollService } from "common/routing/scroll.service";
import { MainService } from "core/main.service";
import { ToDoListDataSource } from "dashboard/to-do-list/to-do-list-data-source.service";

type ToDo = stages.dashboard.ToDo;
type ToDoGroup = stages.dashboard.ToDoGroup;

@Component({
	selector: "stages-to-do-list",
	templateUrl: "to-do-list.component.html",
	styleUrls: ["to-do-list.component.scss"],
})
export class ToDoListComponent implements OnInit, OnDestroy {
	ToDoListComponent = ToDoListComponent;
	dataSource!: PageableDataSource<ToDoGroup>;
	menuItems: MenuItem[] = [];

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mainService: MainService,
		private toDoListDataSource: ToDoListDataSource,
		private scrollService: ScrollService,
	) {}

	ngOnInit(): void {
		this.dataSource = this.toDoListDataSource.connect(this.route);

		this.menuItems = [
			{
				name: "dashboard.todolist.task.compare",
				iconClass: "ico ico-interface ico-exposed",
				disabled: (task: ToDo) => {
					return !task.referenceProcess || !task.actions.ShowChanges;
				},
				on: (task: ToDo) => {
					this.showChanges(task);
				},
			},
		];
	}

	ngOnDestroy(): void {
		if (this.dataSource) {
			this.dataSource.disconnect();
		}
	}

	openTask(task: ToDo): void {
		this.scrollService.setScrollPosition("keep");
		this.router.navigate(
			[
				{
					outlets: {
						popup: ["tasks", "task", task.id],
					},
				},
			],
			{ relativeTo: this.mainService.findPopupContainer(this.route) },
		);
	}

	showChanges(task: ToDo): void {
		this.scrollService.setScrollPosition("keep");
		this.router.navigate(
			[
				{
					outlets: {
						popup: ["tasks", "taskdiff", task.id],
					},
				},
			],
			{ relativeTo: this.mainService.findPopupContainer(this.route) },
		);
	}

	static getId(task: ToDo): string {
		return task.id;
	}

	static isDisabled(_task: ToDo): boolean {
		return false;
	}

	static getGroupId(taskGroup: ToDoGroup): string {
		return taskGroup.name;
	}

	static isGroupDisabled(_taskGroup: ToDoGroup): boolean {
		return false;
	}

	static getClasses(task: ToDo): string[] {
		return task.overdue ? ["overdue"] : [];
	}
}
