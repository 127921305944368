<header class="dialog-header">
	<div class="title">
		<ng-content></ng-content>
	</div>
	<ng-container *ngIf="languages">
		<div
			[class.action]="true"
			[class.language]="true"
			[class.selected]="isLanguageSelected(i)"
			*ngFor="let language of languages; index as i"
			(click)="selectLanguage(i)"
		>
			<div>{{ language.toUpperCase() }}</div>
		</div>
	</ng-container>
	<button
		[attr.title]="'close' | translate"
		[attr.aria-label]="'close' | translate"
		class="dialog-header-button"
		(click)="close()"
	>
		<i class="ico ico-close"></i>
	</button>
</header>
