import { NgModule } from "@angular/core";
import { StagesCommonModule } from "common/common.module";
import { FilesAddComponent } from "files/add/files-add.component";
import { FilesAuthDialogComponent } from "files/auth/files-auth-dialog.component";
import { FilesAuthEmbeddableComponent } from "files/auth/files-auth-embeddable.component";
import { FilesOAuthConfirmComponent } from "files/auth/files-oauth-confirm.component";
import { FilesOAuthEmbeddableConfirmComponent } from "files/auth/files-oauth-embeddable-confirm.component";
import { FilesOAuthSuccessComponent } from "files/auth/files-oauth-success.component";
import { CmsBrowserComponent } from "files/browser/cms-browser.component";
import { FilesCheckinComponent } from "files/checkin/files-checkin.component";
import { FilesCheckoutComponent } from "files/checkout/files-checkout.component";
import { DownloadAfterAuthenticationComponent } from "files/download-after-authentication/download-after-authentication.component";
import { FilesErrorComponent } from "files/error/files-error.component";
import { StagesLoadingErrorComponent } from "files/error/loading-error.component";
import { FilesLoadingErrorEmbeddableComponent } from "files/error/stages-loading-error-embeddable.component";
import { FilesRoutingModule } from "files/files-routing.module";
import { FilesComponent } from "files/files.component";
import { FiletypeValidatorDirective } from "files/filetype.directive";
import { MainComponent } from "files/main.component";
import { FilesPropertiesComponent } from "files/properties/files-properties.component";
import { FilesRevertComponent } from "files/revert/files-revert.component";
import { FilesStartDialogComponent } from "files/start/files-start-dialog.component";
import { FilesStartComponent } from "files/start/files-start.component";
import { FilesSetStateComponent } from "files/state/files-set-state.component";
import { FilesTemplateComponent } from "files/template/files-template.component";
import { FilesEditUrlComponent } from "files/url/files-edit-url.component";
import { FilesVersionsComponent } from "files/versions/files-versions.component";

@NgModule({
	imports: [StagesCommonModule, FilesRoutingModule],
	declarations: [
		MainComponent,
		FilesStartComponent,
		FilesStartDialogComponent,
		FilesComponent,
		FilesAddComponent,
		FilesCheckinComponent,
		FiletypeValidatorDirective,
		FilesCheckoutComponent,
		FilesEditUrlComponent,
		FilesSetStateComponent,
		FilesAuthDialogComponent,
		FilesAuthEmbeddableComponent,
		FilesPropertiesComponent,
		FilesVersionsComponent,
		FilesErrorComponent,
		CmsBrowserComponent,
		FilesRevertComponent,
		StagesLoadingErrorComponent,
		FilesTemplateComponent,
		FilesOAuthConfirmComponent,
		FilesOAuthEmbeddableConfirmComponent,
		FilesOAuthSuccessComponent,
		DownloadAfterAuthenticationComponent,
		FilesLoadingErrorEmbeddableComponent,
	],
	exports: [FilesComponent, FilesPropertiesComponent, FilesVersionsComponent],
})
export class StagesFilesModule {}
