import { Injectable } from "@angular/core";
import { ComplianceResource } from "core/stages-client";
import { RatingUpdate } from "process/compliance/compliance.to";

@Injectable({ providedIn: "root" })
export class ComplianceService {
	constructor(private readonly complianceResource: ComplianceResource) {}

	async updateRating(
		elementTypeIdent: string,
		elementId: string,
		processId: string,
		targetProcessId: string,
		workspaceId: string,
		pv: string,
		rating: string,
	): Promise<RatingUpdate> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.complianceResource.updateRating(workspaceId, elementTypeIdent, elementId, rating, {
			processId: processId,
			targetProcessId: targetProcessId,
		});
	}

	async updateComment(
		elementTypeIdent: string,
		elementId: string,
		processId: string,
		targetProcessId: string,
		workspaceId: string,
		pv: string,
		comment: string,
	): Promise<void> {
		this.complianceResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.complianceResource.updateComment(workspaceId, elementTypeIdent, elementId, comment, {
			processId: processId,
			targetProcessId: targetProcessId,
		});
	}
}
