import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
	selector: "[stagesFilename]",
	providers: [{ provide: NG_VALIDATORS, useExisting: FilenameValidatorDirective, multi: true }],
})
export class FilenameValidatorDirective implements Validator {
	validate(control: AbstractControl): ValidationErrors | null {
		return filenameValidator()(control);
	}
}

export function filenameValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!!control && (!control.value || control.value.substr(control.value.length - 1) === "/")) {
			return { filenameRequired: true };
		}
		return null;
	};
}
