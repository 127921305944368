import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { AssociationService } from "process/associations/association.service";
import { ModelAssociationStore } from "process/associations/model-association.store.logic";
import { AddService } from "process/element/add/add.service";
import { AssociationTargetLabelService } from "common/associations/association-target-label.service";

@Injectable({ providedIn: "root" })
export class ModelAssociationStoreResolver implements Resolve<ModelAssociationStore> {
	constructor(
		private viewService: ViewService,
		private associationService: AssociationService,
		private associationTargetLabelService: AssociationTargetLabelService,
		private elementAddService: AddService,
		private mainService: MainService,
	) {}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async resolve(): Promise<ModelAssociationStore> {
		return new ModelAssociationStore(
			this.viewService,
			this.associationService,
			this.associationTargetLabelService,
			this.elementAddService,
			this.mainService,
		);
	}
}
