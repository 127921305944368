import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { TargetWorkspace } from "common/associations/association-browser.interface";
import { BrowseService } from "core/browse.service";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";

type View = stages.process.View;
type Workspace = stages.workspace.Workspace;

type PartView = Partial<View>;

export interface ModelBrowseView extends stages.common.CombinedViewForBrowser {
	folder: stages.process.ProcessTreeItem;
	targetWorkspace: TargetWorkspace;
}

@Injectable({ providedIn: "root" })
export class ModelBrowseViewResolver implements Resolve<ModelBrowseView> {
	constructor(
		private browseService: BrowseService,
		private mainService: MainService,
		private viewService: ViewService,
	) {}

	private getEmptyFolder(route: ActivatedRouteSnapshot): stages.process.ProcessTreeItem {
		return {
			id: route.paramMap.get("browseWorkspaceId")!,
			identity: route.paramMap.get("browseIdentity")!,
			type: {
				ident: route.paramMap.get("browseType")!,
				subtypeIdent: "",
			},
			folder: {},
			sortKey: 0,
			guid: "",
			allowedOperations: {},
			isLeaf: true,
			validChildSubtypes: [],
			tailored: false,
			name: "",
			label: "",
		};
	}

	async resolve(route: ActivatedRouteSnapshot): Promise<ModelBrowseView> {
		const view = await this.resolveView(route);
		const folder =
			view.processView !== undefined
				? this.viewService.getOverviewElement(this.viewService.getSelf(view.processView))
				: this.getEmptyFolder(route);
		const browseView: ModelBrowseView = {
			element: folder,
			process: view.viewWorkspace,
			folder: folder,
			targetWorkspace: view.viewWorkspace,
		};
		return browseView;
	}

	async resolveView(route: ActivatedRouteSnapshot): Promise<PartView> {
		return this.mainService.getCurrentWorkspace().then(async (workspaceContext) => {
			if (
				route.paramMap.get("browseWorkspaceId") === workspaceContext.currentWorkspace.id &&
				!workspaceContext.currentWorkspace.viewedProcess
			) {
				return {
					viewWorkspace: workspaceContext.currentWorkspace,
				};
			}

			const relatedWorkspaces: Workspace[] = route.parent!.data.related ? route.parent!.data.related : null;

			return this.browseService.getView(
				route.paramMap.get("browseWorkspaceId")!,
				this.browseService.getBrowseProcessVersion(
					relatedWorkspaces,
					route.paramMap.get("browseWorkspaceId")!,
					route.paramMap.get("browseProcessVersion")!,
				),
				route.paramMap.get("browseType")!,
				route.paramMap.get("browseIdentity")!,
				route.paramMap.get("workspaceId")!,
				workspaceContext.currentWorkspace.viewedProcess &&
					workspaceContext.currentWorkspace.viewedProcess.workingVersion
					? "_wv"
					: route.paramMap.get("processVersion")!,
				undefined,
				false,
				route.data.filterByScopes,
			);
		});
	}
}
