import { Component, OnInit } from "@angular/core";
import { SlotService } from "common/slot.service";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { CardType } from "process/view/type.interface";

@Component({
	selector: "stages-card-dynamic",
	templateUrl: "./card-dynamic.component.html",
	styleUrls: ["./card-dynamic.component.scss"],
	providers: [SlotService],
})
export class CardDynamicComponent extends BaseComponent implements OnInit {
	title!: string;

	toolbarId!: string;

	menuId!: string;

	menuItems: MenuItem[] = [];

	menuNoCollapse?: boolean;

	contentCollapsible?: boolean;

	constructor(componentService: ComponentService, slotService: SlotService) {
		super(componentService, slotService);
	}

	ngOnInit(): void {
		const cardType = this.type as CardType;
		this.title = cardType.translate!;
		this.menuId = "menu_" + cardType.id;
		this.toolbarId = "toolbar_" + cardType.id;
		this.menuNoCollapse = !cardType.menuNoCollapse;
		this.contentCollapsible =
			cardType.contentCollapsible && JSON.parse(cardType.contentCollapsible) === false ? false : undefined;
	}
}
