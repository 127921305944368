<ng-template #buttonbar>
	<stages-buttons-bottom [removeMargin]="true">
		<button class="sm cancel" (click)="cancel()" translate="cancel"></button>
		<button class="sm save" [disabled]="!saveEnabled" (click)="save()">
			<span *ngIf="!isLoading" translate="save"></span>
			<stages-spin *ngIf="isLoading" size="s"></stages-spin>
		</button>
	</stages-buttons-bottom>
</ng-template>

<div
	class="description-overflow-container"
	[ngClass]="{ 'edit-off': !editorActive, collapsed: !expanded, expanded: expanded, alreadyLoaded: !firstLoad }"
>
	<div #descriptionSlideContainer [@expandCollapseFromTop]="animationParams">
		<article class="description module" [ngClass]="{ invisible: firstLoad && !expanded }">
			<p class="empty" *ngIf="!editorActive && isEmpty()" translate="none"></p>
			<stages-description-view
				*ngIf="!editorActive"
				[descriptionId]="descriptionId"
				[html]="displayDescription.html"
			></stages-description-view>
			<div *ngIf="editorActive && renderedDescription !== undefined">
				<ng-container *ngTemplateOutlet="buttonbar"></ng-container>
				<div class="description-spacing">
					<stages-description-editor
						[editorId]="editorId"
						[modifiable]="modifiable"
						[unsafe]="false"
						[description]="$any(renderedDescription)"
						[processTypeIdent]="$any(processTypeIdent)"
						[pluginRegistry]="pluginRegistry"
						[beanType]="beanType"
						[beanId]="beanId"
						[beanIdentity]="beanIdentity"
						[workspaceId]="workspaceId"
						[pv]="pv"
						[properties]="properties"
					>
					</stages-description-editor>
				</div>
				<ng-container *ngTemplateOutlet="buttonbar"></ng-container>
			</div>
		</article>
	</div>
</div>
