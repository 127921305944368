import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { ProcessVersionService } from "common/version/process-version.service";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	template: "",
})
export class TaskProcessDiffRedirectComponent implements OnDestroy {
	private subscription: Subscription;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mutexService: MutexService,
		private viewService: ViewService,
		private versionService: ProcessVersionService,
		private mainService: MainService,
	) {
		this.subscription = route.data.pipe(map((data) => data.task)).subscribe((task) => {
			if (!task.referenceProcess) {
				this.router.navigate(
					[
						{
							outlets: {
								popup: [
									"process",
									"compare",
									"unavailable"
								],
							},
						},
					],
					{
						relativeTo: this.mainService.findPopupContainer(this.route),
					},
				);
			} else if (task.referenceProcess.diffAvailable) {
				const commands: unknown[] = [
					"workspace",
					task.workspaceId,
					task.versionIdentifier,
					{
						swid: task.workspaceId,
						spv: task.referenceProcess.versionIdentifier,
						smode: "compare",
					},
					"process",
				];
				this.router.navigate(commands).then(() => {
					this.viewService.refreshView(task.workspaceId, task.versionIdentifier);
				});
			} else {
				this.mutexService.invoke("compare", async () =>
					this.versionService
						.diff(task.workspaceId, task.versionIdentifier, task.workspaceId, task.referenceProcess!.versionIdentifier)
						.then(async (jobIdentifier) => {
							return this.router.navigate(
								[
									{
										outlets: {
											popup: [
												"process",
												"compare",
												"progress",
												{
													jobName: jobIdentifier.name,
													compareWorkspace: task.workspaceId,
													compareVersion: task.referenceProcess!.versionIdentifier,
													specificProcessVersion: task.versionIdentifier,
													forceSameWorkspace: true,
												},
											],
										},
									},
								],
								{
									relativeTo: this.mainService.findPopupContainer(this.route),
								},
							);
						}),
				);
			}
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
