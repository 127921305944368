import { AfterViewInit, Component, ElementRef, HostBinding, Input, ViewChild } from "@angular/core";

@Component({
	selector: "stages-table-zoom",
	templateUrl: "./table-zoom.component.html",
})
export class TableZoomComponent implements AfterViewInit {
	@HostBinding("class.lg") dialogLgClass = true;
	@HostBinding("class.autoHeight") dialogAutoHeightClass = true;

	@Input()
	tableZoomId!: string;

	@ViewChild("spacer", { static: true })
	private spacerElement!: ElementRef;

	ngAfterViewInit(): void {
		const clonedTable = $("#" + this.tableZoomId).clone();
		clonedTable.css("display", "");
		clonedTable.insertBefore($(this.spacerElement.nativeElement));
	}
}
