<ng-container>
	<h1 *ngIf="headline" translate="{{ headline }}" class="dialog-title"></h1>
	<div class="options">
		<p class="empty" translate="{{ translateNone }}" *ngIf="!startOptions.length && translateNone"></p>

		<div class="wrapper" *ngIf="startOptions.length">
			<article class="option" [ngClass]="option.classes" *ngFor="let option of startOptions">
				<header>
					<div class="wrapper">
						<h5 translate="{{ option.title }}"></h5>
						<i class="{{ option.icon }}"></i>
					</div>
				</header>
				<p translate="{{ option.message }}"></p>
				<footer>
					<button
						class="button lg"
						(click)="option.on()"
						translate="{{ option.button }}"
						[disabled]="option.disabled"
					></button>
				</footer>
			</article>
		</div>
	</div>
</ng-container>
