import { Component } from "@angular/core";
import { CompareToDialogComponent } from "process/compare/compare-to-dialog.component";

@Component({
	templateUrl: "./compare-to-unavailable.component.html",
})
export class CompareToUnavailableComponent {

	constructor(private compareToDialogComponent: CompareToDialogComponent) {
	}

	close(): void {
		this.compareToDialogComponent.dialog.close();
	}
}
