import { Injectable } from "@angular/core";
import { Association } from "common/associations/association-list.component";
import { AssocSearchResult } from "common/associations/search-query.logic";
import { EnactmentAssociationResource, EnactmentElementResource, SearchQueryService } from "core/stages-client";
import { Tailorable } from "management/tailoring/associations/tailoring-association-store.logic";

type TypedId = stages.core.TypedId;
type TypedIdentity = stages.core.TypedIdentity;
type TargetElement = stages.common.AssociationTarget;

@Injectable({ providedIn: "root" })
export class EnactmentAssociationService {
	constructor(
		private readonly enactmentAssociationResource: EnactmentAssociationResource,
		private readonly enactmentElementResource: EnactmentElementResource,
		private readonly searchQueryService: SearchQueryService,
	) {}

	async deleteAssociations(associations: Association<TargetElement>[], workspaceId: string, pv: string): Promise<void> {
		const ids = associations.map((association) => association.id);
		return this.enactmentAssociationResource.deleteAssociation(workspaceId, { associationIds: ids });
	}

	async getTree(browseWorkspaceId: string, elementTypes: string[]): Promise<stages.process.TreeElement[]> {
		const result = [];
		if (elementTypes.length > 0) {
			const trees = await this.enactmentElementResource.getTree(browseWorkspaceId, elementTypes[0], {
				elementTypes: elementTypes,
			});
			result.push(...trees);
		}
		return result;
	}

	async searchProcessElements(
		query: string,
		targetElementType: string,
		targetElementSubtypes: string[] | undefined,
		targetElementIdentities: TypedIdentity[],
		currentWorkspaceId: string,
		pv: string,
		targetProcessIds?: string[],
	): Promise<AssocSearchResult[]> {
		this.searchQueryService.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.searchQueryService.findAssociationTargetsInSelectedProcesses(targetElementIdentities, {
			query: query,
			type: targetElementType,
			subtypes: targetElementSubtypes,
			workspaceId: currentWorkspaceId,
			pv: pv,
			processIds: targetProcessIds,
			documentTypes: ["enactment"],
			searchRelatedWorkspaces: false,
		}) as Promise<AssocSearchResult[]>;
	}

	async createAssociation(
		enactmentElementType: string,
		enactmentElementId: string,
		enactableType: string,
		enactableId: string,
		associationSubtype: string,
		sourceRole: string | undefined,
		workspaceId: string,
		pv: string,
	): Promise<stages.common.Association<stages.common.AssociationTarget>> {
		return this.enactmentAssociationResource.addAssociation(
			workspaceId,
			enactmentElementType,
			enactmentElementId,
			enactableType,
			enactableId,
			{
				pv: pv,
				enactmentElementRole: sourceRole,
			},
		);
	}

	hasTargetSubtype(element: Tailorable, targetSubtypes?: string[]): boolean {
		if (!targetSubtypes || targetSubtypes.length === 0) {
			return true;
		}
		return !!element.type.subtypeIdent && targetSubtypes.indexOf(element.type.subtypeIdent) > -1;
	}

	findMatchingAssociation(
		associations: Association<Tailorable>[],
		expectedTargetElement: Tailorable,
	): Association<Tailorable> | null {
		for (const assoc of associations) {
			if (assoc.targetElement && assoc.targetElement.id === expectedTargetElement.id) {
				return assoc;
			}
		}
		return null;
	}

	selectable(
		association: Association<Tailorable> | null,
		element: Tailorable,
		targetSubtypes: string[] | undefined,
	): boolean {
		return this.hasTargetSubtype(element, targetSubtypes) && (!association || association.actions!.DELETE);
	}

	async applyAssociationModifications(
		enactableType: string,
		enactableId: string,
		groupPath: string,
		added: TypedId[],
		removed: TypedId[],
		workspaceId: string,
		pv: string,
	): Promise<void> {
		return this.enactmentAssociationResource.updateEnactmentAssociations(
			workspaceId,
			enactableType,
			enactableId,
			groupPath,
			{
				added: added,
				removed: removed,
				modified: [],
			},
		);
	}

	async addChild(
		parentId: string,
		targetType: string,
		subtype: string | null,
		name: string,
		workspaceId: string,
		pv: string,
	): Promise<NavigationEntry<stages.core.metamodel.NamedType>> {
		// TODO: Use generic addChild method in EnactmentAssociationResource instead of
		// specific method in EnactmentActivatorResource
		this.enactmentElementResource.setAdditionalHttpParamsForNextRequest({
			pv: pv,
		});
		return this.enactmentElementResource
			.create(workspaceId, targetType, {
				name: name,
				parentId: parentId,
				subtype: subtype,
			})
			.then((newChild) => {
				return newChild;
			});
	}
}
