import { Component, ViewChild } from "@angular/core";
import { NgForm, ValidationErrors } from "@angular/forms";
import { MutexService } from "common/concurrency/mutex.service";
import { FormService } from "common/form/form.service";

export abstract class ClassInterfaceUsedByEditNameComponent {
	abstract readonly actionKey: string;
	abstract readonly nameLabelKey: string;
	abstract readonly namePattern: string;
	abstract readonly patternError: string;
	abstract readonly existsError: string;
	abstract readonly systemError: string;
	abstract readonly explanationKey?: string;
	abstract explanationKeyParams?: StringToString;

	abstract name: string;

	abstract close(): void;
	abstract save(): Promise<unknown>;
}

@Component({
	selector: "stages-edit-name",
	templateUrl: "edit-name.component.html",
	styleUrls: ["./edit-name.component.scss"],
})
export class EditNameComponent {
	@ViewChild("form", { static: true })
	form!: NgForm;

	serverSideValidationErrors: ValidationErrors = {};

	private readonly mutexId = String(new Date().getTime());

	constructor(
		readonly sc: ClassInterfaceUsedByEditNameComponent,
		private readonly mutexService: MutexService,
		private readonly formService: FormService,
	) {}

	inputChanged(): void {
		this.serverSideValidationErrors = {};
	}

	save(): void {
		if (this.form.valid) {
			this.mutexService.invoke(this.mutexId, async () => {
				try {
					await this.sc.save();
					// eslint-disable-next-line @typescript-eslint/no-implicit-any-catch -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32477: Use "unknown" on "catch" clauses in TS files
				} catch (error) {
					this.formService.populateValidationErrorsOrThrow(this.serverSideValidationErrors, error);
				}
			});
		}
	}
}
