import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ProcessElementsResource } from "core/stages-client";
import { ViewService } from "core/view.service";
import { ProcessComplianceAssociationStore } from "process/compliance/associations/process-compliance-association-store.logic";
import { ProcessComplianceAssociationService } from "process/compliance/associations/process-compliance-association.service";

@Injectable({ providedIn: "root" })
export class ProcessComplianceAssociationStoreResolver implements Resolve<ProcessComplianceAssociationStore> {
	constructor(
		private viewService: ViewService,
		private associationService: ProcessComplianceAssociationService,
		private processElementsResource: ProcessElementsResource,
	) {}

	// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	async resolve(): Promise<ProcessComplianceAssociationStore> {
		return new ProcessComplianceAssociationStore(
			this.viewService,
			this.associationService,
			this.processElementsResource,
		);
	}
}
