import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Observable } from "rxjs";

@Component({
	selector: "stages-error-message",
	templateUrl: "error-message.component.html",
})
export class ErrorMessageComponent {
	paramMap$: Observable<ParamMap>;

	constructor(route: ActivatedRoute) {
		this.paramMap$ = route.paramMap;
	}
}
