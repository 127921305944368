import { Component, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ViewService } from "core/view.service";
import { ElementCommentService } from "process/comments/element-comment.service";
import { Subscription } from "rxjs";

type Element = stages.process.ViewableElement;
type Comment = stages.process.ViewableComment;

@Component({
	selector: "stages-element-comment",
	templateUrl: "./element-comment.component.html",
})
export class ElementCommentComponent implements OnDestroy {
	private subscription: Subscription;

	editCommentValue!: string;
	newCommentText!: string;
	currentElement!: Element;
	pageSize!: number;
	showButton = false;
	viewLoaded = false;
	commentMaxLength: string = "1000";
	editOn?: string;

	group: FormGroup;

	constructor(
		private elementCommentService: ElementCommentService,
		private route: ActivatedRoute,
		private viewService: ViewService,
	) {
		this.subscription = this.viewService.awaitSelfElementObservable().subscribe((view) => {
			this.currentElement = view as unknown as Element;
			this.pageSize = this.currentElement.comments !== undefined ? this.currentElement.comments.items.length : 0;
			this.viewLoaded = true;
		});

		this.group = new FormGroup({ newComment: new FormControl(this.newCommentText, [Validators.maxLength(1000)]) });
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	getMenuItems(comment: Comment): MenuItem[] {
		return [
			{
				name: "edit",
				iconClass: "ico ico-edit",
				disabled: () => {
					return !this.isAllowed(comment, "MODIFY");
				},
				on: () => {
					this.setEditOn(comment.id, comment.value);
				},
			},
			{
				name: "delete",
				iconClass: "ico ico-delete",
				disabled: () => {
					return !this.isAllowed(comment, "DELETE");
				},
				on: () => {
					this.elementCommentService
						.deleteComment(this.currentElement, comment, this.route.snapshot.paramMap.get("workspaceId")!)
						.then(() => {
							if (
								this.currentElement &&
								this.currentElement.comments &&
								this.currentElement.comments.items.length < this.pageSize
							) {
								this.loadOneMore();
							}
						});
				},
			},
		];
	}

	//edit

	hasComments(): boolean {
		const items = this.currentElement.comments?.items;
		return items !== undefined && items.length > 0;
	}

	getItems(): stages.process.ViewableComment[] | undefined {
		if (this.currentElement.comments) {
			return this.currentElement.comments.items;
		}
		return this.currentElement.comments;
	}

	canCreateComments(): boolean {
		return this.currentElement.allowedOperations.CREATE_COMMENT;
	}

	isEditOn(id: string): boolean {
		return "comment" + id === this.editOn;
	}

	setEditOn(id: string, value: string): void {
		this.editOn = "comment" + id;
		const editComment = value;
		this.group.addControl("comment" + id, new FormControl(editComment, [Validators.maxLength(1000)]));
	}

	cancelEdit(id: string): void {
		this.editOn = undefined;
		this.group.removeControl("comment" + id);
	}

	saveEditExistingComment(comment: Comment, controlName: string): void {
		const control = this.group.get(controlName) as FormControl;
		comment.value = control.value;
		this.elementCommentService.saveComment(
			this.currentElement,
			comment,
			this.route.snapshot.paramMap.get("workspaceId")!,
		);
		this.cancelEdit(comment.id);
	}

	//add new comment

	addComment(): void {
		const control = this.group.get("newComment") as FormControl;
		this.elementCommentService.addComment(
			this.currentElement,
			control.value,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
		);
		this.resetTextarea();
	}

	resetTextarea(): void {
		this.setShowButton(false);
		this.group.controls.newComment.reset("");
	}

	setShowButton(input: boolean): void {
		this.showButton = input;
	}

	isSubmitAllowed(controlName: string): boolean {
		return this.isCommentTextAllowed(controlName) && this.getCommentLength(controlName) <= 1000;
	}

	getCommentCountMessageKey(): string {
		return this.getTotalCommentCount() === 1
			? "process.element.comments.commentCountSingular"
			: "process.element.comments.commentCountPlural";
	}

	getTotalCommentCount(): number {
		if (this.currentElement.comments) {
			return this.currentElement.comments.totalItemCount;
		}
		return 0;
	}

	isCommentTextAllowed(controlName: string): boolean {
		const control = this.group.get(controlName) as FormControl;
		if (control.value && control.value.trim() !== "") {
			return true;
		}
		return false;
	}

	isAllowed(comment: Comment, op: string): boolean {
		return comment.allowedOperations && comment.allowedOperations[op];
	}

	//display textarea info

	getCommentLengthInfo(name: string): string {
		return this.getCommentLength(name).toString() + "/" + this.commentMaxLength;
	}

	getCommentLength(name: string): number {
		const control = this.group.get(name) as FormControl;
		return control.value !== null ? control.value.length : 0;
	}

	hasErrors(name: string): boolean {
		const control = this.group.get(name);

		if (!control) {
			throw new Error("Could not getcontrol from formGroup");
		}

		if (control.errors) {
			return true;
		}

		return false;
	}

	getErrors(name: string): string[] {
		const control = this.group.get(name);
		const keys = control && control.errors ? Object.keys(control.errors) : [];

		return keys;
	}

	//load comments

	isShowMoreButtonDisplayed(): boolean {
		if (this.currentElement.comments) {
			const commentsItemsLength = this.currentElement.comments.items.length;
			const commentsTotalItemCount = this.currentElement.comments.totalItemCount;
			return commentsItemsLength < commentsTotalItemCount;
		}
		return false;
	}

	async loadMore(): Promise<void> {
		const alreadyLoadedComments =
			this.currentElement.comments !== undefined ? this.currentElement.comments.items.length : 0;
		return this.loadComments(alreadyLoadedComments, alreadyLoadedComments + this.pageSize);
	}

	async loadOneMore(): Promise<void> {
		const alreadyLoadedComments =
			this.currentElement.comments !== undefined ? this.currentElement.comments.items.length : 0;
		return this.loadComments(alreadyLoadedComments, alreadyLoadedComments + 1);
	}

	private async loadComments(begin: number, end: number): Promise<void> {
		return this.elementCommentService
			.loadMoreComments(
				this.currentElement,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				begin.toString(),
				end.toString(),
			)
			.then((view) => {
				const element = this.viewService.getSelf(view.processView) as Element;

				if (this.currentElement.comments && element.comments) {
					element.comments.items.forEach((comment: Comment) => {
						if (this.currentElement.comments) {
							this.currentElement.comments.items.push(comment);
						}
					});

					this.currentElement.comments.totalItemCount = element.comments.totalItemCount;
				}
			});
	}
}
