import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FolderRepository } from "common/workspace/folder.repository";
import { assertNever } from "core/functions";
import { UserResource, WorkspacesResource } from "core/stages-client";

export type TabName = "archived" | "favorites" | "modules" | "navigate" | "related" | "search";

type Workspace = stages.workspace.Workspace;
type Folder = stages.workspace.Folder;

@Injectable({ providedIn: "root" })
export class WorkspaceService {
	constructor(
		private readonly translateService: TranslateService,
		private readonly folderRepository: FolderRepository,
		private readonly workspacesResource: WorkspacesResource,
		private readonly userResource: UserResource,
	) {}

	async getWorkspaces(
		tabName: "archived" | "favorites" | "modules" | "related",
		currentWorkspaceId: string,
		pv: string,
		filterBy?: string,
		filterParam1?: string,
		filterParam2?: string,
	): Promise<Workspace[]> {
		switch (tabName) {
			case "favorites": {
				return this.workspacesResource.getFavoriteWorkspaces({
					filterBy: filterBy,
					filterParam1: filterParam1,
					filterParam2: filterParam2,
					workspaceId: currentWorkspaceId,
					pv: pv,
				});
			}
			case "archived": {
				return this.workspacesResource.getArchivedWorkspaces({
					filterBy: filterBy,
					filterParam1: filterParam1,
					filterParam2: filterParam2,
					workspaceId: currentWorkspaceId,
					pv: pv,
				});
			}
			case "related": {
				return this.workspacesResource.getRelatedWorkspaces({
					filterBy: filterBy,
					filterParam1: filterParam1,
					filterParam2: filterParam2,
					workspaceId: currentWorkspaceId,
					pv: pv,
				});
			}
			case "modules": {
				return this.workspacesResource.getModuleWorkspaces({
					filterBy: filterBy,
					filterParam1: filterParam1,
					filterParam2: filterParam2,
					workspaceId: currentWorkspaceId,
					pv: pv,
				});
			}
			default:
				return assertNever(tabName);
		}
	}

	async searchWorkspaces(
		term: string,
		currentWorkspaceId: string,
		pv: string,
		filterBy?: string,
		filterParam1?: string,
		filterParam2?: string,
	): Promise<Workspace[]> {
		return this.workspacesResource.searchWorkspaces({
			query: term,
			workspaceId: currentWorkspaceId,
			pv: pv,
			filterBy: filterBy,
			filterParam1: filterParam1,
			filterParam2: filterParam2,
		});
	}

	async toggleFavorite(
		currentUserId: string,
		workspace: Workspace,
		currentWorkspaceId: string,
		pv: string,
	): Promise<Workspace[]> {
		this.userResource.setAdditionalHttpParamsForNextRequest({
			workspaceId: currentWorkspaceId,
			pv: pv,
		});
		if (workspace.favorite) {
			return this.userResource.removeFavorite(currentUserId, workspace.id!).then((newFavorites) => {
				workspace.favorite = false;
				return newFavorites;
			});
		} else {
			return this.userResource.addFavorite(currentUserId, workspace.id!).then((newFavorites: Workspace[]) => {
				workspace.favorite = true;
				return newFavorites;
			});
		}
	}

	getFilteredWorkspaceName(workspace: stages.administration.usergroup.permission.Workspace): string {
		return workspace.hidden ? this.translateService.instant(workspace.name) : workspace.name;
	}

	async loadWorkspaceFolder(
		folderId: string,
		filterBy: string | null,
		filterParam1: string | null,
		filterParam2: string | null,
		currentWorkspaceId: string,
		pv: string,
	): Promise<Folder> {
		const promise = this.workspacesResource.getWorkspaceTreeFolder(folderId, {
			filterBy: filterBy,
			filterParam1: filterParam1,
			filterParam2: filterParam2,
			workspaceId: currentWorkspaceId,
			pv: pv,
		});
		promise.then((folder) => this.folderRepository.add(folder));
		return promise;
	}

	setActiveWorkspace(workspaceId: string): void {
		this.folderRepository.setActive(workspaceId);
	}

	clearStore(): void {
		this.folderRepository.reset();
	}
}
