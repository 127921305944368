import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-navigation-entry-change-marker",
	templateUrl: "./change-marker-navigation-entry.component.html",
	styleUrls: ["./change-marker-navigation-entry.component.scss"],
})
export class ChangeMarkerNavigationEntryComponent {
	@Input()
	entry!: ChangeMarkerEntry;

	@Input()
	mode?: NAV_ENTRY_DISPLAY_MODE;

	get originalHasChangeMarker(): boolean {
		const original = this.entry.original;
		return (
			original.changeMarker &&
			(original.changeMarker.subelements > 0 ||
				original.changeMarker.changed ||
				original.changeMarker.added ||
				original.changeMarker.deleted)
		);
	}
}
