<!-- Table View -->
<article class="files" *ngIf="fileContainer !== undefined && layout === 'table'">
	<p
		class="empty"
		translate="{{ translateNone }}"
		*ngIf="(!fileContainer.files || !fileContainer.files.length) && !isUploadAllowed() && !showStartOptions"
	></p>
	<table class="table dense" *ngIf="fileContainer.files && fileContainer.files.length">
		<colgroup>
			<col class="name-col" />
			<col class="small-icon-col" />
			<col />
			<col />
			<col />
			<col class="action-col" />
		</colgroup>
		<thead>
			<tr>
				<th translate="files.filetable.columnheader.name"></th>
				<th class="icon-cell"><i class="ico ico-lock"></i></th>
				<th translate="files.filetable.columnheader.author"></th>
				<th translate="files.filetable.columnheader.lastChange"></th>
				<th translate="files.filetable.columnheader.state"></th>
				<th
					*ngIf="someChangeMarker(fileContainer.files)"
					class="change-marker"
					translate="files.filetable.columnheader.modification"
				></th>
				<th class="warning"></th>
				<th class="action"></th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let file of this.fileContainer.files"
				[ngClass]="{ disabled: isDisabled(file), 'new-item': file.recentlyAdded, 'add-item': isRenamed(file) }"
			>
				<td>
					<form #form="ngForm" class="form-add-item icon-name" novalidate *ngIf="isRenamed(file)">
						<ng-container *ngTemplateOutlet="fileRenameInput; context: { $implicit: file }"></ng-container>
					</form>
					<ng-container *ngTemplateOutlet="fileTableLink; context: { $implicit: file }"></ng-container>
				</td>
				<td [ngClass]="{ fixed: file.frozenVersion }">
					<ng-container *ngTemplateOutlet="fileStatusIcons; context: { $implicit: file }"></ng-container>
				</td>
				<td>
					<span *ngIf="!!file.user && !!file.user.fullname">{{ file.user?.fullname }}</span>
				</td>
				<td>
					<span *ngIf="!!file.date">{{ file.date }}</span>
				</td>
				<td>
					<div [ngClass]="getFileStateClasses(file)">
						<span class="state-icon"></span>
						<ng-container *ngTemplateOutlet="fileStateLabel; context: { $implicit: file }"></ng-container>
					</div>
				</td>
				<td *ngIf="someChangeMarker(fileContainer.files)" class="change-marker">
					<ng-container *ngTemplateOutlet="changeMarker; context: { $implicit: file }"></ng-container>
				</td>
				<td class="warning">
					<button
						[attr.title]="'error' | translate"
						[attr.aria-label]="'error' | translate"
						class="warning-button"
						*ngIf="showWarning(file)"
						(click)="onError(file)"
					>
						<i class="ico ico-warning warning-color"></i>
					</button>
				</td>
				<td class="action progress-img">
					<ng-container *ngTemplateOutlet="fileMenu; context: { $implicit: file }"></ng-container>
					<div>
						<stages-spin *ngIf="isShowProgressBar(file)" size="sm"></stages-spin>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<ng-container *ngTemplateOutlet="fileWidgetFooter"></ng-container>
</article>

<!-- List View -->
<article class="files" *ngIf="fileContainer !== undefined && layout === 'list'">
	<p
		class="empty"
		translate="{{ translateNone }}"
		*ngIf="(!fileContainer.files || !fileContainer.files.length) && !isUploadAllowed() && !showStartOptions"
	></p>

	<ul class="list dense" *ngIf="fileContainer.files && fileContainer.files.length">
		<li
			class="list-item"
			*ngFor="let file of fileContainer.files"
			[ngClass]="{ disabled: isDisabled(file), 'new-item': file.recentlyAdded }"
		>
			<form #form="ngForm" class="form-add-item label" novalidate *ngIf="isRenamed(file)">
				<ng-container *ngTemplateOutlet="fileRenameInput; context: { $implicit: file }"></ng-container>
			</form>
			<ng-container *ngTemplateOutlet="fileListLink; context: { $implicit: file }"></ng-container>
			<div class="action" *ngIf="file.hasError">
				<button [attr.title]="'error' | translate" [attr.aria-label]="'error' | translate" (click)="onError(file)">
					<i class="ico ico-warning warning-color"></i>
				</button>
			</div>
			<div class="action progress-img" *ngIf="isShowProgressBar(file)">
				<stages-spin size="sm"></stages-spin>
			</div>
			<ng-container *ngTemplateOutlet="fileMenu; context: { $implicit: file }"></ng-container>
		</li>
	</ul>
	<ng-container *ngTemplateOutlet="fileWidgetFooter"></ng-container>
</article>

<ng-template #fileRenameInput let-file>
	<i [ngClass]="getIconClasses(file)"></i>
	<div style="width: 100%">
		<h4>
			<input
				#nameControl="ngModel"
				id="name"
				name="name"
				type="text"
				[stagesMaxLength]="255"
				required
				[(ngModel)]="renameName"
				(stagesKeyUp)="onKeyUp($event, file)"
				(blur)="onRenameBlur()"
				stagesAutofocus
			/>
		</h4>
		<div class="info">
			<div class="errors" *ngIf="nameControl.errors && (nameControl.dirty || nameControl.touched)">
				<div *ngIf="nameControl?.errors?.required" class="error" role="alert" translate="input.required"></div>
				<div
					*ngIf="nameControl?.errors?.max"
					class="error"
					role="alert"
					translate="input.maxLength"
					[translateParams]="{ length: 255 }"
				></div>
			</div>
			<div class="max-length">{{ nameControl.value?.length || 0 }}/255</div>
		</div>
	</div>
</ng-template>

<ng-template #fileTableLink let-file>
	<div *ngIf="isDisabled(file) && !isRenamed(file)" (contextmenu)="onRightClick($event, file)" class="icon-name">
		<ng-container *ngTemplateOutlet="fileTableLinkContent; context: { $implicit: file }"></ng-container>
	</div>
	<a
		*ngIf="!isDisabled(file) && !isRenamed(file) && !isURL(file)"
		draggable="true"
		(click)="onLeftClick(file)"
		(contextmenu)="onRightClick($event, file)"
		class="icon-name clickable"
		(dragstart)="onDragStart($event, file)"
	>
		<ng-container *ngTemplateOutlet="fileTableLinkContent; context: { $implicit: file }"></ng-container>
	</a>
	<a
		*ngIf="!isDisabled(file) && !isRenamed(file) && isURL(file)"
		draggable="true"
		[attr.target]="getTarget(file)"
		[attr.rel]="getTargetRelation(file)"
		[attr.href]="getUrlWithoutUnsafeForNonHttp(file)"
		(contextmenu)="onRightClick($event, file)"
		class="icon-name clickable"
		(dragstart)="onDragStart($event, file)"
	>
		<ng-container *ngTemplateOutlet="fileTableLinkContent; context: { $implicit: file }"></ng-container>
	</a>
</ng-template>

<ng-template #fileTableLinkContent let-file>
	<i [ngClass]="getIconClasses(file)"></i>
	<h4 title="{{ file.url }}">{{ file.name }}</h4>
</ng-template>

<ng-template #fileListLink let-file>
	<div *ngIf="isDisabled(file) && !isRenamed(file)" class="label" (contextmenu)="onRightClick($event, file)">
		<ng-container *ngTemplateOutlet="fileListLinkContent; context: { $implicit: file }"></ng-container>
	</div>
	<a
		*ngIf="!isDisabled(file) && !isRenamed(file) && !isURL(file)"
		class="label clickable"
		draggable="true"
		(click)="onLeftClick(file)"
		(contextmenu)="onRightClick($event, file)"
		(draggable)="(true)"
		(dragstart)="onDragStart($event, file)"
	>
		<ng-container *ngTemplateOutlet="fileListLinkContent; context: { $implicit: file }"></ng-container>
	</a>
	<a
		*ngIf="!isDisabled(file) && !isRenamed(file) && isURL(file)"
		class="label clickable"
		draggable="true"
		[attr.target]="getTarget(file)"
		[attr.rel]="getTargetRelation(file)"
		[attr.href]="getUrlWithoutUnsafeForNonHttp(file)"
		(contextmenu)="onRightClick($event, file)"
		(draggable)="(true)"
		(dragstart)="onDragStart($event, file)"
	>
		<ng-container *ngTemplateOutlet="fileListLinkContent; context: { $implicit: file }"></ng-container>
	</a>
</ng-template>

<ng-template #fileListLinkContent let-file>
	<i [ngClass]="getIconClasses(file)"></i>
	<div class="text">
		<h4>{{ file.name }}</h4>
		<p *ngIf="isUserAndDateKnown(file)">
			<ng-container *ngTemplateOutlet="fileStatusIcons; context: { $implicit: file }"></ng-container>
			{{ file.user?.fullname }} - {{ file.date }}
		</p>
		<p *ngIf="isOnlyUserKnown(file)">
			<ng-container *ngTemplateOutlet="fileStatusIcons; context: { $implicit: file }"></ng-container>
			{{ file.user?.fullname }}
		</p>
		<p [ngClass]="getFileStateClasses(file)">
			<ng-container *ngTemplateOutlet="fileStateLabel; context: { $implicit: file }"></ng-container>
		</p>
		<p *ngIf="file.changeMarker">
			<ng-container *ngTemplateOutlet="changeMarker; context: { $implicit: file }"></ng-container>
		</p>
	</div>
</ng-template>

<ng-template #fileStateLabel let-file>
	<span class="state-name">{{ file.stateMessageKey ? (file.stateMessageKey | translate) : file.stateIdent }}</span>
</ng-template>

<ng-template #fileStatusIcons let-file>
	<i class="ico ico-lock" *ngIf="file.locked && !file.frozenVersion"></i>
	<i class="ico ico-pin" *ngIf="file.frozenVersion"></i>
</ng-template>

<ng-template #fileMenu let-file>
	<stages-menu
		*ngIf="!isShowProgressBar(file)"
		menuId="fam{{ file.id }}"
		[noCollapse]="true"
		class="action"
		iconClasses="ico ico-more-vertical"
		openTo="left"
		[items]="menuItems"
		[menuTitle]="$any(!!file.frozenVersion ? fixedVersionMenuTitle.title : null)"
		[subtitle]="$any(!!file.frozenVersion ? fixedVersionMenuTitle.subtitle : null)"
		titleIconClass="ico-pin"
		[titleParams]="[file.frozenVersion]"
		[context]="file"
	>
	</stages-menu>
</ng-template>

<ng-template #fileWidgetFooter>
	<stages-upload
		*ngIf="isUploadAllowed()"
		uploadIcon="ico ico-file"
		[uploadCompletedCallback]="uploadCompletedCallback"
		[filesDroppedCallback]="filesDroppedCallback"
		[uploadUrl]="uploadUrl"
		[multiple]="true"
	>
	</stages-upload>
	<stages-files-start
		[startOptions]="$any(startOptions)"
		translate-none="files.start.options.none"
		*ngIf="showStartOptions && !(!!fileContainer.files && fileContainer.files.length)"
	></stages-files-start>
</ng-template>

<ng-template #changeMarker let-file>
	<ng-container *ngIf="file.changeMarker">
		<span *ngIf="file.changeMarker.added" class="badge added-badge" translate="process.compare.added"></span>
		<span *ngIf="file.changeMarker.deleted" class="badge deleted-badge" translate="process.compare.deleted"></span>
		<span *ngIf="file.changeMarker.changed" class="badge changed-badge" translate="process.compare.changed"></span>
	</ng-container>
</ng-template>
