import { Component, OnDestroy, OnInit, TrackByFunction, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MutexService } from "common/concurrency/mutex.service";
import { NewDialogComponent } from "common/newdialog/dialog.component";
import { Selection, SelectionService } from "common/selection.service";
import { SortEvent, SortOrder, TableCell, TableColumn, TableRow } from "common/tableViewer/table-viewer.component";
import { TranslateChoiceService } from "core/translate/translate-choice.service";
import { ViewService } from "core/view.service";
import { CmsBrowserService } from "files/browser/cms-browser.service";
import { filenameValidator } from "files/browser/filename-validator.directive";
import { FileService } from "files/files.service";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

type LinkFileSpec = stages.file.LinkFileSpec;
type BrowserRepository = stages.management.files.browser.BrowserRepository;
type BrowserItem = stages.management.files.browser.BrowserItem;

@Component({
	selector: "stages-cms-browser",
	templateUrl: "cms-browser.component.html",
})
export class CmsBrowserComponent implements OnInit, OnDestroy {
	repositories$!: Observable<BrowserRepository[]>;
	isRepositorySelectionActive = false;
	activeRepository!: BrowserRepository;
	activePath: string | null;
	activeItem!: BrowserItem;
	selectionInThisFolder!: Selection<BrowserItem>;
	selectionSize = 0;
	saveInProgress = false;

	private readonly selectionsForAllFolders = new Map();
	private sortKey = "name";
	private sortOrder = SortOrder.Ascending;
	/*
	 * Currently, single file mode in the CMS browser is not used. It will be needed when the CMS browser is used in the "File Properties" dialog
	 * and in the CM plan.
	 */
	/*
	 * The onSave method was resolved while navigating to this component.
	 * For ease it is implemented in the component itself now.
	 * @Input()
	 * private readonly onSave: Function;*/
	private destroy$ = new Subject<boolean>();
	/*
	 * Currently, single file mode in the CMS browser is not used. It will be needed when the CMS browser is used in the "File Properties" dialog
	 * and in the CM plan.
	 */
	private readonly singleFile: boolean = false;

	@ViewChild("dialog")
	dialog!: NewDialogComponent;

	readonly tableColumns: TableColumn[] = [
		{
			name: "name",
			columnClass: "name-col",
			sortable: true,
			initialSortOrder: SortOrder.Ascending,
		},
		{
			name: "changed",
			sortable: true,
			initialSortOrder: SortOrder.Undefined,
			optional: true,
		},
	];

	@ViewChild("form")
	form!: NgForm;

	constructor(
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private cmsBrowserService: CmsBrowserService,
		private selectionService: SelectionService,
		private translateChoiceService: TranslateChoiceService,
		private mutexService: MutexService,
		private fileService: FileService,
		private viewService: ViewService,
	) {
		this.activePath = CmsBrowserService.SEPARATOR;
	}

	ngOnInit(): void {
		this.repositories$ = this.route.data.pipe(map((data) => data.browserRepositories)).pipe(takeUntil(this.destroy$));
		this.repositories$.subscribe((repositories) => {
			// The CMS Browser may not have a suggested repository if the suggested repository is the database
			const suggestedRepositories = repositories.filter((r) => r.suggested);
			this.activeRepository = suggestedRepositories.length === 1 ? suggestedRepositories[0] : repositories[0];

			this.activePath = this.activeRepository.suggestedPath;
			this.refresh();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	trackByRepositoryId: TrackByFunction<BrowserRepository> = (_index, repository) => {
		return repository.id;
	};

	needsSelectionSizeDisplay(): boolean {
		if (this.isRepositorySelectionActive) {
			return false;
		}

		if (this.singleFile) {
			return false;
		}

		if (this.activeRepository.contentBrowseable) {
			return true;
		}

		return this.getLinkSpecsForSelections().length > 0;
	}

	getSelectionSizeMessage(): string {
		return this.translateChoiceService.getTranslation(
			{
				count: this.selectionSize,
				inOther: this.isInManualEntryMode(),
			},
			"management.files.browser.selectCount.{count}.{inOther}",
		);
	}

	get navigationButtonsHidden(): boolean {
		return !this.activeItem || this.activeItem.path === "";
	}

	handleUpClicked(): void {
		this.activePath = this.activeItem.parentPath;
		this.refresh();
	}

	handleRootClicked(): void {
		this.activePath = CmsBrowserService.SEPARATOR;
		this.refresh();
	}

	handleSelectionUpdate(): void {
		this.saveSelectionInFolder();
	}

	handleItemClicked(item: BrowserItem): void {
		if (item.folder) {
			this.activePath = item.path;
			this.refresh();
		} else {
			this.doSave([createLinkSpec(item)]);
		}
	}

	handleSortOrderChanged(sort: SortEvent): void {
		this.sortKey = sort.sortKey;
		this.sortOrder = sort.sortOrder;
		this.refresh();
	}

	saveSelectionInFolder(): void {
		if (this.activeItem) {
			this.selectionsForAllFolders.set(
				createFolderKey(this.activeItem.repositoryId, this.activeItem.path),
				this.selectionInThisFolder,
			);
			this.selectionSize = this.getLinkSpecsForSelections().length;
		}
	}

	async refresh(): Promise<void> {
		if (this.activeRepository.contentBrowseable && (this.activePath || this.activePath === "")) {
			const item = await this.cmsBrowserService.getItem(
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				this.route.snapshot.paramMap.get("elementType")!,
				this.route.snapshot.paramMap.get("elementId")!,
				this.activeRepository.id,
				this.activePath,
				this.sortKey,
				SortOrder[this.sortOrder],
			);
			this.updateActiveItem(item);
		}
	}

	updateActiveItem(newActiveItem: BrowserItem): void {
		this.activeItem = newActiveItem;
		this.activePath = newActiveItem.path;
		this.restoreSelectionInFolder();
	}

	isInManualEntryMode(): boolean {
		return !this.isRepositorySelectionActive && !this.activeRepository.contentBrowseable;
	}

	canSave(): boolean {
		if (this.activeRepository.error || this.saveInProgress) {
			return false;
		} else if (this.isInManualEntryMode()) {
			return !this.form || (!this.form.invalid && !filenameValidator()(this.form.controls?.path));
		} else {
			return this.getLinkSpecsForSelections().length > 0 && !this.isPathTooLong();
		}
	}

	showRepositories(): void {
		this.isRepositorySelectionActive = true;
	}

	showFileSelection(): void {
		this.isRepositorySelectionActive = false;
		if (!this.activeRepository.contentBrowseable) {
			this.activePath = this.activeRepository.suggestedPath;
			this.refresh();
		}
	}

	handleRepositorySelected(repository: BrowserRepository): void {
		this.activeRepository = repository;
		this.activePath = repository.suggestedPath;
		this.isRepositorySelectionActive = false;
		this.refresh();
	}

	getTableRow(item: BrowserItem): TableRow {
		return {
			iconClass: item.folder ? "ico-workspace" : "ico-file",
			disabled: !item.selectable,
			clickable: this.singleFile ? true : item.folder,
			checkItem: this.singleFile ? false : !item.folder,
			id: item.path,
		};
	}

	getTableCell(item: BrowserItem, index: number): TableCell {
		if (index === 0) {
			return {
				text: item.name,
			};
		} else if (index === 1) {
			return {
				text: item.lastChangedDate,
			};
		}

		throw new Error("Invalid column index");
	}

	getSwitchToFileInputMessage(): string {
		if (this.singleFile) {
			return this.translateService.instant("management.files.browser.selectFiles.single");
		} else {
			return this.translateService.instant("management.files.browser.selectFiles.multiple");
		}
	}

	getDialogTitleKey(): string {
		if (!this.isRepositorySelectionActive && this.activeRepository.contentBrowseable) {
			return "management.files.browser.selectFiles.heading";
		} else if (!this.isRepositorySelectionActive && !this.activeRepository.contentBrowseable) {
			return "management.files.browser.enterFilePath.heading";
		} else {
			return "management.files.browser.selectRepository.heading";
		}
	}

	getSelectableItemsInThisFolder(): BrowserItem[] {
		if (!this.activeItem) {
			return [];
		} else {
			return this.activeItem.children.filter((child) => !child.folder && child.selectable);
		}
	}

	restoreSelectionInFolder(): void {
		const savedSelection = this.selectionsForAllFolders.get(
			createFolderKey(this.activeItem.repositoryId, this.activeItem.path),
		);
		this.selectionInThisFolder = savedSelection
			? savedSelection
			: this.selectionService.newSelection(this.getSelectableItemsInThisFolder(), (item: BrowserItem) => item.path);
	}

	needsSaveAndCancelButtons(): boolean {
		if (this.isRepositorySelectionActive) {
			return false;
		}

		if (this.activeRepository.contentBrowseable && this.singleFile) {
			return false;
		}

		return true;
	}

	save(): void {
		const linkSpecs = this.getLinkSpecsForSelections();
		if (this.isInManualEntryMode() && this.activePath) {
			linkSpecs.push({
				repositoryId: this.activeRepository.id,
				path: this.activePath,
			});
		}
		this.doSave(linkSpecs);
	}

	async onSave(linkSpecs: LinkFileSpec[]): Promise<void> {
		const containerType = this.route.snapshot.paramMap.get("elementType")!;
		const containerId = this.route.snapshot.paramMap.get("elementId")!;

		await this.mutexService.invokeWithResult("linkFiles" + containerType + containerId, async () => {
			return this.fileService.link(
				this.route.snapshot.paramMap.get("workspaceId")!,
				containerType,
				containerId,
				linkSpecs,
				this.route.snapshot.paramMap.get("processVersion")!,
			);
		});
	}

	private async doSave(linkSpecs: LinkFileSpec[]): Promise<void> {
		this.saveInProgress = true;
		await this.onSave(linkSpecs);

		this.dialog.close();
		this.saveInProgress = false;
		await this.dialog.close();
		await this.viewService.refresh(this.route.snapshot.paramMap);
	}

	getLinkSpecsForSelections(): LinkFileSpec[] {
		const linkSpecs: LinkFileSpec[] = [];
		this.selectionsForAllFolders.forEach((selectionForOneFolder: Selection<BrowserItem>) => {
			if (selectionForOneFolder) {
				selectionForOneFolder.getSelected().forEach((selectedItem: BrowserItem) => {
					linkSpecs.push(createLinkSpec(selectedItem));
				});
			}
		});
		return linkSpecs;
	}

	/* Manual entry */

	isPathTooLong(): boolean {
		for (const linkSpec of this.getLinkSpecsForSelections()) {
			const pathSeparatorIndex = linkSpec.path.lastIndexOf("/");
			if (pathSeparatorIndex > 255) {
				return true;
			}
			if (linkSpec.path.substring(pathSeparatorIndex + 1).length > 255) {
				return true;
			}
		}
		return false;
	}
}

function createFolderKey(repositoryId: string, path: string): string {
	return repositoryId + CmsBrowserService.SEPARATOR + path;
}

function createLinkSpec(item: BrowserItem): LinkFileSpec {
	return {
		repositoryId: item.repositoryId,
		path: item.path,
	};
}
