<stages-dialog2 #dialog>
	<stages-popup-header [viewedDialog]="dialog"> </stages-popup-header>
	<h1 *ngIf="loadingError$ | async as error" translate="error.title"></h1>

	<stages-popup-content>
		<stages-loading-error-embeddable
			[credentialsValid]="true"
			[loadingError$]="loadingError$"
		></stages-loading-error-embeddable>
	</stages-popup-content>
	<stages-popup-footer [viewedDialog]="dialog"></stages-popup-footer>
</stages-dialog2>
