<div *ngIf="(applicationState.isMobileView | async) !== null">
	<header id="mobile-header" [ngClass]="{ 'has-banner': bannerEnabled }">
		<button
			[attr.title]="'navigation.workspace.select' | translate"
			[attr.aria-label]="'navigation.workspace.select' | translate"
			class="action"
			(click)="applicationState.toggleNavigationDrawer()"
		>
			<div
				class="delightful-icon"
				[ngClass]="{ hamburger: !applicationState.navigationDrawerOpen, close: applicationState.navigationDrawerOpen }"
			>
				<span class="first"></span> <span class="second"></span> <span class="third"></span>
			</div>
		</button>
		<button
			[attr.aria-label]="'search' | translate"
			[attr.title]="'search' | translate"
			class="action search-action"
			(click)="applicationState.toggleSearch()"
			*ngIf="!applicationState.navigationDrawerOpen"
		>
			<i class="ico" [ngClass]="{ 'ico-search': !searchOpen, 'ico-close': searchOpen }"></i>
		</button>
		<stages-menu
			*ngIf="applicationState.toolbarVisible && !applicationState.navigationDrawerOpen"
			(click)="applicationState.navigationDrawerOpen = false"
			menuId="mainMenu"
			[iconClasses]="['ico', 'ico-more-vertical']"
			openTo="left"
			[items]="menuItems"
			[noCollapse]="true"
			class="action"
		></stages-menu>
	</header>

	<div id="banner" *ngIf="bannerEnabled">
		<span class="secondary" [innerHtml]="'global.main.bannerLeftRightText' | translate | safe: 'html'"></span
		><span class="primary" [innerHtml]="'global.main.bannerCenterText' | translate | safe: 'html'"></span
		><span class="secondary" [innerHtml]="'global.main.bannerLeftRightText' | translate | safe: 'html'"></span>
	</div>

	<stages-navigation-drawer></stages-navigation-drawer>
	<router-outlet name="clipboard"></router-outlet>
	<div
		id="search"
		[ngClass]="{
			'has-banner': bannerEnabled,
			visible: searchOpen,
			expand: !applicationState.navigationDrawerOpen && (applicationState.isMobileView | async) === false
		}"
	>
		<stages-global-search-box></stages-global-search-box>
	</div>
	<div
		id="content"
		[ngClass]="{
			'has-banner': bannerEnabled,
			'has-search': searchOpen,
			'has-toolbar': applicationState.toolbarVisible,
			expand: !applicationState.navigationDrawerOpen && (applicationState.isMobileView | async) === false,
			'document-view': applicationState.documentViewEnabled
		}"
	>
		<div class="wrapper">
			<router-outlet name="dialog" (activate)="onDialogActivate()" (deactivate)="onDialogDeactivate()"></router-outlet>
			<!-- *ngIf="!dialogActive" would be better but loses main navigation on reload -->
			<div [hidden]="dialogActive">
				<router-outlet></router-outlet>
			</div>
			<footer class="page-footer">
				<span class="img stages"></span>
			</footer>
		</div>
	</div>

	<!--
		The ui-mask must be always present, it propagates all events to the document on mobile safari
		TC: Open long page on iPad, scroll down, open menu and click on "document", the menu will not be
		closed if the ui-mask is not present because the event does not bubble to the document
		We cannot switch to flexbox because it's impossible to position the stages logo in IE11 (justify-content
		works only with a height set and not with min-height)
	-->
	<div
		id="ui-mask"
		(click)="applicationState.toggleNavigationDrawer()"
		[ngClass]="{ visible: applicationState.maskVisible }"
	></div>
	<stages-snackbar></stages-snackbar>
</div>
<router-outlet name="popup" (activate)="onPopupOpened($event)" (deactivate)="onPopupClosed($event)"></router-outlet>
