<stages-dialog2 #dialog *ngIf="paramMap$ | async as paramMap">
	<stages-dialog-header>
		<p class="p1" translate="{{ paramMap.get('errorTitle') }}"></p>
	</stages-dialog-header>
	<div class="content contentpadding">
		<p>{{ paramMap.get("errorMessage") }}</p>
	</div>
	<footer>
		<div class="buttons">
			<button class="sm" (click)="close()" translate="close"></button>
		</div>
	</footer>
</stages-dialog2>
