import { Component, Input } from "@angular/core";

interface HasLabelAndOptionalChangeMarkerAndTailored {
	label: string;
	tailored?: boolean;
	changeMarker?: {
		label?: string | null;
	};
}

@Component({
	selector: "stages-element-name",
	templateUrl: "./element-name.component.html",
})
export class ElementNameComponent {
	@Input()
	self?: HasLabelAndOptionalChangeMarkerAndTailored;

	@Input()
	headerLevel = 1;
}
