import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ProcessSearchService } from "process/description/process-search.service";
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";

const DEBOUNCE_TIME = 150;

const KEY_RETURN = 13;

const KEY_ESCAPE = 27;

const KEY_UP = 38;

const KEY_DOWN = 40;

export interface LinkSearchEvent {
	term: string;
	results?: unknown[];
	searchEngineAvailable: boolean;
}

@Component({
	selector: "stages-description-editor-link-search",
	templateUrl: "./link-search.component.html",
})
export class LinkSearchComponent implements OnInit, OnDestroy {
	private searchInputHandler?: Subscription;
	term!: string;

	@Input()
	workspace!: stages.workspace.Workspace;

	@Input()
	iconClass?: string;

	@Output() readonly search = new EventEmitter<LinkSearchEvent>();

	@ViewChild("searchInput", { read: ElementRef, static: true })
	input!: ElementRef;

	// https://github.com/cartant/rxjs-tslint-rules/issues/95
	private linkableTypes$ = new BehaviorSubject([] as string[]);

	@Input()
	set linkableTypes(types: string[]) {
		this.linkableTypes$.next(types);
	}

	get linkableTypes(): string[] {
		return this.linkableTypes$.getValue();
	}

	constructor(private route: ActivatedRoute, private searchService: ProcessSearchService) {}

	ngOnInit(): void {
		this.term = "";
		const allKeys = fromEvent<KeyboardEvent>(this.input.nativeElement, "keyup");

		this.searchInputHandler = combineLatest([
			this.route.paramMap,
			allKeys.pipe(
				// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
				filter((e: KeyboardEvent) => !isSpecialKey(e.keyCode)),
				debounceTime(DEBOUNCE_TIME),
			),
			this.linkableTypes$,
		]).subscribe(([paramMap, _keys, linkableTypes]) => {
			this.updateItems(paramMap, linkableTypes);
		});
	}

	ngOnDestroy(): void {
		if (this.searchInputHandler) {
			this.searchInputHandler.unsubscribe();
		}
	}

	private async updateItems(paramMap: ParamMap, linkableTypes: string[]): Promise<void> {
		if (this.term.trim() === "") {
			this.search.emit({ term: this.term, results: [], searchEngineAvailable: true });
		} else {
			try {
				const results = await this.searchService.searchElementsByName(
					this.term,
					"LOCAL",
					linkableTypes,
					paramMap.get("processVersion")!,
					1,
					this.workspace,
				);
				this.search.emit({ term: this.term, results: results, searchEngineAvailable: true });
			} catch (err: unknown) {
				this.search.emit({ term: this.term, results: [], searchEngineAvailable: false });
			}
		}
	}
}

function isSpecialKey(keyCode: number): boolean {
	return keyCode === KEY_ESCAPE || keyCode === KEY_RETURN || keyCode === KEY_UP || keyCode === KEY_DOWN;
}
