import { ParamMap } from "@angular/router";
import { Store } from "common/data/store.logic";
import { ProcessInterfacesService } from "management/process-interfaces/process-interfaces.service";

type ProcessElementInterface = stages.management.interfaces.ProcessElementInterface;

export class InterfacesListStore implements Store<ProcessElementInterface> {
	_showOnlyChanges: boolean = false;
	_isReleasePreview: boolean = false;
	_filterType?: string;
	_searchTerm?: string;

	set showOnlyChanges(showOnlyChanges: boolean) {
		this._showOnlyChanges = showOnlyChanges;
	}

	set isReleasePreview(isReleasePreview: boolean) {
		this._isReleasePreview = isReleasePreview;
	}

	set filterType(filterType: string) {
		this._filterType = filterType;
	}

	set filterTerm(filterTerm: string) {
		this._searchTerm = filterTerm;
	}

	constructor(
		private processInterfacesService: ProcessInterfacesService,
		private pageSize: number,
		private sourceWorkspaceId: string,
		private sourceProcessVersion: string,
		private targetWorkspaceId: string,
		showOnlyChanges: boolean,
		isReleasePreview: boolean,
	) {
		this.showOnlyChanges = showOnlyChanges;
		this.isReleasePreview = isReleasePreview;
	}

	async getPage(
		pageIndex: number,
		_workspaceId: string,
		_pv: string,
		_paramMap: ParamMap,
	): Promise<PagedResult<ProcessElementInterface>> {
		const processVersionToCompare: string = this._showOnlyChanges ? "_vv" : this.sourceProcessVersion;
		return this.processInterfacesService.loadMoreInterfaces(
			pageIndex,
			this.pageSize,
			this.sourceWorkspaceId,
			this.targetWorkspaceId,
			this.sourceProcessVersion,
			processVersionToCompare,
			this._showOnlyChanges,
			this._isReleasePreview,
			this._filterType ? this._filterType : "",
			this._searchTerm ? this._searchTerm : "",
		);
	}
}
