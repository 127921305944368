import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProcessInterfacesFilterService implements OnDestroy {
	constructor(private route: ActivatedRoute) {
		this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data) => {
			if (data.showOnlyChanges !== undefined) {
				this.setShowOnlyChanges(data.showOnlyChanges);
			}
		});
	}

	private destroy$ = new Subject<boolean>();
	showOnlyChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>("");
	filterType$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
	isFilterToolbarVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	setShowOnlyChanges(showOnlyChanges: boolean): void {
		this.showOnlyChanges$.next(showOnlyChanges);
	}

	setSearchTerm(searchTerm: string): void {
		this.searchTerm$.next(searchTerm);
	}

	setFilterType(filterType: string): void {
		this.filterType$.next(filterType);
	}

	setFilterToolbarVisible(isVisible: boolean): void {
		this.isFilterToolbarVisible$.next(isVisible);
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
