import { WithContributors } from "process/diagram/with-contributors";

interface FitToPageContainer {
	fitToPage: boolean;
}

export class FitToPageMenuItemImpl implements SwitchMenuItem, WithContributors {
	constructor(private fitToPageContainer: FitToPageContainer, public id: string) {}
	name: string = "fitToPage";
	iconClass: string[] = [];
	isSwitch = true;
	onValueChange!: (value: boolean) => void;
	contributors: Set<string> = new Set([this.id]);
	getValue(): boolean {
		return this.fitToPageContainer.fitToPage;
	}
}
