<div class="wrapper relative" [ngClass]="{ active: flyoutVisible }" *ngIf="workspaces">
	<i class="ico ico-search"></i>
	<input
		#searchInput
		type="search"
		class="search"
		[placeholder]="'search.input.placeholder' | translate"
		autocomplete="off"
		[(ngModel)]="searchTerm"
		(keydown)="onKeyDown($event)"
		(stagesDebouncedChange)="onChange()"
		[debouncedEvent]="'input'"
		(focus)="onFocus()"
		(click)="preventClosingFlyout($event)"
		maxlength="1337"
	/>
</div>

<div class="progress" *ngIf="searching">
	<div class="indeterminate"></div>
</div>
<div
	[hidden]="(!isSearchOpen && isMobileView) || !flyoutVisible"
	class="search-flyout"
	*ngIf="(overviewResults && workspaces) || !searchEngineAvailable"
>
	<div class="wrapper" *ngIf="overviewResults && workspaces">
		<ul class="suggestions" *ngIf="overviewResults?.suggestions?.length">
			<li
				*ngFor="let suggestion of overviewResults?.suggestions; index as index"
				[ngClass]="{ selected: isSelected(1, index) }"
			>
				<span class="cell">
					<a
						*ngIf="!isFile(suggestion)"
						class="clickable"
						[routerLink]="buildElementRouterLink(workspaces, suggestion)"
						(click)="closeSearch()"
					>
						<span [ngClass]="{ tailored: suggestion.tailored }">{{ suggestion.label }} </span>
						<span>({{ suggestion.type.name }})</span>
						<span class="workspace-name" *ngIf="searchScope !== 'local'"> [{{ suggestion.workspaceName }}]</span>
					</a>

					<ng-container *ngIf="isFile(suggestion)">
						<a
							class="clickable"
							(click)="downloadFileFromSearchSuggestion(suggestion, workspaces.currentWorkspace.id); closeSearch()"
							(stagesKeyUp)="onKeyUpForFileSearchResult($event, suggestion, workspaces.currentWorkspace.id)"
						>
							<span class="ico" [ngClass]="getFileIconClass(suggestion)"></span>
							<span [ngClass]="{ tailored: suggestion.tailored }">{{ suggestion.label }} </span>
							<span>({{ suggestion.type.name }})</span> </a
						>&nbsp; <span translate="search.flyout.search.at.container"></span>&nbsp;
						<a
							class="clickable"
							[routerLink]="buildFileContainerRouterLink(workspaces, suggestion)"
							(click)="closeSearch()"
						>
							<span>{{ suggestion.containerElementName }}</span>
							<span class="workspace-name" *ngIf="searchScope !== 'local'"> [{{ suggestion.workspaceName }}]</span>
						</a>
					</ng-container>
				</span>
			</li>
		</ul>
		<ul class="aggregations" *ngIf="overviewResults?.type_aggregations?.length">
			<li
				*ngFor="let aggregation of overviewResults?.type_aggregations; index as index"
				[ngClass]="{ selected: isSelected(2, index) }"
			>
				<span class="cell">
					<a (click)="openResults(searchScope, [aggregation.key]); closeSearch()">
						<span class="term">{{ searchTerm }} </span>
						<span
							class="category"
							translate="search.flyout.search.in"
							[translateParams]="{ category: aggregation.key }"
						></span>
						<span class="counter">~&nbsp;{{ aggregation.doc_count }}</span>
					</a>
				</span>
			</li>
		</ul>
		<ul class="other">
			<li class="not-clickable" *ngIf="!hasResults()">
				<span class="cell">
					<a translate="search.flyout.no.results" [translateParams]="{ term: searchTerm }"></a>
				</span>
			</li>
			<li [ngClass]="{ selected: isSelected(3, 0) }" *ngIf="searchScope !== 'local' && hasResults()">
				<span class="cell">
					<a
						class="clickable"
						(click)="openResults('local'); closeSearch()"
						translate="search.flyout.search.current"
					></a>
				</span>
			</li>
			<li [ngClass]="{ selected: isSelected(3, 0) }" *ngIf="searchScope !== 'all' && hasResultsInOtherWorkspaces()">
				<span class="cell">
					<a class="clickable" (click)="openResults('all'); closeSearch()" translate="search.flyout.search.all"></a>
				</span>
			</li>
		</ul>
	</div>
	<div class="wrapper" *ngIf="!searchEngineAvailable">
		<ul>
			<li class="label"><span translate="search.notavailable"></span></li>
		</ul>
	</div>
</div>
