<form #form="ngForm" (ngSubmit)="save()" novalidate stagesFocusError>
	<stages-dialog-header></stages-dialog-header>

	<h1 [translate]="sc.actionKey"></h1>
	<div class="content">
		<div class="form">
			<div class="input-container lg required">
				<p
					class="text"
					[translate]="sc.explanationKey"
					[translateParams]="sc.explanationKeyParams"
					*ngIf="sc.explanationKey"
				></p>
				<label for="name-input" class="input-label" [translate]="sc.nameLabelKey"></label>
				<input
					type="text"
					class="text fullsize"
					id="name-input"
					name="name"
					[(ngModel)]="sc.name"
					stagesAutofocus
					tabindex="1"
					#nameControl="ngModel"
					(ngModelChange)="inputChanged()"
					[stagesMaxLength]="255"
					[pattern]="sc.namePattern"
					required
				/>
				<div class="info">
					<div class="errors" *ngIf="form.submitted || nameControl.dirty || nameControl.touched">
						<div
							*ngIf="nameControl.errors?.max"
							class="error"
							role="alert"
							translate="input.maxLength"
							[translateParams]="{ length: '255' }"
						></div>
						<div *ngIf="nameControl.errors?.required" class="error" role="alert" translate="input.required"></div>
						<div *ngIf="nameControl.errors?.pattern" class="error" [translate]="sc.patternError"></div>
						<div *ngIf="serverSideValidationErrors.exists" class="error" [translate]="sc.existsError"></div>
						<div *ngIf="serverSideValidationErrors.system" class="error" [translate]="sc.systemError"></div>
					</div>
					<div class="maxlength">{{ nameControl.value?.length || 0 }}/255</div>
				</div>
			</div>
		</div>
	</div>

	<footer>
		<div class="buttons-bottom">
			<div class="spacer"></div>
			<button class="sm cancel" (click)="sc.close()" translate="cancel"></button>
			<button type="submit" class="sm prime" [disabled]="!form.valid" [translate]="sc.actionKey"></button>
		</div>
	</footer>
</form>
