import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

type FileError = stages.file.FileError;

@Component({
	selector: "stages-files-error",
	templateUrl: "./files-error.component.html",
	animations: [
		trigger("swipe", [
			state(
				"left",
				style({
					transform: "translateX(0)",
				}),
			),
			state(
				"right",
				style({
					transform: "translateX(-50%)",
				}),
			),
			transition("* => *", animate("0.4s 0.1s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
})
export class FilesErrorComponent {
	error$: Observable<FileError>;
	errorDetails$: Observable<unknown[]>;
	details = false;
	detailsState = "left";

	constructor(private route: ActivatedRoute) {
		this.error$ = this.route.data.pipe(map((data) => data.error));
		this.errorDetails$ = this.error$.pipe(
			map((error) => {
				const errorDetails: unknown[] = [];
				for (const prop in error.details) {
					if (error.details.hasOwnProperty(prop)) {
						errorDetails.push({ key: prop, value: error.details[prop] });
					}
				}
				return errorDetails;
			}),
		);
	}

	toggleState(): void {
		this.detailsState = this.detailsState === "left" ? "right" : "left";
	}
}
