import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { KeyboardService } from "common/keyboard.service";
import { ScrollService } from "common/routing/scroll.service";
import { MainService } from "core/main.service";
import { TranslateChoiceService } from "core/translate/translate-choice.service";
import { ApplicationState } from "core/volatile-application-state.model";
import { FileService } from "files/files.service";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { OverviewResults, SearchService, Suggestion } from "search/search.service";
import { ApplicationContextRepository } from "core/application-context.repository";

type WorkspaceView = stages.workspace.application.WorkspaceView;

enum KEY_CODE {
	ARROW_UP = 38,
	ARROW_DOWN = 40,
	ENTER = 13,
	ESCAPE = 27,
}

@Component({
	selector: "stages-global-search-box",
	templateUrl: "search.component.html",
})
export class GlobalSearchBoxComponent implements OnInit, OnDestroy {
	@ViewChild("searchInput") searchInput!: ElementRef;

	applicationState!: ApplicationState;
	isMobileView: boolean = false;
	isSearchOpen: boolean = false;

	private destroy$ = new Subject<boolean>();
	readonly MIN_TERM_LENGTH = 1;

	appSearchScope$!: Observable<string>;
	applicationContext$!: Observable<unknown>;

	flyoutVisible = false;
	downloadRequest = false;
	clickListener?: () => void;
	onKeyDown!: (event: KeyboardEvent) => void;

	index = -1;
	currentIndex = -1;

	searchTerm!: string;
	searchEngineAvailable = true;
	searching = false;
	overviewResults?: OverviewResults | null;
	workspaces!: WorkspaceView;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private mainService: MainService,
		private searchService: SearchService,
		private scrollService: ScrollService,
		private keyboardService: KeyboardService,
		private translateChoiceService: TranslateChoiceService,
		private renderer: Renderer2,
		private applicationContextRepository: ApplicationContextRepository,
		private fileService: FileService,
		translateService: TranslateService,
	) {
		translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => (this.overviewResults = null));
	}

	ngOnInit(): void {
		this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
			this.searchService.setDefaultProcessVersionToSearchFor(paramMap.get("processVersion")!);
			this.searchService.setProcessVersionToSearchFor(paramMap.get("processVersion")!);
			this.mainService.getApplication(paramMap.get("processVersion")!);
		});

		this.appSearchScope$ = this.applicationContextRepository.searchScope$;

		this.appSearchScope$.pipe(takeUntil(this.destroy$)).subscribe((appSearchScope) => {
			const convertedAppSearchScope = convertSearchScope(appSearchScope);
			this.searchService.setDefaultSearchScope(convertedAppSearchScope);
			this.searchService.setSearchScope(convertedAppSearchScope);
		});

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.destroy$),
			)
			.subscribe(() => {
				const urlTree = this.router.parseUrl(this.router.url);
				const filterMode = urlTree.root.children.primary.children.dialog;
				if (filterMode) {
					const segment = urlTree.root.children.primary.children.dialog.segments[0];
					if (segment.path === "search") {
						this.searchService.setCategories(segment.parameterMap);
						this.searchService.setCategories(segment.parameterMap);
						const scopeFromRouteChild = segment.parameterMap.get("scope");
						if (scopeFromRouteChild) {
							this.searchService.setSearchScope(scopeFromRouteChild);
						}
						const selectedPVToSearchFor = segment.parameterMap.get("pv");
						if (selectedPVToSearchFor) {
							this.searchService.setProcessVersionToSearchFor(selectedPVToSearchFor);
						}

						this.searchTerm = segment.parameterMap.get("term") || "";

						if (this.searchTerm === "") {
							this.clearResults();
							this.onKeyDown = this.keyDownDummy;
						} else {
							this.onKeyDown = this.keyDownForResultsLoaded;
						}

						this.searchInput.nativeElement.value = this.searchTerm;
					}
				}
			});

		this.applicationState = this.mainService.applicationState;
		this.onKeyDown = this.keyDownDummy;

		this.mainService.workspaceView$.pipe(takeUntil(this.destroy$)).subscribe((workspaceView: WorkspaceView) => {
			this.workspaces = workspaceView;
		});

		this.mainService.applicationState.isMobileView.pipe(takeUntil(this.destroy$)).subscribe((isMobileView: boolean) => {
			this.isMobileView = isMobileView;
		});

		this.mainService.applicationState.isSearchOpen.pipe(takeUntil(this.destroy$)).subscribe((isSearchOpen: boolean) => {
			this.isSearchOpen = isSearchOpen;
			if (isSearchOpen && this.isMobileView) {
				this.searchInput.nativeElement.focus();
			}
		});
	}

	ngOnDestroy(): void {
		if (this.clickListener) {
			this.clickListener();
		}

		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	keyDownForResultsLoaded(event: KeyboardEvent): void {
		// eslint-disable-next-line deprecation/deprecation -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		const key = event.which || event.keyCode;

		switch (key) {
			case KEY_CODE.ESCAPE: {
				event.preventDefault();
				this.closeSearch();
				break;
			}
			case KEY_CODE.ENTER: {
				event.preventDefault();
				if (this.enterKeyOpensResultPage()) {
					this.openResults(this.searchService.getSearchScope());
				} else if (this.searchEngineAvailable) {
					this.openItem(this.workspaces);
				}
				this.closeSearch();
				break;
			}
			case KEY_CODE.ARROW_DOWN: {
				event.preventDefault();
				if (this.index === -1 && this.isTermAvailable()) {
					this.showFlyout();
				}
				this.index = ++this.index % this.getItemCount();
				break;
			}
			case KEY_CODE.ARROW_UP: {
				event.preventDefault();
				this.index = this.index > 0 ? --this.index : this.getItemCount() - 1;
				break;
			}
			default: {
				// do nothing
			}
		}
	}

	keyDownDummy(): void {
		// nothing to do
	}

	private enterKeyOpensResultPage(): boolean {
		return this.index === -1 && this.isTermAvailable() && this.searchEngineAvailable;
	}

	private isTermAvailable(): boolean {
		if (!this.searchTerm) {
			return false;
		}
		return this.searchTerm.length >= this.MIN_TERM_LENGTH;
	}

	closeSearch(): void {
		if (this.flyoutVisible) {
			this.hideFlyout();
		}
		this.mainService.applicationState.closeSearch();
	}

	openInvertedResults(): void {
		this.openResults(this.searchService.getSearchInvertedScope());
	}

	openResults(searchScope: string, categories?: string[]): void {
		this.hideFlyout();
		this.router
			.navigate(
				[
					{
						outlets: {
							dialog: [
								"search",
								{
									term: this.searchTerm,
									scope: searchScope,
									pv: this.searchService.getProcessVersionToSearchFor(),
									categories: categories ? categories : this.searchService.getCategories(),
									rpage: 1,
								},
							],
						},
					},
				],
				{
					relativeTo: this.activatedRoute,
				},
			)
			.then(() => {
				this.hideFlyout();
			});
	}

	private clearResults(): void {
		this.hideFlyout();
		this.searching = false;
		this.overviewResults = null;
	}

	private showFlyout(): void {
		/* We display the flyout only when we have results or the search engine is not available. */
		if (!this.flyoutVisible && (this.overviewResults || !this.searchEngineAvailable)) {
			this.index = -1;
			this.clickListener = this.renderer.listen("document", "click", () => this.hideFlyout());
			if (!this.flyoutVisible) {
				this.scrollService.disableWindowScroll();
			}
			this.flyoutVisible = true;
		}
	}

	private hideFlyout(): void {
		this.index = -1;
		this.scrollService.enableWindowScroll();
		this.flyoutVisible = false;
		if (this.clickListener) {
			this.clickListener();
			this.clickListener = undefined;
		}
	}

	preventClosingFlyout($event: UIEvent): void {
		if ($event.preventDefault) {
			$event.preventDefault();
			$event.stopPropagation();
		}
	}

	isFlyoutVisible(): boolean {
		const results = this.overviewResults;
		/* The search engine is available and the results have been loaded/fetched. */
		const resultsLoaded =
			this.searchEngineAvailable &&
			this.flyoutVisible &&
			results &&
			results.type_aggregations.length + results.suggestions.length + results.other_workspaces_count[0].doc_count > 0;
		/* The search engine is not available. */
		const searchNotAvailable = !this.searchEngineAvailable && this.flyoutVisible;
		return resultsLoaded || searchNotAvailable;
	}

	private getItemCount(): number {
		if (this.overviewResults) {
			return (
				this.overviewResults.type_aggregations.length +
				this.overviewResults.suggestions.length +
				this.overviewResults.other_workspaces_count.length
			);
		}
		return -1;
	}

	isFile(suggestion: Suggestion): boolean {
		return suggestion.type.ident === "file";
	}

	getFileIconClass(suggestion: Suggestion): string {
		if (suggestion.fileType) {
			return "ico-ft-" + suggestion.fileType;
		}
		return "ico-" + suggestion.type.subtypeIdent;
	}

	getFileUrl(suggestion: Suggestion, workspaceId: string): string {
		return "file/" + suggestion.id + "?workspaceId=" + workspaceId;
	}

	hasResults(): boolean {
		if (!this.overviewResults) {
			return false;
		}
		return this.overviewResults.suggestions.length > 0 || this.overviewResults.type_aggregations.length > 0;
	}

	hasResultsInOtherWorkspaces(): boolean {
		const otherWorkspaceCount = this.overviewResults ? this.overviewResults.other_workspaces_count[0].doc_count : 0;
		return otherWorkspaceCount > 0;
	}

	getReverseSearchLabel(): string {
		return this.translateChoiceService.getTranslation(
			{
				currentOrAll: this.searchService.getSearchInvertedScope() === "all" ? "current" : "all",
			},
			"search.flyout.search.{currentOrAll}",
		);
	}

	get searchScope(): string {
		return this.searchService.getSearchScope();
	}

	buildFileContainerRouterLink(workspaces: WorkspaceView, fileSearchSuggestion: Suggestion): unknown[] {
		return this.buildRouterLink(workspaces, fileSearchSuggestion, [
			"process",
			fileSearchSuggestion.containerElementTypeIdent!,
			fileSearchSuggestion.containerElementIdentity!,
		]);
	}

	buildElementRouterLink(workspaces: WorkspaceView, elementSearchSuggestion: Suggestion): unknown[] {
		if (elementSearchSuggestion.type.ident === "tailoring") {
			return this.buildRouterLink(workspaces, elementSearchSuggestion, [
				"management",
				"tailoring",
				elementSearchSuggestion.identity,
			]);
		} else if (elementSearchSuggestion.type.ident === "description") {
			return this.buildRouterLink(workspaces, elementSearchSuggestion, [
				"management",
				"descriptions",
				elementSearchSuggestion.identity,
			]);
		} else if (elementSearchSuggestion.type.ident.startsWith("enactment")) {
			// type ident is enactmentactivator or enactmentscript but url segment needs to be activator or script
			return this.buildRouterLink(workspaces, elementSearchSuggestion, [
				"management",
				"enactment",
				elementSearchSuggestion.type.ident.substring(9),
				elementSearchSuggestion.identity,
			]);
		}
		return this.buildRouterLink(workspaces, elementSearchSuggestion, [
			"process",
			elementSearchSuggestion.type.ident,
			elementSearchSuggestion.identity,
		]);
	}

	private buildRouterLink(workspaces: WorkspaceView, suggestion: Suggestion, commands: unknown[]): unknown[] {
		const preserve = this.searchService.preserveSecondaryWorkspaceInSearchResultNavigation(
			workspaces,
			suggestion.workspaceId,
			suggestion.processId,
		);

		return [
			"/",
			"workspace",
			suggestion.workspaceId,
			suggestion.processVersionIdentifier,
			...this.mainService.getSecondaryRouterPart(preserve),
			{ outlets: { primary: commands, dialog: null } },
		];
	}

	isSelected(group: number, itemIndex: number): boolean {
		const results = this.overviewResults;
		if (!results) {
			return false;
		}
		return this.index === getIndex(results, group, itemIndex);
	}

	onKeyUpForFileSearchResult(event: KeyboardEvent, suggestion: Suggestion, workspaceId: string): void {
		if (this.keyboardService.isReturnKeyPressed(event)) {
			this.downloadFileFromSearchSuggestion(suggestion, workspaceId);
		}
	}

	downloadFileFromSearchSuggestion(suggestion: Suggestion, workspaceId: string): void {
		this.fileService.download(
			this.activatedRoute,
			suggestion.id,
			workspaceId,
			this.activatedRoute.snapshot.paramMap.get("ProcessVersion")!,
			null,
			false,
		);
	}

	openItem(workspaces: WorkspaceView): void {
		const results = this.overviewResults;
		if (!results) {
			throw new Error("no sesults - cannot open item");
		}
		const index = this.index;
		this.hideFlyout();
		if (index < results.suggestions.length) {
			const result = results.suggestions[index];
			if (result.type.ident === "file") {
				this.clearResults();
				this.searchTerm = "";
				this.downloadFileFromSearchSuggestion(result, workspaces.currentWorkspace.id);
			} else {
				this.router.navigate(this.buildElementRouterLink(workspaces, result));
			}
		} else if (index < results.type_aggregations.length + results.suggestions.length) {
			this.openResults(this.searchService.getSearchScope(), [
				results.type_aggregations[index - results.suggestions.length].key,
			]);
		} else {
			this.openResults(this.searchService.getSearchInvertedScope());
		}
	}

	onFocus(): void {
		this.flyoutVisible = this.isFlyoutVisible();
		if (this.isTermAvailable()) {
			// we restored the term, but there are no cached results
			this.onChange();
		} else {
			this.showFlyout();
		}
	}

	async onChange(): Promise<void> {
		if (this.isTermAvailable()) {
			this.searching = true;
			try {
				this.overviewResults = await this.searchService.getOverview(
					this.workspaces.currentWorkspace.id,
					this.searchService.getProcessVersionToSearchFor(),
					this.searchTerm,
					this.searchService.getSearchScope(),
				);
				this.searchEngineAvailable = true;
				this.searching = false;
				this.onKeyDown = this.keyDownForResultsLoaded;
				this.showFlyout();
			} catch {
				this.searchEngineAvailable = false;
				this.searching = false;
				this.onKeyDown = this.keyDownForResultsLoaded;
				this.showFlyout();
			}
		} else {
			this.clearResults();
			this.onKeyDown = this.keyDownDummy;
		}
	}
}

function convertSearchScope(searchScope: string): string {
	switch (searchScope) {
		case "ALL_WORKSPACES": {
			return "all";
		}
		case "CURRENT_WORKSPACE": {
			return "local";
		}
		case "FAVORITE_WORKSPACES": {
			return "my";
		}
		case "RECOMMENDED_WORKSPACES": {
			return "recommended";
		}
		default: {
			throw new Error("Unknown search scope: " + searchScope);
		}
	}
}

function getIndex(results: OverviewResults, group: number, itemIndex: number): number {
	switch (group) {
		case 1:
			return itemIndex;
		case 2:
			return itemIndex + results.suggestions.length;
		case 3:
			return itemIndex + results.type_aggregations.length + results.suggestions.length;
		default:
			throw new Error(`unexpected itemIndex: ${itemIndex}`);
	}
}
