<div class="content">
	<h1 translate="management.tailoring.root.reset"></h1>

	<div class="form">
		<div class="form-group">
			<div class="input-container sm">
				<p translate="management.tailoring.root.reset.warning"></p>
			</div>
		</div>
	</div>
</div>

<footer>
	<div class="buttons">
		<button class="sm cancel" (click)="close()" translate="cancel"></button>
		<button class="sm save" (click)="reset()" [disabled]="resetInProgress">
			<stages-spin size="sm" *ngIf="resetInProgress"></stages-spin>
			<span translate="management.tailoring.root.reset" *ngIf="!resetInProgress"></span>
		</button>
	</div>
</footer>
