import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DialogService } from "common/dialog/dialog.service";
import { assertNever } from "core/functions";
import { UploadService } from "core/upload.service";
import { Subject, Subscription } from "rxjs";
import { tap } from "rxjs/operators";

type FileUpload = stages.file.FileUpload;

export type UploadCompletedCallback = (body: FileUpload, status: number) => void;
export type FilesDroppedCallback = (files: File[]) => boolean;

@Component({
	selector: "stages-upload",
	templateUrl: "./upload.component.html",
})
export class UploadComponent implements OnInit, OnDestroy {
	@Input()
	confirmMessage?: string;

	// A comma-separated list of file extensions, including "." (example: ".jpg, .gif, .png")
	// File extension match is case-insensitive
	@Input()
	fileExtensions?: string;

	@Input()
	uploadIcon?: string;

	@Input()
	uploadCompletedCallback!: UploadCompletedCallback;

	@Input()
	filesDroppedCallback?: FilesDroppedCallback;

	@Input()
	multiple?: boolean;

	@Input()
	uploadUrl!: string;

	@Input()
	maxFileSize?: number;

	@Input()
	uploadProgress$?: Subject<number>;

	@Input()
	expandDroparea: boolean = false;

	fileSubscriber: Subscription;

	progress?: number | null;

	@Output() readonly errorEmitter = new EventEmitter<Error>();

	constructor(
		private uploadService: UploadService,
		private httpClient: HttpClient,
		private dialogService: DialogService,
	) {
		this.fileSubscriber = this.uploadService.pendingFilesObservableOutput.subscribe((newFiles) => {
			// eslint-disable-next-line eqeqeq  -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			if (newFiles != undefined) {
				this.send(newFiles);
			}
		});
	}

	ngOnInit(): void {
		if (this.uploadService.getPendingFiles().length > 0) {
			this.send(this.uploadService.getPendingFiles());
		}
	}

	ngOnDestroy(): void {
		this.fileSubscriber.unsubscribe();
	}

	handleFilesUpdated(handleFiles: File[]): void {
		// Cannot use for..of directly with FileList because of https://github.com/Microsoft/TypeScript/issues/3164
		this.send(Array.from(handleFiles));
	}

	private async send(files: File[]): Promise<void> {
		if (this.confirmMessage) {
			if (await this.dialogService.confirm(this.confirmMessage, undefined, "yes", "no")) {
				this.executeSend(files);
			}
		} else {
			this.executeSend(files);
		}
	}

	setProgress(progress: number): void {
		this.progress = progress;
		if (this.uploadProgress$) {
			this.uploadProgress$.next(progress);
		}
	}

	private executeSend(files: File[]): void {
		this.progress = 0;

		if (files.length && files.length > 0) {
			if (this.filesDroppedCallback && !this.filesDroppedCallback(files)) {
				return;
			}
			const formData = this.uploadService.createFormData(files);

			const request = new HttpRequest("POST", this.uploadUrl, formData, { reportProgress: true });
			this.httpClient
				.request<FileUpload>(request)
				.pipe(tap(this.handleEvent))
				.subscribe({
					error: (errorResponse: unknown): void => {
						if (errorResponse instanceof HttpErrorResponse && this.errorEmitter.observers.length > 0) {
							this.errorEmitter.emit(errorResponse.error);
							this.progress = 0;
						} else {
							throw errorResponse;
						}
					},
				});
		}
	}

	private handleEvent = (event: HttpEvent<FileUpload>): void => {
		switch (event.type) {
			case HttpEventType.Sent:
				this.progress = 0;
				break;
			case HttpEventType.UploadProgress:
				if (event.total && event.total > 0) {
					const progress = event.loaded / event.total;
					if (this.progress !== progress) {
						this.setProgress(progress);
					}
				}
				break;
			case HttpEventType.Response:
				this.setProgress(1);
				this.uploadCompletedCallback(event.body!, 200);
				setTimeout(() => {
					this.progress = null;
				}, 700);
				break;
			case HttpEventType.ResponseHeader:
			case HttpEventType.DownloadProgress:
				// ignore
				break;
			case HttpEventType.User:
				throw new Error(`Unexpected object: ${event}`);
			default:
				return assertNever(event);
		}
	};
}
