<ng-container *ngIf="processView$ | async as processView">
	<article class="module widget">
		<ul class="list">
			<li class="list-item">
				<a class="label" [href]="getLink(processView)" target="_blank" rel="noopener noreferrer">
					<i class="ico ico-navigate-to"></i>
					<div class="text">
						<h4
							translate="process.feedback.link.give"
							[translateParams]="{ systemName: processView.feedback!.displayName }"
						></h4>
					</div>
				</a>
			</li>
		</ul>
	</article>
</ng-container>
