import { ValidatorFn } from "@angular/forms";
import { InputBase } from "common/form/input/types/input-base";
import { TextInputArgs } from "common/form/input/types/text-input";

export class JsonInput extends InputBase<string> {
	constructor(args: TextInputArgs) {
		super({
			key: args.key,
			value: args.value,
			type: "json",
			required: args.required,
			maxLength: null,
			translateKey: args.translateKey,
			untranslatableName: args.untranslatableName,
			readonly: args.readonly,
			hidden: args.hidden,
		});

		super.addValidator(this.validateJSON());
	}

	validateJSON(): ValidatorFn {
		return (control) => {
			if (control.value) {
				try {
					JSON.parse(control.value);
				} catch (e: unknown) {
					return {
						json: false,
					};
				}
			}
			return null;
		};
	}
}
