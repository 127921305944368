import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "percent" })
export class PercentPipe implements PipeTransform {
	/**
	 * Expects as input a number 0..1, returns the percentage as string. Example: 0.5 => "50%"
	 */
	transform(input: number): string {
		return isNaN(input) ? "" : Math.floor(input * 100).toString() + "%";
	}
}
