/* tslint:disable */
/* eslint-disable */

export interface HttpClient {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; }): RestResponse<R>;
}

export class AdminJobsResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /adminjobs
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.delete
     */
    delete(jobIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`adminjobs`, data: jobIds });
    }

    /**
     * HTTP GET /adminjobs
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.getAllAdminJobs
     */
    getAllAdminJobs(): RestResponse<PagedResult<stages.adminjobs.AdminJobName>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`adminjobs` });
    }

    /**
     * HTTP POST /adminjobs/add
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.addAdminJob
     */
    addAdminJob(adminJob: stages.adminjobs.AdminJob): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`adminjobs/add`, data: adminJob });
    }

    /**
     * HTTP POST /adminjobs/save
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.saveAdminJob
     */
    saveAdminJob(adminJob: stages.adminjobs.AdminJob): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`adminjobs/save`, data: adminJob });
    }

    /**
     * HTTP GET /adminjobs/validate
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.validateCronExpression
     */
    validateCronExpression(queryParams?: NullableProperties<{ cron?: string; }>): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`adminjobs/validate`, queryParams: queryParams });
    }

    /**
     * HTTP GET /adminjobs/{id}
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.getAdminJob
     */
    getAdminJob(id: string): RestResponse<stages.adminjobs.AdminJob> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`adminjobs/${id}` });
    }

    /**
     * HTTP PUT /adminjobs/{id}/pause
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.pauseAdminJob
     */
    pauseAdminJob(id: string): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`adminjobs/${id}/pause` });
    }

    /**
     * HTTP PUT /adminjobs/{id}/resume
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.resumeAdminJob
     */
    resumeAdminJob(id: string): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`adminjobs/${id}/resume` });
    }

    /**
     * HTTP GET /adminjobs/{id}/script
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.download
     */
    download(id: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`adminjobs/${id}/script` });
    }

    /**
     * HTTP POST /adminjobs/{id}/script
     * Java method: de.methodpark.stages.webapp.adminjobs.main.AdminJobsResource.uploadScript
     */
    uploadScript(id: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`adminjobs/${id}/script`, queryParams: queryParams, data: formData });
    }
}

export class AdministrationDashboardResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /administration/systemproperties
     * Java method: de.methodpark.stages.webapp.administration.main.AdministrationDashboardResource.collectSystemProperties
     */
    collectSystemProperties(): RestResponse<stages.administration.SystemInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/systemproperties` });
    }

    /**
     * HTTP GET /administration/userbase
     * Java method: de.methodpark.stages.webapp.administration.main.AdministrationDashboardResource.getUserBase
     */
    getUserBase(queryParams?: NullableProperties<{ range?: string; }>): RestResponse<stages.administration.AggregatedUserBase> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/userbase`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usersessions
     * Java method: de.methodpark.stages.webapp.administration.main.AdministrationDashboardResource.getUserSessionDetails
     */
    getUserSessionDetails(queryParams?: NullableProperties<{ range?: string; }>): RestResponse<stages.administration.AggregatedUserSessions> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usersessions`, queryParams: queryParams });
    }
}

export class AuthenticationServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /authentication/auto
     * Java method: de.methodpark.pkit.rest.AuthenticationService.needsAuthentication
     */
    needsAuthentication(): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`authentication/auto` });
    }

    /**
     * HTTP DELETE /authentication/logout
     * Java method: de.methodpark.pkit.rest.AuthenticationService.deauthenticate
     */
    deauthenticate(): RestResponse<pkit.rest.LogoutResult> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`authentication/logout` });
    }

    /**
     * HTTP POST /authentication/password
     * Java method: de.methodpark.pkit.rest.AuthenticationService.checkCredentials
     */
    checkCredentials(credentials: pkit.auth.AuthenticationCredentials): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`authentication/password`, data: credentials });
    }
}

export class ChartResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /chart/mfuFiles
     * Java method: de.methodpark.stages.webapp.chart.main.ChartResource.getMostFrequentlyUsedFiles
     */
    getMostFrequentlyUsedFiles(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; group?: string; global?: boolean; range?: string; }>): RestResponse<stages.chart.DataPoint[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`chart/mfuFiles`, queryParams: queryParams });
    }

    /**
     * HTTP GET /chart/mfuProcessElements
     * Java method: de.methodpark.stages.webapp.chart.main.ChartResource.getMostFrequentlyUsedProcessElements
     */
    getMostFrequentlyUsedProcessElements(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; global?: boolean; range?: string; }>): RestResponse<stages.chart.DataPoint[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`chart/mfuProcessElements`, queryParams: queryParams });
    }

    /**
     * HTTP GET /chart/mfuTypes
     * Java method: de.methodpark.stages.webapp.chart.main.ChartResource.getMostFrequentlyUsedTypes
     */
    getMostFrequentlyUsedTypes(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; range?: string; }>): RestResponse<stages.chart.DataPoint[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`chart/mfuTypes`, queryParams: queryParams });
    }

    /**
     * HTTP GET /chart/mfuWorkspaces
     * Java method: de.methodpark.stages.webapp.chart.main.ChartResource.getMostFrequentlyUsedWorkspaces
     */
    getMostFrequentlyUsedWorkspaces(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; range?: string; }>): RestResponse<stages.chart.DataPoint[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`chart/mfuWorkspaces`, queryParams: queryParams });
    }
}

export class CleanupAdminResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /administration/cleanup/archives
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.deleteArchives
     */
    deleteArchives(archiveIds: string[], queryParams?: NullableProperties<{ page?: number; pageSize?: number; includeFiles?: boolean; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.Archive>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/cleanup/archives`, queryParams: queryParams, data: archiveIds });
    }

    /**
     * HTTP GET /administration/cleanup/archives
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getArchives
     */
    getArchives(queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.Archive>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/archives`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/cleanup/archives/{id}/download
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.downloadArchives
     */
    downloadArchives(id: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/archives/${id}/download` });
    }

    /**
     * HTTP GET /administration/cleanup/archives/{id}/errors
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getErrors
     */
    getErrors(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.ArchiveError>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/archives/${id}/errors`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/cleanup/rules
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.addRule
     */
    addRule(rule: stages.administration.cleanup.CleanupRuleProperties, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/cleanup/rules`, queryParams: queryParams, data: rule });
    }

    /**
     * HTTP DELETE /administration/cleanup/rules
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.deleteRules
     */
    deleteRules(ruleIds: string[], queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.CleanupRule>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/cleanup/rules`, queryParams: queryParams, data: ruleIds });
    }

    /**
     * HTTP GET /administration/cleanup/rules
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getRules
     */
    getRules(queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.CleanupRule>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/rules`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/cleanup/rules/new
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.newRule
     */
    newRule(): RestResponse<stages.administration.cleanup.CleanupRuleProperties> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/rules/new` });
    }

    /**
     * HTTP PUT /administration/cleanup/rules/{id}
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.editRule
     */
    editRule(id: string, rule: stages.administration.cleanup.CleanupRuleProperties, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/cleanup/rules/${id}`, queryParams: queryParams, data: rule });
    }

    /**
     * HTTP GET /administration/cleanup/rules/{id}
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getRule
     */
    getRule(id: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<stages.administration.cleanup.CleanupRuleProperties> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/rules/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/cleanup/rules/{id}/enable
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.enableRule
     */
    enableRule(id: string, enabled: boolean, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/cleanup/rules/${id}/enable`, queryParams: queryParams, data: enabled });
    }

    /**
     * HTTP PUT /administration/cleanup/rules/{id}/rename
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.renameRule
     */
    renameRule(id: string, name: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/cleanup/rules/${id}/rename`, queryParams: queryParams, data: name });
    }

    /**
     * HTTP GET /administration/cleanup/rules/{id}/workspaceCollections
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getWorkspaceCollections
     */
    getWorkspaceCollections(id: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.WorkspaceCollection>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/rules/${id}/workspaceCollections`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/cleanup/rules/{id}/workspaceCollections
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.updateWorkspaceCollections
     */
    updateWorkspaceCollections(id: string, changes: stages.administration.cleanup.WorkspaceChanges, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.WorkspaceCollection>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/cleanup/rules/${id}/workspaceCollections`, queryParams: queryParams, data: changes });
    }

    /**
     * HTTP GET /administration/cleanup/rules/{id}/workspaceIds
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getWorkspaceIds
     */
    getWorkspaceIds(id: string): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/rules/${id}/workspaceIds` });
    }

    /**
     * HTTP GET /administration/cleanup/rules/{id}/workspaces
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.getWorkspaces
     */
    getWorkspaces(id: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.Workspace>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/cleanup/rules/${id}/workspaces`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/cleanup/rules/{id}/workspaces
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.updateWorkspaces
     */
    updateWorkspaces(id: string, changes: stages.administration.cleanup.WorkspaceChanges, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.cleanup.Workspace>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/cleanup/rules/${id}/workspaces`, queryParams: queryParams, data: changes });
    }

    /**
     * HTTP PUT /administration/cleanup/rules/{ruleId}/workspaces/{id}/transitive
     * Java method: de.methodpark.stages.webapp.administration.cleanup.main.CleanupAdminResource.setTransitive
     */
    setTransitive(ruleId: string, id: string, transitive: boolean): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/cleanup/rules/${ruleId}/workspaces/${id}/transitive`, data: transitive });
    }
}

export class CleanupForPasteServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /cleanupforpaste
     * Java method: de.methodpark.stages.webapp.common.main.CleanupForPasteService.cleanup
     */
    cleanup(html: stages.common.main.CleanupForPasteServicePasteData): RestResponse<stages.common.main.CleanupForPasteServicePasteData> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`cleanupforpaste`, data: html });
    }
}

export class CmsBrowserResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/files/browser/{scope}/{id}/repositories
     * Java method: de.methodpark.stages.webapp.management.files.browser.main.CmsBrowserResource.getBrowserRepositories
     */
    getBrowserRepositories(workspaceId: string, scope: string, id: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.files.browser.BrowserRepository[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/files/browser/${scope}/${id}/repositories`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/files/browser/{scope}/{id}/repository/{repositoryId}
     * Java method: de.methodpark.stages.webapp.management.files.browser.main.CmsBrowserResource.getItem
     */
    getItem(workspaceId: string, scope: string, id: string, repositoryId: string, queryParams?: NullableProperties<{ path?: string; pv?: string; sortKey?: string; sortOrder?: string; }>): RestResponse<stages.management.files.browser.BrowserItem> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/files/browser/${scope}/${id}/repository/${repositoryId}`, queryParams: queryParams });
    }
}

export class ComplianceManagementResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/management/compliance/referencemodels
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.addReferenceModel
     */
    addReferenceModel(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; page?: number; scopeIds?: string[]; }>): RestResponse<stages.management.compliance.ReferenceModels> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/referencemodels`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/management/compliance/referencemodels
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.deleteReferenceModel
     */
    deleteReferenceModel(workspaceId: string, ids: string[], queryParams?: NullableProperties<{ pv?: string; page?: number; }>): RestResponse<stages.management.compliance.ReferenceModels> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/referencemodels`, queryParams: queryParams, data: ids });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/referencemodels
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getReferenceModels
     */
    getReferenceModels(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; page?: number; }>): RestResponse<stages.management.compliance.ReferenceModels> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/referencemodels`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/management/compliance/referencemodels
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.setReferenceModels
     */
    setReferenceModels(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; selected?: string[]; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/referencemodels`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/referencemodels/assigned
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getAssignedScopeIds
     */
    getAssignedScopeIds(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/referencemodels/assigned`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/referencemodels/folder/{folderId}
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getReferenceModelFolder
     */
    getReferenceModelFolder(workspaceId: string, folderId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.compliance.referencemodel.tree.Folder> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/referencemodels/folder/${folderId}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/management/compliance/scopes
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.addScope
     */
    addScope(workspaceId: string, name: string, queryParams?: NullableProperties<{ pv?: string; page?: number; }>): RestResponse<SecuredPagedResult<stages.management.compliance.Scope>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes`, queryParams: queryParams, data: name });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/management/compliance/scopes
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.deleteScopes
     */
    deleteScopes(workspaceId: string, ids: string[], queryParams?: NullableProperties<{ pv?: string; page?: number; }>): RestResponse<SecuredPagedResult<stages.management.compliance.Scope>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes`, queryParams: queryParams, data: ids });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/scopes
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getScopes
     */
    getScopes(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; page?: number; }>): RestResponse<SecuredPagedResult<stages.management.compliance.Scope>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/scopes/browse
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getBrowseView
     */
    getBrowseView(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; browseType?: string; browseIdentity?: string; }>): RestResponse<stages.common.CombinedViewForBrowser> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes/browse`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/management/compliance/scopes/{complianceScopeId}/updateelements
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.updateScopeElements
     */
    updateScopeElements(workspaceId: string, complianceScopeId: string, updatedElements: stages.common.UpdatedElements, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes/${complianceScopeId}/updateelements`, queryParams: queryParams, data: updatedElements });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/scopes/{scopeId}
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getScope
     */
    getScope(workspaceId: string, scopeId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.compliance.Scope> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes/${scopeId}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/management/compliance/scopes/{scopeId}
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.renameScope
     */
    renameScope(workspaceId: string, scopeId: string, queryParams?: NullableProperties<{ pv?: string; name?: string; }>): RestResponse<stages.management.compliance.Scope> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes/${scopeId}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/compliance/scopes/{scopeId}/treeandinitialselection
     * Java method: de.methodpark.stages.webapp.management.compliance.main.ComplianceManagementResource.getTreeAndInitialSelection
     */
    getTreeAndInitialSelection(workspaceId: string, scopeId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.common.ComplianceTreeAndInitialSelection> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/compliance/scopes/${scopeId}/treeandinitialselection`, queryParams: queryParams });
    }
}

export class ComplianceResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /compliance/workspace/{workspaceId}/process/elements/{type}/{id}/comment
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.updateComment
     */
    updateComment(workspaceId: string, type: string, id: string, comment: string, queryParams?: NullableProperties<{ processId?: string; targetProcessId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`compliance/workspace/${workspaceId}/process/elements/${type}/${id}/comment`, queryParams: queryParams, data: comment });
    }

    /**
     * HTTP PUT /compliance/workspace/{workspaceId}/process/elements/{type}/{id}/rating
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.updateRating
     */
    updateRating(workspaceId: string, type: string, id: string, rating: string, queryParams?: NullableProperties<{ processId?: string; pv?: string; targetProcessId?: string; }>): RestResponse<stages.compliance.RatingUpdate> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`compliance/workspace/${workspaceId}/process/elements/${type}/${id}/rating`, queryParams: queryParams, data: rating });
    }

    /**
     * HTTP POST /compliance/{processElementType}/{processElementId}/assocFromProcessModel/{referenceElementType}/{referenceElementId}
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.createAssociationFromProcess
     */
    createAssociationFromProcess(processElementType: string, processElementId: string, referenceElementType: string, referenceElementId: string): RestResponse<stages.compliance.ComplianceAssociation> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`compliance/${processElementType}/${processElementId}/assocFromProcessModel/${referenceElementType}/${referenceElementId}` });
    }

    /**
     * HTTP POST /compliance/{referenceElementType}/{referenceElementId}/assocFromReferenceModel/{processElementType}/{processElementId}
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.createAssociationFromReferenceModel
     */
    createAssociationFromReferenceModel(referenceElementType: string, referenceElementId: string, processElementType: string, processElementId: string): RestResponse<stages.compliance.ComplianceAssociation> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`compliance/${referenceElementType}/${referenceElementId}/assocFromReferenceModel/${processElementType}/${processElementId}` });
    }

    /**
     * HTTP DELETE /compliance/{workspaceId}/associations
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.deleteAssociations
     */
    deleteAssociations(workspaceId: string, queryParams?: NullableProperties<{ associationIds?: string[]; swid?: string; spv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`compliance/${workspaceId}/associations`, queryParams: queryParams });
    }

    /**
     * HTTP POST /compliance/{workspaceId}/associations/{associationId}
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.updateAssociationComment
     */
    updateAssociationComment(workspaceId: string, associationId: string, newComment: string, queryParams?: NullableProperties<{ swid?: string; spv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`compliance/${workspaceId}/associations/${associationId}`, queryParams: queryParams, data: newComment });
    }

    /**
     * HTTP POST /compliance/{workspaceId}/{processElementType}/{processElementId}/processAssociations
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.updateAssociationsFromProcess
     */
    updateAssociationsFromProcess(workspaceId: string, processElementType: string, processElementId: string, assocContainer: stages.common.UpdatedElements): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`compliance/${workspaceId}/${processElementType}/${processElementId}/processAssociations`, data: assocContainer });
    }

    /**
     * HTTP POST /compliance/{workspaceId}/{referenceElementType}/{referenceElementId}/associations/{processElementType}/comment
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.createCommentOnlyAssociation
     */
    createCommentOnlyAssociation(workspaceId: string, referenceElementType: string, referenceElementId: string, processElementType: string, newComment: string, queryParams?: NullableProperties<{ pv?: string; swid?: string; spv?: string; }>): RestResponse<stages.compliance.ComplianceAssociation> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`compliance/${workspaceId}/${referenceElementType}/${referenceElementId}/associations/${processElementType}/comment`, queryParams: queryParams, data: newComment });
    }

    /**
     * HTTP POST /compliance/{workspaceId}/{referenceElementType}/{referenceElementId}/referenceAssociations
     * Java method: de.methodpark.stages.webapp.compliance.main.ComplianceResource.updateAssociationsFromReferenceModel
     */
    updateAssociationsFromReferenceModel(workspaceId: string, referenceElementType: string, referenceElementId: string, assocContainer: stages.common.UpdatedElements): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`compliance/${workspaceId}/${referenceElementType}/${referenceElementId}/referenceAssociations`, data: assocContainer });
    }
}

export class ContextResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /context
     * Java method: de.methodpark.stages.webapp.context.main.ContextResource.getApplicationContext
     */
    getApplicationContext(queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.context.ApplicationContext> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`context`, queryParams: queryParams });
    }

    /**
     * HTTP GET /editorConfig
     * Java method: de.methodpark.stages.webapp.context.main.ContextResource.getEditorConfig
     */
    getEditorConfig(queryParams?: NullableProperties<{ processTypeIdent?: string; }>): RestResponse<stages.context.EditorConfig> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`editorConfig`, queryParams: queryParams });
    }
}

export class DashboardResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /dashboard/myMostFrequentlyUsedElements
     * Java method: de.methodpark.stages.webapp.dashboard.main.DashboardResource.getMyMostFrequentlyUsedElements
     */
    getMyMostFrequentlyUsedElements(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; global?: boolean; }>): RestResponse<PagedResult<stages.dashboard.Element>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`dashboard/myMostFrequentlyUsedElements`, queryParams: queryParams });
    }

    /**
     * HTTP GET /dashboard/myRoles/workspace/{workspaceId}
     * Java method: de.methodpark.stages.webapp.dashboard.main.DashboardResource.getMyRoles
     */
    getMyRoles(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; page?: number; pageSize?: number; filterByWorkspaceId?: string; }>): RestResponse<PagedResult<stages.dashboard.Role>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`dashboard/myRoles/workspace/${workspaceId}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /dashboard/myRoles/workspace/{workspaceId}/filterWorkspaces
     * Java method: de.methodpark.stages.webapp.dashboard.main.DashboardResource.getFilterWorkspaces
     */
    getFilterWorkspaces(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.dashboard.Workspace[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`dashboard/myRoles/workspace/${workspaceId}/filterWorkspaces`, queryParams: queryParams });
    }

    /**
     * HTTP GET /dashboard/toDo/{taskId}
     * Java method: de.methodpark.stages.webapp.dashboard.main.DashboardResource.getToDo
     */
    getToDo(taskId: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<stages.dashboard.ToDo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`dashboard/toDo/${taskId}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /dashboard/toToList
     * Java method: de.methodpark.stages.webapp.dashboard.main.DashboardResource.getToDoList
     */
    getToDoList(queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.dashboard.ToDoGroup>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`dashboard/toToList`, queryParams: queryParams });
    }
}

export class DependencyResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /administration/dependencies
     * Java method: de.methodpark.stages.webapp.administration.credits.main.DependencyResource.getDependencyGroups
     */
    getDependencyGroups(): RestResponse<stages.administration.credits.DependencyGroup[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/dependencies` });
    }
}

export class EnactmentAssociationResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/management/enactment/association/associations
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentAssociationResource.deleteAssociation
     */
    deleteAssociation(workspaceId: string, queryParams?: NullableProperties<{ associationIds?: string[]; }>): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/management/enactment/association/associations`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/association/{enactmentElementType}/{enactmentElementId}/associations/{targetElementType}/{targetElementId}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentAssociationResource.addAssociation
     */
    addAssociation(workspaceId: string, enactmentElementType: string, enactmentElementId: string, targetElementType: string, targetElementId: string, queryParams?: NullableProperties<{ enactmentElementRole?: string; pv?: string; }>): RestResponse<stages.common.Association<stages.common.AssociationTarget>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/association/${enactmentElementType}/${enactmentElementId}/associations/${targetElementType}/${targetElementId}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/association/{sourceType}/{sourceId}/associations/{associationPath}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentAssociationResource.updateEnactmentAssociations
     */
    updateEnactmentAssociations(workspaceId: string, sourceType: string, sourceId: string, associationPath: string, assocContainer: stages.core.model.AssociationsContainer): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/association/${sourceType}/${sourceId}/associations/${associationPath}`, data: assocContainer });
    }
}

export class EnactmentElementResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/management/enactment/element
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.delete
     */
    delete(workspaceId: string, typedIds: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/management/enactment/element`, data: typedIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/copy/{type}/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.copy
     */
    copy(workspaceId: string, type: string, id: string, sourceIds: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/copy/${type}/${id}`, data: sourceIds });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/edit/{type}/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.getEnactmentElementForEdit
     */
    getEnactmentElementForEdit(workspaceId: string, type: string, id: string): RestResponse<stages.management.enactment.EditableEnactmentElement> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/edit/${type}/${id}` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/enactment/element/edit/{type}/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.saveEditedEnactmentElement
     */
    saveEditedEnactmentElement(workspaceId: string, type: string, id: string, enactmentElement: stages.management.enactment.EditableEnactmentElement): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/edit/${type}/${id}`, data: enactmentElement });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/editattributes/{type}/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.getEnactmentAttributesForEdit
     */
    getEnactmentAttributesForEdit(workspaceId: string, type: string, id: string): RestResponse<stages.process.EditableAttribute[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/editattributes/${type}/${id}` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/enactment/element/editattributes/{type}/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.saveEditedAttributes
     */
    saveEditedAttributes(workspaceId: string, type: string, id: string, editedAttributes: stages.core.Attribute[]): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/editattributes/${type}/${id}`, data: editedAttributes });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/move/{type}/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.move
     */
    move(workspaceId: string, type: string, id: string, sourceIds: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/move/${type}/${id}`, data: sourceIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/overwrite
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.overwrite
     */
    overwrite(workspaceId: string, enactmentElementTypedIds: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/overwrite`, data: enactmentElementTypedIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/restore
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.restore
     */
    restore(workspaceId: string, enactmentElementTypedIds: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/restore`, data: enactmentElementTypedIds });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/script/file/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.downloadEnactmentScript
     */
    downloadEnactmentScript(workspaceId: string, id: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/script/file/${id}` });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/script/file/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.updateEnactmentScriptFile
     */
    updateEnactmentScriptFile(workspaceId: string, id: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.enactment.EnactmentScript> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/script/file/${id}`, queryParams: queryParams, data: formData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/script/{id}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.updateEnactmentScriptContent
     */
    updateEnactmentScriptContent(workspaceId: string, id: string, scriptContent: string): RestResponse<stages.management.enactment.EnactmentScript> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/script/${id}`, data: scriptContent });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/{type}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.create
     */
    create(workspaceId: string, type: string, queryParams?: NullableProperties<{ parentId?: string; name?: string; subtype?: string; }>): RestResponse<NavigationEntry<stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/{type}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.getRootIdentity
     */
    getRootIdentity(workspaceId: string, type: string): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/{type}/tree
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.getTree
     */
    getTree(workspaceId: string, type: string, queryParams?: NullableProperties<{ pv?: string; elementTypes?: string[]; }>): RestResponse<stages.process.TreeElement[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}/tree`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/{type}/{identity}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.getNavigation
     */
    getNavigation(workspaceId: string, type: string, identity: string): RestResponse<CurrentNavigationEntry<stages.management.enactment.EnactmentElementView, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}/${identity}` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/element/{type}/{identity}/browseview
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.getBrowseView
     */
    getBrowseView(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ browsePV?: string; }>): RestResponse<stages.management.enactment.BrowseView> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}/${identity}/browseview`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/element/{type}/{id}/rearrange
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.rearrange
     */
    rearrange(workspaceId: string, type: string, id: string, sortedChildIds: string[], queryParams?: NullableProperties<{ alphabetical?: boolean; pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}/${id}/rearrange`, queryParams: queryParams, data: sortedChildIds });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/enactment/element/{type}/{id}/rename
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentElementResource.rename
     */
    rename(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<CurrentNavigationEntry<stages.management.enactment.EnactmentElementView, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/enactment/element/${type}/${id}/rename`, queryParams: queryParams });
    }
}

export class EnactmentExportResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/export
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.enactmentExport
     */
    enactmentExport(workspaceId: string, enactmentForm: stages.management.enactment.EnactmentForm, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/export`, queryParams: queryParams, data: enactmentForm });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/export/enactmentdashboarddata
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.getEnactmentDashboardData
     */
    getEnactmentDashboardData(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; fetchLastExport?: boolean; }>): RestResponse<stages.management.enactment.EnactmentDashboardData> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentdashboarddata`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/management/enactment/export/enactmentprojects
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.deleteEnactmentProjects
     */
    deleteEnactmentProjects(workspaceId: string, enactmentProjectIds: string[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<SecuredPagedResult<stages.management.enactment.EnactmentProject>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentprojects`, queryParams: queryParams, data: enactmentProjectIds });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/enactment/export/enactmentprojects
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.saveEnactmentProject
     */
    saveEnactmentProject(workspaceId: string, enactmentProject: stages.management.enactment.EnactmentProject, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentprojects`, queryParams: queryParams, data: enactmentProject });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/enactment/export/enactmentprojects/{enactmentProjectId}/default
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.setDefaultEnactmentProject
     */
    setDefaultEnactmentProject(workspaceId: string, enactmentProjectId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentprojects/${enactmentProjectId}/default`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/export/enactmentprojects/{projectId}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.getEnactmentProject
     */
    getEnactmentProject(workspaceId: string, projectId: string, queryParams?: NullableProperties<{ pv?: string; serverIdent?: string; }>): RestResponse<stages.management.enactment.EnactmentProject> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentprojects/${projectId}`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/management/enactment/export/enactmentservers
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.deleteEnactmentServers
     */
    deleteEnactmentServers(workspaceId: string, enactmentServerIdents: string[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<SecuredPagedResult<stages.management.enactment.EnactmentServer>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentservers`, queryParams: queryParams, data: enactmentServerIdents });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/enactment/export/enactmentservers
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.saveEnactmentServer
     */
    saveEnactmentServer(workspaceId: string, enactmentServer: stages.management.enactment.EnactmentServer, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentservers`, queryParams: queryParams, data: enactmentServer });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/export/enactmentservers/{serverIdent}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.getEnactmentServer
     */
    getEnactmentServer(workspaceId: string, serverIdent: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.enactment.EnactmentServer> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentservers/${serverIdent}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/export/enactmentsystems
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.getEnactmentSystems
     */
    getEnactmentSystems(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.enactment.EnactmentSystem[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/enactmentsystems`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/enactment/export/executeactivator/{activatorId}
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.executeActivator
     */
    executeActivator(workspaceId: string, activatorId: string, title: string, queryParams?: NullableProperties<{ processElementId?: string; processElementType?: string; pv?: string; }>): RestResponse<stages.management.enactment.ExecutionResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/executeactivator/${activatorId}`, queryParams: queryParams, data: title });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/enactment/export/lastenactmentexportsuccess
     * Java method: de.methodpark.stages.webapp.management.enactment.main.EnactmentExportResource.getLastSuccessfulEnactmentExport
     */
    getLastSuccessfulEnactmentExport(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.enactment.EnactmentExport> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/enactment/export/lastenactmentexportsuccess`, queryParams: queryParams });
    }
}

export class EntryPageAdministrationResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /administration/entrypage
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.addEntryPage
     */
    addEntryPage(queryParams?: NullableProperties<{ name?: string; }>): RestResponse<stages.administration.entrypage.EntryPage> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/entrypage`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /administration/entrypage
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.deleteEntryPages
     */
    deleteEntryPages(entryPageIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/entrypage`, data: entryPageIds });
    }

    /**
     * HTTP GET /administration/entrypage
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.getEntryPages
     */
    getEntryPages(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<SecuredPagedResult<stages.administration.entrypage.EntryPage>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/entrypage`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/entrypage/rearrange
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.rearrange
     */
    rearrange(sortedEntryPageIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/entrypage/rearrange`, data: sortedEntryPageIds });
    }

    /**
     * HTTP POST /administration/entrypage/update
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.updateEntryPage
     */
    updateEntryPage(newEntryPage: stages.administration.entrypage.EntryPage): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/entrypage/update`, data: newEntryPage });
    }

    /**
     * HTTP DELETE /administration/entrypage/{id}
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.deleteEntryPage
     */
    deleteEntryPage(id: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/entrypage/${id}` });
    }

    /**
     * HTTP GET /administration/entrypage/{id}
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.getEntryPage
     */
    getEntryPage(id: string): RestResponse<stages.administration.entrypage.EntryPage> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/entrypage/${id}` });
    }

    /**
     * HTTP PUT /administration/entrypage/{id}
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.renameEntryPage
     */
    renameEntryPage(id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/entrypage/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/entrypage/{id}/description
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.getDescription
     */
    getDescription(id: string, queryParams?: NullableProperties<{ workspaceId?: string; viewMode?: string; pv?: string; }>): RestResponse<DataAndInfo<stages.core.format.DisplayDescription, string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/entrypage/${id}/description`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/entrypage/{id}/description
     * Java method: de.methodpark.stages.webapp.administration.entrypage.main.EntryPageAdministrationResource.setDescription
     */
    setDescription(id: string, description: stages.core.format.DisplayDescription, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<DataAndInfo<stages.core.format.DisplayDescription, boolean>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/entrypage/${id}/description`, queryParams: queryParams, data: description });
    }
}

export class EntryPageResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /entrypage/tabs
     * Java method: de.methodpark.stages.webapp.entrypage.main.EntryPageResource.getTabs
     */
    getTabs(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<stages.entrypage.EntryPageTab[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`entrypage/tabs`, queryParams: queryParams });
    }

    /**
     * HTTP GET /entrypage/{entryPageId}
     * Java method: de.methodpark.stages.webapp.entrypage.main.EntryPageResource.getEntryPage
     */
    getEntryPage(entryPageId: string): RestResponse<stages.entrypage.EntryPage> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`entrypage/${entryPageId}` });
    }
}

export class FileManagementPlanResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/files/plans/{scope}/{id}
     * Java method: de.methodpark.stages.webapp.management.files.plan.main.FileManagementPlanResource.getFileManagementPlan
     */
    getFileManagementPlan(workspaceId: string, scope: string, id: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.files.plan.FileManagementPlan> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/files/plans/${scope}/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/management/files/plans/{scope}/{id}
     * Java method: de.methodpark.stages.webapp.management.files.plan.main.FileManagementPlanResource.updateItem
     */
    updateItem(workspaceId: string, scope: string, id: string, item: stages.management.files.plan.Item, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/management/files/plans/${scope}/${id}`, queryParams: queryParams, data: item });
    }
}

export class FilesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /files
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.delete
     */
    delete(ids: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`files`, data: ids });
    }

    /**
     * HTTP GET /files/dashboard/recentlyUsed
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getRecentlyUsedFiles
     */
    getRecentlyUsedFiles(queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<stages.file.File[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/dashboard/recentlyUsed`, queryParams: queryParams });
    }

    /**
     * HTTP GET /files/jobs
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getLatestJobStatus
     */
    getLatestJobStatus(queryParams?: NullableProperties<{ fileIds?: string[]; }>): RestResponse<{ [index: string]: stages.core.scheduler.Status }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/jobs`, queryParams: queryParams });
    }

    /**
     * HTTP GET /files/oauth_callback
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getOauthCallback
     */
    getOauthCallback(queryParams?: NullableProperties<{ code?: string; state?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/oauth_callback`, queryParams: queryParams });
    }

    /**
     * HTTP POST /files/removeUploads
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.removeUploads
     */
    removeUploads(fileUpload: stages.file.FileUpload): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/removeUploads`, data: fileUpload });
    }

    /**
     * HTTP GET /files/{fileId}/checkoutDataAndInfo
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getCheckoutDataAndInfo
     */
    getCheckoutDataAndInfo(fileId: string, queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<DataAndInfo<stages.core.Attribute[], stages.core.AttributeType[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${fileId}/checkoutDataAndInfo`, queryParams: queryParams });
    }

    /**
     * HTTP GET /files/{fileId}/elementlink
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getProcessElementLink
     */
    getProcessElementLink(fileId: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<stages.process.ElementLink> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${fileId}/elementlink`, queryParams: queryParams });
    }

    /**
     * HTTP GET /files/{fileId}/revertDataAndInfo
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getRevertDataAndInfo
     */
    getRevertDataAndInfo(fileId: string, queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<DataAndInfo<stages.core.Attribute[], stages.core.AttributeType[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${fileId}/revertDataAndInfo`, queryParams: queryParams });
    }

    /**
     * HTTP GET /files/{fileId}/revision/{revisionIdentifier}
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.downloadWithRevision
     */
    downloadWithRevision(fileId: string, revisionIdentifier: string, queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${fileId}/revision/${revisionIdentifier}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /files/{id}
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.download
     */
    download(id: string, queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /files/{id}
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.uploadFile
     */
    uploadFile(id: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.file.FileUpload> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}`, data: formData });
    }

    /**
     * HTTP POST /files/{id}/authorizeDownload
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.authorizeDownload
     */
    authorizeDownload(id: string, queryParams?: NullableProperties<{ redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}/authorizeDownload`, queryParams: queryParams });
    }

    /**
     * HTTP POST /files/{id}/checkin
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.saveUploads
     */
    saveUploads(id: string, fileUpload: stages.file.FileUpload, queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}/checkin`, queryParams: queryParams, data: fileUpload });
    }

    /**
     * HTTP POST /files/{id}/checkout
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.checkout
     */
    checkout(id: string, attributes: stages.core.Attribute[], queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}/checkout`, queryParams: queryParams, data: attributes });
    }

    /**
     * HTTP GET /files/{id}/errors/{job}
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getError
     */
    getError(id: string, job: string): RestResponse<stages.file.FileError> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${id}/errors/${job}` });
    }

    /**
     * HTTP POST /files/{id}/performCmsAuthentication
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.performCmsAuthentication
     */
    performCmsAuthentication(id: string, queryParams?: NullableProperties<{ redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}/performCmsAuthentication`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /files/{id}/properties
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.editFileProperties
     */
    editFileProperties(id: string, fileProperties: stages.file.FileProperties, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`files/${id}/properties`, queryParams: queryParams, data: fileProperties });
    }

    /**
     * HTTP GET /files/{id}/properties
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getFileProperties
     */
    getFileProperties(id: string, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<stages.file.FilePropertiesInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${id}/properties`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /files/{id}/refresh
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.refresh
     */
    refresh(id: string, queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`files/${id}/refresh`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /files/{id}/rename
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.rename
     */
    rename(id: string, name: string): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`files/${id}/rename`, data: name });
    }

    /**
     * HTTP POST /files/{id}/revert
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.revert
     */
    revert(id: string, attributes: stages.core.Attribute[], queryParams?: NullableProperties<{ workspaceId?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}/revert`, queryParams: queryParams, data: attributes });
    }

    /**
     * HTTP GET /files/{id}/revisions
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getFileWithHistory
     */
    getFileWithHistory(id: string, queryParams?: NullableProperties<{ pv?: string; workspaceId?: string; }>): RestResponse<stages.file.FileWithHistory> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${id}/revisions`, queryParams: queryParams });
    }

    /**
     * HTTP POST /files/{id}/saveState
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.saveState
     */
    saveState(id: string, attributes: stages.core.Attribute[], queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`files/${id}/saveState`, queryParams: queryParams, data: attributes });
    }

    /**
     * HTTP PUT /files/{id}/url
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.editUrl
     */
    editUrl(id: string, urlProperties: stages.file.UrlProperties): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`files/${id}/url`, data: urlProperties });
    }

    /**
     * HTTP GET /files/{id}/url
     * Java method: de.methodpark.stages.webapp.file.main.FilesResource.getUrlProperties
     */
    getUrlProperties(id: string): RestResponse<stages.file.UrlProperties> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`files/${id}/url` });
    }
}

export class FrontendLoggingServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /logger/{logLevel}
     * Java method: de.methodpark.stages.webapp.logging.main.FrontendLoggingService.log
     */
    log(logLevel: string, input: stages.logging.LoggerInput): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`logger/${logLevel}`, data: input });
    }
}

export class ImagesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /images/{containerType}/{containerId}
     * Java method: de.methodpark.stages.webapp.images.main.ImagesResource.storeImage
     */
    storeImage(containerType: string, containerId: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<stages.images.ImageReference> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`images/${containerType}/${containerId}`, queryParams: queryParams, data: formData });
    }

    /**
     * HTTP POST /images/{containerType}/{containerId}/validateMap
     * Java method: de.methodpark.stages.webapp.images.main.ImagesResource.validateImageMap
     */
    validateImageMap(containerType: string, containerId: string, imageMap: string): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`images/${containerType}/${containerId}/validateMap`, data: imageMap });
    }

    /**
     * HTTP GET /images/{containerType}/{containerId}/{name}
     * Java method: de.methodpark.stages.webapp.images.main.ImagesResource.getImage
     */
    getImage(containerType: string, containerId: string, name: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`images/${containerType}/${containerId}/${name}` });
    }

    /**
     * HTTP GET /images/{containerType}/{containerId}/{name}/properties
     * Java method: de.methodpark.stages.webapp.images.main.ImagesResource.getImageProperties
     */
    getImageProperties(containerType: string, containerId: string, name: string): RestResponse<stages.images.ImageReference> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`images/${containerType}/${containerId}/${name}/properties` });
    }

    /**
     * HTTP PUT /images/{containerType}/{containerId}/{name}/properties
     * Java method: de.methodpark.stages.webapp.images.main.ImagesResource.setImageProperties
     */
    setImageProperties(containerType: string, containerId: string, name: string, properties: stages.images.ImageReference): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`images/${containerType}/${containerId}/${name}/properties`, data: properties });
    }
}

export class InstallResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /workspace/{workspaceId}/install/copy
     * Java method: de.methodpark.stages.webapp.install.main.InstallResource.copyProcess
     */
    copyProcess(workspaceId: string, processSelection: stages.install.ProcessSelection, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/install/copy`, queryParams: queryParams, data: processSelection });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/install/import
     * Java method: de.methodpark.stages.webapp.install.main.InstallResource.importProcess
     */
    importProcess(workspaceId: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/install/import`, data: formData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/install/search
     * Java method: de.methodpark.stages.webapp.install.main.InstallResource.searchProcesses
     */
    searchProcesses(workspaceId: string, queryParams?: NullableProperties<{ query?: string; pv?: string; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/install/search`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/install/{id}
     * Java method: de.methodpark.stages.webapp.install.main.InstallResource.getProcessSelection
     */
    getProcessSelection(workspaceId: string, id: string): RestResponse<stages.install.ProcessSelection> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/install/${id}` });
    }
}

export class LoginBootstrapServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /bootstrapProperties
     * Java method: de.methodpark.pkit.rest.LoginBootstrapService.getLoginProperties
     */
    getLoginProperties(): RestResponse<pkit.rest.BootstrapProperties> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`bootstrapProperties` });
    }
}

export class MaintenanceResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /administration/maintenance/start
     * Java method: de.methodpark.stages.webapp.administration.maintenance.main.MaintenanceResource.start
     */
    start(maintenanceForm: stages.administration.maintenance.MaintenanceFormImpl): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/maintenance/start`, data: maintenanceForm });
    }

    /**
     * HTTP PUT /administration/maintenance/stop
     * Java method: de.methodpark.stages.webapp.administration.maintenance.main.MaintenanceResource.stop
     */
    stop(): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/maintenance/stop` });
    }
}

export class ManagementResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/navigation
     * Java method: de.methodpark.stages.webapp.management.main.ManagementResource.getNavigationEntries
     */
    getNavigationEntries(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.NavigationEntry[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/navigation`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/navigation/enactment
     * Java method: de.methodpark.stages.webapp.management.main.ManagementResource.getEnactmentNavigationEntries
     */
    getEnactmentNavigationEntries(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.NavigationEntry[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/navigation/enactment`, queryParams: queryParams });
    }
}

export class MessageResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /messageResources/{lang}
     * Java method: de.methodpark.stages.webapp.main.MessageResource.getMessageResourcesByLanguage
     */
    getMessageResourcesByLanguage(lang: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`messageResources/${lang}` });
    }
}

export class ModulesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/modules
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.getInstallations
     */
    getInstallations(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.management.modules.ModuleInstallation>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/modules`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/modules
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.remove
     */
    remove(workspaceId: string, ids: string[], queryParams?: NullableProperties<{ pv?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.management.modules.ModuleInstallation>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/modules`, queryParams: queryParams, data: ids });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/modules/add
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.add
     */
    add(workspaceId: string, modules: stages.management.modules.ModuleSelection[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/modules/add`, queryParams: queryParams, data: modules });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/modules/ids
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.getWorkspaceIdsForInstalledModules
     */
    getWorkspaceIdsForInstalledModules(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/modules/ids`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/modules/overwrite
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.overwrite
     */
    overwrite(workspaceId: string, elementIds: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/modules/overwrite`, queryParams: queryParams, data: elementIds });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/modules/pullupdate
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.pullUpdate
     */
    pullUpdate(workspaceId: string, moduleInstallationIds: string[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/modules/pullupdate`, queryParams: queryParams, data: moduleInstallationIds });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/modules/pushupdate
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.pushUpdate
     */
    pushUpdate(workspaceId: string, moduleInstallationIds: string[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/modules/pushupdate`, queryParams: queryParams, data: moduleInstallationIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/modules/restore
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.restore
     */
    restore(workspaceId: string, elementIds: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/modules/restore`, queryParams: queryParams, data: elementIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/modules/search
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.searchModules
     */
    searchModules(workspaceId: string, installedModuleWorkspaceIds: string[], queryParams?: NullableProperties<{ query?: string; pv?: string; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/modules/search`, queryParams: queryParams, data: installedModuleWorkspaceIds });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/modules/usage
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.getUsage
     */
    getUsage(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.management.modules.ModuleInstallation>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/modules/usage`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/modules/{id}
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.getModule
     */
    getModule(workspaceId: string, id: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.modules.ModuleSelection> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/modules/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/modules/{id}/ondemand
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.setOnDemand
     */
    setOnDemand(workspaceId: string, id: string, onDemand: boolean, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/modules/${id}/ondemand`, queryParams: queryParams, data: onDemand });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/modules/{id}/setpushupdates
     * Java method: de.methodpark.stages.webapp.management.modules.main.ModulesResource.setPushUpdatesEnabled
     */
    setPushUpdatesEnabled(workspaceId: string, id: string, pushUpdatesEnabled: boolean, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/modules/${id}/setpushupdates`, queryParams: queryParams, data: pushUpdatesEnabled });
    }
}

export class NotificationResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /notification/seen
     * Java method: de.methodpark.stages.webapp.notification.facadeimpl.NotificationResource.markAsSeen
     */
    markAsSeen(queryParams?: NullableProperties<{ objectType?: string; objectId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`notification/seen`, queryParams: queryParams });
    }
}

export class PDFImagesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /pdfimages
     * Java method: de.methodpark.stages.webapp.print.main.PDFImagesResource.getImage
     */
    getImage(queryParams?: NullableProperties<{ src?: string; metamodel?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`pdfimages`, queryParams: queryParams });
    }
}

export class PDFPrintResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /print/pdf/availableTemplates/{type}/{id}
     * Java method: de.methodpark.stages.webapp.print.main.PDFPrintResource.getAvailableTemplates
     */
    getAvailableTemplates(type: string, id: string, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<stages.print.TemplateDefinition[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`print/pdf/availableTemplates/${type}/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /print/pdf/{type}/{id}
     * Java method: de.methodpark.stages.webapp.print.main.PDFPrintResource.generatePdfForId
     */
    generatePdfForId(type: string, id: string, queryParams?: NullableProperties<{ template?: string; workspaceId?: string; pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`print/pdf/${type}/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /print/pdf/{type}/{id}/{tempFolderName}
     * Java method: de.methodpark.stages.webapp.print.main.PDFPrintResource.downloadPdf
     */
    downloadPdf(type: string, id: string, tempFolderName: string, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`print/pdf/${type}/${id}/${tempFolderName}`, queryParams: queryParams });
    }
}

export class ProcessElementsQueryResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/query/{queryProvider}/{queryIdent}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsQueryResource.getQueryResult
     */
    getQueryResult(workspaceId: string, type: string, identity: string, queryProvider: string, queryIdent: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.QueryTableJson> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/query/${queryProvider}/${queryIdent}`, queryParams: queryParams });
    }
}

export class ProcessElementsResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/elements
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.delete
     */
    delete(workspaceId: string, ids: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/elements`, data: ids });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/elements/associations
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.deleteAssociations
     */
    deleteAssociations(workspaceId: string, queryParams?: NullableProperties<{ associationIds?: string[]; pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/elements/associations`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/associations/{id}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.updateAssociationComment
     */
    updateAssociationComment(workspaceId: string, id: string, comment: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/associations/${id}`, queryParams: queryParams, data: comment });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/children
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getBrowseView
     */
    getBrowseView$GET$workspace_workspaceId_process_elements_children(workspaceId: string, queryParams?: NullableProperties<{ browsePV?: string; lang?: java.util.Locale; includeDescriptionIndex?: boolean; isReferenceModelBrowse?: boolean; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/children`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/copy/{type}/{id}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.copy
     */
    copy(workspaceId: string, type: string, id: string, ids: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/copy/${type}/${id}`, data: ids });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/feedbacktemplate
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getTemplate
     */
    getTemplate(workspaceId: string): RestResponse<stages.process.feedback.UserFeedbackRequestTemplate> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/feedbacktemplate` });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/move/{type}/{id}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.move
     */
    move(workspaceId: string, type: string, id: string, ids: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/move/${type}/${id}`, data: ids });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/process/properties
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getEditableProcess
     */
    getEditableProcess(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; defaultLanguage?: boolean; }>): RestResponse<stages.process.EditableProcess> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/process/properties`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/process/properties
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.saveEditedProcess
     */
    saveEditedProcess(workspaceId: string, newProcess: stages.process.Process, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/process/properties`, queryParams: queryParams, data: newProcess });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/scopeElements
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getAllElementsInScope
     */
    getAllElementsInScope(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; referenceModelWorkspaceId?: string; }>): RestResponse<stages.core.TypedId[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/scopeElements`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getViewWithoutIdentity
     */
    getViewWithoutIdentity(workspaceId: string, type: string, queryParams?: NullableProperties<{ perspective?: string; pv?: string; swid?: string; spv?: string; smode?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/index/children
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getIndexView
     */
    getIndexView(workspaceId: string, type: string, queryParams?: NullableProperties<{ browsePV?: string; workspaceId?: string; swid?: string; spv?: string; smode?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/index/children`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/index/childrenPerSubtype/{subtype}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getIndexViewPerSubtype
     */
    getIndexViewPerSubtype(workspaceId: string, type: string, subtype: string, queryParams?: NullableProperties<{ browsePV?: string; workspaceId?: string; swid?: string; spv?: string; smode?: string; lang?: java.util.Locale; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/index/childrenPerSubtype/${subtype}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/tree
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getTree
     */
    getTree(workspaceId: string, type: string, queryParams?: NullableProperties<{ pv?: string; elementTypes?: string[]; swid?: string; spv?: string; smode?: string; includeDependentElements?: boolean; }>): RestResponse<stages.process.TreeElement[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/tree`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getView
     */
    getView(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ perspective?: string; pv?: string; swid?: string; spv?: string; smode?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/associations/combined
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getCombinedAssociations
     */
    getCombinedAssociations(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ combinedTargetElementIdentities?: string[]; path?: string; page?: number; pageSize?: number; pv?: string; }>): RestResponse<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/associations/combined`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/elements/{type}/{identity}/associations/combined/deleteSelected
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.deleteFromCombinedAssociations
     */
    deleteFromCombinedAssociations(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ combinedTargetElementIdentities?: string[]; path?: string; associationIds?: string[]; page?: number; pageSize?: number; pv?: string; }>): RestResponse<SecuredPagedResult<stages.common.Association<stages.process.RemoteTargetElement>>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/associations/combined/deleteSelected`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{identity}/associations/rearrange
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.rearrangeAssociations
     */
    rearrangeAssociations(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ sortedAssociationIds?: string[]; sortStrategy?: stages.core.sort.SortStrategy; path?: string; groupBy?: string; pv?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/associations/rearrange`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/attributes/{attributeScope}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getEditableAttributes
     */
    getEditableAttributes(workspaceId: string, type: string, identity: string, attributeScope: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.EditableAttribute[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/attributes/${attributeScope}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{identity}/attributes/{attributeScope}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.saveEditedAttributes
     */
    saveEditedAttributes(workspaceId: string, type: string, identity: string, attributeScope: string, newAttributes: stages.core.Attribute[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/attributes/${attributeScope}`, queryParams: queryParams, data: newAttributes });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/children
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getBrowseView
     */
    getBrowseView$GET$workspace_workspaceId_process_elements_type_identity_children(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ browsePV?: string; workspaceId?: string; swid?: string; spv?: string; smode?: string; lang?: java.util.Locale; isReferenceModelBrowse?: boolean; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/children`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/childrenPerSubtype/{subtype}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getBrowseViewPerSubtype
     */
    getBrowseViewPerSubtype(workspaceId: string, type: string, identity: string, subtype: string, queryParams?: NullableProperties<{ browsePV?: string; workspaceId?: string; swid?: string; spv?: string; smode?: string; lang?: java.util.Locale; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/childrenPerSubtype/${subtype}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/comments
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getCommentsPage
     */
    getCommentsPage(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ from?: number; to?: number; pv?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/comments`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/description
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsDescriptionResource.getDescription
     */
    getDescription(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ perspective?: string; pv?: string; lang?: java.util.Locale; editable?: boolean; }>): RestResponse<stages.process.Description> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/description`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{identity}/description
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsDescriptionResource.saveDescription
     */
    saveDescription(workspaceId: string, type: string, identity: string, description: stages.core.format.DisplayDescription, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.format.DisplayDescription> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/description`, queryParams: queryParams, data: description });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/description/resource
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsDescriptionResource.getReadonlyDescriptionResource
     */
    getReadonlyDescriptionResource(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ pv?: string; lang?: java.util.Locale; viewModeEdit?: boolean; }>): RestResponse<stages.process.Description> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/description/resource`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/enactment/executionresults
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsEnactmentResource.getEnactmentExecutionResultsPage
     */
    getEnactmentExecutionResultsPage(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ from?: number; to?: number; pv?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/enactment/executionresults`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/properties
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getEditableElement
     */
    getEditableElement(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ pv?: string; defaultLanguage?: boolean; }>): RestResponse<stages.process.EditableElement> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/properties`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{identity}/properties
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.saveEditedElement
     */
    saveEditedElement(workspaceId: string, type: string, identity: string, newElement: stages.process.Element, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/properties`, queryParams: queryParams, data: newElement });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{identity}/rename
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.renameProcessElement
     */
    renameProcessElement(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ name?: string; pv?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/rename`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{identity}/userGroups
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.assignUserGroup
     */
    assignUserGroup(workspaceId: string, type: string, identity: string, groupId: string, queryParams?: NullableProperties<{ page?: number; firstPageSize?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.usergroup.role.UserGroupAssignment>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/userGroups`, queryParams: queryParams, data: groupId });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{identity}/userGroups
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getUserGroups
     */
    getUserGroups(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ page?: number; firstPageSize?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.usergroup.role.UserGroupAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/userGroups`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/elements/{type}/{identity}/userGroups
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.unassignUserGroups
     */
    unassignUserGroups(workspaceId: string, type: string, identity: string, userGroupAssignmentIds: string[], queryParams?: NullableProperties<{ page?: number; firstPageSize?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.usergroup.role.UserGroupAssignment>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${identity}/userGroups`, queryParams: queryParams, data: userGroupAssignmentIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/associations/{assocType}/sourceRole{sourceRole}/{targetElementType}/{targetElementId}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.addAssociation
     */
    addAssociation$POST$workspace_workspaceId_process_elements_type_id_associations_assocType_sourceRolesourceRole_targetElementType_targetElementId(workspaceId: string, type: string, id: string, assocType: string, sourceRole: string, targetElementType: string, targetElementId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.Association> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/associations/${assocType}/sourceRole${sourceRole}/${targetElementType}/${targetElementId}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/associations/{assocType}/{targetElementType}/{targetElementId}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.addAssociation
     */
    addAssociation$POST$workspace_workspaceId_process_elements_type_id_associations_assocType_targetElementType_targetElementId(workspaceId: string, type: string, id: string, assocType: string, targetElementType: string, targetElementId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.Association> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/associations/${assocType}/${targetElementType}/${targetElementId}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/associations/{associationPath}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.updateAssociations
     */
    updateAssociations(workspaceId: string, type: string, id: string, associationPath: string, assocContainer: stages.core.model.AssociationsContainer, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.Association[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/associations/${associationPath}`, queryParams: queryParams, data: assocContainer });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/associations/{associationType}/{sourceRole}/{targetType}/{targetSubtype}/comment
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.addCommentOnlyAssociation
     */
    addCommentOnlyAssociation(workspaceId: string, type: string, id: string, associationType: string, sourceRole: string, targetType: string, targetSubtype: string, comment: string): RestResponse<stages.process.Association> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/associations/${associationType}/${sourceRole}/${targetType}/${targetSubtype}/comment`, data: comment });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{id}/comments
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.createComment
     */
    createComment(workspaceId: string, type: string, id: string, formData: { [index: string]: any }): RestResponse<stages.process.Comment> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/comments`, data: formData });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/elements/{type}/{id}/comments/{commentId}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.deleteComment
     */
    deleteComment(workspaceId: string, type: string, id: string, commentId: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/comments/${commentId}` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/elements/{type}/{id}/comments/{commentId}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.saveComment
     */
    saveComment(workspaceId: string, type: string, id: string, commentId: string, formData: { [index: string]: any }): RestResponse<stages.process.Comment> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/comments/${commentId}`, data: formData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/feedback
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.save
     */
    save(workspaceId: string, type: string, id: string, userResponse: stages.process.feedback.UserFeedbackResponse, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.feedback.CreatedTicket> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/feedback`, queryParams: queryParams, data: userResponse });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{id}/feedbacklink
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.feedbackLink
     */
    feedbackLink(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ pv?: string; elementUrl?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/feedbacklink`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{id}/fileContainer
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.getFileContainerView
     */
    getFileContainerView(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ pv?: string; withFiles?: boolean; path?: string; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/fileContainer`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.uploadFiles
     */
    uploadFiles(workspaceId: string, type: string, id: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.file.FileUpload> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files`, data: formData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/authorizeDownload
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.authorizeDownload
     */
    authorizeDownload(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/authorizeDownload`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{id}/files/jobs
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.getLatestJobStatus
     */
    getLatestJobStatus$GET$workspace_workspaceId_process_elements_type_id_files_jobs(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ pv?: string; path?: string; }>): RestResponse<{ [index: string]: stages.core.scheduler.Status }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/jobs`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/link
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.link
     */
    link(workspaceId: string, type: string, id: string, linkSpecs: stages.file.LinkFileSpec[], queryParams?: NullableProperties<{ redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/link`, queryParams: queryParams, data: linkSpecs });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/performCmsAuthentication
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.performCmsAuthentication
     */
    performCmsAuthentication(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/performCmsAuthentication`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{id}/files/properties
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.getProperties
     */
    getProperties(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ fileId?: string; pv?: string; redirectUrl?: string; }>): RestResponse<stages.file.FileOperationPropertiesInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/properties`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/propertiesWithTemplateProperties
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.getPropertiesWithTemplateProperties
     */
    getPropertiesWithTemplateProperties(workspaceId: string, type: string, id: string, fileContainerDescriptors: stages.process.FileDescriptor[], queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<stages.file.FileOperationPropertiesInfoWithTemplateProperties> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/propertiesWithTemplateProperties`, queryParams: queryParams, data: fileContainerDescriptors });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/save
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.saveUploads
     */
    saveUploads(workspaceId: string, type: string, id: string, fileUpload: stages.file.FileUpload, queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/save`, queryParams: queryParams, data: fileUpload });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/saveTemplate
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.saveTemplate
     */
    saveTemplate(workspaceId: string, type: string, id: string, attributes: stages.core.Attribute[], queryParams?: NullableProperties<{ pv?: string; templateId?: string; fileName?: string; redirectUrl?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/saveTemplate`, queryParams: queryParams, data: attributes });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/saveTemplateAndLock
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.saveTemplateAndLock
     */
    saveTemplateAndLock(workspaceId: string, type: string, id: string, attributes: stages.core.Attribute[], queryParams?: NullableProperties<{ pv?: string; templateId?: string; fileName?: string; redirectUrl?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/saveTemplateAndLock`, queryParams: queryParams, data: attributes });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/url
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.addUrl
     */
    addUrl(workspaceId: string, type: string, id: string, properties: stages.file.UrlProperties): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/url`, data: properties });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/files/url/validate
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.validateUrl
     */
    validateUrl(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ url?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/url/validate`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{id}/files/{fileId}/stateAndInfo
     * Java method: de.methodpark.stages.webapp.process.elements.facadeimpl.ProcessElementsFilesResource.getStateAndInfo
     */
    getStateAndInfo(workspaceId: string, type: string, id: string, fileId: string, queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<DataAndInfo<stages.file.FileState, stages.file.FileStateMetadata>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/files/${fileId}/stateAndInfo`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/freeze
     * Java method: de.methodpark.stages.webapp.process.freeze.facadeimpl.ProcessElementsFreezeResource.freeze
     */
    freeze(workspaceId: string, type: string, id: string, dependentPhases: stages.process.freeze.FreezeSpecification[], queryParams?: NullableProperties<{ newValidVersionName?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/freeze`, queryParams: queryParams, data: dependentPhases });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/elements/{type}/{id}/freeze/jobs
     * Java method: de.methodpark.stages.webapp.process.freeze.facadeimpl.ProcessElementsFreezeResource.getLatestJobStatus
     */
    getLatestJobStatus$GET$workspace_workspaceId_process_elements_type_id_freeze_jobs(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ elementType?: string; ids?: string[]; workspaceIds?: string[]; }>): RestResponse<{ [index: string]: stages.core.scheduler.JobExecutionInfo }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/freeze/jobs`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/newChild
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.createChild
     */
    createChild(workspaceId: string, type: string, id: string, formData: { [index: string]: any }): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/newChild`, data: formData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/rearrange
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.rearrange
     */
    rearrange(workspaceId: string, type: string, id: string, sortedChildIds: string[], queryParams?: NullableProperties<{ alphabetical?: boolean; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/rearrange`, queryParams: queryParams, data: sortedChildIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/elements/{type}/{id}/sequence
     * Java method: de.methodpark.stages.webapp.process.main.ProcessElementsResource.addSequence
     */
    addSequence(workspaceId: string, type: string, id: string, sequence: { [index: string]: string }[], queryParams?: NullableProperties<{ predecessorType?: string; predecessorId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/elements/${type}/${id}/sequence`, queryParams: queryParams, data: sequence });
    }
}

export class ProcessInterfacesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /management/interfaces/{workspaceId}/interfaces/{targetWorkspaceId}
     * Java method: de.methodpark.stages.webapp.management.interfaces.main.ProcessInterfacesResource.getProcessInterfaces
     */
    getProcessInterfaces(workspaceId: string, targetWorkspaceId: string, queryParams?: NullableProperties<{ processVersion?: string; processVersionToCompareWith?: string; page?: number; pageSize?: number; showOnlyChanges?: boolean; isReleasePreview?: boolean; filterType?: string; filterTerm?: string; }>): RestResponse<stages.management.interfaces.ProcessInterfacesWithDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/interfaces/${workspaceId}/interfaces/${targetWorkspaceId}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/interfaces/{workspaceId}/workspaces
     * Java method: de.methodpark.stages.webapp.management.interfaces.main.ProcessInterfacesResource.getInterfaceWorkspaces
     */
    getInterfaceWorkspaces(workspaceId: string, queryParams?: NullableProperties<{ processVersion?: string; processVersionToCompareWith?: string; page?: number; pageSize?: number; showOnlyChanges?: boolean; isReleasePreview?: boolean; }>): RestResponse<SecuredPagedResultAndInfo<stages.management.interfaces.ProcessInterfaceWorkspace, stages.management.interfaces.ProcessInterfaceWorkspaceSelectionOverview[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/interfaces/${workspaceId}/workspaces`, queryParams: queryParams });
    }
}

export class ProcessReleaseAdminResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /administration/processrelease/editinghelp
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getEditingHelp
     */
    getEditingHelp(): RestResponse<stages.administration.processrelease.EditingHelpItem[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/editinghelp` });
    }

    /**
     * HTTP PUT /administration/processrelease/stakeholder
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.addStakeholder
     */
    addStakeholder(queryParams?: NullableProperties<{ name?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/stakeholder`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /administration/processrelease/stakeholder
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.deleteStakeholders
     */
    deleteStakeholders(stakeholderIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/processrelease/stakeholder`, data: stakeholderIds });
    }

    /**
     * HTTP GET /administration/processrelease/stakeholder
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getStakeholders
     */
    getStakeholders(queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/stakeholder`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/processrelease/stakeholder/{id}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.renameStakeholder
     */
    renameStakeholder(id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/stakeholder/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/processrelease/stakeholder/{id}/assignUsers
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.assignUsers
     */
    assignUsers(id: string, userIds: string[]): RestResponse<stages.administration.processrelease.AlreadyAssignedUsers> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/stakeholder/${id}/assignUsers`, data: userIds });
    }

    /**
     * HTTP POST /administration/processrelease/stakeholder/{id}/unassignUsers
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.unassignUsers
     */
    unassignUsers(id: string, userIds: string[], queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/stakeholder/${id}/unassignUsers`, queryParams: queryParams, data: userIds });
    }

    /**
     * HTTP PUT /administration/processrelease/state
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.addState
     */
    addState(state: stages.administration.processrelease.EditableState, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayState>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/state`, queryParams: queryParams, data: state });
    }

    /**
     * HTTP DELETE /administration/processrelease/state
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.deleteStates
     */
    deleteStates(stateIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/processrelease/state`, data: stateIds });
    }

    /**
     * HTTP GET /administration/processrelease/state
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getStates
     */
    getStates(queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayState>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/state`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/processrelease/state/initial
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.setInitialState
     */
    setInitialState(stateId: string): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/state/initial`, data: stateId });
    }

    /**
     * HTTP POST /administration/processrelease/state/{id}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.editState
     */
    editState(id: string, state: stages.administration.processrelease.EditableState, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayState>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/state/${id}`, queryParams: queryParams, data: state });
    }

    /**
     * HTTP GET /administration/processrelease/state/{id}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getState
     */
    getState(id: string): RestResponse<stages.administration.processrelease.EditableState> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/state/${id}` });
    }

    /**
     * HTTP PUT /administration/processrelease/state/{id}/rename
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.renameState
     */
    renameState(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; name?: string; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayState>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/state/${id}/rename`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/processrelease/workflow
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.addWorkflow
     */
    addWorkflow(queryParams?: NullableProperties<{ name?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayWorkflow>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/workflow`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /administration/processrelease/workflow
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.deleteWorkflows
     */
    deleteWorkflows(workflowIds: string[]): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/processrelease/workflow`, data: workflowIds });
    }

    /**
     * HTTP GET /administration/processrelease/workflow
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getWorkflows
     */
    getWorkflows(queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayWorkflow>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/processrelease/workflow/import
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.importWorkflow
     */
    importWorkflow(formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/workflow/import`, data: formData });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getWorkflow
     */
    getWorkflow(id: string): RestResponse<stages.administration.processrelease.DisplayWorkflow> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}` });
    }

    /**
     * HTTP POST /administration/processrelease/workflow/{id}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.renameWorkflow
     */
    renameWorkflow(id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/workflow/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/export
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.exportWorkflow
     */
    exportWorkflow(id: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/export` });
    }

    /**
     * HTTP PUT /administration/processrelease/workflow/{id}/step
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.addStep
     */
    addStep(id: string, step: stages.administration.processrelease.Step): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/workflow/${id}/step`, data: step });
    }

    /**
     * HTTP DELETE /administration/processrelease/workflow/{id}/step
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.deleteSteps
     */
    deleteSteps(id: string, stepIds: string[], queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStep>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/processrelease/workflow/${id}/step`, queryParams: queryParams, data: stepIds });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/step/template
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getStepTemplate
     */
    getStepTemplate(id: string, queryParams?: NullableProperties<{ workspaceId?: string; type?: string; }>): RestResponse<stages.administration.processrelease.Step> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/step/template`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/step/types
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getStepTypeOptions
     */
    getStepTypeOptions(id: string): RestResponse<stages.administration.processrelease.Group<stages.Option>[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/step/types` });
    }

    /**
     * HTTP POST /administration/processrelease/workflow/{id}/step/{stepid}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.editStep
     */
    editStep(id: string, stepid: string, step: stages.administration.processrelease.Step): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}`, data: step });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/step/{stepid}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getStep
     */
    getStep(id: string, stepid: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<stages.administration.processrelease.Step> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/processrelease/workflow/{id}/step/{stepid}/next
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.addNext
     */
    addNext(id: string, stepid: string, successor: stages.administration.processrelease.TransitionWithTarget): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/next`, data: successor });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/step/{stepid}/next/template
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getNextTemplate
     */
    getNextTemplate(id: string, stepid: string, queryParams?: NullableProperties<{ workspaceId?: string; type?: string; }>): RestResponse<stages.administration.processrelease.TransitionWithTarget> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/next/template`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /administration/processrelease/workflow/{id}/step/{stepid}/transition
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.addTransition
     */
    addTransition(id: string, stepid: string, tansition: stages.administration.processrelease.Transition): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/transition`, data: tansition });
    }

    /**
     * HTTP DELETE /administration/processrelease/workflow/{id}/step/{stepid}/transition
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.deleteTransitions
     */
    deleteTransitions(id: string, stepid: string, transitionIds: string[], queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStep>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/transition`, queryParams: queryParams, data: transitionIds });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/step/{stepid}/transition/template
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getTransitionTemplate
     */
    getTransitionTemplate(id: string, stepid: string): RestResponse<stages.administration.processrelease.Transition> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/transition/template` });
    }

    /**
     * HTTP POST /administration/processrelease/workflow/{id}/step/{stepid}/transition/{transitionid}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.editTransition
     */
    editTransition(id: string, stepid: string, transitionid: string, transition: stages.administration.processrelease.Transition): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/transition/${transitionid}`, data: transition });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/step/{stepid}/transition/{transitionid}
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getTransition
     */
    getTransition(id: string, stepid: string, transitionid: string): RestResponse<stages.administration.processrelease.Transition> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/step/${stepid}/transition/${transitionid}` });
    }

    /**
     * HTTP GET /administration/processrelease/workflow/{id}/steps
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.getSteps
     */
    getSteps(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStep>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/processrelease/workflow/${id}/steps`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/processrelease/workflow/{id}/steps/rearrange
     * Java method: de.methodpark.stages.webapp.administration.processrelease.main.ProcessReleaseAdminResource.rearrange
     */
    rearrange(id: string, sortedStepIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/processrelease/workflow/${id}/steps/rearrange`, data: sortedStepIds });
    }
}

export class ProcessReleaseResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/active
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getActiveWorkflows
     */
    getActiveWorkflows(workspaceId: string): RestResponse<stages.process.release.WorkflowInstance[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/active` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/currentProcessId/{processId}/collectReleaseDetailsPaged
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getCollectedReleaseDetailsByProcessIdPaged
     */
    getCollectedReleaseDetailsByProcessIdPaged(workspaceId: string, processId: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; getModules?: boolean; excludeSystemTasks?: boolean; }>): RestResponse<SecuredPagedResult<stages.process.release.ReleaseDetailsPaged>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/currentProcessId/${processId}/collectReleaseDetailsPaged`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/history
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getApprovalHistoryForProcessVersion
     */
    getApprovalHistoryForProcessVersion(workspaceId: string, queryParams?: NullableProperties<{ processId?: string; }>): RestResponse<stages.process.release.ApprovalHistory[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/history`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/task/{taskId}
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getTaskDetails
     */
    getTaskDetails(workspaceId: string, taskId: string): RestResponse<stages.process.release.TaskDetails> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/task/${taskId}` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/release/task/{taskId}/claim
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.claimTask
     */
    claimTask(workspaceId: string, taskId: string): RestResponse<stages.process.release.WorkflowInstance[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/release/task/${taskId}/claim` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/release/task/{taskId}/complete
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.completeTask
     */
    completeTask(workspaceId: string, taskId: string, comment: string, queryParams?: NullableProperties<{ transitionId?: string; scheduledProcessReleaseDate?: Date; }>): RestResponse<stages.process.release.WorkflowInstance[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/release/task/${taskId}/complete`, queryParams: queryParams, data: comment });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/release/task/{taskId}/reassign
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.reassignTask
     */
    reassignTask(workspaceId: string, taskId: string, queryParams?: NullableProperties<{ userId?: string; }>): RestResponse<stages.process.release.WorkflowInstance[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/release/task/${taskId}/reassign`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/release/{workflowId}/instance/{instanceId}/stop
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.stopWorkflowInstance
     */
    stopWorkflowInstance(workspaceId: string, workflowId: string, instanceId: string): RestResponse<stages.process.release.WorkflowInstance[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/release/${workflowId}/instance/${instanceId}/stop` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/{workflowId}/name
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getWorkflowName
     */
    getWorkflowName(workspaceId: string, workflowId: string): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/${workflowId}/name` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/{workflowId}/parameters
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getWorkflowParameters
     */
    getWorkflowParameters(workspaceId: string, workflowId: string): RestResponse<stages.process.release.DisplayWorkflowParameter[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/${workflowId}/parameters` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/release/{workflowId}/scopetype
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.getScopeTypeOfWorkflow
     */
    getScopeTypeOfWorkflow(workspaceId: string, workflowId: string): RestResponse<stages.workflowservice.subcomponent.runtime.core.ScopeType> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/release/${workflowId}/scopetype` });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/release/{workflowId}/start
     * Java method: de.methodpark.stages.webapp.process.release.main.ProcessReleaseResource.startWorkflow
     */
    startWorkflow(workspaceId: string, workflowId: string, startParameters: stages.process.release.StartParameters, queryParams?: NullableProperties<{ processId?: string; pv?: string; }>): RestResponse<stages.process.release.WorkflowInstance[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/release/${workflowId}/start`, queryParams: queryParams, data: startParameters });
    }
}

export class ProcessResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/comparebaselines
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.getCompareBaselines
     */
    getCompareBaselines(workspaceId: string, queryParams?: NullableProperties<{ compareWorkspaceId?: string; pv?: string; }>): RestResponse<stages.process.CompareBaselines> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/comparebaselines`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/create
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.createProcess
     */
    createProcess(workspaceId: string): RestResponse<stages.process.ProcessVersion> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/create` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/resolve
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.resolveTextualReference
     */
    resolveTextualReference(workspaceId: string, queryParams?: NullableProperties<{ tref?: string; pv?: string; lang?: java.util.Locale; }>): RestResponse<stages.process.ResolvedTextualReference> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/resolve`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/resolveRedirect
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.resolveTextualReferenceAndRedirect
     */
    resolveTextualReferenceAndRedirect(workspaceId: string, queryParams?: NullableProperties<{ tref?: string; pv?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/resolveRedirect`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/tailor
     * Java method: de.methodpark.stages.webapp.process.main.ProcessManualTailoringResource.tailor
     */
    tailor$POST$workspace_workspaceId_process_tailor(workspaceId: string, tailoringData: stages.process.TailoringData, queryParams?: NullableProperties<{ pv?: string; status?: number; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/tailor`, queryParams: queryParams, data: tailoringData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/tailor/preview
     * Java method: de.methodpark.stages.webapp.process.main.ProcessManualTailoringResource.preview
     */
    preview$POST$workspace_workspaceId_process_tailor_preview(workspaceId: string, tailoringData: stages.process.TailoringData, queryParams?: NullableProperties<{ pv?: string; status?: number; }>): RestResponse<stages.process.tailoring.ManualTailoringPreview> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/tailor/preview`, queryParams: queryParams, data: tailoringData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/tailoringreset
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.reset
     */
    reset(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/tailoringreset`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/tailoringwizard
     * Java method: de.methodpark.stages.webapp.process.main.ProcessTailoringWizardResource.getTailoringWizard
     */
    getTailoringWizard(workspaceId: string, queryParams?: NullableProperties<{ secondaryWorkspaceId?: string; pv?: string; }>): RestResponse<stages.process.tailoring.wizard.WizardRoot> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/tailoringwizard`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/tailoringwizard
     * Java method: de.methodpark.stages.webapp.process.main.ProcessTailoringWizardResource.tailor
     */
    tailor$POST$workspace_workspaceId_process_tailoringwizard(workspaceId: string, answeredQueries: stages.process.tailoring.wizard.AnsweredQuery[], queryParams?: NullableProperties<{ secondaryWorkspaceId?: string; pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/tailoringwizard`, queryParams: queryParams, data: answeredQueries });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/tailoringwizard/preview
     * Java method: de.methodpark.stages.webapp.process.main.ProcessTailoringWizardResource.preview
     */
    preview$POST$workspace_workspaceId_process_tailoringwizard_preview(workspaceId: string, answeredQueries: stages.process.tailoring.wizard.AnsweredQuery[], queryParams?: NullableProperties<{ secondaryWorkspaceId?: string; pv?: string; }>): RestResponse<stages.process.tailoring.wizard.TailoringWizardPreview> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/tailoringwizard/preview`, queryParams: queryParams, data: answeredQueries });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/versions
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.getProcessVersions
     */
    getProcessVersions$GET$workspace_workspaceId_process_versions(workspaceId: string): RestResponse<stages.process.ProcessVersion[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/versions` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/versions/{type}/{identity}
     * Java method: de.methodpark.stages.webapp.process.main.ProcessResource.getProcessVersions
     */
    getProcessVersions$GET$workspace_workspaceId_process_versions_type_identity(workspaceId: string, type: string, identity: string): RestResponse<{ [index: string]: stages.process.ProcessVersion[] }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/versions/${type}/${identity}` });
    }
}

export class ProcessSettingsManagementResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /management/settings/workspace/{workspaceId}/pv/{pv}
     * Java method: de.methodpark.stages.webapp.management.settings.main.ProcessSettingsManagementResource.getSettingsAndInfo
     */
    getSettingsAndInfo(workspaceId: string, pv: string): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/settings/workspace/${workspaceId}/pv/${pv}` });
    }

    /**
     * HTTP PUT /management/settings/workspace/{workspaceId}/pv/{pv}
     * Java method: de.methodpark.stages.webapp.management.settings.main.ProcessSettingsManagementResource.saveSettings
     */
    saveSettings(workspaceId: string, pv: string, newSettings: stages.process.subcomponent.management.Settings): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`management/settings/workspace/${workspaceId}/pv/${pv}`, data: newSettings });
    }
}

export class ProcessVersionsManagementResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.createProcessVersion
     */
    createProcessVersion(workspaceId: string, settings: stages.management.processversions.ProcessVersionCreationSettings): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}`, data: settings });
    }

    /**
     * HTTP DELETE /management/versions/workspace/{workspaceId}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.deleteProcess
     */
    deleteProcess(workspaceId: string): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`management/versions/workspace/${workspaceId}` });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getProcessVersions
     */
    getProcessVersions(workspaceId: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResultAndInfo<stages.management.processversions.ProcessVersion, stages.management.processversions.main.ProcessVersionsManagementResourceProcessVersionProgressInfo>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/creatableStates
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getCreatableProcessVersionStates
     */
    getCreatableProcessVersionStates(workspaceId: string): RestResponse<stages.administration.processrelease.DisplayState[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/creatableStates` });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/diff/{targetWorkspaceId}/{targetProcessVersion}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.diff
     */
    diff(workspaceId: string, targetWorkspaceId: string, targetProcessVersion: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/diff/${targetWorkspaceId}/${targetProcessVersion}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/process/{processId}/description
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getDescription
     */
    getDescription(workspaceId: string, processId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.format.DisplayDescription> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/process/${processId}/description`, queryParams: queryParams });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/process/{processId}/description
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.setDescription
     */
    setDescription(workspaceId: string, processId: string, description: stages.core.format.DisplayDescription, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/process/${processId}/description`, queryParams: queryParams, data: description });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/process/{processId}/state
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.setState
     */
    setState(workspaceId: string, processId: string, settings: stages.management.processversions.SetStateSettings, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/process/${processId}/state`, queryParams: queryParams, data: settings });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/process/{processId}/vv
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getReleaseAsValidVersionProperties
     */
    getReleaseAsValidVersionProperties(workspaceId: string, processId: string): RestResponse<stages.management.processversions.SetStateSettingsAndInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/process/${processId}/vv` });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/process/{processId}/vv
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.releaseAsValidVersion
     */
    releaseAsValidVersion(workspaceId: string, processId: string, settings: stages.management.processversions.SetStateSettings, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/process/${processId}/vv`, queryParams: queryParams, data: settings });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/releaseinterfaces
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.releaseInterfaces
     */
    releaseInterfaces(workspaceId: string, settings: stages.management.processversions.CreateProcessInterfaceVersionSettings): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/releaseinterfaces`, data: settings });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/stakeholder/{id}/assignUsers
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.assignUsersToStakeholdersInWorkspace
     */
    assignUsersToStakeholdersInWorkspace(workspaceId: string, id: string, userIds: string[], queryParams?: NullableProperties<{ page?: number; pageSize?: number; isWorkflowStart?: boolean; workflowId?: string; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/stakeholder/${id}/assignUsers`, queryParams: queryParams, data: userIds });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/stakeholder/{id}/resetUsersToDefault
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.resetUsersToStakeholdersInWorkspace
     */
    resetUsersToStakeholdersInWorkspace(workspaceId: string, id: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; isWorkflowStart?: boolean; workflowId?: string; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/stakeholder/${id}/resetUsersToDefault`, queryParams: queryParams });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/stakeholder/{id}/unassignUsers
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.unassignUsersToStakeholdersInWorkspace
     */
    unassignUsersToStakeholdersInWorkspace(workspaceId: string, id: string, userIds: string[], queryParams?: NullableProperties<{ page?: number; pageSize?: number; isWorkflowStart?: boolean; workflowId?: string; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/stakeholder/${id}/unassignUsers`, queryParams: queryParams, data: userIds });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/stakeholders
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getStakeholdersInWorkspace
     */
    getStakeholdersInWorkspace(workspaceId: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; isWorkflowStart?: boolean; workflowId?: string; remoteWorkspaceIds?: string[]; }>): RestResponse<SecuredPagedResult<stages.administration.processrelease.DisplayStakeholder>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/stakeholders`, queryParams: queryParams });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/updateVVConsiderFrozen
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.updateValidVersionConsiderFrozen
     */
    updateValidVersionConsiderFrozen(workspaceId: string, otherWorkspaceIdsToUpdate: string[], queryParams?: NullableProperties<{ newValidVersionName?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/updateVVConsiderFrozen`, queryParams: queryParams, data: otherWorkspaceIdsToUpdate });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/validateProcessVersionName
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.validateProcessVersionName
     */
    validateProcessVersionName(workspaceId: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/validateProcessVersionName`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/validationReport
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.validateProcess
     */
    validateProcess(workspaceId: string): RestResponse<stages.core.scheduler.JobIdentifier[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/validationReport` });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/validators
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getValidatorsForProcess
     */
    getValidatorsForProcess(workspaceId: string): RestResponse<stages.process.validation.ValidatorType[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/validators` });
    }

    /**
     * HTTP GET /management/versions/workspace/{workspaceId}/validity
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.getValidities
     */
    getValidities(workspaceId: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.management.processversions.Validity>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/workspace/${workspaceId}/validity`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /management/versions/workspace/{workspaceId}/version/{versionId}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.saveRenamedProcessVersion
     */
    saveRenamedProcessVersion(workspaceId: string, versionId: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`management/versions/workspace/${workspaceId}/version/${versionId}`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /management/versions/workspace/{workspaceId}/versions
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.deleteProcessVersions
     */
    deleteProcessVersions(workspaceId: string, idsOrOperationNames: string[], queryParams?: NullableProperties<{ page?: number; pageSize?: number; pv?: string; }>): RestResponse<SecuredPagedResultAndInfo<stages.management.processversions.ProcessVersion, stages.management.processversions.main.ProcessVersionsManagementResourceProcessVersionProgressInfo>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`management/versions/workspace/${workspaceId}/versions`, queryParams: queryParams, data: idsOrOperationNames });
    }

    /**
     * HTTP POST /management/versions/workspace/{workspaceId}/wr
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.replaceWorkingVersion
     */
    replaceWorkingVersion(workspaceId: string, processId: string): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/workspace/${workspaceId}/wr`, data: processId });
    }

    /**
     * HTTP POST /management/versions/{id}/archive
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.archive
     */
    archive(id: string, archiveForm: stages.management.processversions.ArchiveForm, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/${id}/archive`, queryParams: queryParams, data: archiveForm });
    }

    /**
     * HTTP GET /management/versions/{id}/downloadarchive/{tempFileName}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.downloadArchive
     */
    downloadArchive(id: string, tempFileName: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/${id}/downloadarchive/${tempFileName}` });
    }

    /**
     * HTTP GET /management/versions/{id}/export
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.exportProcess
     */
    exportProcess(id: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/${id}/export`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/versions/{id}/exportForTranslation
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.exportProcessForTranslation
     */
    exportProcessForTranslation(id: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/versions/${id}/exportForTranslation` });
    }

    /**
     * HTTP POST /management/versions/{workspaceId}/importTranslation/import/{targetLanguage}
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.importTranslation
     */
    importTranslation(workspaceId: string, targetLanguage: string, queryParams?: NullableProperties<{ uploadId?: string; overwrite?: boolean; }>): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/${workspaceId}/importTranslation/import/${targetLanguage}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /management/versions/{workspaceId}/importTranslation/upload
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.uploadTranslation
     */
    uploadTranslation(workspaceId: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.file.Upload> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/${workspaceId}/importTranslation/upload`, data: formData });
    }

    /**
     * HTTP POST /management/versions/{workspaceId}/replace
     * Java method: de.methodpark.stages.webapp.management.processversions.main.ProcessVersionsManagementResource.importProcess
     */
    importProcess(workspaceId: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.core.scheduler.JobIdentifier> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/versions/${workspaceId}/replace`, data: formData });
    }
}

export class ProgressMonitorServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /progress
     * Java method: de.methodpark.stages.webapp.common.main.ProgressMonitorService.getProgressInfo
     */
    getProgressInfo(queryParams?: NullableProperties<{ operationType?: string; workspaceId?: string; }>): RestResponse<stages.common.ProgressInfo[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`progress`, queryParams: queryParams });
    }

    /**
     * HTTP GET /progress/latest
     * Java method: de.methodpark.stages.webapp.common.main.ProgressMonitorService.getLatestJob
     */
    getLatestJob(queryParams?: NullableProperties<{ operationType?: string[]; workspaceId?: string; }>): RestResponse<stages.common.ProgressInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`progress/latest`, queryParams: queryParams });
    }

    /**
     * HTTP GET /progress/{jobName}
     * Java method: de.methodpark.stages.webapp.common.main.ProgressMonitorService.getCurrentProgress
     */
    getCurrentProgress(jobName: string, queryParams?: NullableProperties<{ operationType?: string; workspaceId?: string; }>): RestResponse<stages.common.ProgressInfo> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`progress/${jobName}`, queryParams: queryParams });
    }
}

export class ReportingResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/reporting/delete
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.delete
     */
    delete(workspaceId: string, entries: stages.core.TypedId[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/reporting/delete`, data: entries });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/reporting/move
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.moveNavigationEntry
     */
    moveNavigationEntry(workspaceId: string, entries: stages.core.TypedId[], queryParams?: NullableProperties<{ newParentId?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/reporting/move`, queryParams: queryParams, data: entries });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/reporting/report/getexport
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.downloadExportFile
     */
    downloadExportFile(workspaceId: string, queryParams?: NullableProperties<{ fileType?: string; reportName?: string; downloadKey?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/reporting/report/getexport`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/reporting/report/{id}/edit
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.getEditableReport
     */
    getEditableReport(workspaceId: string, id: string): RestResponse<stages.reporting.EditableReport> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/reporting/report/${id}/edit` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/reporting/report/{id}/edit
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.saveEditedReport
     */
    saveEditedReport(workspaceId: string, id: string, editableReport: stages.reporting.EditableReport): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/reporting/report/${id}/edit`, data: editableReport });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/reporting/report/{id}/script
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.download
     */
    download(workspaceId: string, id: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/reporting/report/${id}/script` });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/reporting/report/{reportId}/prepareexport
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.prepareExport
     */
    prepareExport(workspaceId: string, reportId: string, parameters: { [index: string]: any }, queryParams?: NullableProperties<{ pv?: string; fileType?: string; reportName?: string; cookieName?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/reporting/report/${reportId}/prepareexport`, queryParams: queryParams, data: parameters });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/reporting/report/{reportId}/render
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.renderReport
     */
    renderReport(workspaceId: string, reportId: string, parameters: { [index: string]: any }, queryParams?: NullableProperties<{ pv?: string; fileType?: string; reportName?: string; }>): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/reporting/report/${reportId}/render`, queryParams: queryParams, data: parameters });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/reporting/report/{reportId}/script/parameters
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.getScriptParameters
     */
    getScriptParameters(workspaceId: string, reportId: string, selectedCascadingGroupValues: { [index: string]: any[] }, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.reporting.ParameterGroup[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/reporting/report/${reportId}/script/parameters`, queryParams: queryParams, data: selectedCascadingGroupValues });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/reporting/report/{reportId}/script/parameters/cascadinggroup/{groupName}/nextchoice
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.getNextCascadingSelectionChoices
     */
    getNextCascadingSelectionChoices(workspaceId: string, reportId: string, groupName: string, selectedValues: any[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<{ [index: string]: stages.reporting.NextCascadingSelectionChoices }> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/reporting/report/${reportId}/script/parameters/cascadinggroup/${groupName}/nextchoice`, queryParams: queryParams, data: selectedValues });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/reporting/{id}/description
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.updateDescription
     */
    updateDescription(workspaceId: string, id: string, queryParams?: NullableProperties<{ entityName?: string; entityDescription?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/reporting/${id}/description`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/reporting/{type}
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.createNewChild
     */
    createNewChild(workspaceId: string, type: string, queryParams?: NullableProperties<{ name?: string; parentId?: string; }>): RestResponse<CurrentNavigationEntry<stages.reporting.View, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/reporting/${type}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/reporting/{type}/{id}
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.getNavigationEntry
     */
    getNavigationEntry(workspaceId: string, type: string, id: string): RestResponse<CurrentNavigationEntry<stages.reporting.View, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/reporting/${type}/${id}` });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/reporting/{type}/{id}
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.updateChild
     */
    updateChild(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ name?: string; parentId?: string; }>): RestResponse<CurrentNavigationEntry<stages.reporting.View, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/reporting/${type}/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/reporting/{type}/{id}/rename
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.renameNavigationEntry
     */
    renameNavigationEntry(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/reporting/${type}/${id}/rename`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/reporting/{type}/{id}/script
     * Java method: de.methodpark.stages.webapp.reporting.facadeimpl.ReportingResource.uploadScript
     */
    uploadScript(workspaceId: string, type: string, id: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/reporting/${type}/${id}/script`, queryParams: queryParams, data: formData });
    }
}

export class RepositoryManagementResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /workspace/{workspaceId}/process/management/files/repositories
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.addRepository
     */
    addRepository(workspaceId: string, repository: stages.management.files.repository.Repository, queryParams?: NullableProperties<{ pv?: string; redirectUrl?: string; }>): RestResponse<stages.management.files.repository.RepositoryOverview> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories`, queryParams: queryParams, data: repository });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/management/files/repositories
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.deleteRepositories
     */
    deleteRepositories(workspaceId: string, repositoryIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories`, data: repositoryIds });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/files/repositories
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.getRepositories
     */
    getRepositories(workspaceId: string, queryParams?: NullableProperties<{ scope?: string; pv?: string; redirectUrl?: string; }>): RestResponse<stages.management.files.repository.RepositoriesOverview> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/files/repositories/configurations/{scope}/{typeIdent}
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.getConfiguration
     */
    getConfiguration(workspaceId: string, scope: string, typeIdent: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.files.repository.Configuration> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories/configurations/${scope}/${typeIdent}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/management/files/repositories/default/{scope}
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.setDefaultRepository
     */
    setDefaultRepository(workspaceId: string, scope: string, id: string): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories/default/${scope}`, data: id });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/process/management/files/repositories/{id}
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.deleteRepository
     */
    deleteRepository(workspaceId: string, id: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories/${id}` });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/management/files/repositories/{id}
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.getRepository
     */
    getRepository(workspaceId: string, id: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.files.repository.Repository> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/management/files/repositories/{id}
     * Java method: de.methodpark.stages.webapp.management.files.repository.main.RepositoryManagementResource.updateRepository
     */
    updateRepository(workspaceId: string, id: string, repositoryUpdate: stages.management.files.repository.Repository, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.files.repository.RepositoryOverview> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/management/files/repositories/${id}`, queryParams: queryParams, data: repositoryUpdate });
    }
}

export class ScriptServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /administration/script
     * Java method: de.methodpark.stages.webapp.administration.scriptconsole.main.ScriptService.executeScript
     */
    executeScript(script: string, queryParams?: NullableProperties<{ workspaceId?: string; pv?: string; }>): RestResponse<string> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/script`, queryParams: queryParams, data: script });
    }
}

export class SearchQueryServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /query/findAssociationTargetsInSelectedProcesses
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findAssociationTargetsInSelectedProcesses
     */
    findAssociationTargetsInSelectedProcesses(associatedElements: stages.core.TypedIdentity[], queryParams?: NullableProperties<{ query?: string; type?: string; subtypes?: string[]; workspaceId?: string; pv?: string; processIds?: string[]; startFrom?: number; documentTypes?: string[]; searchRelatedWorkspaces?: boolean; }>): RestResponse<unknown[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`query/findAssociationTargetsInSelectedProcesses`, queryParams: queryParams, data: associatedElements });
    }

    /**
     * HTTP POST /query/findComplianceAssociationTargetsInProcessModel
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findComplianceAssociationTargetsInProcessModel
     */
    findComplianceAssociationTargetsInProcessModel(alreadyAssociatedElements: stages.core.TypedId[], queryParams?: NullableProperties<{ query?: string; type?: string; processModelWorkspaceId?: string; processModelProcessVersion?: string; }>): RestResponse<unknown[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`query/findComplianceAssociationTargetsInProcessModel`, queryParams: queryParams, data: alreadyAssociatedElements });
    }

    /**
     * HTTP POST /query/findComplianceAssociationTargetsInReferenceModel
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findComplianceAssociationTargetsInReferenceModel
     */
    findComplianceAssociationTargetsInReferenceModel(alreadyAssociatedElements: stages.core.TypedId[], queryParams?: NullableProperties<{ query?: string; referenceModelWorkspaceId?: string; }>): RestResponse<unknown[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`query/findComplianceAssociationTargetsInReferenceModel`, queryParams: queryParams, data: alreadyAssociatedElements });
    }

    /**
     * HTTP POST /query/findDependentAssociationTargets
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findDependentAssociationTargets
     */
    findDependentAssociationTargets(associatedElements: stages.core.TypedIdentity[], queryParams?: NullableProperties<{ query?: string; type?: string; containerElementIdentity?: string; workspaceId?: string; pv?: string; processIds?: string[]; searchRelatedWorkspaces?: boolean; ignoreTailoringStatus?: boolean; ignoreAssignable?: boolean; }>): RestResponse<unknown[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`query/findDependentAssociationTargets`, queryParams: queryParams, data: associatedElements });
    }

    /**
     * HTTP GET /query/findElementsByName
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findElementsByName
     */
    findElementsByName(queryParams?: NullableProperties<{ query?: string; searchWorkspaceId?: string; swid?: string; spv?: string; smode?: string; typeFilter?: string[]; startFrom?: number; size?: number; searchPv?: string; }>): RestResponse<unknown[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/findElementsByName`, queryParams: queryParams });
    }

    /**
     * HTTP GET /query/findRolesByName
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findRolesByName
     */
    findRolesByName(queryParams?: NullableProperties<{ query?: string; startFrom?: number; size?: number; }>): RestResponse<{ [index: string]: any }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/findRolesByName`, queryParams: queryParams });
    }

    /**
     * HTTP GET /query/findScopes
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findScopes
     */
    findScopes(queryParams?: NullableProperties<{ query?: string; workspaceId?: string; pv?: string; startFrom?: number; size?: number; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/findScopes`, queryParams: queryParams });
    }

    /**
     * HTTP GET /query/findSteakholdersByName
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findStakeholdersByName
     */
    findStakeholdersByName(queryParams?: NullableProperties<{ term?: string; size?: number; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/findSteakholdersByName`, queryParams: queryParams });
    }

    /**
     * HTTP POST /query/findTailoringAssociationTargets
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findTailoringAssociationTargets
     */
    findTailoringAssociationTargets(associatedElements: stages.core.TypedId[], queryParams?: NullableProperties<{ query?: string; type?: string; subtypes?: string[]; searchWorkspaceId?: string; startFrom?: number; searchPv?: string; ignoreTailoring?: boolean; documentTypes?: string[]; }>): RestResponse<unknown[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`query/findTailoringAssociationTargets`, queryParams: queryParams, data: associatedElements });
    }

    /**
     * HTTP GET /query/findWorkspaceCollectionsByName
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.findWorkspaceCollectionsByName
     */
    findWorkspaceCollectionsByName(queryParams?: NullableProperties<{ term?: string; size?: number; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/findWorkspaceCollectionsByName`, queryParams: queryParams });
    }

    /**
     * HTTP GET /query/globalsearch
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.searchThroughWorkspaces
     */
    searchThroughWorkspaces(queryParams?: NullableProperties<{ query?: string; workspaceId?: string; swid?: string; spv?: string; smode?: string; workspaceFilter?: string; typeFilter?: string[]; subtypeFilter?: string[]; startFrom?: number; size?: number; searchPv?: string; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/globalsearch`, queryParams: queryParams });
    }

    /**
     * HTTP GET /query/overview
     * Java method: de.methodpark.stages.webapp.search.main.SearchQueryService.getSearchQueryOverview
     */
    getSearchQueryOverview(queryParams?: NullableProperties<{ query?: string; workspaceId?: string; swid?: string; spv?: string; smode?: string; searchPv?: string; workspaceFilter?: string; }>): RestResponse<unknown> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`query/overview`, queryParams: queryParams });
    }
}

export class SqlServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /administration/sql
     * Java method: de.methodpark.stages.webapp.administration.sqlconsole.main.SqlService.executeSql
     */
    executeSql(sql: string): RestResponse<stages.administration.sqlconsole.ResultContainer> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/sql`, data: sql });
    }

    /**
     * HTTP GET /administration/sql/version
     * Java method: de.methodpark.stages.webapp.administration.sqlconsole.main.SqlService.getVersion
     */
    getVersion(): RestResponse<number> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/sql/version` });
    }
}

export class SupportInfoResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /supportinfo
     * Java method: de.methodpark.stages.webapp.support.facadeimpl.SupportInfoResource.download
     */
    download(): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`supportinfo` });
    }

    /**
     * HTTP POST /supportinfo
     * Java method: de.methodpark.stages.webapp.support.facadeimpl.SupportInfoResource.send
     */
    send(formData: { [index: string]: string }): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`supportinfo`, data: formData });
    }
}

export class TailoringResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/management/tailorings
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.deleteTailoringResources
     */
    deleteTailoringResources(workspaceId: string, typedIds: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/management/tailorings`, queryParams: queryParams, data: typedIds });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/tailorings
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.getRootLevel
     */
    getRootLevel(workspaceId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<CurrentNavigationEntry<stages.management.tailoring.TailoringResource, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/tailorings`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspace/{workspaceId}/management/tailorings/associations
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.deleteAssociations
     */
    deleteAssociations(workspaceId: string, queryParams?: NullableProperties<{ associationIds?: string[]; pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspace/${workspaceId}/management/tailorings/associations`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/copy/{id}
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.copy
     */
    copy(workspaceId: string, id: string, ids: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/copy/${id}`, queryParams: queryParams, data: ids });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/move/{id}
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.move
     */
    move(workspaceId: string, id: string, ids: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/move/${id}`, queryParams: queryParams, data: ids });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/overwrite
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.overwrite
     */
    overwrite(workspaceId: string, elementIds: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/overwrite`, queryParams: queryParams, data: elementIds });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/restore
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.restore
     */
    restore(workspaceId: string, elementIds: stages.core.TypedId[], queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/restore`, queryParams: queryParams, data: elementIds });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/tailorings/{identity}
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.getTreeLevel
     */
    getTreeLevel(workspaceId: string, identity: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<CurrentNavigationEntry<stages.management.tailoring.TailoringResource, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${identity}`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspace/{workspaceId}/management/tailorings/{identity}/description
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringDescriptionResource.getDescriptionForEdit
     */
    getDescriptionForEdit(workspaceId: string, identity: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.Description> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${identity}/description`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/tailorings/{identity}/description
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringDescriptionResource.saveDescription
     */
    saveDescription(workspaceId: string, identity: string, description: stages.core.format.DisplayDescription, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.core.format.DisplayDescription> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${identity}/description`, queryParams: queryParams, data: description });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/{id}/rearrange
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.rearrange
     */
    rearrange(workspaceId: string, id: string, sortedChildIds: string[], queryParams?: NullableProperties<{ alphabetical?: boolean; pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${id}/rearrange`, queryParams: queryParams, data: sortedChildIds });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/tailorings/{id}/rename
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.renameTailoringResource
     */
    renameTailoringResource(workspaceId: string, id: string, queryParams?: NullableProperties<{ name?: string; pv?: string; }>): RestResponse<CurrentNavigationEntry<stages.management.tailoring.TailoringResource, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${id}/rename`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/management/tailorings/{id}/wizardTailoringMode
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.setWizardTailoringMode
     */
    setWizardTailoringMode(workspaceId: string, id: string, queryParams?: NullableProperties<{ mode?: stages.process.subcomponent.tailoring.core.WizardTailoringMode; pv?: string; }>): RestResponse<stages.management.tailoring.TailoringResource> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${id}/wizardTailoringMode`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/{parentId}/newChild
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.newChild
     */
    newChild(workspaceId: string, parentId: string, formData: { [index: string]: any }, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<CurrentNavigationEntry<stages.management.tailoring.TailoringResource, stages.core.metamodel.NamedType>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${parentId}/newChild`, queryParams: queryParams, data: formData });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/{tailoringResourceId}/associations/{associationPath}
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.updateAssociations
     */
    updateAssociations(workspaceId: string, tailoringResourceId: string, associationPath: string, assocContainer: stages.core.model.AssociationsContainer, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.tailoring.TailoringResource> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${tailoringResourceId}/associations/${associationPath}`, queryParams: queryParams, data: assocContainer });
    }

    /**
     * HTTP POST /workspace/{workspaceId}/management/tailorings/{tailoringResourceId}/associations/{associationSubtype}/{tailorableType}/{tailorableId}
     * Java method: de.methodpark.stages.webapp.management.tailoring.facadeimpl.TailoringResource.addAssociation
     */
    addAssociation(workspaceId: string, tailoringResourceId: string, associationSubtype: string, tailorableType: string, tailorableId: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.management.tailoring.TailoringAssociation> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspace/${workspaceId}/management/tailorings/${tailoringResourceId}/associations/${associationSubtype}/${tailorableType}/${tailorableId}`, queryParams: queryParams });
    }
}

export class TrackingServiceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /tracking/{requestId}.gif
     * Java method: de.methodpark.stages.trackingservice.main.TrackingService.getTrackingGif
     */
    getTrackingGif(requestId: string, queryParams?: NullableProperties<{ time?: number; viewport?: number; resolution?: number; }>): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`tracking/${requestId}.gif`, queryParams: queryParams });
    }
}

export class TranslateResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspace/{workspaceId}/process/translate/{type}/{identity}
     * Java method: de.methodpark.stages.webapp.process.translate.main.TranslateResource.getTranslation
     */
    getTranslation(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<stages.process.translate.Translation> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspace/${workspaceId}/process/translate/${type}/${identity}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /workspace/{workspaceId}/process/translate/{type}/{identity}/translate
     * Java method: de.methodpark.stages.webapp.process.translate.main.TranslateResource.saveTranslate
     */
    saveTranslate(workspaceId: string, type: string, identity: string, translatedElements: { [index: string]: stages.process.translate.TranslatedElement }, queryParams?: NullableProperties<{ pv?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspace/${workspaceId}/process/translate/${type}/${identity}/translate`, queryParams: queryParams, data: translatedElements });
    }
}

export class UserAdministrationResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /management/users
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.createUser
     */
    createUser(user: stages.administration.user.User): RestResponse<stages.administration.user.DisplayUser> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/users`, data: user });
    }

    /**
     * HTTP DELETE /management/users
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.deleteUsers
     */
    deleteUsers(userIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`management/users`, data: userIds });
    }

    /**
     * HTTP GET /management/users
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getDisplayUsers
     */
    getDisplayUsers(queryParams?: NullableProperties<{ page?: number; }>): RestResponse<SecuredPagedResult<stages.administration.user.DisplayUser>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/users/active
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getActiveUsers
     */
    getActiveUsers(queryParams?: NullableProperties<{ page?: number; }>): RestResponse<SecuredPagedResult<stages.administration.user.ActiveUser>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/active`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/users/search
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.searchUsersPaged
     */
    searchUsersPaged(queryParams?: NullableProperties<{ query?: string; }>): RestResponse<SecuredPagedResult<stages.administration.user.DisplayUser>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/search`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/users/template
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getUserTemplate
     */
    getUserTemplate(): RestResponse<stages.administration.user.User> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/template` });
    }

    /**
     * HTTP PUT /management/users/{id}
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.editUser
     */
    editUser(id: string, userData: stages.administration.user.User): RestResponse<stages.administration.user.DisplayUser> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`management/users/${id}`, data: userData });
    }

    /**
     * HTTP GET /management/users/{id}
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getEditableUser
     */
    getEditableUser(id: string): RestResponse<stages.administration.user.User> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/${id}` });
    }

    /**
     * HTTP GET /management/users/{id}/permissions
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getPermissions
     */
    getPermissions(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; groupBy?: stages.authorizationservice.subcomponent.administration.GroupBy; }>): RestResponse<PagedResult<stages.administration.permission.PermissionGroup<stages.administration.user.UserPermission>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/${id}/permissions`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/users/{id}/roles
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getRoles
     */
    getRoles(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.role.RoleAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/${id}/roles`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /management/users/{id}/sessions/{sessionId}
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.logout
     */
    logout(id: string, sessionId: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`management/users/${id}/sessions/${sessionId}` });
    }

    /**
     * HTTP GET /management/users/{id}/stakeholders
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getStakeholders
     */
    getStakeholders(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.stakeholder.UserStakeholderAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/${id}/stakeholders`, queryParams: queryParams });
    }

    /**
     * HTTP GET /management/users/{id}/usergroups
     * Java method: de.methodpark.stages.webapp.administration.user.main.UserAdministrationResource.getUserGroups
     */
    getUserGroups(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.UserGroupWithCounts>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/users/${id}/usergroups`, queryParams: queryParams });
    }
}

export class UserAssignmentsResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /userassignments/workspace/{workspaceId}/process/elements/{type}/{identity}
     * Java method: de.methodpark.stages.webapp.userassignments.main.UserAssignmentsResource.getPage
     */
    getPage(workspaceId: string, type: string, identity: string, queryParams?: NullableProperties<{ path?: string; page?: number; pageSize?: number; elementPageSize?: number; pv?: string; }>): RestResponse<PagedResult<stages.userassignments.UserAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`userassignments/workspace/${workspaceId}/process/elements/${type}/${identity}`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /userassignments/workspace/{workspaceId}/process/elements/{type}/{id}
     * Java method: de.methodpark.stages.webapp.userassignments.main.UserAssignmentsResource.unassign
     */
    unassign(workspaceId: string, type: string, id: string, queryParams?: NullableProperties<{ pv?: string; identity?: string; guids?: string[]; page?: number; pageSize?: number; firstPageSize?: number; elementPageSize?: number; }>): RestResponse<PagedResult<stages.userassignments.UserAssignment>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`userassignments/workspace/${workspaceId}/process/elements/${type}/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP PUT /userassignments/workspace/{workspaceId}/process/elements/{type}/{id}/comment
     * Java method: de.methodpark.stages.webapp.userassignments.main.UserAssignmentsResource.comment
     */
    comment(workspaceId: string, type: string, id: string, comment: string, queryParams?: NullableProperties<{ guid?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`userassignments/workspace/${workspaceId}/process/elements/${type}/${id}/comment`, queryParams: queryParams, data: comment });
    }

    /**
     * HTTP PUT /userassignments/workspace/{workspaceId}/process/elements/{type}/{id}/deputy
     * Java method: de.methodpark.stages.webapp.userassignments.main.UserAssignmentsResource.deputy
     */
    deputy(workspaceId: string, type: string, id: string, deputy: boolean, queryParams?: NullableProperties<{ guid?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`userassignments/workspace/${workspaceId}/process/elements/${type}/${id}/deputy`, queryParams: queryParams, data: deputy });
    }

    /**
     * HTTP POST /userassignments/workspace/{workspaceId}/process/elements/{type}/{id}/{userId}
     * Java method: de.methodpark.stages.webapp.userassignments.main.UserAssignmentsResource.assign
     */
    assign(workspaceId: string, type: string, id: string, userId: string, queryParams?: NullableProperties<{ pv?: string; identity?: string; page?: number; pageSize?: number; firstPageSize?: number; elementPageSize?: number; }>): RestResponse<PagedResult<stages.userassignments.UserAssignment>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`userassignments/workspace/${workspaceId}/process/elements/${type}/${id}/${userId}`, queryParams: queryParams });
    }
}

export class UserGroupResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /administration/usergroups
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.createUserGroup
     */
    createUserGroup(queryParams?: NullableProperties<{ name?: string; workspaceId?: string; }>): RestResponse<stages.administration.usergroup.UserGroup> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /administration/usergroups
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.deleteUserGroups
     */
    deleteUserGroups(userGroupIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/usergroups`, data: userGroupIds });
    }

    /**
     * HTTP GET /administration/usergroups
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getUserGroups
     */
    getUserGroups(queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<SecuredPagedResult<stages.administration.usergroup.UserGroupWithCounts>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/domains
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getPermissionDomains
     */
    getPermissionDomains(): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/domains` });
    }

    /**
     * HTTP GET /administration/usergroups/filter
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.filterUserPermissionGroups
     */
    filterUserPermissionGroups(queryParams?: NullableProperties<{ workspaceId?: string; term?: string; excludeDefault?: boolean; }>): RestResponse<SecuredPagedResult<stages.administration.usergroup.UserGroupWithCounts>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/filter`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/filterRoles
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.findRolesByNameAndGroupId
     */
    findRolesByNameAndGroupId(queryParams?: NullableProperties<{ query?: string; groupId?: string; workspaceId?: string; startFrom?: number; size?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.role.RoleAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/filterRoles`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/searchAssignable
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.searchAssignabelUserPermissionGroups
     */
    searchAssignabelUserPermissionGroups(queryParams?: NullableProperties<{ workspaceId?: string; term?: string; excludeDefault?: boolean; }>): RestResponse<SecuredPagedResult<stages.administration.usergroup.UserGroup>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/searchAssignable`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /administration/usergroups/{id}
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.deleteUserGroup
     */
    deleteUserGroup(id: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/usergroups/${id}` });
    }

    /**
     * HTTP GET /administration/usergroups/{id}
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getUserPermissionGroup
     */
    getUserPermissionGroup(id: string): RestResponse<stages.administration.usergroup.UserGroup> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}` });
    }

    /**
     * HTTP PUT /administration/usergroups/{id}
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.renameUserGroup
     */
    renameUserGroup(id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/usergroups/${id}`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/assignRoles
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.assignRoles
     */
    assignRoles(id: string, roleIdentities: stages.core.BaselinedIdentity[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/assignRoles`, data: roleIdentities });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/assignStakeholders
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.assignStakeholders
     */
    assignStakeholders(id: string, stakeholderIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/assignStakeholders`, data: stakeholderIds });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/assignUsers
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.assignUsers
     */
    assignUsers(id: string, userIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/assignUsers`, data: userIds });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/permissions
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.addPermission
     */
    addPermission(id: string, queryParams?: NullableProperties<{ domain?: string; fromRole?: boolean; workspaceId?: string; }>): RestResponse<stages.administration.permission.Permission> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/permissions`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/{id}/permissions
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getPermissions
     */
    getPermissions(id: string, queryParams?: NullableProperties<{ groupBy?: stages.authorizationservice.subcomponent.administration.GroupBy; }>): RestResponse<stages.administration.permission.PermissionGroup<stages.administration.permission.Permission>[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}/permissions`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/permissions/group
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.addPermissionGroup
     */
    addPermissionGroup(id: string, queryParams?: NullableProperties<{ domain?: string; }>): RestResponse<stages.administration.permission.PermissionGroup<stages.administration.permission.Permission>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/permissions/group`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /administration/usergroups/{id}/permissions/{permissionId}
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.deletePermission
     */
    deletePermission(id: string, permissionId: string): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`administration/usergroups/${id}/permissions/${permissionId}` });
    }

    /**
     * HTTP PUT /administration/usergroups/{id}/permissions/{permissionId}
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.updatePermission
     */
    updatePermission(id: string, permissionId: string, permissionToSave: stages.administration.permission.Permission): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`administration/usergroups/${id}/permissions/${permissionId}`, data: permissionToSave });
    }

    /**
     * HTTP GET /administration/usergroups/{id}/roles
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getRoles
     */
    getRoles(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.role.RoleAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}/roles`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/{id}/rolesForBrowse
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getRolesForBrowse
     */
    getRolesForBrowse(id: string, queryParams?: NullableProperties<{ browseWorkspaceId?: string; workspaceId?: string; }>): RestResponse<PagedResult<stages.administration.usergroup.role.RoleAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}/rolesForBrowse`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/setUnassignableToRoles
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.setUnassignableToRoles
     */
    setUnassignableToRoles(id: string, queryParams?: NullableProperties<{ unassignableToRoles?: boolean; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/setUnassignableToRoles`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/{id}/stakeholders
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getStakeholders
     */
    getStakeholders(id: string, queryParams?: NullableProperties<{ workspaceId?: string; page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.stakeholder.StakeholderAssignment>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}/stakeholders`, queryParams: queryParams });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/unassignRoles
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.unassignRoles
     */
    unassignRoles(id: string, roleAssignmentIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/unassignRoles`, data: roleAssignmentIds });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/unassignStakeholders
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.unassignStakeholders
     */
    unassignStakeholders(id: string, stakeholderIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/unassignStakeholders`, data: stakeholderIds });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/unassignUsers
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.unassignUsers
     */
    unassignUsers(id: string, userIds: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/unassignUsers`, data: userIds });
    }

    /**
     * HTTP POST /administration/usergroups/{id}/updateRoles
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.updateRoles
     */
    updateRoles(id: string, roleUpdate: stages.administration.usergroup.role.RoleUpdate): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`administration/usergroups/${id}/updateRoles`, data: roleUpdate });
    }

    /**
     * HTTP GET /administration/usergroups/{id}/users
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.getUsers
     */
    getUsers(id: string, queryParams?: NullableProperties<{ page?: number; pageSize?: number; }>): RestResponse<PagedResult<stages.administration.usergroup.user.User>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}/users`, queryParams: queryParams });
    }

    /**
     * HTTP GET /administration/usergroups/{id}/users/search
     * Java method: de.methodpark.stages.webapp.administration.usergroup.main.UserGroupResource.searchUsersInUserGroup
     */
    searchUsersInUserGroup(id: string, queryParams?: NullableProperties<{ query?: string; }>): RestResponse<PagedResult<stages.administration.usergroup.user.User>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`administration/usergroups/${id}/users/search`, queryParams: queryParams });
    }
}

export class UserResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /users/{userId}/favorites/{favoriteId}
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.addFavorite
     */
    addFavorite(userId: string, favoriteId: string): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`users/${userId}/favorites/${favoriteId}` });
    }

    /**
     * HTTP DELETE /users/{userId}/favorites/{favoriteId}
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.removeFavorite
     */
    removeFavorite(userId: string, favoriteId: string): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`users/${userId}/favorites/${favoriteId}` });
    }

    /**
     * HTTP GET /users/{userId}/originalProfileImage
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.getOriginalProfileImage
     */
    getOriginalProfileImage(userId: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`users/${userId}/originalProfileImage` });
    }

    /**
     * HTTP PUT /users/{userId}/password
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.changePassword
     */
    changePassword(userId: string, password: stages.user.Password): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`users/${userId}/password`, data: password });
    }

    /**
     * HTTP PUT /users/{userId}/profileImage
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.cropProfileImage
     */
    cropProfileImage(userId: string, croppedProfileImage: stages.user.CroppedProfileImage): RestResponse<any> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`users/${userId}/profileImage`, data: croppedProfileImage });
    }

    /**
     * HTTP GET /users/{userId}/profileImage
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.getScaledProfileImage
     */
    getScaledProfileImage(userId: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`users/${userId}/profileImage` });
    }

    /**
     * HTTP GET /users/{userId}/settings
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.getSettings
     */
    getSettings(userId: string): RestResponse<DataAndInfo<stages.user.UserSettings, stages.user.UserSettingsMetadata>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`users/${userId}/settings` });
    }

    /**
     * HTTP PUT /users/{userId}/settings
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.saveSettings
     */
    saveSettings(userId: string, userSettings: stages.user.UserSettings): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`users/${userId}/settings`, data: userSettings });
    }

    /**
     * HTTP POST /users/{userId}/settings/cancelPreview/{uploadId}
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.cancelPreview
     */
    cancelPreview(userId: string, uploadId: string): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`users/${userId}/settings/cancelPreview/${uploadId}` });
    }

    /**
     * HTTP POST /users/{userId}/settings/previewProfileImage
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.uploadProfileImage
     */
    uploadProfileImage(userId: string, formData: org.jboss.resteasy.plugins.providers.multipart.MultipartFormDataInput): RestResponse<stages.file.Upload> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`users/${userId}/settings/previewProfileImage`, data: formData });
    }

    /**
     * HTTP GET /users/{userId}/settings/previewProfileImage/{uploadId}
     * Java method: de.methodpark.stages.webapp.user.main.UserResource.previewProfileImage
     */
    previewProfileImage(userId: string, uploadId: string): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`users/${userId}/settings/previewProfileImage/${uploadId}` });
    }
}

export class WorkspaceAdministrationResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /management/workspaces/{id}
     * Java method: de.methodpark.stages.webapp.management.workspace.main.WorkspaceAdministrationResource.getWorkspaceFolder
     */
    getWorkspaceFolder(id: string): RestResponse<stages.workspace.Folder> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/workspaces/${id}` });
    }

    /**
     * HTTP POST /management/workspaces/{id}/add
     * Java method: de.methodpark.stages.webapp.management.workspace.main.WorkspaceAdministrationResource.addWorkspace
     */
    addWorkspace(id: string, formData: { [index: string]: any }, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<stages.workspace.Folder> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/workspaces/${id}/add`, queryParams: queryParams, data: formData });
    }

    /**
     * HTTP DELETE /management/workspaces/{id}/delete
     * Java method: de.methodpark.stages.webapp.management.workspace.main.WorkspaceAdministrationResource.deleteWorkspace
     */
    deleteWorkspace(id: string, queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<boolean> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`management/workspaces/${id}/delete`, queryParams: queryParams });
    }

    /**
     * HTTP POST /management/workspaces/{id}/move
     * Java method: de.methodpark.stages.webapp.management.workspace.main.WorkspaceAdministrationResource.moveWorkspaces
     */
    moveWorkspaces(id: string, idsToMove: string[], queryParams?: NullableProperties<{ workspaceId?: string; }>): RestResponse<stages.workspace.Folder> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`management/workspaces/${id}/move`, queryParams: queryParams, data: idsToMove });
    }

    /**
     * HTTP GET /management/workspaces/{id}/settings
     * Java method: de.methodpark.stages.webapp.management.workspace.main.WorkspaceAdministrationResource.getWorkspaceSettings
     */
    getWorkspaceSettings(id: string): RestResponse<stages.management.workspace.Settings> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`management/workspaces/${id}/settings` });
    }

    /**
     * HTTP PUT /management/workspaces/{id}/settings
     * Java method: de.methodpark.stages.webapp.management.workspace.main.WorkspaceAdministrationResource.saveSettings
     */
    saveSettings(id: string, newSettings: stages.management.workspace.Settings): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`management/workspaces/${id}/settings`, data: newSettings });
    }
}

export class WorkspaceAttributeTypesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /workspaces/attributeTypes
     * Java method: de.methodpark.stages.webapp.workspace.attributes.main.WorkspaceAttributeTypesResource.createType
     */
    createType(savedType: stages.workspace.attributes.SavedWorkspaceAttributeType): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspaces/attributeTypes`, data: savedType });
    }

    /**
     * HTTP DELETE /workspaces/attributeTypes
     * Java method: de.methodpark.stages.webapp.workspace.attributes.main.WorkspaceAttributeTypesResource.deleteTypes
     */
    deleteTypes(ids: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspaces/attributeTypes`, data: ids });
    }

    /**
     * HTTP GET /workspaces/attributeTypes
     * Java method: de.methodpark.stages.webapp.workspace.attributes.main.WorkspaceAttributeTypesResource.getTypes
     */
    getTypes(queryParams?: NullableProperties<{ page?: number; }>): RestResponse<SecuredPagedResult<stages.workspace.attributes.WorkspaceAttributeType>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/attributeTypes`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspaces/attributeTypes/workspaceCount
     * Java method: de.methodpark.stages.webapp.workspace.attributes.main.WorkspaceAttributeTypesResource.getWorkspaceCount
     */
    getWorkspaceCount(ids: string[]): RestResponse<number> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspaces/attributeTypes/workspaceCount`, data: ids });
    }

    /**
     * HTTP GET /workspaces/attributeTypes/{id}
     * Java method: de.methodpark.stages.webapp.workspace.attributes.main.WorkspaceAttributeTypesResource.getType
     */
    getType(id: string): RestResponse<stages.workspace.attributes.WorkspaceAttributeType> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/attributeTypes/${id}` });
    }

    /**
     * HTTP PUT /workspaces/attributeTypes/{id}
     * Java method: de.methodpark.stages.webapp.workspace.attributes.main.WorkspaceAttributeTypesResource.updateType
     */
    updateType(id: string, savedType: stages.workspace.attributes.SavedWorkspaceAttributeType): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspaces/attributeTypes/${id}`, data: savedType });
    }
}

export class WorkspaceCollectionsResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /workspaces/collections
     * Java method: de.methodpark.stages.webapp.workspace.collections.main.WorkspaceCollectionsResource.createWorkspaceCollection
     */
    createWorkspaceCollection(queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspaces/collections`, queryParams: queryParams });
    }

    /**
     * HTTP DELETE /workspaces/collections
     * Java method: de.methodpark.stages.webapp.workspace.collections.main.WorkspaceCollectionsResource.deleteWorkspaceCollections
     */
    deleteWorkspaceCollections(ids: string[]): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`workspaces/collections`, data: ids });
    }

    /**
     * HTTP GET /workspaces/collections
     * Java method: de.methodpark.stages.webapp.workspace.collections.main.WorkspaceCollectionsResource.getWorkspaceCollections
     */
    getWorkspaceCollections(queryParams?: NullableProperties<{ page?: number; }>): RestResponse<SecuredPagedResult<stages.workspace.collections.WorkspaceCollection>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/collections`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/collections/{id}
     * Java method: de.methodpark.stages.webapp.workspace.collections.main.WorkspaceCollectionsResource.getWorkspaceCollection
     */
    getWorkspaceCollection(id: string): RestResponse<stages.workspace.collections.WorkspaceCollection> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/collections/${id}` });
    }

    /**
     * HTTP PUT /workspaces/collections/{id}/rename
     * Java method: de.methodpark.stages.webapp.workspace.collections.main.WorkspaceCollectionsResource.renameWorkspaceCollection
     */
    renameWorkspaceCollection(id: string, queryParams?: NullableProperties<{ name?: string; }>): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`workspaces/collections/${id}/rename`, queryParams: queryParams });
    }

    /**
     * HTTP POST /workspaces/collections/{id}/update
     * Java method: de.methodpark.stages.webapp.workspace.collections.main.WorkspaceCollectionsResource.updateWorkspaceCollectionItems
     */
    updateWorkspaceCollectionItems(id: string, update: stages.workspace.collections.WorkspaceCollectionUpdate): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`workspaces/collections/${id}/update`, data: update });
    }
}

export class WorkspacesResourceBase {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /workspaces/archived
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getArchivedWorkspaces
     */
    getArchivedWorkspaces(queryParams?: NullableProperties<{ filterBy?: string; filterParam1?: string; filterParam2?: string; workspaceId?: string; pv?: string; }>): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/archived`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/favorites
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getFavoriteWorkspaces
     */
    getFavoriteWorkspaces(queryParams?: NullableProperties<{ filterBy?: string; filterParam1?: string; filterParam2?: string; workspaceId?: string; pv?: string; }>): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/favorites`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/modules
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getModuleWorkspaces
     */
    getModuleWorkspaces(queryParams?: NullableProperties<{ filterBy?: string; filterParam1?: string; filterParam2?: string; workspaceId?: string; pv?: string; }>): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/modules`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/related
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getRelatedWorkspaces
     */
    getRelatedWorkspaces(queryParams?: NullableProperties<{ filterBy?: string; filterParam1?: string; filterParam2?: string; workspaceId?: string; pv?: string; }>): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/related`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/root
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getRootWorkspace
     */
    getRootWorkspace(): RestResponse<stages.workspace.Workspace> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/root` });
    }

    /**
     * HTTP GET /workspaces/search
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.searchWorkspaces
     */
    searchWorkspaces(queryParams?: NullableProperties<{ query?: string; workspaceId?: string; pv?: string; filterBy?: string; filterParam1?: string; filterParam2?: string; }>): RestResponse<stages.workspace.Workspace[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/search`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/{folderId}/folder
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getWorkspaceTreeFolder
     */
    getWorkspaceTreeFolder(folderId: string, queryParams?: NullableProperties<{ filterBy?: string; filterParam1?: string; filterParam2?: string; workspaceId?: string; pv?: string; }>): RestResponse<stages.workspace.Folder> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/${folderId}/folder`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/{workspaceId}/home
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getCurrentWorkspaceWithHomeSpec
     */
    getCurrentWorkspaceWithHomeSpec(workspaceId: string, queryParams?: NullableProperties<{ swid?: string; spv?: string; smode?: string; pv?: string; }>): RestResponse<stages.workspace.application.WorkspaceView> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/${workspaceId}/home`, queryParams: queryParams });
    }

    /**
     * HTTP GET /workspaces/{workspaceId}/nullForTestComponent
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getNullForTestComponent
     */
    getNullForTestComponent(workspaceId: string): RestResponse<stages.workspace.application.WorkspaceView> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/${workspaceId}/nullForTestComponent` });
    }

    /**
     * HTTP GET /workspaces/{workspaceId}/transferObjectWithNullAttributeForTestComponent
     * Java method: de.methodpark.stages.webapp.workspace.main.WorkspacesResource.getTransferObjectWithNullAttributeForTestComponent
     */
    getTransferObjectWithNullAttributeForTestComponent(workspaceId: string): RestResponse<stages.workspace.application.WorkspaceView> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`workspaces/${workspaceId}/transferObjectWithNullAttributeForTestComponent` });
    }
}

export type RestResponse<R> = Promise<R>;

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AngularEmitterExtension' extension
import { Injectable } from "@angular/core";
import { AngularHttpClientAdapter, HttpResponseType } from "core/angular-http-client-adapter";
import { Headers } from "core/http.service";

@Injectable({ providedIn: "root" })
export class AdminJobsResource extends AdminJobsResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class AdministrationDashboardResource extends AdministrationDashboardResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class AuthenticationService extends AuthenticationServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ChartResource extends ChartResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class CleanupAdminResource extends CleanupAdminResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class CleanupForPasteService extends CleanupForPasteServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class CmsBrowserResource extends CmsBrowserResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ComplianceManagementResource extends ComplianceManagementResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ComplianceResource extends ComplianceResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ContextResource extends ContextResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class DashboardResource extends DashboardResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class DependencyResource extends DependencyResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class EnactmentAssociationResource extends EnactmentAssociationResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class EnactmentElementResource extends EnactmentElementResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class EnactmentExportResource extends EnactmentExportResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class EntryPageAdministrationResource extends EntryPageAdministrationResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class EntryPageResource extends EntryPageResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class FileManagementPlanResource extends FileManagementPlanResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class FilesResource extends FilesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class FrontendLoggingService extends FrontendLoggingServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ImagesResource extends ImagesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class InstallResource extends InstallResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class LoginBootstrapService extends LoginBootstrapServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class MaintenanceResource extends MaintenanceResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ManagementResource extends ManagementResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class MessageResource extends MessageResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ModulesResource extends ModulesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class NotificationResource extends NotificationResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class PDFImagesResource extends PDFImagesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class PDFPrintResource extends PDFPrintResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessElementsQueryResource extends ProcessElementsQueryResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessElementsResource extends ProcessElementsResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessInterfacesResource extends ProcessInterfacesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessReleaseAdminResource extends ProcessReleaseAdminResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessReleaseResource extends ProcessReleaseResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessResource extends ProcessResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessSettingsManagementResource extends ProcessSettingsManagementResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProcessVersionsManagementResource extends ProcessVersionsManagementResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ProgressMonitorService extends ProgressMonitorServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ReportingResource extends ReportingResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class RepositoryManagementResource extends RepositoryManagementResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class ScriptService extends ScriptServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class SearchQueryService extends SearchQueryServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class SqlService extends SqlServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class SupportInfoResource extends SupportInfoResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class TailoringResource extends TailoringResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class TrackingService extends TrackingServiceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class TranslateResource extends TranslateResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class UserAdministrationResource extends UserAdministrationResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class UserAssignmentsResource extends UserAssignmentsResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class UserGroupResource extends UserGroupResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class UserResource extends UserResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class WorkspaceAdministrationResource extends WorkspaceAdministrationResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class WorkspaceAttributeTypesResource extends WorkspaceAttributeTypesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class WorkspaceCollectionsResource extends WorkspaceCollectionsResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}

@Injectable({ providedIn: "root" })
export class WorkspacesResource extends WorkspacesResourceBase {
    constructor(private readonly angularHttpClientAdapter: AngularHttpClientAdapter) {
        super(angularHttpClientAdapter);
    }

    setAdditionalHttpParamsForNextRequest(httpParams: StringToString): void {
        this.angularHttpClientAdapter.setAdditionalHttpParamsForNextRequest(httpParams);
    }

    setHeadersForNextRequest(headers: Headers): void {
        this.angularHttpClientAdapter.setHeadersForNextRequest(headers);
    }

    setResponseTypeForNextRequest(responseType: HttpResponseType): void {
        this.angularHttpClientAdapter.setResponseTypeForNextRequest(responseType);
    }
}
