<ng-container *ngIf="processView$ | async as processView">
    <article class="dependent-elements-list">
        <ng-container *ngIf="dependentSubType !== undefined; else wrongConfig"></ng-container>
        <ng-container *ngTemplateOutlet="cardHeader"></ng-container>
        <ng-container
                *ngTemplateOutlet="dependentElements.length > 0 || mode === Mode.ADD ? dependentElementsList : empty"
        ></ng-container>
    </article>
</ng-container>

<ng-template #wrongConfig>
    <p class="empty" translate="process.element.dependentElementsList.wrongConfiguration"></p>
</ng-template>

<ng-template #cardHeader>
    <ng-container *ngIf="mode === Mode.BULKDELETE || mode === Mode.REARRANGE">
        <stages-buttons-responsive>
            <button
                    (click)="mode === Mode.BULKDELETE ? cancelDelete() : cancelRearrange()"
                    class="button sm cancel"
                    translate="cancel"
            ></button>
            <button
                    (click)="mode === Mode.BULKDELETE ? deleteSelectedElements() : rearrange()"
                    [class]="mode === Mode.BULKDELETE ? 'button sm delete' : 'button sm save sort'"
                    [disabled]="mode === Mode.BULKDELETE ? !anyElementSelectedForDelete() : !sortOrderWasChanged"
            >
                <span [translate]="mode === Mode.BULKDELETE ? 'delete' : 'rearrange'" *ngIf="!isSaving"></span>
                <stages-spin size="sm" *ngIf="isSaving"></stages-spin>
            </button>
        </stages-buttons-responsive>
        <ng-container *ngTemplateOutlet="selectOrderBox"></ng-container>
    </ng-container>
</ng-template>

<ng-template #selectOrderBox>
    <ng-container *ngIf="mode === Mode.REARRANGE">
        <div class="rearrange-selection-box">
            <div class="select"></div>
            <div class="label">
                <select name="order" id="order" (change)="onSortStrategyChange($any($event.target).value)"
                        class="fullsize">
                    <option
                            [selected]="sortStrategy === 'ALPHABETICAL'"
                            translate="rearrange.order.az"
                            value="ALPHABETICAL"
                    ></option>
                    <option [selected]="sortStrategy === 'CUSTOM'" translate="rearrange.order.custom"
                            value="CUSTOM"></option>
                </select>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #dependentElementsList>
    <ul
            class="grouped-list"
            cdkDropList
            [cdkDropListDisabled]="mode !== Mode.REARRANGE"
            (cdkDropListDropped)="drop($event)"
    >
        <ng-container *ngTemplateOutlet="addListItem"></ng-container>
        <li class="group" *ngFor="let dependentElement of dependentElements" cdkDragLockAxis="y" cdkDrag>
            <div class="dragged-item-placeholder" *cdkDragPlaceholder></div>
            <div class="dragged-item-placeholder cdk-drag-preview" *cdkDragPreview>
                <ul class="grouped-list">
                    <li class="group">
                        <header class="group-header">
                            <div class="handle draggableAreaForSort">
                                <i class="ico ico-handle"></i>
                            </div>
                            <stages-element-name [self]="dependentElement" [headerLevel]="3"></stages-element-name>
                        </header>
                    </li>
                </ul>
            </div>
            <ng-container
                    *ngTemplateOutlet="dependentElementHeader; context: { dependentElement: dependentElement }"
            ></ng-container>
            <ng-container
                    *ngTemplateOutlet="dependentElementDescription; context: { dependentElement: dependentElement }"
            ></ng-container>
        </li>
    </ul>
</ng-template>

<ng-template #empty>
    <p class="empty" translate="{{ messageKeyNone ? messageKeyNone : 'none' }}"></p>
</ng-template>

<ng-template #addListItem>
    <ng-container *ngIf="mode === Mode.ADD">
        <li class="group relative list-item add-item">
            <header class="group-header">
                <div class="fullsize">
                    <stages-add
                            [focusOnInit]="true"
                            [placeholder]="'start.typing' | translate"
                            [maxLength]="255"
                            (submitted)="add($event)"
                            (focusLost)="cancelAdd()"
                            (cancelled)="cancelAdd()"
                            class="relative"
                            pattern="^[^|]+$"
						    patternMessageKey="process.element.edit.name.pattern"
                    >
                    </stages-add>
                </div>
            </header>
        </li>
    </ng-container>
</ng-template>

<ng-template #deleteCheckbox let-dependentElement="dependentElement">
    <div *ngIf="mode === Mode.BULKDELETE && isSelectable(dependentElement)" class="select">
        <input
                type="checkbox"
                class="checkbox"
                [id]="'select' + dependentElement.id!"
                [name]="'select' + dependentElement.id!"
                [checked]="dependentElement.selected"
                (change)="toggleSelected(dependentElement)"
        />
        <label [for]="'select' + dependentElement.id!"></label>
    </div>
</ng-template>

<ng-template #dependentElementHeader let-dependentElement="dependentElement">
    <header class="group-header" [ngClass]="isInRenameMode(dependentElement) ? 'rename-header' : ''">
        <div *ngIf="mode === Mode.REARRANGE" class="handle draggableAreaForSort">
            <i class="ico ico-handle"></i>
        </div>
        <ng-container
                *ngTemplateOutlet="deleteCheckbox; context: { dependentElement: dependentElement }"></ng-container>
        <div class="name">
            <stages-editable
                    [item]="dependentElement"
                    [dataView]="dataView"
                    property="label"
                    maxLength="255"
                    existsMessageKey="administration.entrypages.add.name.exists"
            >
                <stages-element-name
                        [self]="dependentElement"
                        [headerLevel]="3"
                        (click)="toggleExpanded(dependentElement)"
                ></stages-element-name>
            </stages-editable>
        </div>
        <stages-button-expand-collapse
                [classes]="'action menu-button'"
                [isCollapsed]="!dependentElement.expanded"
                (pressedButton)="toggleExpanded(dependentElement)"
        ></stages-button-expand-collapse>
        <stages-menu
                menuId="executable-activator-menu"
                iconClasses="ico ico-more-vertical"
                openTo="left"
                [items]="dependentElementMenuItems"
                [context]="dependentElement"
                [noCollapse]="false"
                class="action"
        ></stages-menu>
    </header>
    <p *ngIf="dependentElement.changeMarker?.added" class="change-marker">
        <span class="badge added-badge" translate="process.compare.added"></span>
    </p>
    <p *ngIf="dependentElement.changeMarker?.deleted" class="change-marker">
        <span class="badge deleted-badge" translate="process.compare.deleted"></span>
    </p>
    <p *ngIf="dependentElement.changeMarker?.changed" class="change-marker">
        <span class="badge changed-badge" translate="process.compare.changed"></span>
    </p>
</ng-template>

<ng-template #dependentElementDescription let-dependentElement="dependentElement">
    <div class="relative-container">
        <div class="animation-container">
            <div
                #expandableDependentElement
                [@expandCollapseFromTop]="getExpandCollapseFromTop(dependentElement, expandableDependentElement)"
                class="expandable"
                [ngClass]="dependentElement.expanded ? 'expand' : 'collapse'"
            >
                <div>
                    <div class="panel dependent-element-description">
                        <stages-description
                                [beanType]="dependentElement.type.ident"
                                [beanId]="dependentElement.id"
                                [beanIdentity]="dependentElement.identity"
                                [modifiable]="dependentElement.allowedOperations.EDIT"
                                [processTypeIdent]="processView!.processType"
                                [unsafe]="false"
                                [displayDescription]="dependentElement | getDependentElementDescription"
                                [descriptionService]="descriptionService"
                                [workspaceId]="workspaceId"
                                [pv]="processVersion"
                                (afterSave)="afterDescriptionEditSave($event)"
                        >
                        </stages-description>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
