import {
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Inject,
	Injectable,
	ViewContainerRef,
} from "@angular/core";
import { ModelAssociationListComponent } from "process/associations/model-association-list.component";
import { ComplianceAssociationListComponent } from "process/compliance/associations/compliance-association-list.component";
import { ProcessComplianceAssociationListComponent } from "process/compliance/associations/process-compliance-association-list.component";
import { DiagramComponent } from "process/diagram/diagram.component";
import { FilesComponent } from "process/element/process-files.component";
import { PropertiesWrapperComponent } from "process/element/properties/properties-wrapper.component";
import { PhaseFreezeOverviewComponent } from "process/freeze/overview/phase-freeze-overview.component";
import { ActivityStreamComponent } from "process/history/activity-stream.component";
import { IndexComponent } from "process/index/index.component";
import { QueryTableComponent } from "process/query/query-table.component";
import { ProcessDocumentComponent } from "process/table/process-document.component";
import { ProcessListComponent } from "process/table/process-list.component";
import { ProcessTableComponent } from "process/table/process-table.component";
import { UserAssignmentsComponent } from "process/userassignments/user-assignments.component";
import { UserGroupsComponent } from "process/usergroups/user-groups.component";
import { BaseComponent } from "process/view/base.component";
import {
	ActivityStreamType,
	AssociationListType,
	ComponentType,
	DependentElementsListType,
	DiagramType,
	DocumentComponentType,
	FilesType,
	IndexType,
	ListComponentType,
	PhaseFreezeOverviewType,
	PropertiesType,
	QueryType,
	TableComponentType,
	UserAssignmentsType,
	UserGroupsType,
} from "process/view/type.interface";
import { DependentElementsListComponent } from "process/dependant-elements/dependent-elements-list.component";

@Injectable({ providedIn: "root" })
export class ComponentService {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		@Inject("ViewComponents") private viewComponents: any,
	) {}

	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	getComponentFactory(name: string): ComponentFactory<{}> {
		return this.componentFactoryResolver.resolveComponentFactory(this.viewComponents[name]);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	appendComponent(componentType: ComponentType, container: ViewContainerRef): ComponentRef<{}> {
		const componentFactory = this.getComponentFactory(componentType.class);
		const componentRef = container.createComponent(componentFactory);
		const component = componentRef.instance as BaseComponent;
		component.type = componentType;
		initialize(componentRef.instance, componentType);
		return componentRef;
	}
}

// eslint-disable-next-line complexity, @typescript-eslint/ban-types -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".,complexity,complexity
function initialize(component: {}, componentType: ComponentType): void {
	switch (componentType.class) {
		case "FilesComponent":
			const filesComponent = component as FilesComponent;
			const filesComponentType = componentType as FilesType;
			filesComponent.translateNone = filesComponentType.translateNone;
			filesComponent.showStartOptions = filesComponentType.showStartOptions;
			filesComponent.layout = filesComponentType.layout;
			filesComponent.path = filesComponentType.path;
			break;

		case "PropertiesComponent":
			const propertiesComponent = component as PropertiesWrapperComponent;
			const propertiesComponentType = componentType as PropertiesType;
			propertiesComponent.propertyList = propertiesComponentType.propertyList;
			propertiesComponent.translateNone = propertiesComponentType.translateNone;
			break;

		case "UserAssignmentsComponent":
			const userAssignmentsComponent = component as UserAssignmentsComponent;
			const userAssignmentsType = componentType as UserAssignmentsType;
			userAssignmentsComponent.translateNone = userAssignmentsType.translateNone;
			userAssignmentsComponent.pageSize = userAssignmentsType.pageSize;
			userAssignmentsComponent.elementPageSize = userAssignmentsType.elementPageSize;
			userAssignmentsComponent.path = userAssignmentsType.path;
			userAssignmentsComponent.showUsername = userAssignmentsType.showUsername;
			break;

		case "DiagramComponent":
			const diagramComponent = component as DiagramComponent;
			const diagramComponentType = componentType as DiagramType;
			diagramComponent.config = diagramComponentType;
			break;

		case "AssociationListComponent":
			const associationListComponent = component as ModelAssociationListComponent;
			const associationListType = componentType as AssociationListType;
			associationListComponent.groups = associationListType.groups;
			associationListComponent.messageKeyNone = associationListType.translateNone;
			associationListComponent.editable = associationListType.editable;
			associationListComponent.showEmptyGroups = associationListType.showEmptyGroups;
			associationListComponent.allowCreateElements = associationListType.allowCreateElements;
			if (associationListType.classes) {
				associationListComponent.classes = associationListType.classes;
			}
			break;
		case "DependentElementsListComponent":
			const dependentElementsListComponent = component as DependentElementsListComponent;
			const dependentElementsListType = componentType as DependentElementsListType;
			dependentElementsListComponent.messageKeyNone = dependentElementsListType.translateNone;
			dependentElementsListComponent.classes = dependentElementsListType.classes;
			dependentElementsListComponent.dependentElementTypeIdent = dependentElementsListType.subtype;
			break;

		case "ComplianceAssociationListComponent":
			const complianceAssociationListComponent = component as ComplianceAssociationListComponent;
			const complianceAssociationListType = componentType as AssociationListType;
			complianceAssociationListComponent.messageKeyNone = complianceAssociationListType.translateNone;
			complianceAssociationListComponent.editable = complianceAssociationListType.editable;
			complianceAssociationListComponent.showEmptyGroups = complianceAssociationListType.showEmptyGroups;
			if (complianceAssociationListType.classes) {
				complianceAssociationListComponent.classes = complianceAssociationListType.classes;
			}
			break;

		case "ProcessComplianceAssociationListComponent":
			const processComplianceAssociationListComponent = component as ProcessComplianceAssociationListComponent;
			const processComplianceAssociationListType = componentType as AssociationListType;
			processComplianceAssociationListComponent.messageKeyNone = processComplianceAssociationListType.translateNone;
			processComplianceAssociationListComponent.editable = processComplianceAssociationListType.editable;
			processComplianceAssociationListComponent.showEmptyGroups = processComplianceAssociationListType.showEmptyGroups;
			if (processComplianceAssociationListType.classes) {
				processComplianceAssociationListComponent.classes = processComplianceAssociationListType.classes;
			}
			break;

		case "ActivityStreamComponent":
			const activityStreamComponent = component as ActivityStreamComponent;
			const activityStreamComponentType = componentType as ActivityStreamType;
			activityStreamComponent.translateNone = activityStreamComponentType.translateNone;
			break;

		case "QueryTableComponent":
			const queryTableComponent = component as QueryTableComponent;
			const queryTableComponentType = componentType as QueryType;
			queryTableComponent.queryProvider = queryTableComponentType.queryProvider;
			queryTableComponent.queryIdent = queryTableComponentType.queryIdent;
			break;

		case "PhaseFreezeOverviewComponent":
			const phaseFreezeOverviewComponent = component as PhaseFreezeOverviewComponent;
			const phaseFreezeOverviewComponentType = componentType as PhaseFreezeOverviewType;
			phaseFreezeOverviewComponent.dependentPhasesPath = phaseFreezeOverviewComponentType.dependentPhasesPath;
			phaseFreezeOverviewComponent.phaseSubtypeIdent = phaseFreezeOverviewComponentType.phaseSubtype;
			phaseFreezeOverviewComponent.showUpdateButton = phaseFreezeOverviewComponentType.showUpdateButton;
			break;

		case "ProcessTableComponent":
			const tableComponent = component as ProcessTableComponent;
			const tableComponentType = componentType as TableComponentType;
			tableComponent.path = tableComponentType.path;
			tableComponent.columns = tableComponentType.propertyList;
			break;

		case "ProcessListComponent":
			const listComponent = component as ProcessListComponent;
			const listComponentType = componentType as ListComponentType;
			listComponent.path = listComponentType.path;
			listComponent.properties = listComponentType.propertyList;
			break;

		case "ProcessDocumentComponent":
			const documentComponent = component as ProcessDocumentComponent;
			const documentComponentType = componentType as DocumentComponentType;
			documentComponent.path = documentComponentType.path;
			documentComponent.autoNumber = documentComponentType.autoNumber;
			break;

		case "NotificationComponent":
			break;

		case "UserGroupsComponent":
			const userGroupsComponent = component as UserGroupsComponent;
			const userGroupsType = componentType as UserGroupsType;
			userGroupsComponent.translateNone = userGroupsType.translateNone;
			userGroupsComponent.pageSize = userGroupsType.pageSize;
			break;

		case "IndexComponent":
			const indexComponent = component as IndexComponent;
			const indexType = componentType as IndexType;
			indexComponent.hideShortname = indexType.hideShortname;
			break;

		default:
			break;
	}
}
