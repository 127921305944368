import { Component, Input } from "@angular/core";
import { TailoringWizardComponent } from "process/tailoring/containers/tailoring-wizard/tailoring-wizard.component";
import { IAnswer } from "process/tailoring/models/answer.interface";
import { IQuery } from "process/tailoring/models/query.interface";
import { TailoringWizardLogicService } from "process/tailoring/wizard-logic.service";

@Component({
	selector: "stages-process-tailoring-wizard-answer-item",
	templateUrl: "./answer-item.component.html",
})
export class TailoringWizardAnswerItemComponent {
	@Input()
	query?: IQuery;

	constructor(private wizardLogic: TailoringWizardLogicService, private wizard: TailoringWizardComponent) {}

	onSelect(answer: IAnswer, query: IQuery): void {
		this.wizardLogic.selectAnswer(query, answer);
		setTimeout(() => this.wizard.onClickNext(), 200);
	}

	isActive(answerId: string, query: IQuery): boolean {
		return this.wizardLogic.isAnswerSelected(query.id, answerId);
	}
}
