import { Component, HostBinding, Input } from "@angular/core";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";

export interface WorkspaceFilter {
	filterBy: string;
	filterParam1: string;
	filterParam2: string;
}

@Component({
	templateUrl: "./workspace-selection-dialog.component.html",
})
export class WorkspaceSelectionDialogComponent {
	@HostBinding("class.select-workspace-dialog") customClass = true;

	constructor(private dialog: DialogAdapter) {}

	@Input()
	filter!: WorkspaceFilter;

	dialogClosed(): void {
		this.dialog.close({
			value: "cancel",
		});
	}

	onSelectWorkspace(workspace: WorkspaceSelected): void {
		this.dialog.close({
			value: "ok",
			workspace: workspace,
		});
	}
}
