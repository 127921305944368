import { Component, HostBinding, Input } from "@angular/core";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogAdapter } from "common/dialog/dialog-adapter";
import { ResetTailoringService } from "process/tailoring/reset/reset-tailoring.service";

@Component({
	templateUrl: "./reset-dialog.component.html",
	// styleUrls: ["./reset-dialog.component.scss"]
})
export class ResetDialogComponent {
	@HostBinding("class.autoHeight") dialogAutoHeightClass = true;

	constructor(
		private dialog: DialogAdapter,
		private resetTailoringService: ResetTailoringService,
		private mutexService: MutexService,
	) {}

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	resetInProgress = false;

	reset(): void {
		this.mutexService.invoke(`resetTailoring${this.workspaceId}`, async () => {
			this.resetInProgress = true;
			await this.resetTailoringService.reset(this.workspaceId, this.pv);
			this.resetInProgress = false;
			this.close();
		});
	}

	close(): void {
		this.dialog.close(undefined);
	}
}
