import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SlotService } from "common/slot.service";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { BreadcrumbService } from "process/breadcrumb.service";
import { MenuFactoryService } from "process/menu-factory.service";
import { ComponentService } from "process/view/component.service";
import { WidgetContainerComponent } from "process/view/widget-container.component";

@Component({
	selector: "stages-template-6-6",
	templateUrl: "./template-6-6.component.html",
	providers: [SlotService],
})
export class Template66Component extends WidgetContainerComponent {
	constructor(
		componentService: ComponentService,
		route: ActivatedRoute,
		mainService: MainService,
		viewService: ViewService,
		breadcrumbService: BreadcrumbService,
		menuFactoryService: MenuFactoryService,
		slotService: SlotService,
	) {
		super(componentService, route, mainService, viewService, breadcrumbService, menuFactoryService, slotService);
	}
}
