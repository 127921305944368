import { animate, state, style, transition, trigger } from "@angular/animations";
import { ViewportScroller } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
	ViewRef,
} from "@angular/core";
import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CardComponent } from "common/card/card.component";
import { ImageMap } from "common/image-map/image-map.component";
import { ZoomToolbarService } from "common/zoom/zoom-toolbar/zoom-toolbar.service";
import { HttpService } from "core/http.service";
import { MainService } from "core/main.service";
import { PreferencesService } from "core/preferences.service";
import { debounceTimeAfterFirst } from "core/rxjs-operators";
import { UrlService } from "core/url.service";
import { ViewService } from "core/view.service";
import { pick } from "lodash";
import { DiagramHtmlPipe } from "process/diagram/diagram-html.pipe";
import { FitToPageMenuItemImpl } from "process/diagram/fit-to-page-menu-item.model";
import { WithContributors } from "process/diagram/with-contributors";
import { BaseComponent } from "process/view/base.component";
import { ComponentService } from "process/view/component.service";
import { DiagramType } from "process/view/type.interface";
import { BehaviorSubject, combineLatest, lastValueFrom, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from "rxjs/operators";

type ProcessView = stages.process.ProcessView;

type UserParameters = StringToBoolean;

interface BlobWithUrl {
	blob: Blob;
	url: string;
}

interface SelfElement extends ProcessView {
	_diagramIds: Record<string, string>;
}

class SwitchMenuItemImpl implements SwitchMenuItem, WithContributors {
	constructor(private parameters: UserParameters, private paramKey: string) {}

	name: string = "";
	id: string = "";
	iconClass: string[] = [];
	isSwitch = true;
	mergable = true;
	contributors: Set<string> = new Set();
	onValueChange: (value: boolean) => void = () => {
		console.log("empty onValueChange function");
	};

	getValue(): boolean {
		return this.parameters[this.paramKey];
	}

	setValueInternal(value: boolean): void {
		this.parameters[this.paramKey] = value;
	}
}

class CompoundSwitchMenuItem implements SwitchMenuItem, WithContributors {
	constructor(
		private menuItem1: CompoundSwitchMenuItem | SwitchMenuItemImpl,
		private menuItem2: CompoundSwitchMenuItem | SwitchMenuItemImpl,
	) {
		this.menuItem2.setValueInternal(this.menuItem1.getValue());
		this.contributors = new Set([...menuItem1.contributors, ...menuItem2.contributors]);
	}

	name: string = this.menuItem1.name;
	id: string = this.menuItem1.id;
	mergable = true;
	iconClass: string[] = this.menuItem1.iconClass;
	isSwitch: boolean = this.menuItem1.isSwitch;
	contributors: Set<string>;

	getValue(): boolean {
		return this.menuItem1.getValue();
	}

	onValueChange(value: boolean): void {
		this.menuItem1.onValueChange(value);
		this.menuItem2.onValueChange(value);
	}

	setValueInternal(value: boolean): void {
		this.menuItem1.setValueInternal(value);
		this.menuItem2.setValueInternal(value);
	}
}

@Component({
	selector: "stages-process-diagram",
	templateUrl: "./diagram.component.html",
	styleUrls: ["./diagram.component.scss"],
	animations: [
		trigger("expandCollapseFromTop", [
			state("collapsed", style({ "margin-top": "{{contentHeight}}px" }), { params: { contentHeight: 0 } }),
			state("expanded", style({ "margin-top": "0px" })),
			transition("collapsed <=> expanded", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
})
export class DiagramComponent extends BaseComponent implements OnInit, OnDestroy {
	@Input()
	config!: DiagramType;

	@ViewChild("diagramContainer")
	diagramContainer!: ElementRef;

	@HostBinding("class.noContent") noContent: boolean = false;

	/* svg configuration */
	imageZoomToolLoaded: boolean = false;

	imageMap?: ImageMap;
	diagramId?: string;
	url: string = "";
	src?: SafeHtml | SafeUrl;
	private readonly imgFileReader = new FileReader();
	localImageMap?: ImageMap;
	fitToPage!: boolean;
	isFirstDiagramOnPage: boolean = false;
	diagramLoaded?: boolean;
	sourceChanged: boolean = false;

	private userParameters!: UserParameters;
	private userParameters$ = new BehaviorSubject<UserParameters>(this.userParameters);
	private userParamsHaveChanged = false;
	isBrowserSupported: boolean = false;
	currentElement!: ProcessView;
	destroy$: Subject<boolean> = new Subject<boolean>();

	refreshDiagram$: Subject<boolean> = new Subject<boolean>();
	refreshingDiagramWithoutReload: boolean = false;
	refreshingDiagram = false;
	private savedScrollPositionBeforeUpdate = [0, 0];
	private diagramHeight = 0;

	expanded: boolean = true;
	private diagramPreference: string = "diagram.expanded";
	private diagramType!: string;
	toggleFunction: MenuItem;
	openCloseState: StringToBoolean = {};

	private debounceTime = 500;

	constructor(
		componentService: ComponentService,
		private viewportScroller: ViewportScroller,
		// eslint-disable-next-line deprecation/deprecation -- disable is ok, because request caching is needed
		private readonly httpService: HttpService,
		private readonly route: ActivatedRoute,
		private readonly preferencesService: PreferencesService,
		private readonly urlService: UrlService,
		private readonly translateService: TranslateService,
		readonly card: CardComponent,
		private readonly viewService: ViewService,
		private readonly mainService: MainService,
		private readonly httpClient: HttpClient,
		private readonly domSanitizer: DomSanitizer,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly diagramHtmlPipe: DiagramHtmlPipe,
		private readonly zoomToolbarService: ZoomToolbarService,
	) {
		super(componentService);
	}

	ngOnInit(): void {
		this.initializeMenuItems();

		this.route.paramMap.subscribe((paramMap) => {
			this.diagramType = "diagram" + paramMap.get("type")!;

			this.preferencesService.getPreference(this.diagramPreference, {}).then((preference: StringToBoolean) => {
				this.openCloseState = preference;
				this.setDiagramCollapseHandling(preference);
			});
		});

		combineLatest([
			this.viewService.awaitSelfElementObservable().pipe(
				distinctUntilChanged(
					(x, y) => x.id !== y.id,
					/* prevents update of previous element / diagram, old view is not yet destroyed */
				),
			),
			this.refreshDiagram$.pipe(
				startWith(true),
				debounceTimeAfterFirst(this.debounceTime), //debounce fast parameter switching, but do not delay initial load to prevent multiple handling of the same refresh event
				takeUntil(this.destroy$),
			),
			this.route.paramMap,
			this.userParameters$.pipe(debounceTimeAfterFirst(this.debounceTime), takeUntil(this.destroy$)),
		])
			.pipe(debounceTime(this.debounceTime), takeUntil(this.destroy$))
			.subscribe(([selfElement, forcedRefresh, paramMap, userParams]) => {
				this.userParamsHaveChanged = userParams !== this.userParameters;
				if (selfElement) {
					this.currentElement = selfElement;
					if (this.expanded) {
						//render diagram
						this.updateDiagram(this.currentElement as SelfElement, forcedRefresh, paramMap, userParams);
					}
				}
			});

		this.isFirstDiagramOnPage = this.config.position === 1;
		this.isBrowserSupported = this.zoomToolbarService.isBrowserSupported;
		this.addDiagramListener();

		if (!this.isBrowserSupported) {
			//read initial fitToPageParameter from local storage
			this.readFitToPageParameter().then((ftpValue) => {
				this.fitToPage = ftpValue;
			});
		}
	}

	private addDiagramListener(): void {
		this.imgFileReader.addEventListener(
			"load",
			() => {
				this.imageZoomToolLoaded = false;
				const svgFile = this.imgFileReader.result as string;

				if (this.isBrowserSupported) {
					const svgContent = svgFile.substring(svgFile.indexOf(",") + 1, svgFile.length);

					const atobConvertedFile = b64DecodeUnicode(svgContent);
					this.src = this.diagramHtmlPipe.transform(atobConvertedFile);
					this.zoomToolbarService.registerVisibleDiagram();
				} else {
					this.src = this.domSanitizer.bypassSecurityTrustUrl(svgFile);
				}
			},
			false,
		);

		this.imgFileReader.onloadend = () => {
			if (this.imgFileReader.result === this.url && !this.userParamsHaveChanged) {
				this.refreshingDiagram = false;
				this.imageZoomToolLoaded = true;
			}
		};
	}

	private setDiagramCollapseHandling(preferences: StringToUnknown): void {
		this.toggleFunction = {
			disabled: () => {
				return false;
			},
			on: () => {
				this.toggleDiagram();
			},
		};

		if (this.card) {
			if (this.card.contentCollapsible === undefined) {
				this.card.contentCollapsible = true;
				this.card.showCollapseOption = true;
				this.card.resetHeight();
			}

			let preference = true;

			if (this.card.contentCollapsible && preferences[this.diagramType] !== undefined) {
				preference = preferences[this.diagramType] as boolean;
			}

			this.expanded = preference;
			this.card.expanded = preference;
			this.card.externalToggleFunctions?.push(this.toggleFunction);

			if (!this.card.contentCollapsible && !this.expanded) {
				this.expanded = true;
				this.card.expanded = true;
				this.storeState(this.expanded);
			}
		}
	}

	private initializeMenuItems(): void {
		const defaultUserParameters = DiagramComponent.getDefaultUserParameters(this.config);
		// create all menuItems
		this.readParameters(this.config.prefixedIdent, defaultUserParameters).then((storedParameters) => {
			// ST-31477: Display only menu items for the parameters that are currently configured, not all parameters for which values are stored.

			this.userParameters = pick(storedParameters, Object.keys(defaultUserParameters));
			if (!this.diagramLoaded) {
				this.userParameters$.next(this.userParameters);
			}
			const menuItems: MenuItem[] = [];

			for (const paramKey of Object.keys(this.userParameters)) {
				const menuItem = new SwitchMenuItemImpl(this.userParameters, paramKey);
				menuItem.name = "process.diagram." + this.config.ident + "." + paramKey;
				menuItem.id = "process.diagram." + (this.config.mergeParameters ? "" : this.config.ident + ".") + paramKey;
				menuItem.mergable = this.config.mergeParameters;
				menuItem.onValueChange = (value: boolean) => {
					this.userParameters[paramKey] = value;
					this.storeParameters(this.config.prefixedIdent, paramKey, defaultUserParameters, this.userParameters);
					this.userParameters$.next(this.userParameters);
					this.refreshDiagram$.next(false);
				};
				menuItem.contributors.add(this.config.ident);
				menuItems.push(menuItem);
			}

			if (!this.isBrowserSupported) {
				// fitToPage-functionality for all browsers that are not supported
				const fitMenuItem = new FitToPageMenuItemImpl(this, this.config.ident);
				fitMenuItem.onValueChange = (value: boolean) => {
					this.fitToPage = value;
					this.storeFitToPageParameter();
					if (!(this.changeDetector as ViewRef).destroyed) {
						this.changeDetector.detectChanges();
					}
				};
				menuItems.push(fitMenuItem);
			}
			this.card.menuItems = this.mergeMenuItems(this.card.menuItems || [], menuItems);
		});
	}

	private mergeMenuItems(existingMenuItems: MenuItem[], ownMenuItems: MenuItem[]): MenuItem[] {
		if (
			existingMenuItems.length > 0 &&
			this.config.mergeParameters &&
			existingMenuItems.find((mi) => mi.mergable) !== undefined
		) {
			const result = [];
			for (const mi of existingMenuItems) {
				const mergeCandidate = ownMenuItems.find((ownMi) => this.isMergable(ownMi, mi));
				if (mergeCandidate) {
					result.push(new CompoundSwitchMenuItem(mi, mergeCandidate));
				} else {
					result.push(mi);
				}
			}
			for (const ownMi of ownMenuItems) {
				if (!result.some((mi) => this.isMergable(mi, ownMi))) {
					result.push(ownMi);
				}
			}
			return result;
		}
		return existingMenuItems.length > 0 ? [...existingMenuItems, { isSeparator: true }, ...ownMenuItems] : ownMenuItems;
	}

	private isMergable(menuItem1: MenuItem, menuItem2: MenuItem): boolean {
		return (
			menuItem1.id === menuItem2.id &&
			(menuItem1 instanceof SwitchMenuItemImpl || menuItem1 instanceof CompoundSwitchMenuItem) &&
			(menuItem2 instanceof SwitchMenuItemImpl || menuItem2 instanceof CompoundSwitchMenuItem) &&
			menuItem1.mergable &&
			menuItem2.mergable
		);
	}

	private async getImageAsBlob(imageUrl: string): Promise<BlobWithUrl | null> {
		try {
			const response = await lastValueFrom(
				this.httpClient.get(imageUrl, { responseType: "blob", observe: "response" }),
			);
			if (response.status === 204) {
				this.handleNoContent();
			}
			return response.body ? { blob: response.body, url: imageUrl } : null;
		} catch (e: unknown) {
			//Failing to load the visualization must not prevent displaying the remainder of the page.
			console.log(e);
			return null;
		}
	}

	private handleNoContent(): void {
		this.removeMyMenuItemsFromCard();
		this.noContent = true;
	}

	private updateDiagram(
		selfElement: SelfElement,
		forceReload: boolean,
		paramMap: ParamMap,
		userParameters: UserParameters,
	): void {
		if (!forceReload) {
			this.refreshingDiagramWithoutReload = true;
		}

		const diagramId = this.getDiagramId(selfElement, forceReload);
		const diagramURL = this.createDiagramURL(
			diagramId,
			selfElement,
			paramMap,
			this.isBrowserSupported,
			this.config,
			userParameters,
			this.translateService.currentLang,
		);

		this.savedScrollPositionBeforeUpdate = this.viewportScroller.getScrollPosition();
		this.updateDiagramId(diagramId, userParameters);
		if (this.url !== diagramURL) {
			if (this.src && this.isBrowserSupported) {
				this.sourceChanged = true;
			}
			this.src = undefined;
			if (this.isBrowserSupported) {
				this.diagramLoaded = false;
			}
			this.localImageMap = undefined;
			this.url = diagramURL;
			this.refreshingDiagram = true;
			this.getImageAsBlob(diagramURL).then((blobWithUrl) => {
				if (blobWithUrl?.blob) {
					this.savedScrollPositionBeforeUpdate = this.viewportScroller.getScrollPosition();
					this.getImageFromBlob(blobWithUrl);
					if (!this.isBrowserSupported) {
						this.updateImageMap(diagramURL, this.diagramId!);
					}
					if (!forceReload) {
						this.refreshingDiagramWithoutReload = false;
					}
				}
			});
		}
	}

	setScrollPosition(): void {
		const offset = getCumulativeOffset(this.diagramContainer.nativeElement);
		const currentPosition = this.viewportScroller.getScrollPosition();
		if (this.savedScrollPositionBeforeUpdate[1] > 0 && currentPosition[1] > offset) {
			this.diagramHeight = this.diagramContainer.nativeElement.offsetHeight;
			this.viewportScroller.scrollToPosition([0, this.savedScrollPositionBeforeUpdate[1] + this.diagramHeight]);
		}
	}

	private createDiagramURL(
		diagramId: string,
		selfElement: ProcessView,
		paramMap: ParamMap,
		isBrowserSupported: boolean,
		config: DiagramType,
		userParameters: UserParameters,
		currentLang: string,
	): string {
		const imageType = isBrowserSupported ? ".svg" : ".png";
		const processVersion = paramMap.get("processVersion")!;

		return this.urlService.build(
			"diagramView/" + diagramId + imageType,
			{},
			{
				id: selfElement.guid,
				diagramId: diagramId,
				assocBaseline: processVersion,
				template: config.template,
				metamodel: selfElement.processType,
				lang: currentLang,
				parameters: convert2UriParams(config.parameters),
				userparameters: convert2UriParams(combineUserParametersWithURLOverwrites(paramMap, userParameters)),
				swid: this.mainService.secondaryWorkspaceId,
				smode: this.mainService.secondaryMode,
				spv: this.mainService.secondaryProcessVersion,
			},
		);
	}

	private updateDiagramId(id: string, userParameters: UserParameters): void {
		if (this.diagramId !== id) {
			if (this.diagramId && !this.isBrowserSupported && this.userParameters === userParameters) {
				this.httpService.invalidate(createImageMapUrl(this.url, this.diagramId));
			}
			this.diagramId = id;
		}
	}

	private getImageFromBlob(blobWithUrl: BlobWithUrl): void {
		if (blobWithUrl.url === this.url && !this.userParamsHaveChanged) {
			if (this.imgFileReader.readyState === FileReader.LOADING) {
				this.imgFileReader.abort();
			}
			this.imgFileReader.readAsDataURL(blobWithUrl.blob);
		}

		this.imgFileReader.onloadend = () => {
			if (blobWithUrl.url === this.url && !this.userParamsHaveChanged) {
				this.refreshingDiagram = false;
				this.imageZoomToolLoaded = true;
			}
		};
	}

	private updateImageMap(diagramUrl: string, diagramId: string): void {
		const mapUrl = createImageMapUrl(diagramUrl, diagramId);
		this.imageMap = undefined;
		this.httpService.getCached(mapUrl).then(
			(map: ImageMap) => {
				if (this.url === diagramUrl) {
					this.localImageMap = map;
					this.imageMap = this.localImageMap;
				}
			},
			(rejection) => {
				console.log(rejection);
			},
		);
	}

	private getDiagramId(selfElement: SelfElement, forceReload: boolean): string {
		if (!selfElement._diagramIds || forceReload) {
			selfElement._diagramIds = {};
		}
		if (!selfElement._diagramIds[this.config.prefixedIdent]) {
			selfElement._diagramIds[this.config.prefixedIdent] = createNewDiagramId(selfElement.guid);
		}
		return selfElement._diagramIds[this.config.prefixedIdent];
	}

	/* parameter storage handling / preferences */

	private storeParameters(
		prefKey: string,
		keyOfChangedParameter: string,
		defaultUserParams: UserParameters,
		newParameters: UserParameters,
	): void {
		this.preferencesService.getPreference(prefKey, defaultUserParams).then((oldParameters) => {
			if (oldParameters[keyOfChangedParameter] !== newParameters[keyOfChangedParameter]) {
				this.preferencesService.setPreference(prefKey, newParameters);
			}
		});
	}

	private async readParameters(prefKey: string, defaultUserParams: UserParameters): Promise<UserParameters> {
		if (defaultUserParams) {
			return this.preferencesService.getPreference(prefKey, defaultUserParams);
		}
		return new Promise<UserParameters>(defaultUserParams);
	}

	private storeFitToPageParameter(): void {
		const prefKey = this.config.prefixedIdent ? this.config.prefixedIdent + ".fitToPage" : "fitToPage";
		const keyOfChangedParameter = "state";
		this.preferencesService.getPreference(prefKey, { state: true }).then((oldParameters) => {
			if (oldParameters[keyOfChangedParameter] !== this.fitToPage) {
				this.preferencesService.setPreference(prefKey, { state: this.fitToPage });
			}
		});
	}

	private async readFitToPageParameter(): Promise<boolean> {
		const prefKey: string = this.config.prefixedIdent ? this.config.prefixedIdent + ".fitToPage" : "fitToPage";
		const defaultUserParams: StringToBoolean = {
			state: true,
		};
		if (defaultUserParams) {
			return this.preferencesService.getPreference(prefKey, defaultUserParams).then((parameters) => {
				return parameters.state;
			});
		}
		return new Promise<StringToBoolean>(defaultUserParams).then((parameters) => {
			return parameters.state;
		});
	}

	override ngOnDestroy(): void {
		// "super.ngOnDestroy();" is not called. This is perhaps an error, but adding it was explicitely not wanted as part of ST-33629.
		this.destroy$.next(true);
		this.removeMyMenuItemsFromCard();
		if (this.src && this.isBrowserSupported) {
			this.zoomToolbarService.storeZoomParametersIfVisibleDiagramExists();
			this.zoomToolbarService.unregisterVisibleDiagram();
		}
	}

	@HostListener("window:beforeunload")
	doBeforePageReload(): void {
		this.zoomToolbarService.storeZoomParametersIfVisibleDiagramExists();
	}

	private removeMyMenuItemsFromCard(): void {
		// A menu item can be removed from the card when the current diagram is the only contributor for the menu item
		// A menu item has multiple contributors when there are multiple diagrams in the current tab and the menu items have been merged
		if (this.card?.menuItems) {
			const removeMyselfAsContributor = (mi: MenuItem): MenuItem => {
				if (mi.contributors) {
					mi.contributors.delete(this.config.ident);
				}
				return mi;
			};
			const anyContributorsLeft = (mi: MenuItem): boolean =>
				mi.isSeparator || (mi.contributors && mi.contributors.size > 0);
			this.card.menuItems = this.card.menuItems.map(removeMyselfAsContributor).filter(anyContributorsLeft);
		}
	}

	private static getDefaultUserParameters(config: DiagramType): StringToBoolean {
		const result: StringToBoolean = {};
		return config.components.reduce((defaultUserParams, userParam) => {
			defaultUserParams[userParam.name] = userParam.defaultValue;
			return defaultUserParams;
		}, result);
	}

	toggleDiagram(): void {
		this.expanded = !this.expanded;
		this.storeState(this.expanded);

		if (this.expanded) {
			this.refreshDiagram$.next(true);
			this.initializeMenuItems();
		} else if (this.src && this.isBrowserSupported) {
			this.refreshDiagram$.next(false);
			this.zoomToolbarService.storeZoomParametersIfVisibleDiagramExists();
			this.zoomToolbarService.unregisterVisibleDiagram();
			this.removeMyMenuItemsFromCard();
		}
	}

	getExpandCollapseFromTop(expandable: HTMLDivElement): { value: string; params: Record<string, unknown> } {
		const targetState = this.expanded ? "expanded" : "collapsed";
		return {
			value: targetState,
			params: {
				contentHeight: -30 - expandable.scrollHeight,
			},
		};
	}

	storeState(expanded: boolean): void {
		this.preferencesService.getPreference(this.diagramPreference, {}).then((oldPreferences: StringToBoolean) => {
			const newPreferences = { ...oldPreferences };

			newPreferences[this.diagramType] = expanded;
			this.openCloseState = newPreferences;
			this.preferencesService.setPreference(this.diagramPreference, newPreferences);
		});
	}
}

function combineUserParametersWithURLOverwrites(paramMap: ParamMap, userParameters: StringToBoolean): StringToBoolean {
	const result: StringToBoolean = { ...userParameters };
	paramMap.keys.forEach((key) => {
		if (key.startsWith("dup_")) {
			result[key.slice("dup_".length)] = !!paramMap.get(key);
		}
	});
	return result;
}

function createImageMapUrl(diagramUrl: string, diagramId: string): string {
	return diagramUrl + "&mapname=" + diagramId;
}

function convert2UriParams(json: StringToUnknown): string | undefined {
	if (!json || Object.keys(json).length === 0) {
		return undefined;
	}
	return Object.keys(json)
		.map((k) => {
			return encodeURIComponent(k) + "=" + encodeURIComponent(String(json[k]));
		})
		.join(";");
}

function createNewDiagramId(elementGuid: string): string {
	// we need a new id each time we want the diagram to reflect recent changes instead of just loading it from the diagram image cache
	return elementGuid + "_" + Date.now().toString() + "_" + Math.floor(Math.random() * 1000).toString();
}

function b64DecodeUnicode(str: string): string {
	// Going backwards: from bytestream, to percent-encoding, to original string.
	return decodeURIComponent(
		[...atob(str)]
			.map((c: string) => {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);
}

// calculate ablsolute top offset of the diagram for all type of views (mobile, desktop)
function getCumulativeOffset(element: HTMLElement): number {
	let top = 0;
	let _element = element;
	do {
		top += _element.offsetTop || 0;
		_element = _element.offsetParent as HTMLElement;
	} while (_element);
	return top;
}
