import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

@Component({
	selector: "stages-image-crop",
	templateUrl: "./image-crop.component.html",
})
export class ImageCropComponent implements OnInit, OnDestroy {
	@Input("source")
	source!: string;

	@Output() readonly cancel = new EventEmitter<void>();

	@Output() readonly save = new EventEmitter<number[]>();

	@ViewChild("croppieParent", { read: ElementRef, static: true })
	croppieParent!: ElementRef;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	croppie?: any;

	ngOnInit(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		this.croppie = new (window as any).Croppie(this.croppieParent.nativeElement, {
			viewport: {
				width: 224,
				height: 224,
				type: "circle",
			},
			boundary: {
				width: "100%",
				height: "300px",
			},
		});
		this.croppie
			.bind({
				url: this.source,
			})
			.then(() => {
				this.croppie.setZoom(0);
			});
	}

	ngOnDestroy(): void {
		this.croppie.destroy();
	}

	onCancel(): void {
		this.cancel.emit();
	}

	onSave(): void {
		this.save.emit(this.croppie.get().points);
	}
}
