import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ProgressService } from "core/progress.service";

@Component({
	selector: "stages-summary",
	templateUrl: "./summary.component.html",
	styleUrls: ["summary.component.scss"],
})
export class SummaryComponent implements OnInit {
	@Input()
	operationName!: string;

	@Input()
	jobName!: string;

	@Input()
	workspaceId!: string;

	@Input()
	pv!: string;

	@Input()
	download?: string | null;

	@Output() readonly close = new EventEmitter<void>();

	@Output() readonly finished = new EventEmitter<stages.common.ProgressInfo>();

	warningsExpanded: boolean = true;
	progress!: number;
	remainingTimeMillis!: number;
	finalState!: string;
	error?: string | null;
	incidentId?: string | null;
	warnings: string[] = [];

	constructor(private progressService: ProgressService) {}

	toggleWarningList(): void {
		this.warningsExpanded = !this.warningsExpanded;
	}

	ngOnInit(): void {
		this.progressService
			.getJobProgress(this.operationName, this.workspaceId, this.pv, this.jobName)
			.then((progress: stages.common.ProgressInfo) => {
				this.onProgressFinished(progress);
			});
	}

	onProgressFinished(progress: stages.common.ProgressInfo): void {
		this.progress = 1;
		this.remainingTimeMillis = 0;
		switch (progress.jobStatus) {
			case "COMPLETED_SUCCESSFUL":
				this.finalState = "success";
				this.finished.emit(progress);
				break;
			case "COMPLETED_FAILED":
				this.finalState = "failure";
				this.error = progress.errorMessage;
				this.incidentId = progress.incidentId;
				this.warnings = progress.warnings;
				this.finished.emit(progress);
				break;
			case "COMPLETED_WITH_WARNINGS":
				this.finalState = "warnings";
				this.warnings = progress.warnings;
				this.finished.emit(progress);
				break;
			default:
				this.finalState = status;
				break;
		}
		//$scope.$applyAsync();
	}
}
