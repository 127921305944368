<article class="query-table">
	<stages-media-query #media query="screen and (max-width:736px)">
		<!-- Wait for query -->
		<div class="query" *ngIf="isRunning && !error">
			<div class="query-error">
				<stages-spin size="sm"></stages-spin>
				<div>
					<h3 translate="query.waiting"></h3>
				</div>
			</div>
		</div>

		<div *ngIf="status.resolved">
			<div *ngIf="!error">
				<div *ngIf="table">
					<!-- Mobile View -->
					<ul class="grouped-list" *ngIf="media.matches">
						<li class="group" *ngFor="let header of table.headers; index as i">
							<header class="group-header">
								<h3 (click)="toggle(i)" [title]="header?.hover ? header.hover : ''">{{ header.text }}</h3>
								<stages-button-expand-collapse
									[classes]="'action menu-button'"
									[isCollapsed]="!isOpen(i)"
									(pressedButton)="toggle(i)"
								></stages-button-expand-collapse>
							</header>
							<div class="relative-container" *ngIf="isOpen(i)">
								<div class="animation-container">
									<div class="expandable">
										<ul class="list dense last">
											<li
												class="list-item"
												*ngFor="let row of table.data; trackBy: trackByIndex"
												[title]="row[i]?.hover ? row[i].hover : ''"
											>
												<div class="label" *ngIf="row[i]">
													<div class="text">
														<h4 [ngSwitch]="row[i].type">
															<a
																*ngSwitchCase="'LINK'"
																[href]="row[i].link"
																target="_blank"
																rel="noopener noreferrer"
																>{{ row[i].text }}</a
															>
															<img *ngSwitchCase="'IMAGE'" [src]="row[i].link" alt="{{ row[i].text }}" />
															<span *ngSwitchDefault>{{ row[i].text }}</span>
														</h4>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</li>
					</ul>
					<!-- Web View -->
					<div *ngIf="!media.matches">
						<div class="query-input">
							<input
								id="filterText"
								name="filterText"
								type="text"
								class="text"
								tabindex="1"
								(keyup)="applyFilter($any($event.target).value)"
								placeholder="{{ 'query.filter' | translate }}"
							/>
							<button class="sm" (click)="exportAsExcel()" translate="query.export"></button>
						</div>
						<div class="query-table-container">
							<table
								mat-table
								[dataSource]="sortedTableData"
								matSort
								(matSortChange)="sortData($event)"
								class="table dense"
							>
								<ng-container *ngFor="let header of table.headers; index as i" [matColumnDef]="header.text">
									<th
										mat-header-cell
										*matHeaderCellDef
										[mat-sort-header]="header.text"
										[title]="header?.hover ? header.hover : ''"
									>
										{{ header.text }}
									</th>
									<td mat-cell *matCellDef="let data" [title]="data[i]?.hover ? data[i].hover : ''">
										<ng-container *ngIf="data[i]" [ngSwitch]="data[i].type">
											<a *ngSwitchCase="'LINK'" [href]="data[i].link" target="_blank">{{ data[i].text }}</a>
											<img *ngSwitchCase="'IMAGE'" [src]="data[i].link" alt="{{ data[i].text }}" />
											<span *ngSwitchDefault>{{ data[i].text }}</span>
										</ng-container>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
							</table>
						</div>
					</div>
				</div>
				<p class="empty" *ngIf="!table"></p>
			</div>

			<div class="query" *ngIf="error">
				<div class="query-error">
					<div>
						<i class="ico ico-warning"></i>
					</div>
					<h3 translate="query.error"></h3>
					<span class="reason">{{ error }}</span>
				</div>
			</div>
		</div>
	</stages-media-query>
</article>
