<div *ngIf="query && query.answers">
	<div
		class="answer-item"
		*ngFor="let answer of query.answers"
		[class.active]="isActive(answer.id, query)"
		(click)="onSelect(answer, query)"
	>
		<p>{{ answer.name }}</p>
	</div>
</div>
