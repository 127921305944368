import { Injectable } from "@angular/core";

type DisplayDescription = stages.core.format.DisplayDescription;

@Injectable({ providedIn: "root" })
export class EditorToDisplayDescriptionMap {
	private map = new Map<string, DisplayDescription>();

	set(editorId: string, beanType: string, beanId: string, description: DisplayDescription): void {
		this.map.set(getEditorKey(editorId, beanType, beanId), description);
	}

	getAndDelete(editorId: string, beanType: string, beanId: string): DisplayDescription | undefined {
		const editorKey = getEditorKey(editorId, beanType, beanId);
		const description = this.map.get(editorKey);
		this.map.delete(editorKey);
		return description;
	}
}

function getEditorKey(editorId: string, beanType: string, beanId: string): string {
	return `${editorId}//${beanType}//${beanId}`;
}
