import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { PageableDataSource } from "common/data/pageable-data-source.logic";
import { DashboardResource } from "core/stages-client";
import { MyTaskStore } from "dashboard/to-do-list/to-do-list-store.logic";

type ToDoGroup = stages.dashboard.ToDoGroup;

@Injectable({ providedIn: "root" })
export class ToDoListDataSource {
	connected: boolean = false;
	dataSource!: PageableDataSource<ToDoGroup>;

	constructor(
		private router: Router,
		private mutexService: MutexService,
		private dashboardResource: DashboardResource,
	) {}

	connect(route: ActivatedRoute): PageableDataSource<ToDoGroup> {
		if (!this.dataSource) {
			this.dataSource = new PageableDataSource(
				new MyTaskStore(this.dashboardResource),
				this.mutexService,
				this.router,
				route,
			);
		} else {
			this.dataSource.connect(route);
		}

		return this.dataSource;
	}

	disconnect(): void {
		this.dataSource.disconnect();
	}
}
