import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "stages-switch",
	templateUrl: "./switch.component.html",
})
export class SwitchComponent {
	@Input()
	id?: string;

	@Input()
	value?: boolean;

	@Output() readonly valueChange = new EventEmitter<boolean>();

	valueChanged(): void {
		this.valueChange.emit(this.value);
	}
}
