import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProcessVersionService, ProcessVersionWithAvailability } from "common/version/process-version.service";
import { ProcessInterfacesFilterService } from "management/process-interfaces/process-interfaces-filter.service";
import { ProcessInterfacesParams } from "management/process-interfaces/process-interfaces.component";

export interface DropdownEntry {
	ident: string;
	translate: string;
}

@Component({
	selector: "stages-process-interfaces-filter",
	templateUrl: "./process-interfaces-filter.component.html",
	styleUrls: ["./process-interfaces-filter.component.scss"],
})
export class ProcessInterfacesFilterComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	_dropdownContent?: DropdownEntry[];
	_processVersions?: ProcessVersionWithAvailability[];
	_currentWorkspaceId!: string;
	_queryParams?: ParamMap;

	@Input()
	set dropdownContent(dropdownContent: DropdownEntry[] | undefined) {
		this._dropdownContent = dropdownContent;

		if (dropdownContent === undefined) {
			this.dropdownValue = undefined;
		}
		if (dropdownContent) {
			this.setDropdownValue(this._queryParams);
		}

		this.processInterfacesFilterService.setFilterToolbarVisible(this.isFilterToolbarVisible());
	}

	get dropdownContent(): DropdownEntry[] | undefined {
		return this._dropdownContent;
	}

	@Input()
	showSearch: boolean = false;

	@Input()
	placeholderText: string = "management.process.interfaces.filter.search";

	@Input()
	showChangesSwitch: boolean = true;

	showChanges: boolean = false;
	workingVersionSelected = false;
	dropdownValue?: string;
	searchTermValue: string = "";

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private processVersionService: ProcessVersionService,
		private processInterfacesFilterService: ProcessInterfacesFilterService,
	) {}

	ngOnInit(): void {
		combineLatest([this.route.paramMap, this.route.queryParamMap])
			.pipe(takeUntil(this.destroy$))
			.subscribe(async ([urlParamMap, urlQueryParamMap]) => {
				this._currentWorkspaceId = urlParamMap.get(ProcessInterfacesParams.WORKSPACEID)!;
				this._queryParams = urlQueryParamMap;

				if (urlQueryParamMap.has(ProcessInterfacesParams.SHOWONLYCHANGES)) {
					this.processInterfacesFilterService.setShowOnlyChanges(
						urlQueryParamMap.get(ProcessInterfacesParams.SHOWONLYCHANGES) === "true",
					);
				} else {
					await this.onShowOnlyChangesToggle();
				}

				if (!this._processVersions) {
					this._processVersions = await this.processVersionService.getProcessVersions(this._currentWorkspaceId, true);
				}

				if (this._processVersions) {
					const specificProcessVersion = urlQueryParamMap.has(ProcessInterfacesParams.PVFORINTERFACES)
						? urlQueryParamMap.get(ProcessInterfacesParams.PVFORINTERFACES)
						: urlParamMap.get(ProcessInterfacesParams.PV)!;

					this.workingVersionSelected =
						this._processVersions.find(
							(version) => specificProcessVersion === ProcessInterfacesParams.WORKINGVERSION && version.workingVersion,
						) !== undefined;

					if (!this.workingVersionSelected) {
						this.showChanges = false;
						await this.onShowOnlyChangesToggle();
					}
				}

				this.processInterfacesFilterService.setFilterToolbarVisible(this.isFilterToolbarVisible());

				if (this.isFilterToolbarVisible()) {
					await this.setDropdownValue(urlQueryParamMap);
				}
			});

		this.processInterfacesFilterService.showOnlyChanges$.pipe(takeUntil(this.destroy$)).subscribe((showOnlyChanges) => {
			this.showChanges = showOnlyChanges;
		});

		this.processInterfacesFilterService.searchTerm$.pipe(takeUntil(this.destroy$)).subscribe((searchTerm) => {
			if (searchTerm !== this.searchTermValue) {
				this.searchTermValue = searchTerm;
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}

	async setDropdownValue(queryParams?: ParamMap): Promise<void> {
		if (this.dropdownContent) {
			const oldDropdownValue = this.dropdownValue;
			if (queryParams?.has(ProcessInterfacesParams.TYPE)) {
				const typeName = queryParams.get(ProcessInterfacesParams.TYPE)!;
				this.dropdownValue = this.dropdownContent.find((entry) => entry.ident === typeName)?.ident;
			}
			if (this.dropdownValue === undefined) {
				this.dropdownValue = this.dropdownContent[0].ident;
			}
			if (oldDropdownValue !== this.dropdownValue) {
				await this.onFilterTypeChange();
			}
		}
	}

	isFilterToolbarVisible(): boolean {
		return !!this.dropdownContent || this.showSearch || (this.showChangesSwitch && this.workingVersionSelected);
	}

	onSearchTermChange(): void {
		this.processInterfacesFilterService.setSearchTerm(this.searchTermValue);
	}

	async onShowOnlyChangesToggle(): Promise<void> {
		this.processInterfacesFilterService.setShowOnlyChanges(this.showChanges);
		await this.internalRouterNavigateWithQueryParams({ showOnlyChanges: this.showChanges });
	}

	async onFilterTypeChange(): Promise<void> {
		this.processInterfacesFilterService.setFilterType(this.dropdownValue!);
		const queryFilterType = this.dropdownValue!.length <= 0 ? null : this.dropdownValue;
		await this.internalRouterNavigateWithQueryParams({ type: queryFilterType });
	}

	async internalRouterNavigateWithQueryParams(params: Params): Promise<void> {
		await this.router.navigate([], {
			queryParamsHandling: ProcessInterfacesParams.MERGE,
			queryParams: params,
			replaceUrl: true,
		});
	}
}
