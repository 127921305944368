<ng-container *ngIf="!self?.changeMarker; else compare">
	<ng-container [ngSwitch]="headerLevel">
		<h1 *ngSwitchDefault [ngClass]="{ tailored: self?.tailored }">{{ self?.label }}</h1>
		<h2 *ngSwitchCase="2" [ngClass]="{ tailored: self?.tailored }">{{ self?.label }}</h2>
		<h3 *ngSwitchCase="3" [ngClass]="{ tailored: self?.tailored }">{{ self?.label }}</h3>
		<h4 *ngSwitchCase="4" [ngClass]="{ tailored: self?.tailored }">{{ self?.label }}</h4>
		<h5 *ngSwitchCase="5" [ngClass]="{ tailored: self?.tailored }">{{ self?.label }}</h5>
		<h6 *ngSwitchCase="6" [ngClass]="{ tailored: self?.tailored }">{{ self?.label }}</h6>
	</ng-container>
</ng-container>
<ng-template #compare>
	<ng-container [ngSwitch]="headerLevel">
		<h1 *ngSwitchDefault [ngClass]="{ tailored: self?.tailored }" [innerHTML]="(self?.changeMarker)!.label"></h1>
		<h2 *ngSwitchCase="2" [ngClass]="{ tailored: self?.tailored }" [innerHTML]="(self?.changeMarker)!.label"></h2>
		<h3 *ngSwitchCase="3" [ngClass]="{ tailored: self?.tailored }" [innerHTML]="(self?.changeMarker)!.label"></h3>
		<h4 *ngSwitchCase="4" [ngClass]="{ tailored: self?.tailored }" [innerHTML]="(self?.changeMarker)!.label"></h4>
		<h5 *ngSwitchCase="5" [ngClass]="{ tailored: self?.tailored }" [innerHTML]="(self?.changeMarker)!.label"></h5>
		<h6 *ngSwitchCase="6" [ngClass]="{ tailored: self?.tailored }" [innerHTML]="(self?.changeMarker)!.label"></h6>
	</ng-container>
</ng-template>
