<header>
	<div class="current-workspace">
		<a class="title clickable" (click)="closeAnchors.emit()">
			<i class="ico ico-arrow-back"></i>
			<h3 class="shorten" translate="editor.link.element.selection"></h3>
		</a>
	</div>
	<h3
		class="plugin-title"
		translate="editor.link.anchors.heading"
		[translateParams]="{ elementName: previewTitle }"
	></h3>
</header>
<article class="preview-container">
	<nav>
		<ul class="list">
			<li class="list-item" (click)="selectAnchorName('')">
				<div class="radio">
					<input type="radio" name="anchor" [(ngModel)]="selectedAnchor" value="" />
					<label for="version"></label>
				</div>
				<div translate="editor.link.anchors.top"></div>
			</li>
			<ng-container *ngIf="previewHtml">
				<li class="list-item" *ngFor="let anchorName of getAnchors(previewHtml)" (click)="selectAnchorName(anchorName)">
					<div class="radio">
						<input type="radio" name="anchor" [(ngModel)]="selectedAnchor" [value]="anchorName" />
						<label for="version"></label>
					</div>
					<div>{{ anchorName }}</div>
				</li>
			</ng-container>
		</ul>
	</nav>
</article>
<footer>
	<div class="buttons-bottom">
		<div class="spacer"></div>
		<button class="sm save" translate="insert" (click)="insertAnchor.emit()"></button>
	</div>
</footer>
