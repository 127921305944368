<article class="dashboard responsive-8-4">
	<stages-page-header
		[headline]="processInterfacesWorkspacesListPageHeader"
		[breadCrumbTemplate]="breadCrumb"
		[backButtonDisabled]="!detailsWorkspaceId"
		[showProcessVersionSwitch]="showProcessVersionSelector && !detailsWorkspaceId"
		(backButtonClicked)="onDetailsClosed()"
	></stages-page-header>
	<div class="container row">
		<div class="container column" [ngClass]="isFilterToolbarVisible ? 'col-8' : 'col-12'">
			<stages-card>
				<h5 [translate]="getCardHeadlineTranslateKey(detailsWorkspaceId)" [ngClass]="{ hide: !this.isFullSize }"></h5>
				<h5 translate="filter.show" [ngClass]="{ hide: this.isFullSize }"></h5>
				<div switchFilter class="switch-filter" [ngClass]="{ hide: this.isFullSize }">
					<input id="search.filter" type="checkbox" hidden="hidden" [(ngModel)]="showFilterActive" />
					<label for="search.filter" class="switch"></label>
				</div>
				<div *ngIf="!this.isFullSize" [ngClass]="{ hide: !showFilterActive }">
					<ng-container *ngTemplateOutlet="interfacesFilter"></ng-container>
				</div>

				<article>
					<router-outlet></router-outlet>
				</article>
				<div class="footer"></div>
			</stages-card>
		</div>
		<div *ngIf="this.isFullSize" [ngClass]="!isFilterToolbarVisible ? 'hide' : 'container column col-4'">
			<stages-card [showHeader]="false">
				<ng-container *ngTemplateOutlet="interfacesFilter"></ng-container>
				<div class="footer"></div>
			</stages-card>
		</div>
	</div>
</article>

<ng-template #interfacesFilter>
	<stages-process-interfaces-filter
		[dropdownContent]="detailsWorkspaceId ? dropdownContentArray : undefined"
		[showSearch]="!!detailsWorkspaceId"
	>
	</stages-process-interfaces-filter>
</ng-template>
