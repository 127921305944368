import { Directive, OnInit } from "@angular/core";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { PropertyService } from "process/element/properties/property.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
type ProcessView = stages.process.ProcessView;
type ViewableAssociation = stages.process.ViewableAssociation;
type ProcessElementProperty = stages.process.ProcessElementProperty;

export interface CollectedAssociation extends ViewableAssociation {
	sequence?: number[];
}

@Directive()
export class ProcessCollectionComponent implements OnInit {
	path!: string;
	self$!: Observable<ProcessView>;
	associations$!: Observable<ViewableAssociation[]>;

	constructor(
		private viewService: ViewService,
		private propertyService: PropertyService,
		protected mainService: MainService,
	) {}

	ngOnInit(): void {
		this.self$ = this.viewService.awaitSelfElementObservable();

		this.associations$ = this.self$.pipe(
			map((self: ProcessView) => {
				const associations: CollectedAssociation[] = self.associations![this.path].list;
				const depthStart: number = self.depth!;
				const sequence: number[] = [];

				associations
					.filter((a) => !!a.targetElement)
					.forEach((association) => {
						const depth = association.targetElement!.depth! - depthStart;
						if (depth > sequence.length - 1) {
							// down
							sequence.push(1);
						} else if (depth < sequence.length - 1) {
							// up
							sequence.pop();
							const index = sequence.pop();
							sequence.push(index ? index + 1 : 1);
						} else {
							// next
							const index = sequence.pop();
							sequence.push(index ? index + 1 : 1);
						}

						association.sequence = [...sequence];

						if (association.sequence.length > 0 && this.path.startsWith(".")) {
							association.sequence.shift();
						}
					});

				return associations;
			}),
		);
	}

	getValue(
		association: CollectedAssociation,
		property: ProcessElementProperty,
		useDisplayValue: boolean,
	): string | null | undefined {
		if (property.type === "derived" && property.ident === "autoNumber") {
			return association.sequence!.join(".");
		}
		if (property.ident === "description") {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			return (association.targetElement as any).description.html;
		}

		const value = this.propertyService.getValue(association.targetElement, property, useDisplayValue);
		if (this.isDescriptionValue(value)) {
			return (value as stages.core.format.DisplayDescription).html;
		}
		return value as string;
	}

	hasDescriptionValue(
		association: CollectedAssociation,
		property: ProcessElementProperty,
		useDisplayValue: boolean,
	): boolean {
		if (property.type === "derived" && property.ident === "autoNumber") {
			return false;
		}
		if (property.ident === "description") {
			return true;
		}
		const value = this.propertyService.getValue(association.targetElement, property, useDisplayValue);
		return this.isDescriptionValue(value);
	}

	private isDescriptionValue(value: stages.core.format.DisplayDescription | string | null | undefined): boolean {
		return !!value && !!(value as stages.core.format.DisplayDescription).html;
	}

	getRoute(association: CollectedAssociation): unknown[] {
		const commands = [];
		commands.push("/");
		commands.push("workspace");
		commands.push(association.targetElement!.workspaceId);
		commands.push(association.targetElement!.process.pv);

		if (
			this.mainService.secondaryMode &&
			this.mainService.secondaryWorkspaceId &&
			this.mainService.secondaryProcessVersion &&
			!association.isProcessInterface
		) {
			commands.push({
				swid: this.mainService.secondaryWorkspaceId,
				spv: this.mainService.secondaryProcessVersion,
				smode: this.mainService.secondaryMode,
			});
		}
		return [...commands, "process", association.targetElement!.type.ident, association.targetElement!.identity];
	}
}
