import { Injectable } from "@angular/core";
import { ProcessInterfacesResource } from "core/stages-client";
import { BehaviorSubject } from "rxjs";
import { Selectable } from "common/selection/state/selectable.model";

type ManualInterfaceSelection = stages.process.release.ManualInterfaceSelection;
type ProcessInterfaceWorkspaceSelectionOverview =
	stages.management.interfaces.ProcessInterfaceWorkspaceSelectionOverview;
type ProcessElementInterface = stages.management.interfaces.ProcessElementInterface;
type ProcessInterfaceWorkspace = stages.management.interfaces.ProcessInterfaceWorkspace;

@Injectable({ providedIn: "root" })
export class ProcessInterfacesService {
	constructor(private readonly processInterfacesResource: ProcessInterfacesResource) {}
	_filterableElementTypes: string[] = [];

	targetWorkspaceId$ = new BehaviorSubject<string | undefined>(undefined);
	workspaceNameFrom = new BehaviorSubject<string | undefined>(undefined);
	workspaceNameTo = new BehaviorSubject<string | undefined>(undefined);
	showReleaseWarning$ = new BehaviorSubject<boolean>(false);
	workspacePage$ = new BehaviorSubject<number>(1);
	filterableElementTypes$ = new BehaviorSubject<string[]>([]);
	filterElementType$ = new BehaviorSubject<string | undefined>(undefined);

	setTargetWorkspaceId(targetWorkspaceId: string | undefined): void {
		this.targetWorkspaceId$.next(targetWorkspaceId);
	}

	setShowReleaseWarning(showReleaseWarning: boolean): void {
		this.showReleaseWarning$.next(showReleaseWarning);
	}

	setWorkspacePage(workspacePage: number): void {
		this.workspacePage$.next(workspacePage);
	}

	setFilterElementType(filterType: string): void {
		this.filterElementType$.next(filterType);
	}

	async loadMoreWorkspaces(
		page: number,
		pageSize: number,
		showOnlyChanges: boolean,
		isReleasePreview: boolean,
		workspaceId: string,
		processVersion: string,
		processVersionToCompare: string,
	): Promise<SecuredPagedResultAndInfo<ProcessInterfaceWorkspace, ProcessInterfaceWorkspaceSelectionOverview[]>> {
		return this.processInterfacesResource.getInterfaceWorkspaces(workspaceId, {
			page: page,
			pageSize: pageSize,
			showOnlyChanges: showOnlyChanges,
			isReleasePreview: isReleasePreview,
			processVersion: processVersion,
			processVersionToCompareWith: processVersionToCompare,
		});
	}

	async loadMoreInterfaces(
		page: number,
		pageSize: number,
		workspaceId: string,
		targetWorkspaceId: string,
		processVersion: string,
		processVersionToCompare: string,
		showOnlyChanges: boolean,
		isReleasePreview: boolean,
		filterType: string,
		filterTerm: string,
	): Promise<PagedResult<ProcessElementInterface>> {
		const interfacesWithDetails = await this.processInterfacesResource.getProcessInterfaces(
			workspaceId,
			targetWorkspaceId,
			{
				page: page,
				pageSize: pageSize,
				processVersion: processVersion,
				processVersionToCompareWith: processVersionToCompare,
				showOnlyChanges: showOnlyChanges,
				isReleasePreview: isReleasePreview,
				filterType: filterType,
				filterTerm: filterTerm,
			},
		);

		if (
			interfacesWithDetails.filterableElementTypes.length !== this._filterableElementTypes.length ||
			!interfacesWithDetails.filterableElementTypes.every((value, index) => {
				return value === this._filterableElementTypes[index];
			})
		) {
			this._filterableElementTypes = interfacesWithDetails.filterableElementTypes;
			this.filterableElementTypes$.next(interfacesWithDetails.filterableElementTypes);
		}

		if (interfacesWithDetails.fromWorkspace) {
			this.workspaceNameFrom.next(interfacesWithDetails.fromWorkspace);
		}

		if (interfacesWithDetails.toWorkspace) {
			this.workspaceNameTo.next(interfacesWithDetails.toWorkspace);
		}

		return interfacesWithDetails.processInterfaces;
	}
}

// TODO simplify
export function splitBy(
	selectedWorkspaces: Selectable[],
	interfacesToReleaseSelectables: Selectable[],
): ManualInterfaceSelection[] {
	const selectedInterfaces = interfacesToReleaseSelectables.reduce(
		(interfacesToRelease: ManualInterfaceSelection[], selectable: Selectable) => {
			const existingInterface = interfacesToRelease.find(
				(interfaceToRelease) => selectable.parentId === interfaceToRelease.workspaceId,
			);

			const hasSelectedWorkspace = selectedWorkspaces.find((selectedWorkspace: Selectable) => {
				return selectedWorkspace.id === selectable.parentId && selectedWorkspace.isSelected;
			});

			if (existingInterface) {
				if (!selectable.initiallySelected && selectable.isSelected) {
					existingInterface.manuallySelectedAssociationIds.push(selectable.id);
				}
				if (selectable.initiallySelected && !selectable.isSelected) {
					existingInterface.manuallyDeselectedAssociationIds.push(selectable.id);
				}
			} else if (!selectable.initiallySelected && selectable.isSelected && !!hasSelectedWorkspace) {
				// add to added interfaces list
				interfacesToRelease.push({
					workspaceId: selectable.parentId!,
					manuallySelectedAssociationIds: [selectable.id],
					manuallyDeselectedAssociationIds: [],
					onlyPreselected: false,
				});
			} else if (selectable.initiallySelected && !selectable.isSelected && !!hasSelectedWorkspace) {
				// add to removed interfaces list
				interfacesToRelease.push({
					workspaceId: selectable.parentId!,
					manuallySelectedAssociationIds: [],
					manuallyDeselectedAssociationIds: [selectable.id],
					onlyPreselected: false,
				});
			}
			return interfacesToRelease;
		},
		[],
	);

	const allSelectedWorkspaces: ManualInterfaceSelection[] = [];

	selectedWorkspaces.forEach((selectedWorkspace: Selectable) => {
		// if we changed the selection of interfaces regarding this workspace we ignore the ws and use the manually selected interfaces instead
		if (selectedWorkspace.isSelected && !selectedInterfaces.find((s) => selectedWorkspace.id === s.workspaceId)) {
			allSelectedWorkspaces.push({
				workspaceId: selectedWorkspace.id,
				manuallySelectedAssociationIds: [],
				manuallyDeselectedAssociationIds: [],
				onlyPreselected: !!selectedWorkspace.indeterminate,
			});
		}
	});

	return [...allSelectedWorkspaces, ...selectedInterfaces];
}
