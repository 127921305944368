<ng-container *ngIf="phaseMatrix$ | async as phaseMatrix">
	<ng-container *ngIf="phaseMatrix.program as program">
		<ng-container *ngIf="program.phases as programPhases">
			<stages-fit-to-page [fitToPage]="fitToPage">
				<table class="phaseOverview">
					<tr>
						<td class="program"></td>
						<ng-container *ngFor="let item of [].constructor(phaseMatrix.program.duration); let i = index">
							<td>
								<div class="phase dummy"></div>
							</td>
						</ng-container>
					</tr>

					<tr>
						<td class="program">
							<div class="ws-name" *ngIf="showUpdateButton">
								<div class="action" *ngIf="program.hasErrors">
									<button
										[attr.aria-label]="'error' | translate"
										[attr.title]="'error' | translate"
										(click)="openPopup(program.getJobProgressRoute())"
									>
										<i class="ico ico-warning error-color"></i>
									</button>
								</div>
								<button
									class="action"
									[disabled]="program.isDisabled()"
									(click)="openPopup(phaseMatrix.getUpdateConsiderFrozenAllRoute())"
								>
									<i [ngClass]="phaseMatrix.getIconClasses()" *ngIf="!program.inProgress"></i>
									<a (click)="openPopup(program.getJobProgressRoute())" *ngIf="program.inProgress">
										<stages-spin></stages-spin>
									</a>
								</button>
							</div>
						</td>
						<ng-container *ngFor="let phaseAdapter of program.phases; trackBy: getPhaseId">
							<ng-container
								*ngTemplateOutlet="phaseCard; context: { $implicit: phaseAdapter, processAdapter: program }"
							></ng-container>
						</ng-container>
					</tr>

					<ng-container *ngFor="let process of phaseMatrix.projects; trackBy: getProcessId">
						<tr>
							<td>
								<div class="ws-name">
									<a [routerLink]="process.getRouterLink()" title="{{ $any(process.process).workspaceName }}">
										<span class="span2">{{ $any(process.process).workspaceName }}</span>
									</a>
									<div class="action" *ngIf="process.hasErrors && showUpdateButton">
										<button
											[attr.aria-label]="'error' | translate"
											[attr.title]="'error' | translate"
											(click)="openPopup(process.getJobProgressRoute())"
										>
											<i class="ico ico-warning error-color"></i>
										</button>
									</div>
									<button
										class="action"
										[disabled]="process.isDisabled()"
										(click)="openPopup(process.getUpdateConsiderFrozenRoute())"
										*ngIf="showUpdateButton"
									>
										<i [ngClass]="process.getIconClasses()" *ngIf="!process.inProgress"></i>
										<a (click)="openPopup(process.getJobProgressRoute())" *ngIf="process.inProgress">
											<stages-spin></stages-spin>
										</a>
									</button>
								</div>
							</td>
							<ng-container *ngFor="let phaseAdapter of process.phases; trackBy: getPhaseId">
								<ng-container
									*ngTemplateOutlet="phaseCard; context: { $implicit: phaseAdapter, processAdapter: process }"
								></ng-container>
							</ng-container>
							<ng-container *ngFor="let phaseAdapter of program.phases; trackBy: getPhaseId; let i = index">
								<td [attr.colspan]="1" *ngIf="i >= process.duration">
									<div class="wrapper"></div>
								</td>
							</ng-container>
						</tr>
					</ng-container>
				</table>
			</stages-fit-to-page>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template #phaseCard let-phaseAdapter let-processAdapter="processAdapter">
	<td [attr.colspan]="phaseAdapter.duration">
		<div class="phase" [ngClass]="phaseAdapter.getFreezeStateClasses()">
			<a [routerLink]="phaseAdapter.getRouterLink()" title="{{ phaseAdapter.phase.label }}">
				<span [ngClass]="phaseAdapter.getLabelClasses()" class="span2">{{ phaseAdapter.phase.label }}</span>
			</a>
			<div class="action" *ngIf="phaseAdapter.hasErrors">
				<button
					[attr.aria-label]="'error' | translate"
					[attr.title]="'error' | translate"
					(click)="openPopup(phaseAdapter.getJobProgressRoute())"
				>
					<i class="ico ico-warning error-color"></i>
				</button>
			</div>
			<button
				class="action"
				[disabled]="phaseAdapter.isDisabled() || processAdapter.isDisabled()"
				(click)="openPopup(phaseAdapter.getStartFreezeRoute())"
			>
				<i [ngClass]="getIconClasses(phaseAdapter)" *ngIf="!phaseAdapter.inProgress"></i>
				<a (click)="openPopup(phaseAdapter.getJobProgressRoute())" *ngIf="phaseAdapter.inProgress">
					<stages-spin></stages-spin>
				</a>
			</button>
		</div>
	</td>
</ng-template>
