<header class="dialog-header">
	<div></div>
	<button class="action">
		<i class="ico ico-close" (click)="cancel()"></i>
	</button>
</header>

<div class="content">
	<form [formGroup]="form" class="form" (ngSubmit)="execute()" novalidate>
		<h1
			translate="process.enactment.activator.execution.start.dialogtitle"
			[translateParams]="{ title: keys.activatorName }"
		></h1>
		<div class="form">
			<div class="form-group">
				<div class="input-container sm required">
					<div *ngFor="let input of inputs" class="fullsize">
						<stages-input [form]="form" [input]="input"></stages-input>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<footer>
	<div class="buttons-bottom buttons-right buttons-bottom-margin-fix">
		<div class="spacer"></div>
		<button class="button sm cancel" translate="cancel" (click)="cancel()"></button>
		<button class="button sm ok" translate="execute" (click)="execute()"></button>
	</div>
</footer>
