<ng-container *ngIf="getVisibleGroups(groups) as visibleGroups">
	<article class="collapsible-groups" [ngClass]="{ 'mulitple-groups': visibleGroups.length > 1 }">
		<p *ngIf="showTranslateNone(visibleGroups) && translateNone" class="empty" translate="{{ translateNone }}"></p>
		<ul class="grouped-list" *ngIf="visibleGroups.length > 1 || singleGroupCollapsible">
			<ng-container *ngFor="let group of visibleGroups; trackBy: trackBy; let last = last">
				<li class="group" [ngClass]="{ last: last }">
					<header class="group-header" [ngClass]="{ hover: hoverOnHeader }" (click)="toggle(group)">
						<ng-container
							[ngTemplateOutlet]="!!groupHeaderTemplate ? groupHeaderTemplate : defaultGroupHeader"
							[ngTemplateOutletContext]="{ $implicit: group }"
						>
						</ng-container>
						<stages-button-expand-collapse
							[isCollapsed]="!isGroupOpen(group)"
							[classes]="'action menu-button'"
						></stages-button-expand-collapse>
						<ng-container
							*ngIf="!!groupHeaderActionMenuTemplate"
							[ngTemplateOutlet]="groupHeaderActionMenuTemplate"
							[ngTemplateOutletContext]="{ $implicit: group }"
						></ng-container>
					</header>
					<div class="relative-container">
						<div class="animation-container">
							<div
								#expandableGroup
								[@expandCollapseFromTop]="getExpandCollapseFromTop(group, expandableGroup)"
								class="expandable"
								[ngClass]="isGroupOpen(group) ? 'expand' : 'collapse'"
							>
								<ng-container
									[ngTemplateOutlet]="groupContentTemplate"
									[ngTemplateOutletContext]="{ $implicit: group }"
								>
								</ng-container>
							</div>
						</div>
					</div>
				</li>
			</ng-container>
		</ul>
		<ul class="grouped-list" *ngIf="visibleGroups.length == 1 && !singleGroupCollapsible">
			<ng-container
				[ngTemplateOutlet]="groupContentTemplate"
				[ngTemplateOutletContext]="{ $implicit: visibleGroups[0] }"
			>
			</ng-container>
		</ul>
	</article>
</ng-container>

<ng-template #defaultGroupHeader let-group>
	<h3>
		{{ group.name ? group.name : (group.nameKey! | translate)
		}}<span class="counter">{{ group.getMemberCount() }}</span>
	</h3>
</ng-template>
