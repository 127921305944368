import { Injectable } from "@angular/core";
import { StagesCoreModule } from "core/core.module";
import {
	BasicNavigationConfiguration,
	NavigationConfiguration,
} from "navigation/list/navigation-configuration.interface";
import { BasicNavigationEntry, ListNavigationEntry } from "navigation/list/navigation-entry.interface";
import { NavigationMode } from "navigation/list/navigation.interface";
import { Observable, ReplaySubject, Subject } from "rxjs";

@Injectable({
	providedIn: StagesCoreModule,
})
export class NavigationService {
	navigationConfiguration$: Observable<NavigationConfiguration>;
	private navigationConfigurationSubject = new ReplaySubject<NavigationConfiguration>(1);
	forceRefresh$: Observable<boolean>;
	private forceRefreshSubject = new Subject<boolean>();

	constructor() {
		this.navigationConfiguration$ = this.navigationConfigurationSubject;
		this.forceRefresh$ = this.forceRefreshSubject;
	}

	update(navigationConfiguration: NavigationConfiguration): void {
		this.navigationConfigurationSubject.next(navigationConfiguration);
	}

	updateSimpleNavigation(basicNavigationConfiguration: BasicNavigationConfiguration): void {
		this.navigationConfigurationSubject.next(convert(basicNavigationConfiguration));
	}

	refresh(): void {
		this.forceRefreshSubject.next(true);
	}
}

function convertEntry(source: BasicNavigationEntry): ListNavigationEntry {
	return {
		...source,
		entryType: "single-line",
		actions: {},
		editDone: async () => Promise.resolve(),
		exact: source.exact !== undefined && source.exact,
	};
}

function convert(source: BasicNavigationConfiguration): NavigationConfiguration {
	return {
		homeRoute: source.homeRoute,
		parentRoute: source.parentRoute,
		list: source.list.map(convertEntry),
		selectionIdentifier: "baseNav.unused",
		supportedModes: new Map<string, NavigationMode>(),
		clipboardConfiguration: {
			workspaceFilter: null,
			routeAfterWorkspaceChange: [],
			supportedModes: new Map<string, NavigationMode>(),
		},
		invalidate(): void {},
	};
}
