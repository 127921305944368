<div class="content">
	<h1 translate="process.element.tailor.heading"></h1>
	<div *ngIf="targetStatus !== TailorStatus.RESET" class="panel">
		<h5 translate="process.element.tailor.justification"></h5>
		<stages-description-editor
			[editorId]="'tailoringjustification'"
			[modifiable]="true"
			[unsafe]="false"
			[description]="justification"
			[processTypeIdent]="processTypeIdent!"
			[pluginRegistry]="pluginRegistry"
			[beanType]="'process'"
			[beanId]="processId!"
			[beanIdentity]="'unused'"
			[workspaceId]="workspaceId"
			[pv]="pv"
			(editEnabledChange)="onEditEnabledChanged($event)"
		>
		</stages-description-editor>
	</div>
	<stages-list-of-lists>
		<stages-collapsible-list
			*ngIf="complianceConflicts.length > 0"
			[ngClass]="'compliance-conflicts'"
			[chevronClass]="'chevron'"
			[header]="'process.element.tailor.complianceconflicts.title' | translate"
			[listItemTemplate]="listConflictsTemplate"
			[listClass]="'dense'"
			[itemClass]="'conflict'"
			[items]="complianceConflicts"
			[expanded]="true"
		>
		</stages-collapsible-list>
		<stages-collapsible-list
			*ngIf="addedElements.length > 0"
			[header]="'process.element.tailor.addedelements' | translate"
			[listItemTemplate]="listItemTemplate"
			[listClass]="'dense'"
			[items]="addedElements"
			[expanded]="false"
		>
		</stages-collapsible-list>
		<stages-collapsible-list
			*ngIf="removedElements.length > 0"
			[header]="'process.element.tailor.removedelements' | translate"
			[listItemTemplate]="listItemTemplate"
			[listClass]="'dense'"
			[items]="removedElements"
			[expanded]="false"
		>
		</stages-collapsible-list>
	</stages-list-of-lists>
</div>
<footer>
	<div class="buttons">
		<button class="button sm cancel" translate="cancel" (click)="onCancel()"></button>
		<button class="button sm ok" [disabled]="isSaveDisabled()" (click)="onSave()">
			<span *ngIf="!saveInProgress" translate="save"></span>
			<stages-spin *ngIf="saveInProgress" size="s"></stages-spin>
		</button>
	</div>
</footer>

<ng-template #listItemTemplate let-item>
	<div class="label" *ngIf="!item.dependent">
		<i class="ico" [ngClass]="getIconClasses(item)"></i>
		<h4>{{ item.label }}</h4>
	</div>
	<div class="label" *ngIf="item.dependent">
		<i class="ico" [ngClass]="getIconClasses(item.dependentElementContainer)"></i>
		<h4>{{ item.dependentElementContainer.label }}</h4>
		<i class="ico ico-tag" [ngClass]="getIconClasses(item)"></i>
		<h4>{{ item.label }}</h4>
	</div>
</ng-template>

<ng-template #listConflictsTemplate let-conflict>
	<div class="compliance-conflict">
		<div class="label">
			<i class="ico" [ngClass]="getIconClasses(conflict)"></i>
			<h4>{{ conflict.label }}</h4>
		</div>
		<div class="workspace">
			<p>{{ conflict.workspaceName }}</p>
		</div>
	</div>
</ng-template>
