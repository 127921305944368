import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Group, GroupDefinition } from "common/associations/association-group.service";
import { Container, Groups, SourceElementAndGroup } from "common/associations/association-list.component";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { ComplianceAssociationService } from "process/compliance/associations/compliance-association-service";
import {
	ComplianceAdapter,
	ComplianceAssociationStore,
} from "process/compliance/associations/compliance-association-store.logic";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

type ViewableElement = stages.process.ViewableElement;

type TargetElement = stages.compliance.TargetElement;
type ReferenceComplianceAssociationGroup = stages.compliance.ReferenceComplianceAssociationGroup;

@Component({
	selector: "stages-compliance-association-list",
	templateUrl: "./compliance-association-list.component.html",
})
export class ComplianceAssociationListComponent implements OnInit {
	@Input()
	classes: string = "";

	@Input()
	messageKeyNone?: string;

	@Input()
	editable?: boolean;

	@Input()
	showEmptyGroups = false;

	container$!: Observable<Container<ViewableElement, TargetElement>>;

	associationStore: ComplianceAssociationStore;

	constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private viewService: ViewService,
		associationService: ComplianceAssociationService,
	) {
		this.associationStore = new ComplianceAssociationStore(viewService, associationService);
	}

	ngOnInit(): void {
		this.container$ = this.viewService.awaitSelfElementObservable().pipe(
			//while same id
			distinctUntilChanged((p: stages.process.ProcessView, q: stages.process.ProcessView) => p.id !== q.id),
			map((p) => {
				return {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					sourceElement: p as any,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
					associationGroups: this.buildGroups(p as any),
					preferencesKey: "modelAssociation.list.state." + p.processType + "." + p.type.ident,
				};
			}),
		);
	}

	openBrowse(
		sourceElementAndGroup: SourceElementAndGroup<ComplianceAdapter, TargetElement>,
		container: Container<ViewableElement, TargetElement>,
	): void {
		const paramMap = this.route.snapshot.paramMap;

		const group = sourceElementAndGroup.associationGroup;
		const groupMeta = container.sourceElement.complianceRatingContainer!.associations[group.path];
		const browseWorkspaceId = groupMeta.limitTo ? this.mainService.secondaryWorkspaceId : paramMap.get("workspaceId")!;

		this.mainService.openPopup(
			[
				"process",
				"compliance",
				paramMap.get("type"),
				sourceElementAndGroup.sourceElement.identity,
				browseWorkspaceId,
				this.mainService.secondaryProcessVersion,
				group.targetType,
				"index",
				{
					browseroottype: group.targetType,
					grouppath: group.path,
					allowNavigation: false,
					allowIndex: true,
					dependentTypesRestrictions: group.allowAssociateDependentElements
						? group.targetDependentTypes === undefined
							? null
							: group.targetDependentTypes
						: [],
				},
				"elements",
			],
			this.route,
		);
	}

	buildGroups(self: ViewableElement): Groups<TargetElement> {
		const result: Groups<TargetElement> = {};
		const complianceRatingContainer = self.complianceRatingContainer;
		if (complianceRatingContainer) {
			// TODO: Fix this warning and do not Copy&Paste this code!
			for (const groupKey in complianceRatingContainer.associations) {
				if (groupKey) {
					result[groupKey] = this.buildGroup(complianceRatingContainer.associations[groupKey]);
				}
			}
		}
		return result;
	}

	buildGroup(complianceAssociationGroup: ReferenceComplianceAssociationGroup): Group<TargetElement> {
		return {
			id: complianceAssociationGroup.id,
			path: complianceAssociationGroup.targetType + ":" + complianceAssociationGroup.type,
			translate: complianceAssociationGroup.translate,
			list: complianceAssociationGroup.list,
			derived: false,
			commentSupported: true,
			commentOnlyAssociationsSupported: true,
			allowAssociateDependentElements: false,
			targetType: complianceAssociationGroup.targetType,
			type: complianceAssociationGroup.type,
			actions: complianceAssociationGroup.actions,
			workspace: this.mainService.secondaryWorkspaceId!,
			subgroups: [],
		};
	}

	asArray(groups: Groups<TargetElement>): GroupDefinition[] {
		return Object.keys(groups).map((key) => {
			return {
				path: key,
				id: groups[key].id,
				translate: groups[key].translate,
				allowAssociateDependentElements: groups[key].allowAssociateDependentElements,
			};
		});
	}
}
