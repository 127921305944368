<stages-dialog2 #dialog>
	<stages-dialog-header></stages-dialog-header>

	<h1 [translate]="actionMessageProperty"></h1>
	<ng-container *ngIf="checkoutDataAndInfo$ | async as checkoutDataAndInfo; else loadingOrError">
		<form [formGroup]="formModel" class="content" name="group" novalidate #formElement>
			<div class="form">
				<stages-attribute-edit
					[attributeTypes]="checkoutDataAndInfo.metadata"
					[attributes]="checkoutDataAndInfo.data"
					[form]="formModel"
				>
				</stages-attribute-edit>
			</div>
		</form>

		<footer>
			<div class="buttons">
				<button class="button sm cancel" translate="cancel" (click)="dialog.close()" tabindex="2"></button>
				<button
					class="button sm ok"
					[translate]="actionMessageProperty"
					(click)="submit(checkoutDataAndInfo.data)"
					tabindex="3"
				></button>
			</div>
		</footer>
	</ng-container>
</stages-dialog2>

<ng-template #loadingOrError>
	<h1 *ngIf="loadingError$ | async as error" translate="error.title"></h1>
	<stages-popup-content>
		<stages-loading-error-embeddable
			[credentialsValid]="true"
			[loadingError$]="loadingError$"
		></stages-loading-error-embeddable>
	</stages-popup-content>
	<stages-popup-footer [hasCancelButton]="false" [buttons]="buttons"> </stages-popup-footer>
</ng-template>
