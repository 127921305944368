import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { ProcessVersionService, ProcessVersionWithAvailability } from "common/version/process-version.service";
import { WorkspaceSelected } from "common/workspace/workspace-selection.component";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { CompareToDialogComponent } from "process/compare/compare-to-dialog.component";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
	templateUrl: "./compare-to.component.html",
	styleUrls: ["./compare-to.component.scss"],
})
export class CompareToComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	formGroup: FormGroup = new FormGroup({});
	versions$!: Observable<ProcessVersionWithAvailability[]>;
	previousValidVersion!: stages.process.ProcessVersion | null;
	workspaceSelected!: BehaviorSubject<WorkspaceSelected>;
	workspaceSelectionSubject = new BehaviorSubject(false);
	workspaceSelectionMode?: boolean;
	currentWorkspaceId!: string;

	AUTO_HEIGHT_CLASS = "autoHeight";

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private mainService: MainService,
		private versionService: ProcessVersionService,
		private viewService: ViewService,
		private mutexService: MutexService,
		private parentComponent: CompareToDialogComponent,
	) {
		this.mainService.applicationState.isMobileView.pipe(takeUntil(this.destroy$)).subscribe((isMobileView: boolean) => {
			const classes = isMobileView ? ["lg"] : ["md"];
			classes.push(this.AUTO_HEIGHT_CLASS);
			this.parentComponent.classes = classes;
		});
		this.formGroup = new FormGroup({
			version: new FormControl("", [Validators.required]),
		});
	}

	async ngOnInit(): Promise<void> {
		const workspaceView: stages.workspace.application.WorkspaceView = await this.mainService.getCurrentWorkspace();
		this.currentWorkspaceId = workspaceView.currentWorkspace.id;
		this.workspaceSelected = new BehaviorSubject({
			id: workspaceView.currentWorkspace.id,
			name: workspaceView.currentWorkspace.name,
			path: workspaceView.currentWorkspace.path,
			pathSegments: workspaceView.currentWorkspace.pathSegments,
		});

		this.versions$ = combineLatest([
			this.workspaceSelectionSubject.asObservable(),
			this.workspaceSelected.asObservable(),
			this.route.paramMap,
		])
			.pipe(
				switchMap(async ([workspaceSelection, workspaceSelected, paramMap]) => {
					this.workspaceSelectionMode = workspaceSelection;
					if (workspaceSelection) {
						// clear versions in workspace selection mode in order to avoid flickering
						return { versions: [], previousValidVersion: null };
					}
					return this.versionService.getCompareBaselines(
						paramMap.get("workspaceId")!,
						workspaceSelected.id,
						paramMap.get("processVersion")!,
					);
				}),
			)
			.pipe(
				tap(
					(compareBaselines: {
						previousValidVersion: stages.process.ProcessVersion | null;
						versions: stages.process.ProcessVersion[];
					}) => {
						if (compareBaselines.previousValidVersion) {
							this.previousValidVersion = compareBaselines.previousValidVersion;
							this.formGroup.get("version")!.setValue(compareBaselines.previousValidVersion.versionIdentifier);
						} else if (compareBaselines.versions.length > 0) {
							this.formGroup.get("version")!.setValue(compareBaselines.versions[0].versionIdentifier);
						}
					},
				),
			)
			.pipe(map((compareBaselines: { versions: stages.process.ProcessVersion[] }) => compareBaselines.versions));
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	close(): void {
		this.parentComponent.dialog.close();
	}

	compare(versions: stages.process.ComparableProcessVersion[]): void {
		const versionIdentifier = this.formGroup.get("version")!.value;
		const selectedVersion = versions.find((v) => v.versionIdentifier === versionIdentifier);
		if (selectedVersion && selectedVersion.diffAvailable) {
			const commands: unknown[] = [
				"workspace",
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				{
					swid: this.workspaceSelected.getValue().id,
					spv: versionIdentifier,
					smode: "compare",
				},
				"process",
			];
			this.router.navigate(commands).then(() => {
				this.viewService.refreshView(
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
				);
			});
		} else {
			this.mutexService.invoke("compare", async () =>
				this.versionService
					.diff(
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
						this.workspaceSelected.getValue().id,
						versionIdentifier,
					)
					.then(async (jobIdentifier) => {
						return this.router.navigate(
							[
								"../",
								"progress",
								{
									jobName: jobIdentifier.name,
									compareWorkspace: this.workspaceSelected.getValue().id,
									compareVersion: versionIdentifier,
								},
							],
							{ relativeTo: this.route },
						);
					}),
			);
		}
	}

	getDotClasses(version: stages.process.ComparableProcessVersion): string[] {
		const classes = ["dot", getStateClass(version)];
		const versionClass = getVersionClass(version);
		if (versionClass) {
			classes.push(versionClass);
		}
		return classes;
	}

	toggleWorkspaceSelection(): void {
		!this.workspaceSelectionMode
			? this.parentComponent.classes!.pop()
			: this.parentComponent.classes!.push(this.AUTO_HEIGHT_CLASS);
		this.workspaceSelectionSubject.next(!this.workspaceSelectionMode);
	}

	onSelectWorkspace(workspace: WorkspaceSelected): void {
		this.workspaceSelectionSubject.next(false);
		this.workspaceSelected.next(workspace);
	}

	selectVersion(versionIdentifier: string): void {
		this.formGroup.get("version")!.setValue(versionIdentifier);
	}
}

function getStateClass(version: stages.process.ComparableProcessVersion): string {
	if (version.state && !version.workingVersion) {
		return "state-" + version.state.ident;
	}

	return "state-draft";
}

function getVersionClass(version: stages.process.ProcessVersion): string | undefined {
	if (version.validVersion) {
		return "validversion";
	}

	if (version.workingVersion) {
		return "workingversion";
	}

	return undefined;
}
