<ng-container *ngIf="properties$ | async as properties">
	<stages-dialog2 #dialog *ngIf="repositories$ | async as repositories">
		<stages-dialog-header></stages-dialog-header>

		<form #form="ngForm" name="form" class="content" novalidate stagesFocusError>
			<h1 translate="properties"></h1>
			<div class="form">
				<div class="form-group">
					<div class="input-container md required">
						<label class="input-label" for="name" translate="files.name"></label>
						<input
							#nameControl="ngModel"
							id="name"
							name="name"
							type="text"
							class="text fullsize"
							tabindex="1"
							[(ngModel)]="properties.name"
							[stagesMaxLength]="maxLengthBig"
							required
							stagesAutofocus
						/>
						<div class="info">
							<div class="errors" role="alert" *ngIf="form.submitted || nameControl.dirty || nameControl.touched">
								<div
									*ngIf="nameControl.errors?.max"
									class="error"
									role="alert"
									translate="input.maxLength"
									[translateParams]="{ length: maxLengthBig }"
								></div>
								<div *ngIf="nameControl.errors?.required" class="error" role="alert" translate="input.required"></div>
							</div>
							<div class="maxlength">{{ getLength(nameControl) }} / {{ maxLengthBig }}</div>
						</div>
					</div>
				</div>

				<div class="form-group" *ngIf="properties.repositories.length > 0">
					<div class="input-container md">
						<label class="input-label" for="type" translate="files.repository"></label>
						<select
							name="repository"
							[(ngModel)]="properties.repository"
							tabindex="2"
							[disabled]="properties.repositories.length == 1"
						>
							<option *ngFor="let repository of properties.repositories" value="{{ repository.repositoryId }}">
								{{ repository.name }}
							</option>
						</select>
						<div class="info"></div>
					</div>
				</div>

				<div class="form-group" *ngIf="isLocationManagable(properties)">
					<div class="input-container md required">
						<label class="input-label" for="path" translate="files.path"></label>
						<input
							#pathControl="ngModel"
							id="path"
							name="path"
							type="text"
							class="text fullsize"
							tabindex="3"
							[(ngModel)]="properties.path"
							required
							[stagesMaxLength]="maxLengthBig"
						/>
						<div class="info">
							<div class="errors" *ngIf="form.submitted || pathControl.dirty || pathControl.touched">
								<div *ngIf="pathControl.errors?.required" class="error" role="alert" translate="input.required"></div>
								<div
									*ngIf="pathControl.errors?.max"
									class="error"
									role="alert"
									translate="input.maxLength"
									[translateParams]="{ length: maxLengthBig }"
								></div>
								<div
									*ngIf="validationErrors.lengthDirectory"
									class="error"
									role="alert"
									translate="files.properties.length.directory"
								></div>
								<div
									*ngIf="validationErrors.lengthName"
									class="error"
									role="alert"
									translate="files.properties.length.filename"
								></div>
								<div
									*ngIf="validationErrors.patternDirectory"
									class="error"
									role="alert"
									[translate]="getTranslatePattern(repositories, properties, 'directory')!"
								></div>
								<div
									*ngIf="validationErrors.patternName"
									class="error"
									role="alert"
									[translate]="getTranslatePattern(repositories, properties, 'name')!"
								></div>
							</div>
							<div class="maxlength">{{ getLength(pathControl) }} / {{ maxLengthBig }}</div>
						</div>
					</div>
				</div>

				<div class="form-group" *ngIf="isLocationManagable(properties)">
					<div class="input-container md">
						<label class="input-label" for="frozenVersion" translate="files.properties.version.frozen"></label>
						<input
							#versionControl="ngModel"
							id="frozenVersion"
							name="frozenVersion"
							type="text"
							class="text fullsize"
							tabindex="4"
							[(ngModel)]="properties.frozenVersion"
							[stagesMaxLength]="maxLengthSmall"
						/>
						<div class="info">
							<div class="errors">
								<div
									*ngIf="versionControl.errors?.max"
									class="error"
									role="alert"
									translate="input.maxLength"
									[translateParams]="{ length: maxLengthSmall }"
								></div>
							</div>
							<div class="maxlength">{{ getLength(versionControl) }} / {{ maxLengthSmall }}</div>
						</div>
					</div>
				</div>

				<div class="form-group" *ngIf="isLocationManagable(properties) && !!properties.lifecycles.length">
					<div class="input-container md">
						<label class="input-label" for="lifecycle" translate="files.properties.lifecycle"></label>
						<select name="lifecycle" [(ngModel)]="properties.lifecycle" class="fullsize" tabindex="5">
							<option
								*ngFor="let lifecycle of properties?.lifecycles"
								value="{{ lifecycle.id }}"
								[translate]="lifecycle.name"
							></option>
						</select>
						<div class="info"></div>
					</div>
				</div>

				<div class="input-container" *ngIf="isSecurityLevelEditable(properties)">
					<legend class="input-label" translate="security.level"></legend>
					<div class="radio" tabindex="6">
						<input
							id="level-open"
							type="radio"
							name="security-level"
							[value]="1"
							[(ngModel)]="properties.securityLevel"
						/>
						<label for="level-open" translate="security.level.1"></label>
					</div>
					<div class="radio" *ngIf="properties.maximumAvailableSecurityLevel >= 2" tabindex="7">
						<input
							id="level-project"
							type="radio"
							name="security-level"
							[value]="2"
							[(ngModel)]="properties.securityLevel"
						/>
						<label for="level-project" translate="security.level.2"></label>
					</div>
					<div class="radio" *ngIf="properties.maximumAvailableSecurityLevel >= 3" tabindex="8">
						<input
							id="level-internal"
							type="radio"
							name="security-level"
							[value]="3"
							[(ngModel)]="properties.securityLevel"
						/>
						<label for="level-internal" translate="security.level.3"></label>
					</div>
					<div class="radio" *ngIf="properties.maximumAvailableSecurityLevel >= 4" tabindex="9">
						<input
							id="level-confidential"
							type="radio"
							name="security-level"
							[value]="4"
							[(ngModel)]="properties.securityLevel"
						/>
						<label for="level-confidential" translate="security.level.4"></label>
					</div>
					<div class="radio" *ngIf="properties.maximumAvailableSecurityLevel >= 5" tabindex="10">
						<input
							id="level-secret"
							type="radio"
							name="security-level"
							[value]="5"
							[(ngModel)]="properties.securityLevel"
						/>
						<label for="level-secret" translate="security.level.5"></label>
					</div>
					<div class="info"></div>
				</div>
			</div>
		</form>

		<stages-popup-footer [hasCancelButton]="false">
			<button class="sm cancel" (click)="dialog.close()" translate="cancel"></button>
			<button class="sm save" (click)="save(properties)" translate="save" [disabled]="!form.valid"></button>
		</stages-popup-footer>
	</stages-dialog2>
</ng-container>
