import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
	selector: "[stagesRestrictInput]",
	providers: [{ provide: NG_VALIDATORS, useExisting: RestrictInputDirective, multi: true }],
})
export class RestrictInputDirective implements Validator {
	@Input("stagesRestrictInput") forbiddenCharacters!: string;

	validate(control: AbstractControl): ValidationErrors | null {
		if (!control.value || typeof control.value !== "string") {
			return null;
		}

		const regex = new RegExp(this.forbiddenCharacters, "g");
		const transformedInput = control.value.replace(regex, "");
		if (transformedInput !== control.value) {
			control.setValue(transformedInput);
		}
		return null;
	}
}
