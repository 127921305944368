<article class="dashboard">
	<stages-page-header [headline]="{ translate: 'dashboard.title' }" [backButtonDisabled]="true"></stages-page-header>

	<div class="container column col-12">
		<stages-entry-pages></stages-entry-pages>
	</div>
	<div class="container" [ngClass]="!isSmallScreen() ? 'row' : 'column'">
		<div class="container column" [ngClass]="{ 'col-8': isMediumScreen(), 'col-4': isLargeScreen() }">
			<!--<stages-links></stages-links>-->
			<stages-my-most-frequently-used-elements></stages-my-most-frequently-used-elements>
		</div>

		<div class="container" [ngClass]="{ row: isLargeScreen(), column: !isLargeScreen(), 'col-8': !isSmallScreen() }">
			<div
				class="container column"
				[ngClass]="{
					'col-6': !isSmallScreen(),
					'col-12': isSmallScreen()
				}"
			>
				<stages-to-do-list></stages-to-do-list>
				<stages-recently-used-files layout="list"></stages-recently-used-files>
			</div>

			<div
				class="container column"
				[ngClass]="{
					'col-6': !isSmallScreen(),
					'col-12': isSmallScreen()
				}"
			>
				<stages-support></stages-support>
				<!-- <stages-my-roles > </stages-my-roles> -->
			</div>
		</div>
	</div>
</article>
