import { Location } from "@angular/common";
import {
	HttpContext,
	HttpContextToken,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpParams,
	HttpRequest,
	HttpResponse,
	HttpUrlEncodingCodec,
} from "@angular/common/http";
import { Injectable, Injector, NgZone } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "core/authentication.service";
import { emptyOrUndefinedToNull, hasProperty } from "core/functions";
import {
	MainService,
	SECONDARY_MODE_PARAM,
	SECONDARY_PROCESS_VERSION_PARAM,
	SECONDARY_WORKSPACE_ID_PARAM,
} from "core/main.service";
import { from, lastValueFrom, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

export const REQUEST_START_TIME = new HttpContextToken<number>(() => Date.now());

export interface StagesHttpResponseBody {
	_appContext: stages.context.ApplicationContext;
	_entity: unknown;
	_workspaceView: stages.workspace.application.WorkspaceView;
}

export interface RedirectToLoginDoneListener {
	redirectToLoginDone(): Promise<void>;
}

@Injectable({
	providedIn: "root",
})
export class StagesHttpInterceptor implements HttpInterceptor {
	private readonly mainService: MainService;
	private readonly authService: AuthenticationService;
	private pendingLoginNavigation?: string;
	private lastContextUpdateStartedByRequestStartTime?: number;

	private readonly redirectToLoginListeners: RedirectToLoginDoneListener[] = [];

	constructor(
		// Use Injector to retrieve all our own services instead of injecting these services directly, because otherwise we get the following runtime error for non-aot Angular builds:
		// "Can't resolve all parameters for ..."
		injector: Injector,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly location: Location,
		private readonly ngZone: NgZone,
	) {
		this.mainService = injector.get<MainService>(MainService);
		this.authService = injector.get<AuthenticationService>(AuthenticationService);
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const newRequest = request.clone({
			headers: request.headers.get("Cache-Control")
				? request.headers
				: request.headers.append("Cache-Control", "no-cache").append("Pragma", "no-cache"),
			params: this.appendSecondaryViewParamsIfSet(replaceAngularHttpParameterCodec(request.params)),
			context: new HttpContext().set(REQUEST_START_TIME, Date.now()),
		});

		return next.handle(newRequest).pipe(
			catchError((error: unknown) => from(this.handleError(newRequest, next, error))),
			//"map" must be called after "catchError", because "handleError" fixes SPNEGO problems and "setContext" must also be called on these responses
			map((errorHandledHTTPEvent) => {
				return this.setContext(errorHandledHTTPEvent, newRequest);
			}),
		);
	}

	private appendSecondaryViewParamsIfSet(params: HttpParams): HttpParams {
		const secondaryWorkspaceId = this.mainService.secondaryWorkspaceId;
		const secondaryProcessVersion = this.mainService.secondaryProcessVersion;

		const newParams =
			secondaryWorkspaceId && secondaryProcessVersion
				? params
						.append(SECONDARY_WORKSPACE_ID_PARAM, secondaryWorkspaceId)
						.append(SECONDARY_PROCESS_VERSION_PARAM, secondaryProcessVersion)
				: params;

		const secondaryMode = this.mainService.secondaryMode;
		return secondaryMode ? newParams.append(SECONDARY_MODE_PARAM, secondaryMode) : newParams;
	}

	private async handleError(
		request: HttpRequest<unknown>,
		next: HttpHandler,
		error: unknown,
	): Promise<HttpEvent<unknown>> {
		if (!(error instanceof HttpErrorResponse)) {
			throw error;
		}

		if (!this.authService.needsAuthentication(error)) {
			throw error;
		}

		if (error.status === 401) {
			const wwwAuthenticate = error.headers.get("WWW-Authenticate");
			if (wwwAuthenticate) {
				const redirectTo = this.route.snapshot.queryParamMap.get("redirectTo") || this.location.path(true);
				const paramSeparator = wwwAuthenticate.indexOf("?error=") > 0 ? "&" : "?";
				window.location.href = `${wwwAuthenticate}${paramSeparator}redirectTo=${encodeURIComponent(redirectTo)}`;
				return new Promise<HttpEvent<unknown>>(() => {
					// Promise never resolves! This does not matter, because HTTP Request was redirected to SAML IDP.
				});
			}
			return this.retryRequestAfterAuthentication(request, next, error);
		}

		if (error.status === 403) {
			// do not autologin in case of session timeout during polling. Redirect to logout page instead
			if (request.headers.get("Stages-Polling") != null) {
				await this.authService.logout(this.getRedirectToQueryParam());
				throw error;
			}

			/*
                If the status code "403 Forbidden" is returned, login is tried with SSOFilters. e.g. SPNEGO or customer specific ones.
                If the iframe with id "SSO_FILTER_AUTH" does not exist, it is added to the document.
                The source file of this iframe is "autologin.nocache.html".
                This HTML file tries to load the protected file `autologin.nocache.js` at the beginning.
                When loading, the browser checks in conjunction with Tomcat whether login with SSOFilters works.
                If loading of `autologin.nocache.js` succeeds, all "onAutoLoginFilterSuccess"-handlers are called after the loading of the iframe completed.
                If login succeeded, "AuthenticationService.needsAuthentication" works and the original request is tried again.
                If loading of `autologin.nocache.js` fails, all "onAutoLoginFilterFailed"-handlers are called and user is redirected to login page,
                without trying to call authService.autoAuthentication().
             */
			// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
			const w: any = window;
			if (document.querySelectorAll("#SSO_FILTER_AUTH").length === 0) {
				const ssoFilterIframe = document.createElement("iframe");
				ssoFilterIframe.setAttribute("id", "SSO_FILTER_AUTH");
				ssoFilterIframe.setAttribute("style", "visibility:hidden;display:none");
				ssoFilterIframe.setAttribute("src", "autologin.nocache.html");
				document.querySelector("body")!.appendChild(ssoFilterIframe);
				w.onAutoLoginFilterSuccess = [];
				w.onAutoLoginFilterFailed = [];
				// "onAutoLoginFilterSuccess" must be an array, not only because "removeSSOFilterIframe" should only be called once, but mainly because
				// multiple requests with 403 error occur for a deep link after a browser restart and every request must be retried.
				w.onAutoLoginFilterSuccess.push(() => {
					removeSSOFilterIframe();
					this.authService.invalidateAllCaches();
				});
				w.onAutoLoginFilterFailed.push(() => {
					removeSSOFilterIframe();
					this.authService.invalidateAllCaches();
					// TODO reenable me after SAML refactoring for SSOFilterBase
					//void this.ngZone.run(() => this.navigateToLogin(error));
				});
			}
			return new Promise<HttpEvent<unknown>>((resolve, reject) => {
				w.onAutoLoginFilterSuccess.push(() =>
					this.retryRequestAfterAuthentication(request, next, error).then(resolve, reject),
				);
				// TODO remove me after SAML refactoring for SSOFilterBase
				w.onAutoLoginFilterFailed.push(() =>
					this.retryRequestAfterAuthentication(request, next, error).then(resolve, reject),
				);
			});
		}
		throw error;
	}

	private async retryRequestAfterAuthentication(
		request: HttpRequest<unknown>,
		next: HttpHandler,
		errorResponse: HttpErrorResponse,
	): Promise<HttpEvent<unknown>> {
		if (request.url.indexOf("rest/authentication") > -1) {
			throw errorResponse;
		}

		try {
			await this.authService.autoAuthentication();
			return lastValueFrom(next.handle(request)); // retry request after successful authentication
		} catch (error: unknown) {
			if (this.authService.needsAuthentication(errorResponse)) {
				console.log(`Authentication needed for ${request.url}`);
				void this.ngZone.run(() => this.navigateToLogin(error));
			}
			throw errorResponse;
		}
	}

	private readonly setContext = (httpEvent: HttpEvent<unknown>, request: HttpRequest<unknown>): HttpEvent<unknown> => {
		if (
			httpEvent instanceof HttpResponse &&
			httpEvent.body &&
			hasProperty(httpEvent.body, "_workspaceView") &&
			hasProperty(httpEvent.body, "_appContext")
		) {
			const body = httpEvent.body as StagesHttpResponseBody;
			if (
				this.lastContextUpdateStartedByRequestStartTime === undefined ||
				request.context.get(REQUEST_START_TIME) > this.lastContextUpdateStartedByRequestStartTime
			) {
				this.mainService.refreshCurrentWorkspaceAndAppContext(body._workspaceView, body._appContext);
				this.lastContextUpdateStartedByRequestStartTime = request.context.get(REQUEST_START_TIME);
			}
			return httpEvent.clone({ body: body._entity });
		}
		return httpEvent;
	};

	private async navigateToLogin(error: unknown): Promise<void> {
		const redirectToQueryParam = this.getRedirectToQueryParam();
		const errorQueryParam =
			error &&
			hasProperty(error, "error") &&
			error.error instanceof Array &&
			error.error[0] &&
			error.error[0].keys &&
			error.error[0].keys[0]
				? error.error[0].keys[0]
				: null;
		const newLoginNavigation = `login?redirectTo=${redirectToQueryParam}&error=${errorQueryParam}`;
		if (this.pendingLoginNavigation !== undefined && redirectToQueryParam === null && errorQueryParam === null) {
			console.log(`Ignore navigation to ${newLoginNavigation} during another login-navigation, because it is trivial`);
			return;
		}

		if (this.pendingLoginNavigation === newLoginNavigation) {
			console.log(`Ignore navigation to ${newLoginNavigation} during the same login-navigation`);
			return;
		}

		console.log(`Navigate to ${newLoginNavigation}`);
		this.pendingLoginNavigation = newLoginNavigation;
		try {
			await this.router.navigate(["login"], {
				queryParams: {
					redirectTo: redirectToQueryParam,
					error: errorQueryParam,
				},
			});
			this.redirectToLoginListeners.forEach((l) => l.redirectToLoginDone());
		} finally {
			this.pendingLoginNavigation = undefined;
		}
	}

	private getRedirectToQueryParam(): string | null {
		const redirectToQueryParam = emptyOrUndefinedToNull(
			this.route.snapshot.queryParamMap.get("redirectTo") || this.location.path(true),
		);
		if (redirectToQueryParam === "/login") {
			return null;
		}
		return redirectToQueryParam;
	}

	registerNotifyRedirectToLoginDoneListener(listener: RedirectToLoginDoneListener): void {
		this.redirectToLoginListeners.push(listener);
	}
}

function removeSSOFilterIframe(): void {
	const ssoFilterIframe = document.querySelector("#SSO_FILTER_AUTH");
	if (ssoFilterIframe) {
		// "ssoFilterIframe.remove()" does not work in IE11.
		// As we did not find an easy way to apply an existing polyfill, we rewrote it as described here:
		// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
		ssoFilterIframe.parentNode!.removeChild(ssoFilterIframe);
	}
}

/**
 * Minimal invasive fix for ST-31398:
 * https://stackoverflow.com/questions/45428842/angular-url-plus-sign-converting-to-space/52458069#52458069
 * https://github.com/angular/angular/issues/18261
 * https://github.com/angular/angular/pull/32598
 */
function replaceAngularHttpParameterCodec(httpParams: HttpParams): HttpParams {
	return new HttpParams({ encoder: new StagesHttpParameterCodec(), fromString: httpParams.toString() });
}

class StagesHttpParameterCodec extends HttpUrlEncodingCodec {
	override encodeKey(k: string): string {
		return standardEncoding(k);
	}

	override encodeValue(v: string): string {
		return standardEncoding(v);
	}
}

/**
 * Largely copied from https://github.com/angular/angular/blob/master/packages/common/http/src/params.ts
 * but do not replace "%2B" with "+", because "+" will be interpreted as " " by Stages server.
 * Perhaps other replacements should also not be done. E.g. replacing "%3D" with "=" could be a problem for keys.
 */
function standardEncoding(v: string): string {
	return (
		encodeURIComponent(v)
			.replace(/%40/gi, "@")
			.replace(/%3A/gi, ":")
			.replace(/%24/gi, "$")
			.replace(/%2C/gi, ",")
			.replace(/%3B/gi, ";")
			//.replace(/%2B/gi, '+')
			.replace(/%3D/gi, "=")
			.replace(/%3F/gi, "?")
			.replace(/%2F/gi, "/")
	);
}
