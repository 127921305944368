<stages-dialog2 #dialog *ngIf="startOptions$ | async as startOptions">
	<stages-dialog-header></stages-dialog-header>
	<div class="content">
		<stages-files-start
			[startOptions]="startOptions"
			headline="files.start.title"
			translateNone="none"
		></stages-files-start>
	</div>
</stages-dialog2>
