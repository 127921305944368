<ng-container *ngIf="processView$ | async as processView">
	<article class="module widget">
		<ng-container *ngIf="processView.allowedOperations.CREATE_FEEDBACK">
			<div *ngIf="state === 'minified' || state === 'open' || state === 'inProgress'" class="form">
				<div [formGroup]="form" id="edit-attributes-container">
					<div class="input-container right" *ngIf="state !== 'minified'">
						<button class="button sm cancel" translate="cancel" (click)="cancel()"></button>
						<button
							class="button sm save"
							(click)="save(processView)"
							[disabled]="!isSubmitAllowed('summary') || state === 'inProgress'"
						>
							<span translate="save" *ngIf="state !== 'inProgress'"></span>
							<stages-spin size="sm" *ngIf="state === 'inProgress'"></stages-spin>
						</button>
					</div>

					<div class="input-container">
						<stages-input [form]="form" [input]="summaryInput"></stages-input>
						<ng-container *ngIf="state !== 'minified'">
							<div *ngFor="let input of inputs" class="fullsize">
								<stages-input [form]="form" [input]="input"></stages-input>
							</div>
						</ng-container>
					</div>

					<div class="input-container right" *ngIf="state !== 'minified'">
						<button class="button sm cancel" translate="cancel" (click)="cancel()"></button>
						<button
							class="button sm save"
							(click)="save(processView)"
							[disabled]="!isSubmitAllowed('summary') || state === 'inProgress'"
						>
							<span translate="save" *ngIf="state !== 'inProgress'"></span>
							<stages-spin size="sm" *ngIf="state === 'inProgress'"></stages-spin>
						</button>
					</div>
				</div>
			</div>

			<div *ngIf="state === 'created'" class="form">
				<div class="form-group">
					<div class="input-container sm">
						<p>
							<span translate="process.feedback.created"></span>&nbsp;
							<a [href]="response.uri" target="_blank" rel="noopener noreferrer">{{ response.summary }}</a>
						</p>
					</div>
				</div>

				<div class="input-container right">
					<button class="button sm cancel" translate="close" (click)="cancel()"></button>
				</div>
			</div>

			<div *ngIf="state === 'error'" class="form">
				<div class="form-group">
					<div class="input-container sm">
						<p class="title" translate="error.general.title"></p>
						<p class="error" translate="error.general.message"></p>
					</div>
				</div>

				<div class="input-container right">
					<button class="button sm back" translate="back" (click)="retry()"></button>
					<button class="button sm cancel" translate="cancel" (click)="cancel()"></button>
				</div>
			</div>
		</ng-container>

		<p *ngIf="!processView.allowedOperations.CREATE_FEEDBACK" class="empty" translate="none"></p>
	</article>
</ng-container>
