<ng-container *ngIf="data$ | async as data; else spinner">
	<div class="button-bar" *ngIf="buttons.length && !actionInProgress">
		<div class="buttons">
			<div class="buttons">
				<ng-container *ngFor="let button of buttons">
					<button
						*ngIf="$any(button).visible()"
						(click)="button.click()"
						[class]="button.class"
						[translate]="button.translate"
						[disabled]="$any(button).disabled()"
					></button>
				</ng-container>
			</div>
		</div>
	</div>

	<ng-template #list>
		<div class="information no-border" *ngIf="informationTemplate">
			<ng-container *ngTemplateOutlet="informationTemplate; context: { $implicit: data }"></ng-container>
		</div>
		<ul [class]="listStyles">
			<li class="list-item" *ngIf="listHeaderTemplate">
				<ng-container *ngTemplateOutlet="listHeaderTemplate; context: { $implicit: data }"></ng-container>
			</li>

			<ng-container *ngIf="mode === Mode.ADD">
				<li class="relative list-item add-item">
					<div class="fullsize">
						<ng-container *ngIf="!autoCompleteTemplate">
							<stages-add
								#addComponent
								[focusOnInit]="true"
								[placeholder]="getSearchPlaceHolder() | translate"
								[maxLength]="addMaxLength"
								[pattern]="addPattern"
								[patternMessageKey]="addPatternMessageKey"
								(submitted)="add($event)"
								(focusLost)="cancelAdd()"
								(cancelled)="cancelAdd()"
								class="relative"
							>
							</stages-add>
						</ng-container>
						<ng-container *ngIf="autoCompleteTemplate">
							<stages-auto-complete
								#autoComplete
								[focusOnInit]="true"
								[placeholder]="getSearchPlaceHolder() | translate"
								[query]="dataSource.newSearchQuery()"
								(submitted)="add($event)"
								(cancelled)="cancelAdd()"
								(focusLost)="cancelAdd()"
								[searchResultTemplate]="autoCompleteTemplate"
								[browseButtonActive]="browseItemButtonActive"
								(openBrowserButtonPressed)="openButtonPressed()"
								(closeButtonPressed)="closeButtonPressed()"
								class="relative"
							>
							</stages-auto-complete>
							<p *ngIf="autoCompleteBrowseTemplate" [class]="autoCompleteBrowseTemplatePragraphStyles">
								<ng-container *ngTemplateOutlet="autoCompleteBrowseTemplate"></ng-container>
							</p>
						</ng-container>
					</div>
				</li>
			</ng-container>
			<ng-container *ngIf="searchEngineAvailable || !filterTerm">
				<ng-container *ngFor="let item of data.items; trackBy: trackBy">
					<li class="list-item" [ngClass]="getClasses(item)" [class.disabled]="inactiveFn(item)">
						<div *ngIf="selectable" class="select">
							<input
								type="checkbox"
								class="checkbox"
								[id]="'select' + idFn(item)"
								[name]="'select' + idFn(item)"
								[checked]="
									(!idFnNew && selection.isSelected(idFn(item))) ||
									(item
										| selectionStoreIsSelected
											: storeIdentifier
											: isSelectAllActivated
											: triggerChangeCounter
											: idFnNew
											: isPreselectedFn)
								"
								(change)="toggleSelection(item)"
								[disabled]="disabledFn(item, data, mode)"
								[indeterminate]="item | selectionStoreIsPartiallySelected: storeIdentifier:triggerChangeCounter:idFnNew"
							/>
							<label [for]="'select' + idFn(item)"></label>
						</div>
						<ng-container *ngTemplateOutlet="listItemTemplate!; context: { $implicit: item }"></ng-container>
					</li>
				</ng-container>

				<li class="list-item" *ngIf="data.page < data.totalPageCount && showMore">
					<a class="label clickable" (click)="dataSource.loadMore()">
						<div class="avatar-cell-48"></div>
						<div class="text center">
							<span class="show-more" translate="more"></span>
						</div>
					</a>
				</li>

				<ng-container *ngIf="!!translateNone && data.totalItemCount === 0">
					<ng-container *ngTemplateOutlet="listInfoMessage; context: { $implicit: translateNone }"></ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="!searchEngineAvailable && filterTerm">
				<ng-container *ngTemplateOutlet="listInfoMessage; context: { $implicit: 'search.notavailable' }"></ng-container>
			</ng-container>
		</ul>
		<li class="list-item" *ngIf="isSelectAllEnabled()">
			<ng-container *ngTemplateOutlet="selectAllTemplate"></ng-container>
		</li>
	</ng-template>

	<ng-template #table>
		<div class="information no-border" *ngIf="informationTemplate">
			<ng-container *ngTemplateOutlet="informationTemplate; context: { $implicit: data }"></ng-container>
		</div>
		<table class="table center" *ngIf="searchEngineAvailable || !filterTerm" #coreTable>
			<thead *ngIf="headerTemplate && !(!!translateNone && data.totalItemCount === 0)">
				<tr>
					<th *ngIf="selectable" class="select"></th>
					<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
				</tr>
			</thead>

			<tbody [ngClass]="mode === Mode.REARRANGE ? 'sortable-container' : ''">
				<tr *ngIf="autoCompleteTemplate && mode === Mode.ADD" class="add-item">
					<td class="relative" [attr.colspan]="tableColumns">
						<stages-auto-complete
							#autoComplete
							[focusOnInit]="true"
							[placeholder]="getSearchPlaceHolder() | translate"
							[query]="dataSource.newSearchQuery()"
							(submitted)="add($event)"
							(cancelled)="cancelAdd()"
							(focusLost)="cancelAdd()"
							[searchResultTemplate]="autoCompleteTemplate"
							[browseButtonActive]="browseItemButtonActive"
							(openBrowserButtonPressed)="openButtonPressed()"
							(closeButtonPressed)="closeButtonPressed()"
							class="relative"
						>
						</stages-auto-complete>
						<p *ngIf="autoCompleteBrowseTemplate">
							<ng-container *ngTemplateOutlet="autoCompleteBrowseTemplate"></ng-container>
						</p>
					</td>
				</tr>

				<tr *ngIf="!autoCompleteTemplate && mode === Mode.ADD" class="add-item">
					<td class="relative" [attr.colspan]="tableColumns">
						<stages-add
							#addComponent
							[focusOnInit]="true"
							[placeholder]="getSearchPlaceHolder() | translate"
							[maxLength]="addMaxLength"
							[pattern]="addPattern"
							[patternMessageKey]="addPatternMessageKey"
							(submitted)="add($event)"
							(focusLost)="cancelAdd()"
							(cancelled)="cancelAdd()"
							class="relative"
						>
						</stages-add>
					</td>
				</tr>

				<tr
					*ngFor="let item of data.items; trackBy: trackBy"
					[ngClass]="getClasses(item)"
					[class.disabled]="inactiveFn(item)"
				>
					<td *ngIf="selectable" class="select">
						<input
							type="checkbox"
							class="checkbox"
							[id]="'select' + idFn(item)"
							[name]="'select' + idFn(item)"
							[checked]="
								(!idFnNew && selection.isSelected(idFn(item))) ||
								(item
									| selectionStoreIsSelected
										: storeIdentifier
										: isSelectAllActivated
										: triggerChangeCounter
										: idFnNew
										: isPreselectedFn)
							"
							(change)="toggleSelection(item)"
							[disabled]="disabledFn(item, data, mode)"
							[indeterminate]="item | selectionStoreIsPartiallySelected: storeIdentifier:triggerChangeCounter:idFnNew"
						/>
						<label [for]="'select' + idFn(item)"></label>
					</td>
					<ng-container *ngTemplateOutlet="tableItemTemplate!; context: { $implicit: item }"></ng-container>
				</tr>
			</tbody>
		</table>
		<ul class="list" *ngIf="isSelectAllEnabled()">
			<li class="list-item">
				<ng-container *ngTemplateOutlet="selectAllTemplate"></ng-container>
			</li>
		</ul>
		<ng-container *ngIf="!!translateNone && data.totalItemCount === 0 && (searchEngineAvailable || !filterTerm)">
			<ul class="list">
				<ng-container *ngTemplateOutlet="listInfoMessage; context: { $implicit: translateNone }"></ng-container>
			</ul>
		</ng-container>
		<ng-container *ngIf="!searchEngineAvailable && filterTerm">
			<ul class="list">
				<ng-container *ngTemplateOutlet="listInfoMessage; context: { $implicit: 'search.notavailable' }"></ng-container>
			</ul>
		</ng-container>
	</ng-template>

	<form class="form filter" *ngIf="(data.items.length > 0 || filterTerm !== undefined) && dataSource.filterEnabled()">
		<div class="input-container">
			<div class="auto-complete">
				<label class="relative">
					<input
						#filterInput
						autoComplete="off"
						[(ngModel)]="filterTerm"
						id="filterTerm"
						name="filterTerm"
						type="search"
						class="search"
						[attr.placeholder]="getFilterPlaceHolder() | translate"
						(keyup)="onFilterKeyUp($any($event.target).value)"
					/>
					<!-- stagesAutofocus does not work on iPad -->
				</label>
			</div>
		</div>
	</form>

	<ng-container *ngIf="!useBreakpointObserver">
		<stages-media-query *ngIf="listItemTemplate && tableItemTemplate" #media query="screen and (max-width:1440px)">
			<ng-container *ngIf="media.matches">
				<ng-container *ngTemplateOutlet="list"></ng-container>
			</ng-container>
			<ng-container *ngIf="!media.matches">
				<ng-container *ngTemplateOutlet="table"></ng-container>
			</ng-container>
		</stages-media-query>
	</ng-container>

	<ng-container *ngIf="useBreakpointObserver">
		<stages-media-query *ngIf="listItemTemplate && tableItemTemplate">
			<ng-container *ngIf="showListTemplate()">
				<ng-container *ngTemplateOutlet="list"></ng-container>
			</ng-container>
			<ng-container *ngIf="!showListTemplate()">
				<ng-container *ngTemplateOutlet="table"></ng-container>
			</ng-container>
		</stages-media-query>
	</ng-container>

	<ng-container *ngIf="listItemTemplate && !tableItemTemplate">
		<ng-container *ngTemplateOutlet="list"></ng-container>
	</ng-container>
	<ng-container *ngIf="tableItemTemplate && !listItemTemplate">
		<ng-container *ngTemplateOutlet="table"></ng-container>
	</ng-container>

	<div class="list" *ngIf="data.totalPageCount > 1 && !showMore">
		<footer class="footer-pager">
			<stages-pager
				[pageSize]="data.pageSize ? data.pageSize : pageSize"
				[total]="data.totalItemCount"
				[page]="data.page"
				(goTo)="dataSource.goTo($event)"
			></stages-pager>
		</footer>
	</div>
</ng-container>

<ng-template #spinner>
	<stages-spin size="sm"></stages-spin>
</ng-template>

<ng-template #selectAllTemplate>
	<h5 translate="select.all"></h5>
	<input
		id="selectAll"
		type="checkbox"
		hidden="hidden"
		[checked]="isSelectAllActivated"
		(click)="selectDeselectAll()"
	/>
	<label for="selectAll" class="switch"></label>
</ng-template>

<ng-template #listInfoMessage let-context>
	<li class="list-item none">
		<div class="label">
			<p [translate]="context"></p>
		</div>
	</li>
</ng-template>
