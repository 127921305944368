import { Component, Input } from "@angular/core";

@Component({
	selector: "stages-navigation-entry-two-line",
	templateUrl: "./two-line-navigation-entry.component.html",
	styleUrls: ["./two-line-navigation-entry.component.scss"],
})
export class TwoLineNavigationEntryComponent {
	@Input()
	entry!: TwoLineEntry;

	@Input()
	mode?: NAV_ENTRY_DISPLAY_MODE;
}
