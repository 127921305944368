import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Pipe,
	PipeTransform,
	QueryList,
	TrackByFunction,
	ViewChildren,
} from "@angular/core";
import { Selectable } from "common/selection/state/selectable.model";
import { getIdKeyOfTypeId, SelectionStoreService } from "common/selection/state/selection-store.service";
import { assertDefined, nullToUndefined } from "core/functions";
import { EMPTY, Observable, Subscription } from "rxjs";
import { Group } from "common/collapsible-groups/collapsible-groups.component";
import { SelectionRepository } from "common/selection/state/selection.repository";
import TypedViewable = stages.process.TypedViewable;
import ViewableDependentElement = stages.process.ViewableDependentElement;

type TypedId = stages.core.TypedId;
type ProcessTreeItem = stages.process.ProcessTreeItem;
type UpdatedElements = stages.common.UpdatedElements;

interface DependentsGroup extends Group, ProcessTreeItem {
	name: string;
	dependentElements: ViewableDependentElement[];
}

@Pipe({
	name: "getAssignableDependentElements",
	pure: true,
})
export class GetAssignableDependentElementsPipe implements PipeTransform {
	transform(
		dependentElements: ViewableDependentElement[],
		ignoreDependentsAssignable: boolean,
		restrictAssociateDependentElementTypes: string[] | undefined,
	): ViewableDependentElement[] {
		return getAssignableDependentElements(
			dependentElements,
			ignoreDependentsAssignable,
			restrictAssociateDependentElementTypes,
		);
	}
}

@Component({
	selector: "stages-viewable-selectable-browser",
	templateUrl: "./viewable-selectable-browser.component.html",
	styleUrls: ["viewable-selectable-browser.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewableSelectableBrowserComponent implements OnInit, OnDestroy {
	@Input()
	folder!: ProcessTreeItem;

	@Input()
	children?: Selectable[];

	@Input()
	allElements!: Selectable[];

	@Input()
	initialSelections!: Selectable[];

	@Input()
	filteredSelectables?: TypedId[];

	@Input()
	allowWorkspaceNavigation: boolean = true;

	@Input()
	browseRootType!: string;

	@Input()
	rootLabel?: string;

	@Input()
	translateNone?: string;

	showVisibleOnly: boolean = false;

	@Input()
	extendedMode: boolean = false;

	@Input()
	allowIndexElementsForSelection: boolean = false;

	@Input()
	storeInstanceName!: string;

	@Input()
	isDialog: boolean = true;

	@Input()
	commentsAllowed: boolean = false;

	@Input()
	restrictAssociateDependentElementTypes: string[] | undefined;

	@Input() // all dependent type shall be tailorable even if specified as not assignable
	ignoreDependentsAssignable: boolean = false;

	@Output() readonly addSelection = new EventEmitter<ProcessTreeItem>();

	@Output() readonly removeSelection = new EventEmitter<ProcessTreeItem>();

	@Output()
	readonly navigateToChild = new EventEmitter<ProcessTreeItem>();

	@Output()
	readonly navigateToParent = new EventEmitter<ProcessTreeItem>();

	@Output()
	readonly navigateToWorkspaces = new EventEmitter<ProcessTreeItem>();

	@Output() readonly cancel = new EventEmitter<void>();
	@Output() readonly save = new EventEmitter<UpdatedElements>();

	@ViewChildren("commentInput")
	commentInputs?: QueryList<ElementRef>;

	allSelectables$!: Observable<Selectable[]>;
	allSelectables!: Selectable[];

	selectedElements$!: Observable<Selectable[]>;
	selectedElements!: Selectable[];

	addedElements$!: Observable<Selectable[]>;
	addedElements!: Selectable[];

	removedElements$!: Observable<Selectable[]>;
	removedElements!: Selectable[];

	updatedElements$!: Observable<Selectable[]>;
	updatedElements!: Selectable[];

	selectedElementsCounter$: Observable<number> = EMPTY;

	addedElementsCounter$!: Observable<number>;
	addedElementsCounter: number = 0;

	removedElementsCounter$!: Observable<number>;
	removedElementsCounter: number = 0;

	isLoading$!: Observable<boolean>;

	private wasChanged: boolean = false;

	private readonly subs = new Subscription();

	contextMenuItems: MenuItem[] = [];

	isLoading: boolean = false;

	constructor(private selectionStoreService: SelectionStoreService, private selectionRepository: SelectionRepository) {}

	ngOnInit(): void {
		assertDefined(this.storeInstanceName, "storeInstanceName has to be defined!");
		assertDefined(this.folder, "folder has to be defined!");
		assertDefined(this.initialSelections, "initialSelections has to be defined!");
		assertDefined(this.browseRootType, "browseRootType has to be defined!");

		if (this.extendedMode) {
			assertDefined(this.allElements, "allElements has to be defined!");
			this.createAndInitializeDynamicStore();
			this.initializeExtendedModeVariables();
		} else {
			this.selectionStoreService.initializeCommonStore(this.initialSelections);
			this.initializeSimpleModeVariables();
		}

		this.addSubscriptions();

		this.createContextMenuItems();
	}

	private initializeSimpleModeVariables(): void {
		this.selectedElements$ = this.selectionRepository.getAllSelectedFromCommonStore$(this.storeInstanceName);
		this.selectedElementsCounter$ = this.selectionRepository.getSelectedCountByIdentifier$(this.storeInstanceName);

		this.addedElements$ = this.selectionRepository.getAllAddedFromCommonStore$(this.storeInstanceName);
		this.addedElementsCounter$ = this.selectionRepository.getAllAddedCountFromCommonStore$(this.storeInstanceName);
		this.removedElements$ = this.selectionRepository.getAllRemovedFromCommonStore$(this.storeInstanceName);

		this.removedElementsCounter$ = this.selectionRepository.getAllRemovedCountFromCommonStore$(this.storeInstanceName);
		this.updatedElements$ = this.selectionRepository.getAllUpdatedFromCommonStore$(this.storeInstanceName);
		this.isLoading$ = this.selectionRepository.selectLoading$;
	}

	private createAndInitializeDynamicStore(): void {
		if (!this.extendedMode) {
			throw new Error("should only be called in advanced mode");
		}
		if (this.selectionStoreService.hasState(this.storeInstanceName)) {
			throw new Error("dynamic store should not exist and not be cached!");
		}
		this.selectionStoreService.createAndInitializeDynamicStore(
			this.storeInstanceName,
			this.allElements,
			this.initialSelections,
		);
	}

	private initializeExtendedModeVariables(): void {
		this.allSelectables$ = this.selectionRepository.getAllFromDynamicStore$(this.storeInstanceName)!;

		this.selectedElements$ = this.selectionRepository.getAllSelectedFromDynamicStore$(this.storeInstanceName)!;

		this.selectedElementsCounter$ = this.selectionRepository.getAllSelectedCountFromDynamicStore$(
			this.storeInstanceName,
		)!;

		this.addedElementsCounter$ = this.selectionRepository.getAllAddedCountFromDynamicStore$(this.storeInstanceName)!;
		this.addedElements$ = this.selectionRepository.getAllAddedFromDynamicStore$(this.storeInstanceName)!;

		this.removedElementsCounter$ = this.selectionRepository.getAllRemovedCountFromDynamicStore$(
			this.storeInstanceName,
		)!;

		this.removedElements$ = this.selectionRepository.getAllRemovedFromDynamicStore$(this.storeInstanceName)!;

		this.updatedElements$ = this.selectionRepository.getAllUpdatedFromDynamicStore$(this.storeInstanceName)!;

		this.isLoading$ = this.selectionRepository.getLoadingDynamicStore(this.storeInstanceName)!;

		this.subs.add(
			this.allSelectables$.subscribe((elements) => {
				this.allSelectables = elements;
			}),
		);
	}

	private addSubscriptions(): void {
		this.subs.add(this.selectedElements$.subscribe((elements) => (this.selectedElements = elements)));

		this.subs.add(this.addedElements$.subscribe((elements) => (this.addedElements = elements)));

		this.subs.add(this.addedElementsCounter$.subscribe((addedCounter) => (this.addedElementsCounter = addedCounter)));

		this.subs.add(this.removedElements$.subscribe((elements) => (this.removedElements = elements)));
		this.subs.add(
			this.removedElementsCounter$.subscribe((removedCounter) => (this.removedElementsCounter = removedCounter)),
		);

		this.subs.add(this.updatedElements$.subscribe((elements) => (this.updatedElements = elements)));

		this.subs.add(this.isLoading$.subscribe((loading) => (this.isLoading = loading)));
	}

	private createContextMenuItems(): void {
		this.contextMenuItems = [
			{
				name: "common.browser.selectable.elements.addAll",
				iconClass: "ico ico-elements-add-all",
				disabled: () => this.isLoading,
				on: (element: ProcessTreeItem) => {
					this.selectionStoreService.selectAll(
						this.storeInstanceName,
						{ id: element.id, typeIdent: element.type.ident },
						false,
					);
				},
			},
			{
				name: "common.browser.selectable.elements.addLeaves",
				iconClass: "ico ico-elements-add-all-in-folders",
				disabled: () => this.isLoading,
				on: (element: ProcessTreeItem) => {
					this.selectionStoreService.selectAll(
						this.storeInstanceName,
						{ id: element.id, typeIdent: element.type.ident },
						true,
					);
				},
			},
			{
				name: "common.browser.selectable.elements.removeAll",
				iconClass: "ico ico-elements-remove-all",
				disabled: () => this.isLoading,
				on: (element: ProcessTreeItem) => {
					this.selectionStoreService.deselectAllDynamicStore(this.storeInstanceName, {
						id: element.id,
						typeIdent: element.type.ident,
					});
				},
			},
		];
	}

	goToWorkspaces(item: ProcessTreeItem): void {
		this.navigateToWorkspaces.emit(item);
	}

	goToChild(item: ProcessTreeItem, $event: Event): void {
		$event.stopPropagation(); // prevent toggle of expand/collapse
		this.navigateToChild.emit(item);
	}

	goToParent(item: ProcessTreeItem): void {
		this.navigateToParent.emit(item);
	}

	ngOnDestroy(): void {
		if (this.subs) {
			this.subs.unsubscribe();
		}
		if (this.extendedMode) {
			this.selectionStoreService.delete(this.storeInstanceName);
		}
	}

	getSelectionKey(item: ProcessTreeItem): string {
		return this.getSelectionKeyOfTypeId({ id: item.id, typeIdent: item.type.ident });
	}

	private getSelectionKeyOfTypeId(typedId: TypedId): string {
		return "ViewableSelectableBrowserComponent_" + this.storeInstanceName + typedId.typeIdent + "_" + typedId.id;
	}

	onSelectionChanged(itemTypeId: TypedId): void {
		const selectionKey: string = getIdKeyOfTypeId(itemTypeId);
		if (this.extendedMode) {
			const selectionTarget = this.selectionRepository.getEntityFromDynamicStore(this.storeInstanceName, selectionKey);
			this.toggleExistingDynamicStore(assertDefined(selectionTarget));
		} else {
			const selectionTarget = this.selectionRepository.getEntity(selectionKey, this.storeInstanceName);
			if (selectionTarget) {
				this.toggleExistingCommon(selectionTarget);
			} else {
				this.selectionStoreService.addNewSelectable(
					itemTypeId,
					false,
					undefined,
					this.storeInstanceName,
					undefined,
					undefined,
					undefined,
					undefined,
					true,
					true,
				);
			}
		}
	}

	private toggleExistingCommon(selectable: Selectable): void {
		if (selectable?.isSelected) {
			this.selectionStoreService.unselectFromCommonSelection(selectable);
		} else if (selectable && !selectable.isSelected && selectable.isSelectable) {
			this.selectionStoreService.selectToCommonSelection(selectable);
		}
	}

	private toggleExistingDynamicStore(selectable: Selectable): void {
		if (selectable.isSelected) {
			this.selectionStoreService.removeFromSelection(selectable);
		} else {
			this.selectionStoreService.addToSelection(selectable);
		}
	}

	onToggleVisibleOnly(): void {
		this.showVisibleOnly = !this.showVisibleOnly;
	}

	getComment(item: ProcessTreeItem): string | null {
		const selectionKey: string = getIdKeyOfTypeId({ id: item.id, typeIdent: item.type.ident });
		const selectable = this.selectionRepository.getEntity(selectionKey, this.storeInstanceName);
		return selectable ? selectable.comment : null;
	}

	updateComment(itemTypeId: TypedId): void {
		this.wasChanged = true;
		const selectionKey: string = getIdKeyOfTypeId(itemTypeId);
		const selectable = this.selectionRepository.getEntity(selectionKey, this.storeInstanceName);
		if (selectable) {
			const keyToSearchFor: string = this.getSelectionKeyOfTypeId(itemTypeId);
			const allComments = this.commentInputs?.toArray();
			const newComment = allComments?.find((el) => el.nativeElement.id === keyToSearchFor);
			this.selectionStoreService.updateComment(selectable, newComment?.nativeElement.value);
		}
	}

	trackById: TrackByFunction<TypedViewable> = (_index, item) => {
		return getIdKeyOfTypeId({ id: item.id, typeIdent: item.type.ident });
	};

	isSelectable(element: ProcessTreeItem): boolean {
		if (this.extendedMode) {
			const selectionKey: string = getIdKeyOfTypeId({ id: element.id, typeIdent: element.type.ident });
			const selectable = this.selectionRepository.getEntityFromDynamicStore(this.storeInstanceName, selectionKey);
			return selectable ? selectable.isSelectable : false;
		}
		const viewable = element as stages.process.ViewableElement;
		const isSelectableChildrenOfFolder = this.isElementSelectable(element);
		const isSelectableThroughSpecialFiltering = this.isElementSelectableThroughSpecialFiltering(element);
		return this.allowIndexElementsForSelection && viewable.index
			? true
			: isSelectableChildrenOfFolder && isSelectableThroughSpecialFiltering;
	}

	private isElementSelectable(element: ProcessTreeItem): boolean {
		if (this.children) {
			const foundChild = this.children.find(
				(selectableChild) => selectableChild.id === element.id && selectableChild.type === element.type.ident,
			);
			if (foundChild) {
				return foundChild.isSelectable;
			}
		}
		return true;
	}

	private isElementSelectableThroughSpecialFiltering(element: ProcessTreeItem): boolean {
		if (this.filteredSelectables) {
			return this.filteredSelectables.some(
				(selectableTypedId) =>
					selectableTypedId.id === element.id && selectableTypedId.typeIdent === element.type.ident,
			);
		}
		return true;
	}

	onCancel(): void {
		this.cancel.emit();
	}

	onSave(): void {
		if (this.extendedMode) {
			this.selectionStoreService.setLoading(this.storeInstanceName, true);
		} else {
			this.isLoading = true;
		}
		const updatedElements: UpdatedElements = this.selectionStoreService.getUpdatedSelectables(
			this.addedElements,
			this.removedElements,
			this.updatedElements,
		);
		this.save.emit(updatedElements);
	}

	get isBrowseRoot(): boolean {
		if (this.folder.type.ident === "process") {
			return true;
		}

		if (nullToUndefined(this.folder.parent) === undefined) {
			return true;
		}
		const parent = assertDefined(this.folder.parent);
		return this.folder.type.ident !== parent.type.ident && this.browseRootType !== "process";
	}

	get isDirty(): boolean {
		return this.wasChanged || this.addedElementsCounter > 0 || this.removedElementsCounter > 0;
	}

	getGroupInfo(item: ProcessTreeItem): DependentsGroup {
		return {
			...item,
			name: item.label,
			ident: item.type.ident + item.id,
			showEmpty: true,
			getMemberCount(): number {
				if (item.dependentElements) {
					return item.dependentElements.length;
				}
				return 0;
			},
			dependentElements: item.dependentElements ?? [],
		};
	}

	showDependentElements(item: ProcessTreeItem): boolean {
		return (
			this.isSelectable(item) &&
			item.dependentElements !== undefined &&
			getAssignableDependentElements(
				item.dependentElements,
				this.ignoreDependentsAssignable,
				this.restrictAssociateDependentElementTypes,
			).length > 0
		);
	}
}

function getAssignableDependentElements(
	dependentElements: ViewableDependentElement[],
	ignoreDependentsAssignable: boolean,
	restrictAssociateDependentElementTypes: string[] | undefined,
): ViewableDependentElement[] {
	const assignableDependentElements = dependentElements.filter(
		(dependentElement) => ignoreDependentsAssignable || dependentElement.type.isAssignableDependentElement,
	);
	if (!restrictAssociateDependentElementTypes) {
		return assignableDependentElements;
	}
	return assignableDependentElements.filter(
		(dependentElement) =>
			dependentElement.type.subtypeIdent &&
			restrictAssociateDependentElementTypes.includes(dependentElement.type.subtypeIdent),
	);
}
