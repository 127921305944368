import { Injectable } from "@angular/core";
import { MainService } from "core/main.service";
import { UserPreferencesService } from "core/user-preferences.service";

type ApplicationContext = stages.context.ApplicationContext;

@Injectable({ providedIn: "root" })
export class PreferencesService {
	constructor(private mainService: MainService, private userPreferencesService: UserPreferencesService) {}

	async getUserPrefix(): Promise<string> {
		return this.mainService.getApplication("_vv").then((application: ApplicationContext) => {
			return application.username;
		});
	}

	/**
	 * Returns the localStorage version of a preference stored by the unique preference Key. Handling for different users is done internally.
	 *
	 * @param preferenceKey
	 * @param defaultValue
	 *            it will be returned in case no preferences are available in storage or there is no storage support.defaultValue
	 * @return promise with JSON result object
	 */
	async getGlobalPreference<T extends object>(prefKey: string, defaultValue: T): Promise<T> {
		return this.getUserPrefix().then((userPrefix) => {
			return this.userPreferencesService.getGlobalPreference(userPrefix, prefKey, defaultValue);
		});
	}

	/**
	 * Returns the sessionStorage (tab local) version of a preference stored by the unique preference Key. The sessionStorage gets a copy of the localStorage version in case no sessionStorage
	 * version is available. Handling for different users is done internally.
	 *
	 * @param preferenceKey
	 * @param defaultValue
	 *            it will be returned in case no preferences are available in storage or there is no storage support.defaultValue
	 * @return promise with JSON result object
	 */
	async getPreference<T extends object>(prefKey: string, defaultValue: T): Promise<T> {
		return this.getUserPrefix().then((userPrefix) => {
			return this.userPreferencesService.getPreference(userPrefix, prefKey, defaultValue);
		});
	}

	/**
	 * Stores the preference object in the sessionStorage and localStorage by the unique preference Key. Already existing other sessionStorage instances do not get an update. Handling for
	 * different users is done internally.
	 *
	 * @param preferenceKey
	 * @param value
	 *            JSON preference object to be stored.
	 * @return promise
	 */
	setPreference<T extends object>(prefKey: string, value: T): void {
		this.getUserPrefix().then((userPrefix) => {
			this.userPreferencesService.setPreference(userPrefix, prefKey, value);
		});
	}

	/**
	 * Delete the stored preference object in the sessionStorage and localStorage by the unique preference Key.
	 * Handling for different users is done internally.
	 */
	delPreference(prefKey: string): void {
		this.getUserPrefix().then((userPrefix) => {
			this.userPreferencesService.delPreference(userPrefix, prefKey);
		});
	}
}
