<article class="error-page" *ngIf="paramMap$ | async as paramMap">
	<div class="wrapper">
		<h5 class="title">{{ getTitleKey(paramMap) | translate }}</h5>
		<div class="error" translate="{{ getMessageKey(paramMap) }}"></div>

		<div *ngIf="isReportFormAvailable(paramMap)">
			<button
				class="sm"
				[routerLink]="[
					'..',
					'errorReport',
					{
						errorType: paramMap.get('errorType'),
						incidentId: paramMap.get('incidentId'),
						isReportable: paramMap.get('isReportable'),
						isTemporary: paramMap.get('isTemporary')
					}
				]"
			>
				{{ "error.report.message" | translate }}
			</button>
		</div>
		<div *ngIf="isSupportInfoAvailable(paramMap)">
			<a href="app/supportinfo" target="_self"
				><button class="sm">{{ "error.downloadSupportinfo.message.part1" | translate }}</button></a
			>
			<a
				href="mailto:{{ supportEmailAddress }}?subject=[Incident ID {{ paramMap.get('incidentId') }}]&body={{
					'error.report.placeholder' | translate
				}}"
				><button class="sm">{{ "error.downloadSupportinfo.message.part2" | translate }}</button></a
			>
		</div>
		<div *ngIf="isLinkToHomeAvailable(paramMap)">
			<button class="sm" [ngClass]="getHomeButtonClasses(paramMap)" (click)="goHome()">
				{{ "error.goHome" | translate }}
			</button>
		</div>
		<div *ngIf="isRetryAvailable(paramMap)">
			<button class="sm" (click)="retry()">{{ "error.retry" | translate }}</button>
		</div>
	</div>
</article>
