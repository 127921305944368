import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Selection, SelectionService } from "common/selection.service";
import { MainService } from "core/main.service";
import { FileService } from "files/files.service";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

type FileWithHistory = stages.file.FileWithHistory;
type FileRevision = stages.file.FileRevision;

@Component({
	selector: "stages-files-version",
	templateUrl: "./files-versions.component.html",
})
export class FilesVersionsComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	fileWithHistory$!: Observable<FileWithHistory>;
	expandedDropdown!: Selection<FileRevision>;
	downloadActionKey = "Download";
	linkActionKey = "Link";
	isDownloadable?: boolean;
	openNewTab: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private selectionService: SelectionService,
		private fileService: FileService,
	) {}

	ngOnInit(): void {
		this.fileWithHistory$ = this.route.data
			.pipe(
				map((data) => {
					data.fileWithHistory.revisions = data.fileWithHistory.revisions.map(this.createDisplayVersion);
					return data.fileWithHistory;
				}),
			)
			.pipe(takeUntil(this.destroy$));

		this.fileWithHistory$.subscribe((fileWithHistory) => {
			this.isDownloadable =
				fileWithHistory.actions[this.downloadActionKey] || fileWithHistory.actions[this.linkActionKey];
			this.openNewTab = !fileWithHistory.actions[this.downloadActionKey];
			this.expandedDropdown = this.selectionService.newSelection<FileRevision>(fileWithHistory.revisions, this.keyFn);
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	async downloadRevision(version: FileRevision): Promise<void> {
		if (this.isDownloadable) {
			return this.fileService.download(
				this.route,
				this.route.snapshot.paramMap.get("fileId")!,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				version.identifier,
				this.openNewTab,
			);
		}
	}

	isFixedVersion(version: FileRevision, fileWithHistory: FileWithHistory): boolean {
		return fileWithHistory.frozenVersion === version.identifier;
	}

	getNumberOfElements(version: FileRevision): number {
		if (version.labels.length && version.checkinComment) {
			return Number(version.labels.length) + 1;
		}
		if (version.labels.length && !version.checkinComment) {
			return version.labels.length;
		}
		if (!version.labels.length && version.checkinComment) {
			return 1;
		}
		return -1;
	}

	showDropdown(version: FileRevision): number | string {
		return version.checkinComment || version.labels.length;
	}

	keyFn = (version: FileRevision): string => {
		return version.identifier;
	};

	createDisplayVersion = (version: FileRevision): FileRevision => {
		const displayVersion = JSON.parse(JSON.stringify(version));
		return displayVersion;
	};

	getFileStateClasses(version: FileRevision): string[] {
		return this.fileService.getFileStateClasses(version.state, version.stateMessageKey);
	}

	close(): void {
		this.mainService.closeDialog(this.route);
	}
}
