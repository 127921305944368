<stages-media-query #media query="{{ mediaQuery }}">
	<table class="table dense center">
		<colgroup>
			<col />
			<ng-container *ngFor="let column of columns">
				<col [ngClass]="getColumnClass(column, !media.matches)!" *ngIf="!column.optional || !media.matches" />
			</ng-container>
		</colgroup>
		<thead>
			<tr>
				<th class="avatar-cell">
					<input
						type="checkbox"
						class="checkbox"
						id="selectAllItems"
						(change)="onSelectAllToggled()"
						[(ngModel)]="allSelected"
						*ngIf="isSelectAllDisplayed()"
					/>
					<label for="selectAllItems" *ngIf="isSelectAllDisplayed()" title="{{ getSelectCheckboxTitle() }}"></label>
				</th>
				<ng-container *ngFor="let column of columns">
					<th (click)="column.sortable && sort(column.name)" *ngIf="!column.optional || !media.matches">
						<span [ngClass]="{ clickable: column.sortable }"
							>{{ columnNamePrefix + column.name | translate }}
							<i *ngIf="column.sortable && isSortedBy(column, 1)" class="ico sort-icon ico-chevron-up"></i>
							<i *ngIf="column.sortable && isSortedBy(column, 2)" class="ico sort-icon ico-chevron-down"></i>
						</span>
					</th>
				</ng-container>
			</tr>
		</thead>
		<tbody *ngIf="isDisplayingMessageWhenEmpty()">
			<tr>
				<td class="avatar-cell"></td>
				<td [attr.colspan]="columns.length" class="empty-content">
					<span translate="{{ translateNone }}"></span>
				</td>
			</tr>
		</tbody>
		<tbody *ngIf="!isDisplayingMessageWhenEmpty()">
			<tr *ngFor="let item of items" [ngClass]="{ disabled: rowProvider(item).disabled }">
				<td class="avatar-cell">
					<input
						type="checkbox"
						class="checkbox"
						id="{{ rowProvider(item).id }}"
						(change)="onSelect()"
						[(ngModel)]="selection.set[rowProvider(item).id]"
						*ngIf="rowProvider(item).checkItem"
						[disabled]="rowProvider(item).disabled"
					/>
					<label for="{{ rowProvider(item).id }}" *ngIf="rowProvider(item).checkItem"></label>
					<i
						class="ico"
						[ngClass]="getRowIconClasses(item)"
						*ngIf="!rowProvider(item).checkItem"
						(click)="isClickHandled(item) && itemClicked.emit(item)"
					>
					</i>
				</td>
				<ng-container *ngFor="let column of columns; index as index">
					<td *ngIf="!column.optional || !media.matches">
						<span
							class="icon-name"
							(click)="isClickHandled(item) && itemClicked.emit(item)"
							[ngClass]="{ clickable: isClickHandled(item) }"
						>
							{{ cellProvider(item, index).text }}
						</span>
					</td>
				</ng-container>
			</tr>
		</tbody>
	</table>
</stages-media-query>
