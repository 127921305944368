<ng-container *ngIf="paramMap$ | async as paramMap">
	<stages-dialog-header>
		<a class="title clickable" [routerLink]="['..', 'elements']">
			<i class="ico ico-arrow-back"></i>
			<h3 class="shorten" translate="process.element.associationBrowser.target.selection"></h3>
		</a>
	</stages-dialog-header>

	<stages-workspace-selection
		*ngIf="filterByElementType(paramMap)"
		activeTabPreferenceKey="AssociationWorkspaceBrowser.activeTab"
		(selectWorkspace)="onSelectWorkspace($event)"
		[bookmarkable]="false"
		[filterBy]="'ELEMENT_TYPE'"
		[filterParam1]="paramMap.get('browseType')!"
		[filterParam2]="'READ'"
		[useBrowsePV]="true"
		[showRelated]="showRelated"
		[showArchived]="showArchived"
		[showFavorites]="showFavorites"
		[showNavigate]="showNavigate"
		[showSearch]="showSearch"
	>
	</stages-workspace-selection>
	<stages-workspace-selection
		*ngIf="!filterByElementType(paramMap)"
		activeTabPreferenceKey="AssociationWorkspaceBrowser.activeTab"
		(selectWorkspace)="onSelectWorkspace($event)"
		[bookmarkable]="false"
		[filterBy]="'PROCESS_EXISTS'"
		[showRelated]="showRelated"
		[showArchived]="showArchived"
		[showFavorites]="showFavorites"
		[showNavigate]="showNavigate"
		[showSearch]="showSearch"
	>
	</stages-workspace-selection>
</ng-container>
