/* eslint-disable @typescript-eslint/no-shadow -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".*/
import { animate, AnimationEvent, state, style, transition, trigger } from "@angular/animations";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DOCUMENT } from "@angular/common";
import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TrackByFunction,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AssociationSource } from "common/associations/association-browser.service";
import { Group, GroupDefinition, Subgroup } from "common/associations/association-group.service";
import { AssociationStore } from "common/associations/association-store.interface";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogService } from "common/dialog/dialog.service";
import { KeyboardService } from "common/keyboard.service";
import { MultiLevelSelection, MultiLevelSelectionService } from "common/multi-level-selection.service";
import { MainService } from "core/main.service";
import { PreferencesService } from "core/preferences.service";
import { Subscription } from "rxjs";
import { ViewService } from "core/view.service";
import { AssociationTargetLabelService } from "common/associations/association-target-label.service";

type ActionType = "add" | "delete" | "sort" | "view";
type SortStrategy = stages.core.sort.SortStrategy;

interface ExpandCollapseSetting {
	value: string;
	params: Record<string, unknown>;
}

export interface Container<S, T> {
	sourceElement: S;
	preferencesKey: string;
	associationGroups: Groups<T>;
}

export interface SourceElementAndGroup<S, T> {
	sourceElement: S;
	associationGroup: Group<T>;
}

export type Groups<T> = Record<string, Group<T>>;

interface AssociationAndGroupInfo<T> {
	association: Association<T>;
	group: UIGroup<T>;
	subgroup: UISubgroup<T>;
	expandableGroup: ElementRef;
}

class UIGroup<T> implements Group<T> {
	id!: string;
	path!: string;
	translate?: string;
	name?: string;
	derived!: boolean;
	commentSupported!: boolean;
	commentOnlyAssociationsSupported!: boolean;
	allowAssociateDependentElements!: boolean;

	list: Association<T>[] = [];
	visibleList: Association<T>[] = [];
	subgroups: UISubgroup<T>[] = [];

	sourceDependentTypes?: string[];
	targetType?: string;
	targetSubtypes?: string[];
	targetDependentTypes?: string[];
	sourceRole?: string;
	type!: string;
	workspace?: string;
	actions!: StringToBoolean;

	visible: boolean = false;
	expand: boolean = false;
	action: ActionType = "view";

	targetElementCreateAllowed: boolean = true;
	targetElementParentId?: string;
	selection?: MultiLevelSelection<Association<T>>;

	sortStrategy: SortStrategy = "HIERARCHICAL";
	groupBy?: string;
	forcedSort: boolean = false;

	constructor(group: Group<T>) {
		Object.assign(this, group);
	}
}

class UISubgroup<T> {
	name!: string;
	guid!: string;
	list: Association<T>[] = [];
	visibleList: Association<T>[] = [];
	visible: boolean = false;
	expand: boolean = false;
	currentOffset: number = 1;
	last: boolean = false;
	tailored: boolean = false;

	constructor(subgroup: Subgroup<T>) {
		Object.assign(this, subgroup);
	}
}

export interface Association<T> {
	id: string;
	identity: string;
	targetType: string | null;
	subtype: string | null;
	comment: string | null;
	isProcessInterface?: boolean;
	isCommentOnly?: boolean;
	targetElement: T | null;
	allowedOperations?: Record<string, boolean>;
	actions?: Record<string, boolean>;
	sortKey?: number;
	combined: boolean;
}

export interface AssociationTarget {
	type: {
		ident: string;
	};
	id: string;
	identity: string;
	label: string;
	dependent?: boolean;
	dependentElementsContainer?: boolean;
	process: {
		id: string;
		pv: string;
		isValidVersion: boolean;
		isWorkingRevision: boolean;
		workspaceId: string;
	};
	parent?: {
		identity: string;
		label: string;
		type: {
			ident: string;
		};
		process: {
			pv: string;
			isValidVersion: boolean;
			isWorkingRevision: boolean;
			workspaceId: string;
		};
	} | null;
}

@Component({
	selector: "stages-association-list",
	templateUrl: "./association-list.component.html",
	styleUrls: ["./association-list.component.scss"],
	animations: [
		trigger("expandCollapseFromTop", [
			state("collapsed", style({ "margin-top": "{{contentHeight}}px" }), { params: { contentHeight: 0 } }),
			state("expanded", style({ "margin-top": 0 })),
			state("add", style({ "margin-top": 0 })),
			transition("collapsed <=> expanded", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
			transition("add => collapsed", animate("0.4s 0s cubic-bezier(0.4, 0, 0.2, 1)")),
		]),
	],
})
export class AssociationListComponent<S extends AssociationSource<T>, T extends AssociationTarget>
	implements OnInit, OnDestroy, OnChanges
{
	sortOrderWasChanged: boolean = false;
	showMenu: boolean = true;
	newSortStrategy?: SortStrategy;
	isSaveInProgress = false;

	@HostBinding("class")
	get hostClasses(): string {
		return "association-list module " + this.classes;
	}

	@Input("class")
	classes: string = "";

	@Input()
	groups!: GroupDefinition[];

	uiGroups: UIGroup<T>[] = [];

	@Input()
	messageKeyNone?: string;

	@Input()
	editable?: boolean;

	@Input()
	showEmptyGroups = false;

	@Input()
	allowCreateElements?: boolean;

	@Input()
	allowCreateCommentOnlyElements?: boolean = true;

	@Input()
	targetElementProcessIds?: string[];

	@Input()
	remoteWorkspaceSeparatorInAutocomplete = true;

	@Input()
	associationStore!: AssociationStore<S, T>;

	@Input()
	container!: Container<S, T>;

	private isInit = false;

	groupSortListBackup: Association<T>[] | undefined = undefined;

	@Output() readonly openBrowse = new EventEmitter<SourceElementAndGroup<S, T>>();

	@Input()
	showProcessInterfaceName: boolean = true;

	currentOffset: number = 1;
	readonly visibleItemsMultiplikator = 25;

	// TODO wsl/twn Simplifiy lastVisible and visibleGroups
	lastVisible: UIGroup<T> | null = null;
	commentInputAssociationId?: string;
	commentInputAssociationSubgroupGuid?: string;
	commentInputText?: string;

	visibleGroups: UIGroup<T>[] = [];

	associationMenuItems: MenuItem[] = [];
	associationGroupMenuItems: MenuItem[] = [];
	outsideAddGroupClickHandler: EventListener | null = null;

	sourceElementForAdd!: S;

	private subscription!: Subscription;

	constructor(
		private route: ActivatedRoute,
		@Inject(DOCUMENT) private $document: Document,
		private preferencesService: PreferencesService,
		private dialogService: DialogService,
		private selectionService: MultiLevelSelectionService<Association<T>>,
		private mutexService: MutexService,
		private card: CardComponent,
		private KeyboardService: KeyboardService,
		private mainService: MainService,
		private viewService: ViewService,
		private associationTargetLabelService: AssociationTargetLabelService,
	) {}

	private createAssociationMenuItems(): MenuItem[] {
		return [
			{
				name: "editComment",
				iconClass: "ico ico-edit",
				disabled: (context: AssociationAndGroupInfo<T>) => {
					return (
						!context.group.commentSupported ||
						!context.association ||
						!this.associationStore.getActions(context.association).MODIFY ||
						context.association.combined
					);
				},
				on: (context: AssociationAndGroupInfo<T>) => {
					const { association, subgroup } = context;
					this.showCommentInput(association, subgroup);
				},
			},
			{
				name: "remove",
				iconClass: "ico ico-delete",
				disabled: (context: AssociationAndGroupInfo<T>) => {
					return context.group.derived || !this.deleteAllowed(context.association);
				},
				on: async (context: AssociationAndGroupInfo<T>) => {
					const { association } = context;

					let messageKey: string;
					let translateValues = {};
					if (association.isCommentOnly) {
						messageKey = "process.element.associationList.deleteCommentOnlyAssociation";
						translateValues = { comment: association.comment };
					} else {
						const name = this.getTargetElementCombinedLabel(association.targetElement!);
						messageKey = "process.element.associationList.delete";
						translateValues = { name: name };
					}

					if (await this.dialogService.confirm(messageKey, translateValues, "remove", "cancel", true)) {
						const group = this.findGroup(context.group.id);
						this.mutexService.invoke("associationDelete" + association.id, async () => {
							if (group) {
								const deletedAssociations = await this.associationStore.deleteAssociations(
									[association],
									this.route.snapshot.paramMap.get("workspaceId")!,
									this.route.snapshot.paramMap.get("processVersion")!,
								);
								this.updateGroupAfterDelete(group, deletedAssociations);
							}
						});
					}
				},
			},
			{
				name: "details",
				iconClass: "ico ico-info",
				disabled: (context: AssociationAndGroupInfo<T>) => {
					return !context.association || !context.association.combined;
				},
				on: (context: AssociationAndGroupInfo<T>) => {
					const { association, group } = context;
					this.openDetailViewOfCombined(association, group, this.container.sourceElement);
				},
			},
			{
				name: "remove",
				iconClass: "ico ico-delete",
				disabled: (context: AssociationAndGroupInfo<T>) => {
					return (
						!context.association ||
						!context.association.combined ||
						context.group.derived ||
						!this.associationStore.getActions(context.association).DELETE
					);
				},
				on: (context: AssociationAndGroupInfo<T>) => {
					const { association, group } = context;
					this.openDetailViewOfCombined(association, group, this.container.sourceElement, "remove");
				},
			},
		];
	}

	private containsDependentsMatchingType(dependentElements: S[], dependentTypeRestrictions?: string[]): boolean {
		if (!dependentTypeRestrictions) {
			return dependentElements.length > 0;
		}
		return !!dependentElements.find((d) => {
			return !!d.type.subtypeIdent && dependentTypeRestrictions.includes(d.type.subtypeIdent);
		});
	}

	private hasDependentMatchingType(dependentElements: S, dependentTypeRestrictions?: string[]): boolean {
		return this.containsDependentsMatchingType([dependentElements], dependentTypeRestrictions);
	}

	private createAssociationGroupMenuItems(): MenuItem[] {
		const dependentSourceElements = this.associationStore.getAdditionalSourceElements(this.container.sourceElement);
		const groupMenuItems: MenuItem[] = [];

		const directElementMenuItem = {
			id: (group: UIGroup<T>) => {
				return this.container.sourceElement.id + group.path;
			},
			disabled: (group: UIGroup<T>) => {
				return (
					group.derived ||
					!this.editable ||
					!group.actions.CREATE_ASSOCIATION ||
					!this.containsDependentsMatchingType(dependentSourceElements, group.sourceDependentTypes)
				);
			},
			isSwitch: false,
			name: "process.element.associationList.directly",
			nameParamValue: this.container.sourceElement.label,
			iconClass: this.getIconClasses(this.container.sourceElement),
			level: 1,
			expandable: false,
			on: (group: UIGroup<T>) => {
				this.sourceElementForAdd = this.container.sourceElement;
				this.editGroup(group, "add");
			},
		};
		const dependentGroupMenuItems = this.getDependentGroupMenuItems(dependentSourceElements);

		const addMenuItem = {
			id: (group: UIGroup<T>) => {
				return "add" + group.path;
			},
			disabled: (group: UIGroup<T>) => {
				return (
					group.derived ||
					!this.editable ||
					!group.actions.CREATE_ASSOCIATION ||
					this.containsDependentsMatchingType(dependentSourceElements, group.sourceDependentTypes)
				);
			},
			isSwitch: false,
			name: "add",
			nameParam: (group: UIGroup<T>) => this.associationStore.getAddMenuEntryNameKeyParam(group),
			nameParamValue: (group: UIGroup<T>) => this.associationStore.getAddMenuEntryNameKeyParamValue(group),
			level: 0,
			expandable: false,
			iconClass: "ico ico-add",
			on: (group: UIGroup<T>) => {
				this.sourceElementForAdd = this.container.sourceElement;
				this.editGroup(group, "add");
			},
		};

		const addMenuItemExpandable = {
			id: (group: UIGroup<T>) => {
				return "addexp" + group.path;
			},
			disabled: (group: UIGroup<T>) => {
				return (
					group.derived ||
					!this.editable ||
					!group.actions.CREATE_ASSOCIATION ||
					!this.containsDependentsMatchingType(dependentSourceElements, group.sourceDependentTypes)
				);
			},
			isSwitch: false,
			name: "add",
			nameParam: (group: UIGroup<T>) => this.associationStore.getAddMenuEntryNameKeyParam(group),
			nameParamValue: (group: UIGroup<T>) => this.associationStore.getAddMenuEntryNameKeyParamValue(group),
			level: 0,
			expandable: true,
			iconClass: "ico ico-add",
			on: (group: UIGroup<T>) => {},
		};

		const sortMenuItem = {
			name: "rearrange",
			iconClass: "ico ico-rearrange",
			disabled: (group: UIGroup<T>) => {
				return (
					group.derived ||
					group.forcedSort ||
					!this.editable ||
					!this.hasMoreThenOneSubgroupAssociation(group) ||
					!group.actions.SORT_ASSOCIATION
				);
			},
			isSwitch: false,
			on: (group: UIGroup<T>) => {
				group.selection = this.selectionService.newSelection(
					group.list,
					"association.selection." + group.id,
					(a) => a.id,
				);
				this.editGroup(group, "sort");
			},
		};

		const removeMenuItem = {
			name: "remove",
			iconClass: "ico ico-delete",
			disabled: (group: UIGroup<T>) => {
				return group.derived || !this.editable || !this.isAnyAssociationDeletable(group);
			},
			isSwitch: false,
			on: (group: UIGroup<T>) => {
				group.selection = this.selectionService.newSelection(
					this.getCompleteVisibleList(group),
					"association.selection." + group.id,
					(a) => a.id,
				);
				this.editGroup(group, "delete");
			},
		};

		groupMenuItems.push(addMenuItem, addMenuItemExpandable, directElementMenuItem);
		dependentGroupMenuItems.forEach((dependentMenuItem) => groupMenuItems.push(dependentMenuItem));
		if (dependentGroupMenuItems.length > 0) {
			groupMenuItems.push({ id: "Separator", level: 1, isSeparator: true });
		}
		groupMenuItems.push(sortMenuItem, removeMenuItem);
		return groupMenuItems;
	}

	ngOnInit(): void {
		this.initializeMenus();
		this.isInit = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.isInit && changes.container && changes.groups) {
			this.uiGroups = [];
			this.visibleGroups = [];
			this.initializeMenus();
		}
	}

	initializeMenus(): void {
		const groupDefinitions = this.groups;

		this.associationMenuItems = this.createAssociationMenuItems();
		this.associationGroupMenuItems = this.createAssociationGroupMenuItems();

		if (this.card && this.editable) {
			this.createCardMenuItems(groupDefinitions);
		}

		this.createUiGroups(groupDefinitions);
	}

	private createCardMenuItems(groupDefinitions: GroupDefinition[]): void {
		const menuItems: MenuItem[] = [];
		for (const groupDefinition of groupDefinitions) {
			const associationGroup = this.container.associationGroups[this.getGroupIdentifier(groupDefinition)];
			const dependentSourceElements = this.associationStore.getAdditionalSourceElements(this.container.sourceElement);

			const children = this.getDependentCardMenuItems(dependentSourceElements, associationGroup, groupDefinition);

			const addMenuItem = {
				id: "add_" + groupDefinition.path,
				disabled:
					associationGroup.derived ||
					!associationGroup.actions.CREATE_ASSOCIATION ||
					this.containsDependentsMatchingType(dependentSourceElements, associationGroup.sourceDependentTypes),
				isSwitch: false,
				name: this.associationStore.getAddMenuEntryNameKey(associationGroup),
				nameParam: this.associationStore.getAddMenuEntryNameKeyParam(associationGroup),
				nameParamValue: this.associationStore.getAddMenuEntryNameKeyParamValue(associationGroup),
				level: 0,
				expandable: false,

				on: () => {
					this.sourceElementForAdd = this.container.sourceElement;
					this.editGroup(this.findGroup(groupDefinition.id), "add");
				},
			};

			const addMenuItemExpandable = {
				id: "addexp_" + groupDefinition.path,
				disabled:
					associationGroup.derived ||
					!associationGroup.actions.CREATE_ASSOCIATION ||
					!this.containsDependentsMatchingType(dependentSourceElements, associationGroup.sourceDependentTypes),
				isSwitch: false,
				name: this.associationStore.getAddMenuEntryNameKey(associationGroup),
				nameParam: this.associationStore.getAddMenuEntryNameKeyParam(associationGroup),
				nameParamValue: this.associationStore.getAddMenuEntryNameKeyParamValue(associationGroup),
				level: 0,
				expandable: !!children && children.length > 0,
			};

			menuItems.push(addMenuItem);
			menuItems.push(addMenuItemExpandable);

			const directElementMenuItem = {
				id: this.container.sourceElement.id + groupDefinition.path,
				disabled: associationGroup.derived || !associationGroup.actions.CREATE_ASSOCIATION || !this.editable,
				isSwitch: false,
				name: "process.element.associationList.directly",
				nameParamValue: this.container.sourceElement.label,
				iconClass: this.getIconClasses(this.container.sourceElement),
				level: 1,
				on: () => {
					this.sourceElementForAdd = this.container.sourceElement;
					this.editGroup(this.findGroup(groupDefinition.id), "add");
				},
			};

			menuItems.push(directElementMenuItem);
			children.forEach((child) => menuItems.push(child));
			if (children.length > 0) {
				menuItems.push({ id: "Separator" + groupDefinition.path, level: 1, isSeparator: true });
			}
		}
		setTimeout(() => {
			this.card.menuNoCollapse = true;
			this.card.menuItems = menuItems;
		});
	}

	private getDependentCardMenuItems(
		dependentSourceElements: S[],
		associationGroup: Groups<T>[string],
		groupDefinition: GroupDefinition,
	): MenuItem[] {
		const dependentMenuItems: MenuItem[] = [];
		for (const dependentSourceElement of dependentSourceElements) {
			const dependentMenuItem = {
				id: dependentSourceElement.id + groupDefinition.path,
				disabled:
					associationGroup.derived ||
					!associationGroup.actions.CREATE_ASSOCIATION ||
					!this.hasDependentMatchingType(dependentSourceElement, associationGroup.sourceDependentTypes),
				isSwitch: false,
				iconClass: "ico " + this.getIconClasses(dependentSourceElement).join(" "),
				translated: dependentSourceElement.label,
				level: 1,
				on: () => {
					this.sourceElementForAdd = dependentSourceElement;
					this.editGroup(this.findGroup(groupDefinition.id), "add");
				},
			};
			dependentMenuItems.push(dependentMenuItem);
		}
		return dependentMenuItems;
	}

	private getDependentGroupMenuItems(dependentSourceElements: S[]): MenuItem[] {
		const menuItems: MenuItem[] = [];

		for (const dependentSourceElement of dependentSourceElements) {
			const menuItem = {
				id: (group: UIGroup<T>) => {
					return dependentSourceElement.id + group.path;
				},
				disabled: (group: UIGroup<T>) => {
					return (
						group.derived ||
						!this.editable ||
						!group.actions.CREATE_ASSOCIATION ||
						!this.hasDependentMatchingType(dependentSourceElement, group.sourceDependentTypes)
					);
				},
				isSwitch: false,
				// TODO
				translated: dependentSourceElement.label,
				iconClass: "ico " + this.getIconClasses(dependentSourceElement).join(" "),
				level: 1,
				expandable: false,
				on: (group: UIGroup<T>) => {
					this.sourceElementForAdd = dependentSourceElement;
					this.editGroup(group, "add");
				},
			};
			menuItems.push(menuItem);
		}
		return menuItems;
	}

	private async createUiGroups(groupDefinitions: GroupDefinition[]): Promise<void> {
		const preferences: StringToBoolean = await this.preferencesService.getPreference(this.container.preferencesKey, {});
		const subgroupPreferences: StringToBoolean = await this.preferencesService.getPreference(
			this.getSubgroupPreferencesKey(),
			{},
		);

		groupDefinitions.forEach(async (groupDefinition, index) => {
			const group = this.container.associationGroups[this.getGroupIdentifier(groupDefinition)];
			const uiGroup: UIGroup<T> = await this.getOrCreateUIGroup(group, this.container);
			const expandPreference = preferences[this.getGroupIdentifier(uiGroup)];
			uiGroup.expand = uiGroup.visible && (typeof expandPreference === "boolean" ? expandPreference : index === 0);

			uiGroup.subgroups.forEach((subgroup, index) => {
				const expandPreference = subgroupPreferences[group.id + "_" + subgroup.guid];
				subgroup.expand = typeof expandPreference === "boolean" ? expandPreference : index === 0;
			});
		});

		this.lastVisible = this.visibleGroups.length === 0 ? null : this.visibleGroups[this.visibleGroups.length - 1];
	}

	private async getOrCreateUIGroup(group: Group<T>, container: Container<S, T>): Promise<UIGroup<T>> {
		const existingUIGroup = this.uiGroups.find((g) => g.id === group.id);
		if (existingUIGroup) {
			existingUIGroup.list = group.list;
			return existingUIGroup;
		}
		return this.createUIGroup(group, container);
	}

	private updateUIGroup(newGroup: UIGroup<T>): void {
		this.updateVisibleLists(newGroup);

		// replace old group with new group
		const index = this.visibleGroups.findIndex((group) => group.id === newGroup.id);
		if (index > -1) {
			this.visibleGroups[index] = newGroup;
		}
	}

	private updateVisibleLists(group: UIGroup<T>): void {
		group.visibleList = group.list.slice(0, this.visibleItemsMultiplikator);

		group.subgroups.forEach((subgroup) => {
			subgroup.visibleList = subgroup.list.slice(0, this.visibleItemsMultiplikator);
			subgroup.visible = subgroup.visibleList.length > 0;
			subgroup.currentOffset = 1;
		});
	}

	private async createUIGroup(group: Group<T>, container: Container<S, T>): Promise<UIGroup<T>> {
		const uiGroup: UIGroup<T> = new UIGroup(group);
		const visibleGroup = this.showEmptyGroups || this.hasAnyAssociations(uiGroup);

		uiGroup.commentOnlyAssociationsSupported =
			group.commentOnlyAssociationsSupported && this.allowCreateCommentOnlyElements === true;

		this.uiGroups.push(uiGroup);

		if (visibleGroup) {
			uiGroup.visibleList = group.list.slice(0, this.visibleItemsMultiplikator);
			uiGroup.visible = true;
			uiGroup.sortStrategy = this.getSortStrategy(group);
			this.visibleGroups.push(uiGroup);
		}

		uiGroup.subgroups.forEach((subgroup) => {
			subgroup.visibleList = subgroup.list.slice(0, this.visibleItemsMultiplikator);
			subgroup.visible = subgroup.visibleList.length > 0;
			subgroup.currentOffset = 1;
		});

		if (uiGroup.subgroups.length > 0) {
			this.markLastVisibleSubgroup(uiGroup.subgroups);
		}

		uiGroup.targetElementCreateAllowed = false;
		if (group.targetType && this.allowCreateElements) {
			const potentialParent: T | null = await this.associationStore.getParentForNewTargetElement(
				container.sourceElement,
				group.targetType,
			);
			if (potentialParent) {
				uiGroup.targetElementCreateAllowed =
					this.associationStore.getTargetElementActions(potentialParent).CREATE_CHILD;
				uiGroup.targetElementParentId = potentialParent.id;
			}
		}

		return uiGroup;
	}

	getSortStrategy(group: Group<T>): SortStrategy {
		const assocSortKey = this.getFirstRelevantAssoc(group)?.sortKey;

		switch (true) {
			case assocSortKey === 1:
				return "ALPHABETICAL";
			case assocSortKey !== undefined && assocSortKey < 0:
				return "CUSTOM";
			default:
				return "HIERARCHICAL";
		}
	}

	getFirstRelevantAssoc(group: Group<T>): Association<T> | undefined {
		if (group.list.length > 0) {
			return group.list[0];
		} else if (group.subgroups.length > 0) {
			return group.subgroups[0].list[0];
		}
		return undefined;
	}

	private markLastVisibleSubgroup(subgroups: UISubgroup<T>[]): void {
		let markedLastVisible;
		for (let i = subgroups.length - 1; i >= 0; i--) {
			subgroups[i].last = subgroups[i].visible && !markedLastVisible;
			if (subgroups[i].last) {
				markedLastVisible = true;
			}
		}
	}

	getCurrentAction(groupId: string): ActionType {
		const group = this.uiGroups.find((g) => g.id === groupId);
		if (group) {
			return group.action;
		}
		return "view";
	}

	associationAdded(association: Association<T>, groupId: string): void {
		const group = this.findGroup(groupId);
		if (group) {
			this.updateGroupAfterAdd(group, association);
		}
	}

	updateGroupAfterAdd(group: UIGroup<T>, association: Association<T>): void {
		group.list.splice(0, 0, association);
		this.expand(group);
	}

	getTargetElementsToIgnore(group: UIGroup<T>): T[] {
		return this.getCompleteList(group)
			.filter((association) => {
				return !!association.targetElement;
			})
			.map((association) => {
				return association.targetElement!;
			});
	}

	getDependentElementTypesRestrictions(group: UIGroup<T>): string[] | undefined {
		return group.allowAssociateDependentElements ? group.targetDependentTypes : [];
	}

	deleteAllowed(association?: Association<T>): boolean {
		return association !== undefined && this.associationStore.getActions(association).DELETE && !association.combined;
	}

	getIconClasses(element: S | T, additionalClasses?: string): string[] {
		return this.associationStore.getIconClasses(element, !!element.dependent, additionalClasses);
	}

	getLabelClasses(sourceElement: S, targetElement: T, additionalClasses?: string): string[] {
		return this.associationStore.getLabelClasses(sourceElement, targetElement, additionalClasses);
	}

	getAssociationsCount(group: UIGroup<T>): number {
		let count = group.list.length;
		group.subgroups.forEach((subgroup) => {
			count += subgroup.list.length;
		});
		return count;
	}

	private getCompleteList(group: UIGroup<T>): Association<T>[] {
		const completeList: Association<T>[] = [];
		completeList.push(...group.list);
		group.subgroups.forEach((subgroup) => {
			completeList.push(...subgroup.list);
		});
		return completeList;
	}

	private getCompleteVisibleList(group: UIGroup<T>): Association<T>[] {
		const completeList: Association<T>[] = [];
		completeList.push(...group.visibleList);
		group.subgroups.forEach((subgroup) => {
			completeList.push(...subgroup.visibleList);
		});
		return completeList;
	}

	toggle(group: UIGroup<T>): void {
		if (group.expand) {
			this.collapse(group);
		} else {
			this.expand(group);
		}
	}

	toggleSubgroup(subgroup: UISubgroup<T>, group: UIGroup<T>): void {
		if (subgroup.expand) {
			this.collapseSubgroup(subgroup, group);
		} else {
			this.expandSubgroup(subgroup);
		}
	}

	hasAnyAssociations(group: UIGroup<T>): boolean {
		return this.hasMoreThanXAssociations(group, 0);
	}

	private hasMoreThenOneSubgroupAssociation(group: UIGroup<T>): boolean {
		return this.hasMoreThanXAssociations(group, 1);
	}

	private hasMoreThanXAssociations(group: UIGroup<T>, numberOfAssociations: number): boolean {
		return (
			group.list.length > numberOfAssociations || this.hasMoreThanXAssociationsInSubgroup(group, numberOfAssociations)
		);
	}

	private hasMoreThanXAssociationsInSubgroup(group: UIGroup<T>, numberOfAssociations: number): boolean {
		return group.subgroups.some((subgroup: UISubgroup<T>) => {
			return subgroup.list.length > numberOfAssociations;
		});
	}

	private isAnyAssociationDeletable(group: UIGroup<T>): boolean {
		return (
			group.list.some((association) => this.deleteAllowed(association)) ||
			group.subgroups.some((subgroup: UISubgroup<T>) => {
				return subgroup.list.some((association) => this.deleteAllowed(association));
			})
		);
	}

	isLastVisibleGroup(group: GroupDefinition): boolean {
		const lv = this.lastVisible;
		return lv !== null && lv.id === group.id;
	}

	hasAnyEnabledGroupMenuItems(group: UIGroup<T>): boolean {
		return this.associationGroupMenuItems.some((menuItem: StandardMenuItem<UIGroup<T>>) => {
			return typeof menuItem.disabled === "function" ? !menuItem.disabled(group) : false;
		});
	}

	unbindOnOutsideClick(): void {
		const eventListener = this.outsideAddGroupClickHandler;
		if (eventListener) {
			this.$document.removeEventListener("click", eventListener);
			this.outsideAddGroupClickHandler = null;
		}
	}

	bindOnOutsideClick(onOutsideClickFunc: EventListener): void {
		this.outsideAddGroupClickHandler = onOutsideClickFunc;
		this.$document.addEventListener("click", onOutsideClickFunc);
	}

	ngOnDestroy(): void {
		this.unbindOnOutsideClick();
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	closeInputOnOutsideClick(group: UIGroup<T>): void {
		/* wait for the add menu click handling to finish, before we register our outside click handler */
		setTimeout(() => {
			const onOutsideClick: EventListener = (event: Event) => {
				this.closeEdit(group, false);
			};
			this.unbindOnOutsideClick();
			this.bindOnOutsideClick(onOutsideClick);
		}, 0);
	}

	preventClosingAdd($event: UIEvent): void {
		if ($event.preventDefault) {
			$event.preventDefault();
			$event.stopPropagation();
		}
	}

	editGroup(group: UIGroup<T> | null, action: ActionType): void {
		if (group === null) {
			return;
		}
		group.action = action;

		if (!group.visible) {
			this.setVisible(group, true);
		}

		if (!group.expand) {
			this.expand(group);
		}

		if (action === "add") {
			this.closeInputOnOutsideClick(group);
		}
		this.showMenu = false;
	}

	closeEdit(group: UIGroup<T>, storeState: boolean = true): void {
		this.unbindOnOutsideClick();
		if (this.hasAnyAssociations(group)) {
			if (group.selection) {
				group.selection.clean();
			}
			group.action = "view";
		} else {
			this.collapse(group, storeState);
		}
		this.showMenu = true;
	}

	browse(groupDef: GroupDefinition): void {
		const group = this.container.associationGroups[this.getGroupIdentifier(groupDef)];
		const sourceElementAndGroup: SourceElementAndGroup<S, T> = {
			sourceElement: this.sourceElementForAdd,
			associationGroup: group,
		};
		this.openBrowse.emit(sourceElementAndGroup);
	}

	getCommentAssociationIconClasses(commentAssoc: Association<T>): string[] {
		const classes = [];
		if (commentAssoc.targetType) {
			classes.push("ico-et-" + commentAssoc.targetType.toLowerCase());
		}
		if (commentAssoc.subtype) {
			classes.push("ico-et-" + commentAssoc.subtype);
		}
		return classes;
	}

	findGroup(id: string): UIGroup<T> | null {
		return findGroup(id, this.uiGroups);
	}

	doneExpandCollapse(event: AnimationEvent, group: UIGroup<T>): void {
		if (event.toState === "collapsed" && !this.hasAnyAssociations(group) && !this.showEmptyGroups) {
			this.setVisible(group, false);
		}
	}

	expand(group: UIGroup<T>): void {
		group.expand = true;
		group.visibleList = group.list.slice(0, this.visibleItemsMultiplikator * this.currentOffset);
		this.storeState();
	}

	collapse(group: UIGroup<T>, storeState: boolean = true): void {
		group.expand = false;
		group.action = "view";
		this.showMenu = true;
		if (storeState) {
			this.storeState();
		}
	}

	expandSubgroup(subgroup: UISubgroup<T>): void {
		subgroup.expand = true;
		subgroup.visibleList = subgroup.list.slice(0, this.visibleItemsMultiplikator * subgroup.currentOffset);
		this.storeSubgroupState();
	}

	collapseSubgroup(subgroup: UISubgroup<T>, group: UIGroup<T>): void {
		subgroup.expand = false;
		group.action = "view";
		this.showMenu = true;
		this.storeSubgroupState();
	}

	setVisible(group: UIGroup<T>, visibility: boolean): void {
		group.visible = visibility;

		let lastVisible: UIGroup<T> | null = null;
		const visibleGroups: UIGroup<T>[] = [];

		this.uiGroups.forEach((g: UIGroup<T>) => {
			if (g.visible) {
				lastVisible = g;
				visibleGroups.push(g);
			}
		});

		this.lastVisible = lastVisible;
		this.visibleGroups = visibleGroups;
	}

	async storeState(): Promise<void> {
		const oldPreferences = await this.preferencesService.getPreference(this.container.preferencesKey, {});
		const newPreferences: StringToBoolean = { ...oldPreferences };

		this.visibleGroups.forEach((group) => {
			newPreferences[this.getGroupIdentifier(group)] = group.expand;
		});
		this.preferencesService.setPreference(this.container.preferencesKey, newPreferences);
	}

	async storeSubgroupState(): Promise<void> {
		const oldPreferences = await this.preferencesService.getPreference(this.getSubgroupPreferencesKey(), {});
		const newPreferences: StringToBoolean = { ...oldPreferences };

		this.visibleGroups.forEach((group) => {
			group.subgroups.forEach((subgroup) => {
				newPreferences[group.id + "_" + subgroup.guid] = subgroup.expand;
			});
		});
		this.preferencesService.setPreference(this.getSubgroupPreferencesKey(), newPreferences);
	}

	private getSubgroupPreferencesKey(): string {
		return this.container.preferencesKey + "_subgroups";
	}

	isSelectable(association: Association<T>): boolean {
		return !!this.associationStore.getActions(association).DELETE && !association.combined;
	}

	async deleteAssociations(group: UIGroup<T>): Promise<void> {
		group.selection = this.selectionService.newSelection(
			this.getCompleteVisibleList(group),
			"association.selection." + group.id,
			(a) => a.id,
		);

		if (group.selection) {
			const deletedAssociations = await this.associationStore.deleteAssociations(
				group.selection.get(),
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
			);
			this.updateGroupAfterDelete(group, deletedAssociations);
		}
	}

	updateGroupAfterDelete(group: UIGroup<T>, deletedAssociations: Association<T>[]): void {
		this.removeDeletedAssociationsFromGroup(group, deletedAssociations);
		group.action = "view";
		if (group.selection) {
			group.selection.clean();
		}
		if (!this.hasAnyAssociations(group)) {
			this.collapse(group);
			this.setVisible(group, false);
		}

		group.subgroups.forEach((subgroup) => {
			if (subgroup.list.length === 0) {
				this.collapseSubgroup(subgroup, group);
				subgroup.visible = false;
			}
		});
		this.markLastVisibleSubgroup(group.subgroups);
	}

	private removeDeletedAssociationsFromGroup(group: UIGroup<T>, deletedAssociations: Association<T>[]): void {
		for (const association of deletedAssociations) {
			this.removeDeletedAssociationFromList(association, group.list);

			group.subgroups.forEach((subgroup) => {
				this.removeDeletedAssociationFromList(association, subgroup.list);
			});
		}
	}

	private removeDeletedAssociationFromList(association: Association<T>, list: Association<T>[]): void {
		for (let i = 0; i < list.length; i++) {
			if (list[i].id === association.id) {
				list.splice(i, 1);
				break;
			}
		}
	}

	onCommentInputKeyUp(event: KeyboardEvent, association: Association<T>): void {
		if (this.KeyboardService.isEscapeKeyPressed(event)) {
			this.hideCommentInput();
		} else if (this.KeyboardService.isReturnKeyPressed(event)) {
			const newComment = this.commentInputText ?? "";
			if (newComment !== association.comment) {
				void this.associationStore.updateComment(
					association.id,
					newComment,
					this.route.snapshot.paramMap.get("workspaceId")!,
					this.route.snapshot.paramMap.get("processVersion")!,
				);

				// Currently we do not need to update the displayed associations because the association comment, that is displayed, is not rendered anymore on the server.
				// This must be changed when we implement the use case "Variable Resolution".
				// Additionally, the ordering of comment-only-associations is currently not updated. This can be changed, if ordering of associations is implemented and the reordering
				// does not confuse the user.
				// (Btw. textual references should not be resolved anymore.)
				association.comment = newComment;
			}
			this.hideCommentInput();
		}
	}

	showCommentInput(association: Association<T>, subgroup?: UISubgroup<T>): void {
		this.commentInputAssociationId = association.id;
		this.commentInputAssociationSubgroupGuid = subgroup ? subgroup.guid : undefined;
		this.commentInputText = association.comment === null ? undefined : association.comment;
	}

	hideCommentInput(): void {
		this.commentInputAssociationId = undefined;
		this.commentInputAssociationSubgroupGuid = undefined;
		this.commentInputText = undefined;
	}

	getTargetElementLink(association: Association<T>): unknown[] {
		return this.associationStore.getLink(association, this.getTargetProcessVersion(association));
	}

	getTargetElementLabel(targetElement: AssociationTarget): string {
		return this.associationTargetLabelService.getLabel(targetElement);
	}

	getTargetElementCombinedLabel(targetElement: AssociationTarget): string {
		return this.associationTargetLabelService.getCombinedLabel(targetElement);
	}

	openDetailViewOfCombined(association: Association<T>, group: UIGroup<T>, sourceElement: S, mode?: string): void {
		if (this.associationStore.getDetailLink) {
			const detailLink = this.associationStore.getDetailLink(
				sourceElement,
				association,
				group.path,
				group.groupBy,
				mode,
			);
			this.mainService.openPopup(detailLink, this.route, false);
		}
	}

	private getTargetProcessVersion(association: Association<T>): string {
		const selectedVersion = this.route.snapshot.paramMap.get("processVersion")!;
		if (!association.targetElement || !association.targetElement.process || !association.isProcessInterface) {
			return selectedVersion;
		}

		return association.targetElement.process.pv;
	}

	getProcessInterfaceIconClasses(association: Association<T>): string[] {
		if (!association.isProcessInterface || !association.targetElement) {
			return [];
		}

		const classes: string[] = [];
		classes.push("ico");

		if (association.targetElement.process?.isValidVersion) {
			if (
				association.targetElement.process.isWorkingRevision &&
				this.route.snapshot.paramMap.get("processVersion") === "_wv"
			) {
				classes.push("ico-change-workspace-w");
			} else {
				classes.push("ico-change-workspace-v");
			}
		} else if (association.targetElement.process?.isWorkingRevision) {
			classes.push("ico-change-workspace-w");
		} else {
			classes.push("ico-change-workspace");
		}

		return classes;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32217: Enable strict template type checking and suppress or fix all existing warnings.
	trackById: TrackByFunction<any> = (_index: number, e: UIGroup<T>) => {
		return e.id;
	};

	getGroupStyleClasses(group: UIGroup<T>): string[] {
		const classes = ["group"];
		if (this.isLastVisibleGroup(group)) {
			classes.push("last");
		}
		return classes;
	}

	getExpandCollapseFromTop(
		group: UIGroup<T>,
		expandable: HTMLDivElement,
		subgroup?: UISubgroup<T>,
	): ExpandCollapseSetting {
		let targetState = "collapsed";
		if (group.action !== "view" && group.expand) {
			targetState = "add";
		} else if (subgroup) {
			targetState = subgroup.expand ? "expanded" : "collapsed";
		} else {
			targetState = group.expand ? "expanded" : "collapsed";
		}

		return {
			value: targetState,
			params: {
				contentHeight: 0 - expandable.scrollHeight,
			},
		};
	}

	asTypedId(sourceElement: S): stages.core.TypedId {
		return this.associationStore.asTypedId(sourceElement);
	}

	loadMore(group: UIGroup<T>): void {
		this.currentOffset++;
		group.visibleList = group.list.slice(0, this.currentOffset * this.visibleItemsMultiplikator);
	}

	hasMore(group: UIGroup<T>): boolean {
		if (group.list.length > 0) {
			const currentLength = this.currentOffset * this.visibleItemsMultiplikator;
			return currentLength < group.list.length;
		}
		return false;
	}

	loadMoreIntoSubgroup(subgroup: UISubgroup<T>): void {
		subgroup.currentOffset++;
		subgroup.visibleList = subgroup.list.slice(0, subgroup.currentOffset * this.visibleItemsMultiplikator);
	}

	hasMoreInSubgroup(subgroup: UISubgroup<T>): boolean {
		if (subgroup.list.length > 0) {
			const currentLength = subgroup.currentOffset * this.visibleItemsMultiplikator;
			return currentLength < subgroup.list.length;
		}
		return false;
	}

	isCommentInputAssociation(association: Association<T>, subgroup?: UISubgroup<T>): boolean {
		return (
			this.commentInputAssociationId === association.id &&
			(subgroup === undefined || this.commentInputAssociationSubgroupGuid === subgroup.guid)
		);
	}

	isCombinedAssociation(association: Association<T>, subgroup?: UISubgroup<T>): boolean {
		// TODO no method needed -> do it in template?!
		return association.combined;
	}

	drop(event: CdkDragDrop<UIGroup<T>>, group: UIGroup<T>): void {
		this.sortOrderWasChanged = true;

		moveItemInArray(group.visibleList, event.previousIndex, event.currentIndex);
		this.newSortStrategy = "CUSTOM";
	}

	dropSubgroup(event: CdkDragDrop<UIGroup<T>>, group: UIGroup<T>, subGroup: UISubgroup<T>): void {
		this.sortOrderWasChanged = true;

		moveItemInArray(subGroup.visibleList, event.previousIndex, event.currentIndex);
		this.newSortStrategy = "CUSTOM";
	}

	async saveSortedAssociations(group: UIGroup<T>): Promise<void> {
		group.action = "view";
		this.showMenu = true;
		const completeAssocList = this.getCompleteVisibleList(group);
		const sortStrategy = this.newSortStrategy ? this.newSortStrategy : group.sortStrategy;

		try {
			this.isSaveInProgress = true;
			const newSortedAssociationsForGroup = await this.associationStore.rearrangeAssociations(
				this.route.snapshot.paramMap.get("workspaceId")!,
				completeAssocList.map((assoc) => assoc.id),
				sortStrategy,
				this.route.snapshot.paramMap.get("type")!,
				this.route.snapshot.paramMap.get("identity")!,
				group.path,
				group.groupBy ? group.groupBy : null,
				this.route.snapshot.paramMap.get("processVersion")!,
			);

			const sortedGroup = this.updateGroupWithNewSortedAssociations(group, newSortedAssociationsForGroup, sortStrategy);
			this.updateUIGroup(sortedGroup);
			this.updateViewWithNewSort(sortedGroup);
		} finally {
			this.isSaveInProgress = false;
			this.sortOrderWasChanged = false;
			this.newSortStrategy = undefined;
		}
	}

	updateGroupWithNewSortedAssociations(
		group: UIGroup<T>,
		newSortedAssocs: Record<string, Partial<UIGroup<T>>>,
		sortStrategy: SortStrategy,
	): UIGroup<T> {
		const newGroup = Object.keys(newSortedAssocs)
			.filter((elementKey) => this.associationStore.getGroupIdentifier(group.path, group.groupBy) === elementKey)
			.map((elementKey) => {
				return newSortedAssocs[elementKey];
			});

		if (newGroup) {
			const relevantGroup = newGroup[0];
			relevantGroup.sortStrategy = sortStrategy;
			return this.copyRelevantPropertysFrom(group, relevantGroup);
		}
		return group;
	}

	copyRelevantPropertysFrom(group: UIGroup<T>, newGroup: Partial<UIGroup<T>>): UIGroup<T> {
		group.list = newGroup.list!;
		group.sortStrategy = newGroup.sortStrategy!;

		const newSubgroups = [...group.subgroups];

		newSubgroups.forEach((subgroup) => {
			const foundNewGroup = newGroup.subgroups!.find((sg) => sg.guid === subgroup.guid);
			if (foundNewGroup) {
				subgroup.list = foundNewGroup.list;
			}
		});

		return { ...group, subgroups: newSubgroups };
	}

	onCancel(group: UIGroup<T>): void {
		this.updateVisibleLists(group);

		this.sortOrderWasChanged = false;
		this.newSortStrategy = undefined;
		group.action = "view";
		this.showMenu = true;
	}

	onOrderChange(sortStrategy: SortStrategy, group: UIGroup<T>): void {
		this.newSortStrategy = sortStrategy;
		this.sortOrderWasChanged = true;
		if (this.newSortStrategy === "ALPHABETICAL") {
			// fast sort for preview, real sort happens in backend after save
			this.sortAssociationsOfGroup(group);
		}
	}

	sortAssociationsOfGroup(group: UIGroup<T> | UISubgroup<T>): void {
		group.visibleList.sort((a, b) => {
			if (a.targetElement && b.targetElement) {
				const aCompareLabel =
					a.targetElement.dependent && a.targetElement.parent
						? a.targetElement.parent.label + "$$" + a.targetElement.label
						: a.targetElement.label;
				const bCompareLabel =
					b.targetElement.dependent && b.targetElement.parent
						? b.targetElement.parent.label + "$$" + b.targetElement.label
						: b.targetElement.label;
				return aCompareLabel.localeCompare(bCompareLabel);
			} else if (a.comment && b.comment) {
				return a.comment.localeCompare(b.comment);
			}
			return 0;
		});

		if (group instanceof UIGroup && group.subgroups.length > 0) {
			group.subgroups.forEach((subgroup) => this.sortAssociationsOfGroup(subgroup));
		}
	}

	getActualSortStrategy(group: UIGroup<T>): SortStrategy {
		return this.newSortStrategy ? this.newSortStrategy : group.sortStrategy;
	}

	private updateViewWithNewSort(sortedGroup: UIGroup<T>): void {
		const sortedIds = sortedGroup.list.map((assoc) => assoc.id);
		const groupIdentifier = this.getGroupIdentifier(this.convertToGroupDefinition(sortedGroup));
		const groupToUpdate = this.container.associationGroups[groupIdentifier];
		this.container.associationGroups[groupIdentifier].list = groupToUpdate.list.sort((a, b) => {
			return sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
		});

		if (groupToUpdate.list.length === sortedGroup.list.length) {
			for (let i = 0; i < groupToUpdate.list.length; ++i) {
				groupToUpdate.list[i].sortKey = sortedGroup.list[i].sortKey;
			}
		}
		this.viewService.notifyModified();
	}

	private getGroupIdentifier(group: GroupDefinition): string {
		return this.associationStore.getGroupIdentifier(group.path, group.groupBy);
	}

	private convertToGroupDefinition(group: UIGroup<T>): GroupDefinition {
		return {
			groupBy: group.groupBy,
			id: group.id,
			name: group.name,
			path: group.path,
			forcedSort: group.forcedSort,
			translate: group.translate,
		};
	}
}

function findGroup<T extends { id: string }>(id: string, groups: T[]): T | null {
	for (const group of groups) {
		if (group.id === id) {
			return group;
		}
	}
	return null;
}
