<article class="versions" *ngIf="fileWithHistory$ | async as fileWithHistory">
	<header class="page-header">
		<ul class="breadcrumb">
			<li>
				<a class="back" (click)="close()">
					<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
				</a>
			</li>
		</ul>
		<h1 translate="files.revisions.title" [translateParams]="{ fileName: fileWithHistory.name }"></h1>
	</header>

	<article class="card">
		<stages-media-query #media query="screen and (max-width:880px)">
			<div class="panel-component">
				<ul class="list" *ngIf="media.matches">
					<ng-container *ngFor="let version of fileWithHistory.revisions">
						<li class="list-item">
							<a class="label versions" href="javascript:void(0);" (click)="downloadRevision(version)">
								<div class="avatar-cell">
									<stages-user-image
										[userId]="version.creator.id"
										[userName]="version.creator.fullname"
										[version]="version.creator.profileImageVersion"
									></stages-user-image>
								</div>
								<div class="text center">
									<div class="justify-icon">
										<h4>{{ version.creator.fullname }}</h4>
										<i *ngIf="isFixedVersion(version, fileWithHistory)" class="ico ico-pin"></i>
									</div>
									<p>
										{{ version.creationDate }}, {{ "files.revisions.columnheader.identifier.short" | translate }}:
										{{ version.identifier }}
									</p>
									<p [ngClass]="getFileStateClasses(version)">
										<span class="state-icon"></span>
										<span
											class="state-name"
											translate="{{ version.stateMessageKey }}"
											*ngIf="version.stateMessageKey"
										></span>
										<span class="state-name" *ngIf="!version.stateMessageKey">{{ version.state }}</span>
									</p>
									<p *ngIf="showDropdown(version)">
										<span translate="files.revisions.cellinfo.hiddenElements"></span>
										<span class="counter">{{ getNumberOfElements(version) }}</span>
									</p>
								</div>
							</a>
							<stages-button-expand-collapse
								*ngIf="showDropdown(version)"
								[isCollapsed]="!expandedDropdown.isSelected(version)"
								[classes]="'action menu-button'"
								(pressedButton)="expandedDropdown.toggleSelected(version)"
							></stages-button-expand-collapse>
						</li>
						<li class="list-item" *ngIf="expandedDropdown.isSelected(version) && showDropdown(version)">
							<div class="label">
								<div class="avatar-cell"></div>

								<div class="text center">
									<div class="dropdown-styling" *ngIf="version.checkinComment">
										<h4 translate="files.revisions.columnheader.comment"></h4>
										<p class="preformatted">{{ version.checkinComment }}</p>
									</div>

									<div class="list-space"></div>
									<div class="dropdown-styling" *ngIf="version.labels.length">
										<h4 translate="files.revisions.columnheader.tags"></h4>
										<div class="list-space"></div>
										<div class="tag-visualizer" *ngIf="version.labels.length">
											<span *ngFor="let label of version.labels">{{ label }}</span>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ng-container>
				</ul>
				<table class="table center" *ngIf="!media.matches">
					<thead>
						<tr>
							<th class="avatar-cell"></th>
							<th translate="files.revisions.columnheader.modifiedBy"></th>
							<th translate="files.revisions.columnheader.date"></th>
							<th class="icon-cell versions"><i class="ico ico-pin"></i></th>
							<th translate="files.revisions.columnheader.identifier"></th>
							<th translate="files.revisions.columnheader.state"></th>
							<th translate="files.revisions.cellinfo.hiddenElements"></th>
							<th class="action"></th>
						</tr>
					</thead>

					<tbody>
						<ng-container *ngFor="let version of fileWithHistory.revisions">
							<tr [ngClass]="{ disabled: !isDownloadable }">
								<td class="avatar-cell">
									<a href="javascript:void(0);" (click)="downloadRevision(version)">
										<stages-user-image
											userId="{{ version.creator.id }}"
											[userName]="version.creator.fullname"
											version="{{ version.creator.profileImageVersion }}"
										></stages-user-image>
									</a>
								</td>
								<td>
									<a href="javascript:void(0);" (click)="downloadRevision(version)">
										<h4>{{ version.creator.fullname }}</h4>
									</a>
								</td>
								<td>
									<a href="javascript:void(0);" (click)="downloadRevision(version)">
										<p>{{ version.creationDate }}</p>
									</a>
								</td>
								<td class="icon-cell versions">
									<i *ngIf="isFixedVersion(version, fileWithHistory)" class="ico ico-pin"></i>
								</td>
								<td>
									<a href="javascript:void(0);" (click)="downloadRevision(version)">
										<p>{{ version.identifier }}</p>
									</a>
								</td>
								<td>
									<a href="javascript:void(0);" (click)="downloadRevision(version)">
										<p [ngClass]="getFileStateClasses(version)">
											<span class="state-icon"></span>
											<span
												class="state-name"
												[translate]="version.stateMessageKey"
												*ngIf="version.stateMessageKey"
											></span>
											<span class="state-name" *ngIf="!version.stateMessageKey">{{ version.state }}</span>
										</p>
									</a>
								</td>
								<td>
									<span *ngIf="showDropdown(version)" class="counter">{{ getNumberOfElements(version) }}</span>
								</td>
								<td class="label action">
									<stages-button-expand-collapse
										*ngIf="showDropdown(version)"
										[isCollapsed]="!expandedDropdown.isSelected(version)"
										[classes]="'menu-button'"
										(pressedButton)="expandedDropdown.toggleSelected(version)"
									></stages-button-expand-collapse>
								</td>
							</tr>
							<tr *ngIf="showDropdown(version) && expandedDropdown.isSelected(version)">
								<td></td>
								<td colspan="3" class="label">
									<div class="text center">
										<div class="dropdown-styling" *ngIf="version.checkinComment">
											<h4 translate="files.revisions.columnheader.comment"></h4>
											<p class="preformatted">{{ version.checkinComment }}</p>
										</div>
										<div class="dropdown-styling" *ngIf="version.labels.length">
											<h4 translate="files.revisions.columnheader.tags"></h4>
											<p></p>
											<div class="tag-visualizer">
												<span *ngFor="let label of version.labels">{{ label }}</span>
											</div>
										</div>
									</div>
								</td>
								<td colspan="3"></td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</stages-media-query>
	</article>
</article>
