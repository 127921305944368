<ng-container *ngIf="container$ | async as container">
	<stages-association-list
		[associationStore]="associationStore"
		[groups]="asArray(container.associationGroups)"
		[messageKeyNone]="messageKeyNone"
		[editable]="editable"
		[showEmptyGroups]="showEmptyGroups"
		[allowCreateElements]="false"
		[class]="classes"
		[container]="$any(container)"
		[remoteWorkspaceSeparatorInAutocomplete]="false"
		(openBrowse)="openBrowse($event, container)"
	>
	</stages-association-list>
</ng-container>
