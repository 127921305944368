import { Injectable } from "@angular/core";
import { DashboardResource, FilesResource } from "core/stages-client";

type File = stages.file.File;
type Element = stages.dashboard.Element;

export class EditableDescription {
	description!: stages.core.format.DisplayDescription;
	isEditable?: boolean;
}

@Injectable({ providedIn: "root" })
export class DashboardService {
	constructor(private readonly dashboardResource: DashboardResource, private readonly filesResource: FilesResource) {}

	async getMyMostFrequentlyUsedElements(workspaceId: string, pv: string): Promise<PagedResult<Element>> {
		return this.dashboardResource.getMyMostFrequentlyUsedElements({
			workspaceId: workspaceId,
			pv: pv,
		});
	}

	async getRecentlyUsedFiles(currentWorkspaceId: string, pv: string): Promise<File[]> {
		return this.filesResource.getRecentlyUsedFiles({
			workspaceId: currentWorkspaceId,
			pv: pv,
		});
	}
}
