import { Pipe, PipeTransform } from "@angular/core";

export type DisabledFunction = (args: unknown[]) => boolean;

export interface HasOptionalDisabled {
	disabled?: DisabledFunction | boolean;
}

@Pipe({
	name: "disabledFilter",
})
export class DisabledFilterPipe implements PipeTransform {
	transform(items: HasOptionalDisabled[], ...args: unknown[]): unknown {
		if (!items) {
			return items;
		}

		return items.filter((item) => {
			if (item.disabled === undefined) {
				return true;
			}

			return item.disabled instanceof Function ? !item.disabled(args) : !item.disabled;
		});
	}
}
