import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProcessInterfacesComponent } from "management/process-interfaces/process-interfaces.component";
import { ProcessInterfacesWorkspaceListComponent } from "management/process-interfaces/workspace-list/process-interfaces-workspace-list.component";
import { ProcessInterfacesInterfacesListComponent } from "management/process-interfaces/interfaces-list/process-interfaces-interfaces-list.component";

export const processInterfacesRoutes: Routes = [
	{
		path: "",
		component: ProcessInterfacesComponent,
		children: [
			{
				path: "",
				pathMatch: "full",
				redirectTo: "workspaces;page=1",
			},
			{
				path: "workspaces",
				component: ProcessInterfacesWorkspaceListComponent,
			},
			{
				path: "workspaces/:targetWorkspaceId",
				component: ProcessInterfacesInterfacesListComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(processInterfacesRoutes)],
})
export class ProcessInterfacesRoutingModule {}
