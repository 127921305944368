import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ExtendedTemplateDefinition, PDFService } from "print/pdf.service";

@Injectable({ providedIn: "root" })
export class TemplateDefinitionsResolver implements Resolve<ExtendedTemplateDefinition[]> {
	constructor(private pdfService: PDFService) {}

	async resolve(route: ActivatedRouteSnapshot): Promise<ExtendedTemplateDefinition[]> {
		return this.pdfService.getTemplates(
			route.paramMap.get("workspaceId")!,
			route.paramMap.get("processVersion")!,
			route.paramMap.get("type")!,
			route.paramMap.get("id")!,
		);
	}
}
