import { Component } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ExtendedTemplateDefinition } from "print/pdf.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	templateUrl: "./pdf.component.html",
})
export class PdfComponent {
	templateDefinitions$: Observable<ExtendedTemplateDefinition[]>;
	paramMap$: Observable<ParamMap>;

	constructor(private route: ActivatedRoute) {
		this.templateDefinitions$ = this.route.data.pipe(map((data) => data.templateDefinitions));
		this.paramMap$ = this.route.paramMap;
	}
}
