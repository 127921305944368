import { Directive, Input, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { SlotService } from "common/slot.service";

@Directive({
	selector: "[stagesSlot]",
})
export class SlotDirective implements OnInit, OnDestroy {
	@Input() stagesSlot!: string;

	constructor(public viewContainerRef: ViewContainerRef, private slotService: SlotService) {}

	ngOnInit(): void {
		this.slotService.registerSlot(this.stagesSlot, this.viewContainerRef);
	}

	ngOnDestroy(): void {
		this.slotService.destroySlot(this.stagesSlot, this.viewContainerRef);
	}
}
