/* eslint-disable @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */

import { Pipe, PipeTransform } from "@angular/core";
import { SelectionRepository } from "common/selection/state/selection.repository";
import { getIdKeyOfTypeId } from "common/selection/state/selection-store.service";
import TypedId = stages.core.TypedId;

@Pipe({
	name: "selectionStoreIsSelected",
})
export class SelectionStoreIsSelectedPipe implements PipeTransform {
	constructor(private selectionRepository: SelectionRepository) {}

	transform(
		item: any,
		storeIdentifier: string,
		isSelectAllActivated: boolean,
		_toggleCounter: number, // underscore needed to pass "never read lint warning" because we need it to trigger pipe run
		idFnNew?: (item: any) => TypedId,
		isPreselectedFn?: (item: any) => boolean,
	): boolean {
		if (!item || !idFnNew) {
			return false;
		}
		const typedId = idFnNew(item);
		const entity = this.selectionRepository.getEntity(getIdKeyOfTypeId(typedId), storeIdentifier);
		if (entity) {
			const isSelected = entity.isSelected && !entity.indeterminate;
			const isFullySelectable = entity.selectableChildrenCount === entity.totalChildrenCount;
			// TODO return entity?.isSelected should work but does not when checking indeterminate item
			return isSelected && isFullySelectable;
		}
		const isPreselected = isPreselectedFn ? isPreselectedFn(item) : false;
		return isPreselected && isSelectAllActivated;
	}
}
