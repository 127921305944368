import { Component, HostBinding, Input } from "@angular/core";
import { ListService } from "common/list/list.service";
import { trackByIndexFunction } from "core/functions";

export interface Entry {
	label: string;
	badges?: Badge[];
	showWarning?: boolean;
	linkDisabled?: boolean;
	getIconClasses(): string[];
	getCardClasses(): string[];
	getLabelClasses(): string[];
	getRouterLink(): unknown[];
}

export interface Badge {
	translate: string;
	styleClass: string;
}

@Component({
	selector: "stages-common-index",
	templateUrl: "./index.component.html",
	styleUrls: ["./index.component.scss"],
})
export class IndexComponent {
	@HostBinding("class.container") container = true;
	@HostBinding("class.index") index = true;

	@Input() entries?: Entry[];

	trackByIndex = trackByIndexFunction;

	constructor(private listService: ListService) {}

	getIconClasses(entry: Entry): string[] {
		const classes = ["ico"];
		return classes.concat(entry.getIconClasses());
	}

	getCardClasses(entry: Entry): string[] {
		const classes = ["card", "linked-card"];
		return classes.concat(entry.getCardClasses());
	}

	getVisibleEntries(): Entry[] | undefined {
		if (!this.entries) {
			return undefined;
		}
		return this.listService.getVisibleListItems(this.entries) as Entry[];
	}

	hasOmmittedEntries(): boolean {
		if (!this.entries) {
			return false;
		}
		return this.listService.hasOmmittedItems(this.entries);
	}
}
