<stages-media-query #media query="screen and (max-width:768px)">
	<div [ngClass]="{ 'horizontal-color-picker-container': isHorizontalOrientation() && !media.matches }">
		<color-circle
			[width]="horizontalWidth!"
			[colors]="colorPalette"
			[color]="currentColor"
			(onChange)="onColorChange($event)"
		></color-circle>
		<hr
			[ngClass]="!isHorizontalOrientation() || media.matches ? 'verticalColorSeparator' : 'horizontalColorSeparator'"
		/>
		<div
			[ngClass]="{
				'horizontal-color-picker-input-container': isHorizontalOrientation() && !media.matches
			}"
		>
			<input
				#color
				id="color"
				name="color"
				type="text"
				class="text colorInput"
				[required]="required"
				[value]="currentColor"
				[maxLength]="maxLengthOfInput"
				(paste)="onInputChange()"
				(input)="onInputChange()"
				(keydown)="onInputChange()"
				(blur)="onTouched()"
			/>
			<div
				class="currentSelectedColorCircle"
				[ngClass]="isHorizontalOrientation() && !media.matches ? 'selectedColorHorizontal' : 'selectedColorVertical'"
				[ngStyle]="{ 'box-shadow': currentColor + ' 0px 0px 0px 14px inset' }"
			></div>
		</div>
	</div>
</stages-media-query>
