import { MultiLevelSelection } from "common/multi-level-selection.service";
import { ListNavigationEntry } from "navigation/list/navigation-entry.interface";

export type NavigationMenuItem = AddMenuItem | FollowUpActionMenuItem | SeparatorMenuItem;

export interface FollowUpActionMenuItem {
	name: string;
	nameParam?: string;
	iconClass: string[] | string;
	storeElement: boolean;
	indicateProgress: boolean;
	invalidate: boolean;
	disabled: boolean | ((context: ListNavigationEntry) => boolean);
	onDoFollowUp(context: ListNavigationEntry, folder: ListNavigationEntry): Promise<FollowUpAction>;
}

export enum NavMode {
	NO_NAV,
	SELF,
	FOLDER_RESET_PARAMS,
	FORCED_FOLDER,
	FORCED_CHILD_AFTER_BACKUP,
	FORCED_FOLDER_AFTER_BACKUP,
	CONTEXT_AS_NEW_FOLDER,
	BACK_TO_SELF,
	KEEP_SELF_RESET_PARAMS,
	BACK_TO_SELF_AND_FOLDER_KEEP_BACKUP,
	TO_CONTEXT,
}

export interface AddMenuItem {
	name: string;
	nameParam?: string;
	iconClass: string[] | string;
	disabled: boolean | ((context: ListNavigationEntry) => boolean);
	onClick(context: ListNavigationEntry): Promise<FollowUpAction>;
	newChild(context: ListNavigationEntry): ListNavigationEntry;
}

export interface NavigationMode {
	actionPermissionName: string | null; //allowedOperation name
	isFolderChangeAllowed: boolean;
	isMenuEnabled: boolean;
	isRearrangeEnabled: boolean;
	isSelectEnabled: boolean;
	uiActions: UIAction[];
}

export interface MenuUIAction {
	indicateProgress: boolean;
	invalidate: boolean;
	self: ListNavigationEntry;
	folder: ListNavigationEntry;
	onMenuClick(): Promise<FollowUpAction>;
}

export type UIAction = RearrangeUIAction | SimpleUIAction;

export interface SimpleUIAction {
	name: string;
	buttonClass: string;
	indicateProgress: boolean;
	invalidate: boolean;
	self: ListNavigationEntry;
	folder: ListNavigationEntry;
	disabled?(selection: MultiLevelSelection<ListNavigationEntry>): boolean;
	on(selection: MultiLevelSelection<ListNavigationEntry>): Promise<FollowUpAction>;
}

export interface RearrangeUIAction {
	name: string;
	buttonClass: string;
	indicateProgress: boolean;
	invalidate: boolean;
	self: ListNavigationEntry;
	folder: ListNavigationEntry;
	disabled?(selection: MultiLevelSelection<ListNavigationEntry>): boolean;
	getSortedEntries(): ListNavigationEntry[];
	onRearrange(sortedEntries: ListNavigationEntry[], sortOrder: "alphabetical" | "custom"): Promise<FollowUpAction>;
}

export class FollowUpAction {
	actionParamName: string | null;
	navMode: NavMode;
	event?: string;
	forceReload: boolean = false;
	subtypeIdent?: string;
	context?: ListNavigationEntry;
	substateName?: string[];
	restoreRouteFromParams?: (params: unknown) => unknown[];
	hash?: string;
	popupRoute: unknown[] | null = null;

	constructor(navMode: NavMode, name?: string, event?: string) {
		this.navMode = navMode;
		this.actionParamName = name === undefined ? null : name;
		this.event = event;
	}

	withForcedReload(): FollowUpAction {
		this.forceReload = true;
		return this;
	}

	withSubtype(subtypeIdent?: string): FollowUpAction {
		this.subtypeIdent = subtypeIdent;
		return this;
	}

	withContext(context: ListNavigationEntry): FollowUpAction {
		this.context = context;
		return this;
	}

	gotoSubstate(substateName: string[]): FollowUpAction {
		this.substateName = substateName;
		return this;
	}

	openPopup(popupRoute: unknown[]): FollowUpAction {
		this.popupRoute = popupRoute;
		return this;
	}

	withRestoreRoute(restoreRouteFromParams: (params: unknown) => unknown[]): FollowUpAction {
		this.restoreRouteFromParams = restoreRouteFromParams;
		return this;
	}

	withHash(hash: string): FollowUpAction {
		this.hash = hash;
		return this;
	}
}
