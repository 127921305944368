import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: "highlightMatches",
	pure: true,
})
export class HighlightMatchesPipe implements PipeTransform {
	transform(name: string, searchTerm?: string): string {
		if (searchTerm && searchTerm.length > 0) {
			const regex = new RegExp(`(${escapeHtml(escapeRegex(searchTerm))})`, "gi");
			return escapeHtml(name).replace(regex, "<em>$1</em>");
		}

		return escapeHtml(name);
	}
}

function escapeHtml(unsafe: string): string {
	return unsafe
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");
}

function escapeRegex(unsafe: string): string {
	return unsafe.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}
