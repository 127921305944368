import { Injectable } from "@angular/core";
import { IAnsweredQuery } from "process/tailoring/models/answered-query.interface";
import { IQuery } from "process/tailoring/models/query.interface";
import { IRoot } from "process/tailoring/models/root.interface";
import { BehaviorSubject, Observable, OperatorFunction } from "rxjs";
import { distinctUntilChanged, pluck } from "rxjs/operators";

type preview = stages.process.tailoring.wizard.TailoringWizardPreview;

export interface State {
	root?: IRoot;
	queries?: IQuery[];
	query?: IQuery;
	answeredQueries?: IAnsweredQuery[];
	previewInProgress?: boolean;
	workspaceId?: string;
	pv?: string;
	preview?: preview;
}

const UNDEFINED_STATE: Partial<State> = {
	root: undefined,
	queries: undefined,
	query: undefined,
	answeredQueries: undefined,
	previewInProgress: undefined,
	workspaceId: undefined,
	pv: undefined,
	preview: undefined,
};

@Injectable({ providedIn: "root" })
export class TailoringWizardStore {
	private subject = new BehaviorSubject(UNDEFINED_STATE);
	private store = this.subject.asObservable().pipe(distinctUntilChanged());

	get value(): State {
		return this.subject.value;
	}

	select<T>(name: string): Observable<T> {
		return this.store.pipe(pluck(name) as OperatorFunction<State, T>);
	}

	set(name: string, state: unknown): void {
		this.subject.next({
			...this.value,
			[name]: state,
		});
	}
}
