import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

export interface DataRoute {
	commands: unknown[];
}

export interface Area {
	shape: string;
	coords: number[];
	title?: string;
	alt?: string;
	"data-route"?: DataRoute;
	href?: string;
}

export interface ImageMap {
	name: string;
	height?: number;
	width?: number;
	areas: Area[];
}

@Component({
	selector: "stages-image-map",
	templateUrl: "./image-map.component.html",
	styleUrls: ["./image-map.component.scss"],
})
export class ImageMapComponent {
	@Input()
	imageMap!: ImageMap;

	@Input()
	originalWidth!: number;

	@Input()
	width!: number;

	@Input()
	originalHeight!: number;

	@Input()
	height!: number;

	constructor(private location: Location, private router: Router) {}

	/*
	 * WP 34991: In IE 11 on Windows 7, visualization images are only scaled horizontally, not vertically.
	 * This means that separate X and Y zoom factors need to be computed for getting correct image map area
	 * coordinates in ZoomPipe.
	 */
	getXZoomFactor(imageMap: ImageMap | undefined, width: number, originalWidth: number): number {
		if (imageMap !== undefined && imageMap.width !== undefined) {
			//trust the width of the image map more than the natural width which is browser dependent
			return width / imageMap.width;
		}
		return width / originalWidth;
	}

	getYZoomFactor(imageMap: ImageMap | undefined, height: number, originalHeight: number): number {
		if (imageMap !== undefined && imageMap.height !== undefined) {
			//trust the height of the image map more than the natural height which is browser dependent
			return height / imageMap.height;
		}
		return height / originalHeight;
	}

	getHref(area: Area): string {
		if (area["data-route"]) {
			const urlTree = this.router.createUrlTree(area["data-route"].commands);
			return this.location.prepareExternalUrl(this.router.serializeUrl(urlTree));
		} else if (area.href) {
			return area.href;
		} else {
			return "javascript:void()";
		}
	}

	getTarget(area: Area): string {
		if (area.href) {
			return "_blank";
		}
		return "_self";
	}
}
