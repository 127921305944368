<stages-list-of-lists *ngIf="answeredQueries$ | async as answeredQueries; else noAnswers">
	<stages-collapsible-list
		[header]="'process.tailoring.wizard.answeredqueries.description' | translate"
		[listItemTemplate]="listItemTemplate"
		[listClass]="'dense'"
		[items]="answeredQueries"
		[expanded]="false"
	>
	</stages-collapsible-list>
</stages-list-of-lists>

<ng-template #noAnswers>
	<p class="empty" translate="process.tailoring.wizard.answeredqueries.empty"></p>
</ng-template>

<ng-template #listItemTemplate let-item class="space-between">
	<a class="label" [routerLink]="['./query/', item?.queryId]">
		<div class="text">
			<h4 class="space-after">{{ getQuery(item.queryId)?.name }}</h4>
			<p>{{ getAnswer(item.answerId)?.name }}</p>
		</div>
	</a>
</ng-template>
