<ng-container *ngIf="mode === 'ADD' || mode === 'RENAME'">
	<div class="text">
		<ng-content></ng-content>
		<h6 *ngIf="!entry.secondLineTranslate">{{ entry.secondLine }}</h6>
		<h6 *ngIf="entry.secondLineTranslate" translate="{{ entry.secondLineTranslate }}"></h6>
	</div>
</ng-container>
<ng-container *ngIf="!(mode === 'ADD' || mode === 'RENAME')">
	<div class="text">
		<h3 *ngIf="entry.labelTranslate" [ngClass]="entry.labelClasses" [translate]="entry.labelTranslate"></h3>
		<h3 *ngIf="!entry.labelTranslate" [ngClass]="entry.labelClasses">{{ entry.label }}</h3>
		<h6 *ngIf="!entry.secondLineTranslate">{{ entry.secondLine }}</h6>
		<h6 *ngIf="entry.secondLineTranslate" translate="{{ entry.secondLineTranslate }}"></h6>
	</div>
	<ng-container> </ng-container
></ng-container>
