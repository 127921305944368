import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { MainService } from "core/main.service";
import { ViewService } from "core/view.service";
import { ViewableNavigationConfigurationProvider } from "navigation/list/navigation-viewable-navigation.logic";
import { NavigationService } from "navigation/list/navigation.service";
import { AddService } from "process/element/add/add.service";
import { DeleteService } from "process/element/delete/delete.service";
import { EditService } from "process/element/edit/edit.service";
import { PasteService } from "process/element/paste/paste.service";
import { RearrangeService } from "process/element/rearrange/rearrange.service";
import { ModuleService } from "process/modules/module.service";
import { ProcessStore } from "process/navigation/process-navigation-store.logic";
import { NavigationSpec, ViewSpec } from "process/view/type.interface";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	templateUrl: "./process-navigation.component.html",
})
export class ProcessNavigationComponent implements OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private route: ActivatedRoute,
		navigationService: NavigationService,
		mutexService: MutexService,
		private mainService: MainService,
		editService: EditService,
		addService: AddService,
		deleteService: DeleteService,
		pasteService: PasteService,
		rearrangeSerive: RearrangeService,
		moduleService: ModuleService,
		private viewService: ViewService,
	) {
		const processStore = new ProcessStore(
			editService,
			addService,
			deleteService,
			pasteService,
			rearrangeSerive,
			moduleService,
			viewService,
		);
		const navigationProvider = new ViewableNavigationConfigurationProvider(
			navigationService,
			mainService,
			mutexService,
			processStore,
		);

		combineLatest([this.viewService.awaitSelfElementObservable(), this.route.paramMap])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([view, params]) => {
				let navigationSpec: NavigationSpec;
				if (this.mainService.secondaryMode === "compare") {
					navigationSpec = {
						labelType: "change-marker",
						showAddButton: false,
					};
				} else if (this.mainService.secondaryMode === "compliance") {
					navigationSpec = {
						labelType: "two-line-split-rating",
						showAddButton: false,
					};
				} else {
					navigationSpec = (view.viewType as ViewSpec).navigation;
				}

				navigationProvider.updateNavigationFromParams(
					view as unknown as stages.process.ViewableElement,
					params,
					route,
					navigationSpec.showAddButton,
					navigationSpec.labelType,
				);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
