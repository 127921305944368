import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ViewService } from "core/view.service";
import { ComplianceService } from "process/compliance/compliance.service";
import { merge, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
	selector: "stages-compliance-rating",
	templateUrl: "./rating.html",
})
export class RatingComponent implements OnDestroy {
	rating!: stages.compliance.ComplianceRating;
	ratingMenuItems!: MenuItem[];
	commentMenuItems!: MenuItem[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	private self: any;
	translateMap: StringToString;
	comment!: string;
	editEnabled: boolean = false;
	private refreshMenu: boolean = false;
	private origComment!: string;

	private destroy$ = new Subject<boolean>();

	constructor(
		private route: ActivatedRoute,
		private viewService: ViewService,
		private complianceService: ComplianceService,
	) {
		merge(this.route.data.pipe(map((data) => data.view)), viewService.awaitViewObservable())
			.pipe(takeUntil(this.destroy$))
			.subscribe((view) => {
				this.self = this.viewService.getSelf(view.processView);

				if (view.processView.complianceRatingContainer) {
					this.rating = view.processView.complianceRatingContainer.rating;
					this.comment = view.processView.complianceRatingContainer.rating.notes;
					this.commentMenuItems = [
						{
							name: "process.compliance.action.edit",
							iconClass: "ico ico-edit",
							disabled: () => {
								return !view.processView.complianceRatingContainer.actions.EditCommentPolicy;
							},
							on: () => {
								this.edit();
							},
						},
					];

					this.ratingMenuItems = [
						{
							name: "process.compliance.rating.notRated",
							disabled: () => !view.processView.complianceRatingContainer.actions.EditRatingPolicy,
							iconPosition: "right",
							iconClass: "ico rating-icon ico-star-0",
							on: () => {
								this.storeRating("NOT_RATED");
							},
						},
						{
							name: "process.compliance.rating.none",
							disabled: () => !view.processView.complianceRatingContainer.actions.EditRatingPolicy,
							iconPosition: "right",
							iconClass: "ico rating-icon ico-star-1",
							on: () => {
								this.storeRating("NONE");
							},
						},
						{
							name: "process.compliance.rating.incomplete",
							disabled: () => !view.processView.complianceRatingContainer.actions.EditRatingPolicy,
							iconPosition: "right",
							iconClass: "ico rating-icon ico-star-2",
							on: () => {
								this.storeRating("INCOMPLETE");
							},
						},
						{
							name: "process.compliance.rating.fair",
							disabled: () => !view.processView.complianceRatingContainer.actions.EditRatingPolicy,
							iconPosition: "right",
							iconClass: "ico rating-icon ico-star-3",
							on: () => {
								this.storeRating("FAIR");
							},
						},
						{
							name: "process.compliance.rating.complete",
							disabled: () => !view.processView.complianceRatingContainer.actions.EditRatingPolicy,
							iconPosition: "right",
							iconClass: "ico rating-icon ico-star-4",
							on: () => {
								this.storeRating("COMPLETE");
							},
						},
					];
				} else {
					this.comment = "";
				}
				this.origComment = this.comment;
			});

		this.translateMap = {
			NOT_RATED: "process.compliance.rating.notRated",
			NONE: "process.compliance.rating.none",
			INCOMPLETE: "process.compliance.rating.incomplete",
			FAIR: "process.compliance.rating.fair",
			COMPLETE: "process.compliance.rating.complete",
		};
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	edit(): void {
		this.editEnabled = true;
	}

	cancel(): void {
		this.editEnabled = false;
		if (this.refreshMenu || this.comment !== this.origComment) {
			this.viewService.refresh(this.route.snapshot.paramMap);
		}
	}

	isEmpty(): boolean {
		return !this.comment;
	}

	storeRating(rating: string): void {
		if (!this.rating) {
			return;
		}

		if (!this.rating.targetProcessId) {
			return;
		}

		this.complianceService
			.updateRating(
				this.rating.elementTypeIdent,
				this.rating.elementId,
				this.rating.processId,
				this.rating.targetProcessId,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				rating,
			)
			.then(() => {
				this.self.complianceRatingContainer.rating.rating = rating;
				if (!this.editEnabled) {
					this.viewService.refresh(this.route.snapshot.paramMap);
				}
				this.refreshMenu = true;
			});
	}

	saveComment(): void {
		if (!this.rating) {
			return;
		}
		if (!this.rating.targetProcessId) {
			return;
		}
		this.rating.notes = this.comment;
		this.complianceService
			.updateComment(
				this.rating.elementTypeIdent,
				this.rating.elementId,
				this.rating.processId,
				this.rating.targetProcessId,
				this.route.snapshot.paramMap.get("workspaceId")!,
				this.route.snapshot.paramMap.get("processVersion")!,
				this.rating.notes,
			)
			.then(() => {
				this.self.complianceRatingContainer.rating.notes = this.comment;
				this.viewService.refresh(this.route.snapshot.paramMap);
			});
		this.editEnabled = false;
	}

	getIconClasses(): string[] {
		const classes: string[] = [];

		if (!this.rating) {
			return [];
		}

		switch (this.rating.rating) {
			case "NOT_RATED":
				classes.push("rating-not-rated");
				break;
			case "NONE":
				classes.push("rating-none");
				break;
			case "INCOMPLETE":
				classes.push("rating-incomplete");
				break;
			case "FAIR":
				classes.push("rating-fair");
				break;
			case "COMPLETE":
				classes.push("rating-complete");
				break;
			default:
				break;
		}

		return classes;
	}
}
