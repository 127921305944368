import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ErrorType } from "common/exception/error-handler";
import { ScrollService } from "common/routing/scroll.service";
import { MainService } from "core/main.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

function getMessageKey(errorType: ErrorType, suffix: string): string {
	return `error.${errorType}.${suffix}`;
}

@Component({
	selector: "stages-error",
	templateUrl: "error.component.html",
})
export class ErrorComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();
	paramMap$!: Observable<ParamMap>;
	mailServerConfigured?: boolean;
	userEmailAddress?: string | null;
	supportEmailAddress?: string | null;

	constructor(
		private route: ActivatedRoute,
		private readonly mainService: MainService,
		private scrollService: ScrollService,
	) {}

	ngOnInit(): void {
		this.scrollService.enableWindowScroll();
		this.paramMap$ = this.route.paramMap;
		this.paramMap$.pipe(takeUntil(this.destroy$)).subscribe((paramMap) => {
			this.mainService.getApplication(paramMap.get("processVersion")!).then((context) => {
				this.mailServerConfigured = context.mailServerConfigured;
				this.userEmailAddress = context.userEmailAddress;
				this.supportEmailAddress = context.supportEmailAddress;
			});
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	getTitleKey(paramMap: ParamMap): string {
		return getMessageKey(paramMap.get("errorType") as ErrorType, "title");
	}

	getMessageKey(paramMap: ParamMap): string {
		return getMessageKey(paramMap.get("errorType") as ErrorType, "message");
	}

	isReportFormAvailable(paramMap: ParamMap): boolean {
		return isErrorReportable(paramMap) && this.isErrorReportFormEnabled();
	}

	isSupportInfoAvailable(paramMap: ParamMap): boolean {
		return isErrorReportable(paramMap) && !this.isErrorReportFormEnabled();
	}

	isLinkToHomeAvailable(paramMap: ParamMap): boolean {
		return !this.isRetryAvailable(paramMap);
	}

	isRetryAvailable(paramMap: ParamMap): boolean {
		return isErrorTemporary(paramMap);
	}

	getHomeButtonClasses(paramMap: ParamMap): string[] {
		if (this.isSupportInfoAvailable(paramMap)) {
			return ["cancel"];
		}
		return [];
	}

	isErrorReportFormEnabled(): boolean {
		return !!(this.mailServerConfigured && !!this.userEmailAddress && !!this.supportEmailAddress);
	}

	goHome(): void {
		void this.mainService.goHome(
			this.route.snapshot.paramMap.has("processVersion") ? this.route.snapshot.paramMap.get("processVersion")! : "_vv",
		);
	}

	retry(): void {
		location.reload();
	}
}

function isErrorReportable(paramMap: ParamMap): boolean {
	return paramMap.get("isReportable") === "true";
}

function isErrorTemporary(paramMap: ParamMap): boolean {
	return paramMap.get("isTemporary") === "true";
}
