import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "stages-process-tailoring-wizard-preview",
	templateUrl: "./preview.component.html",
	styleUrls: ["preview.component.scss"],
})
export class TailoringWizardPreviewComponent {
	preview$: Observable<stages.process.tailoring.wizard.TailoringWizardPreview>;

	constructor(private route: ActivatedRoute) {
		this.preview$ = this.route.data.pipe(
			map((data) => {
				return data.preview;
			}),
		);
	}

	showEmpty(preview: stages.process.tailoring.wizard.TailoringWizardPreview): boolean {
		return preview.removedElements.length === 0 && preview.addedElements.length === 0;
	}

	getIconClasses(tailorable: stages.process.tailoring.Tailorable): string[] {
		const result: string[] = [];
		const iconPrefix = tailorable.type.ident.startsWith("enactment") ? "enactment" : "et";
		result.push(`ico-${iconPrefix}-${tailorable.type.ident}`);
		if (tailorable.type.subtypeIdent) {
			result.push(`ico-${iconPrefix}-${tailorable.type.subtypeIdent}`);
		}
		return result;
	}
}
