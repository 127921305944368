import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class MSOfficeGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const identityLength = 23;
		const identity = route.paramMap.get("identity");
		if (identity !== null && identity.length > identityLength + 1 && identity.indexOf("#") === identityLength) {
			const cleanedIdentity = identity.substring(0, identityLength);
			const fragment = identity.substring(identityLength + 1);
			this.router.navigate(
				[
					"workspace",
					route.paramMap.get("workspaceId"),
					route.paramMap.get("processVersion"),
					"process",
					route.paramMap.get("type"),
					cleanedIdentity,
				],
				{ fragment: fragment, replaceUrl: true },
			);
			return false;
		}
		return true;
	}
}
