<ng-template let-editableProcess="editableProcess" #buttonbar>
	<div class="input-container right">
		<button
			type="submit"
			class="button sm save"
			name="submit"
			tabindex="101"
			(click)="save(editableProcess)"
			[disabled]="isSaveInProgress"
		>
			<span *ngIf="!isSaveInProgress" translate="save"></span>
			<stages-spin *ngIf="isSaveInProgress" size="s"></stages-spin>
		</button>
		<button
			type="button"
			class="button sm cancel"
			name="cancel"
			tabindex="100"
			(click)="cancel()"
			translate="cancel"
		></button>
	</div>
</ng-template>

<ng-container *ngIf="workspaceView$ | async as workspaceView">
	<ng-container *ngIf="data$ | async as data">
		<article class="edit-element" *ngIf="editableProcess$ | async as editableProcess">
			<header class="page-header">
				<ul class="breadcrumb">
					<li>
						<a class="back" [routerLink]="['..']">
							<i class="ico ico-arrow-back"></i><span class="label" translate="back"></span>
						</a>
					</li>
				</ul>
				<h1 translate="process.element.edit.title"></h1>
			</header>

			<article class="card">
				<form class="form" [formGroup]="formGroup" #formElement>
					<ng-container *ngTemplateOutlet="buttonbar; context: { editableProcess: editableProcess }"></ng-container>
					<div class="input-container">
						<label class="input-label" for="edt1" translate="process.element.edit.description"></label>
						<stages-description-editor
							[editorId]="'processDescriptionEditor'"
							[modifiable]="true"
							[unsafe]="false"
							[description]="editableProcess.process.displayDescription"
							[processTypeIdent]="data.view.processView.processType"
							[pluginRegistry]="pluginRegistry"
							[beanType]="data.view.processView.type.ident"
							[beanId]="data.view.processView.id"
							[beanIdentity]="data.view.processView.identity"
							[workspaceId]="workspaceView.currentWorkspace.id"
							[pv]="workspaceView.currentWorkspace.viewedProcess!.versionIdentifier"
						>
						</stages-description-editor>
						<div class="info"></div>
					</div>

					<stages-attribute-edit
						[attributeTypes]="editableProcess.attributeTypes"
						[attributes]="editableProcess.process.attributes"
						[form]="formGroup"
						[richtextInputContext]="getContext(data, workspaceView)"
					>
					</stages-attribute-edit>

					<div class="info"></div>
					<ng-container *ngTemplateOutlet="buttonbar; context: { editableProcess: editableProcess }"></ng-container>
				</form>
			</article>
		</article>
	</ng-container>
</ng-container>
