import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { CardComponent } from "common/card/card.component";
import { MutexService } from "common/concurrency/mutex.service";
import { DialogService } from "common/dialog/dialog.service";
import { KeyboardService } from "common/keyboard.service";
import { FilesDroppedCallback, UploadCompletedCallback } from "common/upload/upload.component";
import { UtilService } from "common/util.service";
import { MainService } from "core/main.service";
import { NotificationService } from "core/notification.service";
import { UploadService } from "core/upload.service";
import { FileService } from "files/files.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

type File = stages.file.File;
type FileDescriptor = stages.process.FileDescriptor;
type Status = stages.core.scheduler.Status;

const MAILTO_PATTERN: RegExp = /mailto:.*/;
const URL_REGEX_PROTOCOL: RegExp = /^\w+:\/\//gi;
const URL_REGEX_UNC: RegExp = /^\\\\\w+/gi;

export interface FileSource {
	getFilesFunction(): Promise<File[]>;
	pollFilesFunction(fileIds: string[]): Promise<Map<string, Status>>;
}

export interface FileContainer {
	elementId: string;
	elementType: string;
	files: File[];
	templateFileDescriptors: FileDescriptor[];
	allowedOperations: StringToBoolean;
}

interface StartOption {
	classes: string[];
	icon: string;
	title: string;
	message: string;
	button: string;
	disabled: boolean | ((a: unknown) => boolean);
	on(a: unknown): void;
}

export class FilePolling {
	intervalId: number = 0;
	initial = true;

	constructor(
		private notificationService: NotificationService,
		private fileContainer: FileContainer,
		private fileSource: FileSource,
		private fileService: FileService,
	) {}

	private updateFileJobStatus(fileIds: string[]): (runningJobs: Map<string, Status>) => void {
		return (runningJobs: Map<string, Status>) => {
			if (!runningJobs || Object.keys(runningJobs).length === 0) {
				this.stopPolling();
				this.fileService.deleteInProgressFileIds(fileIds);
				this.fileSource.getFilesFunction().then((files) => {
					this.fileContainer.files = files;
				});
			}
		};
	}

	startPolling(fileIds: string[]): void {
		if (!fileIds.length) {
			return;
		}
		if (!this.initial) {
			this.fileContainer.files.forEach((file) => {
				if (fileIds.includes(file.id)) {
					setStatusToRunning(file);
				}
			});
		}
		this.stopPolling();

		this.intervalId = this.notificationService.pollPromise(
			async (fids) => this.fileSource.pollFilesFunction(fids),
			this.updateFileJobStatus(fileIds),
			1000,
			fileIds,
		);

		this.initial = false;
	}

	stopPolling(): void {
		if (this.intervalId) {
			this.notificationService.cancel(this.intervalId);
			this.intervalId = 0;
		}
	}

	filesReady(): boolean {
		if (!this.fileContainer.files) {
			return true;
		}

		let returnValue = true;
		this.fileContainer.files.forEach((file) => {
			if (file.job && file.job.status && file.job.status.substring(0, 10) !== "COMPLETED_") {
				returnValue = false;
			}
		});
		return returnValue;
	}
}
@Component({
	selector: "stages-files-files",
	templateUrl: "./files.component.html",
})
export class FilesComponent implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	addFilesActionKey = "AddFiles";
	addUrlsActionKey = "AddURLs";
	checkinActionKey = "Checkin";
	checkoutActionKey = "Checkout";
	deleteActionKey = "Delete";
	deleteFilesActionKey = "DeleteFiles";
	deleteUrlsActionKey = "DeleteURLs";
	downloadActionKey = "Download";
	editPropertiesActionKey = "EditProperties";
	linkFilesActionKey = "LinkFiles";
	refreshActionKey = "Refresh";
	renameActionKey = "Rename";
	revertActionKey = "Revert";
	setFileStateActionKey = "SetFileState";
	setStateProcessActionKey = "SetState_process";
	uploadActionKey = "Upload";
	linkActionKey = "Link";

	fixedVersionMenuTitle = {
		title: "files.fixedVersion",
		subtitle: "files.fixedVersionSubtitle",
	};

	messageKeyForAdd!: string;
	mode!: string;
	renamedFile!: string;
	renameName!: string;
	uploadUrl!: string;
	startOptions!: StartOption[];
	menuItems!: MenuItem[];

	toolbarActivated: boolean = false;

	uploadCompletedCallback!: UploadCompletedCallback;
	filesDroppedCallback!: FilesDroppedCallback;
	_fileContainer!: FileContainer;

	filePolling!: FilePolling;

	@ViewChild("form")
	form!: NgForm;

	@Input()
	translateNone?: string;

	@Input()
	fileSource!: FileSource;

	@Input()
	layout?: string;

	@Input()
	showStartOptions?: boolean;

	constructor(
		private route: ActivatedRoute,
		private mainService: MainService,
		private uploadService: UploadService,
		private fileService: FileService,
		private notificationService: NotificationService,
		private utilService: UtilService,
		private keyboardService: KeyboardService,
		private mutexService: MutexService,
		private dialogService: DialogService,
		private card: CardComponent,
		private sanitizer: DomSanitizer,
	) {}

	ngOnInit(): void {
		this.fileService.inProgressFileIds$.pipe(takeUntil(this.destroy$)).subscribe((fileIds: Set<string>) => {
			this.filePolling.startPolling(Array.from(fileIds));
		});
	}

	ngOnDestroy(): void {
		this.filePolling.stopPolling();
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	get fileContainer(): FileContainer {
		return this._fileContainer;
	}

	@Input("fileContainer")
	set fileContainer(fileContainer: FileContainer) {
		this._fileContainer = fileContainer;
		this.messageKeyForAdd = this.fileService.getMessageKeyForAdd(
			false,
			fileContainer.files ? fileContainer.files.length : 0,
		);
		this.uploadCompletedCallback = () => {
			// Nothing to do
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		this.filesDroppedCallback = (files: any) => {
			this.uploadService.setPendingFiles(files);
			this.mainService.openPopup(
				[
					"files",
					"add",
					{
						singleFile: false,
						currentFileCount: files.length,
						elementType: fileContainer.elementType,
						elementId: fileContainer.elementId,
					},
				],
				this.route,
			);

			return false;
		};

		this.mode = "view";
		this.renameName = "";

		if (!this.filePolling) {
			this.filePolling = new FilePolling(this.notificationService, fileContainer, this.fileSource, this.fileService);
		}
		this.fileService.addInProgressFileIds((fileContainer.files ? fileContainer.files : []).map((file) => file.id));

		this.startOptions = [
			{
				classes: ["upload-file"],
				icon: "ico ico-add-file",
				title: "files.start.upload.title",
				message: "files.start.upload.message",
				button: "files.start.upload.button",
				disabled:
					!fileContainer.allowedOperations[this.addFilesActionKey] ||
					!fileContainer.allowedOperations[this.uploadActionKey],
				on: () => {
					this.onUpload(fileContainer);
				},
			},
			{
				classes: ["link-file"],
				icon: "ico ico-link-file",
				title: "files.start.cm.title",
				message: "files.start.cm.message",
				button: "files.start.cm.button",
				disabled:
					!fileContainer.allowedOperations[this.addFilesActionKey] ||
					!fileContainer.allowedOperations[this.linkFilesActionKey],
				on: () => {
					this.onLink(fileContainer);
				},
			},
			{
				classes: ["add-url"],
				icon: "ico ico-link",
				title: "files.start.url.title",
				message: "files.start.url.message",
				button: "files.start.url.button",
				disabled: !fileContainer.allowedOperations[this.addUrlsActionKey],
				on: () => {
					this.onAddUrl(fileContainer);
				},
			},
			{
				classes: ["file-template"],
				icon: "ico ico-add-file",
				title: "files.start.template.title",
				message: "files.start.template.message",
				button: "files.start.template.button",
				disabled:
					!fileContainer.allowedOperations[this.addFilesActionKey] ||
					!fileContainer.allowedOperations[this.uploadActionKey] ||
					this.fileContainer.templateFileDescriptors.length === 0,
				on: () => {
					this.onAddTemplate(fileContainer);
				},
			},
		];

		this.menuItems = [
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.uploadActionKey];
				},
				name: "files.upload",
				iconClass: "ico ico-cloud-upload",
				on: (file: File) => this.onCheckin(file),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.checkoutActionKey] || !this.isFileDownloadable(file);
				},
				name: "checkout",
				iconClass: "ico ico-export",
				on: (file: File) => this.lockAndDownload(file, true),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.checkinActionKey];
				},
				name: "checkin",
				iconClass: "ico ico-cloud-upload",
				on: (file: File) => this.onCheckin(file),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.checkoutActionKey];
				},
				name: "lock",
				iconClass: "ico ico-sign-in",
				on: (file: File) => this.lockAndDownload(file, false),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.revertActionKey];
				},
				name: "revert",
				iconClass: "ico ico-file-revert",
				on: (file: File) => this.unlock(file),
			},
			{
				disabled: (file: File) => {
					return (
						!file.allowedOperations[this.setFileStateActionKey] ||
						!file.allowedOperations[this.setStateProcessActionKey]
					);
				},
				name: "setState",
				iconClass: "ico ico-set-state",
				on: (file: File) => this.onSetState(file),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations.Versions;
				},
				name: "files.revisions",
				iconClass: "ico ico-filehistory",
				on: (file: File) => this.openVersionsDialog(file),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.refreshActionKey];
				},
				name: "refresh",
				iconClass: "ico ico-refresh",
				on: (file: File) => {
					return this.fileService.refresh(
						file.id,
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
					);
				},
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.renameActionKey];
				},
				name: "rename",
				iconClass: "ico ico-edit-text",
				on: (file: File) => this.rename(file),
			},
			{
				disabled: (file: File) => {
					return !file.allowedOperations[this.editPropertiesActionKey];
				},
				name: "properties",
				iconClass: "ico ico-edit",
				on: (file: File) => this.openPropertiesDialog(file),
			},
			{
				disabled: (file: File) => {
					return (
						!file.allowedOperations[this.deleteActionKey] ||
						// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
						((file as any).hyperlink && !fileContainer.allowedOperations[this.deleteUrlsActionKey]) ||
						// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
						(!(file as any).hyperlink && !fileContainer.allowedOperations[this.deleteFilesActionKey])
					);
				},
				name: "delete",
				iconClass: "ico ico-delete",
				on: (file: File) => {
					this.onDelete(file);
				},
			},
		];

		this.refreshToolbar();
	}

	private openPropertiesDialog(file: File): void {
		this.mainService.openPopup(
			[
				"files",
				file.source === "URL" ? "editUrl" : "properties",
				{
					elementType: file.elementType,
					elementId: file.elementId,
					fileId: file.id,
					currentWorkspaceId: this.route.snapshot.paramMap.get("workspaceId"),
				},
			],
			this.route,
		);
	}

	private lockAndDownload(file: File, download: boolean): void {
		this.fileService.lockAndDownload(file.id, file.elementType, file.elementId, download, this.route);
	}

	private unlock(file: File): void {
		this.fileService.unlock(file.id, file.elementType, file.elementId, this.route);
	}

	private openVersionsDialog(file: File): void {
		this.mainService.openDialog(
			[
				"files",
				"versions",
				{
					elementType: file.elementType,
					elementId: file.elementId,
					fileId: file.id,
				},
			],
			this.route,
		);
	}

	isFileDownloadable(file: File): boolean {
		return file.allowedOperations[this.downloadActionKey] || file.allowedOperations[this.linkActionKey];
	}

	isDisabled(file: File): boolean {
		if (!this.isFileDownloadable(file)) {
			return true;
		}
		return !file.exists;
	}

	isUploadAllowed(): boolean {
		return (
			!this.showStartOptions &&
			this.fileContainer &&
			this.fileContainer.allowedOperations[this.addFilesActionKey] &&
			this.fileContainer.allowedOperations[this.uploadActionKey]
		);
	}

	isURL(file: File): boolean {
		return file.source === "URL";
	}

	getTarget(file: File): string {
		return file.url && file.url.match(MAILTO_PATTERN) ? "_self" : "_blank";
	}

	getTargetRelation(file: File): string | null {
		return file.url && file.url.match(MAILTO_PATTERN) ? null : "noopener noreferrer";
	}

	onLeftClick(file: File): string | void {
		if (file.exists && this.isFileDownloadable(file)) {
			this.getFile(file);
		}
	}

	private getFile(file: File): void {
		const openNewTab: boolean = !file.allowedOperations[this.downloadActionKey];
		this.fileService.download(
			this.route,
			file.id,
			this.route.snapshot.paramMap.get("workspaceId")!,
			this.route.snapshot.paramMap.get("processVersion")!,
			null,
			openNewTab,
		);
	}

	getDragoutUrl(file: File): string | null {
		if (file.exists && this.isFileDownloadable(file) && file.contentType) {
			if (this.isURL(file)) {
				return file.url;
			}
			const baseUrl = window.location.protocol + "//" + window.location.host;
			return (
				file.contentType +
				":" +
				file.name +
				":" +
				baseUrl +
				"/stages/file/" +
				file.id +
				"?workspaceId=" +
				this.route.snapshot.paramMap.get("workspaceId")!
			);
		}

		return null;
	}

	getCopyLinkUrl(file: File): string | null {
		if (file.exists && this.isFileDownloadable(file) && file.contentType) {
			if (this.isURL(file)) {
				return file.url;
			}
			return "file/" + file.id + "?workspaceId=" + this.route.snapshot.paramMap.get("workspaceId")!;
		}

		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	onRightClick(event: any, file: File): void {
		if (file.exists) {
			event.currentTarget.setAttribute("href", this.getCopyLinkUrl(file));
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	onDragStart(event: any, file: File): void {
		if (file.exists && this.isFileDownloadable(file)) {
			const fileDragoutUrl = this.getDragoutUrl(file);
			event.dataTransfer.setData("DownloadURL", fileDragoutUrl);
		}
	}

	getUrlWithoutUnsafeForNonHttp(file: File): SafeUrl | string | null {
		if (file.url === null || !(file.url.match(URL_REGEX_PROTOCOL) || file.url.match(URL_REGEX_UNC))) {
			return file.url;
		}
		return this.sanitizer.bypassSecurityTrustUrl(file.url);
	}

	getIconClasses(file: File): string[] {
		if (file.source === "URL") {
			return ["ico", "ico-link"];
		}

		const classes = ["ico", "ico-file"];
		if (file.fileType) {
			classes.push("ico-ft-" + file.fileType);
		}

		return classes;
	}

	getFileStateClasses(file: File): string[] {
		return this.fileService.getFileStateClasses(file.stateIdent, file.stateMessageKey);
	}

	isShowProgressBar(file: File): boolean {
		return !!file.job && (file.job.status === "WAITING" || file.job.status === "RUNNING");
	}

	isUserAndDateKnown(file: File): boolean {
		return !!file.user && file.user.name !== "" && file.date !== "";
	}

	isOnlyUserKnown(file: File): boolean {
		return !!file.user && file.user.name !== "" && (!file.date || file.date === "");
	}

	isStateKnown(file: File): boolean {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
		return !!(file as any).state;
	}

	private rename(file: File): void {
		this.mode = "rename";
		this.renamedFile = file.id;
		if (this.renameName === "") {
			this.renameName = file.name;
		}
		if (!this.utilService.deviceIsIOS()) {
			window.setTimeout(() => {
				$("[stages-autofocus]").trigger("focus");
			}, 0);
		}
	}

	isRenamed(file: File): boolean {
		return this.renamedFile === file.id && this.mode === "rename";
	}

	onKeyUp(event: KeyboardEvent, file: File): void {
		if (this.keyboardService.isReturnKeyPressed(event)) {
			this.onRenameInput(event, file);
		} else if (this.keyboardService.isEscapeKeyPressed(event)) {
			this.onRenameBlur();
		}
	}

	onRenameBlur(): void {
		this.renameName = "";
		this.mode = "view";
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint".
	onRenameInput($event: any, file: File): void {
		if (this.renameName && this.form.valid) {
			this.mutexService.invoke("mutexRenameFile" + file.id, async () => {
				return this.fileService
					.rename(
						file.id,
						this.renameName,
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
					)
					.then(() => {
						file.name = this.renameName;
						this.renameName = "";
						$event.target.blur();
					});
			});
		}
	}

	showWarning(file: File): boolean {
		if (file.hasError || (file.job && file.job.status === "COMPLETED_FAILED")) {
			return true;
		}
		return false;
	}

	private onCheckin(file: File): void {
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
		this.mutexService.invoke("onOpenCheckinPopup" + file.id + file.elementType + file.elementId, async () => {
			this.fileService.openPopup(file.id, file.elementType, file.elementId, "checkin", false, this.route);
			return null;
		});
	}

	private onSetState(file: File): void {
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
		this.mutexService.invoke("onOpenSetStatePopup" + file.id + file.elementType + file.elementId, async () => {
			this.fileService.openPopup(file.id, file.elementType, file.elementId, "setState", false, this.route);
			return null;
		});
	}

	private onStart(fileContainer: FileContainer): void {
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
		this.mutexService.invoke("onOpenStartPopup" + fileContainer.elementType + fileContainer.elementId, async () => {
			this.mainService.openPopup(
				[
					"files",
					"start",
					{
						singleFile: false,
						elementType: fileContainer.elementType,
						elementId: fileContainer.elementId,
					},
				],
				this.route,
			);
			return null;
		});
	}

	private onUpload(fileContainer: FileContainer): void {
		this.mutexService.invoke("onOpenUploadPopup" + fileContainer.elementType + fileContainer.elementId, async () => {
			return this.fileService.openFilesAddPopup(
				fileContainer.elementType,
				fileContainer.elementId,
				false,
				this.fileContainer.files ? this.fileContainer.files.length : 0,
				this.route,
			);
		});
	}

	private onAddUrl(fileContainer: FileContainer): void {
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
		this.mutexService.invoke("onOpenAddUrlPopup" + fileContainer.elementType + fileContainer.elementId, async () => {
			this.mainService.openPopup(
				[
					"files",
					"addUrl",
					{
						elementType: fileContainer.elementType,
						elementId: fileContainer.elementId,
					},
				],
				this.route,
			);
			return null;
		});
	}

	private onLink(fileContainer: FileContainer): void {
		// eslint-disable-next-line @typescript-eslint/require-await -- TODO: Remove this comment and fix warnings! This comment was added as part of ST-32708: "Migrate from TSLint to ESLint". */
		this.mutexService.invoke("onOpenLinkPopup" + fileContainer.elementType + fileContainer.elementId, async () => {
			this.mainService.openPopup(
				[
					"files",
					"browse",
					{
						singleFile: false,
						elementType: fileContainer.elementType,
						elementId: fileContainer.elementId,
					},
				],
				this.route,
			);
			return null;
		});
	}

	private async onDelete(file: File): Promise<void> {
		if (await this.dialogService.confirm("delete.confirm.message", { name: file.name }, "delete", "cancel", true)) {
			this.mutexService.invoke("deleteFile" + file.id, async () => {
				this.removeFromUiList(file);
				return this.fileService
					.deleteFile(
						file,
						this.route.snapshot.paramMap.get("workspaceId")!,
						this.route.snapshot.paramMap.get("processVersion")!,
					)
					.then(() => {
						if (this.fileContainer.files.length < 1) {
							this.refreshToolbar();
						}
					});
			});
		}
	}

	onError(file: File): void {
		this.mainService.openPopup(
			[
				"files",
				"error",
				{
					fileId: file.id,
					job: file.job && file.job.name ? file.job.name : null,
				},
			],
			this.route,
		);
	}

	private onAddTemplate(fileContainer: FileContainer): void {
		this.mutexService.invoke("onOpenTemplatePopup" + fileContainer.elementType + fileContainer.elementId, async () => {
			return this.fileService.openFileTemplatePopup(fileContainer.elementType, fileContainer.elementId, this.route);
		});
	}

	private removeFromUiList(file: File): void {
		const files = this.fileContainer.files;
		for (let i = 0; i < files.length; i++) {
			if (files[i].id === file.id) {
				files.splice(i, 1);
				break;
			}
		}
	}

	private refreshToolbar(): void {
		if (!this.fileContainer.files || this.fileContainer.files.length < 1) {
			this.card.toolbarItems = [];
			this.toolbarActivated = false;
		}

		if (
			this.fileContainer.allowedOperations.AddFiles &&
			this.card &&
			(!this.showStartOptions || (this.fileContainer.files ? this.fileContainer.files.length : 0))
		) {
			const toolbarItems = [];

			toolbarItems.push({
				disabled: () => !this.fileContainer.allowedOperations.AddFiles,
				class: "add",
				iconClass: "ico ico-add",
				messageKey: this.messageKeyForAdd,
				onClick: () => {
					this.onStart(this.fileContainer);
				},
			});
			this.card.toolbarItems = toolbarItems;

			const menuItems = [];
			menuItems.push({
				iconClass: "ico ico-add",
				name: this.messageKeyForAdd,
				disabled: () => !this.fileContainer.allowedOperations.AddFiles,
				on: () => {
					this.onStart(this.fileContainer);
				},
			});
			this.card.menuItems = menuItems;
		}
	}

	someChangeMarker(files: File[]): boolean {
		return files.some((file) => !!file.changeMarker);
	}
}

function setStatusToRunning(file: File): void {
	if (!file.job) {
		file.job = {
			status: "RUNNING",
			name: null,
			group: null,
		};
	} else {
		file.job.status = "RUNNING";
	}
}
