import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { assertDefined } from "core/functions";

@Component({
	templateUrl: "unavailable.component.html",
})
export class UnavailableComponent {
	constructor(
		private readonly translateService: TranslateService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
	) {}

	retry(): void {
		const redirectTo = assertDefined(this.route.snapshot.queryParamMap.get("redirectTo"));
		this.router.navigateByUrl(redirectTo);
	}

	getMessage(messageKey: string, fallbackValue: string): string {
		const message: string = this.translateService.instant(messageKey);
		if (message.includes(messageKey)) {
			return fallbackValue;
		}
		return message;
	}
}
