import { Component, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { MutexService } from "common/concurrency/mutex.service";
import { ProcessVersionService, ProcessVersionWithAvailability } from "common/version/process-version.service";
import { MainService } from "core/main.service";
import { PreferencesService } from "core/preferences.service";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import ViewedProcess = stages.workspace.ViewedProcess;

type CurrentWorkspace = stages.workspace.application.CurrentWorkspace;
type ProcessVersion = stages.process.ProcessVersion;

@Component({
	selector: "stages-process-version-label",
	templateUrl: "./process-version-label.component.html",
})
export class ProcessVersionLabelComponent {
	@Input()
	versionName?: string;

	@Input()
	validVersion?: boolean;
}

@Component({
	selector: "stages-process-version",
	templateUrl: "./process-version.component.html",
})
export class ProcessVersionComponent implements OnDestroy {
	subscription: Subscription;

	@Input()
	suppressTypeCheck: boolean = false;

	@Input()
	type?: string;

	@Input()
	identity?: string;

	readonly validVersionIdentifier: string = "_vv";
	readonly workingVersionIdentifier: string = "_wv";
	//template values
	currentWorkspace$: Observable<CurrentWorkspace>;
	currentVersionIdentifier!: string;
	currentOffset: number = 1;
	dropdownOpen: boolean = false;
	loaded: boolean = false;
	filteredProcessVersions!: ProcessVersionWithAvailability[];
	shownProcessVersions!: ProcessVersionWithAvailability[];
	_queryParamMap?: ParamMap;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private mutexService: MutexService,
		private mainService: MainService,
		private processVersionService: ProcessVersionService,
		private preferencesService: PreferencesService,
	) {
		//TODO notify workspace change and process version not available
		//        this.mainService.getCurrentWorkspace().then((workspaceView: WorkspaceView) => {
		//            if (workspaceView.currentWorkspace.viewedProcess) {
		//                this.currentWorkspace = workspaceView.currentWorkspace;
		//                this.viewedProcess = workspaceView.currentWorkspace.viewedProcess;
		//                this.requestedVersionIdentifier = this.stateService.params.pv;
		//                if (this.viewedProcess.actions.READ_PROCESS_VERSIONS) {
		//                    this.processVersionSwitchable = true;
		//                }
		//                if (transition && mainService.isWorkspaceChangeFromAssociationOrLinkNavigation(transition)) {
		//                    this.notifyIfRequestedVersionNotAvailable();
		//                }
		//            }
		//        });
		//    }

		this.currentWorkspace$ = this.mainService.workspaceView$.pipe(
			map((workspaceView) => workspaceView.currentWorkspace),
		);
		this.subscription = this.route.paramMap.subscribe((paramMap) => {
			this.currentVersionIdentifier = paramMap.get("processVersion")!;
		});

		this.subscription.add(
			this.route.queryParamMap.subscribe((queryMap) => {
				this._queryParamMap = queryMap;
			}),
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	isAllowed(currentWorkspace: CurrentWorkspace): boolean {
		return currentWorkspace.viewedProcess!.actions.READ_PROCESS_VERSIONS && !this.mainService.secondaryWorkspaceId;
	}

	toggle(currentWorkspace: CurrentWorkspace): void {
		if (this.isAllowed(currentWorkspace)) {
			if (this.dropdownOpen) {
				this.closeDropdown();
			} else {
				this.updateProcessVersions(currentWorkspace);
				this.openDropdown();
			}
		}
	}

	closeDropdown(): void {
		this.dropdownOpen = false;
	}

	openDropdown(): void {
		this.dropdownOpen = true;
	}

	selectProcessVersion(processVersion: ProcessVersion): void {
		const processVersionURLSegment = 3;
		const cmPlanURLSegment = 7;
		const cmPlanURLValue = "plan";
		const segments = this.router.url.split("/");

		if (segments[cmPlanURLSegment] === cmPlanURLValue) {
			const cmPlanIndexURLSegment = cmPlanURLSegment + 1;
			const cmPlanIndexURLValue = "index";
			segments.splice(cmPlanIndexURLSegment, 1, cmPlanIndexURLValue);
		}

		segments[processVersionURLSegment] = encodeAndReplaceParenthesis(processVersion);
		if (this._queryParamMap?.has("pvForInterfaces")) {
			this.router.navigate(getUrlSegmentsAndParams(segments), {
				queryParams: { pvForInterfaces: processVersion.versionIdentifier },
				queryParamsHandling: "merge",
			});
		} else {
			this.router.navigateByUrl(segments.join("/"));
		}

		this.updatePreferences(processVersion.versionIdentifier);
	}

	private updateProcessVersions(currentWorkspace: CurrentWorkspace): void {
		this.mutexService.invoke("getProcessVersions4Switch", async () =>
			this.processVersionService
				.getProcessVersions(
					currentWorkspace.id,
					this.suppressTypeCheck,
					currentWorkspace.viewedProcess!.versionIdentifier,
					this.type ? this.type : this.route.snapshot.paramMap.get("type")!,
					this.identity ? this.identity : this.route.snapshot.paramMap.get("identity")!,
				)
				.then((processVersions: ProcessVersionWithAvailability[]) => {
					const validWorkingVersion = processVersions.filter((processVersion: ProcessVersion) => {
						return processVersion.workingVersion && processVersion.validVersion;
					});
					// if the working version is also the valid version, we want to have separated entries for them
					if (validWorkingVersion.length !== 0) {
						const nonValidWorkingVersion = { ...validWorkingVersion[0] };
						nonValidWorkingVersion.validVersion = false;
						nonValidWorkingVersion.versionIdentifier = this.workingVersionIdentifier;
						validWorkingVersion[0].versionIdentifier = this.validVersionIdentifier;
						processVersions.unshift(nonValidWorkingVersion);
					}
					this.filteredProcessVersions = processVersions.filter((processVersion: ProcessVersion) => {
						return (
							processVersion.id !== currentWorkspace.viewedProcess!.id ||
							(currentWorkspace.viewedProcess!.workingVersion &&
								this.currentVersionIdentifier !== processVersion.versionIdentifier)
						);
					});
					this.shownProcessVersions = this.filteredProcessVersions.slice(0, 5);
				}),
		);
	}

	loadMore(): void {
		this.currentOffset++;
		this.shownProcessVersions = this.filteredProcessVersions.slice(0, this.currentOffset * 5);
	}

	isProcessVersionShown(process: ProcessVersionWithAvailability, currentWorkspace: CurrentWorkspace): boolean {
		return (
			this.viewedProcessIsWorkingVersionAndPVIsEqualToRoutePV(currentWorkspace.viewedProcess!, process) ||
			this.viewedProcessIsNotWorkingVersionAndProcessIdsAreEqual(currentWorkspace.viewedProcess!, process)
		);
	}

	viewedProcessIsWorkingVersionAndPVIsEqualToRoutePV(
		viewedProcess: ViewedProcess,
		process: ProcessVersionWithAvailability,
	): boolean {
		return viewedProcess.workingVersion && process.versionIdentifier === this.currentVersionIdentifier;
	}

	viewedProcessIsNotWorkingVersionAndProcessIdsAreEqual(
		viewedProcess: ViewedProcess,
		process: ProcessVersionWithAvailability,
	): boolean {
		return !viewedProcess.workingVersion && viewedProcess.id === process.id;
	}

	private updatePreferences(processVersionIdentifier: string): void {
		this.preferencesService.setPreference("pv", {
			pv: processVersionIdentifier,
		});
	}

	//TODO
	//    private notifyIfRequestedVersionNotAvailable(): void {
	//        if (!this.isRequestedProcessVersion() && this.processVersionSwitchable) {
	//            this.broadcastService.broadcast(Events.MESSAGE, {
	//                translate: "process.version.versionNotAvailable"
	//            });
	//        }
	//    }
}

function getUrlSegmentsAndParams(segments: string[]): unknown[] {
	const resultsArray: unknown[] = [];
	const stopMarker = [":", ";", "&", "?"];
	const splitUrlPartAndParameters = "^([^;?]*)((:?\\;[^=]+\\=[^;?]+)*)(:?\\?.*?)?$";
	const parameterSplitter = "\\;([^=]+)\\=([^;]+)";

	segments.forEach((segment) => {
		if (hasStopMarker(segment, stopMarker)) {
			const urlPartAndParameters = new RegExp(splitUrlPartAndParameters).exec(segment);
			if (urlPartAndParameters) {
				resultsArray.push(decodeURIComponent(urlPartAndParameters[1]));

				const parameterPairs = urlPartAndParameters[2];
				if (parameterPairs.length > 0) {
					const paramsObject: StringToString = {};
					const paramNamesAndValues = parameterPairs.matchAll(new RegExp(parameterSplitter, "g"));
					for (const paramAndValue of paramNamesAndValues) {
						paramsObject[decodeURIComponent(paramAndValue[1])] = decodeURIComponent(paramAndValue[2]);
					}
					resultsArray.push(paramsObject);
				}
			}
		} else {
			resultsArray.push(decodeURIComponent(segment));
		}
	});
	return resultsArray;
}

function hasStopMarker(segment: string, stopMarker: string[]): boolean {
	for (const marker of stopMarker) {
		if (segment.includes(marker)) {
			return true;
		}
	}
	return false;
}

function encodeAndReplaceParenthesis(processVersion: ProcessVersion): string {
	return encodeURIComponent(processVersion.versionIdentifier).replace(/\(/g, "%28").replace(/\)/g, "%29");
}
